import { useEffect } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';

import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { useReturnSettingsMyProductQuery } from 'modules/preferences/hooks';
import {
  MY_PRODUCTS_RETURN_SETTINGS_OPTIONS,
  RETURN_SETTINGS_PREFERENCES_TEXT,
  SETTING_APPLIES_OPTIONS,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { PreferencePaper } from '../common';
import { SlzButtonGroup } from 'components/common/SlzButton';
import { mapReturnSettings } from 'modules/preferences/utils';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { Tooltip } from '../tooltip';
import { ReturnSettingDropdown } from '../other';

const ReturnSettingTitleDropdownHeader = ({ header, disabled, tooltip, ...rest }) => {
  return (
    <HStack {...rest}>
      <Text>{header}</Text>
      {tooltip}
    </HStack>
  );
};

const ProductsReturnsSettingForm = ({ disabled = false }) => {
  const { data, isRefetching, isFetching } = useReturnSettingsMyProductQuery();

  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.returnSettingMyProduct]: form } = forms;
  const methods = form?.form;

  useEffect(() => {
    if (!isFetching) {
      methods?.reset(mapReturnSettings(data));
    }
  }, [isFetching]);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <VStack w="full" alignItems="flex-start" opacity={disabled && '.5'} mb="0.875rem">
          <Text fontWeight="bold" fontSize="1.063rem" mt="2.25rem">
            {RETURN_SETTINGS_PREFERENCES_TEXT.productsReturnHeaderText}
          </Text>
          <Skeleton w="full" isLoaded={!isFetching} h="fit-content" borderRadius={3}>
            <PreferencePaper w="full" bg="light.300" mt={1.25} p={5}>
              <Controller
                control={methods?.control}
                name="returnable"
                render={({ field }) => {
                  return (
                    <SlzButtonGroup
                      size="lg"
                      variant="returnSetting"
                      spacing="0"
                      isDisabled={disabled}
                      defaultValue={field?.value}
                      options={MY_PRODUCTS_RETURN_SETTINGS_OPTIONS}
                      value={field?.value}
                      onChange={field?.onChange}
                    />
                  );
                }}
              />

              <Box flex="1" w="full">
                <ReturnSettingTitleDropdownHeader
                  header={RETURN_SETTINGS_PREFERENCES_TEXT.productsReturnDropdownText}
                  fontSize="lg"
                  mb={1}
                  mt={7}
                  tooltip={
                    <Tooltip placement="right" disabled={disabled} trigger="hover" content={null} />
                  }
                />

                <Controller
                  control={methods?.control}
                  name="selection"
                  render={({ field }) => {
                    return (
                      <ReturnSettingDropdown
                        w="full"
                        fontSize="lg"
                        isLoaded={!isRefetching}
                        disabled={disabled}
                        options={SETTING_APPLIES_OPTIONS}
                        value={field?.value}
                        onChange={field?.onChange}
                      />
                    );
                  }}
                />
              </Box>
            </PreferencePaper>
          </Skeleton>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default ProductsReturnsSettingForm;
