import React from 'react';
import { Box, HStack, Icon, useRadio, useRadioGroup } from '@chakra-ui/react';
import { DashboardIcon } from 'components/common/Icons';

const invalidSx = {
  '&[aria-invalid="true"]': {
    border: '1px solid',
    borderColor: 'negative.500'
  }
};

const disabledSx = {
  color: 'dark.300',
  bgColor: 'light.500',
  cursor: 'not-allowed'
};

function SelectorCardGroup({
  children,
  isDisabled,
  isInvalid,
  icon = DashboardIcon,
  title = 'Select an option',
  name,
  defaultValue,
  value,
  onChange,
  ...props
}) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    isDisabled,
    name,
    defaultValue,
    value,
    onChange
  });

  return (
    <Box w="fit-content">
      <Box mb="0.5" rounded="md" bgColor="light.500" px={6} py={3}>
        <Box
          as="span"
          bgColor="main.500"
          color="white"
          display="inline-block"
          rounded="full"
          w={5}
          h={5}
          lineHeight={1.2}
          px="0.3125rem"
          mr={2}>
          <Icon as={icon} w={2.5} h={2.5} mb={0.5} color="white" />
        </Box>

        <Box as="span" fontWeight="bold">
          {title}
        </Box>
      </Box>

      <HStack
        spacing={8}
        px="2.875rem"
        py="2rem"
        bgColor="light.500"
        rounded="md"
        sx={invalidSx}
        aria-invalid={isInvalid}
        {...getRootProps()}
        {...props}>
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return child;
          }
          const { value } = child.props;
          return React.cloneElement(child, { isInvalid, isDisabled, ...getRadioProps({ value }) });
        })}
      </HStack>
    </Box>
  );
}

function SelectorCard({ label, isInvalid, ...props }) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const inputProps = getInputProps();
  const radioProps = getRadioProps();

  return (
    <Box color="dark.500">
      <Box as="label" m={0} textAlign="center">
        <input {...inputProps} />
        <Box
          cursor="pointer"
          borderWidth="1px"
          rounded="xl"
          shadow="slz-md"
          bgColor="white"
          px={4}
          w="21rem"
          h="11rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
          _checked={{
            color: 'main.500',
            borderColor: 'main.500'
          }}
          _disabled={disabledSx}
          sx={invalidSx}
          aria-invalid={isInvalid}
          {...radioProps}>
          {props.children}
        </Box>
      </Box>

      {label && (
        <Box
          mt={4}
          fontSize="xl"
          textAlign="center"
          _checked={{
            color: 'main.500',
            fontWeight: 'bold'
          }}
          _disabled={disabledSx}
          data-checked={radioProps['data-checked']}
          data-disabled={radioProps['data-disabled']}>
          {label}
        </Box>
      )}
    </Box>
  );
}

SelectorCard.Group = SelectorCardGroup;

export default SelectorCard;
