import { Box, Text, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzLabel = (props) => {
  const { children, variant, textColor, colorScheme, ...rest } = props;
  const styles = useStyleConfig('SlzLabel', { variant, colorScheme, textColor });
  return (
    <Box
      as="span"
      __css={{ ...styles, ...rest }}
      data-testid="label-wrap"
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Label`)}>
      <Text noOfLines={1}>{children}</Text>
    </Box>
  );
};

export default SlzLabel;
