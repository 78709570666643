import { Box, useStyleConfig } from '@chakra-ui/react';
import Currency, { flagAPI } from '../../../constants/currency-country';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants'

const SlzCurrencyIcon = (props) => {
  const { code, size } = props;
  const url = `${flagAPI}${code}.svg`;
  const currencyName = Currency.country.find((x) => x.countryCode === code);
  const styles = useStyleConfig('SlzCurrencyIcon', { size, url });
  const { wrapper, images, text } = styles;
  return (
    <Box className="currency-icon-wrapper"
      __css={wrapper}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-CurrencyIcon`)}
    >
      <Box sx={images} data-testid='slz-currency-icon' className='slz-currency-icon'></Box>
      <Box sx={text}>
        <span>{currencyName?.currencyCode}</span>
      </Box>
    </Box>
  );
};
export default SlzCurrencyIcon;
