import { Grid, GridItem, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import SlzCTA from '../../components/common/SlzCTA/SlzCTA';

import { SlzButton } from '../../components/common/SlzButton';
import { SlzTimelineStepper } from '../../components/common/SlzTimelineStepper';
import SlzToast from '../../components/common/SlzToast/SlzToast';
import { useRef } from 'react';
import SlzLink from '../../components/common/SlzLink/SlzLink';

const CTAs = () => (
  <Stack direction="column">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      CTAs
    </Text>
    <VStack spacing={1} alignItems="start">
      {['lg', 'md', 'sm'].map((size) => (
        <SlzCTA
          size={size}
          variant="solid"
          colorScheme="grunge"
          onClose={() => {
            console.log('close cta...');
          }}
          onClick={() => {
            console.log('click cta...');
          }}>
          This is a CTA,{' '}
          <SlzLink variant="underline" href="#" colorScheme="white">
            do something about it
          </SlzLink>
        </SlzCTA>
      ))}
    </VStack>
    <VStack spacing={1} alignItems="start">
      {['lg', 'md', 'sm'].map((size) => (
        <SlzCTA
          size={size}
          variant="solid"
          colorScheme="indigo"
          onClose={() => {
            console.log('close cta...');
          }}
          onClick={() => {
            console.log('click cta...');
          }}>
          This is a CTA, do something about it
        </SlzCTA>
      ))}
    </VStack>
    <VStack spacing={1} alignItems="start">
      {['lg', 'md', 'sm'].map((size) => (
        <SlzCTA
          size={size}
          variant="outline"
          colorScheme="main"
          onClose={() => {
            console.log('close cta...');
          }}
          onClick={() => {
            console.log('click cta...');
          }}>
          This is a CTA, do something about it
        </SlzCTA>
      ))}
    </VStack>

    <VStack spacing={1} alignItems="start">
      {['lg', 'md'].map((size) => (
        <SlzCTA
          width="827px"
          size={size}
          variant="block"
          colorScheme="red"
          buttons={
            <>
              <SlzButton
                size="sm"
                variant="solid"
                colorScheme="red"
                color="red.300"
                bg="light.300"
                onClick={() => {
                  console.log('click ignore this...');
                }}>
                Ignore this
              </SlzButton>
              <SlzButton
                size="sm"
                variant="outline"
                colorScheme="light"
                color="light.300"
                onClick={() => {
                  console.log('click configure...');
                }}>
                Configure
              </SlzButton>
            </>
          }
          onClose={() => {
            console.log('close cta...');
          }}>
          <Text color="light.300" fontWeight="bold" mb="15px">
            You have not completed your shipping options!
          </Text>{' '}
          Not configuring your shipping options may result in higher delivery charges than expected.{' '}
          <br />
          We recommend you assign all your Shopify shipping rates to the Selazar delivery times.{' '}
          <br />
          Default times are used in case we cannot determine the correct shipping option for your
          order.
        </SlzCTA>
      ))}
    </VStack>
  </Stack>
);

const Toasts = (props) => (
  <Stack w="100%" my={5}>
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Toasts
    </Text>
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem w="100%">
        <SlzToast
          variant="solid"
          colorScheme="main"
          size="md"
          description="Read me please!"
          status="info"
          {...props}
        />
      </GridItem>
      <GridItem w="100%">
        <SlzToast
          variant="subtle"
          colorScheme="main"
          size="md"
          description="Read me please!"
          status="info"
          {...props}
        />
      </GridItem>
      <GridItem w="100%">
        <SlzToast
          variant="solid"
          colorScheme="dark"
          size="md"
          description="Read me please!"
          status="info"
          {...props}
        />
      </GridItem>
      <GridItem w="100%">
        <SlzToast
          variant="subtle"
          colorScheme="dark"
          size="md"
          description="Read me please!"
          status="info"
          {...props}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem w="100%">
        <SlzToast
          variant="solid"
          colorScheme="positive"
          size="lg"
          description="Read me please!"
          status="success"
          {...props}
        />
      </GridItem>
      <GridItem w="100%">
        <SlzToast
          variant="subtle"
          colorScheme="positive"
          size="lg"
          description="Well done you!"
          status="success"
          {...props}
        />
      </GridItem>
    </Grid>
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      <GridItem w="100%">
        <SlzToast
          variant="solid"
          colorScheme="negative"
          size="lg"
          description="Read me please!"
          status="warning"
          {...props}
        />
      </GridItem>
      <GridItem w="100%">
        <SlzToast
          variant="subtle"
          colorScheme="negative"
          size="lg"
          description="Sorry but no, just no"
          status="warning"
          {...props}
        />
      </GridItem>
    </Grid>
  </Stack>
);

const Timeline = () => {
  const timelineRef = useRef(null);

  return (
    <Stack py="4">
      <SlzTimelineStepper
        ref={timelineRef}
        steps={[
          { key: 1, label: 'Step 1' },
          { key: 2, label: 'Step 2' },
          { key: 3, label: 'Step 3' },
          { key: 4, label: 'Step 4' },
          { key: 5, label: 'Step 5' }
        ]}
        step={1}
        onCurrentStepChange={(stepIndex) => console.log(stepIndex)}
      />
      <HStack>
        <SlzButton onClick={() => timelineRef.current.back()}>Prev</SlzButton>
        <SlzButton onClick={() => timelineRef.current.next()}>Next</SlzButton>
      </HStack>
    </Stack>
  );
};

const ElementTwo = () => {
  const sizes = ['lg', 'md', 'sm'];

  return (
    <>
      <CTAs sizes={sizes} />
      <Toasts />
      <Timeline />
    </>
  );
};

export default ElementTwo;
