import { useQuery } from 'react-query';
import { getOrdersToday } from '../services/dashboard-api';

export const useOrdersTodayQuery = ({ select }) => {
  return useQuery({
    queryKey: [`orders-today`],
    queryFn: getOrdersToday,
    select
  });
};
