import { UPDATE_PRODUCTS_ACTIONS } from '../actions';
import { ERROR_TYPE, INITIAL_PREFERENCE_STATE, PREFERENCES_ACTIONS } from '../constants';

export const preferenceReducer = (state = INITIAL_PREFERENCE_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    // case PREFERENCES_ACTIONS.UPDATE_FORM_VALUES: {
    //   return { ...state, formValues: payload.formValues };
    // }
    // case PREFERENCES_ACTIONS.SET_IS_ALLOW_TO_NAVIGATE: {
    //   return { ...state, isAllowToNavigate: payload.isAllowToNavigate };
    // }
    case PREFERENCES_ACTIONS.SET_MODAL_LAYOUT: {
      return { ...state, modalLayout: payload.modalLayout };
    }
    // case PREFERENCES_ACTIONS.UPDATE_EORI_FORM_VALUES: {
    //   return { ...state, formValues: { ...state.formValues, eoriForm: payload.eoriFormValue } };
    // }
    case PREFERENCES_ACTIONS.MANAGED_TYPE: {
      return { ...state, managedType: payload.managedType };
    }

    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};

export const updateProductsReducers = (state, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS_ACTIONS.REPLACE:
      return action.products;

    case UPDATE_PRODUCTS_ACTIONS.OPEN_CONFIRM_MODAL:
      return state.map((productImage) => ({
        ...productImage,
        isShowConfirmation: productImage.itemID === action.productId
      }));

    case UPDATE_PRODUCTS_ACTIONS.CANCEL_CONFIRM_MODAL:
      return state.map((productImage) => ({
        ...productImage,
        isShowConfirmation: false,
        isError: productImage.itemID === action.productId ? false : productImage.isError
      }));

    case UPDATE_PRODUCTS_ACTIONS.CLOSE_CONFIRM_MODAL:
      return state.map((productImage) => ({
        ...productImage,
        isShowConfirmation: false
      }));

    case UPDATE_PRODUCTS_ACTIONS.FILE_SIZE_ERROR:
      return state.map((productImage) =>
        productImage.itemID === action.productId
          ? {
              ...productImage,
              isError: true,
              errorType: ERROR_TYPE.LIMIT_EXCEEDED,
              isShowConfirmation: true
            }
          : { ...productImage }
      );

    case UPDATE_PRODUCTS_ACTIONS.UPLOADING_IMAGE:
      return state.map((productImage) => ({
        ...productImage,
        isUploading: productImage.itemID === action.productId,
        isError: !productImage.itemID === action.productId && productImage.isError
      }));

    case UPDATE_PRODUCTS_ACTIONS.UPLOAD_SUCCESS:
      return state.map((productImage) => ({
        ...productImage,
        isSucceed: productImage.itemID === action.productId
      }));

    case UPDATE_PRODUCTS_ACTIONS.LOAD_FILE_ERROR:
      return state.map((productImage) =>
        productImage.itemID === action.productId
          ? {
              ...productImage,
              isError: true,
              errorType: ERROR_TYPE.INCORRECT_IMAGE_FORMAT,
              isShowConfirmation: true
            }
          : { ...productImage }
      );

    default:
      return state;
  }
};
