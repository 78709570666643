import { useState } from 'react';
import { HStack, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';

import { LEAVE_WARNING_TEXT } from 'modules/preferences/constants';

const ConfirmLeaveToast = ({
  message = LEAVE_WARNING_TEXT,
  leaveBtnText = 'Leave',
  saveBtnText = 'Save',
  onLeaveClick,
  onSaveClick
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleSaveClick = async () => {
    setIsClicked(true);
    await onSaveClick();
    setIsClicked(false);
  };

  return (
    <HStack ps={6} spacing={3}>
      <Text fontSize="1rem" fontWeight="normal" color="dark.700">
        {message}
      </Text>
      <SlzButton
        size="md"
        variant="outline"
        borderRadius={1.5}
        borderColor="dark.700"
        colorScheme="dark"
        onClick={onLeaveClick}>
        {leaveBtnText}
      </SlzButton>
      <SlzButton
        size="md"
        borderRadius={1.5}
        color="sunshine.500"
        bg="dark.700"
        colorScheme="dark"
        isLoading={isClicked}
        onClick={handleSaveClick}>
        {saveBtnText}
      </SlzButton>
    </HStack>
  );
};

export default ConfirmLeaveToast;
