import React from 'react';
import { IconButton, Tooltip, useStyles } from '@chakra-ui/react';

export default function NavbarButton({
  icon: Icon,
  name,
  isActive,
  isShowArrow,
  isExpanded,
  onClick
}) {
  const styles = useStyles();

  return (
    <Tooltip
      label={name}
      placement="top-start"
      fontSize="md"
      bgColor="main.500"
      offset={[0, 5]}
      isDisabled={isExpanded}>
      <IconButton
        sx={{
          ...styles.navbarButton,
          _before: {
            ...styles.navbarButton._before,
            content: isExpanded ? `'${name}'` : '""'
          }
        }}
        aria-label={name}
        icon={<Icon />}
        isActive={isActive}
        data-show-arrow={isShowArrow}
        onClick={onClick}
      />
    </Tooltip>
  );
}
