export const ORDER_STATUS = {
  all: {
    label: 'All',
    color: '#95BF47',
    key: 'all'
  },
  pending: {
    label: 'Pending',
    color: '#453C67',
    key: 'pending'
  },
  processing: {
    label: 'In process',
    color: '#F2F7A1',
    key: 'processing'
  },
  processed: {
    label: 'Processed',
    color: '#46C2CB',
    key: 'processed'
  },
  unsuccessful: {
    label: 'Unsuccessful',
    color: '#FF6E31',
    key: 'unsuccessful'
  },
  partial: {
    label: 'Partial',
    color: '#6D67E4',
    key: 'partial'
  },
  onhold: {
    label: 'On hold',
    color: '#FF6E31',
    key: 'onhold'
  }
};

export const UNTRACKED_SERVICE_PREFIX = 'UTK_';
export const UNTRACKED_SERVICE = 'Untracked Service';

export const INDEX_OF_STATUS_ALL = 0;

export const ORDER_PROGRESSES = {
  ORDER_DETAILS: 1,
  DELIVERY_DETAILS: 2,
  ORDER_SUMMARY: 3,
  CONFIRMATION: 4
};

export const ORDER_STEPS = [
  {
    key: 1,
    label: 'Order details'
  },
  {
    key: 2,
    label: 'Delivery details'
  },
  {
    key: 3,
    label: 'Order summary'
  },
  {
    key: 4,
    label: 'Confirmation'
  }
];
// Order Number, Tracking Reference, Customer name, Source
export const ORDER_DISPLAY_FIELDS_INPUT_SEARCH = [
  'customerReference',
  'customerOrganizationPersonName',
  'trackingCode',
  'source'
];

export const ORDER_DISPLAY_FIELDS = [
  'customerReference',
  'customerOrganizationPersonName',
  'status',
  'trackingCode',
  'source',
  'createdDate'
];

export const ORDER_KEY = 'order_list';
export const ORDER_COL_SPAN = 10;
export const ORDER_TYPE = 'order';
export const VARIANT_DETAIL_ORDER = 'detailOrder';
export const ORDER_PROCESSING_STATUS = [
  { key: 1, label: 'Order received', color: '#579BFC' },
  { key: 2, label: 'Processing', color: '#FFAD3B' },
  { key: 3, label: 'Fulfilment in progress', color: '#23D57E' },
  { key: 4, label: 'Awaiting fulfilment', color: '#FD8458' },
  { key: 5, label: 'Order shipped', color: '#3CD1CA' }
];

export const ORDER_PROCESSING_STATUS_MAPPING = {
  'Order received': ORDER_PROCESSING_STATUS[0],
  Processing: ORDER_PROCESSING_STATUS[1],
  Pending: ORDER_PROCESSING_STATUS[1],
  'Fulfilment in progress': ORDER_PROCESSING_STATUS[2],
  'Awaiting fulfilment': ORDER_PROCESSING_STATUS[3],
  'Awaiting Fulfilment - PPO': ORDER_PROCESSING_STATUS[3],
  'Order shipped': ORDER_PROCESSING_STATUS[4],
  'Shipped - PPO': ORDER_PROCESSING_STATUS[4]
};

export const ORDER_UNSUCCESSFUL_SUB_STATUS = {
  processingFailed: 'Processing Failed',
  partFailure: 'Part Failure'
};

export const BUSINESS_MODEL = {
  B2C: 'B2C',
  B2B: 'B2B'
};

export const ORDER_KEY_PARAM = {
  IS_CREATE_ORDER_OPTION_MODAL: 'isCreateOrderOptionModal', // Open 1st modal
  IS_CREATE_B2C_ORDER_MODAL: 'isCreateB2COrderModal',
  IS_CREATE_SINGLE_ORDER_MODAL: 'isCreateSingleOrderModal',
  IS_CREATE_MULTIPLE_ORDER_MODAL: 'isCreateMutipleOrderModal',
  IS_CREATE_B2B_ORDER_MODAL: 'isCreateB2BOrderModal',
  IS_TRACKING_MODAL: 'isTrackingModal'
};

export const addOrderDefaultValue = {
  productsCount: 6,
  orderDetails: {
    id: 123332112313121,
    name: 'John Smith',
    phoneNumber: '+84999994994949',
    email: 'john.smith@selazar.com',
    address: {
      number: 60,
      street: 'Andras House',
      town: 'Belfast',
      country: 'Antrim',
      postCode: 'BT2 7BB',
      countryCode: 'UK'
    }
  },
  products: [
    {
      id: 'product id 1',
      sku: 'SKU Product 1',
      count: 1
    },
    {
      id: 'product id 2',
      sku: 'SKU Product 2',
      count: 2
    },
    {
      id: 'product id 3',
      sku: 'SKU Product 3',
      count: 3
    },
    {
      id: 'product id 4',
      sku: 'SKU Product 4',
      count: 4
    },
    {
      id: 'product id 5',
      sku: 'SKU Product 5',
      count: 5
    },
    {
      id: 'product id 6',
      sku: 'SKU Product 6',
      count: 6
    }
  ],
  notes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
  It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
  desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
};

export const MAX_ITEMS = 50;

export const timeDeliveryDetailsB2c = [
  { id: 1, value: '24 hrs' },
  { id: 6, value: '1-2 days' },
  { id: 2, value: '24 hrs pre 10:30' },
  { id: 7, value: '2-3 days' },
  { id: 3, value: '24 hrs pre-noon' },
  { id: 8, value: '3-5 days' },
  { id: 4, value: '48 hrs' },
  { id: 9, value: '5-10 days' },
  { id: 5, value: '72 hrs' }
];

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Orders'
  },
  {
    path: null,
    title: 'B2C Orders'
  }
];

export const ORDER_SKELETON_CELL = {
  id: null,
  customerReference: null,
  customerOrganizationPersonName: null,
  status: null,
  trackingCode: null,
  source: null,
  createdDate: null,
  orderContents: []
};

export const CREATE_ORDER_OPTIONS = [
  {
    value: BUSINESS_MODEL.B2C,
    text: 'Create B2C order'
  },
  //Temporarily commented out
  /* {
    value: BUSINESS_MODEL.B2B,
    text: 'Create B2B order'
  } */
];

export const INITIAL_ORDER_DETAIL_ITEM = { productId: '', sku: '', count: 1 };

export const INITIAL_ORDER_VALUE = {
  orderId: '',
  items: [INITIAL_ORDER_DETAIL_ITEM],
  phoneNumber: '',
  addressOne: '',
  addressTwo: '',
  countryCode: { value: 'GB', label: 'GB - United Kingdom' },
  postCode: '',
  county: '',
  email: '',
  name: '',
  notes: ''
};

export const INITIAL_ORDER_STATE = {
  list: [],
  params: {},
  filters: []
};

export const B2C_ORDERS_PATHNAME = '/orders/b2c';

export const ISSUE_MESSAGE = `Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fugit vero ducimus architecto
officia quae earum amet veniam numquam, similique odio. Quaerat, a deleniti natus magni
in quidem ipsa accusamus reprehenderit.`; // mock , need to get api

export const Countries = [
  { value: 'AD', label: 'Andorra' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AL', label: 'Albania' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AO', label: 'Angola' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AT', label: 'Austria' },
  { value: 'AU', label: 'Australia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BO', label: 'Bolivia (Plurinational State of)' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BR', label: 'Brazil' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BZ', label: 'Belize' },
  { value: 'CA', label: 'Canada' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CD', label: 'Congo, Democratic Republic of the' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'CG', label: 'Congo' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'CI', label: "Côte d'Ivoire" },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CL', label: 'Chile' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  { value: 'DE', label: 'Germany' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EE', label: 'Estonia' },
  { value: 'EG', label: 'Egypt' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'ES', label: 'Spain' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FI', label: 'Finland' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FM', label: 'Micronesia (Federated States of)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FR', label: 'France' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'GR', label: 'Greece' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GU', label: 'Guam' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HR', label: 'Croatia' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HU', label: 'Hungary' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IN', label: 'India' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IR', label: 'Iran (Islamic Republic of)' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IT', label: 'Italy' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JO', label: 'Jordan' },
  { value: 'JP', label: 'Japan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KM', label: 'Comoros' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'KP', label: "Korea (Democratic People's Republic of)" },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'LA', label: "Lao People's Democratic Republic" },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LY', label: 'Libya' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'ML', label: 'Mali' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MO', label: 'Macao' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MT', label: 'Malta' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MV', label: 'Maldives' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NE', label: 'Niger' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NU', label: 'Niue' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'OM', label: 'Oman' },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Peru' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PL', label: 'Poland' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PW', label: 'Palau' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RS', label: 'Serbia' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SE', label: 'Sweden' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SM', label: 'San Marino' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SO', label: 'Somalia' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TD', label: 'Chad' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'TG', label: 'Togo' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'US', label: 'United States of America' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VA', label: 'Holy See' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'VE', label: 'Venezuela (Bolivarian Republic of)' },
  { value: 'VG', label: 'Virgin Islands (British)' },
  { value: 'VI', label: 'Virgin Islands (U.S.)' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'WS', label: 'Samoa' },
  { value: 'YE', label: 'Yemen' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
];

export const B2C_SINGLE_ORDER_PREFIX = 'b2c.singleOrder';

export const SHOPIFY_ORDER_SOURCE = "Shopify";
export const WOOCOMMERCE_ORDER_SOURCE = "WooCommerce";

export const WARNING_CANCEL_TOAST_ID = 'warning-leave-toast-id';
export const CONFIRM_LEAVE_TOAST_ID = 'confirmLeaveToastId';

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const STYLE_FAILURE_TOAST = {
  colorScheme: 'negative',
  status: 'failure',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const STYLE_SUCCESS_TOAST = {
  colorScheme: 'positive',
  status: 'success',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const TOAST_SUCCESS_MESSAGE = {
  CANCELLED: 'Order has been cancelled!'
};

export const CANCEL_ORDER_TEXT = {
    success: 'Order has been cancelled!',
    failure: 'Order has not been cancelled',
    save: 'Save',
    leavingMessage: 'Are you sure you want to cancel order ',
    warningMessage: '? You cannot undo this action.'
  };