import { Box, Text } from '@chakra-ui/react';
import React from 'react';

export default function AddressCard({ address }) {
  return (
    <Box bg="main.300" p="5" lineHeight="1.3125em">
      <Text mb="0">{address.line}</Text>
      <Text mb="0">
        {address.town}, {address.county}
      </Text>
      <Text mb="0">{address.country}</Text>
      <Text mb="0">{address.postcode}</Text>
    </Box>
  );
}
