import { Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Box, HStack, Text, useToken } from '@chakra-ui/react';
import { HiOutlinePhone } from 'react-icons/hi';

import { SlzButton } from 'components/common/SlzButton';
import SlzLink from 'components/common/SlzLink/SlzLink';

import {
  B2B_ORDER_ACTION_CONTENT,
  BULK_ORDER_CREATE_TEXT,
  BULK_ORDER_EXPLANATIONS
} from 'modules/b2b-orders/constants';
import {
  B2BOrderAction,
  B2BOrderExplanationBox,
  B2BOrderNote
} from 'modules/b2b-orders/components/other';

const B2BOverviewContent = () => {
  const [main500] = useToken('colors', ['main.500']);

  return (
    <>
      <HStack justifyContent="space-between">
        {BULK_ORDER_EXPLANATIONS.map(({ icon, label, description }) => (
          <B2BOrderExplanationBox
            key={uniqueId()}
            icon={icon}
            label={label}
            description={description}
            _first={{ '> p': { px: 10 } }}
            _last={{
              '> p': { px: 9 },
              svg: {
                ml: 2,
                stroke: main500
              }
            }}
          />
        ))}
      </HStack>
      <HStack
        spacing={8}
        mt={4}
        bg="light.300"
        borderRadius={1.5}
        border="1px"
        borderColor="dark.300"
        h="5.25rem"
        pt={4}
        pr={16}
        pb={6}
        pl={7}>
        <HiOutlinePhone style={{ stroke: main500 }} size="2.563rem" />
        <Text>
          <Box as="span">{BULK_ORDER_CREATE_TEXT.OVERVIEW.contactText}</Box>
          <SlzLink
            variant="underline"
            color="main.500"
            href={`mailto:${BULK_ORDER_CREATE_TEXT.OVERVIEW.email}`}>
            {BULK_ORDER_CREATE_TEXT.OVERVIEW.customerServiceTeam}
          </SlzLink>
          <Box as="span">
            {BULK_ORDER_CREATE_TEXT.OVERVIEW.callAt}
            {BULK_ORDER_CREATE_TEXT.OVERVIEW.telephone}
          </Box>
        </Text>
      </HStack>
      <B2BOrderNote noteContent={BULK_ORDER_CREATE_TEXT.OVERVIEW.noteContent} mt={4} />
    </>
  );
};

const B2BOverviewStep = (props) => {
  return (
    <Fragment>
      <B2BOverviewContent />
      <B2BOrderAction {...props}>
        <SlzButton variant="solid" size="lg" onClick={() => props.onNext()}>
          {B2B_ORDER_ACTION_CONTENT.start}
        </SlzButton>
      </B2BOrderAction>
    </Fragment>
  );
};
export default B2BOverviewStep;
