import { HStack, Text, VStack } from '@chakra-ui/react';
import { HomeIcon } from 'components/common/Icons';
import {
  Card,
  CardBody,
  CardFooter,
  CardIcon,
  CardPermissions,
  CardRoleBanner,
  CardRolesAndPermissions
} from './CardRolesAndPermissions';
import { RETAILER_OWNER } from './constants';
import { HORIZONTAL_CARDS_STYLES } from './styles';

const HorizontalRolesAndPermsCard = () => {
  return (
    <HStack>
      <CardRolesAndPermissions styles={HORIZONTAL_CARDS_STYLES}>
        <Card spacing={0} wrap={HStack}>
          <CardIcon>
            <HomeIcon w="53px" h="53px" color="main.500" />
          </CardIcon>
          <CardBody spacing="0px" alignItems="start" flexBasis="50%">
            <VStack h="45px" spacing="0px" alignItems="start">
              <Text color="main.500" fontSize="1rem" lineHeight="21px" fontWeight="bold">
                John Doe
              </Text>
              <Text fontSize="12px" lineHeight="16px">
                johndoe@company.com
              </Text>
            </VStack>
            <CardRoleBanner
              w="150px"
              h="25px"
              textAlign="center"
              fontSize="1rem"
              bg="szrDenim.700"
              color="lightAndShadow.reverseText">
              Retailer Owner
            </CardRoleBanner>
          </CardBody>
          <CardFooter spacing="3px">
            <CardRoleBanner
              color="lightAndShadow.reverseText"
              h="28px"
              px="12px"
              justifyContent="start"
              fontSize="12px"
              lineHeight="16px"
              bg="main.500">
              Retailer owner can access
            </CardRoleBanner>
            <CardPermissions>{RETAILER_OWNER.PERMISSIONS}</CardPermissions>
          </CardFooter>
        </Card>
      </CardRolesAndPermissions>
    </HStack>
  );
};

export default HorizontalRolesAndPermsCard;
