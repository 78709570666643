import { HStack, Text } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';

const SuccessCancelToast = ({ warningText, onOKClick }) => {
  return (
    <HStack ps={6} spacing={3}>
      <Text fontSize="1rem" fontWeight="normal" color="dark.700">
        {warningText}
      </Text>
      <SlzButton
        size="md"
        borderRadius={1.5}
        color="sunshine.500"
        bg="dark.700"
        colorScheme="dark"
        onClick={onOKClick}>
        Ok
      </SlzButton>
    </HStack>
  );
};

export default SuccessCancelToast;