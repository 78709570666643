import { useEffect } from 'react';
import { Checkbox, HStack, Text } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { Controller } from 'react-hook-form';

const B2BCourierResults = ({ control, shippingService, toggleShippingService, watch, ...rest }) => {
  const { name, service, cost } = shippingService;
  const watchSelected = watch('selected');
  useEffect(() => {
    toggleShippingService(watchSelected);
  }, [watchSelected]);

  return (
    <SlzPaper borderRadius={1.5} {...rest}>
      <HStack alignItems="center" h="full" ml={5}>
        <Controller
          control={control}
          name="selected"
          render={({ field }) => {
            const { value } = field;
            return <Checkbox isChecked={value} {...field} />;
          }}
        />
        <Text fontSize={'lg'} pt={1}>
          {`${name}, ${service}, £${cost}`}
        </Text>
      </HStack>
    </SlzPaper>
  );
};

export default B2BCourierResults;
