import { Icon } from '@chakra-ui/react';
import React from 'react';

const TrendIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 8"
      fill="currentColor"
      strokeWidth="0"
      {...props}>
      <path
        id="Polygon_101"
        data-name="Polygon 101"
        d="M6,0l6,6H0Z"
        transform="translate(1 1)"
        fill="#579bfc"
      />
    </Icon>
  );
};

export default TrendIcon;
