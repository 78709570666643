import { Box, HStack } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const SingleProductConsignmentDetails = () => (
  <>
    <ShimmerSkeleton.Block mb="2.75" h="3rem" w="100%" />
    <HStack mb="2.75" spacing="0.875rem">
      <ShimmerSkeleton.Card flex="1" contentStyle={{ h: '9.188rem' }} />
      <ShimmerSkeleton.Card flex="1" contentStyle={{ h: '9.188rem' }} />
    </HStack>
    <HStack spacing="0.875rem">
      <ShimmerSkeleton.Card flex="1" />
      <ShimmerSkeleton.Card flex="1" />
    </HStack>
  </>
);

const MultiProductConsignmentDetails = () => (
  <>
    <ShimmerSkeleton.Card mb="4" contentStyle={{ h: '5.75rem' }} />
    <ShimmerSkeleton.Card contentStyle={{ h: '16.688rem' }} />
  </>
);

const MultiProductConfirm = () => (
  <>
    <ShimmerSkeleton.Card mb="2.75" />
    <HStack spacing="0.5rem">
      <Box flex="1">
        <ShimmerSkeleton.Card contentStyle={{ h: '5.75rem' }} />
        <ShimmerSkeleton.Card contentStyle={{ h: '5.75rem' }} />
      </Box>
      <ShimmerSkeleton.Card flex="1" contentStyle={{ h: '14.75rem' }} />
    </HStack>
  </>
);

const SingleProductConfirm = () => (
  <>
    <HStack spacing="0.5rem" mb="2.75">
      <ShimmerSkeleton.Card flex="1" />
      <ShimmerSkeleton.Card flex="1" />
    </HStack>
    <HStack spacing="0.5rem">
      <ShimmerSkeleton.Card flex="1" contentStyle={{ h: '9.375rem' }} />
      <ShimmerSkeleton.Card flex="1" contentStyle={{ h: '9.375rem' }} />
    </HStack>
  </>
);

const CONSIGNMENT_DETAILS = {
  1: <SingleProductConsignmentDetails />,
  2: <MultiProductConsignmentDetails />
};

const CONFIRM_CONTENT = {
  2: <MultiProductConfirm />,
  1: <SingleProductConfirm />
};

const Overview = () => (
  <ShimmerSkeleton mb={10}>
    <ShimmerSkeleton.Block mb="2.5" h="6.801rem" w="100%" />
    <ShimmerSkeleton.Card mb="2,75" />
    <ShimmerSkeleton.Card contentStyle={{ h: '17.25rem' }} />
  </ShimmerSkeleton>
);

const MultipleProduct = ({ subCard }) => (
  <ShimmerSkeleton mb="10">
    {subCard && <ShimmerSkeleton.Card mb="4" contentStyle={{ h: '5.75rem' }} />}
    <ShimmerSkeleton.Card contentStyle={{ h: '16.688rem' }} />
  </ShimmerSkeleton>
);

const ConsignmentDetails = ({ type, ...rest }) => (
  <ShimmerSkeleton mb="10" {...rest}>
    {CONSIGNMENT_DETAILS[type]}
  </ShimmerSkeleton>
);

const SendInventory = (props) => (
  <ShimmerSkeleton mb="9" {...props}>
    <ShimmerSkeleton.Card w="100%" mb="2.75" contentStyle={{ h: '6rem' }} />
    <ShimmerSkeleton.Card w="100%" contentStyle={{ h: '16rem' }} />
  </ShimmerSkeleton>
);

const Confirm = ({ type }) => <ShimmerSkeleton mb="2.5">{CONFIRM_CONTENT[type]}</ShimmerSkeleton>;

const INVENTORY_TEMPLATES = {
  'inventory.1': Overview,
  'inventory.2': MultipleProduct,
  'inventory.3': ConsignmentDetails,
  'inventory.4': SendInventory,
  'inventory.5': Confirm
};

const InventorySkeleton = (props) => {
  return <BaseSkeleton baseTemplates={INVENTORY_TEMPLATES} {...props} />;
};

export default InventorySkeleton;
