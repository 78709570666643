import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Secure = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="0.9rem"
            h="1.125rem"
            viewBox="0 0 14.396 18"
            fill="gray"
            {...props}>
            <g id="Group_34547" data-name="Group 34547" clip-path="url(#clip-path)">
                <path id="Union_316" data-name="Union 316" d="M6364.7,20989a2.7,2.7,0,0,1-2.7-2.7v-5.4a2.7,2.7,0,0,1,2.7-2.7v-2.7a4.5,4.5,0,0,1,9,0v2.7a2.7,2.7,0,0,1,2.7,2.7v5.4a2.7,2.7,0,0,1-2.7,2.7Zm-.9-8.1v5.4a.9.9,0,0,0,.9.9h9a.9.9,0,0,0,.9-.9v-5.4a.9.9,0,0,0-.9-.9h-9A.9.9,0,0,0,6363.8,20980.9Zm8.1-2.7v-2.7a2.7,2.7,0,0,0-2.7-2.7,2.7,2.7,0,0,0-2.7,2.7v2.7Zm-4.5,5.4a1.8,1.8,0,1,1,1.8,1.795A1.8,1.8,0,0,1,6367.4,20983.6Z" transform="translate(-6362.001 -20970.998)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Secure;