import React from 'react';
import SlzTable from 'components/common/SlzTable/Table';
import { DETAILED_REMITTANCE_COLS } from '../table/billing.columns';
import map from 'lodash/map';
import { dummyDetailedRemittanceData } from '../../dummyData';

const detailedTableStyle = {
  th: { textAlign: 'center', h: '60px', bg: 'white' },
  'tr:hover, td:hover': { bg: 'none', cursor: 'unset' },
  tr: { borderBottomColor: 'inherit' },
  td: { textAlign: 'start', h: '60px', bg: 'white', borderBottomWidth: 'medium' },
  'td p': { marginBottom: '0' }
};

export default function DetailedRemittanceTable() {
  return (
    <>
      <SlzTable
        variant="simple"
        sizes={['sm', 'md', 'lg']}
        showHeader={true}
        showToolbar={false}
        showBoxShadow={false}
        columns={DETAILED_REMITTANCE_COLS}
        data={dummyDetailedRemittanceData}
        displayFields={map(DETAILED_REMITTANCE_COLS, 'field')}
        sx={detailedTableStyle}
      />
    </>
  );
}
