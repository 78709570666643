import React, { Fragment } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import PaymentCard from './PaymentCard';

export default function DirectDebitCard({ size, info, onSetup, onRemove }) {
  const { type, status, customerName, paymentTerms } = info || {};
  const colorScheme = type;

  return (
    <PaymentCard size={size} colorScheme={colorScheme}>
      <PaymentCard.Header>
        {!info ? (
          <PaymentCard.Setup status={status} onSetup={onSetup} />
        ) : (
          <Fragment>
            <PaymentCard.Status status={status} />
            <PaymentCard.Edit onRemove={onRemove} />
          </Fragment>
        )}
      </PaymentCard.Header>

      <PaymentCard.Body>
        <HStack w="full" h="full" alignItems="flex-start" pt="3em">
          <Box flex={1}>
            {info && (
              <PaymentCard.Information customerName={customerName}>
                <Text fontSize="xs" mt={1.5}>
                  Bank Account Type: {type}
                </Text>
                <Text fontSize="xs" mt={1.5}>
                  Last Status Check: {status}
                </Text>
                <Text fontSize="xs" mt={1.5}>
                  Payment Term: {paymentTerms}
                </Text>
              </PaymentCard.Information>
            )}
          </Box>

          <PaymentCard.Logo
            type={type || 'BACS'}
            sx={{
              '& > svg': {
                height: '1.25em',
                width: 'auto'
              }
            }}
          />
        </HStack>
      </PaymentCard.Body>
    </PaymentCard>
  );
}
