import { Flex } from '@chakra-ui/react';
import ControlledField from 'components/form/ControlledField';
import { SlzTextInput } from 'components/common/SlzInput';

const ContactDetails = () => {
  return (
    <>
      <Flex gap={3}>
        <ControlledField
          label="Name*"
          name="name"
          placeholder="Name"
          component={SlzTextInput}
          isInlineError
        />

        <ControlledField
          label="Company name*"
          name="companyName"
          placeholder="Company name"
          component={SlzTextInput}
          isInlineError
        />
      </Flex>

      <Flex gap={3} mt="1.125rem">
        <ControlledField
          label="Email*"
          name="email"
          placeholder="Email"
          component={SlzTextInput}
          isInlineError
        />

        <ControlledField
          label="Phone*"
          name="phone"
          placeholder="Phone"
          component={SlzTextInput}
          isInlineError
        />
      </Flex>
    </>
  );
};

export default ContactDetails;
