import { createIcon } from '@chakra-ui/react';

const UpDownIcon = createIcon({
  displayName: 'UpDownIcon',
  viewBox: '0 0 1296 48',
  path: (
    <rect id="Rectangle_2444" data-name="Rectangle 2444" width="1296" height="48" fill="#f2f2f2" />
  )
});

export default UpDownIcon;
