import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';

import { GeneralTrackingField } from 'modules/b2b-orders/components/form';

const GeneralTrackingFields = ({ control, register, disabled }) => {
  const { fields, append } = useFieldArray({
    control,
    name: 'generalTracking'
  });

  return (
    <VStack
      alignItems={'unset'}
      spacing={5}
      sx={{
        maxH: '200px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
      {fields.map((item, index) => (
        <GeneralTrackingField
          key={item?.id}
          index={index}
          register={register}
          append={append}
          disabled={disabled}
          length={fields.length}
        />
      ))}
    </VStack>
  );
};

export default GeneralTrackingFields;
