// import { Route, Switch } from 'react-router';

// import NotFound from 'components/common/NotFound';
// import { AdminDashboard } from 'components/selazar/AdminDashboard';
// import ResolveSelazarIssue from 'components/selazar/ResolveSelazarIssue';
// import SelazarIssues from 'components/selazar/SelazarIssues';
// import SelazarIssuesList from 'components/selazar/SelazarIssuesList';
// import { SelazarLayout as SlzLayout } from 'components/selazar/SelazarLayout';
// import ProtectedRoute from './ProtectedRoute';

/**
 * Need convert all components in this route to functional components
 * **/
const SelazarRoute = ({ onLogout }) => {
  return (
    <></>
    // <SlzLayout onLogout={onLogout}>
    //   <Switch>
    //     <Route exact path="/" render={() => <AdminDashboard />} />
    //     <ProtectedRoute feature="ViewIssue" path="/issues" component={SelazarIssues} exact />
    //     <ProtectedRoute
    //       feature="ViewIssue"
    //       path="/issues/raisedBy"
    //       component={SelazarIssuesList}
    //       exact
    //     />
    //     <ProtectedRoute
    //       feature="ActionIssue"
    //       path="/issues/resolve"
    //       component={ResolveSelazarIssue}
    //       exact
    //     />
    //     <Route component={NotFound} />
    //   </Switch>
    // </SlzLayout>
  );
};

export default SelazarRoute;
