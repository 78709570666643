import {
  Button,
  ButtonGroup,
  Card,
  FormControl,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useStyleConfig
} from '@chakra-ui/react';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { EAN_LIMIT_QUANTITY, SUID_LIMIT_QUANTITY } from 'modules/consignments/constants';
import Tooltip from 'modules/integrations/components/form/Tooltip';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ConsignmentDetailsIcon } from '../assets';

const ConsignmentDetails = () => {
  const {
    formState: { errors },
    watch,
    control,
    register,
    setValue,
    getValues,
    clearErrors
  } = useFormContext();

  const [hasConsignmentDetailsError, setHasConsignmentDetailsError] = useState(false);

  useEffect(() => {
    setHasConsignmentDetailsError(false);
    const selectedProduct = getValues('product');
    const limitQuantity = selectedProduct?.ean ? EAN_LIMIT_QUANTITY : SUID_LIMIT_QUANTITY;
    const stockboxQuantity = parseInt(watch('stockBoxQuantity')),
      itemsPerStockbox = parseInt(watch('itemsPerStockbox'));

    if (!isNaN(stockboxQuantity * itemsPerStockbox)) {
      setValue(
        'totalQuantityItems',
        stockboxQuantity * itemsPerStockbox > limitQuantity
          ? 'N/A'
          : (stockboxQuantity * itemsPerStockbox).toLocaleString()
      );
      clearErrors('totalQuantityItems');
    }
  }, [watch('stockBoxQuantity'), watch('itemsPerStockbox')]);

  useEffect(() => {
    if (errors?.totalQuantityItems?.type === 'required') {
      setValue('totalQuantityItems', errors.totalQuantityItems.message);
    }

    if (errors?.itemsPerStockbox) setValue('itemsPerStockbox', errors.itemsPerStockbox.message);
    if (errors?.stockBoxQuantity) setValue('stockBoxQuantity', errors.stockBoxQuantity.message);
  }, [errors]);

  const limitQuantity = getValues('product')?.ean
    ? EAN_LIMIT_QUANTITY.toLocaleString()
    : SUID_LIMIT_QUANTITY.toLocaleString();

  const handlePopOverClose = () => {
    setHasConsignmentDetailsError(true);
    clearErrors('totalQuantityItems');
  };

  const handleOnFocus = (field) => clearErrors(field);

  const handleCustomInputChange = ({ target: { name, value } }) => {
    const sanitizedValue = value.replace(/[^0-9]/g, '');
    setValue(name, sanitizedValue);
  }

  return (
    <>
      <VStack w="50%">
        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <ConsignmentDetailsIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Text
              sx={{
                textAlign: 'left',
                lineHeight: '20px',
                letterSpacing: '-0.14px',
                fontSize: '16px',
                color: 'table.mainText'
              }}>
              <b>Consignment details</b>
            </Text>
          </HStack>
        </Card>
        <Card
          bg="light.500"
          border="1px solid"
          borderColor="light.700"
          mt="2rem"
          w="100%"
          h="9.188rem"
          px="1.5rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <VStack>
            <HStack w="full">
              <FormControl isInvalid={false}>
                <HStack spacing={1} alignItems="center">
                  <SlzFormLabel mr={0} display="flex" color="dark.700">
                    Stock box quantity
                  </SlzFormLabel>
                  <Tooltip content={'abc'} />
                </HStack>
                <HStack>
                  <Controller
                    name='stockBoxQuantity'
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SlzInput
                        sx={{
                          '::placeholder': {
                            fontStyle: 'italic',
                            fontSize: 'sm'
                          },
                          width: '100%',
                        }}
                        value={field.value}
                        fontSize={errors?.stockBoxQuantity ? 'xs' : 'sm'}
                        placeholder="Between 1 - 1000 boxes"
                        type='text'
                        size="sm"
                        min="0"
                        step="1"
                        textAlign={errors?.stockBoxQuantity ? 'left' : 'right'}
                        bgColor="light.300"
                        status={(hasConsignmentDetailsError || errors?.stockBoxQuantity) && 'error'}
                        {...field}
                        onFocus={() => handleOnFocus('stockBoxQuantity')}
                        onChange={handleCustomInputChange}
                      />
                    )}
                  />
                </HStack>
              </FormControl>

              <FormControl isInvalid={false}>
                <HStack spacing={1} alignItems="center">
                  <SlzFormLabel mr={0} display="flex" color="dark.700">
                    Items per stock box
                  </SlzFormLabel>
                  <Tooltip content={'abc'} />
                </HStack>

                <HStack>
                  <Controller
                    name='itemsPerStockbox'
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <SlzInput
                        sx={{
                          '::placeholder': {
                            fontStyle: 'italic',
                            fontSize: 'sm'
                          },
                          width: '100%'
                        }}
                        value={field.value}
                        fontSize={errors?.itemsPerStockbox ? 'xs' : 'sm'}
                        placeholder="Between 1 - 1000 items"
                        type='text'
                        size="sm"
                        min="0"
                        textAlign={errors?.itemsPerStockbox ? 'left' : 'right'}
                        bgColor="light.300"
                        status={(hasConsignmentDetailsError || errors?.itemsPerStockbox) && 'error'}
                        {...field}
                        onFocus={() => handleOnFocus('itemsPerStockbox')}
                        onChange={handleCustomInputChange}
                      />
                    )}
                  />
                </HStack>
              </FormControl>
            </HStack>
            <HStack w="full">
              <FormControl w="49%" marginLeft="auto !important">
                <SlzFormLabel display="flex" color="dark.700">
                  Total quantity items
                </SlzFormLabel>

                <TotalStockQuantity
                  errors={errors}
                  register={register}
                  limitQuantity={limitQuantity}
                  isOpen={errors?.totalQuantityItems?.message === 'N/A'}
                  handlePopOverClose={handlePopOverClose}
                />
              </FormControl>
            </HStack>
          </VStack>
        </Card>
      </VStack>
    </>
  );
};

const TotalStockQuantity = ({ errors, register, limitQuantity, isOpen, handlePopOverClose }) => {
  const styles = useStyleConfig('SlzToolTip');

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={handlePopOverClose}
        placement="bottom"
        direction="rtl"
        arrowSize={40}
        closeOnBlur={false}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: ({ placement, reference }) => {
                if (placement.includes('bottom') || placement.includes('top')) {
                  return [0, -(reference.height / 2 - 44)];
                } else return [0, 40];
              }
            }
          }
        ]}>
        <PopoverTrigger>
          <SlzInput
            sx={{
              '::placeholder': {
                fontStyle: 'italic',
                fontSize: 'sm',
                textAlign: 'left'
              },
              fontSize: errors?.totalQuantityItems?.type === 'required' ? 'xs' : 'sm',
              width: '100%',
              color: 'dark.700 !important'
            }}
            placeholder="Your total appears here"
            type="text"
            size="sm"
            min="0"
            step="0.01"
            textAlign={errors?.totalQuantityItems?.type === 'required' ? 'left' : 'right'}
            bgColor="main.300 !important"
            status={errors?.totalQuantityItems?.type === 'required' && 'error'}
            {...register('totalQuantityItems')}
            isDisabled={true}
          />
        </PopoverTrigger>
        <PopoverContent
          sx={{
            ...styles,
            width: 'fit-content',
            pt: '20px',
            pr: '26px',
            pb: '14px',
            p: '26px',
            height: '116px',
            textAlign: 'left',
            align: 'center',
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
          <PopoverHeader fontWeight="semibold" fontSize="sm" borderBottom={0} pt={0} pb={0}>
            Total stock quantity
          </PopoverHeader>
          <PopoverArrow sx={{ transform: 'scaleX(.5) rotate(45deg) !important' }} />
          <PopoverCloseButton />
          <PopoverBody fontSize="xs" pt={0} pb={0} whiteSpace="nowrap">
            The total stock quantity must be between 1 and {limitQuantity}
          </PopoverBody>
          <PopoverFooter borderTop={0} display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button onClick={handlePopOverClose} bgColor="negative.500">
                Ok
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ConsignmentDetails;
