import { TABLES } from './Constants';
import { getGroupFields } from './utils';
import { first } from 'lodash';

export const rootCellHighlight = {
  bg: 'white',
  p: '0 0.188rem'
};

export const getHeightOfCell = (type) => ({
  h: `${TABLES.type.normal.includes(type) ? 12 : 15}`,
  maxH: `${TABLES.type.normal.includes(type) ? 12 : 15}`,
  py: `${TABLES.type.normal.includes(type) ? 3.5 : 4}`
});

export const getHeightOfAccordionCell = (type) => ({
  h: `${TABLES.type.normal.includes(type) ? 12 : 12}`,
  maxH: `${TABLES.type.normal.includes(type) ? 12 : 12}`,
  py: `${TABLES.type.normal.includes(type) ? (type == 'order' || type == 'normal' ? 0 : 3.5) : 4}`
});

export const getFontsCell = (type) => ({
  fontSize: `${TABLES.type.normal.includes(type) ? '1rem' : ''}`,
  lineHeight: `${TABLES.type.normal.includes(type) ? '1.313rem' : ''}`
});

export const getMarkedCellStyle = (color) => ({
  content: '""',
  w: 1.5,
  h: '100%',
  pos: 'absolute',
  top: 0,
  left: 0,
  bg: color
});

export const getFlagStyle = ({ defaultColor, row, cols, targetField, type, markedWidth }) => {
  const color =
    defaultColor || (!targetField ? row[first(getGroupFields(cols))] : row?.targetField)?.color;
  return {
    ...getHeightOfCell(type),
    w: `${markedWidth}px`,
    div: {
      ...dFlex,
      w: `${markedWidth}px`,
      minW: `${markedWidth}px`,
      h: 'full',
      bg: color
    },
    p: 0
  };
};

export const getWarningColor = (num) => {
  if (num <= 10) {
    return {
      primary: '#AD3343',
      secondary: '#F5CCD3'
    };
  }

  if (num <= 100) {
    return {
      primary: '#E0BA25',
      secondary: '#FFE16E'
    };
  }

  if (num <= 200) {
    return {
      primary: '#0760B9',
      secondary: '#CCE5FF'
    };
  }
};

const dFlex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const getCellStyles = ({ col, row, type, theme, isLoading }) => {
  if (!col) {
    return styles;
  }

  let styles = {
    ...getHeightOfCell(type),
    'div.slz-table-cell': {
      ...dFlex
    },
    whiteSpace: 'normal',
    px: 0,
    py: 0
  };

  let color = '';
  if (col?.isEmphasis) {
    const highlightObj = row[col?.field];
    color = highlightObj?.color || 'light';
    styles = {
      ...styles,
      ...rootCellHighlight,
      div: {
        ...dFlex,
        color: col?.type === 'number' ? 'dark.700' : 'light.500',
        w: 'full',
        h: 'full',
        bg: color,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        px: 6,
        ...getFontsCell(type)
      }
    };
  } else if (col?.type === 'number') {
    color = getWarningColor(row[col?.field] || 0);
    styles = {
      ...styles,
      div: {
        ...dFlex,
        w: 'full',
        h: 'full',
        bg: color?.secondary,
        _after: getMarkedCellStyle(color?.primary, theme)
      }
    };
  }

  if (isLoading) {
    styles.pr = 0.75;
    styles.bg = 'white';

    if (col?.isEmphasis) styles.pl = 0;
  }

  return styles;
};

export const getAccordionStyles = (active) => ({
  backgroundColor: !active ? '#C3C4CB' : '#0973EA',
  transform: !active ? '' : 'rotate(180deg)',
  svg: {
    color: !active ? '#303134' : 'white',
    w: '1.875rem',
    h: '1.875rem'
  }
});

export const getTransitionSubRow = (isShow) => ({
  transition: 'max-height .3s',
  maxHeight: isShow ? '25rem' : '0'
});

export const getFontSizeSubCell = (isSpecial) => ({
  '.header': { fontSize: isSpecial ? '0.875rem' : '0.625rem' },
  '.field-value': { fontSize: !isSpecial ? '0.875rem' : '0.625rem' }
});
