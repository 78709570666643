import { extendTheme } from '@chakra-ui/react';
import SlzAttentionCard from './components/slz-attention-card';
import SlzButton from './components/slz-button';
import SlzInput from './components/slz-input';
import { Table } from './components/table';
import Tabs from './components/tabs';
import SlzTag from './components/slz-tag';
import Cta from './components/cta';
import SlzChip from './components/chip';
import Alert from './components/slz-alert';
import SlzToast from './components/slz-toast';
import SlzNotification from './components/slz-notification';
import Pagination from './components/pagination';
import SlzModal from './components/slz-modal';
import SlzPaper from './components/slz-paper';
import SlzTimelineStepper from './components/slz-timeline-stepper';
import SlzUserAvatar from './components/slz-user-avatar';
import SlzLink from './components/slz-link';
import SlzLabel from './components/slz-label';
import SlzFilter from './components/slz-filter';
import SlzCustomTag from './components/slz-custom-tag';
import { foundations } from './foundations';
import Switch from './components/slz-toggle';
import Checkbox from './components/slz-checkbox';
import SlzRadio from './components/slz-radio';
import styles from './styles';
import SlzDefinedRange from './components/slz-defined-range';
import SlzDateRangeInput from './components/slz-date-range-input';
import SlzDatePickerUI from './components/slz-date-picker-ui';
import SlzDateSingleCalendar from './components/slz-date-single-calendar';
import SlzCurrencyIcon from './components/slz-currency-icon';
import SlzPaymentCard from './components/slz-payment-card';
import SlzSplitButton from './components/slz-split-button';
import SlzToolTip from './components/slz-tooltip';
import SlzNotificationWindows from './components/slz-notification-window';
import SlzFormLabelProduct from './components/slz-form-label-product';
import SlzSearchInput from './components/slz-search-input';
import SlzNavbar from './components/slz-navbar';
import SlzBreadcrumb from './components/slz-breadcrumb';
import SlzStatusCard from './components/slz-status-card';
import SlzPopoverConfirm from './components/slz-popover-confirm';
import SlzRadioToggle from './components/slz-radio-toggle';
import SlzCustomBox from './components/slz-custom-box';

const theme = extendTheme({
  styles,
  ...foundations,
  components: {
    Alert,
    Button: SlzButton,
    Input: SlzInput,
    Tabs,
    SlzAttentionCard,
    Table,
    Tag: SlzTag,
    SlzChip,
    Cta,
    SlzModal,
    SlzDefinedRange,
    SlzDateRangeInput,
    SlzDatePickerUI,
    SlzDateSingleCalendar,
    SlzToast,
    SlzNotification,
    Pagination,
    SlzCurrencyIcon,
    SlzPaper,
    SlzTimelineStepper,
    SlzUserAvatar,
    SlzLabel,
    SlzLink,
    Checkbox,
    SlzRadio,
    Switch,
    SlzPaymentCard,
    SlzFilter,
    SlzCustomTag,
    SlzSplitButton,
    SlzToolTip,
    SlzNotificationWindows,
    SlzFormLabelProduct,
    SlzSearchInput,
    SlzNavbar,
    SlzBreadcrumb,
    SlzStatusCard,
    SlzPopoverConfirm,
    SlzRadioToggle,
    SlzCustomBox
  },
  config: {
    cssVarPrefix: 'sel'
  }
});

export default theme;
