import { Fragment } from 'react';
import { Box, HStack, Heading, Icon } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';

const B2BOrderModalHeader = ({ onBack }) => {
  return (
    <Fragment>
      <HStack alignItems="flex-start">
        <Icon
          as={FaChevronLeft}
          fontSize={28}
          color="dark.500"
          mt="0.5"
          cursor="pointer"
          onClick={onBack}
        />
        <Box>
          <Heading as="h3" fontSize="2rem" lineHeight={'none'} mb="0">
            Create B2B order
          </Heading>
        </Box>
      </HStack>
    </Fragment>
  );
};
export default B2BOrderModalHeader;
