import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from 'config/axios';
import { directDebitPayloadMapper } from '../mappers/payment-mapper';

export async function addCreditCard(payload) {
  const url = CommonEndpoints.FINANCE.CARD.POST.NEW;
  const res = await axiosClient.post(url, payload);
  return res.data?.data;
}

export async function replaceCreditCard(payload) {
  const url = CommonEndpoints.FINANCE.CARD.PUT.REPLACE;
  const res = await axiosClient.put(url, payload);
  return res.data?.data;
}

export async function getCreditCardInfo() {
  const url = CommonEndpoints.FINANCE.CARD.GET.LATEST;
  return axiosClient.get(url);
}

export async function removeCreditCard() {
  const url = CommonEndpoints.FINANCE.CARD.DELETE.REMOVE;
  return axiosClient.remove(url);
}

export async function checkCreditCard() {
  const url = CommonEndpoints.FINANCE.CARD.GET.HAS_VALID_DETAILS;
  return axiosClient.get(url);
}

export async function addDirectDebit(payload) {
  const url = CommonEndpoints.FINANCE.DIRECT_DEBIT.CREATE;
  const res = await axiosClient.post(url, directDebitPayloadMapper(payload));
  return res.data?.data;
}

export async function getDirectDebitInfo() {
  return Promise.all([
    axiosClient.get(CommonEndpoints.FINANCE.DIRECT_DEBIT.CURRENT),
    axiosClient.get(CommonEndpoints.FINANCE.DIRECT_DEBIT.GET_FOR_COMPANY)
  ]);
}

export async function removeDirectDebit() {
  const url = CommonEndpoints.FINANCE.DIRECT_DEBIT.REMOVE_CURRENT;
  return axiosClient.put(url);
}

export async function disableDirectDebit() {
  const url = CommonEndpoints.FINANCE.DIRECT_DEBIT.DISABLE_CURRENT;
  return axiosClient.put(url);
}

export async function hasDirectDebit() {
  const url = CommonEndpoints.FINANCE.DIRECT_DEBIT.ENABLED;
  return axiosClient.get(url);
}
