import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import alertTheme from './slz-alert';

const parts = ['container', 'title', 'description', 'icon', 'button'];

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

const baseStyle = definePartsStyle({
  container: {
    ...alertTheme.baseStyle.container,
    display: 'flex',
    borderRadius: 1.5,
    paddingInlineEnd: 1.5,
    paddingInlineStart: 6
  },
  title: {
    ...alertTheme.baseStyle.title,
    paddingInlineStart: 6
  },
  description: {
    paddingInlineStart: 6
  },
  icon: {
    color: $fg.reference,
    flexShrink: 0,
    marginEnd: 0,
    w: '6',
    h: '6'
  },
  button: {
    marginStart: 6,
    w: '6',
    h: '6',
    borderRadius: 0.75,
    _focus: {
      outline: 'none'
    }
  }
});
const sizes = {
  md: definePartsStyle({
    container: {
      h: 'unset',
      minH: 12,
      minW: '28.313rem',
      fontSize: '1rem',
      pt: 2.5,
      pb: 2.5
    }
  }),
  lg: definePartsStyle({
    container: {
      h: 'unset',
      minH: 24,
      minW: '28.313rem',
      fontSize: '1rem',
      pt: 6,
      pb: 6
    }
  })
};

const variantSubtle = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  const color = c === 'dark' ? `${c}.700` : `${c}.500`;
  return {
    container: {
      bg: 'light.300',
      border: '0.063rem solid',
      color: color,
      borderColor: 'currentColor'
    },
    button: {
      _hover: {
        bg: `${c}.300`
      }
    },
    icon: {
      color: color
    }
  };
});

const variantSolid = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  let bg = mode(`${c}.500`, `${c}.200`)(props);
  let fg = mode(`white`, `gray.900`)(props);
  const container = {
    [$bg.variable]: `colors.${bg}`,
    [$fg.variable]: `colors.${fg}`,
    color: 'light.500'
  };
  if (c !== 'dark') {
    return {
      container,
      button: {
        _hover: {
          bg: `${c}.700`
        }
      }
    };
  }
  bg = `${c}.700`;
  return {
    container: {
      ...container,
      [$bg.variable]: `colors.${bg}`
    },
    button: {
      color: 'light.500',
      _hover: {
        bg: 'light.700',
        color: $bg.reference
      }
    }
  };
});

const variants = {
  subtle: variantSubtle,
  solid: variantSolid
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'subtle',
    colorScheme: 'main',
    size: 'lg'
  }
});
