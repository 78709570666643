import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import SlzLabel from 'components/common/SlzLabel/SlzLabel';

import Source from './Source';
import { OrderHeader } from '.';

const OrderInfoDetails = (props) => {
  const { orderDetail, isLoading, isShowCourier = false, isShowTracking = false } = props;
  const shippingBreakdown = orderDetail?.orderBreakdown ? orderDetail?.orderBreakdown[0] : null;

  return (
    <SlzPaper w={props.w} h={props.h} bg="light.500">
      <Flex
        h={props.h}
        justifyContent="space-between"
        alignItems="center"
        borderLeftWidth="0.75rem"
        borderRadius="0.375rem"
        borderLeftColor={orderDetail?.status?.color}>
        {isLoading ? (
          <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
        ) : (
          <>
            <HStack flex="1" justifyContent={'space-between'}>
              <Box mx={5} justifyContent="space-between" width="12rem">
                <Text fontSize="1rem" fontWeight="bold">
                  Order ID {orderDetail?.customer?.reference}
                </Text>
                <Text fontSize="0.875rem">{orderDetail?.createdDate}</Text>
              </Box>
              <OrderHeader text="Source" mt="-1.5rem !important">
                <Text display="flex">
                  <Source
                    styles={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      textColor: 'light.300',
                      h: '1.875rem',
                      w: 'auto !important',
                      px: 3,
                      fontSize: '1rem',
                      borderRadius: 1.5
                    }}
                    type={orderDetail?.source?.type}
                    storeName={orderDetail?.source?.name}
                  />
                </Text>
              </OrderHeader>
              <OrderHeader isShow={isShowCourier} text="Courier" mt="-1.5rem !important">
                {shippingBreakdown?.courier?.name && (
                  <SlzLabel
                    variant="courier"
                    h="1.875rem"
                    bg={shippingBreakdown?.courier?.bg}
                    color={shippingBreakdown?.courier?.color}>
                    {shippingBreakdown?.courier?.name}
                  </SlzLabel>
                )}
              </OrderHeader>
              <OrderHeader isShow={isShowTracking} text="Tracking" mt="-1.5rem !important">
                {shippingBreakdown?.tracking && (
                  <SlzLabel
                    variant="courier"
                    bg="main.500"
                    color="light.300"
                    h="1.875rem"
                    fontSize="1rem"
                    fontWeight="bold">
                    {shippingBreakdown?.tracking}
                  </SlzLabel>
                )}
              </OrderHeader>
            </HStack>
            <Flex ml="1.875rem" width="25rem" justifyContent="space-between" mr="2rem">
              <Flex
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                px="0.938rem"
                fontSize="1rem"
                borderRadius="0.375rem"
                bg="light.700"
                color="black">
                Order value £ {orderDetail?.totalCost}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </SlzPaper>
  );
};

export default OrderInfoDetails;
