import React, { useEffect } from 'react';

import { Box, HStack, useBoolean, useDisclosure } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import { SlzToggle } from 'components/common/SlzToggle';
import { ControlledField, Field } from 'components/form';
import { SlzSplitButton } from 'components/common/SlzButton';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import {
  IOSS_ACTIONS,
  IOSS_EDIT_ACTIONS,
  MESSAGES,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { HeaderTooltip, PreferenceHeader } from 'modules/preferences/components/common';
import { useIsMount } from 'hooks/useIsMount';

function IOSSNumberActions({ actions, label, ...rest }) {
  return (
    <SlzSplitButton
      variant="outline"
      size="md"
      rounded="md"
      sx={{ backgroundColor: 'white' }}
      onlyShowPlaceholder
      placeholder={label}
      items={actions}
      {...rest}
    />
  );
}

export default function IOSSNumber({ defaultValues, isFetching, onSave, onRemove, isSubmitting }) {
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.iossNumber]: form } = forms;
  const methods = form?.form;
  const isMount = useIsMount();

  const popoverConfirmValidation = useDisclosure();
  const popoverConfirmRemoving = useDisclosure();
  const [isEditMode, setIsEditMode] = useBoolean(!defaultValues.euioss);
  const isActiveIOSS = methods.watch('isActiveIOSS');
  const isEditableIOSS = isActiveIOSS && isEditMode;
  const isInvalidIOSS = !!methods.formState.errors?.euioss?.message;

  const resetError = () => {
    methods.reset(methods.getValues());
  };

  const closeAlert = () => {
    resetError();
    popoverConfirmValidation.onClose();
  };

  const resetToDefaultValues = () => {
    methods.reset(defaultValues);
  };

  const cancelRemoving = () => {
    setIsEditMode.off();
    resetToDefaultValues();
    popoverConfirmRemoving.onClose();
  };

  const cancelEditing = () => {
    methods.resetField('euioss', {
      defaultValue: defaultValues?.euioss
    });
    defaultValues?.euioss && setIsEditMode.off();
  };

  const handleSelectedAction = ({ value: action }) => {
    switch (action) {
      case 'edit':
        setIsEditMode.on();
        return;
      case 'remove':
        popoverConfirmRemoving.onOpen();
        return;
      case 'cancel':
        cancelEditing();
        return;
    }
  };

  const updateIOSSValues = (values) => {
    methods.reset(values);
    const isTheEditMode = !values.euioss;
    isTheEditMode ? setIsEditMode.on() : setIsEditMode.off();
  };

  useEffect(() => {
    if (isInvalidIOSS) {
      popoverConfirmValidation.onOpen();
    }
  }, [isInvalidIOSS]);

  useEffect(() => {
    if (!isActiveIOSS && defaultValues.isActiveIOSS && !isMount) {
      popoverConfirmRemoving.onOpen();
    }
  }, [isActiveIOSS]);

  useEffect(() => {
    if (defaultValues) {
      updateIOSSValues(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!isFetching) {
      methods?.reset(defaultValues);
      !defaultValues.euioss ? setIsEditMode.on() : setIsEditMode.off();
    }
  }, [isFetching]);

  return (
    <Skeleton w="full" isLoaded={!isFetching} borderRadius={3}>
      <PreferenceHeader
        header={'IOSS Number'}
        tooltip={
          <HeaderTooltip
            tooltip={{
              content:
                'The IOSS (Import One Stop Shop) number is used in order to sell goods to buyers in the EU under the IOSS scheme. The IOSS VAT identification number consists of 12 alphanumeric characters.'
            }}
          />
        }
      />
      <Box rounded="xl" shadow="slz-md" border="1px" borderColor="dark.300" w="full" p={6}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSave)}>
            <HStack spacing={4} alignItems="flex-start">
              <ControlledField name="isActiveIOSS" component={SlzToggle} mb={0} fontSize="md">
                Use my IOSS Number
              </ControlledField>

              <Box w="full" pos="relative">
                <SlzPopoverConfirm
                  variant="outline"
                  isOpen={popoverConfirmValidation.isOpen}
                  onClose={popoverConfirmValidation.onClose}
                  onCancel={closeAlert}
                  onOk={resetError}
                  title={MESSAGES.INVALID_IOSS_TITLE}
                  description={MESSAGES.INVALID_IOSS_DESCRIPTION}
                  placement="top"
                  cancelText={null}>
                  <Box pos="absolute" w="full" h="full" zIndex="hide"></Box>
                </SlzPopoverConfirm>

                <SlzPopoverConfirm
                  isOpen={popoverConfirmRemoving.isOpen}
                  onClose={popoverConfirmRemoving.onClose}
                  onCancel={cancelRemoving}
                  onOk={onRemove}
                  title={MESSAGES.REMOVING_IOSS_TITLE}
                  description={MESSAGES.REMOVING_IOSS_DESCRIPTION}
                  placement="top"
                  okText="Remove">
                  <Box pos="absolute" w="full" h="full" zIndex="hide"></Box>
                </SlzPopoverConfirm>

                <Field
                  size="sm"
                  name="euioss"
                  rounded="md"
                  minW="194px"
                  placeholder="IM1234567890"
                  showIcon={false}
                  isDisabled={!isEditableIOSS}
                  sx={{
                    _disabled: {
                      border: '1px solid',
                      borderColor: 'dark.300!important',
                      bgColor: 'light.500'
                    }
                  }}
                />
              </Box>

              <Box w="28" flexShrink={0}>
                {isEditMode && (
                  <IOSSNumberActions
                    type="submit"
                    label="Save"
                    actions={IOSS_EDIT_ACTIONS}
                    isDisabled={!isActiveIOSS}
                    onSelected={handleSelectedAction}
                  />
                )}
                {!isEditMode && (
                  <IOSSNumberActions
                    type="button"
                    label="Edit"
                    actions={IOSS_ACTIONS}
                    isDisabled={!isActiveIOSS}
                    onSelected={handleSelectedAction}
                    onClick={setIsEditMode.on}
                  />
                )}
              </Box>
            </HStack>
          </form>
        </FormProvider>
      </Box>
    </Skeleton>
  );
}
