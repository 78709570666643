import React from 'react';
import Row from './Row';

const Rows = ({
  activeRowId = null,
  rows = [],
  isChild,
  showMarked = false,
  markedWidth,
  // showImage = false,
  setRows,
  onClick = () => {}
}) => {
  return rows.map((row, idx) => (
    <Row
      key={`slz-row-${row?.id}-${idx}`}
      row={row}
      rowIndex={idx}
      showMarked={showMarked}
      markedWidth={markedWidth}
      // showImage={showImage}
      isChild={isChild}
      activeRowId={activeRowId}
      onClick={onClick}
      setRows={setRows}
    />
  ));
};
export default Rows;
