import { createIcon, useToast, GridItem } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { SlzGridModal } from 'components/common/SlzModal';
import SlzToast from 'components/common/SlzToast/SlzToast';
import transformUploadedFile from 'utils/transformUploadFile';
import SetupRequirement from 'components/common/SetupRequirement';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';
import ProductSkeleton from 'components/common/Skeleton/templates/product';

import { productSchema } from 'modules/products/components/modal/form/validation/schema';
import Action from './Action';
import Header from './Header';
import { validation as addFormImagesValidateSchema } from 'modules/products/components/modal/form/validation/add/add-form-images';
import { PRODUCTS_ADD_TYPES, TAG_DEFAULT_NAME } from 'modules/products/constants';
import { ADD_PRODUCT_CONTENT } from './ModalContent';

const getValidationSchema = (type) => {
  if (type === PRODUCTS_ADD_TYPES.MANUAL) {
    return productSchema;
  }

  if (type === PRODUCTS_ADD_TYPES.UPLOAD) {
    return addFormImagesValidateSchema;
  }

  return {};
};

const ModalContent = ({ currentType = 0, ...rest }) => {
  const Content = ADD_PRODUCT_CONTENT[currentType];
  return <Content currentType={currentType} {...rest} />;
};

const Modal = (props) => {
  const { type, formValues, isOpen, onClose, onSave, isSubmitting = false, errorCSVFiles } = props;

  const requiredSettingProps = useCheckRequiredSettings();
  const [isUploaded, setIsUploaded] = useState(false);
  const [csvFiles, setCsvFiles] = useState([]);
  const [currentType, setCurrentType] = useState(0);
  const toast = useToast();
  const methods = useForm({
    defaultValues: formValues,
    resolver: yupResolver(getValidationSchema(currentType))
  });

  useEffect(() => {
    type && setCurrentType(type);
  }, [type]);

  useEffect(() => {
    formValues && methods.reset(formValues);
  }, [formValues]);

  useEffect(() => {
    if (csvFiles.length > 0) {
      const products = methods.getValues('products') || [];
      const newProducts = transformUploadedFile(csvFiles);

      methods.reset({
        products: newProducts.map((newProduct) => {
          const oldProduct = products.find((product) => product.skuCode === newProduct.skuCode);

          return oldProduct || newProduct;
        })
      });
    }
  }, [csvFiles]);

  useEffect(() => {
    setCsvFiles((currentCSVFiles) =>
      currentCSVFiles.filter((file) => errorCSVFiles.includes(file.file?.name))
    );
  }, [errorCSVFiles]);

  const onSubmit = async (data) => {
    if (!data.imageUrl && currentType === PRODUCTS_ADD_TYPES.MANUAL) {
      toast({
        isClosable: true,
        render: ({ onClose }) => (
          <SlzToast
            colorScheme="negative"
            title="The Image of Product Overview section is required"
            status="warning"
            size="md"
            variant="solid"
            w="10rem"
            onClose={onClose}
          />
        )
      });
    } else {
      const payload =
        currentType === PRODUCTS_ADD_TYPES.MANUAL
          ? transformData({ data, currentType })
          : assembleUploadData(data);

      const isSuccess = await onSave?.(payload, currentType);
      if (isSuccess) {
        onClose();
        resetAll();
      }
    }
  };

  const transformData = ({ data, currentType }) => {
    if (data.tag && (!data.tag?.text || data.tag?.text?.trim() === '')) {
      data.tag.text = TAG_DEFAULT_NAME;
    }
    switch (currentType) {
      case PRODUCTS_ADD_TYPES.MANUAL:
        return data;
      case PRODUCTS_ADD_TYPES.UPLOAD:
        return data;
      default:
        break;
    }
  };

  const assembleUploadData = ({ products }) => {
    return {
      csvFiles: csvFiles.map((item) => item.file),
      products
    };
  };

  const onClick = () => {
    if (currentType === PRODUCTS_ADD_TYPES.UPLOAD) {
      if (!csvFiles.length) {
        toast({
          isClosable: true,
          render: ({ onClose }) => (
            <SlzToast
              colorScheme="negative"
              title="Files are required"
              status="warning"
              size="md"
              variant="solid"
              w="10rem"
              onClose={onClose}
            />
          )
        });
        return;
      }

      setIsUploaded(!isUploaded);
    }
  };

  const resetAll = () => {
    setCurrentType(0);
    setIsUploaded(false);
    setCsvFiles([]);
    methods?.reset();
  };

  const onBack = useCallback(() => {
    if (isUploaded) {
      setIsUploaded(false);
    } else {
      resetAll();
    }
  }, [isUploaded]);

  const onModalClose = useCallback(() => {
    resetAll();
    onClose();
  }, []);

  return (
    <SlzGridModal
      id="product-add-slz-modal"
      variant={currentType === 2 && !isUploaded && 'addCSVUpload'}
      isOpen={isOpen}
      onClose={onModalClose}>
      <GridItem gridColumn={'2/12'}>
        <Header
          currentType={currentType}
          csvFiles={csvFiles}
          isUploaded={isUploaded}
          onBack={onBack}
        />
      </GridItem>

      <GridItem gridColumn={'2/12'} mb="10">
        <FormProvider {...methods}>
          <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ProductSkeleton
              isLoaded={!requiredSettingProps.isRequiredSettingsFetching}
              template={`addProduct.${currentType}`}>
              <SetupRequirement.RequirementValidation
                onClose={onClose}
                requiredSettingProps={requiredSettingProps}>
                {() => (
                  <ModalContent
                    currentType={currentType}
                    setCurrentType={setCurrentType}
                    csvFiles={csvFiles}
                    isUploaded={isUploaded}
                    setCsvFiles={setCsvFiles}
                  />
                )}
              </SetupRequirement.RequirementValidation>
              <Action
                isActionDisabled={!requiredSettingProps.isRequiredSettingsEmpty}
                currentType={currentType}
                isUploaded={isUploaded}
                csvFiles={csvFiles}
                onClick={onClick}
                isSubmitting={isSubmitting}
              />
            </ProductSkeleton>
          </form>
        </FormProvider>
      </GridItem>
    </SlzGridModal>
  );
};

export default Modal;
