import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle((props) => {
  return {
    wrapper: wrapperStyles(props),
    images: imageStyles(props),
    text: textStyles(props)
  };
});

const sizes = {
  sm: {
    minW: '12px',
    minH: '12px',
    w: '12px',
    h: '12px',
    borderRadius: '4px'
  },
  md: {
    minW: '24px',
    minH: '24px',
    w: '24px',
    h: '24px',
    borderRadius: '6px'
  },
  lg: {
    minW: '48px',
    minH: '48px',
    w: '48px',
    h: '48px',
    borderRadius: '12px'
  }
};

const wrapperStyles = defineStyle((props) => {
  const { size } = props;
  const direction = () => {
    switch (size) {
      case 'sm':
        return 'row';
      case 'md':
        return 'row';
      case 'lg':
        return 'column';
      default:
        break;
    }
  };
  return {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: direction(),
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: sizes[size].borderRadius
  };
});

const imageStyles = defineStyle((props) => {
  const { url, size } = props;
  return {
    display: 'inline-flex',
    backgroundImage: `url(${url})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '12px',
    ...sizes[size]
  };
});

const textStyles = defineStyle((props) => {
  const { size } = props;
  const font = () => {
    switch (size) {
      case 'sm':
        return {
          fontSize: '10px',
          m: '6px',
          span: {
            mt: '2px'
          }
        };
      case 'md':
        return {
          fontSize: '17px',
          ml: '6px',
          span: {
            mt: '4px'
          }
        };
      case 'lg':
        return {
          fontSize: '17px',
          w: '100%',
          mt: '6px'
        };
      default:
        break;
    }
  };
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    ...font()
  };
});

export default defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'main',
    url: ''
  }
});
