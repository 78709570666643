import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import { ORDER_TYPE } from 'components/common/SlzTable/Constants';
import SlzTable from 'components/common/SlzTable/Table';
import {
  B2C_ORDERS_PATHNAME,
  ORDER_DISPLAY_FIELDS,
  ORDER_SKELETON_CELL,
  ORDER_STATUS
} from '../../constants';
import { useOrderContext } from '../../context/OrderContext';
import { ORDER_ACCORDION_COLUMNS, ORDER_COLUMNS } from './columns';
import { FILTER_OPTIONS } from './constants';
import OrderAccordionTable from './OrderAccordionTable';

import CommonEndpoints from 'components/common/Endpoints';
import { isNullOrEmptyGuid } from 'Utilities';
import { PUT_ASYNC, extractData } from 'Consumer';
import useSlzToast from 'hooks/useSlzToast';
import WarningConfirmToast from 'modules/users/components/other/WarningConfirmToast';
import SuccessCancelToast from 'modules/b2c-orders/components/other/SuccessCancelToast';
import {
  SHOPIFY_ORDER_SOURCE,
  WOOCOMMERCE_ORDER_SOURCE,
  CANCEL_ORDER_TEXT,
  WARNING_CANCEL_TOAST_ID, 
  CONFIRM_LEAVE_TOAST_ID,
  STYLE_WARNING_TOAST,
  STYLE_FAILURE_TOAST,
  STYLE_SUCCESS_TOAST
} from 'modules/b2c-orders/constants/';

const columns = [...ORDER_COLUMNS];

const accordionProps = {
  isShowMarker: true,
  markedWidth: 12,
  columns: ORDER_ACCORDION_COLUMNS,
  showImage: true,
  imageWidth: 48,
  imageStyles: {
    w: '3rem',
    h: 'inherit'
  },
  type: ORDER_TYPE,
  displayFields: ORDER_ACCORDION_COLUMNS.map((col) => col.field)
};

const getCancelEndpoint = (source) => source === SHOPIFY_ORDER_SOURCE
    ? CommonEndpoints.RETAILER.PUT.CANCEL_SHOPIFY_ORDER
    : source === WOOCOMMERCE_ORDER_SOURCE
        ? CommonEndpoints.RETAILER.PUT.CANCEL_WOOCOMMERCE_ORDER
        : CommonEndpoints.RETAILER.PUT.CANCEL_ORDER;

const OrderTable = (props) => {
  const { order } = useOrderContext();
  const history = useHistory();
  const {
    currentPage,
    totalPage,
    onPageChange,
    onItemPerPageChange,
    onStatusFilterChange,
    onSort,
    onRefetching
  } = props;

/*columns[0].isClickable = true;
  columns[0].onClick = (row) => {
    history.push(
      `${B2C_ORDERS_PATHNAME}/${row.id}?status=${order?.params?.status}&source=${row?.source?.type}`
    );
  };*/

  columns[0].isClickable = true;
  columns[0].onClick = (row) => {
    if(row?.status?.name !== "Unprocessed"){
      history.push(
        `${B2C_ORDERS_PATHNAME}/details`,
        {row}
      );
    }
    else{
      showConfirmCancelToast(row)
    }
  };

  const handleDisplayField = useMemo(() => {
    const fields = ['', ORDER_STATUS.processing.key, ORDER_STATUS.processed.key];
    if (fields.includes(order?.params?.status)) {
      return ORDER_DISPLAY_FIELDS;
    }
    return ORDER_DISPLAY_FIELDS.filter((field) => field !== 'trackingCode');
  }, [order?.params?.status]);

  const [_, setToast, toast] = useSlzToast();

  const showConfirmCancelToast = (row) => {
    !toast.isActive(WARNING_CANCEL_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: WARNING_CANCEL_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <WarningConfirmToast
            warningText={CANCEL_ORDER_TEXT.leavingMessage + row.customerReference + CANCEL_ORDER_TEXT.warningMessage}
            OKText="Continue"
            onCancelClick={toast.closeAll}
            onOKClick={() => handleCancelOrder(row)}
          />
        )
      });
  };

  const showSuccessCancelToast = async () => {
    !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: CONFIRM_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_SUCCESS_TOAST,
        render: () => (
          <SuccessCancelToast
            warningText={CANCEL_ORDER_TEXT.success}
            onOKClick={handleOnOKClick}
          />
        )
      });
    onRefetching();
  };

  const showFailedCancelToast = () => {
    !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: CONFIRM_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_FAILURE_TOAST,
        render: () => (
          <SuccessCancelToast
            warningText={CANCEL_ORDER_TEXT.failure}
            onOKClick={handleOnOKClick}
          />
        )
      });
  };

  const handleCancelOrder = async (orderDetail) => {
    const cancelEndpoint = getCancelEndpoint(orderDetail.source.type);
        
      if (!isNullOrEmptyGuid(orderDetail.id)) {
        PUT_ASYNC(cancelEndpoint + "/" + orderDetail.id)
          .then(response => {
            handleOnOKClick();
            if (response.ok) return response.json();
          })
          .then(result => {
            const data = extractData(result);
              if (data === true) {
                  showSuccessCancelToast();
              }
              else{
                showFailedCancelToast();
              }
          })
          .catch(error => console.log(error));
      }
  };
  
  const handleOnOKClick = () => {
    toast.closeAll();
  };

  return (
    <SlzTable
      isLoading={props?.isLoading}
      variant="order"
      showMarked
      isEmptyPage={props?.isEmptyPage}
      messageToast={props?.messageToast}
      onRefetching={props?.onRefetching}
      sizes={['sm', 'md', 'lg']}
      columns={columns}
      data={order?.list || Array(5).fill(ORDER_SKELETON_CELL)}
      events={{ onSort }}
      rowProps={{
        hasExpandedRow: true,
        accordionProps: {
          ...accordionProps,
          template: ({ row }) => {
            return <OrderAccordionTable row={row} accordionProps={accordionProps} />;
          }
          // cellTemplate: ({ col, row, displayFields }) => {
          //   return <SlzAccordionCell col={col} row={row} displayFields={displayFields} />;
          // }
        }
      }}
      displayFields={handleDisplayField}
      hyperLinks={['customerReference']}
      filters={{
        title: 'Status',
        byField: 'status',
        //options: FILTER_OPTIONS,
        handleSelectedFilter: onStatusFilterChange,
        defaultSelectedValues: order?.params?.filterBy ? [order?.params?.filterBy] : []
      }}
      filterByDate={{
        field: 'createdDate',
        start: order?.params?.startDate,
        end: order?.params?.endDate
      }}
      pagination={{
        isLocal: false,
        pages: {
          onChange: (numPerPage) => onItemPerPageChange(numPerPage),
          pageOptions: DEFAULT_PAGING_OPTIONS,
          currentItemPerPage: order?.params?.pageSize
        },
        direction: {
          usingDots: false,
          totalPage,
          currentPage,
          // numDots: 5,
          isGreyBg: true,
          onChange: (currentPage) => onPageChange(currentPage)
        }
      }}
    />
  );
};

export default OrderTable;
