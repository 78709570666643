import { position } from '@chakra-ui/react';
import SlzToast from 'components/common/SlzToast/SlzToast';

export const notifyUpload = (props) => {
  const { toast, onCloseComplete, ...rest } = props;

  const handleClose = (onClose) => {
    onCloseComplete ? onCloseComplete(onClose) : onClose();
  };

  return toast({
    isClosable: true,
    onCloseComplete: onCloseComplete,
    render: ({ onClose }) => (
      <SlzToast
        size="md"
        variant="solid"
        w="53.625rem"
        onClose={() => handleClose(onClose)}
        {...rest}
      />
    ),
    position: rest.position
  });
};
