import map from 'lodash/map';
import { useHistory } from 'react-router';
import SlzTable from 'components/common/SlzTable/Table';
import { CONSIGNMENTS_PATHNAME, CONSIGNMENT_SKELETON_CELL } from '../../constants';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import { useConsignmentsContext } from '../../context/ConsignmentsContext';
import { CONSIGNMENTS_COLUMNS } from './columns';
import { useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { SendConsignmentModal } from '../modal';
import { defaultGuid } from 'Utilities';

const columns = [...CONSIGNMENTS_COLUMNS];

const SendConsignment = ({
  isOpenSendConsignmentModal,
  onCloseSendConsignmentModal,
  setEnableQuery
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
    setEnableQuery(false);
  }, [isOpenSendConsignmentModal]);

  const handleClose = () => {
    onClose();
    onCloseSendConsignmentModal && onCloseSendConsignmentModal();
  };

  return (
    <SendConsignmentModal
      onClose={handleClose}
      isOpen={isOpen}
      formValues={defaultSendConsignmentValue}
    />
  );
};

const defaultSendConsignmentValue = {
  consignmentType: 0,
  stockBoxQuantity: null,
  itemsPerStockbox: null
};

const ConsignmentsTable = (props) => {
  const { consignment } = useConsignmentsContext();
  const history = useHistory();

  const {
    totalPage,
    onPageChange,
    onItemPerPageChange,
    isLoading,
    //receivedStatuses,
    selectedReceivedStatuses,
    isOpenSendConsignmentModal,
    onCloseSendConsignmentModal,
    handleFilterByReceivedStatusChange,
    setEnableQuery,
    onSort
  } = props;

  columns[0].onClick = (row) => {
    // Use consignmentName for external stock
    const consignmentId = row?.id !== defaultGuid ? row?.id : row?.consignmentName;
    history.push(`${CONSIGNMENTS_PATHNAME}/${consignmentId}`);
  };

  return (
    <>
      {isOpenSendConsignmentModal && (
        <SendConsignment
          isOpenSendConsignmentModal={isOpenSendConsignmentModal}
          onCloseSendConsignmentModal={onCloseSendConsignmentModal}
          setEnableQuery={setEnableQuery}
        />
      )}
      <SlzTable
        isLoading={isLoading}
        showMarked
        type="normal"
        variant="simple"
        sizes={['sm', 'md', 'lg']}
        columns={columns}
        onRefetching={props?.onRefetching}
        isEmptyPage={props?.isEmptyPage}
        messageToast={props?.messageToast}
        data={consignment?.list || Array(5).fill(CONSIGNMENT_SKELETON_CELL)}
        displayFields={map(columns, 'field')}
        events={{onSort}}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => onItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: consignment?.params?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage,
            currentPage: consignment?.params?.pageIndex,
            isGreyBg: true,
            onChange: (currentPage) => onPageChange(currentPage)
          }
        }}
        filters={{
          title: 'Status',
          byField: 'receivedStatus',
          //options: receivedStatuses,
          defaultSelectedValues: selectedReceivedStatuses,
          isLocal: false,
          handleSelectedFilter: (receivedStatus) => handleFilterByReceivedStatusChange(receivedStatus)
        }}
        filterByDate={{
          field: 'createdDate',
          start: consignment?.params?.startDate,
          end: consignment?.params?.endDate
        }}
      />
    </>
  );
};

export default ConsignmentsTable;
