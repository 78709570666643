import { Fragment } from 'react';
import {
  Box,
  Icon,
  HStack,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  createStylesContext,
  useStyleConfig,
  useDisclosure,
  Text
} from '@chakra-ui/react';
import { SlOptionsVertical } from 'react-icons/sl';

import { SlzPopoverConfirm } from '../SlzPopoverConfirm';
import { CREDIT_CARD_TYPE, PAYMENT_CARD_STATUS } from 'constants/credit-card';
import ChipCardIcon from '../Icons/ChipCardIcon';
import { SlzTag } from '../SlzTag';
import { SlzButton } from '../SlzButton';
import {
  AmericanExpressIcon,
  DirectDebitIcon,
  MasterCardIcon,
  PaypalIcon,
  VisaIcon
} from '../Icons';

const [StylesProvider, useStyles] = createStylesContext('SlzPaymentCard');

const PAYMENT_CARD_LOGO = {
  [CREDIT_CARD_TYPE.PAYPAL]: PaypalIcon,
  [CREDIT_CARD_TYPE.VISA]: VisaIcon,
  [CREDIT_CARD_TYPE.MASTERCARD]: MasterCardIcon,
  [CREDIT_CARD_TYPE.AMERICAN_EXPRESS]: AmericanExpressIcon,
  [CREDIT_CARD_TYPE.BACS]: DirectDebitIcon
};

const TAG_COLOR_SCHEME = {
  [PAYMENT_CARD_STATUS.SUCCESSFUL]: 'positive',
  [PAYMENT_CARD_STATUS.ACTIVE]: 'positive',
  [PAYMENT_CARD_STATUS.UNSUCCESSFUL]: 'negative',
  [PAYMENT_CARD_STATUS.PENDING]: 'mustard'
};

function Status({ status }) {
  const colorScheme = TAG_COLOR_SCHEME[status];

  return (
    <SlzTag colorScheme={colorScheme} variant="pendingPayment" size="lg" textTransform="capitalize">
      {status}
    </SlzTag>
  );
}

function Setup({ name = 'Setup', onSetup }) {
  return (
    <SlzButton size="sm" rounded="md" onClick={onSetup}>
      {name}
    </SlzButton>
  );
}

function Edit({ onRemove, onReplace }) {
  const confirmation = useDisclosure();
  const menu = useDisclosure();

  const confirmRemoving = () => {
    confirmation.onOpen();
  };

  return (
    <Box pos="relative">
      <SlzPopoverConfirm
        title="Removing your payment card?"
        description="Removing your payment card will disable the fulfillment of orders"
        okText="Remove"
        isOpen={confirmation.isOpen}
        onClose={confirmation.onClose}
        onCancel={confirmation.onClose}
        onOk={onRemove}>
        <Box pos="absolute" w="full" h="full" zIndex="hide"></Box>
      </SlzPopoverConfirm>
      <Popover isOpen={menu.isOpen} onOpen={menu.onOpen} onClose={menu.onClose}>
        <PopoverTrigger>
          <Box
            as="button"
            borderWidth="1px"
            lineHeight={0}
            p="0.25em"
            rounded="full"
            color="white"
            borderColor="currentColor">
            <Icon as={SlOptionsVertical} w="1em" h="1em" color="currentColor" />
          </Box>
        </PopoverTrigger>

        <PopoverContent w={24}>
          <PopoverArrow boxShadow="none" />
          <PopoverBody p={0} fontSize="small">
            <Menu isOpen={menu.isOpen} strategy="relative" onClose={menu.onClose}>
              <MenuList tr minW="auto" w="100%" boxShadow="none" border="none">
                {onReplace && (
                  <MenuItem
                    _hover={{ bgColor: 'main.300' }}
                    outline="none !important"
                    onClick={onReplace}>
                    Replace
                  </MenuItem>
                )}

                {onRemove && (
                  <MenuItem
                    _hover={{ bgColor: 'main.300' }}
                    outline="none !important"
                    onClick={confirmRemoving}>
                    Remove
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
}

function Chip() {
  const styles = useStyles();
  return <Icon sx={styles.chip} as={ChipCardIcon} />;
}

function Logo({ type, sx = {} }) {
  const styles = useStyles();
  const PaymentCardLogo = PAYMENT_CARD_LOGO[type] || Fragment;

  return (
    <Box sx={{ ...styles.logo, ...sx }}>
      <Icon as={PaymentCardLogo} />
    </Box>
  );
}

function Header({ children }) {
  return (
    <HStack justifyContent="space-between" alignItems="flex-start">
      {children}
    </HStack>
  );
}

function Information({ children, customerName, email, cardNumber, expiredDate, paymentTerms }) {
  const styles = useStyles();

  return (
    <Box sx={styles.info}>
      <Text fontWeight="bold">{customerName}</Text>
      {cardNumber && <Text>{cardNumber}</Text>}
      {email && <Text>{email}</Text>}
      {expiredDate && <Text>{expiredDate}</Text>}
      {children}
    </Box>
  );
}

function Body({ children }) {
  const styles = useStyles();

  return <Box sx={styles.body}>{children}</Box>;
}

function PaymentCard({ children, variant, size, colorScheme }) {
  const styles = useStyleConfig('SlzPaymentCard', { size, variant, colorScheme });

  return (
    <StylesProvider value={styles}>
      <Box sx={styles.card}>{children}</Box>
    </StylesProvider>
  );
}

PaymentCard.Header = Header;
PaymentCard.Body = Body;
PaymentCard.Status = Status;
PaymentCard.Setup = Setup;
PaymentCard.Edit = Edit;
PaymentCard.Information = Information;
PaymentCard.Logo = Logo;
PaymentCard.Chip = Chip;

export default PaymentCard;
