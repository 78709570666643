import { ButtonGroup, useRadioGroup } from '@chakra-ui/react';
import React from 'react';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import SlzRadioButton from './SlzRadioButton';
import './SlzButtonGroup.scss';

const SlzButtonGroup = ({
  options,
  variant,
  colorScheme,
  size,
  name,
  value,
  defaultValue,
  isDisabled,
  onChange,
  children,
  ...rest
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    defaultValue,
    onChange
  });

  return (
    <ButtonGroup
      id={uniqueId('SEL-Slz-ButtonGroup')}
      className="btn-group"
      data-testid="slz-button-group"
      size={size}
      spacing={'1px'}
      variant={variant}
      isAttached={variant === 'outline'}
      {...getRootProps()}
      {...rest}>
      {options &&
        map(options || [], ({ value, label }) => (
          <SlzRadioButton
            key={value}
            size={size}
            variant={variant}
            isDisabled={isDisabled}
            colorScheme={colorScheme}
            {...getRadioProps({ value })}>
            {label}
          </SlzRadioButton>
        ))}
      {!options &&
        React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const { value } = child.props;
            return React.cloneElement(child, {
              colorScheme,
              variant,
              size,
              ...getRadioProps({ value })
            });
          }
          return child;
        })}
    </ButtonGroup>
  );
};

export default SlzButtonGroup;

SlzButtonGroup.propTypes = {
  variant: PropTypes.oneOf(['solid', 'outline']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  colorScheme: PropTypes.oneOf(['main', 'positive', 'negative']),
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    })
  ),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};
