import colors from './../../../theme/newdesign/foundations/themeColors';
import { filter, first, isEmpty } from 'lodash';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import {
  NAVIGATION_RAIL_WIDTH,
  NAVIGATION_RAIL_EXPANDED_WIDTH,
  MARGIN_FOR_PAGE_LARGE_SCREEN_WIDTH,
  MARGIN_FOR_PAGE_SMALL_SCREEN_WIDTH,
  PRODUCT_SECONDARY_BUTTON_WIDTH,
  CELL_BUTTON_ACCORDION_WIDTH,
  CELL_CHILD_ACCORDION_TABLE_WIDTH
} from '../Layout/Constants';
import { COL_CUSTOM } from './Constants';
import { EMPHASIS_FIELDS } from 'constants/common';
export const isCustomCell = (colDef) => colDef?.type === COL_CUSTOM;
export const getRandomColor = (colorIdx = null) => {
  const keys = Object.keys(colors);
  return keys[colorIdx || Math.floor(Math.random() * keys.length)];
};
export const getColDefinition = (cols, field) => first(filter(cols, { field }));
export const getGroupFields = (cols) => cols.filter((col) => col?.isEmphasis).map((f) => f?.field);
export const getFields = (cols) => cols.map((col) => col?.field);

export const transforms = (items, cols, filters) => {
  const groupFields = getGroupFields(cols);
  const groups = {};
  let filterOptions = isEmpty(filters) ? [] : filters.options;

  const newItems = items.map((item) => {
    let newItem = { ...item };
    groupFields.forEach((field) => {
      if (item[field]) {
        const randomColor = getRandomColor();
        if (!groups[item[field]]) {
          groups[item[field]] = randomColor;
        }
        newItem = {
          ...newItem,
          [field]: {
            name: item[field],
            highlight: {
              color: groups[item[field]] ?? randomColor
            }
          },
          display: true
        };

        let option = filterOptions.find((option) => option.key === item[field]);
        if (option) {
          option.colorScheme = newItem[field].highlight.color;
        } else {
          const newOption = {
            key: item[field],
            text: item[field],
            colorScheme: newItem[field].highlight.color
          };
          filterOptions.push(newOption);
        }
      }
    });
    return newItem;
  });
  return [newItems, filterOptions];
};

export const calculatorColumnFixedWidth = (args) => {
  const {
    isChild,
    showMarked,
    actions,
    columns,
    displayFields,
    markedWidth,
    isAccordion,
    spacing
  } = args;
  let columnFixedWidth = showMarked ? markedWidth : 0;
  columnFixedWidth += isChild ? CELL_CHILD_ACCORDION_TABLE_WIDTH : 0;
  columnFixedWidth += actions ? PRODUCT_SECONDARY_BUTTON_WIDTH : 0;
  columnFixedWidth += isAccordion ? CELL_BUTTON_ACCORDION_WIDTH : 0;
  columnFixedWidth += spacing ? spacing : 0;
  columnFixedWidth += columns.length
    ? columns.reduce((prev, current) => {
        if (current.width && displayFields.includes(current?.field)) prev += current.width;
        // if (isCustomCell(current)) prev += current.width;
        return prev;
      }, 0)
    : 0;
  return columnFixedWidth;
};

export const countColumnDisplay = (args) => {
  const { columns, displayFields } = args;
  let columnDisplayCount = 0;
  columnDisplayCount += columns.length
    ? columns.reduce((prev, current) => {
        if (displayFields.includes(current?.field) && !current?.width) prev += 1;
        //if (isCustomCell(current)) prev += 1;
        return prev;
      }, 0)
    : 0;
  return columnDisplayCount;
};

export const calculatorTableDimensions = (args) => {
  const { columnFixedWidth, columnDisplayCount, isLargerThan1279, width, isNavbarExpanded } = args;
  const marginValue = isLargerThan1279
    ? MARGIN_FOR_PAGE_LARGE_SCREEN_WIDTH
    : MARGIN_FOR_PAGE_SMALL_SCREEN_WIDTH;
  const navbarWidth = isNavbarExpanded ? NAVIGATION_RAIL_EXPANDED_WIDTH : NAVIGATION_RAIL_WIDTH;

  const availableArea = width - (2 * marginValue + navbarWidth + columnFixedWidth);
  const columnWidth = columnDisplayCount > 0 ? availableArea / columnDisplayCount : 0;
  const paddingValue = availableArea / 100;
  return { columnWidth, paddingValue };
};

export const mappingAccordionRows = (rows) =>
  rows?.length && rows.map((row) => ({ id: row?.id, isExpand: false, ...row }));

export const mappingEmphasisFieldTable = (field) => {
  if (EMPHASIS_FIELDS.includes(field)) return `${field}.name`;

  return field;
};
