import { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import { SlzPaper } from 'components/common/SlzPaper';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { SlzTimelineStepper } from 'components/common/SlzTimelineStepper';
import { SlzSingleButton } from 'components/common/SlzButton';
import { orderInformationCols } from 'modules/products/constants';

import {
  ORDER_STATUS,
  ORDER_PROCESSING_STATUS,
  ORDER_TYPE,
  VARIANT_DETAIL_ORDER
} from 'modules/b2c-orders/constants';
import OrderHeader from './OrderHeader';

const OrderItem = (props) => {
  const { order, index, isShipment = false } = props;
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const statusOrders = (status) => {
    return ORDER_STATUS[status]?.color;
  };

  const renderOrderLabel = () => {
    if (!isShipment) {
      return (
        <OrderHeader text="Order Number">
          <Text fontSize="0.875rem" fontWeight="bold">
            {order?.id}
          </Text>
        </OrderHeader>
      );
    }
    if (selected !== index) {
      return <OrderHeader text={`Shipment ${index + 1}`} />;
    }
    return;
  };

  const renderOrderDetailLabel = () => {
    if (!isShipment) {
      return (
        <Text fontSize="1rem" fontWeight="bold">
          Order details
        </Text>
      );
    }
    if (selected === index) {
      return (
        <Text fontSize="1rem" fontWeight="bold">
          Shipment {index + 1}
        </Text>
      );
    }
    return;
  };

  return (
    <SlzPaper
      variant="auto"
      bg="light.500"
      borderLeftWidth="0.75rem"
      borderRadius="0.375rem"
      boxShadow="0px 3px 6px #00000029"
      borderLeftColor={order?.status?.color}>
      <Flex h="4.5rem" alignItems="center" px={5}>
        <HStack width="full">
          {renderOrderLabel()}
          <OrderHeader text="Status">
            <SlzLabel
              variant="courier"
              bg={order?.status?.color}
              color="light.300"
              fontSize="0.75rem"
              lineHeight="1.313rem">
              {order?.status?.label}
            </SlzLabel>
          </OrderHeader>
          <OrderHeader text="Courier">
            {order?.courier?.name && (
              <SlzLabel variant="courier" bg={order?.courier?.bg} color={order?.courier?.color}>
                {order?.courier?.name}
              </SlzLabel>
            )}
          </OrderHeader>
          <OrderHeader text="Tracking">
            {order?.tracking && (
              <SlzLabel
                variant="courier"
                bg="main.500"
                color="light.300"
                fontSize="1rem"
                fontWeight="bold">
                {order?.tracking}
              </SlzLabel>
            )}
          </OrderHeader>
        </HStack>

        <Flex ml="2rem">
          <SlzSingleButton
            onClick={() => {
              toggle(index);
            }}
            size="lg"
            bg={selected === index ? 'main.500' : 'light.700'}
            icon={
              <ChevronDownIcon
                boxSize="2.5rem"
                transitionDuration="0.3s"
                transform={`rotate(${selected === index ? 180 : 0}deg)`}
              />
            }
          />
        </Flex>
      </Flex>
      <VStack
        px={3}
        mt="1.313rem"
        transitionDuration="0.3s"
        display={`${selected === index ? 'block' : 'none'}`}>
        <Flex w="full" justifyContent="center">
          <SlzTimelineStepper
            disabled={true}
            steps={ORDER_PROCESSING_STATUS}
            step={order?.status?.key}
            w="90%"
          />
        </Flex>
        <Box py={15} pl={3}>
          {renderOrderDetailLabel()}
          <SlzAccordionTable
            variant="order"
            showLoading={false}
            isShow={true}
            data={order?.items}
            id={order?.id}
            isChild={true}
            configProps={{
              isExpand: true,
              markedWidth: 12,
              columns: orderInformationCols,
              showImage: true,
              imageWidth: 80,
              // imageStyles: {
              //   w: '3rem'
              // },
              spacing: 300,
              type: ORDER_TYPE,
              displayFields: orderInformationCols.map((col) => col.field)
            }}
          />
        </Box>
      </VStack>
    </SlzPaper>
  );
};

export default OrderItem;
