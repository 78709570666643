import React, { useEffect, useState } from "react";
import { Box, GridItem, Text, Textarea, VStack } from '@chakra-ui/react'
import { SlzGridModal } from "components/common/SlzModal";
import SlzInput from "components/common/SlzInput/SlzInput";
import { SlzDropdown } from "components/common/SlzDropdown";
import { InfoIcon, LeftArrow } from "components/common/Icons/RequestAccessModal/RequestAccessModal";
import { SlzButton } from "components/common/SlzButton";
import SlzDateSinglePicker from "components/common/SlzDateSinglePicker";
import { createRequestAccess } from 'services/fulfillmentCentre'
import useSlzToast from 'hooks/useSlzToast';
import { SlzTag } from "components/common/SlzTag";
import { STYLE_WARNING_TOAST } from "modules/address-book/constants";
import ConfirmToast from "modules/address-book/components/other/ConfirmToast";
import FulfilmentCentreMiniCard from "../common/FulfilmentCentreMiniCard/FulfilmentCentreMiniCard";

const WH_REQUEST = "WH Access Request";
const user = JSON.parse(localStorage.getItem('user'));
const statuses = {
    available: "Available",
    pending: "Pending"
}

const RequestAccessModal = ({ isOpen, warehouse, categories, setIsModalActive, accessRequestByID, requesStatus }) => {

    const [firstFulfilmentDateEmpty, setFirstFulfilmentDateEmpty] = useState(false);
    const [inventoryArrivalDateEmpty, setInventoryArrivalDateEmpty] = useState(false);
    const [numberOfSkusEmpty, setNumberOfSkusEmpty] = useState(false);
    const [ordersPerMonthEmpty, setOrdersPerMonthEmpty] = useState(false);
    const [productCategoriesEmpty, setProductCategoriesEmpty] = useState(false);
    const [_, setToast, toast] = useSlzToast();

    const [infoRequestAccess, setInfoRequestAccess] = useState({
        ordersPerMonth: '',
        palletsToStore: '',
        numberOfSKUs: '',
        productCategories: [],
        expectedInventoryArrivalDate: '',
        expectedFirstFulfilmentDate: '',
        specialRequirements: '',
        warehouseID: warehouse.warehouseID,
        notificationType: WH_REQUEST,
        companyID: warehouse.companyID,
        RetailerUserID: user.id
    });

    const infoEmpty = {
        ordersPerMonth: '',
        palletsToStore: '',
        numberOfSKUs: '',
        productCategories: [],
        expectedInventoryArrivalDate: '',
        expectedFirstFulfilmentDate: '',
        specialRequirements: '',
        warehouseID: warehouse.warehouseID,
        notificationType: WH_REQUEST,
        companyID: warehouse.companyID,
        RetailerUserID: user.id
    };

    const DELETE_CONFIRMATION = {
        message: 'Are you sure you want to cancel your request?',
        confirmText: 'Yes',
        cancelText: 'No',
        cancelProps: {
            variant: 'outline',
            borderColor: 'dark.700',
            colorScheme: 'dark'
        },
        confirmProps: {
            color: 'sunshine.500',
            bg: 'dark.700',
            colorScheme: 'dark'
        }
    };

    useEffect(() => {
        if (requesStatus === statuses.pending) transformAccessRequest();
    }, [accessRequestByID]);

    const checkInfo = () => {

        if (!reviewInfo()) {

            setToast({
                chakraToastProps: {
                    duration: null
                },
                ...STYLE_WARNING_TOAST,
                render: () => (
                    <ConfirmToast
                        {...DELETE_CONFIRMATION}
                        onConfirmed={() => modalClose()}
                        onCancel={toast.closeAll}
                    />
                )
            });
        }
        else {
            modalClose();
        }
    }

    const modalClose = () => {
        toast.closeAll();
        setInfoRequestAccess(infoEmpty);
        setOrdersPerMonthEmpty(false);
        setNumberOfSkusEmpty(false);
        setProductCategoriesEmpty(false);
        setInventoryArrivalDateEmpty(false);
        setFirstFulfilmentDateEmpty(false);
        setIsModalActive(false);
    }

    const transformAccessRequest = () => {
        const transformedData = {
            ordersPerMonth: accessRequestByID.ordersPerMonth,
            palletsToStore: accessRequestByID.palletsToStore,
            numberOfSKUs: accessRequestByID.numberOfSKUs,
            productCategories: accessRequestByID.productCategories,
            expectedInventoryArrivalDate: accessRequestByID.expectedInventoryArrivalDate,
            expectedFirstFulfilmentDate: accessRequestByID.expectedFirstFulfilmentDate,
            specialRequirements: accessRequestByID.specialRequirements,
            warehouseID: warehouse.warehouseID,
            notificationType: WH_REQUEST,
            companyID: warehouse.companyID,
            RetailerUserID: user.id
        };

        setInfoRequestAccess(transformedData);
    }

    const assignData = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'ordersPerMonth':
                if (value < 1) setInfoRequestAccess(prevState => ({ ...prevState, ordersPerMonth: '' }));
                else {
                    setInfoRequestAccess(prevState => ({ ...prevState, ordersPerMonth: value }));
                    setOrdersPerMonthEmpty(false);
                }
                return;

            case 'palletsToStore':
                value < 1 ? setInfoRequestAccess(prevState => ({ ...prevState, palletsToStore: '' })) : setInfoRequestAccess(prevState => ({ ...prevState, palletsToStore: value }));
                return;

            case 'numberSkus':
                if (value < 1) setInfoRequestAccess(prevState => ({ ...prevState, numberOfSKUs: '' }));
                else {
                    setInfoRequestAccess(prevState => ({ ...prevState, numberOfSKUs: value }));
                    setNumberOfSkusEmpty(false);
                }
                return;

            case 'inventoryArrivalDate':
                setInfoRequestAccess(prevState => ({ ...prevState, expectedInventoryArrivalDate: value }));
                return;

            case 'firstFulfilmentDate':
                setInfoRequestAccess(prevState => ({ ...prevState, expectedFirstFulfilmentDate: value }));
                return;

            case 'specialRequirements':
                setInfoRequestAccess(prevState => ({ ...prevState, specialRequirements: value }));
                return;

            default:
                return null;
        }
    }

    const selectCategories = (e) => {

        const transformedCategories = transformCategories(e);
        setInfoRequestAccess(prevState => ({ ...prevState, productCategories: transformedCategories }));
        setProductCategoriesEmpty(false);
    }

    const setExpectedInventoryArrivalDate = (e) => {
        setInfoRequestAccess(prevState => ({ ...prevState, expectedInventoryArrivalDate: e.$d }));
        setInventoryArrivalDateEmpty(false);
    }

    const setExpectedFirstFulfilmentDate = (e) => {
        setInfoRequestAccess(prevState => ({ ...prevState, expectedFirstFulfilmentDate: e.$d }));
        setFirstFulfilmentDateEmpty(false);
    }

    const validateInfo = () => {
        let isValidInfo = true;

        if (infoRequestAccess.ordersPerMonth === '' || infoRequestAccess.ordersPerMonth < 1) {
            setOrdersPerMonthEmpty(true);
            isValidInfo = false;
        }
        else setOrdersPerMonthEmpty(false);

        if (infoRequestAccess.numberOfSKUs === '' || infoRequestAccess.numberOfSKUs < 1) {
            setNumberOfSkusEmpty(true);
            isValidInfo = false;
        }
        else setNumberOfSkusEmpty(false);

        if (infoRequestAccess.productCategories.length === 0) {
            setProductCategoriesEmpty(true);
            isValidInfo = false;
        }
        else setProductCategoriesEmpty(false);

        if (infoRequestAccess.expectedInventoryArrivalDate === '') {
            setInventoryArrivalDateEmpty(true);
            isValidInfo = false;
        }
        else setInventoryArrivalDateEmpty(false);

        if (infoRequestAccess.expectedFirstFulfilmentDate === '') {
            setFirstFulfilmentDateEmpty(true);
            isValidInfo = false;
        }
        else setFirstFulfilmentDateEmpty(false);

        return isValidInfo;
    }

    const submitRequest = (e) => {
        e.preventDefault();

        if (validateInfo()) {
            newRequestAccess(infoRequestAccess);
        }
    }

    const newRequestAccess = async (body) => {
        try {
            const response = await createRequestAccess(body);
            if (response === "error") setToast({ title: "There was an error submitting your request. Please try again.", status: 'warning', colorScheme: 'negative', chakraToastProps: { duration: null } });
            else {
                modalClose();
                setToast({ title: "Request submitted. A member of our team will review this and be in touch soon.", status: 'success', colorScheme: 'positive', chakraToastProps: { duration: null } });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const transformCategories = (categories) => {
        return categories.map((category) => {
            return {
                CategoryName: category.label,
                createdDate: category.createdDate,
                CategoryID: category.value,
                parentCategoryID: category.parentCategoryID
            }
        })
    }

    const toDateString = (date) => {
        if (date === null || date === "") return "";

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("/");
    }

    const getCategoryName = (category) => {
        try {
            const categoryName = categories.filter(cat => cat.value == category.categoryID);
            return categoryName[0].label;
        }
        catch (error) {
            console.log(error);
        }
    }

    const TagCategories = (listCategories) => {
        return (
            listCategories?.categoryID.map((category) =>
                <SlzTag h='2px' color='dark.500' bg='light.700'>{getCategoryName(category)}</SlzTag>
            )
        )
    }

    const reviewInfo = () => {
        if (requesStatus !== statuses.pending) {

            if (infoRequestAccess.ordersPerMonth !== '') return false;
            if (infoRequestAccess.palletsToStore !== '') return false;
            if (infoRequestAccess.numberOfSKUs !== '') return false;
            if (infoRequestAccess.productCategories.length !== 0) return false;
            if (infoRequestAccess.expectedInventoryArrivalDate !== '') return false;
            if (infoRequestAccess.expectedFirstFulfilmentDate !== '') return false;
            if (infoRequestAccess.specialRequirements !== '') return false;
        }
        return true;
    }

    return (
        <>
            <SlzGridModal isOpen={isOpen} onClose={checkInfo}>
                <GridItem gridColumn={'2/12'}>
                    <Box display='flex' flexDirection='row' alignItems='center' gap='0.719rem' mb='2.558rem'>
                        <LeftArrow />
                        <Text fontSize={24} fontWeight='bold'>Request Access to Selazar {warehouse.warehouseName}</Text>
                    </Box>
                </GridItem>
                <GridItem gridColumn={'2/12'}>
                    <Box display='flex' flexDirection='row' gap='0.752rem' mb='1.174rem'>
                        <FulfilmentCentreMiniCard warehouse={warehouse} />
                        <Box w='30.748rem' h='7.5rem' bg='main.300' p='0.868rem 0rem 0rem 1.528rem' borderRadius='0.75rem 0.75rem 0.75rem 0.75rem'>
                            <Box>
                                <Box display='flex' flexDirection='row' gap='0.406rem'>
                                    <InfoIcon />
                                    <Text fontSize={19} fontWeight='bold' mb='0.313'>Request Information</Text>
                                </Box>
                                <Text fontSize={14} mb='0.675'>The requested information below is in relation to this warehouse only</Text>
                                <Text fontSize={14}>Our team will review this request and get back to you</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' gap='0.752rem' mb='1.121rem'>
                        <Box display='flex' flexDirection='column' w='14.998rem'>
                            <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Number of Orders Per Month</Text>
                            <SlzInput
                                id='ordersPerMonth'
                                name='ordersPerMonth'
                                value={infoRequestAccess.ordersPerMonth}
                                status={ordersPerMonthEmpty && 'error'}
                                type='number'
                                readOnly={requesStatus === statuses.pending ? true : false}
                                onChange={(e) => assignData(e)} />
                            {ordersPerMonthEmpty && <Text fontSize={12} color='red.500'>*Number of Orders Per Month is required</Text>}
                        </Box>
                        <Box id='Pallets' display='flex' flexDirection='column' w='20.248rem'>
                            <Box display='flex' flexDirection='row'>
                                <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Number of Pallets to Store</Text> <Text color='dark.500' fontStyle='italic'> - Optional</Text>
                            </Box>
                            <SlzInput
                                id='palletsToStore'
                                name='palletsToStore'
                                value={infoRequestAccess.palletsToStore}
                                readOnly={requesStatus === statuses.pending ? true : false}
                                type='number'
                                onChange={(e) => assignData(e)} />
                        </Box>
                        <Box display='flex' flexDirection='column' w='14.998rem'>
                            <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Number of SKUs</Text>
                            <SlzInput
                                id='numberSkus'
                                name='numberSkus'
                                value={infoRequestAccess.numberOfSKUs}
                                status={numberOfSkusEmpty && 'error'}
                                readOnly={requesStatus === statuses.pending ? true : false}
                                type='number'
                                onChange={(e) => assignData(e)} />
                            {numberOfSkusEmpty && <Text fontSize={12} color='red.500'>*Number of SKUs is required</Text>}
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='column' mb='1.121rem'>
                        <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Product Categories</Text>
                        {
                            requesStatus === statuses.pending ?
                                <Box
                                    display='flex'
                                    flexDirection='row'
                                    alignItems='center'
                                    gap='0.752rem'
                                    w='51.748rem'
                                    h='3rem'
                                    p='0rem 0rem 0rem 0.625rem'
                                    bg='lightAndShadow.darkModeText'
                                    border='0.063rem solid'
                                    borderColor='pebbel.300'
                                    borderRadius='0.313rem'>
                                    <TagCategories categoryID={accessRequestByID?.productCategories} />
                                </Box>
                                :
                                <SlzDropdown
                                    id='categories'
                                    name='categories'
                                    isMulti
                                    isInvalid={productCategoriesEmpty && true}
                                    size='lg'
                                    placeholder='Select Product Categories...'
                                    options={categories}
                                    onChange={(e) => selectCategories(e)} />
                        }
                        {productCategoriesEmpty && <Text fontSize={12} color='red.500'>*Product Categories is required</Text>}
                    </Box>
                    <Box display='flex' flexDirection='row' gap='0.752rem' mb='1.121rem'>
                        <Box display='flex' flexDirection='column' w='14.998rem' h='5.998rem'>
                            <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Expected Inventory Arrival Date</Text>
                            <SlzDateSinglePicker
                                id='inventoryArrivalDate'
                                name='inventoryArrivalDate'
                                value={infoRequestAccess.expectedInventoryArrivalDate}
                                startDatePlaceHolder={requesStatus === statuses.pending ? toDateString(infoRequestAccess.expectedInventoryArrivalDate) : 'dd/mm/yy'}
                                size='md'
                                onDateInputClick={(e) => setExpectedInventoryArrivalDate(e)} />
                            {inventoryArrivalDateEmpty && <Text fontSize={12} color='red.500'>*Number of SKUs is required</Text>}
                        </Box>
                        <Box display='flex' flexDirection='column' w='14.998rem'>
                            <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Expected First Fulfilment Date</Text>
                            <SlzDateSinglePicker
                                id='firstFulfilmentDate'
                                name='firstFulfilmentDate'
                                value={infoRequestAccess.expectedFirstFulfilmentDate}
                                startDatePlaceHolder={requesStatus === statuses.pending ? toDateString(infoRequestAccess.expectedFirstFulfilmentDate) : 'dd/mm/yy'}
                                size='md'
                                onDateInputClick={(e) => setExpectedFirstFulfilmentDate(e)} />
                            {firstFulfilmentDateEmpty && <Text fontSize={12} color='red.500'>*Number of SKUs is required</Text>}
                        </Box>
                    </Box>
                    <Box id='SpecialRequirements' display='flex' flexDirection='column' w='20.248rem' mb='2.643rem'>
                        <Box display='flex' flexDirection='row'>
                            <Text fontSize={16} fontWeight='bold' color='dark.500' mb='0.5rem'>Special Requirements</Text> <Text color='dark.500' fontStyle='italic'> - Optional</Text>
                        </Box>
                        <Textarea
                            id='specialRequirements'
                            name='specialRequirements'
                            borderColor='pebbel.300'
                            readOnly={requesStatus === statuses.pending ? true : false}
                            value={infoRequestAccess.specialRequirements}
                            w='51.748rem'
                            h='9rem'
                            maxLength={300}
                            placeholder='Please note any special requests. For example, security, controlled temperatures, custom packaging etc.'
                            onChange={(e) => assignData(e)} />
                    </Box>
                    <Box id='Buttons' display='flex' flexDirection='row' justifyContent='space-between'>
                        <SlzButton size='md' variant='outline' colorScheme='negative' onClick={() => checkInfo()} >Cancel</SlzButton>
                        <SlzButton size='md' isDisabled={requesStatus === statuses.pending ? true : false} onClick={(e) => submitRequest(e)}>
                            {requesStatus === statuses.pending ? 'Pending Request' : 'Submit Request'}
                        </SlzButton>
                    </Box>
                </GridItem>
            </SlzGridModal>
        </>
    )
}

export default RequestAccessModal;