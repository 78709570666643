import { useQuery } from 'react-query';
import { getCreditCardInfo } from '../services/payment-apis';
import { creditCardInfoMapper } from '../mappers/payment-mapper';

export default function useGetCreditCardInfo() {
  return useQuery({
    queryKey: ['get-credit-card'],
    queryFn: getCreditCardInfo,
    select: (value) => creditCardInfoMapper(value.data?.data)
  });
}
