import { Icon } from "@chakra-ui/react";

const ConsignmentTypeIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g
        id="Type_of_consignment_icon"
        data-name="Type of consignment icon"
        transform="translate(12458.664 16517)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(-12458.664 -16517)"
          fill="#0973ea"
        />
        <path
          id="Rectangle_1878"
          data-name="Rectangle 1878"
          d="M-.344-1H2.366a.657.657,0,0,1,.656.656V2.366a.657.657,0,0,1-.656.656H-.344A.657.657,0,0,1-1,2.366V-.344A.657.657,0,0,1-.344-1ZM2.366,2.366V-.344H-.344V2.366Z"
          transform="translate(-12452.546 -16510.844)"
          fill="#fafafa"
        />
        <path
          id="Rectangle_1878-2"
          data-name="Rectangle 1878"
          d="M-.344-1H2.366a.657.657,0,0,1,.656.656V2.366a.657.657,0,0,1-.656.656H-.344A.657.657,0,0,1-1,2.366V-.344A.657.657,0,0,1-.344-1ZM2.366,2.366V-.344H-.344V2.366Z"
          transform="translate(-12446.835 -16510.844)"
          fill="#fafafa"
        />
        <path
          id="Rectangle_1879"
          data-name="Rectangle 1879"
          d="M-.344-1H2.366a.657.657,0,0,1,.656.656V2.366a.657.657,0,0,1-.656.656H-.344A.657.657,0,0,1-1,2.366V-.344A.657.657,0,0,1-.344-1ZM2.366,2.366V-.344H-.344V2.366Z"
          transform="translate(-12452.546 -16505.123)"
          fill="#fafafa"
        />
        <path
          id="Rectangle_1880"
          data-name="Rectangle 1880"
          d="M-.344-1H2.366a.657.657,0,0,1,.656.656V2.366a.657.657,0,0,1-.656.656H-.344A.657.657,0,0,1-1,2.366V-.344A.657.657,0,0,1-.344-1ZM2.366,2.366V-.344H-.344V2.366Z"
          transform="translate(-12446.823 -16505.123)"
          fill="#fafafa"
        />
      </g>
    </Icon>
  );
};

export default ConsignmentTypeIcon;