import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = {
  borderRadius: '1rem',
  pt: '42px',
  pb: '44px',
  px: '47px',
  boxShadow: 'drop-shadow(0px 2px 16px #00000065)',
  // boxShadow: '0px 2px 16px #00000065',
  border: 'none',
  w: '311px'
};

const variantBlue = defineStyle((props) => {
  return {
    bg: '#0973EA',
    color: '#F2F2F2',
    arrow: {
      bg: '#0973EA'
    },
    closeButton: {
      bg: '#0973EA',
      color: '#F2F2F2',
      w: '1rem',
      h: '1rem',
      m: 1.5
    }
  };
});
const variantBlack = defineStyle((props) => {
  return {
    bg: '#303134',
    color: '#F2F2F2',
    arrow: {
      bg: '#303134'
    },
    closeButton: {
      bg: '#303134',
      color: '#F2F2F2',
      w: '1rem',
      h: '1rem',
      m: 1.5
    }
  };
});
const variantWhite = defineStyle((props) => {
  return {
    bg: '#FAFAFA',
    color: '#303134',
    arrow: {
      bg: '#FAFAFA'
    },
    closeButton: {
      bg: '#FAFAFA',
      color: '#303134',
      w: '1rem',
      h: '1rem',
      m: 1.5
    }
  };
});

const variantUserSetting = defineStyle((props) => {
  return {
    ...variantBlue(props),
    py: 5,
    px: 6,
    borderRadius: 'xl'
  };
});

export default defineStyleConfig({
  baseStyle,
  variants: {
    blue: variantBlue,
    black: variantBlack,
    white: variantWhite,
    userSetting: variantUserSetting
  }
});
