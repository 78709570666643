import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function FacebookIcon(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.584 22.492" {...props}>
      <path
        id="facebook-icon-2"
        data-name="facebook-icon"
        d="M18.324,3.843h-3.6c-2.133,0-4.506.9-4.506,3.989.01,1.077,0,2.109,0,3.271H7.754V15.03H10.3V26.335h4.675V14.955h3.086l.279-3.864H14.893v-.075c0-.024,0-.053,0-.087v-.252c0-.049,0-.1,0-.156V9.983c0-.063,0-.126,0-.188V9.431c0-.231,0-.432,0-.557,0-1.222,1.271-1.152,1.348-1.152h2.084Z"
        transform="translate(-7.754 -3.843)"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </Icon>
  );
}
