import { Box, Text, VStack } from '@chakra-ui/react';
import SlzTable from 'components/common/SlzTable/Table';
import { B2B_ORDER_OPTION_SUMMARY_COLUMNS } from 'modules/b2b-orders/constants/b2b-summaries-orders-columns';

const B2BOrderOptionTable = ({ rows, ...rest }) => {
  return (
    <VStack w="50%" alignItems="flex-start" {...rest}>
      <Text fontWeight="medium" lineHeight={5.5}>
        B2B order options
      </Text>
      <Box w="full">
        <SlzTable
          borderRadius={1.5}
          variant="b2bOrderOption"
          autoResize={false}
          showBoxShadow={false}
          showToolbar={false}
          columns={B2B_ORDER_OPTION_SUMMARY_COLUMNS}
          data={rows}
          displayFields={B2B_ORDER_OPTION_SUMMARY_COLUMNS.map((col) => col.field)}
        />
      </Box>
    </VStack>
  );
};

export default B2BOrderOptionTable;
