import React from 'react';
import { HStack } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import {
  USER_MANAGEMENT_ACTION_CONTENT,
  USER_MANAGEMENT_USER_STEP_INDEX,
  USER_MANAGEMENT_USER_STEP_SUMMARY
} from 'modules/users/constants';

const DEFAULT_CHILDREN = ({ disableNextButton, isSubmitting, currentStep }) => (
  <SlzButton variant="solid" type="submit" size="lg" isDisabled={disableNextButton || isSubmitting}>
    {currentStep !== USER_MANAGEMENT_USER_STEP_SUMMARY
      ? USER_MANAGEMENT_ACTION_CONTENT.next
      : USER_MANAGEMENT_ACTION_CONTENT.submit}
  </SlzButton>
);

const UserManagementAction = ({
  onPrevious,
  disableNextButton = false,
  isSubmitting,
  currentStep,
  onCancel,
  children = DEFAULT_CHILDREN({ disableNextButton, isSubmitting, currentStep })
}) => {
  return (
    <HStack w="full" spacing={0} justifyContent="space-between" mt={7} mb={6}>
      {currentStep === USER_MANAGEMENT_USER_STEP_INDEX ? (
        <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onCancel}>
          {USER_MANAGEMENT_ACTION_CONTENT.cancel}
        </SlzButton>
      ) : (
        <SlzButton variant="outline" size="lg" onClick={onPrevious}>
          {USER_MANAGEMENT_ACTION_CONTENT.previous}
        </SlzButton>
      )}
      <HStack>
        {currentStep !== USER_MANAGEMENT_USER_STEP_INDEX && (
          <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onCancel}>
            {USER_MANAGEMENT_ACTION_CONTENT.cancel}
          </SlzButton>
        )}
        {children}
      </HStack>
    </HStack>
  );
};

export default UserManagementAction;
