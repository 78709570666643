import { Fragment } from 'react';
import { Box, HStack, Heading, Icon } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';

const UserManagementModalHeader = () => {
  return (
    <Fragment>
      <HStack alignItems="flex-start">
        <Icon as={FaChevronLeft} fontSize={28} color="dark.500" mt="0.5" cursor="pointer" />
        <Box>
          <Heading fontSize="custom.2xl" lineHeight={'none'} mb="0">
            Add a new user
          </Heading>
        </Box>
      </HStack>
    </Fragment>
  );
};
export default UserManagementModalHeader;
