import React from 'react';
import { GridItem, Heading, HStack, VStack, Icon, Skeleton, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';

import HomeIcon from 'components/common/Icons/HomeIcon';
import { SlzGrid } from 'components/common/SlzGrid';
import { CustomerCircleIcon } from 'components/common/Icons';
import { SlzPaper } from 'components/common/SlzPaper';
import { SlzTimelineStepper } from 'components/common/SlzTimelineStepper';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { SlzSkeletonDefault } from 'components/common/Skeleton/Skeleton';

import {
  B2C_ORDERS_PATHNAME,
  ORDER_PROCESSING_STATUS,
  ORDER_TYPE
} from 'modules/b2c-orders/constants';
import {
  OrderCostDetails,
  OrderCustomerDetails,
  OrderInfoDetails,
  OrderItems,
  B2COrderSubStatus
} from 'modules/b2c-orders/components/other';
import { orderInformationCols } from 'modules/products/constants';

const OrderDetailHeader = ({ data, isLoading, ...rest }) => {
  const history = useHistory();

  return (
    <GridItem area="header" colSpan="12">
      <HStack
        alignItems="center"
        cursor="pointer"
        display="inline-flex"
        ml="-0.25rem"
        mb={2}
        onClick={() => history.push(B2C_ORDERS_PATHNAME)}>
        <Icon as={FaChevronLeft} fontSize="lg" color="dark.500" />
        <Heading as="h4" fontSize="1.0625rem">
          B2C Orders
        </Heading>
      </HStack>
      <OrderInfoDetails
        orderDetail={data}
        isLoading={isLoading}
        w="100%"
        h="6rem"
        bg="light.500"
        {...rest}
      />
    </GridItem>
  );
};

const OrderDetailNav = ({ data, isLoading }) => (
  <GridItem area="nav">
    <VStack spacing={6}>
      <B2COrderSubStatus status={data?.subStatus} isLoading={isLoading} />
      <OrderCustomerDetails
        customer={data?.customer}
        w="19.125rem"
        h="auto"
        title="Customer details"
        iconTop={<CustomerCircleIcon />}
        iconBottom={<HomeIcon />}
        isLoading={isLoading}
      />
      <OrderCostDetails
        totalCost={data?.totalCost}
        costBreakdown={data?.costBreakdown}
        isLoading={isLoading}
        w="19.125rem"
        h="auto"
        title="Cost details"
      />
    </VStack>
  </GridItem>
);

const OrderDetailStepper = ({ data, isLoading }) => (
  <GridItem area="stepper" colSpan="12">
    <SlzPaper w="full" h="8.688rem" display="flex" alignItems="center" bg="light.500">
      <Skeleton isLoaded={!isLoading} display="flex" alignItems="center" width="full" height="full">
        <SlzTimelineStepper
          disabled={true}
          steps={ORDER_PROCESSING_STATUS}
          step={data?.orderBreakdown[0]?.status?.key}
          w="80%"
          px={20}
        />
      </Skeleton>
    </SlzPaper>
  </GridItem>
);

const OrderDetailTable = ({ data, isLoading }) => {
  const order = data?.orderBreakdown[0];

  return isLoading ? (
    <SlzSkeletonDefault h="41rem" />
  ) : (
    <GridItem area="main" colSpan="11" height="52.5rem">
      <SlzPaper bg="light.500" w="full" h="100%" p={4}>
        <Text fontSize="1rem" fontWeight="bold" mb={3}>
          Order details
        </Text>
        <SlzAccordionTable
          variant="order"
          showLoading={false}
          isShow={true}
          data={order?.items}
          id={order?.id}
          isChild={true}
          configProps={{
            isExpand: true,
            markedWidth: 12,
            columns: orderInformationCols,
            showImage: true,
            imageWidth: 80,
            spacing: 300,
            type: ORDER_TYPE,
            displayFields: orderInformationCols.map((col) => col.field)
          }}
        />
      </SlzPaper>
    </GridItem>
  );
};

const OrderDetailAccordionTable = ({ data, isLoading }) => (
  <GridItem
    area="main"
    colSpan="11"
    sx={{
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '::-webkit-scrollbar': {
        display: 'none'
      }
    }}
    height="52.5rem"
    overflowY="scroll">
    <OrderItems orderDetail={data} isLoading={isLoading} />
  </GridItem>
);

const OrderDetailFooter = () => <GridItem area="footer"></GridItem>;

const OrderDetail = ({ children, isLoading, data }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { data, isLoading });
    }
    return child;
  });

  return (
    <SlzGrid
      templateAreas={`"header header header header header header"
        "stepper stepper stepper stepper stepper stepper"
        "nav main main main main main"
        "footer main main main main main"`}>
      {childrenWithProps || (
        <>
          <OrderDetailHeader data={data} isLoading={isLoading} />
          <OrderDetailNav data={data} isLoading={isLoading} />
          <OrderDetailFooter />
          <OrderDetailAccordionTable data={data} isLoading={isLoading} />
        </>
      )}
    </SlzGrid>
  );
};

OrderDetail.header = OrderDetailHeader;
OrderDetail.stepper = OrderDetailStepper;
OrderDetail.nav = OrderDetailNav;
OrderDetail.footer = OrderDetailFooter;
OrderDetail.accordionTable = OrderDetailAccordionTable;
OrderDetail.table = OrderDetailTable;
export default OrderDetail;
