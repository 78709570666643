import React, { useEffect } from 'react';

import { HStack, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

import { SlzButton } from 'components/common/SlzButton';
import { GeneralTrackingFields } from 'modules/b2b-orders/components/form';
import { DEFAULT_GENERAL_TRACKING } from 'modules/b2b-orders/constants';

const GeneralTrackingForm = ({
  generalTracking,
  purchaseOrderNumber,
  hasData,
  isEdit,
  onSaveGeneralTracking
}) => {
  const generalTrackingMethods = useForm({
    defaultValues: { generalTracking }
  });

  const filterGeneralTracking = (generalTrackingDetail) => {
    const generalTracking = filter(generalTrackingDetail, (obj) => !every(obj, isEmpty));

    generalTrackingMethods.setValue(
      'generalTracking',
      generalTracking?.length ? generalTracking : DEFAULT_GENERAL_TRACKING
    );

    generalTrackingMethods.reset({}, { keepValues: true });
    return generalTracking;
  };

  const handleSaveGeneralTracking = (values) => {
    let generalTracking = { ...values.generalTracking };

    if (isEdit) {
      generalTracking = filterGeneralTracking(generalTracking);
    }

    onSaveGeneralTracking({ ...values, generalTracking }, generalTrackingMethods.formState.isDirty);
  };

  const getButtonLabel = () => {
    if (isEdit) {
      return 'Save';
    }

    if (hasData) {
      return 'Edit';
    }

    return 'Add tracking';
  };

  useEffect(() => {
    filterGeneralTracking(generalTracking);
  }, []);

  return (
    <FormProvider {...generalTrackingMethods}>
      <form
        onSubmit={generalTrackingMethods?.handleSubmit(handleSaveGeneralTracking)}
        style={{ width: '100%' }}>
        <VStack alignItems="start" mb={6}>
          <Text fontSize="1.25rem" fontWeight="bold" mb={0}>
            Order tracking details
          </Text>
          <Text fontSize="1rem">#{purchaseOrderNumber}</Text>
        </VStack>
        <HStack spacing="1.25rem" mb={6}>
          <Text fontSize="1.25rem" fontWeight="bold" mb={0}>
            General tracking
          </Text>
          <SlzButton variant="outline" borderRadius="6px" type="submit">
            {getButtonLabel()}
          </SlzButton>
        </HStack>

        <GeneralTrackingFields
          control={generalTrackingMethods.control}
          register={generalTrackingMethods.register}
          disabled={!isEdit}
          placeholder="Not supplied"
        />
      </form>
    </FormProvider>
  );
};

export default GeneralTrackingForm;
