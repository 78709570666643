import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Bin = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="0.975rem"
            h="1.125rem"
            viewBox="0 0 15.603 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Union_317" data-name="Union 317" d="M6366.717,20989a3.6,3.6,0,0,1-3.555-3.053l-1.128-7.334a2.7,2.7,0,0,1,2.664-3.113v0h1.5v-.9a3.612,3.612,0,0,1,3.6-3.6,3.607,3.607,0,0,1,3.6,3.6v.9h1.5a2.7,2.7,0,0,1,2.668,3.109l-1.128,7.338a3.612,3.612,0,0,1-3.56,3.053Zm-2.7-11.387a.893.893,0,0,0-.2.727l1.127,7.332a1.792,1.792,0,0,0,1.778,1.527h6.167a1.784,1.784,0,0,0,1.777-1.527l1.133-7.332a.914.914,0,0,0-.208-.727.9.9,0,0,0-.682-.312h-1.5v1.8a.9.9,0,0,1-1.8,0v-1.8H6368v1.8a.9.9,0,0,1-1.8,0v-1.8h-1.5A.9.9,0,0,0,6364.015,20977.611Zm7.585-2.113v-.9a1.8,1.8,0,1,0-3.6,0v.9Z" transform="translate(-6362.001 -20970.998)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Bin;