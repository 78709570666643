import { object, string } from 'yup';

export const validation = {
  name: string()
    .min(5, 'must be at least 5 characters long')
    .max(100, 'value must be less than 100 characters')
    .required(),
  brand: string().max(100, 'value must be less than 100 characters').required(),
  category: object().required()
};
