import BulkOrderRoutes from 'modules/b2b-orders/configs/routes';
import OrdersRoutes from 'modules/b2c-orders/configs/routes';
import ReturnsRoutes from 'modules/returns/configs/routes';
import AddressBookRoutes from 'modules/address-book/configs/routes';
import BillingRoutes from 'modules/billing/configs/routes';
import IntegrationRoutes from 'modules/integrations/configs/routes';
import ProductsRoutes from 'modules/products/configs/routes';
import ConsignmentsRoutes from 'modules/consignments/config/routes';
import FulfillmentCentreRoutes from 'modules/fulfilmentCentres/configs/routes';
import Main from 'pages/Main';
import ReturnRoutes from 'modules/returns/configs/routes';
import UsersRoutes from 'modules/users/configs/routes';
import DashboardRoutes from 'modules/dashboard/configs/routes';
import SkeletonDemo from 'pages/SkeletonDemo';
import PaymentNewCardReturn from 'modules/preferences/pages/payment-new-card-return';

const PrivateRoutes = [
  { path: '/demo', component: Main },
  ...DashboardRoutes,
  ...ProductsRoutes,
  ...OrdersRoutes,
  ...ReturnsRoutes,
  ...AddressBookRoutes,
  ...BulkOrderRoutes,
  ...BillingRoutes,
  ...IntegrationRoutes,
  ...ConsignmentsRoutes,
  ...FulfillmentCentreRoutes,
  ...ReturnRoutes,
  ...UsersRoutes,
  { path: '/skeleton-demo', component: SkeletonDemo },
  { path: '/retailer/finance/newcard/return', component: PaymentNewCardReturn }
];

export default PrivateRoutes;
