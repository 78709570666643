import { Fragment } from 'react';
import { Box, HStack, Text, VStack, useDisclosure } from '@chakra-ui/react';

import { ApiIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { SlzChip } from 'components/common/SlzChip';
import { SlzPaper } from 'components/common/SlzPaper';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import { API_CARD_TEXT, REMOVE_API_CONNECTION } from 'modules/integrations/constants';
import { API_CARD_STYLES } from 'modules/integrations/constants/style';

const ApiCard = () => {};
ApiCard.Wrap = VStack;

ApiCard.Icon = () => {
  return (
    <SlzChip {...API_CARD_STYLES.apiChip}>
      <ApiIcon pr={1} />
      {API_CARD_TEXT.api}
    </SlzChip>
  );
};

ApiCard.ConnectedStatus = () => {
  return <SlzChip {...API_CARD_STYLES.connected}>{API_CARD_TEXT.connected}</SlzChip>;
};

ApiCard.SetupNewButton = ({ onClickSetupNewApi }) => {
  return (
    <HStack w="full" spacing={0} justifyContent="flex-end">
      <SlzButton {...API_CARD_STYLES.setupNewButton} onClick={onClickSetupNewApi}>
        {API_CARD_TEXT.setupNew}
      </SlzButton>
    </HStack>
  );
};

ApiCard.DeleteButton = ({ apiName = '', onClickDeleteApi }) => {
  const confirmation = useDisclosure();
  return (
    <SlzPopoverConfirm
      variant="apiConnection"
      gutter={2}
      arrowSize={22}
      title={REMOVE_API_CONNECTION?.title}
      description={REMOVE_API_CONNECTION?.description(apiName)}
      okText={REMOVE_API_CONNECTION?.okText}
      isOpen={confirmation.isOpen}
      onClose={confirmation.onClose}
      onCancel={confirmation.onClose}
      placement="bottom"
      onOk={onClickDeleteApi}>
      <Box>
        <SlzButton
          {...API_CARD_STYLES.manageButton}
          colorScheme="negative"
          onClick={confirmation.onOpen}>
          {API_CARD_TEXT.delete}
        </SlzButton>
      </Box>
    </SlzPopoverConfirm>
  );
};

ApiCard.ManageButton = ({ onClickManageApi }) => {
  return (
    <SlzButton {...API_CARD_STYLES.manageButton} onClick={onClickManageApi}>
      {API_CARD_TEXT.manage}
    </SlzButton>
  );
};

ApiCard.Name = ({ name }) => {
  return (
    <Text fontSize="custom.2md" color="black" fontWeight="bold" lineHeight="5.5" noOfLines={1}>
      {name}
    </Text>
  );
};

ApiCard.Description = () => {
  return (
    <Text fontSize="sm" color="black" lineHeight="3.5">
      {API_CARD_TEXT.apiConnectionDesc}
    </Text>
  );
};

ApiCard.LastConnectionDate = ({ createdDate }) => {
  return (
    <Text fontSize="sm" color="black" lineHeight="3.5">
      <Box as="span" fontWeight="bold" pr={1}>
        {API_CARD_TEXT.lastConnectionDate}
      </Box>
      {createdDate}
    </Text>
  );
};

ApiCard.Header = ({ children }) => {
  return (
    <HStack spacing={0} align="flex-start" justifyContent="space-between">
      {children}
    </HStack>
  );
};

ApiCard.Footer = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

ApiCard.Body = ({ children }) => {
  return (
    <VStack spacing="0.313rem" align="stretch" flex={1}>
      {children}
    </VStack>
  );
};

const APIConnectionCard = ({
  api,
  isSetupNew,
  onClickSetupNewApi,
  onClickManageApi,
  onClickDeleteApi
}) => {
  return (
    <SlzPaper w="full" minH="12.75rem" bg="white" borderRadius={3} boxShadow="slz-1md">
      <ApiCard.Wrap h="full" spacing="1.625rem" align="stretch" p={6}>
        <ApiCard.Header>
          <ApiCard.Icon />
          {!isSetupNew && <ApiCard.ConnectedStatus />}
        </ApiCard.Header>
        <ApiCard.Body>
          <ApiCard.Name name={isSetupNew ? API_CARD_TEXT.apiConnection : api.name} />
          {isSetupNew ? (
            <ApiCard.Description />
          ) : (
            <ApiCard.LastConnectionDate createdDate={api.createdDate} />
          )}
        </ApiCard.Body>
        <ApiCard.Footer>
          {isSetupNew ? (
            <ApiCard.SetupNewButton onClickSetupNewApi={onClickSetupNewApi} />
          ) : (
            <HStack w="full" spacing={0} justifyContent="space-between" zIndex="overlay">
              <ApiCard.DeleteButton
                apiName={api.name}
                onClickDeleteApi={() => onClickDeleteApi(api.id)}
              />
              <ApiCard.ManageButton onClickManageApi={() => onClickManageApi(api.id)} />
            </HStack>
          )}
        </ApiCard.Footer>
      </ApiCard.Wrap>
    </SlzPaper>
  );
};

export default APIConnectionCard;
