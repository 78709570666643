import { Icon } from '@chakra-ui/react';
import React from 'react';

export const ClearIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14.105 14.105"
      fill="currentColor"
      strokeWidth="0"
      {...props}>
      <path id="Path_3563" data-name="Path 3563" d="M0,0H14.105V14.105H0Z" fill="none" />
      <path
        id="Path_3564"
        data-name="Path 3564"
        d="M12.7,12.527H6.231L3.88,10.176a.588.588,0,0,1,0-.829L9.757,3.47a.588.588,0,0,1,.829,0l2.939,2.939a.588.588,0,0,1,0,.829l-5.29,5.29"
        transform="translate(-1.529 -1.36)"
        fill="none"
        stroke="#3371e2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <line
        id="Line_131"
        data-name="Line 131"
        x1="3.703"
        y1="3.703"
        transform="translate(6.876 3.526)"
        fill="none"
        stroke="#3371e2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </Icon>
  );
};
