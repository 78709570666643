import { useEffect, useState } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    let value = defaultValue;

    try {
      if (window.localStorage && key) {
        value = JSON.parse(window.localStorage.getItem(key) || defaultValue);
      }
    } catch (error) {
      error instanceof Object && console.log(error?.message);
    }
    return value;
  });

  useEffect(() => {
    try {
      if (window.localStorage && storedValue) {
        window.localStorage.setItem(
          key,
          storedValue instanceof Object ? JSON.stringify(storedValue) : storedValue
        );
        setStoredValue(storedValue || defaultValue);
      }
    } catch (error) {
      console.log("Windows local storage does not support this.");
    }
  }, [storedValue]);

  return [storedValue, setStoredValue];
};

export default useLocalStorage;
