import { useQuery } from 'react-query';
import { getApiConnectionDetail } from '../services/integration-api';

export const useApiConnectionDetailQuery = ({ id, select, enabled }) => {
  return useQuery({
    queryKey: [`api-detail`, id],
    queryFn: () => getApiConnectionDetail(id),
    select: select,
    enabled: enabled
  });
};
