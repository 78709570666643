import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, VStack, useToken } from '@chakra-ui/react';
import { Cell, Pie, PieChart, Sector, Tooltip } from 'recharts';
import { SlzPaper } from 'components/common/SlzPaper';
import { ORDERS_TODAY_LABEL, PIE_CHART_LABEL, noOrderTodayData } from 'modules/dashboard/constants';
import { useOrdersTodayQuery } from 'modules/dashboard/hooks';
import { transformOrdersToday } from 'modules/dashboard/utils';
import WarningIcon from 'modules/dashboard/icons/WarningIcon';
import { CustomTooltipContent } from 'modules/dashboard/components/other';

const CustomizedLabel = ({ isLoading, totalOrders }) => {
  if (isLoading)
    return (
      <>
        <rect
          x="35%"
          y="40%"
          textAnchor="middle"
          width="2.813rem"
          height="1.188rem"
          fill="url(#colorGradient)"></rect>
        <text
          x="50%"
          y="60%"
          textAnchor="middle"
          dominantBaseline="central"
          style={{ fontSize: '0.625rem', color: '#222222' }}>
          Orders
        </text>
      </>
    );
  return (
    <>
      {totalOrders ? (
        <>
          <text
            x="50%"
            y="45%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: '1.5rem', color: '#222222', outline: 'none' }}>
            {totalOrders}
          </text>

          <text
            x="50%"
            y="60%"
            textAnchor="middle"
            dominantBaseline="central"
            style={{ fontSize: '0.625rem', color: '#222222' }}>
            Orders
          </text>
        </>
      ) : (
        <WarningIcon
          x="36%"
          y="36%"
          dominantBaseline="central"
          textAnchor="middle"
          color="stone.300"
        />
      )}
    </>
  );
};

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 3}
        outerRadius={outerRadius + 3}
        startAngle={startAngle - 1}
        endAngle={endAngle + 1}
        fill="#0973EA"
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 2}
        outerRadius={outerRadius + 2}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="white"
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 1}
        outerRadius={outerRadius + 1}
        startAngle={startAngle + 1}
        endAngle={endAngle - 1}
        fill={fill}
      />
    </g>
  );
};

const renderInactiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        opacity={0.5}
      />
    </g>
  );
};

const CustomTooltip = ({ payload }) => {
  const content = `${payload?.[0]?.payload?.count} ${payload?.[0]?.payload?.name} orders`;
  return (
    <CustomTooltipContent>
      <Box padding="0.813rem 0.688rem">
        <Text fontSize="sm" fontWeight="bold" color="raisin.300" whiteSpace="nowrap">
          {content}
        </Text>
      </Box>
    </CustomTooltipContent>
  );
};

const PieChartLabel = ({ isLoading, totalOrders, onReload, activeLabel }) => {
  return (
    <>
      {totalOrders > 0 || isLoading ? (
        <VStack alignItems="start">
          <Text fontSize="md" fontWeight="bold" color="raisin.300">
            {PIE_CHART_LABEL.TITLE}
          </Text>
          {ORDERS_TODAY_LABEL.map((label, index) => (
            <HStack key={index} opacity={activeLabel === null || activeLabel === index ? 1 : 0.5}>
              <Box as="div" w="0.625rem" h="0.625rem" bg={label.color}></Box>
              <Text fontSize="sm" color="dark.500" fontWeight="medium">
                {label.title}
              </Text>
            </HStack>
          ))}
        </VStack>
      ) : (
        <VStack spacing="0.313rem" alignItems="flex-start" mt="2.375rem">
          <Text fontSize="sm" fontWeight="medium" color="dark.500">
            {PIE_CHART_LABEL.NO_DATA}
          </Text>
          <Text color="main.500" fontSize="sm" sx={{ cursor: 'pointer' }} onClick={onReload}>
            Reload
          </Text>
        </VStack>
      )}
    </>
  );
};

const OrdersToday = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [totalOrders, setTotalOrders] = useState(0);
  const [ordersTodayPieChart, setOrdersTodayPieChart] = useState([]);
  const {
    data: ordersToday,
    isLoading,
    refetch
  } = useOrdersTodayQuery({
    select: transformOrdersToday
  });
  const [posData, setPosData] = useState({});

  const onMouseOver = (data, index) => {
    setActiveIndex(index);
    setPosData(data);
  };

  const onMouseLeave = () => {
    setActiveIndex(null);
  };

  useEffect(() => {
    const totalOrders = ordersToday?.reduce((acc, order) => acc + order.count, 0);
    setTotalOrders(totalOrders);
  }, [ordersToday]);

  useEffect(() => {
    if (totalOrders) {
      setOrdersTodayPieChart(ordersToday);
    } else {
      setOrdersTodayPieChart(noOrderTodayData);
    }
  }, [totalOrders]);

  return (
    <SlzPaper py="0.875rem" px="1.5rem" w="100%" h="10.9375rem" borderRadius="md" bg="white">
      <HStack justifyContent="space-between" alignItems="flex-start">
        <PieChartLabel
          isLoading={isLoading}
          totalOrders={totalOrders}
          onReload={refetch}
          activeLabel={activeIndex}
        />
        <PieChart width={150} height={150} style={{ outline: 'none' }}>
          <defs>
            <linearGradient id="colorGradient" x1="1" y1="1" x2="0" y2="0">
              <stop offset="30%" stopColor={useToken('colors', 'stone.300')} />
              <stop offset="95%" stopColor="white" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          {!isLoading ? (
            <Pie
              activeIndex={activeIndex}
              data={ordersTodayPieChart || []}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={70}
              paddingAngle={1}
              labelLine={false}
              isAnimationActive={false}
              dataKey="count"
              style={{ outline: 'none' }}
              activeShape={totalOrders > 0 && renderActiveShape}
              inactiveShape={totalOrders > 0 && renderInactiveShape}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}>
              {ordersTodayPieChart?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={useToken('colors', entry.color)} />
              ))}
            </Pie>
          ) : (
            <Pie
              data={[noOrderTodayData[0]]}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={70}
              paddingAngle={0}
              labelLine={false}
              dataKey="count"
              fill="url(#colorGradient)"
              style={{ outline: 'none' }}></Pie>
          )}
          <g>
            <CustomizedLabel isLoading={isLoading} totalOrders={totalOrders} />
          </g>
          {totalOrders && (
            <Tooltip
              content={CustomTooltip}
              position={{ x: posData.tooltipPosition?.x - 72, y: posData.tooltipPosition?.y - 55 }}
            />
          )}
        </PieChart>
      </HStack>
    </SlzPaper>
  );
};

export default OrdersToday;
