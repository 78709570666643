import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Flex, Image, Text, VStack } from '@chakra-ui/react';
import {
  DEFAULT_NUMBER_COUNTDOWN,
  LOGIN_PATHNAME,
  MAX_SECONDS_REDIRECT_TIMER,
  RESET_PASSWORD_IMAGE_URL_SET,
  TEXT_OVERLAY,
  TYPE_RELOAD
} from 'modules/users/constants';
import { CheckSuccessPasswordCircleIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { PublicLayout } from 'components/layout';
import { UserFormHeading } from '../components/other';
import { useConfirmEmailMutation } from 'modules/users/hooks';

const SuccessPassword = (props) => {
  const [countDown, setCountDown] = useState(MAX_SECONDS_REDIRECT_TIMER);
  const history = useHistory();
  const timerRef = useRef();
  const shouldRedirectToLogin = window?.performance?.navigation?.type === TYPE_RELOAD;
  const mutationConfirmEmail = useConfirmEmailMutation();
  const [isSuccess, setIsSuccess] = useState(true);

  const redirectLoginPage = () => {
    history.push(LOGIN_PATHNAME);
  };

  const confirmEmailRequest = async () => {
    try {
      await mutationConfirmEmail.mutateAsync(props?.match?.params?.id);
      setIsSuccess(true);
    } catch (err) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (countDown) {
        setCountDown(countDown - DEFAULT_NUMBER_COUNTDOWN);
      } else {
        history.push(LOGIN_PATHNAME);
      }
    }, 1000);

    return () => clearTimeout(timerRef.current);
  }, [countDown]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      confirmEmailRequest();
    }
    shouldRedirectToLogin && history.push(LOGIN_PATHNAME);
  }, []);

  return (
    <PublicLayout
      imageSrc={RESET_PASSWORD_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={null}>
      <VStack mb={5} mt={10}>
        <Image src="Images/selazar-login.svg" mb={5} />
        <UserFormHeading text={isSuccess ? 'Success!' : 'Error!'} />
        {isSuccess && (
          <>
            <Text fontSize="1rem" color="dark.700" mb={6} lineHeight="21px">
              Your new password is ready to go!
            </Text>
            <CheckSuccessPasswordCircleIcon />
            <Flex w="full" bg="light.500" justifyContent="center" borderRadius="0.375rem" py="3">
              <Text fontWeight="medium">
                You will be automatically redirected in {countDown} seconds
              </Text>
            </Flex>
            <Text fontWeight="medium">Or</Text>
          </>
        )}
        <SlzButton size="lg" w="full" onClick={redirectLoginPage}>
          Login
        </SlzButton>
      </VStack>
    </PublicLayout>
  );
};

export default SuccessPassword;
