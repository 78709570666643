import { VStack } from '@chakra-ui/react';
import { Fragment } from 'react';

import { SlzSkeletonDefault } from 'components/common/Skeleton/Skeleton';
import OrderItem from './OrderItem';

const OrderItems = (props) => {
  const { orderDetail, isLoading } = props;
  return (
    <VStack gap="1rem" p={1}>
      <SlzSkeletonDefault h="41rem" isLoading={isLoading}>
        {(orderDetail?.orderBreakdown || []).map((order, index) => (
          <Fragment key={index}>
            <OrderItem order={order} index={index} isShipment={orderDetail?.isShipment} />
          </Fragment>
        ))}
        {(orderDetail?.orderNumbers || []).map((order, index) => (
          <Fragment key={index}>
            <OrderItem order={order} index={index} isShipment={false} />
          </Fragment>
        ))}
      </SlzSkeletonDefault>
    </VStack>
  );
};

export default OrderItems;
