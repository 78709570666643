import { useQuery } from 'react-query';
import { getUseWithRoles } from '../services/users-api';

export const useGetUseWithRolesQuery = ({ id, enabled }) => {
  return useQuery({
    queryKey: [`get-user-with-roles-${id}`],
    queryFn: () => getUseWithRoles(id),
    enabled
  });
};
