import { HStack, StackItem, Text } from '@chakra-ui/react';
import { Fragment as CustomerSettingFragment, useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';

import HeadphoneIcon from 'components/common/Icons/HeadphoneIcon';
import { SlzToggle } from 'components/common/SlzToggle';
import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import useAuth from 'hooks/useAuth';

import {
  CUSTOMER_SUPPORT_ACCESS_PREFERENCES_TEXT,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { useGetCustomerPreferenceQuery } from 'modules/preferences/hooks/useGetCustomerPreferenceQuery';
import { PreferenceHeader as CustomerSettingHeader, PreferencePaper } from './common';

const CustomerSettingBody = ({ methods, paperContent, isFetching }) => {
  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <PreferencePaper w="25.438rem" mt={2.5}>
          <HStack p="1.25rem 0 1.25rem 1.25rem" alignItems="center" spacing={4}>
            <StackItem>
              <HeadphoneIcon w="27px" h="30px" />
            </StackItem>
            <StackItem>
              <Text>{paperContent}</Text>
            </StackItem>
            <StackItem>
              <Controller
                control={methods?.control}
                name="access"
                render={({ field }) => {
                  return (
                    <SlzToggle
                      isDisabled={isFetching}
                      value={field?.value}
                      isChecked={field?.value}
                      onChange={field?.onChange}
                      mb={0}
                    />
                  );
                }}
              />
            </StackItem>
          </HStack>
        </PreferencePaper>
      </form>
    </FormProvider>
  );
};

const CustomerSetting = ({ activatedSetting }) => {
  const { header, description } = CUSTOMER_SUPPORT_ACCESS_PREFERENCES_TEXT;
  const { paperContent, preference: preferenceType } = CUSTOMER_SUPPORT_ACCESS_PREFERENCES_TEXT;
  const { getUser } = useAuth();
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.customerAccess]: form } = forms;
  const methods = form?.form;

  const { data: customerPreference, isFetching } = useGetCustomerPreferenceQuery();

  useEffect(() => {
    !isFetching &&
      methods?.reset({
        access: customerPreference?.access,
        preference: preferenceType,
        companyID: getUser()?.companyID
      });
  }, [isFetching]);

  return (
    <PreferenceSkeleton isLoaded={!isFetching} template={activatedSetting}>
      <CustomerSettingHeader header={header} description={description} hasTooltip />
      <CustomerSettingBody methods={methods} paperContent={paperContent} isFetching={isFetching} />
    </PreferenceSkeleton>
  );
};

export default CustomerSetting;
