import { Box, HStack, Text } from '@chakra-ui/react';

import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import { SlzPaper } from 'components/common/SlzPaper';
import { ProductImage } from '.';
import { RETURN_DETAIL_PRODUCT_INFOR_STYLES } from 'modules/returns/constants/styles';
import { GRADING_COLOR_SCHEME, RETURN_DETAIL_TEXT } from 'modules/returns/constants';

const ProductInformationHeader = ({ text, ...rest }) => {
  return (
    <Text minW={48} fontSize="lg" fontWeight="bold" lineHeight={5.5} {...rest}>
      {text}
    </Text>
  );
};

const ReturnDetailProductInformation = ({ product, isShowHeader, onSelect, isActive, ...rest }) => {
  const warehouseImageSrc = product?.warehouseImage
    ? `data:image/jpeg;base64,${product?.warehouseImage}`
    : null;

  return (
    <SlzPaper
      {...rest}
      {...RETURN_DETAIL_PRODUCT_INFOR_STYLES.container(isShowHeader)}
      sx={isActive ? RETURN_DETAIL_PRODUCT_INFOR_STYLES.selectedProduct : null}
      onClick={() => onSelect(warehouseImageSrc)}>
      {isShowHeader && (
        <HStack {...RETURN_DETAIL_PRODUCT_INFOR_STYLES.header}>
          <ProductInformationHeader text={RETURN_DETAIL_TEXT.productInfor.productImage} />
          <ProductInformationHeader text={RETURN_DETAIL_TEXT.productInfor.fulfilmentCentrePhoto} />
          <ProductInformationHeader text={RETURN_DETAIL_TEXT.productInfor.returnDetail} pl={6} />
        </HStack>
      )}

      <HStack {...RETURN_DETAIL_PRODUCT_INFOR_STYLES.image(isShowHeader)}>
        <ProductImage minW={48} h={48} imageSrc={product?.productImage} />
        <ProductImage
          minW={48}
          h={48}
          bg="light.700"
          iconStyles={{ w: 6, h: 6 }}
          imageSrc={warehouseImageSrc}
        />

        <Box w="full" h="full" pt={5} pl={6}>
          <Text fontSize="md" fontWeight="bold" lineHeight={4.5}>
            {product?.itemName}
          </Text>
          <Text fontSize="xs" fontWeight="normal">
            {RETURN_DETAIL_TEXT.productInfor.sku}
          </Text>
          <Text fontSize="md" fontWeight="normal" lineHeight={4.5}>
            {product?.skucode}
          </Text>
          <Text fontSize="xs" fontWeight="normal" lineHeight="none" pt={6} pb={1.5}>
            {RETURN_DETAIL_TEXT.productInfor.grading}
          </Text>
          <SlzLabel colorScheme={GRADING_COLOR_SCHEME[product?.grade]}>
            {product?.grade} - {product?.action}
          </SlzLabel>
        </Box>
      </HStack>
    </SlzPaper>
  );
};

export default ReturnDetailProductInformation;
