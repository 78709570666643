import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { CheckSuccessPasswordCircleIcon } from 'components/common/Icons';
import { SlzSingleButton } from 'components/common/SlzButton';

const UploadImageForm = ({ productId, isUploading, isSucceed }) => (
  <Flex
    w="100%"
    h="100%"
    px="3px"
    justifyContent="center"
    backgroundColor="white"
    sx={{
      px: '3px',
      py: 0
    }}>
    {isSucceed ? (
      <VStack spacing="1px" backgroundColor="positive.300" w="95%" justifyContent="center">
        <Box padding="1px" borderRadius="50%" background="white">
          <CheckSuccessPasswordCircleIcon w="24px" h="24px" margin="1px" fill="#0F773C" />
        </Box>
        <Text fontSize="xs" lineHeight="0.688rem" color="dark.700">
          Success!
        </Text>
      </VStack>
    ) : (
      <VStack
        spacing="1px"
        backgroundColor="light.500"
        w="95%"
        id={productId}
        className={`water-fall-animation-container ${isUploading && 'water-fall-animation-active'}`}
        justifyContent="center">
        {!isUploading && (
          <Text fontSize="0.625rem" color="dark.700" lineHeight="0.625rem">
            Add image
          </Text>
        )}
        <Image
          w="1.5rem"
          h="1.5rem"
          loading="lazy"
          src={'/Images/no-image.svg'}
          alt=""
          srcSet={'/Images/no-image.svg'}
          fallbackSrc={'/Images/no-image.svg'}
        />
        {isUploading ? (
          <VStack spacing={0} fontSize="xs" lineHeight="0.688rem" color="dark.700">
            <Text>Uploading</Text>
            <Text>images</Text>
          </VStack>
        ) : (
          <SlzSingleButton
            icon={<AddIcon w="0.5rem" h="0.5rem" />}
            size="sm"
            w="0.75rem"
            h="0.75rem"
            minW="0.75rem"
          />
        )}
      </VStack>
    )}
  </Flex>
);

export default UploadImageForm;
