import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';
import { getColor, mode } from '@chakra-ui/theme-tools';
import { runIfFn } from '../utils/run-if-fn';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const $size = cssVar('checkbox-size');

const baseStyleControl = defineStyle((props) => {
  const { colorScheme: c, theme } = props;

  return {
    w: $size.reference,
    h: $size.reference,
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    fontSize: '1rem',
    border: '0.125rem solid',
    borderRadius: 'sm',
    borderColor: getColor(theme, `${c}.500`),
    color: getColor(theme, 'white'),
    opacity: 1,
    mt: 1,
    '.chakra-checkbox__control': {
      width: '100px',
      div: {
        width: '12px',
        height: '12px'
      }
    },
    _checked: {
      bg: getColor(theme, `${c}.500`),
      color: getColor(theme, `white`),
      _hover: {
        bg: getColor(theme, `${c}.700`),
        borderColor: getColor(theme, `${c}.700`)
      },
      _readOnly: {
        borderColor: getColor(theme, `${c}.300`),
        bg: getColor(theme, `${c}.300`),
        color: getColor(theme, `white`),
        opacity: 1
      }
    },
    _hover: {
      borderColor: getColor(theme, `${c}.700`)
    },
    _indeterminate: {
      bg: getColor(theme, `${c}.500`),
      borderColor: 'none',
      color: getColor(theme, 'white')
    },
    _focusVisible: {
      boxShadow: 'none'
    },
    _invalid: {
      borderColor: getColor(theme, `checkbox.invalid.500`),
      bg: getColor(theme, `checkbox.invalid.300`),
      _hover: {
        borderColor: getColor(theme, `checkbox.invalid.500`)
      },
      _readOnly: {
        bg: `none`,
        borderColor: getColor(theme, `dark.300`)
      },
      _checked: {
        bg: getColor(theme, `${c}.500`),
        color: getColor(theme, `white`)
      }
    },
    _readOnly: {
      bg: `none`,
      borderColor: getColor(theme, `dark.300`),
      _hover: {
        borderColor: `none`
      },
      _checked: {
        borderColor: getColor(theme, `${c}.300`),
        bg: getColor(theme, `${c}.300`),
        color: getColor(theme, 'white'),
        opacity: 1
      }
    },
    _disabled: {
      _hover: {
        border: 'none'
      }
    }
  };
});

const baseStyleContainer = defineStyle({
  lineHeight: 1.2,
  alignItems: 'flex-start',
  mr: 0,
  mb: 0,
  _disabled: { cursor: 'not-allowed' },
  _readOnly: { cursor: 'not-allowed' }
});

const baseStyleLabel = defineStyle({
  userSelect: 'none',
  ml: '0.5rem',
  lineHeight: 1.5,
  _disabled: {
    opacity: 1
  }
});

const baseStyleIcon = defineStyle({
  transitionProperty: 'transform',
  transitionDuration: 'normal'
});

const baseStyle = definePartsStyle((props) => ({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: runIfFn(baseStyleControl, props),
  label: baseStyleLabel
}));

const sizes = {
  sm: definePartsStyle({
    control: { [$size.variable]: 'sizes.4' },
    label: { fontSize: 'sm' },
    icon: { fontSize: '2xs' }
  }),
  md: definePartsStyle({
    control: { [$size.variable]: 'sizes.4' },
    label: { fontSize: 'lg' },
    icon: { fontSize: '2xs' }
  }),
  lg: definePartsStyle({
    control: { [$size.variable]: 'sizes.5' },
    label: { fontSize: 'lg' },
    icon: { fontSize: '2xs' }
  })
};

const variantDeliveryService = definePartsStyle((props) => {
  const { colorScheme: c } = props;
  return {
    icon: baseStyleIcon,
    container: {
      ...baseStyleContainer,
      padding: '6px 20px 6px 10px',
      m: 0,
      background: `light.300`,
      borderWidth: `1px`,
      borderStyle: 'solid',
      borderColor: `${c}.500`,
      borderRadius: '6px',
      _checked: {
        background: `${c}.300`
      }
    },
    control: runIfFn(baseStyleControl, props),
    label: {
      ...baseStyleLabel,
      textAlign: 'right',
      fontSize: '14px',
      w: '100%'
    }
  };
});

// Defining a custom variant
const variantCircular = definePartsStyle((props) => {
  const { colorScheme: c, theme } = props;

  return {
    control: defineStyle({
      rounded: 'full',
      display: 'none',
      bg: getColor(theme, 'light.500'),
      color: getColor(theme, 'dark.500'),
      mt: 0,
      _checked: {
        display: 'initial',
        _disabled: {
          color: getColor(theme, 'lightAndShadow.reverseText'),
          bg: getColor(theme, `${c}.500`),
          borderColor: getColor(theme, `${c}.500`)
        }
      },
      transform: 'scale(0.8)'
    }),
    container: defineStyle({
      _checked: {
        bg: getColor(theme, `${c}.300`),
        color: getColor(theme, `${c}.500`)
      },
      _disabled: {
        _checked: {
          bg: getColor(theme, `${c}.300`),
          color: getColor(theme, `${c}.500`)
        }
      }
    })
  };
});

const variants = {
  deliveryService: variantDeliveryService,
  circular: variantCircular
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    colorScheme: 'main'
  }
});
