import { Flex, Image, Text } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';

export const PRODUCT_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 81,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            h: 'full',
            maxH: '3.688rem',
            px: '3px',
            py: 0
          }}>
          <Image
            w="full"
            h="full"
            sx={rowProps?.accordionProps?.imageStyles}
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true
  },
  {
    name: 'SKU',
    field: 'sku',
    isSorted: true,
    isCopyable: true,
    render: ({ row, field, paddingValue, onClick }) => {
      return (
        <Flex px={paddingValue} justifyContent="center">
          <Text
            fontSize="sm"
            textAlign="center"
            bg="light.700"
            color="dark.700"
            noOfLines={1}
            borderRadius={0.75}
            px={3}
            _hover={{
              bg: '#C3C4CB'
            }}
            onClick={onClick}>
            {row[field] || 'No SKU'}
          </Text>
        </Flex>
      );
    }
  },
  {
    name: 'EAN/SUID',
    field: 'suid',
    isSorted: true,
    isCopyable: true,
    render: ({ row, paddingValue }) => {
      return (
        <Flex px={paddingValue} w="full" justifyContent="center">
          <Text fontSize={'sm'}>{row['ean'] || row['selazarUniqueID'] || 'N/A'}</Text>
        </Flex>
      );
    }
  },
  {
    name: 'Tag',
    field: 'tag',
    isEmphasis: true
  },
  {
    isSorted: true,
    field: 'totalStock',
    name: 'Stock',
    type: 'number'
  },
  {
    isSorted: false,
    field: 'createdDate',
    name: 'Date Created',
    type: 'date'
  },
  {
    name: '',
    field: 'cta',
    width: 110,
    type: COL_CUSTOM,
    isClickable: true,
    render: ({ row, variant, onClick }) => {
      return (
        <Flex>
          <SlzButton
            size="md"
            sx={{
              borderRadius: '3px',
              bg: 'main.500',
              color: 'light.500'
            }}
            onClick={onClick}>
            Add Stock
          </SlzButton>
        </Flex>
      );
    }
  }
];
