import { HStack } from '@chakra-ui/react';
import SlzFilter from '../SlzFilter/SlzFilter';
import SlzPagination from '../SlzPagination/SlzPagination';

const Toolbar = ({
  variant,
  size,
  showToolbar,
  showFilterTitle = true,
  filters,
  pagination,
  paginationProps = {},
  onFilter,
  height,
  padding,
  borderRadius,
  sx
}) => {
  return showToolbar ? (
    <HStack
      gap={5}
      role="slz-table-toolbar"
      as="div"
      display="inline-flex"
      justifyContent={filters?.options?.length > 0 ? 'space-between' : 'flex-end'}
      flexWrap={'wrap'}
      alignItems="center"
      borderRadius={borderRadius}
      borderTopLeftRadius="0.5rem"
      borderTopRightRadius="0.5rem"
      w="full"
      h={variant === 'productSelection' ? '30px' : height || 12}
      minH={variant === 'productSelection' ? '30px' : height || 12}
      bg="table.rowBg"
      mb="0.188rem"
      p={padding || 2.5}>
      {filters?.options?.length > 0 && (
        <SlzFilter
          ps="2.188rem"
          options={filters?.options}
          title={filters?.title}
          shouldSortOptions={filters.shouldSortOptions}
          defaultSelectedValues={filters?.defaultSelectedValues}
          showFilterTitle={showFilterTitle}
          onSelectOption={(option) => onFilter(option)}
          size={filters?.size}
          sx={sx || filters?.sx}
        />
      )}
      {pagination && <SlzPagination mr={2.5} variant={variant} size={size} {...paginationProps} />}
    </HStack>
  ) : (
    <></>
  );
};

export default Toolbar;
