import React, { useEffect, useState } from 'react';
import { GridItem, Heading, HStack, VStack, Icon, Card, Text, Box, Image, Flex, ButtonGroup, Button, Spacer, useToast } from '@chakra-ui/react';
import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { useHistory, useParams } from 'react-router-dom';
import { CONSIGNMENTS_PATHNAME } from '../constants';
import { FaChevronLeft } from 'react-icons/fa';
import ConsignmentRowDetail from '../components/other/ConsignmentRowDetail';
import { transformConsignmentDetail } from '../mappers/consignments-mapper';
import { useConsignmentDetailQuery } from '../hooks/useConsignmentDetailQuery';
import dayjs from 'dayjs';
import { DATE_FORMAT_DDMMYYYY_HH_MM_SS_2 } from 'constants';
import { SlzRadioToggle } from 'components/common/SlzRadioToggle';
import { SlzButton } from 'components/common/SlzButton';
import useSlzToast from 'hooks/useSlzToast';
import ConfirmToast from 'modules/address-book/components/other/ConfirmToast';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NestedTextarea } from 'components/common/SlzForms/base/NestedTextarea';
import { SlzTextarea } from 'components/common/SlzTextarea';
import { useMutation } from 'react-query';
import { createQualityReportResponse } from '../services/onHold-api';
import SlzToast from 'components/common/SlzToast/SlzToast';

const BREADCRUMBS = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/inventory',
    title: 'Inventory'
  },
  {
    path: '/inventory/consignments',
    title: 'Consignments'
  }
];

const TOGGLE_OPTIONS = [
  {
    key: '0',
    value: 'Approve stock'
  },
  {
    key: '1',
    value: 'Do not approve stock'
  }
];

const formValues = {
  resolutionNotes: ''
};

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const STYLE_SUCCESS_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

const DELETE_CONFIRMATION = {
  message: 'Oops! This stock will remain on hold until a quality check is completed Are you sure you want to leave?',
  confirmText: 'Leave',
  cancelText: 'Continue',
  cancelProps: {
    color: 'sunshine.500',
    bg: 'dark.700',
    colorScheme: 'dark'
  },
  confirmProps: {
    variant: 'outline',
    borderColor: 'dark.700',
    colorScheme: 'dark'
  }
};

const DEFAULT_TOGGLE_SELECTED = '0';

const ConsignmentOnHoldDetail = () => {
  const [_, dispatch] = useGlobalState();
  const { consignmentId } = useParams();
  const history = useHistory();
  const { data, isLoading, isSuccess, isError } = useConsignmentDetailQuery(
    consignmentId,
    (data) => transformConsignmentDetail(data)
  );
  
  const [selectedToggleOption, setSelectedToggleOption] = useState(DEFAULT_TOGGLE_SELECTED);
  const [optional, setToast, toast] = useSlzToast();
  const basicToast = useToast();

  const qualityReportResponseMutation = useMutation((response) => createQualityReportResponse(response));

  useEffect(() => {
    if (qualityReportResponseMutation.isSuccess) {
      basicToast({
        isClosable: true,
        render: ({ onClose }) => (
          <SlzToast
            colorScheme="positive"
            title= {selectedToggleOption == 1 ? "Stock record successfully uploaded." : `The consignment has been marked as approved. The warehouse will be notified and this consignment will be processed as normal. If you have any questions please contact us by emailing customer.support@selazar.com`}
            status="success"
            size="lg"
            variant="solid"
            onClose={onClose}
          />
        )
      });
      history.push(`${CONSIGNMENTS_PATHNAME}?process=onHold`);
    }
  }, [qualityReportResponseMutation.isSuccess]);

  useEffect (() => {
    setSelectedToggleOption(data?.consignmentInfoDetails?.quarantineResolution === "No Decision Made" ? DEFAULT_TOGGLE_SELECTED : data?.consignmentInfoDetails?.quarantineResolution === "Not Approved" ? "1" : "0")
  }, [data?.consignmentInfoDetails?.quarantineResolution]);

  const onHoldDetailSchema = yup.object().shape({
    resolutionNotes: yup.string().test('selected-toggle-option', '*Please tell us what to do with your stock', function (value) {
      const { path, createError } = this;
      if (selectedToggleOption === '1' && !value) {
        return createError({
          path,
          message: '*Please tell us what to do with your stock'
        });
      }
      return true;
    })
  });

  const methods = useForm({
    defaultValues: formValues,
    resolver: yupResolver(onHoldDetailSchema)
  });

  useEffect(() => {
    let breadcrumbs = [...BREADCRUMBS];

    if (isSuccess) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          title: data?.consignmentInfoDetails?.fileName
        }
      ];
    }

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadcrumbs
    });
  }, [isSuccess]);

  const onSubmit = async (submitValues) => {
    const dataFormValues = {
      qualityReportID: data?.qualityReportInformation?.id,
      resolution: parseInt(selectedToggleOption),
      ...submitValues
    }
    qualityReportResponseMutation.mutate(dataFormValues);
  };

  const leaveToast = () => {
    history.push(`${CONSIGNMENTS_PATHNAME}?process=onHold`)
    toast.closeAll()
  }

  const openToast = () => {
    setToast({
      chakraToastProps: {
        duration: null
      },
      ...STYLE_WARNING_TOAST,
      render: () => (
        <ConfirmToast
          {...DELETE_CONFIRMATION}
          onConfirmed={leaveToast}
          onCancel={toast.closeAll}
        />
      )
    });
  }

  return (
    <SlzGrid templateAreas={`
        "header header"
        "nav main"
      `}
    >
      <GridItem area={'header'} colSpan="12">
        <HStack
          alignItems="flex-start"
          cursor="pointer"
          display="inline-flex"
          mb={2}
          onClick={() => history.push(`${CONSIGNMENTS_PATHNAME}?process=onHold`)}>
          <Icon as={FaChevronLeft} fontSize="lg" color="dark.500" mt="1" />
          <Heading as="h4" fontSize="1.0625rem">
            Back to consignments
          </Heading>
        </HStack>

        <ConsignmentRowDetail
          consignmentDetail={data}
          isLoading={isLoading}
          w="100%"
          h="6rem"
          bg="light.500"
        />
      </GridItem>
      <GridItem area={'nav'} colSpan={5}>
        <VStack spacing={'0.75rem'} >
          <Card
            bg="light.500"
            variant="filled"
            sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
              <Text
                pl={'1.5rem'}
                sx={{
                  textAlign: 'left',
                  lineHeight: '20px',
                  letterSpacing: '-0.14px',
                  fontSize: 'xl',
                  color: 'table.mainText'
                }}>
                <b>Quality report</b>
              </Text>
          </Card>
          <Box w={'100%'}>
            <Card
              bg="light.500"
              variant="filled"
              sx={{ width: '100%', height: '1.875rem', display: 'flex', justifyContent: 'center' }}>
                <Text
                  pl={'1.5rem'}
                  sx={{
                    textAlign: 'left',
                    lineHeight: '20px',
                    fontSize: 'lg',
                    color: 'table.mainText'
                  }}>
                  <b>Warehouse evidence</b>
                </Text>
            </Card>
            <Image src={data?.qualityReportInformation?.photoLink} mt={'0.175rem'} />
          </Box>
        </VStack>
      </GridItem>
      <GridItem area={'main'} colSpan={5}>
        <VStack spacing={'0.75rem'}>
          <Box w={'100%'}>
            <Card
              bg="light.500"
              variant="filled"
              sx={{ width: '100%', height: '1.875rem', display: 'flex', justifyContent: 'center' }}>
                <Text
                  pl={'1.5rem'}
                  sx={{
                    textAlign: 'left',
                    lineHeight: '20px',
                    fontSize: 'lg',
                    color: 'table.mainText'
                  }}>
                  <b>On hold date & time</b>
                </Text>
            </Card>
            <Text
              pl={'1.5rem'}
              mt={'0.175rem'}
            >
              {dayjs(data?.qualityReportInformation?.createdDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS_2)}
            </Text>
          </Box>
          <Box w={'100%'}>
            <Card
              bg="light.500"
              variant="filled"
              sx={{ width: '100%', height: '1.875rem', display: 'flex', justifyContent: 'center' }}>
                <Text
                  pl={'1.5rem'}
                  sx={{
                    textAlign: 'left',
                    lineHeight: '20px',
                    fontSize: 'lg',
                    color: 'table.mainText'
                  }}>
                  <b>Reason</b>
                </Text>
            </Card>
            <Card
              mt={'0.175rem'}
              sx={{ width: '100%', height: '13.125rem', display: 'flex', justifyContent: 'left' }}
            >
              <Text
                pl={'1.5rem'}
                pt={'1rem'}
              >
                {data?.qualityReportInformation?.notes}
              </Text>
            </Card>
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onSubmit)} style={{width: '100%'}}>
              <Box w={'100%'}>
                <Card
                  bg="light.500"
                  variant="filled"
                  sx={{ width: '100%', height: '1.875rem', display: 'flex', justifyContent: 'center' }}>
                    <Text
                      pl={'1.5rem'}
                      sx={{
                        textAlign: 'left',
                        lineHeight: '20px',
                        fontSize: 'lg',
                        color: 'table.mainText'
                      }}>
                      <b>Do you approve this stock to be processed for fulfilment?</b>
                    </Text>
                </Card>
                <SlzRadioToggle readOnly={data?.consignmentInfoDetails?.quarantineResolution === "Not Approved"} mt={'0.175rem'} name={'approve'} options={TOGGLE_OPTIONS} defaultValue={selectedToggleOption} onChange={(e) => setSelectedToggleOption(e)} size="md" />
              </Box>
              <Box w={'100%'} mt={'1.875rem'}>
                <Card
                  bg="light.500"
                  variant="filled"
                  sx={{ width: '100%', height: '1.875rem', display: 'flex', justifyContent: 'center' }}>
                    <Text
                      pl={'1.5rem'}
                      sx={{
                        textAlign: 'left',
                        lineHeight: '20px',
                        fontSize: 'lg',
                        color: 'table.mainText'
                      }}>
                      {selectedToggleOption === '0' ? <span><b>Comments</b> - optional</span> : <span><b>What should we do with your stock?</b></span> }
                    </Text>
                </Card>
                
                  <NestedTextarea
                    mt={'0.175rem'}
                    sx={{ width: '100%', display: 'flex', justifyContent: 'left' }}
                    field="resolutionNotes"
                    showTooltip={false}
                    fieldProps={{
                      isDisabled: data?.consignmentInfoDetails?.quarantineResolution === "Not Approved",
                      defaultValue: data?.qualityReportResponse?.resolutionNotes,
                      value: data?.qualityReportResponse?.resolutionNotes,
                      pl:'1.5rem',
                      pt:'1rem',
                      placeholder:selectedToggleOption === '0' ? 'Leave a comment for the attention of the warehouse staff' : 'We can assist in the disposal of this stock on your behalf, or you have the option to arrange for its return.',
                      resize:'none',
                      rows:10
                    }}
                  />
                
              </Box>
              <Flex w={'100%'} alignItems='right'>
                <Spacer/>
                {data?.consignmentInfoDetails?.quarantineResolution === "Not Approved" 
                  ? 
                  <SlzButton mt={'0.75rem'} size={'lg'} onClick={() => history.push(`${CONSIGNMENTS_PATHNAME}?process=onHold`)}>Cancel</SlzButton> 
                  : 
                  <ButtonGroup gap='2' mt={'0.75rem'}>
                    <SlzButton size={'lg'} variant={'outline'} colorScheme="negative" onClick={openToast}>Cancel</SlzButton>
                    <SlzButton size={'lg'} type="submit">Confirm</SlzButton>
                  </ButtonGroup>}
              </Flex>
            </form>
          </FormProvider>
        </VStack>
      </GridItem>
    </SlzGrid>
  );
};
export default ConsignmentOnHoldDetail;
