import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { SlzToggle } from 'components/common/SlzToggle';
import { ControlledField } from 'components/form';
import { DISABLE_CONDITION } from 'modules/b2b-orders/constants';

const Toggle = ({ value, ...rest }) => {
  return <SlzToggle isChecked={value} {...rest} />;
};

const OrderOptionToggle = ({ toggle }) => {
  const { watch } = useFormContext();

  return (
    <Box w="auto">
      <ControlledField
        name={toggle.key}
        isDisabled={DISABLE_CONDITION[toggle.key](watch)}
        component={Toggle}
        mb="0.125rem"
      />
    </Box>
  );
};

export default OrderOptionToggle;
