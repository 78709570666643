import { useQuery } from 'react-query';
import { getBulkOrderLimit } from '../services/bulk-orders-api';

export const useBulkOrderLimitQuery = (select) => {
  return useQuery({
    queryKey: [`bulk-order-limits`],
    queryFn: () => getBulkOrderLimit(),
    cacheTime: 60 * 1000,
    select: select
  });
};
