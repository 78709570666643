import { HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import SlzDateRangePicker from '../../../../components/common/SlzDateRangePicker';
import { SlzAutocompleteInput } from '../../../../components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from '../../../../constants';
import { CONSIGNMENT_PROCESS } from '../../constants';
import { transformConsignments } from '../../mappers/consignments-mapper';
import { getConsignments, checkIfUsesExternalWMS } from '../../services/consignments-api';
import { CONSIGNMENTS_COLUMNS } from '../table/columns';
import map from 'lodash/map';
import { SlzButton } from 'components/common/SlzButton';
import { useHistory } from 'react-router-dom';
import { defaultGuid } from 'Utilities';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'constants/table.js';

const ConsignmentsFilter = (props) => {
  const { onSearch, initSearchValue, transformFn } = props;
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
  const [foundConsignments, setFoundConsignments] = useState([]);
  const [displayFields, setDisplayFields] = useState([]);
  const [usesExternalWMS, setUsesExternalWMS] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkUsesExternalWMS();

    let displayFieldsByTab = [];
    switch (props?.process) {
      case CONSIGNMENT_PROCESS.ON_HOLD:
        displayFieldsByTab = map(CONSIGNMENTS_COLUMNS, 'field');
        break;
      default:
        displayFieldsByTab = map(CONSIGNMENTS_COLUMNS, 'field');
        break;
    }
    setDisplayFields(displayFieldsByTab);
    setFoundConsignments([]);
  }, [props?.process]);

  const checkUsesExternalWMS = async () => {
    try {
      const response = await checkIfUsesExternalWMS();
      setUsesExternalWMS(response);
    }  catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAutocompleteSearch = async (searchTerm) => {
    setSearchParams({
      ...searchParams,
      process: props?.process
    });

    switch (props?.process) {
      case CONSIGNMENT_PROCESS.ON_HOLD:
        setSearchParams({
          ...searchParams,
          searchText: searchTerm,
        });
        break;
      default:
        setSearchParams({
          ...searchParams,
          searchText: searchTerm,
        });
        break;
    }
    
    try {
      const data = await getConsignments(searchParams);
      const itemsFound = transformConsignments(data).items;

      if (itemsFound?.length > 0) {
        setFoundConsignments(itemsFound);
        setUsesExternalWMS(itemsFound.every(x => x.id === defaultGuid));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <HStack mb={6} gap={4}>
        <SlzAutocompleteInput
          width="20%"
          variant="accent"
          items={foundConsignments}
          showedFields={displayFields}
          searchBy={[displayFields[0]]}
          placeholder="Search"
          onTypingSearch={handleAutocompleteSearch}
          onNavigate={(record) => props?.handleOpenModal(record)}
          onSearch={onSearch}
          initValue={initSearchValue}
          searchParams={searchParams}
          transformFn={transformFn}
          isLocal={false}
        />

        <SlzDateRangePicker
          onDateRangeClick={(startDate, endDate) => props?.handleSelectedDateRange(startDate, endDate) }
          startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
          endDatePlaceHolder={TODAY}
          endDate={dayjs()}
        />
        <SlzButton onClick={() => history.push({search: '?isSendConsignmentModal=true'})} padding={"1.5rem"} isDisabled={usesExternalWMS}>Send Inventory</SlzButton>
      </HStack>
    </Fragment>
  );
};

export default ConsignmentsFilter;
