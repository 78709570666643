import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useMultiStyleConfig,
  Grid,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  GridItem,
  Box,
  Text,
  chakra,
  Center,
  useDisclosure,
  useOutsideClick,
  InputLeftElement
} from '@chakra-ui/react';
import colors from '../../../theme/newdesign/foundations/themeColors';
import React, { useRef } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';
import { SlzChip } from '../SlzChip';
import { TAG_DEFAULT_COLOR, SEL_PREFIX_ID } from '../Constants';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import slice from 'lodash/slice';
import filter from 'lodash/filter';
import lowerCase from 'lodash/lowerCase';
import uniqueId from 'lodash/uniqueId';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';

import { useState, useCallback, useEffect } from 'react';
import { defaultGuid } from 'Utilities';
import { SlzTag } from '../SlzTag';
import { SlzCustomBox } from '../SlzCustomBox';

const MIN_START_SEARCH = 1;
const ROTATION_0_DEG = 'rotate(0deg)';
const ROTATION_180_DEG = 'rotate(180deg)';
const CREATE_NEW_TAG_TEXT = ' (Create new tag)';

const SlzCustomTag = (props) => {
  const {
    items = [],
    isLocal = true,
    setColorTag,
    sensitive,
    field,
    minStartSearch = MIN_START_SEARCH,
    searchBy = [
      'text'
    ],
    onTypingSearch
  } = props;
  const { register, setValue, getValues } = useFormContext();
  const styles = useMultiStyleConfig('SlzCustomTag', { size: props.size });
  const [rotation, setRotation] = useState(ROTATION_0_DEG);
  const [isActive, setIsActive] = useState(false);
  const [boldField, setBoldField] = useState();
  const [selectedTag, setSelectedTag] = useState(getValues(`${field}.colorCode`) || TAG_DEFAULT_COLOR);
  const [searchTerm, setSearchTerm] = useState('');
  const [resultsTest, setResultsTest] = useState([]);
  const [validSelected, setValidSelected] = useState(false);
  const [newTemporalTag, setNewTemporalTag] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const ref = useRef();

  useEffect(() => {
    setColorTag && selectedTag !== '' && setColorTag(selectedTag);
  }, [selectedTag]);

  useOutsideClick({
    ref: ref,
    handler: () => onClose()
  });

  const getButtonStyle = () => {
    if (isActive) {
      return styles.buttonActive;
    }
    return styles.button;
  };

  const verifyNewTag = (searchTermInput, resultsTest = []) => {
    const currentColor = getValues(`${field}.colorCode`)
    if (!resultsTest.some(x => x.text == searchTermInput && x.colorCode == currentColor)) {
      return {
        id: defaultGuid,
        text: `${searchTermInput}${CREATE_NEW_TAG_TEXT}`,
        colorCode: currentColor
      }
    }
  }

  const open = () => {
    setRotation(ROTATION_180_DEG);
    setIsActive(true);
  };

  const close = () => {
    setRotation(ROTATION_0_DEG);
    setIsActive(false);
  };

  const handleSelectOption = useCallback((tag) => {
    setSelectedTag(tag);
    setValue(`${field}.colorCode`, tag);
  }, []);

  useEffect(() => {
    if (isLocal) {
      setResultsTest(items);
    }
  }, [items]);

  const handleInputChange = (searchTermInput) => {
    setSearchTerm(searchTermInput);
    if (size(searchTermInput) < minStartSearch) {
      setResultsTest(items);
      onOpen();
      return;
    }

    onTypingSearch && onTypingSearch(searchTermInput);

    if (!isLocal) return;
    if (size(searchBy) > 0) {
      let resultsTest = doFilter(items, {
        keyword: searchTermInput,
        sensitive,
        searchBy,
        onFound: setBoldField
      });
      
      const newVerified = verifyNewTag(searchTermInput, resultsTest);
      if (Object.keys(newVerified).length > 0){
        resultsTest.push(newVerified);
        setNewTemporalTag(newVerified);
      } else {
        setNewTemporalTag({});
      }
      setResultsTest(resultsTest);
      size(resultsTest) > 0 ? onOpen() : onClose();
    }
  }

  const handleSelectedTag = (tag) => {
    tag.text = tag.text.replace(CREATE_NEW_TAG_TEXT, '')
    setSelectedTag(tag.colorCode);
    setValue(`${field}`, tag);
    setValidSelected(true);
    onClose();
  }

  const handleRemoveTag = () => {    
    setValue(`${field}.text`, '');
    setValue(`${field}.id`, defaultGuid);
    setValidSelected(false);
    if (Object.keys(newTemporalTag).length > 0) {
      setResultsTest(items);
      setNewTemporalTag({});
    }
    onOpen();
  }

  const onChange = (e) => {
    setSearchTerm(e.target.value);
    handleInputChange(e.target.value);
  };

  function doFilter(items, { keyword, sensitive = false, searchBy = [], onFound }) {
    return filter(items, (item) => {
      for (const field of searchBy) {
        const text = JSON.stringify(
          get(item, field)
        );
  
        if (sensitive && includes(text, keyword)) {
          onFound(field);
          return item;
        }
  
        if (includes(lowerCase(text), lowerCase(keyword))) {
          onFound(field);
          return item;
        }
      }
    });
  }

  const stylesChipContainer = {
    background: 'light.300',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 1,
      cursor: 'not-allowed',
      color: 'dark.300',
      bg: 'light.500',
      border: 'none'
    },
    _placeholder: {
      color: 'dark.500',
      opacity: 1
    },
    margin: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    border: '1px solid black',
    borderColor: 'stone.500',
    borderRadius: '0.1875rem'
  }

  const tagStyles = {
    color: 'dark.500',
    bgColor: 'light.700',
    boxShadow: 'none',
    _hover: {
      color: 'main.500',
      bg: 'rgba(9, 115, 234, 0.16)'
    }
  }

  const tagIconStyles = {
    color: 'dark.500',
    _hover: {
      color: 'main.500',
    }
  }

  function SearchDropdown({
    results,
    boldField,
  }) {
    return (
      <Grid
        data-testid="table-results"
        pos="absolute"
        zIndex={10}
        top="110%"
        left="0"
        w="full"
        p="1.5"
        bgColor="white"
        rounded="0.375rem"
        boxShadow="0 3px 6px #00000067">
        {results.length > 0 && (results.length !== 1 && !(results.length === 1 && results.some(x=> x.text.includes(CREATE_NEW_TAG_TEXT)))) && <Box ml='11px' color='dark.500' fontSize='md' fontWeight='medium'>Existing custom tags</Box>}
        {results.map((tag) => (
          <GridItem
            w="100%"
            key={`slz-customTagResult-${tag.id}`}
            data-testid={`slz-customTagResult-${tag.id}`}
            _hover={{
              bg: 'main.300',
              cursor: 'pointer'
            }}
            onClick={() => handleSelectedTag(tag)}
          >
            <Flex alignItems='center'>
                <Box w='5px' h='28px' bg={tag.colorCode}>&nbsp;</Box>
                <Box ml='6px' h='100%' fontSize='md' color='dark.700' fontWeight={field === boldField && 'medium'} >{tag.text}</Box>
            </Flex>
          </GridItem>
        ))}
        {}
      </Grid>
    );
  }

  return (
    <Box id={uniqueId('SEL-CustomTag-box')} ref={ref} pos="relative" w={props?.width}>
      <InputGroup w={props?.w || props?.width || 'full'} size={props?.size || 'sm'} zIndex="tag">
        <Input
          hidden
          {...register(`${field}.colorCode`)}
          id={uniqueId(`${SEL_PREFIX_ID}-Slz-CustomTag-Color`)}
          data-testid={`slz-customTag-color`}
        />
        {validSelected && 
          <SlzCustomBox
            size={'sm'}
            {...props}
            sx={stylesChipContainer}
            display='flex'
            alignItems='center'
            bgColor='light.300'
          >
            <SlzTag ml='0.763rem' closable={true} onClose={() => handleRemoveTag()} variant="outline" customTagStyles={tagStyles} customTagIconStyles={tagIconStyles} >{getValues(`${field}.text`)}</SlzTag>
          </SlzCustomBox>
        }
        {!validSelected && <Input
          w="full"
          sx={{
            background: 'light.300'
          }}
          {...register(`${field}.text`)}
          id={uniqueId(`${SEL_PREFIX_ID}-Slz-CustomTag-Name`)}
          data-testid={`slz-customTag-name`}
          {...props}
          onChange={debounce(onChange, 300)}
          onFocus={onOpen}
          autoComplete='off'
        />}
        <InputRightElement
          sx={styles.inputRightElementWidth}
          mr={0.5}
          data-testid={`slz-customTag-popover`}
          children={
            <Popover placement="bottom-end" onOpen={() => open()} onClose={() => close()} isLazy>
              <PopoverTrigger>
                <Button sx={getButtonStyle()} data-testid={`slz-customTag-button`}>
                  <Flex
                    p={0.25}
                    style={{
                      borderColor: 'dark.300',
                      borderStyle: 'solid',
                      borderWidth: 0.25,
                      borderRadius: 3,
                      opacity: 1
                    }}>
                    <SlzChip
                    sx={styles.slzChip}
                    mr={0.25}
                    variant="solid"
                    bg={selectedTag}
                    px={2.5}></SlzChip>
                    <ChevronDownIcon
                      sx={styles.chevronDownIcon}
                      transitionDuration="0.3s"
                      transform={rotation}
                      background={isActive ? 'main.300' : 'light.700'}
                      borderRadius={1}
                      data-testid={`slz-customTag-color-icon`}
                    />
                  </Flex>
                </Button>
              </PopoverTrigger>
              <PopoverContent sx={styles.optionContainer}>
                <Grid templateColumns="repeat(5, 1fr)" gap={0.25}>
                  {Object.keys(colors).map((color, index) => (
                    <GridItem
                      _active={{
                        background: 'main.300',
                        borderRadius: '0.188rem'
                      }}
                      w="100%"
                      key={`slz-customTag-color-${index}`}
                      data-testid={`slz-customTag-color-${color}`}>
                      <SlzChip
                        size="sm"
                        variant="solid"
                        h={5}
                        padding={'0rem 0.625rem'}
                        m={0.75}
                        colorScheme={color}
                        onClick={() => handleSelectOption(colors[color][500])}></SlzChip>
                    </GridItem>
                  ))}
                </Grid>
              </PopoverContent>
            </Popover>
          }
        />
      </InputGroup>
      {isOpen && size(resultsTest) > 0 && (
        <SearchDropdown
          boldField={boldField}
          results={resultsTest}
        />
      )}
    </Box>
  );
};

export default SlzCustomTag;
