import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useMultiStyleConfig
} from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';

const SlzGridModal = (props) => {
  const { children, variant, accentColor, showClose = true, hasOverlay = true, ...rest } = props;
  const styles = useMultiStyleConfig('SlzModal', { variant, accentColor });

  return (
    <Modal id={uniqueId('SEL-Slz-Modal')} {...rest}>
      {hasOverlay && <ModalOverlay data-testid="overlay" sx={styles.overlay} />}
      <ModalContent data-testid="content" sx={styles.content} w="995px" maxW="995px">
        {showClose && (
          <ModalCloseButton data-testid="button-close" sx={styles.closeButton}>
            <IoClose size="1.625rem" />
          </ModalCloseButton>
        )}
        <ModalBody
          sx={{
            ...styles.body,
            px: 0,
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            columnGap: '12px'
          }}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SlzGridModal;

SlzGridModal.propTypes = {
  variant: PropTypes.oneOf(['standard', 'product', 'userSetting']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};
