import { Box, HStack, VStack } from '@chakra-ui/react';
import { BaseInput as BaseImageField } from '../common/BaseInput';
import { BaseUploadImage } from '../common/BaseUploadImage';

const AddImageField = ({ name, errors, item, control, onLoadImageFile, index }) => {
  return (
    <HStack
      bg="light.500"
      borderRadius="16px"
      border="1px solid #DEDEDE"
      p={3}
      pt={1}
      gap={2}
      w="100%">
      <BaseUploadImage
        onLoadFile={onLoadImageFile}
        field={`products.${index}.imageUrl`}
        control={control}
        borderColor={errors[name] && errors[name][index] ? 'negative.500' : 'stone.500'}
      />
      <VStack flex={2} gap={0}>
        <HStack gap={1} w="full">
          <BaseImageField control={control} field={`products.${index}.name`} label="Product" />
          <BaseImageField field={`products.${index}.skuCode`} control={control} label="SKU" />
        </HStack>
        <BaseImageField
          w="full"
          control={control}
          field={`products.${index}.description`}
          label="Description"
        />
      </VStack>
    </HStack>
  );
};

export default AddImageField;
