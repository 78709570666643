const {
  STATISTICS_STATUS_DISPLAY,
  STATISTICS_STATUS,
  ORDERS_TODAY_LABEL
} = require('../constants');

export const getStatisticsStatus = (status) =>
  STATISTICS_STATUS_DISPLAY[status] || STATISTICS_STATUS_DISPLAY[STATISTICS_STATUS.NO_CHANGE];

export const formatNumber = (val) => val.toLocaleString('en-US', { minimumFractionDigits: 0 });

export const transformOrdersToday = (data) => {
  const ordersToday = data?.data?.data;
  if (!ordersToday) return [];
  return ordersToday?.map((orderToday, index) => {
    orderToday.name = orderToday.status || orderToday.name;
    orderToday.count = orderToday.count;
    orderToday.color = ORDERS_TODAY_LABEL[index].color;
    delete orderToday.status;
    return orderToday;
  });
};

export const shouldAddBarRadius = (chartData = [], idx) => {
  let sum = 0;
  for (let i = idx + 1; i < chartData.length; i++) {
    sum = sum + (chartData[i]?.value || 0);
  }

  return sum === 0;
};

export const isLineChartNoData = (chartData = []) => {
  return chartData.every((chart) => chart?.total === 0);
};
