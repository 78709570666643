import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { Skeleton } from 'components/common/Skeleton/Skeleton';

const OrderCustomerDetails = (props) => {
  const { customer, isLoading } = props;

  const getAddress = () => {
    const address = [
      customer?.addressLine1,
      customer?.addressLine2,
      customer?.addressTown,
      customer?.country,
      customer?.postcode
    ];
    return address.join(', ');
  };

  return (
    <SlzPaper
      w={props.w}
      h={props.h}
      minH="15.625rem"
      borderRadius="0.375rem"
      bg="light.500"
      overflow="hidden">
      <Skeleton isLoaded={!isLoading} width="100%" height="100%">
        <VStack color="black">
          <Box w="100%" padding="0.875rem 2.25rem 0.438rem" fontWeight="bold" fontSize="1rem">
            {props.title}
          </Box>
          <Divider mt="0 !important" bg="white" h="0.188rem" borderBottom="0" />
          <Box w="100%" padding="1rem 1rem 0rem 2.25rem">
            <HStack spacing="2rem">
              <Box alignSelf="flex-start">{props.iconTop}</Box>
              <Box w="60%" fontSize="0.875rem">
                <Text>{customer?.personName}</Text>
                <Text>{customer?.email}</Text>
                <Text>{customer?.phone}</Text>
              </Box>
            </HStack>
          </Box>
          <Box w="100%" padding="1rem 1rem 1.625rem 2.25rem">
            <HStack spacing="2rem">
              <Box alignSelf="flex-start">{props.iconBottom}</Box>
              <Box w="60%" fontSize="0.875rem">
                <Text>{getAddress()}</Text>
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Skeleton>
    </SlzPaper>
  );
};

export default OrderCustomerDetails;
