import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import { DotIcon } from 'components/common/Icons';
import SlzCTA from 'components/common/SlzCTA/SlzCTA';
import FormWrapper from 'components/common/SlzForms/FormWrapper';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

import { DefaultShipping, RateMatcherFormActions } from 'modules/integrations/components/form';
import {
  RateMatcherCTAText,
  RateMatcherShippingOption,
  StepOneCTAButtons
} from 'modules/integrations/components/other';
import { RATE_MATCHER_CTA_TEXT, RATE_MATCHER_STEP_1, PREFIX } from 'modules/integrations/constants';
import { RateMatchingState } from 'modules/integrations/components/other/RateMatcherShippingOption';

let validationSchema = yup.object().shape({
  ukDefaultShipping: yup.object().required(),
  euDefaultShipping: yup.object().required(),
  restofworldDefaultShipping: yup.object().required()
});

const RateMatcherStepOne = ({
  shopName,
  isEditMode,
  defaultValues,
  numberOfShippingRate,
  fetchedServiceTimes,
  getUniqueServiceKeys,
  onSubmit,
  ...rest
}) => {
  return (
    <FormWrapper defaultValues={defaultValues} validationSchema={validationSchema}>
      {({ methods, errors }) => (
        <FormProvider {...methods}>
          <form>
            <IntegrationSkeleton
              isLoaded={fetchedServiceTimes !== undefined}
              template={`${PREFIX}.${rest?.currentStep}`}>
              <RateMatcherShippingOption shopName={shopName}>
                <RateMatchingState
                  numberOfMatchedRate={rest?.numberOfMatchedRate}
                  numberOfShippingRate={numberOfShippingRate}
                />
              </RateMatcherShippingOption>
              <Flex alignItems="baseline" mt="44px" mb="5" maxW="36.25rem">
                <DotIcon w="3" h="3" color="main.500" mr="8px" />
                <Text fontSize="1.25rem" fontWeight="bold">
                  {RATE_MATCHER_STEP_1.header}
                </Text>
              </Flex>
              <HStack>
                {fetchedServiceTimes &&
                  fetchedServiceTimes.map((serviceTime, index) => {
                    const regionKey = `${serviceTime.countryRegion.toLowerCase()}DefaultShipping`;
                    const defaultItem = {
                      shippingRateID: null,
                      countryRegion: serviceTime.countryRegion,
                      serviceTime: serviceTime.serviceTime,
                      shippingRatePrice: '',
                      shippingRateName: null
                    };
                    const regionPreference =
                      isEditMode && defaultValues[regionKey]
                        ? defaultValues[regionKey].value
                        : defaultItem;
                    if (regionPreference) {
                      return (
                        <DefaultShipping
                          index={index}
                          isEditMode={isEditMode}
                          key={`${serviceTime.countryRegion}-${index}`}
                          serviceTime={serviceTime}
                          regionPreference={regionPreference}
                          getUniqueServiceKeys={getUniqueServiceKeys}
                        />
                      );
                    }
                  })}
              </HStack>
              <Box mt="29px" w="100%" minHeight="169px">
                {!isEmpty(errors) && (
                  <SlzCTA
                    width="827px"
                    size="md"
                    variant="block"
                    colorScheme="red"
                    buttons={
                      <StepOneCTAButtons
                        onSubmit={methods?.handleSubmit(onSubmit)}
                        onNextStep={rest?.onNextStep}
                        clearErrors={methods?.clearErrors}
                      />
                    }
                    onClose={() => methods?.clearErrors()}>
                    <RateMatcherCTAText
                      title={RATE_MATCHER_CTA_TEXT.step1.title}
                      description={RATE_MATCHER_CTA_TEXT.step1.description}
                    />
                  </SlzCTA>
                )}
              </Box>
            </IntegrationSkeleton>

            <RateMatcherFormActions {...rest} />
          </form>
        </FormProvider>
      )}
    </FormWrapper>
  );
};

export default RateMatcherStepOne;
