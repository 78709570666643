import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Heading } from '@chakra-ui/react';

import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import { B2B_BOX_SPLIT_TEXT, BOX_SPLIT } from 'modules/b2b-orders/constants';
import { B2B_BOX_SPLIT_STYLE } from 'modules/b2b-orders/constants/styles';
import { B2BOrderAction, B2BOrderOption } from '.';
import useValidateNoPackingMutation from 'modules/b2b-orders/hooks/useValidateNoPackingMutation';
import useValidateSkuSplitMutation from 'modules/b2b-orders/hooks/useValidateSkuSplitMutation';

const B2BDisabledBoxSplit = ({ currentStep, defaultValues, onPrevious, onCancel, ...rest }) => {
  const { setValue } = useFormContext();
  const validateNoPackingMutation = useValidateNoPackingMutation();
  const validateSkuSplitMutation = useValidateSkuSplitMutation();

  const validateNoPacking = async () => {
    const payload = {
      items: defaultValues.stockSelection
    };

    try {
      const response = await validateNoPackingMutation.mutateAsync(payload);
      const boxes = response?.boxes;
      setValue(BOX_SPLIT, [{ boxes }]);
    } catch (error) {
      console.log(error);
    }
  };

  const validateSkuSplit = async () => {
    const payload = {
      isAutoSplit: true,
      selectedItems: defaultValues.stockSelection
    };

    if (defaultValues.preferences.requireSeperateSKUs) {
      payload.isAutoSplit = false;
    }
    try {
      const response = await validateSkuSplitMutation.mutateAsync(payload);
      const boxes = response?.boxes;
      setValue(BOX_SPLIT, [{ boxes }]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const isNoShippingRequiredAndNoPackagingRequired =
      !defaultValues.preferences.requireShipping && !defaultValues.preferences.requirePackaging;
    if (isNoShippingRequiredAndNoPackagingRequired) {
      validateNoPacking();
      return;
    }
    validateSkuSplit();
  }, []);

  return (
    <>
      <Heading as="h5" fontSize="lx" mb="0.875rem">
        {B2B_BOX_SPLIT_TEXT.disabledBoxSplit.title}
      </Heading>
      <B2BOrderOption {...rest} />
      <SlzAttentionCard
        {...B2B_BOX_SPLIT_STYLE.attentionCard}
        {...B2B_BOX_SPLIT_TEXT.disabledBoxSplit.attentionCard}
      />
      <B2BOrderAction currentStep={currentStep} onPrevious={onPrevious} onCancel={onCancel} />
    </>
  );
};

export default B2BDisabledBoxSplit;
