import { BulkOrderProvider } from 'modules/b2b-orders/context/BulkOrderContext';
import { B2C_ORDERS_PATHNAME } from '../constants';
import { OrderProvider } from '../context/OrderContext';
import OrderDetails from '../pages/order-detail';
import OrderList from '../pages/order-list';

const Order = () => (
  <OrderProvider>
    <OrderList />
  </OrderProvider>
);

const OrdersRoutes = [
  {
    path: B2C_ORDERS_PATHNAME,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: Order
  },
  /* {
    path: `${B2C_ORDERS_PATHNAME}/:orderId`,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: OrderDetails
  } */
  {
    path: `${B2C_ORDERS_PATHNAME}/details`,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: OrderDetails
  }
];

export default OrdersRoutes;
