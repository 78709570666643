import React, { Fragment } from 'react';
import { Box, HStack } from '@chakra-ui/react';

import { CREDIT_CARD_PLACEHOLDER } from 'constants/credit-card';

import PaymentCard from './PaymentCard';

export default function CreditCard({ size, info, onSetup, onRemove }) {
  const { type, status, customerName, email, cardNumber, expiredDate } =
    info || CREDIT_CARD_PLACEHOLDER;
  const colorScheme = type;

  return (
    <PaymentCard size={size} colorScheme={colorScheme}>
      <PaymentCard.Header>
        {!info ? (
          <PaymentCard.Setup name="Add" onSetup={onSetup} />
        ) : (
          <Fragment>
            <PaymentCard.Status status={status} />
            <PaymentCard.Edit onReplace={onSetup} onRemove={onRemove} />
          </Fragment>
        )}
      </PaymentCard.Header>

      <PaymentCard.Body>
        <HStack w="full" h="full" alignItems="flex-start" pt="3em">
          <Box flex={1}>
            <PaymentCard.Chip />
            <PaymentCard.Information
              customerName={customerName}
              cardNumber={cardNumber}
              email={email}
              expiredDate={expiredDate}
            />
          </Box>

          <PaymentCard.Logo type={type} />
        </HStack>
      </PaymentCard.Body>
    </PaymentCard>
  );
}
