import { Icon } from '@chakra-ui/react';
import React from 'react';

const SettingIcon = (props) => {
  const strokeWidth = props?.strokeWidth ?? '2';
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33355" data-name="Group 33355" transform="translate(-1004.776 -2147.551)">
        <path
          id="Path_3489"
          data-name="Path 3489"
          d="M1013.1,2149.868a1.724,1.724,0,0,1,3.35,0,1.723,1.723,0,0,0,2.573,1.066,1.725,1.725,0,0,1,2.37,2.37,1.723,1.723,0,0,0,1.065,2.572,1.724,1.724,0,0,1,0,3.35,1.723,1.723,0,0,0-1.066,2.573,1.725,1.725,0,0,1-2.37,2.37,1.723,1.723,0,0,0-2.572,1.065,1.724,1.724,0,0,1-3.35,0,1.725,1.725,0,0,0-2.573-1.066,1.725,1.725,0,0,1-2.37-2.37,1.724,1.724,0,0,0-.576-2.369,1.743,1.743,0,0,0-.489-.2,1.724,1.724,0,0,1,0-3.35,1.726,1.726,0,0,0,1.066-2.573,1.725,1.725,0,0,1,2.37-2.37A1.723,1.723,0,0,0,1013.1,2149.868Z"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="3"
          cy="3"
          r="3"
          transform="translate(1011.776 2154.551)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </Icon>
  );
};

export default SettingIcon;
