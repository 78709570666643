import { useState } from 'react';
import { Flex, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { CheckSuccessIcon } from 'components/common/Icons';
import { DefaultComponents } from 'components/common/SlzDropdown';
import { SlzButton, SlzSplitButton } from 'components/common/SlzButton';
import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';

import {
  SERVICE_TIME_COUNTRY_REGIONS,
  SHOPIFY_SHIPPING_RATES_BUTTON_ACTIONS
} from 'modules/integrations/constants';
import { getRegionOptions, getServiceTimeOptions } from 'modules/integrations/utils';
import { RateMatcherDropdown } from 'modules/integrations/components/other';

const RegionOption = (props) => {
  const { data: { isMatched = false, MatchedIcon = <></> } = {}, label, ...rest } = props;
  return (
    <DefaultComponents.Option {...rest}>
      <HStack
        bg={isMatched && 'positive.300'}
        color={isMatched && 'dark.700'}
        h="1.875rem"
        w="9.5rem"
        position="absolute"
        pl={1}
        justifyContent="space-between"
        pr={2}>
        <Text>{label}</Text>
        {isMatched && MatchedIcon}
      </HStack>
    </DefaultComponents.Option>
  );
};

const ShippingRateField = ({
  shippingRate,
  index,
  focus,
  setFocus,
  fetchedCurrentPreferences,
  fetchedServiceTimes
}) => {
  const { name, shippingZoneName, price, countryRegion, serviceTime } = shippingRate;
  const { setValue, getValues } = useFormContext();

  const [region, setRegion] = useState(countryRegion);
  const [selectedServiceTime, setSelectedServiceTime] = useState(serviceTime);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMatch, setIsMatch] = useState(countryRegion && serviceTime);

  const shippingRateLabel = `${name} - ${shippingZoneName} - £${price.toFixed(2)}`;

  const commonDropdownFieldProps = {
    menuPosition: 'fixed',
    isDisabled: Boolean(region && selectedServiceTime) && isDisabled,
    chakraStyles: {
      ...SELECT_STYLES,
      container: (provided) => ({
        ...provided,
        '> div': {
          borderRadius: 5
        }
      })
    }
  };

  const regionDropdownFieldProps = {
    options: getRegionOptions(countryRegion, fetchedCurrentPreferences, fetchedServiceTimes),
    placeholder: 'Select a region',
    value: {
      label: SERVICE_TIME_COUNTRY_REGIONS[region?.value],
      value: region?.value
    },
    customComponents: {
      Option: RegionOption
    },
    ...commonDropdownFieldProps
  };

  const serviceTimesDropdownFieldProps = {
    options: getServiceTimeOptions(
      selectedServiceTime,
      countryRegion || region,
      fetchedCurrentPreferences,
      fetchedServiceTimes
    ),
    placeholder: 'Select a service',
    value: selectedServiceTime,
    ...commonDropdownFieldProps
  };

  const handleSelectedRegion = (option) => {
    setRegion(option);
    setValue(`shippingRates.${index}.countryRegion`, option);
    setSelectedServiceTime('');
  };

  const handleSelectedServiceTime = (option) => {
    setSelectedServiceTime(option);
    setValue(`shippingRates.${index}.serviceTime`, option);
  };

  const handleChangeShippingRate = (option) => {
    const currentShippingRate = getValues(`shippingRates.${index}`);

    if (option?.value === 'remove') {
      delete currentShippingRate.countryRegion;
      delete currentShippingRate.serviceTime;
      setRegion('');
      setSelectedServiceTime('');
      setIsMatch(false);
    }

    if (option?.value === 'edit') {
      setIsDisabled(false);
    }
    setValue(`shippingRates.${index}`, currentShippingRate);
  };

  return (
    <HStack w="100%" fontSize="0.875rem" alignItems="end" onClick={() => setFocus(index)}>
      <VStack alignItems="start" w="50%">
        <Text fontWeight="bold">Your shopify shipping rate</Text>
        <Flex
          border="1px solid"
          borderColor="positive.500"
          alignItems="center"
          w="100%"
          borderRadius="5px"
          p="2px 8px"
          mt="0 !important"
          h="30px">
          <Text>{shippingRateLabel}</Text>
        </Flex>
      </VStack>
      {isMatch ? (
        <HStack h="100%" alignItems="end">
          <FormControl>
            <RateMatcherDropdown
              label="Region"
              field={`shippingRates.${index}.countryRegion`}
              showTooltip={false}
              w="160px"
              fieldProps={{ ...regionDropdownFieldProps, onChange: handleSelectedRegion }}
              labelProps={{ fontSize: '0.875rem' }}
            />
          </FormControl>

          <FormControl>
            <NestedDropdown
              label="Selazar shipping service"
              field={`shippingRates.${index}.serviceTime`}
              showTooltip={false}
              w="170px"
              fieldProps={{
                ...serviceTimesDropdownFieldProps,
                onChange: handleSelectedServiceTime
              }}
              labelProps={{ fontSize: '0.875rem', width: '100%' }}
            />
          </FormControl>

          {region && selectedServiceTime && (
            <SlzSplitButton
              items={SHOPIFY_SHIPPING_RATES_BUTTON_ACTIONS}
              size={'md'}
              colorScheme="positive"
              leftIcon={<CheckSuccessIcon />}
              onSelected={handleChangeShippingRate}
            />
          )}
        </HStack>
      ) : (
        <SlzButton onClick={() => setIsMatch(!isMatch)}>Match</SlzButton>
      )}
    </HStack>
  );
};

export default ShippingRateField;
