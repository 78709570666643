import { Text } from '@chakra-ui/react';

const RateMatcherText = ({ children, ...rest }) => {
  return (
    <Text fontSize="1rem" color="dark.700" lineHeight="1.313rem" {...rest}>
      {children}
    </Text>
  );
};
export default RateMatcherText;
