import { Fragment } from 'react';
import { GridItem } from '@chakra-ui/react';
import { BackNavigator, UserSettingFooter } from '../other';
import { PACKAGING_SETTING_FOOTER_TYPE } from 'modules/preferences/constants';
import ManageLibraries from '../other/ManageLibraries';

const ManagedLibrariesLayout = (props) => {
  const { onBack } = props;

  return (
    <Fragment>
      <GridItem gridColumn="2/12">
        <BackNavigator onBack={onBack} />
        <ManageLibraries />
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <UserSettingFooter
          onBack={onBack}
          footerType={PACKAGING_SETTING_FOOTER_TYPE.backToSettings}
        />
      </GridItem>
    </Fragment>
  );
};
export default ManagedLibrariesLayout;
