import { Heading, HStack, Text } from '@chakra-ui/react';

const PreferenceHeader = ({ header, description, tooltip, ...rest }) => {
  return (
    <>
      <HStack spacing={1.5}>
        <Heading
          as="h3"
          fontSize="xl"
          lineHeight={8}
          mb="0"
          color="dark.700"
          fontWeight="bold"
          {...rest}>
          {header}
        </Heading>
        {tooltip}
      </HStack>
      {description && <Text>{description}</Text>}
    </>
  );
};

export default PreferenceHeader;
