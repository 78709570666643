import { Icon } from '@chakra-ui/react';
import React from 'react';

export const ClipboardListIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 101.907 135.367"
      fill="#676a74"
      strokeWidth="0"
      {...props}>
      <path
        id="Path_9788"
        data-name="Path 9788"
        d="M85.022,7.991H77.8v-.44A7.559,7.559,0,0,0,70.249,0H31.658a7.559,7.559,0,0,0-7.551,7.551v.44H16.885A16.9,16.9,0,0,0,0,24.876v93.606a16.9,16.9,0,0,0,16.885,16.885H85.022a16.9,16.9,0,0,0,16.885-16.885V24.876A16.9,16.9,0,0,0,85.022,7.991M94.84,118.482a9.829,9.829,0,0,1-9.818,9.818H16.885a9.829,9.829,0,0,1-9.818-9.818V24.876a9.829,9.829,0,0,1,9.818-9.818h7.223V16.6a7.559,7.559,0,0,0,7.551,7.551H70.249A7.559,7.559,0,0,0,77.8,16.6V15.058h7.222a9.829,9.829,0,0,1,9.818,9.818Z"
        fill="currentColor"
      />
      <path
        id="Path_9789"
        data-name="Path 9789"
        d="M84.217,157.231H75.106a5.151,5.151,0,0,0-5.319,4.952v9.944a5.151,5.151,0,0,0,5.319,4.952h9.111a5.151,5.151,0,0,0,5.319-4.952v-9.944a5.151,5.151,0,0,0-5.319-4.952m-1.748,12.78H76.854V164.3h5.614Z"
        transform="translate(-53.641 -120.854)"
        fill="currentColor"
      />
      <path
        id="Path_9790"
        data-name="Path 9790"
        d="M84.217,276.721H75.106a5.151,5.151,0,0,0-5.319,4.952v9.944a5.151,5.151,0,0,0,5.319,4.952h9.111a5.151,5.151,0,0,0,5.319-4.952v-9.944a5.151,5.151,0,0,0-5.319-4.952M82.469,289.5H76.854v-5.713h5.614Z"
        transform="translate(-53.641 -212.698)"
        fill="currentColor"
      />
      <path
        id="Path_9791"
        data-name="Path 9791"
        d="M84.217,399.364H75.106a5.151,5.151,0,0,0-5.319,4.952v9.943a5.151,5.151,0,0,0,5.319,4.952h9.111a5.151,5.151,0,0,0,5.319-4.952v-9.943a5.151,5.151,0,0,0-5.319-4.952m-1.748,12.78H76.854v-5.713h5.614Z"
        transform="translate(-53.641 -306.966)"
        fill="currentColor"
      />
      <path
        id="Path_9792"
        data-name="Path 9792"
        d="M218.843,426.983H181.272a3.534,3.534,0,0,0,0,7.067h37.572a3.534,3.534,0,0,0,0-7.067"
        transform="translate(-136.616 -328.195)"
        fill="currentColor"
      />
      <path
        id="Path_9793"
        data-name="Path 9793"
        d="M218.843,304.34H181.272a3.534,3.534,0,0,0,0,7.067h37.572a3.534,3.534,0,0,0,0-7.067"
        transform="translate(-136.616 -233.927)"
        fill="currentColor"
      />
      <path
        id="Path_9794"
        data-name="Path 9794"
        d="M218.843,184.85H181.272a3.534,3.534,0,0,0,0,7.067h37.572a3.534,3.534,0,0,0,0-7.067"
        transform="translate(-136.616 -142.083)"
        fill="currentColor"
      />
    </Icon>
  );
};

export default ClipboardListIcon;
