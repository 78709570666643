import React from 'react';
import { Icon } from '@chakra-ui/react';

const ApiAccessIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33694" data-name="Group 33694" transform="translate(-10370.244 6962.335)">
        <g id="Group_33697" data-name="Group 33697" transform="translate(10272.912 -7134.755)">
          <path
            id="Path_5888"
            data-name="Path 5888"
            d="M103.22,185.627H98.313a.981.981,0,1,1,0-1.963h4.906a.981.981,0,1,1,0,1.963Z"
            transform="translate(0 0.343)"
            fill="#303134"
          />
          <path
            id="Path_5889"
            data-name="Path 5889"
            d="M106.313,189.176a1.018,1.018,0,0,1-.981-1.051v-8.409a1.018,1.018,0,0,1,.981-1.051h2.944a3.058,3.058,0,0,1,2.944,3.154v1.051a3.058,3.058,0,0,1-2.944,3.154h-1.963v2.1A1.018,1.018,0,0,1,106.313,189.176Zm.981-5.256h1.963a1.019,1.019,0,0,0,.981-1.051v-1.051a1.019,1.019,0,0,0-.981-1.051h-1.963Z"
            transform="translate(0.765)"
            fill="#303134"
          />
          <path
            id="Path_5890"
            data-name="Path 5890"
            d="M114.313,189.176a1.018,1.018,0,0,1-.981-1.051v-8.409a.984.984,0,1,1,1.963,0v8.409A1.018,1.018,0,0,1,114.313,189.176Z"
            transform="translate(0.959)"
            fill="#303134"
          />
          <path
            id="Path_5891"
            data-name="Path 5891"
            d="M103.22,189.176a1.018,1.018,0,0,1-.981-1.051v-5.781a1.475,1.475,0,1,0-2.944,0v5.781a.984.984,0,1,1-1.963,0v-5.781a3.443,3.443,0,1,1,6.869,0v5.781A1.018,1.018,0,0,1,103.22,189.176Z"
            transform="translate(0)"
            fill="#303134"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ApiAccessIcon;
