import { useCallback, useEffect } from 'react';
import { GridItem, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import { yupResolver } from '@hookform/resolvers/yup';

import useSlzToast from 'hooks/useSlzToast';
import { SlzGridModal } from 'components/common/SlzModal';
import {
  CONFIRM_LEAVE_TOAST_ID,
  LEAVE_CONFIRMATION,
  STYLE_WARNING_TOAST
} from 'modules/address-book/constants';
import { RecipientDetailsForm, repcipientDetailsSchema } from '../form';
import ConfirmToast from '../other/ConfirmToast';
import Action from './Action';

const AddOrEditRecipientModal = (props) => {
  const { formValues, refetch, isOpen, onClose, onSave, isSubmitting = false, onRemove } = props;

  const [_state, setToast, toast] = useSlzToast();

  const methods = useForm({
    defaultValues: formValues,
    resolver: yupResolver(repcipientDetailsSchema)
  });

  const { isDirty } = methods.formState;

  useEffect(() => {
    methods.reset(formValues);
  }, [isOpen]);

  const onModalClose = useCallback(() => {
    refetch && refetch();
    toast.closeAll();
    onClose?.();
  }, []);

  const handleCloseModal = useCallback(() => {
    if (!isDirty) {
      onModalClose();
      return;
    }

    !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: CONFIRM_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <ConfirmToast
            {...LEAVE_CONFIRMATION}
            onConfirmed={onModalClose}
            onCancel={toast.closeAll}
          />
        )
      });
  }, [isDirty]);

  return (
    <SlzGridModal id="recipient-save-slz-modal" isOpen={isOpen} onClose={onModalClose}>
      <GridItem gridColumn={'2/12'}>
        <Text fontSize="2rem" fontWeight="bold" mb="2rem" textAlign="center">
          Recipient details
        </Text>
      </GridItem>
      <GridItem gridColumn={'2/12'} mb="10">
        <FormProvider {...methods}>
          <form onSubmit={methods?.handleSubmit(onSave)} style={{ width: '100%' }}>
            <RecipientDetailsForm />
            <Action
              onClose={handleCloseModal}
              isSubmitting={isSubmitting}
              onRemove={onRemove}
              isEdit={!!formValues.id}
            />
          </form>
        </FormProvider>
      </GridItem>
    </SlzGridModal>
  );
};

export default AddOrEditRecipientModal;
