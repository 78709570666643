import { useQuery } from 'react-query';
import { getUserById } from '../services/users-api';

export const useGetUserByIdQuery = ({ enabled, id }) => {
  return useQuery({
    queryKey: [`get-user-by-id`],
    queryFn: () => getUserById(id),
    enabled
  });
};
