import { useQuery } from 'react-query';
import { getLabel } from '../services/consignments-api';

export const useConsignmentLabelsQuery = (consignmentId, option, uniqueIdentifier, select) => {
  return useQuery([`consignment-label-${consignmentId}-${option}-${uniqueIdentifier}`], () => getLabel(consignmentId, option), {
    cacheTime: 60 * 1000,
    staleTime: 30 * 1000,
    enabled: option !== null,
    select
  });
};
