import { HStack, Text, VStack } from '@chakra-ui/react';
import { ClipboardListIcon, CsvUpload } from 'components/common/Icons';
import SlzPaper from 'components/common/SlzPaper/SlzPaper';
import { PRODUCTS_ADD_TYPES } from 'modules/products/constants';
const paperStyles = {
  w: '23.25rem',
  h: '23.25rem',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'light.700',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
  _hover: {
    borderColor: 'main.500',
    borderWidth: 3
  }
};

const AddOptions = ({ setCurrentType }) => {
  return (
    <VStack mt={2.5} mb={7} gap={6}>
      <Text textAlign="justify">
        Our integrations synchronise your product library from your store. If you have not connected
        your store yet, please set up a connection from the integrations menu. If you wish to
        continue doing this manually we have two options for you.
      </Text>
      <HStack w="full" justifyContent="center" gap={12}>
        <VStack>
          <SlzPaper
            sx={paperStyles}
            size="lg"
            onClick={() => setCurrentType(PRODUCTS_ADD_TYPES.MANUAL)}>
            <ClipboardListIcon sx={{ width: '70%', height: '70%' }} color="dark.500" />
            {/* <ManualUploadIcon /> */}
          </SlzPaper>
          <Text
            sx={{
              fontSize: '20px',
              lineHeight: '26px',
              letterSpacing: '0px',
              color: 'dark.500'
            }}>
            Manual upload
          </Text>
        </VStack>
        <VStack>
          <SlzPaper
            sx={paperStyles}
            size="lg"
            onClick={() => setCurrentType(PRODUCTS_ADD_TYPES.UPLOAD)}>
            <CsvUpload sx={{ width: '70%', height: '70%' }} color="dark.500" />
          </SlzPaper>
          <Text
            sx={{
              fontSize: '20px',
              lineHeight: '26px',
              letterSpacing: '0px',
              color: 'dark.500'
            }}>
            CSV upload
          </Text>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default AddOptions;
