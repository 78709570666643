import { HStack, Icon, Text } from '@chakra-ui/react';
import { SlzRadio } from 'components/common/SlzRadio';
import {
  Card,
  CardFooter,
  CardIcon,
  CardPermissions,
  CardRoleBanner,
  CardRolesAndPermissions
} from 'modules/users/common/cards/CardRolesAndPermissions';
import { HORIZONTAL_CARDS_EDIT_FORM_STYLES } from 'modules/users/common/cards/styles';

const HorizontalEditUserRoleCard = ({ isChecked, radioProps, ...rest }) => {
  const { icon, label, text, permissions, bgLabel } = rest;
  return (
    <CardRolesAndPermissions styles={HORIZONTAL_CARDS_EDIT_FORM_STYLES} {...radioProps}>
      <Card isChecked={isChecked} spacing={0} wrap={HStack}>
        <SlzRadio pos="absolute" top={1.5} left={2.5} {...radioProps} {...rest} />
        <CardIcon bg="light.500">
          <Icon as={icon} w="auto" h={12} color={bgLabel} />
          <Text fontSize="xs" color={bgLabel}>
            {label}
          </Text>
        </CardIcon>

        <CardFooter spacing={0}>
          <CardRoleBanner
            color="lightAndShadow.reverseText"
            h={7}
            px={3}
            justifyContent="start"
            fontSize="xs"
            lineHeight="none"
            bg={bgLabel}
            borderBottomRadius={0}>
            {text}
          </CardRoleBanner>
          <CardPermissions borderTopRadius={0} minH="3.313rem" justifyContent="flex-start">
            {permissions}
          </CardPermissions>
        </CardFooter>
      </Card>
    </CardRolesAndPermissions>
  );
};
export default HorizontalEditUserRoleCard;
