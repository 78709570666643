import { B2B_ORDER_PATHNAME } from '../constants';
import { BulkOrderProvider } from '../context/BulkOrderContext';
import { BulkOrderDetail, BulkOrderList } from '../pages';

const BulkOrder = () => (
  <BulkOrderProvider>
    <BulkOrderList />
  </BulkOrderProvider>
);

const BulkOrderRoutes = [
  {
    path: B2B_ORDER_PATHNAME,
    feature: 'ViewOrder',
    profile: 'Supplier',
    exact: true,
    component: BulkOrder
  },
  {
    path: `${B2B_ORDER_PATHNAME}/:orderId`,
    feature: 'ViewOrder',
    profile: 'Supplier',
    exact: true,
    component: BulkOrderDetail
  }
];

export default BulkOrderRoutes;
