import { useEffect } from 'react';
import { HStack, StackItem, Text } from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';
import { TbArrowsSplit } from 'react-icons/tb';

import { PaginatedTooltipContent } from 'components/common/SlzToolTip/templates';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzToggle } from 'components/common/SlzToggle';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

import {
  ORDER_SETTING_PREFERENCES_TEXT,
  ORDER_SETTING_TOOLTIP_CONTENT,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { usePartialOrderPreferenceQuery } from 'modules/preferences/hooks/usePartialOrderPreferenceQuery';
import {
  PreferenceHeader as OrderSettingHeader,
  PreferencePaper
} from 'modules/preferences/components/common';
import { Tooltip } from './tooltip';
import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';

const OrderSettingBody = ({ data, isFetching }) => {
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.order]: form } = forms;
  const methods = form?.form;

  useEffect(() => {
    !isFetching &&
      methods?.reset({
        partialOrder: data
      });
  }, [isFetching]);

  return (
    <Skeleton w="fit-content" isLoaded={!isFetching} borderRadius={3} bg="light.500">
      <FormProvider {...methods}>
        <form autoComplete="off">
          <PreferencePaper w="407px" mt={2.5}>
            <HStack p="1.25rem 0 1.25rem 1.25rem" alignItems="center" spacing={4}>
              <StackItem>
                <TbArrowsSplit size="30px" />
              </StackItem>
              <StackItem>
                <Text>{ORDER_SETTING_PREFERENCES_TEXT.paperContent}</Text>
              </StackItem>
              <StackItem>
                <Controller
                  control={methods?.control}
                  name="partialOrder"
                  render={({ field: { value, onChange } }) => {
                    return (
                      <SlzToggle
                        isDisabled={isFetching}
                        value={value}
                        isChecked={value}
                        onChange={onChange}
                        mb={0}
                      />
                    );
                  }}
                />
              </StackItem>
            </HStack>
          </PreferencePaper>
        </form>
      </FormProvider>
    </Skeleton>
  );
};

const OrderSettingTooltip = () => {
  return (
    <Tooltip
      placement="right"
      trigger="hover"
      content={<PaginatedTooltipContent tooltipContent={ORDER_SETTING_TOOLTIP_CONTENT} />}
    />
  );
};

const OrderSetting = ({ activatedSetting }) => {
  const { header, description } = ORDER_SETTING_PREFERENCES_TEXT;
  const { data, isFetching } = usePartialOrderPreferenceQuery();

  return (
    <PreferenceSkeleton isLoaded={!isFetching} template={activatedSetting}>
      <OrderSettingHeader
        header={header}
        description={description}
        tooltip={<OrderSettingTooltip />}
      />
      <OrderSettingBody data={data} isFetching={isFetching} />
    </PreferenceSkeleton>
  );
};

export default OrderSetting;
