import { HStack, Text } from '@chakra-ui/react';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { DefaultComponents, SlzDropdown } from 'components/common/SlzDropdown';
import { RETURN_SETTING_STYLE } from 'modules/preferences/constants';

const ReturnSettingOption = (props) => {
  const { data, label, ...rest } = props;
  return (
    <DefaultComponents.Option {...rest}>
      <HStack spacing={0} alignItems="center" minH="1.875rem">
        <Text ml={data?.description && 2.5} flex={1}>
          {label}
        </Text>
        {data?.description && (
          <Text flex={2} fontSize="xs" color={rest?.isSelected ? 'white' : 'dark.500'}>
            {data?.description}
          </Text>
        )}
      </HStack>
    </DefaultComponents.Option>
  );
};

const ReturnSettingDropdown = ({ isLoaded, disabled, ...rest }) => {
  const { customSelectDisabled, customSelect } = RETURN_SETTING_STYLE;
  const styles = disabled ? customSelectDisabled : customSelect;

  return (
    <Skeleton w={rest?.w} isLoaded={isLoaded} borderRadius={1.5} bg="light.300">
      <SlzDropdown
        isDisabled={disabled}
        customComponents={{ Option: ReturnSettingOption }}
        chakraStyles={styles}
        {...rest}
      />
    </Skeleton>
  );
};
export default ReturnSettingDropdown;
