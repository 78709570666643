import React from 'react';
import { Icon } from '@chakra-ui/react';

const DownloadIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="currentColor"
      {...props}>
      <path
        id="Union_43"
        data-name="Union 43"
        d="M1.82,12A1.864,1.864,0,0,1,0,10.1V7.882A.658.658,0,0,1,.643,7.21a.658.658,0,0,1,.643.672V10.1a.547.547,0,0,0,.534.557h8.36a.547.547,0,0,0,.534-.557V7.882a.644.644,0,1,1,1.286,0V10.1A1.864,1.864,0,0,1,10.18,12ZM5.546,8.049,2.633,4.83a.762.762,0,0,1,0-1,.6.6,0,0,1,.909,0L5.357,5.832V.657a.643.643,0,1,1,1.286,0V5.831L8.459,3.826a.6.6,0,0,1,.909,0,.762.762,0,0,1,0,1L6.455,8.049a.6.6,0,0,1-.909,0Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default DownloadIcon;
