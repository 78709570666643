import { createContext, useContext, useReducer } from 'react';
import { CONSIGNMENTS_INITIAL_STATE, ONHOLD_INITIAL_STATE } from '../constants';
import { consignmentsReducer, onHoldReducer } from '../reducers';

export const ConsignmentsContext = createContext(null);
export const OnHoldContext = createContext(null);

export const ConsignmentsProvider = ({ children }) => {
  const [consignment, dispatch] = useReducer(consignmentsReducer, CONSIGNMENTS_INITIAL_STATE);
  const [onHoldItems, onHoldDispatch] = useReducer(onHoldReducer, ONHOLD_INITIAL_STATE);
  return (
    <ConsignmentsContext.Provider value={{ consignment, consignmentDispatch: dispatch }}>
      <OnHoldContext.Provider value={{ onHoldItems, onHoldDispatch}}>
        {children}
      </OnHoldContext.Provider>
    </ConsignmentsContext.Provider>
  );
};

export const useConsignmentsContext = () => useContext(ConsignmentsContext);
export const useOnHoldContext = () => useContext(OnHoldContext);
