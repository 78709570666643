import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

import ToggleButton from './ToggleButton';
import { ControlledField } from 'components/form';

import { B2B_ORDER_OPTION_STYLE } from 'modules/b2b-orders/constants/styles';
import { B2BOrderOptionTitle } from '.';

const B2BOrderOptionContent = ({ optionKey, isOptionDisabled, content }) => (
  <HStack {...B2B_ORDER_OPTION_STYLE.toggleButton.wrapper}>
    <ControlledField
      name={optionKey}
      isOptionDisabled={isOptionDisabled}
      content={content}
      component={ToggleButton}
    />
  </HStack>
);

const B2BOrderOption = ({
  optionKey,
  content,
  isTitleDisabled,
  isOptionDisabled = false,
  ...rest
}) => {
  return (
    <Box mb="0.75rem">
      <B2BOrderOptionTitle isTitleDisabled={isTitleDisabled} {...rest} />
      <B2BOrderOptionContent
        optionKey={optionKey}
        isOptionDisabled={isOptionDisabled}
        content={content}
      />
    </Box>
  );
};
export default B2BOrderOption;
