import { Icon } from '@chakra-ui/react';
import React from 'react';

const PersonShoppingCardIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="64.24"
      height="48"
      viewBox="0 0 64.24 48"
      {...props}>
      <svg>
        <g id="Group_34851" data-name="Group 34851" transform="translate(12458.096 5563.753)">
          <g id="Group_34851-2" data-name="Group 34851" transform="translate(-12458.096 -5563.753)">
            <path
              id="Ellipse_393"
              data-name="Ellipse 393"
              d="M3.8-1A4.8,4.8,0,1,1-1,3.8,4.805,4.805,0,0,1,3.8-1Z"
              transform="translate(15.399 1)"
              fill="#fd642e"
            />
            <path
              id="Line_619"
              data-name="Line 619"
              d="M1.4,13.4A2.4,2.4,0,0,1-.52,9.56l7.2-9.6a2.4,2.4,0,1,1,3.84,2.88l-7.2,9.6A2.4,2.4,0,0,1,1.4,13.4Z"
              transform="translate(3.4 34.599)"
              fill="#fd642e"
            />
            <path
              id="Path_5642"
              data-name="Path 5642"
              d="M2519.174,1365.887a2.4,2.4,0,0,1-2.149-1.327l-4.625-9.251-6.924-6.924a2.4,2.4,0,0,1-.67-2.092l2.4-14.4a2.4,2.4,0,1,1,4.734.789l-2.2,13.181,6.326,6.327a2.4,2.4,0,0,1,.45.624l4.8,9.6a2.4,2.4,0,0,1-2.146,3.474Z"
              transform="translate(-2492.774 -1317.888)"
              fill="#fd642e"
            />
            <path
              id="Path_5643"
              data-name="Path 5643"
              d="M2530.973,1344.287a2.406,2.406,0,0,1-.76-.124l-7.2-2.4a2.391,2.391,0,0,1-.938-.58l-6.239-6.239-7.381,1.845-4.285,6.428a2.4,2.4,0,1,1-3.994-2.662l4.8-7.2a2.4,2.4,0,0,1,1.416-1l9.6-2.4a2.4,2.4,0,0,1,2.279.631l6.8,6.8,6.661,2.221a2.4,2.4,0,0,1-.758,4.677Z"
              transform="translate(-2499.773 -1317.888)"
              fill="#fd642e"
            />
          </g>
          <g id="Group_34852" data-name="Group 34852" transform="translate(-12427.987 -5550.914)">
            <g id="Group_303" data-name="Group 303" transform="translate(7.966 5.826)">
              <path
                id="Path_3539"
                data-name="Path 3539"
                d="M964.239,2220.949a1.746,1.746,0,0,1-1.662-1.215l-4.239-13.3a1.745,1.745,0,0,1,1.133-2.193l6.651-2.121a1.745,1.745,0,0,1,1.06,3.325l-4.988,1.59,3.179,9.977,13.3-4.239-2.12-6.651-8.314,2.649a1.745,1.745,0,0,1-1.06-3.326l9.977-3.179a1.747,1.747,0,0,1,2.193,1.133l3.18,9.977a1.745,1.745,0,0,1-1.133,2.193l-16.628,5.3A1.75,1.75,0,0,1,964.239,2220.949Z"
                transform="translate(-958.255 -2197.039)"
                fill="#fd642e"
              />
              <path
                id="Path_3540"
                data-name="Path 3540"
                d="M968.578,2220.964a1.746,1.746,0,0,1-1.662-1.216l-4.768-14.967a3.494,3.494,0,0,1,2.264-4.384l3.326-1.06a3.5,3.5,0,0,1,4.386,2.265l1.59,4.991a1.745,1.745,0,0,1-3.326,1.06l-1.59-4.99-3.326,1.06,4.768,14.966a1.746,1.746,0,0,1-1.663,2.275Z"
                transform="translate(-955.942 -2199.173)"
                fill="#fd642e"
              />
            </g>
            <path
              id="Path_3541"
              data-name="Path 3541"
              d="M971.177,2225.5a1.746,1.746,0,0,1-1.662-1.216l-7.81-24.512-2.211.7a1.745,1.745,0,0,1-1.06-3.326l3.874-1.234a1.745,1.745,0,0,1,2.193,1.133l7.81,24.511,16.765-5.342a1.745,1.745,0,0,1,1.06,3.326l-18.428,5.871A1.758,1.758,0,0,1,971.177,2225.5Z"
              transform="translate(-957.219 -2195.835)"
              fill="#fd642e"
            />
            <path
              id="Ellipse_41"
              data-name="Ellipse 41"
              d="M3.332-1A4.332,4.332,0,1,1-1,3.332,4.337,4.337,0,0,1,3.332-1Zm0,5.174a.842.842,0,1,0-.842-.842A.843.843,0,0,0,3.332,4.174Z"
              transform="translate(9.614 27.496)"
              fill="#fd642e"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default PersonShoppingCardIcon;
