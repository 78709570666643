import { useQuery } from 'react-query';
import { getProductDetail } from '../services/products-api';

export const useProductDetailQuery = (productId, skuID, enabled) => {
  return useQuery({
    queryKey: [`product-detail-${productId}`],
    queryFn: () => getProductDetail(productId, skuID),
    cacheTime: 60 * 1000,
    staleTime: 2 * 1000,
    enabled: enabled
  });
};
