import { Box, Flex, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';

const WarehouseInformation = (props) => {
  const { warehouseInformation, isLoading } = props;
  return (
    <SlzPaper w={props.w} h={props.h} borderRadius="0.375rem" bg="light.500" overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <>
          <VStack>
            <Box
              w="full"
              borderBottomWidth="0.188rem"
              borderBottomColor="white"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {props.title}
            </Box>
            <Box w="full" h="fit-content" pl="1.563rem" pr="2.375rem" pt="1rem" pb="2rem">
              <VStack alignItems="flex-start" fontSize="md" lineHeight={1}>
                <Text fontWeight="bold">{warehouseInformation?.name}</Text>
                <Text>{warehouseInformation?.addressLine2}</Text>
                <Text>{warehouseInformation?.addressLine1}</Text>
                <Text>{warehouseInformation?.country} {warehouseInformation?.postCode}</Text>
                <Text></Text>
              </VStack>
            </Box>
          </VStack>
        </>
      )}
    </SlzPaper>
  );
};

export default WarehouseInformation;
