import React from 'react';
import { Box } from '@chakra-ui/react';

const BoxHiddenScroll = ({ sx, children, ...rest }) => {
  return (
    <Box
      sx={{
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        ...sx
      }}
      {...rest}>
      {children}
    </Box>
  );
};

export default BoxHiddenScroll;
