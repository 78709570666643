import { Box, Card, FormControl, FormErrorMessage, HStack, Text, VStack } from '@chakra-ui/react';
import { ConsignmentBoxMiniIcon } from './assets';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';

const DimensionsField = ({ label, register, errors, setValue, clearErrors }) => {
  const [dimensionFields] = useState([
    { field: 'consignmentBoxDimensions.height', unit: 'H', errorField: 'height' },
    { field: 'consignmentBoxDimensions.width', unit: 'W', errorField: 'width' },
    { field: 'consignmentBoxDimensions.depth', unit: 'D', errorField: 'depth' }
  ]);

  useEffect(() => {
    if (errors?.consignmentBoxDimensions) {
      for (const { field, errorField } of dimensionFields) {
        if (errors?.consignmentBoxDimensions[errorField]) {
          setValue(field, 'Required', {
            shouldDirty: true
          });
        }
      }

      if (errors?.consignmentBoxDimensions?.weight)
        setValue(
          'consignmentBoxDimensions.weight',
          errors?.consignmentBoxDimensions?.weight?.message,
          { shouldDirty: true }
        );
    }
  }, [errors]);

  const handleOnFocus = (field) => clearErrors(field);

  return (
    <>
      <Box w="19.5rem" marginRight="2.743rem">
        <FormControl>
          <SlzFormLabel display="flex" gap="1" alignItems="center" color="dark.700">
            {label}
          </SlzFormLabel>
          <HStack>
            {dimensionFields.map(({ field, unit, errorField }) => (
              <SlzInput
                key={field}
                type={errors?.consignmentBoxDimensions && errors?.consignmentBoxDimensions[errorField] ? 'text' : 'number'}
                size="sm"
                min="0"
                step="0.01"
                textAlign="right"
                bgColor="light.300"
                leftElContent={unit}
                status={errors?.consignmentBoxDimensions && errors?.consignmentBoxDimensions[errorField] && 'error'}
                {...register(field)}
                onFocus={() => handleOnFocus(field)}
              />
            ))}
          </HStack>
        </FormControl>
      </Box>

      <Box w="15rem">
        <FormControl>
          <SlzFormLabel color="dark.700">Weight (kg)</SlzFormLabel>
          <SlzInput
            sx={{
              '::placeholder': {
                fontStyle: 'italic',
                fontSize: '12px'
              },
              width: '100%'
            }}
            type={errors.consignmentBoxDimensions && errors?.consignmentBoxDimensions?.weight ? 'text' : 'number'}
            size="sm"
            min="0"
            step="0.01"
            bgColor="light.300"
            status={errors.consignmentBoxDimensions && errors?.consignmentBoxDimensions?.weight && 'error'}
            placeholder="Between 0.01 - 2000 kg"
            {...register('consignmentBoxDimensions.weight')}
            onFocus={() => handleOnFocus('consignmentBoxDimensions.weight')}
          />
        </FormControl>
      </Box>
    </>
  );
};

const ConsignmentBoxDimensions = () => {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors
  } = useFormContext();

  return (
    <VStack w="51.625rem" mb="3rem" alignItems="left">
      <Card
        bg="light.500"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem">
          <ConsignmentBoxMiniIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Text
            sx={{
              textAlign: 'left',
              lineHeight: '20px',
              letterSpacing: '-0.14px',
              fontSize: '16px',
              color: 'table.mainText'
            }}>
            <b>Consignment box dimensions & weight</b>
          </Text>
        </HStack>
      </Card>

      <Card
        px="1.5rem"
        paddingTop="0.813rem"
        bg="light.500"
        w="51.625rem"
        h="5.75rem"
        variant="filled"
        sx={{ marginTop: '0.3rem !important' }}>
        <HStack w="full">
          <DimensionsField
            name="dimensions"
            label="Dimensions (cm)"
            register={register}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        </HStack>
      </Card>
    </VStack>
  );
};

export default ConsignmentBoxDimensions;
