import { Box, HStack } from '@chakra-ui/react';
import { Fragment } from 'react';

import { ShopifyIcon, B2CIcon, WooCommerceIcon, CsvUpload } from 'components/common/Icons';

const ORDER_SOURCE = {
  OPEN_API: 'OpenAPI',
  WPP_COMMERCE: 'WooCommerce',
  CSV: 'CSV',
  SHOPIFY: 'Shopify',
  SYNERGY: 'Synergy'
};

const ORDER_SOURCE_BACKGROUND = {
  [ORDER_SOURCE.OPEN_API]: 'iceberg.700',
  [ORDER_SOURCE.WPP_COMMERCE]: '#9B5C8F',
  [ORDER_SOURCE.SHOPIFY]: '#95BF47',
  [ORDER_SOURCE.CSV]: 'iceberg.700',
  [ORDER_SOURCE.SYNERGY]: '#95BF47'
};

const ORDER_SOURCE_COMPONENT = {
  [ORDER_SOURCE.OPEN_API]: B2CIcon,
  [ORDER_SOURCE.WPP_COMMERCE]: WooCommerceIcon,
  [ORDER_SOURCE.SHOPIFY]: ShopifyIcon,
  [ORDER_SOURCE.CSV]: B2CIcon,
  [ORDER_SOURCE.SYNERGY] : B2CIcon
};

const Source = (props) => {
  const { storeName = '', type, styles } = props;
  const Icon = ORDER_SOURCE_COMPONENT[type] || Fragment;

  return (
    <HStack sx={styles} alignItems="center" bg={ORDER_SOURCE_BACKGROUND[type] || ''}>
      <Icon boxSize="0.938rem" mb="0.188rem" />
      <Box>{storeName}</Box>
    </HStack>
  );
};
export default Source;
