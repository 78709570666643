import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers();

const formLabelProductStyle = defineStyle({
  lineHeight: '0.75rem',
  fontWeight: 'bold',
  fontSize: '0.875rem',
  color: 'dark.700',
  width: '8.563rem',
  height: '1.875rem',
  textAlign: 'left',
  maxWidth: '100%',
  maxHeight: '1.875rem',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center'
});

const baseStyle = definePartsStyle(() => ({
  formLabelProduct: formLabelProductStyle
}))

export default defineMultiStyleConfig({
  baseStyle
});
