import * as yup from 'yup';
// import { NUMBER_PATTERN, SPECIAL_CHAR_PATTERN } from 'modules/users/constants';
const NUMBER_PATTERN = /^(?=.*[0-9])/;
const SPECIAL_CHAR_PATTERN = /^(?=.*[#$^+=!*()@%&£"'[\]{},_\\/;:`\-~<>?.])/;

export const registerValidationSchema = yup.object({
  email: yup.string().required('Email address is required').email('Your email is invalid'),
  companyName: yup.string().trim().required('Company name is required'),
  title: yup.object().typeError('Title is required').required('Title is required'),
  forename: yup.string().trim().required('Forename is required'),
  surname: yup.string().trim().required('Surname is required'),
  phone: yup.string().trim().required('Telephone is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'At least six characters long')
    .matches(NUMBER_PATTERN, null)
    .matches(SPECIAL_CHAR_PATTERN, null),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
  line1: yup.string().trim().required('Billing address is required'),
  town: yup.string().trim().required('Town is required'),
  county: yup.string().trim().required('County is required'),
  country: yup.string().trim().required('Country is required'),
  postcode: yup.string().trim().required('Postcode is required'),
  tsandcs: yup.boolean().isTrue('*Please agree to our T&C if you wish to continue'),
  isBot: yup.boolean().required()
});

export const userDetailsValidationSchema = yup.object({
  email: yup.string().required('Email is required').email('Email is required'),
  forename: yup.string().trim().required('Forename is required'),
  surname: yup.string().trim().required('Surname is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'At least six characters long')
    .matches(NUMBER_PATTERN, null)
    .matches(SPECIAL_CHAR_PATTERN, null)
});

export const rolesValidationSchema = yup.object({
  roles: yup.string().required('*Please select a user role')
});

export const editUserDetailValidationSchema = yup.object({
  email: yup.string().required('Email is required').email(),
  forename: yup.string().trim().required('Forename is required'),
  surname: yup.string().trim().required('Surname is required')
});
