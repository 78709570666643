import { Box, HStack } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const Overview = ({ showInfo = true, rest }) => (
  <ShimmerSkeleton w="100%" {...rest}>
    {showInfo && (
      <>
        <HStack mb="1.875rem" spacing="0.75rem">
          <ShimmerSkeleton.Block alignSelf="flex-start" w="14.25rem" h="15rem" />
          <Box>
            {[...Array(3)].map(() => (
              <ShimmerSkeleton.Input inputStyle={{ w: '14.198rem', h: '1.875rem' }} mb="1.125rem" />
            ))}
            <ShimmerSkeleton.Block w="14.198rem" h="1.875rem" />
          </Box>
          <Box alignSelf="end">
            <ShimmerSkeleton.Input inputStyle={{ w: '110.16px', h: '1.875rem' }} mb="1.125rem" />
            <ShimmerSkeleton.Block w="85px" h="1.875rem" mb="0.188rem" />
          </Box>
        </HStack>
        <ShimmerSkeleton.Input inputStyle={{ w: '100%', h: '4.651rem' }} mb="1.563rem" />
      </>
    )}

    <HStack justifyContent="space-between">
      <ShimmerSkeleton.Input w="19.274rem">
        <ShimmerSkeleton.Input inputStyle={{ w: '100%', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '9.275rem', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '10.75rem', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '14.271rem', h: '1.875rem' }} mb="1.125rem" />
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input w="24.209rem">
        <ShimmerSkeleton.Input inputStyle={{ w: '14.229rem', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '9.216rem', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '9.25rem', h: '1.875rem' }} mb="1.125rem" />
        <ShimmerSkeleton.Input inputStyle={{ w: '100%', h: '1.875rem' }} mb="1.125rem" />
      </ShimmerSkeleton.Input>
    </HStack>
  </ShimmerSkeleton>
);

const ExportDetails = (props) => <ShimmerSkeleton w="100%" {...props}></ShimmerSkeleton>;

const SalesAndPricing = (props) => (
  <ShimmerSkeleton w="100%" {...props}>
    <HStack justifyContent="space-between">
      <ShimmerSkeleton.Input flex="1" titleStyle={{ mb: '1.018rem' }}>
        <ShimmerSkeleton.Input inputStyle={{ w: '9.166rem', h: '1.875rem' }} mb="1.125rem" />
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input flex="1" titleStyle={{ w: '162px', mb: '1.018rem' }}>
        <ShimmerSkeleton.Input inputStyle={{ w: '9.166rem', h: '1.875rem' }} mb="1.125rem" />
      </ShimmerSkeleton.Input>
    </HStack>
  </ShimmerSkeleton>
);

const PackagingPreferences = (props) => (
  <ShimmerSkeleton w="100%" {...props}>
    <HStack alignItems="start" mb="20px">
      <ShimmerSkeleton.Input
        titleStyle={{ w: '160px', mb: '24px' }}
        inputStyle={{ h: '24px', w: '102px' }}
      />
      <ShimmerSkeleton.Block mt="5px !important" ml="80px !important" w="466.9px" h="45.5px" />
    </HStack>
    <HStack>
      <ShimmerSkeleton.Input titleStyle={{ w: '135px', h: '19px' }}>
        <ShimmerSkeleton.Block w="76px" h="19px" mb="8px" />
        <ShimmerSkeleton.Block w="76px" h="19px" mb="8px" />
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input titleStyle={{ w: '129px', h: '19px' }} ml="25px !important">
        <ShimmerSkeleton.Block w="159px" h="19px" mb="8px" />
        <ShimmerSkeleton.Block w="159px" h="19px" mb="8px" />
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input titleStyle={{ w: '140px', h: '19px' }} ml="56px !important">
        <ShimmerSkeleton.Block w="60px" h="19px" mb="8px" />
        <ShimmerSkeleton.Block w="60px" h="19px" mb="8px" />
      </ShimmerSkeleton.Input>
    </HStack>
  </ShimmerSkeleton>
);

const ProductOverview = (props) => (
  <ShimmerSkeleton w="100%" {...props}>
    <HStack alignItems="flex-start" justifyContent="space-between">
      <Box>
        <ShimmerSkeleton.Input mb="0.625rem" titleStyle={{ h: '1.313rem', w: '8.75rem' }}>
          <ShimmerSkeleton.Block w="100px" h="1.313rem" mb="0.625rem" />
          <ShimmerSkeleton.Block w="100px" h="1.313rem" />
        </ShimmerSkeleton.Input>
        <ShimmerSkeleton.Input titleStyle={{ h: '1.5rem', w: '8.125rem' }}>
          {[...Array(3)].map((index) => (
            <ShimmerSkeleton.Input
              key={index}
              mb="0.625rem"
              titleStyle={{ h: '1.25rem', w: '3.563rem' }}
              inputStyle={{ w: '12.688rem', h: '3rem' }}
            />
          ))}
        </ShimmerSkeleton.Input>
      </Box>
      <ShimmerSkeleton.Block w="32.313rem" h="18.063rem" />
    </HStack>
  </ShimmerSkeleton>
);

const AddProduct = (props) => (
  <ShimmerSkeleton w="100%" {...props}>
    <ShimmerSkeleton.Block h="2.796rem" w="100%" mb="3.375rem" />
    <HStack justifyContent="space-between" mb="5.625rem">
      <ShimmerSkeleton.Block w="23.239rem" h="23.239rem" />
      <ShimmerSkeleton.Block w="23.239rem" h="23.239rem" />
    </HStack>
  </ShimmerSkeleton>
);

const PRODUCT_TEMPLATES = {
  overview: Overview,
  'addProduct.0': AddProduct,
  'section.1': Overview,
  'section.2': ExportDetails,
  'section.3': SalesAndPricing,
  'section.4': PackagingPreferences,
  'section.5': ProductOverview
};

const ProductSkeleton = (props) => {
  return <BaseSkeleton baseTemplates={PRODUCT_TEMPLATES} {...props} />;
};

export default ProductSkeleton;
