import {
  PACKAGING_SETTINGS,
  PACKAGING_SETTING_HEADERS,
  PACKAGING_TYPES,
  PREFERENCES_ACTIONS
} from 'modules/preferences/constants';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { PreferenceHeader } from 'modules/preferences/components/common';
import { CustomPackage } from 'modules/preferences/components/form';

const PackagingOverview = ({ packagingOverview }) => {
  const { preferenceDispatch } = usePreferenceContext();

  const handleManageLabraries = (layout) => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: layout }
    });
  };

  return (
    <>
      <PreferenceHeader
        header={PACKAGING_SETTING_HEADERS.COMMON.header}
        description={PACKAGING_SETTING_HEADERS.COMMON.description}
      />
      <CustomPackage
        packagingType={PACKAGING_TYPES.box}
        packagingOverview={packagingOverview?.boxes}
        packageHeader={PACKAGING_SETTING_HEADERS.BOXES}
        packageSetting={PACKAGING_SETTINGS.BOXES}
        onManageLabraries={handleManageLabraries}
        mt="0.24rem"
      />
      <CustomPackage
        packagingType={PACKAGING_TYPES.mailingBag}
        packagingOverview={packagingOverview?.mailingBags}
        packageHeader={PACKAGING_SETTING_HEADERS.MAILING_BAGS}
        packageSetting={PACKAGING_SETTINGS.MAILING_BAGS}
        onManageLabraries={handleManageLabraries}
        mt="1.298rem"
      />
    </>
  );
};

export default PackagingOverview;
