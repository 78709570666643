import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, VStack, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Field, ControlledField } from 'components/form';
import { SlzSelect } from 'components/common/SlzSelect';
import SlzLink from 'components/common/SlzLink/SlzLink';
import TermAndConditions from './TermAndConditions';
import { CompanyInfo } from '../other';
import ReCAPTCHA from './ReCAPTCHA';
import PasswordAndConfirmPassword from './PasswordAndConfirmPassword';
import { registerValidationSchema as validationSchema } from './validation-schemas';
import { CONTACT_INFOS, HOME_PAGE_URL, TITLES } from '../../constants';

const defaultValues = {
  email: '',
  companyName: '',
  title: '',
  forename: '',
  surname: '',
  phone: '',
  password: undefined,
  confirmPassword: undefined,
  line1: '',
  line2: '',
  town: '',
  county: '',
  country: '',
  postcode: '',
  tsandcs: false
};

const RegisterForm = ({ onSubmit }) => {
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const isInvalidReCAPTCHA = !!methods.getFieldState('isBot').error;

  const handleOnChangeReCAPTCHA = (token) => {
    if (token) {
      methods.setValue('isBot', !!token);
      methods.clearErrors('isBot');
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} autoComplete="off">
        <VStack spacing={5}>
          <Field label="Email address" name="email" placeholder="Email address" isInlineError />

          <Field label="Company name" name="companyName" placeholder="Company name" isInlineError />

          <ControlledField
            label="Title"
            name="title"
            placeholder="Select a title"
            isInlineError
            component={SlzSelect}
            options={TITLES}
          />

          <Field label="Forename" name="forename" placeholder="Forename" isInlineError />

          <Field label="Surname" name="surname" placeholder="Surname" isInlineError />

          <Field label="Telephone" name="phone" placeholder="Telephone" isInlineError />

          <PasswordAndConfirmPassword />

          <Field
            label="Billing address line 1"
            name="line1"
            placeholder="Billing address"
            isInlineError
          />

          <Field
            label="Billing address line 2"
            name="line2"
            placeholder="Billing address line 2 - optional"
            isInlineError
          />

          <Field label="Town" name="town" placeholder="Town" isInlineError />

          <Field label="County" name="county" placeholder="County" isInlineError />

          <Field label="Country" name="country" placeholder="Country" isInlineError />

          <Field label="Postcode" name="postcode" placeholder="Postcode" isInlineError />

          <Box py={2}>
            <ReCAPTCHA isInvalid={isInvalidReCAPTCHA} onChange={handleOnChangeReCAPTCHA} />
          </Box>

          <Box w="full" textAlign="left">
            <Field name="tsandcs" component={Checkbox} letterSpacing="tight">
              <TermAndConditions />
            </Field>
          </Box>
        </VStack>

        <Box mt={3}>
          <Button type="submit" size="lg" w="full">
            Create account
          </Button>
        </Box>

        <HStack justifyContent="space-between" alignItems="flex-start" mt={8}>
          <SlzLink href={HOME_PAGE_URL} color="main.500" fontWeight="medium">
            Selazar.com
          </SlzLink>

          <CompanyInfo email={CONTACT_INFOS.email} telephone={CONTACT_INFOS.telephone} />
        </HStack>
      </form>
    </FormProvider>
  );
};

export default RegisterForm;
