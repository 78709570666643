import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaChevronLeft } from 'react-icons/fa';
import { GridItem, HStack, Heading, Icon } from '@chakra-ui/react';

import useSlzToast from 'hooks/useSlzToast';
import useAuth from 'hooks/useAuth';
import { editUserDetailValidationSchema } from '../form/validation-schemas';
import { SlzGridModal } from 'components/common/SlzModal';
import { SlzButton } from 'components/common/SlzButton';
import {
  useEditPasswordMutation,
  useEditUserDetailsMutation,
  useGetUseWithRolesQuery
} from 'modules/users/hooks';
import { EDIT_USER_TEXT, WARNING_LEAVE_TOAST_ID } from 'modules/users/constants';
import { EditUserDetailForm, EditUserRolesForm } from '../form';
import { STYLE_WARNING_TOAST } from 'modules/users/constants';
import WarningConfirmToast from 'modules/users/components/other/WarningConfirmToast';

const EditUserFormLayout = ({ isMyOwnUser, userId, ...rest }) => {
  if (isMyOwnUser) {
    return (
      <>
        <EditUserRolesForm isMyOwnUser={isMyOwnUser} title={EDIT_USER_TEXT.userOwnRoles} mb={5} />
        <EditUserDetailForm userId={userId} title={EDIT_USER_TEXT.userDetails} {...rest} />
      </>
    );
  }
  return (
    <>
      <EditUserDetailForm userId={userId} {...rest} />
      <EditUserRolesForm mt={12} />
    </>
  );
};

const EditUserAction = ({ onClose }) => {
  return (
    <HStack w="full" justifyContent="space-between" mt={12} mb={6}>
      <SlzButton colorScheme="negative" size="lg" variant="outline" onClick={onClose}>
        {EDIT_USER_TEXT.cancel}
      </SlzButton>
      <SlzButton type="submit" colorScheme="main" size="lg">
        {EDIT_USER_TEXT.save}
      </SlzButton>
    </HStack>
  );
};

const EditUserModal = (props) => {
  const { userId, refetch, isOpen, onClose } = props;
  const [_, setToast, toast] = useSlzToast();
  const editUserDetailsMutation = useEditUserDetailsMutation();
  const editPasswordMutation = useEditPasswordMutation();
  const [editPasswordForm, setEditPasswordForm] = useState(null);
  const { data } = useGetUseWithRolesQuery({ id: userId, enabled: isOpen });
  const { getUser } = useAuth();

  const user = getUser();
  const isMyOwnUser = userId === user.id;

  const methods = useForm({
    resolver: yupResolver(editUserDetailValidationSchema)
  });

  const handleOnCloseModal = () => {
    refetch && refetch();
    setEditPasswordForm(null);
    methods.reset();
    onClose();
  };

  const handleUpdatePassword = async () => {
    const editPasswordPayload = {
      id: userId,
      password: editPasswordForm.getValues('password')
    };
    await editPasswordMutation.mutateAsync(editPasswordPayload);
  };

  const handleUpdateUserDetail = async (formData) => {
    const editUserDetailPayload = {
      id: userId,
      forename: formData.forename,
      surname: formData.surname,
      email: formData.email,
      roles: [formData.roles]
    };
    await editUserDetailsMutation.mutateAsync(editUserDetailPayload);
  };

  const handleOnSave = async (formData) => {
    try {
      if (editPasswordForm) {
        const isEditPasswordFormValid = await editPasswordForm.trigger();
        if (!isEditPasswordFormValid) return;
        handleUpdatePassword();
      }
      handleUpdateUserDetail(formData);
      setToast({ description: EDIT_USER_TEXT.successUpdated });
      handleOnCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const showConfirmLeaveToast = () => {
    !toast.isActive(WARNING_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: WARNING_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <WarningConfirmToast
            warningText={EDIT_USER_TEXT.leavingMessage}
            OKText="Continue"
            onCancelClick={toast.closeAll}
            onOKClick={handleOnOKClick}
          />
        )
      });
  };

  const handleOnOKClick = () => {
    toast.closeAll();
    onClose();
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        forename: data?.forename,
        surname: data?.surname,
        email: data?.email,
        roles: data?.userRoles?.map((role) => role.roleID)[0]
      });
    }
  }, [data]);

  return (
    <SlzGridModal isOpen={isOpen} onClose={showConfirmLeaveToast}>
      <GridItem gridColumn="2/12" mb="9" pt="6">
        <HStack spacing={1}>
          <Icon as={FaChevronLeft} fontSize={28} color="dark.500" cursor="pointer" />
          <Heading fontSize="2rem">{EDIT_USER_TEXT.editUser}</Heading>
        </HStack>
      </GridItem>
      <GridItem gridColumn="2/12">
        <FormProvider {...methods}>
          <form onSubmit={methods?.handleSubmit(handleOnSave)} style={{ width: '100%' }}>
            <EditUserFormLayout
              isMyOwnUser={isMyOwnUser}
              userId={userId}
              setEditPasswordForm={setEditPasswordForm}
            />
            <EditUserAction onClose={showConfirmLeaveToast} />
          </form>
        </FormProvider>
      </GridItem>
    </SlzGridModal>
  );
};

export default EditUserModal;
