import { useQuery } from 'react-query';
import { getThirdPartyIntegration } from '../services/integration-api';

export const useApiConnectionQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: [`api-connection`],
    queryFn: () => getThirdPartyIntegration(),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
