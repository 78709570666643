import dayjs from 'dayjs';
import { DATE_FORMAT_DDMMYYYY } from '../../../constants/date';
import { ORDER_STATUS_COLOR } from 'constants/common';

export const transformBulkOrders = ({ bulkOrders = [], pageCount = 0 }, status) => {
  const data = {
    bulkOrders,
    totalPage: pageCount
  };
  data.bulkOrders = bulkOrders.map((order, index) => {
    return {
      ...order,
      createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY),
      status: {
        key: index,
        name: order.status,
        color: ORDER_STATUS_COLOR[status]
      }
    };
  });
  return data;
};
