import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GridItem } from '@chakra-ui/react';
import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import OrdersFilter from '../components/form/OrdersFilter';
import { TrackingModal } from '../components/modal';
import { OrdersTable } from '../components/table';
import { ORDER_KEY_PARAM, ORDER_SKELETON_CELL, BREAD_CRUMB_ITEMS } from '../constants';
import { GET_ORDER_LIST, SET_QUERY_PARAM } from '../actions/index';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  PAGE_INDEX,
  PAGE_SIZE,
  DATE_FROM,
  DATE_TO,
  SEARCH_TEXT,
  FILTER_BY,
  SORT_BY,
  IS_DESC,
  STATUS
} from 'constants/table.js';
import { useOrderContext } from '../context/OrderContext';
import { useOrdersQuery } from '../hooks/useOrdersQuery';
import { transformOrders } from '../mappers/order-list-mapper';

const OrderList = () => {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [enableQuery, setEnableQuery] = useState(false);
  const [_, dispatch] = useGlobalState();
  const { order, orderDispatch } = useOrderContext();
  const {
    data: { orders = [], totalPage } = {},
    isFetching,
    isError,
    refetch
  } = useOrdersQuery(
    order?.params,
    (data) => transformOrders(data, order?.params?.status),
    enableQuery
  );

  const { handleCurrentPageChange, handleItemPerPageChange, handleSelectedDateRange } = useTableFilterHandler();

  const shouldOpenTrackingModal = () => {
    return !!routerQuery.get(ORDER_KEY_PARAM.IS_TRACKING_MODAL);
  };

  const handleStatusFilterChange = (statues) => {
    if (statues?.length === 0) {
      routerQuery.delete(FILTER_BY);
    } else {
      routerQuery.set(FILTER_BY, statues[statues.length - 1]?.text);
    }

    history.push({
      search: routerQuery.toString()
    });
  };

  const handleOnSort = (column, isDesc) => {
    if (column) {
      routerQuery.set(SORT_BY, column?.sortField);
      routerQuery.set(IS_DESC, isDesc);
    }
    history.push({
      search: routerQuery.toString()
    });
  };

  useEffect(() => {
    const params = {
      orderSearchText: routerQuery.get(SEARCH_TEXT) ?? '',
      dateFrom: routerQuery.get(DATE_FROM) ?? '',
      dateTo: routerQuery.get(DATE_TO) ?? '',
      status: routerQuery.get(STATUS) || '',
      filterBy: routerQuery.get(FILTER_BY) || '',
      sortBy: routerQuery.get(SORT_BY) || '',
      isDesc: routerQuery.get(IS_DESC) || '',
      pageIndex: routerQuery.get(PAGE_INDEX) ?? DEFAULT_PAGE_INDEX,
      pageSize: routerQuery.get(PAGE_SIZE) ?? DEFAULT_PAGE_SIZE
    };

    orderDispatch({
      type: SET_QUERY_PARAM,
      payload: { params }
    });
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [order?.params]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  useEffect(() => {
    orderDispatch({
      type: GET_ORDER_LIST,
      payload: { orders }
    });
  }, [isFetching]);

  return (
    <Fragment>
      <TrackingModal isOpen={shouldOpenTrackingModal()} />

      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <OrdersFilter
            data={orders}
            onDateRangeChange={handleSelectedDateRange}
            status={routerQuery.get(STATUS)}
          />
          <OrdersTable
            data={orders?.length > 0 ? orders : Array(5).fill(ORDER_SKELETON_CELL)}
            totalPage={totalPage}
            currentPage={routerQuery.get(PAGE_INDEX)}
            isLoading={isFetching}
            isEmptyPage={isError || totalPage === 0}
            messageToast="Sorry! We could not retrieve any orders information"
            onRefetching={refetch}
            onPageChange={handleCurrentPageChange}
            onItemPerPageChange={handleItemPerPageChange}
            onStatusFilterChange={handleStatusFilterChange}
            onSort={handleOnSort}
          />
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
};

export default OrderList;
