import { DragHandleIcon } from '@chakra-ui/icons';
import SlzTable from 'components/common/SlzTable/Table';
import { categoryCols as columns, fake } from './data';

const CategoryTable = () => {
  const products = fake(5);

  return (
    <SlzTable
      variant="simple"
      showMarked={false}
      showHeader={true}
      columns={columns}
      showToolbar={false}
      data={products}
      displayFields={['name', 'sku', 'suid', 'tag', 'totalStock']}
      hyperLinks={['name']}
      pagination={null}
      actions={null}
    />
  );
};

export default CategoryTable;
