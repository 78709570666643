import { Icon } from '@chakra-ui/react';

const ConsignmentArrivingIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34245" data-name="Group 34245" transform="translate(-313 -381)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(313 381)"
          fill="#0973ea"
        />
        <g id="Group_34263" data-name="Group 34263" transform="translate(319.611 387.107)">
          <path
            id="Path_3517"
            data-name="Path 3517"
            d="M534.1,2205.344h-7.834a.49.49,0,0,1-.49-.49v-1.469a.49.49,0,1,1,.979,0v.979h6.855V2198.3l-3.428-1.714-3.428,1.714v1.166a.49.49,0,1,1-.979,0V2198a.49.49,0,0,1,.271-.438l3.917-1.958a.49.49,0,0,1,.438,0l3.917,1.958a.49.49,0,0,1,.271.438v6.855A.49.49,0,0,1,534.1,2205.344Z"
            transform="translate(-526.776 -2196.551)"
            fill="#fafafa"
          />
          <path
            id="Path_3518"
            data-name="Path 3518"
            d="M537.672,2207.53h-4.407a.49.49,0,1,1,0-.979h4.407a.49.49,0,1,1,0,.979Z"
            transform="translate(-533.776 -2202.165)"
            fill="#fafafa"
          />
          <path
            id="Path_3519"
            data-name="Path 3519"
            d="M533.266,2207.468a.49.49,0,0,1-.346-.836l1.123-1.123-1.123-1.123a.49.49,0,0,1,.692-.693l1.469,1.469a.49.49,0,0,1,0,.693l-1.469,1.469A.489.489,0,0,1,533.266,2207.468Z"
            transform="translate(-530.838 -2200.634)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ConsignmentArrivingIcon;
