import React, { useRef } from 'react';
import {
  HStack,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useOutsideClick,
  useStyleConfig
} from '@chakra-ui/react';
import { SlzButton } from '../SlzButton';

const arrowStyle = {
  transform: 'rotate(0deg)!important',
  clipPath: 'polygon(50% 0%, 80% 50%, 50% 100%, 20% 50%)'
};

export default function SlzPopoverConfirm({
  variant,
  children,
  isOpen,
  onClose,
  title,
  description,
  placement,
  position,
  cancelText = 'Cancel',
  okText = 'Ok',
  onCancel = () => {},
  onOk,
  ...rest
}) {
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
    enabled: isOpen
  });

  const styles = useStyleConfig('SlzPopoverConfirm', { variant });

  function handleClickOk() {
    onOk && onOk();
    onClose && onClose();
  }

  return (
    <Popover
      variant={variant}
      isLazy
      arrowShadowColor="none"
      arrowSize={32}
      gutter={20}
      placement={placement}
      isOpen={isOpen}
      strategy={'fixed'}
      {...rest}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        ref={ref}
        boxShadow="none"
        filter="drop-shadow(var(--sel-shadows-slz-lg))"
        rounded="2xl"
        sx={{ ...styles.content }}>
        <PopoverArrow sx={{ ...arrowStyle, ...styles.content?.arrow }} />
        <PopoverCloseButton color="dark.500" onClick={onClose} />
        <PopoverBody px={8} py={6} sx={{ ...styles.body }}>
          <Heading as="h5" fontSize="md" mb={1} sx={{ ...styles.body?.heading }}>
            {title}
          </Heading>
          <Text fontSize="sm" whiteSpace="pre-line" sx={{ ...styles.body?.description }}>
            {description}
          </Text>

          <HStack justifyContent="flex-end" spacing={2} mt={2}>
            {cancelText && (
              <SlzButton variant="outline" size="sm" colorScheme="negative" onClick={onCancel}>
                {cancelText}
              </SlzButton>
            )}
            {okText && (
              <SlzButton size="sm" colorScheme="negative" onClick={handleClickOk}>
                {okText}
              </SlzButton>
            )}
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
