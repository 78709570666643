import SlzTable from 'components/common/SlzTable/Table';
import { ADDRESS_BOOK_DISPLAY_FIELDS, ADDRESS_BOOK_SKELETON_CELL } from '../../constants';
import { useAddressBookContext } from '../../context/AddressBookContext';
import { ADDRESS_BOOK_COLUMNS } from './columns';

const columns = [...ADDRESS_BOOK_COLUMNS];

const AddressBookTable = (props) => {
  const { addressBook } = useAddressBookContext();
  const {
    currentPage,
    totalPage,
    onPageChange,
    handleActionSelect,
    handleClickOnName,
    selectedSearchResult
  } = props;

  columns[0].onClick = handleClickOnName;
  columns[4].onClick = handleActionSelect;

  return (
    <SlzTable
      isLoading={props?.isLoading}
      variant="addressBookList"
      overflowY="visible"
      overflowX="visible"
      sizes={['sm', 'md', 'lg']}
      columns={columns}
      onRefetching={props?.onRefetching}
      isEmptyPage={props?.isEmptyPage}
      messageToast={props?.messageToast}
      data={selectedSearchResult || addressBook?.list || Array(5).fill(ADDRESS_BOOK_SKELETON_CELL)}
      rowProps={{ hasExpandedRow: false }}
      emptyMsg="Your address book is empty. Add a recipient to get started."
      displayFields={ADDRESS_BOOK_DISPLAY_FIELDS}
      hyperLinks={['name']}
      pagination={{
        isLocal: false,
        showTitle: false,
        direction: {
          usingDots: false,
          totalPage,
          currentPage,
          isGreyBg: true,
          onChange: onPageChange
        }
      }}
    />
  );
};

export default AddressBookTable;
