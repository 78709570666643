import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const parts = ['button', 'buttonActive', 'optionContainer', 'title'];

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const buttonStyle = defineStyle({
  p: 0,
  w: '3.938rem',
  h: '1.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '0.063rem solid #C3C4CA',
  borderRadius: 'md',
  backgroundColor: 'transparent',
  color: 'dark.500',
  _hover: 'none',
  _active: 'none'
});

const buttonActiveStyle = defineStyle({
  ...buttonStyle,
  borderColor: 'main.500'
});

const optionContainerStyle = defineStyle({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  minW: 80,
  ps: 6,
  pt: 3.5,
  pb: 3.5,
  pe: 6,
  borderRadius: 'xl',
  boxShadow: '0 0.125rem 0.5rem #00000033',
  border: '0.063rem solid #C3C4CA'
});

const titleStyle = defineStyle({
  w: '100%',
  fontSize: '1rem',
  fontWeight: 'bold'
});

const baseStyle = definePartsStyle(() => ({
  button: buttonStyle,
  buttonActive: buttonActiveStyle,
  optionContainer: optionContainerStyle,
  title: titleStyle
}));

const sizes = {
  md: definePartsStyle({
    ...baseStyle
  }),
  sm: definePartsStyle({
    button: {
      ...buttonStyle,
      h: '1.75rem',
      w: '4.75rem'
    },
    buttonActive: {
      ...buttonActiveStyle,
      h: '1.75rem',
      w: '4.75rem'
    }
  }),
  users: definePartsStyle({
    optionContainer: {
      w: 'fit-content',
      minW: 'unset',
      ps: 3,
      pe: 3
    }
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md'
  }
});
