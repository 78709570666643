import { Heading, HStack, Icon } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const UserSettingHeader = ({ text, onBack }) => {
  return (
    <HStack spacing={1}>
      <Icon as={FaChevronLeft} fontSize={28} color="dark.500" cursor="pointer" onClick={onBack} />
      <Heading fontSize="2rem">Settings</Heading>
      <Icon as={FaChevronRight} fontSize={14} color="dark.500" cursor="pointer" />
      <Heading fontSize="2xl" fontWeight="medium">
        {text}
      </Heading>
    </HStack>
  );
};
export default UserSettingHeader;
