import { useQuery } from 'react-query';
import { getIOSSNumber } from '../services/preferences-api';
import iossNumberMapper from '../mappers/ioss-number-mapper';

export default function useGetIOSSNumberQuery(options) {
  return useQuery({
    queryKey: [`ioss-details`],
    queryFn: getIOSSNumber,
    select: (response) => iossNumberMapper(response.data?.data),
    ...options
  });
}
