import React from 'react';
import { Icon } from '@chakra-ui/react';

const EmissionFreeIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.333 12" fill="currentcolor" {...props}>
      <g xmlns="http://www.w3.org/2000/svg" id="Group_4724" data-name="Group 4724" clip-path="url(#clip-path)">
        <path 
          id="Path_9833" 
        	data-name="Path 9833" 
        	d="M6.667,6.667h-2A4.672,4.672,0,0,1,0,2V.667A.667.667,0,0,1,.667,0h2A4.672,4.672,0,0,1,7.333,4.667a.667.667,0,0,1-.667.667.667.667,0,1,1,0,1.333M1.333,1.333V2A3.337,3.337,0,0,0,4.667,5.333h2A.667.667,0,0,1,6,4.667,3.337,3.337,0,0,0,2.667,1.333Z" 
        	fill="#6ebd45"
        />
    		<path 
					id="Path_9834" 
					data-name="Path 9834" 
					d="M11.667,10h-2a.667.667,0,0,1-.577-1A.664.664,0,0,1,9,8.667,4.672,4.672,0,0,1,13.667,4h2a.667.667,0,0,1,.667.667v.667A4.672,4.672,0,0,1,11.667,10M10.333,8.667h1.333A3.337,3.337,0,0,0,15,5.333H13.667a3.337,3.337,0,0,0-3.333,3.333" 
					transform="translate(-3 -1.333)" 
					fill="#6ebd45"
				/>
    		<path 
					id="Path_9835" 
					data-name="Path 9835" 
					d="M9.667,14A.667.667,0,0,1,9,13.333V6.667a.667.667,0,1,1,1.333,0v6.667A.667.667,0,0,1,9.667,14" 
					transform="translate(-3 -2)" 
					fill="#6ebd45"
				/>
  		</g>
    </Icon>
  );
};

export default EmissionFreeIcon;
