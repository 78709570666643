import React from 'react';
import { IconButton, Tooltip, useStyles } from '@chakra-ui/react';
import { CollapseIcon, ExpandIcon } from '../Icons';

export default function CollapseButton({ isExpanded, onClick }) {
  const styles = useStyles();

  return (
    <Tooltip
      label="Expand menu"
      placement="top-start"
      fontSize="md"
      offset={[0, 5]}
      mt="-1.525rem"
      transform="translateY(10px)"
      bgColor="main.500"
      isDisabled={isExpanded}>
      <IconButton
        sx={styles.collapseButton}
        aria-label="Expand menu"
        icon={
          isExpanded ? (
            <CollapseIcon w={3.5} h="auto" transition="all 0.3s" />
          ) : (
            <ExpandIcon w={3.5} h="auto" transition="all 0.3s" />
          )
        }
        onClick={onClick}
      />
    </Tooltip>
  );
}
