import { Text, VStack } from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import B2BOrderSummaryRow from './B2BOrderSummaryRow';

const B2BOrderSummarySection = ({ title, rows }) => {
  return (
    <VStack w="50%" alignItems="flex-start">
      <Text fontWeight="medium" lineHeight={5.5}>
        {title}
      </Text>
      <VStack w="full" alignItems="flex-start" spacing={3}>
        {rows &&
          rows.map(({ label, value, children }) => {
            return (
              <B2BOrderSummaryRow key={uniqueId()} label={label} value={value}>
                {children}
              </B2BOrderSummaryRow>
            );
          })}
      </VStack>
    </VStack>
  );
};

export default B2BOrderSummarySection;
