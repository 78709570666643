import { BILLING_PATHNAME } from '../constants';
import BillingList from '../pages/billing-list';

const BillingRoutes = [
  {
    path: BILLING_PATHNAME,
    profile: 'Supplier',
    feature: 'ViewInvoice',
    exact: true,
    component: BillingList
  }
];

export default BillingRoutes;
