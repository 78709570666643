import { Icon } from '@chakra-ui/react';

const ConsignmentBoxIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="75.601"
      height="84"
      viewBox="0 0 75.601 84"
      fill="currentColor"
      {...props}>
      <g id="Group_259" data-name="Group 259" transform="translate(-189.775 -2147.551)">
        <path
          id="Path_3455"
          data-name="Path 3455"
          d="M227.575,2231.551a4.192,4.192,0,0,1-2.059-.54l-33.6-18.9a4.2,4.2,0,0,1-2.141-3.661v-37.8a4.2,4.2,0,0,1,2.141-3.66l33.6-18.9a4.2,4.2,0,0,1,4.118,0l33.6,18.9a4.2,4.2,0,0,1,2.141,3.66v37.8a4.2,4.2,0,0,1-2.141,3.661l-33.6,18.9A4.192,4.192,0,0,1,227.575,2231.551Zm-29.4-25.556,29.4,16.537,29.4-16.537v-32.886l-29.4-16.537-29.4,16.537Z"
          transform="translate(0.001 -0.001)"
        />
        <path
          id="Line_19"
          data-name="Line 19"
          d="M3.2,26.3A4.2,4.2,0,0,1,1.141,18.44l33.6-18.9a4.2,4.2,0,0,1,4.118,7.321l-33.6,18.9A4.182,4.182,0,0,1,3.2,26.3Z"
          transform="translate(224.375 2167.451)"
        />
        <path
          id="Line_20"
          data-name="Line 20"
          d="M3.2,45.2A4.2,4.2,0,0,1-1,41V3.2a4.2,4.2,0,0,1,8.4,0V41A4.2,4.2,0,0,1,3.2,45.2Z"
          transform="translate(224.376 2186.351)"
        />
        <path
          id="Line_21"
          data-name="Line 21"
          d="M36.8,26.3a4.181,4.181,0,0,1-2.055-.54l-33.6-18.9A4.2,4.2,0,1,1,5.26-.46l33.6,18.9A4.2,4.2,0,0,1,36.8,26.3Z"
          transform="translate(190.776 2167.451)"
        />
        <path
          id="Line_22"
          data-name="Line 22"
          d="M3.2,26.3A4.2,4.2,0,0,1,1.141,18.44l33.6-18.9a4.2,4.2,0,0,1,4.118,7.321l-33.6,18.9A4.182,4.182,0,0,1,3.2,26.3Z"
          transform="translate(207.575 2158.001)"
        />
      </g>
    </Icon>
  );
};

export default ConsignmentBoxIcon;
