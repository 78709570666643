import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from 'config/axios';

export async function getProducts() {
  let url = CommonEndpoints.STOCK.GET.ALL;
  return await axiosClient.request(url);
}

export async function uploadSingleConsignment(payload) {
  let url = CommonEndpoints.STOCK.PUT.ADD_STOCK;
  return await axiosClient.post(url, payload);
}

export async function uploadMultipleConsignment(files, data, numberToLoop) {
  const url = `${CommonEndpoints.STOCK.POST.UPLOAD_CSV}`;

  let formData = new FormData();
  files.forEach((f) => {
    formData.append('files', f.file, f.name);
  });
  formData.append('data', JSON.stringify(data));
  formData.append('numberToLoop', parseInt(numberToLoop));

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await axiosClient.post(url, formData, config);
}
