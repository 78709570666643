import { Flex, Image, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { random, sample } from 'lodash';
import { size } from 'lodash/size';
import { SlzChip } from 'components/common/SlzChip';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';
import { DATE_FORMAT_DDMMYYYY } from 'constants';
import SlzButton from 'components/common/SlzButton/SlzButton';
import BundleIcon from 'components/common/Icons/BundleIcon';

const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const PRODUCTS_ACTIONS = [
  {
    id: 1,
    label: 'Product Overview',
    onClick: (e, row) => {
      console.log(row);
    }
  },
  {
    id: 2,
    label: 'Sales & Pricing',
    onClick: () => {}
  },
  {
    id: 3,
    label: 'Packaging Preferences',
    onClick: () => {}
  },
  {
    id: 4,
    label: 'Return information',
    onClick: () => {}
  }
];

const tags = ['easy', 'hard', 'difficult', 'finished'];

const tagColors = {
  easy: '#bde0fe',
  hard: '#e9c46a',
  difficult: '#e9edc9',
  finished: '#e63946'
};

const getTags = () => {
  const tag = sample(tags);
  return {
    name: tag,
    color: tagColors[tag]
  };
};

export const fake = (numItems = 50) => {
  const data = [];
  const images = [
    'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    'https://image.shutterstock.com/image-vector/white-mens-tshirt-realistic-mock-260nw-705901483.jpg',
    'https://image.shutterstock.com/image-photo/golf-club-ball-grass-260nw-158881226.jpg'
  ];
  const names = ['Greens', 'Apple', 'Samsung', 'Vinfast'];

  let i = 0;
  while (i <= numItems) {
    data.push({
      id: `[products]-${uuidv4()}`,
      category: 'test-category',
      imageUrl: sample(images),
      name: sample(names),
      sku: 'SKU' + uuidv4(),
      tag: getTags(),
      suid: uuidv4(),
      shelfStock: random(1, 500),
      palletStock: random(1, 500),
      totalStock: random(1, 500),
      description:
        'Fusce dolor quam, elementum at, egestas a, scelerisque sed, sapien. Nunc pulvinar',
      ean: 'MHX64NSS2OM',
      selazarUniqueID: 'SSS-RCL78HMF8OG',
      status: 'LOW',
      createdDate: sample(['10/28/2022', '10/20/2022']),
      isPrePacked: true,
      depth: 56.1,
      width: 68.1,
      height: 66.1,
      weight: 65.1,
      commodityCode: '686378',
      countryCodeOfManufacture: 'US',
      brand: 'Elit Erat Industries',
      currentPrice: 42.32,
      units: 12,
      requiresBoxPackaging: false,
      requiresPaperPackaging: false,
      isFragile: false
    });
    i++;
  }
  return data;
};

export const productCols = [
  {
    name: '',
    field: 'image',
    width: 81,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
            px: '3px',
            py: 0
          }}>
          <Image
            w="full"
            h="full"
            sx={rowProps?.accordionProps?.imageStyles}
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true
  },
  {
    name: 'SKU',
    field: 'sku',
    isSorted: true,
    isCopyable: true,
    render: ({ row, field, paddingValue, onClick }) => {
      return (
        <Flex px={paddingValue} w="full">
          <SlzChip
            bg="light.700"
            color="dark.700"
            _hover={{
              bg: '#C3C4CB'
            }}
            onClick={onClick}>
            {row[field]}
          </SlzChip>
        </Flex>
      );
    }
  },
  {
    name: 'EAN/SUID',
    field: 'suid',
    isSorted: true,
    isCopyable: true
  },
  {
    name: 'Tag',
    field: 'tag',
    isEmphasis: true
  },
  {
    isSorted: true,
    field: 'totalStock',
    name: 'Stock',
    type: 'number'
  },
  {
    isSorted: false,
    field: 'createdDate',
    name: 'Date Created',
    type: 'date'
  },
  {
    name: '',
    field: 'cta',
    width: 110,
    type: COL_CUSTOM,
    render: ({ row, variant }) => {
      return (
        <Flex>
          <SlzButton
            size="md"
            sx={{
              borderRadius: '3px',
              bg: 'main.500',
              color: 'light.500'
            }}>
            Add Stock
          </SlzButton>
        </Flex>
      );
    }
  }
];

export const categoryCols = [
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true
  },
  {
    name: 'SKU',
    field: 'sku',
    isSorted: true
  },
  {
    name: 'EAN/SUID',
    field: 'suid',
    isSorted: true
  },
  {
    field: 'tag',
    name: 'Tag',
    isEmphasize: true
  },
  {
    isSorted: true,
    field: 'totalStock',
    name: 'Stock',
    type: 'number'
  }
];

/**
 * Convert data from Form to match with data product table
 * @param {any} value The number to raise.
 */
export const convertProduct = (value) => {
  return {
    tag: getTags(),
    ...value,
    categoryId: '', // Find category by name
    isFragile: convertStringToBoolean(value.isFragile),
    isPrePacked: convertStringToBoolean(value.isPrePacked),
    requiresBoxPackaging: convertStringToBoolean(value.requiresBoxPackaging),
    requiresPaperPackaging: convertStringToBoolean(value.requiresPaperPackaging),
    // seed data
    sku: 'SKU' + uuidv4(),
    id: `[products]-${uuidv4()}`,
    // tag: getTags(),
    suid: uuidv4(),
    totalStock: random(1, 500),
    createdDate: dayjs().format(DATE_FORMAT_DDMMYYYY)
  };
};

const convertStringToBoolean = (strValue) => {
  switch (String(strValue)?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;
    default:
      return JSON.parse(strValue);
  }
};

export const orderInformation = [
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  }
];
