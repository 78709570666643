import { Box, InputGroup, InputRightElement, Input, useStyleConfig } from '@chakra-ui/react';
import { ArrowRightIcon, CalendarIcon } from '../../Icons';
import { DATE_FORMAT_DDMMYY, TODAY } from '../../../../constants';
import dayjs from 'dayjs';
function SlzDateSingleInput(props) {
  const { colorScheme, className, size } = props;
  const { startDatePlaceHolder } = props;

  const handleOpenDateSingleCalendar = () => {
    props?.onDateSingleInputClick();
  };

  const renderDate = (date) => {
    return date
      ? date?.format(DATE_FORMAT_DDMMYY) == dayjs().format(DATE_FORMAT_DDMMYY)
        ? TODAY
        : date?.format(DATE_FORMAT_DDMMYY)
      : null;
  };

  const dateSingleInputStyle = useStyleConfig('SlzDateRangeInput', { colorScheme, size });
  const { container, inputGroup, h, rounded } = dateSingleInputStyle;
  return (
    <Box className={className} onClick={handleOpenDateSingleCalendar} sx={{...container, h, rounded}}>
      <Box sx={{ width: '100%' }}>
        <InputGroup size="sm" sx={inputGroup}>
          <Input
            data-testid="slz-date-single-start-date"
            isReadOnly
            type="input"
            _focusVisible={{
              outline: 'none'
            }}
            value={renderDate(props.startDate) ?? startDatePlaceHolder}
          />
          <InputRightElement
            sx={inputGroup.rightElement}
            h="100%"
            pointerEvents="none"
            children={
              <CalendarIcon
                width="1rem"
                height="1rem"
                data-testid="slz-date-single-input-calendar-icon"
                color="#0973EA"
              />
            }
          />
        </InputGroup>
      </Box>
    </Box>
  );
}
export default SlzDateSingleInput;
