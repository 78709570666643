import axios from 'axios';
import get from 'lodash/get';
import { authHeader, getUser, logout } from '../Utilities';
import dayjs from 'dayjs';

const singleton = Symbol();
const singletonEnforcer = Symbol();

function readCookie(name) {
  const match = document.cookie.match(new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'));
  return match ? decodeURIComponent(match[3]) : null;
}

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.session = axios.create({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': readCookie('csrftoken')
      }
    });
    this.session.interceptors.request.use(function (config) {
      const user = getUser();
      config.headers.Authorization = user?.token ? `Bearer ${user.token}` : '';
      return config;
    });

    this.session.interceptors.response.use(
      (response) => response,
      (error) => {
        // const status = get(error, 'response.status');
        // if (status === 401) {
        //   logout();
        // }

        return Promise.reject(error);
      }
    );

    this.session.interceptors.request.use((request) => {
      const user = getUser() || {};

      const { expiry } = user;
      if (!!expiry && dayjs().isAfter(dayjs(new Date(expiry)))) {
        logout();
      }

      return request;
    });
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  setAuth() {
    this.session.defaults.headers = {
      ...this.session.defaults.headers,
      ...authHeader()
    };
  }

  get = async (...params) => this.session.get(...params);
  post = async (...params) => this.session.post(...params);
  put = async (...params) => this.session.put(...params);
  patch = async (...params) => this.session.patch(...params);
  remove = async (...params) => this.session.delete(...params);

  request = async (...params) => {
    const onSuccess = function (response) {
      const { data } = response;
      return data?.data || [];
    };

    const onError = function (error) {
      return Promise.reject(error.response);
    };

    return this.session(...params)
      .then(onSuccess)
      .catch(onError);
  };
}

export default ApiService.instance;
