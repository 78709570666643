import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { THead, Tr, Th } from './SlzTable';
import SlzCalendarNext from './SlzCalendarNext';
import SlzCalendarPrev from './SlzCalendarPrev';
import SlzCalendarTitle from './SlzCalendarTitle';
import { COL_SPAN_5, COL_SPAN_6 } from '../../../constants';
export default class SlzCalendarHeader extends React.Component {
  static defaultProps = {
    showISOWeekNumbers: false,
    showWeekNumbers: false,
    showNext: true,
    showPrev: true,
    nextDate: (calendar) => calendar.add(1, 'month'),
    prevDate: (calendar) => calendar.subtract(1, 'month')
  };

  createNextProps = () => {
    const { showNext, calendar, nextDate, handleNext, isDisableRightButton, position } = this.props;
    const date = nextDate(calendar);
    return {
      next: showNext,
      position,
      isDisableRightButton,
      calendar: date,
      handleNext
    };
  };

  createPrevProps = () => {
    const { showPrev, calendar, prevDate, handlePrev, position } = this.props;
    const date = prevDate(calendar);
    return {
      prev: showPrev,
      position,
      calendar: date,
      handlePrev
    };
  };

  renderTitle = () => {
    const {
      showWeekNumbers,
      calendar,
      isDisableRightButton,
      locale,
      showDropdowns,
      handleSelected,
      position
    } = this.props;
    let showISOWeekNumbers = false;
    const colSpan = showISOWeekNumbers || showWeekNumbers ? COL_SPAN_6 : COL_SPAN_5;
    const props = {
      colSpan,
      calendar,
      locale,
      position,
      showDropdowns,
      isDisableRightButton,
      handleSelected
    };
    return <SlzCalendarTitle {...props} />;
  };

  renderNext = () => {
    const props = this.createNextProps();
    return <SlzCalendarNext {...props} />;
  };

  renderPrev = () => {
    const props = this.createPrevProps();
    return <SlzCalendarPrev {...props} />;
  };

  renderWeeks = () => {
    const { locale } = this.props;
    const { weekNames, weekLabel } = locale;
    const showWeeks = false;
    const weeks = showWeeks ? [weekLabel].concat(weekNames) : weekNames;
    const WeekData = weeks.map((children, key) => {
      const className = classNames({
        week: !!(key === 0 && showWeeks)
      });
      const thProps = {
        children,
        className,
        key
      };
      return <Th {...thProps} key={`${key}+th`} />;
    });
    return <Tr>{WeekData}</Tr>;
  };
  render() {
    return (
      <THead>
        <Tr>
          {this.renderTitle()}
          <td className="action-calendar-btn">
            {this.renderPrev()}
            {this.renderNext()}
          </td>
        </Tr>
        {this.renderWeeks()}
      </THead>
    );
  }
}
