import { NestedInput } from 'components/common/SlzForms/base/NestedInput';

export const BaseInput = ({ fieldProps, ...rest }) => (
  <NestedInput
    {...rest}
    labelProps={{ color: 'dark.700' }}
    formControlProps={{ mt: '3px !important' }}
    fieldProps={{
      bgColor: 'light.300',
      _hover: { borderColor: 'stone.500' },
      _focusVisible: { borderColor: 'stone.500' },
      borderColor: 'stone.500',
      readonly: false,
      ...fieldProps
    }}
    showTooltip={false}
  />
);
