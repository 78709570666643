import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { Container } from 'react-bootstrap';

const Fragile = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="0.787rem"
            h="1.125rem"
            viewBox="0 0 12.595 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Union_323" data-name="Union 323" d="M6368.3,20989h-3.6a.9.9,0,0,1,0-1.8h2.7v-3.65a6.678,6.678,0,0,1-3.481-1.381,5.015,5.015,0,0,1-1.917-3.965,1.013,1.013,0,0,1,.01-.127l.9-6.3a.894.894,0,0,1,.89-.773h4.3a.355.355,0,0,1,.064,0h4.636a.9.9,0,0,1,.89.773l.9,6.3a.485.485,0,0,1,.011.127,5,5,0,0,1-1.917,3.965,6.735,6.735,0,0,1-3.482,1.387v3.645h2.7a.9.9,0,1,1,0,1.8Zm-4.5-10.74a3.221,3.221,0,0,0,1.243,2.508,5.637,5.637,0,0,0,6.505,0,3.238,3.238,0,0,0,1.249-2.508l-.78-5.457h-3.5l-.626,2.076,2.022,1.443a.694.694,0,0,1,.2.906l-1.385,2.422a.685.685,0,0,1-.6.348.674.674,0,0,1-.348-.09.688.688,0,0,1-.253-.941l1.075-1.881-1.917-1.369a.679.679,0,0,1-.258-.76l.646-2.154h-2.49Z" transform="translate(-6362 -20971)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Fragile;