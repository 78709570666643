import React from 'react';
import { Icon } from '@chakra-ui/react';

const CompassIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentcolor" {...props}>
      <g
        id="Group_33709"
        data-name="Group 33709"
        transform="translate(480.887 -1200.773) rotate(90)">
        <circle
          id="Ellipse_95"
          data-name="Ellipse 95"
          cx="9"
          cy="9"
          r="9"
          transform="translate(1201.773 461.887)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3679"
          data-name="Path 3679"
          d="M1210.773,475.887l-1-4-4-1,9-4Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default CompassIcon;
