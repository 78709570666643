import OrderDetail from './OrderDetail';

const OrderMultipleShipment = (props) => {
  return (
    <OrderDetail {...props}>
      <OrderDetail.header />
      <OrderDetail.nav />
      <OrderDetail.footer />
      <OrderDetail.accordionTable />
    </OrderDetail>
  );
};

export default OrderMultipleShipment;
