import React from 'react';
import { Icon } from '@chakra-ui/react';

const SendConsignmentIcon = ({ color = '#303134', ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      color={color}
      {...props}>
      <g id="Group_33675" data-name="Group 33675" transform="translate(-1777.411 -268.887)">
        <path
          id="Path_4530"
          data-name="Path 4530"
          d="M1785.88,285.879l-7.107-4v-8l7.107-4,7.107,4v4"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4531"
          data-name="Path 4531"
          d="M1786.773,278.385l7.107-4"
          transform="translate(-0.893 -0.502)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4532"
          data-name="Path 4532"
          d="M1786.773,278.887v8"
          transform="translate(-0.893 -1.004)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4533"
          data-name="Path 4533"
          d="M1785.88,278.385l-7.107-4"
          transform="translate(0 -0.502)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4534"
          data-name="Path 4534"
          d="M1789.773,284.887h6.219"
          transform="translate(-1.227 -1.674)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4535"
          data-name="Path 4535"
          d="M1793.773,281.887l2.665,2.665-2.665,2.665"
          transform="translate(-1.674 -1.339)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default SendConsignmentIcon;
