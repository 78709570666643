import { useQuery } from 'react-query';
import { getCurrentPreferences } from '../services/integration-api';

export const useCurrentPreferences = (enabled) => {
  return useQuery({
    queryKey: [`current-preferences`],
    queryFn: () => getCurrentPreferences(),
    enabled: enabled
  });
};
