import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { dayjs } from '../../../utils';
import { Table } from '../CalendarCommonComponents/SlzTable';
import CalendarHead from '../CalendarCommonComponents/SlzCalendarHead';
import SlzCalendarBody from '../CalendarCommonComponents/SlzCalendarBody';
import SlzPickerUI from './SlzPickerUI';
import { Box, useStyleConfig } from '@chakra-ui/react';

const SlzUnlinkedCalendar = (props) => {
  const { isShowFuture } = props;
  const [leftCalendar, setLeftCalendar] = useState(null);
  const [rightCalendar, setRightCalendar] = useState(null);
  const [isDisableLeft, setIsDisableLeft] = useState(false);
  const [isDisableRight, setIsDisableRight] = useState(false);

  useEffect(() => {
    let lCalendar = props.startDate;

    let temp = props.endDate ?? dayjs();
    if (!lCalendar || renderLeftCalendar()) {
      lCalendar = temp.subtract(1, 'month');
    }
    setLeftCalendar(lCalendar);
    setRightCalendar(temp);
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    if (!isShowFuture) {
      leftCalendar?.format('MM/YYYY') == dayjs().subtract(1, 'month').format('MM/YYYY')
        ? setIsDisableLeft(true)
        : setIsDisableLeft(false);

      rightCalendar?.format('MM/YYYY') == dayjs().format('MM/YYYY')
        ? setIsDisableRight(true)
        : setIsDisableRight(false);
    }
  }, [leftCalendar, rightCalendar]);

  const renderLeftCalendar = () => {
    return (
      props?.startDate &&
      (props.startDate.format('MM/YYYY') == props?.endDate?.format('MM/YYYY') ||
        props.startDate.format('MM/YYYY') == dayjs()?.format('MM/YYYY'))
    );
  };
  const handlePrev = (leftCal) => {
    let isDisable = false;
    if (leftCal && leftCal >= rightCalendar) {
      setRightCalendar(leftCal.add(1, 'month'));
      isDisable = true;
    }
    setLeftCalendar(leftCal);
    !isShowFuture && setIsDisableLeft(isDisable);
  };

  const handleNext = (rightCal) => {
    let isDisable = false;
    if (rightCal && rightCal <= leftCalendar.add(1, 'month')) {
      isDisable = true;
      setLeftCalendar(rightCal.subtract(1, 'month'));
    }
    setRightCalendar(rightCal);
    !isShowFuture && setIsDisableRight(isDisable);
  };

  const createProps = () => {
    const leftState = Object.assign({}, props, {
      calendar: leftCalendar ?? dayjs().subtract(1, 'month')
    });

    const rightState = Object.assign({}, props, {
      calendar: rightCalendar ?? dayjs()
    });

    return {
      leftProps: {
        handlePrev,
        handleNext: handlePrev,
        handleSelected: handlePrev,
        ...leftState,
        isDisableRightButton: isDisableLeft,
        showWeekNumbers: false,
        position: 'left',
        opens: 'left'
      },
      rightProps: {
        handleSelected: handleNext,
        handlePrev: handleNext,
        handleNext,
        ...rightState,
        showWeekNumbers: false,
        isDisableRightButton: isDisableRight,
        position: 'right',
        opens: 'right'
      }
    };
  };

  const renderTable = () => {
    const propsTable = createProps();
    const { leftProps, rightProps } = propsTable;
    const leftClassName = classNames({
      'drp-calendar': true,
      left: true
    });
    const rightClassName = classNames({
      'drp-calendar': true,
      right: true
    });
    return [
      <Box className={leftClassName} key={0}>
        <Box className={'calendar-table'}>
          <Table className="table-condensed">
            <CalendarHead {...leftProps} />
            <SlzCalendarBody {...leftProps} />
          </Table>
        </Box>
      </Box>,
      <Box className={rightClassName} key={1}>
        <Box className="calendar-table">
          <Table className="table-condensed">
            <CalendarHead {...rightProps} />
            <SlzCalendarBody {...rightProps} />
          </Table>
        </Box>
      </Box>
    ];
  };

  const { opens, children } = props;
  const className = classNames({
    [`opens${opens}`]: true,
    'daterangepicker ltr show-calendar': true
  });

  return (
    <div
      className={className}
      style={{
        left: 'auto'
      }}>
      {renderTable()}
      {children}
    </div>
  );
};

export default function UnlinkedCalendarUI(props) {
  const { selectedDefinedRange } = props;
  const onHandleDateRangeClick = (startDate, endDate, id) => {
    props?.onDateRangeClick(startDate, endDate, id);
  };
  const handleClearSelection = () => {
    props.onClearSelection();
  };
  const styles = useStyleConfig('SlzDatePickerUI', props);
  const uiProps = { ...props, styles, component: SlzUnlinkedCalendar };
  return (
    <SlzPickerUI
      {...uiProps}
      onDateRangeClick={onHandleDateRangeClick}
      onClearSelection={handleClearSelection}
      selectedDefinedRange={selectedDefinedRange}
    />
  );
}
