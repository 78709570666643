import CommonEndpoints from '../../../components/common/Endpoints';
import { buildQuery } from '../../../utils/helper';
import axiosClient from '../../../config/axios';

export async function getConsignments(filters) {
  const searchParams = {
    ...filters,
    orderDescending: filters?.isDesc
  };
  let url = new URL(CommonEndpoints.STOCK.GET.CONSIGNMENTS_BY);
  return await axiosClient.request(buildQuery(url, searchParams));
}

export async function getConsignmentDetail(consignmentId) {  
  let url = `${CommonEndpoints.STOCK.GET.BY_ID_WITH_ITEMS}/${consignmentId}`;
  return await axiosClient.request(url);
}

export async function getWarehouses() {
  return await axiosClient.request(CommonEndpoints.RETAILER.GET.WAREHOUSES);
}

export async function checkIfUsesExternalWMS() {
  const usesExternalWMS = await axiosClient.request(CommonEndpoints.WAREHOUSE.GET.USES_EXTERNAL_WMS);
  return usesExternalWMS && usesExternalWMS.length !== 0;
}

export async function getLabel(consignmentId, option) {
  let url = `${CommonEndpoints.STOCK.GET.LABEL_DOWNLOAD}/${consignmentId}/${option}`;
  const response = await axiosClient.get(url, { responseType: 'blob' });
  return new Blob(['\ufeff', response.data], { type: response.headers['content-type'], encoding: 'utf-16be' });
}

export async function affixLabels(consignmentId) {
  let url = `${CommonEndpoints.STOCK.PUT.AFFIX_LABELS}/${consignmentId}`;
  return await axiosClient.put(url);
}
