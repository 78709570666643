import React from 'react';
import { Icon } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

const TickIcon = ({ color = 'main', ...props }) => {
  const { colors } = useTheme();

  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
      <g id="Group_4545" data-name="Group 4545" transform="translate(11970.15 -23180.141)">
        <ellipse
          id="Ellipse_1213"
          data-name="Ellipse 1213"
          cx="5"
          cy="5"
          rx="5"
          ry="5"
          transform="translate(-11970.15 23180.141)"
          fill={colors[color][500]}
        />
        <path
          id="Back_Stroke_"
          data-name="Back (Stroke)"
          d="M2.815,3.953l-.222-.221-.223.221A.316.316,0,0,0,2.815,3.953ZM2.593,3.29,1.137,1.842a.316.316,0,0,0-.445,0,.312.312,0,0,0,0,.442L2.37,3.953l.223-.221.222.221L6.053.734a.312.312,0,0,0,0-.442.316.316,0,0,0-.445,0Z"
          transform="translate(-11968.522 23183.039)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  );
};

export default TickIcon;
