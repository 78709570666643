import dayjs from 'dayjs';

import { DATE_FORMAT_DDMMYYYY } from '../../../constants';
import { NO_ACCESS_PERMISSION_OPTION } from '../constants';

export const transformIntegrations = (data) =>
  data.map((item) => {
    const metadata = item.metadata && JSON.parse(item.metadata);
    const isConfiguredRateMatcher = metadata?.shopifyShippingRatePreferences?.some(
      (shippingRate) => shippingRate.shippingRateID !== null
    );
    return {
      ...item,
      createdDate: dayjs(item.createdDate).format(DATE_FORMAT_DDMMYYYY),
      dateOfIntegration: dayjs(item.dateOfIntegration).format(DATE_FORMAT_DDMMYYYY),
      inventoryManagement: metadata?.inventoryManagement,
      isEditable: true,
      metadata,
      isConfiguredRateMatcher
    };
  });

export const transformIntegrationDetail = (data) => {
  const metadata = data?.metadata && JSON.parse(data.metadata);
  return {
    ...data,
    metadata
  };
};

export const transformPayloadIntegration = (data) => {
  return {
    ...data,
    fulfillmentService: data?.metadata?.fulfillmentService,
    storeUrl: data?.metadata?.storeUrl,
    username: data?.metadata?.username
  };
};

export const transformAPIConnection = (data) =>
  data.map((api) => {
    return {
      ...api,
      createdDate: dayjs(api.createdDate).format(DATE_FORMAT_DDMMYYYY)
    };
  });

export const transformApiDetail = (data) => {
  const permissions = data.permissions.reduce((acc, permission) => {
    const selectedPerm = permission.modulePermissions.find((perm) => perm.access);
    if (selectedPerm) {
      return {
        ...acc,
        [permission.name]: { value: selectedPerm.id, label: selectedPerm.friendlyName }
      };
    }
    return {
      ...acc,
      [permission.name]: { ...NO_ACCESS_PERMISSION_OPTION }
    };
  }, {});

  return {
    ...data,
    inventorySQSSettings: {
      ...data?.inventorySQSSettings,
      url: data?.inventorySQSSettings?.url ?? '',
      accessKey: data?.inventorySQSSettings?.accessKey ?? '',
      privateKey: data?.inventorySQSSettings?.privateKey ?? ''
    },
    ordersSQSSettings: {
      ...data?.ordersSQSSettings,
      url: data?.ordersSQSSettings?.url ?? '',
      accessKey: data?.ordersSQSSettings?.accessKey ?? '',
      privateKey: data?.ordersSQSSettings?.privateKey ?? ''
    },
    permissions
  };
};
