import { Box, FormControl, HStack, Text, VStack, color } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import {
  CONSIGNMENT_BOX_LOCATION_TEXT,
  PALLET_LOCATION_TEXT
} from 'modules/consignments/constants';

const ConsignmentDetails = (props) => {
  const { consignmentDetails, isLoading } = props;
  return (
    <SlzPaper
      w={props.w}
      h={props.h}
      minH="15.625rem"
      borderRadius="0.375rem"
      bg="light.500"
      overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <>
          <VStack>
            <Box
              w="100%"
              borderBottomWidth="0.188rem"
              borderBottomColor="light.300"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {props.title}
            </Box>
            <Box
              bg="light.500"
              w="100%"
              h="fit-content"
              pl="1.563rem"
              pr="2.375rem"
              pt="1rem"
              pb="2rem">
              <Text color="dark.700" fontSize="sm" fontWeight="bold">
                Consignment type
              </Text>
              <Box
                rounded="0.375rem"
                w="fit-content"
                h="1.5rem"
                py="0.313rem"
                fontSize="sm"
                color="main.500"
                px="0.75rem"
                bgColor="main.300"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb="1rem">
                {consignmentDetails?.isPallet
                  ? PALLET_LOCATION_TEXT
                  : CONSIGNMENT_BOX_LOCATION_TEXT}
              </Box>
              {!consignmentDetails?.isPallet && (
                <HStack alignItems="flex-start" justifyContent="space-between" mb="1.25rem">
                  <Box w="full">
                    <SlzFormLabel fontSize="sm" color="dark.700" me={0} whiteSpace="nowrap">
                      Consignment box dimensions (cm)
                    </SlzFormLabel>
                    <HStack>
                      <Box w="4.678rem">
                        <SlzInput
                          fontSize="sm"
                          textAlign="right"
                          leftElContent="H"
                          color="dark.700 !important"
                          size="xs"
                          bgColor="light.300 !important"
                          value={consignmentDetails?.consignmentBoxDimensions?.height}
                          disabled
                        />
                      </Box>
                      <Box w="4.678rem">
                        <SlzInput
                          fontSize="sm"
                          leftElContent="W"
                          textAlign="right"
                          color="dark.700 !important"
                          size="xs"
                          bgColor="light.300 !important"
                          value={consignmentDetails?.consignmentBoxDimensions?.width}
                          disabled
                        />
                      </Box>
                      <Box w="4.678rem">
                        <SlzInput
                          fontSize="sm"
                          leftElContent="D"
                          textAlign="right"
                          color="dark.700 !important"
                          size="xs"
                          bgColor="light.300 !important"
                          value={consignmentDetails?.consignmentBoxDimensions?.depth}
                          disabled
                        />
                      </Box>
                    </HStack>
                  </Box>
                </HStack>
              )}
              <HStack alignItems="flex-start" justifyContent="space-between">
                <Box w="full">
                  <SlzFormLabel fontSize="sm" color="dark.700" me={0} whiteSpace="nowrap">
                    Items
                  </SlzFormLabel>
                  <HStack>
                    <Box w="6.438rem">
                      <SlzInput
                        fontSize="sm"
                        textAlign="right"
                        color="dark.700 !important"
                        size="xs"
                        bgColor="light.300 !important"
                        value={consignmentDetails?.totalItems}
                        disabled
                      />
                    </Box>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </>
      )}
    </SlzPaper>
  );
};

export default ConsignmentDetails;
