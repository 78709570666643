import { Redirect, Route } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import {
  FORGOT_PASSWORD_PATHNAME,
  REGISTER_EMAIL_CONFIRM_PATHNAME,
  REGISTER_PATHNAME,
  REGISTER_RESULT_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  SUCCESS_PASSWORD_PATHNAME
} from 'modules/users/constants';
import { ForgotPassword, Login, ResetPassword, SuccessPassword } from 'modules/users/pages';
import RegisterPage from 'modules/users/pages/register';
import CheckInboxPage from 'modules/users/pages/check-inbox';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { isLogged } = useAuth();
  const isAuthenticated = isLogged();

  return (
    <Route
      render={(props) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/dashboard" />
      }
      {...rest}
    />
  );
};

const PublicRoutes = [
  { path: '/', component: Login, exact: true },
  { path: REGISTER_PATHNAME, component: RegisterPage },
  { path: REGISTER_RESULT_PATHNAME, component: CheckInboxPage },
  { path: REGISTER_EMAIL_CONFIRM_PATHNAME, component: SuccessPassword },
  { path: FORGOT_PASSWORD_PATHNAME, component: ForgotPassword },
  { path: SUCCESS_PASSWORD_PATHNAME, component: SuccessPassword },
  { path: RESET_PASSWORD_PATHNAME, component: ResetPassword }
];

export default PublicRoutes;
