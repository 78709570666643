import CommonEndpoints from 'components/common/Endpoints';
import { buildQuery } from 'utils/helper';
import axiosClient from 'config/axios';

const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export async function getProducts(filters) {
  const url = new URL(CommonEndpoints.STOCK.GET.BY);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function getProductDetail(productId, skuID) {
  const url = `${CommonEndpoints.STOCK.GET.BY_ID}${productId}/${skuID}`;
  const result = await axiosClient.request(url);
  return result;
}

export async function createProduct(payload) {
  const result = await axiosClient.post(CommonEndpoints.STOCK.POST.CREATE, payload);
  return result;
}

export async function uploadCSV(files) {
  let formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file, file.name);
  });

  const result = await axiosClient.post(
    CommonEndpoints.STOCK.POST.UPLOAD_ITEM_CSV,
    formData,
    config
  );
  return result;
}

export async function uploadImageForCSV(file, itemId, sku) {
  let formData = new FormData();
  formData.append('files', file, file.name);

  const url = `${CommonEndpoints.STOCK.POST.UPLOAD_IMAGE}/${itemId}/${sku}`;

  return await axiosClient.post(url, formData, config);
}

export async function uploadSingleImage(files, productId) {
  let formData = new FormData();
  formData.append('files', files, files.name);

  const url = productId
    ? `${CommonEndpoints.STOCK.POST.UPLOAD_IMAGE}/${productId}`
    : CommonEndpoints.STOCK.POST.UPLOAD_IMAGE;

  return await axiosClient.post(url, formData, config);
}

export async function getProductReturnSettings(productId) {
  const url = `${CommonEndpoints.STOCK.GET.GET_RETURN_INFO_BY_ID}${productId}`;
  const result = await axiosClient.request(url);
  return result;
}

export async function getReturnConfig() {
  const url = `${CommonEndpoints.RETAILER.GET.CONFIGURATION_RETURNS}`;
  const result = await axiosClient.request(url);
  return result;
}

export async function updateProduct(payload) {
  const result = await axiosClient.put(CommonEndpoints.STOCK.PUT.UPDATE, payload);
  return result;
}

export async function setProductReturnSettings(productId, payload) {
  const url = `${CommonEndpoints.STOCK.POST.SET_RETURN_INFO_BY_ID}${productId}`;
  const result = await axiosClient.post(url, payload);
  return result;
}
