import { CONSIGNMENT_ARRIVING_TYPES } from "../constants";

export const transformMultipleProductConsignment = (consignment) => {
  const data = {
    supplierPrintedLabels: consignment?.enabledLabelling,
    shippingReference: consignment?.shippingReference,
    isPallet: consignment?.consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.PALLETS,	
    boxHeight: consignment?.consignmentBoxDimensions?.height,
    boxWidth: consignment?.consignmentBoxDimensions?.width,
    boxDepth: consignment?.consignmentBoxDimensions?.depth,
    boxWeight: consignment?.consignmentBoxDimensions?.weight,
    pickFromPallet: '',
    pickFromPallet: consignment?.consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.PALLETS,
    ignore_whitespace: false
  }

  return data;
};