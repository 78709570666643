import { useQuery } from 'react-query';
import { checkCreditCard } from '../services/payment-apis';

export default function useCheckCreditCard() {
  return useQuery({
    queryKey: ['check-credit-card'],
    queryFn: checkCreditCard,
    select: (value) => value.data?.data
  });
}
