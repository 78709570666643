import React from 'react';
import { Box, Switch } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

export const SlzToggle = React.forwardRef((props, ref) => {
  const { error, previousLabel, children, onChange, onToggle, ...rest } = props;

  const switchHandler = (e) => {
    onChange && onChange(e);
    onToggle && onToggle(e);
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'start'}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Toggle`)}
      flexDirection="column">
      <Box display={'flex'} alignItems="center">
        {previousLabel}
        <Switch ref={ref} {...rest} onChange={switchHandler}>
          {children}
        </Switch>
      </Box>
      {error && props?.isInvalid && (
        <Box>
          <Box as="span" sx={{ color: '#DE4259' }}>{`*${error}`}</Box>
        </Box>
      )}
    </Box>
  );
});
