import { Box, Text } from '@chakra-ui/react';

const WrapField = ({ text, children }) => {
  return (
    <Box position="relative" flex="0 0 29%">
      <Text
        as="i"
        sx={{
          position: 'absolute',
          top: -5,
          right: 1,
          color: 'dark.500',
          fontSize: '0.75rem',
          textAlign: 'right'
        }}>
        {text}
      </Text>
      {children}
    </Box>
  );
};

export default WrapField;
