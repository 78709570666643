import { createIcon } from "@chakra-ui/react";
const AmericanExpressIcon = createIcon({
    displayName: "AmericanExpressIcon",
    viewBox: "0 0 135.345 48.971",
    path: (
        <g id="Group_316" data-name="Group 316">
            <path id="Path_1531" data-name="Path 1531" d="M193.64,559.686v-21.2h22.443l2.408,3.139,2.488-3.139h81.463v19.734a9.407,9.407,0,0,1-4.594,1.462H252.739l-2.715-3.341v3.341h-8.9v-5.7a7.463,7.463,0,0,1-3.842.8h-3.028v4.907h-13.47l-2.4-3.206-2.441,3.206Z" transform="translate(-167.432 -510.715)" fill="#fff" />
            <path id="Path_1532" data-name="Path 1532" d="M0,345.076l5.061-11.8h8.753l2.872,6.609v-6.609h10.88l1.71,4.777,1.658-4.777h48.84v2.4a10.537,10.537,0,0,1,6.787-2.4l15.847.056,2.822,6.523v-6.578h9.105l2.506,3.747v-3.747h9.189v21.2h-9.189l-2.4-3.759v3.759H101.062l-1.345-3.341h-3.6L94.8,354.474H85.725a8.967,8.967,0,0,1-5.952-2.353v2.353H66.1l-2.715-3.341v3.341H12.517l-1.344-3.341H7.588l-1.335,3.341H0Z" transform="translate(0 -333.277)" fill="#fff" />
            <path id="Path_1533" data-name="Path 1533" d="M7.019,352.584.193,368.455H4.637l1.26-3.178h7.322l1.253,3.178h4.542l-6.82-15.871Zm2.526,3.694,2.232,5.554H7.306Z" transform="translate(-0.167 -349.971)" fill="#016fd0" />
            <path id="Path_1534" data-name="Path 1534" d="M142.723,368.436V352.564l6.315.023,3.673,10.233,3.585-10.256h6.265v15.871h-3.968V356.741l-4.206,11.695h-3.48l-4.218-11.695v11.695Z" transform="translate(-123.406 -349.954)" fill="#016fd0" />
            <path id="Path_1535" data-name="Path 1535" d="M309.361,368.436V352.564h12.948v3.55h-8.938v2.715H322.1v3.341h-8.729v2.819h8.938v3.446Z" transform="translate(-267.491 -349.954)" fill="#016fd0" />
            <path id="Path_1536" data-name="Path 1536" d="M422,352.584v15.871h3.968v-5.638h1.671l4.757,5.638h4.849l-5.221-5.847a4.833,4.833,0,0,0,4.353-4.875c0-3.34-2.621-5.149-5.547-5.149Zm3.968,3.55H430.5a1.821,1.821,0,0,1,1.879,1.671,1.793,1.793,0,0,1-1.821,1.671h-4.594Z" transform="translate(-364.882 -349.971)" fill="#016fd0" />
            <rect id="Rectangle_993" data-name="Rectangle 993" width="4.051" height="15.871" transform="translate(73.112 2.61)" fill="#016fd0" />
            <path id="Path_1537" data-name="Path 1537" d="M592.067,368.435h-.875c-4.231,0-6.8-3.333-6.8-7.87,0-4.649,2.54-8,7.883-8h4.385v3.759h-4.546c-2.169,0-3.7,1.693-3.7,4.281,0,3.074,1.754,4.365,4.281,4.365h1.044Z" transform="translate(-505.298 -349.954)" fill="#016fd0" />
            <path id="Path_1538" data-name="Path 1538" d="M661.278,352.584l-6.826,15.871H658.9l1.26-3.178h7.322l1.253,3.178h4.542l-6.82-15.871Zm2.526,3.694,2.232,5.554h-4.47Z" transform="translate(-565.875 -349.971)" fill="#016fd0" />
            <path id="Path_1539" data-name="Path 1539" d="M796.933,368.436V352.564h5.045l6.441,9.972v-9.972h3.968v15.871h-4.881L800.9,358.2v10.233Z" transform="translate(-689.072 -349.954)" fill="#016fd0" />
            <path id="Path_1540" data-name="Path 1540" d="M213.7,573.648V557.777h12.948v3.55h-8.938v2.715h8.729v3.341h-8.729V570.2h8.938v3.446Z" transform="translate(-184.775 -527.392)" fill="#016fd0" />
            <path id="Path_1541" data-name="Path 1541" d="M682.446,573.648V557.777h12.948v3.55h-8.938v2.715h8.687v3.341h-8.687V570.2h8.938v3.446Z" transform="translate(-590.081 -527.392)" fill="#016fd0" />
            <path id="Path_1542" data-name="Path 1542" d="M312.115,573.648l6.3-7.838-6.454-8.033h5l3.844,4.966,3.857-4.966h4.8l-6.369,7.936,6.316,7.936h-5l-3.732-4.888-3.641,4.888Z" transform="translate(-269.742 -527.392)" fill="#016fd0" />
            <path id="Path_1543" data-name="Path 1543" d="M444.369,557.8v15.871h4.072v-5.012h4.177c3.534,0,6.213-1.875,6.213-5.521,0-3.02-2.1-5.338-5.7-5.338h-8.764Zm4.072,3.589h4.4a1.791,1.791,0,0,1,1.958,1.827,1.831,1.831,0,0,1-1.971,1.827h-4.385Z" transform="translate(-384.226 -527.409)" fill="#016fd0" />
            <path id="Path_1544" data-name="Path 1544" d="M563.948,557.777v15.871h3.968V568.01h1.671l4.757,5.638h4.849l-5.221-5.847a4.833,4.833,0,0,0,4.353-4.875c0-3.34-2.621-5.149-5.547-5.149Zm3.968,3.55h4.536A1.821,1.821,0,0,1,574.331,563a1.793,1.793,0,0,1-1.821,1.671h-4.594Z" transform="translate(-487.62 -527.392)" fill="#016fd0" />
            <path id="Path_1545" data-name="Path 1545" d="M788.469,573.648V570.2h7.941c1.175,0,1.684-.635,1.684-1.331,0-.667-.507-1.342-1.684-1.342h-3.588c-3.119,0-4.856-1.9-4.856-4.754,0-2.545,1.591-5,6.226-5h7.727l-1.671,3.571h-6.683c-1.277,0-1.671.67-1.671,1.31a1.384,1.384,0,0,0,1.462,1.384h3.759c3.477,0,4.986,1.972,4.986,4.555,0,2.777-1.681,5.051-5.175,5.051Z" transform="translate(-681.318 -527.392)" fill="#016fd0" />
            <path id="Path_1546" data-name="Path 1546" d="M896.066,573.648V570.2h7.941c1.175,0,1.684-.635,1.684-1.331,0-.667-.507-1.342-1.684-1.342h-3.588c-3.119,0-4.856-1.9-4.856-4.754,0-2.545,1.591-5,6.226-5h7.727l-1.671,3.571h-6.682c-1.277,0-1.671.67-1.671,1.31a1.384,1.384,0,0,0,1.462,1.384h3.759c3.477,0,4.986,1.972,4.986,4.555,0,2.777-1.681,5.051-5.175,5.051Z" transform="translate(-774.352 -527.392)" fill="#016fd0" />
        </g>
    )
});

export default AmericanExpressIcon;