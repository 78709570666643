const case1 = [
  { id: 0, name: 'James', value: 'A', isReadOnly: false },
  { id: 1, name: 'Elliot', value: 'B', isReadOnly: false },
  { id: 2, name: 'Anna', value: 'C', isReadOnly: true },
  { id: 3, name: 'Sussan', value: 'D', isNoSelection: true, isReadOnly: true }
];

const case2 = [
  { id: 0, name: 'James', value: 'A' },
  { id: 1, name: 'Elliot', value: 'B' },
  { id: 2, name: 'Anna', value: 'C' },
  { id: 3, name: 'Sussan', value: 'D' }
];
const case3 = [
  { id: 0, name: 'James', value: 'A' },
  { id: 1, name: 'Elliot', value: 'B' },
  { id: 2, name: 'Anna', value: 'C', isReadOnly: true },
  { id: 3, name: 'Sussan', value: 'D' }
];
const case4 = [
  { id: 0, name: 'James', value: 'A' },
  { id: 1, name: 'Elliot', value: 'B' },
  { id: 2, name: 'Anna', value: 'C' },
  { id: 3, name: 'Sussan', value: 'D' }
];

const case5 = [
  { id: 0, name: 'James', value: 'A' },
  { id: 1, name: 'Elliot', value: 'B' },
  { id: 2, name: 'Anna', value: 'C' },
  { id: 3, name: 'Sussan', value: 'D' }
];

export { case1, case2, case3, case4, case5 };
