import memoize from 'lodash/memoize';
import isEmpty from 'lodash/isEmpty';
import { Flex, Text } from '@chakra-ui/react';
import { B2B_ORDER_OPTION_STATUS, SERVICE_TIMES_LABELS } from '../constants';
import { ORDER_OPTIONS_KEY, ORDER_OPTIONS_TOGGLE_KEY } from 'modules/b2b-orders/constants';

export const calculateQuantitySelectedProducts = (selectedProducts = []) => {
  return selectedProducts?.reduce(
    (accumulator, currentValue) => accumulator + (currentValue?.quantity || 0),
    0
  );
};

const getB2BOrderSummaryRows = (bulkOrder) => {
  return bulkOrder?.stockSelection?.map((stock) => {
    return {
      productId: stock.name,
      sku: stock.sku,
      quantity: stock.quantity
    };
  });
};

const getB2BOrderDetailRows = (bulkOrder) => {
  const orderDetailRows = [
    {
      label: 'PO Number',
      value: bulkOrder?.purchaseOrderNumber
    },
    {
      label: 'Name',
      value: bulkOrder?.contactDetails?.name
    },
    {
      label: 'Email',
      value: bulkOrder?.contactDetails?.emailAddress
    },
    {
      label: 'Phone number',
      value: bulkOrder?.contactDetails?.contactNumber
    },
    {
      label: 'Address',
      children: (
        <Flex flexDirection="column" gap={0.5} flexBasis="67%">
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.addressLine1}</Text>
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.addressLine2}</Text>
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.county}</Text>
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.town}</Text>
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.postCode}</Text>
          <Text lineHeight={5.5}>{bulkOrder?.destinationAddress?.country}</Text>
        </Flex>
      )
    }
  ];

  return orderDetailRows;
};

const getB2BOrderOptionRows = (bulkOrder) => {
  const isBoxSplitDisabled =
    !bulkOrder?.preferences?.isBoxSplit && !bulkOrder?.preferences?.requireSeperateSKUs;
  return [
    {
      options: 'Shipping',
      status: bulkOrder?.preferences?.requireShipping
        ? B2B_ORDER_OPTION_STATUS.shipping.selazarFulfilled
        : B2B_ORDER_OPTION_STATUS.shipping.selfFulfilled
    },
    {
      options: '- SFF Packing',
      status: bulkOrder?.preferences?.requirePackaging
        ? B2B_ORDER_OPTION_STATUS.packing.boxes
        : B2B_ORDER_OPTION_STATUS.packing.loose
    },
    {
      options: 'Box split',
      status: !isBoxSplitDisabled
        ? bulkOrder?.preferences?.requireSeperateSKUs
          ? B2B_ORDER_OPTION_STATUS.boxSplit.separateSKU
          : B2B_ORDER_OPTION_STATUS.boxSplit.customeBoxSplit
        : B2B_ORDER_OPTION_STATUS.boxSplit.none
    },
    {
      options: 'Fragile items',
      status: bulkOrder?.preferences?.isFragile
        ? B2B_ORDER_OPTION_STATUS.fragileItem.fragile
        : B2B_ORDER_OPTION_STATUS.fragileItem.notFragile
    }
  ];
};

const getShippingRows = (bulkOrder) => {
  if (!bulkOrder.preferences.requireShipping) {
    return [
      {
        label: 'Self-fulfilled',
        value: 'Arrange with Customer Service'
      }
    ];
  }
  return [
    {
      label: 'Selazar',
      value: `${bulkOrder.shippingService.name}, ${bulkOrder.shippingService.service}, £${bulkOrder.shippingService.cost} `
    }
  ];
};

export const getB2BOrderSummaryData = memoize((bulkOrder) => {
  const b2bOrderSummaryRows = getB2BOrderSummaryRows(bulkOrder);
  const orderDetailRows = getB2BOrderDetailRows(bulkOrder);
  const b2bOrderOptionRows = getB2BOrderOptionRows(bulkOrder);
  const shippingRows = getShippingRows(bulkOrder);

  return {
    b2bOrderSummaryRows,
    orderDetailRows,
    shippingRows,
    b2bOrderOptionRows
  };
});

export const inputReferencesMapper = (values) => {
  return {
    ...values,
    [ORDER_OPTIONS_KEY.isBoxSplitOrSeparate]:
      !values.requireSeperateSKUs && !values.isBoxSplit ? true : values.isBoxSplit,
    [ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled]: values.isBoxSplit || values.requireSeperateSKUs
  };
};

export const outputReferencesMapper = (values) => {
  const { isBoxSplitEnabled, isBoxSplitOrSeparate, ...outputValues } = values;
  return {
    preferences: {
      ...outputValues,
      isBoxSplit: values.isBoxSplitEnabled && values.isBoxSplitOrSeparate,
      requireSeperateSKUs: values.isBoxSplitEnabled && !values.isBoxSplitOrSeparate
    }
  };
};

export const customBoxDefaultValuesMapper = (array) => {
  const object = {};
  array.forEach((product) => {
    object[product.identifier] = {
      ...product,
      quantity: 0,
      label: 0,
      value: 0
    };
  });

  return object;
};

export const manualSplitPayloadMapper = (boxes) => {
  const payload = [];
  boxes.forEach((box) => {
    payload.push({
      items: Object.values(box)
    });
  });

  return {
    boxes: payload
  };
};

export const transformServiceTimeOptions = (options) => {
  return options.map((serviceTime) => {
    return {
      value: serviceTime,
      label: SERVICE_TIMES_LABELS[serviceTime] ?? serviceTime
    };
  });
};

export const filterArray = (array) => {
  return array?.filter((item) => !isEmpty(item)) || [];
};

export const getCustomBoxSplitSummaryData = memoize((boxes) => {
  if (isEmpty(boxes)) return null;

  const boxSplitSummaryRows = boxes?.map((box, index) => {
    const row = {
      boxIndex: index + 1,
      productIds: [],
      skus: [],
      quantities: []
    };
    box.items.filter((item) => {
      if (item.quantity > 0) {
        row.productIds.push(item.name);
        row.skus.push(item.sku);
        row.quantities.push(item.quantity);
      }
    });

    return row;
  });
  return boxSplitSummaryRows;
});

export const getSeperateSkuSummaryData = memoize((boxes, selectedItems) => {
  if (isEmpty(boxes) || isEmpty(selectedItems)) return null;

  let isHavePartialBox = false;
  const rows = selectedItems.map(({ name, companyItemID, sku }) => {
    let maxQuantityPerBox = 0;
    let numOfPartialBoxes = 0;
    const row = {
      name,
      sku,
      quantity: 0,
      fullBoxes: 0,
      partialBoxes: 0,
      quantityPerBox: 0,
      totalBoxes: 0
    };
    boxes.map((box) => {
      const item = box.items.find(
        (item) => item.companyItemID === companyItemID && item.sku === sku
      );
      if (!item) return;

      if (maxQuantityPerBox <= item.quantity) {
        maxQuantityPerBox = item.quantity;
      } else {
        numOfPartialBoxes++;
      }
      row.quantity += item.quantity;
      row.totalBoxes++;
    });

    if (numOfPartialBoxes > 0) {
      isHavePartialBox = true;
    }

    row.quantityPerBox = maxQuantityPerBox;
    row.fullBoxes = row.totalBoxes - numOfPartialBoxes;
    row.partialBoxes = numOfPartialBoxes;
    return row;
  });

  return { isHavePartialBox, rows };
});
