import { getStatusColor } from 'modules/b2c-orders/mappers/order-list-mapper';
import { getFilterColor } from '../utils';

export const RETURNS_PATHNAME = '/returns';

export const RETURN_DISPLAY_FIELDS_INPUT_SEARCH = [
  'orderID',
  'customerName',
  'returnService.value',
  'formattedProcessedDate'
];

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Returns'
  }
];

export const RETURN_DISPLAY_FIELDS = [
  'orderID',
  'customerName',
  'returnService',
  'status',
  'formattedProcessedDate'
];

export const RETURN_SKELETON_CELL = {
  orderID: null,
  customerName: null,
  returnService: null,
  status: null,
  processedDate: null
};

export const STATUS = {
  PROCESSED: {
    value: 'Processed',
    label: 'Processed'
  },
  UNPROCESSED: {
    value: 'Unprocessed',
    label: 'Unprocessed'
  },
  UNDER_INVESTIGATION: {
    value: 'UnderInvestigation',
    label: 'Under Investigation'
  }
};

export const STATUS_MAPPING = {
  [STATUS.PROCESSED.value]: STATUS.PROCESSED.label,
  [STATUS.UNPROCESSED.value]: STATUS.UNPROCESSED.label,
  [STATUS.UNDER_INVESTIGATION.value]: STATUS.UNDER_INVESTIGATION.label
};

export const GRADING = {
  A: 'A',
  B: 'B'
};

export const RETURN_SERVICE = {
  EXTERNAL: {
    value: 'External',
    label: 'External'
  },
  HOME_COLLECTION: {
    value: 'Home Collection',
    label: 'Home Collection'
  },
  DROP_OFF: {
    value: 'DropOff',
    label: 'Drop off'
  }
};
export const RETURN_SERVICE_MAPPING = {
  [RETURN_SERVICE.EXTERNAL.value]: RETURN_SERVICE.EXTERNAL.label,
  [RETURN_SERVICE.DROP_OFF.value]: RETURN_SERVICE.DROP_OFF.label,
  [RETURN_SERVICE.HOME_COLLECTION.value]: RETURN_SERVICE.HOME_COLLECTION.label
};

export const FILTER_TYPE = {
  STATUS: 'Status',
  RETURN_SERVICE: 'Return service'
};

export const FILTER_OPTIONS = [
  STATUS.PROCESSED,
  STATUS.UNPROCESSED,
  STATUS.UNDER_INVESTIGATION,
  RETURN_SERVICE.EXTERNAL,
  RETURN_SERVICE.HOME_COLLECTION,
  RETURN_SERVICE.DROP_OFF
].map((item, idx) => ({
  key: item.value,
  text: item.label,
  type: idx > 2 ? FILTER_TYPE.RETURN_SERVICE : FILTER_TYPE.STATUS,
  bg: getFilterColor(item.value)
}));

export const RETURN_DETAIL_TEXT = {
  evidence: {
    title: 'Fulfilment Centre evidence',
    description: 'Select a returned product to view the fulfilment centre photograph'
  },
  overview: {
    orderId: 'Order ID',
    returnService: 'Return service',
    orderButton: 'View original order'
  },
  orderInfor: {
    header: 'Order information',
    orderId: 'Order ID: ',
    fulfilment: 'Fulfilment centre: ',
    customerName: 'Customer name: '
  },
  productInfor: {
    productImage: ' Product Image',
    fulfilmentCentrePhoto: 'Fulfilment centre photo',
    returnDetail: 'Return details',
    sku: 'SKU',
    grading: 'Grading'
  }
};

export const GRADING_COLOR_SCHEME = {
  A: 'tetra',
  B: 'sunshine',
  C: 'cherryBomb'
};

export const COST_BREAKDOWN_MAPPING = [
  { label: 'Cross Zonal Charge', keys: ['crossZonalCharge'] },
  { label: 'Fuel Charge', keys: ['fuelCharge'] },
  { label: 'Item Picking Charge', keys: ['pickingCharge'] },
  { label: 'Packing Charge', keys: ['packingCharge'] },
  { label: 'Packaging Charge', keys: ['packagingCharge'] },
  { label: 'Service Charge', keys: ['serviceCharge', 'selazarMarkup'] },
  { label: 'Subtotal', keys: ['subtotal'] },
  {
    label: 'Selazar charge(%rate% %)',
    keys: ['adminCharge'],
    labelInsert: { keyword: '%rate%', key: 'adminRate' }
  },
  { label: 'VAT', keys: ['vatCharge'] }
];
