import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Image, Input, Stack, VStack } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CategoryOptions, NO_AVATAR_URL, TAG_DEFAULT_COLOR } from 'components/common/Constants';
import { SlzButton } from 'components/common/SlzButton';
import { SlzChip } from 'components/common/SlzChip';
import { SlzCustomTag } from 'components/common/SlzCustomTag';
import { SlzFlexBoxView } from 'components/common/SlzFlexBoxView';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';
import { NestedFieldContainer } from 'components/common/SlzForms/base/NestedField';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import { NestedTextarea } from 'components/common/SlzForms/base/NestedTextarea';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';
import { getAllItemCustomTags } from 'modules/products/services/customTags-api';
import { STOCK_STATUS_COLOR } from 'modules/products/constants';

const ProductOverview = (props) => {
  const { setColorTag, isEditMode = false, onAddStock } = props;

  const formImgWidth = '240px';
  const formImgheight = '240px';
  const { register, setValue, getValues } = useFormContext();
  const [customTags, setCustomTags] = useState([]);
  const status = getValues('status');

  const obtainCustomTags = async () => {
    const dataCustomTags = await getAllItemCustomTags();
    setCustomTags(dataCustomTags);
  };

  useEffect(() => {
    obtainCustomTags();
  }, []);

  const [imgURL, setImgURL] = useState(getValues('imageUrl'));
  const uploadAvatarForm = (
    <Box
      position="relative"
      _hover={{
        ' > div': {
          opacity: 1
        },
        ' > img': {
          opacity: 0.3
        }
      }}>
      <Image src={imgURL ? imgURL : NO_AVATAR_URL} w={formImgWidth} h={formImgheight} />
      <Box transition=".5s ease" opacity={0} position="absolute" top="45%" left="25%">
        <SlzButton>Add Photo</SlzButton>
      </Box>
    </Box>
  );

  const onLoadFile = (file, reader) => {
    setImgURL(URL.createObjectURL(file));
    setValue('imageUrl', URL.createObjectURL(file));
  };

  return (
    <Stack w="full" mb={2}>
      <HStack>
        <VStack flex={4} spacing="5">
          <Box>
            {!isEditMode ? (
              <Image src={imgURL ? imgURL : NO_AVATAR_URL} w={formImgWidth} h={formImgheight} />
            ) : (
              <SlzUpload
                uploadForm={uploadAvatarForm}
                onLoadFile={onLoadFile}
                acceptType="image/*"
              />
            )}
            <Input {...register('imageUrl')} hidden />
          </Box>
        </VStack>
        <VStack textAlign="left" gap={3} flex={7}>
          <NestedInput
            label="Name"
            field="name"
            showTooltip={false}
            fieldProps={{ readonly: !isEditMode }}
            labelProps={{ fontSize: '1rem' }}
          />
          <NestedInput
            label="Brand"
            field="brand"
            showTooltip={false}
            fieldProps={{ readonly: !isEditMode }}
            labelProps={{ fontSize: '1rem' }}
          />

          <HStack w="full" spacing="5">
            <Box flex={1}>
              {!isEditMode ? (
                <NestedInput
                  label="Category"
                  field="category"
                  showTooltip={false}
                  fieldProps={{
                    readonly: !isEditMode,
                    value: getValues('category')?.label || getValues('category')
                  }}
                  labelProps={{ fontSize: '1rem' }}
                />
              ) : (
                <NestedDropdown
                  label="Category"
                  field="category"
                  showTooltip={false}
                  fieldProps={{
                    options: CategoryOptions
                  }}
                  labelProps={{ fontSize: '1rem' }}
                />
              )}
            </Box>
            <Box flex={1}>
              <NestedFieldContainer
                labelProps={{ fontSize: '1rem' }}
                label="Custom Tag"
                field="tag">
                {!isEditMode ? (
                  <SlzChip
                    width={'100%'}
                    borderRadius={0.75}
                    h={7.5}
                    bg={getValues('tag.colorCode') || TAG_DEFAULT_COLOR}>
                    {getValues('tag.text')}
                  </SlzChip>
                ) : (
                  <SlzCustomTag
                    width={'100%'}
                    field="tag"
                    setColorTag={setColorTag}
                    items={customTags}
                  />
                )}
              </NestedFieldContainer>
            </Box>
          </HStack>

          <HStack w="full" spacing="5">
            <Box flex={1} bg={STOCK_STATUS_COLOR[status] ?? STOCK_STATUS_COLOR.UNKNOWN}>
              <SlzFlexBoxView
                leftComponent={<SlzFormLabel>Stock</SlzFormLabel>}
                rightComponent={
                  <Flex height={22}>
                    <Box>{getValues('stockCount')}</Box>
                    <Box style={{ paddingLeft: 4, marginTop: -1 }}>
                      <InfoOutlineIcon boxSize="3" color="main.500" />
                    </Box>
                  </Flex>
                }
              />
            </Box>
            <Box flex={1}>
              <SlzButton onClick={onAddStock}>Add Stock</SlzButton>
            </Box>
          </HStack>
        </VStack>
        <VStack textAlign="left" flex={1}></VStack>
      </HStack>
      <NestedTextarea
        size="sm"
        label="Description"
        field="description"
        showTooltip={false}
        fieldProps={{
          readonly: !isEditMode,
          rows: 4,
          resize: 'none'
        }}
        labelProps={{ fontSize: '1rem' }}
      />
    </Stack>
  );
};

export default ProductOverview;
