import * as yup from 'yup';

export const customBoxValidationSchema = yup.object({
  depth: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Length is required')
    .min(10, 'Minimum 10cm'),
  height: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Height is required')
    .min(10, 'Minimum 10cm'),
  maxWeight: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Weight is required')
    .min(0.01, 'Minimum 0.01Kg'),
  name: yup.string().required('Package name is required'),
  width: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Width is required')
    .min(10, 'Minimum 10cm')
});

export const customMailingBagValidationSchema = yup.object({
  height: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Height is required')
    .min(10, 'Minimum 10cm'),
  maxWeight: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Weight is required')
    .min(0.01, 'Minimum 0.01Kg'),
  name: yup.string().required('Package name is required'),
  width: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Width is required')
    .min(10, 'Minimum 10cm')
});
