import CommonEndpoints from '../components/common/Endpoints';
import axiosClient from '../config/axios';

export async function fetchFulfillmentCentres(companyID) {
    try {
        let url = new URL(CommonEndpoints.WAREHOUSE.GET.WAREHOUSES.replace("{id}", companyID));
        const res = await axiosClient.get(url);
        return await res.data?.data;
    }
    catch (error) {
        console.log(error);
    }
}

export async function getCategories() {
    try {
        let url = new URL(CommonEndpoints.CATEGORY.GET.ALL);
        const res = await axiosClient.get(url);
        return await res.data?.data;
    }
    catch (error) {
        console.log(error);
    }
}

export async function createRequestAccess(body) {
    try {
        let url = new URL(CommonEndpoints.WAREHOUSE.POST.CREATE_WAREHOUSE_REQUEST_ACCESS);
        const res = await axiosClient.post(url, body);
        return await res.data?.data;
    }
    catch (error) {
        console.log(error);
        return "error";
    }
}

export async function getAccessRequestById(requestID) {
    try {
        let url = new URL(CommonEndpoints.WAREHOUSE.GET.ACCESS_REQUEST_BY_ID.replace("{id}", requestID));
        const res = await axiosClient.get(url);
        return await res.data?.data;
    }
    catch (error) {
        console.log(error);
        return "error";
    }
}