import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function ReturnsIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.629"
      height="29.734"
      viewBox="0 0 29.629 29.734"
      fill="currentColor"
      {...props}>
      <g
        id="Returns_section_icon"
        data-name="Returns section icon"
        transform="translate(1.042 1.043)">
        <path
          id="Path_4991"
          data-name="Path 4991"
          d="M2037.8,1375.861a13.871,13.871,0,1,0-10.362,25.733m0-7.686v8.635H2018.8"
          transform="translate(-2018.739 -1374.854)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_400"
          data-name="Line 400"
          y2="0.017"
          transform="translate(24.875 5.516)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_401"
          data-name="Line 401"
          y2="0.017"
          transform="translate(15.601 27.587)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_402"
          data-name="Line 402"
          y2="0.017"
          transform="translate(22.233 24.876)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_403"
          data-name="Line 403"
          y2="0.017"
          transform="translate(26.602 19.228)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_404"
          data-name="Line 404"
          y2="0.017"
          transform="translate(27.587 12.147)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
