import { Flex, Image } from '@chakra-ui/react';

import { getHeightOfAccordionCell } from '../Styles';
import { DEFAULT_IMAGE } from 'constants/common';

const ImageCell = ({ row, rowProps, variant = 'order', ...rest }) => (
  <Flex
    w="inherit"
    sx={{
      ...getHeightOfAccordionCell('order'),
      pr: '0.188rem',
      py: 0,
      height: '3rem',
      width: '3.375rem',
      ...rest
    }}>
    <Image
      sx={rowProps?.accordionProps?.imageStyles}
      w="3rem"
      h="3rem"
      objectFit="cover"
      loading="lazy"
      src={row?.imageUrl || DEFAULT_IMAGE}
      alt=""
      srcSet={row?.imageUrl || DEFAULT_IMAGE}
      fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
    />
  </Flex>
);

export default ImageCell;
