import { OWNER_ROLE } from 'constants/common';
import { USERS_PATHNAME } from '../constants';
import UsersList from '../pages/users-list';

const UsersRoutes = [
  {
    path: USERS_PATHNAME,
    component: UsersList,
    feature: 'ViewUser',
    exact: true
  }
];

export default UsersRoutes;
