import { useEffect, useState } from 'react';
import { VStack } from '@chakra-ui/react';

import useSlzToast from 'hooks/useSlzToast';
import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';

import { EORINumbers, EuVatRegistered } from 'modules/preferences/components/form';
import { transformEoriNumbers } from 'modules/preferences/mappers/eori-numbers-mapper';
import { checkEuVatEnabled } from 'modules/preferences/utils';
import {
  useVatRegisteredQuery,
  useEORIDetailsQuery,
  useUpdateIOSSNumberMutation,
  useGetIOSSNumberQuery,
  useRemoveIOSSNumberMutation
} from 'modules/preferences/hooks';
import { IOSSNumber } from './other';
import { useIsMount } from 'hooks/useIsMount';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

const TaxAndDutySetting = ({ activatedSetting, trigger }) => {
  const [isEuVatEnabled, setIsEuVatEnabled] = useState(false);
  const isMount = useIsMount();

  const {
    rootFormState: { isSubmitting, isSubmitSuccessful }
  } = useMultipleFormContext();

  const {
    data: eoriNumbers,
    isFetching: isEoriFetching,
    refetch: eoriRefetch
  } = useEORIDetailsQuery({
    select: (data) => transformEoriNumbers(data)
  });
  const {
    data: vatCountries,
    refetch: vatRefetch,
    isFetching: isFetchingVAT
  } = useVatRegisteredQuery({
    select: (data) => data.vatCountries
  });
  const iossNumberQuery = useGetIOSSNumberQuery({
    initialData: {
      isActiveIOSS: false,
      euioss: ''
    }
  });
  const iossNumberUpdating = useUpdateIOSSNumberMutation();
  const iossNumberRemoving = useRemoveIOSSNumberMutation();
  const [_, setToast] = useSlzToast();

  useEffect(() => {
    setIsEuVatEnabled(checkEuVatEnabled(eoriNumbers));
  }, [eoriNumbers]);

  useEffect(() => {
    if (iossNumberRemoving.isSuccess) {
      setToast({ title: 'Your IOSS Number has been successfully removed' });
      iossNumberQuery.refetch();
    }
  }, [iossNumberRemoving.isSuccess]);

  useEffect(() => {
    if (iossNumberUpdating.isSuccess) {
      setToast({ title: 'Your IOSS Number has been successfully saved' });
      iossNumberQuery.refetch();
    }
  }, [iossNumberUpdating.isSuccess]);

  useEffect(() => {
    if (!isMount && !isSubmitting && isSubmitSuccessful) {
      eoriRefetch();
      vatRefetch();
      iossNumberQuery.refetch();
    }
  }, [activatedSetting, isSubmitting, isSubmitSuccessful]);

  return (
    <PreferenceSkeleton
      isLoaded={!isFetchingVAT && !isEoriFetching && !iossNumberQuery.isFetching}
      template={activatedSetting}>
      <VStack alignItems="flex-start" spacing={8}>
        <EORINumbers eoriNumbers={eoriNumbers} isFetching={isEoriFetching} refetch={eoriRefetch} />
        <EuVatRegistered
          isEuVatEnabled={isEuVatEnabled}
          vatCountries={vatCountries}
          isFetching={isFetchingVAT}
          refetch={vatRefetch}
        />

        <IOSSNumber
          defaultValues={iossNumberQuery.data}
          isFetching={iossNumberQuery.isFetching}
          onSave={iossNumberUpdating.mutate}
          onRemove={iossNumberRemoving.mutate}
        />
      </VStack>
    </PreferenceSkeleton>
  );
};

export default TaxAndDutySetting;
