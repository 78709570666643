import { Fragment } from 'react';
import { Flex, Heading, HStack, VStack, useToken, Text } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { RateMatcherText } from '.';
import { CheckCircleIcon } from 'components/common/Icons';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { RATE_MATCHER_SHIPPING_OPTION_TEXT } from 'modules/integrations/constants';

const shippingOptionStyle = {
  w: '9',
  h: '6',
  pr: '2',
  borderRadius: 'md',
  border: '1px',
  borderColor: 'main.500',
  bg: 'light.500',
  color: 'dark.700',
  alignItems: 'center',
  justifyContent: 'flex-end',
  fontSize: '1rem',
  lineHeight: '1.313rem'
};

export const RateMatchingState = ({ numberOfMatchedRate = 0, numberOfShippingRate = 0 }) => {
  const isAllRateUnMatched = numberOfMatchedRate === 0;
  const isAllRateMatched = numberOfMatchedRate > 0 && numberOfMatchedRate === numberOfShippingRate;
  const isSomeRateMatched = numberOfMatchedRate > 0 && numberOfMatchedRate < numberOfShippingRate;

  return (
    <Fragment>
      <RateMatcherText fontWeight="medium">
        We found {numberOfShippingRate} shopify shipping rates
      </RateMatcherText>
      {isAllRateMatched && (
        <HStack spacing={1}>
          <CheckCircleIcon color="main.500" w="0.75rem" h="0.75rem" />
          <Text color="main.500" fontSize="1rem" fontWeight="bold">
            {RATE_MATCHER_SHIPPING_OPTION_TEXT.allRateMatched}
          </Text>
        </HStack>
      )}

      {isAllRateUnMatched && (
        <HStack>
          <RateMatcherText>{RATE_MATCHER_SHIPPING_OPTION_TEXT.totalOption}</RateMatcherText>
          <Flex sx={shippingOptionStyle}>{numberOfShippingRate}</Flex>
        </HStack>
      )}

      {isSomeRateMatched && (
        <HStack>
          <RateMatcherText>{RATE_MATCHER_SHIPPING_OPTION_TEXT.unMatchedRates}</RateMatcherText>
          <Flex sx={shippingOptionStyle}>{numberOfShippingRate - numberOfMatchedRate}</Flex>
        </HStack>
      )}
    </Fragment>
  );
};

const RateMatcherShippingOption = ({ shopName, isLoading, children }) => {
  const [boxShadow] = useToken('colors', ['boxShadow']);

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton
          w="full"
          h="10.875rem"
          borderRadius="0.75rem"
          boxShadow={`0 0.188rem 0.375rem ${boxShadow}`}></Skeleton>
      ) : (
        <SlzPaper w="full" h="auto" p={5} boxShadow={`0 0.188rem 0.375rem ${boxShadow}`}>
          <VStack spacing="1" w="full" alignItems="flex-start">
            <RateMatcherText>{RATE_MATCHER_SHIPPING_OPTION_TEXT.description}</RateMatcherText>
            <Heading
              fontSize="1.25rem"
              color="dark.700"
              lineHeight="1.563rem"
              fontWeight="medium"
              textDecoration="underline">
              {shopName}
            </Heading>
            {children}
          </VStack>
        </SlzPaper>
      )}
    </Fragment>
  );
};

export default RateMatcherShippingOption;
