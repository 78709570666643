import { HStack, Text, Skeleton, VStack, Flex, Box } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { SlzPaper } from 'components/common/SlzPaper';
import SlzToolTip from 'components/common/SlzToolTip';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { SlzButton } from 'components/common/SlzButton';
import SlzChip from 'components/common/SlzChip/SlzChip';
import SlzLink from 'components/common/SlzLink/SlzLink';
import { INTEGRATION_TYPE, MARKET_PLACE_CARD_STATUS } from '../../constants';

const MarketPlaceCard = (props) => {
  const { data, isLoading, onClickMarketPlaceCard } = props;

  return (
    <SlzPaper w="100%" h="100%" minH="12.75rem" borderRadius={3} bg="#FFFFFF" overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <VStack spacing={6} align="stretch" p={6}>
          <HStack spacing={0} align="flex-start" justifyContent="space-between" minH={12}>
            {data.icon}
            {data.status === MARKET_PLACE_CARD_STATUS.new && (
              <Box>
                <SlzChip size="lg" bg="#0973EA" lineHeight="1rem">
                  {data.status}
                </SlzChip>
              </Box>
            )}
          </HStack>

          <VStack spacing={3} align="stretch">
            <Flex align="center">
              <Text as="h4" fontSize="1.063rem" fontWeight="bold" pr={1.5}>
                {data?.name}
              </Text>
              {data.type === INTEGRATION_TYPE.shopify_v2 && (
                <SlzChip size="xs" bg="#9EDE14" h={4} lineHeight="1rem" mr={1.5}>
                  2.0
                </SlzChip>
              )}
              <SlzToolTip content={data.tooltipContent} placement="top-start" variant="blue">
                <InfoOutlineIcon boxSize="3" color="main.500" />
              </SlzToolTip>
            </Flex>
            <Flex>
              <Text fontSize="0.75rem" noOfLines={2} fontWeight="normal">
                {data?.description}
              </Text>
            </Flex>
            <HStack spacing={0} align="flex-end" justifyContent="space-between">
              <SlzLink
                variant="underline"
                href={data.externalLink}
                isExternal={true}
                color="main.500"
                fontSize="0.75rem"
                fontWeight="normal">
                Learn more
              </SlzLink>
              <SlzButton
                size="sm"
                variant="solid"
                colorScheme="main"
                lineHeight={1}
                onClick={() => onClickMarketPlaceCard && onClickMarketPlaceCard(data.type)}>
                Install
              </SlzButton>
            </HStack>
          </VStack>
        </VStack>
      )}
    </SlzPaper>
  );
};

export default MarketPlaceCard;
