import React, { useCallback, useEffect, useState } from 'react';

import { Box, GridItem, HStack, Heading, Text, VStack } from '@chakra-ui/react';

import {
  ConfirmLeaveToast,
  UserSettingFooter,
  UserSettingHeader
} from 'modules/preferences/components/other';
import {
  ENABLE_RETURNS,
  FOOTER_TYPE,
  PREFERENCES_ACTIONS,
  PRODUCTS_MISSING_IMAGE_WARNING_TEXT,
  PRODUCT_IMAGES_REQUIRED,
  USER_SETTING_FORMS_DEFAULT_NAME,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { ProductImagesRequiredTable } from 'modules/preferences/components/table';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { SlzChip } from 'components/common/SlzChip';
import useSlzToast from 'hooks/useSlzToast';
import { transformProductsImageRequired } from 'modules/preferences/mappers/return-settings-mapper';
import useProductNoImageQuery from 'modules/preferences/hooks/useProductNoImageQuery';
import { CONFIRM_LEAVE_TOAST_ID, STYLE_WARNING_TOAST } from 'modules/users/constants';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

const ProductImagesRequiredModal = (props) => {
  const { activateSetting, onBack, setCallbackFns } = props;
  const { preferenceDispatch } = usePreferenceContext();

  const [_, setToast, toast] = useSlzToast();

  const [currentPage, setCurrentPage] = useState(1);

  const [productImageRequiredData, setProductImageRequiredData] = useState([]);

  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.returnSettingEnable]: form } = forms;

  const methods = form?.form;

  const {
    data: productImagesRequired,
    isFetching,
    refetch
  } = useProductNoImageQuery({
    filters: { pageIndex: currentPage },
    select: (data) => transformProductsImageRequired(data)
  });

  const handleOnClose = useCallback(() => {
    try {
      if (productImageRequiredData?.length > 0) {
        !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
          setToast({
            chakraToastProps: {
              id: CONFIRM_LEAVE_TOAST_ID,
              duration: null,
              containerStyle: {
                maxWidth: `60.063rem !important`
              }
            },
            ...STYLE_WARNING_TOAST,
            render: () => (
              <ConfirmLeaveToast
                message={PRODUCTS_MISSING_IMAGE_WARNING_TEXT}
                leaveBtnText="Leave"
                saveBtnText="Complete"
                onSaveClick={() => {
                  toast.closeAll();
                  setCallbackFns((prev) => ({
                    ...prev,
                    onClose: handleOnClose
                  }));
                }}
                onLeaveClick={() => {
                  methods.reset();
                  toast.closeAll();
                  preferenceDispatch({
                    type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
                    payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.default.key }
                  });
                }}
              />
            )
          });
      } else {
        methods.setValue('enabled', true);

        preferenceDispatch({
          type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
          payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.default.key }
        });
      }
    } catch (error) {
      setToast({ title: ENABLE_RETURNS.failed, status: 'warning', colorScheme: 'negative' });
    }
  }, [productImageRequiredData]);

  const handleBack = () => {
    methods.reset();
    onBack();
  };

  useEffect(() => {
    setProductImageRequiredData(productImagesRequired?.products);
  }, [productImagesRequired?.products]);

  useEffect(() => {
    setCallbackFns((prev) => ({
      ...prev,
      onClose: handleOnClose
    }));
  }, [productImageRequiredData, toast]);

  return (
    <>
      <GridItem gridColumn="2/12" mb="8" pt="6">
        <UserSettingHeader text={activateSetting?.text} onBack={handleBack} />
      </GridItem>
      <GridItem gridColumn="2/12">
        <VStack alignItems="start" mb="0.563rem" spacing={0}>
          <HStack spacing="1.125rem">
            <Heading fontSize="1.25rem">{PRODUCT_IMAGES_REQUIRED.TITLE}</Heading>

            <Box>
              <SlzChip
                size="lg"
                bg="main.300"
                color="main.500"
                lineHeight="1rem"
                px="0.75rem"
                borderRadius="md">
                {PRODUCT_IMAGES_REQUIRED.FILE_UPLOAD_RULE}
              </SlzChip>
            </Box>
          </HStack>
          <HStack
            fontSize="0.75rem"
            marginBottom="0.375rem"
            justifyContent="space-between"
            w="full">
            <Text>{PRODUCT_IMAGES_REQUIRED.CONTENT}</Text>
            <HStack
              h="1.875rem"
              w="12.375rem"
              bg="main.300"
              fontSize="md"
              color="main.500"
              lineHeight="1rem"
              fontWeight="medium"
              justifyContent="space-between"
              py={2}
              pl="1.125rem"
              pr="0.688rem"
              border="commonBlue"
              borderWidth="1px"
              borderRadius={1.5}>
              <Text>{PRODUCT_IMAGES_REQUIRED.IMAGES_REQUIRED}</Text>
              <Text color="dark.700">1</Text> //TODO: waiting total images required from BE
            </HStack>
          </HStack>
        </VStack>
        <ProductImagesRequiredTable
          productImagesRequired={productImagesRequired}
          isFetching={isFetching}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          refetch={refetch}
        />
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <UserSettingFooter
          footerType={FOOTER_TYPE.HAVE_DONE_BUTTON}
          onClose={handleBack}
          onSave={handleOnClose}
        />
      </GridItem>
    </>
  );
};

export default ProductImagesRequiredModal;
