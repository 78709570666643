import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Box, Link } from '@chakra-ui/react';

export default function TermAndConditions() {
  return (
    <Box>
      I agree to Selazar’s{' '}
      <Link
        as={ReactLink}
        color="main.500"
        textDecor="underline"
        target="_blank"
        to="/files/term-and-conditions.pdf">
        Terms and Conditions
      </Link>{' '}
      and{' '}
      <Link
        as={ReactLink}
        color="main.500"
        textDecor="underline"
        target="_blank"
        to="/files/private-policy.pdf">
        Privacy Policy
      </Link>
    </Box>
  );
}
