import { GridItem, HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import HomeIcon from 'components/common/Icons/HomeIcon';
import { SlzGrid } from 'components/common/SlzGrid';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { useHistory, useParams } from 'react-router-dom';
import { useConsignmentDetailQuery } from '../hooks/useConsignmentDetailQuery';

import { SlzSkeletonDefault } from 'components/common/Skeleton/Skeleton';
import { SlzPaper } from 'components/common/SlzPaper';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { BULK_ORDER_TYPE } from 'modules/b2b-orders/constants';
import { FaChevronLeft } from 'react-icons/fa';
import ConsignmentDetails from '../components/other/ConsignmentDetails';
import ConsignmentInfoDetails from '../components/other/ConsignmentInfoDetails';
import ShippingAndLabelling from '../components/other/ShippingAndLabelling';
import WarehouseInformation from '../components/other/WarehouseInformation';
import { CONSIGNMENT_BREAKDOWN_ACCORDION_COLUMNS } from '../components/table/consignment-breakdown-column';
import { CONSIGNMENTS_PATHNAME } from '../constants';
import { transformConsignmentDetail } from '../mappers/consignment-detail-mapper';

const ConsignmentDetail = () => {
  const { consignmentId } = useParams();
  const routerQuery = useRouterQuery();
  const history = useHistory();

  const { data, isLoading, refetch } = useConsignmentDetailQuery(consignmentId, (data) =>
    transformConsignmentDetail(data)
  );

  return (
    <SlzGrid
      templateAreas={`"header header header header header header"
        "nav main main main main main"
        "footer main main main main main"`}>
      <GridItem area={'header'} colSpan="12">
        <HStack
          alignItems="flex-start"
          cursor="pointer"
          display="inline-flex"
          mb={2}
          onClick={() => history.push(CONSIGNMENTS_PATHNAME)}
        >
          <Icon as={FaChevronLeft} fontSize="lg" color="dark.500" mt="1" />
          <Heading as="h4" fontSize="1.0625rem">
            Back to consignments
          </Heading>
        </HStack>

        <ConsignmentInfoDetails
          consignmentInfoDetails={data?.consignmentInfoDetails}
          isLoading={isLoading}
          w="100%"
          h="6rem"
          bg="light.500"
        />
      </GridItem>

      <GridItem area={'nav'}>
        <VStack spacing={6}>
          <ConsignmentDetails
            consignmentDetails={data?.consignmentDetails}
            w="19.125rem"
            h="auto"
            title="Consignment details"
            iconTop={<HomeIcon />}
            iconBottom={<HomeIcon />}
            isLoading={isLoading}
          />
          <ShippingAndLabelling
            shippingAndLabelling={data?.shippingAndLabelling}
            isLoading={isLoading}
            w="19.125rem"
            h="auto"
            title="Shipping & Labelling"
            refetchConsignmentDetails={refetch}
          />
          <WarehouseInformation
            warehouseInformation={data?.warehouseInformation ?? null}
            isLoading={isLoading}
            w="19.125rem"
            h="auto"
            title="Warehouse"
          />
        </VStack>
      </GridItem>
      <GridItem area={'footer'}></GridItem>
      <GridItem area={'main'} colSpan="11" mb={3}>
        {isLoading ? (
          <SlzSkeletonDefault h="full" />
        ) : (
          <SlzPaper
            w="full"
            h="full"
            bg="light.500"
            borderRadius="0.375rem"
            boxShadow="0px 3px 6px #00000029"
            px={6}>
            <HStack justifyContent="space-between" py={3}>
              <Text fontSize="1rem" fontWeight="bold">
                Consignment breakdown
              </Text>
            </HStack>
            <SlzAccordionTable
              variant="order"
              isShow={true}
              data={data?.consignmentBreakDown}
              isLoading={isLoading}
              isChild={true}
              configProps={{
                ...accordionProps,
                isShowMarker: false,
                markerColor: 'main.500',
                spacing: 300
              }}
            />
          </SlzPaper>
        )}
      </GridItem>
    </SlzGrid>
  );
};

const accordionProps = {
  isShowMarker: true,
  markedWidth: 12,
  columns: CONSIGNMENT_BREAKDOWN_ACCORDION_COLUMNS,
  showImage: true,
  imageWidth: 48,
  imageStyles: {
    w: '3rem',
    h: 'inherit'
  },
  type: BULK_ORDER_TYPE,
  displayFields: CONSIGNMENT_BREAKDOWN_ACCORDION_COLUMNS.map((col) => col.field)
};

export default ConsignmentDetail;
