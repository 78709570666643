import { HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { SlzButton, SlzSplitButton } from 'components/common/SlzButton';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants';
import { PRODUCT_TAB } from '../../constants';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'constants/table.js';
import { transformBundles } from '../../mappers/bundle-list-mapper';
import { transformProducts } from '../../mappers/product-list-mapper';
import { getBundles } from '../../services/bundles-api';
import { getProducts } from '../../services/products-api';
import { BUNDLE_COLS } from '../table/bundles.columns';

const ProductFilter = (props) => {
  const { tab, onSearch, initSearchValue } = props;
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE });
  const [displayFields, setDisplayFields] = useState([]);

  useEffect(() => {
    switch (tab) {
      case PRODUCT_TAB.bundle.key:
        setDisplayFields(
          BUNDLE_COLS.filter((col) => col.field != 'image' && col.isDisplayed).map(
            (col) => col.field
          )
        );
        break;
      default:
        setDisplayFields(['name', 'sku', 'selazarUniqueID', 'tag']);
        break;
    }
  }, [tab]);

  const handleAutocompleteSearch = (searchTerm) => {
    if (!searchTerm) {
      onSearch(searchTerm);
    }
    setSearchParams({
      tab: tab,
      searchText: searchTerm
    });
  };

  const fetchFn = ({ tab, searchText }) => {
    switch (tab) {
      case PRODUCT_TAB.bundle.key:
        return getBundles({ searchText });
      default:
        return getProducts({ searchText });
    }
  };

  const transformFn = (res) => {
    if (res !== undefined && res?.items !== undefined) {
      return transformProducts(res).products;
    }

    if (res !== undefined && res?.productBundles !== undefined) {
      return transformBundles(res).productBundles;
    }
  };

  return (
    <Fragment>
      <HStack mb={6} gap="25px">
        <SlzAutocompleteInput
          isLocal={false}
          width="39.109rem"
          placeholder="Search"
          variant="accent"
          process={tab}
          accentField={tab === PRODUCT_TAB.bundle.key ? 'bundleTag' : 'tag'}
          searchBy={[displayFields[0]]}
          showedFields={displayFields}
          onTypingSearch={handleAutocompleteSearch}
          onNavigate={(record) => props?.onOpenModal(record)}
          transformFn={transformFn}
          fetchFn={fetchFn}
          searchParams={searchParams}
          onSearch={onSearch}
          initValue={initSearchValue}
        />
        {tab === PRODUCT_TAB.product.key && (
          <Fragment>
            {/* <SlzDateRangePicker
              onDateRangeClick={(startDate, endDate) =>
                props?.handleSelectedDateRange(startDate, endDate)
              }
              startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
              endDatePlaceHolder={TODAY}
              startDate={null}
              endDate={dayjs()}
            /> */}
            <SlzSplitButton
              items={props?.addSteps}
              size={'lg'}
              placeholder="Add product"
              hasArrow={true}
              onlyShowPlaceholder={true}
              onClick={() => props?.openAddForm()}
              onSelected={(type) => props?.openAddFormByType(type)}
            />

            <SlzSplitButton items={[]} size={'lg'} placeholder="Export" />
          </Fragment>
        )}
        {tab === PRODUCT_TAB.bundle.key && (
          <SlzButton size="lg" onClick={() => props?.onOpenCreateBundleModal()}>
            Create bundle
          </SlzButton>
        )}
      </HStack>
    </Fragment>
  );
};

export default ProductFilter;
