import { HStack } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { API_SETUP_DETAIL_STEP, API_SETUP_SQS_STEP } from 'modules/integrations/constants';

const CancelButton = ({ onCancel }) => (
  <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onCancel}>
    Cancel
  </SlzButton>
);

const PreviousButton = ({ onClick }) => (
  <SlzButton variant="outline" size="lg" colorScheme="main" onClick={onClick}>
    Previous
  </SlzButton>
);

const NextButton = ({ isDisabled, onClick }) => (
  <SlzButton variant="solid" size="lg" isDisabled={isDisabled} onClick={onClick}>
    Next
  </SlzButton>
);
const SetupButton = ({ submitText, isDisabled }) => (
  <SlzButton variant="solid" size="lg" type="submit" isDisabled={isDisabled}>
    {submitText}
  </SlzButton>
);

const API_SETUP_FORM_BUTTONS = [
  {
    left: CancelButton,
    right: ({ submitText, showNextStep, isDisabled, setCurrentStep }) => {
      if (showNextStep) {
        return (
          <NextButton onClick={() => setCurrentStep(API_SETUP_SQS_STEP)} isDisabled={isDisabled} />
        );
      }
      return <SetupButton submitText={submitText} isDisabled={isDisabled} />;
    }
  },
  {
    left: ({ setCurrentStep }) => {
      return <PreviousButton onClick={() => setCurrentStep(API_SETUP_DETAIL_STEP)} />;
    },
    right: ({ submitText, isDisabled, onCancel }) => {
      return (
        <>
          <CancelButton onCancel={onCancel} />
          <SetupButton submitText={submitText} isDisabled={isDisabled} />
        </>
      );
    }
  }
];

const ApiSetupFormAction = (props) => {
  const { currentStep } = props;
  const { left: LeftFormActions, right: RightFormActions } = API_SETUP_FORM_BUTTONS[currentStep];

  return (
    <HStack justifyContent="space-between" mt="4.75rem">
      <LeftFormActions {...props} />
      <HStack spacing={3} justifyContent="space-between">
        <RightFormActions {...props} />
      </HStack>
    </HStack>
  );
};

export default ApiSetupFormAction;
