import { Box, Button, Flex } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { PRODUCT_QUANTITY_OPTIONS } from '../constants';
const productSelectStyles = {
  control: () => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '48px',
    height: '31px',
    background: 'white',
    border: 'common',
    borderRadius: '0.75'
  }),
  dropdownIndicator: (provided, { selectProps }) => ({
    '> svg': {
      transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`
    }
  }),
  valueContainer: () => {
    return {
      display: 'grid',
      alignItems: 'center',
      flexWrap: 'wrap',
      overflow: 'hidden'
    };
  },
  indicatorSeparator: (state) => ({
    display: 'none'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    background: isSelected ? '#cce4ff' : undefined
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 10
  })
};

export default function ProductSelectForm(props) {
  const { onRemove, onChange, isEditMode = false, value, defaultValue } = props;

  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <Flex justifyContent={'end'}>
        <Box
          padding={1}
          boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
          width={'5.313rem'}
          background={'lightAndShadow.background'}
          borderRadius={'3px'}
          ref={innerRef}
          {...innerProps}>
          <Button
            onClick={onRemove}
            height={9}
            color={'#3b3c3f'}
            borderRadius={0}
            background={'lightAndShadow.borderObject'}
            size="lg"
            width="full">
            Remove
          </Button>
          {children}
        </Box>
      </Flex>
    ) : (
      <></>
    );

  return (
    <Select
      isDisabled={!isEditMode}
      chakraStyles={productSelectStyles}
      options={PRODUCT_QUANTITY_OPTIONS}
      value={PRODUCT_QUANTITY_OPTIONS.find((itemOption) => itemOption.value === value)}
      defaultValue={defaultValue}
      onChange={({ value }) => onChange(value)}
      components={{ MenuList: CustomMenu }}
    />
  );
}

// export const SelectProductCard = (props) => {
//   const {
//     onRemove,
//     item,
//     index,
//     methods,
//     setIsChangeQuantity,
//     isEditMode = false,
//     optionSelect = []
//   } = props;
//   const CustomMenu = ({ innerRef, innerProps, isDisabled, children }) =>
//     !isDisabled ? (
//       <Flex justifyContent={'end'}>
//         <Box
//           padding={1}
//           boxShadow={'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}
//           width={'5.313rem'}
//           background={'lightAndShadow.background'}
//           borderRadius={'3px'}
//           ref={innerRef}
//           {...innerProps}>
//           <Button
//             onClick={() => {
//               onRemove(item);
//             }}
//             height={9}
//             color={'#3b3c3f'}
//             borderRadius={0}
//             background={'lightAndShadow.borderObject'}
//             size="lg"
//             width="full">
//             Remove
//           </Button>
//           {children}
//         </Box>
//       </Flex>
//     ) : null;

//   return (
//     <Controller
//       name={`productBundleCompanyItems.${index}.quantity`}
//       control={methods.control}
//       render={({ field: { onChange, value, ref }, fieldState: { isDirty } }) => (
//         <Select
//           isDisabled={!isEditMode}
//           chakraStyles={chakraStyles}
//           options={optionSelect}
//           value={optionSelect.find((itemOption) => itemOption.value === value)}
//           onChange={(val) => {
//             setIsChangeQuantity(val);
//             return onChange(val.value);
//           }}
//           defaultValue={optionSelect.find(
//             (itemOption) =>
//               itemOption.value === methods.getValues(`productBundleCompanyItems.${index}.quantity`)
//           )}
//           components={{ MenuList: CustomMenu }}
//         />
//       )}
//       rules={{ required: true }}
//     />
//   );
// };
