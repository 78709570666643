import { useQuery } from 'react-query';
import { getBundleDetail } from '../services/bundles-api';

export const useBundleDetailQuery = (bundleId, select, enabled) => {
  return useQuery({
    queryKey: [`bundle-detail-${bundleId}`],
    queryFn: () => getBundleDetail(bundleId),
    select: select,
    enabled: enabled
  });
};
