import { Icon } from '@chakra-ui/react';

const ApiIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="17.172"
      height="17.171"
      viewBox="0 0 17.172 17.171"
      fill="currentColor"
      {...props}>
      <path
        id="Path_9915"
        data-name="Path 9915"
        d="M8.586,13.011H3A3,3,0,0,1,3,7h.429a.859.859,0,1,1,0,1.717H3a1.288,1.288,0,1,0,0,2.576H8.586a.859.859,0,0,1,0,1.717"
        transform="translate(0 -0.991)"
        fill="#fafafa"
      />
      <path
        id="Path_9916"
        data-name="Path 9916"
        d="M10,18.445a3.008,3.008,0,0,1-3-3v-.429a.859.859,0,0,1,1.717,0v.429a1.288,1.288,0,0,0,2.576,0V9.86a.859.859,0,1,1,1.717,0V15.44a3.008,3.008,0,0,1-3,3"
        transform="translate(-0.99 -1.274)"
        fill="#fafafa"
      />
      <path
        id="Path_9917"
        data-name="Path 9917"
        d="M15.439,12.011H15.01a.859.859,0,0,1,0-1.717h.429a1.288,1.288,0,1,0,0-2.576H9.859A.859.859,0,1,1,9.859,6h5.581a3,3,0,1,1,0,6.01"
        transform="translate(-1.272 -0.849)"
        fill="#fafafa"
      />
      <path
        id="Path_9918"
        data-name="Path 9918"
        d="M6.859,9.445A.859.859,0,0,1,6,8.587V3.006a3,3,0,1,1,6.01,0v.429a.859.859,0,0,1-1.717,0V3.006a1.288,1.288,0,1,0-2.576,0V8.587a.859.859,0,0,1-.859.859"
        transform="translate(-0.849 -0.001)"
        fill="#fafafa"
      />
    </Icon>
  );
};

export default ApiIcon;
