import React, { useEffect } from 'react';

import { FormProvider } from 'react-hook-form';
import { VStack, HStack, Heading, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import { Field } from 'components/form';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { USER_SETTING_FORMS_DEFAULT_NAME } from '../../constants';

export default function CreditConfigsForm({ isSubmitting, onSubmit, onBack }) {
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.credit]: form } = forms;
  const methods = form?.form;

  useEffect(() => {
    resetCreditForm();
  }, []);

  const resetCreditForm = () => {
    methods.reset();
    const formValues = methods.getValues();
    Object.keys(formValues).forEach((key) => {
      methods.setValue(key, '');
    });
  };

  const handleBack = () => {
    resetCreditForm();
    onBack();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} autoComplete="off" id="adyen-encrypted-form">
        <VStack spacing={4} alignItems="flex-start" mb={8}>
          <Heading as="h4" fontSize="xl">
            Cardholder details
          </Heading>

          <Field label="Forename" name="forename" isInlineError />

          <Field label="Surname" name="surname" isInlineError />

          <Field label="Email" name="email" isInlineError />
        </VStack>

        <VStack spacing={4} alignItems="flex-start">
          <Heading as="h5" fontSize="lg">
            Cardholder details
          </Heading>

          <Field label="Address line 1" name="addressLine1" isInlineError />

          <Field
            label={
              <>
                Address line 2
                <Text as="span" fontWeight="normal">
                  {' '}
                  - Optional
                </Text>
              </>
            }
            name="addressLine2"
            showStatus={false}
            isInlineError
          />

          <Field label="Town" name="town" isInlineError />

          <Field label="Postcode" name="postCode" isInlineError />
        </VStack>

        <HStack justifyContent="space-between" mt={12} mb={6}>
          <SlzButton size="lg" variant="outline" colorScheme="negative" onClick={handleBack}>
            Back to cards
          </SlzButton>

          <SlzButton type="submit" size="lg" isDisabled={isSubmitting}>
            Proceed to payment details
          </SlzButton>
        </HStack>
      </form>
    </FormProvider>
  );
}
