import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  useMultiStyleConfig,
  VStack
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { CountryList } from 'components/common/Constants';

const DimensionsField = ({ label, disabled, register, errors }) => {
  const styles = useMultiStyleConfig('Input');
  const [dimensionFields] = useState([
    { field: 'height', unit: 'H' },
    { field: 'width', unit: 'W' },
    { field: 'depth', unit: 'D' }
  ]);

  const isInvalid = errors['dimension'];
  return (
    <FormControl isInvalid={isInvalid}>
      <SlzFormLabel>{label}</SlzFormLabel>
      <HStack>
        <HStack pos="relative" spacing={0}>
          {dimensionFields.map(({ field, unit }) => (
            <SlzInput
              type="number"
              size="sm"
              min="0"
              step="0.01"
              autoComplete="off"
              readonly={disabled}
              textAlign="right"
              rightElContent={unit}
              inputGroupProps={{
                sx: {
                  '& > div': {
                    lineHeight: 'unset',
                    pl: 0
                  },
                  '& > input': {
                    border: 0,
                    pr: 0
                  },
                  '& > input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& > input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '&:focus-within ~ .chakra-input__group > input.substitute': styles.field._hover,
                  '&:hover ~ .chakra-input__group > input.substitute': styles.field._hover
                }
              }}
              {...register(field)}
            />
          ))}
          <SlzInput
            size="sm"
            inputGroupProps={{
              sx: {
                pos: 'absolute',
                zIndex: -1,
                '.chakra-stack > &': { ml: 0 }
              }
            }}
            status={isInvalid && 'error'}
            readonly={disabled}
          />
        </HStack>
        <InfoOutlineIcon boxSize="3" color="main.500" />
      </HStack>
      <FormErrorMessage>{errors.dimension?.message}</FormErrorMessage>
    </FormControl>
  );
};

const IdentifiersAndDetailsSection = ({ isEditable }) => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext();

  const listCountries = useMemo(
    () => CountryList.map((item) => ({ value: item.code, label: item.name })),
    []
  );

  return (
    <HStack spacing="8" mb={8}>
      <VStack flex={3} spacing="5">
        <Heading as="h3" size="md" w="full">
          Identifiers
        </Heading>

        <NestedInput field="sku" label="SKU" fieldProps={{ readonly: !isEditable }} />

        <HStack spacing="5" w="full">
          <Box flex={4}>
            <NestedInput field="ean" label="EAN" fieldProps={{ readonly: !isEditable }} />
          </Box>
          <Box flex={3}></Box>
        </HStack>

        <NestedInput field="suid" label="SUID" fieldProps={{ readonly: !isEditable }} />
      </VStack>

      <VStack flex={4} spacing="5">
        <Heading as="h3" size="md" w="full">
          Details
        </Heading>

        <HStack spacing="5" w="full">
          <Box flex={1}>
            <DimensionsField
              name="dimensions"
              label="Dimensions (cm)"
              disabled={!isEditable}
              register={register}
              errors={errors}
            />
          </Box>
          <Box flex={1}></Box>
        </HStack>

        <HStack spacing="5" w="full">
          <Box flex={1}>
            <NestedInput
              field="weight"
              label="Weight"
              fieldProps={{
                type: 'number',
                min: 0,
                step: 0.01,
                textAlign: 'right',
                rightElContent: 'kg',
                readonly: !isEditable,
                inputGroupProps: { sx: { '& > input': { pr: 0 } } }
              }}
            />
          </Box>

          <Box flex={2}>
            <NestedInput
              field="commodityCode"
              label="Commodity code"
              fieldProps={{ type: 'number', min: 0, readonly: !isEditable }}
            />
          </Box>
        </HStack>

        {isEditable ? (
          <NestedDropdown
            menuIsOpen={true}
            field="countryCodeOfManufacture"
            label="Country of manufacturing"
            fieldProps={{
              options: listCountries
            }}
          />
        ) : (
          <NestedInput
            field="countryCodeOfManufacture"
            label="Country of manufacturing"
            fieldProps={{
              readonly: !isEditable,
              value:
                getValues('countryCodeOfManufacture')?.label ||
                getValues('countryCodeOfManufacture')
            }}
          />
        )}
      </VStack>
    </HStack>
  );
};

export default IdentifiersAndDetailsSection;
