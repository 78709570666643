const ANGLE = 180;
const PAGES_ITEM_DISPLAY = 'items per page';
const PAGINATION_TITLE = 'Pagination';
const PAGINATION_PREV_TITLE = 'Prev';
const PAGINATION_NEXT_TITLE = 'Next';

export {
  ANGLE,
  PAGINATION_TITLE,
  PAGES_ITEM_DISPLAY,
  PAGINATION_PREV_TITLE,
  PAGINATION_NEXT_TITLE
};