import { Icon } from '@chakra-ui/react';

const PackageIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="30.294"
      height="33.659"
      viewBox="0 0 30.294 33.659"
      {...props}>
      <g id="Group_34356" data-name="Group 34356" transform="translate(-189.775 -2147.551)">
        <path
          id="Path_3455"
          data-name="Path 3455"
          d="M204.922,2181.21a1.68,1.68,0,0,1-.825-.216l-13.464-7.573a1.682,1.682,0,0,1-.858-1.467v-15.146a1.683,1.683,0,0,1,.858-1.467l13.464-7.573a1.682,1.682,0,0,1,1.65,0l13.464,7.573a1.683,1.683,0,0,1,.858,1.467v15.146a1.682,1.682,0,0,1-.858,1.467l-13.464,7.573A1.68,1.68,0,0,1,204.922,2181.21Zm-11.781-10.24,11.781,6.626,11.781-6.626v-13.178l-11.781-6.626-11.781,6.626Z"
          transform="translate(0 0)"
          fill="#0973ea"
        />
        <path
          id="Line_19"
          data-name="Line 19"
          d="M.685,9.94A1.683,1.683,0,0,1-.142,6.79L13.322-.784a1.683,1.683,0,1,1,1.65,2.934L1.508,9.723A1.676,1.676,0,0,1,.685,9.94Z"
          transform="translate(204.239 2156.124)"
          fill="#0973ea"
        />
        <path
          id="Line_20"
          data-name="Line 20"
          d="M.683,17.512A1.683,1.683,0,0,1-1,15.829V.683a1.683,1.683,0,0,1,3.366,0V15.829A1.683,1.683,0,0,1,.683,17.512Z"
          transform="translate(204.239 2163.698)"
          fill="#0973ea"
        />
        <path
          id="Line_21"
          data-name="Line 21"
          d="M14.145,9.94a1.675,1.675,0,0,1-.824-.216L-.142,2.15A1.683,1.683,0,0,1,1.508-.784L14.972,6.79a1.683,1.683,0,0,1-.827,3.15Z"
          transform="translate(190.776 2156.124)"
          fill="#0973ea"
        />
        <path
          id="Line_22"
          data-name="Line 22"
          d="M.685,9.94A1.683,1.683,0,0,1-.142,6.79L13.322-.784a1.683,1.683,0,1,1,1.65,2.934L1.508,9.723A1.676,1.676,0,0,1,.685,9.94Z"
          transform="translate(197.507 2152.338)"
          fill="#0973ea"
        />
      </g>
    </Icon>
  );
};

export default PackageIcon;
