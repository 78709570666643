import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { useIsMount } from 'hooks/useIsMount';

import B2BOrderOption from 'modules/b2b-orders/components/other/B2BOrderOption';
import {
  DISABLE_CONDITION,
  ORDER_OPTIONS_CONTENT,
  ORDER_OPTIONS_KEY,
  ORDER_OPTIONS_TOGGLE_KEY
} from 'modules/b2b-orders/constants';
import { B2B_ORDER_OPTIONS_WRAPPER_STYLE } from 'modules/b2b-orders/constants/styles';
import { B2BOrderAction } from 'modules/b2b-orders/components/other';
import { inputReferencesMapper, outputReferencesMapper } from 'modules/b2b-orders/utils';

const B2BOrderOptionsContent = ({ children, ...rest }) => {
  return (
    <FormWrapper defaultValues={inputReferencesMapper(rest.defaultValues.preferences)}>
      {({ methods }) => {
        const isMount = useIsMount();
        const checkDisableStatus = (key) => (key ? DISABLE_CONDITION[key](methods.watch) : false);

        useEffect(() => {
          if (!isMount) {
            methods.setValue(
              ORDER_OPTIONS_KEY.requirePackaging,
              !methods.getValues(ORDER_OPTIONS_KEY.requireShipping)
            );

            methods.setValue(ORDER_OPTIONS_KEY.isBoxSplitOrSeparate, true);
          }
        }, [methods.watch(ORDER_OPTIONS_KEY.requireShipping)]);

        useEffect(() => {
          if (!isMount) {
            methods.setValue(ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled, false);

            !methods.getValues(ORDER_OPTIONS_KEY.requirePackaging) &&
              methods.setValue(ORDER_OPTIONS_KEY.isBoxSplitOrSeparate, true);
          }
        }, [methods.watch(ORDER_OPTIONS_KEY.requirePackaging)]);

        useEffect(() => {
          if (!isMount && !methods.getValues(ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled)) {
            methods.setValue(ORDER_OPTIONS_KEY.isBoxSplitOrSeparate, true);
          }
        }, [methods.watch(ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled)]);

        const handleNextStep = (values) => {
          rest?.onNext(outputReferencesMapper(values));
        };

        return (
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(handleNextStep)}>
              <Box {...B2B_ORDER_OPTIONS_WRAPPER_STYLE}>{children({ checkDisableStatus })}</Box>
              <B2BOrderAction {...rest} />
            </form>
          </FormProvider>
        );
      }}
    </FormWrapper>
  );
};

const B2BOrderOptionList = (props) => {
  return (
    <B2BOrderOptionsContent {...props}>
      {({ checkDisableStatus }) =>
        ORDER_OPTIONS_CONTENT.map((option) => (
          <B2BOrderOption
            key={option.key}
            optionKey={option.key}
            title={option.title}
            toggle={option.toggle}
            tooltip={option.tooltip}
            tag={option.tag}
            content={option.content}
            isOptionDisabled={checkDisableStatus(option.key)}
            isTitleDisabled={checkDisableStatus(option?.toggle?.key)}
          />
        ))
      }
    </B2BOrderOptionsContent>
  );
};
export default B2BOrderOptionList;
