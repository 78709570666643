import camelCase from 'lodash/camelCase';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import { parse } from 'papaparse';
import { CSV_DELIMITER } from 'modules/products/constants';

const transformUploadedFile = (items) =>
  reduce(
    map(items, 'text'),
    (accumulator, item) => {
      const { data, errors } = parse(item, { delimiter: CSV_DELIMITER }) || [];
      if (!errors.length && data.length) {
        const headers = data.shift(); //remove header in each files
        const row = data.map((row) =>
          headers.reduce((object, header, index) => {
            object[convertHeader(camelCase(header))] = row[index];
            return object;
          }, {})
        );
        accumulator = accumulator.concat(row);
      }
      return accumulator;
    },
    []
  );

const headerMapping = [
  {
    header: 'heightCm',
    newHeader: 'height'
  },
  {
    header: 'weightKg',
    newHeader: 'weight'
  },
  {
    header: 'widthCm',
    newHeader: 'width'
  },
  {
    header: 'depthCm',
    newHeader: 'depth'
  },
  {
    header: 'countryOfManufacture',
    newHeader: 'countryCodeOfManufacture'
  },
  {
    header: 'image',
    newHeader: 'imageUrl'
  },
  {
    header: 'price',
    newHeader: 'currentPrice'
  },
  {
    header: 'expectedWeeklySales',
    newHeader: 'units'
  }
];

const convertHeader = (strHeader) => {
  const findHeader = headerMapping.find((x) =>
    x.header.toLowerCase().includes(strHeader?.toLowerCase())
  );
  return findHeader ? findHeader.newHeader : camelCase(strHeader);
};

export default transformUploadedFile;
