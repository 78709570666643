import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SlzGridModal } from 'components/common/SlzModal';
import useSlzToast from 'hooks/useSlzToast';
import {
  CONFIRM_LEAVE_TOAST_ID,
  CREATE_USER_MANAGEMENT_MODAL_LAYOUT,
  LEAVE_WARNING_TEXT,
  TOAST_MESSAGES,
  USER_MANAGEMENT_DEFAULT_VALUE,
  USER_MANAGEMENT_MODAL_LAYOUT_KEYS,
  USER_MANAGEMENT_STEPS,
  USER_MANAGEMENT_USER_STEP_INDEX,
  VENDOR
} from 'modules/users/constants';
import { useAddCompanyUserMutation } from 'modules/users/hooks';
import { CONFIRM_LEAVE_TOAST } from 'constants';

const ModalLayout = ({ modalLayout, ...rest }) => {
  const Layout = CREATE_USER_MANAGEMENT_MODAL_LAYOUT[modalLayout]?.component;
  return <Layout {...rest} />;
};

const UserManagementModal = (props) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(USER_MANAGEMENT_USER_STEP_INDEX);
  const [userManagement, setUserManagement] = useState(USER_MANAGEMENT_DEFAULT_VALUE);
  const [_, setToast, toast] = useSlzToast();
  const [layoutKey, setLayoutKey] = useState(USER_MANAGEMENT_MODAL_LAYOUT_KEYS.DEFAULT);
  const mutationAddCompanyUser = useAddCompanyUserMutation();
  const isSubmitting = mutationAddCompanyUser.isLoading;

  const submitForm = async () => {
    try {
      const transform = {
        ...userManagement,
        roles: [userManagement.roles]
      };

      await mutationAddCompanyUser.mutateAsync(transform);

      setToast({
        title: TOAST_MESSAGES.addUserSuccess
      });

      handleOnLeaveClick();
    } catch (err) {
      const { message, response } = err;

      setToast({
        title: response?.data?.message || message,
        status: 'warning',
        colorScheme: 'negative'
      });
    }
  };

  const handleOnPrevious = ({ values }) => {
    if (currentStep > 0 && currentStep !== USER_MANAGEMENT_STEPS.length - 1) {
      setUserManagement((currentValue) => ({ ...currentValue, ...values }));
    }
    setCurrentStep(currentStep - 1);
  };

  const handleOnNext = (values) => {
    if (currentStep !== USER_MANAGEMENT_STEPS.length - 1) {
      setUserManagement((currentValue) => ({ ...currentValue, ...values }));
      setCurrentStep(currentStep + 1);
      return;
    }

    submitForm();
  };

  const handleCloseModal = () => {
    history.push({ search: undefined });
    setUserManagement(USER_MANAGEMENT_DEFAULT_VALUE);
    setCurrentStep(USER_MANAGEMENT_USER_STEP_INDEX);
  };

  const handleOnLeaveClick = () => {
    toast.closeAll();
    handleCloseModal();
  };

  const showConfirmLeaveToast = () => {
    !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
      setToast({
        type: CONFIRM_LEAVE_TOAST,
        chakraToastProps: {
          id: CONFIRM_LEAVE_TOAST_ID,
          duration: null
        },
        warningText: LEAVE_WARNING_TEXT,
        okText: 'Continue',
        onCancelClick: toast.closeAll,
        onOkClick: handleOnLeaveClick
      });
  };

  return (
    <SlzGridModal isOpen={props?.isOpen} onClose={handleCloseModal}>
      <ModalLayout
        modalLayout={layoutKey}
        isSubmitting={isSubmitting}
        defaultValues={userManagement}
        currentStep={currentStep}
        onCancel={showConfirmLeaveToast}
        onPrevious={handleOnPrevious}
        onNext={handleOnNext}
        setLayoutKey={setLayoutKey}
      />
    </SlzGridModal>
  );
};
export default UserManagementModal;
