import { Text } from '@chakra-ui/react';
import { TbTrash } from 'react-icons/tb';

import { SlzButton } from 'components/common/SlzButton';

import {
  B2B_CUSTOM_BOX_SPLIT_CONTENT,
  CUSTOM_BOX_ERROR_FLAG,
  CUSTOM_BOX_PREFIX
} from 'modules/b2b-orders/constants';
import { CUSTOM_BOX_SPLIT_BUTTONS_STYLE } from 'modules/b2b-orders/constants/styles';
import { filterArray } from 'modules/b2b-orders/utils';
import { useFormContext } from 'react-hook-form';

const DeleteBoxButton = () => {
  const { getValues, setValue } = useFormContext();

  const handleDeleteBox = () => {
    const updatedFormBoxes = filterArray(getValues(CUSTOM_BOX_PREFIX));
    updatedFormBoxes.pop();
    setValue(CUSTOM_BOX_PREFIX, updatedFormBoxes);

    const boxError = getValues(CUSTOM_BOX_ERROR_FLAG.boxError)?.errors || [];
    const fitleredErrors = boxError.filter((error) => error?.id !== updatedFormBoxes.length + 1);
    setValue(CUSTOM_BOX_ERROR_FLAG.boxError, {
      shouldValidate: false,
      errors: [...fitleredErrors]
    });
  };

  return (
    <SlzButton {...CUSTOM_BOX_SPLIT_BUTTONS_STYLE.deleteButton} onClick={handleDeleteBox}>
      <TbTrash w="12px" h="13.33px" color="red" />
      <Text ml="6.6px">{B2B_CUSTOM_BOX_SPLIT_CONTENT.buttons.delete}</Text>
    </SlzButton>
  );
};

export default DeleteBoxButton;
