import { Fragment, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { GridItem } from '@chakra-ui/react';

import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { BulkOrderTable } from '../components/table';
import { GET_BULK_ORDER_LIST, SET_QUERY_PARAM } from '../actions/index';
import { BULK_ORDER_TAB, BREAD_CRUMB_ITEMS } from '../constants';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  PAGE_INDEX,
  PAGE_SIZE,
  TAB,
  DATE_FROM,
  DATE_TO,
  SORT_BY
} from 'constants/table.js';
import { useBulkOrderContext } from '../context/BulkOrderContext';
import { useBulkOrdersQuery } from '../hooks/useBulkOrdersQuery';
import { transformBulkOrders } from '../mappers/bulk-order-list-mapper';
import { BulkOrderFilter } from 'modules/b2b-orders/components/form';
import BulkOrderTab from 'modules/b2b-orders/components/table/BulkOrderTab';

const BulkOrderList = () => {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [enableQuery, setEnableQuery] = useState(false);
  const [tab, setTab] = useState(routerQuery.get(TAB) ?? BULK_ORDER_TAB.pending.key);
  const [tabIndex, setTabIndex] = useState(
    tab ? Object.keys(BULK_ORDER_TAB).findIndex((key) => key === tab) : 0
  );

  const [_, dispatch] = useGlobalState();
  const { bulkOrder, bulkOrderDispatch } = useBulkOrderContext();

  const {
    data: { bulkOrders = [], totalPage } = {},
    isFetching,
    isError,
    refetch
  } = useBulkOrdersQuery(bulkOrder?.params, (data) => transformBulkOrders(data, tab), enableQuery);

  const { handleCurrentPageChange, handleItemPerPageChange, handleSelectedDateRange } =
    useTableFilterHandler();

  const handleOnTabChange = (index) => {
    const currentTab = Object.keys(BULK_ORDER_TAB)[index];
    setTabIndex(index);
    setTab(currentTab);
    const pendingTab = BULK_ORDER_TAB.pending.key;
    const isPendingTab = currentTab === pendingTab;
    if (!isPendingTab) {
      routerQuery.set(TAB, currentTab);
    } else {
      routerQuery.delete(TAB);
    }
    history.push({ search: routerQuery.toString() });
  };

  const handleOnSort = (column, isDesc) => {
    if (column) {
      routerQuery.set(SORT_BY, column?.sortField);
    }
    history.push({
      search: routerQuery.toString()
    });
  };

  useEffect(() => {
    const params = {
      dateFrom: routerQuery.get(DATE_FROM) ?? '',
      dateTo: routerQuery.get(DATE_TO) ?? '',
      tab: routerQuery.get(TAB),
      sortBy: routerQuery.get(SORT_BY) || '',
      pageIndex: routerQuery.get(PAGE_INDEX) ?? DEFAULT_PAGE_INDEX,
      pageSize: routerQuery.get(PAGE_SIZE) ?? DEFAULT_PAGE_SIZE
    };
    bulkOrderDispatch({
      type: SET_QUERY_PARAM,
      payload: { params }
    });
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [bulkOrder?.params]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  useEffect(() => {
    bulkOrderDispatch({
      type: GET_BULK_ORDER_LIST,
      payload: { bulkOrders }
    });
  }, [isFetching]);

  return (
    <Fragment>
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <BulkOrderFilter tabIndex={tabIndex} onSelectedDateRange={handleSelectedDateRange} />
          <BulkOrderTab tabIndex={tabIndex} onTabChange={handleOnTabChange} />
          <BulkOrderTable
            totalPage={totalPage}
            currentPage={routerQuery.get(PAGE_INDEX)}
            isLoading={isFetching}
            isEmptyPage={isError || totalPage === 0}
            messageToast="Sorry! We could not retrieve any orders information"
            onRefetching={refetch}
            onPageChange={handleCurrentPageChange}
            onItemPerPageChange={handleItemPerPageChange}
            onSort={handleOnSort}
          />
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
};

export default BulkOrderList;
