import React from 'react';

import { SlzPaper } from 'components/common/SlzPaper';
import { Box, Flex } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { SelectRecipientOptionCustom } from './SelectRecipientOptionCustom';
import { ControlledField } from 'components/form';
import { SELECT_RECIPIENT_SELECT_STYLE } from 'modules/b2b-orders/constants/styles';
import { SELECT_RECIPIENTS } from 'modules/b2b-orders/constants';
import { SlzDropdown } from 'components/common/SlzDropdown';

const RecipientDropdown = ({ name, addresses, onAddRecipient }) => {
  const transformAddressOptions = (addresses) => {
    return addresses?.map((address) => ({
      value: address,
      label: JSON.stringify(address)
    }));
  };

  return (
    <>
      <SlzPaper w="full" h="auto" bg="light.300">
        <Box px="1.875rem" py={6}>
          <ControlledField
            label=""
            isInlineError
            name={name}
            placeholder={
              addresses.length === 0
                ? SELECT_RECIPIENTS.NO_OPTIONS_PLACEHOLDER
                : SELECT_RECIPIENTS.PLACEHOLDER
            }
            options={transformAddressOptions(addresses)}
            component={SlzDropdown}
            components={SelectRecipientOptionCustom}
            value={{ label: '', value: '' }}
            isDisabled={addresses.length === 0}
            size="lg"
            color="inherit"
            chakraStyles={SELECT_RECIPIENT_SELECT_STYLE}
          />
        </Box>
        <Flex
          justifyContent="end"
          px="1.875rem"
          py={3}
          borderTop="1px solid"
          borderTopColor="light.700">
          <SlzButton borderRadius="md" onClick={onAddRecipient}>
            +Add Recipient
          </SlzButton>
        </Flex>
      </SlzPaper>
    </>
  );
};

export default RecipientDropdown;
