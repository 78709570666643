import React, { useEffect, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import { OverlayScrollbars, ClickScrollPlugin } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  OS_SCROLLBAR_VERTICAL_INACTIVE_CLASS,
  OS_SCROLLBAR_VERTICAL_SCROLLING_CLASS
} from './constants';

import 'overlayscrollbars/overlayscrollbars.css';
import './index.scss';

const SlzScrollBar = (props) => {
  const { children, ...rest } = props;
  const ref = useRef();
  OverlayScrollbars.plugin(ClickScrollPlugin);

  const handleStopScroll = useMemo(
    () =>
      debounce((element) => {
        element.classList.remove(OS_SCROLLBAR_VERTICAL_SCROLLING_CLASS);
        element.classList.add(OS_SCROLLBAR_VERTICAL_INACTIVE_CLASS);
      }, 4000),
    []
  );

  const handleOnScroll = () => {
    const element = ref.current.getElement().children[3];
    element.classList.remove(OS_SCROLLBAR_VERTICAL_INACTIVE_CLASS);
    element.classList.add(OS_SCROLLBAR_VERTICAL_SCROLLING_CLASS);
    handleStopScroll(element);
  };

  useEffect(() => {
    debounce(() => {
      const element = ref.current.getElement().children[3];
      element.classList.add(OS_SCROLLBAR_VERTICAL_INACTIVE_CLASS);
    }, 4000)();
  }, []);

  return (
    <OverlayScrollbarsComponent
      element={rest?.element}
      ref={ref}
      style={{ height: '100%' }}
      id={uniqueId('SEL-Slz-Scroll-Bar')}
      options={{ scrollbars: { autoHide: 'never', clickScroll: true } }}
      events={{
        scroll: handleOnScroll
      }}>
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default SlzScrollBar;
