import { useQuery } from 'react-query';
import { getIntegrations } from '../services/integration-api';

export const useIntegrationQuery = (select, enabled) => {
  return useQuery({
    queryKey: [`integrations`],
    queryFn: () => getIntegrations(),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
