import React from 'react';
import { Card, Text, VStack, HStack, Box, FormControl } from '@chakra-ui/react';
import { WarehouseIcon } from '../assets';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import {
  CONSIGNMENT_ARRIVING_TYPES,
  CONSIGNMENT_BOX_LOCATION_TEXT,
  NO_BATCH_NUMBER_TEXT,
  PALLET_LOCATION_TEXT,
  SEND_CONSIGNMENT_MODAL_STEPS
} from 'modules/consignments/constants';
import SlzInput from 'components/common/SlzInput/SlzInput';

const StockDetailsSummary = ({
  width,
  height,
  depth,
  weight,
  consignmentArrivingType,
  stockBoxQuantity,
  itemsPerStockbox,
  totalQuantityItems,
  expiryDate,
  batchNumber,
  handleEditStep
}) => {
  const arrivingLocation =
    consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX
      ? CONSIGNMENT_BOX_LOCATION_TEXT
      : PALLET_LOCATION_TEXT;

  return (
    <>
      <Card
        bg="light.700"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem" display="flex" justifyContent="space-between">
          <Box w="50%" display="flex" alignItems="center" justifyContent="flex-start">
            <WarehouseIcon marginRight="0.375rem" sx={{ width: '1.25rem', height: '100%' }} />

            <Box display="flex" alignItems="baseline">
              <Text
                marginRight="0.375rem"
                sx={{
                  fontSize: 'lg',
                  color: 'table.mainText',
                  fontWeight: 'bold'
                }}>
                Stock details
              </Text>

              <Text
                onClick={() => handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS)}
                fontSize="xs"
                cursor="pointer"
                color="main.500">
                Edit
              </Text>
            </Box>
          </Box>
          <Box
            rounded="0.375rem"
            h="1.5rem"
            py="0.313rem"
            px="0.75rem"
            bgColor="main.300"
            display="flex"
            alignItems="center">
            <Text fontSize="sm" color="main.500">
              {arrivingLocation}
            </Text>
          </Box>
        </HStack>
      </Card>
      <Card
        bg="light.300"
        border="1px solid"
        borderColor="light.700"
        w="full"
        px="1.5rem"
        py="1rem"
        variant="filled"
        flexGrow={1}
        sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0.2rem !important' }}>
        <VStack alignItems="flex-start">
          <HStack alignItems="flex-end">
            <Box w="full" marginRight="3.375rem">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Stock Box Quantity
                </SlzFormLabel>
                <Box w="8.625rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={stockBoxQuantity}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
            <Box w="full">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Dimensions (cm)
                </SlzFormLabel>
                <Box w="4.678rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    leftElContent="H"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={height}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
          </HStack>
          <HStack alignItems="flex-end" marginTop="1.125rem !important">
            <Box w="full" marginRight="3.375rem">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Items Per Stock Box
                </SlzFormLabel>
                <Box w="8.625rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={itemsPerStockbox}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
            <Box w="full">
              <FormControl>
                <Box w="4.678rem">
                  <SlzInput
                    fontSize="sm"
                    leftElContent="W"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={width}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
          </HStack>
          <HStack alignItems="flex-end" marginTop="1.125rem !important">
            <Box w="full" marginRight="3.375rem">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Total Stock Quantity
                </SlzFormLabel>
                <Box w="8.625rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={totalQuantityItems}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
            <Box w="full">
              <FormControl>
                <Box w="4.678rem">
                  <SlzInput
                    fontSize="sm"
                    leftElContent="D"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={depth}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
          </HStack>
          <HStack alignItems="flex-end" marginTop="1.125rem !important">
            <Box w="full" marginRight="3.375rem">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Batch number
                </SlzFormLabel>
                <Box w="8.625rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={batchNumber || NO_BATCH_NUMBER_TEXT}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
            <Box w="full">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Stock Box Weight (kg)
                </SlzFormLabel>
                <Box w="8.625rem">
                  <SlzInput
                    fontSize="sm"
                    textAlign="right"
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    value={weight}
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
          </HStack>
          <HStack alignItems="flex-end" marginTop="1.125rem !important">
            <Box w="full" marginRight="3.375rem">
              <FormControl>
                <SlzFormLabel fontSize="sm" color="dark.700">
                  Expiration date
                </SlzFormLabel>
                <Box w="5.188rem">
                  <SlzInput
                    textAlign="center"
                    fontSize="sm"
                    placeholder="DD/MM/YY"
                    value={expiryDate}
                    color="dark.700 !important"
                    size="sm"
                    bgColor="light.300"
                    disabled
                  />
                </Box>
              </FormControl>
            </Box>
          </HStack>
        </VStack>
      </Card>
    </>
  );
};

export default StockDetailsSummary;
