import {
  defineStyle,
  createMultiStyleConfigHelpers,
  defineStyleConfig
} from '@chakra-ui/styled-system';

const parts = ['container', 'title', 'description', 'icon'];

const { definePartsStyle } = createMultiStyleConfigHelpers(parts);

const colorScheme = {
  success: {
    bg: '#EBF7F1',
    color: 'positive.500'
  },
  main: {
    bg: '#F0F7FF',
    color: 'main.500'
  },
  dark: {
    bg: 'light.500',
    color: 'dark.700'
  }
};

const baseStyle = definePartsStyle({
  container: {
    minH: '1rem',
    fontSize: '0.875rem',
    position: 'relative',
    border: '1px',
    borderRadius: '0.375rem',
    fontWeight: 'bold',
    flexDirection: 'column'
  },
  title: {
    color: 'light.500',
    px: 4,
    borderTopRadius: '0.3rem',
    fontWeight: 'bold'
  },
  description: {
    height: 'full',
    px: 2,
    py: '0.313rem',
    fontWeight: 'bold',
    alignItems: 'center'
  },
  icon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    w: 6,
    h: 6,
    top: '-0.75rem',
    right: '-0.75rem',
    borderRadius: 'full',
    bg: '#FEE16E'
  }
});
const sizes = {};

const variant = defineStyle((props) => {
  const { variant } = props;
  const { bg, color } = colorScheme[variant];
  return {
    container: {
      position: 'relative',
      border: '1px',
      bg: bg,
      borderColor: color,
      borderRadius: '0.375rem',
      fontWeight: 'medium',
      flexDirection: 'column'
    },
    title: {
      bg: color,
      color: 'light.500',
      px: 2,
      borderTopRadius: '0.3rem'
    },
    description: {
      height: 'full',
      px: 2,
      py: '0.313rem',
      alignItems: 'center'
    }
  };
});

const variants = {
  success: variant,
  main: variant,
  dark: variant
};

const defaultProps = {
  variant: 'main'
};

export default defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps
});
