import {
  Box,
  Card,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
  useMultiStyleConfig
} from '@chakra-ui/react';
import { TagIcon } from '../assets';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { getProducts } from 'modules/products/services/products-api';
import { transformProducts } from 'modules/products/mappers/product-list-mapper';
import { PRODUCT_COLUMNS } from 'modules/consignments/constants';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { IoClose } from 'react-icons/io5';

const getPaperErrorStyles = {
  borderColor: 'negative.500',
  borderWidth: 2
};

const ProductsDropdown = () => {
  const {
    formState: { errors },
    setValue,
    watch,
    clearErrors
  } = useFormContext();

  const styles = useMultiStyleConfig('SlzSearchInput');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  useEffect(() => {
    setSelectedProduct(watch('product'));
  }, [watch('product')]);

  const handleAutocompleteSearch = (searchTerm) => {
    setSearchParams({
      searchText: searchTerm
    });
  };

  const handleSetProduct = (product) => {
    setValue('product', product);
    clearErrors('product');
  };

  const fetchFn = ({ searchText }) => getProducts({ searchText });

  const transformFn = (res) => {
    if (res !== undefined && res?.items !== undefined) return transformProducts(res).products;
  };

  return (
    <VStack w="51.625rem" mt={2.5} mb={3}>
      <Card
        bg="light.500"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem">
          <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Text
            sx={{
              textAlign: 'left',
              lineHeight: '20px',
              letterSpacing: '-0.14px',
              fontSize: '16px',
              color: 'table.mainText'
            }}>
            <b>Select a product</b>
          </Text>
        </HStack>
      </Card>
      <FormControl isInvalid={errors?.product}>
        <Card
          bg="light.500"
          mt="2rem"
          w="100%"
          h="4.5rem"
          px="1.5rem"
          variant="filled"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '0.2rem !important',
            ...(errors?.product && getPaperErrorStyles)
          }}>
          {selectedProduct ? (
            <Box
              pos="relative"
              height="3rem"
              bgColor="light.300"
              borderRightRadius="0.375rem"
              border="1px solid"
              borderColor="dark.300"
              borderLeftRadius="0.375rem"
              _before={{
                content: `""`,
                pos: 'absolute',
                w: '0.5625rem',
                h: 'full',
                bg: selectedProduct?.tag?.color,
                borderLeftRadius: '0.375rem'
              }}>
              <HStack h="full" display="flex" alignItems="flex-start" justifyContent="space-evenly">
                <Text
                  h="full"
                  display="flex"
                  alignItems="center"
                  fontSize="12px"
                  sx={{ fontWeight: 'bold' }}>
                  {selectedProduct?.name}
                </Text>

                <Box>
                  <Box fontWeight="bold" fontSize="10px">
                    SKU
                  </Box>
                  <Box fontSize="10px" lineHeight="1.5">
                    {selectedProduct?.sku || 'N/A'}
                  </Box>
                </Box>

                <Box>
                  <Box fontWeight="bold" fontSize="10px">
                    EAN/SUID
                  </Box>
                  <Box fontSize="10px" lineHeight="1.5">
                    {selectedProduct?.ean || selectedProduct?.selazarUniqueID}
                  </Box>
                </Box>

                <Box
                  w="7.938rem"
                  h="full"
                  bgColor={selectedProduct?.tag?.color}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  {selectedProduct?.tag?.name}
                </Box>

                <IconButton
                  alignSelf="center"
                  w="full"
                  icon={<Icon as={IoClose} />}
                  sx={styles.clearButton}
                  onClick={() => setValue('product', null)}
                />
              </HStack>
            </Box>
          ) : (
            <SlzAutocompleteInput
              customInputStyles={{ bgColor: 'light.300' }}
              isLocal={false}
              width="100%"
              placeholder="Search"
              variant="accent"
              accentField="tag"
              searchBy={['name']}
              showedFields={PRODUCT_COLUMNS.map((col) => col)}
              onTypingSearch={handleAutocompleteSearch}
              onNavigate={(record) => handleSetProduct(record)}
              transformFn={transformFn}
              fetchFn={fetchFn}
              searchParams={searchParams}
            />
          )}
        </Card>
        <FormErrorMessage fontSize="lg">{errors?.product?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default ProductsDropdown;
