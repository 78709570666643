import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Td } from '@chakra-ui/react';
import { CELL_BUTTON_ACCORDION_WIDTH } from '../../Layout/Constants';
import { SlzSingleButton } from '../../SlzButton';
import { getAccordionStyles } from '../Styles';

const SlzAccordionMenu = (props) => {
  const { row, onClick, size } = props;
  const buttonStyles = getAccordionStyles(row?.isExpand);
  return (
    <Td
      className="table--td"
      pos="relative"
      sx={{ py: 'inherit', w: CELL_BUTTON_ACCORDION_WIDTH, maxW: CELL_BUTTON_ACCORDION_WIDTH }}>
      <Box pos="relative">
        <Flex
          data-testid={`btn-chevron-icon-${row?.id}`}
          justifyContent="center"
          alignItems="center">
          <SlzSingleButton
            sx={buttonStyles}
            size={size ?? 'md'}
            icon={<ChevronDownIcon />}
            onClick={onClick}
          />
        </Flex>
      </Box>
    </Td>
  );
};

export default SlzAccordionMenu;
