import { Text } from '@chakra-ui/react';

export const INITIAL_ADDRESS_BOOK_STATE = {
  list: [],
  params: {},
  filters: []
};

export const ADDRESS_BOOK_PATHNAME = '/orders/address-book';

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Orders'
  },
  {
    path: '/orders/address-book',
    title: 'Address Book'
  }
];

export const ADDRESS_BOOK_DEFAULT_PAGE = 1;
export const ADDRESS_BOOK_DEFAULT_PAGE_SIZE = 10;

export const ADDRESS_BOOK_DISPLAY_FIELDS_INPUT_SEARCH = [
  'name',
  'companyName',
  'email',
  'phone',
  'location'
];

export const ADDRESS_BOOK_DISPLAY_FIELDS = ['name', 'email', 'phone', 'location', 'id'];

export const ADDRESS_BOOK_SKELETON_CELL = {
  name: null,
  email: null,
  phone: null,
  location: null,
  id: null
};

export const ACTION_TYPES = {
  EDIT: 1,
  DELETE: 2
};

export const ACTION_OPTIONS = [
  {
    value: ACTION_TYPES.EDIT,
    text: <Text align="left">Edit recipient</Text>,
    isDisabled: false
  },
  {
    value: ACTION_TYPES.DELETE,
    text: (
      <Text align="left" color="red.300">
        Remove
      </Text>
    ),
    isDisabled: false,
    isWarning: true
  }
];

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const CONFIRM_DELETE_TOAST_ID = 'confirmDeleteToastId';

export const CONFIRM_LEAVE_TOAST_ID = 'confirmLeaveToastId';

export const DELETE_CONFIRMATION = {
  message: 'Are you sure you want to remove this recipient from the address book?',
  confirmText: 'Yes, remove',
  cancelText: 'No',
  cancelProps: {
    variant: 'outline',
    borderColor: 'dark.700',
    colorScheme: 'dark'
  },
  confirmProps: {
    color: 'sunshine.500',
    bg: 'dark.700',
    colorScheme: 'dark'
  }
};

export const LEAVE_CONFIRMATION = {
  message: 'Oops! Are you sure you want to leave without saving this recipient?',
  confirmText: 'Leave',
  cancelText: 'Continue',
  confirmProps: {
    variant: 'outline',
    borderColor: 'dark.700',
    colorScheme: 'dark',
    ml: '2rem'
  },
  cancelProps: {
    color: 'sunshine.500',
    bg: 'dark.700',
    colorScheme: 'dark'
  }
};

export const ACTION_MESSAGE = {
  DELETE: {
    SUCCESS: 'Recipient has been successfully removed from address book.',
    FAIL: 'There seems to be a problem'
  },
  SAVE: {
    CREATE_SUCCESS: 'Recipient has been successfully added to address book',
    UPDATE_SUCCESS: 'Recipient details have been successfully updated',
    DUPLICATE:
      'Oops, recipient already exists. You can use the search bar to find existing recipients.',
    FAIL: 'There was an error saving your changes. Please try again.'
  }
};
