import { useFormContext } from 'react-hook-form';
import { SlzTextarea } from '../../SlzTextarea';
import { NestedFieldContainer } from './NestedField';

export const NestedTextarea = ({ fieldProps = {}, ...rest }) => {
  const {
    formState: { errors }
  } = useFormContext();
  return (
    <NestedFieldContainer
      SlzComponent={SlzTextarea}
      isControlled={true}
      fieldProps={{
        ...{
          size: 'sm',
          autoComplete: 'off',
          status: errors[rest?.field] && 'error'
        },
        ...fieldProps
      }}
      {...rest}
    />
  );
};
