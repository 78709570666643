import { Box, Text, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';
import {
  CustomPackagingIcon,
  SameDayDeliveryIcon,
  NextDayDeliveryIcon,
  EmissionFreeIcon
} from '../Icons/chipIcons';

const ChipIcon = (props) => {
  const { iconName, size } = props;
	let height;
	let width;

	if (size == 'md' || size == 'lg') {
		height = "0.75rem";
		width = "0.688rem";
	} else if (size == 'sm') {
		height = "0.563rem";
		width = "0.5rem";
	}
  let iconBody = <></>;

  switch (iconName) {
    case 'customPackaging':
      iconBody = <CustomPackagingIcon h={height} w={width } />;
      break;
    case 'sameDayDelivery':
      iconBody = <SameDayDeliveryIcon h={height} w={width} />;
      break;
    case 'nextDayDelivery':
      iconBody = <NextDayDeliveryIcon h={height} w={width} />;
      break;
    case 'emissionFree':
      iconBody = <EmissionFreeIcon h={height} w={width} />;
      break;
    default:
      iconBody = <></>
      break;
  }

  return iconBody;
};

const SlzIconChip = (props) => {
  const { children, variant, colorScheme, icon, size, ...rest } = props;
  const styles = useStyleConfig('SlzChip', { variant, colorScheme, size });

  return (
      <Box
        as="span"
        role="slz-icon-chip"
        __css={styles}
        {...rest}
				pl="0.375rem"
				pr="0.375rem"
				borderRadius="0.375rem"
        data-testid="chip-icon-wrap"
        id={uniqueId(`${SEL_PREFIX_ID}-Slz-Chip`)}>
        <ChipIcon iconName = {icon} size = {size} data-testid="icon-wrap" />
        <Text noOfLines={1} mb={0} data-testid="chip-content" pl="0.375rem">
          {children}
        </Text>
      </Box>
  );
};

export default SlzIconChip;
