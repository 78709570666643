import { Link, useStyleConfig } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzLink = (props) => {
  const { children, variant, leftIcon, rightIcon, color, ...rest } = props;
  const styles = useStyleConfig('SlzLink', { variant });

  return (
    <Link
      data-testid="slz-link"
      variant={variant}
      {...rest}
      sx={{
        ...styles.link,
        color
      }}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Link`)}>
      {leftIcon && <ExternalLinkIcon data-testid="link-left-icon" sx={styles.leftIcon} />}
      {children}
      {rightIcon && <ExternalLinkIcon data-testid="link-right-icon" sx={styles.rightIcon} />}
    </Link>
  );
};

export default SlzLink;
