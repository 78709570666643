import { VStack, Text, Icon } from '@chakra-ui/react';
import { SlzMeasuredInput, SlzPasswordInput, SlzTextInput } from '../../components/common/SlzInput';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledField, Field } from 'components/form';
import { SlzButton } from 'components/common/SlzButton';
import SelectorCard from 'components/card/SelectorCard';
import { GridIcon, MasonryIcon } from 'components/common/Icons';

const TextFields = () => {
  const methods = useForm({
    defaultValues: {
      author: 'Bao Truong',
      username: ''
    },
    resolver: yupResolver(
      yup.object({
        author: yup.string().required(),
        username: yup.string().required(),
        height: yup.number().required(),
        weight: yup.number().required(),
        gender: yup.string().required('*Please select an option')
      })
    ),
    reValidateMode: 'onSubmit',
    shouldFocusError: false
  });

  return (
    <>
      <Text as="h1" fontSize="2xl" fontWeight={600} mb={5}>
        Text Fields
      </Text>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((values) => console.log(values))}>
          <VStack spacing={'4'} mb="4">
            <Field size="md" label="Author" name="author" component={SlzTextInput} isDisabled />
            <Field
              size="md"
              label="Username"
              name="username"
              component={SlzTextInput}
              placeholder="Username"
              isInlineError
            />
            <Field
              size="md"
              label="Password"
              name="password"
              component={SlzPasswordInput}
              placeholder="Password"
              isInlineError
            />
            <Field
              size="md"
              label="Height (cm)"
              name="height"
              component={SlzMeasuredInput}
              isInlineError
              unit="Height"
              min={0}
            />
            <Field
              size="md"
              label="Weight"
              name="weight"
              component={SlzMeasuredInput}
              isInlineError
              unit="Kg"
              alignment="right"
              min={0}
            />

            <ControlledField
              label="Gender"
              name="gender"
              component={(props) => (
                <SelectorCard.Group spacing="3.75rem" {...props}>
                  <SelectorCard value="male" label="Male">
                    <Icon as={GridIcon} w="5.25rem" h="5.25rem" />
                  </SelectorCard>
                  <SelectorCard value="female" label="Female">
                    <Icon as={MasonryIcon} w="5.25rem" h="5.25rem" />
                  </SelectorCard>
                </SelectorCard.Group>
              )}
            />
          </VStack>

          <SlzButton type="submit">Submit</SlzButton>
        </form>
      </FormProvider>
    </>
  );
};

export default TextFields;
