import React, { Fragment, memo } from 'react';
import { GridItem, Heading, HStack, Image, Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';

import { SlzGridModal } from 'components/common/SlzModal';

import { INTEGRATION_TYPE } from 'modules/integrations/constants';
import {
  CreateShopifyIntegration,
  CreateWooCommIntegration
} from 'modules/integrations/components/form';

const COMPONENT_MAP = {
  [INTEGRATION_TYPE.shopify]: CreateShopifyIntegration,
  [INTEGRATION_TYPE.woocommerce]: CreateWooCommIntegration
};

function CreateIntegrationBody({ type, ...rest }) {
  const Component = COMPONENT_MAP[type] || Fragment;

  return <Component {...rest} />;
}

export default memo(function CreateIntegrationModal({
  title,
  logo,
  isOpen,
  onClose,
  type,
  onSubmit,
  isSubmitting
}) {
  return (
    <SlzGridModal isOpen={isOpen} variant="integrations" onClose={onClose}>
      <GridItem gridColumn="2/12" mb="5" pt="8">
        <HStack spacing={0}>
          <Image src={logo} h="10" />
          <Icon as={FaChevronRight} fontSize={28} color="dark.500" cursor="pointer" />
          <Heading as="h3" fontSize="2xl" lineHeight={'none'} mb="0">
            {title}
          </Heading>
        </HStack>
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <CreateIntegrationBody
          type={type}
          onCancel={onClose}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </GridItem>
    </SlzGridModal>
  );
});
