import React from 'react';
import { Icon } from '@chakra-ui/react';
const CheckSuccessIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="14.217"
      height="10.219"
      viewBox="0 0 14.217 10.219"
      {...props}>
      <svg>
        <g id="Group_34579" data-name="Group 34579" transform="translate(-50.892 56.109)">
          <path
            id="Union_311"
            data-name="Union 311"
            d="M4.366,9.85l-4-4a1.257,1.257,0,0,1,0-1.768,1.24,1.24,0,0,1,.886-.369,1.218,1.218,0,0,1,.881.369L5.247,7.2,12.081.369a1.25,1.25,0,1,1,1.773,1.762L6.134,9.85a1.243,1.243,0,0,1-1.768,0Z"
            transform="translate(50.891 -56.109)"
            fill="#fff"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default CheckSuccessIcon;
