import CommonEndpoints from 'components/common/Endpoints';
import { buildQuery } from 'utils/helper';
import axiosClient from 'config/axios';

export async function getReturns(filters) {
  let url = new URL(CommonEndpoints.RETURNS.GET.RETAILER_RETURNS);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function getReturnDetail(id) {
  let url = CommonEndpoints.RETURNS.GET.RETAILER_RETURN_DETAIL + id;
  return await axiosClient.get(url);
}

export async function getOrderDetail(id) {
  let url = `${CommonEndpoints.RETAILER.GET.ORDER_DETAILS}/${id}`;
  return await axiosClient.get(url);
}
