import OrderDetail from './OrderDetail';

const OrderPartials = (props) => {
  return (
    <OrderDetail {...props}>
      <OrderDetail.header />
      <OrderDetail.nav />
      <OrderDetail.footer />
      <OrderDetail.accordionTable />
    </OrderDetail>
  );
};

export default OrderPartials;
