import { Box, Table, Tbody, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useGlobalState } from 'contexts/GlobalContext';
import { useWindowDimensions } from '../../../../hooks/useWindowDimensions';
import Rows from '../Rows';
import { getTransitionSubRow } from '../Styles';
import { TableContext } from '../TableContext';
import {
  calculatorColumnFixedWidth,
  calculatorTableDimensions,
  countColumnDisplay
} from '../utils';

const ContentRender = ({
  isShow,
  styles,
  markerColor,
  configProps,
  items,
  isChild,
  variant,
  context,
  ...rest
}) => {
  return (
    <Box sx={styles}>
      <TableContext.Provider value={context}>
        <Table
          sx={{ borderTop: '0.188rem solid white' }}
          size="sm"
          variant={variant}
          colorScheme={markerColor}
          bg={'white'}
          {...rest}>
          <Tbody>
            <Rows
              rows={items}
              isChild={isChild}
              type={configProps?.type}
              showMarked={configProps?.isShowMarker}
              showImage={configProps?.showImage}
            />
          </Tbody>
        </Table>
      </TableContext.Provider>
    </Box>
  );
};

const SlzAccordionTable = ({
  rowId,
  data,
  isShow,
  configProps,
  isChild,
  variant,
  spacing = false,
  isLoading,
  refetch,
  ...rest
}) => {
  const [columns] = useState(configProps?.columns);
  const [context, setContext] = useState({
    columnWidth: 0,
    paddingValue: 0,
    columns,
    rows: [],
    displayFields: configProps?.displayFields,
    variant,
    rowProps: {
      hasExpandedRow: false,
      accordionProps: configProps
    }
  });
  const { width } = useWindowDimensions();
  const [isLargerThan1279] = useMediaQuery('(min-width: 1279px)');
  const [{ app }] = useGlobalState();

  const markerColor = configProps?.markerColor;
  const markerColorStyles = configProps?.isShowMarker
    ? {
        borderLeftWidth: `${configProps?.markedWidth}px`,
        borderLeftColor: markerColor ?? 'inherit'
      }
    : {};

  const styles = {
    ...markerColorStyles,
    pl: configProps?.isShowMarker ? '0.188rem' : '',
    bg: 'white',
    overflow: 'hidden',
    display: 'flex',
    ...getTransitionSubRow(isShow)
  };

  const getDimensions = useMemo(() => {
    const columnFixedWidth = calculatorColumnFixedWidth({
      isChild,
      showMarked: configProps?.isShowMarker,
      markedWidth: configProps?.markedWidth,
      showImage: configProps?.showImage,
      imageWidth: configProps?.imageWidth,
      isAccordion: false,
      actions: false,
      displayFields: configProps?.displayFields,
      spacing: configProps?.spacing,
      columns
    });
    const columnDisplayCount = countColumnDisplay({
      children: {},
      columns,
      displayFields: configProps?.displayFields
    });
    return calculatorTableDimensions({
      columnFixedWidth,
      columnDisplayCount,
      isLargerThan1279,
      width,
      isNavbarExpanded: app?.isNavbarExpanded
    });
  }, [
    width,
    columns,
    isLargerThan1279,
    configProps?.isShowMarker,
    configProps?.markedWidth,
    configProps?.showImage,
    configProps?.imageWidth,
    configProps?.spacing,
    app?.isNavbarExpanded
  ]);

  useEffect(() => {
    setContext((context) => ({
      ...context,
      ...getDimensions
    }));
  }, [width]);

  useEffect(() => {
    setContext((context) => ({
      ...context,
      isLoading
    }));
  }, [isLoading]);

  useEffect(() => {
    !!isShow && rowId && refetch();
  }, [isShow]);

  return (
    <ContentRender
      isShow={isShow}
      styles={styles}
      markerColor={markerColor}
      configProps={configProps}
      items={isLoading ? [{}] : data}
      isChild={isChild}
      columns={columns}
      variant={variant}
      context={context}
      {...rest}
    />
  );
};

export default SlzAccordionTable;
