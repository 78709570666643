import dayjs from 'dayjs';
import { DATE_FORMAT_DDMMYY } from 'constants';
import { getFilterColor, getReturnServiceLabel, getReturnStatusLabel } from '../utils';
import { STATUS } from '../constants';

export const transformReturns = ({ returns = [], pageCount = 0 }) => ({
  returns: returns.map((returnItem) => {
    const { status } = returnItem;
    const itemStatus = status === null ? STATUS.PROCESSED.value : status;
    return {
      ...returnItem,
      status: {
        name: getReturnStatusLabel(itemStatus),
        key: itemStatus,
        color: getFilterColor(itemStatus)
      },
      returnService: {
        key: returnItem.returnService,
        value: getReturnServiceLabel(returnItem.returnService)
      },
      formattedProcessedDate: dayjs(returnItem.processedDate).format(DATE_FORMAT_DDMMYY)
    };
  }),
  totalPage: pageCount
});

export const transformAccordionItems = ({ data }, returnData) => {
  const {
    data: { retailerReturnItems }
  } = data;

  return retailerReturnItems.map((detailItem) => ({
    ...detailItem,
    imageUrl: detailItem.productImage,
    returnData
  }));
};
