import React, { useEffect, useState } from 'react';
import { Box, useBreakpointValue, useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { SlzNavbar } from 'components/common/SlzNavbar';
import {
  ACTIVE_NAVBAR_ITEM,
  ACTIVE_USER_SETTING_MODAL,
  NAVBAR_CONFIGS,
  NAVBAR_CONFIGS_ADMIN,
  MANAGE_PREFERENCE_PERMISSION,
  SET_IS_NAVBAR_EXPANDED
} from 'constants/common';
import Header from './Header';
import { useGlobalState } from 'contexts/GlobalContext';
import UserSettingModal from 'modules/preferences/components/modal/UserSettingModal';
import { USER_MANAGEMENT_KEY_PARAM } from 'modules/users/constants';
import { UserManagementModal } from 'modules/users/components/modal';
import { getUser } from 'Utilities';

const EXPANDED = 'expanded';
const getNavBarConfig = () => {
  const user = getUser();

  if (user === null)
    return NAVBAR_CONFIGS;
  else {
    if (user.permissions.includes(MANAGE_PREFERENCE_PERMISSION))
    return NAVBAR_CONFIGS_ADMIN;
  }
  return NAVBAR_CONFIGS;
}

const SEL_NAVBAR_CONFIGS = getNavBarConfig();

const findSectionByPath = (path) => {
  const section = SEL_NAVBAR_CONFIGS.find((item) => {
    const isItemPathIncluded = path.includes(item.path);
    if (isItemPathIncluded) {
      return true;
    }
    const isChildPathIncluded = item.children?.some((child) => path.includes(child.path));
    return isChildPathIncluded;
  });

  return section;
};

const MainLayout = ({ children }) => {
  const [{ app }, dispatch] = useGlobalState();
  const useSettingModal = useDisclosure();
  const location = useLocation();
  const variant = useBreakpointValue({ xl: EXPANDED }, { ssr: false }); // set ssr to make sure we get initial value correctly
  const [isExpanded, setIsExpanded] = useState(variant === EXPANDED);

  const [navbarActiveItem, setNavbarActiveItem] = useState({
    key: `${location.pathname}${location.search}`,
    section: findSectionByPath(`${location.pathname}${location.search}`)
  });

  const routerQuery = useRouterQuery();
  const isOpenAddUserModal = routerQuery.get(USER_MANAGEMENT_KEY_PARAM.IS_CREATE_USER_MANAGEMENT);

  const shouldExpanded = () => {
    setIsExpanded(variant === EXPANDED);
  };

  const handleCollapseMenu = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleOnCloseUserSettingModal = () => {
    useSettingModal.onClose();
    dispatch({
      type: ACTIVE_USER_SETTING_MODAL,
      payload: null
    });
  };

  useEffect(() => {
    dispatch({
      type: SET_IS_NAVBAR_EXPANDED,
      payload: isExpanded
    });
  }, [isExpanded]);

  useEffect(() => {
    shouldExpanded();
  }, [variant]);

  useEffect(() => {
    const currentNavbarActiveItem = {
      key: `${location.pathname}${location.search}`,
      section: findSectionByPath(`${location.pathname}${location.search}`)
    };

    setNavbarActiveItem(currentNavbarActiveItem);

    dispatch({
      type: ACTIVE_NAVBAR_ITEM,
      payload: currentNavbarActiveItem
    });
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (app && app[ACTIVE_USER_SETTING_MODAL]) {
      useSettingModal.onOpen();
    }
  }, [app]);

  return (
    <React.Fragment>
      {useSettingModal.isOpen && <UserSettingModal onClose={handleOnCloseUserSettingModal} />}
      <UserManagementModal isOpen={isOpenAddUserModal} />
      <Header isExpanded={isExpanded} />
      <Box
        className="content-wrapper"
        sx={{ pl: isExpanded ? '15.5rem' : '6rem', marginTop: '23px' }}>
        <SlzNavbar
          isExpanded={isExpanded}
          activeItem={navbarActiveItem}
          configs={SEL_NAVBAR_CONFIGS}
          onCollapseMenu={handleCollapseMenu}
        />
        <Box sx={{ p: '0 0 24px 0' }}>{children}</Box>
      </Box>
    </React.Fragment>
  );
};

export default MainLayout;
