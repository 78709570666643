import { SlzButton } from 'components/common/SlzButton';
import SlzCTA from 'components/common/SlzCTA/SlzCTA';
import { RATE_MATCHER_CTA_TEXT } from 'modules/integrations/constants';
import { RateMatcherCTAText } from '.';

const StepOneCTAButtons = ({ step, onIgnore, onNextStep, onConfigure }) => (
  <>
    <SlzButton
      size="sm"
      marginRight="1rem"
      variant="solid"
      color="negative.500"
      colorScheme="light"
      onClick={() => {
        onNextStep && onNextStep(step);
        onIgnore();
      }}>
      Ignore this
    </SlzButton>
    <SlzButton
      size="sm"
      variant="outline"
      colorScheme="light"
      color="light.300"
      onClick={onConfigure}>
      Configure
    </SlzButton>
  </>
);

export default function RateMatcherWarning({ step, onIgnore, onNextStep, onConfigure, onClose }) {
  return (
    <SlzCTA
      width="827px"
      size="md"
      variant="block"
      colorScheme="red"
      buttons={
        <StepOneCTAButtons
          step={step}
          onIgnore={onIgnore}
          onNextStep={onNextStep}
          onConfigure={onConfigure}
        />
      }
      onClose={onClose}>
      <RateMatcherCTAText
        title={RATE_MATCHER_CTA_TEXT.step1.title}
        description={RATE_MATCHER_CTA_TEXT.step1.description}
      />
    </SlzCTA>
  );
}
