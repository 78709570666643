import React, { useState } from 'react';
import { Icon } from '@chakra-ui/react';
import { VscChromeClose } from 'react-icons/vsc';
import { HiCheck } from 'react-icons/hi';
import Input from './Input';
import { PasswordIcon } from './SlzInput';

export default React.forwardRef(function TextInput(
  { size, isInvalid, isValid, onClear, onChange, inputGroupStyles, type, ...props },
  ref
) {
  const [showedPassword, setShowedPassword] = useState(props?.showedPassword || false);

  return (
    <Input.Group size={size} {...inputGroupStyles}>
      <Input
        ref={ref}
        isInvalid={isInvalid}
        isValid={isValid}
        onChange={onChange}
        type={type === 'password' && showedPassword ? 'text' : type}
        {...props}
      />
      {isInvalid && (
        <Input.RightElement>
          <Icon as={VscChromeClose} color="negative.500" onClick={onClear} />
        </Input.RightElement>
      )}
      {isValid && (
        <Input.RightElement>
          <Icon as={HiCheck} color="positive.500" />
        </Input.RightElement>
      )}
      {type === 'password' && !isValid && !isInvalid && (
        <Input.RightElement>
          <PasswordIcon showedPassword={showedPassword} setShowedPassword={setShowedPassword} />
        </Input.RightElement>
      )}
    </Input.Group>
  );
});
