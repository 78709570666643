import { Icon } from '@chakra-ui/react';
import React from 'react';

const EyeIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      {...props}>
      <g id="Group_3388" data-name="Group 3388" transform="translate(5716)">
        <circle
          id="Ellipse_209"
          data-name="Ellipse 209"
          cx="15"
          cy="15"
          r="15"
          transform="translate(-5716)"
          fill="currentColor"
        />
        <g id="tabler-icon-eye" transform="translate(-5713 3)">
          <path id="Path_8701" data-name="Path 8701" d="M0,0H24V24H0Z" fill="none" />
          <path
            id="Path_8702"
            data-name="Path 8702"
            d="M22,12q-4,7-10,7T2,12Q6,5,12,5t10,7"
            fill="none"
            stroke="#676a74"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <circle
            id="Ellipse_1078"
            data-name="Ellipse 1078"
            cx="2"
            cy="2"
            r="2"
            transform="translate(10 10)"
            fill="#686a73"
            stroke="#686a73"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default EyeIcon;
