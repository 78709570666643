import React, { Fragment, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useIsMount } from 'hooks/useIsMount';
import { Field } from 'components/form';

import { PasswordCriteria } from 'modules/users/components/other';
import { useCheckPasswordCriteria } from 'modules/users/hooks';

export default function PasswordAndConfirmPassword() {
  const isMount = useIsMount();
  const { formState, setValue } = useFormContext();
  const { isDirty, errors } = formState;
  const { password, confirmPassword } = useWatch();
  const criteriaStatus = useCheckPasswordCriteria({
    password,
    confirmPassword,
    isDirty: isDirty
  });

  useEffect(() => {
    if (!isMount && password === undefined && confirmPassword === undefined) {
      setValue('password', '', { shouldDirty: true });
      setValue('confirmPassword', '', { shouldDirty: true });
    }
  }, [errors.confirmPassword, errors.password]);

  return (
    <Fragment>
      <Box w="full">
        <PasswordCriteria criteriaStatus={criteriaStatus} />
      </Box>

      <Field
        type="password"
        label="Password"
        name="password"
        placeholder="Password"
        isInlineError
      />

      <Field
        type="password"
        label="Confirm password"
        name="confirmPassword"
        placeholder="Confirm password"
        isInlineError
      />
    </Fragment>
  );
}
