import { ChevronLeftIcon } from '@chakra-ui/icons';
import { HStack, Text } from '@chakra-ui/react';

import { CUSTOM_LIBRARY_TEXT } from 'modules/preferences/constants';
import { CUSTOM_LIBRARY_STYLE } from 'modules/preferences/styles';

const BackNavigator = ({ onBack }) => {
  return (
    <HStack onClick={onBack} {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.backNavigation.wrapper}>
      <ChevronLeftIcon {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.backNavigation.icon} />
      <Text {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.backNavigation.text}>
        {CUSTOM_LIBRARY_TEXT.backButton}
      </Text>
    </HStack>
  );
};

export default BackNavigator;
