import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['container', 'homeItem', 'item']);

const SlzBreadcrumb = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      listStyleType: 'none',
      m: 0,
      display: 'flex',
      alignItems: 'center',
      '& > li:last-child > button': {
        color: 'dark.700',
        fontWeight: 700,
        _after: {
          display: 'none'
        }
      }
    },
    homeItem: {
      pos: 'relative',
      lineHeight: 0,
      mt: 1,
      _after: {
        content: '""',
        pos: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        w: 'full',
        h: 'full',
        bgColor: 'light.700',
        transition: 'all 0.3s',
        transform: 'scale(0)'
      },
      _hover: {
        _after: { transform: 'scale(1)' }
      }
    },
    item: {
      pos: 'relative',
      lineHeight: 1,
      fontSize: 'xl',
      fontWeight: 400,
      color: 'dark.500',
      _after: {
        content: '""',
        pos: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        w: 'full',
        h: 'full',
        bgColor: 'light.700',
        transition: 'all 0.3s',
        transform: 'scale(0)'
      },
      _hover: {
        _after: { transform: 'scale(1)' }
      },
      '& > svg': {
        color: 'dark.500',
        w: '2',
        h: '3',
        mr: 1,
        mb: 0.5
      }
    }
  },
  sizes: {
    md: {
      container: {
        '& > li:nth-of-type(2) > button': {
          h: 8,
          fontSize: '2rem'
        }
      },
      homeItem: {
        w: 6,
        h: 6,
        borderRadius: '0.1875rem',
        '& > svg': {
          color: 'dark.500',
          w: '0.875rem',
          h: '0.875rem'
        }
      },
      item: {
        h: 6,
        px: 1,
        borderRadius: '0.1875rem'
      }
    }
  },
  variants: {},
  defaultProps: {
    size: 'md'
  }
});

export default SlzBreadcrumb;
