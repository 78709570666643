import { useQuery } from 'react-query';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { getBulkOrderDetail } from 'modules/b2b-orders/services/bulk-orders-api';
import { transformAccordionItems } from 'modules/b2b-orders/mappers/bulk-order-list-accordion-mapper';

const BulkOrderAccordionTable = ({ row, accordionProps }) => {
  const {
    data: itemDetails,
    isLoading,
    refetch
  } = useQuery([`bulk-orders-detail-${row?.id}`], () => getBulkOrderDetail(row?.id), {
    // time until stale data is garbage collected
    cacheTime: 60 * 1000,
    // time until data becomes stale
    staleTime: 30 * 1000,
    enabled: false,
    select: (data) => transformAccordionItems(data || [])
  });
  return (
    <SlzAccordionTable
      variant="order"
      isShow={row?.isExpand}
      rowId={row?.id}
      data={itemDetails}
      isLoading={isLoading}
      refetch={refetch}
      isChild={true}
      configProps={{ ...accordionProps, markerColor: row?.status?.color }}
    />
  );
};

export default BulkOrderAccordionTable;
