import { HStack } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';

import { PRODUCTS_ADD_TYPES } from 'modules/products/constants';

const Action = ({
  isUploaded,
  currentType,
  onClick,
  csvFiles,
  isSubmitting,
  isActionDisabled = false
}) => {
  return (
    <HStack w="full" justifyContent="flex-end">
      {currentType === PRODUCTS_ADD_TYPES.MANUAL && (
        <SlzButton
          mt={7.5}
          type="submit"
          colorScheme="main"
          size="lg"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || isActionDisabled}>
          Create product
        </SlzButton>
      )}

      {currentType === PRODUCTS_ADD_TYPES.UPLOAD && !isUploaded && csvFiles.length > 0 && (
        <SlzButton isDisabled={isActionDisabled} mt={2} colorScheme="main" onClick={onClick}>
          Upload
        </SlzButton>
      )}

      {currentType === PRODUCTS_ADD_TYPES.UPLOAD && isUploaded && (
        <SlzButton mt={'2.688rem'} type="submit" colorScheme="main">
          Finish
        </SlzButton>
      )}
    </HStack>
  );
};

export default Action;
