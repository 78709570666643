import { Flex, Td, Text, VStack } from '@chakra-ui/react';
import { memo } from 'react';
import { skeletonAnimation as animation } from '../../Skeleton/config';
import { Skeleton } from '../../Skeleton/Skeleton';
import { COL_NUMBER_TYPE } from '../Constants';
import { getFontSizeSubCell, getHeightOfAccordionCell } from '../Styles';
import { useTableContext } from '../TableContext';
import { isCustomCell } from '../utils';

const SlzAccordionCell = ({ col, row, displayFields }) => {
  const { columnWidth, paddingValue, isLoading } = useTableContext();
  const getStyles = () => ({
    textAlign: 'left',
    ...getFontSizeSubCell(),
    ...getHeightOfAccordionCell('order'),
    ...(isLoading ? { p: 0, pr: 1 } : {})
  });
  const { field, header, cellHeader, cellValue } = col;

  const renderCell = () => {
    if (col?.render) {
      return col?.render({ row, field, columnWidth, paddingValue });
    }

    return (
      <Flex justifyContent={col?.align ?? 'start'}>
        <VStack alignItems={COL_NUMBER_TYPE === col?.type ? 'center' : 'start'}>
          <Text w={columnWidth} className="header" noOfLines={2}>
            {cellHeader ? cellHeader(row) : header}
          </Text>
          <Text w={columnWidth} className="field-value" noOfLines={2} sx={{ mt: '0 !important' }}>
            {cellValue ? cellValue(row) : row[field]}
          </Text>
        </VStack>
      </Flex>
    );
  };

  return (
    displayFields?.includes(col?.field) && (
      <Td
        pos="relative"
        className={`sel-td-${col?.field}`}
        w={col?.width ? `${col?.width}px` : columnWidth}
        maxW={col?.width ? `${col?.width}px` : columnWidth}
        key={`${row[field]}-[sel]-${field}`}
        sx={getStyles()}
        px={isCustomCell(col) ? '3px' : paddingValue}>
        {isLoading ? (
          <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
        ) : (
          renderCell()
        )}
      </Td>
    )
  );
};

export default memo(SlzAccordionCell);
