import { useQuery } from 'react-query';

import { getOrderTotals } from 'modules/dashboard/services/dashboard-api';
import { transformOrders } from 'modules/dashboard/mappers/dashboard-mapper';

export const useOrderTotalsQuery = () => {
  return useQuery({
    queryKey: [`order-totals`],
    queryFn: getOrderTotals,
    select: transformOrders
  });
};
