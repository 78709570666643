import { useQuery } from 'react-query';
import { getProducts } from '../services/products-api';

export const useProductsQuery = (filters, select, enabled) => {
  return useQuery({
    queryKey: [`products`, filters],
    queryFn: () => getProducts(filters),
    cacheTime: 60 * 1000,
    staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
