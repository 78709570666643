import { Box, Flex, Alert, useStyleConfig, HStack } from '@chakra-ui/react';
import SlzPaper from '../SlzPaper/SlzPaper';
import { BellIcon } from '../Icons';
import SlzIndicator from '../SlzIndicator/SlzIndicator';
import SlzLink from '../SlzLink/SlzLink';
const SlzNotificationWindows = (props) => {
  const { items, alertContent } = props;
  const style = useStyleConfig('SlzNotificationWindows', {});

  return (
    <SlzPaper
      size="lg"
      w="19.125rem"
      h="21.063rem"
      borderRadius={2.5}
      data-testid="slz-notification-window">
      <Box pos="relative" sx={style.titleStyle} data-testid="slz-notification-window-title">
        <Flex align="center">
          <BellIcon sx={style.leftIcon} data-testid="slz-notification-window-icon" />
          Notifications
        </Flex>
      </Box>
      <Box h="17.5rem" overflowY="auto">
        {items.map((item) => (
          <Box
            key={item.value}
            sx={style?.itemNotification}
            data-testid="slz-notifications-window-item">
            <HStack>
              <SlzIndicator size="lg" />
              <Box>
                <SlzLink href="#" isExternal>
                  {item.text}
                </SlzLink>
              </Box>
              {item.isImportant ? (
                <Alert size="md" variant="subtle" colorScheme={'negative'}>
                  {alertContent}
                </Alert>
              ) : (
                ''
              )}
            </HStack>
          </Box>
        ))}
      </Box>
    </SlzPaper>
  );
};
export default SlzNotificationWindows;
