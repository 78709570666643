import { Fade } from '@chakra-ui/react';
import { Fragment } from 'react';

import useDelayRender from 'hooks/useDelayRender';
import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';

const CHILDREN_WRAPPER = {
  true: Fade,
  false: Fragment
};

const BaseSkeleton = ({
  template = undefined,
  children = Fragment,
  isLoaded = false,
  isFaded = true,
  baseTemplates,
  ...rest
}) => {
  const canShow = useDelayRender({ isLoaded, template });
  const Skeleton = baseTemplates[template] || ShimmerSkeleton.Block;
  const Wrapper = CHILDREN_WRAPPER[isFaded];

  return !canShow ? <Skeleton {...rest} /> : <Wrapper in={canShow}>{children}</Wrapper>;
};

export default BaseSkeleton;
