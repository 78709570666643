import { useMutation } from 'react-query';
import { updateProduct } from '../services/products-api';

export default function useUpdateProductMutation() {
  return useMutation({
    mutationFn: async (payload) => {
      const response = await updateProduct(payload);

      return response;
    }
  });
}
