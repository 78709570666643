import { AddIcon, CheckIcon } from '@chakra-ui/icons';
import { Box, HStack, Text } from '@chakra-ui/react';

import { SlzSingleButton } from 'components/common/SlzButton';
import { SlzDropdown } from 'components/common/SlzDropdown';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import B2BProductInformationCell from 'components/common/SlzTable/templates/B2BProductInformationCell';
import ImageCell from 'components/common/SlzTable/templates/ImageCell';
import { POPOVER_CONFIRMATION_QUANTITY, quantityOptions } from 'modules/b2b-orders/constants';

export const PRODUCT_SELECTION_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 48,
    type: COL_CUSTOM,
    render: ImageCell
  },
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    width: 230,
    header: { align: 'left', paddingLeft: '1.125rem' },
    render: B2BProductInformationCell
  },
  {
    name: 'Stock',
    field: 'totalStock',
    width: 72,
    isSorted: true,
    type: 'number'
  }
];

export const B2B_SELECTED_PRODUCT_COLUMNS = [
  {
    name: 'Your B2B Order',
    field: 'name',
    isSorted: true,
    width: 250,
    header: { align: 'left', paddingLeft: '1.125rem' },
    render: ({ row, variant, rowProps }) => (
      <Box
        id={`product-selection-${row?.id}`}
        sx={{
          '.wrapName': { alignItems: 'start', justifyContent: 'start' },
          '.innerName': { alignItems: 'start' }
        }}>
        <HStack className="wrapName" pos="relative" w="full">
          <ImageCell row={row} rowProps={rowProps} variant={variant} px={0} />
          <B2BProductInformationCell row={row} />
        </HStack>
      </Box>
    )
  }
];

export const getB2BProductSelectionColumns = ({ onSelectedProduct }) => {
  const columns = [...PRODUCT_SELECTION_COLUMNS];

  columns[3] = {
    name: '',
    field: 'action',
    width: 48,
    render: ({ row }) => (
      <>
        {row?.isSelected ? (
          <SlzSingleButton
            icon={<CheckIcon />}
            color="light.300 !important"
            bgColor="main.500 !important"
          />
        ) : (
          <SlzSingleButton
            icon={<AddIcon />}
            color="dark.500"
            bgColor="dark.300"
            sx={{
              ':hover': {
                bgColor: 'main.300'
              }
            }}
            onClick={() => onSelectedProduct(row)}
          />
        )}
      </>
    )
  };

  return columns;
};

export const getB2BProductSelectedColumns = ({ onCloseConfirmQuantity, onSelectedQuantity }) => {
  const columns = [...B2B_SELECTED_PRODUCT_COLUMNS];

  columns[1] = {
    name: 'Quantity',
    field: 'quantity',
    width: 66,
    render: ({ row }) => (
      <Box pos="relative" w="4.5rem">
        <SlzPopoverConfirm
          variant="quantity"
          placement="right"
          title={POPOVER_CONFIRMATION_QUANTITY.title}
          description={POPOVER_CONFIRMATION_QUANTITY.description}
          cancelText=""
          isOpen={row?.showConfirmQuantity || false}
          matchWidth
          onClose={onCloseConfirmQuantity}
          onOk={onCloseConfirmQuantity}>
          <Box>
            <SlzDropdown.Creatable
              isInvalid={row.quantity > row.totalStock}
              value={{ value: row.quantity, label: row.quantity }}
              options={quantityOptions}
              onChange={(value) => onSelectedQuantity(row, value)}
              menuPosition="fixed"
              formatCreateLabel={(value) => <Text fontSize="0.875rem">{value}</Text>}
              maxMenuHeight={443}
            />
          </Box>
        </SlzPopoverConfirm>
      </Box>
    )
  };

  return columns;
};
