import { Text } from '@chakra-ui/react';

import InventorySkeleton from 'components/common/Skeleton/templates/inventory';

import {
  CONSIGNMENT_ARRIVING_TYPE_CONTENT,
  CONSIGNMENT_DETAIL_CONTENT,
  LABELLING_CONTENT,
  SEND_INVENTORY_MODAL_CONTENT,
  SUMMARY_CONTENT
} from 'modules/consignments/constants';
import SelectConsignmentType from './SelectConsignmentType';
import Warehouse from './Warehouse';
import SelectConsignmentArrivingType from './SelectConsignmentArrivingType';
import { Fragment } from 'react';

const ConsignmentTypeContent = ({ warehousesData, currentType }) => {
  return (
    <InventorySkeleton isLoaded={!!warehousesData} template={`inventory.${currentType}`}>
      <Text
        sx={{
          textAlign: 'left',
          fontSize: '1rem',
          color: 'table.mainText',
          mb: 4.5
        }}>
        {SEND_INVENTORY_MODAL_CONTENT[currentType]}
      </Text>

      <Warehouse warehousesData={warehousesData} currentType={currentType} />
      <SelectConsignmentType />
    </InventorySkeleton>
  );
};

const ConsignmentArrivingTypeContent = ({
  warehousesData,
  currentType,
  selectedConsignmentType,
  selectedConsignmentArrivingType
}) => {
  const Dropdown = CONSIGNMENT_ARRIVING_TYPE_CONTENT.dropdown[selectedConsignmentType] || Fragment;
  const BoxDimension =
    CONSIGNMENT_ARRIVING_TYPE_CONTENT.boxDimension[selectedConsignmentArrivingType] || Fragment;

  return (
    <>
      <Warehouse warehousesData={warehousesData} currentType={currentType} />
      <InventorySkeleton
        isLoaded={!!warehousesData}
        template={`inventory.${currentType}`}
        subCard={CONSIGNMENT_ARRIVING_TYPE_CONTENT.isSubCardRequired[selectedConsignmentType]}>
        <Dropdown />
        <SelectConsignmentArrivingType />
        <BoxDimension />
      </InventorySkeleton>
    </>
  );
};

const ConsignmentDetailsContent = ({
  selectedConsignmentType,
  warehousesData,
  currentType,
  setCsvFiles,
  csvFiles
}) => {
  const Component = CONSIGNMENT_DETAIL_CONTENT[selectedConsignmentType] || Fragment;

  return (
    <Component
      warehousesData={warehousesData}
      selectedConsignmentType={selectedConsignmentType}
      currentType={currentType}
      setCsvFiles={setCsvFiles}
      csvFiles={csvFiles}
    />
  );
};

const LabellingContent = ({ selectedConsignmentType, warehousesData, currentType }) => {
  const Component = LABELLING_CONTENT[selectedConsignmentType] || Fragment;

  return (
    <Component
      warehousesData={warehousesData}
      currentType={currentType}
      selectedConsignmentType={selectedConsignmentType}
    />
  );
};

const SummaryContent = ({
  selectedConsignmentType,
  warehousesData,
  currentType,
  handleEditStep,
  setCsvFiles,
  csvFiles
}) => {
  const Component = SUMMARY_CONTENT[selectedConsignmentType] || Fragment;

  return (
    <InventorySkeleton
      isLoaded={!!warehousesData}
      type={selectedConsignmentType}
      template={`inventory.${currentType}`}>
      <Component
        warehousesData={warehousesData}
        currentType={currentType}
        handleEditStep={handleEditStep}
        csvFiles={csvFiles}
        setCsvFiles={setCsvFiles}
      />
    </InventorySkeleton>
  );
};

export const SEND_CONSIGNMENT_STEPS = {
  1: ConsignmentTypeContent,
  2: ConsignmentArrivingTypeContent,
  3: ConsignmentDetailsContent,
  4: LabellingContent,
  5: SummaryContent
};
