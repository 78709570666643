import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, HStack } from '@chakra-ui/react';
import has from 'lodash/has';

import { useLazyLoading } from 'hooks/useLazyLoading';
import { SlzButton } from 'components/common/SlzButton';
import { SlzSearchInput } from 'components/common/SlzInput';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import { filtersProductSelection, PRODUCT_SELECTION_COLUMNS } from 'modules/products/_mock';
import BundleTracking from 'modules/products/components/other/BundleTracking';
import ProductSelectionTable from 'modules/products/components/table/ProductSelectionTable';
import { transformProducts } from 'modules/products/mappers/product-list-mapper';
import { transformEditProducts } from 'modules/products/mappers/bundle-list-mapper';
import { calculateQuantitySelectedProducts } from 'modules/products/utils';
import { getProducts } from 'modules/products/services/products-api';

const defaultBundleForRenderSkeleton = {
  id: null,
  image: null,
  name: null,
  skuCode: null,
  bundleTag: null,
  bundleStockTotal: null
};

const ProductSelectionForm = ({
  defaultValues = {},
  isEditMode = false,
  onSubmit,
  onCancel,
  onPrevious
}) => {
  const [selectedProducts, setSelectedProducts] = useState(
    defaultValues?.productBundleCompanyItems || []
  );
  const [enableQuery, setEnableQuery] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState('');

  const productsQuery = useLazyLoading({
    filters: { pageSize, pageIndex, pagingRequired: true, searchText },
    fetchFn: getProducts,
    transformFn: (data) => {
      return transformProducts(data).products;
    },
    enabled: enableQuery
  });

  const handleSelectProduct = (_, row) => {
    const foundIndex = selectedProducts.findIndex(({ companyItemID, skuCode }) => {
      return companyItemID === row?.companyItemID && skuCode === row?.sku;
    });

    if (foundIndex === -1) {
      setSelectedProducts((products) => {
        const newProducts = [...products];
        row.ean = row.selazarUniqueID || row.ean;
        row.skuCode = row.sku;
        row.quantity = 1;
        newProducts.push(row);
        return newProducts;
      });
    } else {
      setSelectedProducts((products) => {
        const newProducts = [...products];
        newProducts.splice(foundIndex, 1);
        return newProducts;
      });
    }
  };

  const handleSelectedQuantity = useCallback(
    (row, quantity) => {
      setSelectedProducts((products) => {
        let newProducts = [...products];
        const foundIndex = newProducts.findIndex((product) =>
          has(product, 'id')
            ? product?.id === row?.id || product?.companyItemID === row?.companyItemID
            : product?.companyItemID === row?.companyItemID
        );
        if (foundIndex === -1) return products;
        if (quantity === 'remove') {
          newProducts.splice(foundIndex, 1);
        } else {
          newProducts[foundIndex] = {
            ...newProducts[foundIndex],
            quantity
          };
        }

        return newProducts;
      });
    },
    [selectedProducts]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSearchReset = () => {
    setSearchText('');
  };

  const handleCurrentPageChange = (currentPage) => {
    setPageIndex(currentPage);
  };

  const handleItemPerPageChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const handleSubmit = () => {
    const submitValues = { ...defaultValues };
    submitValues.productBundleCompanyItems = transformEditProducts(
      selectedProducts,
      submitValues?.id
    );
    submitValues.totalProducts = calculateQuantitySelectedProducts(selectedProducts);

    onSubmit && onSubmit(submitValues);
  };

  useEffect(() => {
    setEnableQuery(true);
  }, [pageSize, pageIndex, searchText]);

  return (
    <>
      <BundleTracking bundleItems={selectedProducts} isEdit={false} />

      <Box mb={1}>
        <SlzSearchInput
          clearable
          size="sm"
          width="100%"
          placeholder="Search"
          variant="accent"
          height="30px"
          sx={{ borderWidth: '1px' }}
          iconStyle={{ height: '24px', width: '24px', minWidth: '24px' }}
          onSearch={handleSearch}
          onReset={handleSearchReset}
        />
      </Box>
      <ProductSelectionTable
        isLoading={productsQuery?.isLoading}
        isFetching={productsQuery?.isFetching}
        filters={filtersProductSelection}
        columns={PRODUCT_SELECTION_COLUMNS}
        products={
          productsQuery.data?.pages.flat(1) && !productsQuery.isLoading
            ? productsQuery.data?.pages.flat(1)
            : Array(5).fill(defaultBundleForRenderSkeleton)
        }
        isEditMode={isEditMode}
        selectedProducts={selectedProducts}
        onSelectProduct={handleSelectProduct}
        onSelectQuantity={handleSelectedQuantity}
        pagination={{
          isLocal: false,
          pages: {
            onChange: handleItemPerPageChange,
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: productsQuery?.data?.pageSize || ''
          },
          direction: {
            usingDots: false,
            isGreyBg: true,
            totalPage: productsQuery?.data?.totalPage || '',
            currentPage: pageIndex,
            onChange: handleCurrentPageChange
          },
          showTitle: false
        }}
      />
      <Flex mb={5} mt={10} justifyContent="space-between">
        {!isEditMode ? (
          <SlzButton size="md" onClick={onPrevious}>
            Previous
          </SlzButton>
        ) : (
          <SlzButton variant="outline" size="md" colorScheme="negative" onClick={onCancel}>
            Cancel
          </SlzButton>
        )}

        <HStack spacing={5}>
          {!isEditMode && (
            <SlzButton variant="outline" size="md" colorScheme="negative" onClick={onCancel}>
              Cancel
            </SlzButton>
          )}
          <SlzButton type="submit" size="md" onClick={handleSubmit}>
            Next
          </SlzButton>
        </HStack>
      </Flex>
    </>
  );
};

export default ProductSelectionForm;
