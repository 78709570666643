import {
  useCustomerPreference,
  usePartialOrderPreferenceMutation,
  useTaxesDutiesPreferenceMutation,
  useCourierSettingsMutation,
  useReturnSettingsMutation,
  usePackagingSettingMutation,
  usePaymentSettingMutation
} from './index';

const useUserSettingMutations = (settingType) => {
  const customerPreferenceMutation = useCustomerPreference();
  const partialOrderPreferenceMutation = usePartialOrderPreferenceMutation();
  const taxesDutiesPreferenceMutation = useTaxesDutiesPreferenceMutation();
  const courierSettingsMutation = useCourierSettingsMutation();
  const returnSettingsMutation = useReturnSettingsMutation();
  const packagingSettingMutation = usePackagingSettingMutation();
  const paymentSettingMutation = usePaymentSettingMutation();

  const USER_PREFERENCES_SETTING_SUBMIT_ACTION_MAP = {
    customerSupportAccess: customerPreferenceMutation,
    orderSettings: partialOrderPreferenceMutation,
    taxesDuties: taxesDutiesPreferenceMutation,
    courierSettings: courierSettingsMutation,
    returnSettings: returnSettingsMutation,
    packagingSettings: packagingSettingMutation,
    paymentMethods: paymentSettingMutation
  };

  return USER_PREFERENCES_SETTING_SUBMIT_ACTION_MAP[settingType];
};

export default useUserSettingMutations;
