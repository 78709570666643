import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';

export const SCROLL_BAR_STYLE = {
  '&::-webkit-scrollbar': {
    borderRadius: '9px',
    width: '5px',
    background: '#D6D7D7'
  },
  '&:hover::-webkit-scrollbar': {
    background: '#959698'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#B5B7B7',
    opacity: 0.16,
    borderRadius: '9px'
  },
  '&:active::-webkit-scrollbar-thumb': {
    background: '#494B4D'
  },
  '&:hover::-webkit-scrollbar-thumb': {
    background: '#494B4D'
  }
};

export const RETURNABLE_LABEL_STYLE = {
  color: 'dark.500',
  fontSize: 'lg',
  lineHeight: 5.5
};

export const GRADING_DROPDOWN_LABEL_STYLE = {
  color: 'dark.700',
  fontSize: 'lg',
  fontWeight: 'medium',
  lineHeight: 5.5,
  pb: 1.5
};

export const GRADING_DROPDOWN_STYLE = {
  ...SELECT_STYLES,
  singleValue: (provided) => ({
    ...provided,
    fontSize: 'lg',
    color: 'lightAndShadow.mainText'
  }),
  container: (provided) => ({
    ...provided,
    '> div': {
      borderRadius: 1.5
    }
  }),
  control: (provided, state) => ({
    ...provided,
    '> div:first-child': {
      px: 5,
      py: 0
    }
  }),
  menu: (...args) => ({
    ...SELECT_STYLES.menu(...args),
    '> div > div': {
      h: '2.188rem',
      fontSize: 'md'
    }
  })
};
