import CommonEndpoints from 'components/common/Endpoints';
import { buildQuery } from 'utils/helper';
import axiosClient from 'config/axios';

export async function getAddressBook(companyId, filters) {
  let url = new URL(CommonEndpoints.ADDRESS_BOOK.GET.ADDRESS_BY_COMPANY + companyId);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function saveRecipient(payload) {
  const isCreate = !payload.id;
  let url = isCreate
    ? CommonEndpoints.ADDRESS_BOOK.POST.CUSTOMER_ADDRESS
    : CommonEndpoints.ADDRESS_BOOK.PUT.CUSTOMER_ADDRESS + payload.id;

  const response = isCreate
    ? await axiosClient.post(url, payload)
    : await axiosClient.put(url, payload);

  return response;
}

export async function deleteRecipient(customerAddressId) {
  let url = CommonEndpoints.ADDRESS_BOOK.DELETE.CUSTOMER_ADDRESS + customerAddressId;
  return await axiosClient.remove(url);
}
