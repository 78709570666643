import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function MasonryIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 84"
      fill="currentColor"
      strokeWidth="0"
      {...props}>
      <g id="Group_33454" data-name="Group 33454" transform="translate(-1393.773 -701.887)">
        <path
          id="Path_4044"
          data-name="Path 4044"
          d="M1398.439,701.887h28a4.667,4.667,0,0,1,4.667,4.667v37.333a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V706.554A4.667,4.667,0,0,1,1398.439,701.887Zm23.333,9.333h-18.667v28h18.667Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          id="Path_4045"
          data-name="Path 4045"
          d="M1398.439,713.887h28a4.667,4.667,0,0,1,4.667,4.667V737.22a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V718.554A4.667,4.667,0,0,1,1398.439,713.887Zm23.333,9.333h-18.667v9.333h18.667Z"
          transform="translate(0 44)"
          fill="currentColor"
        />
        <path
          id="Path_4046"
          data-name="Path 4046"
          d="M1408.439,709.887h28a4.667,4.667,0,0,1,4.667,4.667v37.333a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V714.553A4.667,4.667,0,0,1,1408.439,709.887Zm23.333,9.333h-18.667v28h18.667Z"
          transform="translate(36.667 29.334)"
          fill="currentColor"
        />
        <path
          id="Path_4047"
          data-name="Path 4047"
          d="M1408.439,701.887h28a4.667,4.667,0,0,1,4.667,4.667V725.22a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V706.554A4.667,4.667,0,0,1,1408.439,701.887Zm23.333,9.333h-18.667v9.333h18.667Z"
          transform="translate(36.667 0)"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
