import React, { useState } from 'react';

import { useFieldArray } from 'react-hook-form';

import { ShippingRateField } from 'modules/integrations/components/form';

const ShippingRateFields = ({ control, ...rest }) => {
  const [isFocusField, setIsFocusedField] = useState(0);

  const { fields } = useFieldArray({
    control,
    name: 'shippingRates'
  });

  return (
    <>
      {fields.map((shippingRate, index) => (
        <ShippingRateField
          key={shippingRate?.shippingRateID}
          shippingRate={shippingRate}
          index={index}
          focus={isFocusField === index}
          setFocus={setIsFocusedField}
          {...rest}
        />
      ))}
    </>
  );
};

export default ShippingRateFields;
