import { Box, createIcon } from '@chakra-ui/react';

export const Dot = ({ styles }) => {
  return (
    <Box
      data-testid='slz-dot'
      transition={'all 0.3s ease-out'}
      transformstyle="preserve-3d"
      transform="transform(scale(1.1))"
      as="div"
      __css={styles}></Box>
  );
};

export const GiHamburgerMenu = createIcon({
  displayName: 'GiHamburgerMenu',
  viewBox: '0 0 512 512',
  path: (
    <path
      fill="currentColor"
      d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"></path>
  )
});
