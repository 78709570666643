import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function BillingIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="23.529"
      height="29.681"
      viewBox="0 0 23.529 29.681"
      fill="currentColor"
      {...props}>
      <g id="Billing_component_icon" data-name="Billing component icon" transform="translate(1 1)">
        <path
          id="Path_8217"
          data-name="Path 8217"
          d="M1068.332,510.664v6.151a1.537,1.537,0,0,0,1.538,1.538h6.151"
          transform="translate(-1054.492 -510.664)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_8218"
          data-name="Path 8218"
          d="M1077.786,538.344h-15.378a3.076,3.076,0,0,1-3.075-3.076V513.74a3.077,3.077,0,0,1,3.075-3.076h10.765l7.689,7.689v16.916A3.075,3.075,0,0,1,1077.786,538.344Z"
          transform="translate(-1059.332 -510.664)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_1424"
          data-name="Line 1424"
          x2="1.538"
          transform="translate(6.151 9.228)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_1425"
          data-name="Line 1425"
          x2="9.227"
          transform="translate(6.151 15.379)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_1426"
          data-name="Line 1426"
          x2="9.227"
          transform="translate(6.151 21.53)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
