import { Icon } from '@chakra-ui/react';
import React from 'react';

const WooCommerceIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.787"
      height="17.731"
      viewBox="0 0 29.787 17.731"
      {...props}>
      <g id="Group_2368" data-name="Group 2368" transform="translate(-39)">
        <g
          id="Group_2341"
          data-name="Group 2341"
          transform="translate(39)"
          clip-path="url(#clip-path)">
          <path
            id="Path_8345"
            data-name="Path 8345"
            d="M202.737,42.854c-.436-.085-.86.157-1.259.751a3.917,3.917,0,0,0-.642,1.441,4.213,4.213,0,0,0-.085.884,3.07,3.07,0,0,0,.218,1.1c.182.472.424.727.714.787a1.164,1.164,0,0,0,.981-.387,3.2,3.2,0,0,0,.92-1.792h0a4.25,4.25,0,0,0,.085-.9,3.073,3.073,0,0,0-.218-1.1c-.182-.472-.424-.727-.714-.787"
            transform="translate(-177.392 -37.853)"
            fill="#fff"
          />
          <path
            id="Path_8346"
            data-name="Path 8346"
            d="M138.2,43.641c-.182-.472-.424-.727-.714-.787-.436-.085-.86.157-1.259.751a3.915,3.915,0,0,0-.642,1.441,4.213,4.213,0,0,0-.085.884,3.075,3.075,0,0,0,.218,1.1c.182.472.424.727.714.787a1.164,1.164,0,0,0,.981-.387,3.2,3.2,0,0,0,.92-1.792h0a3.572,3.572,0,0,0,.085-.9,3.07,3.07,0,0,0-.218-1.1"
            transform="translate(-119.735 -37.853)"
            fill="#fff"
          />
          <path
            id="Path_8347"
            data-name="Path 8347"
            d="M27.011,0H2.765A2.765,2.765,0,0,0,0,2.777v9.255a2.775,2.775,0,0,0,2.776,2.777H14.261l5.249,2.923-1.193-2.923h8.695a2.775,2.775,0,0,0,2.777-2.777V2.777A2.775,2.775,0,0,0,27.011,0M12.34,12.145a1.437,1.437,0,0,1-.145.8.781.781,0,0,1-.642.436,1.217,1.217,0,0,1-.957-.448A11.988,11.988,0,0,1,7.92,7.774Q6.74,10.1,6.177,11.261c-.714,1.368-1.32,2.071-1.828,2.107-.327.024-.605-.254-.848-.835a52.46,52.46,0,0,1-2-9.191A1.031,1.031,0,0,1,1.7,2.531a.974.974,0,0,1,.763-.375.884.884,0,0,1,1.053.872q.563,3.8,1.223,6.43L7.387,4.408A1.15,1.15,0,0,1,8.3,3.681c.533-.036.86.3.993,1.017a22.628,22.628,0,0,0,1.15,4.141,18.624,18.624,0,0,1,1.6-6.66.93.93,0,0,1,.8-.533,1.052,1.052,0,0,1,.763.242.948.948,0,0,1,.375.69,1.051,1.051,0,0,1-.121.581,14.656,14.656,0,0,0-1.175,4.371,22.56,22.56,0,0,0-.339,4.614m7.435-1.9a3.238,3.238,0,0,1-2.845,1.816,3.173,3.173,0,0,1-.654-.073,2.788,2.788,0,0,1-1.937-1.4,4.689,4.689,0,0,1-.581-2.4A6.416,6.416,0,0,1,14.7,4.771a3.265,3.265,0,0,1,2.845-1.816,3.18,3.18,0,0,1,.654.073,2.814,2.814,0,0,1,1.937,1.4,4.581,4.581,0,0,1,.581,2.385,6.488,6.488,0,0,1-.944,3.427m7.58,0a3.238,3.238,0,0,1-2.845,1.816,3.172,3.172,0,0,1-.654-.073,2.788,2.788,0,0,1-1.937-1.4,4.689,4.689,0,0,1-.581-2.4,6.415,6.415,0,0,1,.944-3.415,3.265,3.265,0,0,1,2.845-1.816,3.181,3.181,0,0,1,.654.073,2.789,2.789,0,0,1,1.937,1.4A4.468,4.468,0,0,1,28.3,6.817a6.489,6.489,0,0,1-.944,3.427"
            transform="translate(0)"
            fill="#fff"
          />
        </g>
      </g>
    </Icon>
  );
};

export default WooCommerceIcon;
