import { useMutation } from 'react-query';
import { USER_SETTING_FORMS_DEFAULT_NAME } from '../constants';
import { updateCurrentPreferences } from '../services/preferences-api';

const useCourierSettingsMutation = () =>
  useMutation({
    mutationFn: (payload) =>
      updateCurrentPreferences(payload[USER_SETTING_FORMS_DEFAULT_NAME.courier])
  });

export default useCourierSettingsMutation;
