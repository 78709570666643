import {
  Popover,
  PopoverTrigger,
  Box,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  PopoverArrow,
  useStyleConfig
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import './index.scss';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzToolTip = (props) => {
  const {
    trigger = 'click',
    content,
    placement,
    variant,
    children,
    arrowSize = 40,
    ...rest
  } = props;
  const styles = useStyleConfig('SlzToolTip', { variant });
  const [isOpenArrow, setIsOpenArrow] = useState(false);
  const [arrowStyle, setArrowStyle] = useState({});
  const onOpen = (e) => {
    setIsOpenArrow(true);
  };

  useEffect(() => {
    if (placement) {
      if (placement.includes('top') || placement.includes('bottom')) {
        setArrowStyle({ transform: 'scaleX(.5) rotate(45deg) !important' });
      } else {
        setArrowStyle({ transform: 'scaleY(.5) rotate(45deg) !important' });
      }
      setIsOpenArrow(true);
    }
  }, [placement]);

  return (
    <Box
      as="div"
      sx={{ '> .chakra-popover__popper': { filter: 'drop-shadow(0px 2px 16px #00000065)' } }}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-ToolTip`)}
      data-testid={`slz-tooltip`}>
      <Popover
        id="popper"
        direction="rtl"
        flip={false}
        isLazy
        trigger={trigger}
        placement={placement}
        arrowSize={arrowSize}
        onOpen={(e) => onOpen(e)}
        {...rest}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: ({ placement, reference, popper }) => {
                if (placement.includes('bottom') || placement.includes('top')) {
                  return [
                    placement.includes('start') ? -80 : placement.includes('end') ? 80 : 0,
                    placement.includes('start')
                      ? reference.height / 2 + 20
                      : placement.includes('end')
                      ? -(reference.height / 2 - 44)
                      : reference.height / 2 + 20
                  ];
                }

                if (placement.includes('left') || placement.includes('right')) {
                  return [
                    placement.includes('start')
                      ? -reference.width / 2 - 40
                      : placement.includes('end')
                      ? reference.width / 2 + 40
                      : 0,
                    40
                  ];
                } else return [0, 40];
              }
            }
          }
        ]}>
        <PopoverTrigger>{children}</PopoverTrigger>
        {isOpenArrow && (
          <PopoverContent
            className="slz-tooltip-content"
            sx={{ mb: '0', ...styles }}
            data-testid="slz-tooltip-content">
            <SlzPopoverArrow
              isOpen={isOpenArrow}
              bgColor={styles?.arrow.bg}
              boxShadow={styles?.arrow.boxShadow}
              placement={placement}
              arrowStyle={arrowStyle}
            />
            <PopoverCloseButton
              sx={styles?.closeButton}
              data-testid="slz-tooltip-content-popover-close-button"
            />
            <PopoverBody px={0} py={0}>
              {content}
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    </Box>
  );
};

const SlzPopoverArrow = ({ bgColor, arrowStyle }) => {
  return (
    <PopoverArrow
      bg={bgColor}
      id="popover-arrow"
      boxShadow={'none !important'}
      sx={arrowStyle}
      data-testid="slz-tooltip-content-popover-arrow"></PopoverArrow>
  );
};

export default SlzToolTip;
