import { Flex, Text } from '@chakra-ui/react';

const B2BOrderSummaryRow = ({ label, value, children }) => {
  return (
    <Flex
      w="full"
      pl={3}
      pr={3.5}
      py={1}
      minH="1.875rem"
      bg="light.500"
      borderRadius={1.5}
      justifyContent="space-between">
      <Text fontWeight="bold" lineHeight={5.5}>
        {label}
      </Text>
      {children ? children : <Text lineHeight={5.5}>{value}</Text>}
    </Flex>
  );
};

export default B2BOrderSummaryRow;
