import { MAX_WEIGHT } from 'modules/consignments/constants';
import * as yup from 'yup';

const applyConditionalConsignmentBoxValidation = (message) => {
  return yup
    .mixed()
    .when('consignmentArrivingType', {
      is: 2,
      then: yup
        .object()
        .shape({
          height: yup.number().typeError(message).required(message),
          width: yup.number().typeError(message).required(message),
          depth: yup.number().typeError(message).required(message),
          weight: yup
            .number()
            .typeError('Stockbox weight is required')
            .max(MAX_WEIGHT, 'Must be below 2kg')
            .required('Stockbox weight is required'),
        }),
      otherwise: yup.mixed().strip(),
    });
};

export const selectConsignmentArrivingTypeSchema = yup.object().shape({
  product: yup
    .mixed()
    .when('consignmentType', {
      is: 1,
      then: yup.object().typeError('*Please select a product').required('*Please select a product'),
      otherwise: yup.mixed().strip(),
    }),
  consignmentArrivingType: yup
    .number()
    .min(1, '*Please select a type of consignment')
    .required('*Please select a type of consignment'),
  consignmentBoxDimensions: applyConditionalConsignmentBoxValidation('Required'),
});
