import {
  Box,
  chakra,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { SlzTextarea } from 'components/common/SlzTextarea';

const OrderSummaryB2C = ({ defaultValues, itemCount, onConfirm, onBack }) => {
  return (
    <>
      <Flex
        flexBasis={'100%'}
        justifyContent={'space-between'}
        alignItems="end"
        color={'#303134'}
        mt={'1.25rem'}>
        <chakra.span fontWeight={'bold'} fontSize={'1.625rem'}>
          Order summary
        </chakra.span>
        <ItemCount label={'Item count'} value={itemCount} />
      </Flex>
      <HStack spacing="0.75rem" mt={'1rem'} alignItems={'start'}>
        <Flex flexBasis={'39.7%'} flexDirection="column">
          <OrderDetails orderDetails={defaultValues} />
        </Flex>
        <Flex flexBasis={'60.3%'} flexDirection="column">
          <ProductDetails products={defaultValues} />
          <Notes notes={defaultValues?.notes} />
        </Flex>
      </HStack>
      <HStack mt={'119px'} justifyContent="space-between">
        <SlzButton variant="outline" size="lg" onClick={onBack}>
          Previous
        </SlzButton>
        <SlzButton variant="solid" size="lg" onClick={onConfirm}>
          Confirm
        </SlzButton>
      </HStack>
    </>
  );
};

export default OrderSummaryB2C;

const OrderDetails = ({ orderDetails }) => {
  const {
    orderId,
    name,
    phoneNumber,
    email,
    postCode,
    town,
    country,
    countryCode,
    addressOne,
    addressTwo
  } = orderDetails;

  return (
    <>
      <OrderSummaryHeader text={'Order details'} />
      <OrderDetailsRow text={'Order ID'} value={orderId} />
      <OrderDetailsRow text={'Name'} value={name} />
      <OrderDetailsRow text={'Phone Number'} value={phoneNumber} />
      <OrderDetailsRow text={'Email'} value={email} />
      <OrderDetailsRow
        text={'Address'}
        value={
          <Box sx={{ mr: '4.375rem' }}>
            <Text>{addressOne}</Text>
            <Text>{addressTwo}</Text>
            <Text>{town}</Text>
            <Text>{country}</Text>
            <Text>{postCode}</Text>
            <Text mb={0}>{countryCode?.value}</Text>
          </Box>
        }
        sx={{ alignItems: 'start' }}
      />
    </>
  );
};

const OrderSummaryHeader = ({ text }) => (
  <Box
    sx={{
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#303134'
    }}>
    {text}
  </Box>
);

const OrderDetailsRow = ({ text, value, sx }) => {
  return (
    <HStack
      bg={'#CCE5FF'}
      p="0.5rem 0.75rem"
      mt={'0.75rem'}
      justifyContent={'space-between'}
      lineHeight={1}
      borderRadius={'0.375rem'}
      sx={{ ...sx }}>
      <chakra.span color={'#0973EA'} fontWeight={'medium'}>
        {text}
      </chakra.span>
      <chakra.span maxW={'11.375rem'}>{value}</chakra.span>
    </HStack>
  );
};

const ProductDetails = ({ products }) => {
  return (
    <>
      <OrderSummaryHeader text={'Product details'} />
      <TableContainer mt={'0.75rem'} borderRadius="0.375rem" maxWidth={'100%'}>
        <Table variant={'orderSummary'}>
          <Thead>
            <Tr>
              <Th>Product Id</Th>
              <Th>SKU</Th>
              <Th isNumeric textAlign={'end'}>
                Count
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {products?.items?.map(({ productId, sku, count }, index) => (
              <Tr key={index + productId}>
                <Td>{productId}</Td>
                <Td>{sku}</Td>
                <Td isNumeric textAlign={'end'}>
                  {count}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const Notes = ({ notes }) => {
  return (
    <VStack
      bg={'#CCE5FF'}
      mt={'0.75rem'}
      alignItems="start"
      minH="10.875rem"
      borderRadius={'0.375rem'}
      p="0.5rem 0.75rem">
      <chakra.span color={'#0973EA'} fontWeight={'medium'}>
        Notes
      </chakra.span>
      <SlzTextarea
        sx={{ border: 'none', height: '10.875rem' }}
        readOnly={'true'}
        value={notes}
        className="scroll-hide"
        marginTop={0}
        resize="none"
      />
    </VStack>
  );
};

export const ItemCount = ({ label, value }) => (
  <HStack
    w="155px"
    h="48px"
    border="1px solid"
    borderColor="main.500"
    rounded="6px"
    px="5"
    justifyContent="space-between">
    <chakra.span color="main.500" fontWeight="medium">
      {label}
    </chakra.span>
    <chakra.span color="dark.700" fontWeight="bold" ml="2rem">
      {value}
    </chakra.span>
  </HStack>
);
