import { FormControl, HStack } from '@chakra-ui/react';
import { calculateQuantitySelectedProducts, getCalculatedStock } from '../../utils';
import FormLabel from '../form/FormLabel';
import Tag from '../form/Tag';

const BundleTracking = ({ bundleItems = [], isEdit = false }) => {
  return (
    <HStack spacing="12px" mb={1}>
      <FormControl flexBasis="10%">
        <FormLabel>SKUs</FormLabel>
        <Tag>{bundleItems?.length}</Tag>
      </FormControl>
      <FormControl flexBasis="10%">
        <FormLabel>Items</FormLabel>
        <Tag>{calculateQuantitySelectedProducts(bundleItems)}</Tag>
      </FormControl>
      {isEdit && (
        <FormControl flexBasis="20%">
          <FormLabel>Calculated Stock</FormLabel>
          <Tag>{getCalculatedStock(bundleItems)}</Tag>
        </FormControl>
      )}
    </HStack>
  );
};

export default BundleTracking;
