import React from 'react';
import { Icon, useMultiStyleConfig, useNumberInput, useTheme } from '@chakra-ui/react';
import { VscChromeClose } from 'react-icons/vsc';
import { HiCheck } from 'react-icons/hi';
import Input from './Input';

export default React.forwardRef(function MeasuredInput(
  {
    isInvalid,
    isValid,
    size,
    unit = '',
    alignment = 'left',
    placeholder,
    onClear,
    onClearError,
    ...props
  },
  ref
) {
  const { getInputProps } = useNumberInput(props);
  const theme = useTheme();
  const inputStyles = useMultiStyleConfig('Input', { size });
  const inputHeight = theme.sizes[inputStyles.field.h];
  const hoverBorderColor = inputStyles.field._hover.borderColor;
  const isLeftAlign = alignment === 'left';
  const isRightAlign = alignment === 'right';
  const hoverBorderStyle =
    !isInvalid && !isValid
      ? {
          '&:focus-within > .chakra-input__left-addon, &:hover > .chakra-input__left-addon': {
            borderColor: hoverBorderColor
          }
        }
      : {};
  return (
    <Input.Group size={size} sx={hoverBorderStyle}>
      {isLeftAlign && (
        <Input.LeftAddon aria-invalid={isInvalid} aria-valid={isValid}>
          {unit}
        </Input.LeftAddon>
      )}

      <Input
        isInvalid={isInvalid}
        isValid={isValid}
        textAlign={alignment}
        placeholder={placeholder}
        {...getInputProps({}, ref)}
        value={isInvalid ? '' : getInputProps({}, ref)?.value}
        onFocus={isInvalid ? onClearError : undefined}
      />

      {isInvalid && (
        <Input.RightElement right={isRightAlign ? inputHeight : 0}>
          <Icon as={VscChromeClose} color="negative.500" onClick={onClear} />
        </Input.RightElement>
      )}
      {isValid && (
        <Input.RightElement right={isRightAlign ? inputHeight : 0}>
          <Icon as={HiCheck} color="positive.500" />
        </Input.RightElement>
      )}

      {isRightAlign && (
        <Input.RightAddon aria-invalid={isInvalid} aria-valid={isValid}>
          {unit}
        </Input.RightAddon>
      )}
    </Input.Group>
  );
});
