import { REQUIRED_SETTINGS } from 'components/common/SetupRequirement/constants';

export const buildQuery = (url, filters) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value));
    } else {
      params.append(key, value);
    }
  });

  return `${url}?${params.toString()}`;
};

export const convertStringToBoolean = (strValue) => {
  switch (String(strValue)?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;
    default:
      return JSON.parse(strValue);
  }
};

export const applyVariableToMessage = (variable, message = '') => {
  const regex = /\{\{(.*?)\}\}/g;
  return message.replace(regex, (i, match) => {
    return variable[match];
  });
};

export const getItemCookie = (key) =>
  document.cookie.split('; ').reduce((total, currentCookie) => {
    const item = currentCookie.split('=');
    const [storedKey, storedValue] = item;

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, '');

export const setItemCookie = ({ key, value, numberOfMinutes }) => {
  const now = new Date();
  const MINUTE_TO_MILLISECOND = 60 * 1000;
  // set the time to be now + numberOfMinutes
  now.setTime(now.getTime() + numberOfMinutes * MINUTE_TO_MILLISECOND);

  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const filterRequiredSettings = (requiredSettings) =>
  requiredSettings.filter((setting) => REQUIRED_SETTINGS.includes(setting));
