import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { HStack, VStack } from '@chakra-ui/react';

import SelectedProduct from './singleConsignmentUpload/SelectedProduct';
import Warehouse from './Warehouse';
import {
  disabledCheckboxStyles,
  enabledCheckboxStyles
} from 'modules/consignments/constants/styles';
import {
  ConsigmentCard,
  LabellingContent,
  ShippingContent
} from 'modules/consignments/components/modal/send/others';
import IventorySkeleton from 'components/common/Skeleton/templates/inventory';
import { SEND_CONSIGNMENT_TYPES } from 'modules/consignments/constants';
import InventorySkeleton from 'components/common/Skeleton/templates/inventory';

const ConsignmentLabelling = ({
  warehousesData,
  currentType,
  isGTINEnabled,
  selectedConsignmentType
}) => {
  const {
    setValue,
    register,
    formState: { errors }
  } = useFormContext();

  const [labellingEnabled, setlabellingEnabled] = useState(false);
  const [productLabelsSelected, setProductLabelsSelected] = useState(false);
  const [stockBoxLabelsSelected, setStockBoxLabelsSelected] = useState(false);
  const [consignmentLabelSelected, setConsignmentLabelSelected] = useState(false);

  const labellingEnabledHandler = (e) => {
    setlabellingEnabled(e.target?.checked);
  };

  const labelsEnabledHandler = ({ value, id }) => {
    switch (id) {
      case 0:
        setProductLabelsSelected(value);
        setValue('productLabelsSelected', value);
        break;
      case 1:
        setStockBoxLabelsSelected(value);
        setValue('stockBoxLabelsSelected', value);
        break;
      case 3:
        setConsignmentLabelSelected(value);
        setValue('consignmentLabelSelected', value);
    }
  };

  const productCheckboxStyles = () => {
    if (labellingEnabled) {
      return enabledCheckboxStyles;
    } else {
      return disabledCheckboxStyles;
    }
  };

  const stockBoxCheckboxStyles = () => {
    if (isGTINEnabled) {
      return { display: 'none' };
    }
    if (labellingEnabled) {
      return enabledCheckboxStyles;
    } else {
      return disabledCheckboxStyles;
    }
  };
  const consignmentCheckboxStyles = () => {
    if (selectedConsignmentType !== SEND_CONSIGNMENT_TYPES.SINGLE) {
      return { display: 'none' };
    }
    if (labellingEnabled) {
      return enabledCheckboxStyles;
    } else {
      return disabledCheckboxStyles;
    }
  };

  let checkboxData = [
    { 
      id: 0, 
      name: 'Product label(s)', 
      checked: productLabelsSelected, 
      ...productCheckboxStyles() 
    },
    {
      id: 1,
      name: 'Stock box label(s)',
      checked: stockBoxLabelsSelected,
      ...stockBoxCheckboxStyles()
    },
    {
      id: 2,
      name: 'Consignment label',
      checked: consignmentLabelSelected,
      ...consignmentCheckboxStyles()
    }
  ];

  return (
    <VStack w="100%" mb="3rem" alignItems="left">
      <HStack w="full" mb="-8px">
        {selectedConsignmentType === SEND_CONSIGNMENT_TYPES.SINGLE && <SelectedProduct />}
        <Warehouse warehousesData={warehousesData} currentType={currentType} width="50%" />
      </HStack>
      <InventorySkeleton
        isLoaded={!!warehousesData}
        selectedConsignmentType={selectedConsignmentType}
        mt="1rem !important"
        template={`inventory.${currentType}`}>
        <ConsigmentCard title="Shipping" h="6rem">
          <ShippingContent errors={errors} register={register} />
        </ConsigmentCard>

        <ConsigmentCard title="Labelling" showTooltip pt="14px">
          <LabellingContent
            errors={errors}
            register={register}
            labellingEnabledHandler={labellingEnabledHandler}
            labelsEnabledHandler={labelsEnabledHandler}
            checkboxData={checkboxData}
          />
        </ConsigmentCard>
      </InventorySkeleton>
    </VStack>
  );
};

export default ConsignmentLabelling;
