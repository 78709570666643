import { useQuery } from 'react-query';
import { getBulkOrderAvailableServiceTimes } from '../services/bulk-orders-api';
import { transformServiceTimeOptions } from '../utils';

export const useBulkOrderServiceTimesQuery = (country) => {
  return useQuery({
    queryKey: [`bulk-order-service-time`, country],
    queryFn: () => getBulkOrderAvailableServiceTimes(country),
    select: (data) => transformServiceTimeOptions(data)
  });
};
