import { FormControl, FormErrorMessage, Text } from '@chakra-ui/react';

import SlzInput from 'components/common/SlzInput/SlzInput';

import { CONSIGNMENT_LABELLING_STYLES } from 'modules/consignments/constants/styles';

const ShippingContent = ({ errors, register }) => (
  <FormControl isInvalid={errors?.shippingReference}>
    <Text {...CONSIGNMENT_LABELLING_STYLES.text}>
      <b>Shipping reference</b>
      {' -  optional'}
    </Text>
    <SlzInput
      value={null}
      {...register('shippingReference')}
      fontSize="0.938rem"
      sx={errors?.shippingReference ? { borderColor: `${colors.red[500]} !important` } : {}}
    />
    <FormErrorMessage fontSize="lg">{errors?.shippingReference?.message}</FormErrorMessage>
  </FormControl>
);

export default ShippingContent;
