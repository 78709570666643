import React from 'react';

import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, GridItem, Heading, HStack, Icon, Text } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { SlzGridModal } from 'components/common/SlzModal';
import SlzToolTip from 'components/common/SlzToolTip';
import Upload from '../form/b2c-form/upload/Upload';

const MultipleOrderB2CModal = (props) => {
  const history = useHistory();

  const handleCloseCreateOrderModal = () => {
    history.push({ search: undefined });
  };

  return (
    <SlzGridModal isOpen={props.isOpen} onClose={handleCloseCreateOrderModal}>
      <GridItem gridColumn="2/12" mb="3">
        <HStack alignItems="center">
          <Icon
            as={FaChevronLeft}
            fontSize={28}
            color="dark.500"
            mt={-1}
            cursor="pointer"
            onClick={handleCloseCreateOrderModal}
          />
          <Box>
            <Heading as="h3" fontSize="2rem" lineHeight="2.6875rem" mt="0.875rem" mb="0">
              Create B2C order
            </Heading>
            <Text color="dark.500" lineHeight="1.3125rem" mt={-1} display="flex" gap="5px">
              File Upload
              <SlzToolTip content={'File Upload'} placement="top-start" variant="blue">
                <InfoOutlineIcon boxSize="3" color="main.500" />
              </SlzToolTip>
            </Text>
          </Box>
        </HStack>
        <Upload />
      </GridItem>
    </SlzGridModal>
  );
};

export default MultipleOrderB2CModal;
