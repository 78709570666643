import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Hazmat = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.431rem"
            h="1.276rem"
            viewBox="0 0 22.897 20.414"
            fill="gray"
            {...props}>
            <g id="Group_34553" data-name="Group 34553" transform="translate(-459.495 -1248.719)">
                <path id="Path_10266" data-name="Path 10266" d="M12,12m-2,0a2,2,0,1,0,2-2,2,2,0,0,0-2,2" transform="translate(458.952 1247.91)" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                <path id="Path_10267" data-name="Path 10267" d="M12.884,15.088c0,.19.053.386.062.586v.239a5.226,5.226,0,0,1-4.987,5.22H7.721M2.495,15.907a5.226,5.226,0,0,1,8.512-4.063m7.165,9.288a5.226,5.226,0,0,1-5.16-6.054h-.066m1.941-3.239a5.226,5.226,0,0,1,8.5,3.825V15.9M11.076,11.709A5.226,5.226,0,0,1,9.065,3.322l.18-.189m7.391,0A5.226,5.226,0,0,1,14.8,11.712" transform="translate(458 1247)" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g>
        </Icon>
    </Box>
);
export default Hazmat;