import { Icon } from '@chakra-ui/react';

const PageNotFoundIcon = (props) => {
  return (
    <Icon
      id="Group_160472"
      data-name="Group 160472"
      xmlns="http://www.w3.org/2000/svg"
      width="774.116"
      height="742.562"
      viewBox="0 0 774.116 742.562"
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_147739"
            data-name="Rectangle 147739"
            width="774.116"
            height="742.562"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            id="Rectangle_147703"
            data-name="Rectangle 147703"
            width="454.935"
            height="480.808"
            transform="translate(18.52 130.431)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-3">
          <rect
            id="Rectangle_147704"
            data-name="Rectangle 147704"
            width="681.811"
            height="662.584"
            transform="translate(19.478 63.218)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-4">
          <rect
            id="Rectangle_147705"
            data-name="Rectangle 147705"
            width="84.75"
            height="84.75"
            transform="translate(111.695 115.072)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-5">
          <rect
            id="Rectangle_147706"
            data-name="Rectangle 147706"
            width="70.121"
            height="74.337"
            transform="translate(111.727 125.475)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-6">
          <rect
            id="Rectangle_147707"
            data-name="Rectangle 147707"
            width="7.748"
            height="7.748"
            transform="translate(127.5 132.805)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-7">
          <rect
            id="Rectangle_147708"
            data-name="Rectangle 147708"
            width="7.748"
            height="7.748"
            transform="translate(173.07 128.933)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-8">
          <rect
            id="Rectangle_147709"
            data-name="Rectangle 147709"
            width="12.678"
            height="12.678"
            transform="translate(160.392 154.086)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-9">
          <rect
            id="Rectangle_147710"
            data-name="Rectangle 147710"
            width="16.946"
            height="16.946"
            transform="translate(135.979 170.029)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-10">
          <rect
            id="Rectangle_147711"
            data-name="Rectangle 147711"
            width="74.365"
            height="74.366"
            transform="translate(582.93 622.903)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-11">
          <rect
            id="Rectangle_147712"
            data-name="Rectangle 147712"
            width="61.611"
            height="67.623"
            transform="translate(595.679 629.663)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-12">
          <rect
            id="Rectangle_147713"
            data-name="Rectangle 147713"
            width="141.883"
            height="193.038"
            transform="translate(498.883 273.865)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-13">
          <rect
            id="Rectangle_147714"
            data-name="Rectangle 147714"
            width="417.236"
            height="480.889"
            transform="translate(279.825 141.955)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-14">
          <rect
            id="Rectangle_147715"
            data-name="Rectangle 147715"
            width="50.061"
            height="70.393"
            transform="translate(483.454 98.09)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-15">
          <rect
            id="Rectangle_147716"
            data-name="Rectangle 147716"
            width="66.966"
            height="139.262"
            transform="translate(480.911 161.848)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-16">
          <rect
            id="Rectangle_147717"
            data-name="Rectangle 147717"
            width="18.928"
            height="17.368"
            transform="translate(521.919 264.369)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-17">
          <rect
            id="Rectangle_147718"
            data-name="Rectangle 147718"
            width="4.114"
            height="10.676"
            transform="translate(543.75 274.177)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-18">
          <rect
            id="Rectangle_147719"
            data-name="Rectangle 147719"
            width="66.082"
            height="38.034"
            transform="translate(411.496 106.163)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-19">
          <rect
            id="Rectangle_147720"
            data-name="Rectangle 147720"
            width="17.317"
            height="16.864"
            transform="translate(404.708 101.611)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-20">
          <rect
            id="Rectangle_147721"
            data-name="Rectangle 147721"
            width="64.321"
            height="79.751"
            transform="translate(459.365 94.544)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-21">
          <rect
            id="Rectangle_147722"
            data-name="Rectangle 147722"
            width="13.6"
            height="14.954"
            transform="translate(501.149 113.173)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-22">
          <rect
            id="Rectangle_147723"
            data-name="Rectangle 147723"
            width="38.742"
            height="40.62"
            transform="translate(455.961 66.148)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-23">
          <rect
            id="Rectangle_147724"
            data-name="Rectangle 147724"
            width="114.093"
            height="101.619"
            transform="translate(494.361 156.606)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-24">
          <rect
            id="Rectangle_147725"
            data-name="Rectangle 147725"
            width="7.684"
            height="22.41"
            transform="translate(585.898 223.897)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-25">
          <rect
            id="Rectangle_147726"
            data-name="Rectangle 147726"
            width="7.077"
            height="7.811"
            transform="translate(600.565 226.12)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-26">
          <rect
            id="Rectangle_147727"
            data-name="Rectangle 147727"
            width="45.053"
            height="21.843"
            transform="translate(479.958 154.327)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-27">
          <rect
            id="Rectangle_147728"
            data-name="Rectangle 147728"
            width="64.328"
            height="58.443"
            transform="translate(486.28 92.46)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-28">
          <rect
            id="Rectangle_147729"
            data-name="Rectangle 147729"
            width="16.415"
            height="17.057"
            transform="translate(538.786 142.932)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-29">
          <rect
            id="Rectangle_147730"
            data-name="Rectangle 147730"
            width="26.948"
            height="35.193"
            transform="translate(475.279 115.714)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-30">
          <rect
            id="Rectangle_147731"
            data-name="Rectangle 147731"
            width="9.98"
            height="9.979"
            transform="translate(470.285 126.036)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-31">
          <rect
            id="Rectangle_147732"
            data-name="Rectangle 147732"
            width="3.272"
            height="3.271"
            transform="translate(480.551 123.689)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-32">
          <rect
            id="Rectangle_147733"
            data-name="Rectangle 147733"
            width="3.271"
            height="3.271"
            transform="translate(484.867 122.008)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-33">
          <rect
            id="Rectangle_147734"
            data-name="Rectangle 147734"
            width="18.965"
            height="10.587"
            transform="translate(475.188 135.656)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-34">
          <rect
            id="Rectangle_147737"
            data-name="Rectangle 147737"
            width="174.829"
            height="34.023"
            transform="translate(176.318 13.313)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-35">
          <rect
            id="Rectangle_147735"
            data-name="Rectangle 147735"
            width="126.321"
            height="18.023"
            transform="translate(176.318 13.313)"
            fill="none"
          />
        </clipPath>
        <clipPath id="clip-path-36">
          <rect
            id="Rectangle_147736"
            data-name="Rectangle 147736"
            width="174.829"
            height="18.023"
            transform="translate(176.318 29.313)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_160587" data-name="Group 160587">
        <g id="Group_160586" data-name="Group 160586">
          <g id="Group_160585" data-name="Group 160585" clip-path="url(#clip-path)">
            <path
              id="Path_83182"
              data-name="Path 83182"
              d="M31.233,222.059C1.594,300.992,27.174,407.531,72.965,490.885,190.859,707.3,478.02,584.931,473.072,486.653c6.742-93.777-77.581-121.621-106.189-174.2C206.7,15.755,55.076,155.516,31.233,222.059"
              fill="#cce5ff"
            />
            <g id="Group_160482" data-name="Group 160482" opacity="0.9">
              <g id="Group_160481" data-name="Group 160481">
                <g id="Group_160480" data-name="Group 160480" clip-path="url(#clip-path-2)">
                  <path
                    id="Path_83183"
                    data-name="Path 83183"
                    d="M31.233,222.059C1.594,300.992,27.174,407.531,72.965,490.885,190.859,707.3,478.02,584.931,473.072,486.653c6.742-93.777-77.581-121.621-106.189-174.2C206.7,15.755,55.076,155.516,31.233,222.059"
                    fill="#cce5ff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160485" data-name="Group 160485" opacity="0.3">
              <g id="Group_160484" data-name="Group 160484">
                <g id="Group_160483" data-name="Group 160483" clip-path="url(#clip-path-3)">
                  <path
                    id="Path_83184"
                    data-name="Path 83184"
                    d="M269.569,214.034a3.607,3.607,0,1,1-3.607-3.607,3.607,3.607,0,0,1,3.607,3.607"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83185"
                    data-name="Path 83185"
                    d="M24.6,350.4a2.559,2.559,0,1,1-2.559-2.559A2.559,2.559,0,0,1,24.6,350.4Z"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83186"
                    data-name="Path 83186"
                    d="M447.493,722.139a3.662,3.662,0,1,1-3.662-3.661h0a3.661,3.661,0,0,1,3.661,3.661"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83187"
                    data-name="Path 83187"
                    d="M534.282,574.509a2.559,2.559,0,1,1-2.559-2.559,2.559,2.559,0,0,1,2.559,2.559Z"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83188"
                    data-name="Path 83188"
                    d="M701.289,117.331a2.559,2.559,0,1,1-2.56-2.559,2.56,2.56,0,0,1,2.56,2.559Z"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83189"
                    data-name="Path 83189"
                    d="M215.033,65.777a2.559,2.559,0,1,1-2.56-2.559,2.56,2.56,0,0,1,2.56,2.559Z"
                    fill="#407bff"
                  />
                  <path
                    id="Path_83190"
                    data-name="Path 83190"
                    d="M420.555,197.254A2.559,2.559,0,1,1,418,194.7a2.559,2.559,0,0,1,2.559,2.559Z"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83191"
              data-name="Path 83191"
              d="M196.445,157.447a42.375,42.375,0,1,1-42.375-42.375,42.375,42.375,0,0,1,42.375,42.375Z"
              fill="#407bff"
            />
            <g id="Group_160488" data-name="Group 160488" opacity="0.7">
              <g id="Group_160487" data-name="Group 160487">
                <g id="Group_160486" data-name="Group 160486" clip-path="url(#clip-path-4)">
                  <path
                    id="Path_83192"
                    data-name="Path 83192"
                    d="M196.445,157.447a42.375,42.375,0,1,1-42.375-42.375,42.375,42.375,0,0,1,42.375,42.375Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160491" data-name="Group 160491" opacity="0.2">
              <g id="Group_160490" data-name="Group 160490">
                <g id="Group_160489" data-name="Group 160489" clip-path="url(#clip-path-5)">
                  <path
                    id="Path_83193"
                    data-name="Path 83193"
                    d="M139.465,125.475a42.388,42.388,0,0,0-16.711,3.423,42.394,42.394,0,0,0,48.1,67.471v-.019a42.393,42.393,0,0,0-31.389-70.875"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160494" data-name="Group 160494" opacity="0.2">
              <g id="Group_160493" data-name="Group 160493">
                <g id="Group_160492" data-name="Group 160492" clip-path="url(#clip-path-6)">
                  <path
                    id="Path_83194"
                    data-name="Path 83194"
                    d="M135.249,136.679a3.874,3.874,0,1,1-3.874-3.874,3.873,3.873,0,0,1,3.874,3.874"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160497" data-name="Group 160497" opacity="0.2">
              <g id="Group_160496" data-name="Group 160496">
                <g id="Group_160495" data-name="Group 160495" clip-path="url(#clip-path-7)">
                  <path
                    id="Path_83195"
                    data-name="Path 83195"
                    d="M180.818,132.807a3.874,3.874,0,1,1-3.873-3.874,3.873,3.873,0,0,1,3.873,3.874"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160500" data-name="Group 160500" opacity="0.2">
              <g id="Group_160499" data-name="Group 160499">
                <g id="Group_160498" data-name="Group 160498" clip-path="url(#clip-path-8)">
                  <path
                    id="Path_83196"
                    data-name="Path 83196"
                    d="M173.07,160.426a6.339,6.339,0,1,1-6.34-6.34,6.339,6.339,0,0,1,6.34,6.34"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160503" data-name="Group 160503" opacity="0.2">
              <g id="Group_160502" data-name="Group 160502">
                <g id="Group_160501" data-name="Group 160501" clip-path="url(#clip-path-9)">
                  <path
                    id="Path_83197"
                    data-name="Path 83197"
                    d="M152.926,178.5a8.473,8.473,0,1,1-8.473-8.473,8.472,8.472,0,0,1,8.473,8.473Z"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83198"
              data-name="Path 83198"
              d="M657.295,660.087A37.183,37.183,0,1,1,620.111,622.9a37.182,37.182,0,0,1,37.184,37.184"
              fill="#407bff"
            />
            <g id="Group_160506" data-name="Group 160506" opacity="0.3">
              <g id="Group_160505" data-name="Group 160505">
                <g id="Group_160504" data-name="Group 160504" clip-path="url(#clip-path-10)">
                  <path
                    id="Path_83199"
                    data-name="Path 83199"
                    d="M657.295,660.087A37.183,37.183,0,1,1,620.111,622.9a37.182,37.182,0,0,1,37.184,37.184"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160509" data-name="Group 160509" opacity="0.4">
              <g id="Group_160508" data-name="Group 160508">
                <g id="Group_160507" data-name="Group 160507" clip-path="url(#clip-path-11)">
                  <path
                    id="Path_83200"
                    data-name="Path 83200"
                    d="M643.578,631.248A37.188,37.188,0,0,0,609.391,695.7a37.188,37.188,0,0,0,34.187-64.452"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83201"
              data-name="Path 83201"
              d="M584.591,671.107c-39.716,18.687-10.091,33.09,45.857,12.983,51.8-18.619,71.5-42.1,25.244-34.827,2.386,9.881-62.494,35.161-71.1,21.843"
              fill="#407bff"
            />
            <path
              id="Path_83202"
              data-name="Path 83202"
              d="M138.038,231.816l3.127,6.336,6.994,1.017L143.1,244.1l1.2,6.965-6.259-3.289-6.255,3.288,1.2-6.965-5.061-4.932,6.994-1.017Z"
              fill="#ebebeb"
            />
            <path
              id="Path_83203"
              data-name="Path 83203"
              d="M760.869,536.454l-6.992,1.017,5.059,4.932-1.193,6.965L764,546.08l6.255,3.288-1.194-6.961,5.06-4.932-6.994-1.017L764,530.122Z"
              fill="#f5f5f5"
            />
            <path
              id="Path_83204"
              data-name="Path 83204"
              d="M636.888,720.349l-6.994,1.017,5.06,4.932-1.193,6.965,6.253-3.288,6.257,3.288-1.2-6.965,5.06-4.932-6.992-1.017-3.129-6.336Z"
              fill="#f5f5f5"
            />
            <path
              id="Path_83205"
              data-name="Path 83205"
              d="M356.375,119.46l1.16,2.352,2.6.377a.791.791,0,0,1,.439,1.348l-1.88,1.832.445,2.584a.791.791,0,0,1-1.147.834l-2.321-1.22-2.323,1.22a.791.791,0,0,1-1.145-.834l.443-2.584-1.878-1.832a.79.79,0,0,1,.437-1.348l2.6-.377,1.162-2.352a.789.789,0,0,1,1.416,0"
              fill="#ebebeb"
            />
            <path
              id="Path_83206"
              data-name="Path 83206"
              d="M36.931,683.922l1.16,2.352,2.6.377a.791.791,0,0,1,.44,1.348l-1.879,1.831.443,2.584a.79.79,0,0,1-1.146.834l-2.321-1.22-2.324,1.22a.791.791,0,0,1-1.145-.834l.443-2.584L31.32,688a.791.791,0,0,1,.437-1.348l2.6-.377,1.163-2.352a.789.789,0,0,1,1.057-.358.8.8,0,0,1,.359.358"
              fill="#f5f5f5"
            />
            <path
              id="Path_83207"
              data-name="Path 83207"
              d="M89.108,94.6l1.161,2.352,2.6.377a.791.791,0,0,1,.439,1.348l-1.882,1.832.443,2.584a.791.791,0,0,1-1.147.834l-2.321-1.22-2.321,1.219a.79.79,0,0,1-1.145-.834l.446-2.583L83.5,98.679a.79.79,0,0,1,.437-1.348l2.6-.377L87.692,94.6a.79.79,0,0,1,1.416,0"
              fill="#ebebeb"
            />
            <path
              id="Path_83208"
              data-name="Path 83208"
              d="M85.967,560.746A2.566,2.566,0,1,1,83.4,558.18a2.566,2.566,0,0,1,2.566,2.566"
              fill="#ebebeb"
            />
            <path
              id="Path_83209"
              data-name="Path 83209"
              d="M452.549,238.87a2.567,2.567,0,1,1-2.567-2.566h0a2.566,2.566,0,0,1,2.566,2.566"
              fill="#ebebeb"
            />
            <path
              id="Path_83210"
              data-name="Path 83210"
              d="M319.167,96.729a2.567,2.567,0,1,1-2.567-2.567,2.567,2.567,0,0,1,2.567,2.567"
              fill="#f5f5f5"
            />
            <path
              id="Path_83211"
              data-name="Path 83211"
              d="M334.22,709.78a14.21,14.21,0,1,0-14.211,14.21,14.21,14.21,0,0,0,14.211-14.21"
              fill="#f0f0f0"
            />
            <path
              id="Path_83212"
              data-name="Path 83212"
              d="M315.086,701.488a13.716,13.716,0,0,0-8.1,2.63,14.2,14.2,0,0,0,20.3,17.853c-.019-.019-.019-.019,0-.019a13.875,13.875,0,0,0-12.2-20.462"
              fill="#e6e6e6"
            />
            <path
              id="Path_83213"
              data-name="Path 83213"
              d="M160.387,343.768v73.179H98.192Zm0-69.738-117.7,139.9v53.138h117.7V510.53H216.7V467.072h29.185V416.947H216.694V274.03Z"
              fill="#0973ea"
            />
            <path
              id="Path_83214"
              data-name="Path 83214"
              d="M266.024,393.313q0-66.3,23.872-92.792t72.726-26.491q23.473,0,38.545,5.791t24.58,15.068A80.157,80.157,0,0,1,440.741,314.4a101.5,101.5,0,0,1,8.806,23.874,222.759,222.759,0,0,1,6.5,54.248q0,63.287-21.414,92.634T360.874,514.5q-29.344,0-47.429-9.359a76.77,76.77,0,0,1-29.661-27.44q-8.41-12.846-13.086-35.134a240.1,240.1,0,0,1-4.678-49.253m64.083.159q0,44.417,7.848,60.675T360.714,470.4a23.85,23.85,0,0,0,17.052-6.9q7.215-6.9,10.63-21.81t3.41-46.477q0-46.312-7.852-62.257T360.4,317.016q-16.017,0-23.158,16.26t-7.135,60.2"
              fill="#0973ea"
            />
            <path
              id="Path_83215"
              data-name="Path 83215"
              d="M593.734,467.072h-117.7V413.93l117.7-139.9h56.31V416.947h29.187v50.125H650.044V510.53h-56.31Zm0-50.125V343.766l-62.19,73.181Z"
              fill="#0973ea"
            />
            <g id="Group_160512" data-name="Group 160512" opacity="0.2">
              <g id="Group_160511" data-name="Group 160511">
                <g id="Group_160510" data-name="Group 160510" clip-path="url(#clip-path-12)">
                  <path
                    id="Path_83216"
                    data-name="Path 83216"
                    d="M640.766,273.865C588.759,309.009,521.435,355.349,502.848,466.9h-3.965c18.083-110.2,82.952-157.759,134.978-193.038Z"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83217"
              data-name="Path 83217"
              d="M371.782,622.842c-32.821,0-65.388-14.819-81.2-39.043-9.773-14.968-22.564-44.175,11.234-86l3.144,2.089c-23.909,29.589-27.02,57.479-11.14,81.8,19.331,29.607,64.977,44.495,103.919,33.9,39.9-10.856,64.118-45.561,66.456-95.21,8-169.824,98.219-222.136,164.1-260.334,40.928-23.731,70.5-40.878,64-77.049-.9-5.019-2.9-8.487-6.117-10.6-8.419-5.549-24.858-1.714-43.891,2.723-37.339,8.7-88.475,20.611-118.939-31.2l3.334-1.961c29.022,49.365,76.544,38.294,114.726,29.4,19.9-4.636,37.089-8.639,46.9-2.187,4.148,2.731,6.7,7.032,7.8,13.15,6.98,38.84-25.168,57.478-65.868,81.078-65.12,37.741-154.288,89.442-162.187,257.158-2.421,51.438-27.682,87.435-69.3,98.763a102.956,102.956,0,0,1-26.968,3.524"
              fill="#407bff"
            />
            <g id="Group_160515" data-name="Group 160515" opacity="0.2">
              <g id="Group_160514" data-name="Group 160514">
                <g id="Group_160513" data-name="Group 160513" clip-path="url(#clip-path-13)">
                  <path
                    id="Path_83218"
                    data-name="Path 83218"
                    d="M371.782,622.842c-32.821,0-65.388-14.819-81.2-39.043-9.773-14.968-22.623-44.269,11.175-86.1l3.009,2.433c-23.912,29.591-26.831,57.231-10.95,81.554,19.331,29.607,64.977,44.5,103.919,33.9,39.9-10.856,64.118-45.561,66.456-95.21,8-169.824,98.219-222.136,164.1-260.334,40.928-23.731,70.5-40.878,64-77.049-.9-5.019-2.9-8.487-6.117-10.6-8.419-5.549-24.858-1.714-43.891,2.723-37.339,8.7-88.475,20.611-118.939-31.2l3.334-1.961c29.022,49.365,76.544,38.294,114.726,29.4,19.9-4.636,37.089-8.639,46.9-2.187,4.148,2.731,6.7,7.032,7.8,13.15,6.98,38.84-25.168,57.478-65.868,81.078-65.116,37.743-154.284,89.444-162.183,257.16-2.421,51.438-27.682,87.435-69.3,98.763a102.956,102.956,0,0,1-26.968,3.524"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83219"
              data-name="Path 83219"
              d="M483.454,98.1c13.258-.242,26.269,4.127,26.269,4.127s21.207,36.3,23.793,44.613c-.891,8.2-14.729,21.644-14.729,21.644Z"
              fill="#407bff"
            />
            <g id="Group_160518" data-name="Group 160518" opacity="0.3">
              <g id="Group_160517" data-name="Group 160517">
                <g id="Group_160516" data-name="Group 160516" clip-path="url(#clip-path-14)">
                  <path
                    id="Path_83220"
                    data-name="Path 83220"
                    d="M483.454,98.1c13.258-.242,26.268,4.127,26.268,4.127s21.207,36.3,23.793,44.613c-.89,8.2-14.728,21.644-14.728,21.644Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83221"
              data-name="Path 83221"
              d="M546.466,274.454c-8-10.15-15.853-20.489-22.356-31.689q-2.441-4.2-4.62-8.539-1.242-2.463-2.406-4.965c-.679-1.456-2.178-3.6-2.329-5.191-2.323-24.51,6.067-42.551-1.988-62.222L480.911,174.3s2.71,35.049,8.893,56.016c3.837,13.008,12.523,24.27,20.9,34.693,2.619,3.259,5.141,6.6,7.756,9.86,2.474,3.091,5.243,5.926,7.725,9,3.766,4.665,5.017,9.121,2.156,14.623q-.233.441-.478.876c-.812,1.433,2.992,3.058,5.379.039,3.949-4.99,3.329-4.684,6.7-8.939,2.044-2.584,4.392-5.377,6.419-7.8a6.511,6.511,0,0,0,.114-8.212"
              fill="#407bff"
            />
            <g id="Group_160521" data-name="Group 160521" opacity="0.7">
              <g id="Group_160520" data-name="Group 160520">
                <g id="Group_160519" data-name="Group 160519" clip-path="url(#clip-path-15)">
                  <path
                    id="Path_83222"
                    data-name="Path 83222"
                    d="M546.466,274.454c-8-10.15-15.853-20.489-22.356-31.689q-2.439-4.2-4.62-8.539-1.242-2.463-2.406-4.965c-.678-1.456-2.178-3.6-2.329-5.191-2.323-24.51,6.068-42.551-1.987-62.222L480.911,174.3s2.71,35.049,8.894,56.016c3.836,13.008,12.522,24.27,20.9,34.693,2.619,3.259,5.142,6.6,7.757,9.86,2.474,3.091,5.242,5.926,7.724,9,3.766,4.665,5.018,9.121,2.156,14.623q-.233.441-.478.876c-.811,1.433,2.992,3.058,5.379.039,3.949-4.99,3.329-4.684,6.7-8.939,2.044-2.584,4.391-5.377,6.419-7.8a6.512,6.512,0,0,0,.113-8.212"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160524" data-name="Group 160524" opacity="0.3">
              <g id="Group_160523" data-name="Group 160523">
                <g id="Group_160522" data-name="Group 160522" clip-path="url(#clip-path-16)">
                  <path
                    id="Path_83223"
                    data-name="Path 83223"
                    d="M538.677,264.369c-4.266,5.154-12.935,11.84-16.758,14.55.831.926,1.657,1.872,2.482,2.818a85.075,85.075,0,0,0,16.447-14.513c-.728-.94-1.452-1.9-2.172-2.855"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160527" data-name="Group 160527" opacity="0.3">
              <g id="Group_160526" data-name="Group 160526">
                <g id="Group_160525" data-name="Group 160525" clip-path="url(#clip-path-17)">
                  <path
                    id="Path_83224"
                    data-name="Path 83224"
                    d="M546.458,274.457c-.074-.1-.151-.182-.224-.28a9.866,9.866,0,0,0-2.447,6.129,9.365,9.365,0,0,0,.731,4.547c.642-.747,1.244-1.485,1.835-2.187a6.5,6.5,0,0,0,.1-8.208"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83225"
              data-name="Path 83225"
              d="M475.879,121.311a69.179,69.179,0,0,1-12.332,13.914A46,46,0,0,1,455,140.988a36.812,36.812,0,0,1-5,2.1l-1.3.42-.422.132c-.3.093-.613.176-.923.251a12.5,12.5,0,0,1-1.688.263,14.44,14.44,0,0,1-4.862-.451,23.364,23.364,0,0,1-5.679-2.445,44.466,44.466,0,0,1-4.167-2.735,76.57,76.57,0,0,1-6.92-5.9A103.038,103.038,0,0,1,412.46,119.6a4.856,4.856,0,0,1,6.483-7.011l.147.1c4.564,2.9,9.169,5.953,13.663,8.688,2.268,1.34,4.484,2.684,6.664,3.733a30.5,30.5,0,0,0,3.069,1.385,6.2,6.2,0,0,0,2.083.516c.1-.006-.019-.141-.73-.118a4.341,4.341,0,0,0-.664.087,3.391,3.391,0,0,0-.422.108c-.133.044.056-.037.068-.048l.638-.327a25.639,25.639,0,0,0,2.495-1.526,36.839,36.839,0,0,0,4.839-4.1,127.548,127.548,0,0,0,9.481-11.19l.044-.056a9.674,9.674,0,0,1,15.561,11.479"
              fill="#407bff"
            />
            <g id="Group_160530" data-name="Group 160530" opacity="0.7">
              <g id="Group_160529" data-name="Group 160529">
                <g id="Group_160528" data-name="Group 160528" clip-path="url(#clip-path-18)">
                  <path
                    id="Path_83226"
                    data-name="Path 83226"
                    d="M475.879,121.311a69.206,69.206,0,0,1-12.332,13.914A46,46,0,0,1,455,140.988a36.812,36.812,0,0,1-5,2.1l-1.3.42-.422.132c-.3.093-.613.176-.923.251a12.5,12.5,0,0,1-1.688.263,14.44,14.44,0,0,1-4.862-.451,23.364,23.364,0,0,1-5.679-2.445,44.466,44.466,0,0,1-4.167-2.735,76.57,76.57,0,0,1-6.92-5.9A103.038,103.038,0,0,1,412.46,119.6a4.856,4.856,0,0,1,6.483-7.011l.147.1c4.564,2.9,9.169,5.953,13.663,8.688,2.268,1.34,4.484,2.684,6.664,3.733a30.5,30.5,0,0,0,3.069,1.385,6.2,6.2,0,0,0,2.083.516c.1-.006-.019-.141-.73-.118a4.341,4.341,0,0,0-.664.087,3.391,3.391,0,0,0-.422.108c-.133.044.056-.037.068-.048l.638-.327a25.639,25.639,0,0,0,2.495-1.526,36.839,36.839,0,0,0,4.839-4.1,127.548,127.548,0,0,0,9.481-11.19l.044-.056a9.674,9.674,0,0,1,15.561,11.479"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83227"
              data-name="Path 83227"
              d="M405.175,108.674l2.267,3.8s1.731,5.059,5.2,6l9.388-3.047-.47-.789h.014c-1.2-1.832-1.073-5.363-.652-8.3s-1.1-3.042-2.226-2.311c-.65.422-1.11,1.762-1.638,3.207a14.945,14.945,0,0,0-1.516-1.806l-2.869-2.854a3.3,3.3,0,0,0-4.526-.128l-2.325,2.066a3.3,3.3,0,0,0-.642,4.16"
              fill="#407bff"
            />
            <g id="Group_160533" data-name="Group 160533" opacity="0.7">
              <g id="Group_160532" data-name="Group 160532">
                <g id="Group_160531" data-name="Group 160531" clip-path="url(#clip-path-19)">
                  <path
                    id="Path_83228"
                    data-name="Path 83228"
                    d="M405.175,108.675l2.267,3.8s1.731,5.059,5.2,6l9.388-3.047-.47-.789h.014c-1.2-1.832-1.073-5.363-.652-8.3s-1.1-3.042-2.226-2.311c-.65.422-1.11,1.762-1.638,3.207a14.945,14.945,0,0,0-1.516-1.806l-2.869-2.854a3.3,3.3,0,0,0-4.526-.128l-2.325,2.066a3.3,3.3,0,0,0-.642,4.16"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83229"
              data-name="Path 83229"
              d="M492.961,94.761a114.657,114.657,0,0,0-29.686,12.512,8.332,8.332,0,0,0-3.737,8.75c3.721,18.257,12.212,42.714,21.377,58.271L523.68,156.6c.3-7.545-10.089-31.951-20.663-55.542-1.9-4.241-5.539-7.4-10.057-6.3"
              fill="#407bff"
            />
            <g id="Group_160536" data-name="Group 160536" opacity="0.8">
              <g id="Group_160535" data-name="Group 160535">
                <g id="Group_160534" data-name="Group 160534" clip-path="url(#clip-path-20)">
                  <path
                    id="Path_83230"
                    data-name="Path 83230"
                    d="M492.961,94.762a114.657,114.657,0,0,0-29.686,12.512,8.332,8.332,0,0,0-3.737,8.75c3.721,18.257,12.212,42.714,21.377,58.271l42.765-17.689c.3-7.545-10.089-31.951-20.663-55.542-1.9-4.241-5.539-7.4-10.057-6.3"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160539" data-name="Group 160539" opacity="0.3">
              <g id="Group_160538" data-name="Group 160538">
                <g id="Group_160537" data-name="Group 160537" clip-path="url(#clip-path-21)">
                  <path
                    id="Path_83231"
                    data-name="Path 83231"
                    d="M509.636,116.018l-8.487-2.845c1.9,4.963,8.767,11.258,13.6,14.954-1.592-3.88-3.317-7.935-5.11-12.109"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83232"
              data-name="Path 83232"
              d="M490.142,75.608c-3.529-6.733-11.165-10.119-20.338-9.354-7.734.645-14.59,8.539-13.778,12.806s7.32,6.071,8.5,7.552L459.163,90.5a5.8,5.8,0,0,0-1.3,8.1c.046.065.094.128.143.19,2.257,2.866,5.23,5.86,6.959,7.976,14.823-.385,25.785-6.042,29.734-11.481-1.42-6.926-1.05-12.985-4.56-19.681"
              fill="#407bff"
            />
            <g id="Group_160542" data-name="Group 160542" opacity="0.8">
              <g id="Group_160541" data-name="Group 160541">
                <g id="Group_160540" data-name="Group 160540" clip-path="url(#clip-path-22)">
                  <path
                    id="Path_83233"
                    data-name="Path 83233"
                    d="M490.142,75.608c-3.529-6.733-11.165-10.119-20.338-9.354-7.734.645-14.59,8.539-13.778,12.806s7.32,6.071,8.5,7.552L459.163,90.5a5.8,5.8,0,0,0-1.3,8.1c.046.065.094.128.143.19,2.257,2.866,5.23,5.86,6.959,7.976,14.823-.385,25.785-6.042,29.734-11.481-1.42-6.926-1.05-12.985-4.56-19.681"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83234"
              data-name="Path 83234"
              d="M482.876,79.778A14.647,14.647,0,1,1,463.9,71.467h0a14.644,14.644,0,0,1,18.971,8.308v0"
              fill="#263238"
            />
            <path
              id="Path_83235"
              data-name="Path 83235"
              d="M608.451,254.077c-.215-6.361-.489-5.725-.675-11.148-.109-3.294-.126-6.941-.147-10.1a6.513,6.513,0,0,0-5.224-6.338c-2.563-.52-5.133-1.015-7.69-1.561-3.337-.71-6.638-1.478-9.916-2.429q-3.815-1.105-7.552-2.45c-2.666-.961-5.3-2.015-7.908-3.133-3.047-1.307-6.057-2.7-9.047-4.135-3.336-1.6-6.648-3.255-9.948-4.932-12.729-21.073-13-34.958-26.663-51.245l-29.32,13.264S515.9,208.1,530.576,224.3c8.445,9.328,21.7,13.533,33.511,16.585,8.525,2.2,17.171,3.886,25.825,5.475,3.365.617,7.024.857,9.92,2.864a11.107,11.107,0,0,1,4.133,6.676c.137.536.254,1.079.356,1.623.307,1.619,4.261.4,4.13-3.448"
              fill="#407bff"
            />
            <g id="Group_160545" data-name="Group 160545" opacity="0.8">
              <g id="Group_160544" data-name="Group 160544">
                <g id="Group_160543" data-name="Group 160543" clip-path="url(#clip-path-23)">
                  <path
                    id="Path_83236"
                    data-name="Path 83236"
                    d="M608.451,254.077c-.215-6.361-.489-5.725-.675-11.148-.109-3.294-.126-6.941-.147-10.1a6.513,6.513,0,0,0-5.224-6.338c-2.563-.52-5.133-1.015-7.69-1.561-3.337-.71-6.638-1.478-9.916-2.429q-3.815-1.105-7.552-2.45c-2.666-.961-5.3-2.015-7.908-3.133-3.047-1.307-6.057-2.7-9.047-4.135-3.336-1.6-6.648-3.255-9.948-4.932-12.729-21.073-13-34.958-26.663-51.245l-29.32,13.264S515.9,208.1,530.576,224.3c8.445,9.328,21.7,13.533,33.511,16.585,8.525,2.2,17.171,3.886,25.825,5.475,3.365.617,7.024.857,9.92,2.864a11.107,11.107,0,0,1,4.133,6.676c.137.536.254,1.079.356,1.623.307,1.619,4.261.4,4.13-3.448"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160548" data-name="Group 160548" opacity="0.3">
              <g id="Group_160547" data-name="Group 160547">
                <g id="Group_160546" data-name="Group 160546" clip-path="url(#clip-path-24)">
                  <path
                    id="Path_83237"
                    data-name="Path 83237"
                    d="M593.583,224.686c-1.156-.253-2.328-.513-3.493-.789.091,6.678-3.042,18.215-4.192,21.71,1.219.23,2.459.466,3.678.7a73.362,73.362,0,0,0,4.007-21.619"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160551" data-name="Group 160551" opacity="0.3">
              <g id="Group_160550" data-name="Group 160550">
                <g id="Group_160549" data-name="Group 160549" clip-path="url(#clip-path-25)">
                  <path
                    id="Path_83238"
                    data-name="Path 83238"
                    d="M607.622,232.826a6.512,6.512,0,0,0-5.21-6.346c-.607-.124-1.234-.253-1.847-.36a8.815,8.815,0,0,0,3.149,5.6,9.852,9.852,0,0,0,3.928,2.211c-.015-.385-.017-.747-.019-1.108"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83239"
              data-name="Path 83239"
              d="M480.139,79.9c.783,2.932-2.5,6.015-5.125,3.479-2.131-2.058-5.343-5.5-7.963-7.142-2.692-1.683.9-4.617,5.125-3.477,3.948,1.062,7.179,4.209,7.962,7.141"
              fill="#fff"
            />
            <path
              id="Path_83240"
              data-name="Path 83240"
              d="M480.366,173.354c-1.364.5,1.128,2.816,1.128,2.816s27.019-9.258,43.507-18.791a3.609,3.609,0,0,0-1.311-3.052,420.071,420.071,0,0,1-43.324,19.027"
              fill="#407bff"
            />
            <g id="Group_160554" data-name="Group 160554" opacity="0.5">
              <g id="Group_160553" data-name="Group 160553">
                <g id="Group_160552" data-name="Group 160552" clip-path="url(#clip-path-26)">
                  <path
                    id="Path_83241"
                    data-name="Path 83241"
                    d="M480.366,173.354c-1.364.5,1.128,2.816,1.128,2.816s27.019-9.258,43.507-18.791a3.609,3.609,0,0,0-1.311-3.052,420.071,420.071,0,0,1-43.324,19.027"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83242"
              data-name="Path 83242"
              d="M500.282,93.483c5.334,2.694,10.355,5.547,15.37,8.7,2.5,1.574,4.982,3.189,7.431,4.93,2.468,1.708,4.9,3.51,7.347,5.535l.917.75,1.141,1.031a24.228,24.228,0,0,1,2.006,2.035c.627.679,1.143,1.333,1.652,1.984.528.656,1.029,1.305,1.48,1.95a84.681,84.681,0,0,1,5,7.773,113.9,113.9,0,0,1,7.67,16.147,4.873,4.873,0,0,1-8.1,5.063l-.095-.1c-3.886-4.116-7.612-8.456-11.363-12.49-3.669-4.021-7.566-8.148-10.709-9.943-4.39-2.739-9.28-5.458-14.138-8.137l-14.625-8.117-.037-.021a9.672,9.672,0,0,1,9.053-17.089"
              fill="#407bff"
            />
            <g id="Group_160557" data-name="Group 160557" opacity="0.8">
              <g id="Group_160556" data-name="Group 160556">
                <g id="Group_160555" data-name="Group 160555" clip-path="url(#clip-path-27)">
                  <path
                    id="Path_83243"
                    data-name="Path 83243"
                    d="M500.282,93.483c5.334,2.694,10.355,5.547,15.37,8.7,2.5,1.574,4.982,3.189,7.431,4.93,2.468,1.708,4.9,3.51,7.347,5.535l.917.75,1.141,1.031a24.228,24.228,0,0,1,2.006,2.035c.627.679,1.143,1.333,1.652,1.984.528.656,1.029,1.305,1.48,1.95a84.681,84.681,0,0,1,5,7.773,113.9,113.9,0,0,1,7.67,16.147,4.873,4.873,0,0,1-8.1,5.063l-.095-.1c-3.886-4.116-7.612-8.456-11.363-12.49-3.669-4.021-7.566-8.148-10.709-9.943-4.39-2.739-9.28-5.458-14.138-8.137l-14.625-8.117-.037-.021a9.672,9.672,0,0,1,9.053-17.089"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83244"
              data-name="Path 83244"
              d="M554.966,153.778l-1.643-4.11s-.917-5.27-4.19-6.736l-9.75,1.532.34.853h-.012c.9,2,.219,5.466-.66,8.3s.61,3.178,1.837,2.632c.71-.313,1.372-1.565,2.122-2.911a14.5,14.5,0,0,0,1.214,2.021l2.385,3.272a3.3,3.3,0,0,0,4.448.836l2.622-1.677a3.3,3.3,0,0,0,1.286-4.007"
              fill="#407bff"
            />
            <g id="Group_160560" data-name="Group 160560" opacity="0.8">
              <g id="Group_160559" data-name="Group 160559">
                <g id="Group_160558" data-name="Group 160558" clip-path="url(#clip-path-28)">
                  <path
                    id="Path_83245"
                    data-name="Path 83245"
                    d="M554.966,153.778l-1.643-4.11s-.917-5.27-4.19-6.736l-9.75,1.532.34.853h-.012c.9,2,.219,5.466-.66,8.3s.61,3.178,1.837,2.632c.71-.313,1.372-1.565,2.122-2.911a14.5,14.5,0,0,0,1.214,2.021l2.385,3.272a3.3,3.3,0,0,0,4.448.836l2.622-1.677a3.3,3.3,0,0,0,1.286-4.007"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160563" data-name="Group 160563" opacity="0.3">
              <g id="Group_160562" data-name="Group 160562">
                <g id="Group_160561" data-name="Group 160561" clip-path="url(#clip-path-29)">
                  <path
                    id="Path_83246"
                    data-name="Path 83246"
                    d="M492.113,115.714l-2.358.191-14.476,34.98a7.635,7.635,0,0,0,2.358-.191s18.876-7.036,24.591-9.614c-3.848-7.966-10.113-25.361-10.113-25.361"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83247"
              data-name="Path 83247"
              d="M465.677,124.891c.806,6.3,5.529,19.687,9.6,25.99,7.069-2.437,18.877-7.034,24.59-9.614a263.065,263.065,0,0,1-10.113-25.361c-6.09.406-19.362,5.406-24.079,8.986"
              fill="#fff"
            />
            <g id="Group_160566" data-name="Group 160566" opacity="0.3">
              <g id="Group_160565" data-name="Group 160565">
                <g id="Group_160564" data-name="Group 160564" clip-path="url(#clip-path-30)">
                  <path
                    id="Path_83248"
                    data-name="Path 83248"
                    d="M480.053,132.455a4.988,4.988,0,1,1-3.349-6.208h0a4.986,4.986,0,0,1,3.349,6.2l0,0"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160569" data-name="Group 160569" opacity="0.6">
              <g id="Group_160568" data-name="Group 160568">
                <g id="Group_160567" data-name="Group 160567" clip-path="url(#clip-path-31)">
                  <path
                    id="Path_83249"
                    data-name="Path 83249"
                    d="M483.753,125.792a1.635,1.635,0,1,1-1.1-2.035,1.636,1.636,0,0,1,1.1,2.035v0"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160572" data-name="Group 160572" opacity="0.6">
              <g id="Group_160571" data-name="Group 160571">
                <g id="Group_160570" data-name="Group 160570" clip-path="url(#clip-path-32)">
                  <path
                    id="Path_83250"
                    data-name="Path 83250"
                    d="M488.069,124.111a1.635,1.635,0,1,1-1.1-2.035,1.636,1.636,0,0,1,1.1,2.035"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <g id="Group_160575" data-name="Group 160575" opacity="0.5">
              <g id="Group_160574" data-name="Group 160574">
                <g id="Group_160573" data-name="Group 160573" clip-path="url(#clip-path-33)">
                  <path
                    id="Path_83251"
                    data-name="Path 83251"
                    d="M494.153,139.174l-17.884,7.069-1.081-3.518,17.884-7.069Z"
                    fill="#407bff"
                  />
                </g>
              </g>
            </g>
            <path
              id="Path_83252"
              data-name="Path 83252"
              d="M159.318,0h203a12,12,0,0,1,12,12V51a12,12,0,0,1-12,12h-203a12,12,0,0,1-12-12V12a12,12,0,0,1,12-12"
              fill="#cce5ff"
            />
            <g
              id="Group_160584"
              data-name="Group 160584"
              style={{ 'mix-blend-mode': 'normal', isolation: 'isolate' }}>
              <g id="Group_160583" data-name="Group 160583">
                <g id="Group_160582" data-name="Group 160582" clip-path="url(#clip-path-34)">
                  <g
                    id="Group_160578"
                    data-name="Group 160578"
                    style={{ 'mix-blend-mode': 'normal', isolation: 'isolate' }}>
                    <g id="Group_160577" data-name="Group 160577">
                      <g id="Group_160576" data-name="Group 160576" clip-path="url(#clip-path-35)">
                        <text
                          id="We_seem_to_have_"
                          data-name="We seem to have "
                          transform="translate(176.318 27)"
                          fill="#0973ea"
                          font-size="16"
                          font-family="Roboto-Bold, Roboto"
                          font-weight="700">
                          <tspan x="0" y="0">
                            We seem{' '}
                          </tspan>
                          <tspan y="0" letter-spacing="-0.016em">
                            t
                          </tspan>
                          <tspan y="0">o h</tspan>
                          <tspan y="0" letter-spacing="-0.007em">
                            a
                          </tspan>
                          <tspan y="0">ve </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_160581"
                    data-name="Group 160581"
                    style={{ 'mix-blend-mode': 'normal', isolation: 'isolate' }}>
                    <g id="Group_160580" data-name="Group 160580">
                      <g id="Group_160579" data-name="Group 160579" clip-path="url(#clip-path-36)">
                        <text
                          id="misplaced_your_package"
                          data-name="misplaced your package"
                          transform="translate(176.318 43)"
                          fill="#0973ea"
                          font-size="16"
                          font-family="Roboto-Bold, Roboto"
                          font-weight="700">
                          <tspan x="0" y="0">
                            misplaced your package
                          </tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <rect
              id="Rectangle_147738"
              data-name="Rectangle 147738"
              width="4"
              height="72.416"
              transform="translate(376.351 61.287) rotate(-62.999)"
              fill="#cce5ff"
            />
            <path
              id="Path_83253"
              data-name="Path 83253"
              d="M37.975,623.184,8.13,676.252l44.794,61.63,59.689-12.008,29.845-53.067L97.663,611.176Z"
              fill="#cce5ff"
            />
            <path
              id="Path_83254"
              data-name="Path 83254"
              d="M49.083,738.638,5.068,678.079l18.547-4.217,15.268,21.007,24.777-5.545L48.336,668.241l17.933-4.076,44.012,60.555ZM69.1,662.11l32.129-53.92,44.015,60.558-32.133,53.915Zm-20.147,2.4,31.6-53.026,16.93-3.854-31.6,53.032ZM5.683,674.356h0L37.29,621.321l16.93-3.854L22.614,670.506Zm29.366-56.118L0,677.06l47.608,65.5,66.769-15.181,35.049-58.822-47.609-65.5Z"
              fill="#0973ea"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
};

export default PageNotFoundIcon;
