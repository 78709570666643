import { useQuery } from 'react-query';
import { getOnHold } from '../services/onHold-api';

export const useOnHoldQuery = (filters, select, enabled) => {
  return useQuery({
    queryKey: [`onhold`, filters],
    queryFn: () => getOnHold(filters),
    cacheTime: 60 * 1000,
    select: select,
    enabled: enabled
  });
};
