import React,  { useState } from 'react';
import { Fragment } from 'react';
import { Card, CardHeader, CardBody, Link, Text, Box, HStack, VStack } from '@chakra-ui/react';
import SlzButton from 'components/common/SlzButton/SlzButton';
import { Field } from 'components/form';

import {
  Warehouse,
  UKFlag
} from 'components/common/Icons/EnabledCentreCards/EnabledFulfilmentCentreIcons';
import {
  AlcoholIcon,
  BinIcon,
  FragileIcon,
  GarmenOnHangerIcon,
  HazmatIcon,
  OversizedItemIcon,
  PalletIcon,
  SecureIcon,
  ShelfIcon,
  TemperatureControlledIcon
} from 'components/common/Icons/storageCapabilitiesIcons/index.js';
import { SlzIconChip } from 'components/common/SlzChip';
import useSlzToast from 'hooks/useSlzToast';
import { getAccessRequestById } from 'services/fulfillmentCentre';

import TradingZone from 'modules/fulfilmentCentres/components/TradingZone/TradingZone.jsx';
import Input from 'components/common/SlzInput/Input';

const SELECTED_STYLES = {
  true: {
    bg: 'main.300',
    color: 'dark.300'
  },
  false: {
    bg: 'light.700',
    color: 'dark.300'
  }
};

const PendingStatusIndicator = ({ background, text, textColor, border, borderColor }) => {
  return (
    <Box
      w="5.375rem"
      h="1.125rem"
      justifyContent="center"
      display="flex"
      alignItems="center"
      borderRadius="1.313rem"
      bg={background}
      border={border}
      borderColor={borderColor}>
      <Text fontSize={12} color={textColor}>
        {text}
      </Text>
    </Box>
  );
};

const ICON_HEADER = {
  New: () => (
    <PendingStatusIndicator
      background="barb.500"
      text="New"
      textColor="lightAndShadow.reverseText"
    />
  ),
  ComingSoon: () => (
    <PendingStatusIndicator
      background="negative.300"
      text="ComingSoon"
      textColor="barb.500"
      border="0.063rem solid"
      borderColor="barb.500"
    />
  ),
  Pending: () => (
    <PendingStatusIndicator background="sunshine.500" text="Pending" textColor="willow.500" />
  )
};

const ACTION_BUTTON = {
  Available: ({ showModal }) => (
    <SlzButton type="submit" colorScheme="main" onClick={() => showModal()}>
      Request
    </SlzButton>
  ),
  New: ({ showModal }) => (
    <SlzButton type="submit" colorScheme="main" onClick={() => showModal()}>
      Request
    </SlzButton>
  ),
  ComingSoon: () => (
    <SlzButton type="submit" colorScheme="main" variant="outline" isDisabled="true">
      Request
    </SlzButton>
  ),
  Pending: ({ getAccessRequest }) => (
    <SlzButton
      type="submit"
      colorScheme="main"
      variant="outline"
      onClick={() => getAccessRequest()}>
      Review
    </SlzButton>
  )
};

export const WarehouseCard = ({
  isPostMVP,
  tradingZones,
  storageCapabilities,
  iconChip,
  warehouse,
  categories,
  children,
  showStatus = true,
  selectedWarehouse,
  ...rest
}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [accessRequestByID, setAccessRequestByID] = useState([]);
  const [_, setToast] = useSlzToast();

  const StorageCapabilityIcon = ({ icon }) => {
    switch (icon) {
      case 'AlcoholIcon':
        return <AlcoholIcon />;

      case 'BinIcon':
        return <BinIcon />;

      case 'FragileIcon':
        return <FragileIcon />;

      case 'GarmenOnHangerIcon':
        return <GarmenOnHangerIcon />;

      case 'HazmatIcon':
        return <HazmatIcon />;

      case 'OversizedItemIcon':
        return <OversizedItemIcon />;

      case 'PalletIcon':
        return <PalletIcon />;

      case 'SecureIcon':
        return <SecureIcon />;

      case 'ShelfIcon':
        return <ShelfIcon />;

      case 'TemperatureControlledIcon':
        return <TemperatureControlledIcon />;

      default:
        return null;
    }
  };

  const IconChip = () => {
    switch (iconChip) {
      case 'CustomPackaging':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="marine"
            children="Custom Packaging"
            icon="customPackaging"
          />
        );

      case 'EmissionFree':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Emission Free"
            icon="emissionFree"
          />
        );

      case 'NextDayDelivery':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Next Day Delivery"
            icon="nextDayDelivery"
          />
        );

      case 'SameDayDelivery':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Same Day Delivery"
            icon="sameDayDelivery"
          />
        );

      default:
        return null;
    }
  };

  const PostMVPInformation = () => {
    return (
      <Box mt="0.938rem">
        <Box>
          <IconChip />
        </Box>
        <Box display="flex" flexDirection="row" gap="0.375rem" mt="0.375rem">
          {storageCapabilities.map((storage) => {
            return <StorageCapabilityIcon icon={storage} />;
          })}
        </Box>
        <Box mt="0.469rem">
          <Text fontSize={12}>Trading Zones:</Text>
          <Box display="flex" flexDirection="row" gap="0.375rem" mt="0.406rem">
            {tradingZones.map((zone, index) => {
              return <TradingZone key={index} tradingZone={zone} />;
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  const showModal = () => {
    setIsModalActive(true);
  };

  const getAccessRequest = async () => {
    const response = await getAccessRequestById(warehouse.requestID);
    if (response === 'error')
      setToast({
        title: 'Error in reviewing your pending request. Please try again.',
        status: 'warning',
        colorScheme: 'negative',
        chakraToastProps: { duration: null }
      });
    setAccessRequestByID(response);
    showModal();
  };

  const showExtWarehouse = (warehouse) => {
    if (warehouse.externalWMS === true && selectedWarehouse === null){
      return (
        <Box display="flex" flexDirection="row">
          <VStack alignItems="flex-start">
            <HStack>
              <Text fontSize={16}>Existing Customer?</Text>
              <input
                    type="checkbox"
                    name="ExistingRef"/>
            </HStack>
            <Field 
            name="CustomerRef" 
            placeholder="Enter Customer Ref" />
          </VStack>
        </Box>           
      );
    }
  }
  
  const ActionButton = ACTION_BUTTON[warehouse.state] || Fragment;
  const HeaderIcon = ICON_HEADER[warehouse.state] || Fragment;

  return (
    <Card w="19.125rem" borderRadius="0.75rem" boxShadow="slz-1md" {...rest}>
      <CardHeader
        bg="indigo.500"
        color="white"
        borderRadius="0.75rem 0.75rem 0rem 0rem"
        p="1.35rem 1.5rem 1.188rem 1.5rem">
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="flex-end" gap="0.313rem">
              <Warehouse />
              <Text fontSize={12}>Fulfillment Centre</Text>
            </Box>
            {showStatus && <HeaderIcon />}
          </Box>
          <Text fontSize={16}>{warehouse.name}</Text>
        </Box>
      </CardHeader>

      <CardBody p="0.906rem 1.513rem 1.5rem 1.5rem" borderRadius="0.75rem 0.75rem 0rem 0rem">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Text fontSize={12}>{warehouse.address?.line1}</Text>
            <Text fontSize={12}>{warehouse.address?.line2}</Text>
            <Text fontSize={12}>{warehouse.address?.postcode}</Text>
            <Text fontSize={12}>
              {warehouse.address?.town}, {warehouse.address?.country}
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-start" gap="0.375rem">
            <Box
              w="1.25rem"
              h="1.25rem"
              color="white"
              bg="szrNavy.500"
              borderRadius="0.188rem"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Text>{warehouse.currency?.symbol || '£'}</Text>
            </Box>
            <UKFlag />
          </Box>
        </Box>
        {isPostMVP && <PostMVPInformation />}
        <Box
          display="flex"
          justifyContent={isPostMVP ? 'space-between' : 'flex-end'}
          alignItems="flex-end"
          mt="1.5rem">
          {isPostMVP && (
            <Link>
              <Text as="u" fontSize={12} color="main.500">
                View full details
              </Text>
            </Link>
          )}
          <Box>
           {children || <ActionButton showModal={showModal} getAccessRequest={getAccessRequest} />}
          </Box>
        </Box>       
        <Box display="flex" flexDirection="column" justifyContent="space-between" gap="0.375rem" mt="0.150rem">
          <Box display="flex" flexDirection="row" justifyContent="space-between" gap="0.375rem">
            <HStack fontSize="0.2rem" mt="0.2rem" mb="2px">
              <Input
                hidden
                name="WarehouseID" 
                value={warehouse.id}/>
              <Input 
                hidden
                name="WarehouseName" 
                value={warehouse.name}/>
            </HStack>
          </Box>
          {showExtWarehouse(warehouse)}       
          <Box display="flex" flexDirection="row">
            <SlzButton
              marginLeft="auto"
              type="submit"
              sx={{ _disabled: SELECTED_STYLES[selectedWarehouse === warehouse?.id] }}
              isDisabled={!!selectedWarehouse}>
              Select
            </SlzButton> 
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};

export default WarehouseCard;
