import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const parts = ['steps', 'step', 'completedStep', 'currentStep', 'connector', 'completedIcon'];

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const stepsStyle = defineStyle({
  display: 'flex',
  alignItems: 'center',
  m: 0,
  pt: 2,
  pb: 8,
  fontSize: '0.875rem'
});

const stepStyle = defineStyle({
  listStyleType: 'none',
  rounded: 'full',
  textAlign: 'center',
  cursor: 'pointer',
  pos: 'relative',
  zIndex: 1,
  transition: 'all 0.4s',
  border: '2px solid',
  _before: {
    content: '""',
    pos: 'absolute',
    zIndex: 1,
    rounded: 'full',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'all 0.4s',
    border: '2px solid',
    opacity: 0
  }
});

const completedStepStyle = defineStyle({
  ...stepStyle
});

const currentStepStyle = defineStyle({
  ...stepStyle,
  _before: {
    ...stepStyle._before,
    opacity: 1
  }
});

const connectorStyle = defineStyle({
  flex: 1,
  pos: 'relative',
  zIndex: 0,
  transition: 'all 0.4s'
});

const baseStyle = definePartsStyle((props) => ({
  steps: stepsStyle,
  step: stepStyle,
  completedStep: completedStepStyle,
  currentStep: currentStepStyle,
  connector: connectorStyle
}));

const variants = {
  primary: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    return {
      step: {
        bgColor: `white`,
        color: 'dark.700',
        borderColor: 'light.700'
      },
      completedStep: {
        bgColor: `${c}.500`,
        color: 'white',
        borderColor: `${c}.500`
      },
      currentStep: {
        bgColor: `${c}.500`,
        boxShadow: '0 0 0 5px white',
        color: 'white',
        borderColor: `${c}.500`,
        _before: {
          borderColor: `${c}.500`,
          boxShadow: `0 3px 6px #00000030`
        }
      },
      connector: {
        bgColor: 'light.700'
      }
    };
  })
};

const sizes = {
  md: definePartsStyle({
    steps: {
      minW: '10rem'
    },
    step: {
      w: 9,
      h: 9,
      lineHeight: 8
    },
    completedStep: {
      w: 9,
      h: 9,
      lineHeight: 8
    },
    currentStep: {
      w: 9,
      h: 9,
      lineHeight: 8,
      _before: {
        w: 12,
        h: 12
      }
    },
    connector: {
      h: 1
    },
    completedIcon: {
      w: '1.5rem',
      h: '1.125rem'
    }
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'primary',
    size: 'md',
    colorScheme: 'main'
  }
});
