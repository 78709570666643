import React from 'react';
import { Icon } from '@chakra-ui/react';

const EbayShortIcon = (props) => {
  return (
    <Icon
      id="EBay_logo"
      data-name="Path 9687"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 74.873 30"
      {...props}>
      <g id="EBay_logo" transform="translate(-0.1 -0.1)">
        <path
          id="e"
          d="M9.865,26.308C4.539,26.308.1,28.568.1,35.386c0,5.4,2.985,8.8,9.9,8.8,8.143,0,8.665-5.364,8.665-5.364H14.722s-.846,2.888-4.96,2.888C6.411,41.712,4,39.449,4,36.276H19.08V34.285c0-3.139-1.992-7.977-9.215-7.977Zm-.138,2.544c3.189,0,5.364,1.954,5.364,4.882h-11C4.088,30.626,6.927,28.853,9.727,28.853Z"
          transform="translate(0 -19.744)"
          fill="#e53238"
        />
        <path
          id="b"
          d="M75.184.1V21.157c0,1.2-.085,2.874-.085,2.874h3.763S79,22.826,79,21.724c0,0,1.859,2.909,6.915,2.909,5.324,0,8.94-3.7,8.94-8.991a8.5,8.5,0,0,0-8.931-8.888c-5.253,0-6.885,2.837-6.885,2.837V.1Zm9.765,9.258c3.615,0,5.914,2.683,5.914,6.284,0,3.861-2.655,6.387-5.888,6.387-3.858,0-5.94-3.013-5.94-6.352,0-3.112,1.868-6.318,5.914-6.318Z"
          transform="translate(-56.326 0)"
          fill="#0064d2"
        />
        <path
          id="a"
          d="M161.644,26.308c-8.013,0-8.527,4.388-8.527,5.089H157.1s.209-2.562,4.264-2.562c2.635,0,4.676,1.206,4.676,3.524v.825h-4.676c-6.208,0-9.49,1.816-9.49,5.5,0,3.627,3.032,5.6,7.13,5.6,5.585,0,7.384-3.086,7.384-3.086,0,1.227.095,2.437.095,2.437h3.546s-.138-1.5-.138-2.458V32.889c0-5.436-4.385-6.58-8.252-6.58Zm4.4,9.352v1.1c0,1.435-.886,5-6.1,5-2.855,0-4.079-1.425-4.079-3.077C155.867,35.68,159.989,35.661,166.045,35.661Z"
          transform="translate(-113.976 -19.745)"
          fill="#f5af02"
        />
        <path
          id="y"
          d="M214.879,29.041h4.487l6.44,12.9,6.425-12.9H236.3l-11.7,22.968h-4.264l3.377-6.4Z"
          transform="translate(-161.323 -21.909)"
          fill="#86b817"
        />
      </g>
    </Icon>
  );
};

export default EbayShortIcon;
