import { useState } from 'react';

import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';

import { PreferenceHeader as ReturnSettingHeader } from 'modules/preferences/components/common';
import { RETURN_SETTINGS_PREFERENCES_TEXT } from 'modules/preferences/constants';
import {
  EnableReturnsSettingsForm,
  ProductsReturnsSettingForm,
  ReturnSettingGradeForm
} from 'modules/preferences/components/form';
import { useReturnSettingsEnableQuery } from 'modules/preferences/hooks';

const ReturnSetting = ({ activatedSetting }) => {
  const { header } = RETURN_SETTINGS_PREFERENCES_TEXT;
  const { data, isFetching: isFetchingEnable } = useReturnSettingsEnableQuery();

  const [isEnable, setIsEnable] = useState(data?.enabled);

  const handleOnToggle = (isToggle) => {
    setIsEnable(isToggle);
  };

  return (
    <PreferenceSkeleton isLoaded={!isFetchingEnable} template={activatedSetting}>
      <ReturnSettingHeader header={header} />
      <EnableReturnsSettingsForm
        onToggle={handleOnToggle}
        enabled={data?.enabled}
        isFetching={isFetchingEnable}
      />
      <ProductsReturnsSettingForm disabled={!isEnable} />
      <ReturnSettingGradeForm disabled={!isEnable} />
    </PreferenceSkeleton>
  );
};

export default ReturnSetting;
