import React, { forwardRef, Fragment, memo, useCallback, useState } from 'react';

import { Flex, Td, Tr } from '@chakra-ui/react';
import { CELL_MARKED_WIDTH } from '../Layout/Constants';

import { skeletonAnimation as animation } from '../Skeleton/config';
import { Skeleton } from '../Skeleton/Skeleton';
import SlzCell from './Cell';
import { ACCORDION_COL_SPAN } from './Constants';
import MenuOnRow from './MenuOnRow';
import SkeletonCell from './SkeletonCell';
import SlzAccordionCell from './SlzAccordion/SlzAccordionCell';
import SlzAccordionMenu from './SlzAccordion/SlzAccordionMenu';
import { getFlagStyle } from './Styles';
import { useTableContext } from './TableContext';
import { isCustomCell } from './utils';

const AccordionIconCell = ({ row, rowIndex, rowProps, isLoading, setRows }) =>
  isLoading ? (
    <SkeletonCell />
  ) : (
    <SlzAccordionMenu
      row={row}
      onClick={() => {
        if (rowProps?.hasExpandedRow) {
          setRows &&
            setRows((rows) => {
              const newRows = [...rows];
              newRows[rowIndex] = { ...newRows[rowIndex], isExpand: !newRows[rowIndex].isExpand };
              return newRows;
            });
        }
      }}
    />
  );

const AccordionTemplate = ({ row, rowProps, isLoading }) => (
  <Tr id={row?.id} className={`expand-row`}>
    {isLoading ? (
      <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
    ) : (
      <Td colSpan={ACCORDION_COL_SPAN} sx={{ padding: 0 }}>
        {rowProps?.accordionProps?.template && rowProps?.accordionProps?.template({ row })}
      </Td>
    )}
  </Tr>
);

const Row = memo(
  forwardRef(
    ({
      row,
      rowIndex,
      showMarked,
      markedWidth = CELL_MARKED_WIDTH,
      isChild,
      activeRowId,
      onClick,
      setRows
    }) => {
      const {
        targetField,
        actions,
        displayFields,
        columns,
        rowProps,
        hyperLinks,
        variant,
        isLoading = false
      } = useTableContext();
      const [hoveredRows, setHoveredRows] = useState({});
      const handleMouseEnterEvent = (rowId) => {
        setHoveredRows((prev) => ({
          ...prev,
          [rowId]: true
        }));
      };

      const handleMouseLeaveEvent = (rowId) => {
        setHoveredRows((prev) => ({
          ...prev,
          [rowId]: false
        }));
      };

      const showMarkedElm = useCallback(() => {
        const defaultColor = rowProps?.accordionProps?.markerColor ?? row?.tag?.color;
        const width = rowProps?.accordionProps?.markedWidth ?? markedWidth;
        let styles = getFlagStyle({
          defaultColor,
          row,
          cols: columns || [],
          targetField,
          type: variant,
          markedWidth: width
        });

        if (isLoading) {
          styles.borderBottomColor = 'white !important';
          styles.p = '0 !important';
        }

        return (
          <Td sx={styles} className="marked-left-td">
            {isLoading ? (
              <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
            ) : (
              <Flex className="marked-left"></Flex>
            )}
          </Td>
        );
      }, [isLoading, row?.tag?.color]);

      return (
        <Fragment>
          {/** Show normal row for table **/}
          <Tr
            key={row?.id}
            ref={row.ref}
            className={!rowProps || rowProps?.hasExpandedRow ? 'parent' : 'child'}
            data-row-id={`${row?.id}`}
            onMouseEnter={() => handleMouseEnterEvent(row?.id)}
            onMouseLeave={() => handleMouseLeaveEvent(row?.id)}
            onClick={() => onClick(row?.id, row)}
            sx={{
              td: { bg: row?.id === activeRowId ? 'main.300' : '' }
            }}>
            {showMarked && showMarkedElm()}
            {/* {showImage && showImageElm()} */}
            {columns?.map(
              (col) =>
                (displayFields?.includes(col?.field) || isCustomCell(col)) && (
                  <SlzCell
                    key={`[slz-cell]-${row?.id}-${col?.field}`}
                    row={row}
                    col={col}
                    field={col?.field}
                    hyperLinks={hyperLinks}
                    render={col?.render}>
                    {isChild && (
                      <SlzAccordionCell
                        col={col}
                        row={row}
                        isLoading={isLoading}
                        displayFields={displayFields}
                      />
                    )}
                  </SlzCell>
                )
            )}
            {/** Show accordion up/down icon for accordion cell **/}
            {rowProps?.hasExpandedRow && (
              <AccordionIconCell
                row={row}
                rowIndex={rowIndex}
                rowProps={rowProps}
                setRows={setRows}
                variant={variant}
                isLoading={isLoading}
              />
            )}
            {/** Show menu actions on cell **/}
            {actions?.list?.length && (
              <MenuOnRow actions={actions} row={row} hoveredRows={hoveredRows} />
            )}
          </Tr>

          {/** Show sub row for accordion **/}
          {rowProps?.hasExpandedRow && (
            <AccordionTemplate row={row} rowProps={rowProps} isLoading={isLoading} />
          )}
        </Fragment>
      );
    }
  )
);

export default Row;
