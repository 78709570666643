import dayjs from 'dayjs';
import sample from 'lodash/sample';

import { DATE_FORMAT_DDMMYYYY } from 'constants/date';
import { ORDER_STATUS } from 'modules/b2c-orders/constants';
import { ORDER_STATUS_COLOR } from 'constants/common';
import { renderTrackingRef } from 'modules/b2c-orders/mappers/common';

// fake source
const sources = [
  {
    type: 'OpenAPI',
    name: 'B2C Order'
  },
  {
    type: 'WooCommerce',
    name: 'Store Name'
  },
  {
    type: 'CSV',
    name: 'B2C Order'
  },
  {
    type: 'Shopify',
    name: 'Store 1'
  },
  {
    type: 'Shopify',
    name: 'Store 2'
  },
  {
    type: 'Synergy',
    name: 'Synergy'
  }
];

const ORDER_DATA_MAPPERS = {
  [ORDER_STATUS.unsuccessful.key]: (order, status) => ({
    id: order.id,
    customerReference: order.identifier,
    customerOrganizationPersonName: order.sourceDisplay,
    status: {
      key: order.statusID,
      name: order.status,
      color: ORDER_STATUS_COLOR[status] //mock
    },
    trackingCode: renderTrackingRef(order),
    source: sources.find((source) => source.type === order.source) ?? sample(sources), // mock
    createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY),
    orderContents: []
  }),
  [ORDER_STATUS.onhold.key]: (order, status) => ({
    id: order.orderID,
    customerReference: order.customerReference,
    status: {
      key: order.status?.id,
      name: order.status === 'Pending' ? 'Awaiting stock' : order.status,
      color: order.status === 'Pending' ? ORDER_STATUS_COLOR.pending : ORDER_STATUS_COLOR[status] //mock
    },
    source: sources.find((source) => source.type === order.source) ?? sample(sources), //mock
    createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY)
  }),
  [ORDER_STATUS.pending.key]: (order, status) => ({
    id: order?.id,
    customerReference: order.identifier,
    customerOrganizationPersonName: order.sourceDisplay,
    status: {
      key: order?.statusID,
      name: order?.status,
      color: ORDER_STATUS_COLOR[status]
    },
    source: sources.find((source) => source.type === order.source) ?? sources[2], //mock CSV type for pending status
    createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY)
  }),
  [ORDER_STATUS.partial.key]: (order, status) => ({
    id: order.id,
    customerReference: order.customerReference,
    customerOrganizationPersonName: order.customerOrganisationPersonName,
    status: {
      key: order?.statusID,
      name: order.containsCancelledPartialOrder ? 'Part Cancellation' : 'Awaiting Stock',
      color: ORDER_STATUS_COLOR[status]
    },
    source: sources.find((source) => source.type === order.source) ?? sample(sources), //mock
    createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY)
  })
};

const DEFAULT_ORDER_MAPPER = (order, status) => ({
  id: order.id,
  customerReference: order.customerReference,
  customerOrganizationPersonName: order.customerOrganisationPersonName,
  status: {
    key: order.status?.id,
    name: order.status?.name,
    color: status ? ORDER_STATUS_COLOR[status] : getStatusColor(order.status?.name) //mock
  },
  trackingCode: renderTrackingRef(order),
  source: sources.find((source) => source.type === order.source) ?? sample(sources), //mock
  createdDate: dayjs(order.createdDate).format(DATE_FORMAT_DDMMYYYY),
  orderContents: []
});

export const transformOrders = (data, status) => {
  const {
    orders = (data.length !== undefined ? data : data.orders) || [],
    pageCount = (data.length !== undefined ? 1 : pageCount) || 0
  } = data;
  const mappingData = {
    orders,
    totalPage: pageCount
  };

  mappingData.orders = mappingData.orders.map((order) => {
    const mapper = ORDER_DATA_MAPPERS[status] || DEFAULT_ORDER_MAPPER;
    return mapper(order, status);
  });

  return mappingData;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'Awaiting Collection':
    case 'Collection Booked':
    case 'Shipped':
    case 'Part Fulfilled':
      return '#3CD1CA';
    case 'Received':
      return '#579BFC';
    case 'Awaiting Fulfilment':
    case 'Fulfilment In Progress':
    case 'Picking In Progress':
    case 'Packing In Progress':
      return '#23D57E';
    case 'Processing Failed':
      return '#d0b8fd';
    case 'Processing':
    case 'Order Queued':
      return '#FFAD3B';
    default:
      return '#CED3DD';
  }
};
