import { Box, Flex, Text } from '@chakra-ui/react';
import { SlzBreadcrumb } from 'components/common/SlzBreadcrumb';
import { useGlobalState } from 'contexts/GlobalContext';
import { UserNavigation } from 'modules/users/components/other';

const Header = ({ isExpanded }) => {
  const [{ app }] = useGlobalState();

  const headerStyle = { height: '96px', pl: isExpanded ? '280px' : '120px', pr: '24px' };

  return (
    <Box
      as="nav"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bg="light.700"
      sx={headerStyle}>
      <Flex alignItems="center">
        <Flex flexDirection="column">
          <SlzBreadcrumb items={app?.breadcrumb} />
          <Text fontSize="17px" color="dark.700">
            Administrator
          </Text>
        </Flex>
      </Flex>

      <UserNavigation />
    </Box>
  );
};

export default Header;
