import OrderDetail from './OrderDetail';

const OrderSingleShipment = (props) => {
  return (
    <OrderDetail {...props}>
      <OrderDetail.header isShowCourier isShowTracking />
      <OrderDetail.stepper />
      <OrderDetail.nav />
      <OrderDetail.footer />
      <OrderDetail.table />
    </OrderDetail>
  );
};

export default OrderSingleShipment;
