import CommonEndpoints from 'components/common/Endpoints';
import { BULK_ORDER_ENDPOINT_MAPPING, BULK_ORDER_TAB } from '../constants';
import { buildQuery } from 'utils/helper';
import axiosClient from 'config/axios';

export async function getBulkOrders(filters) {
  const tab = filters?.tab ?? BULK_ORDER_TAB.pending.key;
  const url = new URL(BULK_ORDER_ENDPOINT_MAPPING[tab]);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function getBulkOrderDetail(orderId) {
  const url = `${CommonEndpoints.RETAILER.GET.BULK_ORDER_DETAILS}${orderId}`;
  return await axiosClient.request(url);
}

export async function updateBulkOrderTracking(payload) {
  const url = new URL(CommonEndpoints.RETAILER.POST.BULK_ORDER_UPDATE_PAYLOAD);
  return await axiosClient.post(url, payload);
}

export async function getProducts(filters) {
  let url = new URL(CommonEndpoints.STOCK.GET.BY);

  return await axiosClient.request(buildQuery(url, filters));
}

export async function createBulkOrder(payload) {
  const url = new URL(CommonEndpoints.RETAILER.POST.BULK_ORDER_SUBMIT);
  return await axiosClient.post(url, payload);
}

export async function getBulkOrderLimit() {
  const url = `${CommonEndpoints.INTERNAL.GET.BULK_ORDER_LIMITS}`;
  return await axiosClient.request(url);
}

export async function validateManualSplit(payload) {
  const url = new URL(CommonEndpoints.RETAILER.POST.BULK_ORDER_MANUAL_SPLIT_VALIDATE);
  return await axiosClient.post(url, payload);
}

export async function validateSkuSplit({ isAutoSplit, selectedItems }) {
  const url = new URL(
    `${CommonEndpoints.RETAILER.POST.BULK_ORDER_SKU_SPLIT_VALIDATE}${isAutoSplit}`
  );
  const res = await axiosClient.post(url, { items: selectedItems });
  return res?.data?.data;
}

export async function validateNoPacking(payload) {
  const url = new URL(`${CommonEndpoints.RETAILER.POST.BULK_ORDER_NO_PACKAGING_VALIDATE}`);
  const res = await axiosClient.post(url, payload);
  return res?.data?.data;
}

export async function validateSelectedStock(payload) {
  const url = new URL(CommonEndpoints.RETAILER.POST.BULK_ORDER_SELECTED_ITEMS_VALIDATE);
  return await axiosClient.post(url, payload);
}
export async function findShippingOptions(payload) {
  const url = new URL(CommonEndpoints.INTERNAL.POST.BULK_ORDER_FIND_SHIPPING_OPTIONS);
  return await axiosClient.post(url, payload);
}

export async function getBulkOrderAvailableServiceTimes(country) {
  const url = `${CommonEndpoints.INTERNAL.GET.BULK_ORDER_AVAILABLE_SERVICE_TIMES}?country=${country}`;
  return await axiosClient.request(url);
}
