import { useQuery } from 'react-query';
import {
  getTodayOrderCountByStatus,
  getCountOrder,
  getOrderAverageCost
} from '../services/dashboard-api';
import { ORDERS_24HRS_PARAMS } from '../constants';

export const useOrdersStatistics24HoursQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: ['dashboard-24hrs'],
    queryFn: async () => {
      const results = await Promise.all(
        ORDERS_24HRS_PARAMS.map((status) => getTodayOrderCountByStatus(status))
      );

      return results.reduce((acc, result, idx) => {
        acc[ORDERS_24HRS_PARAMS[idx]] = result?.data?.data;
        return acc;
      }, {});
    },
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled
  });
};

export const useOrdersStatistics28DaysQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: ['dashboard-28days'],
    queryFn: async () => {
      const [total, averageCost] = await Promise.all([getCountOrder(), getOrderAverageCost()]);

      return {
        total: total?.data?.data,
        averageCost: averageCost?.data?.data
      };
    },
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled
  });
};
