import { Box, Icon, keyframes } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

const animationKeyframes = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.25); }
  100% { transform: scale(1); }
`;

const getSize = (size) => {
  if (size === 'sm') return '0.1875rem';
  if (size === 'lg') return '0.75rem';
  return '0.375rem';
};

const SlzIndicator = (props) => {
  const { color = 'main.500', isAnimated, duration = '1.2s', size } = props;

  return (
    <Box
      data-testid="indicator"
      id={uniqueId('SEL-Slz-Indicator')}
      as={motion.div}
      sx={{
        animationDuration: isAnimated ? duration : '0s',
        animationName: `${animationKeyframes}`,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite'
      }}
      color={color}
      w={getSize(size)}
      h={getSize(size)}>
      <Box w="full" h="full" bgColor="currentColor" rounded="full"></Box>
    </Box>
  );
};

export default SlzIndicator;

SlzIndicator.propTypes = {
  isAnimated: PropTypes.bool,
  duration: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};
