import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { useEffect, useState } from 'react';
import {
  CONSIGNMENT_LABEL_TEXT,
  PRODUCT_LABELS_TEXT,
  STOCK_BOX_LABEL_TEXT
} from 'modules/consignments/constants';
import { useConsignmentLabelsQuery } from 'modules/consignments/hooks/useConsignmentLabelsQuery';
import { ChevronDownIcon } from '@chakra-ui/icons';

const ConsignmentInfoDetails = (props) => {
  const { consignmentInfoDetails, isLoading } = props;

  const [downloadableLabelsOptions, setDownloadableLabelsOptions] = useState([]);
  const [labelOption, setLabelOption] = useState(null);
  const [uniqueIdentifier, setUniqueIdentifier] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (consignmentInfoDetails) getDownloadableLabels(consignmentInfoDetails);
  }, [consignmentInfoDetails]);

  const {
    data,
    isLoading: isFetchingLabels,
    isSuccess
  } = useConsignmentLabelsQuery(
    consignmentInfoDetails?.id,
    labelOption,
    uniqueIdentifier,
    (blob) => blob
  );

  useEffect(() => {
    if (isSuccess && data) {
      const a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [isSuccess, data]);

  const getLabel = (option) => {
    setUniqueIdentifier(Date.now());
    setLabelOption(option);
  };

  const getDownloadableLabels = ({
    pickFromPallet,
    hasProductsWithoutGtin,
    hasProductsWithSelazarUniqueID
  }) => {
    let options = [{ label: CONSIGNMENT_LABEL_TEXT, value: 'ConsignmentLabelUrl' }];
    if (!pickFromPallet && hasProductsWithoutGtin)
      options.push({ label: STOCK_BOX_LABEL_TEXT, value: 'BoxLabelsUrl' });
    if (hasProductsWithSelazarUniqueID)
      options.push({ label: PRODUCT_LABELS_TEXT, value: 'ItemLabelsUrl' });

    setDownloadableLabelsOptions([...options]);
  };

  return (
    <SlzPaper w={props.w} h={props.h} bg={props.bg}>
      <Flex
        h={props.h}
        justifyContent="space-between"
        alignItems="center"
        borderLeftWidth="0.75rem"
        borderRadius="0.375rem"
        borderLeftColor={consignmentInfoDetails?.status?.color}>
        {isLoading ? (
          <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
        ) : (
          <>
            <Box w="full" display="flex" justifyContent="space-between">
              <HStack>
                <Box w="19.125rem" mx={5} justifyContent="space-between">
                  <Text fontSize="1rem" fontWeight="bold">
                    {consignmentInfoDetails?.fileName}
                  </Text>
                  <Text fontSize="0.875rem">{consignmentInfoDetails?.createdDate}</Text>
                </Box>
                <Box position="relative">
                  <Text position="absolute" top="-1.688rem" fontSize="1rem" fontWeight="bold">
                    Status
                  </Text>
                  <Text
                    h="1.5rem"
                    px="1.5rem"
                    color="light.500"
                    rounded="0.188rem"
                    bgColor={consignmentInfoDetails?.status?.bgColor}
                    display="flex"
                    alignItems="center">
                    {consignmentInfoDetails?.status?.name}
                  </Text>
                </Box>
              </HStack>

              <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <PopoverTrigger mr="2rem">
                  <Button
                    mr="2rem"
                    rightIcon={
                      <Icon
                        as={ChevronDownIcon}
                        transition="transform 0.3s ease"
                        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                      />
                    }>
                    Download your labels
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverBody>
                    {downloadableLabelsOptions.map((option) => (
                      <Button
                        isLoading={isFetchingLabels}
                        key={option.value}
                        onClick={() => getLabel(option.value)}
                        justifyContent="flex-start"
                        color="dark.700"
                        bgColor="light.300"
                        sx={{ _hover: { bgColor: 'main.300' } }}
                        w="full">
                        {option.label}
                      </Button>
                    ))}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </>
        )}
      </Flex>
    </SlzPaper>
  );
};

export default ConsignmentInfoDetails;
