import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

const B2BProductInformationCell = ({ row, ...rest }) => {
  return (
    <Box
      id={`product-selection-${row?.id}`}
      sx={{
        paddingLeft: '0.5rem',
        '.wrapName': { alignItems: 'start', justifyContent: 'start' },
        '.innerName': { alignItems: 'start' },
        ...rest
      }}>
      <HStack className="wrapName" pos="relative" w="full">
        <VStack
          className="innerName"
          w="full"
          fontSize="0.625rem"
          lineHeight="0.813rem"
          whiteSpace="nowrap"
          overflow="hidden"
          maxW="15.625rem"
          spacing="1px"
          gap={0}
          alignItems="start">
          <Text color="#0b73ea" mb={0}>
            {row?.name}
          </Text>
          <Flex
            sx={{
              textTransform: 'uppercase',
              alignItems: 'center',
              justifyContent: 'start',
              w: '80%'
            }}>
            <Text fontWeight="bold" mb={0} noOfLines={1}>
              SKU
            </Text>
            &nbsp;
            <Text mb={0} noOfLines={1}>
              {row?.sku}
            </Text>
          </Flex>
          <Flex
            sx={{
              textTransform: 'uppercase',
              alignItems: 'center',
              justifyContent: 'start',
              w: '80%'
            }}>
            <Text fontWeight="bold" mb={0} noOfLines={1}>
              EAN/SUID
            </Text>
            &nbsp;
            <Text mb={0} noOfLines={1}>
              {row?.ean}
            </Text>
          </Flex>
        </VStack>
      </HStack>
    </Box>
  );
};

export default B2BProductInformationCell;
