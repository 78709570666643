import { Card, FormControl, FormErrorMessage, HStack, Text, VStack } from '@chakra-ui/react';
import { SingleProductIcon, ConsignmentTypeIcon, MultipleProductsIcon } from './assets';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import SlzPaper from 'components/common/SlzPaper/SlzPaper';
import { SEND_CONSIGNMENT_TYPES } from 'modules/consignments/constants';

const getPaperStyles = {
  w: '21.063rem',
  h: '10.25rem',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  bgColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
  _hover: {
    borderColor: 'main.500',
    borderWidth: 2
  }
};

const getPaperDefaultStyles = {
  borderColor: 'main.500',
  borderWidth: 2
};

const getPaperErrorStyles = {
  borderColor: 'negative.500',
  borderWidth: 2
};

const activeColors = {
  [SEND_CONSIGNMENT_TYPES.SINGLE]: {
    [SEND_CONSIGNMENT_TYPES.SINGLE]: 'main.500',
    [SEND_CONSIGNMENT_TYPES.MULTIPLE]: 'dark.500'
  },
  [SEND_CONSIGNMENT_TYPES.MULTIPLE]: {
    [SEND_CONSIGNMENT_TYPES.SINGLE]: 'dark.500',
    [SEND_CONSIGNMENT_TYPES.MULTIPLE]: 'main.500'
  }
};

const SelectConsignmentType = () => {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const [selectedValue, setSelectedValue] = useState(null);

  const handleConsignmentTypeClick = (value) => {
    setValue('consignmentType', value);
    clearErrors('consignmentType');
  };

  useEffect(() => {
    setSelectedValue(watch('consignmentType'));
  }, [watch('consignmentType')]);

  return (
    <VStack w="51.625rem" mb="3rem" alignItems="left">
      <Card
        bg="light.500"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem">
          <ConsignmentTypeIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Text
            sx={{
              textAlign: 'left',
              lineHeight: '20px',
              letterSpacing: '-0.14px',
              fontSize: '16px',
              color: 'table.mainText'
            }}>
            <b>What type of consignment are you sending?</b>
          </Text>
        </HStack>
      </Card>
      <FormControl sx={{ marginTop: '0.3rem !important' }} isInvalid={errors.consignmentType}>
        <Card
          bg="light.500"
          w="51.625rem"
          h="16.688rem"
          variant="filled"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            ...(errors?.consignmentType && getPaperErrorStyles)
          }}>
          <HStack align="baseline" sx={{ justifyContent: 'space-evenly' }}>
            <VStack>
              <SlzPaper
                sx={{
                  ...getPaperStyles,
                  ...(errors?.consignmentType && getPaperErrorStyles),
                  ...(selectedValue === SEND_CONSIGNMENT_TYPES.SINGLE && getPaperDefaultStyles)
                }}
                size="lg"
                {...register('consignmentType')}
                onClick={() => handleConsignmentTypeClick(SEND_CONSIGNMENT_TYPES.SINGLE)}>
                <SingleProductIcon
                  sx={{
                    width: '5.25rem',
                    height: '5.25rem',
                    color: `${
                      activeColors[SEND_CONSIGNMENT_TYPES.SINGLE][selectedValue] || 'dark.500'
                    }`
                  }}
                />
              </SlzPaper>
              <Text
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  letterSpacing: '0px',
                  color: `${
                    activeColors[SEND_CONSIGNMENT_TYPES.SINGLE][selectedValue] || 'dark.500'
                  }`
                }}>
                Single product consignment
              </Text>
            </VStack>
            <VStack>
              <SlzPaper
                sx={{
                  ...getPaperStyles,
                  ...(errors?.consignmentType && getPaperErrorStyles),
                  ...(selectedValue === SEND_CONSIGNMENT_TYPES.MULTIPLE && getPaperDefaultStyles)
                }}
                size="lg"
                {...register('consignmentType')}
                onClick={() => handleConsignmentTypeClick(SEND_CONSIGNMENT_TYPES.MULTIPLE)}>
                <MultipleProductsIcon
                  sx={{ width: '5.25rem', height: '5.25rem' }}
                  color={`${
                    activeColors[SEND_CONSIGNMENT_TYPES.MULTIPLE][selectedValue] || 'dark.500'
                  }`}
                />
              </SlzPaper>
              <Text
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  letterSpacing: '0px',
                  color: `${
                    activeColors[SEND_CONSIGNMENT_TYPES.MULTIPLE][selectedValue] || 'dark.500'
                  }`
                }}>
                Multiple product consignment
              </Text>
            </VStack>
          </HStack>
        </Card>
        <FormErrorMessage fontSize="lg">{errors.consignmentType?.message}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default SelectConsignmentType;
