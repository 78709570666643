import { useEffect, useState } from 'react';
import { MatchedIcon } from '../components/other/MatchedIcon';
import {
  AVAILABLE_COUNTRY_REGIONS,
  SERVICE_TIMES_ORDERED_LABEL,
  SFP,
  SFP_VALUE
} from '../constants';
import { transformShippingRates, sortServiceTimes } from '../utils';

export const useRateMatcherUtils = (props) => {
  const {
    integrationId,
    shippingRates,
    shopifyShippingRatePreferences,
    fetchedCurrentPreferences,
    fetchedServiceTimes
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [regionPreferences, setRegionPreferences] = useState({});

  const getAvailableRegionServiceTimes = (availableRegion) => {
    const currentRegionPreference = fetchedCurrentPreferences.find(
      (preference) => preference.countryRegion === availableRegion
    );

    if (!currentRegionPreference) {
      return [];
    }

    const availableRegionServiceTimes = currentRegionPreference?.preferences?.flatMap(
      (preference) => preference?.ServiceTimes
    );

    return availableRegionServiceTimes;
  };

  const checkRegionServiceTimeValid = (availableRegion, region) => {
    const availableRegionServiceTimes = getAvailableRegionServiceTimes(availableRegion);

    const regionServiceTimesList = fetchedServiceTimes
        .find((serviceTime) => serviceTime.countryRegion === availableRegion)
        .availableServiceTimeOptions.filter((service) => service.companyName !== SFP)
        .flatMap((m) => m.serviceTimes),
      regionServiceTimes = Object.assign({}, ...regionServiceTimesList);

    let uniqueRegionServiceTimes = Object.keys(regionServiceTimes);
    if (availableRegionServiceTimes) {
      uniqueRegionServiceTimes = uniqueRegionServiceTimes.filter((uniqueServiceTime) =>
        availableRegionServiceTimes.includes(uniqueServiceTime)
      );
    }

    if (!uniqueRegionServiceTimes.includes(region.serviceTime)) {
      region.serviceTime = null;
    }

    return [regionServiceTimes, region];
  };

  const getRegionPreference = (availableRegion) =>
    shopifyShippingRatePreferences.find(
      ({ shippingRateID, countryRegion }) =>
        null === shippingRateID && countryRegion === availableRegion
    );

  const getUniqueServiceKeys = ({ serviceTimes = [], serviceTime }) => {
    let availableServiceTimes;
    let isSFP = false;

    const currentRegionPreference = fetchedCurrentPreferences.find(
      (c) => c.countryRegion === serviceTime.countryRegion
    );
    if (currentRegionPreference) {
      availableServiceTimes = currentRegionPreference.preferences.flatMap((p) => p.ServiceTimes);
      isSFP =
        currentRegionPreference.preferences
          .flatMap((p) => p.ServiceTimes)
          .findIndex((s) => s === SFP_VALUE) > -1;
    }

    let uniqueServiceTimeKeys = Object.keys(serviceTimes);

    if (availableServiceTimes) {
      uniqueServiceTimeKeys = uniqueServiceTimeKeys.filter((u) =>
        availableServiceTimes.includes(u)
      );
    }

    uniqueServiceTimeKeys = sortServiceTimes(uniqueServiceTimeKeys);
    return {
      uniqueServiceTimeKeys,
      isSFP
    };
  };

  useEffect(() => {
    const isLoadedFullClues =
      shippingRates?.length && fetchedCurrentPreferences?.length && fetchedServiceTimes?.length;

    if (isLoadedFullClues) {
      const mappedShippingRatesFromIntegration = shippingRates.map((shippingRate) => {
        const foundRate = shopifyShippingRatePreferences?.find(
          (shopifyRate) => shopifyRate.shippingRateID === shippingRate.shippingRateID
        );

        return {
          ...shippingRate,
          countryRegion: foundRate?.countryRegion,
          serviceTime: foundRate?.serviceTime
        };
      });

      AVAILABLE_COUNTRY_REGIONS.map((availableRegion) => {
        const regionPreference = getRegionPreference(availableRegion);

        if (regionPreference) {
          const [regionServiceTimes, region] = checkRegionServiceTimeValid(
            availableRegion,
            regionPreference
          );
          const label =
            regionServiceTimes[region.serviceTime] ||
            SERVICE_TIMES_ORDERED_LABEL[region.serviceTime];
          const regionKey = `${region.countryRegion.toLowerCase()}DefaultShipping`;
          const regionDefaultShipping = {
            label,
            value: region,
            isMatched: true,
            showInputIcon: true,
            MatchedIcon
          };

          setRegionPreferences((prev) => ({
            ...prev,
            [regionKey]: regionDefaultShipping
          }));
        }
      });

      setRegionPreferences((prev) => ({
        ...prev,
        shippingRates: mappedShippingRatesFromIntegration
      }));

      setIsLoaded(true);
    }
  }, [
    integrationId,
    shippingRates,
    fetchedCurrentPreferences,
    fetchedServiceTimes,
    shopifyShippingRatePreferences
  ]);

  return {
    isLoaded,
    regionPreferences,
    getUniqueServiceKeys,
    getAvailableRegionServiceTimes
  };
};
