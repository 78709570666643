export const DEFAULT_TOAST_SETTINGS = {
  isClosable: true,
  position: 'top'
};

export const DEFAULT_SLZ_TOAST = {
  colorScheme: 'positive',
  status: 'success',
  variant: 'solid',
  w: '10rem'
};

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const CONFIRM_LEAVE_TOAST = 'confirmLeaveToast';
