import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function TwitterIcon(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20.69" {...props}>
      <path
        id="twitter-icon-2"
        data-name="twitter-icon"
        d="M8.038,21.08c9.434,0,14.594-7.959,14.594-14.861q0-.339-.015-.675a10.539,10.539,0,0,0,2.559-2.7,10.082,10.082,0,0,1-2.945.822A5.227,5.227,0,0,0,24.486.773a10.177,10.177,0,0,1-3.257,1.268A5.081,5.081,0,0,0,17.485.391a5.177,5.177,0,0,0-5.13,5.222A5.305,5.305,0,0,0,12.488,6.8,14.479,14.479,0,0,1,1.916,1.347,5.291,5.291,0,0,0,3.5,8.32a5.022,5.022,0,0,1-2.323-.653c0,.022,0,.043,0,.067a5.2,5.2,0,0,0,4.115,5.12,5.031,5.031,0,0,1-2.316.09A5.147,5.147,0,0,0,7.77,16.57,10.173,10.173,0,0,1,1.4,18.806a10.232,10.232,0,0,1-1.223-.072A14.33,14.33,0,0,0,8.038,21.08"
        transform="translate(-0.176 -0.391)"
        fill="currentColor"
      />
    </Icon>
  );
}
