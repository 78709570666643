import { Icon } from '@chakra-ui/react';
import React from 'react';

const ExclamationIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="3.351"
      height="14.193"
      viewBox="0 0 3.351 14.193"
      {...props}>
      <path
        id="Path_1322"
        data-name="Path 1322"
        d="M.117,12.079A1.117,1.117,0,0,1-1,10.962V3.908a1.117,1.117,0,0,1,2.234,0v7.054A1.117,1.117,0,0,1,.117,12.079Z"
        transform="translate(1.558 -2.791)"
        fill="#303134"
      />
      <circle
        id="Ellipse_157"
        data-name="Ellipse 157"
        cx="1.675"
        cy="1.675"
        r="1.675"
        transform="translate(0 10.843)"
        fill="#303134"
      />
    </Icon>
  );
};

export default ExclamationIcon;
