import { PAGE_INDEX } from 'constants/table.js';

export const calculateQuantitySelectedProducts = (selectedProducts = []) => {
  return selectedProducts.reduce(
    (accumulator, currentValue) => accumulator + (currentValue?.quantity || 0),
    0
  );
};

export const getCalculatedStock = (items) =>
  items.sort((a, b) => a?.companyItem?.stockCount - b?.companyItem?.stockCount)[0]?.companyItem
    ?.stockCount || 0;

export const transformProductPayload = (productOrigin, formValues) => {
  const stockKeepingUnits = [...productOrigin?.stockKeepingUnits];
  if (formValues?.sku) {
    stockKeepingUnits[0] = {
      ...stockKeepingUnits[0],
      code: formValues.sku,
      companyItemID: productOrigin?.id
    };
  }

  return {
    ...productOrigin,
    item: {
      ...productOrigin?.item,
      name: formValues?.name,
      brand: formValues?.brand,
      categoryID: formValues?.category?.value,
      description: formValues?.description,
      sku: formValues?.sku,
      ean: formValues?.ean,
      selazarUniqueID: formValues?.suid,
      width: formValues?.width,
      height: formValues?.height,
      depth: formValues?.depth,
      weight: formValues?.weight,
      commodityCode: formValues?.commodityCode,
      countryCodeOfManufacture: formValues?.countryCodeOfManufacture?.value
    },
    itemCustomTag: {
      ...productOrigin?.itemCustomTag,
      text: formValues?.tag?.text,
      colorCode: formValues?.tag?.colorCode
    },
    stockKeepingUnits: stockKeepingUnits,
    stockCount: formValues?.stockCount,
    currentPrice: { ...productOrigin?.currentPrice, price: formValues?.currentPrice },
    expectedWeeklySales: formValues?.units,
    isPrePacked: formValues?.isPrePacked,
    requiresBoxPackaging: !formValues?.isPrePacked ? formValues?.requiresBoxPackaging : false,
    requiresPaperPackaging: !formValues?.isPrePacked ? formValues?.requiresPaperPackaging : false,
    isFragile: formValues?.isFragile
  };
};

export const transformReturnSettingsPayload = (formValues) => {
  if (formValues?.returnable === undefined) return false;

  const grading = Object.keys(formValues?.grading).map((key) => {
    return {
      grade: key,
      action: formValues?.grading[key] && formValues?.grading[key].value
    };
  });

  return {
    returnable: formValues?.returnable,
    grading
  };
};

export const getPageIndex = (str) => {
  const params = new URLSearchParams(str);
  return params.get(PAGE_INDEX);
};
