import { defineStyleConfig, defineStyle } from '@chakra-ui/styled-system';

const baseStyle = defineStyle(() => ({
  borderRadius: '0.1875rem',
  _checked: {
    bg: 'main.500',
    color: 'light.300',
    boxShadow: 'md'
  },
  _focus: {
    bg: 'main.500',
    color: 'light.300',
    boxShadow: 'md'
  },
  borderRadius: '0.1875rem',
}));

const sizes = {
  sm: defineStyle({
    px: 2.5,
    my: '0.188rem',
    h: '1.5rem',
    borderRadius: 'sm'
  }),
  md: defineStyle({
    px: 2,
    my: '0.375rem',
    h: '2.25rem',
    borderRadius: 'md'
  })
};

const SlzRadioToggle = defineStyleConfig({
  baseStyle,
  sizes
});

export default SlzRadioToggle;

