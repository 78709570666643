import { Avatar, Box, HStack, Text, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

const getSpaceStyle = (size) => {
  if (size === 'sm') return 1.5;
  if (size === 'lg') return 5;
  return 2.5;
};

const SlzUserAvatar = (props) => {
  const { src, size, variant, title, subtitle, ...rest } = props;
  const styles = useMultiStyleConfig('SlzUserAvatar', { size, variant });

  return (
    <HStack id={uniqueId('SEL-Slz-UserAvatar')} spacing={getSpaceStyle(size)} {...rest}>
      <Avatar
        role="image"
        src={src}
        sx={styles.avatar}
        borderRadius={styles.avatar.borderRadius}
        size={styles.avatar.size}
        name={title}
      />
      <Box>
        <Text role="title" sx={styles.title}>
          {title}
        </Text>
        <Text role="subtitle" sx={styles.subtitle}>
          {subtitle}
        </Text>
      </Box>
    </HStack>
  );
};

export default SlzUserAvatar;
SlzUserAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['circle', 'square']),
  title: PropTypes.string,
  subtitle: PropTypes.string
};
