import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Box, Checkbox, CheckboxGroup, HStack, Icon, Image, Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { SlzToggle } from 'components/common/SlzToggle';
import { SlzSelect } from 'components/common/SlzSelect';
import { TRACKING_OPTIONS } from 'constants/common';

function CustomCheckbox({
  label,
  value,
  isTracked,
  isDisabledService,
  onSelectTracking,
  ...props
}) {
  const [isChecked, setIsChecked] = useState();
  const inputRef = useRef();
  const hasTracking = isTracked !== undefined;
  const isDisabled = isDisabledService || !isChecked;
  const defaultSelectValue = TRACKING_OPTIONS.find(({ value }) => value === isTracked);

  const handleOnChange = (e) => setIsChecked(e.target.checked);

  const handleOnSelect = ({ value: isTracked }) => {
    if (isTracked) {
      onSelectTracking({ time: value, isTracked });
      return;
    }
    onSelectTracking({ time: value });
  };

  useEffect(() => {
    setIsChecked(inputRef.current.checked);
  }, []);

  return (
    <HStack justifyContent="space-between">
      <Checkbox
        ref={inputRef}
        isReadOnly={isDisabledService}
        value={value}
        onChange={handleOnChange}
        {...props}>
        {label}
      </Checkbox>
      {hasTracking && (
        <SlzSelect
          size="xs"
          minW="110px"
          isDisabled={isDisabled}
          isSearchable={false}
          defaultValue={defaultSelectValue}
          options={TRACKING_OPTIONS}
          onChange={handleOnSelect}
        />
      )}
    </HStack>
  );
}

const ACTIONS = {
  UPDATE_STATUS: 'update_status',
  UPDATE_SERVICE_TIMES: 'update_service_times'
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.UPDATE_STATUS: {
      return {
        ...state,
        isActive: payload.isActive
      };
    }
    case ACTIONS.UPDATE_SERVICE_TIMES: {
      return {
        ...state,
        serviceTimes: payload.serviceTimes
      };
    }
    default:
      return state;
  }
}

export default function CourierCard({
  defaultSettings = {},
  isDisabled,
  name,
  region,
  onChange = () => {}
}) {
  const { getValues, watch } = useFormContext();
  const initialSettings = getValues(name);

  const [courier, dispatch] = useReducer(reducer, initialSettings || defaultValue);
  const { isActive, serviceTimes = [] } = courier;
  const { companyLogo, companyName } = initialSettings;

  const checkedServiceTimes = map(
    filter(initialSettings.serviceTimes, { isChecked: true }),
    'value'
  );

  const handleOnActive = (isActive) => {
    const updateServiceTimes = serviceTimes.map((serviceTime) => ({
      ...serviceTime,
      isChecked: isActive
    }));

    dispatch({
      type: ACTIONS.UPDATE_STATUS,
      payload: {
        isActive,
        serviceTimes: updateServiceTimes
      }
    });
    onChange({
      ...courier,
      isActive,
      serviceTimes: updateServiceTimes
    });
  };

  const handleOnChangeCheckbox = (values) => {
    const updatedServiceTimes = serviceTimes.map((serviceTime) => {
      const selected = values.includes(serviceTime.value);
      if (selected) {
        return { ...serviceTime, isChecked: true };
      }
      return { ...serviceTime, isChecked: false };
    });

    dispatch({
      type: ACTIONS.UPDATE_SERVICE_TIMES,
      payload: { serviceTimes: updatedServiceTimes }
    });

    onChange({ ...courier, serviceTimes: updatedServiceTimes });
  };

  const handleOnSelectTracking = ({ time, isTracked }) => {
    const updatedServiceTimes = serviceTimes.map((serviceTime) => {
      if (serviceTime.isTracked === undefined || serviceTime.value !== time) return serviceTime;
      if (isTracked) {
        return { ...serviceTime, isTracked: true };
      }
      return { ...serviceTime, isTracked: false };
    });

    dispatch({
      type: ACTIONS.UPDATE_SERVICE_TIMES,
      payload: { serviceTimes: updatedServiceTimes }
    });
    onChange({ ...courier, serviceTimes: updatedServiceTimes });
  };

  useEffect(() => {
    dispatch({
      type: ACTIONS.UPDATE_STATUS,
      payload: {
        isActive: initialSettings.serviceTimes.some((serviceTime) => serviceTime.isChecked)
      }
    });
    onChange(initialSettings);
  }, [initialSettings]);

  return (
    <Box rounded="md" boxShadow="slz-md" w="fit-content" minW={32}>
      <HStack
        spacing={5}
        justifyContent="space-between"
        alignItems="center"
        p={3}
        roundedTop="md"
        bg="light.500">
        {/* wait for an API to return */}
        {/* <Image src={companyLogo} alt={companyName} h={5} w="auto" /> */}
        <Icon h={5} w="auto" as={companyLogo} />
        <SlzToggle
          mb={0}
          isChecked={isActive}
          isDisabled={isDisabled}
          onToggle={(e) => handleOnActive(e.target.checked)}
        />
      </HStack>

      <CheckboxGroup
        // defaultValue={checkedServiceTimes}
        value={checkedServiceTimes || []}
        onChange={handleOnChangeCheckbox}>
        <Stack spacing={1} pl={3} pr={8} py={2} roundedBottom="md" bg="light.300">
          {serviceTimes.map(({ label, value, isTracked }) => (
            <CustomCheckbox
              key={value}
              label={label}
              value={value}
              isTracked={isTracked}
              isDisabledService={!isActive}
              onSelectTracking={handleOnSelectTracking}
            />
          ))}
        </Stack>
      </CheckboxGroup>
    </Box>
  );
}
