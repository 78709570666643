import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
const baseStyle = defineStyle((props) => {
  return {
    footerBtnStyle: {
      clear: 'both',
      p: '0.625rem 0 1rem 0',
      float: 'right',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '.drp-selected': {
        display: 'inline-block',
        fontSize: '1.25rem',
        pr: '0.5rem'
      },
      '.clear-btn': {
        fontSize: '0.875rem',
        display: 'flex',
        alignItems: 'center',
        color: 'icon.500',
        fontWeight: 400,
        cursor: 'pointer',
        div: {
          display: 'flex'
        },
        svg: {
          w: 5,
          mr: '0.175rem'
        }
      }
    }
  };
});
export default defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'main'
  }
});
