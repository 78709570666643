import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Link,
  useToast,
  VStack
} from '@chakra-ui/react';
import { CountryList } from 'components/common/Constants';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';
import { TooltipField } from 'components/common/SlzForms/base/NestedField';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import { NestedTextarea } from 'components/common/SlzForms/base/NestedTextarea';
import SlzInput from 'components/common/SlzInput/SlzInput';
import SlzToast from 'components/common/SlzToast/SlzToast';
import { tooltipContent } from 'pages/Elements/ElementOne';
import { MAX_DIMENSION, MAX_WEIGHT } from 'modules/products/constants';

const DimensionsField = ({ label, register, errors }) => {
  const [dimensionFields] = useState([
    { field: 'height', unit: 'H' },
    { field: 'width', unit: 'W' },
    { field: 'depth', unit: 'D' }
  ]);

  const isInvalid = errors['dimension'];
  return (
    <FormControl isInvalid={isInvalid}>
      <SlzFormLabel display="flex" gap="1" alignItems="center" color="dark.700">
        {label} <TooltipField tooltipContent={'abc'} />
      </SlzFormLabel>
      <HStack>
        <HStack pos="relative" spacing={3}>
          {dimensionFields.map(({ field, unit }) => (
            <SlzInput
              type="number"
              size="sm"
              min="0"
              step="0.01"
              textAlign="right"
              bgColor="light.300"
              leftAddonContent={unit}
              status={errors[field] && 'error'}
              {...register(field)}
            />
          ))}
        </HStack>
      </HStack>
      <FormErrorMessage>{errors.dimension?.message}</FormErrorMessage>
    </FormControl>
  );
};

const ProductInformation = () => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();
  const toast = useToast();

  const listCountries = useMemo(
    () => CountryList.map((item) => ({ value: item.code, label: item.name })),
    []
  );

  useEffect(() => {
    if (
      watch('height') * watch('width') * watch('depth') > MAX_DIMENSION ||
      watch('weight') > MAX_WEIGHT
    ) {
      const TOAST_SIZE = 'TOAST_SIZE';
      !toast.isActive(TOAST_SIZE) &&
        toast({
          id: TOAST_SIZE,
          isClosable: true,
          render: ({ onClose }) => (
            <SlzToast
              colorScheme="positive"
              title="Pick from pallet product"
              description={
                <>
                  You are attempting to create a large product.
                  <br />
                  This product will be kept on pallets and picked directly from them.
                </>
              }
              status="info"
              size="md"
              variant="solid"
              w="10rem"
              onClose={onClose}
            />
          )
        });
    }
  }, [watch('height'), watch('width'), watch('depth'), watch('weight')]);

  return (
    <VStack spacing="8" alignItems="flex-start">
      <HStack w="full" spacing={20}>
        <Box w="19.5rem">
          <DimensionsField
            name="dimensions"
            label="Dimensions (cm)"
            register={register}
            errors={errors}
          />
        </Box>
        <HStack spacing={8}>
          <Box w="11rem">
            <NestedInput
              field="currentPrice"
              label="Product price"
              labelProps={{ color: 'dark.700' }}
              fieldProps={{
                type: 'number',
                min: 0,
                max: 9999999,
                step: 0.01,
                leftAddonContent: '£',
                textAlign: 'right',
                bgColor: 'light.300'
              }}
              tooltipContent={tooltipContent}
              useTooltipLabel={true}
            />
          </Box>
          <Box w="11rem">
            <NestedInput
              field="units"
              label="Expected weekly sales"
              labelProps={{ color: 'dark.700' }}
              fieldProps={{
                type: 'number',
                min: 0,
                step: 1,
                leftAddonContent: 'Units',
                textAlign: 'right',
                bgColor: 'light.300'
              }}
              tooltipContent={tooltipContent}
              useTooltipLabel={true}
            />
          </Box>
        </HStack>
      </HStack>

      <HStack w="full" spacing={8} alignItems="flex-end">
        <Box w="6rem">
          <NestedInput
            field="weight"
            label="Weight (kg)"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{
              type: 'number',
              min: 0,
              step: 0.01,
              textAlign: 'right',
              bgColor: 'light.300',
              w: '200px'
            }}
            tooltipContent={tooltipContent}
            useTooltipLabel={true}
          />
        </Box>
        <Box w="27rem">
          <SlzAttentionCard
            variant="product"
            w="27rem"
            maxW="27rem"
            title={
              <>
                For shelf locations your product must be below <b>5kg</b>, if you product exceeds
                that weight it will be kept on pallets to provide you with a more cost-effective
                storage
              </>
            }
          />
        </Box>
      </HStack>

      <HStack w="full" spacing={8} alignItems="flex-end">
        <Box w="12.5rem">
          <NestedDropdown
            field="countryCodeOfManufacture"
            label="Country of manufacturing"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{
              options: listCountries,
              chakraStyles: {
                ...SELECT_STYLES,
                container: (provided, state) => ({
                  ...provided,
                  '> div': {
                    background: 'light.300'
                  }
                })
              }
            }}
          />
        </Box>
        <Box w="10rem">
          <NestedInput
            field="commodityCode"
            label="Commodity code"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{ type: 'number', min: 0, bgColor: 'light.300' }}
          />
        </Box>
        <Box w="26rem">
          <SlzAttentionCard
            variant="product"
            w="26rem"
            maxW="26rem"
            title={
              <>
                This information is required by customs, and should be 8-10 characters. If you do
                not know your commodity code you can find it at{' '}
                <Link href="https://www.gov.uk/" color="main.500">
                  gov.uk
                </Link>
              </>
            }
          />
        </Box>
      </HStack>

      <HStack w="full" spacing={8}>
        <NestedTextarea
          label="Description"
          field="description"
          showTooltip={false}
          labelProps={{ color: 'dark.700' }}
          fieldProps={{ rows: 4, bgColor: 'light.300', resize: 'none' }}
        />
      </HStack>
    </VStack>
  );
};

export default ProductInformation;
