import { Flex, Image } from '@chakra-ui/react';

import BundleIcon from 'components/common/Icons/BundleIcon';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';
import Source from '../other/Source';

export const ORDER_ACCORDION_COLUMNS = [
  {
    field: 'image',
    type: COL_CUSTOM,
    width: 48,
    render: ({ row, variant }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
            h: '100%',
            maxH: '100%',
            py: 0
          }}>
          {row?.isBundle ? (
            <BundleIcon w="3rem" h="3rem" />
          ) : (
            <Image
              w="full"
              h="full"
              loading="lazy"
              src={row?.imageUrl || DEFAULT_IMAGE}
              alt=""
              srcSet={row?.imageUrl || DEFAULT_IMAGE}
              fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
            />
          )}
        </Flex>
      );
    }
  },
  {
    field: 'name',
    cellHeader: (row) => row.name,
    cellValue: (row) => row.skuCode || ''
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    cellValue: (row) => row['ean'] || row['suid'] || 'N/A'
  },
  {
    header: 'Ware House',
    field: 'wareHouse',
    type: 'string'
  },
  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number'
  },
  {
    field: 'price',
    header: 'Price',
    type: 'string'
  },
  {
    field: 'weight',
    header: 'Weight',
    type: 'number'
  }
];

export const ORDER_COLUMNS = [
  {
    name: 'Order ID',
    field: 'customerReference',
    isSorted: true,
    sortField: 'ORDER_NUMBER'
  },
  {
    name: 'Customer',
    field: 'customerOrganizationPersonName',
    isSorted: true,
    sortField: 'CUSTOMER_NAME'
  },
  {
    name: 'Status',
    field: 'status',
    isSorted: true,
    isEmphasis: true,
    sortField: 'STATUS'
  },
  {
    name: 'Tracking',
    field: 'trackingCode',
    isSorted: false
  },
  {
    name: 'Source',
    field: 'source',
    isSorted: false,
    render: ({ row }) => {
      return (
        <Source
          styles={{
            textColor: 'white',
            w: 'full',
            h: 'full',
            px: 6,
            fontSize: '1rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          type={row?.source?.type || ''}
          storeName={row?.source?.name || ''}
        />
      );
    }
  },
  {
    name: 'Date created',
    field: 'createdDate',
    isSorted: true,
    type: 'date',
    sortField: 'DATE_CREATED'
  }
];
