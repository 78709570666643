import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const GarmentOnHanger = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.324rem"
            h="1.125rem"
            viewBox="0 0 21.176 18"
            fill="gray"
            {...props}>
            <g id="Group_34547" data-name="Group 34547" clip-path="url(#clip-path)">
                <path id="Path_10262" data-name="Path 10262" d="M18,18H3.176A3.18,3.18,0,0,1,0,14.824v-.871a3.176,3.176,0,0,1,1.634-2.777l7.182-3.99a6.052,6.052,0,0,1-1.4-4.007,3.176,3.176,0,1,1,6.353,0,1.059,1.059,0,0,1-2.118,0,1.059,1.059,0,1,0-2.118,0A4,4,0,0,0,11.2,6.539l8.345,4.636a3.177,3.177,0,0,1,1.634,2.777v.871A3.18,3.18,0,0,1,18,18M10.588,8.623l-7.926,4.4a1.059,1.059,0,0,0-.544.925v.872a1.061,1.061,0,0,0,1.059,1.059H18a1.061,1.061,0,0,0,1.059-1.059v-.871a1.058,1.058,0,0,0-.545-.926Z" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default GarmentOnHanger;