import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import badgeTheme from './badge';

const sizes = {
  xs: {
    minH: 4,
    flexBasis: 5,
    fontSize: '0.625rem',
    px: 3
  },
  sm: {
    minH: 4,
    flexBasis: 5,
    fontSize: '0.625rem',
    px: 3
  },
  md: {
    minH: 5,
    flexBasis: 12,
    fontSize: '0.75rem',
    px: 6
  },
  lg: {
    minH: 6,
    flexBasis: 12,
    fontSize: '0.75rem',
    px: 6
  }
};

const baseStyle = {
  borderRadius: '0.188rem',
  display: 'inline-flex',
  verticalAlign: 'top',
  alignItems: 'center'
};

const variants = {
  subtle: defineStyle((props) => badgeTheme.variants?.subtle(props)),
  solid: defineStyle((props) => badgeTheme.variants?.solid(props)),
  outline: defineStyle((props) => badgeTheme.variants?.outline(props))
};

export default defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'main'
  }
});
