import { useEffect } from 'react';
import { Flex, Icon, Text, VStack } from '@chakra-ui/react';
import { Controller, FormProvider, useFieldArray } from 'react-hook-form';

import { SlzToggle } from 'components/common/SlzToggle';
import { USER_SETTING_FORMS_DEFAULT_NAME } from 'modules/preferences/constants';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { PreferenceHeader, PreferencePaper } from '../common';

const CustomIconExtras = ({ name, extraIcon }) => {
  return (
    <Flex gap={5} alignItems="center">
      <Icon h={7.5} w={7.5} as={extraIcon} />
      <Text fontSize="lg">{name}</Text>
    </Flex>
  );
};

const ExtrasPackagingSettingForm = ({ data }) => {
  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.configureExtras]: form } = forms;
  const methods = form?.form;

  const { fields } = useFieldArray({
    control: methods?.control,
    name: 'extras'
  });

  useEffect(() => {
    data && methods.reset({ extras: data });
  }, [data]);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <VStack alignItems="flex-start" spacing={0} mt="1.25rem">
          <PreferenceHeader header="Extras" fontSize="1.063rem" spacing={0} />
          <PreferencePaper w="14.938rem" h="16.938rem" mt={1.25} p={5} bg="light.300">
            <Flex w="full" flexDirection="column" gap={8} p="0.375rem 0.938rem 0 0">
              {fields?.map(({ typeName, icon }, indexExtras) => {
                return (
                  <Flex
                    key={`${typeName}-${indexExtras}`}
                    w="full"
                    justifyContent="space-between"
                    alignItems="center">
                    <CustomIconExtras extraIcon={icon} name={typeName} />
                    <Controller
                      control={methods?.control}
                      name={`extras.${indexExtras}.enabled`}
                      render={({ field }) => {
                        return (
                          <SlzToggle
                            value={field?.value}
                            isChecked={field?.value}
                            onChange={field?.onChange}
                            mb={0}
                          />
                        );
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </PreferencePaper>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default ExtrasPackagingSettingForm;
