import { getStatusColor } from 'modules/b2c-orders/mappers/order-list-mapper';
import {
  STATUS,
  RETURN_SERVICE,
  RETURN_SERVICE_MAPPING,
  GRADING,
  STATUS_MAPPING,
  COST_BREAKDOWN_MAPPING
} from '../constants';

export const getFilterColor = (key) => {
  switch (key) {
    case STATUS.PROCESSED.value:
      return 'tetra.500';
    case STATUS.UNPROCESSED.value:
      return 'strawberry.300';
    case STATUS.UNDER_INVESTIGATION.value:
      return 'sunrise.500';
    case RETURN_SERVICE.EXTERNAL.value:
      return 'szrDenim.500';
    case RETURN_SERVICE.DROP_OFF.value:
    case RETURN_SERVICE.HOME_COLLECTION.value:
      return 'szrElectricBlue.500';
  }
};

export const getGradingColor = (key) => {
  switch (key) {
    case GRADING.A:
      return 'tetra.700';
    case GRADING.B:
      return 'sunshine.500';
  }
};

export const getReturnStatusLabel = (key) => STATUS_MAPPING[key];
export const getReturnServiceLabel = (key) => RETURN_SERVICE_MAPPING[key];

export const isUnprocessedReturn = (status) => status === STATUS.UNPROCESSED.value;

export const getOrderShippingStatus = (shipment = {}) => {
  const { missingItems = [], status = '' } = shipment;

  let trimStatus = status.replace(' - PPO', '');

  if (trimStatus === 'Shipped' && missingItems.lenght > 0) {
    trimStatus = 'Part Fulfilled';
  }

  return {
    bg: getStatusColor(trimStatus),
    label: trimStatus
  };
};

export const getCostBreakdowns = (orderCostBreakdown = {}) => {
  return COST_BREAKDOWN_MAPPING.map((item) => {
    const { labelInsert, label, keys } = item;

    return {
      label: !labelInsert
        ? label
        : label.replace(labelInsert.keyword, orderCostBreakdown[labelInsert.key]),
      value: keys.reduce((acc, key) => (orderCostBreakdown[key] || 0) + acc, 0).toFixed(2)
    };
  });
};

export const getItemCount = (orderContents) => {
  if (!orderContents) {
    return 0;
  }

  const { orderedCompanyItems = [], orderedProductBundles = [] } = orderContents;
  const companyItems = orderedCompanyItems.reduce((acc, item) => acc + item.quantity, 0);
  const productBundles = orderedProductBundles.reduce(
    (acc, bundle) => acc + bundle.productBundle.totalProducts * bundle.quantity,
    0
  );

  return companyItems + productBundles;
};
