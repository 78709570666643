import moment from 'moment';
import { mockProductTag } from '../_mock';
import { MAX_DIMENSION, MAX_WEIGHT } from '../constants';

export const handleTransformCreateProduct = (data, user) => {
  return {
    id: '00000000-0000-0000-0000-000000000000',
    editable: false,
    active: false,
    companyID: user?.companyID,
    itemID: '00000000-0000-0000-0000-000000000000',
    expectedWeeklySales: data.units,
    isPrePacked: data.isPrePacked,
    requiresBoxPackaging: data.requiresBoxPackaging,
    requiresPaperPackaging: data.requiresPaperPackaging,
    isFragile: data.isFragile,
    pickFromPallet: false,
    stockKeepingUnits: handleStockKeepingUnits(data.sku ?? []),
    item: handleTransformItem(data, false),
    currentPrice: {
      id: '00000000-0000-0000-0000-000000000000',
      companyItemID: '00000000-0000-0000-0000-000000000000',
      unitPriceExclVat: 0,
      price: data.currentPrice,
      costPrice: 0,
      active: true,
      ignore_whitespace: false
    },
    noScan: true
  };
};

export const transformCreateProductPayload = (data, user) => {
  const payloadInitId = '00000000-0000-0000-0000-000000000000';

  const { height, width, depth, weight } = data;

  const pickFromPallet = height * width * depth > MAX_DIMENSION || weight > MAX_WEIGHT;

  return {
    id: payloadInitId,
    editable: false,
    active: false,
    companyID: user?.companyID,
    itemID: payloadInitId,
    expectedWeeklySales: data.units,
    isPrePacked: data.isPrePacked,
    requiresBoxPackaging: data.requiresBoxPackaging === 'true',
    requiresPaperPackaging: data.requiresPaperPackaging === 'true',
    isFragile: data.isFragile === 'true',
    pickFromPallet,
    stockKeepingUnits: data.sku.map((item) => ({
      id: payloadInitId,
      code: item.skuCode,
      description: item.skuDescription,
      itemImageID: item.uploadedImage?.id,
      companyItemID: payloadInitId,
      quantity: 0,
      ignore_whitespace: false
    })),
    item: {
      id: payloadInitId,
      name: data.name,
      description: data.description,
      brand: data.brand,
      height: data.height?.toString(),
      width: data.width?.toString(),
      depth: data.depth?.toString(),
      weight: data.weight?.toString(),
      categoryID: data.category?.value,
      ean: data.ean,
      selazarUniqueID: null,
      jan: null,
      upc: null,
      isbn: data.isbn,
      batch: false,
      mpn: null,
      countryCodeOfManufacture: 'AS',
      commodityCode: data.commodityCode?.toString(),
      itemImages: [],
      ignore_whitespace: false
    },
    currentPrice: {
      id: payloadInitId,
      companyItemID: payloadInitId,
      price: data.currentPrice,
      active: true
    },
    noScan: data.noScan
  };
};

export const handleTransformEditProduct = (newData, currentData) => {
  return {
    editable: true,
    active: true,
    expectedWeeklySales: newData.units,
    companyID: newData.companyID,
    itemID: newData.itemID,
    item: handleTransformItem(newData, true),
    requiresBoxPackaging: false,
    requiresBubbleWrapPackaging: false,
    requiresPaperPackaging: false,
    requiresEcoPackaging: false,
    isFragile: false,
    isPrePacked: false,
    pickFromPallet: false,
    noScan: true,
    stockKeepingUnits: [],
    stockLocations: [],
    companyItemPrices: [],
    currentPrice: {
      active: true,
      price: newData.currentPrice?.price,
      companyItemID: currentData.currentPrice?.companyItem?.itemID,
      id: currentData.currentPrice?.companyItem?.id,
      lastModified: new Date().toISOString()
    },
    displayPrice: {
      active: true,
      price: currentData.currentPrice?.price,
      companyItemID: currentData.currentPrice?.companyItem?.itemID,
      id: currentData.currentPrice?.companyItem?.id,
      lastModified: new Date().toISOString()
    },
    stockCount: 0,
    isPackagingPreferencesValid: true,
    id: currentData?.currentPrice?.companyItem?.id,
    lastModified: new Date().toISOString()
  };
};

const handleStockKeepingUnits = (sku) => {
  if (sku && !Array.isArray(sku)) return [];
  return sku.map((itemSku) => {
    return {
      code: itemSku.skuCode,
      companyItemID: '00000000-0000-0000-0000-000000000000',
      description: itemSku.skuDescription,
      id: '00000000-0000-0000-0000-000000000000',
      ignore_whitespace: false,
      itemImage: { link: itemSku.imageUrl },
      itemImageID: '',
      quantity: 0
    };
  });
};

const handleTransformItem = (data, isEdit) => {
  const category = {
    name: data?.category?.label,
    id: data?.category?.value
  };
  const item = {
    id: data?.itemID ? data.itemID : '00000000-0000-0000-0000-000000000000',
    name: data.name,
    description: data.description,
    brand: data.brand,
    height: data.height,
    width: data.width,
    depth: data.depth,
    weight: data.weight,
    categoryID: data.category.value,
    ean: data.ean,
    selazarUniqueID: null,
    jan: null,
    upc: null,
    isbn: data.isbn,
    mpn: null,
    countryCodeOfManufacture: data.countryCodeOfManufacture.value,
    commodityCode: data.commodityCode,
    itemImages: [],
    ignore_whitespace: false
  };
  return { ...item, category: isEdit ? category : '' };
};

export const transformProducts = ({ items = [], pageCount = 0 }) => {
  const data = {
    products: items,
    totalPage: pageCount
  };
  
  data.products = data.products.map((product) => ({
    ...product,
    name: product?.name,
    id: `${product?.companyItemID}-${product?.sku}`,
    identifier: product?.companyItemID,
    sku: product?.sku,
    palletStock: product?.palletStock,
    isPrePacked: product?.isPrePacked,
    shelfStock: product?.shelfStock,
    selazarUniqueID: product?.selazarUniqueID,
    ean: product?.ean,
    totalStock: product?.totalStock,
    imageUrl: product?.imageFileLink,
    createdDate: moment(product?.createdDate).format('MM/DD/YYYY'),
    category: product?.category,
    companyItemID: product?.companyItemID,
    status: product?.status,
    tag: { id: product?.itemCustomTagID, colorCode: product?.colorCode, text: product?.text, key: product?.itemCustomTagID, color: product?.colorCode, name: product?.text}
  }));

  return data;
};
