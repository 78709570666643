import { Box, Text, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzChip = (props) => {
  const { children, variant, colorScheme, size, ...rest } = props;
  const styles = useStyleConfig('SlzChip', { variant, colorScheme, size });
  return (
    <Box
      as="span"
      role="slz-chip"
      __css={styles}
      {...rest}
      data-testid="chip-wrap"
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Chip`)}>
      <Text noOfLines={1} mb={0} data-testid="chip-content">
        {children}
      </Text>
    </Box>
  );
};

export default SlzChip;
