import { Icon } from '@chakra-ui/react';
import React from 'react';

const CsvUpload = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="66.171"
      height="82.709"
      viewBox="0 0 66.171 82.709"
      fill="currentColor"
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_5321"
            data-name="Rectangle 5321"
            width="66.171"
            height="82.709"
            fill="currentColor"
          />
        </clipPath>
      </defs>
      <g id="Group_4011" data-name="Group 4011" clip-path="url(#clip-path)">
        <path
          id="Path_9782"
          data-name="Path 9782"
          d="M65.507,21.869,44.525.68A2.3,2.3,0,0,0,42.895,0H10.964A10.976,10.976,0,0,0,0,10.964V71.745A10.977,10.977,0,0,0,10.964,82.709H55.207A10.976,10.976,0,0,0,66.171,71.745V23.484a2.294,2.294,0,0,0-.664-1.614M45.005,7.687,58.491,21.306H50.125a5.126,5.126,0,0,1-5.12-5.12Zm-34.041-3.1H40.651V9.3H4.811a6.386,6.386,0,0,1,6.153-4.71M55.207,78.12H10.964a6.382,6.382,0,0,1-6.375-6.375V13.888H40.651v2.3a9.486,9.486,0,0,0,9.475,9.475H61.582V71.745a6.382,6.382,0,0,1-6.375,6.375"
          fill="currentColor"
        />
        <path
          id="Path_9783"
          data-name="Path 9783"
          d="M120.172,458.634H82.77a2.295,2.295,0,1,0,0,4.589h37.4a2.295,2.295,0,0,0,0-4.589"
          transform="translate(-68.385 -389.733)"
          fill="currentColor"
        />
        <path
          id="Path_9784"
          data-name="Path 9784"
          d="M127,142.674a2.287,2.287,0,0,0,1.622-.672l8.172-8.172v17.493a2.295,2.295,0,1,0,4.589,0V133.83L149.561,142a2.294,2.294,0,1,0,3.245-3.245L140.718,126.67v0l-.023-.021c-.046-.045-.094-.089-.144-.13-.029-.024-.06-.045-.09-.067s-.059-.046-.09-.066-.07-.043-.105-.064-.057-.035-.087-.052-.072-.035-.108-.052-.063-.031-.095-.044-.071-.026-.107-.039-.07-.026-.105-.037-.072-.019-.107-.028-.073-.02-.111-.027-.082-.013-.124-.019-.065-.011-.1-.014c-.075-.008-.151-.012-.227-.012s-.151,0-.226.012c-.034,0-.067.01-.1.014s-.081.011-.121.018-.076.018-.114.028-.07.016-.1.027-.073.025-.109.038-.069.024-.1.038-.066.03-.1.046-.07.032-.1.05-.062.037-.093.055-.067.039-.1.06-.067.049-.1.073-.055.039-.081.06q-.089.073-.17.154l-12.087,12.087A2.295,2.295,0,0,0,127,142.674"
          transform="translate(-105.975 -107.068)"
          fill="currentColor"
        />
        <path
          id="Path_9785"
          data-name="Path 9785"
          d="M114.92,360.673a6.2,6.2,0,0,0,2.679-.554,4.282,4.282,0,0,0,1.822-1.562,4.6,4.6,0,0,0,.71-2.324H117.11a2.094,2.094,0,0,1-.589,1.567,2.333,2.333,0,0,1-1.6.493,1.853,1.853,0,0,1-1.744-.865,6.031,6.031,0,0,1-.506-2.882v-.892a5.45,5.45,0,0,1,.554-2.709,1.815,1.815,0,0,1,1.662-.848,2.385,2.385,0,0,1,1.662.5,2.257,2.257,0,0,1,.58,1.653h3.03a4.771,4.771,0,0,0-1.584-3.328,5.432,5.432,0,0,0-3.687-1.207,5.181,5.181,0,0,0-2.8.757,4.887,4.887,0,0,0-1.857,2.147,7.631,7.631,0,0,0-.645,3.242v.623a6.683,6.683,0,0,0,1.428,4.57,4.949,4.949,0,0,0,3.912,1.619"
          transform="translate(-93.117 -295.478)"
          fill="currentColor"
        />
        <path
          id="Path_9786"
          data-name="Path 9786"
          d="M190.6,350.426a2.075,2.075,0,0,1,1.286-.359,1.839,1.839,0,0,1,1.277.424,1.523,1.523,0,0,1,.472,1.194h3.03a3.605,3.605,0,0,0-.6-2.047,3.858,3.858,0,0,0-1.675-1.381,5.878,5.878,0,0,0-2.436-.485,6.72,6.72,0,0,0-2.51.446,3.95,3.95,0,0,0-1.727,1.246,2.965,2.965,0,0,0-.61,1.848q0,2.1,2.45,3.307a15.906,15.906,0,0,0,1.939.77,5.87,5.87,0,0,1,1.658.77,1.248,1.248,0,0,1,.472,1.039,1.072,1.072,0,0,1-.437.913,1.957,1.957,0,0,1-1.19.325,2.475,2.475,0,0,1-1.7-.48,1.932,1.932,0,0,1-.524-1.493h-3.047a3.922,3.922,0,0,0,.628,2.2,4.181,4.181,0,0,0,1.87,1.506,6.781,6.781,0,0,0,2.774.55,5.545,5.545,0,0,0,3.419-.943,3.077,3.077,0,0,0,1.246-2.6,3.608,3.608,0,0,0-2.043-3.246,11.208,11.208,0,0,0-2.147-.9,6.544,6.544,0,0,1-1.827-.814,1.058,1.058,0,0,1-.048-1.792"
          transform="translate(-158.677 -295.527)"
          fill="currentColor"
        />
        <path
          id="Path_9787"
          data-name="Path 9787"
          d="M262.6,358.1l-2.5-9.175h-3.367l4.207,12.6h3.324l4.241-12.6h-3.393Z"
          transform="translate(-218.165 -296.506)"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
};

export default CsvUpload;
