import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const parts = ['avatar', 'title', 'subtitle'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  subtitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

const variants = {
  circle: definePartsStyle((props) => {
    return {
      avatar: {
        borderRadius: 'full'
      }
    };
  }),
  square: definePartsStyle((props) => {
    const { size } = props;
    let borderRadius = '0.375rem';
    if (size === 'sm') {
      borderRadius = '0.1875rem';
    }
    return {
      avatar: {
        borderRadius
      }
    };
  })
};

const sizes = {
  sm: definePartsStyle(() => ({
    avatar: {
      size: 'xs'
    },
    title: {
      fontSize: '0.625rem',
      maxW: '7rem'
    },
    subtitle: {
      fontSize: '0.5rem',
      maxW: '7rem'
    }
  })),
  md: definePartsStyle(() => ({
    avatar: {
      size: 'md'
    },
    title: {
      fontSize: '1.0625rem',
      maxW: '13.5rem'
    },
    subtitle: {
      fontSize: '0.9375',
      maxW: '13.5rem'
    }
  })),
  lg: definePartsStyle(() => ({
    avatar: {
      size: 'xl'
    },
    title: {
      fontSize: '1.9375rem',
      maxW: '22.5rem'
    },
    subtitle: {
      fontSize: '1.75rem',
      maxW: '22.5rem'
    }
  }))
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'circle',
    size: 'md'
  }
});
