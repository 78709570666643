import { useEffect, useState } from 'react';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { Box, createIcon } from '@chakra-ui/react';
import { SORT_BY, IS_DESC } from 'constants/table.js';
const ANGLE = -180;

export const TriangleIcon = createIcon({
  displayName: 'UpDownIcon',
  viewBox: '0 0 512 512',
  path: <path fill="currentColor" d="M256 32L20 464h472L256 32z"></path>
});

const rotateAnimation = (angle = 0) => {
  return `rotate(${angle}deg) translateY(0px);`;
};

const SortIcon = (props) => {
  const routerQuery = useRouterQuery();
  const [isDesc, setIsDesc] = useState(false);
  const [transform, setTransform] = useState(null);
  const { column, onSort, isShow } = props;

  useEffect(() => {
    const rotate = rotateAnimation(isDesc ? ANGLE : 0);
    setTransform(rotate);
  }, [isDesc]);

  useEffect(() => {
    if (routerQuery.get(SORT_BY) === column?.sortField) {
      const sortDirection = routerQuery.get(IS_DESC);
      setIsDesc(sortDirection === 'true' || sortDirection === true);
    }
  }, [routerQuery.get(IS_DESC), routerQuery.get(SORT_BY)]);

  const handleClick = () => {
    setIsDesc(!isDesc);
    typeof onSort === 'function' && onSort(column, !isDesc);
  };

  const getStyle = () => {
    if (isShow) {
      return {
        display: 'inline-flex'
        // visibility: 'visible',
        // opacity: 1
      };
    }
    return {
      display: 'none'
      // visibility: 'hidden',
      // opacity: 0,
      // transition: 'visibility 0s ease-out 0.3s, opacity 0.3s'
    };
  };

  return (
    <Box
      as="button"
      onClick={handleClick}
      borderRadius={50}
      w="1.25rem"
      h="1.25rem"
      bg="light.700"
      outline="none"
      _focus={{ outline: 'none' }}
      alignItems="center"
      justifyContent="center"
      sx={getStyle()}>
      <TriangleIcon
        transition={'0.3s ease-out;'}
        transform={transform}
        color="dark.500"
        w="0.625rem"
        h="0.625rem"
      />
    </Box>
  );
};

export default SortIcon;
