import { useState, useEffect } from 'react';
import { useSize } from '@chakra-ui/react-use-size';

export const useWindowDimensions = () => {
  const rootEl = document.getElementById('root');
  const dimensions = useSize(rootEl, true);
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    setWindowDimensions(dimensions);
  }, [dimensions]);

  return windowDimensions;
};
