import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, Image, VStack } from '@chakra-ui/react';
import { PublicLayout, PublicLayoutHeader } from 'components/layout';
import { SlzButton } from 'components/common/SlzButton';
import SlzLink from 'components/common/SlzLink/SlzLink';
import { CompanyInfo } from '../components/other';
import {
  CONTACT_INFOS,
  HOME_PAGE_URL,
  MEDIA_LINKS,
  REGISTRATION_PASSWORD_IMAGE_URL_SET,
  TEXT_OVERLAY,
  VERIFICATION_EMAIL_TEXT
} from '../constants';
import useResendVerification from '../hooks/useResendVerification';
import { useQuery } from 'hooks/useQuery';

const RegisterPage = () => {
  const [isResend, setIsResend] = useState(false);
  const mailto = `mailto:${CONTACT_INFOS.email}`;
  const query = useQuery();

  const resendVerification = useResendVerification();

  const handleResendVerification = () => {
    const payload = {
      id: query.get('userId')
    };
    resendVerification.mutate(payload);
  };

  useEffect(() => {
    if (resendVerification.isSuccess) {
      setIsResend(true);
    }
  }, [resendVerification.isSuccess]);

  return (
    <PublicLayout
      imageSrc={REGISTRATION_PASSWORD_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={MEDIA_LINKS}
      sx={{ alignItems: 'start', paddingTop: 0 }}>
      <Box mt="21.11vh">
        <PublicLayoutHeader
          title={!isResend ? VERIFICATION_EMAIL_TEXT.title : VERIFICATION_EMAIL_TEXT.title2}
          description={
            <Text textAlign="center">
              {!isResend
                ? VERIFICATION_EMAIL_TEXT.emailSended
                : VERIFICATION_EMAIL_TEXT.resendingYourEmail}
              <br />
              {!isResend
                ? VERIFICATION_EMAIL_TEXT.emailSended2
                : VERIFICATION_EMAIL_TEXT.resendingYourEmail2}
            </Text>
          }
          isCenter
        />

        <VStack spacing={5}>
          <Image src="Images/resend-email.svg" w={48} h={48} />
          <Box h={14} textAlign={'center'}>
            <Text>{!isResend ? VERIFICATION_EMAIL_TEXT.cannotFindEmail : ''}</Text>
            <Text>{!isResend ? VERIFICATION_EMAIL_TEXT.or : ''}</Text>
          </Box>

          <SlzButton size="lg" w="full" isDisabled={isResend} onClick={handleResendVerification}>
            {VERIFICATION_EMAIL_TEXT.resendEmail}
          </SlzButton>

          <Text textAlign={'center'}>
            {!isResend ? VERIFICATION_EMAIL_TEXT.havingIssues : VERIFICATION_EMAIL_TEXT.refreshPage}
            {isResend ? <br /> : null}
            {isResend ? VERIFICATION_EMAIL_TEXT.contactUs : null}
            <SlzLink href={mailto} color="main.500" fontWeight="medium">
              {CONTACT_INFOS.email}
            </SlzLink>
          </Text>

          <Box
            h={12}
            w="full"
            lineHeight="3rem"
            textAlign="center"
            rounded="md"
            bgColor="light.500"
            fontWeight="medium">
            {VERIFICATION_EMAIL_TEXT.closeWindow}
          </Box>
        </VStack>

        <HStack justifyContent="space-between" alignItems="flex-start" mt={24}>
          <SlzLink href={HOME_PAGE_URL} color="main.500" fontWeight="medium">
            Selazar.com
          </SlzLink>

          <CompanyInfo email={CONTACT_INFOS.email} telephone={CONTACT_INFOS.telephone} />
        </HStack>
      </Box>
    </PublicLayout>
  );
};

export default RegisterPage;
