import { Fade } from '@chakra-ui/react';

import AddFileUploadForm from './file-upload';
import AddManual from './manual';
import AddOptions from './AddOptions';

const ManualAdd = () => {
  return (
    <Fade in>
      <AddManual />
    </Fade>
  );
};

const UploadAdd = ({ csvFiles, isUploaded, setCsvFiles }) => {
  return (
    <Fade in>
      <AddFileUploadForm csvFiles={csvFiles} isUploaded={isUploaded} setCsvFiles={setCsvFiles} />
    </Fade>
  );
};

export const ADD_PRODUCT_CONTENT = {
  0: AddOptions,
  1: ManualAdd,
  2: UploadAdd
};
