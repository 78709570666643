const spacing = {
  space: {
    0.25: '0.0625rem', // 1px
    0.5: '0.125rem', // 2px
    0.75: '0.1875rem', // 3px,
    1.25: '0.3125rem', // 5px,
    2.5: '0.625rem', // 10px
    2.75: '0.75rem', // 12px
    4: '1rem', // 16px
    4.25: '1.125rem',
    5: '1.25rem', // 20px,
    6: '1.5rem', // 24px,
    6.5: '1.625rem', // 26px
    7.5: '1.875rem', // 30px,
    9: '2.125rem', // 34px
    10: '2.25rem', // 36px
    11: '2.75rem', // 44px
    11.5: '2.875rem', // 46px
    14: '3.25rem', // 52px
    15: '3.75rem', // 60px
    18: '4.375rem', // 70px
    19: '4.688rem', // 75px,
    20: '5.313rem', // 85px
    30: '7.5rem' // 120px
  }
};

export default spacing;
