import { UNTRACKED_SERVICE, UNTRACKED_SERVICE_PREFIX } from 'modules/b2c-orders/constants';

export const setUntrackedService = (parcelReferences) =>
  parcelReferences[0]?.toUpperCase().includes(UNTRACKED_SERVICE_PREFIX)
    ? UNTRACKED_SERVICE
    : parcelReferences.join(' ');

export const renderTrackingRef = (order) => {
  let trackingRef = 'N/A';

  if (order.parcelReferences.length > 0) trackingRef = setUntrackedService(order.parcelReferences);

  return trackingRef;
};
