export default {
  dropdownIndicator: (provided, { isDisabled, selectProps }) => {
    let color = provided.color;

    if (selectProps.isInvalid) {
      color = 'negative.500';
    }

    if (selectProps.isValid) {
      color = 'positive.500';
    }

    if (isDisabled) {
      color = 'dark.300';
    }

    return {
      ...provided,
      bgColor: 'transparent',
      // marginRight: provided.mr,
      '> svg': {
        width: 'inherit',
        height: 'inherit',
        transition: 'all 0.2s',
        transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
        color
      }
    };
  },
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    let bgColor = 'white';

    if (isFocused) {
      bgColor = 'light.500';
    }

    if (isSelected) {
      bgColor = 'main.300';
    }

    return {
      ...provided,
      color: isDisabled ? 'dark.300' : 'dark.700',
      bgColor,
      // height: 7.5,
      padding: '0 0.75rem',
      _disabled: {
        opacity: 1
        // _hover: {
        //   bgColor: 'light.500'
        // }
      }
    };
  },
  menuList: (provided) => ({
    ...provided,
    padding: '3px'
  }),
  multiValue: (provided) => ({
    ...provided,
    rounded: 'full'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    rounded: 'full',
    w: 5,
    h: 5,
    color: 'dark.500',
    bg: 'light.500',
    '& > svg': {
      w: 2,
      h: 2
    },
    _hover: {
      bg: 'light.700'
    }
  }),
  placeholder: (provided, { selectProps }) => {
    return {
      ...provided,
      color: selectProps.isInvalid ? 'negative.500' : 'dark.500'
    };
  },
  valueContainer: (provided, { selectProps }) => {
    return {
      ...provided,
      paddingRight: 0,
      paddingTop: selectProps.size === 'xs' ? 0 : provided.paddingTop
    };
  },
  control: (provided, { selectProps }) => {
    const isValid = selectProps.isValid;
    const validStyles = isValid && selectProps.inputStyles.field['&[aria-valid="true"]'];

    return {
      ...provided,
      ...validStyles
    };
  },
  container: (provided) => {
    return {
      ...provided
    };
  }
};
