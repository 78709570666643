import { useQuery } from 'react-query';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { transformAccordionItems } from '../../mappers/bundle-list-accordion-mapper';
import { getBundleDetail } from '../../services/bundles-api';

const BundleAccordionTable = ({ row, accordionProps }) => {
  const {
    data: itemDetails, // should replace for current data props after API has already
    isLoading,
    refetch
  } = useQuery([`bundles-detail-${row?.id}`], () => getBundleDetail(row?.id), {
    // time until stale data is garbage collected
    cacheTime: 60 * 1000,
    // time until data becomes stale
    staleTime: 30 * 1000,
    enabled: false,
    select: ({ productBundleCompanyItems = [] }) =>
      transformAccordionItems(productBundleCompanyItems || [])
  });
  return (
    <SlzAccordionTable
      variant="order"
      isShow={row?.isExpand}
      rowId={row?.id}
      data={itemDetails}
      isLoading={isLoading}
      refetch={refetch}
      isChild={true}
      configProps={{ ...accordionProps, markerColor: row?.bundleTag?.color }}
    />
  );
};

export default BundleAccordionTable;
