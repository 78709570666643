import { Icon } from '@chakra-ui/react';
import React from 'react';

const B2CIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="25.794"
      height="25.794"
      fill="currentColor"
      viewBox="0 0 25.794 25.794"
      {...props}>
      <path
        id="Path_8349"
        data-name="Path 8349"
        d="M25.415,11.985,13.808.378a1.288,1.288,0,0,0-1.824,0L.378,11.985a1.289,1.289,0,0,0,.912,2.2h1.29v7.738a3.874,3.874,0,0,0,3.869,3.869h12.9a3.874,3.874,0,0,0,3.869-3.869V14.186H24.5a1.289,1.289,0,0,0,.912-2.2M15.476,23.214H10.317V16.766a1.291,1.291,0,0,1,1.29-1.29h2.579a1.29,1.29,0,0,1,1.29,1.29Zm5.159-1.29a1.29,1.29,0,0,1-1.29,1.29h-1.29V16.766A3.874,3.874,0,0,0,14.186,12.9H11.607a3.874,3.874,0,0,0-3.869,3.869v6.448H6.448a1.291,1.291,0,0,1-1.29-1.29V12.9a1.28,1.28,0,0,0-.092-.457c-.018-.044-.041-.081-.062-.124a1.258,1.258,0,0,0-.178-.263,1.3,1.3,0,0,0-.1-.106,1.265,1.265,0,0,0-.286-.192c-.032-.017-.058-.037-.092-.052-.01,0-.017-.012-.027-.015L12.9,3.113l8.575,8.575c-.009,0-.017.012-.026.015-.035.014-.063.036-.1.053a1.334,1.334,0,0,0-.284.191,1.133,1.133,0,0,0-.1.107,1.226,1.226,0,0,0-.178.263,1.363,1.363,0,0,0-.062.123,1.28,1.28,0,0,0-.092.457Z"
        transform="translate(0 0)"
      />
    </Icon>
  );
};

export default B2CIcon;
