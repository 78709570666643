import { Fragment, useCallback, useEffect, useState } from 'react';

import { GridItem, HStack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { SlzGrid } from 'components/common/SlzGrid';
import { SlzButton } from 'components/common/SlzButton';
import {
  BREAD_CRUMB_ITEMS,
  DEFAULT_EXPIRED_PASSWORD_INTEGRATION_IN_MINUTES,
  INTEGRATION_EXIST_ERROR,
  INTEGRATION_TAB,
  INTEGRATION_TYPE,
  KEY,
  MANAGE_TYPE,
  MESSAGES_MANAGE,
  RATE_MATCHER_TOAST_ID,
  SHOPIFY_STORE_URL
} from 'modules/integrations/constants';
import { TAB } from 'constants/table.js';
import { API, IntegrationTabs, MarketPlace, MyApp } from 'modules/integrations/components/other';
import { useIntegrationQuery } from '../hooks/useIntegrationQuery';
import {
  transformAPIConnection,
  transformIntegrations,
  transformPayloadIntegration
} from 'modules/integrations/mappers/integration-list-mapper';
import {
  CreateApiIntegrationModal,
  CreateIntegrationModal,
  ManageIntegrationModal,
  RateMatcherSettingModal
} from 'modules/integrations/components/modal';
import {
  updateIntegration,
  createIntegrateShopifyApi,
  createIntegrateWooCommerceApi
} from 'modules/integrations/services/integration-api';
import SlzToast from 'components/common/SlzToast/SlzToast';
import { applyVariableToMessage, getItemCookie, setItemCookie } from 'utils/helper';
import EditCredentialsModal from 'modules/integrations/components/modal/EditCredentialsModal';
import { useApiConnectionQuery } from '../hooks';
import { useVerifyPasswordMutation } from '../hooks/useVerifyPasswordMutatation';

const RefreshToastContent = () => (
  <HStack ps={6} spacing={4}>
    <Text fontSize="1rem" fontWeight="normal">
      Please refresh your browser after setting up your integration
    </Text>
    <SlzButton
      onClick={() => window.location.reload()}
      size="sm"
      variant="outline"
      fontSize="0.875rem"
      color="#F2F2F2"
      colorScheme="light">
      Refresh
    </SlzButton>
  </HStack>
);

export default function Integration() {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const toast = useToast();
  const [_, dispatch] = useGlobalState();
  const createIntegrationModal = useDisclosure();
  const editCredentialsModal = useDisclosure();
  const passwordConfirmationModal = useDisclosure();
  const rateMatcherSettingModal = useDisclosure();
  const createApiIntegrationModal = useDisclosure();
  const [integrationTab, setIntegrationTab] = useState(routerQuery.get(TAB) ?? INTEGRATION_TAB.my_app.key);
  const [tabIndex, setTabIndex] = useState(integrationTab ? Object.keys(INTEGRATION_TAB).findIndex((key) => key === integrationTab) : 0);
  const [isDisable, setIsDisable] = useState({ connect: false, inventory: false });
  const [integration, setIntegration] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [credentials, setCredentials] = useState({});
  const [apiConnectionId, setApiConnectionId] = useState(null);

  const verifyPasswordMutation = useVerifyPasswordMutation();

  const {
    data: fetchedIntegrations,
    isFetching,
    isSuccess,
    refetch
  } = useIntegrationQuery(
    (data) => transformIntegrations(data),
    integrationTab === INTEGRATION_TAB.my_app.key
  );

  const {
    data: fetchedApiConnection,
    isSuccess: isSuccessFetchedApiConnection,
    refetch: refetchApiConnection
  } = useApiConnectionQuery({
    select: (data) => transformAPIConnection(data),
    enabled: integrationTab === INTEGRATION_TAB.api.key
  });

  const integrationUpdateMutation = useMutation({
    mutationFn: ({ id, payload }) => {
      return updateIntegration(id, payload);
    }
  });

  const useCreateIntegrationMutation = (apiCall) => useMutation(apiCall);
  const createIntegrationWooCommerceMutation = useCreateIntegrationMutation(
    createIntegrateWooCommerceApi
  );
  const createIntegrationShopifyMutation = useCreateIntegrationMutation(createIntegrateShopifyApi);

  const handleTabChange = (index) => {
    const currentTab = Object.keys(INTEGRATION_TAB)[index];
    setTabIndex(index);
    setIntegrationTab(currentTab);
    routerQuery.set(TAB, currentTab);
    history.push({ search: routerQuery.toString() });
  };

  const handleCreateIntegrationSubmit = useCallback(
    async (values) => {
      const createIntegrationInstance = getCreateMutationInstance();
      createIntegrationInstance && createIntegrationInstance?.mutate(values);
    },
    [integration.type]
  );

  const handleUpdateCredentialsMessage = (changedFields) => {
    const messagesVariable = { integrationType: integration?.type };

    const isUpdatedStoreURL = has(changedFields, 'storeUrl') && !has(changedFields, 'shopName');
    const isUpdatedShopName = has(changedFields, 'shopName') && !has(changedFields, 'storeUrl');

    if (isUpdatedStoreURL) {
      return applyVariableToMessage(
        messagesVariable,
        MESSAGES_MANAGE[MANAGE_TYPE.UPDATED_STORE_URL]
      );
    }

    if (isUpdatedShopName) {
      return applyVariableToMessage(
        messagesVariable,
        MESSAGES_MANAGE[MANAGE_TYPE.UPDATED_STORE_NAME]
      );
    }

    return applyVariableToMessage(
      messagesVariable,
      MESSAGES_MANAGE[MANAGE_TYPE.UPDATED_CREDENTIALS]
    );
  };

  const handleSaveCredentialsMessage = (isSuccessful, changedFields) => {
    if (isSuccessful) {
      editCredentialsModal.onClose();
      setCredentials({});
      refetch();
    }

    const messages = isSuccessful
      ? {
          message: handleUpdateCredentialsMessage(changedFields)
        }
      : {
          message: applyVariableToMessage(
            { integrationType: integration.type },
            MESSAGES_MANAGE.errorCreateIntegration
          ),
          type: 'warning'
        };
    showToastMessage(messages);
  };

  const handleSaveCredentials = useCallback(
    async (values, changedFields) => {
      const payload = { ...credentials, ...values };

      try {
        await checkIntegrationPasswordExpired(integration?.id);
      } catch (e) {
        setCredentials(payload);
        editCredentialsModal.onClose();
        return;
      }

      const result = await integrationUpdateMutation.mutateAsync({
        id: payload?.id,
        payload: transformPayloadIntegration(payload)
      });

      handleSaveCredentialsMessage(!!result?.data, changedFields);
    },
    [integration?.id, credentials]
  );

  const setCurrentIntegration = ({ type = '', id = null, isManageOpen = false }) => {
    const allowedTypes = [INTEGRATION_TYPE.woocommerce, INTEGRATION_TYPE.shopify];
    if (!allowedTypes.includes(type)) {
      return;
    }

    const currentIntegration = {
      id,
      type,
      logo: `/Images/${type?.toLowerCase()}-logo.png`,
      isManageOpen
    };

    setIntegration(currentIntegration);
  };

  const handleClickMarketPlaceCard = (type) => {
    if (type == INTEGRATION_TYPE.shopify_v2) {
      handleTabChange(0);
      showRefreshToast();
      window.open(SHOPIFY_STORE_URL);
      return;
    }
    setCurrentIntegration({ type });
    createIntegrationModal.onOpen();
  };

  const handleClickEditCredentials = async (data) => {
    try {
      const currentIntegration = { type: data.integrationType, id: data.id };
      setCurrentIntegration(currentIntegration);
      await checkIntegrationPasswordExpired(data?.id);
    } catch (e) {
      return;
    }

    setCredentials(data);
    editCredentialsModal.onOpen();
  };

  const getCreateMutationInstance = () => {
    const integrationType = integration.type;
    const mutationMap = {
      [INTEGRATION_TYPE.woocommerce]: createIntegrationWooCommerceMutation,
      [INTEGRATION_TYPE.shopify]: createIntegrationShopifyMutation
    };

    return mutationMap[integrationType] || null;
  };

  const showToastMessage = ({ message, type = 'success' }) => {
    const colorScheme = type === 'success' ? 'positive' : 'negative';
    toast({
      isClosable: true,
      position: 'top',
      containerStyle: {
        maxWidth: '934px'
      },
      render: ({ onClose }) => (
        <SlzToast
          colorScheme={colorScheme}
          description={message}
          status={type}
          size="lg"
          variant="solid"
          w="100%"
          onClose={onClose}
        />
      )
    });
  };

  const getIntegrationCredentials = (integrationId, integrationType) => {
    if (integrationType === INTEGRATION_TYPE.shopify) {
      return {
        password: getItemCookie(`${KEY.PASSWORD}_${integrationId}`)
      };
    }
    return null;
  };

  const handleWarehouse = async ({ isChecked, messages, integration }) => {
    const credentials = getIntegrationCredentials(integration.id, integration.integrationType);

    let payload = {
      ...integration,
      ...credentials,
      inventoryManagement: isChecked.inventory,
      connected: isChecked.connect
    };

    const result = await integrationUpdateMutation.mutateAsync({
      id: payload.id,
      payload: transformPayloadIntegration(payload)
    });

    if (!result?.data) {
      messages = { message: MESSAGES_MANAGE.errors, type: 'warning' };
    }
    setIsDisable({ connect: false, inventory: false });
    messages.forEach(function (message) {
      showToastMessage({ message });
    });
  };

  /**
   * check whether current password of integration is expired or not,
   * if the password is expired, open confirmation modal to requires user fill the password until successful
   */
  const checkIntegrationPasswordExpired = async (integrationId, appIntegration) => {
    const integrationPassword = getItemCookie(`${KEY.PASSWORD}_${integrationId}`);
    if (!!appIntegration) {
      return;
    }

    if (!integrationPassword) {
      passwordConfirmationModal.onOpen();
      throw new Error('password is expired...');
    }

    try {
      await checkPasswordIntegrationFromAPI(integrationPassword, integrationId);
    } catch (error) {
      passwordConfirmationModal.onOpen();
      throw new Error('password is invalid...');
    }
  };

  const saveIntegration = async ({ isChecked, integration }) => {
    try {
      await checkIntegrationPasswordExpired(integration?.id, integration?.appIntegration);
    } catch (e) {
      return;
    }

    let messages = [];

    if (isChecked.connect != integration.connected) {
      let status = isChecked.connect ? 'connected' : 'disconnected';
      let messagesParams = { status, shopName: integration?.shopName };
      messages.push(applyVariableToMessage(messagesParams, MESSAGES_MANAGE[MANAGE_TYPE.CONNECT]));
    }

    if (isChecked.inventory != integration.inventoryManagement) {
      let status = isChecked.inventory ? 'on' : 'off';
      let messagesParams = { status, shopName: integration?.shopName };
      messages.push(applyVariableToMessage(messagesParams, MESSAGES_MANAGE[MANAGE_TYPE.INVENTORY]));
    }

    setIsDisable({ connect: true, inventory: true });
    await handleWarehouse({ isChecked, messages, integration });
    refetch();
  };

  const handleSaveIntegration = async (currentCard, integration, isChecked) => {
    let saveRequired =
      isChecked.connect != integration.connected ||
      isChecked.inventory != integration.inventoryManagement;
    if (saveRequired) {
      saveIntegration({ isChecked, integration });
      return;
    } else {
      handleOpenManageIntegration(currentCard);
    }
  };

  const handleOpenManageIntegration = async ({
    type,
    id,
    isShopifyVersion2,
    isConfiguredRateMatcher
  }) => {
    const shouldShowRateMatcherToast = isShopifyVersion2 && !isConfiguredRateMatcher;
    if (shouldShowRateMatcherToast) {
      showRateMatcherConfigToast();
      handleUpdateIntegrations(id);
      return;
    }
    if (isShopifyVersion2) {
      handleUpdateIntegrations(id);
      return;
    }
    try {
      setCurrentIntegration({ type, id, isManageOpen: true });
      await checkIntegrationPasswordExpired(id);
    } catch (e) {
      return;
    }
    handleUpdateIntegrations(id);
  };

  const handleValidatePasswordIntegration = useCallback(
    async ({ password }) => {
      const integrationType = integration.type;
      const message = applyVariableToMessage(
        { integrationType },
        MESSAGES_MANAGE.passwordValidationError
      );

      if (!password) {
        showToastMessage({ message, type: 'warning' });

        return;
      }

      try {
        await checkPasswordIntegrationFromAPI(password);
      } catch (error) {
        showToastMessage({ message, type: 'warning' });
        return;
      }

      handleUpdateIntegrations(integration?.id, true);
      passwordConfirmationModal.onClose();

      /**
       * If you have opened modals edit Credentials before, but the password is gone after entering the password,
       * you need to open modal edit again.
       */
      const isFirstTimeClickedToCredentials = Object.keys(credentials).length;
      if (isFirstTimeClickedToCredentials) {
        editCredentialsModal.onOpen();
      }
    },
    [integration.type, integration.id]
  );

  const handleUpdateIntegrations = (id, isSubmitFromManageIntegration) => {
    // const updatedIntegrations = integrations.map((item) => {
    //   /**
    //    * If you are in the edit integration state and the password expires after updating the password,
    //    * you need to keep the edit integration state otherwise, you need to set it according to the status
    //    */
    //   const isAllowsManageWhenSubmitFrom = isSubmitFromManageIntegration && !item.isEditable;
    //   const isEditable = isAllowsManageWhenSubmitFrom ? item.isEditable : !item.isEditable;
    //   return item.id === id ? { ...item, isEditable } : item;
    // });
    setIntegrations((prevIntegrations) => {
      return prevIntegrations.map((item) => {
        /**
         * If you are in the edit integration state and the password expires after updating the password,
         * you need to keep the edit integration state otherwise, you need to set it according to the status
         */
        const isAllowsManageWhenSubmitFrom = isSubmitFromManageIntegration && !item.isEditable;
        const isEditable = isAllowsManageWhenSubmitFrom ? item.isEditable : !item.isEditable;
        return item.id === id ? { ...item, isEditable } : item;
      });
    });
  };

  const checkPasswordIntegrationFromAPI = async (password, integrationId) => {
    try {
      const integrationID = integrationId ?? integration?.id;
      // handle call API and handle error when enter password not correct
      const result = await verifyPasswordMutation.mutateAsync({
        integrationID,
        password
      });

      if (typeof result === 'boolean' && result) {
        setItemCookie({
          key: `${KEY.PASSWORD}_${integrationID}`,
          value: password,
          numberOfMinutes: DEFAULT_EXPIRED_PASSWORD_INTEGRATION_IN_MINUTES
        });
      } else {
        throw new Error('password is invalid...');
      }

      // assuming the user always enters the correct password
    } catch (error) {
      throw error;
    }
  };

  /**
   * If password is expired or invalid, not allow user to close the modal with no onClose callback
   */
  const handlePasswordConfirmationClose = useCallback(() => {
    const integrationPassword = getItemCookie(`${KEY.PASSWORD}_${integration?.id}`);

    return integrationPassword || (integration.isManageOpen && passwordConfirmationModal.onClose());
  }, [integration.isManageOpen, integration.id]);

  /**
   * Update an integration in current integrations by id
   */
  const updateIntegrationsById = (id, changedFields = {}) => {
    setIntegrations((prevIntegrations) => {
      return prevIntegrations.map((integration) =>
        integration.id === id ? { ...integration, ...changedFields } : integration
      );
    });
  };

  const handleEditCredentialsModalClose = useCallback(() => {
    editCredentialsModal.onClose();
    setCredentials({});
  }, []);

  const showRefreshToast = () => {
    toast({
      isClosable: true,
      position: 'top',
      duration: null,
      containerStyle: {
        maxWidth: '934px'
      },
      render: ({ onClose }) => (
        <SlzToast
          colorScheme="positive"
          status="success"
          size="lg"
          variant="solid"
          w="100%"
          onClose={onClose}
          render={() => <RefreshToastContent />}
        />
      )
    });
  };

  const showRateMatcherConfigToast = () => {
    if (toast.isActive(RATE_MATCHER_TOAST_ID)) {
      return;
    }
    toast({
      id: RATE_MATCHER_TOAST_ID,
      isClosable: true,
      position: 'top',
      containerStyle: {
        maxWidth: '934px'
      },
      render: ({ onClose }) => (
        <SlzToast
          colorScheme="positive"
          status="success"
          size="lg"
          variant="solid"
          w="100%"
          onClose={onClose}
          render={() => (
            <HStack ps={6} spacing={4}>
              <Text fontSize="1rem" fontWeight="normal">
                You are nearly there! The final step is <u>the rate matcher configuration</u>
              </Text>
            </HStack>
          )}
        />
      )
    });
  };

  const handleClickRateMatcherButton = useCallback(
    ({ id, shopName, metadata: { shopifyShippingRatePreferences = [] } }) => {
      handleEditCredentialsModalClose();
      setIntegration({
        integrationId: id,
        shopName: shopName,
        shopifyShippingRatePreferences
      });
      rateMatcherSettingModal.onOpen();
    },
    []
  );

  const handleOnClickManageApi = (apiId) => {
    setApiConnectionId(apiId);
    createApiIntegrationModal.onOpen();
  };

  const handleClickNewApi = () => {
    setApiConnectionId(null);
    createApiIntegrationModal.onOpen();
  };

  const handleCloseApiIntegrationModal = () => {
    setApiConnectionId(null);
    createApiIntegrationModal.onClose();
  };

  const handleClickNewIntegration = () => {
    handleTabChange(2);
  };

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
    return () => toast.closeAll();
  }, []);

  useEffect(() => {
    const createMutation = getCreateMutationInstance();

    if (createMutation?.isSuccess) {
      const messagesVariable = { integrationType: integration.type };
      const toastTitleSuccess = applyVariableToMessage(
        messagesVariable,
        MESSAGES_MANAGE[MANAGE_TYPE.CREATED_SUCCESS]
      );
      showToastMessage({ message: toastTitleSuccess });
      createIntegrationModal.onClose();
      handleTabChange(0);
    }
  }, [createIntegrationWooCommerceMutation.isSuccess, createIntegrationShopifyMutation.isSuccess]);

  useEffect(() => {
    const createMutation = getCreateMutationInstance();
    const createMutationError = createMutation?.error;

    if (createMutationError) {
      const errorResponseData = createMutationError?.response?.data;
      const message = errorResponseData?.message;
      const shopName = createMutation?.variables?.shopName;
      const messagesVariable = { integrationType: integration.type, shopName };
      const errorMessage = applyVariableToMessage(
        messagesVariable,
        MESSAGES_MANAGE[
          message?.includes(INTEGRATION_EXIST_ERROR)
            ? MANAGE_TYPE.ALREADY_INTEGRATION
            : MANAGE_TYPE.ERROR_CREATE_INTEGRATION
        ]
      );
      showToastMessage({ message: errorMessage, type: 'warning' });
    }
  }, [createIntegrationWooCommerceMutation.isError, createIntegrationShopifyMutation.isError]);

  useEffect(() => {
    const integrationResult = integrationUpdateMutation?.data?.data;
    if (!integrationResult) return;
    const { id, metadata } = integrationResult;
    const { inventoryManagement } = JSON.parse(metadata);
    const connected = integrationResult.connected;

    updateIntegrationsById(id, { inventoryManagement, connected });
  }, [integrationUpdateMutation.isSuccess]);

  useEffect(() => {
    setIntegrations(fetchedIntegrations);
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (!isEmpty(credentials)) {
      updateIntegrationsById(credentials.id, { shopName: credentials.shopName });
    }
  }, [credentials]);

  useEffect(() => {
    const tabName = routerQuery.get(TAB) ?? INTEGRATION_TAB.my_app.key;
    const tabIndex =
      Object.keys(INTEGRATION_TAB).findIndex(
        (key) => key === (routerQuery.get(TAB) ?? INTEGRATION_TAB.my_app.key)
      ) || 0;
    setIntegrationTab(tabName);
    setTabIndex(tabIndex);
  }, [routerQuery.get(TAB)]);

  return (
    <Fragment>
      {rateMatcherSettingModal.isOpen && (
        <RateMatcherSettingModal
          integrationId={integration.integrationId}
          shopName={integration.shopName}
          shopifyShippingRatePreferences={integration.shopifyShippingRatePreferences}
          isOpen={rateMatcherSettingModal.isOpen}
          onClose={rateMatcherSettingModal.onClose}
          refetch={refetch}
        />
      )}

      <CreateIntegrationModal
        title="Set up a new integration"
        type={integration.type}
        logo={integration.logo}
        isOpen={createIntegrationModal.isOpen}
        onClose={createIntegrationModal.onClose}
        onSubmit={handleCreateIntegrationSubmit}
        isSubmitting={
          createIntegrationWooCommerceMutation.isLoading ||
          createIntegrationShopifyMutation.isLoading
        }
      />

      <EditCredentialsModal
        type={integration.type}
        logo={integration.logo}
        isOpen={editCredentialsModal.isOpen}
        onClose={handleEditCredentialsModalClose}
        onSubmit={handleSaveCredentials}
        onClickRateMatcher={handleClickRateMatcherButton}
        credentials={credentials}
        isSubmitting={integrationUpdateMutation.isLoading}
      />

      <ManageIntegrationModal
        isOpen={passwordConfirmationModal.isOpen}
        logo={integration.logo}
        onSubmit={handleValidatePasswordIntegration}
        onClose={handlePasswordConfirmationClose}
      />

      <CreateApiIntegrationModal
        isOpen={createApiIntegrationModal.isOpen}
        apiConnectionId={apiConnectionId}
        onClose={handleCloseApiIntegrationModal}
        refetch={refetchApiConnection}
      />

      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          {integrationTab === INTEGRATION_TAB.my_app.key && (
            <SlzButton size="lg" onClick={handleClickNewIntegration}>
              New integration
            </SlzButton>
          )}

          {integrationTab === INTEGRATION_TAB.api.key && (
            <SlzButton size="lg" onClick={handleClickNewApi}>
              Create new API
            </SlzButton>
          )}

          <IntegrationTabs tabIndex={tabIndex} onChange={handleTabChange} />
          {integrationTab === INTEGRATION_TAB.my_app.key && (
            <MyApp
              isDisable={isDisable}
              cards={integrations}
              isLoading={isFetching}
              onClickEditCredentials={handleClickEditCredentials}
              onOpenManageIntegration={handleOpenManageIntegration}
              onSaveIntegration={handleSaveIntegration}
              onClickRateMatcher={handleClickRateMatcherButton}
            />
          )}
          {integrationTab === INTEGRATION_TAB.api.key && isSuccessFetchedApiConnection && (
            <API
              apiConnections={fetchedApiConnection}
              refetch={refetchApiConnection}
              onClickSetupNewApi={handleClickNewApi}
              onClickManageApi={handleOnClickManageApi}
            />
          )}
          {integrationTab === INTEGRATION_TAB.market_place.key && (
            <MarketPlace onClickMarketPlaceCard={handleClickMarketPlaceCard} />
          )}
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
}
