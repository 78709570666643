export const notifications = [
  {
    key: 1,
    text: 'Notifications feed'
  },
  {
    key: 2,
    text: 'My activity feed'
  }
];

export const breadcrumbConfigs = {
  '/inventory/products': [
    {
      path: '/',
      title: 'Home'
    },
    {
      path: '/inventory',
      title: 'Inventory'
    },
    {
      path: '/inventory/products',
      title: 'Products'
    }
  ],
  '/inventory/orders': [
    {
      path: '/',
      title: 'Home'
    },
    {
      path: '/section',
      title: 'Inventory'
    },
    {
      path: '/section/subsection',
      title: 'Orders'
    }
  ]
};

export const filterOptions = [
  {
    key: 1,
    text: 'Received',
    colorScheme: 'szrElectricBlue'
  },
  {
    key: 2,
    text: 'Part',
    colorScheme: 'ibiza'
  },
  {
    key: 3,
    text: 'Processing',
    colorScheme: 'californiaOrange'
  },
  {
    key: 4,
    text: 'Processed',
    colorScheme: 'tetra'
  },
  {
    key: 5,
    text: 'Unsuccessful',
    colorScheme: 'negative'
  }
];
export const itemsSplitButton = [
  {
    value: 1,
    text: 'Option 1',
    isDisabled: false
  },
  {
    value: 2,
    text: 'Option 2',
    isDisabled: false
  },
  {
    value: 3,
    text: 'Option 3',
    isDisabled: false
  },
  {
    value: 4,
    text: 'Option 4 (Long text)',
    isDisabled: false
  },
  {
    value: 5,
    text: 'Option 5',
    isDisabled: true
  }
];
export const itemsNotificationWindows = [
  {
    value: 1,
    text: 'Product on very low stock',
    isImportant: true
  },
  {
    value: 2,
    text: 'Invoice due',
    isImportant: true
  },
  {
    value: 3,
    text: 'Return received action required',
    isImportant: false
  },
  {
    value: 4,
    text: 'Notification 4',
    isImportant: false
  },
  {
    value: 5,
    text: 'Notification 5',
    isImportant: false
  },
  {
    value: 6,
    text: 'Notification 6',
    isImportant: false
  }
];
