import { Box, Input, Text, VStack } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import uniqueId from 'lodash/uniqueId';
import { SEL_PREFIX_ID } from '../Constants';
import { SlzButton } from '../SlzButton';

import React, { useCallback } from 'react';

import './index.scss';

const uploadDefaultForm = (
  <VStack
    mb={2}
    px={6}
    py={4}
    sx={{
      background: 'light.300',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: 'dark.300',
      borderRadius: 3
    }}
    textAlign="center">
    <Text fontSize="sm" color="gray" mb={3}>
      Drag and drop your product image here or
    </Text>
    <SlzButton>Browse</SlzButton>
  </VStack>
);

const SlzUpload = (props) => {
  const {
    acceptType = 'image/*',
    onLoadFile,
    onLoadFileError,
    uploadForm = uploadDefaultForm
  } = props;
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        onLoadFile && onLoadFile(file, reader);
      };
      if (acceptType === '.csv') {
        reader.readAsText(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptType,
    onDropRejected: () => {
      console.log(`only ${acceptType} file accepted`);
      onLoadFileError(`only ${acceptType} file accepted`);
    }
  });

  return (
    <Box {...getRootProps()} id={uniqueId(`${SEL_PREFIX_ID}-Slz-Upload`)} {...props}>
      <Input {...getInputProps()} accept={acceptType} type="file" />
      {uploadForm}
    </Box>
  );
};

export default SlzUpload;
