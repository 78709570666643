import React from 'react';
import { Select, chakraComponents, useChakraSelectProps } from 'chakra-react-select';
import { Checkbox, useMultiStyleConfig } from '@chakra-ui/react';
import chakraStyles from './styles';

const components = {
  Option: (props) => {
    const { data, selectProps, isSelected, isDisabled } = props;

    if (selectProps.isMulti) {
      return (
        <chakraComponents.Option {...props}>
          <Checkbox isChecked={isSelected} isReadOnly={isDisabled}>
            {data.label}
          </Checkbox>
        </chakraComponents.Option>
      );
    }

    return <chakraComponents.Option {...props} />;
  },
  MultiValue: ({ selectProps, ...rest }) => {
    const size = selectProps.size === 'lg' ? 'md' : selectProps.size;
    const multiValueProps = { ...rest, selectProps: { ...selectProps, size } };

    return <chakraComponents.MultiValue {...multiValueProps} />;
  }
};

export default function SlzSelect(props) {
  const inputStyles = useMultiStyleConfig('Input', { variant: 'outline' });

  const selectProps = useChakraSelectProps({
    useBasicStyles: true,
    tagVariant: 'outline',
    colorScheme: 'default',
    placeholder: 'Select',
    errorMessage: 'Error message',
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.isMulti,
    components,
    chakraStyles,
    inputStyles,
    ...props
  });

  return <Select {...selectProps} />;
}
