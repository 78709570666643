import { HStack } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const BASE_INPUT_STYLE = {
  borderRadius: '1.5',
  h: '1rem'
};

const BASE_BLOCK_STYLE = {
  borderRadius: '1.5'
};

const TaxesAndDuties = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input titleStyle={{ w: '8.438rem', ...BASE_INPUT_STYLE }} mb="1.563rem">
      <ShimmerSkeleton.Block w="14.063rem" h="1rem" mb="0.438rem" {...BASE_BLOCK_STYLE} />
      <ShimmerSkeleton.Block h="7.625rem" {...BASE_BLOCK_STYLE} />
    </ShimmerSkeleton.Input>
    <ShimmerSkeleton.Input titleStyle={{ w: '11.25rem', ...BASE_INPUT_STYLE }} mb="1.938rem">
      <ShimmerSkeleton.Block w="80%" h="2.625rem" mb="0.438rem" {...BASE_BLOCK_STYLE} />
      <ShimmerSkeleton.Block h="18.688rem" {...BASE_BLOCK_STYLE} />
    </ShimmerSkeleton.Input>
    <ShimmerSkeleton.Input
      titleStyle={{ w: '11.25rem', ...BASE_INPUT_STYLE }}
      inputStyle={{ h: '5.25rem', ...BASE_BLOCK_STYLE }}
    />
  </ShimmerSkeleton>
);

const Payment = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input
      titleStyle={{ w: '7.5rem', mb: '0.75rem', ...BASE_INPUT_STYLE }}
      inputStyle={{ w: '14.993rem', h: '10.378rem', ...BASE_BLOCK_STYLE }}
      mb="2.188rem"
    />
    <ShimmerSkeleton.Input titleStyle={{ w: '11.25rem', mb: '0.625rem', ...BASE_INPUT_STYLE }}>
      <ShimmerSkeleton.Block h="71.78px" mb="0.625rem" {...BASE_BLOCK_STYLE} />
      <ShimmerSkeleton.Block h="10.378rem" w="14.993rem" {...BASE_BLOCK_STYLE} />
    </ShimmerSkeleton.Input>
  </ShimmerSkeleton>
);

const CustomerSupport = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input titleStyle={{ w: '15rem', mb: '0.5rem', ...BASE_INPUT_STYLE }}>
      <ShimmerSkeleton.Block h="2.813rem" mb="0.5rem" {...BASE_BLOCK_STYLE} />
      <ShimmerSkeleton.Block h="3.929rem" w="25.46rem" {...BASE_BLOCK_STYLE} />
    </ShimmerSkeleton.Input>
  </ShimmerSkeleton>
);

const Courier = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '9.375rem', mb: '1rem' }}
      mb="2.688rem">
      <ShimmerSkeleton.Input titleStyle={{ ...BASE_INPUT_STYLE, w: '108px' }}>
        <HStack alignItems="flex-start" wrap="wrap">
          <ShimmerSkeleton.Block h="7.25rem" w="9.75rem" mb="0.625rem" {...BASE_BLOCK_STYLE} />
          <ShimmerSkeleton.Block h="7.25rem" w="9.75rem" mb="0.625rem" {...BASE_BLOCK_STYLE} />
          <ShimmerSkeleton.Block h="7.25rem" w="15rem" mb="0.625rem" {...BASE_BLOCK_STYLE} />
          <ShimmerSkeleton.Block h="9rem" w="15rem" ml="0 !important" {...BASE_BLOCK_STYLE} />
          <ShimmerSkeleton.Block h="5.5rem" w="9.75rem" {...BASE_BLOCK_STYLE} />
        </HStack>
      </ShimmerSkeleton.Input>
    </ShimmerSkeleton.Input>
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, h: '1.625rem', w: '7.5rem', mb: '1rem' }}
      mb="2.688rem">
      <HStack spacing="0.75rem" alignItems="flex-start">
        <ShimmerSkeleton.Block h="9rem" w="9.75rem" {...BASE_BLOCK_STYLE} />
        <ShimmerSkeleton.Block h="5.5rem" w="9.75rem" {...BASE_BLOCK_STYLE} />
      </HStack>
    </ShimmerSkeleton.Input>
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, h: '1.625rem', w: '18rem', mb: '1rem' }}>
      <HStack spacing="0.75rem " alignItems="flex-start">
        <ShimmerSkeleton.Block h="3rem" w="9.75rem" {...BASE_BLOCK_STYLE} />
        <ShimmerSkeleton.Block h="3rem" w="9.75rem" {...BASE_BLOCK_STYLE} />
      </HStack>
    </ShimmerSkeleton.Input>
  </ShimmerSkeleton>
);

const Order = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input titleStyle={{ ...BASE_INPUT_STYLE, w: '15.625rem', mb: '0.625rem' }}>
      <ShimmerSkeleton.Block h="2.313rem" w="23.688rem" mb="0.375rem" {...BASE_BLOCK_STYLE} />
      <ShimmerSkeleton.Block h="3.929rem" w="25.46rem" {...BASE_BLOCK_STYLE} />
    </ShimmerSkeleton.Input>
  </ShimmerSkeleton>
);

const Return = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '12.5rem', mb: '0.25rem' }}
      inputStyle={{ h: '3.75rem', ...BASE_BLOCK_STYLE }}
      mb="2.25rem"
    />
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '12.5rem' }}
      inputStyle={{ h: '10.144rem', ...BASE_BLOCK_STYLE }}
      mb="1.25rem"
    />
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '12.5rem' }}
      inputStyle={{ h: '25.25rem', ...BASE_BLOCK_STYLE }}
    />
  </ShimmerSkeleton>
);

const Packaging = (props) => (
  <ShimmerSkeleton w="100%" mb="2.188rem" {...props}>
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '45%', mb: '0.25rem' }}
      inputStyle={{ h: '3.75rem', ...BASE_BLOCK_STYLE }}
      mb="1.25rem"
    />
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '45%', mb: '0.25rem' }}
      inputStyle={{ h: '3.75rem', ...BASE_BLOCK_STYLE }}
      mb="1.25rem"
    />
    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '45%', mb: '0.25rem' }}
      inputStyle={{ h: '3.75rem', ...BASE_BLOCK_STYLE }}
      mb="1.25rem"
    />

    <ShimmerSkeleton.Input
      titleStyle={{ ...BASE_INPUT_STYLE, w: '12.5rem', mb: '0.25rem' }}
      inputStyle={{ h: '16.938rem', w: '14.963rem', ...BASE_BLOCK_STYLE }}
    />
  </ShimmerSkeleton>
);

const SETTING_TEMPLATES = {
  taxesDuties: TaxesAndDuties,
  paymentMethods: Payment,
  customerSupportAccess: CustomerSupport,
  courierSettings: Courier,
  orderSettings: Order,
  returnSettings: Return,
  packagingSettings: Packaging
  // warehouseSettings: WarehouseSetting
};

const PreferenceSkeleton = (props) => {
  return <BaseSkeleton baseTemplates={SETTING_TEMPLATES} {...props} />;
};

export default PreferenceSkeleton;
