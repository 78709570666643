import React from 'react';

import { Flex, FormControl, HStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { SlzButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzTextarea } from 'components/common/SlzTextarea';
import BundleTracking from '../other/BundleTracking';
import FormLabel from './FormLabel';
import ProductCardsForm from './ProductCardsForm';
import Tag from './Tag';
import { TAG_DEFAULT_COLOR } from 'components/common/Constants';

export default function BundleConfirmationForm({ defaultValues, onSubmit, onCancel, onPrevious }) {
  const { productBundleCompanyItems } = defaultValues;
  const methods = useForm({
    defaultValues
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <BundleTracking bundleItems={productBundleCompanyItems} isEdit={false} />

        <Flex gap="12px" mb={5}>
          <FormControl flexBasis="39.2%">
            <FormLabel>Bundle SKU</FormLabel>
            <SlzInput
              size="sm"
              placeholder="Bundle SKU"
              disabled
              {...methods.register('skuCode')}
            />
          </FormControl>

          <FormControl flexBasis="18.9%">
            <FormLabel>Price</FormLabel>
            <SlzInput
              type="number"
              min="0"
              step="0.01"
              size="sm"
              textAlign="right"
              leftElContent={'GBP £'}
              disabled
              {...methods.register('price')}
            />
          </FormControl>
          <FormControl flexBasis="38.9%">
            <FormLabel>Bundle tag</FormLabel>
            <Tag
              justifyContent="flex-start"
              color="white"
              bg={defaultValues.tag.colorCode || TAG_DEFAULT_COLOR}>
              {defaultValues.tag.text}
            </Tag>
          </FormControl>
        </Flex>

        <Flex gap="12px" mb={10}>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <SlzTextarea
              size="sm"
              rows="3"
              placeholder="Description"
              disabled
              {...methods.register('description')}
            />
          </FormControl>
        </Flex>

        <ProductCardsForm products={productBundleCompanyItems} isEditMode={false} />

        <Flex mb={5} mt={10} justifyContent="space-between">
          <SlzButton size="md" onClick={onPrevious}>
            Previous
          </SlzButton>

          <HStack spacing={5}>
            <SlzButton variant="outline" size="md" colorScheme="negative" onClick={onCancel}>
              Cancel
            </SlzButton>
            <SlzButton type="submit" size="md">
              Next
            </SlzButton>
          </HStack>
        </Flex>
      </form>
    </FormProvider>
  );
}
