import { Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import AddImageField from './AddImageField';

const AddImagesForm = ({ items }) => {
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'products'
  });

  const onImageLoad = (index) => (file) => {
    const objectUrl = URL.createObjectURL(file);
    setValue(`products.${index}.imageFile`, file);
    setValue(`products.${index}.imageUrl`, objectUrl);
  };

  return (
    <Stack mt={3}>
      <Text lineHeight="21px" mb={0}>
        An image is required for all products if your returns are being handled by Selazar. A
        product will not be active until an image has been added.
      </Text>
      <VStack gap={1} minW="765px">
        {fields.map((field, index) => (
          <AddImageField
            name={'products'}
            errors={errors}
            item={field}
            control={control}
            index={index}
            onLoadImageFile={onImageLoad(index)}
          />
        ))}
      </VStack>
    </Stack>
  );
};

export default AddImagesForm;
