import { Checkbox, Text } from '@chakra-ui/react';
import { VAT_REGISTERED_MODE } from '../../constants';

const TagCheckbox = ({ country, value, isEuVatEnabled, mode, ...rest }) => {
  return (
    <Checkbox
      maxH="1.25rem"
      size="sm"
      pl="0.375rem"
      py="0.125rem"
      borderRadius="1.5"
      variant="circular"
      backgroundColor="light.500"
      color="dark.500"
      colorScheme="main"
      isDisabled={mode === VAT_REGISTERED_MODE.start}
      {...rest}
      isChecked={value && isEuVatEnabled}>
      <Text ml="-0.25rem" mt="-0.063rem" color="currentColor" fontSize="0.75rem" pr="0.75rem">
        {country}
      </Text>
    </Checkbox>
  );
};

export default TagCheckbox;
