import * as yup from 'yup';

export const stepOneSchema = yup
  .object({
    purchaseOrderNumber: yup.string().required('Purchase Order Number is required'),
    recipient: yup.object().typeError('Recipient is required').required('Recipient is required')
    // contactDetails: yup.object({
    //   name: yup.string().required('Name is required'),
    //   contactNumber: yup
    //     .string()
    //     .matches(/\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/, 'Phone is required')
    //     .required('Phone is required'),
    //   emailAddress: yup
    //     .string()
    //     .email()
    //     .matches(/^(?!.*@[^,]*,)/)
    //     .required('Email is required')
    // }),
    // destinationAddress: yup.object({
    //   addressLine1: yup.string().required('Address line 1 is required'),
    //   town: yup.string().required('Town is required'),
    //   county: yup.string().required('County is required'),
    //   postCode: yup.string().required('Postcode is required'),
    //   country: yup
    //     .object()
    //     .typeError('Country code is required')
    //     .required('Country code is required')
    // })
  })
  .required();

export const modifyRecipientSchema = yup.object({
  name: yup.string().required('Name is required'),
  companyName: yup.string().required('Company name is required'),
  phone: yup
    .string()
    .matches(/\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/, 'Phone is required')
    .required('Phone is required'),
  email: yup
    .string()
    .email()
    .matches(/^(?!.*@[^,]*,)/)
    .required('Email is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  town: yup.string().required('Town is required'),
  county: yup.string().required('County is required'),
  postCode: yup.string().required('Postcode is required'),
  country: yup.object().typeError('Country code is required').required('Country code is required')
});

// to do validation something
