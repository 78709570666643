import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
const baseStyle = defineStyle((props) => {
  return {
    container: {
      position: 'relative',
      fontSize: '1rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      w: '100%',
      h: 12,
      backgroundColor: `lightAndShadow.darkModeText`,
      borderWidth: '0.063rem',
      borderStyle: 'solid',
      borderColor: 'dark.300',
      borderRadius: '0.375rem',
      input: {
        display: 'block',
        w: 40,
        p: 0,
        pl: 6,
        cursor: 'pointer',
        h: '100%',
        border: 'none',
        _focus: { outline: 'none' }
      },
      '&.active': {
        borderColor: 'main.500',
        '.date-range-input-arrow-icon': {
          borderTop: 'none',
          borderBottom: 'none'
        }
      }
    },
    arrowIcon: {
      minW: 12,
      w: 12,
      h: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'light.300',
      borderLeftWidth: `0.063rem`,
      borderRightWidth: `0.063rem`,
      borderStyle: 'solid',
      borderColor: 'dark.300',
      svg: { w: 6, h: 6, color: 'dark.500' },
      '.active': {
        minW: 12,
        w: 12,
        h: '100%',
        svg: { w: 6, h: 6, color: 'dark.500' }
      }
    },
    inputGroup: {
      rightElement: {
        right: '0.375rem',
        svg: { w: 4, h: 4 }
      }
    }
  };
});

const sizes = {
  sm: defineStyle({
    h: '1.875rem',
    rounded: '0.1875rem'
  }),
  md: defineStyle({
    h: '3rem',
    rounded: '0.375rem'
  })
};

export default defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    colorScheme: 'main'
  }
});
