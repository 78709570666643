import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'navbar',
  'navbarSection',
  'navbarButton',
  'collapseButton',
  'buttonLink'
]);

const SlzNavbar = helpers.defineMultiStyleConfig({
  baseStyle: {
    navbar: {
      position: 'fixed',
      top: 0,
      left: 0,
      bgColor: 'szrNavy.500',
      zIndex: 'banner',
      px: '1.0625rem',
      transition: 'all 0.2s'
    },
    navbarSection: {
      position: 'fixed',
      boxShadow: '3px 0px 6px #00000030',
      bgColor: 'light.500',
      transition: 'left 0.2s, right 0.2s'
    },
    navbarButton: {
      position: 'relative',
      color: 'white',
      justifyContent: 'flex-start',
      p: '0.9375rem',
      bgColor: 'szrNavy.500',
      _hover: {
        bgColor: '#F2F2F226'
      },
      _active: {
        border: '1px solid',
        borderColor: '#FFFFFF65',
        bgColor: '#F2F2F226'
      },
      _before: {
        pos: 'absolute',
        top: 0,
        left: '4rem',
        height: '100%',
        lineHeight: '3.875rem',
        fontSize: '1rem',
        transition: 'all 0.2s'
      },
      '&[data-show-arrow="true"]': {
        _after: {
          content: '""',
          position: 'absolute',
          top: '50%',
          right: '-1.125rem',
          transform: 'translateY(-50%)',
          w: '0px',
          h: '1.25rem',
          border: '10px solid',
          borderColor: 'transparent',
          borderRightColor: 'light.500'
        }
      }
    },
    collapseButton: {
      color: 'white',
      padding: '0 0.625rem',
      transition: 'all 0.2s',
      bgColor: 'szrNavy.500',
      _hover: {
        bgColor: '#F2F2F226'
      }
    },
    buttonLink: {
      display: 'flex',
      alignItems: 'center',
      w: 'full',
      borderBottom: '1px solid #C3C4CA',
      _hover: {
        bg: 'light.700'
      },
      _active: {
        bg: 'main.300'
      },
      _focus: {
        outline: 'none'
      }
    }
  },
  sizes: {
    sm: {
      navbar: {},
      navbarSection: {},
      navbarButton: {},
      collapseButton: {},
      buttonLink: {}
    },
    md: {
      navbar: {},
      navbarSection: {},
      navbarButton: {
        rounded: 'xl'
      },
      collapseButton: {
        rounded: 'md'
      },
      buttonLink: {}
    }
  },
  variants: {
    minimal: ({ theme }) => ({
      navbar: {
        height: '100vh',
        width: 24
      },
      navbarSection: {
        left: 24,
        height: '100vh',
        width: '15rem'
      },
      navbarButton: {
        width: '3.875rem',
        height: '3.875rem',
        justifyContent: 'center'
      },
      collapseButton: {
        w: 8,
        h: 8,
        [`@media screen and (max-width: ${theme.breakpoints.xl})`]: {
          visibility: 'hidden'
        }
      },
      buttonLink: {
        px: 4,
        py: 3,
        gap: 2
      }
    }),
    expanded: {
      navbar: {
        height: '100vh',
        width: '15.5rem'
      },
      navbarSection: {
        left: '15.5rem',
        height: '100vh',
        width: '15rem'
      },
      navbarButton: {
        width: 'full',
        height: '3.875rem'
      },
      collapseButton: {
        w: 'calc(100% + 1.5rem)',
        h: 8,
        overflow: 'hidden',
        justifyContent: 'flex-end',
        pos: 'relative',
        _after: {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: -1,
          w: '32px',
          h: 'full',
          fontSize: 'md',
          bgColor: 'szrNavy.500',
          transition: 'all 0.2s',
          textAlign: 'left',
          lineHeight: 2.2
        },
        _hover: {
          _after: {
            content: '"Collapse menu"',
            w: 'full',
            px: '10px'
          }
        }
      },
      buttonLink: {
        px: 4,
        py: 3,
        gap: 2
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'minimal'
  }
});

export default SlzNavbar;
