import { useMutation } from 'react-query';
import { USER_SETTING_FORMS_DEFAULT_NAME } from '../constants';
import { updateCustomerAccessPreference } from '../services/preferences-api';

const useCustomerPreference = () =>
  useMutation({
    mutationFn: (payload) =>
      updateCustomerAccessPreference(payload[USER_SETTING_FORMS_DEFAULT_NAME.customerAccess])
  });

export default useCustomerPreference;
