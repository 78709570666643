import { useQueries } from 'react-query';
import { getProductReturnSettings, getReturnConfig } from '../services/products-api';
import {
  getGradingActionsPreferenceInformation,
  getGradingActionsPreferenceOptions
} from 'modules/preferences/services/preferences-api';

const useReturnPreferencesQuery = ({ productId, enabled }) => {
  const [
    { data: productReturnSettings, isSuccess: isSuccessProductReturnSettings },
    { data: returnConfig, isSuccess: isSuccessReturnConfig },
    { data: gradingOptions, isSuccess: isSuccessGradingOptions },
    { data: gradingInformation, isSuccess: isSuccessGradingInformation }
  ] = useQueries([
    {
      queryKey: [`product-return-setting-${productId}`],
      queryFn: () => getProductReturnSettings(productId),
      enabled: enabled
    },
    {
      queryKey: [`return-config`],
      queryFn: () => getReturnConfig(),
      enabled: enabled
    },
    {
      queryKey: [`grading-actions-preference-option`],
      queryFn: () => getGradingActionsPreferenceOptions(),
      select: (data) => {
        return data?.map((option) => {
          return {
            value: option.grade,
            label: option.grade
          };
        });
      },
      enabled: enabled
    },
    {
      queryKey: [`grading-actions-preference-information`],
      queryFn: () => getGradingActionsPreferenceInformation(),
      enabled: enabled
    }
  ]);

  return {
    data: {
      productReturnSettings,
      returnConfig,
      gradingOptions,
      gradingInformation
    },
    isSuccess:
      isSuccessProductReturnSettings &&
      isSuccessReturnConfig &&
      isSuccessGradingOptions &&
      isSuccessGradingInformation
  };
};

export default useReturnPreferencesQuery;
