import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { UKFlag } from 'components/common/Icons/EnabledCentreCards/EnabledFulfilmentCentreIcons';

const FulfilmentCentreMiniCard = ({ warehouse }) => {
  return (
    <Box id='fulfilmentCentreMiniCard' w='20.248rem' h='7.5rem' display='flex' flexDirection='row' gap='0.75rem' boxShadow='lg' borderRadius='0.75rem'>
      <Image src="Images/fc_mini_card_border.svg" className="left-border" />
      <Box w='100%' display='flex' flexDirection='row' justifyContent='space-between' mt='0.938rem' mr='0.938rem'>

        <Box display='flex' flexDirection='column'>
          <Text fontSize={12} fontWeight='bold'>{warehouse?.warehouseName}</Text>
          <Text fontSize={12}>{warehouse?.address?.line1}</Text>
          {warehouse?.address?.line2 !== "" && <Text fontSize={12}>{warehouse?.address?.line2}</Text>}
          <Text fontSize={12}>{warehouse?.address?.postcode}</Text>
          <Text fontSize={12}>{warehouse?.address?.town}, {warehouse?.address?.country}</Text>
        </Box>

        <Box display='flex' flexDirection='row' gap='0.375rem'>
          <Box w='1.25rem' h='1.25rem' color='white' bg='szrNavy.500' borderRadius='0.188rem' display='flex' alignItems='center' justifyContent='center'>
            <Text>{warehouse.currency?.symbol}</Text>
          </Box>
          <UKFlag />
        </Box>
      </Box>
    </Box>
  )
}

export default FulfilmentCentreMiniCard;