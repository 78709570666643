import {
  Box,
  createIcon,
  Flex,
  HStack,
  Stack,
  StylesProvider,
  Text,
  useMultiStyleConfig,
  useStyles
} from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const CTACloseIcon = createIcon({
  displayName: 'CTACloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
  )
});

const CtaItems = ({ children, buttons, onClose, onClick }) => {
  const styles = useStyles();

  return (
    <Flex
      className="cta-class"
      data-testid="cta-wrap-item"
      flexDirection="row"
      alignItems="center"
      w="full"
      h="full"
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-CTA`)}>
      <Text sx={styles.text} data-testid="cta-content">
        {children}
      </Text>
      <Flex className="right-stack">
        <HStack sx={styles.button.wrapper}>
          {buttons ? (
            buttons
          ) : (
            <Box as="button" role="cta-confirm-btn" sx={styles.button} onClick={onClick}>
              OK
            </Box>
          )}
        </HStack>
        <Flex
          onClick={onClose}
          as="button"
          role="cta-close-btn"
          type="button"
          sx={styles.icon?.wrapper}
          justifyContent="center">
          <CTACloseIcon sx={{ cursor: 'pointer', ...styles.icon }} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const SlzCTA = (props) => {
  const { width, variant, colorScheme, size, ...rest } = props;
  const styles = useMultiStyleConfig('Cta', { variant, colorScheme, size });
  return (
    <Box __css={styles.container} width={width} data-testid="cta-wrap">
      <StylesProvider value={styles}>
        <CtaItems {...rest} />
      </StylesProvider>
    </Box>
  );
};

export default SlzCTA;
