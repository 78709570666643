import { Textarea, useMultiStyleConfig } from '@chakra-ui/react';
import { SEL_PREFIX_ID } from '../Constants';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

const SlzTextarea = React.forwardRef((props, ref) => {
  const { disabled, readonly, ...rest } = props;
  const styles = useMultiStyleConfig('Input', { size: props.size });
  const { h, ...textareaStyles } = styles.field;
  const disabledStyles = styles.field._disabled;
  const { color, ...readonlyStyles } = { ...disabledStyles };
  return (
    <Textarea
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Textarea`)}
      ref={ref}
      sx={textareaStyles}
      disabled={disabled || readonly}
      _disabled={readonly ? readonlyStyles : disabledStyles}
      {...rest}
    />
  );
});

export default SlzTextarea;
