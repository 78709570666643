import { Icon } from '@chakra-ui/react';
import React from 'react';

const CompanyIcon = (props) => {
  const { strokeWidth = '2', stroke = '#000' } = props;

  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33479" data-name="Group 33479" transform="translate(-528.332 -1853.664)">
        <line
          id="Line_1177"
          data-name="Line 1177"
          x2="18"
          transform="translate(529.332 1872.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1178"
          data-name="Line 1178"
          x2="1"
          transform="translate(535.332 1859.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1179"
          data-name="Line 1179"
          x2="1"
          transform="translate(535.332 1863.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1180"
          data-name="Line 1180"
          x2="1"
          transform="translate(535.332 1867.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1181"
          data-name="Line 1181"
          x2="1"
          transform="translate(540.332 1859.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1182"
          data-name="Line 1182"
          x2="1"
          transform="translate(540.332 1863.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <line
          id="Line_1183"
          data-name="Line 1183"
          x2="1"
          transform="translate(540.332 1867.664)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="Path_6977"
          data-name="Path 6977"
          d="M531.332,1872.664v-16a2,2,0,0,1,2-2h10a2,2,0,0,1,2,2v16"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </Icon>
  );
};

export default CompanyIcon;
