import React from 'react';
import { Icon } from '@chakra-ui/react';

const EvriLogoCompany = (props) => {
  return (
    <Icon
      id="Group_33869"
      data-name="Group 33869"
      xmlns="http://www.w3.org/2000/svg"
      width="68.76"
      height="20"
      viewBox="0 0 68.76 20"
      {...props}>
      <svg>
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_7024"
              data-name="Rectangle 7024"
              width="68.76"
              height="20"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_33866" data-name="Group 33866" clip-path="url(#clip-path)">
          <path
            id="Path_9970"
            data-name="Path 9970"
            d="M65.046,0a2.432,2.432,0,1,1-2.49,2.432A2.443,2.443,0,0,1,65.046,0M16.94.132V5.377H13.582V3.251H5.5V8.5h7.344v2.978H5.5v5.3h8.08V14.657h3.358v5.252H0V16.783H1.862V3.251H0V.132ZM28.784,14.086,34.2.132H36.27L28.413,20H26.245L18.347.132h5.07ZM40.572,9.652v-7.1H51.143c1.769,0,2.778.3,3.465.927a3.179,3.179,0,0,1,.96,2.457,3.777,3.777,0,0,1-.927,2.688C54,9.3,53.028,9.66,51.209,9.66H40.572ZM60.307,19.909l-6.989-8.073A5.621,5.621,0,0,0,58.537,5.9a5.309,5.309,0,0,0-1.531-4.053C55.774.679,53.988.132,51.144.132H37.692V19.909h2.878V12.068h9.537l6.658,7.841h3.541Zm6.907-2.664c0,1.158.48,1.63,1.546,2.167v.5H61.547v-.5c1.067-.529,1.547-1.009,1.547-2.167V9.677c0-1.025-.182-1.587-1.547-2.5v-.5h5.666Z"
            fill="#007bc4"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default EvriLogoCompany;
