import { useMutation } from 'react-query';
import { createNewApiIntegration } from '../services/integration-api';
import { editApiIntegration } from '../services/integration-api';

export const useSetupApiMutation = () => {
  return useMutation({
    mutationFn: (payload) => {
      const apiIntegrationPayload = {
        ...payload,
        permissions: Object.entries(payload.permissions)
          .filter(([, permission]) => permission && permission.value)
          .map(([key, permission]) => ({
            name: key,
            modulePermissions: [
              {
                id: permission.value,
                friendlyName: permission.label,
                access: true
              }
            ]
          }))
      };

      if (!payload.id) {
        return createNewApiIntegration(apiIntegrationPayload);
      }
      return editApiIntegration(apiIntegrationPayload);
    }
  });
};
