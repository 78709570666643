import React from 'react';
import PropTypes from 'prop-types';
import { cx, unitType } from '../../../utils';
import { outRangeDate } from '../../../utils/cx';

export default class SlzDayCell extends React.Component {
  static propTypes = {
    day: PropTypes.object.isRequired,
    calendar: PropTypes.object.isRequired,
    minDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object.isRequired
  };

  handleOnMouseEnter = () => {
    const { onDayMouseEnter, day } = this.props;
    if (onDayMouseEnter) {
      onDayMouseEnter(day);
    }
  };

  handleOnClick = () => {
    const { onDayClick, day } = this.props;
    if (onDayClick) {
      onDayClick(day);
    }
  };

  render() {
    const { day, isShowFuture, position } = this.props;
    let className = cx({ ...this.props });
    const isOutRange = outRangeDate(day, isShowFuture);
    return (
      <td
        id={`slz-calendar-day-${position}-${day.format('DD')}-${day.format('MM')}`}
        data-testid={`slz-calendar-day-${position}-${day.format('DD')}-${day.format('MM')}`}
        className={className}
        onClick={!isOutRange ? this.handleOnClick : () => { }}
        onMouseEnter={!isOutRange ? this.handleOnMouseEnter : () => { }}>
        {day.format('DD')}
      </td>
    );
  }
}
export class WeekCell extends React.Component {
  render() {
    const { week } = this.props;
    return <td className="week">{week}</td>;
  }
}

export const cellMapper = {
  [unitType.DAY]: SlzDayCell,
  [unitType.WEEK]: WeekCell
};
