import { Icon } from '@chakra-ui/react';
import React from 'react';

const ToastInfoIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <g transform="translate(1169 986) rotate(180)">
        <path
          d="M12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2m0-2A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
          transform="translate(1145 962)"
          fill=""
        />
        <path
          d="M0,9.5A1.5,1.5,0,0,1-1.5,8V0A1.5,1.5,0,0,1,0-1.5,1.5,1.5,0,0,1,1.5,0V8A1.5,1.5,0,0,1,0,9.5Z"
          transform="translate(1157 966.5)"
          fill=""
        />
        <circle cx="2" cy="2" r="2" transform="translate(1155 978)" fill="" />
      </g>
    </Icon>
  );
};

const ToastCheckIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <g transform="translate(-1145 -962)">
        <path
          d="M12,2A10,10,0,1,0,22,12,10.011,10.011,0,0,0,12,2m0-2A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
          transform="translate(1145 962)"
          fill=""
        />
        <path
          d="M2331.366,21939.85l-4-4a1.257,1.257,0,0,1,0-1.768,1.24,1.24,0,0,1,.886-.369,1.218,1.218,0,0,1,.881.369l3.116,3.115,6.833-6.832a1.25,1.25,0,1,1,1.773,1.762l-7.72,7.719a1.243,1.243,0,0,1-1.768,0Z"
          transform="translate(-1177.108 -20961.109)"
          fill=""
        />
      </g>
    </Icon>
  );
};

const ToastTriangleIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
      <g transform="translate(-1097.97 -938.747)">
        <path
          d="M12.5,4.33,3.3,22H21.7L12.5,4.33m0-2.582a1.484,1.484,0,0,1,1.33.807L23.858,21.807A1.5,1.5,0,0,1,22.527,24H2.473a1.5,1.5,0,0,1-1.33-2.193L11.17,2.554A1.484,1.484,0,0,1,12.5,1.747Z"
          transform="translate(1097 937)"
          fill=""
        />
        <path
          d="M0,11.107a1,1,0,0,1-1-1V3.791a1,1,0,0,1,1-1,1,1,0,0,1,1,1v6.316A1,1,0,0,1,0,11.107Z"
          transform="translate(1109.5 942.5)"
          fill=""
        />
        <circle cx="1.5" cy="1.5" r="1.5" transform="translate(1108 955)" fill="" />
      </g>
    </Icon>
  );
};

export {
  ToastInfoIcon,
  ToastCheckIcon,
  ToastTriangleIcon
};
