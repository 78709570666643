import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
const baseStyle = defineStyle((props) => {
  return {
    titleStyle: titleStyle,
    leftIcon: leftIcon,
    itemNotification: itemNotification
  };
});
const titleStyle = defineStyle({
  fontSize: 'xl',
  fontWeight: 'medium',
  pl: '1.5rem',
  h: '3.75rem',
  lineHeight: '3.75rem',
  color: '#303134',
  borderBottom: 'solid 1px #D0D0D0'
});
const leftIcon = defineStyle({
  w: '1.125rem',
  h: '1.5rem',
  mr: '0.75rem'
});
const itemNotification = defineStyle({
  fontSize: 'md',
  h: '3rem',
  lineHeight: '3rem',
  pl: '.75rem',
  color: '#676A74',
  borderBottom: 'solid 1px #D0D0D0',
  '&:last-of-type': {
    borderBottom: 'none'
  }
});
export default defineStyleConfig({
  baseStyle
});
