import React from 'react';
import { VStack, HStack } from '@chakra-ui/react';
import StockDetailsSummary from '../singleConsignmentUpload/StockDetailsSummary';
import SelectedProduct from '../singleConsignmentUpload/SelectedProduct';
import Warehouse from '../Warehouse';
import Shipping from './Shipping';
import GtinSummary from '../singleConsignmentUpload/GtinSummary';
import ConsignmentBoxDimensionsSummary from '../multipleConsignmentUpload/ConsignmentBoxDimensionsSummary';
import { useFormContext } from 'react-hook-form';
import { CONSIGNMENT_ARRIVING_TYPES } from 'modules/consignments/constants';

const SingleProductConsignmentSummary = ({ warehousesData, currentType, handleEditStep }) => {
  const { getValues } = useFormContext();

  const {
    consignmentBoxDimensions,
    consignmentArrivingType,
    stockBoxDimensions,
    stockBoxQuantity,
    itemsPerStockbox,
    totalQuantityItems,
    expiryDate,
    batchNumber,
    gtinSwitch,
    gtin,
    shippingReference,
    enabledLabelling,
    productLabelsSelected,
    stockBoxLabelsSelected
  } = getValues();

  return (
    <>
      <VStack mb="4.25rem">
        <HStack w="full">
          <SelectedProduct currentType={currentType} handleEditStep={handleEditStep} bg="light.700" />
          <Warehouse currentType={currentType} warehousesData={warehousesData} width="50%" handleEditStep={handleEditStep} bg="light.700" />
        </HStack>

        {consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX && (
          <VStack w="full">
            <ConsignmentBoxDimensionsSummary consignmentBoxDimensions={consignmentBoxDimensions} handleEditStep={handleEditStep} />
          </VStack>
        )}

        <HStack h="100%" w="full" display="flex" alignItems="stretch">
          <VStack w="50%">
            <StockDetailsSummary
              {...stockBoxDimensions}
              consignmentArrivingType={consignmentArrivingType}
              stockBoxQuantity={stockBoxQuantity}
              itemsPerStockbox={itemsPerStockbox}
              totalQuantityItems={totalQuantityItems}
              expiryDate={expiryDate}
              batchNumber={batchNumber}
              handleEditStep={handleEditStep}
            />
          </VStack>

          <VStack w="50%">
            <Shipping
              shippingReference={shippingReference}
              enabledLabelling={enabledLabelling}
              productLabelsSelected={productLabelsSelected}
              stockBoxLabelsSelected={stockBoxLabelsSelected}
              handleEditStep={handleEditStep}
            />
          </VStack>
        </HStack>

        {gtinSwitch && (
          <HStack w="full">
            <GtinSummary gtin={gtin} handleEditStep={handleEditStep} />
          </HStack>
        )}
      </VStack>
    </>
  );
};

export default SingleProductConsignmentSummary;
