import React from 'react';
import { Icon } from '@chakra-ui/react';

const SelazarMainIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="523.489"
      height="100.562"
      viewBox="0 0 523.489 100.562"
      {...props}>
      <svg>
        <g id="Selazar_Main" data-name="Selazar Main" transform="translate(479.257 92.065)">
          <g id="Group_160598" data-name="Group 160598" transform="translate(-479.257 -92.065)">
            <g id="Group_160594" data-name="Group 160594" transform="translate(0 8.211)">
              <g id="Group_160593" data-name="Group 160593">
                <path
                  id="Path_83257"
                  data-name="Path 83257"
                  d="M13.68,39.579l15.77,15.8a24.008,24.008,0,0,1,0,33.971L13.68,105.122a44.424,44.424,0,0,1-7.562-9.806L20.751,80.651a12.457,12.457,0,0,0,1.69-2.152H.43a44.133,44.133,0,0,1,0-12.3h21.98a10.942,10.942,0,0,0-1.659-2.121L6.086,49.416a45.743,45.743,0,0,1,7.594-9.837"
                  transform="translate(0 -26.174)"
                  fill="#dedede"
                />
                <path
                  id="Path_83258"
                  data-name="Path 83258"
                  d="M80.9,37.354,91.289,18.294a45.962,45.962,0,0,1,10.73,6.026L91.749,43.134a8.991,8.991,0,0,0,2.522-.4L113.7,37.171a46.111,46.111,0,0,1,4.95,11.343l-21,6.025a23.967,23.967,0,0,1-29.7-16.448L61.807,16.727a46.233,46.233,0,0,1,12.3-1.692L79.759,34.71A10.75,10.75,0,0,0,80.9,37.354"
                  transform="translate(-28.051 -15.035)"
                  fill="#dedede"
                />
                <path
                  id="Path_83259"
                  data-name="Path 83259"
                  d="M97.652,111.007l21,6.025a46.176,46.176,0,0,1-4.95,11.345l-19.429-5.565a11.338,11.338,0,0,0-2.644-.43l10.392,18.845a46.317,46.317,0,0,1-10.7,6.025L80.836,128.284a10.888,10.888,0,0,0-1.077,2.552L74.1,150.51a46.232,46.232,0,0,1-12.3-1.689l6.147-21.368a23.972,23.972,0,0,1,29.7-16.446"
                  transform="translate(-28.051 -58.16)"
                  fill="#dedede"
                />
              </g>
            </g>
            <g id="Group_160597" data-name="Group 160597" transform="translate(114.62)">
              <path
                id="Path_83260"
                data-name="Path 83260"
                d="M263.423,77.661q0-6.156-4.328-9.491t-15.618-6.735q-11.291-3.4-17.958-7.57-12.763-8.015-12.762-20.908,0-11.286,9.2-18.6t23.892-7.313a40.351,40.351,0,0,1,17.38,3.593,28.525,28.525,0,0,1,11.993,10.229,26.253,26.253,0,0,1,4.362,14.719H263.423q0-7.312-4.586-11.448T245.721,20q-7.951,0-12.345,3.4a11.317,11.317,0,0,0-4.393,9.492q0,5.132,4.747,8.563t15.648,6.67A72,72,0,0,1,266.887,55.5a27.835,27.835,0,0,1,9.684,9.492,24.714,24.714,0,0,1,3.079,12.538q0,11.674-8.948,18.567t-24.275,6.895A45.61,45.61,0,0,1,227.8,99.242a31.29,31.29,0,0,1-13.211-10.358,25.807,25.807,0,0,1-4.714-15.391H226.1q0,7.951,5.259,12.313t15.071,4.362q8.467,0,12.731-3.433a11.067,11.067,0,0,0,4.265-9.074"
                transform="translate(-209.87 -3.198)"
                fill="#dedede"
              />
              <path
                id="Path_83261"
                data-name="Path 83261"
                d="M381.878,63.474a13.128,13.128,0,0,0-10.357,4.491q-3.943,4.488-5.034,12.505h29.7V79.316q-.516-7.826-4.17-11.833t-10.134-4.009m1.861,59.455q-14.816,0-24.017-9.333t-9.205-24.852V86.819a41.648,41.648,0,0,1,4.009-18.567,30.5,30.5,0,0,1,11.257-12.73,29.745,29.745,0,0,1,16.161-4.554q14.173,0,21.9,9.043T411.575,85.6v6.287H366.23q.706,8.594,5.742,13.6a17.231,17.231,0,0,0,12.666,5,21.109,21.109,0,0,0,17.447-8.658l8.4,8.017a28.07,28.07,0,0,1-11.129,9.653,34.835,34.835,0,0,1-15.617,3.431"
                transform="translate(-273.703 -23.132)"
                fill="#dedede"
              />
              <rect
                id="Rectangle_147740"
                data-name="Rectangle 147740"
                width="15.585"
                height="98.514"
                transform="translate(147.751)"
                fill="#dedede"
              />
              <g id="Group_160595" data-name="Group 160595" transform="translate(173.818 27.836)">
                <path
                  id="Path_83262"
                  data-name="Path 83262"
                  d="M554.684,110.422a17.709,17.709,0,0,0,8.689-2.245,14.982,14.982,0,0,0,6.126-6.028V89.064h-8.4q-8.658,0-13.02,3.015a9.764,9.764,0,0,0-4.362,8.53,9.112,9.112,0,0,0,2.982,7.152,11.549,11.549,0,0,0,7.985,2.661m17.124,11.224a24.055,24.055,0,0,1-1.8-6.477,24.262,24.262,0,0,1-18.214,7.76q-10.455,0-17.06-5.964a19.084,19.084,0,0,1-6.606-14.753q0-11.095,8.241-17.028t23.57-5.931H569.5V74.7a12.154,12.154,0,0,0-3.014-8.626q-3.016-3.239-9.17-3.24a13.725,13.725,0,0,0-8.724,2.662,8.23,8.23,0,0,0-3.4,6.766H529.607a17.3,17.3,0,0,1,3.785-10.678,25.22,25.22,0,0,1,10.295-7.792,36.15,36.15,0,0,1,14.527-2.822q12.183,0,19.432,6.125t7.439,17.221v31.3q0,9.362,2.63,14.942v1.091Z"
                  transform="translate(-528.132 -50.968)"
                  fill="#dedede"
                />
              </g>
              <path
                id="Path_83263"
                data-name="Path 83263"
                d="M669.2,110.269h36.942v12.443H649.828v-10.2L685.04,65.887H650.472V53.316H704.8v9.877Z"
                transform="translate(-409.546 -24.198)"
                fill="#dedede"
              />
              <g id="Group_160596" data-name="Group 160596" transform="translate(302.864 27.835)">
                <path
                  id="Path_83264"
                  data-name="Path 83264"
                  d="M790.968,110.422a17.712,17.712,0,0,0,8.689-2.245,15.012,15.012,0,0,0,6.126-6.028V89.064h-8.4q-8.658,0-13.021,3.015a9.764,9.764,0,0,0-4.362,8.53,9.109,9.109,0,0,0,2.983,7.152,11.55,11.55,0,0,0,7.986,2.661m17.124,11.224a23.994,23.994,0,0,1-1.8-6.477,24.265,24.265,0,0,1-18.214,7.76q-10.455,0-17.061-5.964a19.081,19.081,0,0,1-6.605-14.753q0-11.095,8.241-17.028t23.567-5.931h9.559V74.7a12.144,12.144,0,0,0-3.016-8.626q-3.016-3.239-9.171-3.24a13.729,13.729,0,0,0-8.723,2.662,8.238,8.238,0,0,0-3.4,6.766H765.891a17.3,17.3,0,0,1,3.785-10.678,25.213,25.213,0,0,1,10.292-7.792,36.168,36.168,0,0,1,14.527-2.822q12.186,0,19.433,6.125t7.44,17.221v31.3q0,9.362,2.631,14.942v1.091Z"
                  transform="translate(-764.416 -50.967)"
                  fill="#dedede"
                />
                <path
                  id="Path_83265"
                  data-name="Path 83265"
                  d="M928.178,66.488a38.531,38.531,0,0,0-6.349-.513q-10.715,0-14.432,8.209v47.461H891.811V52.25h14.881l.385,7.76q5.643-9.043,15.649-9.043a14.593,14.593,0,0,1,5.514.9Z"
                  transform="translate(-822.235 -50.967)"
                  fill="#dedede"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default SelazarMainIcon;
