import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';

const SlzGrid = ({ children, ...props }) => {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(4, 1fr)',
        sm: 'repeat(8, 1fr)',
        md: 'repeat(12, 1fr)',
        lg: 'repeat(12, 1fr)',
        xl: 'repeat(12, 1fr)'
      }}
      columnGap={{ base: 3, sm: 3, md: 3, lg: 6, xl: 6 }}
      rowGap={6}
      px={{ base: 3, sm: 3, md: 3, lg: 6, xl: 6 }}
      {...props}>
      {children}
    </Grid>
  );
};

export default SlzGrid;
