import { Box, Card, HStack, Text, VStack } from '@chakra-ui/react';
import { SlzDropdown } from 'components/common/SlzDropdown';
import { TagIcon, WarehouseIcon } from './assets';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';

const warehouseOptions = [
  {
    label: 'Select a warehouse',
    value: '',
    isDisabled: true
  }
];

const Warehouse = ({
  warehousesData,
  currentType,
  handleEditStep,
  width = '100%',
  bg = 'light.500'
}) => {
  const {
    formState: { errors },
    watch,
    control
  } = useFormContext();

  const [warehouses, setWarehouses] = useState([]);
  const [warehouseInformation, setWarehouseInformation] = useState({});

  useEffect(() => {
    setWarehouses([...warehouseOptions, warehousesData]);
  }, []);

  useEffect(() => {
    setWarehouseInformation(watch('warehouse')?.warehouseInformation);
  }, [watch('warehouse')?.value]);

  return (
    <>
      {currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE ? (
        <VStack mb={3} w={width}>
          <Card
            bg={bg}
            variant="filled"
            sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
            <HStack px="1.5rem">
              {currentType === SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE ? (
                <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
              ) : (
                <WarehouseIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
              )}
              <Box display="flex" alignItems="baseline">
                <Text
                  marginRight="0.375rem"
                  sx={{
                    fontSize: 'lg',
                    color: 'table.mainText',
                    fontWeight: 'bold'
                  }}>
                  Your warehouse
                </Text>

                {currentType === SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY && (
                  <Text
                    onClick={() =>
                      handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE)
                    }
                    fontSize="xs"
                    cursor="pointer"
                    color="main.500">
                    Edit
                  </Text>
                )}
              </Box>
            </HStack>
          </Card>
          <Card
            bg="light.300"
            border="1px solid"
            borderColor="light.700"
            mt="2rem"
            w="100%"
            h="5.563rem"
            px="1.5rem"
            variant="filled"
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
            <Text
              sx={{
                textAlign: 'left',
                lineHeight: '20px',
                letterSpacing: '-0.14px',
                fontSize: '16px',
                color: 'table.mainText'
              }}>
              <b>
                {warehouseInformation?.name} <br />
              </b>
              {warehouseInformation?.addressLine2}, {warehouseInformation?.town} <br />
              {warehouseInformation?.county}, {warehouseInformation?.country}, {warehouseInformation?.postcode}
            </Text>
          </Card>
        </VStack>
      ) : (
        <VStack w="51.625rem" mt={2.5} mb={3}>
          <Card
            bg="light.500"
            variant="filled"
            sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
            <HStack px="1.5rem">
              <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
              <Text
                sx={{
                  textAlign: 'left',
                  lineHeight: '20px',
                  letterSpacing: '-0.14px',
                  fontSize: '16px',
                  color: 'table.mainText'
                }}>
                <b>Select a warehouse</b>
              </Text>
            </HStack>
          </Card>
          <Card
            bg="light.500"
            mt="2rem"
            w="100%"
            h="4.5rem"
            px="1.5rem"
            variant="filled"
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
            <Controller
              render={({ field: { onChange, value, ...fieldRest }, ...rest }) => (
                <SlzDropdown
                  isInvalid={errors?.warehouse}
                  options={warehouses}
                  size="md"
                  isControlled={true}
                  placeholder="Select a warehouse"
                  value={value}
                  {...fieldRest}
                  {...rest}
                  onChange={(value) => onChange(value)}
                />
              )}
              control={control}
              name="warehouse"
              defaultValue={warehousesData}
            />
          </Card>
        </VStack>
      )}
    </>
  );
};

export default Warehouse;
