import { useQuery } from 'react-query';
import { getOrderDetail } from '../services/returns-api';

export const useOrderDetailQuery = ({ id, select, enabled = false }) => {
  return useQuery([`order-detail-${id}`], () => getOrderDetail(id), {
    // time until stale data is garbage collected
    cacheTime: 60 * 1000,
    // time until data becomes stale
    staleTime: 30 * 1000,
    enabled,
    select
  });
};
