import React from 'react';

import { Icon } from '@chakra-ui/react';

const WarningIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="23.059"
      height="22.253"
      fill="currentColor"
      viewBox="0 0 25.794 25.794"
      {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.059"
        height="22.253"
        viewBox="0 0 23.059 22.253">
        <g id="Group_4469" data-name="Group 4469" transform="translate(-1097.97 -938.747)">
          <path
            id="Polygon_17"
            data-name="Polygon 17"
            d="M12.5,4.33,3.3,22H21.7L12.5,4.33m0-2.582a1.484,1.484,0,0,1,1.33.807L23.858,21.807A1.5,1.5,0,0,1,22.527,24H2.473a1.5,1.5,0,0,1-1.33-2.193L11.17,2.554A1.484,1.484,0,0,1,12.5,1.747Z"
            transform="translate(1097 937)"
            fill="#de4259"
          />
          <path
            id="Path_1322"
            data-name="Path 1322"
            d="M0,11.107a1,1,0,0,1-1-1V3.791a1,1,0,0,1,1-1,1,1,0,0,1,1,1v6.316A1,1,0,0,1,0,11.107Z"
            transform="translate(1109.5 942.5)"
            fill="#de4259"
          />
          <circle
            id="Ellipse_157"
            data-name="Ellipse 157"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(1108 955)"
            fill="#de4259"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default WarningIcon;
