import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';

import { BoxesField } from 'modules/b2b-orders/components/form';

const BoxesFields = ({ control, register, disabled }) => {
  const { fields } = useFieldArray({
    control,
    name: 'boxes'
  });

  return (
    <VStack
      alignItems={'unset'}
      spacing={5}
      sx={{
        mb: 5,
        maxH: '350px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
      {fields.map((item, index) => (
        <BoxesField
          item={item}
          key={item.id}
          index={index}
          register={register}
          disabled={disabled}
        />
      ))}
    </VStack>
  );
};

export default BoxesFields;
