import React from 'react';

export default function CyberSourceForm({ signedModel = {}, creditFormRef }) {
  return (
    <form
      id="payment_form"
      action={process.env.REACT_APP_CYBERSOURCE_CHECKOUT}
      method="post"
      ref={creditFormRef}>
      <input type="hidden" name="access_key" value={signedModel.accessKey} />
      <input type="hidden" name="profile_id" value={signedModel.profileID} />
      <input type="hidden" name="transaction_uuid" value={signedModel.transactionID} />
      <input type="hidden" name="signed_field_names" value={signedModel.signedFieldNames} />
      <input type="hidden" name="signature" value={signedModel.signature} />
      <input type="hidden" name="unsigned_field_names" />
      <input type="hidden" name="signed_date_time" value={signedModel.signedDateTime} />
      <input type="hidden" name="locale" value="en-GB" />
      <input type="hidden" name="transaction_type" size="50" value={signedModel.transactionType} />
      <input type="hidden" name="reference_number" size="50" value={signedModel.referenceNumber} />
      <input type="hidden" name="amount" size="25" value={signedModel.amount} />
      <input type="hidden" name="currency" size="25" value={signedModel.currency} />
      <input
        type="hidden"
        name="bill_to_company_name"
        size="25"
        value={signedModel.billToCompanyName}
      />
      <input type="hidden" name="bill_to_forename" size="25" value={signedModel.billToForename} />
      <input type="hidden" name="bill_to_surname" size="25" value={signedModel.billToSurname} />
      <input type="hidden" name="bill_to_email" size="25" value={signedModel.billToEmail} />
      <input
        type="hidden"
        name="bill_to_address_line1"
        size="25"
        value={signedModel.billToAddressLine1}
      />
      <input
        type="hidden"
        name="bill_to_address_line2"
        size="25"
        value={signedModel.billToAddressLine2}
      />
      <input
        type="hidden"
        name="bill_to_address_city"
        size="25"
        value={signedModel.billToAddressCity}
      />
      <input
        type="hidden"
        name="bill_to_address_state"
        size="25"
        value={signedModel.billToAddressState}
      />
      <input
        type="hidden"
        name="bill_to_address_country"
        size="25"
        value={signedModel.billToAddressCountry}
      />
      <input
        type="hidden"
        name="bill_to_address_postal_code"
        size="25"
        value={signedModel.billToAddressPostalCode}
      />
      <input type="hidden" name="bill_to_phone" size="25" value={signedModel.phoneNumber} />
    </form>
  );
}
