import { Icon } from '@chakra-ui/react';

const B2BIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="240"
      viewBox="0 0 240 240"
      fill="currentColor"
      {...props}>
      <g id="Group_2345" data-name="Group 2345" transform="translate(0)">
        <path
          id="Path_8348"
          data-name="Path 8348"
          d="M240,72V60.24a11.7,11.7,0,0,0-.324-3.012,12.293,12.293,0,0,0-1.044-2.808L214.737,6.636A12.013,12.013,0,0,0,204,0H36A12.013,12.013,0,0,0,25.26,6.636L1.38,54.42A11.961,11.961,0,0,0,0,59.808V72a47.844,47.844,0,0,0,24,41.328V216H12a12,12,0,0,0,0,24H228a12,12,0,0,0,0-24H216V113.328A47.844,47.844,0,0,0,240,72m-24,0a24,24,0,1,1-48,0ZM43.415,24H196.581l12,24H31.416ZM144,72a24,24,0,1,1-48,0ZM72,72a24,24,0,1,1-48,0Zm72,144H96V180a12.012,12.012,0,0,1,12-12h24a12,12,0,0,1,12,12Zm48,0H168V180a36.044,36.044,0,0,0-36-36H108a36.044,36.044,0,0,0-36,36v36H48V120a47.881,47.881,0,0,0,36-16.284,47.935,47.935,0,0,0,72,0A47.881,47.881,0,0,0,192,120Z"
        />
      </g>
    </Icon>
  );
};

export default B2BIcon;
