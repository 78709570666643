import { useQuery } from 'react-query';

import { getReturnPreferences } from 'modules/preferences/services/preferences-api';

export const useReturnSettingsMyProductQuery = (enabled) => {
  return useQuery({
    queryKey: [`return-settings-return-preference`],
    queryFn: () => getReturnPreferences(),
    enabled: enabled
  });
};
