import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';

import { ExtrasPackagingSettingForm } from 'modules/preferences/components/form';
import { PackagingOverview } from 'modules/preferences/components/other';
import { usePackagingExtrasQuery, usePackagingOverviewQuery } from 'modules/preferences/hooks';
import { mapPackagingExtras } from 'modules/preferences/utils';

const PackageSetting = ({ activatedSetting }) => {
  const { data: packagingOverview, isFetching: isPackagingFetching } = usePackagingOverviewQuery();
  const { data: packagingExtras, isPackagingExtrasFetching } = usePackagingExtrasQuery({
    select: (data) => mapPackagingExtras(data)
  });

  return (
    <PreferenceSkeleton
      isLoaded={!isPackagingFetching && !isPackagingExtrasFetching}
      template={activatedSetting}>
      <PackagingOverview packagingOverview={packagingOverview} />
      <ExtrasPackagingSettingForm data={packagingExtras} />
    </PreferenceSkeleton>
  );
};

export default PackageSetting;
