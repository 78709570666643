import { FULFILLMENT_CENTRES } from '../constants';
import { BulkOrderDetail } from '../pages'


const FulfillmentCentreRoutes = [
  {
    path: FULFILLMENT_CENTRES,
    feature: 'ViewOrder',
    profile: 'Supplier',
    exact: true,
    component: BulkOrderDetail
  }
];

export default FulfillmentCentreRoutes;
