import { Box, InputGroup, InputRightElement, Input, useStyleConfig } from '@chakra-ui/react';
import { ArrowRightIcon, CalendarIcon } from '../../Icons';
import { DATE_FORMAT_DDMMYY, TODAY } from '../../../../constants';
import dayjs from 'dayjs';
function SlzDateRangeInput(props) {
  const { colorScheme, className } = props;
  const { startDatePlaceHolder, endDatePlaceHolder } = props;

  const handleOpenDaterangeCalendar = () => {
    props?.onDateRangeInputClick();
  };

  const renderDate = (date) => {
    return date
      ? date?.format(DATE_FORMAT_DDMMYY) == dayjs().format(DATE_FORMAT_DDMMYY)
        ? TODAY
        : date?.format(DATE_FORMAT_DDMMYY)
      : null;
  };

  const dateRangeInputStyle = useStyleConfig('SlzDateRangeInput', { colorScheme });
  const { container, arrowIcon, inputGroup } = dateRangeInputStyle;
  return (
    <Box className={className} onClick={handleOpenDaterangeCalendar} sx={container}>
      <Box sx={{ width: '100%' }}>
        <InputGroup sx={inputGroup}>
          <Input
            data-testid="slz-date-range-start-date"
            sx={container.input}
            isReadOnly
            type="input"
            _focusVisible={{
              outline: 'none'
            }}
            value={renderDate(props.startDate) ?? startDatePlaceHolder}
          />
          <InputRightElement
            sx={inputGroup.rightElement}
            h="100%"
            pointerEvents="none"
            children={
              <CalendarIcon
                width="1rem"
                height="1rem"
                data-testid="slz-date-range-input-calendar-icon"
                color="#0973EA"
              />
            }
          />
        </InputGroup>
      </Box>
      <Box className={`date-range-input-arrow-icon ${className}`} sx={arrowIcon}>
        <ArrowRightIcon />
      </Box>
      <Box sx={{ width: '100%' }}>
        <InputGroup sx={inputGroup}>
          <Input
            data-testid="slz-date-range-end-date"
            sx={container.input}
            isReadOnly
            _focusVisible={{
              outline: 'none'
            }}
            value={renderDate(props.endDate) ?? endDatePlaceHolder}
          />
          <InputRightElement
            sx={inputGroup.rightElement}
            h="100%"
            pointerEvents="none"
            children={
              <CalendarIcon
                width="1rem"
                height="1rem"
                data-testid="slz-date-range-input-calendar-icon"
                color="#0973EA"
              />
            }
          />
        </InputGroup>
      </Box>
    </Box>
  );
}
export default SlzDateRangeInput;
