import React from 'react';
import { Icon } from '@chakra-ui/react';

const RoyalMailLogoCompany = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="81.394"
      height="20.061"
      viewBox="0 0 81.394 20.061"
      {...props}>
      <svg>
        <g
          id="royal-mail-logo_logotyp.us"
          data-name="royal-mail-logo@logotyp.us"
          transform="translate(-461.401 5010.331)">
          <path
            id="Path_9944"
            data-name="Path 9944"
            d="M461.431-4990.3h81.333v-20H461.431Z"
            transform="translate(0 0)"
            fill="#f00020"
          />
          <path
            id="Path_9945"
            data-name="Path 9945"
            d="M461.431-4990.3h81.333v-20H461.431Z"
            transform="translate(0 0)"
            fill="#ee2722"
            stroke="#ee2722"
            stroke-width="0.061"
          />
          <g id="Group_33864" data-name="Group 33864" transform="translate(466.703 -5006.973)">
            <path
              id="Path_9946"
              data-name="Path 9946"
              d="M-6.887-20.56a.255.255,0,0,1-.288-.286.282.282,0,0,1,.288-.286.291.291,0,0,1,.28.286A.26.26,0,0,1-6.887-20.56Zm0-1.233a.941.941,0,0,0-.951.948.914.914,0,0,0,.951.948.929.929,0,0,0,.947-.948.956.956,0,0,0-.947-.948"
              transform="translate(73.55 21.793)"
              fill="#fddd1c"
            />
            <path
              id="Path_9947"
              data-name="Path 9947"
              d="M-27.48-40.188a3.611,3.611,0,0,1-2.514,1.047,3.478,3.478,0,0,1-2.517-1.047A3.374,3.374,0,0,1-33.556-42.7a3.539,3.539,0,0,1,3.562-3.563,3.6,3.6,0,0,1,2.514,1.043A3.7,3.7,0,0,1-26.435-42.7,3.7,3.7,0,0,1-27.48-40.188Zm.525-5.558a4.4,4.4,0,0,0-3.039-1.284A4.329,4.329,0,0,0-34.315-42.7a4.3,4.3,0,0,0,1.233,3.038,4.46,4.46,0,0,0,3.088,1.284,4.426,4.426,0,0,0,3.039-1.284A4.272,4.272,0,0,0-25.722-42.7a4.259,4.259,0,0,0-1.233-3.041"
              transform="translate(42.101 49.592)"
              fill="#fddd1c"
            />
            <path
              id="Path_9948"
              data-name="Path 9948"
              d="M-15.026-36.525a2.477,2.477,0,0,1-1.8-.712,2.612,2.612,0,0,1-.762-1.8,2.586,2.586,0,0,1,.762-1.806,2.469,2.469,0,0,1,1.8-.715,2.424,2.424,0,0,1,1.757.714,2.543,2.543,0,0,1,.758,1.806A2.517,2.517,0,0,1-15.026-36.525Zm2.278-4.846a3.183,3.183,0,0,0-2.278-.949,3.23,3.23,0,0,0-2.325.949,3.154,3.154,0,0,0-.951,2.329,3.165,3.165,0,0,0,.951,2.326,3.254,3.254,0,0,0,2.325.95,3.311,3.311,0,0,0,3.276-3.276,3.36,3.36,0,0,0-1-2.329"
              transform="translate(27.132 45.929)"
              fill="#fddd1c"
            />
            <path
              id="Path_9949"
              data-name="Path 9949"
              d="M-25.5-16.243v7.507A2.886,2.886,0,0,1-26.3-6.741a2.934,2.934,0,0,1-1.993.807,2.981,2.981,0,0,1-1.993-.807A2.95,2.95,0,0,1-31-8.119h-.759a3.636,3.636,0,0,0,.95,1.9,3.541,3.541,0,0,0,2.516,1,3.479,3.479,0,0,0,2.516-1,3.686,3.686,0,0,0,1.045-2.52v-7.506Z"
              transform="translate(49.141 19.09)"
              fill="#fddd1c"
            />
            <path
              id="Path_9950"
              data-name="Path 9950"
              d="M-15.85-43.017a3.384,3.384,0,0,0,1.758-.474v.33A1.733,1.733,0,0,1-15.85-41.4a1.649,1.649,0,0,1-1.234-.521,1.792,1.792,0,0,1-.429-.617h-.756a2.238,2.238,0,0,0,.663,1.139,2.438,2.438,0,0,0,1.757.762,2.525,2.525,0,0,0,2.517-2.517v-2.233a3.126,3.126,0,0,1-.568.807,2.852,2.852,0,0,1-1.949.853,2.921,2.921,0,0,1-1.992-.853,2.846,2.846,0,0,1-.808-1.948v-4.133h-.712v4.133a3.383,3.383,0,0,0,1,2.47,3.488,3.488,0,0,0,2.514,1.047"
              transform="translate(36.692 53.514)"
              fill="#fddd1c"
            />
            <path
              id="Path_9951"
              data-name="Path 9951"
              d="M-6.209-36.866a2.579,2.579,0,0,0,1.757.761,2.644,2.644,0,0,0,2.518-2.662v-3.991h-.761v3.99a1.863,1.863,0,0,1-1.758,1.9,1.767,1.767,0,0,1-1.234-.524,1.7,1.7,0,0,1-.522-1.235v-4.133h-.758v4.133a2.486,2.486,0,0,0,.758,1.759"
              transform="translate(25.294 45.605)"
              fill="#fddd1c"
            />
            <path
              id="Path_9952"
              data-name="Path 9952"
              d="M648.828-4983.7h.756v-10.783h-.756Z"
              transform="translate(-612.456 4994.667)"
              fill="#fddd1c"
            />
            <path
              id="Path_9953"
              data-name="Path 9953"
              d="M644.321-4983.7h.713v-10.783h-.713Z"
              transform="translate(-608.951 4994.667)"
              fill="#fddd1c"
            />
            <path
              id="Path_9954"
              data-name="Path 9954"
              d="M785.784-4974.55h.712v-8.169h-.712Z"
              transform="translate(-718.978 4985.521)"
              fill="#fddd1c"
            />
            <path
              id="Path_9955"
              data-name="Path 9955"
              d="M781.071-4974.55h.76v-8.169h-.76Z"
              transform="translate(-715.312 4985.521)"
              fill="#fddd1c"
            />
            <path
              id="Path_9956"
              data-name="Path 9956"
              d="M800.935-4983.7h.713v-10.783h-.713Z"
              transform="translate(-730.762 4994.667)"
              fill="#fddd1c"
            />
            <path
              id="Path_9957"
              data-name="Path 9957"
              d="M796.228-4983.7h.763v-10.783h-.763Z"
              transform="translate(-727.101 4994.667)"
              fill="#fddd1c"
            />
            <path
              id="Path_9958"
              data-name="Path 9958"
              d="M-23.16-27.384a2.388,2.388,0,0,1-2.422,2.424,2.32,2.32,0,0,1-1.706-.714A2.4,2.4,0,0,1-28-27.384a2.427,2.427,0,0,1,.713-1.707,2.376,2.376,0,0,1,1.708-.716h2.422Zm-4.654-2.233a3.234,3.234,0,0,0-.947,2.233,3.191,3.191,0,0,0,.947,2.231,3.1,3.1,0,0,0,2.232.952,3.172,3.172,0,0,0,3.18-3.183v-3.183h-3.18a3.2,3.2,0,0,0-2.232.95"
              transform="translate(55.303 34.364)"
              fill="#fddd1c"
            />
            <path
              id="Path_9959"
              data-name="Path 9959"
              d="M-18.174-16.243a4.161,4.161,0,0,0-4.176,4.182,4.146,4.146,0,0,0,1.231,2.994,4.274,4.274,0,0,0,2.945,1.235,4.14,4.14,0,0,0,2.422-.81v.569h.758v-2.613a3.7,3.7,0,0,1-.709,1.1,3.567,3.567,0,0,1-2.471,1,3.455,3.455,0,0,1-2.422-1,3.59,3.59,0,0,1-1.043-2.468,3.518,3.518,0,0,1,3.466-3.471h3.468v7.459h.761v-8.17Z"
              transform="translate(47.894 19.043)"
              fill="#fddd1c"
            />
            <path
              id="Path_9960"
              data-name="Path 9960"
              d="M-23.169-27.384a2.451,2.451,0,0,1-2.47,2.424,2.336,2.336,0,0,1-1.708-.714,2.432,2.432,0,0,1-.712-1.71,2.444,2.444,0,0,1,.713-1.707,2.387,2.387,0,0,1,1.708-.716h2.468Zm-4.7-2.233a3.019,3.019,0,0,0-.9,2.233,2.983,2.983,0,0,0,.9,2.231,3.107,3.107,0,0,0,2.23.95,3.168,3.168,0,0,0,3.18-3.182v-3.183h-3.18a3.205,3.205,0,0,0-2.23.95"
              transform="translate(85.699 34.364)"
              fill="#fddd1c"
            />
            <path
              id="Path_9961"
              data-name="Path 9961"
              d="M-18.173-16.243a4.167,4.167,0,0,0-4.176,4.182,4.165,4.165,0,0,0,1.234,2.994,4.275,4.275,0,0,0,2.942,1.235,4.389,4.389,0,0,0,2.47-.81v.569h.71v-2.613a3.576,3.576,0,0,1-.71,1.1,3.551,3.551,0,0,1-2.47,1,3.458,3.458,0,0,1-2.42-1,3.364,3.364,0,0,1-1-2.468,3.448,3.448,0,0,1,3.417-3.471H-14.7v7.459h.756v-8.17Z"
              transform="translate(78.233 19.043)"
              fill="#fddd1c"
            />
            <path
              id="Path_9962"
              data-name="Path 9962"
              d="M-21.337-40.527a3.471,3.471,0,0,0,1.091-.76,3.743,3.743,0,0,0,1.092-2.662A3.367,3.367,0,0,0-20.2-46.465a3.217,3.217,0,0,0-2.422-1h-3.8V-36.68h.758v-10.07h3.039a2.652,2.652,0,0,1,1.9.81,2.835,2.835,0,0,1,.853,1.994,3.221,3.221,0,0,1-.9,2.138A2.7,2.7,0,0,1-22.62-41l3.038,4.319h.95Z"
              transform="translate(26.417 47.65)"
              fill="#fddd1c"
            />
            <path
              id="Path_9963"
              data-name="Path 9963"
              d="M-11.2-34.368h.571A2.664,2.664,0,0,0-8.16-37.03a2.655,2.655,0,0,0-.762-1.807,2.452,2.452,0,0,0-1.708-.709h-2.755v9.785h.712v-9.031h2.043a1.639,1.639,0,0,1,1.185.481,1.8,1.8,0,0,1,.522,1.279,1.853,1.853,0,0,1-1.708,1.9h-1.995l3.8,5.367h.9Z"
              transform="translate(14.427 40.732)"
              fill="#fddd1c"
            />
            <path
              id="Path_9964"
              data-name="Path 9964"
              d="M-6.223-16.243h-.762l1.9,10.783h.763Z"
              transform="translate(59.732 16.431)"
              fill="#fddd1c"
            />
            <path
              id="Path_9965"
              data-name="Path 9965"
              d="M-16.851-38.865-19.8-45.328h-.854l3.8,8.217,3.8-8.218h-.805Z"
              transform="translate(65.422 45.517)"
              fill="#fddd1c"
            />
            <path
              id="Path_9966"
              data-name="Path 9966"
              d="M-3.556-53.982H-2.8L-.853-64.764h-.762Z"
              transform="translate(45.293 64.953)"
              fill="#fddd1c"
            />
            <path
              id="Path_9967"
              data-name="Path 9967"
              d="M-23.823-46.021l-3.942-8.508-1.849,10.4h.758l1.426-7.933,3.606,7.792,3.609-7.788,1.424,7.931h.759l-1.851-10.4Z"
              transform="translate(72.394 55.092)"
              fill="#fddd1c"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default RoyalMailLogoCompany;
