import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import SlzDateSingleInput from './SlzDateSingleInput';
import SlzCalendar from './SlzCalendar';
import { Box, Fade, VStack, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useOutside } from '../../../hooks/useOutside';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

function SlzDateSinglePicker(props) {
  const { onDateInputClick, startDatePlaceHolder, disableChoosingPrev = false, size } = props;
  const [startDate, setStartDate] = useState(
    props.startDate ? dayjs(props.startDate) : null
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (startDate) {
      onClose();
    }
  }, [startDate]);

  const outSideClose = () => {
    onClose();
  };
  useOutside(wrapperRef, outSideClose);

  const className = classNames({
    'date-single-picker-calendar': true
  });
  const dateSingleInputClassName = classNames({
    'date-single-input': true,
    active: isOpen
  });
  const handleDateInputClick = (startDate) => {
    onDateInputClick && onDateInputClick(startDate);
    setStartDate(startDate);
    isOpen && onClose();
  };

  const handleClearSelection = () => {
    onDateInputClick && onDateInputClick(null);
    setStartDate(null);
    isOpen && onClose();
  };

  const handleOnDateSingleInputClicked = () => {
    !isOpen && onOpen();
  };

  return (
    <Box
      className="date-single-input-wrapper"
      __css={{ color: '#676a74', position: 'relative' }}
      ref={wrapperRef}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-DateSinglePicker`)}>
      <VStack>
        <SlzDateSingleInput
          size={size}
          className={dateSingleInputClassName}
          onDateSingleInputClick={handleOnDateSingleInputClicked}
          startDate={startDate}
          startDatePlaceHolder={startDatePlaceHolder}
        />
      </VStack>
      {isOpen && (
        <Box className={className} __css={{ position: 'relative', float: 'right' }} >
          <SlzCalendar
            disableChoosingPrev={disableChoosingPrev}
            isShowFuture={true}
            showDropdowns={false}
            onDateInputClick={handleDateInputClick}
            onClearSelection={handleClearSelection}
            startDate={startDate}
          />
        </Box>
      )}
    </Box>
  );
}

export default SlzDateSinglePicker;
