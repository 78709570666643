import { PAYMENT_CARD_STATUS } from 'constants/credit-card';

export function creditCardInfoMapper(rawData) {
  if (!rawData) return;

  let cardNumber = rawData.cardSummary ? String(rawData.cardSummary).replaceAll('x', '*') : '';

  return {
    customerName: rawData.holderName,
    cardNumber,
    expiredDate: '03/26',
    status: PAYMENT_CARD_STATUS[rawData.status],
    type: 'visa'
  };
}

export function directDebitInfoMapper(rawData) {
  if (!rawData) return {};

  const parsed = JSON.parse(rawData.directDebitDetailsContentsPayload || '{}');
  return {
    paymentTerms: `${rawData.paymentTerms} days`,
    type: rawData.bankAccountType,
    customerName: parsed.AccountHolder.Name,
    status: PAYMENT_CARD_STATUS[rawData.status]
  };
}

export function directDebitPayloadMapper(rawData) {
  if (!rawData) return;

  return {
    name: rawData.name,
    email: rawData.email,
    phone: rawData.phone,
    address: {
      line1: rawData.addressLine1,
      line2: rawData.addressLine2,
      city: rawData.town,
      postalCode: rawData.postCode
    },
    successURL: '/',
    cancelURL: '/',
    bankAccountType: rawData.bankAccountType
  };
}
