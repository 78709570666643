import { Button } from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

const SlzButton = (props) => {
  return (
    <Button id={uniqueId('SEL-Slz-Button')} data-testid="Slz-Button" {...props}>
      {props.children}
    </Button>
  );
};

export default SlzButton;
