import { Icon } from '@chakra-ui/react';

const ConsignmentDetailsIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34152" data-name="Group 34152" transform="translate(-379 -352)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(379 352)"
          fill="#0973ea"
        />
        <g id="Group_34268" data-name="Group 34268" transform="translate(-337.141 279.32)">
          <path
            id="Path_7275"
            data-name="Path 7275"
            d="M728.521,88.234H723.76a1.43,1.43,0,0,1-1.428-1.428V81.092a1.43,1.43,0,0,1,1.428-1.428h.952a.476.476,0,0,1,0,.952h-.952a.477.477,0,0,0-.476.476v5.713a.477.477,0,0,0,.476.476h4.761a.477.477,0,0,0,.476-.476V81.092a.477.477,0,0,0-.476-.476h-.952a.476.476,0,1,1,0-.952h.952a1.43,1.43,0,0,1,1.428,1.428v5.713A1.43,1.43,0,0,1,728.521,88.234Z"
            transform="translate(0 -1.048)"
            fill="#fafafa"
          />
          <path
            id="Path_7276"
            data-name="Path 7276"
            d="M727.76,77.664h.952a1.428,1.428,0,1,1,0,2.857h-.952a1.428,1.428,0,0,1,0-2.857Zm.952,1.9a.476.476,0,1,0,0-.952h-.952a.476.476,0,1,0,0,.952Z"
            transform="translate(-2.096)"
            fill="#fafafa"
          />
          <path
            id="Line_1281"
            data-name="Line 1281"
            d="M-.519-.048h0A.476.476,0,0,1-1-.524.476.476,0,0,1-.524-1h0a.476.476,0,0,1,.476.476A.476.476,0,0,1-.519-.048Z"
            transform="translate(725.236 82.949)"
            fill="#fafafa"
          />
          <path
            id="Line_1282"
            data-name="Line 1282"
            d="M.428-.048H-.524A.476.476,0,0,1-1-.524.476.476,0,0,1-.524-1H.428A.476.476,0,0,1,.9-.524.476.476,0,0,1,.428-.048Z"
            transform="translate(727.141 82.949)"
            fill="#fafafa"
          />
          <path
            id="Line_1283"
            data-name="Line 1283"
            d="M-.519-.048h0A.476.476,0,0,1-1-.524.476.476,0,0,1-.524-1h0a.476.476,0,0,1,.476.476A.476.476,0,0,1-.519-.048Z"
            transform="translate(725.236 84.853)"
            fill="#fafafa"
          />
          <path
            id="Line_1284"
            data-name="Line 1284"
            d="M.428-.048H-.524A.476.476,0,0,1-1-.524.476.476,0,0,1-.524-1H.428A.476.476,0,0,1,.9-.524.476.476,0,0,1,.428-.048Z"
            transform="translate(727.141 84.853)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ConsignmentDetailsIcon;
