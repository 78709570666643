import { useQuery } from 'react-query';

import { getEORIDetails } from 'modules/preferences/services/preferences-api';

export const useEORIDetailsQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: [`eori-details`],
    queryFn: () => getEORIDetails(),
    select: select,
    enabled: enabled
  });
};
