import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { exportTrackingReferences } from '../services/orders-api';

const DATE_FORMAT = 'YYYY-MM-DD';
export const useTrackingReferences = (dateRange, options = {}) => {
  return useQuery({
    queryKey: ['ordersTracking', dateRange],
    queryFn: () => {
      const startDate = dateRange[0] ? dayjs(dateRange[0]).format(DATE_FORMAT) : undefined;
      const endDate = dateRange[1] ? dayjs(dateRange[1]).format(DATE_FORMAT) : undefined;

      return exportTrackingReferences({ startDate, endDate });
    },
    enabled: false,
    cacheTime: 0,
    ...options
  });
};
