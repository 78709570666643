import React from 'react';
import { Link, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import SlzToolTip from 'components/common/SlzToolTip';

const Content = ({ content, href, linkContent }) => (
  <Text lineHeight="1rem" fontSize="sm">
    {content}
    <Link
      _hover={{
        color: 'light.500',
        textDecoration: 'underline'
      }}
      href={href}
      isExternal>
      {linkContent}
    </Link>
  </Text>
);

const HeaderTooltip = ({ ml = '0.531rem', tooltip }) => {
  return (
    <SlzToolTip
      content={
        <Content
          content={tooltip?.content}
          linkContent={tooltip?.link?.content}
          href={tooltip?.link?.href}
        />
      }
      placement="right-start"
      variant="blue">
      <InfoOutlineIcon ml={ml} mb="0.125" boxSize="3" color="main.500" />
    </SlzToolTip>
  );
};

export default HeaderTooltip;
