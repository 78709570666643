import React from 'react';
import PaymentCard from './PaymentCard';
import { CREDIT_CARD_TYPE } from 'constants/credit-card';

export default function CompactCreditCard({ size, type = CREDIT_CARD_TYPE.VISA }) {
  const colorScheme = type;
  let extendedLogoStyle;

  if (type === CREDIT_CARD_TYPE.AMERICAN_EXPRESS) {
    extendedLogoStyle = {
      '& > svg': {
        width: '100%',
        height: 'auto'
      }
    };
  }

  return (
    <PaymentCard variant="compact" size={size} colorScheme={colorScheme}>
      <PaymentCard.Body>
        <PaymentCard.Logo type={type} sx={extendedLogoStyle} />
      </PaymentCard.Body>
    </PaymentCard>
  );
}
