import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const parts = ['button', 'buttonActive', 'optionContainer', 'inputRightElementWidth', 'slzChip', 'chevronDownIcon'];

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const sizes = {
  sm: definePartsStyle({
    inputRightElementWidth: {
      w: 12
    },
    slzChip: {
      size: 'sm'
    },
    chevronDownIcon: {
      w: 5,
      h: 5
    }
  }),
  lg: definePartsStyle({
    inputRightElementWidth: {
      w: '23%'
    },
    slzChip: {
      w: "2.063rem",
      h: "2.063rem"
    },
    chevronDownIcon: {
      w: "2.063rem",
      h: "2.063rem"
    }
  })
}

const buttonStyle = defineStyle({
  p: 0,
  width: '100%',
  display: 'flex',
  backgroundColor: 'transparent',
  _hover: {
    backgroundColor: 'none'
  },
  _active: 'none'
});

const buttonActiveStyle = defineStyle({
  ...buttonStyle,
  borderColor: 'main.500'
});

const optionContainerStyle = defineStyle({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  minW: 150,
  w: '100%',
  p: 3,
  borderRadius: 'xl',
  boxShadow: '0 0.125rem 0.5rem #00000033',
  border: '0.063rem solid #C3C4CA'
});
const baseStyle = definePartsStyle(() => ({
  button: buttonStyle,
  buttonActive: buttonActiveStyle,
  optionContainer: optionContainerStyle
}));

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'sm'
  }
});
