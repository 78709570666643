export const inputElementSx = {
  lineHeight: 0,
  position: 'relative',
  zIndex: 1,
  w: 'auto',
  px: 1.5,
  color: 'dark.500'
};

export const inputSx = (sx, styles, props) => ({
  zIndex: 1,
  border: 'none',
  bgColor: 'transparent!important',
  '&:focus + input.substitute': styles.field._hover,
  '&:hover + input.substitute': styles.field._hover,
  ...sx,
  ...props
});

export const substituteInputSx = (sx, styles, props) => ({
  ...sx,
  pos: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  w: 'full',
  ...props
});

export const dropdownTableSx = {
  sx: {
    '&::-webkit-scrollbar': {
      borderRadius: '9px',
      width: '5px',
      background: '#D6D7D7'
    },
    '&:hover::-webkit-scrollbar': {
      background: '#959698'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#B5B7B7',
      opacity: 0.16,
      borderRadius: '9px'
    },
    '&:active::-webkit-scrollbar-thumb': {
      background: '#494B4D'
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: '#494B4D'
    }
  },
  'data-testid': 'table-results',
  maxHeight: '436px',
  overflowY: 'auto',
  pos: 'absolute',
  zIndex: 10,
  top: '110%',
  left: '0',
  w: 'full',
  p: '1.5',
  bgColor: 'white',
  rounded: '0.375rem',
  boxShadow: '0 3px 6px #00000067'
};
