import { Box, Skeleton as SkeletonCharka } from '@chakra-ui/react';

import { skeletonShimmerAnimation } from './config';
import { SkeletonText } from './Skeleton';

const SHIMMER_STYLE = {
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    background: 'unset',
    animation: 'unset',
    backgroundColor: 'skeleton.background !important',
    h: '100%',
    w: '100%',
    fadeDuration: 0.4
  },
  '&::after': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translateX(-100%)',
    backgroundImage: `linear-gradient(90deg, skeleton.lineColor0 0, skeleton.lineColor20 20%, skeleton.lineColor50 60%, skeleton.lineColor0)`,
    animation: skeletonShimmerAnimation,
    content: "''",
    visibility: 'initial !important'
  }
};

const ShimmerSkeleton = ({ children, ...rest }) => {
  return <Box {...rest}>{children}</Box>;
};

const Input = ({ titleStyle, inputStyle, children, ...rest }) => {
  return (
    <Box {...rest}>
      <SkeletonCharka
        {...SHIMMER_STYLE.wrapper}
        _after={SHIMMER_STYLE['&::after']}
        h="0.75rem"
        w="5.313rem"
        mb="0.5rem"
        {...titleStyle}
      />
      {children || (
        <SkeletonCharka
          {...SHIMMER_STYLE.wrapper}
          _after={SHIMMER_STYLE['&::after']}
          h="3.063rem"
          w="100%"
          {...inputStyle}
        />
      )}
    </Box>
  );
};

const Block = ({ children, isLoaded = false, ...rest }) => {
  const { wrap, after } = {
    wrap: {
      ...SHIMMER_STYLE.wrapper,
      backgroundColor: isLoaded ? 'unset' : 'skeleton.background'
    },
    after: {
      ...(isLoaded ? {} : SHIMMER_STYLE['&::after'])
    }
  };

  return (
    <SkeletonCharka {...wrap} _after={after} {...rest} isLoaded={isLoaded}>
      {children}
    </SkeletonCharka>
  );
};

const Card = ({ titleStyle, contentStyle, children, ...rest }) => (
  <Box {...rest}>
    <SkeletonCharka
      {...SHIMMER_STYLE.wrapper}
      _after={SHIMMER_STYLE['&::after']}
      h="3rem"
      w="100%"
      mb="0.188rem"
      {...titleStyle}
    />
    {children || (
      <SkeletonCharka
        {...SHIMMER_STYLE.wrapper}
        _after={SHIMMER_STYLE['&::after']}
        h="4.5rem"
        w="100%"
        {...contentStyle}
      />
    )}
  </Box>
);

const Text = ({ noOfLines = 10, skeletonHeight = 4, children, isLoaded }) => {
  const { wrap, after } = {
    wrap: {
      ...SHIMMER_STYLE.wrapper,
      h: skeletonHeight,
      backgroundColor: isLoaded ? 'unset' : 'skeleton.background'
    },
    after: {
      ...(isLoaded ? {} : SHIMMER_STYLE['&::after'])
    }
  };

  return (
    <SkeletonText
      noOfLines={noOfLines}
      spacing="4"
      sx={{ '& > .chakra-skeleton': { ...wrap, _after: after } }}
      isLoaded={isLoaded}>
      {children}
    </SkeletonText>
  );
};

const Rows = ({ noOfRows = 4, ...rest }) => (
  <>
    {[...Array(noOfRows)].map(() => (
      <SkeletonCharka
        {...SHIMMER_STYLE.wrapper}
        _after={SHIMMER_STYLE['&::after']}
        h="4.5rem"
        w="100%"
        {...rest}
        backgroundColor={isLoaded ? 'unset !important' : 'skeleton.background !important'}
        isLoaded={isLoaded}
      />
    ))}
  </>
);

ShimmerSkeleton.Input = Input;
ShimmerSkeleton.Block = Block;
ShimmerSkeleton.Card = Card;
ShimmerSkeleton.Text = Text;
ShimmerSkeleton.Rows = Rows;

export default ShimmerSkeleton;
