import React from 'react';

import { FormLabel as ChakraFormLabel } from '@chakra-ui/react';

export default function FormLabel({ children }) {
  return (
    <ChakraFormLabel fontSize="lg" fontWeight="medium" mb="0">
      {children}
    </ChakraFormLabel>
  );
}
