import React from 'react';
import {
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  useMultiStyleConfig
} from '@chakra-ui/react';

function Group(props) {
  return <InputGroup {...props}>{props.children}</InputGroup>;
}

function LeftElement(props) {
  const inputStyles = useMultiStyleConfig('Input');
  return (
    <InputLeftElement borderLeftRadius={inputStyles.field.borderRadius} {...props}>
      {props.children}
    </InputLeftElement>
  );
}
LeftElement.id = 'InputLeftElement';

function RightElement(props) {
  const styles = useMultiStyleConfig('Input');

  return (
    <InputRightElement borderRightRadius={styles.field.borderRadius} {...props}>
      {props.children}
    </InputRightElement>
  );
}
RightElement.id = 'InputRightElement';

function LeftAddon(props) {
  return <InputLeftAddon {...props}>{props.children}</InputLeftAddon>;
}
LeftAddon.id = 'InputLeftAddon';

function RightAddon(props) {
  return <InputRightAddon {...props}>{props.children}</InputRightAddon>;
}
RightAddon.id = 'InputRightAddon';

const Input = React.forwardRef(
  ({ autoComplete = 'off', isInvalid, isValid, onChange, ...props }, ref) => {
    return (
      <ChakraInput
        ref={ref}
        autoComplete={autoComplete}
        aria-valid={isValid}
        onChange={onChange}
        {...props}
      />
    );
  }
);
Input.id = 'Input';

Input.Group = Group;
Input.LeftElement = LeftElement;
Input.RightElement = RightElement;
Input.LeftAddon = LeftAddon;
Input.RightAddon = RightAddon;

export default Input;
