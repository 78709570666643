import { HStack } from '@chakra-ui/react';

import { ExclamationIcon } from 'components/common/Icons';

const RequiredSettingStatus = ({ iconRight = '', value, requiredSettings = [] }) => {
  if (requiredSettings.includes(value)) {
    return (
      <HStack marginLeft="auto">
        {iconRight}{' '}
        <ExclamationIcon
          w="1.5rem"
          h="1.5rem"
          borderRadius="full"
          bg="yellowTan.500"
          p="0.313rem"
        />
      </HStack>
    );
  }
  return iconRight;
};

export default RequiredSettingStatus;
