import React from 'react';

import { FormProvider } from 'react-hook-form';
import { VStack, HStack, Heading, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import { ControlledField, Field } from 'components/form';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { BANK_TYPES, USER_SETTING_FORMS_DEFAULT_NAME } from '../../constants';
import { SlzSelect } from 'components/common/SlzSelect';

export default function DirectDebitConfigsForm({ isSubmitting, onSubmit, onBack }) {
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.directDebit]: form } = forms;
  const methods = form?.form;
  const hasOnlyOneBank = BANK_TYPES.length === 1;

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} autoComplete="off">
        <VStack spacing={4} alignItems="flex-start" mb={8}>
          <Heading as="h4" fontSize="xl">
            Account holder details
          </Heading>

          <Field label="Name" name="name" isInlineError />

          <Field label="Email address" name="email" isInlineError />

          <Field label="Phone" name="phone" isInlineError />
        </VStack>

        <VStack spacing={4} alignItems="flex-start">
          <Heading as="h5" fontSize="lg">
            Account holder address
          </Heading>

          <Field label="Address line 1" name="addressLine1" isInlineError />

          <Field
            label={
              <>
                Address line 2
                <Text as="span" fontWeight="normal">
                  {' '}
                  - Optional
                </Text>
              </>
            }
            name="addressLine2"
            isInlineError
          />

          <HStack w="full" spacing={3}>
            <Field label="Town" name="town" isInlineError />

            <Field label="Postcode" name="postCode" isInlineError />
          </HStack>

          <ControlledField
            label="Bank account type"
            name="type"
            isInlineError
            component={SlzSelect}
            options={BANK_TYPES}
            defaultValue={BANK_TYPES[0]}
            isDisabled={hasOnlyOneBank}
          />
        </VStack>

        <HStack justifyContent="space-between" mt={12} mb={6}>
          <SlzButton size="lg" variant="outline" colorScheme="negative" onClick={onBack}>
            Back
          </SlzButton>

          <SlzButton type="submit" size="lg" isDisabled={isSubmitting}>
            Proceed to payment details
          </SlzButton>
        </HStack>
      </form>
    </FormProvider>
  );
}
