import { useQuery } from 'react-query';
import { getDirectDebitInfo } from '../services/payment-apis';
import { directDebitInfoMapper } from '../mappers/payment-mapper';

export default function useGetDirectDebitInfo(enabled) {
  return useQuery({
    queryKey: ['get-direct-debit'],
    queryFn: getDirectDebitInfo,
    enabled,
    select: (values) => {
      const paymentTerms = values[1]?.data.data;
      const directDebitInfo = values[0].data?.data;

      if (!paymentTerms || !directDebitInfo) {
        return;
      }
      return directDebitInfoMapper(
        Object.assign({ paymentTerms: values[1].data?.data.paymentTerms }, values[0].data?.data)
      );
    }
  });
}
