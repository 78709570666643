import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle(({ theme }) => ({
  bgColor: getColor(theme, 'light.300')
}));

const variants = {
  auto: defineStyle(() => {
    return {
      w: 'full',
      h: 'auto'
    };
  }),
  square: defineStyle(() => ({}))
};

const sizes = {
  xl: defineStyle({
    w: '25rem',
    h: '25rem',
    fontSize: '1.875rem',
    boxShadow: '0 2px 16px #00000034',
    // opacity: 0.4,
    rounded: '3rem'
  }),
  lg: defineStyle({
    w: '12.5rem',
    h: '12.5rem',
    fontSize: '1.25rem',
    boxShadow: '0 2px 8px #00000034',
    // opacity: 0.4,
    rounded: '1.5rem'
  }),
  md: defineStyle({
    w: '6.25rem',
    h: '6.25rem',
    fontSize: '0.6875rem',
    boxShadow: '0 2px 8px #00000034',
    // opacity: 0.2,
    rounded: '0.75rem'
  }),
  sm: defineStyle({
    w: '3.125rem',
    h: '3.125rem',
    fontSize: '0.5625rem',
    boxShadow: '0 2px 8px #00000034',
    // opacity: 0.2,
    rounded: '0.375rem'
  })
};

export default defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'square'
  }
});
