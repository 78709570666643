import { ChevronDownIcon } from '@chakra-ui/icons';
import { chakra, HStack, useDisclosure, useStyles, VStack } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useOutside } from '../../../hooks/useOutside';
import { ANGLE, PAGES_ITEM_DISPLAY } from './Constants';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import PropTypes from 'prop-types';
import { GiHamburgerMenu } from './Icon';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const rotateAnimation = (angle = 0) => `rotate(${angle}deg) translateY(0px);`;

export const Pages = ({
  currentItemPerPage,
  pageOptions,
  itemDisplay = PAGES_ITEM_DISPLAY,
  onChange
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { pages } = useStyles();

  const { container, iconRight, iconLeft, items, selected, ...rest } = pages;
  const [itemPerPages, setItemPerPages] = useState(currentItemPerPage || DEFAULT_PAGE_SIZE);
  const [options, setOptions] = useState(pageOptions || PAGES_OPTIONS_DEFAULT);
  const [transform, setTransform] = useState(null);

  const wrapperRef = useRef(null);

  const handleClick = (option) => {
    setItemPerPages(option);
    onClose();
  };

  useEffect(() => {
    onChange && onChange(itemPerPages);
  }, [itemPerPages]);

  useEffect(() => {
    pageOptions.length && setOptions(pageOptions);
  }, [pageOptions]);

  useEffect(() => {
    const rotate = rotateAnimation(isOpen ? ANGLE : 0);
    setTransform(rotate);
  }, [isOpen]);

  useOutside(wrapperRef, onClose);

  const renderPages = useMemo(
    () => (
      <VStack
        display={isOpen ? 'inline-flex' : 'none'}
        __css={{ ...items }}
        data-testid="slz-page-dropdown">
        {options?.map((option) => (
          <chakra.span
            key={option}
            color="dark.700"
            fontSize="custom.sm"
            w="full"
            py={1.5}
            px={2.5}
            maxH="1.875rem"
            textAlign="start"
            _hover={{ bg: 'light.500' }}
            onClick={() => handleClick(option)}>{`${option} ${itemDisplay}`}</chakra.span>
        ))}
      </VStack>
    ),
    [options, isOpen]
  );

  return (
    <VStack
      __css={{ ...rest }}
      ref={wrapperRef}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Page`)}
      data-testid="slz-page">
      <HStack alignItems="center" __css={{ ...container, ...selected }} onClick={onOpen}>
        <GiHamburgerMenu w={4} h={4} data-testid="slz-page-hamburger-menu" />
        <chakra.label
          flexBasis="100%"
          cursor="pointer"
          mb={0}
          ml={2.5}
          data-testid="slz-page-item-per-page-display">
          {`${itemPerPages} ${itemDisplay}`}
        </chakra.label>
        <ChevronDownIcon
          data-testid="slz-page-chevron-down"
          w={5}
          h={5}
          color="#686A73"
          transition={'0.3s ease-out;'}
          transform={transform}
        />
      </HStack>
      {renderPages}
    </VStack>
  );
};

Pages.propTypes = {
  /**
   * Page options for this component
   */
  pageOptions: PropTypes.array,
  /**
   * Callback function for handle current of items per page
   */
  onChange: PropTypes.func
};

Pages.defaultProps = {
  pageOptions: DEFAULT_PAGING_OPTIONS,
  onChange: () => {}
};
