import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { getColor, mode } from '@chakra-ui/theme-tools';

const parts = [
  'container',
  'label',
  'pages',
  'previous',
  'next',
  'middle',
  'currentPage',
  'totalPage'
];

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const directionStyle = ({ isGreyBg = false, showDirectionLabel = true, isTooltipBg = false }) => ({
  zIndex: 1,
  fontSize: '0.875rem',
  userSelect: 'none',
  cursor: 'pointer',
  label: {
    cursor: 'pointer'
  },
  px: 1.5,
  borderRadius: '5px',
  _after: {
    content: '""',
    w: !showDirectionLabel ? 'calc(100% + 2px)' : 'full',
    h: 'full',
    pos: 'absolute',
    bg: `${isTooltipBg ? 'main.700' : `light.${isGreyBg ? '700' : '500'}`}`,
    borderRadius: !showDirectionLabel ? 'full' : '0.188rem',
    transition: 'all 0.4s',
    transform: 'scale(0)',
    transformOrigin: 'center',
    transformBox: 'fill-box',
    left: 0,
    top: 0,
    zIndex: -1
  },
  _hover: {
    _after: {
      transform: 'scale(1)'
    }
    // _active: {
    //   _after: {
    //     transition: 'unset',
    //     bg: `${isGreyBg ? 'light.500' : 'light.700'}`
    //   }
    // }
  },
  _focus: {
    outline: 'none',
    _after: {
      bg: `${isTooltipBg ? 'main.700' : isGreyBg ? 'dark.300' : 'light.700'}`
    }
  },
  _active: {
    _after: {
      bg: `${isTooltipBg ? 'main.700' : isGreyBg ? 'dark.300' : 'light.700'}`
    }
  }
});

const baseStyle = definePartsStyle((props) => {
  const {
    colorScheme: c,
    theme,
    isGreyBg = false,
    showDirectionLabel = true,
    isTooltipBg = false
  } = props;
  const darkColor = getColor(theme, `${c}.700`);
  const lightColor = getColor(theme, `${c}.300`);
  const color = mode(lightColor, darkColor)(props);
  const directionStyles = directionStyle({ isGreyBg, isTooltipBg, showDirectionLabel });

  return {
    container: {
      display: 'block',
      color: darkColor
    },
    pages: {
      pos: 'relative',
      fontSize: '0.875rem',
      w: '13.188rem',
      minW: '13.188rem',
      maxH: '1.875rem',
      container: {
        w: 'full',
        px: 3,
        // mb: 2,
        border: '1px solid #C3C4CA',
        borderRadius: '0.375rem'
      },
      iconRight: {},
      iconLeft: {},
      items: {
        pos: 'absolute',
        w: 'full',
        py: 0.5,
        bg: '#FFFFFF',
        boxShadow: '0px 2px 8px #00000033',
        border: '1px solid #C3C4CA',
        borderRadius: '6px',
        top: '2.25rem',
        zIndex: 999,
        span: {
          cursor: 'pointer',
          userSelect: 'none'
        }
      },
      selected: {
        // bg: '#FFFFFF',
        userSelect: 'none',
        cursor: 'pointer',
        minH: '1.875rem',
        label: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    previous: {
      pos: 'relative',
      ...directionStyles,
      pl: 0.75,
      minH: 6,
      spacing: 2
    },
    next: {
      pos: 'relative',
      ...directionStyles,
      pr: 0.75,
      minH: 6,
      spacing: 2
    },
    middle: {
      minH: 6,
      fontSize: directionStyles.fontSize,
      dot: {
        w: 1.5,
        h: 1.5,
        bg: '#DEDEDE 0% 0% no-repeat padding-box',
        borderRadius: '6px',
        active: {
          w: 2,
          h: 2,
          bg: 'dark.700'
        }
      },
      gap: '9px',
      spacing: props.direction?.usingDots ? 1.5 : 3
    },
    currentPage: {},
    totalPage: {}
  };
});

const sizes = {
  sm: definePartsStyle({
    pages: {
      fontSize: '12px',
      w: '106px',
      minW: '106px',
      maxH: '19px',
      container: {
        w: 'full',
        px: 3
      },
      iconRight: {},
      iconLeft: {},
      items: {}
    }
  }),
  md: definePartsStyle({}),
  lg: definePartsStyle({})
};

const variants = {
  solid: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    const color = getColor(theme, `${c}.500`);

    return {};
  }),
  outline: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    const color = getColor(theme, `${c}.500`);

    return {};
  }),
  light: definePartsStyle((props) => {
    return {
      ...renderLabelColor(props),
      middle: {
        dot: {
          bg: '#F2F2F2 0% 0% no-repeat padding-box',
          active: {
            bg: '#CCE5FF'
          }
        }
      }
    };
  }),
  lightDark: definePartsStyle((props) => {
    return {
      ...renderLabelColor(props),
      middle: {
        dot: {
          bg: '#0760B9 0% 0% no-repeat padding-box',
          active: {
            bg: '#CCE5FF'
          }
        }
      }
    };
  }),
  normalLight: definePartsStyle((props) => {
    return {
      ...renderLabelColor(props),
      middle: {
        dot: {
          bg: '#FFFFFF 0% 0% no-repeat padding-box',
          active: {
            bg: 'main.300'
          }
        }
      }
    };
  }),
  black: definePartsStyle((props) => {
    return {
      ...renderLabelColor(props),
      middle: {
        dot: {
          bg: '#DEDEDE 0% 0% no-repeat padding-box',
          active: {
            bg: '#000000'
          }
        }
      }
    };
  }),
  blackDark: definePartsStyle((props) => {
    return {
      ...renderLabelColor(props),
      middle: {
        dot: {
          bg: '#F2F2F2 0% 0% no-repeat padding-box',
          active: {
            bg: '#000000'
          }
        }
      }
    };
  }),
  productSelection: definePartsStyle((props) => {
    return {
      pages: {
        pos: 'relative',
        fontSize: 'md',
        w: '7.9rem',
        minW: '7.9rem',
        container: {
          px: 2,
          height: '1.5rem',
          minH: '1.5rem',
          w: '7.9rem'
        },
        iconRight: {},
        iconLeft: {},
        items: {
          top: '1.75rem'
        },
        selected: {
          minH: '1.5rem'
        }
      },
      previous: {
        spacing: 1
      },
      next: {
        spacing: 1
      },
      middle: {
        spacing: props.direction?.usingDots ? 1.5 : 2
      }
    };
  })
};

const renderLabelColor = (props) => {
  const { labelColor } = props?.direction;
  return {
    previous: {
      color: labelColor
    },
    next: {
      color: labelColor
    }
  };
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'dark',
    isGreyBg: false
  }
});
