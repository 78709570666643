import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Flex, FormControl, Box, Input } from '@chakra-ui/react';

import { ControlledField } from 'components/form';
import {
  EU_VAT_COUNTRIES,
  EU_VAT_REGISTERED_STYLE,
  VAT_REGISTERED_MODE,
  VAT_SELECTED_TEXT
} from 'modules/preferences/constants';
import { countSelectedVatCountries } from 'modules/preferences/utils';
import { TagCheckbox } from 'modules/preferences/components/common';
import { EuVatRegisteredButtons } from 'modules/preferences/components/other';

const COLOR_MAPPER = {
  START: {
    true: 'dark.300',
    false: 'dark.700'
  },
  EDIT: {
    true: 'dark.500',
    false: 'dark.700'
  }
};

const DISABLED_BACKGROUND_COLOR_MAPPER = {
  true: 'light.300',
  false: 'white'
};

const RegisteredListHeader = ({ isEuVatEnabled, mode }) => {
  const { watch } = useFormContext();

  const color =
    (!isEuVatEnabled && 'dark.300') ||
    COLOR_MAPPER[mode][
      countSelectedVatCountries(watch(), isEuVatEnabled) === VAT_SELECTED_TEXT.empty
    ];

  const isDisabled =
    !isEuVatEnabled ||
    (countSelectedVatCountries(watch(), isEuVatEnabled) === VAT_SELECTED_TEXT.empty &&
      mode === 'START');

  return (
    <Box {...EU_VAT_REGISTERED_STYLE.header}>
      <Input
        {...EU_VAT_REGISTERED_STYLE.header.countriesSelected}
        isDisabled={isDisabled}
        color={color}
        value={countSelectedVatCountries(watch(), isEuVatEnabled)}
      />
    </Box>
  );
};

const EuVatRegisteredList = ({ isEuVatEnabled, ...rest }) => {
  return (
    <FormControl
      isDisabled={rest.mode === VAT_REGISTERED_MODE.start || !isEuVatEnabled}
      {...EU_VAT_REGISTERED_STYLE.table}
      bgColor={
        DISABLED_BACKGROUND_COLOR_MAPPER[rest.mode === VAT_REGISTERED_MODE.start || !isEuVatEnabled]
      }>
      <RegisteredListHeader isEuVatEnabled={isEuVatEnabled} mode={rest.mode} />
      <Flex {...EU_VAT_REGISTERED_STYLE.flex}>
        {EU_VAT_COUNTRIES.map((vatCountry) => (
          <Box mb="0.625rem" h="1.25rem" key={vatCountry.code}>
            <ControlledField
              name={vatCountry.code}
              country={vatCountry.country}
              mode={rest.mode}
              isEuVatEnabled={isEuVatEnabled}
              component={TagCheckbox}
            />
          </Box>
        ))}
      </Flex>
      <EuVatRegisteredButtons isEuVatEnabled={isEuVatEnabled} {...rest} />
    </FormControl>
  );
};

export default EuVatRegisteredList;
