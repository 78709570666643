import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import { SlzPagination } from 'components/common/SlzPagination';
import React from 'react';
import { BILLING_PROCESS } from '../../constants';
import { DetailedCollectionTable, DetailedInvoiceTable, DetailedRemittanceTable } from '../table';

export default function DetailedBreakdown({ process, data = {} }) {
  const { list } = data;

  return (
    <Box>
      <HStack justifyContent="space-between" px="5" py="1" bg="main.300">
        <Heading as="h4" fontSize="1.0625rem">
          Detailed breakdown
        </Heading>
        <SlzPagination
          showTitle={false}
          direction={{
            currentPage: 1,
            totalPage: 2,
            onChange: (currentPage) => console.log(currentPage)
          }}
        />
      </HStack>

      {(process === BILLING_PROCESS.COLLECTION_ADVICE ||
        process === BILLING_PROCESS.REMITTANCE_ADVICE) && (
        <Text px={7} my="4">
          The following invoices are from <strong>{data?.collectionFrom}</strong> to{' '}
          <strong>{data?.collectionTo}</strong>
        </Text>
      )}

      {process === BILLING_PROCESS.INVOICE && (
        <DetailedInvoiceTable data={list} invoiceType={data?.invoiceType} />
      )}
      {process === BILLING_PROCESS.COLLECTION_ADVICE && <DetailedCollectionTable data={list} />}
      {process === BILLING_PROCESS.REMITTANCE_ADVICE && <DetailedRemittanceTable data={list} />}
    </Box>
  );
}
