import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

import { BREAD_CRUMB_ITEMS, STATUS } from '../constants';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { useGlobalState } from 'contexts/GlobalContext';
import { useReturnDetailQuery } from '../hooks';
import { SlzGrid } from 'components/common/SlzGrid';
import { GridItem, VStack, useBoolean } from '@chakra-ui/react';
import {
  ReturnDetailEvidence,
  ReturnDetailOrderInformation,
  ReturnDetailOverview,
  ReturnDetailProductInformation
} from '../components/other';
import { SlzSkeletonDefault } from 'components/common/Skeleton/Skeleton';
import OrderDetailModal from '../components/modal/OrderDetailModal';

const ReturnDetail = () => {
  const [_, dispatch] = useGlobalState();
  const { returnID } = useParams();
  const routerQuery = useRouterQuery();
  const { data, isLoading, isSuccess } = useReturnDetailQuery({
    returnID,
    select: (response) => response?.data?.data,
    enabled: true
  });
  const [enlargeImage, setEnlargeImage] = useState();
  const [selectedProductIndex, setSelectedProductIndex] = useState();

  const [shouldDetailModal, setShouldDetailModalVisible] = useBoolean(false);

  const handleOnSelect = (imageSrc, index) => {
    setEnlargeImage(imageSrc);
    setSelectedProductIndex(index);
  };

  useEffect(() => {
    let breadcrumbs = [...BREAD_CRUMB_ITEMS];
    if (isSuccess) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          title: data?.orderReference
        }
      ];
    }

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadcrumbs
    });
  }, [isSuccess]);

  return (
    <SlzGrid
      templateAreas={`
        "header header"
        "nav main"
      `}>
      <GridItem area={'header'} colSpan={12}>
        <ReturnDetailOverview
          data={data}
          returnStatus={routerQuery.get('status') || STATUS.PROCESSED.value}
          isLoading={isLoading}
          onViewOrder={setShouldDetailModalVisible.on}
        />
      </GridItem>

      <GridItem area={'nav'} w="26rem">
        <ReturnDetailOrderInformation data={data} isLoading={isLoading} />
        <ReturnDetailEvidence imageSrc={enlargeImage} isLoading={isLoading} mt={6} />
      </GridItem>

      <GridItem area={'main'} colSpan={11}>
        {isLoading ? (
          <SlzSkeletonDefault h="41rem" />
        ) : (
          <VStack spacing={3} w="full">
            {data?.retailerReturnItems &&
              data?.retailerReturnItems.map((product, index) => {
                const isShowHeader = index === 0;
                const isActive = selectedProductIndex === index;
                return (
                  <ReturnDetailProductInformation
                    key={uniqueId()}
                    product={product}
                    isShowHeader={isShowHeader}
                    isActive={isActive}
                    onSelect={(imageSrc) => handleOnSelect(imageSrc, index)}
                  />
                );
              })}
          </VStack>
        )}
      </GridItem>
      <OrderDetailModal
        isOpen={shouldDetailModal}
        onClose={setShouldDetailModalVisible.off}
        returnDetail={data}
      />
    </SlzGrid>
  );
};

export default ReturnDetail;
