import { HStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { SlzButton } from 'components/common/SlzButton';

import { EU_VAT_COUNTRIES, VAT_REGISTERED_MODE } from 'modules/preferences/constants';

const VatRegisterButton = ({ children, isEuVatEnabled, ...props }) => (
  <SlzButton
    fontSize="sm"
    px="0.75rem"
    h="1.5rem"
    size="md"
    isDisabled={!isEuVatEnabled}
    {...props}>
    {children}
  </SlzButton>
);

const VAT_REGISTER_BUTTONS = {
  EDIT: ({ setMode, vatCountries, ...rest }) => {
    const { reset, setValue } = useFormContext();
    const handleCancelEdit = () => {
      reset(vatCountries);
      setMode(VAT_REGISTERED_MODE.start);
    };

    return (
      <>
        <HStack>
          <VatRegisterButton
            variant="solid"
            {...rest}
            onClick={() => {
              Object.entries(EU_VAT_COUNTRIES).forEach(([_, country]) =>
                setValue(country.code, true, { shouldDirty: true })
              );
            }}>
            Select all
          </VatRegisterButton>
          <VatRegisterButton
            {...rest}
            onClick={() => {
              Object.entries(EU_VAT_COUNTRIES).forEach(([_, country]) =>
                setValue(country.code, false, { shouldDirty: true })
              );
            }}
            variant="outline">
            Clear
          </VatRegisterButton>
        </HStack>
        <HStack>
          <VatRegisterButton
            {...rest}
            onClick={handleCancelEdit}
            colorScheme="negative"
            variant="outline">
            Cancel
          </VatRegisterButton>
          <VatRegisterButton {...rest} type="submit" variant="solid">
            Save
          </VatRegisterButton>
        </HStack>
      </>
    );
  },
  START: ({ setMode, ...rest }) => {
    return (
      <VatRegisterButton
        {...rest}
        onClick={() => setMode(VAT_REGISTERED_MODE.edit)}
        ml="auto"
        variant="solid">
        Edit
      </VatRegisterButton>
    );
  }
};

const EuVatRegisteredButtons = (props) => {
  const { mode, ...rest } = props;

  const VatRegisteredFormActions = VAT_REGISTER_BUTTONS[mode];

  return (
    <HStack
      px="1.875rem"
      pt="0.75rem"
      borderTop="0.063rem solid"
      borderTopColor="light.700"
      w="full"
      spacing={0}
      justifyContent="space-between">
      <VatRegisteredFormActions {...rest} />
    </HStack>
  );
};

export default EuVatRegisteredButtons;
