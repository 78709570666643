import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DragHandleIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import find from 'lodash/find';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import useAuth from 'hooks/useAuth';
import useSlzToast from 'hooks/useSlzToast';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_PAGING_OPTIONS, PAGE_INDEX } from 'constants/table.js';
import { SendConsignmentModal } from 'modules/consignments/components/modal';
import SlzTable from 'components/common/SlzTable/Table';
import { transformCreateProductPayload } from 'modules/products/mappers/product-list-mapper';
import useCreateProductMutation from 'modules/products/hooks/useCreateProductMutation';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { useQuery } from '../../../../hooks/useQuery';
import {
  PRODUCTS_ADD_TYPES,
  PRODUCTS_STEPS,
  PRODUCT_KEY,
  PRODUCT_KEY_PARAM,
  TAG_DEFAULT_COLOR
} from '../../constants';
import { useProductDetailQuery } from '../../hooks/useProductDetailQuery';
import { transformProductDetail } from '../../mappers/product-detail-mapper';
import { fake } from '../../_mock';
import { ProductAddModal, ProductEditModal } from '../modal';
import { defaultGuid } from 'Utilities';
import { transformProductPayload, transformReturnSettingsPayload } from 'modules/products/utils';
import useUpdateProductMutation from 'modules/products/hooks/useUpdateProductMutation';
import useSetProductReturnSettingsMutation from 'modules/products/hooks/useSetProductReturnSettingsMutation';
import { getPageIndex } from 'modules/products/utils';
import useUploadProductsMutation from 'modules/products/hooks/useUploadProductsMutation';
import { PRODUCT_COLUMNS } from './products.columns';

const filterOptions = [
  {
    key: 'hard',
    text: 'Hard',
    colorScheme: 'szrElectricBlue'
  },
  {
    key: 'easy',
    text: 'Easy',
    colorScheme: 'ibiza'
  },
  {
    key: 'difficult',
    text: 'Difficult',
    colorScheme: 'californiaOrange'
  },
  {
    key: 'finished',
    text: 'Finished',
    colorScheme: 'tetra'
  }
];

const defaultValue = {
  tag: { id: defaultGuid, text: '', colorCode: TAG_DEFAULT_COLOR },
  isReturnable: true,
  isPrePacked: false,
  isFragile: false,
  requiresBoxPackaging: false,
  requiresPaperPackaging: false
};

const ProductTable = (props) => {
  const {
    data,
    queryParams,
    totalPage,
    handleItemPerPageChange,
    handleCurrentPageChange,
    isOpenAddProduct,
    onRefetching,
    onCloseAddProduct,
    refetchProducts,
    ...rest
  } = props;
  const [products, setProducts] = useLocalStorage(PRODUCT_KEY, fake());
  const [errorCSVFiles, setErrorCSVFiles] = useState([]);
  const query = useQuery();
  const history = useHistory();
  const [productId, setProductId] = useState(null);
  const [skuID, setSKUID] = useState(null);
  const [_, setToast] = useSlzToast();

  const {
    data: productsDetail,
    isFetching,
    refetch
  } = useProductDetailQuery(productId, skuID, !!productId);

  const { getUser } = useAuth();

  const mutationCreateProduct = useCreateProductMutation();
  const { isLoading: isCreatingProduct } = mutationCreateProduct;

  const mutationUpdateProduct = useUpdateProductMutation();
  const { isLoading: isUpdatingProduct } = mutationUpdateProduct;

  const mutationSetProductReturnSettings = useSetProductReturnSettingsMutation();
  const mutationUploadProducts = useUploadProductsMutation();
  const { isLoading: isUploadingProduct } = mutationUploadProducts;

  const {
    isOpen: isOpenAddProductModal,
    onOpen: onOpenAddProductModal,
    onClose: onCloseAddProductModal
  } = useDisclosure({ defaultIsOpen: isOpenAddProduct });

  const {
    isOpen: isOpenAddStockModal,
    onOpen: onOpenAddStockModal,
    onClose: onCloseAddStockModal
  } = useDisclosure({ defaultIsOpen: false });

  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: !!query.get('modal') });
  const [selectedItem, setSelectedItem] = useState(
    () =>
      query.get('itemId') && find(products, ({ identifier }) => identifier == query.get('itemId'))
  );

  const handleShowingModal = (row, step) => {
    if (!selectedItem || selectedItem.identifier !== row.identifier) {
      setProductId(row.identifier);
      setSKUID(row.sku || '');
      const pageIdx = getPageIndex(history.location.search) || DEFAULT_PAGE_INDEX;
      pageIdx && query.set(PAGE_INDEX, parseInt(pageIdx));
      query.set('itemId', row.identifier);
      query.set('sku', row.sku || '');
      query.set('stockCount', row.totalStock);
      query.set('status', row.status);
    }
    query.delete(PRODUCT_KEY_PARAM.IS_CREATE_PRODUCT_MODAL);
    query.set('modal', step);
    history.push({ search: query.toString() });
    onOpen();
  };

  const handleCloseModal = () => {
    query.delete('itemId');
    query.delete('sku');
    query.delete('modal');
    setProductId(null);
    history.push({ search: query.toString() });
    onClose();
  };

  const updateProducts = (product) => {
    const cpProducts = [...products];
    const foundIndex = findIndex(cpProducts, ({ identifier }) => identifier === product.identifier);
    if (foundIndex !== -1) {
      cpProducts[foundIndex] = product;
    }

    setProducts(cpProducts);
  };

  const handleUpdateProduct = async (product) => {
    try {
      updateProducts(product);

      const productPayload = transformProductPayload(selectedItem, product);
      await mutationUpdateProduct.mutateAsync(productPayload);

      const returnSettingsPayload = transformReturnSettingsPayload(product);
      if (returnSettingsPayload) {
        await mutationSetProductReturnSettings.mutateAsync({
          productId: product.id,
          payload: returnSettingsPayload
        });
      }

      refetch();
      refetchProducts();
    } catch (error) {}
  };

  const columns = [...PRODUCT_COLUMNS];
  columns[1] = {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true,
    isClickable: true,
    onClick: (row) => handleShowingModal(row, PRODUCTS_STEPS.OVERVIEW)
  };

  columns[7].onClick = onOpenAddStockModal;

  const onCreateProduct = async (data, currentType) => {
    const successToastConfig = {
      title: 'Success',
      status: 'success',
      colorScheme: 'positive'
    };

    const errorToastConfig = {
      title: 'There seems to be a problem',
      status: 'warning',
      colorScheme: 'negative'
    };

    try {
      if (currentType === PRODUCTS_ADD_TYPES.MANUAL) {
        const user = getUser();
        const payload = transformCreateProductPayload(data, user);
        await mutationCreateProduct.mutateAsync({ payload, imageFile: data.imageUrl });
        setToast({
          ...successToastConfig,
          description: `You have successfully added ${payload.item?.name} to your items`
        });
      } else {
        const resultSet = await mutationUploadProducts.mutateAsync(data);
        const hasError = resultSet.some((item) => item.isError);

        setErrorCSVFiles(resultSet.filter((item) => item.isError).map((item) => item.fileName));

        resultSet.forEach((item, idx) => {
          setTimeout(
            () =>
              setToast({
                ...(item.isError ? errorToastConfig : successToastConfig),
                description: item.message || ''
              }),
            idx * 200
          );
        });

        return !hasError;
      }

      return true;
    } catch (error) {
      if (currentType === PRODUCTS_ADD_TYPES.MANUAL) {
        setToast({
          ...errorToastConfig,
          description: error?.response?.data?.message || ''
        });
      }

      return false;
    }
  };

  const handleCloseAddProductModal = () => {
    onCloseAddProductModal();
    onCloseAddProduct && onCloseAddProduct();
  };

  const handleOpenAddStockModal = () => {
    onClose();
    onOpenAddStockModal();
  };

  const handleSaveStock = () => {};

  useEffect(() => {
    if (query.get('itemId')) {
      onOpen();
      setProductId(query.get('itemId'));
    }
  }, [query.get('itemId')]);

  useEffect(() => {
    setSelectedItem({
      ...productsDetail,
      sku: query.get('sku'),
      imageUrl: get(productsDetail, 'itemImages[0].link'),
      stockCount: query.get('stockCount'),
      status: query.get('status')
    });
  }, [isFetching]);

  useEffect(() => {
    if (!isOpenAddStockModal) {
      if (query.get('modal')) {
        onOpen();
      }
    }
  }, [isOpenAddStockModal]);

  const actions = {
    icon: <DragHandleIcon w={5} h={5} />,
    list: [
      {
        label: 'Product overview',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.OVERVIEW)
      },
      {
        label: 'Sales & pricing',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.SALES_PRICING)
      },
      {
        label: 'Packaging preferences',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.PACKAGES_PREFERENCE)
      },
      {
        label: 'Return information',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.RETURN_INFORMATION)
      }
    ]
  };

  useEffect(() => {
    if (isOpenAddProduct) {
      onOpenAddProductModal();
    }
  }, [isOpenAddProduct]);

  return (
    <Fragment>
      <ProductEditModal
        currentViewIdx={parseInt(query.get('modal'))}
        isLoading={isFetching || isUpdatingProduct}
        isOpen={isOpen}
        formValues={transformProductDetail(selectedItem)}
        onClose={handleCloseModal}
        onSave={handleUpdateProduct}
        onAddStock={handleOpenAddStockModal}
      />

      <ProductAddModal
        onClose={handleCloseAddProductModal}
        isOpen={isOpenAddProductModal}
        type={props?.addType || null}
        onSave={onCreateProduct}
        formValues={defaultValue}
        isSubmitting={isCreatingProduct}
        errorCSVFiles={errorCSVFiles}
      />

      <SendConsignmentModal
        onClose={onCloseAddStockModal}
        isOpen={isOpenAddStockModal}
        onSave={handleSaveStock}
      />

      <SlzTable
        variant="productList"
        sizes={['sm', 'md', 'lg']}
        showMarked={true}
        isLoading={props?.isLoading}
        showHeader={true}
        columns={columns}
        data={data}
        isEmptyPage={props?.isEmptyPage}
        onRefetching={onRefetching}
        messageToast="Sorry! We could not retrieve any of your product information"
        displayFields={['image', 'name', 'sku', 'suid', 'tag', 'totalStock', 'createdDate', 'cta']}
        hyperLinks={['name']}
        filters={{
          title: 'Tags',
          byField: 'tag',
          //options: filterOptions
        }}
        filterByDate={{
          field: 'createdDate',
          start: rest?.startDate,
          end: rest?.endDate
        }}
        actions={actions}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => handleItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: queryParams?.pageSize ?? DEFAULT_PAGE_SIZE
          },
          direction: {
            usingDots: false,
            totalPage: totalPage,
            currentPage: parseInt(query.get(PAGE_INDEX)) || DEFAULT_PAGE_INDEX,
            // numDots: 5,
            isGreyBg: true,
            onChange: (currentPage) => handleCurrentPageChange(currentPage)
          }
        }}
      />
    </Fragment>
  );
};

export default ProductTable;
