import React from 'react';
import { Icon } from '@chakra-ui/react';
const CheckSuccessPasswordCircleIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="192"
      height="192"
      viewBox="0 0 192 192"
      {...props}>
      <svg>
        <g id="Group_4549" data-name="Group 4549" transform="translate(11970.15 -23180.141)">
          <circle
            id="Ellipse_1213"
            data-name="Ellipse 1213"
            cx="96"
            cy="96"
            r="96"
            transform="translate(-11970.15 23180.141)"
            fill={props?.fill || '#0973ea'}
          />
          <path
            id="Back_Stroke_"
            data-name="Back (Stroke)"
            d="M43.144,72.28l-4.273-4.248L34.6,72.28A6.068,6.068,0,0,0,43.144,72.28ZM38.872,59.535,10.916,31.74a6.068,6.068,0,0,0-8.546,0,5.984,5.984,0,0,0,0,8.5L34.6,72.28l4.273-4.248,4.273,4.248,62.181-61.824a5.984,5.984,0,0,0,0-8.5,6.068,6.068,0,0,0-8.547,0Z"
            transform="translate(-11927.963 23239.443)"
            fill="#fff"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default CheckSuccessPasswordCircleIcon;
