import { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GridItem, useBoolean } from '@chakra-ui/react';

import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import useAuth from 'hooks/useAuth';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import useSlzToast from 'hooks/useSlzToast';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import {
  ACTION_MESSAGE,
  ACTION_TYPES,
  ADDRESS_BOOK_DEFAULT_PAGE,
  BREAD_CRUMB_ITEMS,
  CONFIRM_DELETE_TOAST_ID,
  DELETE_CONFIRMATION,
  STYLE_WARNING_TOAST
} from '../constants';
import { PAGE_INDEX } from 'constants/table.js';
import AddressBookFilter from '../components/form/AddressBookFilter';
import { AddressBookTable } from '../components/table';
import { useAddressBookContext } from '../context/AddressBookContext';
import {
  transformAddressBook,
  transformRecipientPayload,
  transformRecipientToFormValues
} from '../mappers/address-book-list-mapper';
import { GET_ADDRESS_BOOK_LIST, SET_QUERY_PARAM } from '../actions';
import {
  useAddressBookQuery,
  useDeleteRecipientMutation,
  useSaveRecipientMutation
} from '../hooks';
import ConfirmToast from '../components/other/ConfirmToast';
import AddOrEditRecipientModal from '../components/modal/AddOrEditRecipientModal';

const AddressBookList = () => {
  const routerQuery = useRouterQuery();
  const { getUser } = useAuth();
  const user = getUser();
  const history = useHistory();
  const [currentRecipient, setCurrentRecipient] = useState(null);
  const [enableQuery, setEnableQuery] = useState(false);
  const [shouldModalVisible, setShouldModalVisible] = useBoolean(false);
  const [_, dispatch] = useGlobalState();
  const { addressBook, addressBookDispatch } = useAddressBookContext();
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const {
    data: { addresses = [], totalPage } = {},
    isFetching,
    refetch,
    isError
  } = useAddressBookQuery({
    companyID: user.companyID,
    filters: addressBook?.params,
    select: transformAddressBook,
    enableQuery
  });
  const deleteRecipientMutation = useDeleteRecipientMutation();
  const saveRecipientMutation = useSaveRecipientMutation();
  const { isLoading } = saveRecipientMutation;
  const [_state, setToast, toast] = useSlzToast();

  const { handleCurrentPageChange, handleSearch } = useTableFilterHandler();

  const deleteRecipient = useCallback(async (recipient) => {
    toast.closeAll();
    try {
      await deleteRecipientMutation.mutateAsync(recipient?.id);

      setToast({
        description: ACTION_MESSAGE.DELETE.SUCCESS
      });
      setShouldModalVisible.off();
      refetch();
    } catch (error) {
      setToast({
        description: ACTION_MESSAGE.DELETE.FAIL,
        status: 'warning',
        colorScheme: 'negative'
      });
    }
  }, []);

  const handleDeleteRecipient = useCallback((recipient) => {
    !toast.isActive(CONFIRM_DELETE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: CONFIRM_DELETE_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <ConfirmToast
            {...DELETE_CONFIRMATION}
            onConfirmed={() => deleteRecipient(recipient)}
            onCancel={toast.closeAll}
          />
        )
      });
  }, []);

  const handleEditRecipient = useCallback(() => {
    setShouldModalVisible.on();
  }, []);

  const handleAddRecipient = useCallback(() => {
    setCurrentRecipient(null);
    setShouldModalVisible.on();
  }, []);

  const handleSaveRecipient = async (data) => {
    try {
      const isEdit = !!data.id;
      const { companyID } = user ?? {};

      const payload = transformRecipientPayload(data, companyID);
      await saveRecipientMutation.mutateAsync(payload, companyID);
      setToast({
        description: isEdit
          ? ACTION_MESSAGE.SAVE.UPDATE_SUCCESS
          : ACTION_MESSAGE.SAVE.CREATE_SUCCESS
      });
      refetch();
      setShouldModalVisible.off();
    } catch (error) {
      const isDuplicate = error?.response?.status === 400;

      setToast({
        description: isDuplicate ? ACTION_MESSAGE.SAVE.DUPLICATE : ACTION_MESSAGE.SAVE.FAIL,
        status: 'warning',
        colorScheme: 'negative'
      });
    }
  };

  const handleActionSelect = useCallback((recipient, option) => {
    setCurrentRecipient(recipient);

    switch (option?.value) {
      case ACTION_TYPES.DELETE:
        handleDeleteRecipient(recipient);
        return;
      case ACTION_TYPES.EDIT:
        handleEditRecipient(recipient);
        return;
    }
  }, []);

  const handleSearchingNavigate = (record) => {
    handleSearch('name', record.name);
    setSelectedSearchResult([record]);
  };

  useEffect(() => {
    const params = {
      pageIndex: routerQuery.get(PAGE_INDEX) ?? ADDRESS_BOOK_DEFAULT_PAGE
    };
    const queryText = routerQuery.get('name');

    if (queryText) {
      params.name = queryText;
    }

    addressBookDispatch({
      type: SET_QUERY_PARAM,
      payload: { params }
    });
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [addressBook?.params]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  useEffect(() => {
    addressBookDispatch({
      type: GET_ADDRESS_BOOK_LIST,
      payload: { addresses }
    });
  }, [isFetching]);

  return (
    <Fragment>
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <AddressBookFilter
            onSearchingNavigate={handleSearchingNavigate}
            onAddRecipient={handleAddRecipient}
            status={routerQuery.get('status')}
            onPageChange
            onSearch={(value) => {
              if (!value) {
                setSelectedSearchResult(null);
              }
              handleSearch('name', value);
            }}
            initSearchValue={routerQuery.get('name') || ''}
          />
          <AddressBookTable
            totalPage={totalPage}
            selectedSearchResult={selectedSearchResult}
            currentPage={routerQuery.get(PAGE_INDEX)}
            isLoading={isFetching}
            onRefetching={refetch}
            isEmptyPage={isError || totalPage === 0}
            messageToast="Sorry! We could not retrieve contact information"
            onPageChange={handleCurrentPageChange}
            handleActionSelect={handleActionSelect}
          />
        </GridItem>
      </SlzGrid>
      <AddOrEditRecipientModal
        isOpen={shouldModalVisible}
        refetch={refetch}
        onClose={setShouldModalVisible.off}
        onSave={handleSaveRecipient}
        formValues={transformRecipientToFormValues(currentRecipient)}
        onRemove={() => handleDeleteRecipient(currentRecipient)}
        isSubmitting={isLoading}
      />
    </Fragment>
  );
};

export default AddressBookList;
