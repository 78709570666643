import get from 'lodash/get';

export const transformAccordionItems = (items) =>
  items?.map((item) => {
    return {
      id: item.id,
      name: item?.companyItem?.item?.name || 'N/A',
      selazarUniqueID: item?.companyItem?.item?.selazarUniqueID || 'N/A',
      weight: item?.companyItem?.item?.weight || 0,
      ean: item?.companyItem?.item?.ean,
      suid: item?.companyItem?.item?.suid || 'N/A',
      quantity: item?.quantity || 0,
      skuCode: item?.skuCode || 'N/A',
      price: item?.companyItem?.item?.price || 0,
      imageUrl: get(item, 'companyItem.item.itemImages[0].link')
    };
  });
