import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
export default class SlzCalendarNext extends React.Component {
  static defaultProps = {
    next: true
  };

  handleNext(calendar) {
    const { handleNext } = this.props;
    if (handleNext) {
      handleNext(calendar);
    }
  }

  render() {
    const { next, calendar, isDisableRightButton, position } = this.props;
    const className = classNames({ next, available: next });
    const onClick = calendar ? this.handleNext.bind(this, calendar) : () => {};
    const Span = next ? <span /> : null;
    const nextProps = {
      className,
      onClick
    };
    const disableStyle = {
      cursor: 'default',
      pointerEvents: 'none'
    };

    return (
      <Box
        data-testid={`slz-calendar-${position}-next`}
        onClick={onClick}
        className={isDisableRightButton ? 'disabled' : ''}
        style={isDisableRightButton ? disableStyle : {}}>
        <ChevronRightIcon w={20} h={20} />
      </Box>
    );
  }
}
