import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { useGlobalState } from 'contexts/GlobalContext';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';

import { useOrderDetailQuery } from 'modules/b2c-orders/hooks/useOrderDetailQuery';
import { transformOrderDetail } from 'modules/b2c-orders/mappers/order-detail-mapper';
import OrderMultipleShipment from './OrderMultipleShipment';
import OrderPartials from './OrderPartials';
import OrderSingleShipment from './OrderSingleShipment';
import { BREAD_CRUMB_ITEMS } from 'modules/b2c-orders/constants';

export const ORDER_DETAIL_TYPE = {
  singleShipment: 'singleShipment',
  multipleShipment: 'multipleShipment',
  orderPartials: 'orderPartials'
};

const TEMPLATE = {
  [ORDER_DETAIL_TYPE.singleShipment]: OrderSingleShipment,
  [ORDER_DETAIL_TYPE.multipleShipment]: OrderMultipleShipment,
  [ORDER_DETAIL_TYPE.orderPartials]: OrderPartials
};

const TYPE_MAPPING = {
  1: ORDER_DETAIL_TYPE.singleShipment,
  moreThan1: ORDER_DETAIL_TYPE.multipleShipment,
  0: ORDER_DETAIL_TYPE.singleShipment
};

/*   const OrderDetailTemplate = ({ forcedType = undefined, ...rest }) => {
  const { orderId } = useParams();
  const routerQuery = useRouterQuery();
  const { data, isLoading, isSuccess } = useOrderDetailQuery(
    orderId,
    routerQuery.get('status'),
    routerQuery.get('source'),
    (data) => transformOrderDetail(data, routerQuery.get('status'))
  ); */

const OrderDetailTemplate = ({ forcedType = undefined, ...rest }) => {
  const { state: { row } = {} } = useLocation();
  const { data, isLoading, isSuccess } = useOrderDetailQuery(
    row,
    (data) => transformOrderDetail(data.data)
  );

  const [_, dispatch] = useGlobalState();
  const orderBreakdownLength =
    [data?.orderBreakdown || [], data?.orderNumber || []].flat().length || 0;
  const type =
    forcedType || TYPE_MAPPING[orderBreakdownLength > 1 ? 'moreThan1' : orderBreakdownLength];

  useEffect(() => {
    let breadcrumbs = [...BREAD_CRUMB_ITEMS];

    if (isSuccess) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          title: data?.customer?.reference
        }
      ];
    }

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadcrumbs
    });
  }, [isSuccess]);

  const Template = TEMPLATE[type];

  return <Template isLoading={isLoading} data={data} {...rest} />;
};

export default OrderDetailTemplate;
