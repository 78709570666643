import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzButton } from 'components/common/SlzButton';

const B2BOrderSubStatus = (props) => {
  const { status, isLoading } = props;

  return (
    <Skeleton isLoaded={!isLoading} width="100%" height="100%" borderRadius={1.5}>
      <SlzButton
        size="lg"
        width="full"
        bg={status?.bg}
        _hover={{ bg: status?.bg }}
        boxShadow="slz-md">
        {status?.text}
      </SlzButton>
    </Skeleton>
  );
};

export default B2BOrderSubStatus;
