const NAVIGATION_RAIL_WIDTH = 96;
const NAVIGATION_RAIL_EXPANDED_WIDTH = 248;
const MARGIN_FOR_PAGE_LARGE_SCREEN_WIDTH = 24;
const MARGIN_FOR_PAGE_SMALL_SCREEN_WIDTH = 12;
const GUTTER_FOR_PAGE_WIDTH = 24;
const CELL_MARKED_WIDTH = 12;
const CELL_IMAGE_WIDTH = 80;
const PRODUCT_SECONDARY_BUTTON_WIDTH = 48;
const CELL_CHILD_ACCORDION_TABLE_WIDTH = 95;
const CELL_BUTTON_ACCORDION_WIDTH = 48;

export {
  NAVIGATION_RAIL_WIDTH,
  NAVIGATION_RAIL_EXPANDED_WIDTH,
  MARGIN_FOR_PAGE_LARGE_SCREEN_WIDTH,
  MARGIN_FOR_PAGE_SMALL_SCREEN_WIDTH,
  GUTTER_FOR_PAGE_WIDTH,
  CELL_MARKED_WIDTH,
  CELL_IMAGE_WIDTH,
  PRODUCT_SECONDARY_BUTTON_WIDTH,
  CELL_BUTTON_ACCORDION_WIDTH,
  CELL_CHILD_ACCORDION_TABLE_WIDTH
};
