import React from 'react';
import uniqueId from 'lodash/uniqueId';
import {
  Button,
  ListItem,
  StylesProvider,
  UnorderedList,
  useMultiStyleConfig,
  useStyles
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ChevronRightIcon, DashboardIcon } from '../Icons';

const SlzBreadcrumbItem = ({ path, title, onClick }) => {
  const styles = useStyles();

  return (
    <Button
      role="breadcrumb-item"
      variant="unstyled"
      sx={styles.item}
      onClick={() => onClick(path)}>
      <ChevronRightIcon />
      {title}
    </Button>
  );
};

const SlzBreadcrumb = ({ size, variant, items = [], onNavigate }) => {
  const styles = useMultiStyleConfig('SlzBreadcrumb', { size, variant });

  const handleNavigation = (path) => {
    onNavigate && onNavigate(path);
  };

  return (
    <StylesProvider value={styles}>
      <UnorderedList sx={styles.container}>
        <ListItem>
          <Button variant="unstyled" __css={styles.homeItem} onClick={() => handleNavigation('/')}>
            <DashboardIcon />
          </Button>
        </ListItem>

        {items.map(({ path, title }) => (
          <ListItem key={uniqueId()}>
            <SlzBreadcrumbItem path={path} title={title} onClick={handleNavigation} />
          </ListItem>
        ))}
      </UnorderedList>
    </StylesProvider>
  );
};

export default SlzBreadcrumb;
SlzBreadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string
    })
  )
};
