import { useId, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  chakra,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Td,
  useDisclosure
} from '@chakra-ui/react';

import { useOutside } from '../../../hooks/useOutside';
import { Menu as MenuPseudo } from './Pseudo';

const MenuOnRow = (props) => {
  const { actions, row, hoveredRows = {} } = props;
  const { icon, list } = actions;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrapperRef = useRef(null);
  useOutside(wrapperRef, onClose);
  const handleClick = (e, item) => {
    item?.onClick && item?.onClick(e, row);
    onClose();
  };

  const getIconStyle = () => {
    let style = { ...MenuPseudo.button.after };

    if (true === hoveredRows[row?.id]) {
      style = {
        ...style,
        ...MenuPseudo.button.hover._after,
        zIndex: 0
      };
    }
    return style;
  };

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Td width="3.625rem" p={0} pos="relative" ref={wrapperRef} onMouseLeave={handleClose}>
      <Box pos="relative">
        <Flex
          data-testid={`btn-hamburger-icon-${row?.id}`}
          justifyContent="center"
          alignItems="center"
          onMouseOver={handleOpen}>
          <Popover trigger="hover" placement="bottom-end" arrowSize={20} offset={[8, 8]}>
            <PopoverTrigger>
              <Box>
                <IconButton
                  id={`${row?.id}-btn-menu`}
                  aria-label="Options"
                  transition="all 0.2s"
                  variant="unstyled"
                  pos="relative"
                  zIndex={1}
                  h={7}
                  sx={{ display: 'inline-flex' }}
                  alignItems="center"
                  justifyContent="center"
                  icon={icon ?? <HamburgerIcon w={2} h={2} />}></IconButton>
                <Box data-testid={`hover-circle-${row?.id}`} sx={getIconStyle()}></Box>
              </Box>
            </PopoverTrigger>
            <Portal>
              <PopoverContent w="11.313rem" pt={2} boxShadow="slz-3md">
                <PopoverArrow transform="scaleX(0.5) rotate(45deg) !important" />
                <chakra.ul w="full" h="full" zIndex={1} mb="0">
                  {list.map((item) => (
                    <chakra.li
                      key={useId()}
                      color="dark.700"
                      fontSize="custom.sm"
                      w="full"
                      py={1.5}
                      px={2.5}
                      maxH="1.875rem"
                      textAlign="start"
                      _hover={{ outline: 'none', bg: 'dark.300' }}
                      cursor="pointer"
                      userSelect="none"
                      listStyleType="none"
                      onClick={(e) => handleClick(e, item)}>
                      {item?.label}
                    </chakra.li>
                  ))}
                </chakra.ul>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
      </Box>
    </Td>
  );
};

export default MenuOnRow;
