import { Fragment } from 'react';
import { Box, HStack, Heading, Icon, Text, VStack } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';

const InformationBox = ({ label, value }) => {
  return (
    <HStack
      border="0.0625rem solid"
      borderColor="main.500"
      borderRadius="0.375rem"
      padding="0.8125rem 1.0625rem"
      minW="10rem"
      justifyContent="space-between">
      <Text fontSize="1rem" lineHeight="1.3125rem" color="main.500" fontWeight="medium">
        {label}
      </Text>
      <Text fontSize="1rem" lineHeight="1.3125rem" fontWeight="medium">
        {value}
      </Text>
    </HStack>
  );
};

const OrderDetailModalHeader = ({ onBack, itemCount, orderValue }) => {
  return (
    <Fragment>
      <HStack alignItems="center">
        <Icon
          as={FaChevronLeft}
          fontSize={28}
          color="dark.500"
          mb="0.5"
          cursor="pointer"
          onClick={onBack}
        />
        <Box>
          <Heading as="h3" fontSize="2rem" lineHeight="2.6875rem" mb="0">
            Back to returns
          </Heading>
        </Box>
      </HStack>
      <HStack spacing={0} justifyContent="space-between" alignItems="flex-start">
        <VStack spacing="0.1875rem" alignItems="flex-start" mt="1.375rem" mb="0.875rem">
          <Text fontSize="1.25rem" fontWeight="bold" lineHeight="1.625rem">
            Order Summary
          </Text>
          <Text fontSize="0.75rem" lineHeight="1rem">
            Full shipment information can be found in your orders
          </Text>
        </VStack>
        <HStack spacing={3}>
          <InformationBox label="Order value" value={`£ ${orderValue}`} />
          <InformationBox label="Item count" value={itemCount} />
        </HStack>
      </HStack>
    </Fragment>
  );
};
export default OrderDetailModalHeader;
