import { Box, Text, VStack } from '@chakra-ui/react';
import { ProductImage } from '.';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { RETURN_DETAIL_TEXT } from 'modules/returns/constants';
import { RETURN_DETAIL_EVIDENCE_STYLE } from 'modules/returns/constants/styles';

const ReturnDetailEvidence = ({ imageSrc, isLoading, ...rest }) => {
  return (
    <Box w="full" {...rest}>
      <VStack {...RETURN_DETAIL_EVIDENCE_STYLE}>
        <Text fontSize="lg" fontWeight="bold" lineHeight={5.5}>
          {RETURN_DETAIL_TEXT.evidence.title}
        </Text>
        <Text fontSize="sm" fontWeight="medium" lineHeight="none">
          {RETURN_DETAIL_TEXT.evidence.description}
        </Text>
      </VStack>
      {isLoading ? (
        <Skeleton width="full" h="32.188rem" />
      ) : (
        <ProductImage h="32.188rem" imageSrc={imageSrc} />
      )}
    </Box>
  );
};

export default ReturnDetailEvidence;
