import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GridItem } from '@chakra-ui/react';

import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import ReturnsFilter from '../components/form/ReturnsFilter';
import { ReturnsTable } from '../components/table';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  PAGE_INDEX,
  PAGE_SIZE,
  DATE_FROM,
  DATE_TO
} from 'constants/table.js';
import { RETURNS_PATHNAME } from '../constants';
import { useReturnsQuery } from '../hooks/useReturnsQuery';
import { transformReturns } from '../mappers/return-list-mapper';
import { BREAD_CRUMB_ITEMS } from '../constants';

const ReturnsList = () => {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [enableQuery, setEnableQuery] = useState(false);
  const [params, setParams] = useState(null);
  const [_, dispatch] = useGlobalState();
  const [selectedSearchResult] = useState(null);

  const {
    handleCurrentPageChange,
    handleItemPerPageChange,
    handleSelectedDateRange,
    handleSearch
  } = useTableFilterHandler();

  const {
    data: { returns = [], totalPage } = {},
    isFetching,
    refetch,
    isError
  } = useReturnsQuery({
    filters: params,
    select: transformReturns,
    enabled: enableQuery
  });

  const handleFilterChange = (options) => {
    if (options?.length === 0) {
      routerQuery.delete('filter');
    } else {
      routerQuery.set('filter', options[options.length - 1]?.key);
    }

    history.push({
      search: routerQuery.toString()
    });
  };

  const handleSearchingNavigate = (record) => {
    history.push(`${RETURNS_PATHNAME}/${record.retailerReturnID}?status=${record.status.key}`);
  };

  useEffect(() => {
    const params = {
      search: routerQuery.get('search') ?? '',
      dateFrom: routerQuery.get(DATE_FROM) ?? '',
      dateTo: routerQuery.get(DATE_TO) ?? '',
      pageIndex: routerQuery.get(PAGE_INDEX) ?? DEFAULT_PAGE_INDEX,
      pageSize: routerQuery.get(PAGE_SIZE) ?? DEFAULT_PAGE_SIZE
    };

    setParams(params);
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [params]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  return (
    <Fragment>
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <ReturnsFilter
            onSearchingNavigate={handleSearchingNavigate}
            onDateRangeChange={handleSelectedDateRange}
            status={routerQuery.get('status')}
            params={params}
            onSearch={(value) => handleSearch('search', value)}
            initSearchValue={routerQuery.get('search') || ''}
          />
          <ReturnsTable
            data={selectedSearchResult || returns}
            params={params}
            totalPage={totalPage}
            onRefetching={refetch}
            isEmptyPage={isError || totalPage === 0}
            messageToast="Sorry! We could not retrieve any returns information"
            currentPage={routerQuery.get(PAGE_INDEX)}
            isLoading={isFetching}
            onPageChange={handleCurrentPageChange}
            onItemPerPageChange={handleItemPerPageChange}
            onFilterChange={handleFilterChange}
          />
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
};

export default ReturnsList;
