import { useQuery } from 'react-query';

import { getSelectedWarehouse } from 'modules/preferences/services/preferences-api';

const useGetSelectedWarehouseQuery = () => {
  return useQuery({
    queryKey: [`selected-warehouse`],
    queryFn: () => getSelectedWarehouse(),
    select: (response) => response?.data?.data
  });
};

export default useGetSelectedWarehouseQuery;
