import { Card, FormControl, FormErrorMessage, HStack, Text, VStack } from '@chakra-ui/react';

import SlzCheckBoxGroup from 'components/common/SlzCheckbox/SlzCheckBoxGroup';
import { SlzChip } from 'components/common/SlzChip';
import { SlzToggle } from 'components/common/SlzToggle';

import { CONSIGNMENT_LABELLING_TEXT_CONTENTS } from 'modules/consignments/constants';
import { CONSIGNMENT_LABELLING_STYLES } from 'modules/consignments/constants/styles';

const LabellingContent = ({
  register,
  errors,
  labellingEnabledHandler,
  labelsEnabledHandler,
  checkboxData
}) => (
  <VStack>
    <HStack width="100%" alignItems="flex-start">
      <SlzChip bg="#FECB2E" color="dark.700">
        Important
      </SlzChip>
      <Text {...CONSIGNMENT_LABELLING_STYLES.text} fontSize="12px">
        {CONSIGNMENT_LABELLING_TEXT_CONTENTS.importantNote}
      </Text>
    </HStack>

    <HStack width="100%">
      <FormControl isInvalid={errors?.Labelling}>
        <SlzToggle
          lineHeight="0"
          {...register('enabledLabelling')}
          onChange={labellingEnabledHandler}>
          {CONSIGNMENT_LABELLING_TEXT_CONTENTS.toggleText}
        </SlzToggle>
        <FormErrorMessage fontSize="lg">{errors?.enabledLabelling?.message}</FormErrorMessage>
      </FormControl>
    </HStack>

    <HStack width="100%">
      <Text {...CONSIGNMENT_LABELLING_STYLES.text}>Which labels do you want Selazar to affix?</Text>
    </HStack>
    <HStack width="100%" paddingBottom="1rem">
      <SlzCheckBoxGroup data={checkboxData} onValueChange={labelsEnabledHandler} />
      <VStack w="50%" mb="3rem" alignItems="right" paddingLeft="32%">
        <Card {...CONSIGNMENT_LABELLING_STYLES.card} bg="main.300" height="2rem">
          <HStack px="1.5rem">
            <Text
              {...CONSIGNMENT_LABELLING_STYLES.text}
              fontSize="12px"
              color="main.500"
              fontWeight="300">
              <b>Note</b>
            </Text>
          </HStack>
        </Card>
        <Card {...CONSIGNMENT_LABELLING_STYLES.card} bg="#fafafa" padding="2px">
          <HStack px="1.5rem">
            <Text {...CONSIGNMENT_LABELLING_STYLES.text} fontSize="12px" fontWeight={300}>
              {CONSIGNMENT_LABELLING_TEXT_CONTENTS.note}
            </Text>
          </HStack>
        </Card>
      </VStack>
    </HStack>
  </VStack>
);

export default LabellingContent;
