import { DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';

export const transformBundles = ({ productBundles = [], pageCount = 0 }) => {
  let data = {
    productBundles: [],
    totalPage: pageCount
  };
  if (!productBundles) return data;
  return {
    productBundles: handleProductBundles(productBundles),
    totalPage: pageCount
  };
};

const handleProductBundles = (data) => {
  return data.map((bundle) => {
    return {
      id: bundle.id,
      name: bundle.name,
      skuCode: bundle.skuCode,
      bundleTag: {
        key: bundle.itemCustomTag?.id,
        name: bundle.itemCustomTag?.text,
        color: bundle.itemCustomTag?.colorCode
      },
      bundleStockTotal: bundle.bundleStockTotal
    };
  });
};

export const mapBundleDetail = (item) => ({
  ...item,
  deleted: false,
  productBundleCompanyItems: mapBundleItem(item?.productBundleCompanyItems),
  bundleTag: {
    key: item.itemCustomTag?.id,
    colorCode: item?.itemCustomTag?.colorCode,
    text: item?.itemCustomTag?.text
  }
});

const mapBundleItem = (arrayData) => {
  if (!Array.isArray(arrayData)) return [];
  return arrayData.map((bundleItem) => {
    return {
      name: bundleItem?.companyItem?.item?.name,
      quantity: bundleItem?.quantity,
      skuCode: bundleItem?.skuCode,
      ean: bundleItem?.companyItem?.item?.ean || bundleItem?.companyItem?.item?.selazarUniqueID,
      productBundleID: bundleItem?.productBundleID,
      createdDate: bundleItem?.createdDate,
      id: bundleItem?.id,
      companyItemID: bundleItem?.companyItemID,
      companyItem: bundleItem?.companyItem,
      imageUrl: DEFAULT_IMAGE
    };
  });
};

export const transformBundleUpdatePayload = (data) => {
  const bundle = { ...data };
  return {
    ...bundle,
    itemCustomTag: {
      text: bundle?.bundleTag?.text,
      colorCode: bundle?.bundleTag?.colorCode
    },
    itemCustomTagID: bundle?.bundleTag.id,
    productBundleCompanyItems: transformSelectedProducts(
      bundle?.productBundleCompanyItems,
      bundle?.id
    )
  };
};

export const transformBundleCreatePayload = (data) => {
  const bundle = { ...data };
  return {
    ...bundle,
    itemCustomTag: {
      text: bundle?.tag?.text,
      colorCode: bundle?.tag?.colorCode
    },
    itemCustomTagID: bundle?.tag?.id,
    productBundleCompanyItems: transformSelectedProducts(
      data?.productBundleCompanyItems,
      bundle?.id
    )
  };
};

export const transformSelectedProducts = (selectedProducts, productBundleID) => {
  return selectedProducts.map((selectedProduct) => {
    const { companyItemID, id, skuCode, ...rest } = selectedProduct;
    return {
      companyItemID: selectedProduct?.companyItemID || selectedProduct?.id,
      skuCode,
      id: selectedProduct?.companyItem && id,
      productBundleID,
      ...rest
    };
  });
};

export const transformEditProducts = (selectedProducts, productBundleID) => {
  return selectedProducts.map((selectedProduct) => {
    delete selectedProduct?.tag;
    delete selectedProduct?.isSelected;
    if (selectedProduct?.imageUrl === null) delete selectedProduct?.imageUrl; // API not support update payload has images
    return {
      ...selectedProduct,
      productBundleID: selectedProduct.productBundleID || productBundleID
    };
  });
};

export const transformBundleTagsForFilter = (data = []) => {
  return data.map((item) => ({
    key: item.id,
    text: item.text,
    bg: item.colorCode?.trim()
  }));
};
