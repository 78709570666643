import { Text, Box } from '@chakra-ui/react';
import {
  DotIcon,
  PersonCircleIcon,
  PersonIcon,
  PersonShoppingCardIcon,
  ShoppingCardIcon,
  TickIcon,
  XMarkCircleIcon
} from 'components/common/Icons';
import { UserManagementModalLayout } from '../components/modal';
import AddUserDetailsForm from '../components/form/AddUserDetailsForm';
import { AddRoleAndPermissionForm, UserManagementSummaryForm } from '../components/form';
import B2BIcon from 'components/common/Icons/B2BIcon';
import { SlzTag } from 'components/common/SlzTag';

export const LOGIN_PATHNAME = '/';
export const REGISTER_PATHNAME = '/register/retailer';
export const REGISTER_RESULT_PATHNAME = '/register/result';
export const REGISTER_EMAIL_CONFIRM_PATHNAME = '/register/email/confirm/:id';
export const RESET_PASSWORD_PATHNAME = '/account/password/reset/:id';
export const FORGOT_PASSWORD_PATHNAME = '/forgot/password';
export const SUCCESS_PASSWORD_PATHNAME = '/account/password/result';
export const BREAD_CRUMB_ITEMS = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/',
    title: 'Login'
  }
];

export const BREAD_CRUMB_USERS_ITEMS = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/',
    title: 'Users'
  }
];

export const PREVIOUS_PAGE = {
  LOGIN: {
    title: 'Back to selazar.com',
    href: 'http://selazar.com/'
  },
  FORGOT_PASSWORD: {
    title: 'Back to login',
    href: '/'
  }
};

export const FORGOT_PASSWORD_TEXT = {
  passwordReset: 'Password reset',
  checkYourInbox: 'Check your inbox!',
  resendingYourEmail: 'Resending your email',
  emailAddress: 'Email address',
  resetPassword: 'Reset password',
  resendEmail: 'Resend email',
  instructionPasswordFirst: 'Enter the email address you used to register',
  instructionPasswordSecond: 'We will send you a link to reset your password',
  instructionUpResendEmail: 'We have emailed you a link!',
  instructionDownResendEmail: ' Follow the instructions to reset your password',
  instructionUpResendingEmail: 'We are resending your reset link!',
  instructionDownResendingEmail: 'It should be with you shortly',
  cannotFindEmail: 'Cannot find the email? Check your spam folder',
  or: 'Or',
  emailAddressNoLongInUse: 'Email address no longer in use?',
  refreshPage: 'Refresh this page if you need to resend the link again',
  closeThisWindows: 'You can close this window once you are ready',
  contactUs: 'Contact us ',
  customerService: 'customer.service@selazar.com',
  emailSupport: 'support@selazar.com'
};

export const VERIFICATION_EMAIL_TEXT = {
  title: 'Check your inbox!',
  title2: 'Resending your email',
  emailSended: 'We have sent a verification email!',
  emailSended2: 'Follow the instructions to activate your account',
  resendingYourEmail: 'We are resending your reset link!',
  resendingYourEmail2: 'It should be with you shortly',
  cannotFindEmail: 'Cannot find the email? Check your spam folder',
  or: 'Or',
  resendEmail: 'Resend email',
  havingIssues: 'Having issues? Contact us ',
  refreshPage: 'Refresh this page if you need to resend the link again',
  contactUs: 'Contact us ',
  emailSupport: 'customer.service@selazar.com',
  closeWindow: 'You can close this window once you are ready'
};

export const MESSAGE = {
  LOGIN: {
    ERROR: {
      TITLE: 'Ooooops! Login failed, please try again',
      DESCRIPTION: 'We do not recognise this user name and password combination.',
      HINT_DESCRIPTION: 'Please check you have the correct information and try again.'
    }
  },
  FORGOT: {
    ERROR: {
      TITLE: 'Ooooops! Please use a valid email address',
      DESCRIPTION: 'The email address you have entered is not in the correct format.',
      HINT_DESCRIPTION: 'The format should look like this example.',
      HINT_DESCRIPTION_HIGH_LIGHT: ' user@company.com',
      EMAIL: 'We need your email address',
      ARE_YOU_HUMAN: '* Are you a human?',
      CALL_API: 'Error send email...',
      USER_NOT_CORRECT: {
        TITLE: 'Ooooops! We do not recognise this user',
        DESCRIPTION: 'We do not recognise this user name or email you have entered.',
        HINT_DESCRIPTION: 'Please check you have the correct information and try again.'
      }
    }
  },
  REGISTER: {
    ERROR: {
      TITLE: 'Ooooops! Register failed, please try again'
    }
  }
};

export const PASSWORD_CRITERIA_STATUS = {
  init: 'init',
  pass: 'pass',
  fail: 'fail'
};

export const PASSWORD_VALIDATION_ICONS = {
  init: DotIcon,
  pass: TickIcon,
  fail: XMarkCircleIcon
};

export const NUMBER_PATTERN = /^(?=.*[0-9])/;
export const SPECIAL_CHAR_PATTERN = /^(?=.*[#$^+=!*()@%&£"'[\]{},_\\/;:`\-~<>?.])/;
export const LENGTH_PATTERN = /^.{6,}/;

export const INIT_PASSWORD_CRITERIA = {
  number: { text: 'One number', pattern: NUMBER_PATTERN, status: PASSWORD_CRITERIA_STATUS.init },
  specialChar: {
    text: 'One special character -!@£$%^&*()',
    pattern: SPECIAL_CHAR_PATTERN,
    status: PASSWORD_CRITERIA_STATUS.init
  },
  passwordLength: {
    text: 'At least six characters long',
    pattern: LENGTH_PATTERN,
    status: PASSWORD_CRITERIA_STATUS.init
  },
  confirmPassword: {
    text: 'Password and Confirm password have to match',
    status: PASSWORD_CRITERIA_STATUS.init
  }
};

export const LOGIN_IMAGE_URL_SET = `
  Images/login/login_section_4000px.webp 4000w,
  Images/login/login_section_2500px.webp 2550w,
  Images/login/login_section_1920px.webp 1920w,
  Images/login/login_section_1440px.webp 1440w,
  Images/login/login_section_1336px.webp 1336w,
  Images/login/login_section_1280px.webp 1280w,
`;

export const RESET_PASSWORD_IMAGE_URL_SET = `
  Images/resetPassword/recover_password_4000px.webp 4000w,
  Images/resetPassword/recover_password_2500px.webp 2550w,
  Images/resetPassword/recover_password_1920px.webp 1920w,
  Images/resetPassword/recover_password_1440px.webp 1440w,
  Images/resetPassword/recover_password_1336px.webp 1336w,
  Images/resetPassword/recover_password_1280px.jpg 1280w,
`;

export const REGISTRATION_PASSWORD_IMAGE_URL_SET = `
  Images/registration/User_registration_4000px.webp 4000w,
  Images/registration/User_registration_2500px.webp 2550w,
  Images/registration/User_registration_1920px.webp 1920w,
  Images/registration/User_registration_1440px.webp 1440w,
  Images/registration/User_registration_1336px.webp 1336w,
  Images/registration/User_registration_1280px.webp 1280w,
`;

export const MAX_SECONDS_REDIRECT_TIMER = 30;
export const DEFAULT_NUMBER_COUNTDOWN = 1;
export const TYPE_RELOAD = 1;

export const TITLES = [
  {
    label: 'Mr.',
    value: 'mr'
  },
  {
    label: 'Ms.',
    value: 'ms'
  },
  {
    label: 'Mrs.',
    value: 'mrs'
  }
];

export const TEXT_OVERLAY = {
  title: 'Rapid & Reliable Fulfilment',
  description: 'Store, pick, pack, and ship with Selazar for a seamless customer experience.'
};

export const MEDIA_LINKS = [
  {
    icon: 'linkedin-icon',
    url: 'https://www.linkedin.com/company/selazar-limited'
  },
  {
    icon: 'twitter-icon',
    url: 'https://twitter.com/Selazarfulfil'
  },
  {
    icon: 'instagram-icon',
    url: 'https://www.instagram.com/selazarfulfilment/'
  },
  {
    icon: 'facebook-icon',
    url: 'https://www.facebook.com/Selazarltd/'
  }
];

export const HOME_PAGE_URL = 'https://selazar.com/';

export const CONTACT_INFOS = {
  email: 'customer.service@selazar.com',
  telephone: '+44 (0)20 3950 7860'
};

export const USERS_PATHNAME = '/users';

export const USERS_SKELETON_CELL = {
  name: null,
  access: null,
  lastSeen: null,
  actions: null
};

export const USERS_TABLE = {
  ITEM_PER_PAGES: 15,
  PAGE_DEFAULT: 1,
  PAGES_OPTIONS_DEFAULT: [10, 15, 20]
};

export const USERS_DISPLAY_FIELDS_INPUT_SEARCH = ['forename', 'surname', 'email', 'actions'];

export const USERS_FILTER_OPTIONS = [
  {
    key: 'active',
    text: 'Active',
    byField: 'active',
    value: true,
    bg: '#579BFB',
    size: 'sm',
    height: 4,
    my: 'unset'
  },
  {
    key: 'inactive',
    text: 'Inactive',
    byField: 'active',
    value: false,
    bg: '#F5CCD3',
    color: 'red.300',
    size: 'sm',
    height: 4,
    my: 'unset'
  }
];

export const ACTION_TYPES = {
  EDIT_USER: 1,
  TOGGLE_USER: 2,
  REMOVE: 3,
  THIS_IS_YOU: 4
};

export const ACTION_OPTIONS = (active, thisIsYou) => {
  if (thisIsYou) {
    return [
      {
        value: ACTION_TYPES.THIS_IS_YOU,
        text: <Text align="left">This is you</Text>,
        isDisabled: false
      },
      {
        value: ACTION_TYPES.EDIT_USER,
        text: <Text align="left">Edit user</Text>,
        isDisabled: false
      }
    ];
  } else {
    return [
      {
        value: ACTION_TYPES.EDIT_USER,
        text: <Text align="left">Edit user</Text>,
        isDisabled: false
      },
      {
        value: ACTION_TYPES.TOGGLE_USER,
        text: <Text align="left">{active ? 'Deactivate' : 'Activate'}</Text>,
        isDisabled: false
      },
      {
        value: ACTION_TYPES.REMOVE,
        text: (
          <Text align="left" color="red.300">
            Remove
          </Text>
        ),
        isDisabled: false,
        isWarning: true
      }
    ];
  }
};

export const WARNING_LEAVE_TOAST_ID = 'warning-leave-toast-id';
export const CONFIRM_LEAVE_TOAST_ID = 'confirmLeaveToastId';

export const LEAVE_WARNING_TEXT = 'Oops! Are you sure you want to cancel creating this user?';

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const WARNING_TEXT = {
  ACTIVATE: 'Are you sure you want to activate this user?',
  DEACTIVATE: 'Are you sure you want to deactivate this user?',
  REMOVE: 'Are you sure you want to delete this user?'
};

export const TOAST_SUCCESS_MESSAGE = {
  TOGGLED_USER: '{{forename}} {{surname}}  has been {{toggledStatus}}!',
  DELETED: 'User has been deleted!'
};
export const USER_MANAGEMENT_DEFAULT_VALUE = {
  email: '',
  forename: '',
  id: '',
  ignore_whitespace: false,
  password: '',
  roles: '',
  surname: ''
};

export const TOAST_MESSAGES = {
  addUserSuccess: 'User successfully added'
};

export const CREATE_USER_MANAGEMENT_MODAL_LAYOUT = {
  default: {
    key: 'default',
    component: UserManagementModalLayout
  }
};

export const USER_MANAGEMENT_KEY_PARAM = {
  IS_CREATE_USER_MANAGEMENT: 'isCreateUserManagementModal'
};

export const USER_MANAGEMENT_MODAL_LAYOUT_KEYS = {
  DEFAULT: 'default'
};

export const USER_MANAGEMENT_STEPS = [
  {
    key: 0,
    label: 'User details',
    component: (props) => <AddUserDetailsForm {...props} />
  },
  {
    key: 1,
    label: 'Roles & Permissions',
    component: (props) => <AddRoleAndPermissionForm {...props} />
  },
  {
    key: 2,
    label: 'Submit',
    component: (props) => <UserManagementSummaryForm {...props} />
  }
];

export const USER_MANAGEMENT_USER_STEP_INDEX = 0;
export const USER_MANAGEMENT_USER_STEP_SUMMARY = USER_MANAGEMENT_STEPS.length - 1;

export const USER_MANAGEMENT_ACTION_CONTENT = {
  submit: 'Submit',
  next: 'Next',
  cancel: 'Cancel',
  previous: 'Previous'
};

export const ROLES_USER_ICON = {
  Retailer: {
    Owner: (props) => <B2BIcon w="3.313rem" h="3.313rem" color="main.500" {...props} />
  },
  RetailerUser: {
    Owner: (props) => <PersonCircleIcon w="3.313rem" h="3.313rem" color="main.500" {...props} />
  },
  Supplier: {
    Owner: (props) => <PersonShoppingCardIcon w="3.313rem" h="3.313rem" {...props} />
  }
};

export const EDIT_USER_TEXT = {
  successUpdated: 'User successfully updated',
  editUser: 'Edit user',
  cancel: 'Cancel',
  save: 'Save',
  userDetails: 'User details',
  userOwnRoles: (
    <>
      Your user role -{' '}
      <Box as="span" fontStyle="italic" color="dark.500" fontWeight="normal">
        you cannot modify your own role
      </Box>
    </>
  ),
  leavingMessage: 'Oops! Are you sure you want to cancel editing this user?',
  checkEmailAddress: 'Check you email address',
  checkEmailAddressDesc: 'Please use a valid email address. Eg. user@domain.com'
};

export const VENDOR = {
  Retailer: 'Retailer',
  RetailerUser: 'RetailerUser',
  Supplier: 'Supplier'
};

export const ROLE = {
  Owner: 'Owner',
  User: 'User'
};

export const ROLES_AND_PERMISSIONS = [
  {
    id: '',
    value: '',
    icon: <B2BIcon w="5.938rem" h="5.938rem" color="main.500" />,
    bgIcon: 'main.300',
    bgLabel: 'szrDenim.700',
    label: 'Retailer owner',
    vendor: VENDOR.Retailer,
    role: ROLE.Owner,
    description: `A Retailer owner has the ability to manage and oversee the overall platform.
      They have access to all sections, functionalities and permissions, allowing them to configure settings, 
      manage users, assign roles, and perform administrative tasks.`,
    text: 'Retailer owner can access',
    permissions:
      'Users | B2C Orders | B2B Orders | Returns | Inventory | Products & stock Consignments | Billing | Integrations | Company preferences'
  },
  {
    id: '',
    value: '',
    icon: <PersonCircleIcon w="5.938rem" h="5.938rem" color="main.500" />,
    bgIcon: 'main.300',
    bgLabel: 'szrCerulean.700',
    label: 'Retailer user',
    vendor: VENDOR.RetailerUser,
    role: ROLE.Owner,
    description: `Retailer users can create/edit products, handle orders, and 
    manage consignments. Retailer users have access to inventory control, order management,
     and shipment monitoring.`,
    text: 'Retailer user can access',
    permissions: 'B2C Orders | B2B Orders | Inventory | Products & stock | Consignments'
  },
  {
    id: '',
    value: '',
   // idOwner: '',
    icon: <PersonIcon w="4.563rem" h="6.563rem" color="main.500" ml={2} mr={-1} />,
    iconRight: <ShoppingCardIcon w="4.688rem" h="4.813rem" color="main.500" mt={6} mr={-5} />,
    bgIcon: '#FCCEBE',
    bgLabel: '#FD642E',
    label: 'Supplier',
    vendor: VENDOR.Supplier,
    role: ROLE.Owner,
    description: `Suppliers have access to create/edit and managing consignments and generating shipping labels.`,
    text: 'Supplier can access',
    permissions: 'Products & stock | Consignments'
  }
];

const BASE_STYLES = {
  card: {
    cursor: 'pointer',
    pos: 'relative',
    border: 'commonGrey',
    borderRadius: 3,
    p: '28px 24px 22px 24px',
    gap: '1.563rem'
  },
  cardChecked: {
    border: 'commonBlue'
  },
  cardError: {
    border: 'commonRed'
  },
  icon: {
    borderRadius: 6,
    p: '22px 24.8px 28px 25px',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export const STYLE_STEP_TWO_ROLE_USER = {
  ...BASE_STYLES,
  card: {
    ...BASE_STYLES.card,
    w: '16.5rem',
    minH: '30.688rem',
    _hover: {
      border: 'commonBlue'
    }
  },
  icon: {
    ...BASE_STYLES.icon,
    w: '9.063rem',
    h: '9.063rem'
  },
  body: {
    minH: '8.375rem'
  },
  footer: {
    minH: '4.813rem'
  }
};

export const STYLE_STEP_SUMMARY_ROLE_USER = {
  ...BASE_STYLES,
  card: {
    ...BASE_STYLES.card,
    w: '51.688rem',
    minH: '8.063rem',
    p: '1.375rem',
    border: 'common'
  },
  icon: {
    ...BASE_STYLES.icon,
    w: '5.063rem',
    minH: '5.063rem',
    borderRadius: 3,
    p: '0.75rem'
  },
  body: { alignItems: 'start', flexBasis: '50%' },
  footer: { minW: '25.563rem', justifyContent: 'flex-start' }
};

export const RoleTags = {
  retailerOwner: {
    key: 'RetailerOwner',
    color: 'szrDenim.700',
    label: 'Retailer owner'
  },
  retailerUser: {
    key: 'RetailerUser',
    color: 'szrCerulean.700',
    label: 'Retailer user'
  },
  supplier: {
    key: 'Supplier',
    color: 'sunrise.700',
    label: 'Supplier'
  }
};

export const MAPPING_ROLE_BY_PERMISSIONS = {
  retailerOwner: [
    'ViewUser',
    'ViewRole',
    'ViewReports',
    'ViewOrder',
    'UploadOrderCSV',
    'CancelOrder',
    'BulkPick',
    'ViewReturn',
    'ViewStock',
    'DeleteStock',
    'EditStock',
    'AddStock',
    'UploadCSV',
    'SupplierManagement',
    'ViewPO',
    'DeletePO',
    'AddPO',
    'EditPO',
    'AddUser',
    'EditUser',
    'DeleteUser',
    'ToggleUser',
    'AddPermission',
    'ViewPermission',   
    'EditPermission',
    'DeletePermission',
    'ManagePreferences',
    'ManageCourierPreferences',
    'ManageIntegrations',
    'NewCard',	
    'ViewCard',
    'ViewInvoice',
    'AddPartnerPreference',
    'ViewPartnerPreference',
    'EditPartnerPreference',
    'DeletePartnerPreference',	
    'ViewIssue',
    'EditIssue',
    'AddIssue',
    'DeleteIssue',
    'AssignIssue',
    'ActionIssue',
    'ReassignIssue'
  ],
  retailerUser: [
    'ViewUser',
    'ViewRole',
    'ViewReports',
    'ViewOrder',
    'UploadOrderCSV',
    'CancelOrder',
    'BulkPick',
    'ViewReturn',
    'ViewStock',
    'DeleteStock',
    'EditStock',
    'AddStock',
    'UploadCSV',
    'SupplierManagement',
    'ViewPO',
    'DeletePO',
    'AddPO',
    'EditPO'
  ],
  supplier: [
    'ViewUser',
    'ViewStock',
    'EditStock',
    'AddStock',
    'DeleteStock',
    'UploadCSV',
    'SupplierManagement',
    'ViewRole',
    'CancelOrder',
    'ViewPO',
    'AddPO',
    'DeletePO',
    'EditPO'
  ]
};
export const ERROR_TYPE_EMAIL = 'email';
