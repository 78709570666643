import { HStack } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const SetupTemplate = () => (
  <ShimmerSkeleton>
    {[...Array(4)].map((skeleton) => (
      <ShimmerSkeleton.Input
        key={skeleton}
        mb="2.063rem"
        titleStyle={{ w: '18.313rem', mb: '0.25rem' }}
      />
    ))}

    {[...Array(2)].map((skeleton) => (
      <ShimmerSkeleton.Input
        key={skeleton}
        mb="1.125rem"
        titleStyle={{ w: '31.375rem' }}
        inputStyle={{ w: '13.875rem', h: '2rem' }}
      />
    ))}
  </ShimmerSkeleton>
);

const EditCredentials = () => (
  <ShimmerSkeleton>
    {[...Array(4)].map((skeleton) => (
      <ShimmerSkeleton.Input
        key={skeleton}
        mb="2.063rem"
        titleStyle={{ w: '18.313rem', mb: '0.25rem' }}
      />
    ))}
  </ShimmerSkeleton>
);

const Management = () => <ShimmerSkeleton.Input />;

const InstructionStep = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block h="2.5rem" w="100%" mb="2.25rem" />
    <ShimmerSkeleton.Input
      mb="1.25rem"
      titleStyle={{ mb: '0.5rem', h: '0.75rem', w: '14.375rem' }}
      inputStyle={{ h: '1.625rem' }}
    />
    <ShimmerSkeleton.Block w="100%" h="3.688rem" mb="1.5rem" />
    <ShimmerSkeleton.Block h="10.875rem" w="100%" />
  </ShimmerSkeleton>
);

const StepOne = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block w="100%" h="11.188rem" mb="3rem" />
    <ShimmerSkeleton.Block w="24.75rem" h="1.063rem" mb="1.875rem" />
    <HStack>
      {[...Array(3)].map((skeleton) => (
        <ShimmerSkeleton.Input
          key={skeleton}
          flex="1"
          titleStyle={{ w: '7.875rem' }}
          inputStyle={{ h: '1.875rem' }}
        />
      ))}
    </HStack>
  </ShimmerSkeleton>
);

const StepTwo = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block w="100%" h="8.688rem" mb="2.625rem" />
    <ShimmerSkeleton.Input
      mb="1.125rem"
      titleStyle={{ h: '1.063rem', w: '27.063rem', mb: '0.75rem' }}
      inputStyle={{ w: '100%', h: '2.438rem' }}
    />
    <ShimmerSkeleton.Block w="100%" h="7.25rem" mb="2.125rem" />
    {[...Array(3)].map((skeleton) => (
      <ShimmerSkeleton.Input
        key={skeleton}
        mb="1.875rem"
        titleStyle={{ h: '0.75rem', w: '10.25rem', mb: '0.5rem' }}
        inputStyle={{ w: '100%', h: '1.875rem' }}
      />
    ))}
  </ShimmerSkeleton>
);

const SummaryStep = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block w="15.75rem" h="1.063rem" mb="1.625rem" />
    <ShimmerSkeleton.Input mb="2.75rem" titleStyle={{ w: '10.25rem', h: '0.75rem' }}>
      <HStack>
        {[...Array(3)].map((_, index) => (
          <ShimmerSkeleton.Block key={index} flex="1" h="3.813rem" />
        ))}
      </HStack>
    </ShimmerSkeleton.Input>
    {[...Array(2)].map((skeleton) => (
      <ShimmerSkeleton.Input
        key={skeleton}
        mb="2.5rem"
        titleStyle={{ w: '10.25rem', h: '0.75rem' }}>
        <HStack flexWrap="wrap">
          {[...Array(4)].map((_, index) => (
            <ShimmerSkeleton.Block
              key={index}
              flex="0 0 48.5%"
              h="3.813rem"
              ml="0 !important"
              mb="0.938rem !important"
              mr="0.75rem !important"
            />
          ))}
        </HStack>
      </ShimmerSkeleton.Input>
    ))}
    <ShimmerSkeleton.Block w="100%" h="2.563rem" />
  </ShimmerSkeleton>
);

const ApisTemplate = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Input
      mb="2.063rem"
      titleStyle={{ w: '18.313rem', mb: '0.25rem' }}
      inputStyle={{ w: '25.438rem' }}
    />
    <ShimmerSkeleton.Input titleStyle={{ w: '9.188rem', h: '1.438rem', mb: '1.75rem' }}>
      <HStack flexWrap="wrap">
        {[...Array(5)].map((skeleton) => (
          <ShimmerSkeleton.Input
            ml="0 !important"
            mr="0.813rem !important"
            mb="1.563rem !important"
            key={skeleton}
            flex="0 0 48%"
            titleStyle={{ w: '18.313rem' }}
          />
        ))}
      </HStack>
    </ShimmerSkeleton.Input>

    <ShimmerSkeleton.Block h="1.563rem" w="30.375rem" />
  </ShimmerSkeleton>
);

const INTEGRATION_TEMPLATES = {
  setup: SetupTemplate,
  editCredentials: EditCredentials,
  management: Management,
  'rateMatcher.overview': SummaryStep,
  'rateMatcher.instruction': InstructionStep,
  'rateMatcher.step1': StepOne,
  'rateMatcher.step2': StepTwo,
  'rateMatcher.summary': SummaryStep,
  apis: ApisTemplate
};

const IntegrationSkeleton = (props) => {
  return <BaseSkeleton baseTemplates={INTEGRATION_TEMPLATES} {...props} />;
};

export default IntegrationSkeleton;
