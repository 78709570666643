import { Icon } from '@chakra-ui/react';
import React from 'react';

const FragileIcon = ({ color = '#303134', ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="13.994"
      height="20"
      viewBox="0 0 13.994 20"
      color={color}
      {...props}>
      <path
        id="Union_338"
        data-name="Union 338"
        d="M6369,20991h-4a1,1,0,0,1,0-2h3v-4.055a7.421,7.421,0,0,1-3.868-1.537,5.567,5.567,0,0,1-2.131-4.4,1.168,1.168,0,0,1,.012-.143l1-7a1,1,0,0,1,.989-.859h4.773a.446.446,0,0,1,.072,0H6374a1,1,0,0,1,.988.859l1,7a.54.54,0,0,1,.012.143,5.552,5.552,0,0,1-2.13,4.4,7.46,7.46,0,0,1-3.869,1.541V20989h3a1,1,0,1,1,0,2Zm-5-11.932a3.577,3.577,0,0,0,1.382,2.785,6.251,6.251,0,0,0,7.228,0,3.6,3.6,0,0,0,1.388-2.785l-.867-6.064h-3.893l-.7,2.3,2.247,1.605a.772.772,0,0,1,.223,1.008l-1.539,2.689a.76.76,0,0,1-.668.389.723.723,0,0,1-.386-.1.764.764,0,0,1-.281-1.045l1.194-2.092-2.131-1.52a.753.753,0,0,1-.286-.844l.718-2.395h-2.767Z"
        transform="translate(-6361.999 -20970.996)"
        fill="currentColor"
      />
    </Icon>
  );
};

export default FragileIcon;
