import { useEffect, useState } from 'react';
import map from 'lodash/map';
import SlzTable from 'components/common/SlzTable/Table';
import {
  ACTION_TYPES,
  STYLE_WARNING_TOAST,
  TOAST_SUCCESS_MESSAGE,
  USERS_FILTER_OPTIONS,
  USERS_SKELETON_CELL,
  USERS_TABLE,
  WARNING_LEAVE_TOAST_ID,
  WARNING_TEXT
} from 'modules/users/constants';
import { USERS_COLUMNS } from 'modules/users/components/table/users-table-columns';
import useSlzToast from 'hooks/useSlzToast';
import WarningConfirmToast from 'modules/users/components/other/WarningConfirmToast';
import { removeUser, toggleUser } from 'modules/users/services/users-api';
import { applyVariableToMessage } from 'utils/helper';
import { getUser } from 'Utilities';
import { usersTableStyle } from 'modules/users/components/table/users-table-style';

const columns = [...USERS_COLUMNS];

const UsersTable = (props) => {
  const {
    data,
    isLoading,
    pageSize,
    pageIndex,
    totalPage = 0,
    setPageSize,
    setPageIndex,
    isEmptyPage,
    refetch,
    onEditUser
  } = props;
  const [users, setUsers] = useState([]);
  const [_, setToast, toast] = useSlzToast();
  const currentUser = getUser();

  const showConfirmLeaveToast = ({ warningText, OKText, onOKClick }) => {
    !toast.isActive(WARNING_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: WARNING_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <WarningConfirmToast
            warningText={warningText}
            OKText={OKText}
            onCancelClick={handleCancelClick}
            onOKClick={onOKClick}
          />
        )
      });
  };

  const handleCancelClick = () => {
    toast.closeAll();
  };

  const handleToggleUser = async (userId) => {
    try {
      toast.closeAll();
      const response = await toggleUser(userId);
      const { forename, surname, active } = response.data.data;
      const messagesVariable = {
        forename: forename,
        surname: surname,
        toggledStatus: active ? 'activated' : 'deactivated'
      };
      setToast({
        description: applyVariableToMessage(messagesVariable, TOAST_SUCCESS_MESSAGE?.TOGGLED_USER)
      });
      refetch();
    } catch (error) {}
  };

  const handleRemoveUser = async (userId) => {
    try {
      toast.closeAll();
      const _ = await removeUser(userId);
      setToast({
        description: TOAST_SUCCESS_MESSAGE.DELETED
      });
      refetch();
    } catch (error) {}
  };

  const handleActionSelect = (user, option) => {
    switch (option?.value) {
      case ACTION_TYPES.EDIT_USER:
        onEditUser(user.id);
        return;
      case ACTION_TYPES.TOGGLE_USER:
        showConfirmLeaveToast({
          warningText: user.active ? WARNING_TEXT.DEACTIVATE : WARNING_TEXT.ACTIVATE,
          onOKClick: () => handleToggleUser(user.id)
        });
        return;
      case ACTION_TYPES.REMOVE:
        showConfirmLeaveToast({
          warningText: WARNING_TEXT.REMOVE,
          OKText: 'Delete',
          onOKClick: () => handleRemoveUser(user.id)
        });
        return;
    }
  };

  useEffect(() => {
    if (!data?.users) return;

    const yourOwnerUser = data?.users?.find((user) => user.id === currentUser.id);
    if (yourOwnerUser) {
      const newUsers = data?.users?.filter((user) => user.id !== currentUser.id);
      yourOwnerUser.thisIsYou = true;
      newUsers?.unshift(yourOwnerUser);
      setUsers(newUsers);
    } else {
      setUsers(data?.users);
    }
  }, data?.users);

  columns[3].onClick = handleActionSelect;

  return (
    <SlzTable
      isLoading={isLoading}
      sx={usersTableStyle}
      sizes={['sm', 'md', 'lg']}
      overflowY="visible"
      overflowX="visible"
      columns={columns}
      onRefetching={refetch}
      isEmptyPage={isEmptyPage}
      messageToast="Sorry! We could not retrieve your users information"
      data={users}
      displayFields={map(USERS_COLUMNS, 'field')}
      filters={{
        isLocal: true,
        size: 'users',
        options: USERS_FILTER_OPTIONS
      }}
      pagination={{
        isLocal: false,
        pages: {
          onChange: setPageSize,
          pageOptions: USERS_TABLE.PAGES_OPTIONS_DEFAULT,
          currentItemPerPage: pageSize || ''
        },
        direction: {
          usingDots: false,
          isGreyBg: true,
          totalPage: totalPage,
          currentPage: pageIndex,
          onChange: setPageIndex
        }
      }}
    />
  );
};

export default UsersTable;
