import React from 'react';
import { Icon } from '@chakra-ui/react';

const ExpandIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.352 13.352"
      fill="currentcolor"
      {...props}>
      <path
        id="Rectangle_195"
        data-name="Rectangle 195"
        d="M1.225-1h8.9a2.228,2.228,0,0,1,2.225,2.225v8.9a2.228,2.228,0,0,1-2.225,2.225h-8.9A2.228,2.228,0,0,1-1,10.127v-8.9A2.228,2.228,0,0,1,1.225-1Zm8.9,11.869a.743.743,0,0,0,.742-.742v-8.9a.743.743,0,0,0-.742-.742h-8.9a.743.743,0,0,0-.742.742v8.9a.743.743,0,0,0,.742.742Z"
        transform="translate(1 1)"
        fill="currentcolor"
      />
      <path
        id="Path_4052"
        data-name="Path 4052"
        d="M1399.515,1195.239a.742.742,0,0,1-.742-.742v-11.869a.742.742,0,1,1,1.483,0V1194.5A.742.742,0,0,1,1399.515,1195.239Z"
        transform="translate(-1395.064 -1181.887)"
        fill="currentcolor"
      />
      <path
        id="Path_4053"
        data-name="Path 4053"
        d="M1405,1192.338a.74.74,0,0,1-.525-.217l-1.484-1.484a.742.742,0,0,1,0-1.049l1.484-1.484a.742.742,0,0,1,1.049,1.049l-.959.959.959.959a.742.742,0,0,1-.525,1.266Z"
        transform="translate(1412.417 1196.789) rotate(180)"
        fill="currentcolor"
      />
    </Icon>
  );
};

export default ExpandIcon;
