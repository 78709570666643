import { useCallback, useState } from 'react';
import { Box, HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { SlzButton } from 'components/common/SlzButton';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { ADDRESS_BOOK_DISPLAY_FIELDS_INPUT_SEARCH } from 'modules/address-book/constants';
import { getAddressBook } from 'modules/address-book/services/address-book-api';
import { transformAddressBook } from 'modules/address-book/mappers/address-book-list-mapper';

const AddressBookFilter = (props) => {
  const { onAddRecipient, onSearchingNavigate, onSearch, initSearchValue } = props;

  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  const { getUser } = useAuth();
  const user = getUser();

  const handleAutocompleteSearch = (searchTerm) => {
    if (!searchTerm) {
      onSearch(searchTerm);
    }
    setSearchParams({
      pageIndex: DEFAULT_PAGE_INDEX,
      name: searchTerm
    });
  };

  const transformFn = useCallback((data) => transformAddressBook(data).addresses, []);

  return (
    <Box>
      <HStack mb={6} gap={6}>
        <SlzAutocompleteInput
          width="55%"
          variant="accent"
          showedFields={ADDRESS_BOOK_DISPLAY_FIELDS_INPUT_SEARCH}
          placeholder="Search"
          onTypingSearch={handleAutocompleteSearch}
          transformFn={transformFn}
          onNavigate={onSearchingNavigate}
          fetchFn={(filter) => getAddressBook(user?.companyID, filter)}
          searchParams={searchParams}
          onSearch={onSearch}
          initValue={initSearchValue}
        />

        <SlzButton size="lg" onClick={onAddRecipient}>
          + Add recipient
        </SlzButton>
      </HStack>

      <HStack gap="25px" w="full" mb={6}>
        <Tabs variant="line" size={['sm', 'md', 'lg']} index={0}>
          <TabList>
            <Tab w={36} key="All">
              All
            </Tab>
          </TabList>
        </Tabs>
      </HStack>
    </Box>
  );
};

export default AddressBookFilter;
