import { Icon } from "@chakra-ui/react";

const TagIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g
        id="Select_a_warehouse_icon"
        data-name="Select a warehouse icon"
        transform="translate(-313 -381)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(313 381)"
          fill="#0973ea"
        />
        <g id="Group_34260" data-name="Group 34260" transform="translate(-2035.346 308.283)">
          <circle
            id="Ellipse_332"
            data-name="Ellipse 332"
            cx="0.516"
            cy="0.516"
            r="0.516"
            transform="translate(2356.097 80.211)"
            fill="#fafafa"
          />
          <path
            id="Ellipse_332_-_Outline"
            data-name="Ellipse 332 - Outline"
            d="M.033-1A1.034,1.034,0,0,1,1.066.033,1.034,1.034,0,0,1,.033,1.066,1.034,1.034,0,0,1-1,.033,1.034,1.034,0,0,1,.033-1Z"
            transform="translate(2356.58 80.694)"
            fill="#fafafa"
          />
          <path
            id="Path_5392"
            data-name="Path 5392"
            d="M2355.838,77.887h1.993a1.574,1.574,0,0,1,1.1.457l4.192,4.191a1.564,1.564,0,0,1,0,2.209l-2.5,2.5-.017.016a1.561,1.561,0,0,1-2.193-.016l-4.191-4.191a1.553,1.553,0,0,1-.458-1.1V79.953A2.068,2.068,0,0,1,2355.838,77.887Zm4.075,8.61,2.483-2.483a.531.531,0,0,0,0-.749l-4.192-4.191a.533.533,0,0,0-.374-.155h-1.993a1.034,1.034,0,0,0-1.033,1.033v1.993a.526.526,0,0,0,.155.374l4.191,4.191a.528.528,0,0,0,.748,0Z"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default TagIcon;