import { Icon } from '@chakra-ui/react';

const FileIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}>
      <g id="Group_3653" data-name="Group 3653">
        <rect
          id="Rectangle_4005"
          data-name="Rectangle 4005"
          width="24"
          height="24"
          rx="6"
          fill="#0973ea"
        />
      </g>
      <g id="tabler-icon-file-info" transform="translate(3.749 3)">
        <path id="Path_9733" data-name="Path 9733" d="M0,0H16.5V16.5H0Z" fill="none" />
        <path
          id="Path_9734"
          data-name="Path 9734"
          d="M17.126,6.813h-2.75A1.377,1.377,0,0,1,13,5.438V2.688a.688.688,0,0,1,1.375,0v2.75h2.75a.688.688,0,0,1,0,1.375Z"
          transform="translate(-4.061 -0.625)"
          fill="#fafafa"
        />
        <path
          id="Path_9735"
          data-name="Path 9735"
          d="M12.939,15.752H6.063A2.065,2.065,0,0,1,4,13.69V4.063A2.065,2.065,0,0,1,6.063,2h4.813a.688.688,0,0,1,.486.2L14.8,5.64a.688.688,0,0,1,.2.486V13.69A2.065,2.065,0,0,1,12.939,15.752ZM6.063,3.375a.688.688,0,0,0-.688.688V13.69a.688.688,0,0,0,.688.688h6.876a.688.688,0,0,0,.688-.688V6.411L10.591,3.375Z"
          transform="translate(-1.25 -0.625)"
          fill="#fafafa"
        />
      </g>
    </Icon>
  );
};

export default FileIcon;
