import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';
import { runIfFn } from '../utils/run-if-fn';

const baseStyle = defineStyle(({ theme }) => ({
  lineHeight: '1.2',
  fontWeight: 'normal',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _focusVisible: {
    boxShadow: 'outline'
  },
  _disabled: {
    opacity: 1,
    cursor: 'not-allowed',
    boxShadow: 'none',
    bg: getColor(theme, 'light.700'),
    color: getColor(theme, 'dark.300')
  },
  _hover: {
    opacity: 1,
    _disabled: {
      bg: getColor(theme, 'light.700'),
      color: getColor(theme, 'dark.300')
    }
  },
  _focus: {
    outline: 'none'
  },
  _active: {
    color: getColor(theme, 'dark.700')
  }
}));

const variantGhost = defineStyle((props) => {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      color: getColor(theme, mode(`inherit`, `whiteAlpha.900`)(props)),
      _hover: {
        bg: getColor(theme, mode(`gray.100`, `whiteAlpha.200`)(props))
      },
      _active: { bg: getColor(theme, mode(`gray.200`, `whiteAlpha.300`)(props)) }
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    color: getColor(theme, 'dark.700'),
    bg: 'transparent',
    _hover: {
      bg: getColor(theme, mode(`${c}.700`, darkHoverBg)(props)),
      _disabled: {
        borderColor: getColor(theme, 'dark.300')
      }
    },
    _active: {
      bg: getColor(theme, mode(`${c}.300`, darkActiveBg)(props)),
      _disabled: {
        borderColor: getColor(theme, 'dark.300')
      }
    },
    _disabled: {
      borderColor: getColor(theme, 'dark.300')
    }
  };
});

const variantHollow = defineStyle((props) => {
  const { colorScheme: c, theme } = props;

  return {
    color: getColor(theme, 'dark.700'),
    bg: 'transparent',
    _hover: {
      bg: transparentize(`${c}.500`, 0.5)(theme)
    },
    _active: {
      bg: getColor(theme, `${c}.300`)
    }
  };
});

const variantOutline = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  const borderColor = getColor(theme, mode(`gray.200`, `whiteAlpha.300`)(props));
  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : getColor(theme, `${c}.500`),
    '.chakra-button__group[data-attached] > &:not(:last-of-type)': {
      marginEnd: '-1px'
    },
    ...runIfFn(variantGhost, props),
    _hover: {
      ...variantGhost(props)._hover,
      bg: 'inherit',
      borderColor: getColor(theme, `${c}.700`)
    },
    _active: {
      ...variantGhost(props)._active,
      borderColor: getColor(theme, `${c}.500`)
    }
  };
});

const variantSolid = defineStyle((props) => {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    const bg = getColor(theme, mode(`gray.100`, `whiteAlpha.200`)(props));

    return {
      bg,
      _hover: {
        bg: getColor(theme, mode(`gray.200`, `whiteAlpha.300`)(props)),
        _disabled: {
          bg
        }
      },
      _active: { bg: getColor(theme, mode(`gray.300`, `whiteAlpha.400`)(props)) }
    };
  }

  const background = getColor(theme, mode(`${c}.500`, `${c}.200`)(props));

  return {
    bg: background,
    color: getColor(theme, mode('light.300', `gray.800`)(props)),
    _hover: {
      bg: getColor(theme, mode(`${c}.700`, `${c}.300`)(props)),
      _disabled: {
        bg: getColor(theme, 'light.700'),
        color: getColor(theme, 'dark.300')
      }
    },
    _active: { bg: getColor(theme, mode(`${c}.300`, `${c}.400`)(props)) }
  };
});

const variantLink = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    color: getColor(theme, mode(`${c}.500`, `${c}.200`)(props)),
    _hover: {
      textDecoration: 'underline',
      _disabled: {
        textDecoration: 'none'
      }
    },
    _active: {
      color: getColor(theme, mode(`${c}.700`, `${c}.500`)(props))
    }
  };
});

const variantReturnSetting = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  const borderColor = getColor(theme, mode(`gray.200`, `whiteAlpha.300`)(props));

  return {
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : `${c}.500`,

    '.chakra-button__group[data-attached] > &:not(:last-of-type)': {
      marginEnd: '-1px'
    },
    ...runIfFn(variantGhost, props),
    _hover: {
      ...variantGhost(props)._hover,
      bg: 'inherit',
      borderColor: `${c}.700`
    },

    _active: {
      ...variantGhost(props)._active,
      borderColor: `${c}.500`,
      color: `light.300`,
      bg: `${c}.500`,
      _disabled: {
        bg: 'light.700',
        color: 'dark.300',
        borderColor: 'dark.300'
      }
    }
  };
});

const variantUnstyled = defineStyle({
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: '0',
  p: '0'
});

const variants = {
  ghost: variantGhost,
  hollow: variantHollow,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  returnSetting: variantReturnSetting,
  unstyled: variantUnstyled
};

const sizes = {
  lg: defineStyle({
    h: '12',
    minW: '12',
    fontSize: '1rem',
    px: '6',
    borderRadius: '6px'
  }),
  md: defineStyle({
    h: '1.875rem',
    minW: '1.875rem',
    fontSize: '0.875rem',
    px: '6',
    borderRadius: '3px'
  }),
  sm: defineStyle({
    h: '6',
    minW: '6',
    fontSize: '0.75rem',
    px: '3',
    borderRadius: '3px'
  }),
  xs: defineStyle({
    h: '6',
    minW: '6',
    fontSize: 'xs',
    px: '2',
    borderRadius: '3px'
  })
};

export default defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'main'
  }
});
