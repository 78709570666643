import { Icon } from '@chakra-ui/react';
import React from 'react';

const FilterSlidersIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <g id="Group_2497" data-name="Group 2497" transform="translate(-150 -287)">
        <path
          id="Ellipse_772"
          data-name="Ellipse 772"
          d="M2-1A3,3,0,1,1-1,2,3,3,0,0,1,2-1ZM2,3A1,1,0,1,0,1,2,1,1,0,0,0,2,3Z"
          transform="translate(159 288)"
          fill="#676a74"
        />
        <path
          id="Path_8045"
          data-name="Path 8045"
          d="M588,320h-8a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
        <path
          id="Path_8046"
          data-name="Path 8046"
          d="M596,320h-4a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
        <path
          id="Ellipse_773"
          data-name="Ellipse 773"
          d="M2-1A3,3,0,1,1-1,2,3,3,0,0,1,2-1ZM2,3A1,1,0,1,0,1,2,1,1,0,0,0,2,3Z"
          transform="translate(153 294)"
          fill="#676a74"
        />
        <path
          id="Path_8047"
          data-name="Path 8047"
          d="M582,326h-2a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
        <path
          id="Path_8048"
          data-name="Path 8048"
          d="M596,326H586a1,1,0,0,1,0-2h10a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
        <path
          id="Ellipse_774"
          data-name="Ellipse 774"
          d="M2-1A3,3,0,1,1-1,2,3,3,0,0,1,2-1ZM2,3A1,1,0,1,0,1,2,1,1,0,0,0,2,3Z"
          transform="translate(162 300)"
          fill="#676a74"
        />
        <path
          id="Path_8049"
          data-name="Path 8049"
          d="M591,332H580a1,1,0,0,1,0-2h11a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
        <path
          id="Path_8050"
          data-name="Path 8050"
          d="M596,332h-1a1,1,0,0,1,0-2h1a1,1,0,0,1,0,2Z"
          transform="translate(-429 -29)"
          fill="#676a74"
        />
      </g>
    </Icon>
  );
};

export default FilterSlidersIcon;
