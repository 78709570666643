import React from 'react';

import { Flex, useBreakpointValue } from '@chakra-ui/react';

import SlzLink from 'components/common/SlzLink/SlzLink';

const CompanyReference = ({ title, href, sx }) => {
  const variant = useBreakpointValue({ lg: 'EXPANDED' }, { ssr: false });

  return (
    <Flex
      justifyContent="space-between"
      mb={variant === 'EXPANDED' ? '16.6vh' : '1.25rem'}
      sx={sx}>
      <SlzLink href={href} color="main.500" fontWeight="medium">
        {title}
      </SlzLink>
      <SlzLink href="https://support.selazar.com/" color="main.500" fontWeight="medium">
        Knowledge base
      </SlzLink>
    </Flex>
  );
};

export default CompanyReference;
