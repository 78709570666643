import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { Skeleton } from 'components/common/Skeleton/Skeleton';

const OrderCostDetails = (props) => {
  const { totalCost, costBreakdown, isLoading } = props;
  return (
    <SlzPaper
      w={props.w}
      h={props.h}
      minH="381px"
      borderRadius="0.375rem"
      bg="light.500"
      overflow="hidden">
      <Skeleton isLoaded={!isLoading} width="100%" height="100%">
        <VStack alignItems="flex-start" color="black">
          <Box w="full" padding="0.875rem 2.25rem 0.438rem" fontWeight="bold" fontSize="1rem">
            {props.title}
          </Box>
          <Divider mt="0 !important" bg="white" h="0.188rem" borderBottom="0" />
          <Flex
            flexDirection="column"
            mt="0 !important"
            w="100%"
            fontSize="0.875rem"
            alignItems="flex-start"
            padding="1rem 2.25rem 1.625rem"
            gap="0.169rem">
            <Text fontWeight="bold">Grand Total</Text>
            <Text>£{totalCost}</Text>
            <Text fontWeight="bold">Breakdown</Text>
            <Text>Cross Zonal Charge: £{costBreakdown?.crossZonalCharge}</Text>
            <Text>Fuel Charge: £{costBreakdown?.fuelCharge}</Text>
            <Text>Item Picking Charge: £{costBreakdown?.pickingCharge}</Text>
            <Text>Packing Charge: £{costBreakdown?.packingCharge}</Text>
            <Text>Packaging Charge: £{costBreakdown?.packagingCharge}</Text>
            <Text>Service Charge: £{costBreakdown?.serviceCharge}</Text>
            <Text>Subtotal: £{costBreakdown?.subtotal}</Text>
            <Text>
              Selazar charge ({costBreakdown?.adminRate}%): £{costBreakdown?.adminCharge}
            </Text>
            <Text>VAT: £{costBreakdown?.vatCharge}</Text>
          </Flex>
        </VStack>
      </Skeleton>
    </SlzPaper>
  );
};

export default OrderCostDetails;
