import { EORI_REGIONS } from '../constants';

export const transformEoriNumbers = (eoriDetails) => {
  const integratedRows = EORI_REGIONS?.map((row) => {
    const eoriNumbers = eoriDetails?.eoriNumbers?.find(
      (item) => item.identifier === row.identifier
    );
    if (eoriNumbers) {
      return eoriNumbers;
    } else {
      return row;
    }
  });

  return integratedRows;
};
