import { useQuery } from 'react-query';
import { getBulkOrderDetail } from '../services/bulk-orders-api';

export const useBulkOrderDetailQuery = (orderId, select) => {
  return useQuery({
    queryKey: [`bulk-order-detail`, orderId],
    queryFn: () => getBulkOrderDetail(orderId),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select
  });
};
