import React, { useEffect, useState } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { SlzTextInput } from 'components/common/SlzInput';

export default function Field({
  component: Component = SlzTextInput,
  name = '',
  label,
  isInlineError,
  showIcon = true,
  showStatus = true,
  errorMessageStyles,
  ...props
}) {
  const {
    formState: { errors, submitCount },
    register,
    resetField,
    setFocus,
    getFieldState,
    clearErrors
  } = useFormContext();
  const [isChecked, setIsChecked] = useState(false);
  const errorMessage = getFieldState(name).error?.message;
  const isInvalid = Boolean(errorMessage);
  const isValid = !isInvalid && isChecked;

  let placeholder = props.placeholder;

  if (isInvalid && isInlineError) {
    placeholder = errorMessage;
  }

  const clearField = () => {
    resetField(name, {
      defaultValue: ''
    });
    setFocus(name);
  };

  const handleOnClearError = () => {
    clearErrors(name);
    setIsChecked(false);
  };

  const setCheckedField = () => {
    setIsChecked(false);
  };

  useEffect(() => {
    if (submitCount > 0) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [submitCount]);

  return (
    <FormControl isInvalid={isInvalid} isValid={isValid}>
      {label && (
        <FormLabel htmlFor={name} mb={1} fontSize="1rem">
          {label}
        </FormLabel>
      )}
      <Component
        id={name}
        {...register(name)}
        isInvalid={showStatus && isInvalid}
        isValid={showStatus && isValid}
        onClear={clearField}
        onFocus={setCheckedField}
        onClearError={handleOnClearError}
        {...props}
        placeholder={placeholder}
      />
      {!isInlineError && (
        <FormErrorMessage {...errorMessageStyles}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}
