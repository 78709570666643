import { Icon } from '@chakra-ui/react';
import React from 'react';

const InvoicesIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33680" data-name="Group 33680" transform="translate(-10371.996 7020.836)">
        <g id="Group_33686" data-name="Group 33686" transform="translate(9361.664 -8730.5)">
          <path
            id="Path_8155"
            data-name="Path 8155"
            d="M1020.332,1710.664v4a1,1,0,0,0,1,1h4"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8156"
            data-name="Path 8156"
            d="M1023.332,1728.664h-10a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h7l5,5v11A2,2,0,0,1,1023.332,1728.664Z"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_1411"
            data-name="Line 1411"
            x2="1"
            transform="translate(1015.332 1714.664)"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_1412"
            data-name="Line 1412"
            x2="6"
            transform="translate(1015.332 1720.664)"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_1413"
            data-name="Line 1413"
            x2="2"
            transform="translate(1019.332 1724.664)"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default InvoicesIcon;
