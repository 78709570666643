import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function InstagramIcon(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.492 23.492" {...props}>
      <g id="Group_1857" data-name="Group 1857" transform="translate(0.5 0.5)">
        <path
          id="Path_566"
          data-name="Path 566"
          d="M576.747,180.9c3,0,3.359.012,4.546.066a6.217,6.217,0,0,1,2.089.388,3.715,3.715,0,0,1,2.134,2.135,6.165,6.165,0,0,1,.388,2.088c.055,1.187.066,1.542.066,4.546s-.012,3.358-.066,4.545a6.185,6.185,0,0,1-.388,2.089,3.725,3.725,0,0,1-2.134,2.135,6.223,6.223,0,0,1-2.089.386c-1.186.055-1.541.066-4.546.066s-3.359-.012-4.545-.066a6.226,6.226,0,0,1-2.089-.386,3.725,3.725,0,0,1-2.134-2.135,6.184,6.184,0,0,1-.388-2.089c-.055-1.187-.066-1.542-.066-4.545s.012-3.359.066-4.546a6.164,6.164,0,0,1,.388-2.088,3.715,3.715,0,0,1,2.134-2.135,6.218,6.218,0,0,1,2.089-.388c1.187-.055,1.542-.066,4.545-.066m0-2.027c-3.054,0-3.436.013-4.637.068a8.255,8.255,0,0,0-2.729.524,5.745,5.745,0,0,0-3.288,3.288,8.218,8.218,0,0,0-.524,2.729c-.055,1.2-.067,1.583-.067,4.638s.013,3.436.067,4.637a8.218,8.218,0,0,0,.524,2.729,5.746,5.746,0,0,0,3.288,3.288,8.22,8.22,0,0,0,2.729.524c1.2.055,1.583.067,4.637.067s3.438-.013,4.638-.067a8.218,8.218,0,0,0,2.729-.524,5.746,5.746,0,0,0,3.288-3.288,8.219,8.219,0,0,0,.524-2.729c.055-1.2.067-1.583.067-4.637s-.013-3.438-.067-4.638a8.216,8.216,0,0,0-.524-2.729,5.747,5.747,0,0,0-3.288-3.288,8.247,8.247,0,0,0-2.729-.524C580.185,178.89,579.8,178.877,576.747,178.877Z"
          transform="translate(-565.501 -178.877)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
        <path
          id="Path_567"
          data-name="Path 567"
          d="M575.411,183.016a5.775,5.775,0,1,0,5.776,5.776,5.775,5.775,0,0,0-5.776-5.776Zm0,9.525a3.749,3.749,0,1,1,3.749-3.749,3.749,3.749,0,0,1-3.749,3.749Z"
          transform="translate(-564.169 -177.543)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
        <path
          id="Path_568"
          data-name="Path 568"
          d="M580.225,183.173a1.35,1.35,0,1,1-1.35-1.35,1.35,1.35,0,0,1,1.35,1.35Z"
          transform="translate(-561.63 -177.928)"
          fill="currentColor"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
        />
      </g>
    </Icon>
  );
}
