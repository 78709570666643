import { ADDRESS_BOOK_PATHNAME } from '../constants';
import { AddressBookProvider } from '../context/AddressBookContext';
import AddressBookList from '../pages/address-book-list';

const AddressBook = () => (
  <AddressBookProvider>
    <AddressBookList />
  </AddressBookProvider>
);

const AddressBookRoutes = [
  {
    path: ADDRESS_BOOK_PATHNAME,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: AddressBook
  }
];

export default AddressBookRoutes;
