import { Box, HStack, Heading } from '@chakra-ui/react';
import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BillingSkeleton from 'components/common/Skeleton/templates/billing';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';
import OrderSkeleton from 'components/common/Skeleton/templates/order';

const INTEGRATION_TEMPLATE_KEYS = [
  'setup',
  'editCredentials',
  'management',
  'rateMatcher.overview',
  'rateMatcher.instruction',
  'rateMatcher.step1',
  'rateMatcher.step2',
  'rateMatcher.summary'
];

const BILLING_TEMPLATE_KEYS = ['orderInvoice'];

const ORDER_TEMPLATE_KEYS = [
  'overview',
  'b2c.overview',
  'b2c.upload',
  'b2c.singleOrder.1',
  'b2c.singleOrder.2',
  'b2c.singleOrder.3',
  'b2c.singleOrder.4'
];

const dummyText = (
  <>
    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit repellendus libero, neque beatae
    quasi, illum voluptates nulla sequi iure hic corrupti ut totam expedita debitis reprehenderit,
    facilis vero dolores perspiciatis dolorem? Suscipit, quisquam sit nemo omnis doloribus eos
    culpa. Temporibus repellendus dolores ad quaerat odit vitae soluta laboriosam placeat neque?
  </>
);

const SkeletonDemo = () => {
  return (
    <>
      <HStack p={5} maxW="826px" spacing={6} alignItems="flex-start">
        <Box>
          <Heading as="h4" fontSize="x-large">
            Loading
          </Heading>
          <ShimmerSkeleton.Block isLoaded={false} w="200px">
            {dummyText}
          </ShimmerSkeleton.Block>
        </Box>
        <Box>
          <Heading as="h4" fontSize="x-large">
            Loaded
          </Heading>
          <ShimmerSkeleton.Block isLoaded w="200px">
            {dummyText}
          </ShimmerSkeleton.Block>
        </Box>
        <Box>
          <Heading as="h4" fontSize="x-large">
            Loading Text
          </Heading>
          <ShimmerSkeleton.Text isLoaded={false} w="200px">
            {dummyText}
          </ShimmerSkeleton.Text>
        </Box>
      </HStack>

      <Box p={5} maxW="826px">
        <Heading as="h4" fontSize="x-large">
          Intergration
        </Heading>
        {INTEGRATION_TEMPLATE_KEYS.map((key) => (
          <Box mb={4}>
            <Heading as="h4" fontSize="x-large" mb={4}>
              {key}
            </Heading>
            <IntegrationSkeleton key={key} template={key} />
          </Box>
        ))}
      </Box>
      <Box p={5} maxW="826px">
        <Heading as="h4" fontSize="x-large">
          Billing
        </Heading>
        {BILLING_TEMPLATE_KEYS.map((key) => (
          <Box mb={4}>
            <Heading as="h4" fontSize="x-large" mb={4}>
              {key}
            </Heading>
            <BillingSkeleton key={key} template={key} />
          </Box>
        ))}
      </Box>
      <Box p={5} maxW="826px">
        <Heading as="h4" fontSize="x-large">
          Order
        </Heading>
        {ORDER_TEMPLATE_KEYS.map((key) => (
          <Box mb={4}>
            <Heading as="h4" fontSize="x-large" mb={4}>
              {key}
            </Heading>
            <OrderSkeleton key={key} template={key} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default SkeletonDemo;
