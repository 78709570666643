import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { getColor, isAccessible, mode, transparentize } from '@chakra-ui/theme-tools';

const parts = ['container', 'button', 'icon', 'text'];

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle((props) => {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(`${c}.300`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.700`);
  const color = getColor(theme, mode(lightColor, darkColor)(props));

  return {
    container: {
      '.cta-class': {
        justifyContent: 'end'
      },
      '.right-stack': {
        flexDirection: 'row',
        alignItems: 'center',
        flexBasis: '30%',
        justifyContent: 'space-between'
      }
    },
    text: {},
    button: {
      wrapper: {
        flexBasis: '30%'
      },
      _focus: {
        outline: 'none'
      },
      _hover: {
        bg: color,
        color: 'light.500'
      }
    },
    icon: {
      zIndex: 1,
      wrapper: {
        w: 6,
        h: 6,
        pos: 'relative',
        p: 1,
        display: 'flex',
        alignItems: 'center',
        _after: {
          content: '""',
          w: '0',
          h: '0',
          pos: 'absolute',
          transition: 'all 0.3s ease-in-out',
          transform: 'scale(0)',
          transformOrigin: 'center',
          transformBox: 'fill-box',
          left: '50%',
          top: '50%'
        },
        _hover: {
          _after: {
            bg: color,
            borderRadius: '0.188rem',
            w: 'full',
            h: 'full',
            transform: 'translate(-50%, -50%) scale(1)'
          },
          _selected: {
            _after: {
              w: '90%',
              h: '90%'
            }
          }
        },
        _focus: {
          outline: 'none'
        },
        _focusVisible: {
          outline: 'none'
        }
      }
    }
  };
});

const sizes = {
  sm: definePartsStyle({
    container: {
      h: 8,
      maxW: '37.5rem',
      w: '37.5rem',
      px: 3,
      borderRadius: 'md'
    },
    text: {
      fontSize: 'sm',
      mr: 12,
      flexBasis: '50%',
      textAlign: 'end'
    },
    button: {
      fontSize: 'sm',
      textAlign: 'start',
      lineHeight: 5,
      h: 5,
      px: 3
    },
    icon: {
      w: '1.313rem',
      h: '1.313rem'
    }
  }),
  md: definePartsStyle({
    container: {
      h: 10,
      minH: 10,
      maxW: '37.5rem',
      w: '37.5rem',
      px: 3,
      borderRadius: 'md'
    },
    text: {
      fontSize: 'md',
      mr: 12,
      flexBasis: '50%',
      textAlign: 'end'
    },
    button: {
      fontSize: 'md',
      textAlign: 'start',
      lineHeight: 6,
      h: 6,
      px: 3
    },
    icon: {
      w: '1.313rem',
      h: '1.313rem'
    }
  }),
  lg: definePartsStyle({
    container: {
      maxW: '37.5rem',
      w: '37.5rem',
      h: 12,
      px: 3,
      borderRadius: 'md'
    },
    text: {
      fontSize: 'md',
      mr: 12,
      flexBasis: '50%',
      textAlign: 'end'
    },
    button: {
      fontSize: 'md',
      textAlign: 'start',
      lineHeight: 6,
      h: 6,
      px: 3
    },
    icon: {
      w: '1.313rem',
      h: '1.313rem'
    }
  })
};

const variants = {
  solid: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    let color = getColor(theme, `${c}.500`);
    return {
      container: {
        bg: color
      },
      button: {
        borderRadius: '0.188rem',
        bg: getColor(theme, 'lightAndShadow.reverseText'),
        color
      },
      text: {
        color: getColor(theme, 'lightAndShadow.reverseText')
      },
      icon: {
        color: getColor(theme, 'lightAndShadow.reverseText')
      }
    };
  }),
  outline: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    const color = getColor(theme, `${c}.500`);

    return {
      container: {
        bg: color
      },
      button: {
        borderRadius: '0.188rem',
        bg: color,
        border: `1px solid ${getColor(theme, `light.500`)}`,
        color: getColor(theme, 'lightAndShadow.reverseText')
      },
      text: {
        color: getColor(theme, 'lightAndShadow.reverseText')
      },
      icon: {
        color: getColor(theme, 'lightAndShadow.reverseText')
      }
    };
  }),
  block: definePartsStyle((props) => {
    const { colorScheme: c, theme } = props;
    const color = getColor(theme, `${c}.500`);

    return {
      container: {
        pos: 'relative',
        bg: color,
        w: 'full',
        h: 'full',
        maxW: '100%',
        maxH: '100%',
        p: '1.75rem 1.25rem 1rem 2.375rem',
        borderRadius: 3,
        '.cta-class': {
          // justifyContent: 'stretch',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '0.625rem'
        },
        '.right-stack': {
          // alignItems: 'stretch',
          alignSelf: 'flex-end'
        }
      },
      button: {
        borderRadius: '0.188rem',
        bg: color,
        border: `1px solid ${getColor(theme, `light.500`)}`,
        color: getColor(theme, 'lightAndShadow.reverseText')
      },
      text: {
        flexBasis: '80%',
        textAlign: 'left',
        lineHeight: '1.188rem',
        mr: 0,
        color: getColor(theme, 'lightAndShadow.reverseText')
      },
      icon: {
        color: getColor(theme, 'lightAndShadow.reverseText'),
        wrapper: {
          pos: 'absolute',
          top: '0.625rem',
          right: '0.625rem'
        }
      }
    };
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'main'
  }
});
