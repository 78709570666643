import { useQuery } from 'react-query';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { transformAccordionItems } from '../../mappers/order-list-accordion-mapper';
import { getOrderDetail } from '../../services/orders-api';

const OrderAccordionTable = ({ row, accordionProps }) => {
  const {
    data: itemDetails,
    isLoading,
    refetch
  } = useQuery([`orders-detail-${row?.customerReference}`], () => getOrderDetail(row), {
    // time until stale data is garbage collected
    cacheTime: 60 * 1000,
    // time until data becomes stale
    staleTime: 30 * 1000,
    enabled: false,
    select: (returnData) => transformAccordionItems(returnData?.data.data.orderContents || [])
  });

  return (
    <SlzAccordionTable
      variant="order"
      isShow={row?.isExpand}
      rowId={row?.id}
      data={itemDetails}
      isLoading={isLoading}
      refetch={refetch}
      isChild={true}
      configProps={{ ...accordionProps, markerColor: row?.status?.color }}
    />
  );
};

export default OrderAccordionTable;
