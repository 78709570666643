import { useState } from 'react';
import { Box, Flex, Text, useToast, VStack } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';

import { BiCloudUpload, DownloadIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';
import OrderSkeleton from 'components/common/Skeleton/templates/order';
import {
  ORDER_CSV_INSTRUCTIONS_FILE,
  ORDER_CSV_INSTRUCTIONS_FILE_NAME,
  ORDER_EXAMPLE_CSV_FILE,
  ORDER_EXAMPLE_CSV_FILE_NAME
} from 'components/common/Constants';

import { notifyUpload } from 'modules/b2c-orders/utils/notifyUpload';
import Files from 'modules/b2c-orders/components/form/b2c-form/upload/Files';
import { uploadOrderCsv } from 'modules/b2c-orders/services/orders-api';

const UploadCSVForm = (
  <VStack
    mb={2}
    p={5}
    w="51.625rem"
    h="24.313rem"
    sx={{
      background: 'light.300',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: 'dark.300',
      borderRadius: 3
    }}
    textAlign="center"
    justifyContent="center">
    <Box sx={{ py: 3 }}>
      <BiCloudUpload />
    </Box>
    <Text
      sx={{
        fontSize: '1rem',
        lineHeight: '1.25rem',
        letterSpacing: '0rem',
        color: 'dark.500',
        textAlign: 'center'
      }}
      mb={3}>
      Drag and drop your CSV file here <Box as="br"></Box> or
    </Text>
    <SlzButton h="2.5rem" w="6.25rem">
      Browse
    </SlzButton>
  </VStack>
);

const Upload = () => {
  const [csvFiles, setCsvFiles] = useState([]);

  const toast = useToast();
  const history = useHistory();

  const mutationUpload = useMutation({
    mutationFn: () => {
      return uploadOrderCsv(csvFiles);
    }
  });

  const handleDownloadOrderInstructionExampleURI = () => {
    let link = document.createElement('a');
    link.download = ORDER_CSV_INSTRUCTIONS_FILE_NAME;
    link.href = ORDER_CSV_INSTRUCTIONS_FILE;
    link.click();
  };

  const handleDownloadOrderExampleURI = () => {
    let link = document.createElement('a');
    link.download = ORDER_EXAMPLE_CSV_FILE_NAME;
    link.href = ORDER_EXAMPLE_CSV_FILE;
    link.click();
  };

  const handleDisappearedToast = (onClose) => {
    onClose && onClose();
    history.push({ search: undefined });
  };

  const handleUploadFiles = async () => {
    try {
      const result = await mutationUpload.mutateAsync();
      result?.data?.data.forEach((data) => {
        if (!data.isError) {
          notifyUpload({
            toast,
            onCloseComplete: handleDisappearedToast,
            colorScheme: 'positive',
            title: `${data.fileName} was uploaded successfully!`,
            status: 'success'
          });
        } else {
          notifyUpload({
            toast,
            colorScheme: 'negative',
            title: `${data.fileName} was uploaded failed!`,
            description: `${data.message || ''}`,
            status: 'warning'
          });
        }
      });
    } catch (error) {
      notifyUpload({
        toast,
        colorScheme: 'negative',
        title: 'Cannot upload files',
        status: 'warning'
      });
    }

    setCsvFiles([]);
  };

  const onLoadFile = (file, reader) => {
    setCsvFiles((oldList) => [
      ...oldList,
      {
        id: uuid(),
        name: file.name,
        text: reader.result,
        file
      }
    ]);
  };

  return (
    <>
      <VStack
        align="baseline"
        justifyContent="space-between"
        alignItems="end"
        mb={5}
        w="51.625rem"
        alignSelf="center">
        <Box flex={1} justifyContent="flex-end" textAlign="right" mb={1}>
          <SlzButton
            h="3rem"
            fontSize="lg"
            rightIcon={<DownloadIcon />}
            onClick={handleDownloadOrderExampleURI}>
            CSV Example
          </SlzButton>
        </Box>
        <Box flex={1} justifyContent="flex-end" textAlign="right">
          <SlzButton
            h="3rem"
            fontSize="lg"
            rightIcon={<DownloadIcon />}
            onClick={handleDownloadOrderInstructionExampleURI}>
            CSV Instruction
          </SlzButton>
        </Box>
      </VStack>
      <OrderSkeleton isLoaded template="b2c.upload">
        <SlzUpload mb={5} uploadForm={UploadCSVForm} onLoadFile={onLoadFile} acceptType=".csv" />
      </OrderSkeleton>
      {csvFiles.length > 0 && <Files csvFiles={csvFiles} setCsvFiles={setCsvFiles} />}
      {csvFiles.length > 0 && (
        <Flex gap="0.75rem" mb={2} mt={4} justifyContent="flex-end">
          <SlzButton
            isLoading={mutationUpload?.isLoading}
            loadingText="Uploading"
            type="submit"
            size="lg"
            w="6rem"
            onClick={handleUploadFiles}>
            Upload
          </SlzButton>
        </Flex>
      )}
    </>
  );
};

export default Upload;
