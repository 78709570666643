import {
  SkeletonPageTemplate,
  SkeletonModalTemplate,
  SkeletonTableTemplate,
  SlzSkeletonDefault,
  SkeletonNoImageProductTableTemplate
} from '../Skeleton/Skeleton';
import { SKELETON_TYPE } from '../Constants';

import { Box } from '@chakra-ui/react';
import React from 'react';

const SlzSkeleton = (props) => {
  const { type, ...rest } = props;
  const SkeletonForm = ({ type }) => {
    if (type === SKELETON_TYPE.MODAL) {
      return <SkeletonModalTemplate />;
    }
    if (type === SKELETON_TYPE.TABLE) {
      return <SkeletonTableTemplate />;
    }
    if (type === SKELETON_TYPE.PAGE) {
      return <SkeletonPageTemplate />;
    }
    if (type === SKELETON_TYPE.PRODUCT_IMAGES_REQUIRED) {
      return <SkeletonNoImageProductTableTemplate {...rest} />;
    }
    return <SlzSkeletonDefault />;
  };
  return (
    <Box {...rest}>
      <SkeletonForm type={type} />
    </Box>
  );
};

export default SlzSkeleton;
