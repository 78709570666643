import React, { memo } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { GridItem, Image, HStack, Icon, Heading, Text } from '@chakra-ui/react';

import { SlzGridModal } from 'components/common/SlzModal';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

import { INTEGRATIONS_TEXT } from 'modules/integrations/constants';
import { PasswordConfirmationIntegrationForm } from 'modules/integrations/components/form';

export default memo(function ManageIntegrationModal({ isOpen, onClose, logo, onSubmit }) {
  return (
    <SlzGridModal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <GridItem gridColumn="2/12" mb={'1.563rem'} pt="8">
        <HStack spacing={0}>
          <Image src={logo} h="10" />
          <Icon as={FaChevronRight} fontSize={28} color="dark.500" cursor="pointer" />
          <Heading as="h3" fontSize="2xl" lineHeight={'none'} mb="0">
            Manage your integration
          </Heading>
        </HStack>
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <Text mb="5" maxW={'43.25rem'}>
          {INTEGRATIONS_TEXT.instructionPassword}
        </Text>
        <IntegrationSkeleton isLoaded template="management">
          <PasswordConfirmationIntegrationForm onSubmit={onSubmit} />
        </IntegrationSkeleton>
      </GridItem>
    </SlzGridModal>
  );
});
