import React from 'react';
import { Icon } from '@chakra-ui/react';

const CreateNewProductIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33671" data-name="Group 33671" transform="translate(-10370 6962.335)">
        <g id="Group_33677" data-name="Group 33677" transform="translate(10080.668 -7376.5)">
          <path
            id="Path_6356"
            data-name="Path 6356"
            d="M290.332,419.039v-1.125a2.251,2.251,0,0,1,2.25-2.25h2.25"
            transform="translate(0 0)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_6357"
            data-name="Path 6357"
            d="M290.332,428.664v1.125a2.25,2.25,0,0,0,2.25,2.25h2.25"
            transform="translate(0 1.623)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_6358"
            data-name="Path 6358"
            d="M302.332,415.664h2.25a2.25,2.25,0,0,1,2.25,2.25v1.125"
            transform="translate(1.499 0)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_6359"
            data-name="Path 6359"
            d="M302.332,432.039h2.25a2.249,2.249,0,0,0,2.25-2.25v-1.125"
            transform="translate(1.499 1.623)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <rect
            id="Rectangle_327"
            data-name="Rectangle 327"
            width="1.125"
            height="2.25"
            transform="translate(291.457 423.539)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_874"
            data-name="Line 874"
            y2="2.25"
            transform="translate(297.081 423.539)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <rect
            id="Rectangle_328"
            data-name="Rectangle 328"
            width="1.125"
            height="2.25"
            transform="translate(301.581 423.539)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_875"
            data-name="Line 875"
            y2="2.25"
            transform="translate(307.205 423.539)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default CreateNewProductIcon;
