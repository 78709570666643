import { useQuery } from 'react-query';
import { getReturnDetail } from '../services/returns-api';

export const useReturnDetailQuery = ({ returnID, select, enabled = false }) => {
  return useQuery([`return-detail-${returnID}`], () => getReturnDetail(returnID), {
    // time until stale data is garbage collected
    cacheTime: 60 * 1000,
    // time until data becomes stale
    staleTime: 30 * 1000,
    enabled,
    select
  });
};
