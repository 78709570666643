import { Icon } from '@chakra-ui/react';
import React from 'react';

const PersonIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="73.324"
      height="104.749"
      viewBox="0 0 73.324 104.749"
      {...props}>
      <svg>
        <g id="Group_34345" data-name="Group 34345" transform="translate(0 0.002)">
          <path
            id="Ellipse_393"
            data-name="Ellipse 393"
            d="M9.475-1A10.475,10.475,0,1,1-1,9.475,10.487,10.487,0,0,1,9.475-1Z"
            transform="translate(32.423 0.998)"
            fill="#fd642e"
          />
          <path
            id="Line_619"
            data-name="Line 619"
            d="M4.234,30.425a5.238,5.238,0,0,1-4.186-8.38L15.76,1.1a5.237,5.237,0,1,1,8.38,6.285L8.427,28.33A5.23,5.23,0,0,1,4.234,30.425Z"
            transform="translate(6.237 74.322)"
            fill="#fd642e"
          />
          <path
            id="Path_5642"
            data-name="Path 5642"
            d="M2536.2,1408.451a5.24,5.24,0,0,1-4.689-2.9l-10.093-20.189-15.111-15.111a5.231,5.231,0,0,1-1.463-4.565l5.236-31.424a5.237,5.237,0,1,1,10.332,1.722l-4.794,28.766,13.806,13.807a5.23,5.23,0,0,1,.982,1.361l10.476,20.95a5.242,5.242,0,0,1-4.682,7.582Z"
            transform="translate(-2478.586 -1303.703)"
            fill="#fd642e"
          />
          <path
            id="Path_5643"
            data-name="Path 5643"
            d="M2567.86,1361.314a5.251,5.251,0,0,1-1.657-.27l-15.711-5.238a5.22,5.22,0,0,1-2.048-1.265l-13.616-13.615-16.107,4.027-9.352,14.027a5.237,5.237,0,1,1-8.716-5.81l10.475-15.712a5.235,5.235,0,0,1,3.09-2.176l20.947-5.237a5.234,5.234,0,0,1,4.974,1.376l14.839,14.838,14.537,4.847a5.238,5.238,0,0,1-1.654,10.208Z"
            transform="translate(-2499.773 -1303.703)"
            fill="#fd642e"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default PersonIcon;
