import { Box, Image, Text, VStack } from '@chakra-ui/react';
import { NoImageIcon } from 'components/common/Icons';

const ProductImage = ({ imageSrc, imgDesc = 'Coming soon', iconStyles, ...rest }) => {
  return (
    <Box bg="light.500" {...rest}>
      {imageSrc ? (
        <Image w="full" h="full" fit="cover" src={imageSrc} />
      ) : (
        <VStack h="full" spacing={3.5} justifyContent="center">
          <NoImageIcon w="2.563rem" h="2.563rem" sx={{ ...iconStyles }} />
          <Text fontSize="md" color="dark.500">
            {imgDesc}
          </Text>
        </VStack>
      )}
    </Box>
  );
};

export default ProductImage;
