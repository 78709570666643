import { Icon } from '@chakra-ui/react';
import React from 'react';

const ShoppingCardIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="74.487"
      height="76.732"
      viewBox="0 0 74.487 76.732"
      {...props}>
      <svg>
        <g id="Group_34346" data-name="Group 34346" transform="translate(0 0.002)">
          <g id="Group_303" data-name="Group 303" transform="translate(17.384 12.712)">
            <path
              id="Path_3539"
              data-name="Path 3539"
              d="M971.314,2243.311a3.81,3.81,0,0,1-3.628-2.652l-9.251-29.029a3.809,3.809,0,0,1,2.472-4.785l14.514-4.628a3.808,3.808,0,1,1,2.314,7.257l-10.886,3.471,6.938,21.772,29.03-9.25-4.626-14.516-18.145,5.782a3.809,3.809,0,0,1-2.312-7.258l21.773-6.938a3.813,3.813,0,0,1,4.785,2.473l6.939,21.772a3.809,3.809,0,0,1-2.472,4.785l-36.288,11.563A3.818,3.818,0,0,1,971.314,2243.311Z"
              transform="translate(-958.255 -2191.131)"
              fill="#fd642e"
            />
            <path
              id="Path_3540"
              data-name="Path 3540"
              d="M976.376,2246.729a3.811,3.811,0,0,1-3.628-2.653l-10.405-32.662a7.625,7.625,0,0,1,4.94-9.567l7.259-2.313a7.631,7.631,0,0,1,9.572,4.942l3.47,10.892a3.809,3.809,0,1,1-7.258,2.313l-3.47-10.889L969.6,2209.1l10.406,32.661a3.811,3.811,0,0,1-3.63,4.965Z"
              transform="translate(-948.802 -2199.173)"
              fill="#fd642e"
            />
          </g>
          <path
            id="Path_3541"
            data-name="Path 3541"
            d="M987.68,2260.575a3.811,3.811,0,0,1-3.628-2.653l-17.043-53.493-4.824,1.538a3.809,3.809,0,0,1-2.312-7.259l8.453-2.693a3.808,3.808,0,0,1,4.785,2.474l17.043,53.492,36.587-11.657a3.809,3.809,0,0,1,2.313,7.259l-40.216,12.813A3.82,3.82,0,0,1,987.68,2260.575Z"
            transform="translate(-957.219 -2195.837)"
            fill="#fd642e"
          />
          <path
            id="Ellipse_41"
            data-name="Ellipse 41"
            d="M8.454-1A9.454,9.454,0,1,1-1,8.454,9.465,9.465,0,0,1,8.454-1Zm0,11.291A1.837,1.837,0,1,0,6.617,8.454,1.839,1.839,0,0,0,8.454,10.291Z"
            transform="translate(19.798 58.822)"
            fill="#fd642e"
          />
        </g>
      </svg>
    </Icon>
  );
};

export default ShoppingCardIcon;
