import React, { useEffect } from 'react';

import { Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

import useAuth from 'hooks/useAuth';
import { PublicLayout } from 'components/layout';
import { useGlobalState } from 'contexts/GlobalContext';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { LoginForm } from 'modules/users/components/form';
import { callToLogin } from 'modules/users/services/users-api';
import {
  CompanyInfo,
  CompanyReference,
  InvalidCredentialsAlert,
  UserFormHeading
} from 'modules/users/components/other';
import {
  BREAD_CRUMB_ITEMS,
  CONTACT_INFOS,
  LOGIN_IMAGE_URL_SET,
  MESSAGE,
  PREVIOUS_PAGE,
  TEXT_OVERLAY
} from '../constants';

const Login = () => {
  const [_, dispatch] = useGlobalState();
  const history = useHistory();
  const { setUser } = useAuth();

  const callToLoginMutation = useMutation({
    mutationFn: (payload) => callToLogin(payload)
  });

  const handleLogin = async (values) => {
    const response = await callToLoginMutation.mutateAsync(values);
    const userInfo = response?.data?.data;
    if (userInfo?.token) {
      setUser(userInfo);
    }
    history.push('/');
    // reload the page to force, mainlayout refresh, ensuring correct user is obtained with permissions
    window.location.reload();
  };

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  return (
    <PublicLayout
      imageSrc={LOGIN_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={null}
      sx={{ alignItems: 'start', paddingTop: 0 }}>
      <Image src="Images/selazar-login.svg" mb={5} mt="24.26vh" />
      {callToLoginMutation.isError && (
        <InvalidCredentialsAlert
          mb={4}
          mt={1}
          title={MESSAGE.LOGIN.ERROR.TITLE}
          errorDescription={MESSAGE.LOGIN.ERROR.DESCRIPTION}
          hintDescription={MESSAGE.LOGIN.ERROR.HINT_DESCRIPTION}
        />
      )}
      <UserFormHeading text="Welcome Back!" />
      <LoginForm onLogin={handleLogin} />
      <CompanyReference title={PREVIOUS_PAGE?.LOGIN.title} href={PREVIOUS_PAGE?.LOGIN.href} />
      <CompanyInfo email={CONTACT_INFOS.email} telephone={CONTACT_INFOS.telephone} />
    </PublicLayout>
  );
};

export default Login;
