import React from 'react';
import { Icon } from '@chakra-ui/react';

const NextDayDeliveryIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.6 12" fill="currentcolor" {...props}>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Group_4726"
        data-name="Group 4726"
        clip-path="url(#clip-path)">
        <path
          id="Path_9836"
          data-name="Path 9836"
          d="M4.2,12a.6.6,0,0,1-.6-.6V7.8H.6a.6.6,0,0,1-.485-.953l4.8-6.6A.6.6,0,0,1,6,.6V4.2H9a.6.6,0,0,1,.485.953l-4.8,6.6A.6.6,0,0,1,4.2,12M1.778,6.6H4.2a.6.6,0,0,1,.6.6V9.555L7.822,5.4H5.4a.6.6,0,0,1-.6-.6V2.444Z"
          transform="translate(0 0)"
          fill="#6ebd45"
        />
      </g>
    </Icon>
  );
};

export default NextDayDeliveryIcon;
