import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import { SlzTextInput } from 'components/common/SlzInput';
import { ControlledField } from 'components/form';
import { API_SETUP_TEXT } from 'modules/integrations/constants';
import { ApiConnectionHeading } from '../common';

const ApiSetupSQSNotification = ({ label, field }) => {
  return (
    <Box w="full">
      <Text fontSize="md" fontWeight="medium" pb={1}>
        {label}
      </Text>
      <Flex gap={3} mb={2.5}>
        <ControlledField
          label="SQS URL"
          name={`${field}[url]`}
          component={SlzTextInput}
          errorMessageStyles={{
            fontSize: 'lg'
          }}
        />
      </Flex>
      <Flex gap={3} mb={7}>
        <ControlledField
          label="Access key"
          name={`${field}[accessKey]`}
          component={SlzTextInput}
          errorMessageStyles={{
            fontSize: 'lg'
          }}
        />
        <ControlledField
          label="Secret key"
          name={`${field}[privateKey]`}
          component={SlzTextInput}
          errorMessageStyles={{
            fontSize: 'lg'
          }}
        />
      </Flex>
    </Box>
  );
};

const ApiSetupSQSNotificationForm = () => {
  const methods = useFormContext();
  const ordersSQSSettingsEnabled = methods.getValues('ordersSQSSettings[enabled]');
  const inventorySQSSettingsEnabled = methods.getValues('inventorySQSSettings[enabled]');

  return (
    <Fragment>
      <ApiConnectionHeading text={API_SETUP_TEXT.sqsNotifications} />
      <VStack spacing="4.375rem" w="full" alignItems="flex-start">
        {ordersSQSSettingsEnabled && (
          <ApiSetupSQSNotification field="ordersSQSSettings" label={API_SETUP_TEXT.ordersAccess} />
        )}
        {inventorySQSSettingsEnabled && (
          <ApiSetupSQSNotification
            field="inventorySQSSettings"
            label={API_SETUP_TEXT.stockAccess}
          />
        )}
      </VStack>
    </Fragment>
  );
};

export default ApiSetupSQSNotificationForm;
