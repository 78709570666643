import { Box, Divider, useStyleConfig } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getDefaultState } from '../../../../utils';

const Range = (props) => {
  const { handleActiveClicked, isActive, selectedDefinedRange } = props;
  const state = getDefaultState();
  const { rangesPresets } = state;
  const [isActiveState, setIsActiveState] = useState(false);
  const [activeValue, setActiveValue] = useState(-1);

  useEffect(() => {
    setIsActiveState(isActive);
  }, [isActive]);
  useEffect(() => {
    if (selectedDefinedRange > -1) {
      setIsActiveState(true);
    }
    setActiveValue(selectedDefinedRange);
  }, [selectedDefinedRange]);
  const handleRangeClicked = (id, name) => {
    setActiveValue(id);
    handleActiveClicked(id, name);
  };
  return (
    <>
      {rangesPresets &&
        rangesPresets.map((range, index) => (
          <li
            data-testid="slz-presets"
            key={index}
            onClick={() => handleRangeClicked(range.id, range.name)}
            className={isActiveState && activeValue === range.id ? 'active' : ''}>
            <span>{range.name}</span>
          </li>
        ))}
    </>
  );
};

function SlzDefinedRange(props) {
  const { onActiveClicked, isActive, selectedDefinedRange, colorScheme } = props;
  const handleActiveClicked = (id, value) => {
    onActiveClicked(id, value);
  };
  const definedStyle = useStyleConfig('SlzDefinedRange', { colorScheme });
  const { container, definedRangeHeader, definedRangeContent } = definedStyle;
  return (
    <Box sx={container} data-testid="slz-defined-range">
      <Box sx={definedRangeHeader}>Presets</Box>
      <Box sx={definedRangeContent} data-testid="slz-presets-range">
        <ul>
          <Range
            handleActiveClicked={handleActiveClicked}
            isActive={isActive}
            selectedDefinedRange={selectedDefinedRange}
          />
        </ul>
      </Box>
      <Divider orientation="horizontal" />
    </Box>
  );
}

export default SlzDefinedRange;
