import { Box, Flex, GridItem, Heading, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { SlzSplitButton } from 'components/common/SlzButton';
import { SlzGridModal } from 'components/common/SlzModal';
import BillingSkeleton from 'components/common/Skeleton/templates/billing';

import { pdf } from 'modules/billing/_mock';
import {
  BILLING_DOWNLOADS,
  BILLING_PROCESS,
  BILLING_STATUS_DOWNLOADS
} from 'modules/billing/constants';
import { AddressCard, DetailedBreakdown, TotalCost } from 'modules/billing/components/other';

export default function BillingModal({ isOpen, onClose, data }) {
  const [title, setTitle] = useState('');
  const [detailedBreakdownData, setDetailedBreakdownData] = useState({});

  const totalCostData = {
    date: data.date,
    number: data.number,
    paymentAttemptDate: data.paymentAttemptDate,
    paymentMethod: data.paymentMethod,
    paymentReference: data.paymentReference,
    finalTotal: data.finalTotal,
    process: data.process,
    invoiceTotalCost: data.invoiceTotalCost,
    adminChargeCost: data.adminChargeCost,
    vatCost: data.vatCost,
    adminCharge: data.adminCharge,
    vat: data.vat,
    cardPayment: data.cardPayment
  };

  const downLoadFile = (url, filename = '') => {
    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  };

  const handleDownload = (type, data = '') => {
    switch (type) {
      case BILLING_STATUS_DOWNLOADS.COPY_TO_CLIPBOARD:
        return navigator.clipboard.writeText(JSON.stringify(data));
      case BILLING_STATUS_DOWNLOADS.SAVE_AS_PDF:
        return setTimeout(() => window.print(), 100);
      default:
        return null;
    }
  };

  useEffect(() => {
    if (data.process === BILLING_PROCESS.INVOICE) {
      setTitle(`${data.invoiceType.name} invoice`);
    }
    if (data.process === BILLING_PROCESS.COLLECTION_ADVICE) {
      setTitle('Collection advice');
    }
    if (data.process === BILLING_PROCESS.REMITTANCE_ADVICE) {
      setTitle('Remittance advice');
    }

    const hasPeriod =
      data.process === BILLING_PROCESS.COLLECTION_ADVICE ||
      data.process === BILLING_PROCESS.REMITTANCE_ADVICE;

    setDetailedBreakdownData((prev) => ({
      list: [],
      ...(hasPeriod
        ? {
            collectionFrom: data.collectionFrom,
            collectionTo: data.collectionTo
          }
        : { invoiceType: data?.invoiceType })
    }));
  }, [data.process]);

  return (
    <SlzGridModal isOpen={isOpen} onClose={onClose}>
      <BillingSkeleton isLoaded template="orderInvoice" title={title}>
        <GridItem gridColumn={'2/12'}>
          <Flex justifyContent="space-between" mb="5">
            <Box>
              <Heading as="h3" fontSize="2xl">
                {title}
              </Heading>
              <Text fontWeight="bold" color="main.500" mb="0">
                <Text as="span" mr="5">
                  {data.number}
                </Text>
                <Text as="span">{data.date}</Text>
              </Text>
            </Box>
            <SlzSplitButton
              items={BILLING_DOWNLOADS}
              size={'lg'}
              placeholder="Download"
              hasArrow={true}
              onlyShowPlaceholder={true}
              onClick={() => {
                downLoadFile(pdf);
              }}
              onSelected={(type) => {
                handleDownload(type.value, data);
              }}
            />
          </Flex>
        </GridItem>
        <GridItem gridColumn={'2/12'}>
          <Flex gap={'28px'} mb="5" justifyContent="space-between">
            <Box flexBasis="41.5%">
              <TotalCost data={totalCostData} />
            </Box>
            <Box flexBasis="29%">
              <AddressCard address={data.customerAddress} />
            </Box>
          </Flex>
        </GridItem>
        <GridItem gridColumn={'2/12'}>
          <DetailedBreakdown process={data.process} data={detailedBreakdownData} />
        </GridItem>
      </BillingSkeleton>
    </SlzGridModal>
  );
}
