import { useEffect, useState } from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { HStack, Text, Skeleton, VStack, Flex } from '@chakra-ui/react';
import has from 'lodash/has';
import dayjs from 'dayjs';
import { SlzPaper } from 'components/common/SlzPaper';
import SlzToolTip from 'components/common/SlzToolTip';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { SlzToggle } from 'components/common/SlzToggle';
import { SlzButton } from 'components/common/SlzButton';
import { MANAGE_TYPE } from 'modules/integrations/constants';
import { DATE_FORMAT_DDMMYYYY } from 'constants';
import { IntegrationCardActionButton, IntegrationCardIcon, IntegrationCardStatus } from '../common';
const IntegrationCard = (props) => {
  const {
    data,
    isLoading,
    isDisable,
    onClickEditCredentials,
    onOpenManageIntegration,
    onSaveIntegration,
    onClickRateMatcher
  } = props;
  const [isEditable, setIsEditable] = useState(true);
  const {
    id,
    integrationType,
    appIntegration,
    connected,
    shopName,
    lastModified,
    inventoryManagement,
    isConfiguredRateMatcher
  } = data;
  const [isChecked, setIsChecked] = useState({ connect: connected, inventory: inventoryManagement });

  const handleSave = () => {
    const currentCard = {
      type: integrationType,
      id,
      isShopifyVersion2: appIntegration,
      isConfiguredRateMatcher
    };
    onSaveIntegration(currentCard, data, isChecked);
  };

  const handleManage = () => {
    const currentCard = {
      type: integrationType,
      id,
      isShopifyVersion2: appIntegration,
      isConfiguredRateMatcher
    };
    onOpenManageIntegration(currentCard);
  };

  const handleChangeConnectAndInventory = (checked, type) => {
    if (type === MANAGE_TYPE.CONNECT) {
      checked ? setIsChecked({ connect: true, inventory: false }) : setIsChecked({ connect: false, inventory: false });
    } else {
      setIsChecked({ connect: isChecked.connect ,inventory: checked });
    }
  };

  useEffect(() => {
    if (has(data, 'isEditable')) {
      setIsEditable(data.isEditable);
    }
  }, [data?.isEditable]);
  return (
    <SlzPaper w="100%" h="100%" minH="16.688rem" borderRadius={3} bg="#FFFFFF" overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <VStack spacing="1.125rem" align="stretch" p={6}>
          <VStack spacing={6} align="stretch">
            <HStack spacing={0} justifyContent="space-between">
              <IntegrationCardIcon
                integrationType={integrationType}
                isIntegrateFromApp={appIntegration}
              />
              <IntegrationCardStatus connected={connected} />
            </HStack>

            <VStack spacing={3} align="stretch">
              <Flex>
                <Text as="h5" fontSize="1rem" fontWeight="bold">
                  {shopName}
                </Text>
              </Flex>
              <Flex>
                <Text fontSize="0.75rem" fontWeight="bold">
                  Last connection date
                </Text>
                <Text fontSize="0.75rem" fontWeight="medium" ml={1}>
                  {dayjs(lastModified).format(DATE_FORMAT_DDMMYYYY)}
                </Text>
              </Flex>
            </VStack>

            <VStack spacing={3} align="stretch">
              <Flex>
                <SlzToggle
                  isDisabled={isDisable?.connect}
                  onChange={(e) => handleChangeConnectAndInventory(e.target?.checked, MANAGE_TYPE.CONNECT)}
                  isChecked={isChecked.connect}
                  value={connected}
                  mb={0}
                  pr={1.5}
                  fontSize="1rem"
                  isReadOnly={isEditable}>
                  Connect
                </SlzToggle>
                <SlzToolTip content={'Connect tooltip'} placement="top-start" variant="blue">
                  <InfoOutlineIcon boxSize="3" color="main.500" />
                </SlzToolTip>
              </Flex>

              <Flex>
                <SlzToggle
                  onChange={(e) =>handleChangeConnectAndInventory(e.target?.checked, MANAGE_TYPE.INVENTORY)}
                  isDisabled={isDisable?.inventory || !isChecked.connect}
                  isChecked={isChecked.inventory}
                  value={inventoryManagement}
                  mb={0}
                  pr={1.5}
                  fontSize="1rem"
                  isReadOnly={isEditable}>
                  Manage my inventory
                </SlzToggle>
                <SlzToolTip
                  content={'Manage my inventory tooltip'}
                  placement="top-start"
                  variant="blue">
                  <InfoOutlineIcon boxSize="3" color="main.500" />
                </SlzToolTip>
              </Flex>
            </VStack>
          </VStack>

          <HStack spacing={0} justifyContent="space-between">
            {appIntegration ? (
              <IntegrationCardActionButton
                text="Rate matcher"
                isDisabled={isEditable}
                integrationType={integrationType}
                isConfiguredRateMatcher={isConfiguredRateMatcher}
                onClick={() => onClickRateMatcher(data)}
              />
            ) : (
              <IntegrationCardActionButton
                text="Credentials"
                integrationType={integrationType}
                isDisabled={isEditable}
                isConfiguredRateMatcher={isConfiguredRateMatcher}
                onClick={() => onClickEditCredentials(data)}
              />
            )}
            <SlzButton
              size="md"
              lineHeight="4"
              variant={isEditable ? 'outline' : 'solid'}
              colorScheme="main"
              borderRadius={1.5}
              onClick={isEditable ? handleManage : handleSave}>
              {isEditable ? 'Manage' : 'Save'}
            </SlzButton>
          </HStack>
        </VStack>
      )}
    </SlzPaper>
  );
};

export default IntegrationCard;
