import React from 'react';

import { Icon } from '@chakra-ui/react';

const WarningIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="34.408"
      height="34.408"
      fill="currentColor"
      viewBox="0 0 34.408 34.408"
      {...props}>
      <svg
        stroke="currentColor"
        fill="currentColor"
        viewBox="0 0 256 256"
        height="0.85em"
        width="0.85em"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm-12-80V80a12,12,0,0,1,24,0v52a12,12,0,0,1-24,0Zm28,40a16,16,0,1,1-16-16A16,16,0,0,1,144,172Z"></path>
      </svg>
    </Icon>
  );
};

export default WarningIcon;
