import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Shelf = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.476rem"
            h="1.125rem"
            viewBox="0 0 23.623 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Union_320" data-name="Union 320" d="M6371.9,20986.295a2.687,2.687,0,0,1,.154-.9h-4.808a2.7,2.7,0,1,1-3.443-1.643v-10a.809.809,0,0,1,0-.119v-.836h-.9a.9.9,0,1,1,0-1.8h1.8a.9.9,0,0,1,.9.9v.959l11.761.842a.9.9,0,0,1,.83,1.023l-.9,6.3a.9.9,0,0,1-.892.773h-10.8v1.8h9a2.7,2.7,0,1,1-2.7,2.7Zm1.8,0a.9.9,0,1,0,.9-.9A.9.9,0,0,0,6373.7,20986.295Zm-9.9,0a.9.9,0,1,0,.9-.9A.9.9,0,0,0,6363.8,20986.295Zm11.813-6.3.654-4.568-10.67-.762v5.33Z" transform="translate(-6362.001 -20970.998)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Shelf;