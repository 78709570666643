import { Box, HStack, Heading, VStack } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { PageNotFoundIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { EMAIL_CUSTOMER_SERVICE, KNOWLEDGE_BASE_URL, USEFUL_LINKS_TEXT } from 'constants/common';

const UsefulLinkButton = ({ text, onClick }) => {
  return (
    <SlzButton variant="outline" size="lg" color="main.500" onClick={onClick}>
      {text}
    </SlzButton>
  );
};

const PageNotFound = () => {
  const history = useHistory();

  const handleGoToDashboard = () => {
    history.push('/');
  };

  const handleGoToKnowledgeBase = () => {
    window.open(KNOWLEDGE_BASE_URL, '_blank', 'noopener,noreferrer');
  };

  const handleOpenMail = (e) => {
    e.preventDefault();
    window.location.href = `mailto:${EMAIL_CUSTOMER_SERVICE}`;
  };

  return (
    <HStack alignItems="flex-start" pl="3.813rem" pt="4.813rem" spacing="6.813rem" w="full">
      <VStack alignItems="flex-start" spacing={6}>
        <Heading fontSize="2xl">{USEFUL_LINKS_TEXT}</Heading>
        <UsefulLinkButton text="Home" onClick={handleGoToDashboard} />
        <UsefulLinkButton text="Knowledge base" onClick={handleGoToKnowledgeBase} />
        <UsefulLinkButton text="Email customer service" onClick={handleOpenMail} />
      </VStack>
      <Box>
        <PageNotFoundIcon />
      </Box>
    </HStack>
  );
};

export default PageNotFound;
