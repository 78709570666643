import { Box, Card, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { SlzToggle } from 'components/common/SlzToggle';
import Tooltip from 'modules/integrations/components/form/Tooltip';
import SlzInput from 'components/common/SlzInput/SlzInput';

const GTIN = () => {
  const {
    formState: { errors },
    watch,
    register,
    setValue,
    clearErrors,
    resetField
  } = useFormContext();

  const [isGtinEnabled, setIsGtinEnabled] = useState(false);

  useEffect(() => {
    const gtinSwitch = watch('gtinSwitch');
    setIsGtinEnabled(gtinSwitch);
    if (!gtinSwitch) setValue('gtin', '');
    clearErrors('gtin');
  }, [watch('gtinSwitch')]);

  useEffect(() => {
    if(errors['gtin']) setValue('gtin', errors?.gtin?.message);
  }, [errors]);

  const handleOnFocus = useCallback(
    (field) => {
      if (errors[field]) resetField(field);
      clearErrors(field);
    },
    [errors]
  );

  return (
    <>
      <VStack w="50%">
        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <SlzToggle mb="0 !important" {...register('gtinSwitch')}></SlzToggle>
            <Text
              sx={{
                textAlign: 'left',
                fontSize: 'lg',
                color: 'table.mainText',
                fontWeight: 'bold'
              }}>
              GTIN identifier
            </Text>
            <Tooltip content={'abc'} />
          </HStack>
        </Card>
        <Card
          bg="light.500"
          border="1px solid"
          borderColor="light.700"
          mt="2rem"
          w="100%"
          h="5.875rem"
          px="1.5rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box w="full">
              <FormControl isInvalid={errors?.gtin}>
                <SlzFormLabel
                  display="flex"
                  gap="1"
                  alignItems="left"
                  color="dark.700"
                  mr="0 !important"
                  fontSize="0.75rem">
                  GTIN identifier
                </SlzFormLabel>

                <SlzInput
                  disabled={!isGtinEnabled}
                  type="text"
                  size="sm"
                  textAlign="left"
                  status={errors?.gtin && 'error'}
                  w="full"
                  sx={{
                    '&:disabled': {
                      bgColor: 'light.700 !important'
                    },
                    bgColor: 'light.300',
                    border: '1px',
                    borderColor: 'dark.300',
                    fontSize: errors?.gtin ? 'xs' : 'sm'
                  }}
                  {...register('gtin')}
                  onFocus={() => handleOnFocus('gtin')}
                />
              </FormControl>
            </Box>
          </HStack>
        </Card>
      </VStack>
    </>
  );
};

export default GTIN;
