import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Checkbox } from '@chakra-ui/react';
import { components } from 'chakra-react-select';

const DefaultOption = (props) => {
  const {
    children = null,
    data: { value = '' } = {},
    selectProps: { isMulti = false } = {},
    isSelected = false,
    isDisabled = false
  } = props;

  return (
    <components.Option {...props} data-testid={`slz-dropdown-option-${value}`}>
      {isMulti && <Checkbox mr={2} isChecked={isSelected} isReadOnly={isDisabled} />}
      {!isMulti && <Box ml={1}></Box>}
      {children}
    </components.Option>
  );
};

const DefaultIndicator = (props) => {
  const { selectProps } = props;
  const { menuIsOpen = false, size } = selectProps;
  const iconSize = {
    xs: 4,
    sm: 4.5,
    md: 5,
    lg: 6
  };
  const indicatorIcon = {
    true: <ChevronUpIcon boxSize={iconSize[size]} />,
    false: <ChevronDownIcon boxSize={iconSize[size]} />
  };

  return (
    <components.DropdownIndicator {...props}>
      {indicatorIcon[menuIsOpen]}
    </components.DropdownIndicator>
  );
};

const DefaultMultipleValue = ({ selectProps, ...rest }) => {
  const size = selectProps.size === 'lg' ? 'md' : selectProps.size;
  const multiValueProps = { ...rest, selectProps: { ...selectProps, size } };

  return <components.MultiValue {...multiValueProps} />;
};

const DefaultComponents = () => {};

DefaultComponents.Option = DefaultOption;
DefaultComponents.Indicator = DefaultIndicator;
DefaultComponents.MultipleValue = DefaultMultipleValue;

export default DefaultComponents;
