import { APPEND_BULK_ORDER_LIST, GET_BULK_ORDER_LIST, SET_QUERY_PARAM } from '../actions';
import { INITIAL_BULK_ORDER_STATE } from '../constants';

export const bulkOrderReducer = (state = INITIAL_BULK_ORDER_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_BULK_ORDER_LIST: {
      return { ...state, list: payload.bulkOrders };
    }
    case APPEND_BULK_ORDER_LIST: {
      return { ...state, list: [...state.list, ...payload.bulkOrders] };
    }
    case SET_QUERY_PARAM: {
      return { ...state, params: payload.params };
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};
