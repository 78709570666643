import { useQuery } from 'react-query';
import { getVatRegisteredCountries } from 'modules/preferences/services/preferences-api';

export const useVatRegisteredQuery = ({ select }) => {
  return useQuery({
    queryKey: [`vat-registered`],
    queryFn: () => getVatRegisteredCountries(),
    select: select
  });
};
