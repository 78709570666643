import { useQuery } from 'react-query';
import { getPackagingExtras } from 'modules/preferences/services/preferences-api';

export const usePackagingExtrasQuery = ({ select }) => {
  return useQuery({
    queryKey: [`packaging-extras`],
    queryFn: () => getPackagingExtras(),
    select: select
  });
};
