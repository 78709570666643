import { GET_CONSIGNMENT_LIST, GET_ONHOLD_LIST, SET_QUERY_PARAM } from '../actions';
import { CONSIGNMENTS_INITIAL_STATE, ONHOLD_INITIAL_STATE } from '../constants';

export const consignmentsReducer = (state = CONSIGNMENTS_INITIAL_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_CONSIGNMENT_LIST: {
      return { ...state, list: payload.items };
    }
    case SET_QUERY_PARAM: {
      return { ...state, params: payload?.params };
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};

export const onHoldReducer = (state = ONHOLD_INITIAL_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_ONHOLD_LIST: {
      return { ...state, list: payload.items };
    }
    case SET_QUERY_PARAM: {
      return { ...state, params: payload?.params };
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};
