import { RETURN_SETTING_STYLE } from 'modules/preferences/constants';
import { PreferenceHeader } from '../common';

const ReturnSettingGradePreferenceHeader = ({ disabled, ...rest }) => {
  return (
    <PreferenceHeader
      fontSize="md"
      lineHeight="1.188rem"
      sx={disabled && RETURN_SETTING_STYLE.textDisabled}
      {...rest}
    />
  );
};

export default ReturnSettingGradePreferenceHeader;
