import SlzTable from 'components/common/SlzTable/Table';
import map from 'lodash/map';
import { GET_ONHOLD_LIST } from 'modules/consignments/actions';
import { useOnHoldQuery } from 'modules/consignments/hooks/useOnHoldQuery';
import { transformOnHold } from 'modules/consignments/mappers/onHold-mapper';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CONSIGNMENTS_ONHOLD_PATHNAME, ONHOLD_SKELETON_CELL } from '../../constants';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import { useOnHoldContext } from '../../context/ConsignmentsContext';
import { ONHOLD_COLUMNS } from './columns';

const columns = [...ONHOLD_COLUMNS];

const OnHoldTable = (props) => {
  const { onPageChange, onItemPerPageChange } = props;

  const { onHoldItems, onHoldDispatch } = useOnHoldContext();
  const {
    data: { items = [], totalPage } = {},
    isFetching,
    refetch,
    isError
  } = useOnHoldQuery(onHoldItems?.params, (data) => transformOnHold(data), true);

  const history = useHistory();

  columns[0].onClick = (row) => {
    history.push(`${CONSIGNMENTS_ONHOLD_PATHNAME}/${row.id}`);
  };

  useEffect(() => {
    onHoldDispatch({
      type: GET_ONHOLD_LIST,
      payload: { items }
    });
  }, [isFetching]);

  return (
    <>
      <SlzTable
        isLoading={isFetching}
        showMarked
        type="normal"
        variant="simple"
        sizes={['sm', 'md', 'lg']}
        columns={columns}
        data={onHoldItems?.list || Array(5).fill(ONHOLD_SKELETON_CELL)}
        onRefetching={refetch}
        isEmptyPage={isError || totalPage === 0}
        messageToast="Sorry! We could not retrieve your onhold information"
        displayFields={map(columns, 'field')}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => onItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: onHoldItems?.params?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage,
            currentPage: onHoldItems?.params?.pageIndex,
            isGreyBg: true,
            onChange: (currentPage) => onPageChange(currentPage)
          }
        }}
        filterByDate={{
          field: 'createdDate',
          start: onHoldItems?.params?.startDate,
          end: onHoldItems?.params?.endDate
        }}
      />
    </>
  );
};

export default OnHoldTable;
