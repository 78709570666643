import { useEffect, useState } from 'react';
import { Box, Stack, VStack } from '@chakra-ui/react';
import { FormProvider, useFormContext } from 'react-hook-form';
import uniqueId from 'lodash/uniqueId';

import SlzAttentionCard from 'components/common/SlzAttentionCard/SlzAttentionCard';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';
import { NestedRadioGroup } from 'components/common/SlzForms/base/NestedRadioGroup';
import { tooltipContent } from 'pages/Elements/ElementOne';
import useReturnPreferencesQuery from 'modules/products/hooks/useReturnPreferencesQuery';
import { RETURN_SETTING_GRADES } from 'modules/preferences/constants';
import { returnPreferencesOptions } from 'modules/products/constants';
import {
  GRADING_DROPDOWN_LABEL_STYLE,
  GRADING_DROPDOWN_STYLE,
  RETURNABLE_LABEL_STYLE
} from 'modules/products/constants/constants.style';

const Grade = ({ information }) => {
  return (
    <Box mt={3} lineHeight="0.938rem">
      <Box fontSize="lg" fontWeight="bold" w="full" color="lightAndShadow.mainText">
        {information?.grade} - {information?.description}
      </Box>
      <Box fontSize="md" fontWeight="normal" w="full" color="lightAndShadow.mainText">
        {information?.meaning}
      </Box>
    </Box>
  );
};

const ReturnPreference = ({ isEditable }) => {
  const [isReturnable, setIsReturnable] = useState();
  const [returnOptions, setReturnOptions] = useState();
  const methods = useFormContext();
  const productId = methods.getValues('id');
  const {
    data: { productReturnSettings, returnConfig, gradingOptions, gradingInformation },
    isSuccess
  } = useReturnPreferencesQuery({ productId, enabled: !!productId });

  const gradingInformationSorted = gradingInformation?.sort((a, b) =>
    a.grade.localeCompare(b.grade)
  );
  const handleOnChangeReturnable = (value) => {
    value = value === 'true' || value === true;
    setIsReturnable(value);
  };

  const setFormDefaultValues = () => {
    return {
      grading: productReturnSettings?.grading.reduce(
        (grade, currentValue) => ({
          ...grade,
          [currentValue.grade]: gradingOptions?.find(
            (option) => option.value == currentValue.action
          )
        }),
        {}
      ),
      returnable: productReturnSettings?.returnable
    };
  };

  useEffect(() => {
    if (isSuccess) {
      methods?.reset({ ...methods.getValues(), ...setFormDefaultValues() });
    }
  }, [isSuccess, productReturnSettings]);

  useEffect(() => {
    const isReadOnly = !returnConfig?.enabled || !isEditable;
    setReturnOptions(returnPreferencesOptions({ isReadOnly }));
    setIsReturnable(!isReadOnly);
  }, [isEditable]);

  return (
    <Stack w="full" direction="row" spacing={0} mt={5}>
      <Box flex={1}>
        <FormProvider {...methods}>
          <form autoComplete="off">
            <VStack align="flex-start" spacing={8}>
              <NestedRadioGroup
                size="md"
                mt={1.5}
                label="Return preferences"
                labelProps={RETURNABLE_LABEL_STYLE}
                showTooltip={true}
                useTooltipLabel={true}
                tooltipContent={tooltipContent}
                field="returnable"
                data={returnOptions}
                onValueChange={handleOnChangeReturnable}
              />

              <VStack align="flex-start" w={50.75} spacing="0.438rem">
                <SlzFormLabel color="dark.500" fontSize="lg">
                  Grading actions
                </SlzFormLabel>
                <VStack align="flex-start" w={50.75} spacing="1.313rem">
                  {RETURN_SETTING_GRADES.map((grade) => (
                    <NestedDropdown
                      label={`Grade ${grade}`}
                      field={`grading[${grade}]`}
                      showTooltip={false}
                      labelProps={GRADING_DROPDOWN_LABEL_STYLE}
                      fieldProps={{
                        size: 'lg',
                        options: gradingOptions,
                        isDisabled: !isReturnable,
                        chakraStyles: GRADING_DROPDOWN_STYLE
                      }}
                    />
                  ))}
                </VStack>
              </VStack>
            </VStack>
          </form>
        </FormProvider>
      </Box>
      <Box flex={2}>
        <SlzAttentionCard
          w="full"
          maxW="full"
          variant="returnPreference"
          title="Selazar grading scale"
          description="These are the options that we use in the Selazar warehouse to grade your returns.">
          <Box>
            {gradingInformationSorted &&
              gradingInformationSorted.map((information) => (
                <Grade key={uniqueId()} information={information} />
              ))}
          </Box>
        </SlzAttentionCard>
      </Box>
    </Stack>
  );
};

export default ReturnPreference;
