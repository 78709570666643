import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import SlzUnlinkedCalendar from './SlzUnlinkedCalendar';
import { Box, Fade, VStack, useDisclosure } from '@chakra-ui/react';
import SlzDateRangeInput from './SlzDateRangeInput';
import classNames from 'classnames';
import { DATE_RANGE_PICKER_DEFAULT_VALUE } from '../../../constants';
import dayjs from 'dayjs';
import { useOutside } from '../../../hooks/useOutside';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

function SlzDateRangePicker(props) {
  const defaultEndDate = props?.endDate;
  const { onDateRangeClick, startDatePlaceHolder, endDatePlaceHolder, disableChoosingPrev = true } = props;
  const defaultValue = DATE_RANGE_PICKER_DEFAULT_VALUE;
  const [startDate, setStartDate] = useState(
    props.startDate ? dayjs(props.startDate) : defaultValue.startDate
  );

  const [endDate, setEndDate] = useState(
    props.endDate ? dayjs(props.endDate) : defaultValue.endDate
  );

  const [selectedDefinedRange, setSelectedDefinedRange] = useState(
    defaultValue.definedRangeSelected
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (startDate && !endDate) {
      handleClearSelection();
    }
  }, [isOpen]);

  useEffect(() => {
    if (startDate && endDate) {
      callBackDateRange();
      onClose();
    }
  }, [startDate, endDate]);

  const callBackDateRange = () => {
    if (startDate && endDate) {
      onDateRangeClick && onDateRangeClick(startDate, endDate);
    }
  };
  const outSideClose = () => {
    if (startDate && endDate) callBackDateRange();
    onClose();
  };
  useOutside(wrapperRef, outSideClose);

  const className = classNames({
    'date-range-picker-calendar': true
  });
  const dateRangeInputClassName = classNames({
    'date-range-input': true,
    active: isOpen
  });
  const handleDateRangeClick = (startDate, endDate, id) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDefinedRange(id);
  };

  const handleClearSelection = () => {
    onDateRangeClick && onDateRangeClick(null, null);
    setSelectedDefinedRange(-1);
    setStartDate(null);
    setEndDate(defaultEndDate);
  };

  const handleOnDateRangeInputClicked = () => {
    !isOpen && onOpen();
  };

  return (
    <Box
      className="date-range-input-wrapper"
      __css={{ color: '#676a74', position: 'relative', width: '22.5rem' }}
      ref={wrapperRef}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-DateRangePicker`)}>
      <VStack>
        <SlzDateRangeInput
          className={dateRangeInputClassName}
          onDateRangeInputClick={handleOnDateRangeInputClicked}
          startDate={startDate}
          startDatePlaceHolder={startDatePlaceHolder}
          endDatePlaceHolder={endDatePlaceHolder}
          endDate={endDate}
        />
      </VStack>
      {isOpen && (
        <Box className={className}>
          <SlzUnlinkedCalendar
            disableChoosingPrev={disableChoosingPrev}
            isShowFuture={props?.isShowFuture ?? false}
            showDropdowns={false}
            onDateRangeClick={handleDateRangeClick}
            onClearSelection={handleClearSelection}
            startDate={startDate}
            endDate={endDate}
            selectedDefinedRange={selectedDefinedRange}
          />
        </Box>
      )}
    </Box>
  );
}

export default SlzDateRangePicker;
