import { useState } from 'react';

import { Box, HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { dayjs } from 'utils';

import { SlzButton } from 'components/common/SlzButton';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_YYYYMMDD, DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants/date';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import {
  ORDER_STATUS,
  ORDER_KEY_PARAM,
  B2C_ORDERS_PATHNAME,
  ORDER_DISPLAY_FIELDS_INPUT_SEARCH,
  INDEX_OF_STATUS_ALL
} from 'modules/b2c-orders/constants';
import { DEFAULT_PAGE_INDEX, STATUS } from 'constants/table.js';
import { getOrders } from 'modules/b2c-orders/services/orders-api';
import { transformOrders } from 'modules/b2c-orders/mappers/order-list-mapper';
import { useOrderContext } from 'modules/b2c-orders/context/OrderContext';
import { CreateOrderAction } from '../other';

const OrdersFilter = (props) => {
  const { onDateRangeChange, status } = props;
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(() => {
    if (!status) return INDEX_OF_STATUS_ALL;
    return Object.keys(ORDER_STATUS).indexOf(status);
  });
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });
  const { orderDispatch } = useOrderContext();

  const handleAutocompleteSearch = (searchTerm) => {
    setSearchParams({
      pageIndex: DEFAULT_PAGE_INDEX,
      OrderSearchText: searchTerm,
      status
    });
  };

  const transformFn = (res) => transformOrders(res).orders;

  const onChangeStatus = (index) => {
    setTabIndex(index);
    const status = Object.keys(ORDER_STATUS)[index];
    if (status === 'all') {
      routerQuery.delete(STATUS);
    } else {
      routerQuery.set(STATUS, status);
    }
    history.push({
      search: routerQuery.toString()
    });
  };

  const handleOpenOrdersTracking = () => {
    routerQuery.set(ORDER_KEY_PARAM.IS_TRACKING_MODAL, true);
    history.push({
      search: routerQuery.toString()
    });
  };

  return (
    <Box>
      <HStack mb={6} gap="25px">
        <SlzAutocompleteInput
          width="50%"
          variant="accent"
          showedFields={ORDER_DISPLAY_FIELDS_INPUT_SEARCH}
          placeholder="Search"
          process={routerQuery.get(STATUS) == ORDER_STATUS.onhold.key ? 'b2cOrderOnHold' : 'b2cOrder'}
          onTypingSearch={handleAutocompleteSearch}
          onNavigate={(record) => history.push(B2C_ORDERS_PATHNAME + '/' + record.id)}
          transformFn={transformFn}
          fetchFn={getOrders}
          searchParams={searchParams}
        />

        <SlzDateRangePicker
          onDateRangeClick={onDateRangeChange}
          startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}         
          startDate={null}
          endDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
          endDate={null}
        />

        <CreateOrderAction orderDispatch={orderDispatch} />

        <SlzButton size="lg" onClick={handleOpenOrdersTracking}>
          Tracking
        </SlzButton>
        {/* <SlzSplitButton
          items={[]}
          size={'lg'}
          placeholder="Export"
          hasArrow={true}
          onlyShowPlaceholder={true}
        /> */}
      </HStack>
      <HStack gap="25px" w="full" mb={6}>
        <Tabs variant="line" size={['sm', 'md', 'lg']} index={tabIndex} onChange={onChangeStatus}>
          <TabList flexWrap={'wrap'}>
            {Object.values(ORDER_STATUS).map((status) => (
              <Tab w={36} key={status.label}>
                {status.label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </HStack>
    </Box>
  );
};

export default OrdersFilter;
