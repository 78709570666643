import { useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { dayjs } from 'utils';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants/date';
import { BULK_ORDER_TAB } from 'modules/b2b-orders/constants';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { getBulkOrders } from 'modules/b2b-orders/services/bulk-orders-api';
import { transformBulkOrders } from 'modules/b2b-orders/mappers/bulk-order-list-mapper';
import { BULK_ORDER_COLUMNS } from '../table/bulk-order.columns';
import { CreateOrderAction } from 'modules/b2c-orders/components/other';

const BulkOrderFilter = ({ tabIndex, onSelectedDateRange }) => {
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  const handleAutocompleteSearch = (searchTerm) => {
    setSearchParams({
      pageIndex: DEFAULT_PAGE_INDEX,
      purchaseOrderNumber: searchTerm,
      tab: Object.values(BULK_ORDER_TAB)[tabIndex].key
    });
  };

  const transformFn = (res) => transformBulkOrders(res).bulkOrders;

  return (
    <Box>
      <HStack mb={6} gap="25px">
        <SlzAutocompleteInput
          width="50%"
          variant="accent"
          showedFields={BULK_ORDER_COLUMNS.filter((col) => col.isDisplayed).map((col) => col.field)}
          process="b2bOrder"
          placeholder="Search"
          accentField={['status']}
          onTypingSearch={handleAutocompleteSearch}
          transformFn={transformFn}
          fetchFn={getBulkOrders}
          searchParams={searchParams}
        />

        <SlzDateRangePicker
          onDateRangeClick={onSelectedDateRange}
          startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
          endDatePlaceHolder={TODAY}
          startDate={null}
          endDate={dayjs()}
        />

        <CreateOrderAction />
      </HStack>
    </Box>
  );
};

export default BulkOrderFilter;
