import { Flex, Text } from "@chakra-ui/react";
import { SlzChip } from "components/common/SlzChip";

export const CONSIGNMENTS_COLUMNS = [
  {
    name: 'CSV name/Item name',
    field: 'consignmentName',
    isSorted: true,
    isClickable: true,
    sortField: 'NAME',
  },
  {
    name: 'Date Created',
    field: 'createdDate',
    isSorted: true,
    sortField: 'DATE_CREATED',
  },
  {
    name: 'Status',
    field: 'receivedStatus',
    isSorted: true,
    isEmphasis: true,
    sortField: 'STATUS',
  },
  {
    name: 'Labels',
    field: 'labelStatus',
    isSorted: true,
    sortField: 'LABELS',
    render: ({ row }) => {
      return (
        <SlzChip
          m={1.5}
          color={row.labelStatus.color}
          flexBasis="none"
          lineHeight={4}
          size="md"
          variant="solid"
          bg={row.labelStatus.bgColor}>
          {row.labelStatus.name}
        </SlzChip>
      );
    }
  }
];

export const ONHOLD_COLUMNS = [
  {
    name: 'CSV name/Item name',
    field: 'orderName',
    isSorted: true,
    isClickable: true
  },
  {
    name: 'On hold date',
    field: 'createdDate',
    isSorted: true
  },
  {
    name: 'Status',
    field: 'status',
    isSorted: true,
    isEmphasis: true,
    render: ({ row }) => {
      return (
        <Flex direction="column" bgColor={row?.status.bgColor} color={row?.status.color}>
          <Text noOfLines={1} fontSize="1rem" fontWeight="medium" >
            {row?.status.name}
          </Text>
        </Flex>
      );
    }
  },
  {
    name: 'Outcome',
    field: 'outcome',
    isSorted: true,
    render: ({ row }) => {
      return (
        <SlzChip
          m={1.5}
          color={"#0760B9"}
          flexBasis="none"
          lineHeight={4}
          size="md"
          variant="solid"
          bg={"#bde0fe"}>
          {row.outcome}
        </SlzChip>
      );
    }
  }
];
