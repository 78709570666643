import { useEffect, useState } from 'react';

const useDelayRender = ({ isLoaded, template = undefined }) => {
  const [canShow, setCanShow] = useState(false);

  useEffect(() => {
    setCanShow(false);
  }, [template]);

  useEffect(() => {
    if (isLoaded) {
      const timer = setTimeout(() => setCanShow(true), 500);
      return () => clearTimeout(timer);
    }

    !isLoaded && setCanShow(false);
  }, [isLoaded, template]);

  return canShow;
};

export default useDelayRender;
