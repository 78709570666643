import { Box, GridItem, HStack, Heading } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const OrderInvoice = ({ title, ...rest }) => (
  <ShimmerSkeleton w="100%" {...rest}>
    <Heading as="h3" fontSize="2xl">
      {title}
    </Heading>
    <ShimmerSkeleton.Block w="13.063rem" h="0.938rem" mb="0.894rem" />
    <HStack justifyContent="space-between" mb="1.25rem">
      <Box maxW="20.75rem" w="100%">
        <ShimmerSkeleton.Input titleStyle={{ w: '6.125rem' }}>
          {[...Array(3)].map(() => (
            <HStack justifyContent="space-between" mb="0.625rem">
              <ShimmerSkeleton.Block w="10.125rem" h="0.938rem" />
              <ShimmerSkeleton.Block w="3.813rem" h="0.938rem" />
            </HStack>
          ))}
        </ShimmerSkeleton.Input>
        <HStack>
          <ShimmerSkeleton.Input w="100%" titleStyle={{ w: '100%', h: '2.375rem' }}>
            <HStack justifyContent="space-between" mb="0.625rem">
              <ShimmerSkeleton.Block w="10.125rem" h="0.938rem" />
              <ShimmerSkeleton.Block w="3.813rem" h="0.938rem" />
            </HStack>
            <ShimmerSkeleton.Block w="10.125rem" h="0.938rem" mb="0.625rem" />
          </ShimmerSkeleton.Input>
        </HStack>
      </Box>
      <ShimmerSkeleton.Block w="15rem" h="7.75rem" mb="2.5rem !important" />
    </HStack>

    <HStack>
      <ShimmerSkeleton.Card w="100%" titleStyle={{ mb: '0.625rem' }}>
        {[...Array(3)].map(() => (
          <ShimmerSkeleton.Block w="100%" h="8.75rem" mb="0.625rem" />
        ))}
      </ShimmerSkeleton.Card>
    </HStack>
  </ShimmerSkeleton>
);

const BILLING_TEMPLATES = {
  orderInvoice: OrderInvoice
};

const BillingSkeleton = (props) => {
  return (
    <GridItem gridColumn="2/12">
      <BaseSkeleton baseTemplates={BILLING_TEMPLATES} {...props} />
    </GridItem>
  );
};

export default BillingSkeleton;
