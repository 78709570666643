import {
  Flex,
  Grid,
  HStack,
  Skeleton as SkeletonCharka,
  Stack,
  Td,
  Text,
  Tr
} from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { Fragment } from 'react';

import { skeletonAnimation as animation, skeletonTextAnimation as textAnimation } from './config';
import { AUTO_COMPLETE_SKELETONS, SKELETON_FIRST_LOAD, USING_SKELETON_FEATURES } from './constants';

const SkeRow = ({ h = 10, children, isLoaded = false }) => {
  return (
    <>
      <Flex flexDirection="row" sx={{ marginTop: '4px !important' }}>
        <Skeleton animation={animation} height={h} width="1%" mr={2}></Skeleton>
        <Skeleton animation={animation} height={h} width="50%" mr={2}></Skeleton>
        <Skeleton animation={animation} height={h} width="21%" mr={2}></Skeleton>
        <Skeleton animation={animation} height={h} width="28%"></Skeleton>
      </Flex>
      {isLoaded && children}
    </>
  );
};

const NoImageProductSkeRow = ({ h = 10, children, isLoaded = false }) => {
  return (
    <>
      <Flex flexDirection="row" sx={{ marginTop: '2px !important' }}>
        <Skeleton animation={animation} height={h} width="1.5%" mr="0.125rem"></Skeleton>
        <Skeleton animation={animation} height={h} width="10%" mr="0.125rem"></Skeleton>
        <Skeleton animation={animation} height={h} width="68.5%" mr="0.125rem"></Skeleton>
        <Skeleton animation={animation} height={h} width="20%"></Skeleton>
      </Flex>
      {isLoaded && children}
    </>
  );
};

export const SkeletonTableTemplate = (props) => {
  const { isLoaded, children, ...rest } = props;

  return (
    <>
      {isLoaded ? (
        <>{children}</>
      ) : (
        <Stack {...rest}>
          <Skeleton
            animation={animation}
            height={10}
            borderRadius={10}
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}></Skeleton>
          <SkeRow h={12}></SkeRow>
          <SkeRow h={12}></SkeRow>
          <SkeRow h={12}></SkeRow>
          <SkeRow h={12}></SkeRow>
          <SkeRow h={12}></SkeRow>
        </Stack>
      )}
    </>
  );
};

export const SlzSkeletonDefault = (props) => {
  const { textStyles = {}, children = Fragment, isLoading = true } = props;
  const DEFAULT_HEIGHT = '600px';
  const DEFAULT_TEXT = 'Selazar';

  return isLoading ? (
    <Stack
      alignItems="center"
      justifyContent="center"
      w="100%"
      h={props?.h || props?.height || DEFAULT_HEIGHT}
      bg="light.300">
      <HStack>
        <Text
          {...textStyles}
          p={0}
          m={0}
          animation={textAnimation}
          color="main"
          fontSize="100px"
          textTransform="capitalize">
          {props?.text || DEFAULT_TEXT}
        </Text>
      </HStack>
    </Stack>
  ) : (
    children
  );
};

export const SkeletonPageTemplate = (props) => {
  const { isLoaded, children, ...rest } = props;

  return (
    <>
      {isLoaded ? (
        <>{children}</>
      ) : (
        <Stack {...rest}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="66%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={20} width="4%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="15%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="15%" height="40px"></Skeleton>
          </Flex>
          <Grid templateColumns="repeat(7, 1fr)" gap={3}>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
            <Skeleton borderRadius={10} w="100%" height="40px"></Skeleton>
          </Grid>
          <SkeletonTableTemplate />
        </Stack>
      )}
    </>
  );
};

export const SkeletonModalTemplate = (props) => {
  const { isLoaded, children, ...rest } = props;

  return (
    <>
      {isLoaded ? (
        <>{children}</>
      ) : (
        <Stack {...rest}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="30%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="30%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="40%" height="40px" mr={5}></Skeleton>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="30%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="30%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="40%" height="40px" mr={5}></Skeleton>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between">
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
            <Skeleton borderRadius={10} width="50%" height="40px" mr={5}></Skeleton>
          </Flex>
        </Stack>
      )}
    </>
  );
};

export const Skeleton = ({ children = Fragment, isLoaded = false, ...rest }) => {
  const defaultStartColor = mode('gray.100', 'gray.800')(rest);
  const defaultEndColor = mode('gray.400', 'gray.600')(rest);
  const { startColor = defaultStartColor, endColor = defaultEndColor } = rest;

  return (
    <SkeletonCharka
      isLoaded={isLoaded}
      startColor={startColor}
      endColor={endColor}
      animation={!isLoaded && animation}
      fadeDuration={1}
      {...rest}>
      {children}
    </SkeletonCharka>
  );
};

export const AutocompleteInputSkeleton = ({
  children = Fragment,
  isLoaded = false,
  process = USING_SKELETON_FEATURES.DEFAULT,
  isFirstLoaded = false
}) => {
  const loadingRow = SKELETON_FIRST_LOAD[isFirstLoaded];

  return (
    <>
      {!isFirstLoaded && children}
      {!isLoaded &&
        [...Array(loadingRow)].map((_, rowIndex) => (
          <Tr
            key={`tr-${process}-${rowIndex}`}
            _before={{
              content: '""',
              pos: 'absolute',
              w: '0.5625rem',
              h: '3rem',
              bg: 'dark.300'
            }}>
            {AUTO_COMPLETE_SKELETONS[process]?.map(
              ({ p, colSpan, position = 'unset', w = 'unset', ...rest }, index) => (
                <Td
                  key={`td-${process}-${index}`}
                  p={p}
                  colSpan={colSpan}
                  position={position}
                  w={isFirstLoaded ? '12.5rem' : w}>
                  <SkeletonCharka {...rest} />
                </Td>
              )
            )}
          </Tr>
        ))}
    </>
  );
};

export { default as SkeletonCircle } from './SkeletonCircle';
export { default as SkeletonText } from './SkeletonText';

export const SkeletonNoImageProductTableTemplate = (props) => {
  const { isLoaded, children, ...rest } = props;

  return (
    <>
      {isLoaded ? (
        <>{children}</>
      ) : (
        <Stack {...rest}>
          <Skeleton
            animation={animation}
            height={10}
            borderRadius="0.375rem"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}></Skeleton>
          <Skeleton
            animation={animation}
            height={10}
            mt="0.125rem !important"
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}></Skeleton>
          {[...Array(10)].map((value, i) => (
            <NoImageProductSkeRow key={i} h={12}></NoImageProductSkeRow>
          ))}
        </Stack>
      )}
    </>
  );
};
