export const transformProducts = ({ items = [], pageCount = 0 }) => {
  const data = {
    products: items,
    totalPage: pageCount
  };
  data.products = data.products.map((product) => ({
    ...product,
    id: `${product?.companyItemID}-${product?.sku}`
  }));

  return data;
};

export const transformSelectionProduct = (selectedProducts) => {
  return selectedProducts?.map((selectedProduct) => {
    const companyItemID = selectedProduct?.companyItemID;
    const sku = selectedProduct?.sku;

    return {
      identifier: `${companyItemID}${sku ? `_${sku}` : ''}`,
      companyItemID: selectedProduct?.companyItemID,
      name: selectedProduct?.name,
      quantity: selectedProduct?.quantity,
      sku: selectedProduct?.sku,
      unassigned: selectedProduct?.unassigned || 0,
      assigned: selectedProduct?.assigned || 0,
      availableQuantity: selectedProduct?.totalStock || 0,
      category: selectedProduct?.category
    };
  });
};
