import { DATE_FORMAT_DDMMYYYY } from '../../../constants/date';
import dayjs from 'dayjs';
import { CONSIGNMENT_STATUS_COLOR } from '../constants';

export const transformConsignmentDetail = (data) => {
  const qualityCheck  = data.qualityChecks[0];
  const consignmentInfoDetails = {
    fileName: data.fileName,
    createdDate: data.createdDate,
    quarantineDate: data.quarantineDate,
    quarantineResolution: data.quarantineResolution,
    status: {
      key: "status",
      name: data.status,
      bgColor: CONSIGNMENT_STATUS_COLOR[data.status],
      color: CONSIGNMENT_STATUS_COLOR[data.status]
    }
  }

  const consignmentBreakDown = data.lines.map((product) => {
    return {
      name: product.name,
      sku: product.skuCode,
      ean: product.ean,
      imageUrl: null,
      quantity: product.stockCount,
    }
  });

  const consignmentDetails = {
    consignmentBoxDimensions: {
      height: data.boxHeight,
      weight: data.boxWeight,
      width: data.boxWidth,
      depth: data.boxDepth,
    }
  }

  const shippingAndLabelling = {
    shippingReference: data.shippingReference,
    labelsAffixed: data.labelsAffixed,
    supplierPrintedLabels: data.supplierPrintedLabels
  }

  const warehouseInformation = {
    name: 'Selazar Nuneaton',
    addressLine2: 'Unit 7, Centrovell Trading Estate',
    addressLine1: 'Nuneaton, Warwickshire',
    country: 'United Kingdom',
    postCode: 'CV11 4NG'
  }

  const qualityReportInformation = {
    id: qualityCheck?.qualityReports[0]?.id,
    status: qualityCheck?.status,
    photoLink: qualityCheck?.qualityReports[0]?.photoLink,
    notes: qualityCheck?.qualityReports[0]?.notes,
    createdDate: qualityCheck?.qualityReports[0]?.createdDate
  }

  const qualityReportResponse = {
    resolution: qualityCheck?.qualityReports[0]?.qualityReportResponses[0]?.resolution,
    resolutionNotes: qualityCheck?.qualityReports[0]?.qualityReportResponses[0]?.resolutionNotes
  }

  return {
    consignmentInfoDetails,
    consignmentBreakDown,
    consignmentDetails,
    shippingAndLabelling,
    warehouseInformation,
    qualityReportInformation,
    qualityReportResponse
  };
};

export const transformConsignments = ({ items = [], pageCount = 0 }) => {
  const data = {
    items,
    totalPage: pageCount
  };

  data.items = data.items.map((consignment) => {
    let { bgColor, color } = getLabelsColor(consignment.labelsAffixed);

    return {
      id: consignment.id,
      createdDate: dayjs(consignment.createdDate).format(DATE_FORMAT_DDMMYYYY),
      consignmentName: consignment.fileName,
      receivedStatus: {
        key: 'receivedStatus',
        name: consignment.isReceived ? 'Received' : 'Not Received',
        color: getStatusColor(consignment.isReceived)
      },
      labelStatus: {
        key: 'labelStatus',
        name: consignment.labelsAffixed ? 'Affixed' : 'Not affixed',
        bgColor,
        color

      }
    };
  });

  return data;
};

const getStatusColor = (status) => status ? '#21C474' : 'szrCerulean.300';

const getLabelsColor = (status) => ({ bgColor: status ? 'positive.300' : 'negative.300', color: status ? 'positive.500' : 'red.300' });
