import moment from 'moment';
import { DELIVERY_DESTINATION } from '../constants';

export const transformDeliveryDestinations = ({ data }) => {
  const regions = data?.data || [];
  const chartData = DELIVERY_DESTINATION.map((item) => ({
    ...item,
    value: regions.find((region) => region.region === item.field)?.total ?? 0
  }));

  const total = chartData.reduce((acc, curr) => acc + curr.value, 0);

  return {
    chartData,
    total
  };
};

export const transformMostPopularProduct = ({ data }) => {
  const products = data?.data || [];
  return products.map((item) => ({
    ...item,
    sku: item.skuCode ?? '000000001' //Todo: Mock data, will change after api is updated
  }));
};

export const transformOrders = ({ data }) => {
  return data?.data?.map(({ total, date }) => {
    const givenDate = moment(date);
    const currDate = moment();
    const diffDays = currDate.diff(givenDate, 'days') + 1;

    return {
      total: total,
      days: diffDays
    };
  });
};
