import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function GridIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 84"
      fill="currentColor"
      strokeWidth="0"
      {...props}>
      <g id="Group_33453" data-name="Group 33453" transform="translate(-1393.773 -893.887)">
        <path
          id="Rectangle_184"
          data-name="Rectangle 184"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(1394.773 894.887)"
          fill="currentColor"
        />
        <path
          id="Rectangle_185"
          data-name="Rectangle 185"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(1441.439 894.887)"
          fill="currentColor"
        />
        <path
          id="Rectangle_186"
          data-name="Rectangle 186"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(1394.773 941.554)"
          fill="currentColor"
        />
        <path
          id="Rectangle_187"
          data-name="Rectangle 187"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(1441.439 941.554)"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}
