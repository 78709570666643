import React from 'react';
import { Icon } from '@chakra-ui/react';

const ProductLibraryStockIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33672" data-name="Group 33672" transform="translate(-10371.193 7019.835)">
        <g id="Group_33676" data-name="Group 33676" transform="translate(8018.421 -7192.519)">
          <path
            id="Path_5397"
            data-name="Path 5397"
            d="M2358.632,176.887H2355.8a2.025,2.025,0,0,0-2.025,2.025v2.834a2.025,2.025,0,0,0,.593,1.432l6.116,6.116a2.024,2.024,0,0,0,2.863,0h0l2.834-2.834a2.026,2.026,0,0,0,0-2.864h0l-6.117-6.116A2.028,2.028,0,0,0,2358.632,176.887Z"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_5398"
            data-name="Path 5398"
            d="M2368.346,189.294l2.834-2.834a2.026,2.026,0,0,0,0-2.864h0l-7.117-7.116"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_5399"
            data-name="Path 5399"
            d="M2356.773,179.887h-.01"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ProductLibraryStockIcon;
