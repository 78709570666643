import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation } from 'react-query';

import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import useSlzToast from 'hooks/useSlzToast';
import { HeaderTooltip, PreferenceHeader } from 'modules/preferences/components/common';
import { EuVatRegisteredList } from 'modules/preferences/components/other';
import {
  TAXE_AND_DUTY_MESSAGE,
  TAX_AND_DUTY_FEATURE_HEADERS,
  USER_SETTING_FORMS_DEFAULT_NAME,
  VAT_REGISTERED_MODE
} from 'modules/preferences/constants';
import { updateVatRegisteredCountries } from 'modules/preferences/services/preferences-api';

const EuVatRegisteredContent = ({ handleSave, vatFormMethods, children }) => (
  <FormProvider {...vatFormMethods}>
    <form onSubmit={vatFormMethods.handleSubmit(handleSave)}>{children}</form>
  </FormProvider>
);

const EuVatRegistered = ({ isEuVatEnabled, isFetching, vatCountries, refetch }) => {
  const [mode, setMode] = useState(VAT_REGISTERED_MODE.start);
  const [_, setToast] = useSlzToast();

  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.vat]: form } = forms;
  const vatFormMethods = form?.form;

  const vatCountriesUpdateMutation = useMutation({
    mutationFn: (payload) => {
      return updateVatRegisteredCountries(payload);
    }
  });

  const handleSave = async (countries) => {
    try {
      await vatCountriesUpdateMutation.mutateAsync({ VatCountries: countries });
      await refetch();
      setToast({ title: TAXE_AND_DUTY_MESSAGE.vat.success });
      setMode(VAT_REGISTERED_MODE.start);
    } catch (error) {}
  };

  useEffect(() => {
    !isFetching && vatFormMethods.reset({ ...vatCountries });
  }, [isFetching]);

  return (
    <Box w="full">
      <PreferenceHeader
        header={TAX_AND_DUTY_FEATURE_HEADERS.EU_VAT_REGISTERED.header}
        description={TAX_AND_DUTY_FEATURE_HEADERS.EU_VAT_REGISTERED.description}
        tooltip={<HeaderTooltip tooltip={TAX_AND_DUTY_FEATURE_HEADERS.EU_VAT_REGISTERED.tooltip} />}
      />
      <EuVatRegisteredContent handleSave={handleSave} vatFormMethods={vatFormMethods}>
        <EuVatRegisteredList
          mode={mode}
          setMode={setMode}
          isEuVatEnabled={isEuVatEnabled}
          vatCountries={vatCountries}
        />
      </EuVatRegisteredContent>
    </Box>
  );
};

export default EuVatRegistered;
