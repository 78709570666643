import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function TrolleyIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.313"
      height="29.628"
      viewBox="0 0 29.313 29.628"
      fill="currentColor"
      {...props}>
      <g id="Inventory_section_icon" data-name="Inventory section icon" transform="translate(1 1)">
        <g id="Group_301" data-name="Group 301" transform="translate(8.747 8.566)">
          <path
            id="Path_3536"
            data-name="Path 3536"
            d="M1020.365,2204.124h5.93v11.86H1011.47v-8.9h8.9"
            transform="translate(-1011.47 -2201.159)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_3537"
            data-name="Path 3537"
            d="M1019.4,2216.949v-13.343a1.483,1.483,0,0,0-1.483-1.482h-2.965a1.482,1.482,0,0,0-1.482,1.482v4.448"
            transform="translate(-1010.505 -2202.124)"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <path
          id="Path_3538"
          data-name="Path 3538"
          d="M1007.053,2219.683H1028.4v-23.337h3.453"
          transform="translate(-1004.855 -2196.346)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_39"
          data-name="Ellipse 39"
          cx="2.198"
          cy="2.198"
          r="2.198"
          transform="translate(22.918 23.232)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_40"
          data-name="Ellipse 40"
          cx="2.198"
          cy="2.198"
          r="2.198"
          transform="translate(0 23.232)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
