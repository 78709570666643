import React, { useState, useCallback, useEffect } from 'react';
import { HStack, Text, VStack, useToken } from '@chakra-ui/react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

import DashboardSkeleton from 'components/common/Skeleton/templates/dashboard';
import { SlzPaper } from 'components/common/SlzPaper';
import CustomTooltipContent from './CustomTooltipContent';
import ChartLayout from './ChartLayout';
import { XAXIS_LINE_GRAPH, X_AXIS_LINE_GRAPH } from 'modules/dashboard/constants';

const LOADING_VALUES = [...Array(28)].map((_, i) => ({
  total: 1,
  days: i + 1
}));

const calculateNewYAxisUpper = memoize((data) => {
  const upperValue = Math.max(...(data?.map(({ total = 0 }) => total) || [0]));
  const lowerValue = Math.min(...(data?.map(({ total = 0 }) => total) || [0]));
  const tickRange = Math.floor((upperValue - lowerValue) / 3);
  const newUpperValue = Math.round(tickRange + upperValue);
  return newUpperValue;
});

const calculateAverageValue = memoize((data) => {
  const sumTotalOrder = data?.reduce((sum, { total }) => sum + total, 0);
  const averageTotalOrder = data?.length > 0 ? (sumTotalOrder / data?.length).toFixed(2) : 0;
  return averageTotalOrder;
});

const AVERAGE_UNIT = {
  euro: (data) => `£${calculateAverageValue(data)}`,
  orders: (data) => `${calculateAverageValue(data)}/week`
};

const UNIT_MAPPER = {
  euro: (data) => `£${data}`,
  orders: (data) => `${data} orders`
};

const CustomTooltip = ({ payload, unit }) => {
  return (
    <CustomTooltipContent transform="translateX(-50%) translateY(-50%)" thinArrow>
      <VStack padding="0.25rem 0.625rem" spacing={0}>
        <Text fontSize="sm" fontWeight="bold" color="raisin.300">
          Day {get(payload, '[0].payload.days')}
        </Text>
        <Text fontSize="sm" fontWeight="bold" color="raisin.300">
          {UNIT_MAPPER[unit](get(payload, '[0].payload.total'))}
        </Text>
      </VStack>
    </CustomTooltipContent>
  );
};

const LineGraphChart = ({
  title,
  data,
  mainColor,
  lineColor,
  loadingColor,
  isLoading = true,
  isSuccess,
  refetch,
  unit = 'euro'
}) => {
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);
  const [areaDotPoints, setAreaDotPoints] = useState([]);

  const upperYAxis = calculateNewYAxisUpper(data);

  const chartData = {
    true: LOADING_VALUES,
    false: data
  };

  const onMouseOver = useCallback(
    (data) => {
      setActiveTooltipIndex(data?.activeTooltipIndex);
    },
    [activeTooltipIndex]
  );

  const handleGetPoints = (props) => {
    if (isLoading) return false;

    if (!areaDotPoints?.some((point) => point.key === props.key)) {
      setAreaDotPoints((points) => [
        ...points,
        {
          key: props.key,
          x: props.cx,
          y: props.cy
        }
      ]);
    }

    return false;
  };

  useEffect(() => {
    setAreaDotPoints([]);
  }, [data, isLoading]);

  return (
    <SlzPaper w="full" height="fit-content" bg="white">
      <HStack p="1rem 1.5rem" justifyContent="space-between">
        <Text fontSize="xl" lineHeight="1.625" fontWeight="bold">
          {title}
        </Text>
        <DashboardSkeleton
          isLoaded={!isLoading}
          width="8.644rem"
          h="0.625rem"
          backgroundColor={useToken('colors', loadingColor)}>
          {isSuccess && (
            <Text fontSize="xl" lineHeight="1.625" fontWeight="bold" color={mainColor}>
              Av. {AVERAGE_UNIT[unit](data)}
            </Text>
          )}
        </DashboardSkeleton>
      </HStack>
      <ChartLayout
        onReload={refetch}
        isSuccess={isLoading || isSuccess}
        layout="vertical"
        pb="4.875rem"
        pt="1.313rem">
        <ResponsiveContainer width="100%" height={200}>
          <AreaChart
            width={520}
            height={263}
            data={chartData[isLoading]}
            margin={{
              top: 10,
              right: -30
            }}
            onMouseMove={onMouseOver}>
            <defs>
              <linearGradient id={`gradientColor${mainColor}`} x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={useToken('colors', isLoading ? loadingColor : mainColor)}
                />
                <stop offset="95%" stopColor="white" stopOpacity={0.5} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="1 4"
              stroke={useToken('colors', mainColor)}
              opacity={0.5}
            />
            <XAxis
              dataKey="days"
              type="number"
              domain={X_AXIS_LINE_GRAPH.DOMAIN}
              ticks={X_AXIS_LINE_GRAPH.TICKS}
              tickLine={false}
              tick={{ fill: useToken('colors', 'dark.700') }}
              stroke={useToken('colors', mainColor)}
            />
            <YAxis
              yAxisId="left"
              dataKey="total"
              domain={[0, upperYAxis]}
              ticks={isLoading ? [0, 1] : [0, upperYAxis / 2, upperYAxis || 1]}
              tickLine={false}
              tick={{ fill: useToken('colors', 'dark.700') }}
              stroke={useToken('colors', mainColor)}
            />
            <YAxis
              yAxisId="right"
              tickLine={false}
              domain={[0, 100]}
              tick={false}
              stroke={useToken('colors', mainColor)}
              orientation="right"
            />
            <Tooltip
              content={<CustomTooltip unit={unit} />}
              position={{
                x: areaDotPoints[activeTooltipIndex]?.x,
                y: areaDotPoints[activeTooltipIndex]?.y - 30
              }}
            />
            <Area
              yAxisId="left"
              type="linear"
              dataKey="total"
              stroke={useToken('colors', isLoading ? loadingColor : lineColor)}
              fill={`url(#gradientColor${mainColor})`}
              dot={handleGetPoints}
              activeDot={{
                fill: 'white',
                stroke: useToken('colors', lineColor),
                strokeWidth: 1,
                r: 3
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </ChartLayout>
    </SlzPaper>
  );
};

export default LineGraphChart;
