import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useMultiStyleConfig
} from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { IoClose } from 'react-icons/io5';

const SlzModal = (props) => {
  const { children, variant, accentColor, showClose = true, showOverlay = true, ...rest } = props;
  const styles = useMultiStyleConfig('SlzModal', { variant, accentColor });

  return (
    <Modal id={uniqueId('SEL-Slz-Modal')} {...rest}>
      {showOverlay && <ModalOverlay data-testid="overlay" sx={styles.overlay} />}
      <ModalContent data-testid="content" sx={styles.content} maxW={props.maxW}>
        {showClose && (
          <ModalCloseButton data-testid="button-close" sx={styles.closeButton}>
            <IoClose size="1.625rem" />
          </ModalCloseButton>
        )}
        <ModalBody sx={styles.body}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SlzModal;

SlzModal.propTypes = {
  variant: PropTypes.oneOf(['standard', 'product']),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};
