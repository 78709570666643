import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, HStack, Image, Input, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SlzCustomTag } from 'components/common/SlzCustomTag';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';

import { CategoryOptions, IMAGE_ICON_URL } from 'components/common/Constants';
import { ImageUpload } from 'components/common/Icons';
import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';
import { NestedFieldContainer } from 'components/common/SlzForms/base/NestedField';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';

import { SlzButton } from 'components/common/SlzButton';
import { getAllItemCustomTags } from 'modules/products/services/customTags-api';

const uploadAvatarForm = (
  <VStack
    mb={2}
    px={7}
    py={4}
    sx={{
      background: 'light.300',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: 'dark.300',
      borderRadius: 3,
      w: 200,
      h: 200
    }}
    textAlign="center">
    <Text fontSize="lg" mb={0} fontWeight="bold">
      Product Image
    </Text>
    <ImageUpload color="dark.500" />
    <Text fontSize="sm" color="gray" mb={3}>
      Drag and drop your product image here or
    </Text>
    <SlzButton>Browse</SlzButton>
  </VStack>
);

const AddProductOverview = (props) => {
  const { setColorTag } = props;
  const { control, register, setValue, getValues } = useFormContext();

  const [imgURL, setImgURL] = useState(getValues('imageUrl'));
  const [customTags, setCustomTags] = useState([]);

  const onLoadFile = (file, reader) => {
    setImgURL(URL.createObjectURL(file));
    setValue('imageUrl', file);
  };

  const obtainCustomTags = async () => {
    const dataCustomTags = await getAllItemCustomTags();
    setCustomTags(dataCustomTags);
  };

  useEffect(() => {
    obtainCustomTags();
  }, []);

  return (
    <>
      <HStack alignItems="flex-start" spacing={6}>
        <VStack flex={3} alignItems="flex-start">
          <SlzUpload
            uploadForm={
              imgURL ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    w: 200,
                    maxW: 200,
                    h: 200,
                    borderRadius: 3,
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'dark.300',
                    overflow: 'hidden'
                  }}>
                  <Image src={imgURL} w="full" />
                </Flex>
              ) : (
                uploadAvatarForm
              )
            }
            onLoadFile={onLoadFile}
            acceptType="image/*"
            sx={{ mb: imgURL ? 0 : -3 }}
          />
          <Input {...register('imageUrl')} hidden />
          <HStack mt={-0.5} alignContent="center" alignItems="center">
            <Image
              src={IMAGE_ICON_URL}
              sx={{
                borderRadius: 1.5,
                background: 'main.500',
                color: 'light.700',
                p: 1.25,
                h: 6,
                w: 6
              }}
            />
            <Text fontSize="xs" w={40}>
              PNG, JPEG, JPG
            </Text>
          </HStack>
        </VStack>
        <VStack textAlign="left" flex={5}>
          <NestedInput
            label="Name"
            field="name"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{
              bgColor: 'light.300'
            }}
          />
          <NestedInput
            label="Brand"
            field="brand"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{
              bgColor: 'light.300'
            }}
          />

          <NestedDropdown
            label="Category"
            field="category"
            labelProps={{ color: 'dark.700' }}
            fieldProps={{
              options: CategoryOptions,
              chakraStyles: {
                ...SELECT_STYLES,
                container: (provided) => ({
                  ...provided,
                  '> div': {
                    background: 'light.300'
                  }
                })
              }
            }}
          />
        </VStack>
        <VStack flex={5}>
          <NestedFieldContainer label="Custom Tag" field="tag" labelProps={{ color: 'dark.700' }}>
            <HStack alignItems="center">
              <SlzCustomTag
                width={'100%'}
                field="tag"
                setColorTag={setColorTag}
                w="12.563rem"
                height="1.875rem"
                sx={{
                  background: 'light.300'
                }}
                items={customTags}
              />
              <InfoOutlineIcon boxSize="3" color="main.500" />
            </HStack>
          </NestedFieldContainer>
        </VStack>
        <VStack textAlign="left" flex={1}></VStack>
      </HStack>
    </>
  );
};

export default AddProductOverview;
