import { Box, Checkbox, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import SlzCheckBoxGroup from 'components/common/SlzCheckbox/SlzCheckBoxGroup';
import { SlzRadio } from 'components/common/SlzRadio';
import SlzRadioGroup from 'components/common/SlzRadio/SlzRadioGroup';
import { SlzToggle } from 'components/common/SlzToggle';
import { case1, case2, case3, case4, case5 } from './data';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker/index';
import { dayjs } from 'utils';
import { ENDDAY, STARTDAY } from 'constants';

const RenderCheckBox = () => {
  return (
    <div>
      <Text fontSize="md" fontWeight="bold">
        Checkboxes
      </Text>
      <Box>
        <Checkbox mr={'12px'}>Default</Checkbox>
        <Checkbox mr={'12px'} defaultChecked>
          Selected
        </Checkbox>
        <Checkbox mr={'12px'}>Hover</Checkbox>
        <Checkbox defaultChecked>Selected</Checkbox>
      </Box>

      <Box>
        <Checkbox mr={'12px'} isIndeterminate>
          undetermined
        </Checkbox>
        <Checkbox mr={'12px'} defaultChecked isReadOnly>
          Disabled Selected
        </Checkbox>
        <Checkbox mr={'12px'} isReadOnly>
          Disabled
        </Checkbox>
        <Checkbox isInvalid>Error</Checkbox>
      </Box>
    </div>
  );
};

const RenderRadio = () => {
  return (
    <>
      <Text fontSize="md" fontWeight="bold">
        Radio Buttons
      </Text>
      <SlzRadio mr={'12px'}>Default</SlzRadio>
      <SlzRadio mr={'12px'}>Hover</SlzRadio>
      <SlzRadio mr={'12px'} isReadOnly>
        Disabled
      </SlzRadio>
      <SlzRadio mr={'12px'} defaultChecked>
        Selected / Default Selected
      </SlzRadio>
      <SlzRadio mr={'12px'} defaultChecked isReadOnly>
        Disabled Selected
      </SlzRadio>
      <SlzRadio mr={'12px'} isInvalid>
        Error
      </SlzRadio>
      <SlzRadio mr={'12px'} isNoSelection={true} isReadOnly>
        Disable no selection
      </SlzRadio>
      <SlzRadio isIndeterminate>Indeteminated</SlzRadio>
    </>
  );
};

const RenderCheckboxGroup = () => {
  const checkboxData = [
    { id: 0, name: 'Lam Nguyen', selected: true },
    { id: 1, name: 'Hieu Nguyen', selected: false },
    { id: 2, name: 'Hang Nguyen', selected: false }
  ];
  const handleOnchange = (isSelected, id) => {
    console.log(isSelected, id);
  };
  return (
    <VStack>
      <Text fontSize="md" fontWeight="bold">
        Checkboxes List
      </Text>
      <SlzCheckBoxGroup
        data={checkboxData}
        onValueChange={handleOnchange}
        error={'Error display'}
      />
    </VStack>
  );
};

const RenderRadioGroup = () => {
  const radioOptions = [
    { id: 0, name: 'James Nguyen', value: 'A' },
    { id: 1, name: 'Eliot Nguyen', value: 'B' },
    { id: 2, name: 'Anna Nguyen', value: 'C' }
  ];
  const onValueChange = (value) => {
    console.log(value);
  };

  return (
    <SlzRadioGroup
      data={radioOptions}
      value="A"
      onValueChange={onValueChange}
      error={'Error display'}
    />
  );
};

const Toggles = () => {
  return (
    <Box spacing={5}>
      <Text fontSize="md" fontWeight="bold" mb={5}>
        Toggles
      </Text>
      <SlzToggle id="isChecked" value={true}>
        On
      </SlzToggle>
      <SlzToggle id="isChecked" value={false}>
        Off
      </SlzToggle>
      <SlzToggle id="isChecked" value={true} isReadOnly>
        Disable On
      </SlzToggle>
      <SlzToggle id="isChecked" value={false} isReadOnly>
        Disable Off
      </SlzToggle>
      <SlzToggle id="isChecked" error={'Display Error'} isInvalid>
        Error
      </SlzToggle>
    </Box>
  );
};

const RadioButtonLogic = (props) => {
  const { options } = props;
  const [isOnToggle, setIsOnToggle] = useState(props?.isOntoggle || false);
  const [data, setData] = useState(options);
  const [isDisableToggle, setIsDiableTogle] = useState(props.isDisableToggle);
  const [children, setChildren] = useState(props.children);
  useEffect(() => {
    const objs = data.map((value, index) => {
      return {
        ...value,
        isReadOnly: isOnToggle ? isDisableToggle : true,
        isNoSelection: isOnToggle ? isDisableToggle : true
      };
    });
    setData(objs);
  }, [isOnToggle]);

  const handleOnchange = (value) => {
    setIsOnToggle(value);
    setChildren(value ? 'On' : 'Off');
  };

  const onValueChange = (value) => {
    console.log(value);
  };

  return (
    <Box>
      <SlzToggle
        value={isOnToggle}
        onChange={(event) => handleOnchange(event.target.checked)}
        isReadOnly={isDisableToggle}>
        {children}
      </SlzToggle>
      <SlzRadioGroup value="A" data={data} onValueChange={onValueChange} />
    </Box>
  );
};

const Selection = () => {
  return (
    <>
      <Text fontSize="2xl" fontWeight="bold">
        Selection
      </Text>
      <HStack my={5} spacing={10}>
        <RenderCheckBox />
        <RenderCheckboxGroup />
        <Toggles />
      </HStack>
      <Box my={5}>
        <RenderRadio />
        <RenderRadioGroup />
      </Box>
      <Box>
        <Text fontSize="2xl" fontWeight="bold">
          Toggle and Radio buttons conditions
        </Text>
        <HStack spacing={10}>
          <RadioButtonLogic options={case1} isOntoggle>
            On
          </RadioButtonLogic>
          <RadioButtonLogic options={case2} isOntoggle={false}>
            Off
          </RadioButtonLogic>
          <RadioButtonLogic options={case3} isOntoggle={false}>
            Off
          </RadioButtonLogic>
          <RadioButtonLogic options={case4} isOntoggle={false} isDisableToggle>
            Off (disable)
          </RadioButtonLogic>
          <RadioButtonLogic options={case5} isOntoggle={true} isDisableToggle>
            On (disable)
          </RadioButtonLogic>
        </HStack>
      </Box>
      <Box my={5}>
        <Text fontSize="2xl" fontWeight="bold">
          Date Pickers
        </Text>
        <DatePickers />
      </Box>
    </>
  );
};

const DatePickers = () => {
  const handleSelectedDateRange = (startDate, endDate) => {
    console.log(startDate);
    console.log(endDate);
  };

  return (
    <SlzDateRangePicker
      isShowFuture={false}
      onDateRangeClick={handleSelectedDateRange}
      startDatePlaceHolder={STARTDAY}
      endDatePlaceHolder={ENDDAY}
      startDate={null}
      endDate={null}
    />
  );
};

export default Selection;
