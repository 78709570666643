import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormLabel from './FormLabel';
import { SlzButton } from 'components/common/SlzButton';
import { SlzCustomTag } from 'components/common/SlzCustomTag';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzTextarea } from 'components/common/SlzTextarea';

import { getAllItemCustomTags } from 'modules/products/services/customTags-api';

const validationSchema = yup.object({
  name: yup.string().required('Required'),
  skuCode: yup.string().required('Required')
});

export default function BundleDetailsForm({ defaultValues, onSubmit, onCancel }) {

  const [customTags, setCustomTags] = useState([]);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });
  const errors = methods.formState.errors;

  const obtainCustomTags = async () => {
    const dataCustomTags = await getAllItemCustomTags();
    setCustomTags(dataCustomTags);
  }

  const setColorTag = (selectedColorTag) => {
    console.log('selectedColorTag', selectedColorTag)
  }

  useEffect(() => {
    obtainCustomTags()
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Flex gap="12px" mb={10}>
          <FormControl flexBasis="59.5%" isInvalid={errors.name}>
            <FormLabel>Bundle name</FormLabel>
            <SlzInput
              placeholder="Bundle name"
              {...methods.register('name')}
              status={errors.name && 'error'}
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex gap="12px" mb={10}>
          <FormControl flexBasis="39.2%" isInvalid={errors.skuCode}>
            <FormLabel>Bundle SKU</FormLabel>
            <SlzInput
              placeholder="Bundle SKU"
              {...methods.register('skuCode')}
              status={errors.skuCode && 'error'}
            />
            <FormErrorMessage>{errors?.skuCode?.message}</FormErrorMessage>
          </FormControl>

          <FormControl flexBasis="18.9%" isInvalid={errors.price}>
            <FormLabel>Price</FormLabel>
            <SlzInput
              type="number"
              min="0"
              step="0.01"
              textAlign="right"
              leftAddonContent={'£'}
              {...methods.register('price')}
            />
          </FormControl>

          <FormControl flexBasis="38.9%" isInvalid={errors.bundleTag}>
            <FormLabel>Bundle tag</FormLabel>
            <SlzCustomTag
              size="lg"
              field="tag"
              height="3rem"
              setColorTag={setColorTag}
              sx={{
                background: 'light.300'
              }}
              items={customTags}
              {...methods.register('bundleTag')}
            />
          </FormControl>
        </Flex>

        <Flex gap="12px" mb={10}>
          <FormControl isInvalid={errors.description}>
            <FormLabel>Description</FormLabel>
            <SlzTextarea
              resize="none"
              height="11.913rem"
              rows="5"
              placeholder="Description"
              {...methods.register('description')}
            />
          </FormControl>
        </Flex>

        <Flex mb={5} mt={10} justifyContent="space-between">
          <SlzButton variant="outline" size="md" colorScheme="negative" onClick={onCancel}>
            Cancel
          </SlzButton>
          <SlzButton type="submit" size="md">
            Next
          </SlzButton>
        </Flex>
      </form>
    </FormProvider>
  );
}
