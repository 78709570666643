import { Icon } from '@chakra-ui/react';

const PalletIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="89"
      viewBox="0 0 79 89"
      fill="currentColor"
      {...props}>
      <g id="Group_34145" data-name="Group 34145" transform="translate(-530 -619)">
        <g id="Group_33453" data-name="Group 33453" transform="translate(537.678 619)">
          <path
            id="Rectangle_184"
            data-name="Rectangle 184"
            d="M6.167-1H20.5a7.175,7.175,0,0,1,7.167,7.167V20.5A7.175,7.175,0,0,1,20.5,27.667H6.167A7.175,7.175,0,0,1-1,20.5V6.167A7.175,7.175,0,0,1,6.167-1ZM20.5,20.5V6.167H6.162l0,14.334Z"
            transform="translate(1 1)"
          />
          <path
            id="Rectangle_185"
            data-name="Rectangle 185"
            d="M6.167-1H20.5a7.175,7.175,0,0,1,7.167,7.167V20.5A7.175,7.175,0,0,1,20.5,27.667H6.167A7.175,7.175,0,0,1-1,20.5V6.167A7.175,7.175,0,0,1,6.167-1ZM20.5,20.5V6.167H6.162l0,14.334Z"
            transform="translate(36.834 1)"
          />
          <path
            id="Rectangle_186"
            data-name="Rectangle 186"
            d="M6.167-1H20.5a7.175,7.175,0,0,1,7.167,7.167V20.5A7.175,7.175,0,0,1,20.5,27.667H6.167A7.175,7.175,0,0,1-1,20.5V6.167A7.175,7.175,0,0,1,6.167-1ZM20.5,20.5V6.167H6.162l0,14.334Z"
            transform="translate(1 36.835)"
          />
          <path
            id="Rectangle_187"
            data-name="Rectangle 187"
            d="M6.167-1H20.5a7.175,7.175,0,0,1,7.167,7.167V20.5A7.175,7.175,0,0,1,20.5,27.667H6.167A7.175,7.175,0,0,1-1,20.5V6.167A7.175,7.175,0,0,1,6.167-1ZM20.5,20.5V6.167H6.162l0,14.334Z"
            transform="translate(36.834 36.835)"
          />
        </g>
        <rect
          id="Rectangle_7047"
          data-name="Rectangle 7047"
          width="79"
          height="11"
          rx="3"
          transform="translate(530 686)"
        />
        <path
          id="Rectangle_7048"
          data-name="Rectangle 7048"
          d="M0,0H10a0,0,0,0,1,0,0V8a3,3,0,0,1-3,3H3A3,3,0,0,1,0,8V0A0,0,0,0,1,0,0Z"
          transform="translate(536 697)"
        />
        <path
          id="Rectangle_7049"
          data-name="Rectangle 7049"
          d="M0,0H9A0,0,0,0,1,9,0V8a3,3,0,0,1-3,3H3A3,3,0,0,1,0,8V0A0,0,0,0,1,0,0Z"
          transform="translate(565 697)"
        />
        <path
          id="Rectangle_7050"
          data-name="Rectangle 7050"
          d="M0,0H10a0,0,0,0,1,0,0V8a3,3,0,0,1-3,3H3A3,3,0,0,1,0,8V0A0,0,0,0,1,0,0Z"
          transform="translate(594 697)"
        />
      </g>
    </Icon>
  );
};

export default PalletIcon;
