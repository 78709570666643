import React from 'react';
import { Icon } from '@chakra-ui/react';

const NoImageIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="41.603"
      height="41.603"
      viewBox="0 0 41.603 41.603"
      {...props}>
      <g id="Group_34710" data-name="Group 34710" transform="translate(-794.172 -528.172)">
        <path
          id="Path_10167"
          data-name="Path 10167"
          d="M13.578,4.577A1.991,1.991,0,0,1,14.982,4H26.964a2,2,0,0,1,2,2,3.994,3.994,0,0,0,3.994,3.994h2a3.994,3.994,0,0,1,3.994,3.994V29.961m-2.37,5.648a4.009,4.009,0,0,1-1.624.343H6.994A3.994,3.994,0,0,1,3,31.958V13.985A3.994,3.994,0,0,1,6.994,9.991h2a4,4,0,0,0,1.666-.361"
          transform="translate(794 528.997)"
          fill="none"
          stroke="#c3c4cb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <path
          id="Path_10168"
          data-name="Path 10168"
          d="M11.838,10.448a5.991,5.991,0,1,0,8.288,8.184"
          transform="translate(799.984 535.426)"
          fill="none"
          stroke="#c3c4cb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
        <path
          id="Path_10169"
          data-name="Path 10169"
          d="M3,3,38.946,38.946"
          transform="translate(794 528)"
          fill="none"
          stroke="#c3c4cb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
        />
      </g>
    </Icon>
  );
};

export default NoImageIcon;
