import uniqueId from 'lodash/uniqueId';
import { List, ListIcon as ChakraListIcon, ListItem, Text, VStack } from '@chakra-ui/react';
import { PASSWORD_CRITERIA_STATUS, PASSWORD_VALIDATION_ICONS } from 'modules/users/constants';

const ListIcon = ({ status }) => {
  const sizeOfIcon = status === PASSWORD_CRITERIA_STATUS.init ? '0.375rem' : '0.625rem';

  return (
    <ChakraListIcon
      as={PASSWORD_VALIDATION_ICONS[status]}
      w={sizeOfIcon}
      h={sizeOfIcon}
      me="0.375rem"
    />
  );
};
const PasswordCriteria = ({ criteriaStatus, ...rest }) => {
  return (
    <VStack align="flex-start" bg="main.300" borderRadius="0.375rem" p={6} spacing={0} {...rest}>
      <Text fontSize="0.875rem" color="dark.700" fontWeight="bold">
        Absolute musts
      </Text>
      <List spacing={0}>
        {criteriaStatus &&
          Object.values(criteriaStatus).map((criterion) => {
            return (
              <ListItem
                key={uniqueId()}
                display="flex"
                alignItems="center"
                fontSize="0.875rem"
                color={criterion.status === PASSWORD_CRITERIA_STATUS.pass ? 'main.500' : 'dark.700'}
                lineHeight="1.188rem">
                <ListIcon status={criterion.status} />
                {criterion.text}
              </ListItem>
            );
          })}
      </List>
    </VStack>
  );
};

export default PasswordCriteria;
