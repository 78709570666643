export const dummyDetailedCollectionData = [
  {
    invoiceDate: '02/02/2023',
    invoiceReference: 'INV1014279',
    invoiceType: 'Stock holding',
    amount: 532.34
  },
  {
    invoiceDate: '01/02/2023',
    invoiceReference: 'INV1014280',
    invoiceType: 'Orders',
    amount: 1000
  },
  {
    invoiceDate: '02/03/2023',
    invoiceReference: 'INV1014282',
    invoiceType: 'Stock replenishment',
    amount: 1022.54
  },
  {
    invoiceDate: '05/03/2023',
    invoiceReference: 'INV1014279',
    invoiceType: 'Stock consignment processing',
    amount: 254
  },
  {
    invoiceDate: '13/01/2023',
    invoiceReference: 'INV1014287',
    invoiceType: 'Orders',
    amount: 156
  },
  {
    invoiceDate: '06/01/2023',
    invoiceReference: 'INV1014292',
    invoiceType: 'Stock replenishment',
    amount: 542
  }
]

export const dummyDetailedRemittanceData = [
  {
    invoiceDate: '02/02/2023',
    invoiceReference: 'INV1014279',
    invoiceType: 'Stock holding',
    amount: 532.34
  },
  {
    invoiceDate: '01/02/2023',
    invoiceReference: 'INV1014280',
    invoiceType: 'Orders',
    amount: 1000
  },
  {
    invoiceDate: '02/03/2023',
    invoiceReference: 'INV1014282',
    invoiceType: 'Stock replenishment',
    amount: 1022.54
  },
  {
    invoiceDate: '05/03/2023',
    invoiceReference: 'INV1014279',
    invoiceType: 'Stock consignment processing',
    amount: 254
  },
  {
    invoiceDate: '13/01/2023',
    invoiceReference: 'INV1014287',
    invoiceType: 'Orders',
    amount: 156
  },
  {
    invoiceDate: '06/01/2023',
    invoiceReference: 'INV1014292',
    invoiceType: 'Stock replenishment',
    amount: 542
  }
]
