import { useQuery } from 'react-query';
import { getUsers } from '../services/users-api';

export const useUsersQuery = ({ filters, enabled }) => {
  return useQuery({
    queryKey: [`get-users`, filters],
    queryFn: () => getUsers(filters),
    enabled
  });
};
