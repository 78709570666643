import { GridItem } from '@chakra-ui/react';
import {
  USER_PREFERENCES_SETTING_FOOTER_TYPE_MAP,
  USER_PREFERENCES_SETTING_MAP
} from 'modules/preferences/constants';
import { Fragment } from 'react';
import { UserSettingFooter, UserSettingHeader, UserSettingSideNav } from '../other';

const UserSettingBody = ({ activatedSetting, ...rest }) => {
  const UserSettingContent = USER_PREFERENCES_SETTING_MAP[activatedSetting] || Fragment;
  return <UserSettingContent activatedSetting={activatedSetting} {...rest} />;
};

const DefaultModalLayout = (props) => {
  const {
    activateSetting,
    onClose,
    onItemClick,
    requiredSettings,
    isRequiredSettingsFetching,
    isRequiredSettingsEmpty,
    updateRequiredSettings,
    onSave,
    ...rest
  } = props;

  return (
    <Fragment>
      <GridItem gridColumn="2/12" mb="5" pt="6">
        <UserSettingHeader text={activateSetting?.text} />
      </GridItem>
      <GridItem gridColumn="1/5" pl={8}>
        <UserSettingSideNav
          settingType={activateSetting?.type}
          requiredSettings={requiredSettings}
          isRequiredSettingsEmpty={isRequiredSettingsEmpty}
          onItemClick={onItemClick}
        />
      </GridItem>
      <GridItem gridColumn="5/12">
        <UserSettingBody
          requiredSettings={requiredSettings}
          isRequiredSettingsFetching={isRequiredSettingsFetching}
          isRequiredSettingsEmpty={isRequiredSettingsEmpty}
          updateRequiredSettings={updateRequiredSettings}
          onItemClick={onItemClick}
          activatedSetting={activateSetting?.type}
          {...rest}
        />
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <UserSettingFooter
          footerType={USER_PREFERENCES_SETTING_FOOTER_TYPE_MAP[activateSetting?.type]}
          onClose={onClose}
          onSave={onSave}
        />
      </GridItem>
    </Fragment>
  );
};
export default DefaultModalLayout;
