import { HStack, Heading } from '@chakra-ui/react';
import Tooltip from '../form/Tooltip';

const ApiConnectionHeading = ({ text, showTooltip, tooltipContent, ...rest }) => {
  return (
    <HStack mb="1.438rem">
      <Heading fontSize="custom.xl" lineHeight="tall" fontWeight="medium" {...rest}>
        {text}
      </Heading>
      {showTooltip && <Tooltip placement="right" trigger="hover" content={tooltipContent} />}
    </HStack>
  );
};

export default ApiConnectionHeading;
