import { Box, Text } from '@chakra-ui/react';

const WrapForm = ({ children, label, ...rest }) => {
  return (
    <Box
      w="full"
      p="23px"
      pt="10px"
      {...rest}
      sx={{
        border: '1px solid #C3C4CB',
        bg: '#FFFFFF 0% 0% no-repeat padding-box;',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '12px'
      }}>
      <Text
        color="dark.700"
        fontWeight="bold"
        sx={{ textAlign: 'left', fontSize: '32px', lineHeight: '43px', letterSpacing: '0px' }}>
        {label}
      </Text>
      {children}
    </Box>
  );
};

export default WrapForm;
