import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { random, sample } from 'lodash';
import { CheckCircleIcon } from '../../components/common/Icons';
import SlzButton from '../../components/common/SlzButton/SlzButton';
import { SlzChip } from '../../components/common/SlzChip';
import { COL_CUSTOM, DEFAULT_IMAGE } from '../../components/common/SlzTable/Constants';
import { getHeightOfAccordionCell, getHeightOfCell } from '../../components/common/SlzTable/Styles';
import { DATE_FORMAT_DDMMYYYY } from '../../constants';
import memoize from 'lodash/memoize';

const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const PRODUCTS_ACTIONS = [
  {
    id: 1,
    label: 'Product Overview',
    onClick: (e, row) => {
      console.log(row);
    }
  },
  {
    id: 2,
    label: 'Sales & pricing',
    onClick: () => {}
  },
  {
    id: 3,
    label: 'Packaging preferences',
    onClick: () => {}
  },
  {
    id: 4,
    label: 'Return information',
    onClick: () => {}
  }
];

const tags = ['easy', 'hard', 'difficult', 'finished'];

const tagColors = {
  easy: '#bde0fe',
  hard: '#e9c46a',
  difficult: '#e9edc9',
  finished: '#e63946'
};

export const getTags = () => {
  const tag = sample(tags);
  return {
    name: tag,
    color: tagColors[tag]
  };
};

export const fake = (numItems = 50) => {
  const data = [];
  const images = [
    'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    'https://image.shutterstock.com/image-vector/white-mens-tshirt-realistic-mock-260nw-705901483.jpg',
    'https://image.shutterstock.com/image-photo/golf-club-ball-grass-260nw-158881226.jpg'
  ];
  const names = ['Greens', 'Apple', 'Samsung', 'Vinfast'];

  let i = 0;
  while (i <= numItems) {
    data.push({
      id: `[products]-${uuidv4()}`,
      category: 'test-category',
      imageUrl: sample(images),
      name: sample(names),
      sku: 'SKU' + uuidv4(),
      tag: getTags(),
      suid: uuidv4(),
      shelfStock: random(1, 500),
      palletStock: random(1, 500),
      totalStock: random(1, 500),
      description:
        'Fusce dolor quam, elementum at, egestas a, scelerisque sed, sapien. Nunc pulvinar',
      ean: 'MHX64NSS2OM',
      selazarUniqueID: 'SSS-RCL78HMF8OG',
      status: 'LOW',
      createdDate: sample(['10/28/2022', '10/20/2022']),
      isPrePacked: true,
      depth: 56.1,
      width: 68.1,
      height: 66.1,
      weight: 65.1,
      commodityCode: '686378',
      countryCodeOfManufacture: 'US',
      brand: 'Elit Erat Industries',
      currentPrice: 42.32,
      units: 12,
      requiresBoxPackaging: false,
      requiresPaperPackaging: false,
      isFragile: false
    });
    i++;
  }
  return data;
};

export const categoryCols = [
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true
  },
  {
    name: 'SKU',
    field: 'sku',
    isSorted: true
  },
  {
    name: 'EAN/SUID',
    field: 'suid',
    isSorted: true
  },
  {
    field: 'tag',
    name: 'Tag',
    isEmphasize: true
  },
  {
    isSorted: true,
    field: 'totalStock',
    name: 'Stock',
    type: 'number'
  }
];

/**
 * Convert data from Form to match with data product table
 * @param {any} value The number to raise.
 */
export const convertProduct = (value) => {
  return {
    tag: getTags(),
    ...value,
    categoryId: '', // Find category by name
    isFragile: convertStringToBoolean(value.isFragile),
    isPrePacked: convertStringToBoolean(value.isPrePacked),
    requiresBoxPackaging: convertStringToBoolean(value.requiresBoxPackaging),
    requiresPaperPackaging: convertStringToBoolean(value.requiresPaperPackaging),
    // seed data
    sku: 'SKU' + uuidv4(),
    id: `[products]-${uuidv4()}`,
    // tag: getTags(),
    suid: uuidv4(),
    totalStock: random(1, 500),
    createdDate: dayjs().format(DATE_FORMAT_DDMMYYYY)
  };
};

const convertStringToBoolean = (strValue) => {
  switch (String(strValue)?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
    case undefined:
      return false;
    default:
      return JSON.parse(strValue);
  }
};

export const orderInformation = [
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  },
  {
    id: `[products]-${uuidv4()}`,
    imageUrl:
      'https://image.shutterstock.com/image-photo/set-different-cute-toys-on-260nw-1849897198.jpg',
    name: 'SKU 1246790876XL',
    suid: 'SEL-8DA244C1E524FFB',
    wareHouse: 'Selazar Nuneaton',
    quantity: '2',
    weight: '0.5kg',
    price: '€12'
  }
];
export const orderInformationCols = [
  {
    field: 'name',
    cellHeader: (row) => row.name,
    cellValue: (row) => row.selazarUniqueID
  },
  {
    header: 'EAN/SUID',
    field: 'suid',
    type: 'string'
  },
  {
    header: 'Ware House',
    field: 'wareHouse',
    type: 'string'
  },
  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number'
  },
  {
    field: 'price',
    header: 'Price',
    type: 'string'
  },
  {
    field: 'weight',
    header: 'Weight',
    type: 'number'
  }
];

export const mockProductTag = memoize((productId) => {
  const tag = sample(tags);
  return {
    name: tag,
    color: tagColors[tag]
  };
});

export const mockBundleFilter = () => {
  // TODO: Further work need this object to validate in future the filter
  // return bundleTags.map((tag) => {
  //   const filterOption = {
  //     key: tag.key,
  //     text: tag.name,
  //     bg: tag.color
  //   };
  //   return filterOption;
  // });
};
export const PRODUCT_SELECTION_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 54,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfAccordionCell('order'),
            px: '3px',
            py: 0,
            height: '48px',
            width: '54px'
          }}>
          <Image
            sx={rowProps?.accordionProps?.imageStyles}
            w="48px"
            h="48px"
            objectFit="cover"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Product',
    field: 'name',
    isSorted: true,
    width: 250,
    header: { align: 'left', paddingLeft: '18px' },
    render: ({ row }) => (
      <Box
        id={`product-selection-${row?.id}`}
        className={`${row?.isSelected ? 'selected' : 'unselected'}-product`}
        sx={{
          paddingLeft: '18px',
          '.wrapName': { alignItems: 'start', justifyContent: 'start' },
          '.innerName': { alignItems: 'start' }
        }}>
        <HStack className="wrapName" pos="relative" w="full">
          <VStack
            className="innerName"
            fontSize="10px"
            lineHeight="13px"
            whiteSpace="nowrap"
            overflow={'hidden'}
            maxW={'15.625rem'}
            spacing={'1px'}
            gap={0}
            alignItems="start">
            <Text sx={{ color: '#0b73ea' }} mb={0}>
              {row?.name}
            </Text>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: '5px' }} noOfLines={1}>
                SKU
              </Text>
              &nbsp;
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.sku}
              </Text>
            </Flex>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: '5px' }} noOfLines={1}>
                EAN/SUID
              </Text>
              &nbsp;
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.ean || row?.selazarUniqueID}
              </Text>
            </Flex>
          </VStack>
          {row?.isSelected && (
            <CheckCircleIcon
              width="15px"
              height="15px"
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                color: '#0073ea'
              }}
            />
          )}
        </HStack>
      </Box>
    )
  },
  {
    name: 'Tag',
    field: 'tag',
    width: 72,
    isSorted: true,
    isEmphasis: true,
    render: ({ row }) => (
      <Flex alignItems="center" justifyContent="center" bg={row?.tag?.color}>
        <Text sx={{ fontSize: '10px', lineHeight: '13px', mb: 0 }}>{row?.tag?.name}</Text>
      </Flex>
    )
  }
];

export const YOUR_BUNDLE_SELECTED_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 51,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfAccordionCell(variant),
            px: '0',
            py: 0
          }}>
          <Image
            sx={rowProps?.accordionProps?.imageStyles}
            w="48px"
            h="48px"
            objectFit="cover"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Your bundle',
    field: 'name',
    isSorted: true,
    width: 250,
    header: { align: 'left', paddingLeft: '18px' },
    render: ({ row }) => (
      <Box
        id={`product-selection-${row?.id}`}
        sx={{
          paddingLeft: '18px',
          '.wrapName': { alignItems: 'start', justifyContent: 'start' },
          '.innerName': { alignItems: 'start' }
        }}>
        <HStack className="wrapName" pos="relative" w="full">
          <VStack
            className="innerName"
            fontSize="10px"
            lineHeight="13px"
            whiteSpace="nowrap"
            maxW={'15.625rem'}
            overflow={'hidden'}
            spacing={'1px'}
            gap={0}
            alignItems="start">
            <Text sx={{ color: '#0b73ea' }} mb={0}>
              {row?.name}
            </Text>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: '5px' }} noOfLines={1}>
                SKU
              </Text>
              &nbsp;
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.sku || row?.skuCode}
              </Text>
            </Flex>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: '5px' }} noOfLines={1}>
                EAN/SUID
              </Text>
              &nbsp;
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.ean || row?.selazarUniqueID}
              </Text>
            </Flex>
          </VStack>
        </HStack>
      </Box>
    )
  }
];

const filterProductSelectionOptions = [
  {
    key: 'hard',
    text: 'Hard',
    colorScheme: 'szrElectricBlue'
  },
  {
    key: 'easy',
    text: 'Easy',
    colorScheme: 'ibiza'
  },
  {
    key: 'difficult',
    text: 'Difficult',
    colorScheme: 'californiaOrange'
  },
  {
    key: 'finished',
    text: 'Finished',
    colorScheme: 'tetra'
  }
];

export const filtersProductSelection = {
  isLocal: true,
  title: 'Tags',
  byField: 'tag',
  options: filterProductSelectionOptions
};

export const quantityOptions = [
  {
    value: 'remove',
    label: 'Remove'
  },
  // {
  //   value: 1,
  //   label: 1
  // },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  },
  {
    value: 5,
    label: 5
  },
  {
    value: 6,
    label: 6
  },
  {
    value: 7,
    label: 7
  },
  {
    value: 8,
    label: 8
  },
  {
    value: 9,
    label: 9
  },
  {
    value: 10,
    label: 10
  }
];
