export const RETURN_DETAIL_PRODUCT_INFOR_STYLES = {
  container: (isShowHeader) => {
    const height = isShowHeader ? 64 : '14.25rem';
    return {
      w: 'full',
      cursor: 'pointer',
      bg: 'light.500',
      h: height,
      borderRadius: 1.5
    };
  },

  selectedProduct: {
    border: '1px solid',
    borderColor: 'main.500'
  },
  header: {
    px: 6,
    w: 'full',
    h: 12,
    alignItems: 'center',
    spacing: '0.313rem',
    borderBottom: '3px solid',
    borderColor: 'light.300'
  },
  image: (isShowHeader) => {
    const py = isShowHeader ? 0 : '1.125rem';
    return {
      px: 6,
      py,
      w: 'full',
      alignItems: 'flex-start',
      spacing: '0.313rem'
    };
  }
};

export const RETURN_DETAIL_EVIDENCE_STYLE = {
  h: 12,
  px: 6,
  spacing: 0,
  borderRadius: 1.5,
  mb: '0.188rem',
  bg: 'light.700',
  alignItems: 'flex-start',
  justifyContent: 'center'
};

export const RETURN_DETAIL_OVERVIEW_STYLE = {
  h: 'full',
  px: 6,
  alignItems: 'center',
  justifyContent: 'space-between',
  borderLeftWidth: '0.75rem',
  borderRadius: 1.5
};

export const RETURN_DETAIL_ORDER_INFOR_STYLE = {
  headerInfor: {
    alignItems: 'center',
    fontSize: 'lg',
    fontWeight: 'bold',
    lineHeight: 5.5,
    h: 12,
    w: 'full',
    px: 6,
    borderBottom: '3px solid',
    borderColor: 'light.300'
  },
  detailInfor: {
    alignItems: 'flex-start',
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: 5,
    w: 'full',
    px: 6,
    py: 3,
    spacing: 5
  }
};
