import React from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FormControl, FormErrorMessage, HStack } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzToolTip from 'components/common/SlzToolTip';
import { FORM_MESSAGES } from 'modules/products/constants';

/**
 * 
 * Link ref to yup validate: https://github.com/jquense/yup
 * List of validation rules supported:
    required
    min
    max
    minLength
    maxLength
    pattern
    validate
*/

const NestedField = React.forwardRef(
  (
    {
      children,
      label,
      field,
      validation = {},
      control,
      register,
      formState: { errors },
      fieldProps = {},
      labelProps = {},
      formControlProps = {},
      isControlled = false,
      showTooltip = true,
      useTooltipLabel = false,
      tooltipContent = null,
      disabledErrors = false,
      SlzComponent = null,
      ...rest
    },
    ref
  ) => {
    const propsComponent = {
      name: field,
      ...fieldProps
    };
    const rendererField = () => {
      if (!SlzComponent) {
        return children;
      }

      return (
        <HStack {...rest}>
          {isControlled ? (
            <Controller
              name={field}
              control={control}
              render={({ field }) => {
                return <SlzComponent {...field} {...propsComponent} ref={ref} />;
              }}
            />
          ) : (
            <SlzComponent {...propsComponent} {...register(field)} ref={ref} />
          )}
          {showTooltip && !useTooltipLabel && <TooltipField tooltipContent={tooltipContent} />}
        </HStack>
      );
    };
    return (
      <FormControl isInvalid={errors[field]} {...formControlProps}>
        <HStack spacing={1} alignItems="center">
          <SlzFormLabel {...labelProps} mr={showTooltip && useTooltipLabel ? 0 : ''}>
            {label}
          </SlzFormLabel>
          {showTooltip && useTooltipLabel && <TooltipField tooltipContent={tooltipContent} />}
        </HStack>
        {rendererField()}
        {errors[field] && !disabledErrors && (
          <FormErrorMessage>{errors[field]?.message ? errors[field]?.message : Object.keys(errors[field])[0].length > 0 ? errors[field][Object.keys(errors[field])[0]]?.message : FORM_MESSAGES.invalid}</FormErrorMessage>
        )}
      </FormControl>
    );
  }
);

export const TooltipField = React.memo(({ tooltipContent }) => (
  <SlzToolTip content={tooltipContent} placement="top-start" variant="blue">
    <InfoOutlineIcon boxSize="3" color="main.500" />
  </SlzToolTip>
));

export const NestedFieldContainer = (props) => {
  const methods = useFormContext();
  return <NestedField {...methods} {...props} />;
};
