import {
  chakra,
  forwardRef,
  layoutPropNames,
  omitThemingProps,
  useMultiStyleConfig
} from '@chakra-ui/system';
import { callAll } from '@chakra-ui/shared-utils';
import { split } from '@chakra-ui/object-utils';
import { useRadio, useRadioGroupContext } from '@chakra-ui/react';
import classNames from 'classnames';
const MotionSvg = chakra(motion.svg);
import { motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const IndeterminateIcon = (props) => {
  const { iconStyles, ...rest } = props;
  return (
    <MotionSvg
      pointerEvents={'none'}
      sx={iconStyles}
      width="1.2em"
      viewBox="0 0 24 24"
      variants={{
        unchecked: {
          scaleX: 0.65,
          opacity: 0
        },
        checked: {
          scaleX: 1,
          opacity: 1,
          transition: {
            scaleX: { duration: 0 },
            opacity: { duration: 0.02 }
          }
        }
      }}
      style={{ stroke: 'currentColor', strokeWidth: 4 }}
      {...rest}>
      <line x1="21" x2="3" y1="12" y2="12" />
    </MotionSvg>
  );
};
export const SlzRadio = forwardRef((props, ref) => {
  const group = useRadioGroupContext();
  const { onChange: onChangeProp, value: valueProp, ...restProps } = props;
  const styles = useMultiStyleConfig('SlzRadio', { ...group, ...props });
  const ownProps = omitThemingProps(props);
  const {
    spacing = '0.5rem',
    children,
    isFocusable = group?.isFocusable,
    inputProps: htmlInputProps,
    isNoSelection,
    isIndeterminate,
    isDisabled = group?.isDisabled || isNoSelection,
    ...rest
  } = ownProps;

  let isChecked = props.isChecked;
  if (group?.value != null && valueProp != null) {
    isChecked = group.value === valueProp;
  }

  let onChange = onChangeProp;
  if (group?.onChange && valueProp != null) {
    onChange = callAll(group.onChange, onChangeProp);
  }

  const name = props?.name ?? group?.name;

  const { getInputProps, getCheckboxProps, getLabelProps, getRootProps, htmlProps } = useRadio({
    ...rest,
    isChecked,
    isDisabled,
    isFocusable,
    onChange,
    name
  });

  const [layoutProps, otherProps] = split(htmlProps, layoutPropNames);

  const checkboxProps = getCheckboxProps(otherProps);
  const inputProps = getInputProps(htmlInputProps, ref);
  const labelProps = getLabelProps();
  const rootProps = Object.assign({}, layoutProps, getRootProps());
  const rootStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'top',
    cursor: 'pointer',
    position: 'relative',
    ...styles.container
  };
  const checkboxStyles = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    ...styles.control
  };

  const classNameControl = classNames({
    'chakra-radio__control': true,
    'disable-no-selected': isNoSelection
  });

  const classNameLabel = classNames({
    'chakra-radio__label': true,
    'disable-no-selected': isNoSelection
  });
  return (
    <chakra.label
      className="chakra-radio"
      {...rootProps}
      __css={rootStyles}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Radio`)}>
      <input className="chakra-radio__input" {...inputProps} />
      {isIndeterminate ? (
        <IndeterminateIcon iconStyles={styles.icon} />
      ) : (
        <chakra.span className={classNameControl} {...checkboxProps} __css={checkboxStyles} />
      )}
      {children && (
        <chakra.span
          className={classNameLabel}
          {...labelProps}
          __css={styles.label}
          style={{ opacity: 1 }}>
          {children}
        </chakra.span>
      )}
    </chakra.label>
  );
});

SlzRadio.displayName = 'SlzRadio';
