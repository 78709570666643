import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PublicLayout, PublicLayoutHeader } from 'components/layout';
import { RegisterForm } from '../components/form';
import {
  MEDIA_LINKS,
  MESSAGE,
  REGISTER_RESULT_PATHNAME,
  REGISTRATION_PASSWORD_IMAGE_URL_SET,
  TEXT_OVERLAY
} from '../constants';
import { useRegisterRetailer } from '../hooks';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

const RegisterPage = () => {
  const register = useRegisterRetailer();
  const history = useHistory();
  const routerQuery = useRouterQuery();
  const error = register.isError
    ? {
        title: MESSAGE.LOGIN.ERROR.TITLE,
        description: register.error.response?.data?.message
      }
    : null;

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      title: values.title.value
    };
    register.mutate(payload);
  };

  useEffect(() => {
    if (register.isSuccess) {
      routerQuery.set('userId', register.data?.data?.data?.id)
      history.push(REGISTER_RESULT_PATHNAME + '?' + routerQuery.toString());
    }
  }, [register.isSuccess]);

  return (
    <PublicLayout
      imageSrc={REGISTRATION_PASSWORD_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={MEDIA_LINKS}
      sx={{ alignItems: 'start' }}>
      <div>
        <PublicLayoutHeader title="Register with us" error={error} />
        <RegisterForm onSubmit={handleSubmit} />
      </div>
    </PublicLayout>
  );
};

export default RegisterPage;
