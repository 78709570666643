import { Flex, Image, Text } from '@chakra-ui/react';
import BundleIcon from 'components/common/Icons/BundleIcon';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';

export const BUNDLE_COLS = [
  {
    name: '',
    field: 'image',
    width: 63,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            pl: '3px',
            py: 0
          }}>
          <BundleIcon w="60px" h="58px" />
        </Flex>
      );
    },
    isDisplayed: false
  },
  {
    name: 'Name',
    field: 'name',
    isSorted: true,
    isHyperLink: true,
    isDisplayed: true
  },
  {
    name: 'SKU',
    field: 'skuCode',
    isSorted: true,
    isDisplayed: true
  },
  {
    name: 'Bundle tag',
    field: 'bundleTag',
    isSorted: true,
    isEmphasis: true,
    isDisplayed: true
  },
  {
    name: 'Stock',
    field: 'bundleStockTotal',
    isSorted: true,
    type: 'number',
    isDisplayed: false
  }
];

export const BUNDLE_NESTED_COLS = [
  {
    name: '',
    field: 'image',
    width: 48,
    type: COL_CUSTOM,
    render: ({ row, variant, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell('order'),
            px: '0',
            py: 0
          }}>
          <Image
            w="full"
            h="full"
            sx={rowProps?.accordionProps?.imageStyles}
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    field: 'name',
    isHyperLink: true,
    render: ({ row }) => (
      <Text color="main.500" whiteSpace="normal" fontSize="0.875rem">
        {row?.name}
      </Text>
    )
  },
  {
    field: 'skuCode',
    cellHeader: () => 'SKU',
    cellValue: (row) => row?.skuCode || ''
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    type: 'string',
    cellHeader: () => 'EAN/SUID',
    cellValue: (row) => row['ean'] || row['selazarUniqueID']
  },

  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number'
  },
  {
    field: 'weight',
    header: 'Weight',
    type: 'number',
    cellValue: (row) => `${row?.weight} kg`
  }
];
