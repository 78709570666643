import React, { useEffect, useState } from 'react';
import { Image, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

import {
  RESET_PASSWORD_IMAGE_URL_SET,
  PREVIOUS_PAGE,
  SUCCESS_PASSWORD_PATHNAME,
  CONTACT_INFOS,
  TEXT_OVERLAY,
  FORGOT_PASSWORD_TEXT
} from 'modules/users/constants';
import { ResetPasswordForm } from 'modules/users/components/form';
import { CompanyInfo, CompanyReference, UserFormHeading } from 'modules/users/components/other';
import { resetPassword } from '../services/users-api';
import { usePasswordResetRequestQuery } from '../hooks';
import { PublicLayout } from 'components/layout';

const ResetPassword = () => {
  const { id: passwordResetRequestId } = useParams();
  const [timer, setTimer] = useState(null);
  const history = useHistory();

  const {
    data: passwordResetRequest,
    isError,
    isLoading
  } = usePasswordResetRequestQuery(passwordResetRequestId);

  const handleResetPassword = async (data) => {
    const payload = {
      operatorUserID: passwordResetRequestId,
      userID: passwordResetRequest?.userID,
      password: data.password,
      confirmPassword: data.confirmPassword
    };
    try {
      const response = await resetPassword(payload);
      history.push(SUCCESS_PASSWORD_PATHNAME);
      //TODO redirect to success page.
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isError) {
      //TODO handle error
      console.log('Password Reset Request could not be found');
    }
  }, [isError]);

  return (
    <PublicLayout
      imageSrc={RESET_PASSWORD_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={null}
      sx={{ alignItems: 'start', paddingTop: 0 }}>
      <Image src="Images/selazar-login.svg" mb={5} mt="19.26vh" />
      <UserFormHeading text={FORGOT_PASSWORD_TEXT.passwordReset} />
      <Text fontSize="lg" color="dark.700" mb={6} lineHeight={5.5}>
        Please enter you new password
      </Text>

      {!isLoading && <ResetPasswordForm onSubmit={handleResetPassword} />}
      <CompanyReference
        title={PREVIOUS_PAGE.FORGOT_PASSWORD?.title}
        href={PREVIOUS_PAGE.FORGOT_PASSWORD.href}
        sx={{ marginBottom: '2.125rem' }}
      />
      <CompanyInfo email={CONTACT_INFOS.email} telephone={CONTACT_INFOS.telephone} />
    </PublicLayout>
  );
};

export default ResetPassword;
