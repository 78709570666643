import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
  Flex,
  VStack
} from '@chakra-ui/react';
import { useStyleConfig } from '@chakra-ui/react';
import { IoClose } from 'react-icons/io5';
import { ToastInfoIcon, ToastCheckIcon, ToastTriangleIcon } from '../Icons/ToastIcons';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';
import './SlzToast.scss';

const DEFAULT_WIDTH = '28.313rem';
const ICONS_MAPPING_COMPONENT = {
  info: <ToastInfoIcon data-testid="toast-info-icon" w={6} h={6} />,
  success: <ToastCheckIcon data-testid="toast-success-icon" w={6} h={6} />,
  warning: <ToastTriangleIcon data-testid="toast-warning-icon" w={6} h={6} />
};

const ToastContent = ({ title, description, styles, render }) => {
  if (render) return render();
  return (
    <VStack align="flex-start" spacing={0}>
      {title && (
        <AlertTitle data-testid="toast-title" sx={styles.title}>
          {title}
        </AlertTitle>
      )}
      <AlertDescription data-testid="toast-des" sx={styles.description}>
        {description}
      </AlertDescription>
    </VStack>
  );
};

const ToastIcon = ({ status, styles }) => {
  return (
    <AlertIcon data-testid="toast-icon" sx={styles}>
      {ICONS_MAPPING_COMPONENT[status]}
    </AlertIcon>
  );
};

const SlzToast = (props) => {
  const { size, title, description, variant, status, onClose, colorScheme } = props;
  const styles = useStyleConfig('SlzToast', { variant, size, colorScheme });

  return (
    <Alert
      data-testid="toast-wrap"
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Toast`)}
      sx={styles.container}
      size={size}
      variant={variant}
      colorScheme={colorScheme}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="slz-lg"
      w={props?.w || DEFAULT_WIDTH}>
      <Flex align="center">
        <ToastIcon status={status} styles={{ ...styles.icon, ...props?.iconStyle }} />
        <ToastContent
          title={title}
          description={description}
          styles={styles}
          render={props?.render}
        />
      </Flex>

      <CloseButton
        data-testid="toast-close-btn"
        sx={{ ...styles.button, ...props?.closeButtonStyle }}
        onClick={onClose}>
        <IoClose size="1.413rem" />
      </CloseButton>
    </Alert>
  );
};

export default SlzToast;
