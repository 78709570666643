export const STATUS_CELL_STYLE = {
  '.emphasized-cell': {
    px: 0,
    div: {
      p: {
        fontWeight: '500'
      }
    }
  }
};
