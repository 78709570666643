import { CONSIGNMENT_ARRIVING_TYPES, CONSIGNMENT_LOCATION, INVALID_STOCKBOX_SIZE, MAX_WEIGHT } from 'modules/consignments/constants';
import * as yup from 'yup';

export const consignmentDetailsSchema = yup.object().shape({
  stockBoxQuantity: yup.number().typeError('Please provide quantity').required('Please provide quantity'),
  itemsPerStockbox: yup.number().typeError('Please provide items per box').required('Please provide items per box'),
  totalQuantityItems: yup.string().required('Please complete both fields').test('is-na', 'N/A', function (value) {
    if (value === 'Please complete both fields') return this.createError({ message: 'Please complete both fields', path: 'totalQuantityItems', type: 'required' });
    if (value === 'N/A') return this.createError({ message: 'N/A', path: 'totalQuantityItems' });
    return true;
  }),

  stockBoxDimensions: yup.object()
    .shape({
      height: yup.number().typeError('Required').required('Required'),
      width: yup.number().typeError('Required').required('Required'),
      depth: yup.number().typeError('Required').required('Required'),
      weight: yup
        .number()
        .typeError('Stockbox weight is required')
        .max(MAX_WEIGHT, 'Must be below 2kg')
        .required('Stockbox weight is required'),
    }).when('consignmentArrivingType', {
      is: (val) => val === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX || val === undefined,
      then: yup.object().test(INVALID_STOCKBOX_SIZE, 'PFP', function (value) {

        const { height, width, depth, weight } = value;

        const MAX_HEIGHT = 200;
        const MAX_WIDTH = 60;
        const MAX_DEPTH = 65;
        const MAX_WEIGHT = 70;

        if (height > MAX_HEIGHT || width > MAX_WIDTH || depth > MAX_DEPTH || weight > MAX_WEIGHT) return this.createError({ message: 'PFP', path: 'stockBoxDimensions' });

        return true;
      }),
    }),

    gtin: yup.string().when('gtinSwitch', {
      is: true,
      then: yup.string().min(8, 'Please enter a GTIN of minimum 8 characters').required('Please enter a GTIN of minimum 8 characters')
    })
});
