import { Fragment } from 'react';
import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { DATE_FORMAT_DDMMYYYY_HH_MM_SS } from 'constants';
import { toLocalTimeString } from 'Utilities';
import { getCostBreakdowns } from 'modules/returns/utils';

const Title = ({ children, ...rest }) => {
  return (
    <Text fontSize="1rem" lineHeight="1.3125rem" fontWeight="medium" {...rest}>
      {children}
    </Text>
  );
};

const ContentRow = ({ title, value, children, ...rest }) => (
  <HStack
    bg="main.300"
    px={3}
    py={1}
    justifyContent="space-between"
    alignItems="flex-start"
    borderRadius="0.375rem"
    {...rest}>
    <Title color="main.500">{title}</Title>
    <Text fontSize="1rem" lineHeight="1.3125rem" maxW="12.75rem" textAlign="right">
      {value}
    </Text>
  </HStack>
);

const OrderDetails = ({ data = {} }) => {
  const {
    customerReference,
    internalReference,
    received,
    customerOrganisationPersonName,
    customerAddressLine1,
    customerAddressLine2,
    customerAddressTown,
    customerAddressCounty,
    customerAddressPostcode
  } = data;

  return (
    <VStack alignItems="stretch" w="20.1875rem" spacing={3} mb={5}>
      <Title mb={-2}>Order Details</Title>
      <ContentRow title="OrderID" value={customerReference} />
      <ContentRow title="Internal ref" value={internalReference} />
      <ContentRow
        title="Received "
        value={dayjs(toLocalTimeString(received)).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS)}
      />
      <ContentRow title="Name" value={customerOrganisationPersonName} />
      <ContentRow
        title="Delivery Address"
        spacing={0}
        value={
          <Box textAlign="left" minW="11.5rem">
            <Text>{customerAddressLine1}</Text>
            <Text>{customerAddressLine2}</Text>
            <Text>{customerAddressTown}</Text>
            <Text>{customerAddressCounty}</Text>
            <Text>{customerAddressPostcode}</Text>
          </Box>
        }
        minH="8.625rem"
      />
    </VStack>
  );
};

const CostDetails = ({ data = {} }) => {
  const { orderCostBreakdown = {} } = data;
  const breakDowns = getCostBreakdowns(orderCostBreakdown);
  return (
    <VStack alignItems="stretch" w="20.1875rem" spacing={3}>
      <Title mb={-2}>Cost Details</Title>
      <ContentRow title="Total" value={`£${orderCostBreakdown.total?.toFixed(2)}`} />

      <VStack bg="main.300" px={3} py={1} alignItems="stretch" borderRadius="0.375rem" spacing={1}>
        <Title color="main.500">Breakdown</Title>
        {breakDowns.map((item) => (
          <HStack justifyContent="space-between" spacing={0} key={item.label}>
            <Text fontSize="0.875rem">{`${item.label}:`}</Text>
            <Text fontSize="0.875rem" textAlign="right">{`£${item.value}`}</Text>
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

const ProductDetails = ({ data = {} }) => {
  const { orderContents = {} } = data;
  const { orderedCompanyItems = [], orderedProductBundles = [] } = orderContents;
  return (
    <VStack alignItems="stretch" flex={1} spacing={3}>
      <Title mb={-2}>Product Details</Title>
      <TableContainer borderRadius="1.5" overflow="unset" overflowY="unset">
        <Table variant="returnProduct" w="full" borderRadius="1.5">
          <Thead rounded="md">
            <Tr>
              <Th>Product ID</Th>
              <Th>SKU</Th>
              <Th>Count</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderedCompanyItems.map((product) => (
              <Tr key={product.companyItem?.itemID}>
                <Td>{product.companyItem?.item?.name}</Td>
                <Td>{product.skuCode}</Td>
                <Td>{product.quantity}</Td>
              </Tr>
            ))}

            {orderedProductBundles.map((bundle) => (
              <Tr key={bundle.skuCode}>
                <Td>{bundle.productBundle?.name}</Td>
                <Td>{bundle.skuCode}</Td>
                <Td>{bundle.quantity * (bundle?.productBundle.totalProducts ?? 1)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};

const OrderDetailInformation = ({ data }) => {
  return (
    <Fragment>
      <HStack alignItems="flex-start" spacing={3}>
        <Box>
          <OrderDetails data={data} />
          <CostDetails data={data} />
        </Box>
        <ProductDetails data={data} />
      </HStack>
    </Fragment>
  );
};
export default OrderDetailInformation;
