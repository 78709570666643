import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { calc, cssVar, getColor } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, calc.subtract($height, '0.073rem'));
const $translateX = cssVar('switch-thumb-x');
const basePseudo = (theme) => ({
  content: '""',
  w: '0',
  h: '0',
  pos: 'absolute',
  left: '20%',
  top: '40%',
  transition: 'all 0.3s ease-in'
});

const baseStyleTrack = defineStyle((props) => {
  const { theme } = props;
  return {
    display: 'flex',
    alignItems: 'center',
    bg: getColor(theme, 'dark.500'),
    borderRadius: 'full',
    p: '0.5',
    width: [$width.reference],
    height: [$height.reference],
    transitionProperty: 'common',
    transitionDuration: 'ultraSlow',
    opacity: 1,
    _focusVisible: {
      boxShadow: 'none'
    },
    _before: {
      ...basePseudo(theme)
    },
    _checked: {
      bg: getColor(theme, 'main.500'),
      _before: {
        bg: getColor(theme, 'main.500'),
        w: '2.62rem',
        h: '100%',
        top: 0,
        left: 0,
        borderRadius: 'full'
      },
      _readOnly: {
        opacity: 1,
        cursor: 'not-allowed',
        bg: getColor(theme, `main.300`),
        _before: {
          bg: getColor(theme, `main.300`),
          w: '2.62rem',
          h: '100%',
          top: 0,
          left: 0,
          borderRadius: 'full'
        },
      }
    },
    _readOnly: {
      opacity: 1,
      cursor: 'not-allowed',
      bg: getColor(theme, `light.700`),
      _checked: {
        bg: getColor(theme, `main.300`),
      }
    },
    _invalid: {
      bg: getColor(theme, `primary.negative`)
    }
  };
});

const baseStyleThumb = defineStyle((props) => {
  const { theme } = props;
  return {
    bg: getColor(theme, 'white'),
    borderRadius: 'inherit',
    transitionProperty: 'transform',
    transitionDuration: 'slow',
    w: '1.125rem',
    height: '1.125rem'
  };
});
const baseErrorStyles = defineStyle({
  transitionProperty: 'transform',
  transitionDuration: 'slow',
  color: `primary.negative`,
  fontSize: '1rem'
});
const baseLabelStyles = defineStyle({
  ml: '0.75rem',
  transitionProperty: 'transform',
  transitionDuration: 'slow'
});
const baseStyle = definePartsStyle((props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString()
    },
    display: 'inline-flex',
    alignItems: 'center'
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props),
  error: baseErrorStyles,
  label: baseLabelStyles
}));

const sizes = {
  sm: definePartsStyle({
    container: {
      [$width.variable]: '1.375rem',
      [$height.variable]: 'sizes.3'
    }
  }),
  md: definePartsStyle({
    container: {
      [$width.variable]: '1.875rem',
      [$height.variable]: 'sizes.4'
    }
  }),
  lg: definePartsStyle({
    container: {
      [$width.variable]: '2.365rem',
      [$height.variable]: 'sizes.5'
    }
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
    colorScheme: 'main'
  }
});
