import { HStack, Text } from '@chakra-ui/react';

import { SlzTag } from 'components/common/SlzTag';
import { PaginatedTooltipContent } from 'components/common/SlzToolTip/templates';

import { B2B_BOX_SPLIT_TOOLTIP_CONTENT } from 'modules/b2b-orders/constants';
import { B2B_ORDER_OPTION_STYLE } from 'modules/b2b-orders/constants/styles';
import { Tooltip } from 'modules/b2b-orders/components/tooltip';

const OrderOptionTag = ({ tag }) => (
  <SlzTag {...B2B_ORDER_OPTION_STYLE.tag.wrapper}>
    <Tooltip
      placement="top"
      trigger="hover"
      content={<PaginatedTooltipContent tooltipContent={B2B_BOX_SPLIT_TOOLTIP_CONTENT} />}>
      <HStack maxHeight="1rem !important">
        {tag.content.icon}
        <Text {...B2B_ORDER_OPTION_STYLE.tag.content}>{tag.content.text}</Text>
      </HStack>
    </Tooltip>
  </SlzTag>
);

export default OrderOptionTag;
