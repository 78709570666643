import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

const parts = ['overlay', 'content', 'body', 'closeButton'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(({ theme }) => ({
  overlay: {
    bgColor: 'rgba(48, 49, 52, 0.75)'
  },
  content: {
    rounded: 3,
    boxShadow: '0 3px 16px #00000065'
  },
  body: {
    rounded: 'inherit',
    minH: 20,
    px: 12,
    py: 6
  },
  closeButton: {
    color: getColor(theme, 'dark.500'),
    top: 4,
    right: 4,
    _focus: {
      outline: 'none'
    },
    _focusVisible: { boxShadow: 'none' },
    _hover: {
      bgColor: 'unset'
    },
    _active: {
      bgColor: 'unset'
    }
  },
  iconDelete: {
    w: 5,
    h: 5,
    marginRight: '1.35rem',
    color: 'primary.negative'
  },
  button: {
    cancel: {
      color: 'lightAndShadow.mainText',
      border: '1px solid #DE4259',
      background: 'lightAndShadow.background',
      maxW: '5.875rem',
      borderRadius: 5,
      fontSize: '16px',
      _hover: {
        bgColor: 'unset'
      },
      _active: {
        bgColor: 'unset'
      }
    },
    delete: {
      color: 'lightAndShadow.background',
      background: 'primary.negative',
      maxW: '5.875rem',
      borderRadius: 5,
      fontSize: '16px',
      _hover: {
        bgColor: 'primary.negative'
      },
      _active: {
        bgColor: 'primary.negative'
      }
    }
  }
}));

const variants = {
  standard: definePartsStyle((props) => {
    return {
      body: {
        border: 'none'
      }
    };
  }),
  autoWidth: definePartsStyle((props) => {
    return {
      body: {
        border: 'none'
      },
      content: {
        maxW: 'fit-content'
      }
    };
  }),
  order: definePartsStyle((props) => {
    return {
      body: {
        pl: '5.25rem',
        pr: '5.25rem'
      },
      content: {
        maxW: 'fit-content'
      }
    };
  }),
  product: definePartsStyle((props) => {
    const { accentColor, theme } = props;
    return {
      body: {
        pl: 6,
        borderLeft: '3rem solid',
        borderColor: getColor(theme, accentColor)
      },
      content: {
        rounded: '0.75rem'
      }
    };
  }),
  deleteModal: definePartsStyle((props) => {
    return {
      content: {
        border: '1px solid #d36b79',
        position: 'fixed',
        bottom: 0
      },
      closeButton: {
        width: '1.375rem',
        height: '1.375rem',
        opacity: 1,
        background: '#f4cbd3',
        color: 'primary.negative',
        marginTop: '1.25rem'
      }
    };
  }),
  userSetting: definePartsStyle((props) => {
    return {
      content: {
        rounded: '0.75rem'
      }
    };
  }),
  addCSVUpload: definePartsStyle(() => {
    return {
      content: {
        w: '34.188rem',
        minH: '31.366rem'
      }
    };
  }),
  integrations: definePartsStyle(() => {
    return {
      content: {
        mt: '6.875rem'
      }
    };
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'standard',
    accentColor: 'main.500'
  }
});
