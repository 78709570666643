import { memo } from 'react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import SlzToolTip from 'components/common/SlzToolTip';

const DEFAULT_CHILDREN = ({ disabled }) => (
  <InfoOutlineIcon boxSize="3" color={disabled ? 'dark.300' : 'main.500'} />
);

const Tooltip = memo(
  ({ content, disabled, children = DEFAULT_CHILDREN({ disabled }), ...rest }) => {
    return (
      <SlzToolTip content={content} arrowSize={34} {...rest} variant="userSetting">
        {children}
      </SlzToolTip>
    );
  }
);

export default Tooltip;
