import { APPEND_ADDRESS_BOOK_LIST, SET_QUERY_PARAM, GET_ADDRESS_BOOK_LIST } from '../actions';
import { INITIAL_ADDRESS_BOOK_STATE } from '../constants';

export const addressBookReducer = (state = INITIAL_ADDRESS_BOOK_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_ADDRESS_BOOK_LIST: {
      return {
        ...state,
        list: payload?.addresses
      };
    }
    case APPEND_ADDRESS_BOOK_LIST: {
      return {
        ...state,
        list: [...state.list, ...payload?.addresses]
      };
    }
    case SET_QUERY_PARAM: {
      return {
        ...state,
        params: payload?.params
      };
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};
