import React, { useEffect, useReducer, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';

import { SKELETON_TYPE } from 'components/common/Constants';
import SlzSkeleton from 'components/common/SlzSkeleton/SlzSkeleton';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import SlzTable from 'components/common/SlzTable/Table';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';
import {
  ERROR_TYPE,
  MAX_FILE_SIZE,
  PRODUCT_IMAGES_REQUIRED,
  UPLOAD_ERROR,
  paginationConfig,
  productImagesRequiredTableFilters,
  productImagesRequiredTableStyle
} from 'modules/preferences/constants';
import { uploadSingleImage } from 'modules/preferences/services/preferences-api';
import { PRODUCT_IMAGES_REQUIRED_COLUMNS } from 'modules/preferences/components/table/preferences.columns';
import useSlzToast from 'hooks/useSlzToast';
import { UploadError, UploadImageForm } from 'modules/preferences/components/other';
import { updateProductsReducers } from 'modules/preferences/reducer';
import { UPDATE_PRODUCTS_ACTIONS } from 'modules/preferences/actions';

const ProductImagesRequiredTable = ({
  productImagesRequired,
  isFetching,
  currentPage,
  setCurrentPage,
  refetch
}) => {
  const [productImagesRequiredData, setProductImagesRequiredData] = useReducer(
    updateProductsReducers,
    []
  );
  const timeRef = useRef();

  const inputRefs = useRef([]);

  const [_, setToast] = useSlzToast();

  const addToRefs = (el, productItemId) => {
    inputRefs.current[productItemId] = el;
  };

  const handleOpenConfirmation = (productId) => {
    setProductImagesRequiredData({
      type: UPDATE_PRODUCTS_ACTIONS.OPEN_CONFIRM_MODAL,
      productId
    });
  };

  const handleCancelConfirmation = (productId) => {
    setProductImagesRequiredData({
      type: UPDATE_PRODUCTS_ACTIONS.CANCEL_CONFIRM_MODAL,
      productId
    });
  };

  const handleCloseConfirmation = () => {
    setProductImagesRequiredData({
      type: UPDATE_PRODUCTS_ACTIONS.CLOSE_CONFIRM_MODAL
    });
  };

  const onLoadFile = async (productId, files) => {
    try {
      if (files.size > MAX_FILE_SIZE) {
        setProductImagesRequiredData({
          type: UPDATE_PRODUCTS_ACTIONS.FILE_SIZE_ERROR,
          productId
        });
        return;
      }
      setProductImagesRequiredData({
        type: UPDATE_PRODUCTS_ACTIONS.UPLOADING_IMAGE,
        productId
      });
      await uploadSingleImage(productId, files);
      setProductImagesRequiredData({
        type: UPDATE_PRODUCTS_ACTIONS.UPLOAD_SUCCESS,
        productId
      });
      timeRef.current = setTimeout(() => {
        setToast({ title: PRODUCT_IMAGES_REQUIRED.UPLOAD_IMAGE_SUCCESS });
        refetch();
      }, [1000]);
    } catch (error) {
      console.log('Update image failed!', error);
    }
  };

  const onLoadFileError = (productId) => {
    setProductImagesRequiredData({
      type: UPDATE_PRODUCTS_ACTIONS.LOAD_FILE_ERROR,
      productId
    });
  };

  const handleTryAgainUpload = (productItemId) => {
    inputRefs.current[productItemId]?.querySelector('div > input')?.click();
  };

  let productImageRequiredCols = [...PRODUCT_IMAGES_REQUIRED_COLUMNS];
  productImageRequiredCols[0] = {
    name: '',
    field: 'image',
    width: 81,
    type: COL_CUSTOM,
    render: ({ row }) => {
      return (
        <Box h="100%" ref={(el) => addToRefs(el, row.itemID)}>
          <SlzUpload
            uploadForm={
              <UploadImageForm
                productId={row?.itemID}
                isUploading={row?.isUploading}
                isSucceed={row?.isSucceed}
              />
            }
            onLoadFile={(files) => onLoadFile(row?.itemID, files)}
            onLoadFileError={() => onLoadFileError(row?.itemID)}
            acceptType="image/*"
            h="100%"
            sx={{ display: `${row?.isError ? 'none' : 'unset'}` }}
          />
          {row?.isError && (
            <UploadError
              sx={{ display: `${!row?.isError && 'none'}` }}
              title={
                row?.errorType === ERROR_TYPE.LIMIT_EXCEEDED
                  ? UPLOAD_ERROR[ERROR_TYPE.LIMIT_EXCEEDED].title
                  : UPLOAD_ERROR[ERROR_TYPE.INCORRECT_IMAGE_FORMAT].title
              }
              description={
                row?.errorType === ERROR_TYPE.LIMIT_EXCEEDED
                  ? UPLOAD_ERROR[ERROR_TYPE.LIMIT_EXCEEDED].description
                  : UPLOAD_ERROR[ERROR_TYPE.INCORRECT_IMAGE_FORMAT].description
              }
              okText="Try again"
              isOpen={row?.isShowConfirmation}
              onOpen={() => handleOpenConfirmation(row.itemID)}
              onClose={() => handleCloseConfirmation(row.itemID)}
              onCancel={() => handleCancelConfirmation(row.itemID)}
              onOk={() => handleTryAgainUpload(row.itemID)}
            />
          )}
        </Box>
      );
    }
  };

  useEffect(() => {
    setProductImagesRequiredData({
      type: UPDATE_PRODUCTS_ACTIONS.REPLACE,
      products: productImagesRequired?.products
    });
  }, [productImagesRequired]);

  useEffect(() => {
    return () => {
      clearTimeout(timeRef);
      inputRefs.current = null;
    };
  }, []);

  return (
    <>
      {isFetching ? (
        <SlzSkeleton type={SKELETON_TYPE.PRODUCT_IMAGES_REQUIRED} />
      ) : (
        <SlzTable
          variant="productList"
          sizes={['sm', 'md', 'lg']}
          showMarked={true}
          showHeader={true}
          columns={productImageRequiredCols}
          data={productImagesRequiredData}
          displayFields={['image', 'name', 'skuCode', 'tag']}
          filters={productImagesRequiredTableFilters}
          sx={productImagesRequiredTableStyle}
          overflowX="unset"
          overflowY="scroll"
          pagination={{
            ...paginationConfig(productImagesRequired?.totalPage, currentPage, setCurrentPage)
          }}
        />
      )}
    </>
  );
};

export default ProductImagesRequiredTable;
