import { Heading, HStack, ModalHeader } from '@chakra-ui/react';
import SlzArrowLeftIcon from 'components/common/Icons/ArrowLeftIcon';
import { PRODUCTS_ADD_TYPES } from 'modules/products/constants';

const Header = ({ currentType, isUploaded, onBack }) => {
  return (
    <ModalHeader sx={{ ml: currentType ? -15 : 0 }}>
      <HStack
        onClick={() => onBack && onBack()}
        align="baseline"
        sx={{
          alignItems: 'center',
          justifyContent: currentType ? '' : 'center',
          cursor: currentType ? 'pointer' : ''
        }}>
        {currentType && <SlzArrowLeftIcon color="#686A73" w={8} h={8} />}
        <Heading fontSize="32px" style={{ marginLeft: 0, paddingLeft: 0 }}>
          {currentType === PRODUCTS_ADD_TYPES.UPLOAD && isUploaded ? 'Add images' : 'Add product'}
        </Heading>
      </HStack>
    </ModalHeader>
  );
};

export default Header;
