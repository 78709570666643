
import { React } from 'react';
import { Icon } from '@chakra-ui/react';
import { Container } from 'react-bootstrap';

const TemperatureControlled = (props) => (
    <Container maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1rem"
            h="1.125rem"
            viewBox="0 0 16 18"
            fill="gray"
            {...props}>
            <g id="Group_4579" data-name="Group 4579" clip-path="url(#clip-path)">
                <path id="Path_9806" data-name="Path 9806" d="M11.569,8.362a.819.819,0,0,1-.407-.108L8.708,6.847a.819.819,0,0,1-.411-.71V3.778L7.113,3.187a.818.818,0,1,1,.731-1.464l.452.226V.818a.818.818,0,1,1,1.636,0V1.949l.452-.226a.818.818,0,1,1,.731,1.464l-1.184.592V5.663l2.044,1.171a.818.818,0,0,1-.408,1.528" transform="translate(-1.211)" fill="gray" />
                <path id="Path_9807" data-name="Path 9807" d="M12.518,11.991a.818.818,0,0,1-.818-.816l-.009-2.829a.819.819,0,0,1,.409-.711l2.042-1.179-.079-1.321a.818.818,0,0,1,1.633-.1l.03.5.979-.565a.818.818,0,1,1,.818,1.417l-.979.565.421.278a.818.818,0,1,1-.9,1.366L14.96,7.874l-1.631.942.007,2.356a.818.818,0,0,1-.816.821Z" transform="translate(-2.126 -0.776)" fill="gray" />
                <path id="Path_9808" data-name="Path 9808" d="M14.338,16.644c-.016,0-.034,0-.05,0a.818.818,0,0,1-.767-.866l.079-1.321-1.631-.942L9.932,14.7a.818.818,0,0,1-.822-1.415l2.446-1.422a.819.819,0,0,1,.821,0l2.042,1.179,1.1-.729a.818.818,0,0,1,.9,1.366L16,13.953l.979.565a.818.818,0,1,1-.818,1.417l-.979-.565-.03.5a.818.818,0,0,1-.816.769" transform="translate(-1.582 -2.136)" fill="gray" />
                <path id="Path_9809" data-name="Path 9809" d="M8.934,20.142a.818.818,0,0,1-.818-.818V18.193l-.452.227a.819.819,0,0,1-.731-1.465l1.184-.592V14.479L6.072,13.308a.818.818,0,1,1,.815-1.42L9.341,13.3a.821.821,0,0,1,.411.71v2.358l1.184.592A.819.819,0,0,1,10.2,18.42l-.452-.227v1.131a.818.818,0,0,1-.818.818" transform="translate(-1.029 -2.142)" fill="gray" />
                <path id="Path_9810" data-name="Path 9810" d="M3.053,16.016a.818.818,0,0,1-.816-.769l-.03-.5-.979.565A.818.818,0,1,1,.41,13.89l.979-.565-.421-.278a.818.818,0,0,1,.9-1.366l1.1.729L4.6,11.468,4.6,9.113a.818.818,0,0,1,.816-.821h0a.819.819,0,0,1,.818.816l.008,2.829a.818.818,0,0,1-.409.711L3.791,13.827l.079,1.321a.818.818,0,0,1-.767.866c-.016,0-.034,0-.05,0" transform="translate(0 -1.508)" fill="gray" />
                <path id="Path_9811" data-name="Path 9811" d="M5.425,9.162a.812.812,0,0,1-.409-.11L2.973,7.874l-1.1.729a.818.818,0,0,1-.9-1.366l.421-.278L.41,6.393a.818.818,0,1,1,.818-1.417l.979.565.03-.5a.818.818,0,0,1,1.633.1L3.792,6.456,5.423,7.4,7.459,6.214a.818.818,0,1,1,.822,1.415L5.835,9.051a.809.809,0,0,1-.411.111" transform="translate(0 -0.776)" fill="gray" />
            </g>
        </Icon>
    </Container>
);
export default TemperatureControlled;