import React from 'react';
import { Card, Text, VStack, HStack, Box, FormControl, Checkbox } from '@chakra-ui/react';
import { TruckIcon } from '../assets';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NO_SHIPPING_REFERENCE_TEXT, SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';
import SlzInput from 'components/common/SlzInput/SlzInput';

const Shipping = ({
  shippingReference,
  enabledLabelling,
  productLabelsSelected,
  stockBoxLabelsSelected,
  handleEditStep
}) => {
  return (
    <>
      <Card
        w="full"
        bg="light.700"
        h="3rem"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem" display="flex" justifyContent="space-between">
          <Box
            w="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start">
            <TruckIcon marginRight="0.375rem" sx={{ width: '1.25rem', height: '100%' }} />

            <Box display="flex" alignItems="baseline">
              <Text
                marginRight="0.375rem"
                sx={{
                  fontSize: 'lg',
                  color: 'table.mainText',
                  fontWeight: 'bold'
                }}>
                Shipping & Labelling
              </Text>

              <Text onClick={() => handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.LABELLING)} fontSize="xs" cursor="pointer" color="main.500">
                Edit
              </Text>
            </Box>
          </Box>
        </HStack>
      </Card>
      <Card
        bg="light.300"
        border="1px solid"
        borderColor="light.700"
        w="full"
        px="1.5rem"
        py="1rem"
        variant="filled"
        flexGrow={1}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '0.2rem !important'
        }}>
        <VStack alignItems="flex-start">
          <Box w="full">
            <FormControl>
              <SlzFormLabel fontSize="sm" color="dark.700">
                Shipping reference
              </SlzFormLabel>
              <Box w="8.625rem">
                <SlzInput
                  fontSize="sm"
                  textAlign="left"
                  color="dark.700 !important"
                  size="sm"
                  bgColor="light.300"
                  value={shippingReference || NO_SHIPPING_REFERENCE_TEXT}
                  disabled
                />
              </Box>
            </FormControl>
          </Box>

          <Box w="full" sx={{ marginTop: '1.625rem !important' }}>
            <SlzFormLabel fontSize="sm" color="dark.700">
              Labels
            </SlzFormLabel>
            <Box
              w="10.5rem"
              h="1.5rem"
              rounded="0.188rem"
              bgColor={enabledLabelling ? 'positive.300' : 'main.300'}
              px="1.5rem"
              display="flex"
              alignItems="center">
              <Text w="full" color={enabledLabelling ? 'positive.500' : 'main.500'} fontSize="sm">
                {enabledLabelling ? 'Yes, affix labels for me' : 'Affixing my own labels'}
              </Text>
            </Box>
          </Box>
          {enabledLabelling && (
            <>
              <HStack>
                <Box
                  h="1.5rem"
                  rounded="0.188rem"
                  bgColor="main.300"
                  borderColor="main.500"
                  display="flex"
                  alignItems="center"
                  py="0.313rem"
                  px="0.75rem">
                  <Checkbox
                    id="Slz-checkbox-1"
                    alignItems="flex-end"
                    isDisabled
                    size="sm"
                    defaultChecked={stockBoxLabelsSelected}>
                    Stock box label(s)
                  </Checkbox>
                </Box>
                <Box
                  h="1.5rem"
                  rounded="0.188rem"
                  bgColor="main.300"
                  borderColor="main.500"
                  display="flex"
                  alignItems="center"
                  py="0.313rem"
                  px="0.75rem">
                  <Checkbox
                    id="Slz-checkbox-2"
                    alignItems="flex-end"
                    isDisabled
                    defaultChecked={productLabelsSelected}
                    size="sm">
                    Product label(s)
                  </Checkbox>
                </Box>
              </HStack>
              <VStack w="full" marginTop="1.5rem !important">
                <Card
                  px="0.75rem"
                  rounded="0.188rem"
                  bg="main.300"
                  w="full"
                  h="1.5rem"
                  variant="filled"
                  display="flex"
                  justifyContent="center">
                  <Text fontSize="sm" color="main.500">
                    Note
                  </Text>
                </Card>
                <Card
                  pt="0.688rem"
                  marginTop="0.188rem !important"
                  px="0.75rem"
                  rounded="0.188rem"
                  border="1px"
                  borderColor="light.700"
                  bg="lightAndShadow.darkModeText"
                  w="full"
                  h="4.625rem"
                  variant="filled"
                  display="flex"
                  justifyContent="flex-start">
                  <Text fontSize="sm">
                    Please be aware that by selecting this labelling service the consignment will
                    attract additional processing costs.
                  </Text>
                </Card>
              </VStack>
            </>
          )}
        </VStack>
      </Card>
    </>
  );
};

export default Shipping;
