import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle(({ theme }) => ({
  field: {
    width: '100%',
    minWidth: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _disabled: {
      opacity: 1,
      cursor: 'not-allowed',
      color: getColor(theme, 'dark.300'),
      bg: getColor(theme, 'light.500'),
      border: 'none'
    },
    _placeholder: {
      color: getColor(theme, 'dark.300'),
      opacity: 1
    }
  },
  addon: {
    transitionProperty: 'common',
    transitionDuration: 'normal'
  }
}));

const size = {
  lg: defineStyle({
    fontSize: 'lg',
    px: 4,
    h: '12',
    borderRadius: '0.375rem'
  }),
  md: defineStyle({
    fontSize: 'md',
    px: 3,
    h: '10',
    borderRadius: '0.375rem'
  }),
  sm: defineStyle({
    fontSize: 'md',
    px: 2.5,
    h: 7.5,
    borderRadius: '0.1875rem'
  }),
  xs: defineStyle({
    fontSize: 'sm',
    px: 2,
    height: 6,
    borderRadius: '0.1875rem'
  })
};

const sizes = {
  lg: definePartsStyle({
    field: size.lg,
    addon: size.lg
  }),
  md: definePartsStyle({
    field: size.md,
    addon: size.md
  }),
  sm: definePartsStyle({
    field: size.sm,
    addon: size.sm
  }),
  xs: definePartsStyle({
    field: size.xs,
    addon: size.xs
  })
};

const variantOutline = definePartsStyle((props) => {
  const { theme } = props;
  return {
    field: {
      border: '1px solid',
      borderColor: 'dark.300',
      bg: 'inherit',
      _hover: {
        borderColor: 'main.500'
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'main.500',
        boxShadow: 'none',
        color: 'inherit'
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
        color: 'dark.700'
      },
      _invalid: {
        borderColor: 'negative.500',
        boxShadow: 'none',
        bg: transparentize('negative.300', 0.33)(theme),
        color: 'negative.500',
        _focusVisible: {
          borderColor: 'negative.500'
        },
        _placeholder: {
          color: 'negative.500',
          opacity: 1
        }
      },
      _valid: {
        borderColor: 'positive.500!important',
        boxShadow: 'none',
        bg: transparentize('positive.300', 0.33)(theme)
      },
      '&[aria-valid="true"]': {
        borderColor: 'positive.500',
        boxShadow: 'none',
        color: 'positive.500',
        bg: transparentize('positive.300', 0.33)(theme)
      }
    },
    addon: {
      border: '1px solid',
      borderColor: 'dark.300',
      bgColor: 'light.700',
      color: 'dark.500',
      _invalid: {
        borderColor: 'negative.500'
      },
      '&[aria-valid="true"]': {
        borderColor: 'positive.500'
      }
    }
  };
});

const variants = {
  outline: variantOutline
};

const defaultProps = {
  size: 'lg',
  variant: 'outline'
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps
});
