import React from 'react';
import PropTypes from 'prop-types';
import { Tr, TBody } from './SlzTable';
import { cellMapper } from './SlzCell';
import { unitType } from '../../../utils';
import {
  NUMBER_OF_DAYS_IN_MONTH,
  NUMBER_OF_COLUMNS_IN_MONTH,
  NUMBER_OF_ROWS_IN_MONTH
} from '../../../constants';

export default class SlzCalendarBody extends React.Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    showISOWeekNumbers: PropTypes.bool.isRequired,
    showWeekNumbers: PropTypes.bool.isRequired,
    calendar: PropTypes.object.isRequired
  };
  renderCells = () => {
    const {
      startDate,
      endDate,
      range,
      minDate,
      maxDate,
      calendar,
      onDayClick,
      onDayMouseEnter,
      isShowFuture,
      disableChoosingPrev,
      position
    } = this.props;
    const startOfMonth = calendar.startOf('month');
    const startOfWeek = startOfMonth.startOf('isoWeek');
    const endOfMonth = calendar.endOf('month');
    const endOfWeek = endOfMonth.endOf('isoWeek');
    const data = [];
    let s = startOfWeek.clone();

    for (; s <= endOfWeek;) {
      data.push(s);
      s = s.add(1, 'day');
    }
    if (data.length < NUMBER_OF_DAYS_IN_MONTH) {
      let fill = data.length;
      for (; fill < NUMBER_OF_DAYS_IN_MONTH; fill++) {
        data.push(s);
        s = s.add(1, 'day');
      }
    }
    const resolvedData = [];
    for (let cxi = 0; cxi < NUMBER_OF_ROWS_IN_MONTH; cxi++) {
      const rows = [];
      const rxi = cxi * NUMBER_OF_COLUMNS_IN_MONTH;
      const initLength = rows.length;
      for (let cxj = 0; cxj < NUMBER_OF_COLUMNS_IN_MONTH; cxj++) {
        const key = rxi + cxj;
        const day = data[key];
        const props = {
          unitType: unitType.DAY,
          key: key + initLength,
          minDate,
          maxDate,
          day,
          calendar,
          startDate,
          range,
          endDate,
          onDayMouseEnter,
          onDayClick,
          isShowFuture,
          disableChoosingPrev,
          position
        };
        rows.push(props);
      }
      resolvedData.push(rows);
    }
    return resolvedData.map((rows, rowKey) => {
      const cell = rows.map((props) => {
        const Component = cellMapper[props.unitType];
        return <Component {...props} key={props.key} />;
      });
      return <Tr key={rowKey}>{cell}</Tr>;
    });
  };
  render() {
    return <TBody>{this.renderCells()}</TBody>;
  }
}
