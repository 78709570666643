import { DATE_FORMAT_DDMMYYYY, DATE_FORMAT_DDMMYYYY_HH_MM_SS } from '../../../constants/date';
import dayjs from 'dayjs';
import { map, uniqBy } from 'lodash';
import { CONSIGNMENT_STATUS_COLOR } from '../constants';

export const transformConsignmentDetail = (data) => {

  const hasProductsWithoutGtin = data.lines.some((product) => product.gtin === null);
  const hasProductsWithSelazarUniqueID = data.lines.some((product) => product.selazarUniqueID !== null);

  const consignmentInfoDetails = {
    id: data.id,
    fileName: data.fileName,
    createdDate: dayjs(data.createdDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS),
    status: {
      key: "status",
      name: data.status,
      bgColor: CONSIGNMENT_STATUS_COLOR[data.status],
      color: CONSIGNMENT_STATUS_COLOR[data.status]
    },
    pickFromPallet: data.pickFromPallet,
    hasProductsWithoutGtin,
    hasProductsWithSelazarUniqueID
  }

  const consignmentBreakDown = data.lines.map((product) => {
    return {
      name: product.name,
      sku: product.skuCode,
      ean: product.ean,
      imageUrl: null,
      quantity: product.stockCount,
      suid: product.selazarUniqueID,
      imageUrl: product.productImage
    }
  });

  const consignmentDetails = {
    consignmentBoxDimensions: {
      height: data.boxHeight,
      weight: data.boxWeight,
      width: data.boxWidth,
      depth: data.boxDepth,
    },
    isPallet: data.isPallet,
    totalItems: uniqBy(data?.lines, obj => `${obj.companyItemID}_${obj.skuCode}`).length
  }

  const shippingAndLabelling = {
    consignmentId: data.id,
    shippingReference: data.shippingReference,
    labelsAffixed: data.labelsAffixed,
    supplierPrintedLabels: data.supplierPrintedLabels,
    pickFromPallet: data.pickFromPallet,
    hasProductsWithoutGtin,
    hasProductsWithSelazarUniqueID,
    labelAffixing: data.supplierPrintedLabels,
  }

  const warehouseInformation = {
    name: data?.warehouseInformation?.name,
    addressLine2: data?.warehouseInformation?.address?.line2,
    addressLine1: data?.warehouseInformation?.address?.line1,
    country: data?.warehouseInformation?.address?.country,
    postCode: data?.warehouseInformation?.address?.postcode,
  }

  return {
    consignmentInfoDetails,
    consignmentBreakDown,
    consignmentDetails,
    shippingAndLabelling,
    warehouseInformation
  };
};
