import { Fragment } from 'react';

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzButton } from 'components/common/SlzButton';
import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import SlzLink from 'components/common/SlzLink/SlzLink';

const BulkOrderCostDetail = (props) => {
  const { data, isLoading, onClickTrackingDetail } = props;
  return (
    <SlzPaper
      w={props.w}
      h={props.h}
      minH="23.85rem"
      pb={8}
      borderRadius="0.375rem"
      bg="light.500"
      boxShadow="0px 3px 6px #00000029"
      overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <>
          <VStack>
            <Box
              w="full"
              borderBottomWidth="0.188rem"
              borderBottomColor="white"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {props.title}
            </Box>
            <Box w="full" pl={8}>
              <HStack spacing="2" fontSize="0.875rem">
                <Flex direction="column" gap={2}>
                  <Text fontWeight="bold" mt={2}>
                    Total boxes
                  </Text>
                  <Text>{data?.totalBoxes} Boxes</Text>
                  <Text fontWeight="bold">Split details</Text>
                  <Box>
                    <Text mb={1}>Default split</Text>
                    <SlzButton
                      variant="outline"
                      alignItems="center"
                      size="sm"
                      mb={2}
                      onClick={onClickTrackingDetail}>
                      Split details & tracking
                    </SlzButton>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Shipping option</Text>
                    {data?.requireShipping ? (
                      <SlzLabel variant="solid" colorScheme="szrDenim" size="sm" w="fit-content">
                        Selazar fulfilled
                      </SlzLabel>
                    ) : (
                      <SlzLabel variant="outline" colorScheme="main" size="sm" w="fit-content">
                        Self-fulfilled
                      </SlzLabel>
                    )}
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Courier provider</Text>
                    <Text>{data?.courierProvider?.courierName}</Text>
                  </Box>
                  <Flex direction="column">
                    <Text fontWeight="bold">Tracking information</Text>
                    {data?.trackingInfo?.map((tracking) => {
                      return (
                        <Fragment>
                          <SlzLink
                            href={tracking?.url}
                            color="#3474E2"
                            variant="underline"
                            isExternal>
                            {tracking?.trackingNumber}
                          </SlzLink>
                        </Fragment>
                      );
                    })}
                  </Flex>
                  <Box>
                    <Text fontWeight="bold">Weight</Text>
                    <Text>{data?.totalWeight}Kg</Text>
                  </Box>
                </Flex>
              </HStack>
            </Box>
          </VStack>
        </>
      )}
    </SlzPaper>
  );
};

export default BulkOrderCostDetail;
