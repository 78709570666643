import { useEffect, useState } from 'react';
import { Box, useBoolean } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';

import {
  B2BCourierNetWorkShipping,
  B2BHeaderShipping,
  B2BOrderAction,
  B2BOrderOption
} from 'modules/b2b-orders/components/other';
import {
  B2B_ORDER_DEFAULT_VALUES,
  B2B_SHIPPING_ATTENTION_TEXT,
  B2B_SHIPPING_OPTION,
  BULK_ORDER_CREATE_TEXT,
  DISABLE_CONDITION
} from 'modules/b2b-orders/constants';
import useFindShippingOptionsMutation from 'modules/b2b-orders/hooks/useFindShippingOptionsMutation';
import { inputReferencesMapper } from 'modules/b2b-orders/utils';

const ShippingStep = ({ watch, ...rest }) =>
  !watch(B2B_SHIPPING_OPTION.key) ? (
    <B2BOrderOption {...rest} />
  ) : (
    <B2BCourierNetWorkShipping watch={watch} {...rest} />
  );

const B2BShippingForm = ({ defaultValues, onNext, onPrevious, ...rest }) => {
  const {
    contactDetails,
    serviceTimePreference,
    purchaseOrderNumber,
    destinationAddress,
    preferences,
    stockSelection,
    boxSplits
  } = defaultValues;

  const [courierResult, setCourierResult] = useState({
    shippingService: {
      id: '',
      name: '',
      service: '',
      cost: '',
      selected: false
    },
    shippingResult: {},
    noCourierError: false,
    showError: false,
    selected: false,
    showDifferentServiceMessage: false,
    originalServiceTimePreference: '',
    ...defaultValues
  });

  const isDisabledNextButton =
    !defaultValues.preferences.requireShipping || defaultValues.shippingService.selected;

  const [isChecked, setIsChecked] = useBoolean(isDisabledNextButton);
  const [submissionDetails, setSubmissionDetails] = useState({
    boxes: boxSplits ? boxSplits[0].boxes : [],
    serviceTimePreference: serviceTimePreference,
    destinationAddress: { ...destinationAddress, country: destinationAddress.country },
    contactDetails: contactDetails,
    orderInformation: { purchaseOrderNumber }
  });
  const mutationShippingOption = useFindShippingOptionsMutation();
  const isSubmitting = mutationShippingOption.isLoading;

  const fetchShippingOption = async () => {
    try {
      const { data: shippingOption } = await mutationShippingOption.mutateAsync(submissionDetails);
      const shippingService = {
        id: shippingOption?.data?.cheapestCourierDetails?.courierID,
        name: shippingOption?.data?.cheapestCourierDetails?.courierName,
        service:
          shippingOption?.data?.cheapestCourierDetails?.serviceTimeDetails?.textValue + ' delivery',
        textValue: shippingOption?.data?.cheapestCourierDetails?.serviceTimeDetails?.textValue,
        cost: shippingOption?.data?.cheapestCourierDetails?.cost.toFixed(2),
        selected: false
      };

      if (shippingOption?.data?.noShippingResults) {
        setCourierResult({
          ...courierResult,
          shippingService: {
            ...B2B_ORDER_DEFAULT_VALUES.shippingService
          },
          noCourierError: true,
          showError: false
        });
      } else if (shippingOption?.data?.errorThrown) {
        setCourierResult({
          ...courierResult,
          shippingService: {
            ...B2B_ORDER_DEFAULT_VALUES.shippingService
          },
          noCourierError: false,
          showError: true
        });
      } else {
        setCourierResult({
          ...courierResult,
          noCourierError: false,
          showError: false,
          shippingService: {
            ...shippingService
          },
          shippingResult: {},
          courierCalculation: shippingOption?.data
        });

        if (
          submissionDetails.serviceTimePreference !==
            shippingOption?.data?.cheapestCourierDetails.serviceTimeDetails.enumValue &&
          submissionDetails.serviceTimePreference !== 'noPreference'
        ) {
          setCourierResult({
            ...courierResult,
            shippingService: {
              ...shippingService
            },
            showDifferentServiceMessage: true,
            noCourierError: false,
            showError: false,
            originalServiceTimePreference: shippingOption?.data?.originalServiceTimePreference
          });
        } else if (
          shippingOption?.data?.originalServiceTimePreference ===
          shippingOption?.data?.cheapestCourierDetails.serviceTimeDetails.textValue
        ) {
          setCourierResult({
            ...courierResult,
            shippingService: {
              ...shippingService
            },
            showDifferentServiceMessage: false,
            noCourierError: false,
            showError: false,
            originalServiceTimePreference: shippingOption?.data?.originalServiceTimePreference
          });
        }
      }
    } catch (err) {
      setCourierResult({
        ...courierResult,
        shippingService: {
          ...B2B_ORDER_DEFAULT_VALUES.shippingService
        },
        noCourierError: false,
        showError: true,
        showDifferentServiceMessage: false
      });
    }
  };

  const handlePreviousStep = () => {
    const currentValues = {
      ...defaultValues,
      selected: false,
      shippingService: {
        ...B2B_ORDER_DEFAULT_VALUES.shippingService
      },
      serviceTimePreference: submissionDetails.serviceTimePreference
    };

    onPrevious({ values: currentValues });
  };

  const handleNextStep = () => {
    const currentValues = {
      ...defaultValues,
      selected: true,
      shippingService: {
        ...courierResult.shippingService
      },
      shippingResult: !defaultValues.preferences.requireShipping
        ? {}
        : {
            destinationAddress: defaultValues.destinationAddress,
            bulkOrderBoxes: courierResult.courierCalculation?.packagingRequired,
            totalCost: courierResult.shippingService.cost
          },
      serviceTimePreference: submissionDetails.serviceTimePreference
    };

    onNext(currentValues);
  };

  const toggleShippingService = (select) => {
    setCourierResult({
      ...courierResult,
      shippingService: {
        ...courierResult.shippingService,
        selected: select
      }
    });
  };

  const handleChangeServiceTime = (serviceTime) => {
    setSubmissionDetails({
      ...submissionDetails,
      serviceTimePreference: serviceTime
    });
  };

  useEffect(() => {
    setIsChecked.toggle();
  }, [courierResult.shippingService.selected]);

  return (
    <Box>
      <FormWrapper
        defaultValues={{ ...defaultValues, ...inputReferencesMapper(defaultValues.preferences) }}>
        {({ methods }) => {
          const checkisRequireShipping = methods?.getValues('requireShipping');

          return (
            <FormProvider {...methods}>
              <form onSubmit={methods?.handleSubmit(handleNextStep)}>
                <Box minH="32.75rem">
                  {!checkisRequireShipping && (
                    <B2BHeaderShipping title={BULK_ORDER_CREATE_TEXT.SHIPPING.header} />
                  )}
                  <ShippingStep
                    courierResult={courierResult}
                    control={methods.control}
                    order={defaultValues}
                    isLoading={isSubmitting}
                    handleChangeServiceTime={handleChangeServiceTime}
                    toggleShippingService={toggleShippingService}
                    fetchShippingOption={fetchShippingOption}
                    watch={methods.watch}
                    key={B2B_SHIPPING_OPTION.key}
                    optionKey={B2B_SHIPPING_OPTION.key}
                    title={B2B_SHIPPING_OPTION.title}
                    modify={B2B_SHIPPING_OPTION.modify}
                    content={B2B_SHIPPING_OPTION.content}
                    isOptionDisabled={true}
                    isTitleDisabled={false}
                    onJumpToStep={rest.onJumpToStep}
                  />

                  {!checkisRequireShipping && (
                    <SlzAttentionCard {...B2B_SHIPPING_ATTENTION_TEXT.attentionCard} />
                  )}
                </Box>
                <B2BOrderAction
                  onPrevious={handlePreviousStep}
                  disableNextButton={isChecked}
                  {...rest}
                />
              </form>
            </FormProvider>
          );
        }}
      </FormWrapper>
    </Box>
  );
};

export default B2BShippingForm;
