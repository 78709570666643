import moment from 'moment';
import memoize from 'lodash/memoize';
import { productDetail } from '../constants';
import { CountryList } from 'components/common/Constants';

const filterCountry = (name) => {
  const country = CountryList.filter((country) => country.code === name)[0];
  return { value: country?.code, label: country?.name };
};

export const transformProductDetail = memoize((resp) => {
  return {
    ...productDetail,
    ...resp?.item,
    id: resp?.id,
    status: resp?.status,
    stockCount: resp?.stockCount,
    countryCodeOfManufacture: filterCountry(resp?.item?.countryCodeOfManufacture) || '',
    suid: resp?.item?.selazarUniqueID,
    category: { label: resp?.item?.category?.name, value: resp?.item?.category?.id },
    currentPrice: resp?.displayPrice?.price,
    units: resp?.expectedWeeklySales,
    requiresBoxPackaging: resp?.requiresBoxPackaging,
    requiresPaperPackaging: resp?.requiresPaperPackaging,
    isFragile: resp?.isFragile,
    isPrePacked: resp?.isPrePacked,
    createdDate: moment(resp?.item?.createDate).format('MM/DD/YYYY'),
    tag: {
      id: resp?.itemCustomTag?.id,
      colorCode: resp?.itemCustomTag?.colorCode,
      text: resp?.itemCustomTag?.text
    },
    sku: resp?.stockKeepingUnits && resp?.stockKeepingUnits[0]?.code,
    imageUrl: resp?.item?.itemImages[0]?.link
  };
});
