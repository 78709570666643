import { useMutation } from 'react-query';
import { USER_SETTING_FORMS_DEFAULT_NAME } from '../constants';
import { updatePartialOrderPreference } from '../services/preferences-api';

const usePartialOrderPreferenceMutation = () =>
  useMutation({
    mutationFn: (payload) =>
      updatePartialOrderPreference(payload[USER_SETTING_FORMS_DEFAULT_NAME.order]?.partialOrder)
  });

export default usePartialOrderPreferenceMutation;
