import { HStack, VStack } from '@chakra-ui/react';
import { SummaryCard } from 'modules/dashboard/components/other';
import TrendIcon from 'components/common/Icons/TrendIcon';
import { formatNumber, getStatisticsStatus } from 'modules/dashboard/utils';

const OrderStatistics = ({ isLoading, data24Hours = {}, data28Days = {} }) => {
  const { unprocessed = 0, awaitingfulfilment = 0, completed = 0 } = data24Hours;
  const { total = {}, averageCost = {} } = data28Days;

  const totalStatus = getStatisticsStatus(total?.status);
  const averageCostStatus = getStatisticsStatus(averageCost?.status);

  return (
    <VStack spacing={6} alignItems="stretch">
      <HStack spacing={6}>
        <SummaryCard
          title="Unprocessed"
          value={formatNumber(unprocessed)}
          description="Last 24 hours"
          tagColor="californiaOrange.300"
          isEmpty={!unprocessed}
          isLoading={isLoading}
          h="4.125rem"
        />
        <SummaryCard
          title="Awaiting fulfilment"
          value={formatNumber(awaitingfulfilment)}
          description="Currently in the queue"
          tagColor="szrCerulean.500"
          isEmpty={!awaitingfulfilment}
          isLoading={isLoading}
          h="4.125rem"
        />
        <SummaryCard
          title="Completed"
          value={formatNumber(completed)}
          description="Last 24 hours"
          tagColor="tetra.500"
          isEmpty={!completed}
          isLoading={isLoading}
          h="4.125rem"
        />
      </HStack>
      <HStack spacing={6}>
        <SummaryCard
          title="Average cost"
          value={`£${formatNumber(averageCost.averageCost || 0)}`}
          description={
            <span>
              <TrendIcon w="1rem" transform={`rotate(${averageCostStatus?.rotate})`} mr="2px" />
              {`${averageCostStatus?.label} in the past 28 days`}
            </span>
          }
          color="dark.700"
          tagColor="primrose.500"
          spacing="-0.125rem"
          isEmpty={!averageCost.averageCost}
          p={2}
          isLoading={isLoading}
          subBlock
          h="5.3125rem"
        />
        <SummaryCard
          title="Total number of orders"
          value={formatNumber(total.count || 0)}
          color="dark.700"
          description={
            <span>
              <TrendIcon w="1rem" transform={`rotate(${totalStatus?.rotate})`} mr="2px" />
              {`${totalStatus?.label} in the past 28 days`}
            </span>
          }
          tagColor="transparent"
          spacing="-0.125rem"
          isEmpty={!total.count}
          p={2}
          isLoading={isLoading}
          subBlock
          h="5.3125rem"
        />
      </HStack>
    </VStack>
  );
};
export default OrderStatistics;
