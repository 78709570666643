import { useMutation } from 'react-query';
import { loadStripe } from '@stripe/stripe-js';
import { addDirectDebit } from '../services/payment-apis';

async function redirectToStripeCheckout(sessionId) {
  if (sessionId) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    stripe.redirectToCheckout({ sessionId });
  }
}

export default function useAddDirectDebit() {
  return useMutation({
    mutationFn: (newCard) => addDirectDebit(newCard),
    onSuccess: (data) => redirectToStripeCheckout(data?.id)
  });
}
