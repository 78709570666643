import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { mode } from '@chakra-ui/theme-tools';

const baseStyleTab = (props) => ({
  _focusVisible: {
    zIndex: 1,
    boxShadow: 'none',
    outline: 'none'
  },
  _focus: {
    outline: 'none'
  },
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4
  }
});

const sizes = {
  sm: {
    tab: {
      py: 1,
      px: 4,
      fontSize: 'custom.sm',
      _selected: {
        _before: {
          h: 0.5
        }
      }
    }
  },
  md: {
    tab: {
      h: 12,
      fontSize: 'custom.md',
      py: 2,
      px: 4,
      _selected: {
        _before: {
          h: 0.5
        }
      }
    }
  },
  lg: {
    tab: {
      h: 12,
      fontSize: 'custom.lg',
      py: 3,
      px: 4,
      _selected: {
        _before: {
          h: '0.188rem'
        }
      }
    }
  }
};

const defaultProps = {
  size: 'md',
  variant: 'line',
  colorScheme: 'blue'
};

const baseStyle = (props) => ({
  tab: baseStyleTab(props)
});

const basePseudo = {
  content: '""',
  w: 'full',
  h: 'full',
  pos: 'absolute'
};

const hoverVariantLine = (props) => ({
  _selected: {
    _after: {
      bg: 'transparent'
    }
  },
  _after: {
    bg: mode('lightAndShadow.disabledObject', 'lightAndShadow.darkModeText')(props),
    transform: 'scale(1)',
    borderRadius: '0.375rem',
    zIndex: -1
  }
});

const selectedVariantLine = (props) => ({
  color: 'inherit',
  borderColor: 'transparent',
  fontWeight: 'bold',
  _before: {
    bg: mode('primary.mainHover', 'primary.mainHover')(props)
  }
});

const variantLine = (props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = orientation === 'vertical' ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: '#C3C4CA',
      button: {
        zIndex: 1
      }
    },
    tab: {
      // flexBasis: '100%',
      pos: 'relative',
      [marginProp]: '0.219rem',
      borderRadius: '0.375rem',
      color: mode('lightAndShadow.mainText', 'lightAndShadow.darkModeText')(props),
      _after: {
        ...basePseudo,
        transition: 'all 0.4s',
        transform: 'scale(0)'
      },
      _before: {
        ...basePseudo,
        bottom: '-6px'
      },
      _selected: selectedVariantLine(props),
      _hover: hoverVariantLine(props),
      _disabled: {
        color: mode('lightAndShadow.disableText', 'lightAndShadow.disableText')(props),
        opacity: 1,

        _hover: {
          _after: {
            bg: 'transparent'
          }
        }
      }
    }
  };
};

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants: {
    line: variantLine
  },
  defaultProps
};
