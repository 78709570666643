import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, useBoolean } from '@chakra-ui/react';

import { useMultipleFormContext } from 'contexts/MultipleFormContext';

import {
  PREFERENCES_ACTIONS,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { PACKAGE_SETTINGS_STYLE } from 'modules/preferences/styles';
import {
  HeaderTooltip,
  PreferenceHeader,
  PreferencePaper
} from 'modules/preferences/components/common';
import { PackagingInfo, PackagingToggle } from 'modules/preferences/components/other';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';

const PackagingContent = ({ packagingFormMethods, children, ...rest }) => {
  return (
    <PreferencePaper {...PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.cardWrapper}>
      <PackagingInfo {...rest} />
      <FormProvider {...packagingFormMethods}>
        <form>{children}</form>
      </FormProvider>
    </PreferencePaper>
  );
};

const CustomPackage = ({
  packageHeader,
  mt,
  packagingOverview,
  packageSetting,
  onManageLabraries,
  ...rest
}) => {
  const [isEnabled, setIsEnabled] = useBoolean(packagingOverview?.enforced);
  const { preferenceDispatch } = usePreferenceContext();
  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME[packageSetting.keyName]]: form } = forms;
  const packagingFormMethods = form?.form;

  useEffect(() => {
    if (packagingOverview && packagingOverview.enforced !== undefined) {
      packagingOverview.enforced ? setIsEnabled.on() : setIsEnabled.off();
      packagingFormMethods.reset({ [packageSetting.keyName]: packagingOverview.enforced });
    }
  }, [packagingOverview]);

  useEffect(() => {
    packagingFormMethods.getValues(packageSetting.keyName) ? setIsEnabled.on() : setIsEnabled.off();
  }, [packagingFormMethods.watch(packageSetting.keyName)]);

  const handleManagedPackaging = (layout, packagingType) => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.MANAGED_TYPE,
      payload: { managedType: packagingType }
    });

    onManageLabraries(layout);
  };

  return (
    <Box mt={mt}>
      <PreferenceHeader
        header={packageHeader.header}
        fontSize="1.063rem"
        tooltip={<HeaderTooltip ml="0rem" tooltip={packageHeader.tooltip} />}
      />
      <PackagingContent
        isEnabled={isEnabled}
        packagingOverview={packagingOverview}
        packageSetting={packageSetting}
        packagingFormMethods={packagingFormMethods}
        onManagedPackaging={handleManagedPackaging}
        {...rest}>
        <PackagingToggle packageSetting={packageSetting} />
      </PackagingContent>
    </Box>
  );
};

export default CustomPackage;
