import React, { Fragment, useEffect, useRef } from 'react';
import { GridItem, HStack, Heading, Icon, Text } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { CreditConfigsForm, CyberSourceForm } from 'modules/preferences/components/form';
import {
  useAddCreditCard,
  useCheckCreditCard,
  useReplaceCreditCard
} from 'modules/preferences/hooks';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { PREFERENCES_ACTIONS, USER_SETTING_MODAL_LAYOUT } from 'modules/preferences/constants';

export default function CreditConfigsModalLayout({ updateRequiredSettings }) {
  const { data: hasCard } = useCheckCreditCard();
  const addingCreditCard = useAddCreditCard();
  const replacingCreditCard = useReplaceCreditCard();
  const { preferenceDispatch } = usePreferenceContext();
  const creditFormRef = useRef(null);
  const signedCard = hasCard ? replacingCreditCard.data : addingCreditCard.data;
  const isSubmitting = addingCreditCard.isLoading || replacingCreditCard.isLoading;

  const onClose = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.default.key }
    });
  };

  useEffect(() => {
    const isProceeding = addingCreditCard.isSuccess || replacingCreditCard.isSuccess;
    if (isProceeding) {
      creditFormRef.current.submit();

      updateRequiredSettings();
    }
  }, [addingCreditCard.isSuccess, replacingCreditCard.isSuccess]);

  return (
    <Fragment>
      <GridItem gridColumn="2/12">
        <HStack alignItems="flex-end" my="6">
          <HStack spacing={1}>
            <Icon
              as={FaChevronLeft}
              fontSize={14}
              color="dark.500"
              cursor="pointer"
              onClick={onClose}
            />
            <Heading as="h3" fontSize="xl" lineHeight={'none'} mb="0">
              Back to payment methods
            </Heading>
            <Icon as={FaChevronRight} fontSize={14} color="dark.500" />
            <Text>Add card details</Text>
          </HStack>
        </HStack>
      </GridItem>

      <GridItem gridColumn="2/12">
        <CreditConfigsForm
          isSubmitting={isSubmitting}
          onBack={onClose}
          onSubmit={hasCard ? replacingCreditCard.mutate : addingCreditCard.mutate}
        />
        <CyberSourceForm signedModel={signedCard || {}} creditFormRef={creditFormRef} />
      </GridItem>
    </Fragment>
  );
}
