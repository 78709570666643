import { createContext, useContext, useReducer } from 'react';
import { INITIAL_ADDRESS_BOOK_STATE } from '../constants';
import { addressBookReducer } from '../reducers';

export const AddressBookContext = createContext(null);

export const AddressBookProvider = ({ children }) => {
  const [addressBook, dispatch] = useReducer(addressBookReducer, INITIAL_ADDRESS_BOOK_STATE);
  return (
    <AddressBookContext.Provider value={{ addressBook, addressBookDispatch: dispatch }}>
      {children}
    </AddressBookContext.Provider>
  );
};

export const useAddressBookContext = () => useContext(AddressBookContext);
