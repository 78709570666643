//This need to be changed when multiwarehouse gets released.
export const transformWarehousesList = (item) => {
  item = {
    label: <p><b>{item.name}</b>, {item.addressLine2}, {item.town}, {item.county}, {item.country}, {item.postcode}</p>,
    warehouseInformation: {
      name: item.name,
      addressLine1: item.addressLine1,
      addressLine2: item.addressLine2,
      town: item.town,
      county: item.county,
      country: item.country,
      postcode: item.postcode,
    },
    value: item.id,
    isDisabled: false
  }

  return item;
};


