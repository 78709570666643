import { useQueries } from 'react-query';
import { mapCourierSettings } from 'modules/preferences/utils';
import { getCurrentPreferences, getServiceTimes } from '../services/preferences-api';

export const useCourierSettings = (enabled) => {
  const [
    {
      data: regionPreference,
      isFetching: isLoadingRegionPreference,
      refetch: refetchRegionPreference
    },
    { data: serviceTimes, isFetching: isLoadingServiceTimes }
  ] = useQueries([
    {
      queryKey: [`current-preferences`],
      queryFn: () => getCurrentPreferences(),
      enabled: enabled,
      cacheTime: 0
    },
    {
      queryKey: [`services`],
      queryFn: () => getServiceTimes(),
      enabled: enabled,
      cacheTime: 0
    }
  ]);
  return {
    data: mapCourierSettings(serviceTimes, regionPreference),
    isFetching: isLoadingServiceTimes || isLoadingRegionPreference,
    refetch: refetchRegionPreference
  };
};
