import { useQuery } from 'react-query';

import { getAllWarehouses } from 'modules/preferences/services/preferences-api';

const useGetAllWarehousesQuery = () => {
  return useQuery({
    queryKey: [`all-warehouses`],
    queryFn: () => getAllWarehouses(),
    select: (response) => response.data.data
  });
};

export default useGetAllWarehousesQuery;
