import { Icon } from '@chakra-ui/react';
import React from 'react';

const LogoutIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 19.812 20.005"
      {...props}>
      <g id="Group_33486" data-name="Group 33486" transform="translate(-1922.018 -653.887)">
        <path
          id="Path_4772"
          data-name="Path 4772"
          d="M1926.182,656.887a9.188,9.188,0,0,0-1.059,12.781,8.789,8.789,0,0,0,12.545,1.079,9.155,9.155,0,0,0,0-13.86"
          transform="translate(0 0)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_368"
          data-name="Line 368"
          y2="8"
          transform="translate(1931.924 654.887)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default LogoutIcon;
