import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Text
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import reduce from 'lodash/reduce';

import SlzTable from 'components/common/SlzTable/Table';
import { SlzTag } from 'components/common/SlzTag';
import { useIsMount } from 'hooks/useIsMount';

import { getB2BProductSelectedColumns } from './bulk-order-box-split.columns';
import { CUSTOM_BOX_SPLIT_STYLES } from 'modules/b2b-orders/constants/styles';
import {
  ACCORDION_TABLE_CONTENT,
  B2B_CUSTOM_BOX_SPLIT_CONTENT,
  B2B_CUSTOM_BOX_SPLIT_TAG,
  BOX_SPLIT_ERROR_TYPE,
  CUSTOM_BOX_CHECKING_FLAGS,
  CUSTOM_BOX_ERROR_FLAG,
  CUSTOM_BOX_PREFIX
} from 'modules/b2b-orders/constants';

const B2BAccordionTable = ({ selectedProducts, accordionIndex, ...rest }) => {
  const { watch, getValues, setValue } = useFormContext();
  const isMount = useIsMount();
  const [validatedStatus, setValidatedStatus] = useState('');

  useEffect(() => {
    if (!isMount) {
      const total = reduce(
        getValues(`${CUSTOM_BOX_PREFIX}[${accordionIndex - 1}]`),
        (sum, { value }) => sum + value,
        0
      );
      const boxError = getValues(CUSTOM_BOX_ERROR_FLAG.boxError)?.errors || [];
      const fitleredErrors = boxError.filter((error) => error?.id !== accordionIndex);

      switch (true) {
        case total === 0:
          setValidatedStatus('error.empty');
          setValue(CUSTOM_BOX_ERROR_FLAG.boxError, {
            shouldValidate: true,
            errors: [
              ...fitleredErrors,
              {
                isError: true,
                type: BOX_SPLIT_ERROR_TYPE.emptyBox,
                id: accordionIndex
              }
            ]
          });
          break;
        case getValues(CUSTOM_BOX_CHECKING_FLAGS.isManualValidateSuccess) === false:
          setValidatedStatus('error.tooManyItems');
          break;
        default:
          setValidatedStatus('success');
          setValue(CUSTOM_BOX_ERROR_FLAG.boxError, {
            shouldValidate: true,
            errors: [...fitleredErrors]
          });
          break;
      }
    }
  }, [
    watch(CUSTOM_BOX_CHECKING_FLAGS.validateFlag),
    watch(CUSTOM_BOX_CHECKING_FLAGS.isManualValidateSuccess)
  ]);

  return (
    <AccordionItem {...CUSTOM_BOX_SPLIT_STYLES.accordionTable.accordionItem}>
      <AccordionButton {...CUSTOM_BOX_SPLIT_STYLES.accordionTable.button(validatedStatus)}>
        <Heading as="h6" fontSize="sm">
          {ACCORDION_TABLE_CONTENT.title} {accordionIndex}
        </Heading>
        <Text display="flex" alignItems="center" fontWeight="bold" fontSize="sm">
          {validatedStatus.length > 0 && (
            <SlzTag {...CUSTOM_BOX_SPLIT_STYLES.accordionTable.tag(validatedStatus)}>
              {B2B_CUSTOM_BOX_SPLIT_TAG[validatedStatus]}
            </SlzTag>
          )}
          {ACCORDION_TABLE_CONTENT.accordionToggle}&nbsp;&nbsp;
          <AccordionIcon fontSize="xl" borderRadius="full" bg="main.500" color="white" />
        </Text>
      </AccordionButton>
      <AccordionPanel p="0" pb="0.188rem">
        <SlzTable
          {...CUSTOM_BOX_SPLIT_STYLES.accordionTable.table}
          columns={getB2BProductSelectedColumns({
            boxIndex: accordionIndex - 1,
            ...rest
          })}
          data={selectedProducts}
          {...B2B_CUSTOM_BOX_SPLIT_CONTENT.accordionTable}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default B2BAccordionTable;
