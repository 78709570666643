import { Icon } from '@chakra-ui/react';
import React from 'react';

const PaymentMethodIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 20 16"
      {...props}>
      <g id="Group_33735" data-name="Group 33735" transform="translate(-576.332 -1855.664)">
        <rect
          id="Rectangle_376"
          data-name="Rectangle 376"
          width="14"
          height="10"
          rx="2"
          transform="translate(581.332 1860.664)"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_566"
          data-name="Ellipse 566"
          cx="2"
          cy="2"
          r="2"
          transform="translate(586.332 1863.664)"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_7088"
          data-name="Path 7088"
          d="M591.332,1860.664v-2a2,2,0,0,0-2-2h-10a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2h2"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default PaymentMethodIcon;
