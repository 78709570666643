import { Mode } from 'modules/products/constants';

export const addBoxRequiredRadioOptions = ({ isReadOnly, isOnToggle }) => {
  return [
    {
      id: 1,
      name: 'Box',
      value: true,
      isReadOnly: isReadOnly || isOnToggle,
      isNoSelection: isReadOnly
    },
    {
      id: 0,
      name: 'Mailing bag',
      value: false,
      isReadOnly: isReadOnly || isOnToggle,
      isNoSelection: isReadOnly
    }
  ];
};

export const editBoxRequiredRadioOptions = ({ isReadOnly, isOnToggle, data }) => {
  return [
    {
      id: 1,
      name: 'Yes',
      value: true,
      isReadOnly: isReadOnly || isOnToggle,
      isNoSelection: isReadOnly
    },
    {
      id: 0,
      name: 'No',
      value: false,
      isReadOnly: isReadOnly || isOnToggle,
      isNoSelection: isReadOnly
    }
  ];
};
export const packagingFillRadioOptions = ({ isReadOnly, isOnToggle }) => [
  {
    id: 0,
    name: 'No packaging fill required',
    value: false,
    isReadOnly: isReadOnly || isOnToggle,
    isNoSelection: isReadOnly
  },
  {
    id: 1,
    name: 'Paper packaging',
    value: true,
    isReadOnly: isReadOnly || isOnToggle,
    isNoSelection: isReadOnly
  }
];

export const labelAsFragileRadioOptions = ({ isReadOnly }) => [
  {
    id: 0,
    name: 'Yes',
    value: true,
    isReadOnly: isReadOnly,
    isNoSelection: isReadOnly
  },
  {
    id: 1,
    name: 'No',
    value: false,
    isReadOnly: isReadOnly,
    isNoSelection: isReadOnly
  }
];

export let dataProps = ({ isReadOnly, isOnToggle, data, mode = Mode.EDIT }) => ({
  isDisabledToggle: isReadOnly,
  prePackedProduct: [
    editBoxRequired({ isReadOnly, isOnToggle, data }),
    {
      name: 'Packaging fill',
      data: packagingFillRadioOptions({ isReadOnly, isOnToggle, data }),
      propertyName: 'requiresPaperPackaging'
    },
    {
      name: 'Label as fragile',
      data: labelAsFragileRadioOptions({ isReadOnly, isOnToggle, data }),
      propertyName: 'isFragile'
    }
  ]
});

const editBoxRequired = ({ isReadOnly, isOnToggle, data }) => ({
  name: 'Box required ?',
  data: editBoxRequiredRadioOptions({ isReadOnly, isOnToggle, data }),
  propertyName: 'requiresBoxPackaging'
});

const addBoxRequired = ({ isReadOnly, isOnToggle, data }) => ({
  name: 'Packaging required',
  data: addBoxRequiredRadioOptions({ isReadOnly, isOnToggle, data }),
  propertyName: 'requiresBoxPackaging'
});
