import { useQuery } from 'react-query';
import { getBundles } from '../services/bundles-api';

export const useBundlesQuery = (filters, select, enabled) => {
  return useQuery({
    queryKey: [`bundles`, filters],
    queryFn: () => getBundles(filters),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
