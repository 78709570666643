import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function LinkedInIcon(props) {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25.013" {...props}>
      <path
        id="linkedin-icon-2"
        data-name="linkedin-icon"
        d="M5.833,8.728V25.4H.672V8.728Zm13.275-.509c3.536,0,6.067,2.159,6.067,6.625V25.4H20.039V16.65c0-2.4-.912-3.741-2.81-3.741-2.066,0-3.145,1.4-3.145,3.741V25.4H9.133V8.728h4.95v2.246A5.816,5.816,0,0,1,19.109,8.219ZM3.228.391A3.077,3.077,0,1,1,.176,3.468,3.065,3.065,0,0,1,3.228.391Z"
        transform="translate(-0.176 -0.391)"
        fill="currentColor"
      />
    </Icon>
  );
}
