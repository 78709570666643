import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import { getHeightOfAccordionCell } from 'components/common/SlzTable/Styles';
import { DEFAULT_IMAGE } from 'constants/common';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';

const BoxSplitRow = ({ value, ...rest }) => (
  <Flex
    justifyContent="flex-start"
    w="full"
    pl={3}
    py={0.5}
    fontSize="md"
    lineHeight={4.5}
    {...rest}>
    {value}
  </Flex>
);

export const B2B_ORDER_SUMMARY_COLUMNS = [
  {
    field: 'productId',
    name: 'Product ID',
    type: 'string',
    header: { align: 'left', pl: 3 },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-start" w="full" pl={3}>
          {row?.productId}
        </Flex>
      );
    }
  },
  {
    field: 'sku',
    name: 'SKU',
    type: 'string',
    header: { align: 'left', pl: 32 },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-start" w="full" pl={32}>
          {row?.sku}
        </Flex>
      );
    }
  },
  {
    field: 'quantity',
    name: 'Quantity',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-end" w="full" pr={6}>
          {row?.quantity}
        </Flex>
      );
    }
  }
];

export const BOX_SPLIT_SUMMARY_COLUMNS = [
  {
    field: 'boxIndex',
    name: 'Box',
    type: 'string',
    header: { align: 'left', pl: 3 },
    render: ({ row }) => <BoxSplitRow value={row?.boxIndex} alignItems="flex-start" h="full" />
  },
  {
    field: 'productIds',
    name: 'Product ID',
    type: 'string',
    header: { align: 'left', pl: 3 },
    render: ({ row }) => row?.productIds?.map((id) => <BoxSplitRow key={uniqueId()} value={id} />)
  },
  {
    field: 'skus',
    name: 'SKU',
    type: 'string',
    header: { align: 'left', pl: 3 },
    render: ({ row }) => row?.skus?.map((sku) => <BoxSplitRow key={uniqueId()} value={sku} />)
  },
  {
    field: 'quantities',
    name: 'Quantity',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) =>
      row?.quantities?.map((quantity) => (
        <BoxSplitRow key={uniqueId()} value={quantity} justifyContent="flex-end" pr={6} />
      ))
  }
];

export const BOX_SPLIT_SUMMARY_FOR_SEPARATE_SKU_COLUMNS = [
  {
    field: 'productId',
    name: 'Product ID',
    type: 'string',
    header: { align: 'left', pl: 3 },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-start" w="full" pl={3}>
          {row?.name}
        </Flex>
      );
    }
  },
  {
    field: 'sku',
    name: 'SKU',
    type: 'string',
    header: { align: 'left' },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-start" w="full">
          {row?.sku}
        </Flex>
      );
    }
  },
  {
    field: 'totalBoxes',
    name: 'Boxes',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-end" w="full">
          {row?.totalBoxes}
        </Flex>
      );
    }
  },
  {
    field: 'quantityPerBox',
    name: 'Quantity per Box',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) => {
      return (
        <Flex justifyContent="flex-end" w="full" pr={6}>
          {row?.quantityPerBox}
        </Flex>
      );
    }
  }
];

export const B2B_ORDER_OPTION_SUMMARY_COLUMNS = [
  {
    field: 'options',
    name: 'Options',
    type: 'string',
    header: { align: 'left', pl: 3, bg: 'light.700' },
    render: ({ row }) => {
      return (
        <Text fontWeight="medium" color="dark.700" fontSize="md" textAlign="left" pl={3}>
          {row?.options}
        </Text>
      );
    }
  },
  {
    field: 'status',
    name: 'Status',
    header: { align: 'right', pr: 6, bg: 'light.700' },
    render: ({ row }) => {
      const { text, variant, colorScheme } = row?.status;
      return (
        <Flex justifyContent="flex-end" w="full" pr={6}>
          <SlzLabel size="sm" variant={variant} colorScheme={colorScheme} flexBasis="none">
            {text}
          </SlzLabel>
        </Flex>
      );
    }
  }
];

export const BOX_SPLIT_SUMMARY_PARTIAL_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 54,
    type: COL_CUSTOM,
    render: ({ row, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfAccordionCell('order'),
            px: '0.188rem',
            py: 0,
            height: '3rem',
            width: '3.375rem'
          }}>
          <Image
            sx={rowProps?.accordionProps?.imageStyles}
            w="3rem"
            h="3rem"
            objectFit="cover"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Product',
    field: 'name',
    width: 230,
    header: { align: 'left', pl: 2 },
    render: ({ row }) => (
      <Box
        id={`product-selection-${row?.id}`}
        sx={{
          pl: 2,
          '.wrapName': { alignItems: 'start', justifyContent: 'start' },
          '.innerName': { alignItems: 'start' }
        }}>
        <HStack className="wrapName" pos="relative" w="full">
          <VStack
            className="innerName"
            w="full"
            fontSize="sm"
            lineHeight="none"
            whiteSpace="nowrap"
            overflow={'hidden'}
            maxW={'15.625rem'}
            spacing={1}
            alignItems="start">
            <Text color="main.500" mb={0}>
              {row?.name}
            </Text>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%',
                color: 'dark.700'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: 1.5 }} noOfLines={1}>
                SKU
              </Text>
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.sku}
              </Text>
            </Flex>
          </VStack>
        </HStack>
      </Box>
    )
  },
  {
    name: 'Quantity',
    field: 'quantity',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right">
          {row?.quantity}
        </Text>
      );
    }
  },
  {
    name: 'Full Boxes',
    field: 'fullBoxes',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right">
          {row?.fullBoxes}
        </Text>
      );
    }
  },
  {
    name: 'Partial Boxes',
    field: 'partialBoxes',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right">
          {row?.partialBoxes}
        </Text>
      );
    }
  },
  {
    name: 'Quantity per box',
    field: 'quantityPerBox',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right" pr={6}>
          {row?.quantityPerBox}
        </Text>
      );
    }
  }
];

export const BOX_SPLIT_SUMMARY_NONE_PARTIAL_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 54,
    type: COL_CUSTOM,
    render: ({ row, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfAccordionCell('order'),
            px: '0.188rem',
            py: 0,
            height: '3rem',
            width: '3.375rem'
          }}>
          <Image
            sx={rowProps?.accordionProps?.imageStyles}
            w="3rem"
            h="3rem"
            objectFit="cover"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Product',
    field: 'name',
    width: 230,
    header: { align: 'left', pl: 2 },
    render: ({ row }) => (
      <Box
        id={`product-selection-${row?.id}`}
        sx={{
          pl: 2,
          '.wrapName': { alignItems: 'start', justifyContent: 'start' },
          '.innerName': { alignItems: 'start' }
        }}>
        <HStack className="wrapName" pos="relative" w="full">
          <VStack
            className="innerName"
            w="full"
            fontSize="sm"
            lineHeight="none"
            whiteSpace="nowrap"
            overflow={'hidden'}
            maxW={'15.625rem'}
            spacing={1}
            alignItems="start">
            <Text color="main.500" mb={0}>
              {row?.name}
            </Text>
            <Flex
              sx={{
                textTransform: 'uppercase',
                alignItems: 'center',
                justifyContent: 'start',
                w: '80%',
                color: 'dark.700'
              }}>
              <Text sx={{ fontWeight: 'bold', mb: 0, pr: 1.5 }} noOfLines={1}>
                SKU
              </Text>
              <Text sx={{ mb: 0 }} noOfLines={1}>
                {row?.sku}
              </Text>
            </Flex>
          </VStack>
        </HStack>
      </Box>
    )
  },
  {
    name: 'Quantity',
    field: 'quantity',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right">
          {row?.quantity}
        </Text>
      );
    }
  },
  {
    name: 'Total Boxes',
    field: 'totalBoxes',
    type: 'string',
    header: { align: 'right' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right">
          {row?.totalBoxes}
        </Text>
      );
    }
  },
  {
    name: 'Quantity per box',
    field: 'quantityPerBox',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right" pr={6}>
          {row?.quantityPerBox}
        </Text>
      );
    }
  }
];

export const PRODUCT_SELECTED_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 24,
    type: COL_CUSTOM,
    render: ({ row, rowProps }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfAccordionCell('order'),
            py: 0,
            height: 6,
            width: 6
          }}>
          <Image
            sx={rowProps?.accordionProps?.imageStyles}
            w={6}
            h={6}
            objectFit="cover"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    name: 'Selected Products',
    field: 'name',
    width: 230,
    header: { align: 'left', pl: 3 },
    render: ({ row }) => (
      <Text color="main.500" pl={3} fontSize="xxs" textAlign="left" noOfLines={1}>
        {row?.name}
      </Text>
    )
  },
  {
    name: 'SKU',
    field: 'sku',
    type: 'string',
    header: { align: 'left' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="xxs" textAlign="left" noOfLines={1}>
          {row?.sku}
        </Text>
      );
    }
  },
  {
    name: 'EAN/SUID',
    field: 'ean',
    type: 'string',
    header: { align: 'left' },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="xxs" textAlign="left" noOfLines={1}>
          {row?.ean}
        </Text>
      );
    }
  },
  {
    name: 'Total Quantity',
    field: 'quantity',
    type: 'string',
    header: { align: 'right', pr: 6 },
    render: ({ row }) => {
      return (
        <Text color="dark.700" fontSize="sm" textAlign="right" pr={6}>
          {row?.quantity}
        </Text>
      );
    }
  }
];
