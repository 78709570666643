import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/react';

import SlzToast from 'components/common/SlzToast/SlzToast';
import { DEFAULT_TOAST_SETTINGS, DEFAULT_SLZ_TOAST, CONFIRM_LEAVE_TOAST } from 'constants';
import SlzConfirmLeaveToast from 'components/common/SlzToast/SlzConfirmLeaveToast';

const ToastContent = ({ type, ...rest }) => {
  if (type === CONFIRM_LEAVE_TOAST) {
    return <SlzConfirmLeaveToast {...rest} />;
  }
  return <SlzToast {...DEFAULT_SLZ_TOAST} {...rest} />;
};

const useSlzToast = () => {
  const [state, setState] = useState(undefined);
  const toast = useToast();

  useEffect(() => {
    if (state) {
      const { type, chakraToastProps, ...rest } = state;
      toast({
        ...DEFAULT_TOAST_SETTINGS,
        ...chakraToastProps,
        render: ({ onClose }) => <ToastContent type={type} onClose={onClose} {...rest} />
      });
    }
  }, [state, toast]);

  return [state, setState, toast];
};

export default useSlzToast;
