import { HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { INTEGRATION_TAB } from '../../constants';

const IntegrationTabs = ({ tabIndex, onChange }) => {
  return (
    <HStack gap="25px" w="full" mb={6} pt={6}>
      <Tabs variant="line" size={['sm', 'md', 'lg']} index={tabIndex} onChange={onChange}>
        <TabList flexWrap={'wrap'}>
          {Object.values(INTEGRATION_TAB).map(({ key, label }) => (
            <Tab w={36} key={key}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </HStack>
  );
};

export default IntegrationTabs;
