import {
  ACTIVE_NAVBAR_ITEM,
  ACTIVE_USER_SETTING_MODAL,
  SET_IS_NAVBAR_EXPANDED
} from 'constants/common';

export const SET_BREADCRUMB_LINKS = 'SET_BREADCRUMB_LINKS';

export const appReducer = (state, action) => {
  switch (action.type) {
    case SET_BREADCRUMB_LINKS:
      const appState = { ...state };
      appState.breadcrumb = action.payload;
      return appState;
    case ACTIVE_NAVBAR_ITEM:
      return {
        ...state,
        [ACTIVE_NAVBAR_ITEM]: action.payload
      };
    case SET_IS_NAVBAR_EXPANDED:
      return {
        ...state,
        [SET_IS_NAVBAR_EXPANDED]: action.payload
      };
    case ACTIVE_USER_SETTING_MODAL:
      return {
        ...state,
        [ACTIVE_USER_SETTING_MODAL]: action.payload
      };
    default:
      return state;
  }
};
