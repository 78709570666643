import { Box, chakra, GridItem, HStack, SimpleGrid } from '@chakra-ui/react';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import { NestedRadioGroup } from 'components/common/SlzForms/base/NestedRadioGroup';
import { NestedToggle } from 'components/common/SlzForms/base/NestedToggle';
import { Mode } from 'modules/products/constants';
import { tooltipContent } from 'pages/Elements/ElementOne';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { dataProps } from './data';

const PrepackedProduct = (props) => {
  const { getValues, setValue, watch } = useFormContext();
  let { isPrePacked, requiresBoxPackaging, requiresPaperPackaging, isFragile } = getValues();
  let { isEditable, mode } = props;
  const [data, setData] = useState({});
  const [prePackedProduct, setPrePackedProduct] = useState();
  const [defaultRequireBox, setDefaultRequireBox] = useState(requiresBoxPackaging ?? false);
  const [defaultRequiresPaperPackaging, setDefaultRequiresPaperPackaging] = useState(
    requiresPaperPackaging ?? false
  );

  useEffect(() => {
    setDefaultRequireBox(requiresBoxPackaging);
    setDefaultRequiresPaperPackaging(requiresPaperPackaging);
    setData({
      isPrePacked,
      requiresBoxPackaging,
      requiresPaperPackaging,
      isFragile
    });
    setPrePackedProduct(
      dataProps({ isReadOnly: !isEditable, isOnToggle: isPrePacked, data, mode }).prePackedProduct
    );
  }, []);

  useEffect(() => {
    if (isPrePacked && isEditable) {
      setValue('requiresBoxPackaging', null);
      setValue('requiresPaperPackaging', null);
    } else {
      setValue('requiresBoxPackaging', defaultRequireBox ?? false);
      setValue('requiresPaperPackaging', defaultRequiresPaperPackaging ?? false);
    }

    setPrePackedProduct(
      dataProps({ isReadOnly: !isEditable, isOnToggle: isPrePacked, data, mode }).prePackedProduct
    );
  }, [isEditable, isPrePacked]);

  const handleToggleChange = (value) => {
    setPrePackedProduct(dataProps({ isReadOnly: !isEditable, value, data, mode }).prePackedProduct);
  };
  const radioProps = { size: 'md', mt: '0.25rem' };
  const toggleProps = { mt: '0.25rem' };
  const preLabelToggle = (
    <chakra.label __css={{ mb: '0.125rem', marginRight: '1rem', maxH: '1.5rem' }}>Yes</chakra.label>
  );

  const attentionCardTitle = (
    <>
      A pre-packed product must be provided in an already shippable container.
      <br />
      You may be charged a packing charge if an order contains multiple products
    </>
  );
  return (
    <Box sx={{ mb: '1.375rem' }}>
      <HStack sx={{ mb: '1rem' }} spacing={4} align={'flex-end'}>
        <Box sx={{ mr: mode == Mode.ADD ? '1.875rem' : '' }}>
          <Box display="flex">
            <NestedToggle
              {...toggleProps}
              previousLabel={preLabelToggle}
              field="isPrePacked"
              isChecked={watch('isPrePacked')}
              label="Pre-packed product"
              labelProps={{ fontSize: '0.875rem', color: 'dark.700' }}
              showTooltip={true}
              useTooltipLabel={true}
              tooltipContent={tooltipContent}
              isReadOnly={!isEditable}
              onToggle={handleToggleChange}
              sx={{
                'input+span': {
                  transform: 'rotate(180deg)'
                }
              }}>
              No
            </NestedToggle>
          </Box>
        </Box>
        <SlzAttentionCard
          variant="product"
          maxW="100%"
          minW="24rem"
          fontSize="0.875rem"
          title={attentionCardTitle}
        />
      </HStack>
      <SimpleGrid display={'flex'} spacing="3.625rem">
        {prePackedProduct &&
          prePackedProduct.map((item, index) => (
            <GridItem key={index}>
              <NestedRadioGroup
                key={index}
                {...radioProps}
                label={`${++index}. - ${item.name}`}
                labelProps={{ mb: 0, fontSize: '0.875rem', color: 'dark.700' }}
                showTooltip={true}
                useTooltipLabel={true}
                tooltipContent={tooltipContent}
                field={item.propertyName}
                data={item.data}
              />
            </GridItem>
          ))}
      </SimpleGrid>
    </Box>
  );
};
export default PrepackedProduct;
