import React from 'react';
import { SlzTag } from 'components/common/SlzTag';

export default function Tag({ children, ...props }) {
  return (
    <SlzTag
      h="7.5"
      w="full"
      rounded="0.1875rem"
      justifyContent="flex-end"
      fontSize="0.875rem"
      color="dark.700"
      {...props}>
      {children}
    </SlzTag>
  );
}
