import React from 'react';

import { Box, HStack, Text } from '@chakra-ui/react';

import { WarningIcon } from 'modules/users/common/icons';

const InvalidCredentialsAlert = ({
  title,
  errorDescription,
  hintDescription,
  hintDescriptionHighLight,
  ...rest
}) => {
  return (
    <Box bg="negative.300" borderRadius="12px" p="0.75rem 1.5rem" {...rest}>
      <HStack>
        <WarningIcon />
        <Text color="dark.700" fontSize="17px" fontWeight="medium">
          {title}
        </Text>
      </HStack>
      <Text color="dark.700" fontSize="14px">
        {errorDescription}
      </Text>
      <Text color="dark.700" fontSize="14px">
        {hintDescription}
        {hintDescriptionHighLight && <Box as="b">{hintDescriptionHighLight}</Box>}
      </Text>
    </Box>
  );
};

export default InvalidCredentialsAlert;
