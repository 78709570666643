import { Box, VStack } from '@chakra-ui/react';
import { AddressDetails, ContactDetails } from 'components/form/address';

const RecipientDetailsForm = (props) => {
  return (
    <Box>
      <VStack alignItems="initial" gap={3} mb={10}>
        <ContactDetails />
        <AddressDetails />
      </VStack>
    </Box>
  );
};

export default RecipientDetailsForm;
