import React from 'react';

import { Flex, Icon, VStack } from '@chakra-ui/react';
import { BagIcon, PackageIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { SlzPaper } from 'components/common/SlzPaper';
import { PACKAGING_TYPES } from 'modules/preferences/constants';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';

const AddCustomBox = ({ title, type, onAddBox }) => {
  const { preference } = usePreferenceContext();
  const { managedType } = preference;
  return (
    <SlzPaper w="14.938rem" h="8.688rem" borderRadius="0.75rem" border="common">
      <VStack spacing="0.75rem">
        <Flex
          mt="1rem"
          w="3.75rem"
          h="3.75rem"
          borderRadius="full"
          bg="main.300"
          alignItems="center"
          justifyContent="center">
          {managedType === PACKAGING_TYPES.box ? <PackageIcon /> : <BagIcon fill="#0973ea" />}
        </Flex>
        <SlzButton borderRadius={1.5} fontSize="1rem" onClick={() => onAddBox()}>
          {title}
        </SlzButton>
      </VStack>
    </SlzPaper>
  );
};

export default AddCustomBox;
