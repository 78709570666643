import { HStack, Text } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { DEFAULT_SLZ_TOAST, STYLE_WARNING_TOAST } from 'constants';
import SlzToast from './SlzToast';

const ConfirmLeaveToastContent = (props) => {
  const { warningText, okText = 'OK', cancelText = 'Cancel', onCancelClick, onOkClick } = props;
  return (
    <HStack ps={6} spacing={3}>
      <Text fontSize="1rem" fontWeight="normal" color="dark.700">
        {warningText}
      </Text>
      <SlzButton
        size="md"
        variant="outline"
        borderRadius={1.5}
        borderColor="dark.700"
        colorScheme="dark"
        onClick={onCancelClick}>
        {cancelText}
      </SlzButton>
      <SlzButton
        size="md"
        borderRadius={1.5}
        color="sunshine.500"
        bg="dark.700"
        colorScheme="dark"
        onClick={onOkClick}>
        {okText}
      </SlzButton>
    </HStack>
  );
};

const SlzConfirmLeaveToast = ({ onClose, ...rest }) => {
  return (
    <SlzToast
      onClose={onClose}
      {...DEFAULT_SLZ_TOAST}
      {...STYLE_WARNING_TOAST}
      render={() => <ConfirmLeaveToastContent {...rest} />}
    />
  );
};

export default SlzConfirmLeaveToast;
