import { FormProvider } from 'react-hook-form';
import FormWrapper from 'components/common/SlzForms/FormWrapper';
import {
  B2B_BOX_SPLIT_OPTION,
  DISABLE_CONDITION,
  ORDER_OPTIONS_KEY
} from 'modules/b2b-orders/constants';
import {
  B2BDisabledBoxSplit,
  BoxSplitSummary,
  CustomBoxSplit
} from 'modules/b2b-orders/components/other';
import { B2B_BOX_SPLIT_STYLE } from 'modules/b2b-orders/constants/styles';
import { inputReferencesMapper } from 'modules/b2b-orders/utils';

const BoxSplitStep = ({ watch, step, defaultValues, ...rest }) => {
  if (!watch(B2B_BOX_SPLIT_OPTION.toggleKey))
    return <B2BDisabledBoxSplit defaultValues={defaultValues} {...rest} />;
  else if (
    watch(ORDER_OPTIONS_KEY.isBoxSplitOrSeparate) &&
    !defaultValues.preferences.requireSeperateSKUs
  )
    return <CustomBoxSplit defaultValues={defaultValues} step={step} {...rest} />;
  else return <BoxSplitSummary defaultValues={defaultValues} step="boxSplitSummary" {...rest} />;
};

const B2BBoxSplitWrapper = ({ children, defaultValues, onNext }) => {
  return (
    <FormWrapper defaultValues={inputReferencesMapper(defaultValues.preferences)}>
      {({ methods }) => {
        const checkDisableStatus = (key) => DISABLE_CONDITION[key](methods.watch);

        const handleNextStep = (data) => {
          const currentValues = {
            ...defaultValues,
            boxSplits: data?.boxSplits
          };
          onNext(currentValues);
        };

        return (
          <FormProvider {...methods}>
            <form style={B2B_BOX_SPLIT_STYLE.form} onSubmit={methods?.handleSubmit(handleNextStep)}>
              {children({
                checkDisableStatus,
                watch: methods.watch
              })}
            </form>
          </FormProvider>
        );
      }}
    </FormWrapper>
  );
};

const B2BBoxSplit = ({ onJumpToStep, step, defaultValues, onNext, ...rest }) => {
  return (
    <B2BBoxSplitWrapper defaultValues={defaultValues} onNext={onNext}>
      {({ checkDisableStatus, watch }) => (
        <BoxSplitStep
          watch={watch}
          step={step}
          defaultValues={defaultValues}
          key={B2B_BOX_SPLIT_OPTION.key}
          optionKey={B2B_BOX_SPLIT_OPTION.key}
          title={B2B_BOX_SPLIT_OPTION.title}
          modify={B2B_BOX_SPLIT_OPTION.modify}
          content={B2B_BOX_SPLIT_OPTION.content}
          isOptionDisabled={checkDisableStatus(B2B_BOX_SPLIT_OPTION.key)}
          isTitleDisabled={false}
          onJumpToStep={onJumpToStep}
          {...rest}
        />
      )}
    </B2BBoxSplitWrapper>
  );
};

export default B2BBoxSplit;
