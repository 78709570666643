import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Flex,
  GridItem,
  Heading,
  HStack,
  Text,
  VStack,
  Icon,
  useDisclosure
} from '@chakra-ui/react';
import HomeIcon from 'components/common/Icons/HomeIcon';
import { SlzGrid } from 'components/common/SlzGrid';
import { SlzPaper } from 'components/common/SlzPaper';
import { SlzTimelineStepper } from 'components/common/SlzTimelineStepper';
import { useGlobalState } from 'contexts/GlobalContext';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { useBulkOrderDetailQuery } from '../hooks/useBulkOrderDetailQuery';
import { transformBulkOrderDetail } from '../mappers/bulk-order-detail-mapper';
import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { SlzButton, SlzSplitButton } from 'components/common/SlzButton';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton, SlzSkeletonDefault } from 'components/common/Skeleton/Skeleton';
import {
  B2BOrderSubStatus,
  BulkOrderCostDetail,
  BulkOrderCustomerDetail,
  BulkOrderInfoDetail
} from 'modules/b2b-orders/components/other';
import { accordionProps } from '../components/table/BulkOrderTable';
import { FaChevronLeft } from 'react-icons/fa';
import { B2B_ORDER_PATHNAME, BREAD_CRUMB_ITEMS, ORDER_PROCESSING_STATUS } from '../constants';
import TrackingDetailModal from 'modules/b2b-orders/components/modal/TrackingDetailModal';
import { updateBulkOrderTracking } from 'modules/b2b-orders/services/bulk-orders-api';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { CustomerCircleIcon } from 'components/common/Icons';

const BulkOrderDetail = () => {
  const [_, dispatch] = useGlobalState();
  const { orderId } = useParams();
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const { data, isLoading, isSuccess, refetch } = useBulkOrderDetailQuery(orderId, (data) =>
    transformBulkOrderDetail(data, routerQuery.get('status'))
  );
  const trackingDetailModal = useDisclosure();

  const handleSaveBulkOrderTracking = async (data) => {
    try {
      await updateBulkOrderTracking(data);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let breadcrumbs = [...BREAD_CRUMB_ITEMS];

    if (isSuccess) {
      breadcrumbs = [
        ...breadcrumbs,
        {
          title: data?.purchaseOrderNumber
        }
      ];
    }

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadcrumbs
    });
  }, [isSuccess]);

  return (
    <>
      <TrackingDetailModal
        isOpen={trackingDetailModal.isOpen}
        onClose={trackingDetailModal.onClose}
        title="Split details & Tracking references"
        trackingDetail={data}
        onSaveBulkOrderTracking={handleSaveBulkOrderTracking}
      />
      <SlzGrid
        templateAreas={`"header header header header header header"
        "stepper stepper stepper stepper stepper stepper"
        "nav main main main main main"
        "nav main main main main main"`}>
        <GridItem area={'header'} colSpan="12">
          <HStack
            alignItems="flex-start"
            cursor="pointer"
            display="inline-flex"
            mb={2}
            onClick={() => history.goBack()}>
            <Icon as={FaChevronLeft} fontSize="lg" color="dark.500" mt="1" />
            <Heading as="h4" fontSize="1.0625rem">
              B2B Orders
            </Heading>
          </HStack>
          <BulkOrderInfoDetail
            data={data}
            title="Purchase order details"
            isLoading={isLoading}
            showSorce={false}
            w="100%"
            h="6rem"
            bg="light.500"
            boxShadow="0px 3px 6px #00000029"
          />
        </GridItem>
        <GridItem area={'stepper'} colSpan="12">
          <SlzPaper w="full" h="8.688rem" display="flex" alignItems="center" bg="light.500">
            {isLoading ? (
              <Skeleton animation={animation} width="full" height="full"></Skeleton>
            ) : (
              <SlzTimelineStepper
                disabled={true}
                steps={ORDER_PROCESSING_STATUS}
                step={data?.status?.key}
                w="80%"
                px={15}
              />
            )}
          </SlzPaper>
        </GridItem>

        <GridItem area={'nav'} mb={3}>
          <VStack spacing={6}>
            <B2BOrderSubStatus status={data?.status} isLoading={isLoading} />
            <BulkOrderCustomerDetail
              customer={data}
              w="19.125rem"
              h="auto"
              title="Customer details"
              titleCustomer="Customer details"
              titleAddress="Shipping address"
              iconTop={<CustomerCircleIcon />}
              iconBottom={<HomeIcon />}
              isLoading={isLoading}
            />
            <BulkOrderCostDetail
              data={data}
              isLoading={isLoading}
              w="19.125rem"
              h="auto"
              title="Breakdown"
              onClickTrackingDetail={trackingDetailModal.onOpen}
            />
          </VStack>
        </GridItem>
        <GridItem area={'main'} colSpan="11" mb={3}>
          {isLoading ? (
            <SlzSkeletonDefault h="full" />
          ) : (
            <SlzPaper
              w="full"
              h="full"
              bg="light.500"
              borderRadius="0.375rem"
              boxShadow="0px 3px 6px #00000029"
              px={6}>
              <HStack justifyContent="space-between" py={3}>
                <Text fontSize="1rem" fontWeight="bold">
                  B2B Order
                </Text>
                <Flex gap={5}>
                  <SlzSplitButton
                    items={[]}
                    size="sm"
                    placeholder="B2B Order invoice"
                    hasArrow={true}
                    onlyShowPlaceholder={true}
                    onClick={() => {}}
                    onSelected={() => {}}
                  />
                  <SlzButton size="sm">Packing slip</SlzButton>
                </Flex>
              </HStack>
              <SlzAccordionTable
                variant="order"
                isShow={true}
                id={data?.id}
                data={data?.items}
                isLoading={isLoading}
                isChild={true}
                configProps={{
                  ...accordionProps,
                  isShowMarker: false,
                  markerColor: data?.status?.bg,
                  spacing: 300
                }}
              />
            </SlzPaper>
          )}
        </GridItem>
      </SlzGrid>
    </>
  );
};
export default BulkOrderDetail;
