import { Box } from '@chakra-ui/react';
import SlzChip from 'components/common/SlzChip/SlzChip';

const IntegrationCardStatus = ({ connected }) => {
  return (
    <Box>
      {connected ? (
        <SlzChip size="lg" bg="positive.500" lineHeight="4">
          Connected
        </SlzChip>
      ) : (
        <SlzChip size="lg" bg="light.700" color="dark.300" lineHeight="4">
          Disconnected
        </SlzChip>
      )}
    </Box>
  );
};
export default IntegrationCardStatus;
