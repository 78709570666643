import { Box, Flex, Text } from '@chakra-ui/react';
import { SlzSplitButton } from 'components/common/SlzButton';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import { ACTION_OPTIONS } from 'modules/address-book/constants';

export const ADDRESS_BOOK_COLUMNS = [
  {
    name: 'Name',
    field: 'name',
    isSorted: true,
    isClickable: true,
    type: COL_CUSTOM,
    render: ({ row, onClick }) => {
      return (
        <Flex direction="column">
          <Text
            noOfLines={1}
            fontSize="0.875rem"
            fontWeight="bold"
            color="dark.700"
            onClick={onClick}>
            {row?.name}
          </Text>
          <Text noOfLines={1} fontSize="0.75rem" color="main.500" onClick={onClick}>
            {row?.companyName}
          </Text>
        </Flex>
      );
    }
  },

  {
    name: 'Email',
    field: 'email',
    isSorted: true
  },
  {
    name: 'Phone',
    field: 'phone',
    isSorted: true
  },
  {
    name: 'Location',
    field: 'location',
    isSorted: true
  },
  {
    name: 'Actions',
    field: 'id',
    type: COL_CUSTOM,
    isClickable: true,
    render: ({ onClick }) => {
      return (
        <Box>
          <SlzSplitButton
            items={ACTION_OPTIONS}
            size="lg"
            // defaultValue={2}
            hasArrow
            placeholder="Actions"
            onSelected={onClick}
            onlyShowPlaceholder></SlzSplitButton>
        </Box>
      );
    }
  }
];
