import { Icon } from '@chakra-ui/react';
import React from 'react';

const ShopifyIcon = (props) => {
  return (
    <Icon
      id="Group_2340"
      data-name="Group 2340"
      xmlns="http://www.w3.org/2000/svg"
      width="21.687"
      height="24.584"
      viewBox="0 0 21.687 24.584"
      {...props}>
      <path
        id="Path_8344"
        data-name="Path 8344"
        d="M291.977,248.539s-.277.078-.734.217a5.9,5.9,0,0,0-.355-.872,2.485,2.485,0,0,0-2.2-1.528.792.792,0,0,0-.2.018c-.018-.042-.06-.06-.078-.1a1.87,1.87,0,0,0-1.528-.614,4.4,4.4,0,0,0-3.333,2.419,9.41,9.41,0,0,0-1.33,3.471c-1.371.415-2.322.716-2.34.734-.692.217-.716.24-.794.891-.06.494-1.883,14.467-1.883,14.467l15,2.6V248.52C292.078,248.52,292.018,248.539,291.977,248.539Zm-3.471,1.071c-.794.24-1.667.517-2.521.776a6.161,6.161,0,0,1,1.269-2.479,3.272,3.272,0,0,1,.854-.637,5.587,5.587,0,0,1,.4,2.34m-1.625-3.134a1.361,1.361,0,0,1,.716.18,3.625,3.625,0,0,0-.932.716,7.106,7.106,0,0,0-1.57,3.3c-.715.217-1.431.439-2.082.637C283.446,249.393,285.052,246.517,286.88,246.475Zm-2.3,10.918c.079,1.27,3.435,1.546,3.633,4.542.138,2.363-1.251,3.97-3.255,4.09a4.875,4.875,0,0,1-3.748-1.269l.517-2.184a4.514,4.514,0,0,0,2.4.932.961.961,0,0,0,.932-1.011c-.1-1.666-2.839-1.57-3.014-4.307-.157-2.3,1.348-4.626,4.686-4.842a4.212,4.212,0,0,1,1.943.24l-.752,2.857a4.151,4.151,0,0,0-1.865-.319C284.577,256.22,284.558,257.153,284.577,257.393Zm4.722-8.018a6.349,6.349,0,0,0-.355-2.165c.914.18,1.348,1.191,1.547,1.8q-.533.152-1.191.36m3.356,20.838,6.232-1.546s-2.677-18.119-2.7-18.239a.23.23,0,0,0-.217-.2c-.1,0-1.847-.042-1.847-.042s-1.071-1.034-1.468-1.431Z"
        transform="translate(-277.2 -245.661)"
        fill="#fff"
      />
    </Icon>
  );
};

export default ShopifyIcon;
