import { Fragment } from 'react';
import { HStack } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import {
  DOUBLE_BUTTONS,
  FOOTER_TEXT,
  FOOTER_TYPE,
  SINGLE_BUTTON
} from 'modules/preferences/constants';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

const FooterWithSingleButton = ({ onClick, text, isSubmitting }) => (
  <HStack w="full" spacing={0} justifyContent="flex-end" mt={14}>
    <SlzButton variant="solid" size="lg" onClick={onClick} isLoading={isSubmitting}>
      {text}
    </SlzButton>
  </HStack>
);

const FooterWithDoubleButtons = ({ onClose, onSave, closeText, actionText, isSubmitting }) => (
  <HStack w="full" spacing={0} justifyContent="space-between" mt={14}>
    <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onClose}>
      {closeText}
    </SlzButton>
    <SlzButton variant="solid" size="lg" onClick={onSave} isLoading={isSubmitting}>
      {actionText}
    </SlzButton>
  </HStack>
);

const UserSettingFooter = ({
  footerType = FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  onClose,
  onSave,
  onBack
}) => {
  const {
    rootFormState: { isDirty, isSubmitting }
  } = useMultipleFormContext();

  const SINGLE_BUTTON_ACTION = {
    [FOOTER_TYPE.CLOSE_BUTTON]: onClose,
    [FOOTER_TYPE.BACK_BUTTON]: onBack
  };

  return (
    <Fragment>
      {(DOUBLE_BUTTONS[footerType] || false) &&
        (isDirty ? (
          <FooterWithDoubleButtons
            closeText={FOOTER_TEXT[footerType].cancel}
            actionText={FOOTER_TEXT[footerType].Save}
            isSubmitting={isSubmitting}
            onClose={onClose}
            onSave={onSave}
          />
        ) : (
          <FooterWithSingleButton
            text={FOOTER_TEXT[FOOTER_TYPE.CLOSE_BUTTON]}
            onClick={onClose}
            isSubmitting={isSubmitting}
          />
        ))}
      {(SINGLE_BUTTON[footerType] || false) && (
        <FooterWithSingleButton
          text={FOOTER_TEXT[footerType]}
          onClick={SINGLE_BUTTON_ACTION[footerType]}
          isSubmitting={isSubmitting}
        />
      )}
    </Fragment>
  );
};

export default UserSettingFooter;
