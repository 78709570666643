export const CUSTOM_LIBRARY_STYLE = {
  LIBRARY_CARD: {
    header: {
      wrapper: {
        mb: '0.938rem',
        justifyContent: 'space-between'
      },
      name: {
        sx: { lineBreak: 'anywhere' },
        color: 'dark.700',
        fontSize: '1rem',
        fontWeight: 'bold'
      },
      buttons: {
        edit: {
          mr: '0.313rem',
          fontSize: '0.625rem',
          mr: '0.5rem',
          size: 'sm',
          variant: 'link',
          _hover: {
            _disabled: {
              backgroundColor: 'unset',
              color: 'dark.300'
            }
          },
          _disabled: {
            backgroundColor: 'unset',
            color: 'dark.300'
          }
        },
        delete: {
          fontSize: '0.625rem',
          size: 'sm',
          variant: 'link',
          _hover: {
            _disabled: {
              backgroundColor: 'unset',
              color: 'dark.300'
            }
          }
        }
      }
    },
    wrapper: {
      wrapperStatusStyle: {
        true: 'light.300',
        false: 'light.500'
      },
      w: '20.188rem',
      h: '100%',
      boxShadow: 'slz-md',
      borderRadius: 3,
      p: '0.813rem 0.938rem 1.625rem 1.188rem',
      marginBottom: '0.75rem',
      marginRight: '0.75rem',
      border: 'common',
      backgroundColor: 'light.300',
      disabled: {
        backgroundColor: 'light.500'
      }
    },
    dimensions: {
      wrapper: {
        justifyContent: 'space-between'
      },
      text: {
        fontWeight: 'medium',
        color: 'dark.700',
        fontSize: '0.875rem',
        letterSpacing: '-0.018rem'
      },
      input: {
        border: 'none',
        backgroundColor: 'main.300',
        textAlign: 'right',
        px: '0.938rem',
        h: '2rem',
        maxW: '10.688rem',
        fontSize: '0.875rem',
        _disabled: {
          background: 'light.700'
        }
      }
    },
    weightLimit: {
      wrapper: {
        mt: '0.375rem',
        justifyContent: 'space-between'
      },
      text: {
        fontWeight: 'medium',
        color: 'dark.700',
        fontSize: '0.875rem',
        letterSpacing: '-0.018rem'
      },
      input: {
        border: 'none',
        backgroundColor: 'main.300',
        textAlign: 'right',
        px: '0.625rem',
        h: '2rem',
        maxW: '10.688rem',
        fontSize: '0.875rem',
        _disabled: {
          background: 'light.700'
        }
      }
    }
  },
  LIBRARY_TITLE: {
    backNavigation: {
      wrapper: {
        cursor: 'pointer',
        w: 'auto',
        ml: '-0.5rem'
      },
      icon: {
        boxSize: 6,
        color: 'dark.700'
      },
      text: {
        color: 'dark.700',
        marginLeft: 'unset !important',
        letterSpacing: '-0.021rem',
        fontSize: '1.063rem'
      }
    },
    header: {
      wrapper: {
        mt: '0.563rem',
        mb: '0.813rem'
      },
      text: {
        as: 'h3',
        fontSize: '1.063rem',
        lineHeight: 8,
        mb: '0',
        color: 'dark.700',
        fontWeight: 'bold',
        mr: '1.25rem'
      },
      button: {
        fontSize: '1rem',
        variant: 'outline',
        borderColor: 'icon.500',
        borderRadius: '1.5'
      }
    }
  }
};

export const PACKAGE_SETTINGS_STYLE = {
  PACKAGE_TOGGLE_CARD: {
    cardWrapper: {
      py: '0.823rem',
      px: '1.236rem',
      display: 'flex',
      alignItems: 'center',
      background: 'light.300',
      justifyContent: 'space-between'
    },
    title: {
      w: '13.125rem'
    },
    button: {
      mr: '1.5rem !important',
      fontSize: '1rem',
      variant: 'outline',
      borderColor: 'icon.500',
      borderRadius: 1.5
    },
    tag: {
      justifyContent: 'center',
      fontWeight: 'regular',
      h: '1.875rem',
      px: '1.5rem',
      size: 'lg',
      fontSize: '0.75rem',
      disabled: {
        background: 'light.700 !important',
        color: 'stone.300 !important'
      }
    },
    toggle: {
      marginBottom: '0rem'
    }
  }
};
