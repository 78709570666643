import { HStack } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import {
  SEND_CONSIGNMENT_MODAL_STEPS,
  SEND_CONSIGNMENT_TYPES
} from 'modules/consignments/constants';

const Action = ({
  onClickCancel,
  currentType,
  selectedConsignmentType,
  isLoading,
  onBack,
  isActionDisabled = false
}) => {
  return (
    <HStack display="inline" w="full">
      {currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE && (
        <SlzButton
          onClick={() => onBack && onBack()}
          type="button"
          variant="outline"
          colorScheme="main"
          className="float-left"
          size="lg">
          Previous
        </SlzButton>
      )}
      <SlzButton
        isDisabled={isActionDisabled}
        type="submit"
        isLoading={isLoading}
        className="float-right"
        colorScheme="main"
        size="lg">
        {currentType === SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY
          ? selectedConsignmentType === SEND_CONSIGNMENT_TYPES.SINGLE
            ? 'Confirm stock & upload'
            : 'Confirm CSV & upload'
          : 'Next'}
      </SlzButton>
      <SlzButton
        sx={{ marginRight: '1rem !important' }}
        onClick={onClickCancel}
        type="button"
        variant="outline"
        className={
          currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE
            ? 'float-right'
            : 'float-left'
        }
        colorScheme="negative"
        size="lg">
        Cancel
      </SlzButton>
    </HStack>
  );
};

export default Action;
