import { IconButton } from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

const SlzSingleButton = (props) => {
  const { size = 'md', variant } = props;
  let fontSize = '0.75rem';

  if (size === 'lg') {
    fontSize = '1.5rem';
  }

  return (
    <IconButton
      id={uniqueId('SEL-Slz-SingleButton')}
      data-testid="slz-single-button"
      rounded="full"
      size="sm"
      fontSize={fontSize}
      borderWidth={variant === 'outline' && '2px'}
      {...props}
    />
  );
};

export default SlzSingleButton;
