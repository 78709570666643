import React from 'react';
import { Box, Card, HStack, Text } from '@chakra-ui/react';
import { CheckIcon } from '../assets';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';

const GtinSummary = ({ gtin, handleEditStep }) => {
  return (
    <Card
      bg="light.700"
      variant="filled"
      sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
      <HStack px="1.5rem">
        <CheckIcon w="fit-content" />
        <Box display="flex" alignItems="baseline">
          <Text
            marginRight="0.375rem"
            sx={{
              fontSize: 'lg',
              color: 'table.mainText',
              fontWeight: 'bold'
            }}>
            GTIN identifier
          </Text>

          <Text onClick={() => handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS)} fontSize="xs" cursor="pointer" color="main.500" marginRight="0.688rem">
            Edit
          </Text>
        </Box>
        <Box w="22.438rem">
          <SlzInput
            fontSize="sm"
            textAlign="right"
            color="dark.700 !important"
            size="sm"
            bgColor="light.500"
            value={gtin}
            disabled
          />
        </Box>
      </HStack>
    </Card>
  );
};

export default GtinSummary;
