import React from 'react';
import { Icon } from '@chakra-ui/react';

const HomeIcon = (props) => {
  return (
    <Icon
      h="2.063rem"
      w="2.063rem"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.738 32.738"
      fill="currentColor"
      {...props}>
      <path
        id="Path_9795"
        data-name="Path 9795"
        d="M32.258,15.212,17.526.48a1.635,1.635,0,0,0-2.315,0L.48,15.212a1.636,1.636,0,0,0,1.157,2.794H3.274v9.821a4.917,4.917,0,0,0,4.911,4.911H24.553a4.917,4.917,0,0,0,4.911-4.911V18.006H31.1a1.636,1.636,0,0,0,1.157-2.794M19.643,29.464H13.1V21.28a1.639,1.639,0,0,1,1.637-1.637h3.274a1.637,1.637,0,0,1,1.637,1.637Zm6.548-1.637a1.637,1.637,0,0,1-1.637,1.637H22.916V21.28a4.917,4.917,0,0,0-4.911-4.911H14.732A4.917,4.917,0,0,0,9.821,21.28v8.184H8.185a1.639,1.639,0,0,1-1.637-1.637V16.369a1.625,1.625,0,0,0-.116-.579c-.023-.056-.052-.1-.079-.157a1.6,1.6,0,0,0-.226-.334A1.649,1.649,0,0,0,6,15.164a1.605,1.605,0,0,0-.363-.244c-.041-.021-.074-.047-.116-.065-.013,0-.021-.015-.034-.02L16.369,3.952,27.253,14.835c-.011,0-.021.015-.033.02-.044.018-.08.046-.121.067a1.693,1.693,0,0,0-.36.242,1.438,1.438,0,0,0-.128.136,1.556,1.556,0,0,0-.226.334,1.729,1.729,0,0,0-.079.156,1.625,1.625,0,0,0-.116.579Z"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </Icon>
  );
};

export default HomeIcon;
