export const BILLING_PATHNAME = '/billing';
export const BILLING_DEFAULT_PAGE = 1;
export const BILLING_DEFAULT_PAGE_SIZE = 5;
export const BILLING_KEY = 'billing_list';
export const BILLING_COL_SPAN = 10;
export const BILLING_TYPE = 'billing';
export const VARIANT_DETAIL_BILLING = 'detailOrder';
export const BILLING_INVOICE = 'BILLING_INVOICE';
export const BILLING_COLLECTION = 'BILLING_COLLECTION';
export const BILLING_REMITTANCE = 'BILLING_REMITTANCE';

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Billing'
  }
];

export const BILLING_BREADCRUMB_TEXT = {
  invoice: 'Invoices',
  collection: 'Collection',
  remittance: 'Remittance'
};

export const BILLING_PROCESSES = [
  {
    key: 'invoice',
    label: 'Invoices'
  },
  {
    key: 'collection',
    label: 'Collection'
  },
  {
    key: 'remittance',
    label: 'Remittance'
  }
];

export const BILLING_PARAMS = {
  PROCESS: 'process'
};

export const BILLING_PROCESS = {
  INVOICE: 'invoice',
  COLLECTION_ADVICE: 'collection',
  REMITTANCE_ADVICE: 'remittance'
};

export const BILLING_DOWNLOADS = [
  { value: 1, text: 'Copy to clipboard' },
  { value: 2, text: 'Print' },
  { value: 3, text: 'Save as PDF' }
];

export const BILLING_STATUS_DOWNLOADS = {
  COPY_TO_CLIPBOARD: 1,
  PRINT: 2,
  SAVE_AS_PDF: 3
};

export const BILLING_PROCESS_LIST = Object.entries(BILLING_PROCESS).map((process) => process[1]);

export const INVOICE_TYPE = {
  ORDERS: {
    key: 'ORDERS',
    name: 'Orders',
    color: '#598CA5'
  },
  CREDIT_NOTE: {
    key: 'CREDIT_NOTE',
    name: 'Credit note',
    color: '#579BFC'
  },
  RETURNS: {
    key: 'RETURNS',
    name: 'Returns',
    color: '#B23050'
  },
  STOCK_HOLDING: {
    key: 'STOCK_HOLDING',
    name: 'Stock holding',
    color: '#401694'
  },
  STOCK_REPLENISHMENT: {
    key: 'STOCK_REPLENISHMENT',
    name: 'Stock replenishment',
    color: '#7E3B8A'
  },
  STOCK_CONSIGNMENT_PROCESSING: {
    key: 'STOCK_CONSIGNMENT_PROCESSING',
    name: 'Stock consignment processing',
    color: '#5559DF'
  },
  AD_HOC_CHARGE: {
    key: 'AD_HOC_CHARGE',
    name: 'Additional cost',
    color: '#225091'
  },
  COLLECT_PLUS_RETURNS: {
    key: 'COLLECT_PLUS_RETURNS',
    name: 'Collection and returns',
    color: '#FD642E'
  },
  STOCK_PROCESSING: {
    key: 'STOCK_PROCESSING',
    name: 'Stock processing',
    color: '#A25DDC'
  },
  BULK_ORDER: {
    key: 'BULK_ORDER',
    name: 'Bulk order',
    color: '#01a1a3'
  }
};

export const NUMBER_MAPPER = {
  [BILLING_PROCESS.COLLECTION_ADVICE]: 'caNumber',
  [BILLING_PROCESS.REMITTANCE_ADVICE]: 'raNumber',
  [BILLING_PROCESS.INVOICE]: 'invoiceNumber'
};
