import { useMutation } from 'react-query';
import { uploadImageForCSV, uploadCSV } from '../services/products-api';

const getResponseMessage = ({ isError, isMalformed, fileName, message }) => {
  if (isError) {
    if (isMalformed) {
      return (
        <>
          Formatting error <br />
          {fileName} error detected with formatting
        </>
      );
    }

    return (
      <>
        Upload error <br />
        {fileName}
        <br />
        {message}
      </>
    );
  }

  return `${fileName} uploaded successfully.`;
};

export default function useUploadProductsMutation() {
  return useMutation({
    mutationFn: async ({ csvFiles, products }) => {
      const response = await uploadCSV(csvFiles);
      const {
        data: { data }
      } = response;

      const successCSVs = data
        .filter((item) => !item.isError)
        .flatMap((item) =>
          item.data.lines.map((line) => ({
            itemId: line.itemId,
            skuCode: line.skuCode
          }))
        );

      await Promise.all(
        successCSVs.map((item) => {
          const { itemId, skuCode } = item;
          const imageFile = products.find((product) => product.skuCode === skuCode)?.imageFile;
          return uploadImageForCSV(imageFile, itemId, skuCode);
        })
      );

      const resultSet = data.map((item) => ({
        fileName: item.fileName,
        isError: item.isError,
        message: getResponseMessage(item)
      }));

      return resultSet;
    }
  });
}
