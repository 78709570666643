export default {
    country: [
        {
            countryCode: "BD",
            currencyCode: "BDT"
        },
        {
            countryCode: "EU",
            currencyCode: "EUR"
        },
        {
            countryCode: "BE",
            currencyCode: "EUR"
        },
        {
            countryCode: "BF",
            currencyCode: "XOF"
        },
        {
            countryCode: "BG",
            currencyCode: "BGN"
        },
        {
            countryCode: "BA",
            currencyCode: "BAM"
        },
        {
            countryCode: "BB",
            currencyCode: "BBD"
        },
        {
            countryCode: "WF",
            currencyCode: "XPF"
        },
        {
            countryCode: "BL",
            currencyCode: "EUR"
        },
        {
            countryCode: "BM",
            currencyCode: "BMD"
        },
        {
            countryCode: "BN",
            currencyCode: "BND"
        },
        {
            countryCode: "BO",
            currencyCode: "BOB"
        },
        {
            countryCode: "BH",
            currencyCode: "BHD"
        },
        {
            countryCode: "BI",
            currencyCode: "BIF"
        },
        {
            countryCode: "BJ",
            currencyCode: "XOF"
        },
        {
            countryCode: "BT",
            currencyCode: "BTN"
        },
        {
            countryCode: "JM",
            currencyCode: "JMD"
        },
        {
            countryCode: "BV",
            currencyCode: "NOK"
        },
        {
            countryCode: "BW",
            currencyCode: "BWP"
        },
        {
            countryCode: "WS",
            currencyCode: "WST"
        },
        {
            countryCode: "BQ",
            currencyCode: "USD"
        },
        {
            countryCode: "BR",
            currencyCode: "BRL"
        },
        {
            countryCode: "BS",
            currencyCode: "BSD"
        },
        {
            countryCode: "GB",
            currencyCode: "GBP"
        },
        {
            countryCode: "JE",
            currencyCode: "GBP"
        },
        {
            countryCode: "BY",
            currencyCode: "BYR"
        },
        {
            countryCode: "BZ",
            currencyCode: "BZD"
        },
        {
            countryCode: "RU",
            currencyCode: "RUB"
        },
        {
            countryCode: "RW",
            currencyCode: "RWF"
        },
        {
            countryCode: "RS",
            currencyCode: "RSD"
        },
        {
            countryCode: "TL",
            currencyCode: "USD"
        },
        {
            countryCode: "RE",
            currencyCode: "EUR"
        },
        {
            countryCode: "TM",
            currencyCode: "TMT"
        },
        {
            countryCode: "TJ",
            currencyCode: "TJS"
        },
        {
            countryCode: "RO",
            currencyCode: "RON"
        },
        {
            countryCode: "TK",
            currencyCode: "NZD"
        },
        {
            countryCode: "GW",
            currencyCode: "XOF"
        },
        {
            countryCode: "GU",
            currencyCode: "USD"
        },
        {
            countryCode: "GT",
            currencyCode: "GTQ"
        },
        {
            countryCode: "GS",
            currencyCode: "GBP"
        },
        {
            countryCode: "GR",
            currencyCode: "EUR"
        },
        {
            countryCode: "GQ",
            currencyCode: "XAF"
        },
        {
            countryCode: "GP",
            currencyCode: "EUR"
        },
        {
            countryCode: "JP",
            currencyCode: "JPY"
        },
        {
            countryCode: "GY",
            currencyCode: "GYD"
        },
        {
            countryCode: "GG",
            currencyCode: "GBP"
        },
        {
            countryCode: "GF",
            currencyCode: "EUR"
        },
        {
            countryCode: "GE",
            currencyCode: "GEL"
        },
        {
            countryCode: "GD",
            currencyCode: "XCD"
        },
        {
            countryCode: "GB",
            currencyCode: "GBP"
        },
        {
            countryCode: "GA",
            currencyCode: "XAF"
        },
        {
            countryCode: "SV",
            currencyCode: "USD"
        },
        {
            countryCode: "GN",
            currencyCode: "GNF"
        },
        {
            countryCode: "GM",
            currencyCode: "GMD"
        },
        {
            countryCode: "GL",
            currencyCode: "DKK"
        },
        {
            countryCode: "GI",
            currencyCode: "GIP"
        },
        {
            countryCode: "GH",
            currencyCode: "GHS"
        },
        {
            countryCode: "OM",
            currencyCode: "OMR"
        },
        {
            countryCode: "TN",
            currencyCode: "TND"
        },
        {
            countryCode: "JO",
            currencyCode: "JOD"
        },
        {
            countryCode: "HR",
            currencyCode: "HRK"
        },
        {
            countryCode: "HT",
            currencyCode: "HTG"
        },
        {
            countryCode: "HU",
            currencyCode: "HUF"
        },
        {
            countryCode: "HK",
            currencyCode: "HKD"
        },
        {
            countryCode: "HN",
            currencyCode: "HNL"
        },
        {
            countryCode: "HM",
            currencyCode: "AUD"
        },
        {
            countryCode: "VE",
            currencyCode: "VEF"
        },
        {
            countryCode: "PR",
            currencyCode: "USD"
        },
        {
            countryCode: "PS",
            currencyCode: "ILS"
        },
        {
            countryCode: "PW",
            currencyCode: "USD"
        },
        {
            countryCode: "PT",
            currencyCode: "EUR"
        },
        {
            countryCode: "SJ",
            currencyCode: "NOK"
        },
        {
            countryCode: "PY",
            currencyCode: "PYG"
        },
        {
            countryCode: "IQ",
            currencyCode: "IQD"
        },
        {
            countryCode: "PA",
            currencyCode: "PAB"
        },
        {
            countryCode: "PF",
            currencyCode: "XPF"
        },
        {
            countryCode: "PG",
            currencyCode: "PGK"
        },
        {
            countryCode: "PE",
            currencyCode: "PEN"
        },
        {
            countryCode: "PK",
            currencyCode: "PKR"
        },
        {
            countryCode: "PH",
            currencyCode: "PHP"
        },
        {
            countryCode: "PN",
            currencyCode: "NZD"
        },
        {
            countryCode: "PL",
            currencyCode: "PLN"
        },
        {
            countryCode: "PM",
            currencyCode: "EUR"
        },
        {
            countryCode: "ZM",
            currencyCode: "ZMK"
        },
        {
            countryCode: "EH",
            currencyCode: "MAD"
        },
        {
            countryCode: "EE",
            currencyCode: "EUR"
        },
        {
            countryCode: "EG",
            currencyCode: "EGP"
        },
        {
            countryCode: "ZA",
            currencyCode: "ZAR"
        },
        {
            countryCode: "EC",
            currencyCode: "USD"
        },
        {
            countryCode: "IT",
            currencyCode: "EUR"
        },
        {
            countryCode: "VN",
            currencyCode: "VND"
        },
        {
            countryCode: "SB",
            currencyCode: "SBD"
        },
        {
            countryCode: "ET",
            currencyCode: "ETB"
        },
        {
            countryCode: "SO",
            currencyCode: "SOS"
        },
        {
            countryCode: "ZW",
            currencyCode: "ZWL"
        },
        {
            countryCode: "SA",
            currencyCode: "SAR"
        },
        {
            countryCode: "ES",
            currencyCode: "EUR"
        },
        {
            countryCode: "ER",
            currencyCode: "ERN"
        },
        {
            countryCode: "ME",
            currencyCode: "EUR"
        },
        {
            countryCode: "MD",
            currencyCode: "MDL"
        },
        {
            countryCode: "MG",
            currencyCode: "MGA"
        },
        {
            countryCode: "MF",
            currencyCode: "EUR"
        },
        {
            countryCode: "MA",
            currencyCode: "MAD"
        },
        {
            countryCode: "MC",
            currencyCode: "EUR"
        },
        {
            countryCode: "UZ",
            currencyCode: "UZS"
        },
        {
            countryCode: "MM",
            currencyCode: "MMK"
        },
        {
            countryCode: "ML",
            currencyCode: "XOF"
        },
        {
            countryCode: "MO",
            currencyCode: "MOP"
        },
        {
            countryCode: "MN",
            currencyCode: "MNT"
        },
        {
            countryCode: "MH",
            currencyCode: "USD"
        },
        {
            countryCode: "MK",
            currencyCode: "MKD"
        },
        {
            countryCode: "MU",
            currencyCode: "MUR"
        },
        {
            countryCode: "MT",
            currencyCode: "EUR"
        },
        {
            countryCode: "MW",
            currencyCode: "MWK"
        },
        {
            countryCode: "MV",
            currencyCode: "MVR"
        },
        {
            countryCode: "MQ",
            currencyCode: "EUR"
        },
        {
            countryCode: "MP",
            currencyCode: "USD"
        },
        {
            countryCode: "MS",
            currencyCode: "XCD"
        },
        {
            countryCode: "MR",
            currencyCode: "MRO"
        },
        {
            countryCode: "IM",
            currencyCode: "GBP"
        },
        {
            countryCode: "UG",
            currencyCode: "UGX"
        },
        {
            countryCode: "TZ",
            currencyCode: "TZS"
        },
        {
            countryCode: "MY",
            currencyCode: "MYR"
        },
        {
            countryCode: "MX",
            currencyCode: "MXN"
        },
        {
            countryCode: "IL",
            currencyCode: "ILS"
        },
        {
            countryCode: "FR",
            currencyCode: "EUR"
        },
        {
            countryCode: "IO",
            currencyCode: "USD"
        },
        {
            countryCode: "SH",
            currencyCode: "SHP"
        },
        {
            countryCode: "FI",
            currencyCode: "EUR"
        },
        {
            countryCode: "FJ",
            currencyCode: "FJD"
        },
        {
            countryCode: "FK",
            currencyCode: "FKP"
        },
        {
            countryCode: "FM",
            currencyCode: "USD"
        },
        {
            countryCode: "FO",
            currencyCode: "DKK"
        },
        {
            countryCode: "NI",
            currencyCode: "NIO"
        },
        {
            countryCode: "NL",
            currencyCode: "EUR"
        },
        {
            countryCode: "NO",
            currencyCode: "NOK"
        },
        {
            countryCode: "NA",
            currencyCode: "NAD"
        },
        {
            countryCode: "VU",
            currencyCode: "VUV"
        },
        {
            countryCode: "NC",
            currencyCode: "XPF"
        },
        {
            countryCode: "NE",
            currencyCode: "XOF"
        },
        {
            countryCode: "NF",
            currencyCode: "AUD"
        },
        {
            countryCode: "NG",
            currencyCode: "NGN"
        },
        {
            countryCode: "NZ",
            currencyCode: "NZD"
        },
        {
            countryCode: "NP",
            currencyCode: "NPR"
        },
        {
            countryCode: "NR",
            currencyCode: "AUD"
        },
        {
            countryCode: "NU",
            currencyCode: "NZD"
        },
        {
            countryCode: "CK",
            currencyCode: "NZD"
        },
        {
            countryCode: "XK",
            currencyCode: "EUR"
        },
        {
            countryCode: "CI",
            currencyCode: "XOF"
        },
        {
            countryCode: "CH",
            currencyCode: "CHF"
        },
        {
            countryCode: "CO",
            currencyCode: "COP"
        },
        {
            countryCode: "CN",
            currencyCode: "CNY"
        },
        {
            countryCode: "CM",
            currencyCode: "XAF"
        },
        {
            countryCode: "CL",
            currencyCode: "CLP"
        },
        {
            countryCode: "CC",
            currencyCode: "AUD"
        },
        {
            countryCode: "CA",
            currencyCode: "CAD"
        },
        {
            countryCode: "CG",
            currencyCode: "XAF"
        },
        {
            countryCode: "CF",
            currencyCode: "XAF"
        },
        {
            countryCode: "CD",
            currencyCode: "CDF"
        },
        {
            countryCode: "CZ",
            currencyCode: "CZK"
        },
        {
            countryCode: "CY",
            currencyCode: "EUR"
        },
        {
            countryCode: "CX",
            currencyCode: "AUD"
        },
        {
            countryCode: "CR",
            currencyCode: "CRC"
        },
        {
            countryCode: "CW",
            currencyCode: "ANG"
        },
        {
            countryCode: "CV",
            currencyCode: "CVE"
        },
        {
            countryCode: "CU",
            currencyCode: "CUP"
        },
        {
            countryCode: "SZ",
            currencyCode: "SZL"
        },
        {
            countryCode: "SY",
            currencyCode: "SYP"
        },
        {
            countryCode: "SX",
            currencyCode: "ANG"
        },
        {
            countryCode: "KG",
            currencyCode: "KGS"
        },
        {
            countryCode: "KE",
            currencyCode: "KES"
        },
        {
            countryCode: "SS",
            currencyCode: "SSP"
        },
        {
            countryCode: "SR",
            currencyCode: "SRD"
        },
        {
            countryCode: "KI",
            currencyCode: "AUD"
        },
        {
            countryCode: "KH",
            currencyCode: "KHR"
        },
        {
            countryCode: "KN",
            currencyCode: "XCD"
        },
        {
            countryCode: "KM",
            currencyCode: "KMF"
        },
        {
            countryCode: "ST",
            currencyCode: "STD"
        },
        {
            countryCode: "SK",
            currencyCode: "EUR"
        },
        {
            countryCode: "KR",
            currencyCode: "KRW"
        },
        {
            countryCode: "SI",
            currencyCode: "EUR"
        },
        {
            countryCode: "KP",
            currencyCode: "KPW"
        },
        {
            countryCode: "KW",
            currencyCode: "KWD"
        },
        {
            countryCode: "SN",
            currencyCode: "XOF"
        },
        {
            countryCode: "SM",
            currencyCode: "EUR"
        },
        {
            countryCode: "SL",
            currencyCode: "SLL"
        },
        {
            countryCode: "SC",
            currencyCode: "SCR"
        },
        {
            countryCode: "KZ",
            currencyCode: "KZT"
        },
        {
            countryCode: "KY",
            currencyCode: "KYD"
        },
        {
            countryCode: "SG",
            currencyCode: "SGD"
        },
        {
            countryCode: "SE",
            currencyCode: "SEK"
        },
        {
            countryCode: "SD",
            currencyCode: "SDG"
        },
        {
            countryCode: "DO",
            currencyCode: "DOP"
        },
        {
            countryCode: "DM",
            currencyCode: "XCD"
        },
        {
            countryCode: "DJ",
            currencyCode: "DJF"
        },
        {
            countryCode: "DK",
            currencyCode: "DKK"
        },
        {
            countryCode: "VG",
            currencyCode: "USD"
        },
        {
            countryCode: "DE",
            currencyCode: "EUR"
        },
        {
            countryCode: "YE",
            currencyCode: "YER"
        },
        {
            countryCode: "DZ",
            currencyCode: "DZD"
        },
        {
            countryCode: "US",
            currencyCode: "USD"
        },
        {
            countryCode: "UY",
            currencyCode: "UYU"
        },
        {
            countryCode: "YT",
            currencyCode: "EUR"
        },
        {
            countryCode: "UM",
            currencyCode: "USD"
        },
        {
            countryCode: "LB",
            currencyCode: "LBP"
        },
        {
            countryCode: "LC",
            currencyCode: "XCD"
        },
        {
            countryCode: "LA",
            currencyCode: "LAK"
        },
        {
            countryCode: "TV",
            currencyCode: "AUD"
        },
        {
            countryCode: "TW",
            currencyCode: "TWD"
        },
        {
            countryCode: "TT",
            currencyCode: "TTD"
        },
        {
            countryCode: "TR",
            currencyCode: "TRY"
        },
        {
            countryCode: "LK",
            currencyCode: "LKR"
        },
        {
            countryCode: "LI",
            currencyCode: "CHF"
        },
        {
            countryCode: "LV",
            currencyCode: "EUR"
        },
        {
            countryCode: "TO",
            currencyCode: "TOP"
        },
        {
            countryCode: "LT",
            currencyCode: "LTL"
        },
        {
            countryCode: "LU",
            currencyCode: "EUR"
        },
        {
            countryCode: "LR",
            currencyCode: "LRD"
        },
        {
            countryCode: "LS",
            currencyCode: "LSL"
        },
        {
            countryCode: "TH",
            currencyCode: "THB"
        },
        {
            countryCode: "TF",
            currencyCode: "EUR"
        },
        {
            countryCode: "TG",
            currencyCode: "XOF"
        },
        {
            countryCode: "TD",
            currencyCode: "XAF"
        },
        {
            countryCode: "TC",
            currencyCode: "USD"
        },
        {
            countryCode: "LY",
            currencyCode: "LYD"
        },
        {
            countryCode: "VA",
            currencyCode: "EUR"
        },
        {
            countryCode: "VC",
            currencyCode: "XCD"
        },
        {
            countryCode: "AE",
            currencyCode: "AED"
        },
        {
            countryCode: "AD",
            currencyCode: "EUR"
        },
        {
            countryCode: "AG",
            currencyCode: "XCD"
        },
        {
            countryCode: "AF",
            currencyCode: "AFN"
        },
        {
            countryCode: "AI",
            currencyCode: "XCD"
        },
        {
            countryCode: "VI",
            currencyCode: "USD"
        },
        {
            countryCode: "IS",
            currencyCode: "ISK"
        },
        {
            countryCode: "IR",
            currencyCode: "IRR"
        },
        {
            countryCode: "AM",
            currencyCode: "AMD"
        },
        {
            countryCode: "AL",
            currencyCode: "ALL"
        },
        {
            countryCode: "AO",
            currencyCode: "AOA"
        },
        {
            countryCode: "AQ",
            currencyCode: "",
        },
        {
            countryCode: "AS",
            currencyCode: "USD"
        },
        {
            countryCode: "AR",
            currencyCode: "ARS"
        },
        {
            countryCode: "AU",
            currencyCode: "AUD"
        },
        {
            countryCode: "AT",
            currencyCode: "EUR"
        },
        {
            countryCode: "AW",
            currencyCode: "AWG"
        },
        {
            countryCode: "IN",
            currencyCode: "INR"
        },
        {
            countryCode: "AX",
            currencyCode: "EUR"
        },
        {
            countryCode: "AZ",
            currencyCode: "AZN"
        },
        {
            countryCode: "IE",
            currencyCode: "EUR"
        },
        {
            countryCode: "ID",
            currencyCode: "IDR"
        },
        {
            countryCode: "UA",
            currencyCode: "UAH"
        },
        {
            countryCode: "QA",
            currencyCode: "QAR"
        },
        {
            countryCode: "MZ",
            currencyCode: "MZN"
        }
    ]
}

export const flagAPI = 'http://purecatamphetamine.github.io/country-flag-icons/3x2/'