import { Box, HStack, VStack } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import BaseSkeleton from './BaseSkeleton';

const OrderTemplate = () => (
  <ShimmerSkeleton>
    <Box>
      <Text>Product name</Text>
      <ShimmerSkeleton.Block />
    </Box>
    <Box>
      <Text>SKU</Text>
      <ShimmerSkeleton.Block />
    </Box>
  </ShimmerSkeleton>
);

const MostProduct = () => (
  <ShimmerSkeleton>
    <HStack>
      <VStack>
        <ShimmerSkeleton.Block />
        <HStack>
          <Text>Order ID</Text>
          <ShimmerSkeleton.Block />
        </HStack>
        <HStack>
          <Text>Reference</Text>
          <ShimmerSkeleton.Block />
        </HStack>
      </VStack>
      <ShimmerSkeleton.Block />
    </HStack>
  </ShimmerSkeleton>
);

const SummaryNumber = ({ subBlock, ...rest }) => (
  <ShimmerSkeleton maxW="152px" w="50%" ml="auto" {...rest}>
    <ShimmerSkeleton.Block h="1.625rem" mt={2} w="100%" />
    {subBlock && <ShimmerSkeleton.Block h="0.75rem" mt="9px" w="100%" />}
  </ShimmerSkeleton>
);

const DASHBOARD_TEMPLATES = {
  order: OrderTemplate,
  mostProduct: MostProduct,
  summaryNumber: SummaryNumber
};

const DashboardSkeleton = (props) => {
  return <BaseSkeleton baseTemplates={DASHBOARD_TEMPLATES} {...props} />;
};

export default DashboardSkeleton;
