import { Checkbox } from '@chakra-ui/react';
import classNames from 'classnames';
import { CheckboxGroup, Stack, chakra } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzCheckBoxGroup = (props) => {
  const { data, error, onValueChange } = props;
  const className = classNames({
    'checkbox-list-wrapper': true
  });

  const onCheckBoxValueChange = (value, id) => {
    onValueChange && onValueChange({ value, id });
  };
  return (
    <CheckboxGroup id={uniqueId(`${SEL_PREFIX_ID}-Slz-CheckboxGroup`)}>
      <Stack direction={['column']} className={className}>
        {data &&
          data.map(({ id, name, selected, ...checkboxProps }, index) => (
            <Checkbox
              margin={0}
              {...checkboxProps}
              key={id}
              id={`Slz-checkbox-${id}`}
              data-testid={`Slz-checkbox-${id}`}
              defaultChecked={selected}
              isInvalid={index === data.length - 1 && error}
              onChange={(event) => onCheckBoxValueChange(event.target.checked, id)}>
              {name}
            </Checkbox>
          ))}
        {error && (
          <chakra.span
            __css={{ color: ' #de4259' }}
            className="error-message">{`*${error}`}</chakra.span>
        )}
      </Stack>
    </CheckboxGroup>
  );
};
export default SlzCheckBoxGroup;
