import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const quantity = definePartsStyle({
  content: defineStyle({
    width: '9.75rem',
    height: '6rem',
    borderRadius: '0.375rem',
    arrow: {
      transform: ' rotate(0deg) translate3d(15px, 0px, 0px) scaleX(3) !important '
    }
  }),
  body: defineStyle({
    padding: 3,
    width: '9.75rem',
    height: '6rem',
    textAlign: 'start',
    borderRadius: '6px',
    heading: {
      fontSize: '0.75rem',
      fontWeight: 'medium',
      lineHeight: '1rem',
      letterSpacing: '0'
    },
    description: {
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      letterSpacing: '-0.1px'
    }
  }),
  header: defineStyle({
    color: 'red'
  }),
  footer: defineStyle({
    fontSize: 'xl'
  })
});

const apiConnectionVariant = definePartsStyle({
  content: defineStyle({
    borderRadius: 3
  }),
  body: defineStyle({
    px: 6,
    pt: 5,
    pb: 3,
    borderRadius: 3,
    heading: {
      fontSize: 'sm'
    },
    description: {
      fontSize: 'xs',
      pb: 1.5
    }
  })
});
const emailNoteVariant = definePartsStyle({
  content: defineStyle({
    borderRadius: 3,
    w: '13.063rem'
  }),
  body: defineStyle({
    px: 6,
    pt: 5,
    pb: 3,
    borderRadius: 3,
    heading: {
      fontSize: 'sm'
    },
    description: {
      fontSize: 'xs',
      pb: 1.5
    }
  })
});

const outline = definePartsStyle({
  content: defineStyle({
    borderColor: 'red.300'
  }),
  body: defineStyle({
    pr: 6,
    pl: 5,
    pt: 5,
    pb: 2.75,
    heading: {
      fontSize: 'sm',
      display: 'flex',
      alignItems: 'flex-end'
    },
    description: {
      fontSize: 'xs',
      pb: 1.5
    }
  })
});

const returnSettings = definePartsStyle({
  content: defineStyle({
    width: '18.75rem',
    height: '7.25rem',
    borderRadius: '0.375rem',
    arrow: {
      transform: ' rotate(0deg) translate3d(15px, 0px, 0px) scaleX(3) !important '
    },
    borderRadius: 3
  }),
  body: defineStyle({
    px: 6,
    pt: 5,
    pb: 3,
    width: '100%',
    height: '100%',
    textAlign: 'start',
    heading: {
      fontSize: '0.75rem',
      fontWeight: 'medium',
      lineHeight: '1rem',
      letterSpacing: '0'
    },
    description: {
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
      letterSpacing: '-0.1px'
    }
  }),
  header: defineStyle({
    color: 'red'
  }),
  footer: defineStyle({
    fontSize: 'xl'
  })
});

export default defineMultiStyleConfig({
  variants: {
    quantity,
    apiConnection: apiConnectionVariant,
    outline,
    emailNote: emailNoteVariant,
    returnSettings
  }
});
