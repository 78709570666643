import { Icon } from '@chakra-ui/react';
import React from 'react';

export const CalendarIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" {...props}>
      <g id="CalendarIcon" data-name="Group 3644" transform="translate(-430 -2535)">
        <rect
          id="Rectangle_5593"
          data-name="Rectangle 5593"
          width="16"
          height="16"
          rx="2"
          transform="translate(431 2538)"
          fill="none"
          stroke="#3474e2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_553"
          data-name="Line 553"
          y2="4"
          transform="translate(443 2536)"
          fill="none"
          stroke="#3474e2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_554"
          data-name="Line 554"
          y2="4"
          transform="translate(435 2536)"
          fill="none"
          stroke="#3474e2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_551"
          data-name="Line 551"
          x2="16"
          transform="translate(431 2544)"
          fill="none"
          stroke="#3474e2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};
