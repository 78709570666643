import {
  Box,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import { RETURN_SETTING_STYLE, RETURN_SETTING_GRADING_TEXT } from 'modules/preferences/constants';

const ReturnSettingGrade = ({ information }) => {
  return (
    <Box mt={3}>
      <Text fontSize="0.875rem" fontWeight="bold" w="full" color="dark.700" lineHeight="0.875rem">
        {information?.grade} - {information?.description}
      </Text>
      <Text fontSize="0.625rem" w="full" color="dark.700">
        {information?.meaning}
      </Text>
    </Box>
  );
};

const ReturnSettingGradeDesc = ({ gradingInformation, disabled }) => {
  const gradingInformationSorted = gradingInformation?.sort((a, b) =>
    a.grade.localeCompare(b.grade)
  );
  const gradeInstructionStyles = disabled
    ? RETURN_SETTING_STYLE.settingGradeDescDisabled
    : RETURN_SETTING_STYLE.settingGradeDesc;

  return (
    <Popover placement="bottom" arrowSize="17" gutter={6} trigger="hover">
      <PopoverTrigger>
        <Box __css={gradeInstructionStyles}>
          <Text>{RETURN_SETTING_GRADING_TEXT.gardeDescription.header}</Text>
        </Box>
      </PopoverTrigger>
      {!disabled && gradingInformationSorted && (
        <PopoverContent borderRadius={3} bg="main.300" maxW="16.563rem" boxShadow="slz-md">
          <PopoverArrow bg="main.300" />
          <PopoverCloseButton color="dark.500" />
          <PopoverBody py={6} ps={6} pe={4}>
            <Heading fontSize="1.188rem">Selazar grading scale</Heading>
            <Text fontSize="0.625rem" w="full" color="dark.700">
              {RETURN_SETTING_GRADING_TEXT.gardeDescription.description}
            </Text>
            <Box>
              {gradingInformationSorted.map((information) => (
                <ReturnSettingGrade key={uniqueId()} information={information} />
              ))}
            </Box>
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};
export default ReturnSettingGradeDesc;
