import { Box, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { AiOutlineDownload, AiOutlineFile } from 'react-icons/ai';
import { v4 as uuid } from 'uuid';
import { SlzChip } from 'components/common/SlzChip';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';
import { SlzButton } from 'components/common/SlzButton';
import {
  PRODUCT_EXAMPLE_CSV_FILE,
  PRODUCT_EXAMPLE_CSV_FILE_NAME
} from 'components/common/Constants';
import { CsvUpload } from 'components/common/Icons';
import Files from './Files';

const Upload = ({ csvFiles, setCsvFiles }) => {
  const onLoadFile = (file, reader) => {
    setCsvFiles((oldList) => [
      ...oldList,
      {
        id: uuid(),
        name: file.name,
        text: reader.result,
        file
      }
    ]);
  };

  const uploadCSVForm = (
    <VStack
      mb={2}
      p={5}
      sx={{
        background: 'light.300',
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: 'dark.300',
        borderRadius: 3
      }}
      textAlign="center">
      <Box sx={{ py: 3 }}>
        <CsvUpload color="dark.500" />
      </Box>
      <Text
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '0px',
          color: 'dark.500',
          textAlign: 'center'
        }}
        mb={3}>
        Drag and drop your file here <Box as="br"></Box> or
      </Text>
      <SlzButton>Browse</SlzButton>
    </VStack>
  );
  const downloadURI = () => {
    var link = document.createElement('a');
    link.download = PRODUCT_EXAMPLE_CSV_FILE_NAME;
    link.href = PRODUCT_EXAMPLE_CSV_FILE;
    link.click();
  };

  return (
    <>
      <HStack align="baseline" mb={1.5}>
        <Text
          sx={{
            fontSize: '20px',
            lineHeight: '26px',
            letterSpacing: '0px',
            color: 'dark.500',
            mt: -4
          }}>
          CSV upload
        </Text>
      </HStack>
      <HStack align="baseline" justifyContent="space-between" alignItems="center" mb={2.5}>
        <Box flex={1}>
          <VStack alignContent="flex-start" alignItems="flex-start">
            <HStack>
              <SlzChip px={1.5} mr={-0.5} py={1.25}>
                <AiOutlineFile size={14} />
              </SlzChip>
              <Text
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '0px',
                  color: 'dark.700'
                }}>
                csv
              </Text>
            </HStack>
          </VStack>
        </Box>
        <Box flex={1} justifyContent="flex-end" textAlign="right">
          <SlzButton rightIcon={<AiOutlineDownload size={18} />} onClick={() => downloadURI()}>
            CSV Example
          </SlzButton>
        </Box>
      </HStack>
      <SlzUpload uploadForm={uploadCSVForm} onLoadFile={onLoadFile} acceptType=".csv" />
      {csvFiles.length > 0 && <Files csvFiles={csvFiles} setCsvFiles={setCsvFiles} />}
    </>
  );
};

export default Upload;
