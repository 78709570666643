import { Skeleton } from '../Skeleton/Skeleton';
import { skeletonAnimation as animation } from '../Skeleton/config';
import { getHeightOfCell } from './Styles';
import { Td } from '@chakra-ui/react';

const SkeletonCell = ({ styles, variant }) => {
  return (
    <Td
      sx={{
        p: 0,
        ...getHeightOfCell(variant),
        ...styles
      }}>
      <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
    </Td>
  );
};

export default SkeletonCell;
