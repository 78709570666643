import { Container } from '@chakra-ui/react';

const ForbiddenPage = () => {
  return (
    <Container centerContent>
      <strong>You don't have permission to access this page</strong>
    </Container>
  );
};

export default ForbiddenPage;
