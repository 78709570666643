import { DragHandleIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { PRODUCTS_STEPS, TAG_DEFAULT_COLOR, TAG_DEFAULT_NAME } from 'modules/products/constants';
import { ProductAddModal, ProductEditModal } from 'modules/products/components/modal';
import { PRODUCT_KEY } from 'components/common/SlzTable/Constants';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import SlzTable from 'components/common/SlzTable/Table';
import useLocalStorage from 'hooks/useLocalStorage';
import { useQuery } from 'hooks/useQuery';
import { convertProduct, fake, productCols } from './data';

const filterOptions = [
  {
    key: 'hard',
    text: 'Hard',
    colorScheme: 'szrElectricBlue'
  },
  {
    key: 'easy',
    text: 'Easy',
    colorScheme: 'ibiza'
  },
  {
    key: 'difficult',
    text: 'Difficult',
    colorScheme: 'californiaOrange'
  },
  {
    key: 'finished',
    text: 'Finished',
    colorScheme: 'tetra'
  }
];

const defaultValue = {
  tag: { name: TAG_DEFAULT_NAME, color: TAG_DEFAULT_COLOR },
  isReturnable: true,
  isPrePacked: false,
  isFragile: false,
  requiresBoxPackaging: false,
  requiresPaperPackaging: false
};
const ProductTable = (props) => {
  const { isOpenAddProduct, onCloseAddProduct, ...rest } = props;
  const [products, setProducts] = useLocalStorage(PRODUCT_KEY, fake());
  const query = useQuery();
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: !!query.get('modal') });
  const [selectedItem, setSelectedItem] = useState(
    () => query.get('itemId') && find(products, ({ id }) => id == query.get('itemId'))
  );

  const handleShowingModal = (row, step) => {
    if (!selectedItem || selectedItem.id !== row.id) {
      setSelectedItem({ ...row });
      query.set('itemId', row.id);
    }
    query.set('modal', step);
    history.push({ search: query.toString() });
    onOpen();
  };

  const handleCloseModal = () => {
    query.delete('itemId');
    query.delete('modal');
    history.push({ search: query.toString() });
    onClose();
  };

  const handleUpdateProduct = (product) => {
    const cpProducts = [...products];
    const foundIndex = findIndex(cpProducts, ({ id }) => id === product.id);
    if (foundIndex !== -1) {
      cpProducts[foundIndex] = product;
    }
    setProducts(cpProducts);
    handleCloseModal();
  };

  const columns = [...productCols];
  columns[1] = {
    name: 'Product',
    field: 'name',
    isSorted: true,
    showImage: true,
    isClickable: true,
    onClick: (row) => handleShowingModal(row, PRODUCTS_STEPS.OVERVIEW)
  };

  const actions = {
    icon: <DragHandleIcon w={5} h={5} />,
    list: [
      {
        label: 'Product Overview',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.OVERVIEW)
      },
      {
        label: 'Sales & Pricing',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.SALES_PRICING)
      },
      {
        label: 'Packaging Preferences',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.PACKAGES_PREFERENCE)
      },
      {
        label: 'Return information',
        onClick: (e, row) => handleShowingModal(row, PRODUCTS_STEPS.RETURN_INFORMATION)
      }
    ]
  };

  const AddProduct = ({ isOpenAddProduct, onCloseAddProduct }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    useEffect(() => {
      onOpen();
    }, [isOpenAddProduct]);
    const handleClose = () => {
      onClose();
      onCloseAddProduct && onCloseAddProduct();
    };
    const onSave = (items) => {
      const newProducts = [...products];
      items?.products?.map((value) => {
        const newValue = convertProduct(value);
        newProducts.splice(0, 0, newValue);
      });
      setProducts(newProducts); // Call API and reload( in temp case, get it in localstorage)
      onCloseAddProduct && onCloseAddProduct();
      onClose();
    };
    return (
      <ProductAddModal
        onClose={handleClose}
        isOpen={isOpen}
        type={props?.addType || null}
        onSave={(values) => onSave(values)}
        formValues={defaultValue}
      />
    );
  };

  return (
    <Fragment>
      <ProductEditModal
        currentViewIdx={parseInt(query.get('modal'))}
        isOpen={isOpen}
        formValues={selectedItem}
        onClose={handleCloseModal}
        onSave={handleUpdateProduct}
      />
      {isOpenAddProduct && (
        <AddProduct isOpenAddProduct={isOpenAddProduct} onCloseAddProduct={onCloseAddProduct} />
      )}
      <SlzTable
        variant="simple"
        sizes={['sm', 'md', 'lg']}
        showMarked={true}
        showHeader={true}
        columns={columns}
        data={products}
        displayFields={['name', 'sku', 'suid', 'tag', 'totalStock', 'createdDate']}
        hyperLinks={['name']}
        filters={{
          title: 'Tags',
          byField: 'tag',
          options: filterOptions
        }}
        filterByDate={{
          field: 'createdDate',
          start: rest?.startDate,
          end: rest?.endDate
        }}
        actions={actions}
        pagination={{
          isLocal: true,
          pages: {
            onChange: (numPerPage) => console.log(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS
          },
          direction: {
            usingDots: false,
            // numDots: 5,
            isGreyBg: true,
            onChange: (currentPage) => console.log(currentPage)
          }
        }}
      />
    </Fragment>
  );
};

export default ProductTable;
