import React, { useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Box, FormControl, VStack } from '@chakra-ui/react';
import * as yup from 'yup';

import { SlzButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';
import SlzLink from 'components/common/SlzLink/SlzLink';
import { FormInput, FormLabel } from 'modules/users/components/form';
import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { FORGOT_PASSWORD_PATHNAME } from 'modules/users/constants';

const defaultValues = {
  email: '',
  password: ''
};

const validationSchema = yup.object({
  email: yup.string().required('We need your email address'),
  password: yup.string().required('We need your password')
});

const LoginForm = ({ onLogin }) => {
  const [email, setEmail] = useState('');

  const history = useHistory();

  const onChangeEmail = (value) => {
    setEmail(value);
  };

  return (
    <FormWrapper defaultValues={defaultValues} validationSchema={validationSchema}>
      {({ methods, errors, getFieldStatus, resetFieldStatus, clearField }) => {
        return (
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onLogin)}>
              <FormControl mb="5" isInvalid={errors?.email}>
                <FormLabel>Email address</FormLabel>
                <FormInput
                  name="email"
                  showIcon
                  placeholder={errors?.email?.message || 'Email address'}
                  otherPlaceholder="e.g. username@company.com"
                  methods={methods}
                  value={email}
                  onChangeValue={onChangeEmail}
                  status={getFieldStatus('email')}
                  onFocus={() => resetFieldStatus('email')}
                  onClickError={() => clearField('email')}
                  autoComplete
                />
              </FormControl>

              <FormControl isInvalid={errors?.password}>
                <FormLabel>Password</FormLabel>
                <SlzInput
                  {...methods.register('password')}
                  showIcon
                  type="password"
                  placeholder={errors?.password?.message}
                  status={getFieldStatus('password')}
                  onFocus={() => resetFieldStatus('password')}
                  onClickError={() => clearField('password')}
                />
              </FormControl>

              <Box textAlign="end" my="7px">
                <SlzLink href={FORGOT_PASSWORD_PATHNAME} color="main.500" fontWeight="medium">
                  Forgot password?
                </SlzLink>
              </Box>

              <VStack mb={6} spacing={6}>
                <SlzButton type="submit" size="lg" w="100%">
                  Login
                </SlzButton>
                <SlzButton
                  size="lg"
                  w="100%"
                  variant="outline"
                  onClick={() => history.push('/register/retailer')}>
                  Create an account
                </SlzButton>
              </VStack>
            </form>
          </FormProvider>
        );
      }}
    </FormWrapper>
  );
};

export default LoginForm;
