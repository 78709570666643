import { SlzButton } from 'components/common/SlzButton';

import { CustomBoxSplitSetup, CustomBoxSplitSummary } from 'modules/b2b-orders/components/other';
import { B2B_ORDER_ACTION_CONTENT } from '.';

export const ACTION_BUTTON_PROGRESS = {
  validating: ({ onValidateBoxSplit, isValidating }) => (
    <SlzButton variant="solid" size="lg" isLoading={isValidating} onClick={onValidateBoxSplit}>
      {B2B_ORDER_ACTION_CONTENT.check}
    </SlzButton>
  ),
  summary: ({ onNextToSummary }) => (
    <SlzButton variant="solid" size="lg" onClick={onNextToSummary}>
      {B2B_ORDER_ACTION_CONTENT.next}
    </SlzButton>
  ),
  done: ({ disableNextButton }) => (
    <SlzButton variant="solid" type="submit" size="lg" isDisabled={disableNextButton}>
      {B2B_ORDER_ACTION_CONTENT.next}
    </SlzButton>
  )
};

export const CUSTOM_BOX_PROGRESS = {
  setup: (props) => <CustomBoxSplitSetup {...props} />,
  customBoxSummary: (props) => <CustomBoxSplitSummary {...props} />
};
