import React, { useState } from 'react';

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  FormControl,
  Box
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';

import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzSplitButton } from 'components/common/SlzButton';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import {
  BUTTON_LABEL,
  EDITING_OPTIONS_BUTTON,
  INVALIDATION_EORI_NUMBER_CONFIRMATION,
  OPTIONS_BUTTON,
  REMOVE_EORI_NUMBER_CONFIRMATION
} from 'modules/preferences/constants';
import { DATE_FORMAT_DDMMYYYY } from 'constants';
import { ControlledField } from 'components/form';

const EORITable = ({ items, enableRows = [], onClick, onSave, onRemove }) => {
  const methods = useFormContext();
  const { control, register } = methods;
  const { eoriNumbers } = useWatch({ control: control });
  const [activatedIndexPopovers, setActivatedIndexPopovers] = useState([]);
  const [popoverInformation, setPopoverInformation] = useState(
    INVALIDATION_EORI_NUMBER_CONFIRMATION
  );

  const getLabelButton = (eoriNumber, index) => {
    if (enableRows?.includes(index)) return EDITING_OPTIONS_BUTTON.save.text;
    if (eoriNumber) return OPTIONS_BUTTON.edit.text;
    return BUTTON_LABEL;
  };

  const getOptionsButton = (eoriNumber, index) => {
    const editingOptionsButtons = Object.values(EDITING_OPTIONS_BUTTON);
    const optionsButtons = Object.values(OPTIONS_BUTTON);

    if (enableRows?.includes(index)) {
      return editingOptionsButtons;
    } else if (eoriNumber) {
      return optionsButtons;
    } else {
      return [];
    }
  };

  const handleSaveEORINumber = (index) => {
    const eoriNumber = eoriNumbers[index];
    if (eoriNumber?.eoriNumber?.trim()) {
      onSave(eoriNumber, index);
    } else {
      setPopoverInformation(INVALIDATION_EORI_NUMBER_CONFIRMATION);

      handleOpenPopoverConfirm(index);
    }
  };

  const handleClickButton = (index) => {
    if (enableRows?.includes(index)) {
      handleSaveEORINumber(index);
    } else {
      onClick(index);
    }
  };

  const handleSelectedButton = (item, index) => {
    const actions = {
      [EDITING_OPTIONS_BUTTON.save.value]: () => handleSaveEORINumber(index),
      [EDITING_OPTIONS_BUTTON.cancel.value]: () => {
        methods.resetField(`eoriNumbers.${index}`);
        onClick(index);
      },
      [OPTIONS_BUTTON.edit.value]: () => onClick(index),
      [OPTIONS_BUTTON.remove.value]: () => {
        setPopoverInformation(REMOVE_EORI_NUMBER_CONFIRMATION);
        handleOpenPopoverConfirm(index);
      }
    };

    const action = actions[item.value];
    if (action) {
      action();
    }
  };

  const handleOKPopoverConfirm = (identifier, index) => {
    const isOk = popoverInformation.okText === INVALIDATION_EORI_NUMBER_CONFIRMATION.okText;
    if (isOk) {
      handleClosePopoverConfirm(index);
    } else {
      handleRemoveEORINumber(identifier);
    }
  };

  const handleRemoveEORINumber = (identifier) => {
    onRemove(identifier);
  };

  const handleCancelPopoverConfirm = (index) => {
    methods.reset();
    enableRows.includes(index) && onClick(index);
    handleClosePopoverConfirm(index);
  };

  const handleOpenPopoverConfirm = (index) => {
    setActivatedIndexPopovers((prevActivatedIndexPopovers) => [
      ...prevActivatedIndexPopovers,
      index
    ]);
  };

  const handleClosePopoverConfirm = (selectedIndex) => {
    setActivatedIndexPopovers((prevActivatedIndexPopovers) =>
      prevActivatedIndexPopovers.filter((index) => index !== selectedIndex)
    );
  };

  return (
    <TableContainer
      boxShadow="slz-md"
      borderRadius="1.5"
      border="1px solid"
      borderColor="light.700"
      overflow="unset"
      overflowY="unset">
      <Table variant="setting" w="full" borderRadius="1.5">
        <Thead>
          <Tr>
            <Th borderRadius="1.5">Region</Th>
            <Th>EORI Number</Th>
            <Th>Date Added</Th>
            <Th borderRadius="1.5"></Th>
          </Tr>
        </Thead>

        <Tbody>
          {items?.map(({ identifier, eoriNumber, region, lastEdited }, index) => (
            <Tr key={identifier}>
              <Td className={eoriNumber && 'active-cell'}>
                <Text className={eoriNumber && 'active-text'}>{region}</Text>
              </Td>
              <Td className={(eoriNumber || enableRows?.includes(index)) && 'active-cell'}>
                <FormControl>
                  <Box pos="relative">
                    <SlzPopoverConfirm
                      title={popoverInformation?.title}
                      description={popoverInformation?.description?.replace('%region%', region)}
                      okText={popoverInformation?.okText}
                      isOpen={activatedIndexPopovers?.includes(index)}
                      onClose={() => handleClosePopoverConfirm(index)}
                      onCancel={() => handleCancelPopoverConfirm(index)}
                      onOk={() => handleOKPopoverConfirm(identifier, index)}>
                      <SlzInput
                        name="eoriNumber"
                        value={eoriNumbers[index]?.eoriNumber || ''}
                        placeholder="GB68348666"
                        {...register(`eoriNumbers.${index}.eoriNumber`, {
                          required: !!eoriNumber.length || !!eoriNumbers[index]?.eoriNumber.length
                        })}
                        size="xs"
                        rounded="md"
                        disabled={!enableRows?.includes(index)}
                        sx={{ _disabled: { border: '1px solid currentColor' } }}
                      />
                    </SlzPopoverConfirm>
                  </Box>
                </FormControl>
              </Td>
              <Td className={eoriNumber && 'active-cell'}>
                <Text>{lastEdited || DATE_FORMAT_DDMMYYYY}</Text>
              </Td>
              <Td className="active-cell button-group">
                <SlzSplitButton
                  variant="outline"
                  size="sm"
                  placeholder={getLabelButton(eoriNumber, index)}
                  onlyShowPlaceholder
                  items={getOptionsButton(eoriNumber, index)}
                  onClick={() => handleClickButton(index)}
                  onSelected={(item) => handleSelectedButton(item, index)}
                  sx={{ backgroundColor: 'white' }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default EORITable;
