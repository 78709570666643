import React from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, Flex, FormControl, Image, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormLabel } from 'modules/users/components/form';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzButton } from 'components/common/SlzButton';
import { FORGOT_PASSWORD_TEXT, MESSAGE } from 'modules/users/constants';
import { InvalidCredentialsAlert, UserFormHeading } from 'modules/users/components/other';

export default function ForgotPasswordForm({ onSubmit, isLoading, errorFromAPI }) {
  const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;
  const validationSchema = yup.object({
    email: yup.string().email().required(MESSAGE.FORGOT.ERROR.EMAIL),
    areYouHuman: yup.string().required()
  });
  const methods = useForm({
    resolver: yupResolver(validationSchema)
  });
  const errors = methods.formState.errors;

  const handleOnChange = (value) => {
    if (!value) return;
    methods.setValue('areYouHuman', value);
  };

  const reCaptchaStyle = {
    border: errors.areYouHuman && '1px solid red',
    overflow: 'hidden',
    width: 303,
    height: 76,
    borderRadius: 3
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        <FormControl mb="12">
          <Image src="Images/selazar-login.svg" mb={5} mt="24.26vh"/>
          {errors?.email && errors?.email.type === 'email' && (
            <InvalidCredentialsAlert
              mb={4}
              mt={1}
              title={MESSAGE.FORGOT.ERROR.TITLE}
              errorDescription={MESSAGE.FORGOT.ERROR.DESCRIPTION}
              hintDescription={MESSAGE.FORGOT.ERROR.HINT_DESCRIPTION}
              hintDescriptionHighLight={MESSAGE.FORGOT.ERROR.HINT_DESCRIPTION_HIGH_LIGHT}
            />
          )}
          {!errors?.email && errorFromAPI && (
            <InvalidCredentialsAlert
              mb={4}
              mt={1}
              title={MESSAGE.FORGOT.ERROR.USER_NOT_CORRECT.TITLE}
              errorDescription={MESSAGE.FORGOT.ERROR.USER_NOT_CORRECT.DESCRIPTION}
              hintDescription={MESSAGE.FORGOT.ERROR.USER_NOT_CORRECT.HINT_DESCRIPTION}
            />
          )}
          <UserFormHeading text={FORGOT_PASSWORD_TEXT.passwordReset} />
          <Text>{FORGOT_PASSWORD_TEXT.instructionPasswordFirst}</Text>
          <Text mb="5">{FORGOT_PASSWORD_TEXT.instructionPasswordSecond}</Text>
          <Box mb="12">
            <FormLabel>{FORGOT_PASSWORD_TEXT.emailAddress}</FormLabel>
            <SlzInput
              showIcon
              placeholder={errors.email ? errors.email.message : FORGOT_PASSWORD_TEXT.emailAddress}
              _placeholder={{ color: errors.email && 'red.500' }}
              {...methods.register('email')}
              status={errors.email && 'error'}
              sx={{ color: errors.email && 'checkbox.invalid.500' }}
            />
          </Box>

          <VStack>
            <ReCAPTCHA
              style={reCaptchaStyle}
              sitekey={captchaKey}
              onChange={handleOnChange}
              onExpired={() => methods.setValue('areYouHuman', '')}
            />
            <Flex width={'18.938rem'}>
              <Text fontSize="0.75rem" color="checkbox.invalid.500">
                {errors?.areYouHuman?.message && MESSAGE.FORGOT.ERROR.ARE_YOU_HUMAN}
              </Text>
            </Flex>
          </VStack>
        </FormControl>

        <Flex mb={5} mt={10} justifyContent="center">
          <SlzButton isLoading={isLoading} w={'full'} type="submit" size="lg">
            {FORGOT_PASSWORD_TEXT.resetPassword}
          </SlzButton>
        </Flex>
      </form>
    </FormProvider>
  );
}
