import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';

import { getDefaultState, interval } from 'utils';
import {
  DATE_FORMAT_DDMMYY,
  DAY_STRING_DEFAULT_DISPLAY,
  PRESETS_MAPPER,
  END_DATE_MAPPER
} from 'constants';
import { ClearIcon, ArrowRightIcon } from 'components/common/Icons';

import SlzDefinedRange from './SlzDefinedRange';

const DateLabel = ({ startDate, endDate, dataLabelStyle }) => {
  return (
    <Box sx={dataLabelStyle}>
      {startDate && endDate ? (
        <>
          <span>{startDate.format(DATE_FORMAT_DDMMYY)}</span>
          <ArrowRightIcon sx={dataLabelStyle.arrowRightIcon} />
          <span>{endDate.format(DATE_FORMAT_DDMMYY)}</span>
        </>
      ) : (
        DAY_STRING_DEFAULT_DISPLAY
      )}
    </Box>
  );
};

const PickerFooter = ({
  footerBtnStyle,
  startDate,
  endDate,
  dataLabelStyle,
  onClearSelectedDates
}) => {
  return (
    <Box sx={footerBtnStyle} data-testid="slz-date-range-picker-calendar-footer">
      <span className="drp-selected">
        <DateLabel startDate={startDate} endDate={endDate} dataLabelStyle={dataLabelStyle} />
      </span>
      <Box className="clear-btn" onClick={onClearSelectedDates}>
        <Box>
          <ClearIcon />
        </Box>
        <Box>Clear selection</Box>
      </Box>
    </Box>
  );
};

const SlzPickerUI = (props) => {
  const {
    component: Component,
    selectedDefinedRange,
    onDateRangeClick,
    disableChoosingPrev,
    onClearSelection
  } = props;
  const [pickerState, setPickerState] = useState({ ...getDefaultState() });
  const { footerBtnStyle, dataLabelStyle } = props.styles;
  const componentProps = { ...props, ...pickerState };

  useEffect(() => {
    if (!isEmpty(props.startDate) && !isEmpty(props.endDate)) {
      setPickerState({ ...pickerState, startDate: props.startDate, endDate: props.endDate });
    }
  }, [props.startDate, props.endDate]);

  const onDayMouseEnter = (day) => {
    let { startDate, endDate } = pickerState;
    const range = day >= startDate ? endDate || day : startDate;
    setPickerState({ ...pickerState, range });
  };

  const updateRange = (stateStartDate, stateEndDate, day) => {
    let startDate = day < stateStartDate ? day : stateStartDate;
    let endDate = day > stateEndDate ? day : stateEndDate;

    if (stateStartDate < day && day < stateEndDate) {
      stateEndDate - day > day - stateStartDate ? (startDate = day) : (endDate = day);
    }

    const range = endDate || startDate;
    setPickerState({ ...pickerState, startDate, endDate, range });
    onDateRangeClick && onDateRangeClick(startDate, endDate);
  };

  const onDayClick = (day) => {
    if (pickerState.startDate && pickerState.endDate) {
      updateRange(pickerState.startDate, pickerState.endDate, day);
      return;
    }

    const isOpen = pickerState.closedOrOpen === interval.OPEN;
    const startDate = isOpen && day ? pickerState.startDate : day;
    const { endDate, closedOrOpen } = END_DATE_MAPPER[disableChoosingPrev](isOpen, day, startDate);

    setPickerState({
      ...pickerState,
      startDate,
      endDate,
      closedOrOpen,
      range: endDate || startDate
    });
    onDateRangeClick && onDateRangeClick(startDate, endDate);
  };

  const onClearSelectedDates = () => {
    onClearSelection();
    setPickerState({
      ...pickerState,
      startDate: null,
      endDate: null,
      closedOrOpen: interval.CLOSED,
      range: null,
      type: null,
      isActive: false
    });
  };

  const onDefinedRangeClick = (from, to, id) => {
    setPickerState({
      ...pickerState,
      startDate: from,
      endDate: to,
      closedOrOpen: interval.CLOSED,
      range: to || from,
      type: id,
      isActive: true
    });

    onDateRangeClick && onDateRangeClick(from, to, id);
  };

  const handleRenderDate = (id) => {
    const calendar = dayjs();
    onClearSelectedDates();
    const { toDate = calendar.clone(), fromDate = calendar.clone() } =
      PRESETS_MAPPER(calendar.clone(), calendar, id) || {};

    onDefinedRangeClick(fromDate, toDate, id);
  };

  return (
    <Box data-testid="slz-date-range-picker-calendar">
      <Box style={{ display: 'inline-flex' }}>
        <SlzDefinedRange
          onActiveClicked={handleRenderDate}
          isActive={pickerState.isActive}
          selectedDefinedRange={selectedDefinedRange}
        />
        <Box data-testid="slz-calendar">
          <Component
            onDayClick={onDayClick}
            onDayMouseEnter={onDayMouseEnter}
            {...componentProps}
          />
        </Box>
      </Box>
      <PickerFooter
        startDate={props.startDate}
        endDate={props.endDate}
        dataLabelStyle={dataLabelStyle}
        footerBtnStyle={footerBtnStyle}
        onClearSelectedDates={onClearSelectedDates}
      />
    </Box>
  );
};

export default SlzPickerUI;
