import React, { useEffect, useState } from 'react';
import { GridItem } from '@chakra-ui/react';

import { TAG_DEFAULT_COLOR } from 'components/common/Constants';
import { SlzModal } from 'components/common/SlzModal';
import { EDIT_BUNDLE_STEP, Mode } from '../../constants';
import BundleModalContent from '../other/BundleModalContent';
import { BundleEditHeader } from '../other/BundleModalHeader';
import { useBundleDetailQuery } from '../../hooks/useBundleDetailQuery';
import { SkeletonModalTemplate } from 'components/common/Skeleton/Skeleton';
import { mapBundleDetail } from '../../mappers/bundle-list-mapper';

export default function EditBundleModal({
  bundleId,
  isOpen,
  onSubmitBundle,
  onClose,
  onOpenModalDelete
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [indicatorLabels, setIndicatorLabels] = useState([]);
  const [step, setStep] = useState(EDIT_BUNDLE_STEP.BUNDLE_DETAILS);
  const [colorTag, setColorTag] = useState(TAG_DEFAULT_COLOR);
  const [bundle, setBundle] = useState({});
  const [products, setProducts] = useState([]);

  const { data: bundleDetail, isFetching } = useBundleDetailQuery(
    bundleId,
    (data) => mapBundleDetail(data),
    isOpen
  );

  const handleCancel = () => {
    setIsEditMode(false);
    setStep(EDIT_BUNDLE_STEP.BUNDLE_DETAILS);
    setIndicatorLabels([]);
    onClose();
  };

  const handleChangeContent = (theStep) => (values) => {
    setBundle((prev) => {
      setStep(theStep);
      theStep === EDIT_BUNDLE_STEP.BUNDLE_DETAILS
        ? setIndicatorLabels([])
        : setIndicatorLabels([theStep]);
      return { ...prev, ...values };
    });
  };

  const handleSubmit = (values) => {
    if (step === EDIT_BUNDLE_STEP.BUNDLE_DETAILS) {
      handleChangeContent(EDIT_BUNDLE_STEP.BUNDLE_DETAILS)(values);
      onSubmitBundle && onSubmitBundle(values);
      handleCancel();
      return;
    }

    if (step === EDIT_BUNDLE_STEP.PRODUCT_SELECTION) {
      handleChangeContent(EDIT_BUNDLE_STEP.BUNDLE_DETAILS)(values);
      return;
    }
  };

  const handleAddProductsToBundle = (bundleData) =>
    handleChangeContent(EDIT_BUNDLE_STEP.PRODUCT_SELECTION)(bundleData);

  useEffect(() => {
    if (!isFetching && bundleDetail) {
      setBundle(bundleDetail);
    }
  }, [isFetching, isOpen]);

  return (
    <SlzModal
      id="product-slz-modal"
      size="4xl"
      variant="product"
      accentColor={colorTag}
      colorScheme={colorTag}
      onClose={handleCancel}
      maxW={'995px'}
      isOpen={isOpen}>
      {isFetching ? (
        <GridItem gridColumn="12/12">
          <SkeletonModalTemplate />
        </GridItem>
      ) : (
        <GridItem pt={'1rem'} ml="3.5rem" mr="2rem">
          <GridItem gridColumn="2/12">
            <BundleEditHeader
              indicatorLabels={indicatorLabels}
              isEditMode={isEditMode}
              name={bundle?.name}
              onEdit={() => setIsEditMode(true)}
              onDelete={() => onOpenModalDelete()}
            />
          </GridItem>
          <GridItem gridColumn="2/12" mb="3">
            <BundleModalContent
              type={step}
              action={Mode.EDIT}
              isEditMode={isEditMode}
              defaultValues={bundle}
              setColorTag={setColorTag}
              setProducts={setProducts}
              products={products}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onAddProducts={handleAddProductsToBundle}
              // handleCancel={handleCancel}
            />
          </GridItem>
        </GridItem>
      )}
    </SlzModal>
  );
}
