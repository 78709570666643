import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Link, Text, Box } from '@chakra-ui/react';
import SlzButton from 'components/common/SlzButton/SlzButton';
import {
  Warehouse,
  UKFlag
} from 'components/common/Icons/EnabledCentreCards/EnabledFulfilmentCentreIcons';
import {
  AlcoholIcon,
  BinIcon,
  FragileIcon,
  GarmenOnHangerIcon,
  HazmatIcon,
  OversizedItemIcon,
  PalletIcon,
  SecureIcon,
  ShelfIcon,
  TemperatureControlledIcon
} from 'components/common/Icons/storageCapabilitiesIcons/index.js';
import TradingZone from 'modules/fulfilmentCentres/components/TradingZone/TradingZone.jsx';
import { SlzIconChip } from 'components/common/SlzChip';
import RequestAccessModal from '../RequestAccessModal/RequestAccessModal';
import useSlzToast from 'hooks/useSlzToast';
import { getAccessRequestById } from 'services/fulfillmentCentre';

export const AvailableDisabledCentreCard = ({
  isPostMVP,
  tradingZones,
  storageCapabilities,
  iconChip,
  warehouse,
  categories,
  children,
  ...rest
}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [accessRequestByID, setAccessRequestByID] = useState([]);
  const [_, setToast] = useSlzToast();

  const IconHeader = (status) => {
    switch (status) {
      case 'New':
        return (
          <PendingStatusIndicator
            background="barb.500"
            text="New"
            textColor="lightAndShadow.reverseText"
          />
        );

      case 'ComingSoon':
        return (
          <PendingStatusIndicator
            background="negative.300"
            text="ComingSoon"
            textColor="barb.500"
            border="0.063rem solid"
            borderColor="barb.500"
          />
        );

      case 'Pending':
        return (
          <PendingStatusIndicator background="sunshine.500" text="Pending" textColor="willow.500" />
        );

      default:
        return null;
    }
  };

  const PendingStatusIndicator = ({ background, text, textColor, border, borderColor }) => {
    return (
      <Box
        w="5.375rem"
        h="1.125rem"
        justifyContent="center"
        display="flex"
        alignItems="center"
        borderRadius="1.313rem"
        bg={background}
        border={border}
        borderColor={borderColor}>
        <Text fontSize={12} color={textColor}>
          {text}
        </Text>
      </Box>
    );
  };

  const ButtonFooter = (status) => {
    switch (status) {
      case 'Available':
      case 'New':
        return (
          <Box>
            <SlzButton type="submit" colorScheme="main" onClick={() => showModal()}>
              Request
            </SlzButton>
          </Box>
        );
      case 'ComingSoon':
        return (
          <Box>
            <SlzButton type="submit" colorScheme="main" variant="outline" isDisabled="true">
              Request
            </SlzButton>
          </Box>
        );
      case 'Pending':
        return (
          <Box>
            <SlzButton
              type="submit"
              colorScheme="main"
              variant="outline"
              onClick={() => getAccessRequest()}>
              Review
            </SlzButton>
          </Box>
        );
      default:
        return null;
    }
  };

  const StorageCapabilityIcon = ({ icon }) => {
    switch (icon) {
      case 'AlcoholIcon':
        return <AlcoholIcon />;

      case 'BinIcon':
        return <BinIcon />;

      case 'FragileIcon':
        return <FragileIcon />;

      case 'GarmenOnHangerIcon':
        return <GarmenOnHangerIcon />;

      case 'HazmatIcon':
        return <HazmatIcon />;

      case 'OversizedItemIcon':
        return <OversizedItemIcon />;

      case 'PalletIcon':
        return <PalletIcon />;

      case 'SecureIcon':
        return <SecureIcon />;

      case 'ShelfIcon':
        return <ShelfIcon />;

      case 'TemperatureControlledIcon':
        return <TemperatureControlledIcon />;

      default:
        return null;
    }
  };

  const IconChip = () => {
    switch (iconChip) {
      case 'CustomPackaging':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="marine"
            children="Custom Packaging"
            icon="customPackaging"
          />
        );

      case 'EmissionFree':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Emission Free"
            icon="emissionFree"
          />
        );

      case 'NextDayDelivery':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Next Day Delivery"
            icon="nextDayDelivery"
          />
        );

      case 'SameDayDelivery':
        return (
          <SlzIconChip
            variant="outline"
            size="lg"
            colorScheme="szrLeaf"
            children="Same Day Delivery"
            icon="sameDayDelivery"
          />
        );

      default:
        return null;
    }
  };

  const PostMVPInformation = () => {
    return (
      <Box mt="0.938rem">
        <Box>
          <IconChip />
        </Box>
        <Box display="flex" flexDirection="row" gap="0.375rem" mt="0.375rem">
          {storageCapabilities.map((storage) => {
            return <StorageCapabilityIcon icon={storage} />;
          })}
        </Box>
        <Box mt="0.469rem">
          <Text fontSize={12}>Trading Zones:</Text>
          <Box display="flex" flexDirection="row" gap="0.375rem" mt="0.406rem">
            {tradingZones.map((zone, index) => {
              return <TradingZone key={index} tradingZone={zone} />;
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  const showModal = () => {
    setIsModalActive(true);
  };

  const getAccessRequest = async () => {
    const response = await getAccessRequestById(warehouse.requestID);
    if (response === 'error')
      setToast({
        title: 'Error in reviewing your pending request. Please try again.',
        status: 'warning',
        colorScheme: 'negative',
        chakraToastProps: { duration: null }
      });
    setAccessRequestByID(response);
    showModal();
  };

  return (
    <Card w="19.125rem" borderRadius="0.75rem" boxShadow="md" {...rest}>
      <RequestAccessModal
        isOpen={isModalActive}
        warehouse={warehouse}
        categories={categories}
        setIsModalActive={setIsModalActive}
        accessRequestByID={accessRequestByID}
        requesStatus={warehouse.state}
      />
      <CardHeader
        bg="indigo.500"
        color="white"
        borderRadius="0.75rem 0.75rem 0rem 0rem"
        p="1.35rem 1.5rem 1.188rem 1.5rem">
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="flex-end" gap="0.313rem">
              <Warehouse />
              <Text fontSize={12}>Fulfilment Centre</Text>
            </Box>
            {IconHeader(warehouse.state)}
          </Box>
          <Text fontSize={20}>{warehouse.warehouseName}</Text>
        </Box>
      </CardHeader>

      <CardBody p="0.906rem 1.513rem 1.5rem 1.5rem" borderRadius="0.75rem 0.75rem 0rem 0rem">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Text fontSize={12}>{warehouse.address?.line1}</Text>
            <Text fontSize={12}>{warehouse.address?.line2}</Text>
            <Text fontSize={12}>{warehouse.address?.postcode}</Text>
            <Text fontSize={12}>
              {warehouse.address?.town}, {warehouse.address?.country}
            </Text>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="flex-start" gap="0.375rem">
            <Box
              w="1.25rem"
              h="1.25rem"
              color="white"
              bg="szrNavy.500"
              borderRadius="0.188rem"
              display="flex"
              alignItems="center"
              justifyContent="center">
              <Text>{warehouse.currency?.symbol}</Text>
            </Box>
            <UKFlag />
          </Box>
        </Box>
        {isPostMVP && <PostMVPInformation />}
        <Box
          display="flex"
          justifyContent={isPostMVP ? 'space-between' : 'flex-end'}
          alignItems="flex-end"
          mt="2.813rem">
          {isPostMVP && (
            <Link>
              <Text as="u" fontSize={12} color="main.500">
                View full details
              </Text>
            </Link>
          )}
          <Box>{children || ButtonFooter(warehouse.state)}</Box>
        </Box>
      </CardBody>
    </Card>
  );
};
