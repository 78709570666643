import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, FormControl, FormErrorMessage, Heading, HStack, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import SlzInput from 'components/common/SlzInput/SlzInput';

const SalesAndPricingSection = ({ isEditable }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <HStack spacing="8" mb={8}>
      <VStack flex={3} spacing="5">
        <Heading as="h3" fontSize="lg" w="full">
          Product price
        </Heading>

        <HStack spacing="5" w="full">
          <Box flex={1}>
            <FormControl isInvalid={errors['currentPrice']}>
              <SlzFormLabel>Unit price</SlzFormLabel>
              <HStack>
                <Box
                  pos="relative"
                  _after={{
                    content: '"GBP £"',
                    pos: 'absolute',
                    top: '6px',
                    left: '6px',
                    fontSize: '14px',
                    color: 'dark.500'
                  }}>
                  <SlzInput
                    size="sm"
                    type="number"
                    min="0"
                    step="0.01"
                    readonly={!isEditable}
                    textAlign="right"
                    pl="12"
                    autoComplete="off"
                    {...register('currentPrice', {})}
                  />
                </Box>
                <InfoOutlineIcon boxSize="3" color="main.500" />
              </HStack>
              <FormErrorMessage>{errors['currentPrice']?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box flex={1}></Box>
        </HStack>
      </VStack>
      <VStack flex={4} spacing="5">
        <Heading as="h3" fontSize="lg" w="full">
          Expected weekly sales
        </Heading>

        <HStack spacing="5" w="full">
          <Box flex={1}>
            <NestedInput
              field="units"
              label="Units"
              fieldProps={{ type: 'number', min: 0, readonly: !isEditable }}
            />
          </Box>
          <Box flex={2}></Box>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default SalesAndPricingSection;
