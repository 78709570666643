import React from 'react';
import { Icon } from '@chakra-ui/react';

const XMarkCircleIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
      <g id="Group_4690" data-name="Group 4690">
        <circle id="Ellipse_1213" data-name="Ellipse 1213" cx="5" cy="5" r="5" fill="#de4259" />
        <path
          id="Back_Stroke_"
          data-name="Back (Stroke)"
          d="M2.815,3.953l-.222-.221-.223.221A.316.316,0,0,0,2.815,3.953ZM2.593,3.29,1.137,1.842a.316.316,0,0,0-.445,0,.312.312,0,0,0,0,.442L2.37,3.953l.223-.221.222.221L6.053.734a.312.312,0,0,0,0-.442.316.316,0,0,0-.445,0Z"
          transform="translate(1.628 2.898)"
          fill="#de4259"
          fillRule="evenodd"
        />
      </g>
      <path
        id="Path_9932"
        data-name="Path 9932"
        d="M2.768.242a.244.244,0,0,0-.488,0V2.258H.244a.242.242,0,1,0,0,.484H2.279V4.758a.244.244,0,0,0,.488,0V2.742H4.8a.242.242,0,1,0,0-.484H2.768Z"
        transform="translate(8.553 4.984) rotate(135)"
        fill="#f2f2f2"
        stroke="#f2f2f2"
        strokeWidth="0.5"
        fillRule="evenodd"
      />
    </Icon>
  );
};

export default XMarkCircleIcon;
