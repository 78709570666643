import React from 'react';
import { Icon } from '@chakra-ui/react';

const TageIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="23.189"
      height="33.593"
      viewBox="0 0 23.189 33.593"
      {...props}>
      <g id="Group_1116" data-name="Group 1116" transform="translate(-2400.773 -412.887)">
        <path
          id="Ellipse_351"
          data-name="Ellipse 351"
          d="M5.719-1C7.779-1,9.592.585,10.825,3.462a23.457,23.457,0,0,1,1.612,8.975,23.457,23.457,0,0,1-1.612,8.975c-1.233,2.877-3.047,4.462-5.107,4.462S1.845,24.29.612,21.413A23.457,23.457,0,0,1-1,12.437,23.457,23.457,0,0,1,.612,3.462C1.845.585,3.658-1,5.719-1Zm0,23.515c.472,0,1.3-.759,2.019-2.426a20.363,20.363,0,0,0,1.34-7.652,20.363,20.363,0,0,0-1.34-7.652C7.024,3.118,6.19,2.359,5.719,2.359s-1.3.759-2.019,2.426a20.363,20.363,0,0,0-1.34,7.652A20.363,20.363,0,0,0,3.7,20.089C4.414,21.756,5.247,22.515,5.719,22.515Z"
          transform="translate(2401.773 413.887)"
          fill="#303134"
        />
        <path
          id="Path_5463"
          data-name="Path 5463"
          d="M2422.492,428a1.68,1.68,0,0,1-1.679-1.68,20.355,20.355,0,0,0-1.341-7.652c-.718-1.676-1.531-2.426-2.019-2.426a1.68,1.68,0,0,1,0-3.359c2.06,0,3.874,1.584,5.107,4.462a23.456,23.456,0,0,1,1.612,8.975A1.68,1.68,0,0,1,2422.492,428Z"
          transform="translate(-0.211 0)"
          fill="#303134"
        />
        <path
          id="Path_5464"
          data-name="Path 5464"
          d="M2415.2,416.246h-9.75a1.68,1.68,0,1,1,0-3.359h9.75a1.68,1.68,0,1,1,0,3.359Z"
          transform="translate(2.039 0)"
          fill="#303134"
        />
        <path
          id="Path_5465"
          data-name="Path 5465"
          d="M2413.328,441.722c-.049,0-.1,0-.149-.007a1.679,1.679,0,0,1-1.363-.942l-1.215-2.512-.789,1.087a1.68,1.68,0,0,1-3.039-.986v-16.8a1.68,1.68,0,0,1,3.36,0V433.5a1.68,1.68,0,0,1,2.27.767l1.215,2.512.789-1.087a1.68,1.68,0,0,1,2.117-.513V421.567a1.679,1.679,0,1,1,3.359,0v16.8a1.679,1.679,0,0,1-2.633,1.383l-1.088-.75-1.475,2.033A1.679,1.679,0,0,1,2413.328,441.722Z"
          transform="translate(4.079 4.757)"
          fill="#303134"
        />
        <path
          id="Path_5466"
          data-name="Path 5466"
          d="M2405.47,423.246h-.017a1.68,1.68,0,1,1,0-3.359h.017a1.68,1.68,0,1,1,0,3.359Z"
          transform="translate(2.039 4.757)"
          fill="#303134"
        />
      </g>
    </Icon>
  );
};

export default TageIcon;
