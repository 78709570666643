import { useQuery } from 'react-query';
import { getWarehouses } from '../services/warehouse-api';

export const useWarehouseQuery = (select) => {
  return useQuery({
    queryKey: [`warehouse-selection`],
    queryFn: () => getWarehouses(),
    select: select,
    cacheTime: 60 * 1000,
    staleTime: 2 * 1000,
  });
};
