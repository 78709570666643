import { ORDER_STATUS_COLOR } from 'constants/common';
import { transformAccordionItems } from './bulk-order-list-accordion-mapper';
import { ORDER_PROCESSING_STATUS } from '../constants';

export const transformBulkOrderDetail = (bulkOrderDetail, colorStatus) => {
  const bulkOrderDetailStatus = ORDER_PROCESSING_STATUS.find(
    (status) => status.value === bulkOrderDetail?.status
  );
  return {
    ...bulkOrderDetail,
    status: {
      key: bulkOrderDetailStatus?.key || 0,
      text: bulkOrderDetailStatus?.label || bulkOrderDetail?.status,
      bg: ORDER_STATUS_COLOR[colorStatus] ?? 'creamsicle.500'
    },
    items: transformAccordionItems(bulkOrderDetail || [])
  };
};

export const transformOrderDetail = (recipient) => {
  return {
    contactDetails: {
      name: recipient.name,
      emailAddress: recipient.email,
      contactNumber: recipient.phone,
      companyName: recipient.companyName
    },
    destinationAddress: {
      addressLine1: recipient.addressLine1,
      addressLine2: recipient.addressLine2,
      town: recipient.town,
      county: recipient.county,
      country: recipient.country,
      postCode: recipient.postCode
    }
  };
};
