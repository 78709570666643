import React from 'react';
import { Icon } from '@chakra-ui/react';

const DashboardIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.689"
      height="29.689"
      viewBox="0 0 29.689 29.689"
      fill="currentColor"
      {...props}>
      <path
        id="Rectangle_1878"
        data-name="Rectangle 1878"
        d="M1-1H9.255a2,2,0,0,1,2,2V9.255a2,2,0,0,1-2,2H1a2,2,0,0,1-2-2V1A2,2,0,0,1,1-1ZM9.255,9.255V1H1V9.255Z"
        transform="translate(1 1)"
        fill="currentColor"
      />
      <path
        id="Rectangle_1878-2"
        data-name="Rectangle 1878"
        d="M1-1H9.255a2,2,0,0,1,2,2V9.255a2,2,0,0,1-2,2H1a2,2,0,0,1-2-2V1A2,2,0,0,1,1-1ZM9.255,9.255V1H1V9.255Z"
        transform="translate(18.403 1)"
        fill="currentColor"
      />
      <path
        id="Rectangle_1879"
        data-name="Rectangle 1879"
        d="M1-1H9.255a2,2,0,0,1,2,2V9.255a2,2,0,0,1-2,2H1a2,2,0,0,1-2-2V1A2,2,0,0,1,1-1ZM9.255,9.255V1H1V9.255Z"
        transform="translate(1 18.434)"
        fill="currentColor"
      />
      <path
        id="Rectangle_1880"
        data-name="Rectangle 1880"
        d="M1-1H9.255a2,2,0,0,1,2,2V9.255a2,2,0,0,1-2,2H1a2,2,0,0,1-2-2V1A2,2,0,0,1,1-1ZM9.255,9.255V1H1V9.255Z"
        transform="translate(18.434 18.434)"
        fill="currentColor"
      />
    </Icon>
  );
};

export default DashboardIcon;
