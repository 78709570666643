import { RETURNS_PATHNAME } from '../constants';
import { ReturnDetail } from '../pages';
import ReturnList from '../pages/return-list';

const ReturnsRoutes = [
  {
    path: RETURNS_PATHNAME,
    profile: 'Retailer',
    feature: 'ViewReturn',
    exact: true,
    component: ReturnList
  },
  {
    path: `${RETURNS_PATHNAME}/:returnID`,
    component: ReturnDetail,
    exact: true
  }
];

export default ReturnsRoutes;
