import { AddIcon } from '@chakra-ui/icons';
import { Flex, Image, Input, Text, VStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ImageUpload } from 'components/common/Icons';

import { SlzSingleButton } from 'components/common/SlzButton';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';

const BaseUpload = (
  <VStack
    spacing={1}
    sx={{
      w: 24,
      h: 24,
      py: 1.5,
      overflow: 'hidden'
    }}>
    <Text
      sx={{
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0px',
        color: 'dark.700'
      }}
      mb={-1}>
      Add image
    </Text>
    <ImageUpload width="44px" height="36px" color="dark.500" />
    <SlzSingleButton mt={0} minH={6} size="sm" icon={<AddIcon />} />
  </VStack>
);

export const BaseUploadImage = ({
  onLoadFile,
  field,
  control,
  defaultImageURL,
  uploadProps = {},
  ...rest
}) => {
  const { setValue, watch, getValues } = useFormContext();
  const [imgURL, setImgURL] = useState(getValues(field) || defaultImageURL);
  const onLoad = useCallback(
    (file) => {
      const objectUrl = URL.createObjectURL(file);
      setImgURL(objectUrl);
      setValue(field, objectUrl);
    },
    [imgURL]
  );

  useEffect(() => {
    const image = getValues(field) || defaultImageURL;
    setImgURL(image);
    setValue(field, image);
  }, [watch(field)]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      __css={{
        display: 'flex',
        bgColor: 'light.300',
        border: '1px solid',
        borderColor: 'stone.500',
        borderRadius: '6px',
        w: 24,
        h: 24,
        overflow: 'hidden'
      }}
      {...rest}>
      {imgURL ? (
        <Image src={imgURL} />
      ) : (
        <SlzUpload
          {...uploadProps}
          uploadForm={BaseUpload}
          onLoadFile={onLoadFile || onLoad}
          acceptType="image/*"></SlzUpload>
      )}
      <Controller
        render={({ field }) => {
          <Input {...field} hidden />;
        }}
        name={field}
        control={control}
      />
    </Flex>
  );
};
