import { useState } from 'react';

import { Box, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';

import { CELL_BUTTON_ACCORDION_WIDTH } from '../Layout/Constants';
import { skeletonAnimation as animation } from '../Skeleton/config';
import { Skeleton } from '../Skeleton/Skeleton';
import SortIcon from './SortIcon';
import { useTableContext } from './TableContext';
import { isCustomCell } from './utils';

const Header = ({
  columns,
  actions = {},
  children = [],
  showMarked = false,
  displayFields = [],
  onSort,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hoveredField, setHoveredField] = useState('');
  const { columnWidth, paddingValue, rowProps, isLoading } = useTableContext();
  const handleMouseOver = (field) => {
    setHoveredField(field);
    onOpen();
  };

  const handleMouseLeave = (field) => {
    setHoveredField(field);
    onClose();
  };

  return (
    <>
      <Thead {...rest}>
        <Tr>
          {showMarked && (
            <Th sx={{ w: 3, p: '0' }}>
              {isLoading && <Skeleton animation={animation} width="100%" height="100%"></Skeleton>}
            </Th>
          )}
          {/* {showImage && (
            <Th sx={{ w: 20 }}>
              {isLoading && <Skeleton animation={animation} width="100%" height="100%"></Skeleton>}
            </Th>
          )} */}
          {columns?.length &&
            columns.map(
              (column) =>
                (displayFields.includes(column?.field) || isCustomCell(column)) && (
                  <Th
                    sx={{
                      fontSize: '1rem',
                      lineHeight: '1.313rem',
                      textAlign: column?.header?.align || 'center',
                      w: column?.width ? `${column?.width}px` : columnWidth,
                      minW: column?.width ? `${column?.width}px` : columnWidth,
                      px: paddingValue,
                      ...(column?.isEmphasis || isLoading
                        ? {
                            p: '0'
                          }
                        : {}),
                      ...column?.header
                    }}
                    key={column?.name || column?.field}
                    color="dark.700">
                    {isLoading ? (
                      <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
                    ) : (
                      <Box
                        as="span"
                        onMouseOver={() => handleMouseOver(column?.field)}
                        onMouseLeave={() => handleMouseLeave(column?.field)}>
                        {column?.name}{' '}
                        {column?.isSorted && (
                          <SortIcon
                            isShow={isOpen && hoveredField === column?.field}
                            column={column}
                            onSort={onSort}
                          />
                        )}
                      </Box>
                    )}
                  </Th>
                )
            )}

          {actions?.list?.length && (
            <Th
              sx={{
                p: 0
              }}>
              {isLoading && <Skeleton animation={animation} width="100%" height="100%"></Skeleton>}
            </Th>
          )}

          {rowProps?.hasExpandedRow && (
            <Th
              sx={{
                p: 0,
                w: CELL_BUTTON_ACCORDION_WIDTH,
                maxW: CELL_BUTTON_ACCORDION_WIDTH
              }}>
              {isLoading && <Skeleton animation={animation} width="100%" height="100%"></Skeleton>}
            </Th>
          )}
        </Tr>
      </Thead>
    </>
  );
};

export default Header;
