import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { dayjs } from '../../../../utils';
import { Table } from '../../CalendarCommonComponents/SlzTable';
import SlzCalendarHead from '../../CalendarCommonComponents/SlzCalendarHead';
import SlzCalendarBody from '../../CalendarCommonComponents/SlzCalendarBody';
import { getDefaultState } from '../../../../utils';
import { ClearIcon } from '../../Icons';
import { Box, useStyleConfig } from '@chakra-ui/react';

const SlzCalendar = (props) => {
  let state = getDefaultState();
  const [calendar, setCalendar] = useState(null);
  const styles = useStyleConfig('SlzDateSingleCalendar', props);
  const { footerBtnStyle } = styles;

  useEffect(() => {
    let calendar = props.startDate;
    setCalendar(calendar);;
  }, [props.startDate]);

  const onDayClick = (startDate) => {
    props?.onDateInputClick(startDate);
  };

  const handleClearSelection = () => {
    props.onClearSelection();
  };

  const handleNextPrev = (cal) => {
    setCalendar(cal);
  };

  // Combine props and state into one object 
  const createProps = () => {
    const calendarValue = calendar ?? dayjs();
    const propsState = {...state, ...props };
    
    const tableState = Object.assign({}, propsState, {
      calendar: calendarValue
    });


    return {
      tableProps: {
        handlePrev: handleNextPrev,
        handleNext: handleNextPrev,
        onDayClick: onDayClick,
        ...tableState,
        isDisableRightButton: false,
        showWeekNumbers: false,
        position: 'left',
        opens: 'left'
      }
    };
  };

  const renderTable = () => {
    const propsTable = createProps();
    const { tableProps } = propsTable;
    const className = classNames({
      'drp-calendar': true
    });
    return [
      <Box className={className} key={0}>
        <Box className={'calendar-table'}>
          <Table className="table-condensed">
            <SlzCalendarHead {...tableProps} />
            <SlzCalendarBody {...tableProps} />
          </Table>
        </Box>
        <Box sx={footerBtnStyle} data-testid="slz-date-single-picker-calendar-footer">
          <Box className="clear-btn" onClick={ handleClearSelection }>
            <Box>
              <ClearIcon />
            </Box>
            <Box>Clear selection</Box>
          </Box>
        </Box>
      </Box>
    ];
  };

  const { opens, children } = props;
  const className = classNames({
    [`opens${opens}`]: true,
    'datesinglepicker ltr show-calendar': true
  });

  return (
    <div
      className={className}
      style={{
        left: 'auto'
      }}>
      {renderTable()}
      {children}
    </div>
  );
};


export default SlzCalendar;