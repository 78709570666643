import React from 'react';
import { Icon } from '@chakra-ui/react';

const YodelLogoCompany = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="74.844"
      height="20"
      viewBox="0 0 74.844 20"
      {...props}>
      <svg>
        <path
          id="Yodel__company__logo"
          data-name="Yodel_(company)_logo"
          d="M5.667,12.6,0,.533H5.267l2.8,7.6,2.867-7.6h5.2L10.533,12.6v6.867H5.667ZM34.778.533h6.267c2.133,0,5.933,0,8.4,3.533a9.463,9.463,0,0,1,1.733,5.867c0,5.2-2.533,9.533-9.733,9.533h-6.6V.533Zm4.8,14.667h2.067c3.6,0,4.667-2.533,4.667-5.2a6.547,6.547,0,0,0-.933-3.533,3.885,3.885,0,0,0-3.667-1.733H39.644V15.2ZM52.311.533H63.044V4.8H57.178V7.733h5.733V12H57.178v3.267h5.867v4.267H52.311Zm12.067,0h4.867V15.2h5.6v4.267H64.378ZM13.933,10a10,10,0,1,1,10,10A9.987,9.987,0,0,1,13.933,10Zm10,5.667A5.667,5.667,0,1,0,18.267,10,5.662,5.662,0,0,0,23.933,15.667Z"
          fill="#9c0"
        />
      </svg>
    </Icon>
  );
};

export default YodelLogoCompany;
