import { getStatusColor } from 'modules/b2c-orders/mappers/order-list-mapper';

export const FILTER_OPTIONS = [
  'Awaiting Collection',
  'Collection Booked',
  'Shipped',
  'Part Fulfilled',
  'Received',
  'Awaiting Fulfilment',
  'Fulfilment In Progress',
  'Picking In Progress',
  'Packing In Progress',
  'Processing Failed',
  'Processing',
  'Order Queued'
].map((item) => ({
  key: item,
  text: item,
  bg: getStatusColor(item)
}));
