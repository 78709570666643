import { useEffect, useState } from 'react';
import { Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { FormProvider, useWatch } from 'react-hook-form';

import { DotIcon } from 'components/common/Icons';
import FormWrapper from 'components/common/SlzForms/FormWrapper';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

import { RateMatcherFormActions, ShippingRateFields } from 'modules/integrations/components/form';
import {
  RateMatcherShippingOption,
  RateMatcherStep1Summary
} from 'modules/integrations/components/other';
import { PREFIX, RATE_MATCHER_STEP_2 } from 'modules/integrations/constants';
import { getStepOneValues, transformShippingRates } from 'modules/integrations/utils';
import { RateMatchingState } from 'modules/integrations/components/other/RateMatcherShippingOption';

const RateMatcherStepTwo = ({
  shopName,
  defaultValues,
  numberOfShippingRate,
  updateMatchedShippingRates,
  shopifyShippingRatePreferences,
  fetchedCurrentPreferences,
  fetchedServiceTimes,
  onSubmit,
  ...rest
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const stepOneValues = getStepOneValues(defaultValues);

  useEffect(() => {
    const _defaultValues = {
      ...defaultValues,
      shippingRates: transformShippingRates({
        shippingRates: defaultValues?.shippingRates,
        useDropdown: true
      })
    };
    setValues(_defaultValues);
    setIsLoaded(true);
  }, []);
  return isLoaded ? (
    <FormWrapper defaultValues={values}>
      {({ methods }) => {
        const { shippingRates } = useWatch({ control: methods?.control });
        updateMatchedShippingRates(shippingRates);
        return (
          <FormProvider {...methods}>
            <form>
              <IntegrationSkeleton
                isLoaded={shopName && numberOfShippingRate}
                template={`${PREFIX}.${rest?.currentStep}`}>
                <RateMatcherStep1Summary
                  title="Your default rates for this Shopify store"
                  stepOneValues={stepOneValues}
                />

                <VStack mb="0.75rem" spacing="1" w="full" alignItems="flex-start">
                  <HStack>
                    <DotIcon w="3" h="3" fill="main.500" />
                    <Heading fontSize="1.188rem" color="dark.700" lineHeight="1.563rem">
                      {RATE_MATCHER_STEP_2.header}
                    </Heading>
                  </HStack>
                  <Text fontSize="1rem" color="dark.700" lineHeight="1.313rem">
                    {RATE_MATCHER_STEP_2.description}
                  </Text>
                </VStack>

                <RateMatcherShippingOption shopName={shopName}>
                  <RateMatchingState
                    numberOfMatchedRate={rest?.numberOfMatchedRate}
                    numberOfShippingRate={numberOfShippingRate}
                  />
                </RateMatcherShippingOption>

                <VStack
                  spacing={7}
                  mt="2.35rem"
                  sx={{
                    maxH: '270px',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    }
                  }}>
                  <ShippingRateFields
                    control={methods?.control}
                    fetchedCurrentPreferences={fetchedCurrentPreferences}
                    fetchedServiceTimes={fetchedServiceTimes}
                  />
                </VStack>
              </IntegrationSkeleton>
              <RateMatcherFormActions {...rest} />
            </form>
          </FormProvider>
        );
      }}
    </FormWrapper>
  ) : (
    <></>
  );
};

export default RateMatcherStepTwo;
