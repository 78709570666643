import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const colorsSchema = {
  success: {
    color: 'primary.positiveSelected',
    iconColor: '#2A985A'
  },
  error: {
    color: 'primary.negativeSelected',
    iconColor: '#E53E3E'
  },
  primary: {
    color: 'primary.mainSelected',
    iconColor: '#0973EA'
  },
  warning: {
    color: 'attention.warningBg',
    iconColor: '#303134'
  },
  ghost: {
    color: 'attention.ghostBg',
    iconColor: '#303134'
  }
};
const sizes = {};
const baseStyle = {
  icon: {
    w: 6,
    h: 6
  },
  borderRadius: '0.75rem',
  pt: 3,
  pb: 5,
  pl: 6,
  pr: 5
  // maxW: '17.563rem',
  // w: '17.563rem'
};

const variantSolid = defineStyle((props) => {
  const { status: s } = props;
  const { color, iconColor } = colorsSchema[s] ?? colorsSchema['primary'];
  return {
    bg: color,
    icon: {
      color: iconColor,
      w: '23px',
      h: '22px'
    },
    title: {
      fontWeight: 550,
      fontSize: '17px',
      color: 'lightAndShadow.mainText',
      lineHeight: 6
    },
    description: {
      pt: '9px',
      p: {
        lineHeight: '1.188rem',
        textAlign: 'left',
        color: 'lightAndShadow.mainText',
        fontSize: '0.875rem'
      }
    }
  };
});

const variantProduct = defineStyle((props) => {
  const { status: s } = props;
  const { color, iconColor } = colorsSchema[s] ?? colorsSchema['primary'];
  return {
    w: '416px',
    maxW: '416px',
    minH: '45.5px',
    display: 'flex',
    bg: color,
    borderRadius: 1.5,
    py: 2.5,
    pl: '15px',
    pr: '25px',
    icon: {
      color: iconColor
    },
    title: {
      color: 'lightAndShadow.mainText',
      WebkitLineClamp: 'unset',
      mb: 0,
      fontSize: '0.625rem',
      lineHeight: '13px',
      letterSpacing: '-0.1px'
    },
    description: {
      w: 0,
      h: 0,
      overflow: 'hidden'
    }
  };
});

const variantReturnPreference = defineStyle((props) => {
  const { status: s } = props;
  const { color, iconColor } = colorsSchema[s] ?? colorsSchema['primary'];
  return {
    bg: color,
    pt: 4,
    pb: 6,
    pl: 6,
    pr: 3.5,
    icon: {
      color: iconColor,
      w: 6,
      h: 6
    },
    title: {
      fontWeight: 550,
      fontSize: 'custom.2lg',
      color: 'lightAndShadow.mainText',
      lineHeight: 6
    },
    description: {
      pt: '0.188rem',
      p: {
        lineHeight: '4.5',
        textAlign: 'left',
        color: 'lightAndShadow.mainText',
        fontSize: 'md'
      }
    },
    headerWrapper: {
      spacing: 1.5
    }
  };
});

export default defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    solid: variantSolid,
    product: variantProduct,
    returnPreference: variantReturnPreference
  },
  defaultProps: {
    variant: 'solid'
  }
});
