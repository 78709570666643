import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, Flex, FormControl, Heading } from '@chakra-ui/react';
import * as yup from 'yup';

import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';
import { SlzButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzToggle } from 'components/common/SlzToggle';

import FormLabel from './FormLabel';
import Tooltip from './Tooltip';
import IntegrationForm from './IntegrationForm';

const validationSchema = yup.object({
  // shopName: yup.string().required('Store name is required'),
  // storeUrl: yup.string().required('Store URL is required'),
  // key: yup.string().required('Customer key is required'),
  // secret: yup.string().required('Customer secret is required')
  shopName: yup.string(),
  storeUrl: yup.string(),
  key: yup.string(),
  secret: yup.string()
});

export default function CreateWooCommIntegration({
  title = 'Your Woocommerce store credentials',
  onSubmit,
  onCancel,
  isSubmitting
}) {
  const defaultValues = {
    connected: true,
    inventoryManagement: true
  };

  return (
    <Box>
      {title && (
        <Heading as="h4" fontSize="xl" fontWeight="medium" mb="5">
          {title}
        </Heading>
      )}
      <IntegrationSkeleton isLoaded template="setup">
        <IntegrationForm defaultValues={defaultValues} validationSchema={validationSchema}>
          {({
            methods,
            errors,
            getFieldStatus,
            resetFieldStatus,
            resetAllFieldsStatus,
            clearField
          }) => {
            const isDisabledManageInventory = methods.getValues('connected') === false;

            const toggleConnectionHandler = (e) => {
              methods.register('connected').onChange(e);
              methods.setValue('inventoryManagement', e.target.checked);
            };

            return (
              <FormProvider {...methods}>
                <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
                  <FormControl mb="5" isInvalid={errors.shopName}>
                    <FormLabel>Store name</FormLabel>
                    <SlzInput
                      {...methods.register('shopName')}
                      showIcon
                      // placeholder={errors?.shopName?.message}
                      // status={getFieldStatus('shopName')}
                      // onFocus={() => resetFieldStatus('shopName')}
                      // onClickError={() => clearField('shopName')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.storeUrl}>
                    <FormLabel>Store URL</FormLabel>
                    <SlzInput
                      {...methods.register('storeUrl')}
                      showIcon
                      // placeholder={errors?.storeUrl?.message}
                      // status={getFieldStatus('storeUrl')}
                      // onFocus={() => resetFieldStatus('storeUrl')}
                      // onClickError={() => clearField('storeUrl')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.key}>
                    <FormLabel>Customer key</FormLabel>
                    <SlzInput
                      type="password"
                      {...methods.register('key')}
                      showIcon
                      // placeholder={errors.key?.message}
                      // status={getFieldStatus('key')}
                      // onFocus={() => resetFieldStatus('key')}
                      // onClickError={() => clearField('key')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.secret}>
                    <FormLabel>Customer secret</FormLabel>
                    <SlzInput
                      type="password"
                      {...methods.register('secret')}
                      showIcon
                      // placeholder={errors.secret?.message}
                      // status={getFieldStatus('secret')}
                      // onFocus={() => resetFieldStatus('secret')}
                      // onClickError={() => clearField('secret')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.connected}>
                    <Heading as="h3" fontWeight="medium" fontSize="lg" mb="2">
                      Do you want this integration to be connected now?
                    </Heading>
                    <Flex gap="2">
                      <SlzToggle
                        lineHeight="0"
                        {...methods.register('connected')}
                        onChange={toggleConnectionHandler}>
                        Connect
                      </SlzToggle>
                      <Tooltip content={'abc'} />
                    </Flex>
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.inventoryManagement}>
                    <Heading as="h3" fontWeight="medium" fontSize="lg" mb="2">
                      Do you want to allow for Shopify to use inventory levels from Selazar?
                    </Heading>
                    <Flex gap="2">
                      <SlzToggle
                        lineHeight="0"
                        isChecked={methods.watch('inventoryManagement')}
                        isDisabled={isDisabledManageInventory}
                        {...methods.register('inventoryManagement')}>
                        Manage my inventory
                      </SlzToggle>
                      <Tooltip content={'abc'} />
                    </Flex>
                  </FormControl>

                  <Flex mb={5} mt={10} justifyContent="space-between">
                    <SlzButton
                      variant="outline"
                      size="lg"
                      colorScheme="negative"
                      onClick={onCancel}>
                      Cancel
                    </SlzButton>
                    <SlzButton
                      type="submit"
                      size="lg"
                      onClick={resetAllFieldsStatus}
                      disabled={isSubmitting}
                      isLoading={isSubmitting}>
                      Set up integration
                    </SlzButton>
                  </Flex>
                </form>
              </FormProvider>
            );
          }}
        </IntegrationForm>
      </IntegrationSkeleton>
    </Box>
  );
}
