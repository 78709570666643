export const DASHBOARD_PATHNAME = '/dashboard';

export const ADD_PRODUCT_PATHNAME = '/products?isCreateProductModal=true';

export const BREAD_CRUMB_ITEMS = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/dashboard',
    title: 'Dashboard'
  }
];

export const ORDERS_24HRS_PARAMS = ['unprocessed', 'awaitingfulfilment', 'completed'];

export const STATISTICS_STATUS = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
  NO_CHANGE: 'NOCHANGE'
};

export const STATISTICS_STATUS_DISPLAY = {
  [STATISTICS_STATUS.INCREASE]: {
    label: 'Increase',
    rotate: '0deg'
  },
  [STATISTICS_STATUS.DECREASE]: {
    label: 'Decrease',
    rotate: '180deg'
  },
  [STATISTICS_STATUS.NO_CHANGE]: {
    label: 'No change',
    rotate: '90deg'
  }
};

export const ORDERS_TODAY_LABEL = [
  {
    title: 'Pending',
    color: '#FEAD3B'
  },
  {
    title: 'Processed',
    color: 'indigo.500'
  },
  {
    title: 'Completed',
    color: 'tetra.500'
  }
];

export const noOrderTodayData = [
  { name: '', count: 630, color: 'dark.500' },
  { name: '', count: 270, color: 'light.700' },
  { name: '', count: 100, color: 'dark.300' }
];

export const PIE_CHART_LABEL = {
  TITLE: 'Orders today',
  NO_DATA: 'No data available'
};
export const REGION = {
  EU: 'EU',
  ROW: 'RestOfWorld',
  UK: 'UK'
};

export const DELIVERY_DESTINATION = [
  {
    field: REGION.UK,
    ylabel: 'UK',
    legendLabel: 'UK',
    background: '#21C474',
    value: 202
  },
  {
    field: REGION.EU,
    ylabel: 'EU',
    legendLabel: 'Europe',
    background: '#767AEA',
    value: 163
  },
  {
    field: REGION.ROW,
    ylabel: 'RoW',
    legendLabel: 'Rest of the world',
    background: '#EF971D',
    value: 110
  }
];

export const DELIVERY_DESTINATION_CHART_HOLDER = {
  chartData: [
    {
      field: 'total',
      value: 300
    },
    ...DELIVERY_DESTINATION
  ],
  total: 300
};

export const DELIVERY_DESTINATION_XAXIS_INDICES = [0, 3, 6, 9];

export const DELIVERY_DESTINATION_XAXIS_HOLDER = {
  [DELIVERY_DESTINATION_XAXIS_INDICES[0]]: '0',
  [DELIVERY_DESTINATION_XAXIS_INDICES[1]]: 'A',
  [DELIVERY_DESTINATION_XAXIS_INDICES[2]]: 'B',
  [DELIVERY_DESTINATION_XAXIS_INDICES[3]]: 'C'
};

export const DELIVERY_DESTINATION_CHART_YLABEL = ['Total', 'UK', 'EU', 'RoW'];

export const MOST_POPULAR_PRODUCT_ROWS = Array(10)
  .fill(null)
  .map((item, idx) => idx + 1);

export const X_AXIS_LINE_GRAPH = {
  TICKS: [1, 7, 14, 21, 28],
  DOMAIN: [1, 28]
};
