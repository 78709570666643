import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { ResendEmailForm, ForgotPasswordForm } from 'modules/users/components/form';
import { forgotPassword } from 'modules/users/services/users-api';
import { CompanyInfo, CompanyReference } from 'modules/users/components/other';
import {
  PREVIOUS_PAGE,
  MESSAGE,
  RESET_PASSWORD_IMAGE_URL_SET,
  CONTACT_INFOS,
  TEXT_OVERLAY
} from 'modules/users/constants';
import { PublicLayout } from 'components/layout';

export default function ForgotPassword() {
  const [isForgotPassword, setIsForgotPassword] = useState(true);
  const [email, setEmail] = useState();
  const forgotPasswordMutation = useMutation({
    mutationFn: ({ email }) => {
      return forgotPassword(email);
    }
  });

  const handleForgotPassword = async (values) => {
    try {
      await handleSendEmailFromAPI(values.email);
      setEmail(values.email);
      setIsForgotPassword(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendEmail = async () => {
    try {
      await handleSendEmailFromAPI(email);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendEmailFromAPI = async (email) => {
    const result = await forgotPasswordMutation.mutateAsync({ email });
    if (result?.error) {
      throw new Error(MESSAGE.FORGOT.ERROR.CALL_API);
    }
  };

  return (
    <PublicLayout
      imageSrc={RESET_PASSWORD_IMAGE_URL_SET}
      textOverlay={TEXT_OVERLAY}
      mediaLinks={null}
      sx={{ alignItems: 'start', paddingTop: 0 }}>
      {isForgotPassword ? (
        <>
          <ForgotPasswordForm
            onSubmit={handleForgotPassword}
            isLoading={forgotPasswordMutation.isLoading}
            errorFromAPI={forgotPasswordMutation?.data?.error}
          />
          <CompanyReference
            title={PREVIOUS_PAGE.FORGOT_PASSWORD.title}
            href={PREVIOUS_PAGE.FORGOT_PASSWORD.href}
          />
        </>
      ) : (
        <ResendEmailForm onSubmit={handleResendEmail} />
      )}
      <CompanyInfo email={CONTACT_INFOS.email} telephone={CONTACT_INFOS.telephone} />
    </PublicLayout>
  );
}
