import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, VStack } from '@chakra-ui/react';

import { B2BOrderSummaryTable } from '.';
import { BoxHiddenScroll } from 'components/layout';
import { BOX_SPLIT_SUMMARY_STYLE } from 'modules/b2b-orders/constants/styles';
import { PRODUCT_SELECTED_COLUMNS } from 'modules/b2b-orders/constants/b2b-summaries-orders-columns';
import { BOX_SPLIT_SUMMARY_COLUMNS } from 'modules/b2b-orders/constants/b2b-summaries-orders-columns';
import { getCustomBoxSplitSummaryData } from 'modules/b2b-orders/utils';
import { BOX_SPLIT, BULK_ORDER_CREATE_TEXT, VALIDATE_STATUS } from 'modules/b2b-orders/constants';
import SlzTable from 'components/common/SlzTable/Table';

const CustomBoxSplitSummary = (props) => {
  const { validatedBoxes, defaultValues, setValidateProgress } = props;
  const customBoxSplitSummaryRows = getCustomBoxSplitSummaryData(validatedBoxes.boxes);
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(BOX_SPLIT, [{ boxes: validatedBoxes.boxes }]);
    setValidateProgress(VALIDATE_STATUS.done);
  }, []);

  return (
    <BoxHiddenScroll minH="26rem" maxH="41.938rem" w="full">
      <VStack w="full" alignItems="flex-start" spacing={6}>
        <Box w="full">
          <SlzTable
            sx={BOX_SPLIT_SUMMARY_STYLE.selectedProductTable}
            showBoxShadow={false}
            variant="selectedBundle"
            sizes={['sm', 'md', 'lg']}
            showMarked={false}
            showHeader={true}
            showToolbar={false}
            autoResize={false}
            columns={PRODUCT_SELECTED_COLUMNS}
            data={defaultValues?.stockSelection}
            displayFields={PRODUCT_SELECTED_COLUMNS.map((col) => col.field)}
          />
        </Box>
        <Box w="full">
          <B2BOrderSummaryTable
            title={BULK_ORDER_CREATE_TEXT.BOX_SPLIT.boxSplitDetails}
            columns={BOX_SPLIT_SUMMARY_COLUMNS}
            rows={customBoxSplitSummaryRows}
            spacing={0}
            titleStyles={{
              fontSize: 'xl',
              lineHeight: 'tall',
              mb: 3
            }}
          />
        </Box>
      </VStack>
    </BoxHiddenScroll>
  );
};

export default CustomBoxSplitSummary;
