import { Box, Flex, HStack, useStyleConfig } from '@chakra-ui/react';
import { Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { SlzButton } from 'components/common/SlzButton';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import ProductSelectForm from '../ProductSelectForm';
import { PRODUCT_QUANTITY_OPTIONS } from 'modules/products/constants';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';

const ProductCardForm = ({ index, product, isEditMode, onRemove }) => {
  const styles = useStyleConfig('SlzFormLabelProduct');

  const { getValues, control } = useFormContext();
  const defaultValue = PRODUCT_QUANTITY_OPTIONS.find(
    (itemOption) => itemOption.value === getValues(`productBundleCompanyItems.${index}.quantity`)
  );

  return (
    <Box width={'100%'} bg={'light.700'} borderRadius={'0.75rem'}>
      <HStack pt={2} pr={3} pl={3} pb={2.5} justifyContent="space-between" align="flex-start">
        <SlzFormLabel sx={styles.formLabelProduct}>{product?.name}</SlzFormLabel>
        {isEditMode ? (
          <Controller
            name={`productBundleCompanyItems.${index}.quantity`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProductSelectForm
                onRemove={() => onRemove && onRemove(product)}
                onChange={onChange}
                value={value}
                isEditMode={isEditMode}
                defaultValue={defaultValue}
              />
            )}
            rules={{ required: true }}
          />
        ) : (
          <HStack
            borderRadius={'3px'}
            w="3rem"
            h={7.5}
            bg="light.500"
            justifyContent={'end'}
            pr={'4.7px'}>
            <span>{getValues(`productBundleCompanyItems.${index}.quantity`)}</span>
          </HStack>
        )}
      </HStack>
      <HStack height={'9.188rem'} mb={'0.531rem'} background="light.500">
        <Image
          style={{ maxHeight: '100%', objectFit: 'contain', width: '100%' }}
          src={get(product, 'companyItem.item.itemImages[0].link', DEFAULT_IMAGE)}
          className="mx-auto"
        />
      </HStack>
      <HStack pl={2} pr={2} mb={2}>
        <NestedInput
          label="SKU"
          field={`productBundleCompanyItems.${index}.skuCode`}
          showTooltip={false}
          fieldProps={{
            size: 'xs',
            readonly: true,
            bgColor: 'light.300'
          }}
          labelProps={{ fontSize: '0.75rem', color: 'lightAndShadow.mainText', h: '1.156rem' }}
        />
      </HStack>
      <HStack pl={2} pr={2} pb={6}>
        <NestedInput
          label="EAN/SUID"
          field={`productBundleCompanyItems.${index}.ean`}
          showTooltip={false}
          fieldProps={{
            size: 'xs',
            readonly: true,
            bgColor: 'light.300'
          }}
          labelProps={{ fontSize: '0.75rem', color: 'lightAndShadow.mainText', h: '1.156rem' }}
        />
      </HStack>
    </Box>
  );
};

const ProductCardsForm = ({ products, isEditMode, onClick, ...rest }) => {
  return (
    <Flex pt={'0.75rem'} flexWrap="wrap">
      {products?.map((product, index) => (
        <Box
          key={`${product?.name + index}`}
          w={'14.25rem'}
          h={'20.063rem'}
          mr={'0.75rem'}
          mb={'0.75rem'}>
          <ProductCardForm
            onRemove={rest?.onRemove}
            index={index}
            product={product}
            isEditMode={isEditMode}
          />
        </Box>
      ))}

      {isEditMode && (
        <SlzButton
          fontSize={'0.75rem'}
          maxW={67}
          borderRadius={12}
          h={320}
          display="grid"
          alignContent="center"
          whiteSpace="pre-line"
          width="full"
          onClick={onClick}>
          Add product
          <Box w={6} ml={2.5} mt={2.5}>
            <Image src="Images/add.png" width="100%" />
          </Box>
        </SlzButton>
      )}
    </Flex>
  );
};

export default ProductCardsForm;
