const borders = {
  common: '1px solid #C3C4CA',
  commonThin: '0.5px solid #C3C4CA',
  commonGrey: '1px solid #DEDEDE',
  commonBlue: '2px solid #0973EA',
  commonRed: '2px solid #DE4259',
  commonStone: '1px solid #C3C4CB',
  commonStrawberry: '1px solid #FC506B'
};

export default borders;
