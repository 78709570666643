import { HStack } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import map from 'lodash/map';

import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';

import { BILLING_PROCESS } from 'modules/billing/constants';
import { transformBilling } from 'modules/billing/mappers/billing-list-mapper';
import { getBilling } from 'modules/billing/services/billing-api';
import { COLLECTION_COLS, INVOICE_COLS, REMITTANCE_COLS } from '../table/billing.columns';

const BillingFilter = (props) => {
  const [displayFields, setDisplayFields] = useState([]);
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  useEffect(() => {
    let displayFields = [];
    switch (props?.process) {
      case BILLING_PROCESS.COLLECTION_ADVICE:
        displayFields = map(
          COLLECTION_COLS.filter((col) => col.isDisplayed),
          'field'
        );
        break;
      case BILLING_PROCESS.REMITTANCE_ADVICE:
        displayFields = map(
          REMITTANCE_COLS.filter((col) => col.isDisplayed),
          'field'
        );
        break;
      default:
        displayFields = map(
          INVOICE_COLS.filter((col) => col.isDisplayed),
          'field'
        );
        break;
    }

    setDisplayFields(displayFields);
  }, [props?.process]);

  const transformFn = (data) =>
    transformBilling({
      billing: data,
      process: props?.process,
      wareHouses: props?.wareHouses
    }).items;

  const handleAutocompleteSearch = (searchTerm) => {
    let searchProcess = {
      process: props?.process
    };

    switch (props?.process) {
      case BILLING_PROCESS.COLLECTION_ADVICE:
        setSearchParams({
          ...searchProcess,
          pageIndex: DEFAULT_PAGE_INDEX,
          collectionAdviceNumber: searchTerm
        });
        break;
      case BILLING_PROCESS.REMITTANCE_ADVICE:
        setSearchParams({
          ...searchProcess,
          pageIndex: DEFAULT_PAGE_INDEX,
          remittanceAdviceNumber: searchTerm
        });
        break;
      default:
        setSearchParams({
          ...searchProcess,
          pageIndex: DEFAULT_PAGE_INDEX,
          invoiceNumber: searchTerm
        });
        break;
    }
  };

  return (
    <Fragment>
      <HStack mb={6} gap="1.563rem">
        <SlzAutocompleteInput
          width="50%"
          variant="accent"
          showedFields={displayFields}
          searchBy={[displayFields[0]]}
          placeholder="Search"
          onTypingSearch={handleAutocompleteSearch}
          onNavigate={(record) => props?.handleOpenModal(record)}
          process={props?.process}
          fetchFn={getBilling}
          transformFn={transformFn}
          searchParams={searchParams}
        />

        <SlzDateRangePicker
          onDateRangeClick={(startDate, endDate) =>
            props?.handleSelectedDateRange(startDate, endDate)
          }
          startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
          endDatePlaceHolder={TODAY}
          startDate={null}
          endDate={dayjs()}
        />
      </HStack>
    </Fragment>
  );
};

export default BillingFilter;
