import React from 'react';
import { Icon } from '@chakra-ui/react';

const SelazarLogo = ({ showName = true, color = '#fc148a', ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width={showName ? '173.3' : '33.283'}
      height="33.283"
      viewBox={showName ? '0 0 173.3 33.283' : '0 0 33.283 33.283'}
      color={color}
      {...props}>
      <g id="Group_33759" data-name="Group 33759" transform="translate(-617 -1073.994)">
        <g id="Group_8" data-name="Group 8" transform="translate(617 1076.706)">
          <g id="Group_7" data-name="Group 7" transform="translate(0)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M24.838,53.5l5.225,5.225a7.943,7.943,0,0,1,0,11.245l-5.225,5.225a14.16,14.16,0,0,1-2.495-3.254l4.845-4.863a3.433,3.433,0,0,0,.56-.705h-7.3a12.643,12.643,0,0,1-.145-2.043,12.792,12.792,0,0,1,.145-2.043h7.268a4.067,4.067,0,0,0-.542-.705l-4.845-4.827A14.759,14.759,0,0,1,24.838,53.5"
              transform="translate(-20.3 -49.053)"
              fill="currentColor"
            />
            <path
              id="Path_4"
              data-name="Path 4"
              d="M88.427,36.294l3.435-6.309a14.777,14.777,0,0,1,3.543,1.989L92.025,38.21a3.312,3.312,0,0,0,.832-.127l6.436-1.844A15.222,15.222,0,0,1,100.938,40L94,41.989a7.932,7.932,0,0,1-9.817-5.442L82.1,29.46a15.036,15.036,0,0,1,4.068-.56l1.88,6.526a3.718,3.718,0,0,0,.38.868"
              transform="translate(-70.928 -28.9)"
              fill="currentColor"
            />
            <path
              id="Path_5"
              data-name="Path 5"
              d="M93.978,124.262l6.942,1.989a15.225,15.225,0,0,1-1.645,3.76l-6.436-1.844a3.631,3.631,0,0,0-.868-.145l3.435,6.237a15.5,15.5,0,0,1-3.543,1.989l-3.453-6.273a4.447,4.447,0,0,0-.362.85l-1.88,6.508a15.033,15.033,0,0,1-4.068-.56l2.043-7.069a7.778,7.778,0,0,1,3.778-4.755,7.871,7.871,0,0,1,6.056-.687"
              transform="translate(-70.928 -106.762)"
              fill="currentColor"
            />
          </g>
        </g>
        {showName && (
          <g id="Group_11" data-name="Group 11" transform="translate(654.965 1073.994)">
            <path
              id="Path_6"
              data-name="Path 6"
              d="M248.017,44.194a3.739,3.739,0,0,0-1.428-3.146,16.9,16.9,0,0,0-5.17-2.224,24.65,24.65,0,0,1-5.948-2.513c-2.82-1.772-4.23-4.068-4.23-6.924a7.568,7.568,0,0,1,3.037-6.165,12.333,12.333,0,0,1,7.9-2.423,13.288,13.288,0,0,1,5.749,1.193,9.578,9.578,0,0,1,3.977,3.381,8.733,8.733,0,0,1,1.446,4.881H248a4.877,4.877,0,0,0-1.519-3.8,6.307,6.307,0,0,0-4.339-1.374,6.613,6.613,0,0,0-4.086,1.121,3.7,3.7,0,0,0-1.446,3.146,3.389,3.389,0,0,0,1.573,2.838,17.661,17.661,0,0,0,5.189,2.206,24.753,24.753,0,0,1,5.8,2.441,8.9,8.9,0,0,1,3.2,3.146,8.1,8.1,0,0,1,1.012,4.158,7.355,7.355,0,0,1-2.965,6.147,12.833,12.833,0,0,1-8.027,2.278,15.029,15.029,0,0,1-6.165-1.247,10.376,10.376,0,0,1-4.375-3.435,8.516,8.516,0,0,1-1.555-5.1h5.369a4.985,4.985,0,0,0,1.736,4.068,7.636,7.636,0,0,0,4.99,1.446,6.614,6.614,0,0,0,4.212-1.139,3.523,3.523,0,0,0,1.41-2.965"
              transform="translate(-230.3 -19.553)"
              fill="#fafafa"
            />
            <path
              id="Path_7"
              data-name="Path 7"
              d="M381.2,69.04a4.372,4.372,0,0,0-3.435,1.482,7.683,7.683,0,0,0-1.663,4.14h9.835v-.38a6.309,6.309,0,0,0-1.374-3.923,4.333,4.333,0,0,0-3.363-1.32m.615,19.669a10.692,10.692,0,0,1-7.955-3.091,11.25,11.25,0,0,1-3.055-8.226v-.633a13.766,13.766,0,0,1,1.32-6.147,10.181,10.181,0,0,1,3.724-4.212,9.757,9.757,0,0,1,5.351-1.5,8.977,8.977,0,0,1,7.249,3c1.7,1.989,2.567,4.827,2.567,8.479v2.079H376.007a6.971,6.971,0,0,0,1.9,4.5,5.71,5.71,0,0,0,4.194,1.663,6.954,6.954,0,0,0,5.767-2.874l2.784,2.658a9.328,9.328,0,0,1-3.688,3.2,11.844,11.844,0,0,1-5.152,1.1"
              transform="translate(-345.4 -55.68)"
              fill="#fafafa"
            />
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="5.152"
              height="32.614"
              transform="translate(48.884 0)"
              fill="#fafafa"
            />
            <g id="Group_9" data-name="Group 9" transform="translate(57.526 9.202)">
              <path
                id="Path_8"
                data-name="Path 8"
                d="M557.286,84.487a5.971,5.971,0,0,0,2.875-.741,4.884,4.884,0,0,0,2.025-1.989V77.419H559.4a7.528,7.528,0,0,0-4.3.994,3.25,3.25,0,0,0-1.447,2.82,3,3,0,0,0,.994,2.368,3.762,3.762,0,0,0,2.639.886m5.659,3.724a8.014,8.014,0,0,1-.6-2.151,8.666,8.666,0,0,1-11.661.6,6.3,6.3,0,0,1-2.187-4.881,6.536,6.536,0,0,1,2.73-5.641c1.826-1.3,4.411-1.971,7.81-1.971H562.2v-1.5a4.015,4.015,0,0,0-.994-2.856,3.936,3.936,0,0,0-3.038-1.067,4.524,4.524,0,0,0-2.892.886,2.693,2.693,0,0,0-1.121,2.242h-5.152a5.764,5.764,0,0,1,1.248-3.543,8.393,8.393,0,0,1,3.4-2.585,12.074,12.074,0,0,1,4.809-.94,9.633,9.633,0,0,1,6.436,2.025,7.239,7.239,0,0,1,2.459,5.695V82.878a11.871,11.871,0,0,0,.868,4.953v.362h-5.279Z"
                transform="translate(-548.5 -64.8)"
                fill="#fafafa"
              />
            </g>
            <path
              id="Path_9"
              data-name="Path 9"
              d="M676.6,86.056h12.239v4.122H670.2V86.8l11.642-15.439H670.4V67.2h17.988v3.272Z"
              transform="translate(-590.673 -57.564)"
              fill="#fafafa"
            />
            <g id="Group_10" data-name="Group 10" transform="translate(100.245 9.202)">
              <path
                id="Path_10"
                data-name="Path 10"
                d="M793.586,84.487a5.97,5.97,0,0,0,2.874-.741,4.883,4.883,0,0,0,2.025-1.989V77.419H795.7a7.53,7.53,0,0,0-4.3.994,3.25,3.25,0,0,0-1.446,2.82,3,3,0,0,0,.994,2.368,3.763,3.763,0,0,0,2.64.886m5.658,3.724a8,8,0,0,1-.6-2.151,8.666,8.666,0,0,1-11.661.6,6.3,6.3,0,0,1-2.188-4.881,6.536,6.536,0,0,1,2.73-5.641c1.826-1.3,4.411-1.971,7.81-1.971H798.5v-1.5a4.017,4.017,0,0,0-.994-2.856,3.937,3.937,0,0,0-3.037-1.067,4.524,4.524,0,0,0-2.893.886,2.717,2.717,0,0,0-1.121,2.242h-5.152a5.766,5.766,0,0,1,1.247-3.543,8.4,8.4,0,0,1,3.4-2.585,12.071,12.071,0,0,1,4.809-.94,9.633,9.633,0,0,1,6.436,2.025,7.24,7.24,0,0,1,2.459,5.695V82.878a11.875,11.875,0,0,0,.868,4.953v.362h-5.279Z"
                transform="translate(-784.8 -64.8)"
                fill="#fafafa"
              />
              <path
                id="Path_11"
                data-name="Path 11"
                d="M924.222,69.952a13.319,13.319,0,0,0-2.1-.163q-3.553,0-4.773,2.712v15.71H912.2V65.234h4.918l.126,2.567a5.832,5.832,0,0,1,5.188-3,4.833,4.833,0,0,1,1.826.289Z"
                transform="translate(-889.168 -64.8)"
                fill="#fafafa"
              />
            </g>
          </g>
        )}
      </g>
    </Icon>
  );
};

export default SelazarLogo;
