import React from 'react';
import { SlzButton, SlzButtonGroup } from 'components/common/SlzButton';

import {
  B2B_ORDER_OPTION_STYLE,
  TOGGLE_BUTTON_INACTIVE_STYLE
} from 'modules/b2b-orders/constants/styles';

const ToggleButton = ({ isOptionDisabled, content, value, onChange }) => {
  return (
    <SlzButtonGroup {...B2B_ORDER_OPTION_STYLE.toggleButton.groupButtons}>
      {content.map(({ icon: Icon, ...button }) => (
        <SlzButton
          key={`${button.text}-${button.value}`}
          onClick={() => onChange(button.value)}
          isDisabled={isOptionDisabled}
          sx={value !== button.value && TOGGLE_BUTTON_INACTIVE_STYLE}
          value={button.value}
          leftIcon={Icon}
          {...B2B_ORDER_OPTION_STYLE.toggleButton.button}>
          {button.text}
        </SlzButton>
      ))}
    </SlzButtonGroup>
  );
};

export default ToggleButton;
