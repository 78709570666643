import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import {
  Card,
  CardBody,
  CardFooter,
  CardIcon,
  CardPermissions,
  CardRoleBanner,
  CardRolesAndPermissions
} from 'modules/users/common/cards/CardRolesAndPermissions';
import { STYLE_STEP_SUMMARY_ROLE_USER } from 'modules/users/constants';

const HorizontalUserManagementCard = (props) => {
  const { icon, permissions, text, label, bgIcon, bgLabel, forename, surname, email } = props;

  return (
    <HStack>
      <CardRolesAndPermissions styles={STYLE_STEP_SUMMARY_ROLE_USER}>
        <Card spacing={0} wrap={HStack}>
          <CardIcon bg={bgIcon}>
            <Icon as={icon} w="3.313rem" h="3.313rem" color="main.500" />
          </CardIcon>
          <CardBody spacing="0px" alignItems="start" flexBasis="50%">
            <VStack h="2.813rem" spacing="0px" alignItems="start">
              <Text color="main.500" fontSize="lg" lineHeight="1.313rem" fontWeight="bold">
                {`${forename} ${surname}`}
              </Text>
              <Text fontSize="12px" lineHeight="4">
                {email}
              </Text>
            </VStack>
            <CardRoleBanner
              w="9.375rem"
              h="1.563rem"
              textAlign="center"
              fontSize="lg"
              bg={bgLabel}
              color="lightAndShadow.reverseText">
              {label}
            </CardRoleBanner>
          </CardBody>
          <CardFooter spacing="0.75">
            <CardRoleBanner
              color="lightAndShadow.reverseText"
              h="1.75rem"
              px="0.75rem"
              justifyContent="start"
              fontSize="sm"
              lineHeight="4"
              bg="main.500">
              {text}
            </CardRoleBanner>
            <CardPermissions justifyContent="flex-start">{permissions}</CardPermissions>
          </CardFooter>
        </Card>
      </CardRolesAndPermissions>
    </HStack>
  );
};

export default HorizontalUserManagementCard;
