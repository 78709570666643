import { Text, VStack } from '@chakra-ui/react';
import SlzTable from 'components/common/SlzTable/Table';
import { BoxHiddenScroll } from 'components/layout';

const B2BOrderSummaryTable = ({ title, columns, rows, titleStyles, ...rest }) => {
  return (
    <VStack w="full" alignItems="flex-start" {...rest}>
      <Text fontWeight="medium" lineHeight={5.5} {...titleStyles}>
        {title}
      </Text>
      {rows && (
        <BoxHiddenScroll maxH="16.875rem" w="full">
          <SlzTable
            borderRadius={1.5}
            variant="b2bOrderSummary"
            autoResize={false}
            showBoxShadow={false}
            showToolbar={false}
            columns={columns}
            data={rows}
            displayFields={columns?.map((col) => col.field)}
          />
        </BoxHiddenScroll>
      )}
    </VStack>
  );
};

export default B2BOrderSummaryTable;
