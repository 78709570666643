import { HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { BULK_ORDER_TAB } from 'modules/b2b-orders/constants';

const BulkOrderTab = ({ tabIndex, onTabChange }) => {
  return (
    <HStack gap="25px" w="full" mb={6}>
      <Tabs variant="line" size={['sm', 'md', 'lg']} index={tabIndex} onChange={onTabChange}>
        <TabList flexWrap={'wrap'}>
          {Object.values(BULK_ORDER_TAB).map((tab) => (
            <Tab w={36} key={tab.key}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </HStack>
  );
};

export default BulkOrderTab;
