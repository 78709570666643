import { Text } from '@chakra-ui/react';
import * as yup from 'yup';

export const creditValidationSchema = yup.object({
  forename: yup.string().required('Forename is required'),
  surname: yup.string().required('Surname is required'),
  email: yup.string().required('Email is required').email('Your email is invalid'),
  addressLine1: yup.string().required('Address line 1 is required'),
  town: yup.string().required('Town is required'),
  postCode: yup.string().required('Postcode is required')
});

export const directDebitValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email('Your email is invalid'),
  phone: yup.string().required('Phone is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  town: yup.string().required('Town is required'),
  postCode: yup.string().required('Postcode is required'),
  bankAccountType: yup.string().required('Bank account type is required')
});

export const iossNumberValidationSchema = (regex) =>
  yup.object({
    isActiveIOSS: yup.bool(),
    euioss: yup.string().matches(new RegExp(regex), {
      message: (
        <Text fontSize="md" fontWeight="normal">
          *Incorrect IOSS number format
        </Text>
      )
    })
  });
