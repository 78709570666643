import React, { useEffect } from 'react';
import { Box, HStack, Heading, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { PAYMENT_CARD_STATUS } from 'constants/credit-card';
import { SlzCreditCard, SlzDirectDebitCard } from 'components/common/SlzPaymentCard';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import SlzToolTip from 'components/common/SlzToolTip';
import useSlzToast from 'hooks/useSlzToast';
import { COMPANY_NAVIGATE_MENU_STATUS } from 'constants/common';
import SetupRequirement from 'components/common/SetupRequirement';
import { filterRequiredSettings } from 'utils/helper';
import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';

import {
  useGetCreditCardInfo,
  useGetDirectDebitInfo,
  useHasDirectDebit,
  useRemoveCreditCard,
  useRemoveDirectDebit
} from 'modules/preferences/hooks';
import {
  MESSAGES,
  PREFERENCES_ACTIONS,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';

const CustomSetupRequirement = ({
  settings,
  isCreditCardAvailable,
  addCreditCard,
  onItemClick
}) => (
  <SetupRequirement mt="1.375rem">
    {filterRequiredSettings(settings).map((setting, index) => {
      if (setting === COMPANY_NAVIGATE_MENU_STATUS.paymentMethods && !isCreditCardAvailable)
        return (
          <SetupRequirement.RequiredSetting
            key={setting}
            cardinalNumber={index + 1}
            setting={setting}>
            <SlzCreditCard onSetup={addCreditCard} />
          </SetupRequirement.RequiredSetting>
        );
      return (
        <SetupRequirement.RequiredSetting
          key={setting}
          cardinalNumber={index + 1}
          onItemClick={onItemClick}
          setting={setting}
        />
      );
    })}
  </SetupRequirement>
);

export default function PaymentSetting({
  requiredSettings,
  isRequiredSettingsEmpty,
  isRequiredSettingsFetching,
  updateRequiredSettings,
  activatedSetting,
  ...props
}) {
  const {
    data: creditCard,
    isLoading: isLoadingCreditCard,
    refetch: refetchCreditCardInfo
  } = useGetCreditCardInfo();
  const { mutate: removeCreditCard, isSuccess: isRemoveCreditCardSuccess } = useRemoveCreditCard();
  const { data: hasDirectDebit } = useHasDirectDebit();
  const {
    data: directDebit,
    isSuccess: isGetDirectDebitSuccess,
    refetch: refetchDirectDebitInfo
  } = useGetDirectDebitInfo(hasDirectDebit);
  const { mutate: removeDirectDebit, isSuccess: isRemoveDirectDebitSuccess } =
    useRemoveDirectDebit();
  const { preferenceDispatch } = usePreferenceContext();
  const [_, setToast] = useSlzToast();
  const isDirectDebitPending = directDebit?.status === PAYMENT_CARD_STATUS.PENDING;

  const addCreditCard = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.credit.key }
    });
  };

  const addDirectDebit = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.directDebit.key }
    });
  };

  useEffect(() => {
    if (isRemoveCreditCardSuccess) {
      setToast({ description: MESSAGES.REMOVE_PAYMENT_CARD_SUCCESS });
      refetchCreditCardInfo();

      updateRequiredSettings();
    }
  }, [isRemoveCreditCardSuccess]);

  useEffect(() => {
    if (isRemoveDirectDebitSuccess) {
      setToast({ description: MESSAGES.REMOVE_DEBIT_PAYMENT_CARD_SUCCESS });
      refetchDirectDebitInfo();
    }
  }, [isRemoveDirectDebitSuccess]);

  const isCreditCardAvailable =
    creditCard?.status === (PAYMENT_CARD_STATUS.SUCCESSFUL || PAYMENT_CARD_STATUS.ACTIVE);

  return (
    <Box>
      <PreferenceSkeleton
        isLoaded={!isLoadingCreditCard && isGetDirectDebitSuccess}
        template={activatedSetting}>
        <Box mb={8}>
          <HStack mb={3}>
            <Heading as="h3" size="md">
              Active Cards
            </Heading>
            <SlzToolTip content="Active Cards" placement="top-start" variant="blue">
              <InfoOutlineIcon boxSize="3" color="main.500" />
            </SlzToolTip>
          </HStack>

          <SetupRequirement.RequirementValidation
            requiredSettingProps={{
              isRequiredSettingsFetching,
              isRequiredSettingsEmpty,
              requiredSettings
            }}
            isCreditCardAvailable={isCreditCardAvailable}
            addCreditCard={addCreditCard}
            onItemClick={props.onItemClick}
            setup={CustomSetupRequirement}>
            {() =>
              !isLoadingCreditCard &&
              isCreditCardAvailable && (
                <SlzCreditCard
                  info={creditCard}
                  onSetup={addCreditCard}
                  onRemove={removeCreditCard}
                />
              )
            }
          </SetupRequirement.RequirementValidation>
        </Box>
        {hasDirectDebit && (
          <Box>
            <HStack mb={3}>
              <Heading as="h3" size="md">
                Direct debits
              </Heading>
              <SlzToolTip content="Direct debits" placement="top-start" variant="blue">
                <InfoOutlineIcon boxSize="3" color="main.500" />
              </SlzToolTip>
            </HStack>

            <Text mb={3}>
              Streamline your payment process by connecting your bank account.
              <br />
              Set up your direct debit here. 
              <br />
              We currently only accept UK bank accounts.
            </Text>

            {isGetDirectDebitSuccess && (
              <Box mb="1.25rem">
                <SlzDirectDebitCard
                  info={directDebit}
                  onSetup={addDirectDebit}
                  onRemove={removeDirectDebit}
                />

                {isDirectDebitPending && (
                  <SlzAttentionCard
                    mt={5}
                    py="0.938rem !important"
                    variant="solid"
                    maxW="30.688rem"
                    icon={null}
                    status="success">
                    <Text fontSize="md" color="positive.500">
                      {MESSAGES.DIRECT_DEBIT_PENDING}
                    </Text>
                  </SlzAttentionCard>
                )}
              </Box>
            )}
          </Box>
        )}
      </PreferenceSkeleton>
    </Box>
  );
}
