export const EDIT_USER_STYLES = {
  editPasswordButton: {
    fontSize: 'xs',
    pl: 2,
    fontWeight: 'normal',
    cursor: 'pointer',
    color: 'main.500'
  },
  editPasswordContent: {
    w: '26.25rem',
    h: '17.938rem',
    p: 6,
    borderRadius: 3,
    borderColor: 'main.500',
    boxShadow: 'slz-md'
  },
  arrow: {
    top: '-1px !important',
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderColor: 'main.500'
  }
};
