export const disabledCheckboxStyles = {
  background: 'kinglyCloud.500',
  borderRadius: '0.313rem',
  borderColor: 'elemental.500',
  borderWidth: '0.125rem',
  padding: '0.25rem 0.625rem',
  color: 'flatAluminum.500',
  disabled: true
};

export const enabledCheckboxStyles = {
  borderRadius: '0.313rem',
  borderColor: 'aero.500',
  borderWidth: '0.125rem',
  padding: '0.25rem 0.625rem',
  disabled: false
};

export const CONSIGNMENT_LABELLING_STYLES = {
  text: {
    textAlign: 'left',
    lineHeight: '1.25rem',
    letterSpacing: '-0.009rem',
    fontSize: '1rem',
    color: 'table.mainText'
  },
  title: {
    bg: 'light.500',
    variant: 'filled',
    width: '100%',
    height: '3rem',
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    variant: 'filled',
    width: '21rem',
    display: 'flex',
    justifyContent: 'center'
  },
  wrapperCard: {
    bg: 'light.500',
    mt: '2rem',
    w: '100%',
    variant: 'filled',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.2rem !important',
    px: '1.5rem'
  }
};
