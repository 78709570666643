import { Box, Text, useToken } from '@chakra-ui/react';
import {
  DELIVERY_DESTINATION,
  DELIVERY_DESTINATION_CHART_YLABEL,
  DELIVERY_DESTINATION_XAXIS_HOLDER,
  DELIVERY_DESTINATION_XAXIS_INDICES
} from 'modules/dashboard/constants';
import React, { useCallback, useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Rectangle
} from 'recharts';
import get from 'lodash/get';
import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import { shouldAddBarRadius } from 'modules/dashboard/utils';
import { CustomTooltipContent } from 'modules/dashboard/components/other';

const YAXIX_TICK_PADDING = 17;
const YAXIX_TICK_OFFSET_X = -22;
const YAXIX_TICK_OFFSET_Y = -24;
const XAXIX_TICK_LINE_OFFSET = 11;
const XAXIX_TICK_LINE_HEIGHT = 4;

const DeliveryDestinationChart = ({ data, isLoading }) => {
  const { chartData = [], total } = data;

  const referenceLineColor = useToken('colors', 'dark.300');

  const [posData, setPosData] = useState(null);

  const chartPopulatedData = useMemo(
    () =>
      chartData.reduce((acc, curr) => {
        acc[curr.field] = curr.value;
        return acc;
      }, {}),
    [chartData]
  );
  const references = useMemo(
    () =>
      Array(3)
        .fill(null)
        .map((_item, idx) => Math.round((total / 3) * (idx + 1))),
    [total]
  );

  const ticks = useMemo(
    () =>
      Array(10)
        .fill(null)
        .map((_item, idx) => Math.round((total / 9) * idx)),
    [total]
  );

  const onMouseOver = useCallback((data, index) => {
    setPosData(data);
  }, []);

  const onMouseLeave = useCallback((data, index) => {
    setPosData(null);
  }, []);

  return (
    <ResponsiveContainer height={113} width="110%">
      <BarChart
        barCategoryGap={2}
        barGap={6}
        data={[chartPopulatedData]}
        layout="vertical"
        style={{ transform: 'translate(-2rem, 1.5rem)' }}
        onMouseLeave={onMouseLeave}>
        {references.map((val, idx) => (
          <ReferenceLine
            key={val.toString()}
            x={val}
            stroke={referenceLineColor}
            style={{ transform: `translate(${idx}px, 0px)` }}
          />
        ))}
        {!isLoading &&
          chartData.map((item, idx) => (
            <Bar
              key={`${item.field}-stack`}
              dataKey={item.field}
              fill={item.background}
              stackId="total"
              radius={shouldAddBarRadius(chartData, idx) ? [0, 4, 4, 0] : null}
              style={{ transform: `translate(${idx}px, 0px)` }}
              onMouseOver={onMouseOver}
              shape={(props) => <CustomShape {...props} data={posData} />}
            />
          ))}

        {chartData.map((item) => (
          <Bar
            key={item.field}
            dataKey={item.field}
            fill={item.background}
            radius={[0, 4, 4, 0]}
            onMouseOver={onMouseOver}
            shape={(props) => <CustomShape {...props} data={posData} isLoading={isLoading} />}
          />
        ))}
        <XAxis
          type="number"
          axisLine={false}
          ticks={ticks}
          tickLine={false}
          style={{ transform: 'translate(0px, -5px)' }}
          tickMargin={5}
          tick={(props) => <CustomizedXAxisTick isLoading={isLoading} {...props} />}
          tickCount={10}
          minTickGap={-100}
        />
        <YAxis
          type="category"
          axisLine={{
            stroke: 'black',
            strokeLinejoin: 'round',
            strokeLinecap: 'round',
            isFront: true,
            strokeWidth: 2
          }}
          tickLine={false}
          dataKey="name"
          tick={(props) => <CustomizedYAxisTick isLoading={isLoading} {...props} />}
        />
        {!isLoading && !!posData && (
          <Tooltip
            content={(props) => <CustomTooltip {...props} data={posData} />}
            position={{
              x: posData?.tooltipPosition?.x - 5,
              y: posData.tooltipPosition?.y - 55
            }}
            cursor={{ fill: 'none' }}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DeliveryDestinationChart;

const CustomShape = (props) => {
  const { fill, x, y, width, height, radius, data, style, isLoading } = props;
  const field = get(data, 'tooltipPayload[0].name', '');
  const barField = get(props, 'tooltipPayload[0].name', '');
  const hightlightStrokeColor = useToken('colors', 'main.500');

  if (isLoading) {
    return (
      <foreignObject
        x={x}
        y={y}
        width={width + 2}
        height={12}
        style={{ borderTopRightRadius: '3px', borderBottomRightRadius: '3px' }}>
        <ShimmerSkeleton.Block w="full" h={12} backgroundColor="dark.300" />
      </foreignObject>
    );
  }

  if (field !== barField) {
    return (
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={12}
        stroke="none"
        fill={fill}
        opacity={!!field ? 0.5 : 1}
        radius={radius}
        style={style}
      />
    );
  }

  return (
    <>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={12}
        stroke="none"
        fill={fill}
        radius={radius}
        style={style}
      />
      <Rectangle x={x} y={y} width={width} height={12} stroke="white" fill="none" />
      <Rectangle
        x={x - 0}
        y={y - 1}
        width={width + 1}
        height={14}
        stroke={hightlightStrokeColor}
        fill="none"
      />
      ;
    </>
  );
};

const CustomTooltip = (props) => {
  const { data = {} } = props;
  const { tooltipPayload } = data;

  const orderPayload = get(tooltipPayload, '[0].payload', {});
  const field = get(tooltipPayload, '[0].name', '');
  const value = get(orderPayload, field, '');
  const legendLabel = DELIVERY_DESTINATION.find((item) => item.field === field)?.legendLabel;

  return (
    <CustomTooltipContent transform="translateX(-47%) translateY(0rem)">
      <Box padding="0.813rem 0.688rem">
        <Text fontSize="sm" fontWeight="bold" color="raisin.300">
          {`${value} orders delivered (${legendLabel})`}
        </Text>
      </Box>
    </CustomTooltipContent>
  );
};

const CustomizedXAxisTick = (props) => {
  const { x, y, index, payload, isLoading } = props;

  const tickLineColor = useToken('colors', 'dark.300');
  const tickColor = useToken('colors', 'dark.500');

  if (!DELIVERY_DESTINATION_XAXIS_INDICES.includes(index)) {
    return (
      <line
        orientation="bottom"
        stroke={tickLineColor}
        fill="none"
        x1={x + index / 3}
        y1={y - XAXIX_TICK_LINE_OFFSET - XAXIX_TICK_LINE_HEIGHT}
        x2={x + index / 3}
        y2={y - XAXIX_TICK_LINE_OFFSET}
      />
    );
  }

  return (
    <g transform={`translate(${x},${y + 1})`}>
      <text fontSize={10} textAnchor="middle" fill={tickColor}>
        {isLoading ? DELIVERY_DESTINATION_XAXIS_HOLDER[index] : payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, isLoading } = props;
  const tickColor = useToken('colors', 'dark.700');

  if (isLoading) return null;

  return (
    <g transform={`translate(${x},${y})`}>
      {DELIVERY_DESTINATION_CHART_YLABEL.map((item, idx) => (
        <text
          x={YAXIX_TICK_OFFSET_X}
          fontSize={10}
          y={YAXIX_TICK_OFFSET_Y + idx * YAXIX_TICK_PADDING}
          textAnchor="start"
          key={item}
          fill={tickColor}>
          {item}
        </text>
      ))}
    </g>
  );
};
