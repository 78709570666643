import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle(({ theme }) => ({
  bgColor: getColor(theme, 'dark.300'),
  fontSize: 'md',
  px: 2.5,
  h: 7.5,
  borderRadius: '0.1875rem',
  w: 'full',
}));

const sizes = {
  lg: defineStyle({
    fontSize: 'lg',
    px: 4,
    h: '12',
    borderRadius: '0.375rem'
  }),
  md: defineStyle({
    fontSize: 'md',
    px: 3,
    h: '10',
    borderRadius: '0.375rem'
  }),
  sm: defineStyle({
    fontSize: 'md',
    px: 2.5,
    h: 7.5,
    borderRadius: '0.1875rem'
  }),
  xs: defineStyle({
    fontSize: 'sm',
    px: 2,
    height: 6,
    borderRadius: '0.1875rem'
  })
};

export default defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
    variant: 'square'
  }
});