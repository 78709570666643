import React from 'react';
import { Icon } from '@chakra-ui/react';

const MasterCardIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 138.994 85.908"
      fill="currentColor"
      width="20px"
      height="20px"
      {...props}>
      <rect
        id="Rectangle_982"
        data-name="Rectangle 982"
        width="37.584"
        height="67.54"
        transform="translate(50.703 9.185)"
        fill="#f16522"
      />
      <path
        id="Path_1526"
        data-name="Path 1526"
        d="M114.157,94.587a42.88,42.88,0,0,1,16.406-33.769,42.954,42.954,0,1,0,0,67.54,42.883,42.883,0,0,1-16.406-33.77"
        transform="translate(-61.066 -51.633)"
        fill="#e41b24"
      />
      <path
        id="Path_1527"
        data-name="Path 1527"
        d="M230.755,94.585a42.95,42.95,0,0,1-69.494,33.769,42.963,42.963,0,0,0,0-67.54,42.953,42.953,0,0,1,69.494,33.77m-4.1,26.617V119.82h.558v-.281h-1.422v.281h.558V121.2Zm2.757,0v-1.666h-.436l-.5,1.147-.5-1.147h-.436V121.2h.305v-1.258l.47,1.086h.319l.47-1.088v1.26Z"
        transform="translate(-91.761 -51.633)"
        fill="#f89e1c"
      />
    </Icon>
  );
};

export default MasterCardIcon;
