import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Checkbox, HStack, IconButton, VStack } from '@chakra-ui/react';
import size from 'lodash/size';
import { SlzButton } from 'components/common/SlzButton';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import { tooltipContent } from 'pages/Elements/ElementOne';
import { uploadSingleImage } from 'modules/products/services/products-api';
import { BaseUploadImage } from './common/BaseUploadImage';

const UniqueIdentifiers = () => {
  const {
    watch,
    register,
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  const mutationUploadImage = useMutation({
    mutationFn: (file) => uploadSingleImage(file)
  });

  const productImgValue = getValues('imageUrl');
  const imgURL = productImgValue ? URL.createObjectURL(productImgValue) : '';
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sku'
  });

  const handleSetDefaultImageForSKU = useCallback(async () => {
    const { imageUrl: imageField, sku } = getValues();

    if (sku?.length > 0 && imageField) {
      if (imageField.name !== sku[0].uploadedImage?.fileName) {
        const result = await mutationUploadImage.mutateAsync(imageField);

        const {
          data: { data }
        } = result;

        sku.forEach((element, idx) => {
          if (idx === 0 || element.useMainImage) {
            setValue(`sku.${idx}.uploadedImage`, data);
            setValue(`sku.${idx}.imageUrl`, data.link);
          }
        });
      }
    }
  });

  const handleUseMainImage = (value, index) => {
    const { imageUrl, sku } = getValues();
    setValue(`sku.${index}.imageUrl`, value ? URL.createObjectURL(imageUrl) : '');
    setValue(`sku.${index}.uploadedImage`, value ? sku[0]?.uploadedImage : undefined);
    setValue(`sku.${index}.useMainImage`, value);
  };

  const handleOnLoadSKUImage = (index) => async (file) => {
    const result = await mutationUploadImage.mutateAsync(file);
    const {
      data: { data }
    } = result;

    setValue(`sku.${index}.uploadedImage`, data);
    setValue(`sku.${index}.imageUrl`, data.link);
  };

  const handleAddSKUField = () => {
    append({});

    const { sku } = getValues();

    if (sku.length === 1) {
      handleSetDefaultImageForSKU();
    }
  };

  useEffect(() => {
    handleSetDefaultImageForSKU();
  }, [watch('imageUrl')]);

  return (
    <VStack spacing="4" alignItems="flex-start">
      <HStack w="full" spacing={3} alignItems="flex-end">
        <Box w="15rem">
          <NestedInput
            field="ean"
            label="EAN (Optional)"
            tooltipContent={tooltipContent}
            useTooltipLabel={true}
            labelProps={{ color: 'dark.700' }}
            fieldProps={{ bgColor: 'light.300' }}
          />
        </Box>
        <Box>
          <Checkbox {...register('noScan')} size="sm">
            This product has no barcode
          </Checkbox>
        </Box>
      </HStack>

      <HStack spacing={3} alignItems="flex-end">
        <Box w="15rem">
          <NestedInput
            field="isbn"
            label="ISBN (Optional)"
            tooltipContent={tooltipContent}
            useTooltipLabel={true}
            labelProps={{ color: 'dark.700' }}
            fieldProps={{ bgColor: 'light.300' }}
          />
        </Box>
        <Box w="10.5rem">
          <SlzButton rounded="3px" px={3} onClick={handleAddSKUField}>
            Add SKU
          </SlzButton>
        </Box>
      </HStack>

      {size(fields) > 0 && (
        <VStack spacing={6}>
          {fields.map((item, index) => (
            <HStack key={item.id} spacing={3} alignItems="flex-start">
              <BaseUploadImage
                field={`sku.${index}.imageUrl`}
                control={control}
                defaultImageURL={index === 0 && imgURL}
                bgColor="light.300"
                onLoadFile={handleOnLoadSKUImage(index)}
              />
              <VStack spacing={3} alignItems="flex-start">
                <HStack spacing={3} alignItems="flex-end">
                  <Box w="15rem">
                    <NestedInput
                      field={`sku.${index}.skuCode`}
                      label={`SKU ${index + 1}`}
                      tooltipContent={tooltipContent}
                      useTooltipLabel={true && index === 0}
                      showTooltip={index === 0}
                      labelProps={{ color: 'dark.700' }}
                      fieldProps={{ bgColor: 'light.300' }}
                    />
                  </Box>
                  <Box w="15rem">
                    <NestedInput
                      field={`sku.${index}.skuDescription`}
                      label="SKU Description"
                      tooltipContent={tooltipContent}
                      useTooltipLabel={true && index === 0}
                      showTooltip={index === 0}
                      labelProps={{ color: 'dark.700' }}
                      fieldProps={{ bgColor: 'light.300' }}
                    />
                  </Box>
                  <Box>
                    <IconButton
                      variant="outline"
                      aria-label="Delete a SKU"
                      icon={<CloseIcon />}
                      size="sm"
                      rounded="full"
                      colorScheme="red"
                      my="3px"
                      onClick={() => remove(index)}
                    />
                  </Box>
                </HStack>
                {index !== 0 && (
                  <Checkbox
                    size="sm"
                    onChange={(event) => handleUseMainImage(event.target.checked, index)}>
                    Use main image
                  </Checkbox>
                )}
              </VStack>
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  );
};

export default UniqueIdentifiers;
