import {
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  Text
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import React, { Fragment } from 'react';
import { INVOICE_TYPE } from '../../constants';

const tableStyle = {
  'th, td': {
    textAlign: 'left',
    border: 'none',
    h: 'auto'
  },
  'tr:hover, td:hover': { bg: 'none', cursor: 'unset' },
  'tr, td': { bg: 'none' }
};

const tableBodyStyle = {
  tr: { bg: 'white' },
  td: { pl: '20px', pr: 5, py: '1rem', borderBottom: '3px solid #F2F2F2', color: 'inherit' },
  'tr > td:first-child': { pr: 10 }
};

const tableFooterStyle = {
  th: { bg: 'main.300', px: 5, py: 2, fontSize: '1rem' },
  _before: { content: "''", display: 'block', h: 3 }
};

const Cell = ({ content }) => {
  if (Array.isArray(content)) {
    return (
      <>
        <VStack spacing={'5px'}>
          {content.map((row) => (
            <Flex
              w="full"
              alignContent="center"
              justifyContent="space-between"
              sx={{ fontWeight: !!row?.isBold ? '700' : 'normal' }}>
              <chakra.div sx={{ maxW: '75%', whiteSpace: 'break-spaces' }}>
                {row?.content}
              </chakra.div>{' '}
              <chakra.div>{row?.fee}</chakra.div>
            </Flex>
          ))}
        </VStack>
      </>
    );
  }

  return content;
};

const InvoiceTypeTable = ({ rows = [], headers = [], totalInformation = {} }) => {
  return (
    <>
      <TableContainer>
        <Table
          sx={{
            ...tableStyle,
            'th, td': { textAlign: 'left', border: 'none', h: '8 !important' }
          }}>
          <Thead
            sx={{
              th: { bg: 'white', px: 5, borderBottom: '3px solid #F2F2F2' }
            }}>
            <Tr>
              {headers.map((header) => (
                <Th bg="white" fontSize="1rem">
                  {header?.label || 'header'}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody sx={tableBodyStyle}>
            {rows.map((row) => (
              <Tr>
                {headers.map((header) => {
                  return (
                    <Td>
                      <Cell content={row[header?.key] || ''} />
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>

          {/* <Tfoot h="auto" sx={tableFooterStyle}>
          <Tr mt="5">
            <Th>{totalInformation?.name || 'Total'}</Th>
            <Th isNumeric>{totalInformation?.value || 0}</Th>
          </Tr>
        </Tfoot> */}
        </Table>
      </TableContainer>
      <Flex justifyContent="end" sx={{ mt: '9px' }}>
        <Flex
          justifyContent="space-between"
          alignItems="baseline"
          sx={{
            w: '50%',
            px: 5,
            py: 2,
            bg: 'main.300',
            span: { fontSize: '1rem', fontWeight: 'bold' }
          }}>
          <span>{totalInformation?.name || 'Total'}</span>
          <span>{totalInformation?.value || 0}</span>
        </Flex>
      </Flex>
    </>
  );
};

const OrdersInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      orderId: '106269',
      date: '09/01/2023 22:59',
      chargeDetails: [
        {
          content: 'Service Code: HUK1, Service Name: 48HR Mainland',
          fee: '£3.54'
        },
        {
          content: 'Cross Zonal Charge',
          fee: '£0.00'
        },
        {
          content: 'Fuel Surcharge',
          fee: '£0.00'
        },
        {
          content: 'Item picking charge(x2)',
          fee: '£0.55'
        },
        {
          content: 'Packing Charge',
          fee: '£0.63'
        },
        {
          content: 'Packaging Charge',
          fee: '£0.30'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£5.02'
        }
      ]
    },
    {
      orderId: '106272',
      date: '09/01/2023 22:59',
      chargeDetails: [
        {
          content: 'Service Code: HUK1, Service Name: 48HR Mainland',
          fee: '£3.54'
        },
        {
          content: 'Cross Zonal Charge',
          fee: '£0.00'
        },
        {
          content: 'Fuel Surcharge',
          fee: '£0.00'
        },
        {
          content: 'Item picking charge(x2)',
          fee: '£0.55'
        },
        {
          content: 'Packing Charge',
          fee: '£0.63'
        },
        {
          content: 'Packaging Charge',
          fee: '£1.30'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£5.21'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'orderId', label: 'Order details' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Additional costs total', value: '734.37' };
  return (
    <Fragment>
      {title && <Heading my={5}>{title}</Heading>}
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
    </Fragment>
  );
};

const BulkOrdersInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      orderId: 'TOWEL-001',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: 'Service Code: SFP, Service Name: Self Fulfilled Package',
          fee: '£9.30'
        },
        {
          content: 'Picking Charge',
          fee: '£27.66'
        },
        {
          content: 'Packing Charge',
          fee: '£3.17'
        },
        {
          content: 'Packaging Charge',
          fee: '£6.50'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£46.63'
        }
      ]
    },
    {
      orderId: 'TOWEL-002',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: 'Service Code: SFP, Service Name: Self Fulfilled Package',
          fee: '£8.10'
        },
        {
          content: 'Picking Charge',
          fee: '£16.87'
        },
        {
          content: 'Packing Charge',
          fee: '£0.90'
        },
        {
          content: 'Packaging Charge',
          fee: '£6.50'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£32.37'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'orderId', label: 'Purchase order number' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Additional costs total', value: '734.37' };
  return (
    <Fragment>
      {title && <Heading my={5}>{title}</Heading>}
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
    </Fragment>
  );
};

const CreditNoteInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      creditNoteRef: 'CN1000483',
      orderId: '93238',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: `93238 1003987974 JD0002247509039291 Duplicate order shipped 93274 1003987974 JD0002247509039289 Duplicate order shipped 94116 parcel - not received by yodel`,
          fee: '£734.37'
        }
      ]
    },
    {
      creditNoteRef: 'CN1000484',
      orderId: '93239',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: `93238 1003987974 JD0002247509039291 Duplicate order shipped 93274 1003987974 JD0002247509039289 Duplicate order shipped 94116 parcel - not received by yodel`,
          fee: '£734.37'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'creditNoteRef', label: 'Credit note reference' },
    { key: 'orderId', label: 'Order ID' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Additional costs total', value: '734.37' };
  return (
    <Fragment>
      {title && <Heading my={5}>{title}</Heading>}
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
    </Fragment>
  );
};

const AdditionalCostInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      orderId: 'N/A',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: `30-11-22 - Off site storage and move back to site - Packaging`,
          fee: '£734.37'
        }
      ]
    },
    {
      orderId: '93239',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: `SPE-22 - SHOPIFYLB_1029 - Shopify unique order number updates`,
          fee: '£634.37'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'orderId', label: 'Order ID' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Additional costs total', value: '734.37' };
  return (
    <Fragment>
      {title && <Heading my={5}>{title}</Heading>}
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
    </Fragment>
  );
};

const StockConsignmentInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      stockConsignment: 'LB_FOUND_STOCK_09.01.22_P12.csv',
      date: '09/01/2023 15:15',
      chargeDetails: [
        {
          content: `Stock Quality Check`,
          fee: '£0.01'
        },
        {
          content: `Stock Check-in Run`,
          fee: '£0.04'
        },
        {
          content: `Stock Box Labels(x7)`,
          fee: '£0.14'
        },
        {
          content: `Pallet Placement`,
          fee: '£1.95'
        },
        {
          content: `Box Placement`,
          fee: '£1.52'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£5.57'
        }
      ]
    },
    {
      stockConsignment: 'lb located stock 090123 P13 .csv',
      date: '15/12/2022 22:59',
      chargeDetails: [
        {
          content: `Stock Quality Check`,
          fee: '£0.01'
        },
        {
          content: `Stock Check-in Run`,
          fee: '£0.04'
        },
        {
          content: `Stock Box Labels(x7)`,
          fee: '£0.14'
        },
        {
          content: `Pallet Placement`,
          fee: '£1.95'
        },
        {
          content: `Box Placement`,
          fee: '£1.52'
        },
        {
          isBold: true,
          content: 'Total',
          fee: '£5.57'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'stockConsignment', label: 'Stock consignment' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Additional costs total', value: '734.37' };
  return (
    <Fragment>
      {title && <Heading my={5}>{title}</Heading>}
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
    </Fragment>
  );
};

const StockReplenishmentInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      productDetail: 'Fillted Sheet - Emperor - White SKU: fts9whi-fts',
      date: '27/06/2022 15:00:00',
      chargeDetails: [
        {
          content: '2m 30s x £0.40',
          fee: '£1.00'
        }
      ]
    },
    {
      productDetail: 'Fillted Sheet - Double - White SKU: fts9whi-fts',
      date: '27/06/2022 15:00:00',
      chargeDetails: [
        {
          content: '2m 30s x £0.40',
          fee: '£1.00'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'productDetail', label: 'Product details' },
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Shelf replenishment total', value: '£12.00' };
  const totalInformationPallet = {
    name: 'Pallet holding location replenishment total',
    value: '£734.37'
  };
  return (
    <Fragment>
      <Text px="5" my={5} fontWeight="bold">
        Shelf replenishment
      </Text>
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
      <Text px="5" my={5} fontWeight="bold">
        Pallet holding location replenishment
      </Text>
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformationPallet}
      />
    </Fragment>
  );
};

const StockHoldingtInvoice = ({ title, rows, headers }) => {
  const fakeRows = [
    {
      date: 'Week commencing 27/06/2022',
      chargeDetails: [
        {
          content: '7 pallet(s) x £2.50',
          fee: '£17.50'
        }
      ]
    }
  ];
  const fakeRowsHolding = [
    {
      date: '27/06/2022',
      chargeDetails: [
        {
          content: '7 location(s) x £0.85',
          fee: '£5.95'
        }
      ]
    },
    {
      date: '28/06/2022',
      chargeDetails: [
        {
          content: '7 location(s) x £0.85',
          fee: '£5.95'
        }
      ]
    }
  ];
  const fakeHeaders = [
    { key: 'date', label: 'Date Range' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const fakeHeadersHolding = [
    { key: 'date', label: 'Date' },
    { key: 'chargeDetails', label: 'Charge details' }
  ];
  const totalInformation = { name: 'Pallet total', value: '£17.50' };
  const totalInformationHolding = { name: 'Pallet holding location total', value: '£41.65' };
  return (
    <Fragment>
      <Text px="5" my={5} fontWeight="bold">
        Pallet Location(s)
      </Text>
      <InvoiceTypeTable
        rows={rows || fakeRows}
        headers={headers || fakeHeaders}
        totalInformation={totalInformation}
      />
      <Text px="5" my={5} fontWeight="bold">
        Pallet holding location(s)
      </Text>
      <InvoiceTypeTable
        rows={rows || fakeRowsHolding}
        headers={headers || fakeHeadersHolding}
        totalInformation={totalInformationHolding}
      />
    </Fragment>
  );
};

export default function DetailedInvoiceTable({ title, rows, headers, invoiceType }) {
  return (
    <Fragment>
      {invoiceType?.key === INVOICE_TYPE.ORDERS.key && (
        <OrdersInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.CREDIT_NOTE.key && (
        <CreditNoteInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.RETURNS.key && <></>}
      {invoiceType?.key === INVOICE_TYPE.STOCK_HOLDING.key && (
        <StockHoldingtInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.STOCK_REPLENISHMENT.key && (
        <StockReplenishmentInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.STOCK_CONSIGNMENT_PROCESSING.key && (
        <StockConsignmentInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.AD_HOC_CHARGE.key && (
        <AdditionalCostInvoice title={title} rows={rows} headers={headers} />
      )}
      {invoiceType?.key === INVOICE_TYPE.COLLECT_PLUS_RETURNS.key && <></>}
      {invoiceType?.key === INVOICE_TYPE.STOCK_PROCESSING.key && <></>}
      {invoiceType?.key === INVOICE_TYPE.BULK_ORDER.key && (
        <BulkOrdersInvoice title={title} rows={rows} headers={headers} />
      )}
    </Fragment>
  );
}
