import { GridItem, useDisclosure, useToast } from '@chakra-ui/react';
import { SlzGrid } from 'components/common/SlzGrid';
import SlzToast from 'components/common/SlzToast/SlzToast';
import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import { Fragment, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import ProductFilter from '../components/form/ProductFilter';
import { CreateBundleModal, EditBundleModal } from '../components/modal';
import DeleteBundleModal from '../components/modal/DeleteBundleModal';
import BundlesTable from '../components/table/BundlesTable';
import ProductTable from '../components/table/ProductTable';
import ProductTabs from '../components/table/ProductTabs';
import {
  BREAD_CRUMB_ITEMS,
  INVENTORY_BREADCRUMB_TEXT,
  PRODUCTS_ADD_TYPES,
  PRODUCTS_STEPS,
  PRODUCT_KEY_PARAM,
  PRODUCT_TAB
} from '../constants';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, PAGE_INDEX, PAGE_SIZE, SEARCH_TEXT, TAB } from 'constants/table.js';
import { useBundlesQuery } from '../hooks/useBundlesQuery';
import { useProductsQuery } from '../hooks/useProductsQuery';
import {
  transformBundleCreatePayload,
  transformBundles,
  transformBundleUpdatePayload
} from '../mappers/bundle-list-mapper';
import { transformProducts } from '../mappers/product-list-mapper';
import { createBundle, deleteProductBundle, updateBundle } from '../services/bundles-api';

const addSteps = [
  {
    value: PRODUCTS_ADD_TYPES.MANUAL,
    text: 'Manual Upload'
  },
  {
    value: PRODUCTS_ADD_TYPES.UPLOAD,
    text: 'File Upload'
  }
];

const defaultBundleForRenderSkeleton = {
  id: null,
  image: null,
  name: null,
  skuCode: null,
  bundleTag: null,
  bundleStockTotal: null
};

const ProductList = () => {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [enableQuery, setEnableQuery] = useState(false);
  const [searchParams, setSearchParams] = useState();
  const toast = useToast();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [addType, setAddType] = useState(null);
  const [_, dispatch] = useGlobalState();
  const bundleModalCreator = useDisclosure();
  const bundleModalEdit = useDisclosure();
  const bundleModalDelete = useDisclosure();
  const [tab, setTab] = useState(routerQuery.get(TAB) ?? PRODUCT_TAB.product.key);
  const { handleCurrentPageChange, handleItemPerPageChange, handleSearch } = useTableFilterHandler();

  const {
    data: bundleData,
    isFetching: isBundleFetching,
    isSuccess,
    isError,
    refetch: refetchBundleList
  } = useBundlesQuery(
    searchParams,
    (data) =>
      transformBundles({
        productBundles: data?.productBundles,
        pageCount: data?.pageCount
      }),
    enableQuery && tab === PRODUCT_TAB.bundle.key
  );

  const productsQuery = useProductsQuery(
    searchParams,
    (data) => transformProducts(data),
    enableQuery && tab === PRODUCT_TAB.product.key
  );

  const bundleCreateMutation = useMutation((bundle) => createBundle(bundle));
  const bundleUpdateMutation = useMutation({
    mutationFn: (payload) => updateBundle(payload)
  });
  const bundleDeleteMutation = useMutation({
    mutationFn: (id) => deleteProductBundle(id)
  });
  const handleSelectedDateRange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const openAddForm = () => {
    routerQuery.set(PRODUCT_KEY_PARAM.IS_CREATE_PRODUCT_MODAL, true);
    history.push({ search: routerQuery.toString() });
  };

  const handleCloseAddForm = () => {
    routerQuery.delete(PRODUCT_KEY_PARAM.IS_CREATE_PRODUCT_MODAL);
    history.push({ search: routerQuery.toString() });
  };

  const openAddFormByType = (item) => item && setAddType(item?.value);

  const onTabChange = (index) => {
    const currentTab = Object.keys(PRODUCT_TAB)[index];
    setTab(currentTab);
    const productTab = PRODUCT_TAB.product.key;
    const isProductTab = currentTab === productTab;
    if (!isProductTab) {
      routerQuery.set(TAB, currentTab);
    } else {
      routerQuery.delete(TAB);
    }
    history.push({ search: routerQuery.toString() });
  };

  // handle open detail modal
  const handleOpenModal = (row) => {
    switch (tab) {
      case PRODUCT_TAB.bundle.key:
        routerQuery.set('bundleId', row?.id);
        routerQuery.set('bundleName', row?.name);
        bundleModalEdit.onOpen();
        return;
      default:
        routerQuery.set('sku', row.sku || '');
        routerQuery.set('stockCount', row.totalStock || '');
        routerQuery.set('itemId', row.companyItemID || '');
        routerQuery.set('status', row.status);
        routerQuery.set('modal', PRODUCTS_STEPS.OVERVIEW);
        history.push({
          search: routerQuery.toString()
        });
        return;
    }
  };

  const onCloseHandle = () => {
    bundleModalEdit.onClose();
    // setIsEditMode(false);
  };

  const onOpenModalDelete = () => bundleModalDelete.onOpen();

  useEffect(() => {
    const params = {
      pageIndex: routerQuery.get(PAGE_INDEX) ?? DEFAULT_PAGE_INDEX,
      pageSize: routerQuery.get(PAGE_SIZE) ?? DEFAULT_PAGE_SIZE,
      pagingRequired: true
    };
    const searchText = routerQuery.get(SEARCH_TEXT);

    if (searchText) params.searchText = searchText;

    setSearchParams(params);
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [searchParams]);

  useEffect(() => {
    addType && openAddForm();
  }, [addType]);

  useEffect(() => {
    if (bundleCreateMutation.isSuccess) {
      toast({
        isClosable: true,
        render: ({ onClose }) => (
          <SlzToast
            colorScheme="positive"
            title={`${bundleCreateMutation?.variables?.name ?? ''} has been created`}
            status="info"
            size="lg"
            variant="solid"
            onClose={onClose}
          />
        )
      });
      refetchBundleList();
    }
  }, [bundleCreateMutation.isSuccess]);

  useEffect(() => {
    if (bundleUpdateMutation.isSuccess) {
      toast({
        isClosable: true,
        render: ({ onClose }) => (
          <SlzToast
            colorScheme="positive"
            title={`Bundle ${bundleUpdateMutation?.variables?.name ?? ''} has been updated`}
            status="info"
            size="lg"
            variant="solid"
            onClose={onClose}
          />
        )
      });
      refetchBundleList();
    }
  }, [bundleUpdateMutation.isSuccess]);

  useEffect(() => {
    if (bundleDeleteMutation.isSuccess) {
      bundleModalDelete.onClose();
      bundleModalEdit.onClose();
      toast({
        isClosable: true,
        render: ({ onClose }) => (
          <SlzToast
            colorScheme="positive"
            title={`${routerQuery.get('bundleName') ?? ''} Bundle has been deleted`}
            status="success"
            size="lg"
            variant="solid"
            onClose={onClose}
          />
        )
      });
      refetchBundleList();
    }
  }, [bundleDeleteMutation.isSuccess]);

  useEffect(() => {
    const breadCrumb = [...BREAD_CRUMB_ITEMS, { title: INVENTORY_BREADCRUMB_TEXT[tab] }];

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadCrumb
    });
  }, [tab]);

  return (
    <Fragment>
      <CreateBundleModal
        isOpen={bundleModalCreator.isOpen}
        onClose={bundleModalCreator.onClose}
        onSubmitBundle={(values) =>
          bundleCreateMutation.mutate(transformBundleCreatePayload(values))
        }
      />
      <EditBundleModal
        bundleId={routerQuery.get('bundleId')}
        onClose={bundleModalEdit.onClose}
        isOpen={bundleModalEdit.isOpen}
        onSubmitBundle={(values) =>
          bundleUpdateMutation.mutate(transformBundleUpdatePayload(values))
        }
        onOpenModalDelete={onOpenModalDelete}
      />
      <DeleteBundleModal
        onConfirm={() => bundleDeleteMutation.mutate(routerQuery.get('bundleId'))}
        isOpen={bundleModalDelete.isOpen}
        onClose={bundleModalDelete.onClose}
        isLoading={bundleDeleteMutation.isLoading}
      />
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <ProductFilter
            addSteps={addSteps}
            tab={tab}
            onSearch={(val) => handleSearch(SEARCH_TEXT, val)}
            // handleSelectedDateRange={handleSelectedDateRange}
            onOpenCreateBundleModal={bundleModalCreator.onOpen}
            onOpenModal={handleOpenModal}
            openAddForm={openAddForm}
            openAddFormByType={openAddFormByType}
            initSearchValue={routerQuery.get(SEARCH_TEXT) || ''}
          />
          <ProductTabs tab={tab} onChange={onTabChange} />
          {tab === PRODUCT_TAB.product.key && (
            <ProductTable
              data={productsQuery?.data?.products}
              totalPage={productsQuery?.data?.totalPage}
              queryParams={searchParams}
              onRefetching={productsQuery?.refetch}
              isEmptyPage={productsQuery?.isError || productsQuery?.data?.totalPage === 0}
              isLoading={productsQuery?.isFetching}
              addType={addType}
              handleCurrentPageChange={handleCurrentPageChange}
              handleItemPerPageChange={handleItemPerPageChange}
              isOpenAddProduct={routerQuery.get(PRODUCT_KEY_PARAM.IS_CREATE_PRODUCT_MODAL)}
              onCloseAddProduct={() => {
                handleCloseAddForm();
                setAddType(null);
              }}
              startDate={startDate}
              endDate={endDate}
              refetchProducts={productsQuery?.refetch}
            />
          )}
          {tab === PRODUCT_TAB.bundle.key && (
            <BundlesTable
              data={bundleData?.productBundles}
              isEmptyPage={isError || bundleData?.totalPage === 0}
              onRefetching={refetchBundleList}
              totalPage={bundleData?.totalPage}
              queryParams={searchParams}
              isLoading={isBundleFetching}
              handleOpenModal={handleOpenModal}
              handleCurrentPageChange={handleCurrentPageChange}
              handleItemPerPageChange={handleItemPerPageChange}
            />
          )}
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
};

export default ProductList;
