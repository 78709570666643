import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Fade,
  List,
  ListItem,
  useStyles,
  HStack,
  Heading,
  Collapse,
  Button,
  Box
} from '@chakra-ui/react';
import map from 'lodash/map';
import { ChevronRightIcon } from '../Icons';
import { NAVIGATION_RAIL_EXPANDED_WIDTH, NAVIGATION_RAIL_WIDTH } from '../Layout/Constants';

function ButtonLink({ icon: Icon, name, isActive, isShowArrow, ...rest }) {
  const styles = useStyles();

  return (
    <Button
      variant="unstyled"
      __css={styles.buttonLink}
      isActive={isActive && !isShowArrow}
      {...rest}>
      <Icon w={5} h="auto" />
      <Box flex={1} textAlign="left">
        {name}
      </Box>
      {isShowArrow && (
        <ChevronRightIcon
          h={3}
          transition="all 0.4s"
          transform={isActive ? 'rotate(90deg)' : undefined}
        />
      )}
    </Button>
  );
}

export default function NavbarSection({
  sections = {},
  activeLinkKey,
  isOpen,
  onClose,
  onNavigate,
  isExpanded
}) {
  const { name, icon: Icon, children: links } = sections;
  const styles = useStyles();

  const recurveToRenderList = (items, level = 1) => {
    if (!Array.isArray(items)) return;

    return map(items, (item) => {
      const { key, name, icon, path, children } = item;
      const isActive = activeLinkKey.includes(key);
      const hasNoChildren = !children || !Array.isArray(children);
      const onClick = () => onNavigate && onNavigate(path, key);
      if (hasNoChildren) {
        return (
          <ListItem key={key} sx={{ ...styles.subDrawerItem, _last: { borderBottom: 'none' } }}>
            <ButtonLink
              pl={`${level * 16}px`}
              isActive={isActive}
              name={name}
              icon={icon}
              onClick={onClick}
            />
          </ListItem>
        );
      }

      return (
        <ListItem key={key} sx={styles.subDrawerItem}>
          <ButtonLink
            pl={`${level * 16}px`}
            isActive={isActive}
            name={name}
            path={path}
            icon={icon}
            isShowArrow
            onClick={onClick}
          />
          <Collapse in={isActive} animateOpacity>
            <List>{recurveToRenderList(children, level + 1)}</List>
          </Collapse>
        </ListItem>
      );
    });
  };

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay
        bgColor="#9F9FA080"
        left={`${isExpanded ? NAVIGATION_RAIL_EXPANDED_WIDTH : NAVIGATION_RAIL_WIDTH}px`}
      />
      <DrawerContent as={Fade} sx={styles.navbarSection}>
        <DrawerHeader py={10} borderBottom="1px solid #C3C4CA">
          <HStack justifyContent="center">
            {Icon && <Icon />}
            <Heading as="h3" fontSize="2xl">
              {name}
            </Heading>
          </HStack>
        </DrawerHeader>

        <DrawerBody p={0}>
          <List>{recurveToRenderList(links)}</List>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
