export const SKELETON_FIRST_LOAD = {
  true: 10,
  false: 5
};

export const USING_SKELETON_FEATURES = {
  DEFAULT: 'default',
  BILLING: {
    INVOICE: 'invoice',
    COLLECTION: 'collection',
    REMITTANCE: 'remittance'
  },
  ORDER: {
    B2C_ORDER: 'b2cOrder',
    B2C_ORDER_ON_HOLD: 'b2cOrderOnHold',
    B2B_ORDER: 'b2bOrder',
    PRODUCT_B2B_ORDER: 'productB2bOrder'
  },
  PRODUCT: {
    LIBRARY: 'product',
    BUNDLE: 'bundle',
    BUNDLE_WORKFLOW: 'bundleWorkflow'
  },
  CONSIGMENT: {
    SEARCH_PRODUCT: 'searchProduct',
    SEARCH_INVENTORY: 'searchInventory',
    SEARCH_ON_HOLD: 'searchOnHold'
  },
  RETURN: 'return',
  ADDRESS_BOOK: 'AddressBook',
  USER_MANAGEMENT: 'userManagement'
};

const COMMON_SKELETON_STYLE = {
  startColor: 'light.700',
  endColor: 'ight.700',
  fadeDuration: '1',
  colSpan: '1',
  height: '3rem',
  p: 'unset'
};

export const AUTO_COMPLETE_SKELETONS = {
  [USING_SKELETON_FEATURES.DEFAULT]: [
    {
      ...COMMON_SKELETON_STYLE
    }
  ],
  [USING_SKELETON_FEATURES.BILLING.INVOICE]: [
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE,
      endColor: 'dark.300',
      startColor: 'dark.300'
    },
    {
      ...COMMON_SKELETON_STYLE
    }
  ],
  [USING_SKELETON_FEATURES.BILLING.COLLECTION]: [
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE,
      endColor: 'dark.300',
      startColor: 'dark.300'
    }
  ],
  [USING_SKELETON_FEATURES.BILLING.REMITTANCE]: [
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE,
      endColor: 'dark.300',
      startColor: 'dark.300'
    }
  ],
  [USING_SKELETON_FEATURES.ORDER.B2C_ORDER]: [
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      colSpan: '3'
    },
    {
      ...COMMON_SKELETON_STYLE,
      endColor: 'dark.300',
      startColor: 'dark.300'
    }
  ],
  [USING_SKELETON_FEATURES.ORDER.B2C_ORDER_ON_HOLD]: [
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      colSpan: '3'
    }
  ],
  [USING_SKELETON_FEATURES.ORDER.B2B_ORDER]: [
    {
      ...COMMON_SKELETON_STYLE
    }
  ],
  [USING_SKELETON_FEATURES.ORDER.PRODUCT_B2B_ORDER]: [
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      colSpan: '3'
    }
  ],
  [USING_SKELETON_FEATURES.PRODUCT.BUNDLE]: [
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300'
    }
  ],
  [USING_SKELETON_FEATURES.PRODUCT.BUNDLE_WORKFLOW]: [
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      width: '100%',
      borderRadius: 'unset'
    },
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      pl: '0.188rem',
      borderRadius: 'unset'
    },
    {
      ...COMMON_SKELETON_STYLE,
      width: '100%',
      borderRadius: 'unset'
    },
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300',
      width: '100%',
      borderRadius: 'unset'
    }
  ],
  [USING_SKELETON_FEATURES.PRODUCT.LIBRARY]: [
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE
    },
    {
      ...COMMON_SKELETON_STYLE,
      startColor: 'dark.300'
    }
  ]
};
