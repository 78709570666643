import { Box, Flex } from '@chakra-ui/react';
import { ExclamationIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { INTEGRATION_TYPE } from 'modules/integrations/constants';

const IntegrationCardActionButton = ({
  text,
  isDisabled,
  isConfiguredRateMatcher,
  integrationType,
  onClick,
  ...rest
}) => {
  const shouldShowExclamationIcon =
    integrationType === INTEGRATION_TYPE.shopify && !isConfiguredRateMatcher;
  return (
    <Box position="relative">
      <SlzButton
        size="md"
        variant="solid"
        borderRadius={1.5}
        isDisabled={isDisabled}
        onClick={onClick}
        {...rest}>
        {text}
      </SlzButton>
      {shouldShowExclamationIcon && (
        <Flex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          w={6}
          h={6}
          top="-0.75rem"
          right="-0.75rem"
          borderRadius="full"
          bg="yellowTan.500">
          <ExclamationIcon />
        </Flex>
      )}
    </Box>
  );
};
export default IntegrationCardActionButton;
