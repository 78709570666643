import {
  Box,
  Card,
  FormControl,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useStyleConfig,
  ButtonGroup,
  Button,
  PopoverContent
} from '@chakra-ui/react';
import { StockboxDimensionsIcon } from '../assets';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import Tooltip from 'modules/integrations/components/form/Tooltip';
import { CONSIGNMENT_ARRIVING_TYPES, CONSIGNMENT_LOCATION, INVALID_STOCKBOX_SIZE } from 'modules/consignments/constants';
import SlzInput from 'components/common/SlzInput/SlzInput';

const StockboxDimensions = () => {
  const {
    formState: { errors },
    register,
    setValue,
    clearErrors
  } = useFormContext();

  const [stockboxDimensionsFields] = useState([
    { field: 'stockBoxDimensions.height', unit: 'H', errorField: 'height' },
    { field: 'stockBoxDimensions.width', unit: 'W', errorField: 'width' },
    { field: 'stockBoxDimensions.depth', unit: 'D', errorField: 'depth' }
  ]);

  useEffect(() => {
    if (errors?.stockBoxDimensions) {
      for (const { field, errorField } of stockboxDimensionsFields) {
        if (errors?.stockBoxDimensions[errorField]) {
          setValue(field, 'Required', {
            shouldDirty: true
          });
        }
      }

      if (errors?.stockBoxDimensions?.weight)
        setValue('stockBoxDimensions.weight', errors?.stockBoxDimensions?.weight?.message, {
          shouldDirty: true
        });
    }
  }, [errors]);

  const clearStockboxSizeError = () => clearErrors('stockBoxDimensions');

  const setConsignmentLocation = () => {
    setValue('consignmentArrivingType', CONSIGNMENT_ARRIVING_TYPES.PALLETS);
    clearErrors('consignmentArrivingType');
  } 

  const handleOnFocus = (field) => clearErrors(field);

  return (
    <>
      <VStack w="50%">
        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <StockboxDimensionsIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Text
              sx={{
                textAlign: 'left',
                lineHeight: '20px',
                letterSpacing: '-0.14px',
                fontSize: '16px',
                color: 'table.mainText'
              }}>
              <b>Stock box dimensions & weight</b>
            </Text>
          </HStack>
        </Card>
        <Card
          bg="light.500"
          border="1px solid"
          borderColor="light.700"
          mt="2rem"
          w="100%"
          h="9.188rem"
          px="1.5rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <VStack alignItems="flex-start">
            <Box w="full">
              <FormControl>
                <SlzFormLabel display="flex" gap="1" alignItems="left" color="dark.700">
                  Dimensions (cm)
                </SlzFormLabel>
                <HStack display="flex" alignItems="baseline">
                  {stockboxDimensionsFields.map(({ field, unit, errorField }, index) => (
                    <>
                      <VStack>
                        {stockboxDimensionsFields.length - 1 === index ? (
                          <PickFromPalletAlert
                            errors={errors}
                            errorField={errorField}
                            field={field}
                            register={register}
                            unit={unit}
                            clearStockboxSizeError={clearStockboxSizeError}
                            setConsignmentLocation={setConsignmentLocation}
                            handleOnFocus={handleOnFocus}
                          />
                        ) : (
                          <SlzInput
                            key={field}
                            sx={{
                              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                                appearance: 'none',
                                margin: 0
                              },
                              '&[type=number]': {
                                MozAppearance: 'textfield'
                              },
                              fontSize: errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? 'xs' : 'sm'
                            }}
                            type={errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? 'text' : 'number'}
                            size="sm"
                            min="0"
                            step="0.01"
                            textAlign={errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? "left" : "right"}
                            w="4.304rem"
                            bgColor="light.300"
                            leftElContent={unit}
                            status={
                              errors?.stockBoxDimensions &&
                              errors?.stockBoxDimensions[errorField] &&
                              'error'
                            }
                            {...register(field)}
                            onFocus={() => handleOnFocus(field) }
                          />
                        )}
                      </VStack>
                    </>
                  ))}

                  <Tooltip content={'abc'} />
                </HStack>
              </FormControl>
            </Box>
            <Box w="12rem" justifyContent="flex-start">
              <FormControl>
                <SlzFormLabel color="dark.700">Weight (kg)</SlzFormLabel>
                <SlzInput
                  sx={{
                    '::placeholder': {
                      fontStyle: 'italic',
                      fontSize: '12px'
                    },
                    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                      appearance: 'none',
                      margin: 0
                    },
                    '&[type=number]': {
                      MozAppearance: 'textfield'
                    },
                    fontSize: errors?.stockBoxDimensions && errors?.stockBoxDimensions['weight'] ? 'xs' : 'sm'
                  }}
                  type={errors?.stockBoxDimensions && errors?.stockBoxDimensions['weight'] ? 'text' : 'number'}
                  size="sm"
                  showIcon
                  min="0"
                  step="0.01"
                  bgColor="light.300"
                  status={
                    errors?.stockBoxDimensions && errors?.stockBoxDimensions['weight'] && 'error'
                  }
                  placeholder="Between 0.01 - 2000 kg"
                  {...register('stockBoxDimensions.weight')}
                  onFocus={() => handleOnFocus('stockBoxDimensions.weight')}
                />
              </FormControl>
            </Box>
          </VStack>
        </Card>
      </VStack>
    </>
  );
};

const PickFromPalletAlert = ({ errors, field, register, unit, errorField, clearStockboxSizeError, setConsignmentLocation, handleOnFocus }) => {
  const styles = useStyleConfig('SlzToolTip');

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={errors?.stockBoxDimensions?.type === INVALID_STOCKBOX_SIZE}
        onClose={clearStockboxSizeError}
        placement="right"
        direction="ltr"
        arrowSize={40}
        closeOnBlur={false}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: ({ reference }) => [70, -(reference.width / 2 - 62)]
            }
          }
        ]}>
        <PopoverTrigger>
          <SlzInput
            key={field}
            sx={{
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0
              },
              '&[type=number]': {
                MozAppearance: 'textfield'
              },
              fontSize: errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? '10px' : '12px'
            }}
            type={errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? 'text' : 'number'}
            size="sm"
            min="0"
            step="0.01"
            textAlign={errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] ? "left" : "right"}
            w="4.304rem"
            bgColor="light.300"
            leftElContent={unit}
            status={errors?.stockBoxDimensions && errors?.stockBoxDimensions[errorField] && 'error'}
            {...register(field)}
            onFocus={() => handleOnFocus(field)}
          />
        </PopoverTrigger>
        <PopoverContent
          sx={{
            ...styles,
            width: 'fit-content',
            pt: '20px',
            pr: '26px',
            pb: '14px',
            p: '26px',
            height: 'fit-content',
            textAlign: 'left',
            verticalAlign: 'top',
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
          <PopoverHeader fontWeight="semibold" fontSize="12px" borderBottom={0} pt={0} pb={0}>
            Pick From Pallet Alert
          </PopoverHeader>
          <PopoverArrow sx={{ transform: 'scaleY(.5) rotate(45deg) !important' }} />
          <PopoverCloseButton />
          <PopoverBody fontSize="10px" pt={0} pb={0}>
            You have indicated that your stock box size <br />
            is above our shelf storage restrictions <br />
            <b>(200cm x 60 cm x 65cm x 70kg)</b>. <br />
            <br />
            This qualifies the stock on this consignment <br />
            to be picked from pallet and will require <br />
            stock to be sent in on a pallet. <br /> <br />
            Do you want this consignment to be pick <br />
            from pallet?
          </PopoverBody>
          <PopoverFooter borderTop={0} display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button onClick={clearStockboxSizeError} variant="outline" colorScheme="negative.500">
                Edit
              </Button>
              <Button onClick={setConsignmentLocation} bgColor="negative.500">Yes, continue</Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default StockboxDimensions;
