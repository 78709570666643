import { useEffect } from 'react';

import { Flex, Td, Text, useClipboard, useTheme } from '@chakra-ui/react';

import { skeletonAnimation as animation } from '../Skeleton/config';
import { Skeleton } from '../Skeleton/Skeleton';
import { TABLES } from './Constants';
import { getCellStyles } from './Styles';
import { useTableContext } from './TableContext';

const getLabel = (row, key) => {
  if (typeof row[key] === 'object') {
    return row[key]?.name || '';
  }

  return row[key];
};

const RenderCell = ({ row, col, field, render, onClickCell }) => {
  const { columnWidth, paddingValue, variant, hyperLinks } = useTableContext();

  if (render) {
    return render({ row, field, columnWidth, paddingValue, onClick: onClickCell });
  }

  const cellStyles = {
    fontSize: variant === 'special' ? '0.875rem' : '1rem',
    color: hyperLinks.includes(field) || !!col?.isHyperLink ? 'main.500' : 'inherit'
  };

  return (
    <Flex className="slz-table-cell" px={paddingValue}>
      <Text noOfLines={2} sx={cellStyles} onClick={onClickCell}>
        {getLabel(row, field)}
      </Text>
    </Flex>
  );
};

const SlzCell = ({ children, row, col = {}, field }) => {
  const theme = useTheme();
  const { render, isCopyable = false, isClickable = false } = col;
  const { onCopy, value, setValue } = useClipboard(TABLES.defaultValue);
  const { columnWidth, variant, isLoading } = useTableContext();

  const handleClickCell = (...args) => {
    if (isClickable) {
      col?.onClick && col?.onClick(row, ...args);
    }
    if (isCopyable && row[field]) {
      setValue(row[field]);
    }
  };

  const getCellClass = () => {
    if (col?.isEmphasis) {
      return 'emphasized-cell';
    }

    if ('number' === col?.type) {
      return 'number';
    }

    return '';
  };

  const styles = {
    ...getCellStyles({ col, row, type: variant, theme, isLoading }),
    w: Number.isInteger(col?.width) ? `${col?.width}px` : columnWidth,
    maxW: Number.isInteger(col?.width) ? `${col?.width}px` : columnWidth
  };

  useEffect(() => {
    value && onCopy();
  }, [value]);

  return children ? (
    children
  ) : (
    <Td
      pos="relative"
      className={`sel-td ${getCellClass()}`}
      sx={styles}
      key={`${row[field]}-[sel]-${field}`}>
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <RenderCell
          row={row}
          col={col}
          field={field}
          render={render}
          onClickCell={handleClickCell}
        />
      )}
    </Td>
  );
};

export default SlzCell;
