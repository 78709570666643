import { Box, Icon } from '@chakra-ui/react'

export const Warehouse = (props) => (
    <Box>
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="0.75rem"
            h="0.713rem"
            viewBox="0 0 12 11.4"
            fill="white"
            {...props}>
            <g id="Group_3239" data-name="Group 3239" clip-path="url(#clip-path)">
                <path id="Path_3696" data-name="Path 3696" d="M11.4,11.4a.6.6,0,0,1-.6-.6V3.39L6,1.256,1.2,3.39V10.8a.6.6,0,1,1-1.2,0V3a.6.6,0,0,1,.356-.548l5.4-2.4a.6.6,0,0,1,.487,0l5.4,2.4A.6.6,0,0,1,12,3v7.8a.6.6,0,0,1-.6.6" transform="translate(0 0)" fill="#fff" />
                <path id="Path_3697" data-name="Path 3697" d="M10.6,15h-6a.6.6,0,0,1-.6-.6V10.8a.6.6,0,0,1,.6-.6H8.2a.6.6,0,1,1,0,1.2h-3v2.4H10V10.2H8.2A.6.6,0,1,1,8.2,9h2.4a.6.6,0,0,1,.6.6v4.8a.6.6,0,0,1-.6.6" transform="translate(-1.6 -3.6)" fill="#fff" />
                <path id="Path_3698" data-name="Path 3698" d="M9,14.2a.6.6,0,0,1-.6-.6V8.2H7.2V10A.6.6,0,0,1,6,10V8.2A1.2,1.2,0,0,1,7.2,7H8.4A1.2,1.2,0,0,1,9.6,8.2v5.4a.6.6,0,0,1-.6.6" transform="translate(-2.4 -2.8)" fill="#fff" />
            </g>
        </Icon>
    </Box>
);

export const Active = (props) => (
    <Box>
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.5rem"
            h="1.5rem"
            viewBox="0 0 24 24"
            {...props}>
            <g id="Group_33477" data-name="Group 33477" transform="translate(-17040 -342)">
                <circle id="Ellipse_1304" data-name="Ellipse 1304" cx="11" cy="11" r="11" transform="translate(17041 343)" fill="#fff" />
                <g id="Group_33468" data-name="Group 33468" transform="translate(17040 342)">
                    <g id="Group_33466" data-name="Group 33466" transform="translate(0 0)" clip-path="url(#clip-path)">
                        <path id="Path_9969" data-name="Path 9969" d="M9.882,16.452a1.052,1.052,0,0,1-.746-.309l-3.67-3.67a1.055,1.055,0,1,1,1.491-1.491l2.924,2.924,6.593-6.594A1.055,1.055,0,1,1,17.968,8.8l-7.339,7.339a1.052,1.052,0,0,1-.746.309" transform="translate(0.283 0.384)" fill="#16965a" />
                        <path id="Path_9970" data-name="Path 9970" d="M12,24A12,12,0,1,1,24,12,12.015,12.015,0,0,1,12,24M12,2.11A9.89,9.89,0,1,0,21.89,12,9.9,9.9,0,0,0,12,2.11" transform="translate(0 0)" fill="#fff" />
                    </g>
                </g>
            </g>
        </Icon>
    </Box>
);

export const UKFlag = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="1.25rem"
        h="1.25rem"
        viewBox="0 0 20 20"
        borderRadius="0.188rem"
        {...props}>
        <g id="Mask_Group_7" data-name="Mask Group 7" clip-path="url(#clip-path)">
            <g id="Group_212" data-name="Group 212" transform="translate(-5.325 0.034)">
                <rect id="Rectangle_788" data-name="Rectangle 788" width="29.898" height="19.931" transform="translate(0)" fill="#0d2764" />
                <path id="Path_1416" data-name="Path 1416" d="M15.18.123V7.1H3.22v5.98H15.18v6.975h5.979V13.08H33.118V7.1H21.159V.123Z" transform="translate(-3.22 -0.123)" fill="#fff" />
                <path id="Path_1417" data-name="Path 1417" d="M3.22.123V2.431L29.661,20.055h3.457V17.766L6.651.123Z" transform="translate(-3.22 -0.123)" fill="#fff" />
                <path id="Path_1418" data-name="Path 1418" d="M29.686.123,3.22,17.766v2.289H6.677L33.117,2.431V.123Z" transform="translate(-3.22 -0.123)" fill="#fff" />
                <path id="Path_1419" data-name="Path 1419" d="M16.424.123V8.346H3.22v3.487h13.2v8.222h3.489V11.833h13.2V8.346h-13.2V.123Z" transform="translate(-3.22 -0.123)" fill="#c20320" />
                <path id="Path_1420" data-name="Path 1420" d="M52.615,32.955V31.38l-8.036-5.343H42.212Z" transform="translate(-22.717 -13.081)" fill="#c20320" />
                <path id="Path_1421" data-name="Path 1421" d="M48.694.123,39.1,6.5v.6h1.473L51.058.124H48.694Z" transform="translate(-21.16 -0.123)" fill="#c20320" />
                <path id="Path_1422" data-name="Path 1422" d="M13.626,26.037,3.22,32.956v.056H5.5l9.676-6.436v-.539Z" transform="translate(-3.22 -13.081)" fill="#c20320" />
                <path id="Path_1423" data-name="Path 1423" d="M3.22,1.723l8.1,5.39H13.69L3.22.15Z" transform="translate(-3.22 -0.137)" fill="#c20320" />
            </g>
        </g>
    </Icon>
);

export const Info = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="0.75rem"
        h="0.75rem"
        viewBox="0 0 12 12"
        {...props}>
        <g id="Group_33541" data-name="Group 33541" transform="translate(1157 974) rotate(180)">
            <path id="Ellipse_154" data-name="Ellipse 154" d="M6,1a5,5,0,1,0,5,5A5.006,5.006,0,0,0,6,1M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0Z" transform="translate(1145 962)" fill="#3474e2" />
            <path id="Path_1331" data-name="Path 1331" d="M-.75,4a.75.75,0,0,1-.75-.75v-4A.75.75,0,0,1-.75-1.5.75.75,0,0,1,0-.75v4A.75.75,0,0,1-.75,4Z" transform="translate(1151.75 965)" fill="#3474e2" />
            <circle id="Ellipse_156" data-name="Ellipse 156" cx="1" cy="1" r="1" transform="translate(1150 970)" fill="#3474e2" />
        </g>
    </Icon>
);

export const Standy = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="1.5rem"
        h="1.5rem"
        viewBox="0 0 24 24"
        {...props}>
        <g id="Group_34679" data-name="Group 34679" transform="translate(-667 -3992)">
            <circle id="Ellipse_1308" data-name="Ellipse 1308" cx="12" cy="12" r="12" transform="translate(667 3992)" fill="#fff" />
            <g id="Group_33659" data-name="Group 33659" transform="translate(673 3997)">
                <g id="Group_33657" data-name="Group 33657" clip-path="url(#clip-path)">
                    <path id="Path_9993" data-name="Path 9993" d="M6.53,12H2a.667.667,0,0,1-.667-.667V6.667h10A.667.667,0,0,0,12,6V3.333a2,2,0,0,0-2-2H9.333V.667A.667.667,0,1,0,8,.667v.667H4V.667a.667.667,0,0,0-1.333,0v.667H2a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2H6.53A.667.667,0,1,0,6.53,12M2,2.667h.667v.667A.667.667,0,1,0,4,3.333V2.667H8v.667a.667.667,0,1,0,1.333,0V2.667H10a.667.667,0,0,1,.667.667v2H1.333v-2A.667.667,0,0,1,2,2.667" fill="gray" />
                    <path id="Path_9994" data-name="Path 9994" d="M10.208,6.875a3.333,3.333,0,1,0,3.333,3.333,3.337,3.337,0,0,0-3.333-3.333m0,5.333a2,2,0,1,1,2-2,2,2,0,0,1-2,2" transform="translate(0.458 0.458)" fill="gray" />
                    <path id="Path_9995" data-name="Path 9995" d="M10.708,9.828V9.1a.667.667,0,0,0-1.333,0v1a.662.662,0,0,0,.2.471l.667.667a.667.667,0,1,0,.943-.944Z" transform="translate(0.625 0.562)" fill="gray" />
                </g>
            </g>
        </g>
    </Icon>
);

export const Configure = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="1.5rem"
        h="1.5rem"
        viewBox="0 0 24 24"
        {...props}>
        <g id="Group_34663" data-name="Group 34663" transform="translate(-985 -1711)">
            <circle id="Ellipse_1311" data-name="Ellipse 1311" cx="12" cy="12" r="12" transform="translate(985 1711)" fill="#fee16e" />
            <g id="Group_33678" data-name="Group 33678" transform="translate(989 1715)">
                <g id="Group_33677" data-name="Group 33677" clip-path="url(#clip-path)">
                    <path id="Path_10004" data-name="Path 10004" d="M8,16a2.148,2.148,0,0,1-2.118-1.665.579.579,0,0,0-.7-.427.585.585,0,0,0-.166.069,2.18,2.18,0,0,1-3-3,.577.577,0,0,0-.194-.8.544.544,0,0,0-.164-.068,2.179,2.179,0,0,1,0-4.235.579.579,0,0,0,.358-.865,2.18,2.18,0,0,1,3-3,.578.578,0,0,0,.864-.358A2.148,2.148,0,0,1,8,0H8a2.146,2.146,0,0,1,2.117,1.665.579.579,0,0,0,.7.427.59.59,0,0,0,.166-.069,2.179,2.179,0,0,1,3,3,.581.581,0,0,0-.068.438.575.575,0,0,0,.262.358.547.547,0,0,0,.164.068,2.179,2.179,0,0,1,0,4.235.581.581,0,0,0-.358.865,2.18,2.18,0,0,1-3,3,.578.578,0,0,0-.864.358A2.146,2.146,0,0,1,8,16M5.324,12.291a2.18,2.18,0,0,1,2.114,1.667.579.579,0,0,0,1.125,0,2.18,2.18,0,0,1,3.251-1.346.58.58,0,0,0,.8-.8,2.2,2.2,0,0,1-.257-.622,2.178,2.178,0,0,1,1.6-2.631.579.579,0,0,0,0-1.126,2.179,2.179,0,0,1-1.347-3.25.58.58,0,0,0-.8-.8A2.18,2.18,0,0,1,8.562,2.042a.579.579,0,0,0-1.125,0,2.177,2.177,0,0,1-3.25,1.346.58.58,0,0,0-.8.8,2.163,2.163,0,0,1,.257.621,2.18,2.18,0,0,1-1.6,2.63.579.579,0,0,0,0,1.126,2.192,2.192,0,0,1,.621.258,2.179,2.179,0,0,1,.726,2.994.58.58,0,0,0,.8.8,2.159,2.159,0,0,1,.622-.257,2.19,2.19,0,0,1,.517-.062M6.66,1.854h0Z" fill="#303134" />
                    <path id="Path_10005" data-name="Path 10005" d="M9.2,12.4a3.2,3.2,0,1,1,3.2-3.2,3.2,3.2,0,0,1-3.2,3.2m0-4.8a1.6,1.6,0,1,0,1.6,1.6A1.6,1.6,0,0,0,9.2,7.6" transform="translate(-1.2 -1.2)" fill="#303134" />
                </g>
            </g>
        </g>
    </Icon>
);

export const Attention = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="1.5rem"
        h="1.5rem"
        viewBox="0 0 24 24"
        {...props}>
        <g id="Group_34663" data-name="Group 34663" transform="translate(-997 -1958)">
            <circle id="Ellipse_1297" data-name="Ellipse 1297" cx="12" cy="12" r="12" transform="translate(1021 1982) rotate(180)" fill="#fee16e" />
            <g id="Group_33423" data-name="Group 33423" transform="translate(1007.325 1962.902)">
                <path id="Path_1322" data-name="Path 1322" d="M.117,12.079A1.117,1.117,0,0,1-1,10.962V3.908a1.117,1.117,0,0,1,2.234,0v7.054A1.117,1.117,0,0,1,.117,12.079Z" transform="translate(1.558 -2.791)" fill="#303134" />
                <circle id="Ellipse_157" data-name="Ellipse 157" cx="1.675" cy="1.675" r="1.675" transform="translate(0 10.843)" fill="#303134" />
            </g>
        </g>
    </Icon>
);

