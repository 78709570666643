import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Checkbox, calc } from '@chakra-ui/react';

import { SlzDropdown } from 'components/common/SlzDropdown';
import { ControlledField } from 'components/form';
import { PERMISSION_DROPDOWN_STYLE } from 'modules/integrations/constants/style';
import {
  API_SETUP_TEXT,
  MODULE_ORDERS,
  MODULE_STOCK,
  NO_ACCESS_PERMISSION_OPTION,
  READ_AND_WRITE_ACCESS
} from 'modules/integrations/constants';

const ApiPermission = ({ module }) => {
  const methods = useFormContext();
  const stockModulePermission = useWatch({ name: `permissions[${MODULE_STOCK}]` });
  const ordersModulePermission = useWatch({ name: `permissions[${MODULE_ORDERS}]` });

  const isInventorySQSEnabled =
    module.name === MODULE_STOCK &&
    stockModulePermission?.value ==
      module.modulePermissions.find(
        (modulePerm) => modulePerm.friendlyName === READ_AND_WRITE_ACCESS
      )?.id;

  const isOrderSQSEnabled =
    module.name == MODULE_ORDERS &&
    ordersModulePermission?.value ==
      module.modulePermissions.find(
        (modulePerm) => modulePerm.friendlyName === READ_AND_WRITE_ACCESS
      )?.id;

  const options = [
    NO_ACCESS_PERMISSION_OPTION,
    ...module.modulePermissions?.map((modulePerm) => {
      return {
        value: modulePerm.id,
        label: modulePerm.friendlyName
      };
    })
  ];

  useEffect(() => {
    if (!isOrderSQSEnabled && module.name === MODULE_ORDERS) {
      methods.setValue('ordersSQSSettings[enabled]', false);
    }
  }, [isOrderSQSEnabled]);

  useEffect(() => {
    if (!isInventorySQSEnabled && module.name === MODULE_STOCK) {
      methods.setValue('inventorySQSSettings[enabled]', false);
    }
  }, [isInventorySQSEnabled]);

  return (
    <Box w={calc('100%').subtract('0.75rem').divide(2).toString()}>
      <ControlledField
        name={`permissions[${module.name}]`}
        label={module.name}
        size="lg"
        placeholder={`Select your ${module.name.toLowerCase()} access option`}
        component={SlzDropdown}
        isInlineError
        chakraStyles={PERMISSION_DROPDOWN_STYLE}
        options={options}
      />
      {isInventorySQSEnabled && (
        <Controller
          control={methods?.control}
          name="inventorySQSSettings[enabled]"
          render={({ field }) => {
            return (
              <Checkbox isChecked={field.value} onChange={field.onChange} pt="0.688rem">
                {API_SETUP_TEXT.receiveNotificationsForStock}
              </Checkbox>
            );
          }}
        />
      )}
      {isOrderSQSEnabled && (
        <Controller
          control={methods?.control}
          name="ordersSQSSettings[enabled]"
          render={({ field }) => {
            return (
              <Checkbox isChecked={field.value} onChange={field.onChange} pt="0.688rem">
                {API_SETUP_TEXT.receiveNotificationsForOrders}
              </Checkbox>
            );
          }}
        />
      )}
    </Box>
  );
};

export default ApiPermission;
