import React from 'react';
import { Box, Flex, Heading, HStack, Image, Link, List, ListItem, Text } from '@chakra-ui/react';
import { ICON_MAP } from 'constants/common';

function TextOverlay({ title, description }) {
  return (
    <Box
      pos="absolute"
      bottom={36}
      left={0}
      zIndex="overlay"
      w="30rem"
      px={12}
      py={6}
      bgColor="white">
      <Heading as="h2" color="dark.700" fontSize="1.875rem" fontWeight="bold" mb={1}>
        {title}
      </Heading>
      <Text color="barb.500" fontSize="1.25rem" lineHeight="shorter">
        {description}
      </Text>
    </Box>
  );
}

function MediaLink({ icon, url }) {
  const Icon = ICON_MAP[icon];
  return (
    <ListItem>
      <Link href={url} target="_blank">
        <Icon w="1.5625rem" h="1.5625rem" color="white" />
      </Link>
    </ListItem>
  );
}

function MediaLinks({ links = [] }) {
  return (
    <List
      pos="absolute"
      bottom={0}
      left={0}
      zIndex="overlay"
      display="flex"
      alignItems="center"
      p={12}
      gap={5}>
      {links.map(({ icon, url }) => (
        <MediaLink key={icon} icon={icon} url={url} />
      ))}
    </List>
  );
}

export default function PublicLayout({
  imageSrc,
  textOverlay = {},
  mediaLinks = [],
  children,
  sx = {}
}) {
  return (
    <HStack spacing={0}>
      <Box w="50%" h="100vh" bg="gray.300" pos="relative">
        <Image srcSet={imageSrc} w="full" h="full" objectFit="cover" objectPosition="center" />
        {textOverlay && <TextOverlay {...textOverlay} />}
        {mediaLinks && <MediaLinks links={mediaLinks} />}
      </Box>
      <Flex w="50%" h="100vh" p={20} overflowY="auto" alignItems="center" sx={sx}>
        <Box maxW="28rem" mx="auto" w="100%">
          {children}
        </Box>
      </Flex>
    </HStack>
  );
}
