import { GlobalProvider } from 'contexts/GlobalContext';
import { QueryClientProvider } from 'react-query';
import { withRouter } from 'react-router';
import { ReactQueryDevtools } from 'react-query/devtools';

import Routes from 'routers/Routes';
import queryClient from 'config/query';

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalProvider>
        <Routes />
      </GlobalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withRouter(App);
