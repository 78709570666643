
import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const OversizedItem = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.125rem"
            h="1.125rem"
            viewBox="0 0 18.003 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Union_318" data-name="Union 318" d="M6379,20989h-4a1,1,0,0,1,0-2h1.586l-4.293-4.293a1,1,0,1,1,1.417-1.41l4.288,4.287V20984a1,1,0,1,1,2,0v3.93a.992.992,0,0,1-.29.773l-.05.047a1,1,0,0,1-.564.244h-.01l-.037,0Zm-16,0h-.049l-.038,0h-.01a1,1,0,0,1-.812-.576h0c-.006-.012-.012-.025-.017-.037s-.008-.021-.012-.031l0-.01a.979.979,0,0,1-.06-.393V20984a1,1,0,1,1,2,0v1.586l4.293-4.293a1,1,0,0,1,1.412,1.41l-4.293,4.293H6367a1,1,0,1,1,0,2Zm9.292-10.293a1,1,0,0,1,0-1.416l4.293-4.293H6375a1,1,0,1,1,0-2h3.93a1,1,0,0,1,1.069,1.066V20976a1,1,0,1,1-2,0v-1.58l-4.288,4.287a1.008,1.008,0,0,1-.711.291A1,1,0,0,1,6372.292,20978.707Zm-4,0-4.293-4.293V20976a1,1,0,1,1-2,0v-3.949a1,1,0,0,1,1.069-1.049H6367a1,1,0,1,1,0,2h-1.585l4.293,4.293A1,1,0,0,1,6369,20979,.988.988,0,0,1,6368.294,20978.707Z" transform="translate(-6361.999 -20971)" fill="gray" />                </g>
        </Icon>
    </Box>
);
export default OversizedItem;