import { useMutation } from 'react-query';
import { setProductReturnSettings } from '../services/products-api';

export default function useSetProductReturnSettingsMutation() {
  return useMutation({
    mutationFn: async ({ productId, payload }) => {
      const response = await setProductReturnSettings(productId, payload);

      return response;
    }
  });
}
