import { HStack, Text, CloseButton, VStack } from '@chakra-ui/react';
import { IoClose } from 'react-icons/io5';

import { SlzButton } from 'components/common/SlzButton';
import { SlzPaper } from 'components/common/SlzPaper';
import { CompanyIcon } from 'components/common/Icons';
import { SlzChip } from 'components/common/SlzChip';
import { addCommasOnText } from 'modules/address-book/utils';

const BodyText = (props) => {
  const { children, ...rest } = props;

  return (
    <Text
      as="span"
      wordBreak="break-all"
      fontSize="1rem"
      color="dark.700"
      lineHeight="1.3125rem"
      {...rest}>
      {children}
    </Text>
  );
};

const RecipientHeader = ({ companyName, onRemove }) => (
  <HStack
    w="full"
    pt={2}
    pb="0.4375rem"
    px="1.125rem"
    alignItems="center"
    bg="primary.mainSelected50">
    <HStack
      display="flex"
      bgColor="main.500"
      rounded="full"
      w={6}
      h={6}
      alignItems="center"
      justifyContent="center"
      mr={2}>
      <CompanyIcon stroke="white" w={3} h={3} />
    </HStack>
    <VStack alignItems="flex-start" gap={0} flexGrow={1} spacing={-1}>
      <Text color="main.500" fontSize="0.625rem" lineHeight="0.8125rem">
        Company name
      </Text>
      <Text color="dark.700" lineHeight="1.625rem" fontSize="1.25rem">
        {companyName}
      </Text>
    </VStack>
    <CloseButton
      data-testid="toast-close-btn"
      onClick={onRemove}
      bg="light.700"
      w={6}
      h={6}
      color="dark.500">
      <IoClose size="1.413rem" />
    </CloseButton>
  </HStack>
);

const RecipientBody = ({
  name,
  email,
  phone,
  addressLine1,
  addressLine2,
  town,
  postCode,
  county,
  country
}) => (
  <HStack alignItems="flex-start" minH="10rem" py="1rem" px="3.125rem" gap={20}>
    <VStack flex={1} gap={0} alignItems="flex-start" spacing="0.4375rem">
      <SlzChip
        size="xs"
        bg="light.700"
        lineHeight="1rem"
        fontWeight="medium"
        color="main.500"
        px="6px"
        flexBasis="1rem">
        Contact details
      </SlzChip>
      <BodyText>{name}</BodyText>
      <BodyText>{email}</BodyText>
      <BodyText>{phone}</BodyText>
    </VStack>
    <VStack flex={1} gap={0} alignItems="flex-start" spacing={0}>
      <SlzChip
        size="xs"
        bg="light.700"
        lineHeight="1rem"
        fontWeight="medium"
        color="main.500"
        px="6px"
        flexBasis="1rem"
        mb="0.4375rem">
        Address
      </SlzChip>
      <BodyText mt="0.4375rem">{addCommasOnText(addressLine1)}</BodyText>
      <BodyText>{addressLine2}</BodyText>
      <BodyText>{`${addCommasOnText(town, true)}${addCommasOnText(postCode)}`}</BodyText>
      <BodyText>{county}</BodyText>
      <BodyText>{country}</BodyText>
    </VStack>
  </HStack>
);

const RecipientFooter = ({ isShowBtn, onEdit }) => (
  <HStack
    justifyContent="flex-end"
    minH="4rem"
    borderTopWidth="0.0625rem"
    borderTopColor="light.700">
    {isShowBtn && (
      <SlzButton
        bg="main.700"
        mx={6}
        my="1.1875rem"
        size="md"
        borderRadius="0.375rem"
        onClick={onEdit}>
        Edit
      </SlzButton>
    )}
  </HStack>
);

const RecipientDetailCard = (props) => {
  const { data = {}, onRemove, onEdit } = props;

  const { companyName, ...restAddress } = data;

  return (
    <SlzPaper w="full" h="auto" boxShadow="slz-md" overflow="hidden">
      <RecipientHeader companyName={companyName} onRemove={onRemove} />

      <RecipientBody {...restAddress} />

      <RecipientFooter isShowBtn={!!data?.id} onEdit={onEdit} />
    </SlzPaper>
  );
};

export default RecipientDetailCard;
