import { DASHBOARD_PATHNAME } from '../constants';
import Dashboard from '../pages/dashboard';

const DashboardRoutes = [
  {
    path: DASHBOARD_PATHNAME,
    profile: 'Retailer',
    feature: 'ViewWMSDashboard',
    exact: true,
    component: Dashboard
  }
];

export default DashboardRoutes;
