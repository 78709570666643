import { Fragment, useRef } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddRoleUserManagementCard, UserManagementAction, UserRoleSelectionCard } from '../other';
import { rolesValidationSchema } from './validation-schemas';
import { useRolesCompanyQuery } from 'modules/users/hooks';
import { SlzRadio } from 'components/common/SlzRadio';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { ControlledField } from 'components/form';

const AddRoleAndPermissionForm = ({ defaultValues, onNext, onPrevious, ...rest }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(rolesValidationSchema)
  });
  const selectedRoles = useRef();
  const { data, isFetching } = useRolesCompanyQuery();

  const errorMessage = methods?.formState?.errors?.roles?.message;

  const handlePreviousStep = () => {
    const currentValues = {
      ...defaultValues,
      roles: selectedRoles.current
    };

    onPrevious({ values: currentValues });
  };

  const handleNextStep = () => {
    const currentValues = {
      ...defaultValues,
      roles: selectedRoles.current,
      suppliers: data
    };

    onNext(currentValues);
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(handleNextStep)}>
          <VStack alignItems="initial" gap={2} mb={10}>
            <ControlledField
              name="roles"
              component={(props) => {
                selectedRoles.current = props.value;
                return (
                  <UserRoleSelectionCard
                    styles={{ w: 'full', display: 'flex', justifyContent: 'space-between' }}
                    {...props}>
                    {(getRadioProps) =>
                      (data || Array(3).fill(0)).map(({ id, ...rest }) => {
                        const isChecked = id === props.value;
                        const radioProps = getRadioProps({ value: id });
                        return (
                          <Fragment key={id}>
                            <Skeleton isLoaded={!isFetching} borderRadius={3}>
                              <AddRoleUserManagementCard
                                isChecked={isChecked}
                                isError={errorMessage}
                                radioProps={radioProps}
                                {...rest}>
                                <SlzRadio
                                  pos="absolute"
                                  top="0.313rem"
                                  right="0.625rem"
                                  value={rest.value}
                                  {...radioProps}
                                />
                              </AddRoleUserManagementCard>
                            </Skeleton>
                          </Fragment>
                        );
                      })
                    }
                  </UserRoleSelectionCard>
                );
              }}
            />
          </VStack>
          <UserManagementAction
            disableNextButton={isFetching}
            onPrevious={handlePreviousStep}
            {...rest}
          />
        </form>
      </FormProvider>
    </Box>
  );
};

export default AddRoleAndPermissionForm;
