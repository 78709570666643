import { Box, HStack, Text, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';
import { TriangleWarningIcon } from '../Icons';

const SlzAttentionCard = (props) => {
  const { title, description, variant, status, icon: Icon = TriangleWarningIcon, ...rest } = props;
  const styles = useStyleConfig('SlzAttentionCard', { variant, status });
  const { description: desStyle, icon: iconStyle, title: titleStyle, headerWrapper } = styles;
  return (
    <Box
      data-testid="attention-card-wrap"
      __css={styles}
      className="sel-attention"
      {...rest}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-AttentionCard`)}>
      <HStack spacing={3} {...headerWrapper}>
        {Icon && <Icon data-testid="attention-card-icon" as={Icon} sx={iconStyle} />}
        {title && (
          <Text
            data-testid="attention-card-title"
            noOfLines={1}
            className="sel-attention__title"
            sx={titleStyle}>
            {title}
          </Text>
        )}
      </HStack>
      {description && (
        <Box data-testid="attention-card-desc" sx={desStyle}>
          <Text className="sel-attention__description">{description}</Text>
        </Box>
      )}
      {props?.children}
    </Box>
  );
};

export default SlzAttentionCard;
