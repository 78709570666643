import React from 'react';
import { Icon } from '@chakra-ui/react';

const FlyerIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="26.874"
      height="33.593"
      viewBox="0 0 26.874 33.593"
      {...props}>
      <g id="Group_2162" data-name="Group 2162" transform="translate(-1010.332 -1709.664)">
        <path
          id="Path_8155"
          data-name="Path 8155"
          d="M1029.41,1721.421h-6.719a3.363,3.363,0,0,1-3.359-3.359v-6.719a1.68,1.68,0,1,1,3.359,0v6.719h6.719a1.68,1.68,0,0,1,0,3.359Z"
          transform="translate(6.117 0)"
          fill="#303134"
        />
        <path
          id="Path_8156"
          data-name="Path 8156"
          d="M1032.167,1743.257h-16.8a5.045,5.045,0,0,1-5.039-5.039V1714.7a5.045,5.045,0,0,1,5.039-5.039h11.757a1.68,1.68,0,0,1,1.188.492l8.4,8.4a1.68,1.68,0,0,1,.492,1.188v18.476A5.045,5.045,0,0,1,1032.167,1743.257Zm-16.8-30.234a1.682,1.682,0,0,0-1.68,1.68v23.515a1.682,1.682,0,0,0,1.68,1.68h16.8a1.681,1.681,0,0,0,1.68-1.68v-17.78l-7.414-7.414Z"
          transform="translate(0 0)"
          fill="#303134"
        />
        <path
          id="Line_1411"
          data-name="Line 1411"
          d="M2.359,2.359H.68A1.68,1.68,0,1,1,.68-1h1.68a1.68,1.68,0,0,1,0,3.359Z"
          transform="translate(1018.051 1717.383)"
          fill="#303134"
        />
        <path
          id="Line_1412"
          data-name="Line 1412"
          d="M10.757,2.359H.68A1.68,1.68,0,1,1,.68-1H10.757a1.68,1.68,0,0,1,0,3.359Z"
          transform="translate(1018.051 1727.461)"
          fill="#303134"
        />
        <path
          id="Line_1413"
          data-name="Line 1413"
          d="M4.039,2.359H.68A1.68,1.68,0,1,1,.68-1H4.039a1.68,1.68,0,1,1,0,3.359Z"
          transform="translate(1024.769 1734.18)"
          fill="#303134"
        />
      </g>
    </Icon>
  );
};

export default FlyerIcon;
