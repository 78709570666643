import { useQuery } from 'react-query';
import { getProductNoImage } from '../services/preferences-api';

export default function useProductNoImageQuery({ filters, select }) {
  return useQuery({
    queryKey: ['product-no-image', filters],
    queryFn: () => getProductNoImage(filters),
    select: select
  });
}
