import { HStack } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
// import { PRODUCTS_ADD_TYPES } from 'modules/products/constants';

const Action = ({ onRemove, onClose, isSubmitting, isEdit }) => {
  return (
    <HStack w="full" justifyContent="space-between">
      <SlzButton colorScheme="negative" size="lg" variant="outline" onClick={onClose}>
        Close
      </SlzButton>
      <HStack>
        {isEdit && (
          <SlzButton colorScheme="negative" size="lg" onClick={onRemove}>
            Remove
          </SlzButton>
        )}
        <SlzButton
          type="submit"
          colorScheme="main"
          size="lg"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}>
          Save
        </SlzButton>
      </HStack>
    </HStack>
  );
};

export default Action;
