import { Box, useStyleConfig } from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

const SlzPaper = ({ size, variant, ...props }) => {
  const styles = useStyleConfig('SlzPaper', { size, variant });
  return <Box data-testid="paper" id={uniqueId('SEL-Slz-Paper')} __css={styles} {...props} />;
};

export default SlzPaper;
SlzPaper.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', PropTypes.arrayOf(['sm', 'md', 'lg', 'xl'])])
};
