import { useQuery } from 'react-query';
import { getOrderDetail } from '../services/orders-api';

/* export const useOrderDetailQuery = (orderId, status, source, select) => {
  return useQuery({
    queryKey: [`order-detail-${orderId}`],
    queryFn: () => getOrderDetail(orderId, status, source),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select
  });
}; */

export const useOrderDetailQuery = (row, select) => {
  return useQuery({
    queryKey: [`order-detail-${row?.customerReference}`],
    queryFn: () => getOrderDetail(row),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select
  });
};
