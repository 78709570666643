import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import { SlzButton } from 'components/common/SlzButton';
import { ADD_PRODUCT_PATHNAME, MOST_POPULAR_PRODUCT_ROWS } from 'modules/dashboard/constants';
import { PRODUCTS_STYLES } from './chart.styles';

const Products = () => {};
Products.Wrap = VStack;
Products.Header = () => (
  <Box pt="1rem" pb="1.25rem" pl="1.5rem" alignSelf="stretch">
    <Text fontSize="1.25rem" fontWeight="bold" textAlign="left" lineHeight="1.625rem">
      Most popular products
    </Text>
  </Box>
);

Products.Footer = () => {
  const history = useHistory();
  const handleClickAddProduct = useCallback(() => {
    history.push(ADD_PRODUCT_PATHNAME);
  }, []);

  return (
    <VStack w="100%" alignItems="start" spacing={0} pb="0.625rem">
      <Box pt="1rem" pb="0.75rem" pl="1.1875rem" w="100%" borderTop="common" borderBottom="common">
        <SlzButton size="md" variant="solid" borderRadius={1} onClick={handleClickAddProduct}>
          Add product
        </SlzButton>
      </Box>
    </VStack>
  );
};

Products.Rows = ({ products = [], children }) => {
  const isProductsEmpty = isEmpty(products);

  return (
    <>
      {MOST_POPULAR_PRODUCT_ROWS.map((order, idx) => {
        const { name = '', sku = '' } = products[idx] || {};
        return children({ order, name, sku, isEmptyRow: !isProductsEmpty && !products[idx] });
      })}
    </>
  );
};

Products.Row = ({ children }) => {
  return <HStack {...PRODUCTS_STYLES.row}>{children}</HStack>;
};

Products.RowEmpty = () => <Box h="3.625rem" />;

Products.ProductName = ({ isProductsEmpty, isLoading, name }) => {
  return (
    <>
      <Text
        color={isProductsEmpty && !isLoading ? 'dark.500' : 'positive.500'}
        {...PRODUCTS_STYLES.rowDetail.name(isLoading)}>
        Product name
      </Text>
      <ShimmerSkeleton.Block isLoaded={!isLoading} w="full" h={isLoading ? '0.6875rem ' : 'auto'}>
        <Text fontSize="0.75rem" height="1rem" lineHeight="1rem">
          {name}
        </Text>
      </ShimmerSkeleton.Block>
    </>
  );
};

Products.ProductSKU = ({ isLoading, sku }) => (
  <>
    <Text fontSize="0.625rem" color="dark.500" lineHeight="0.8125rem">
      SKU
    </Text>
    <ShimmerSkeleton.Block isLoaded={!isLoading} w="full" h={isLoading ? '0.6875rem ' : 'auto'}>
      <Text fontSize="0.625rem" lineHeight="0.8125rem" h="0.8125rem">
        {sku}
      </Text>
    </ShimmerSkeleton.Block>
  </>
);

Products.RowDetail = ({ isEmptyRow, isLoading, children }) => {
  if (isEmptyRow && !isLoading) {
    return <Products.RowEmpty />;
  }

  return <VStack {...PRODUCTS_STYLES.rowDetail.wrap(isLoading)}>{children}</VStack>;
};

Products.RowOrder = ({ isEmptyAndLoaded, children }) => (
  <Box w={6} bg={isEmptyAndLoaded ? 'light.700' : 'positive.300'} textAlign="center">
    <Text color={isEmptyAndLoaded ? 'dark.700' : 'positive.500'} {...PRODUCTS_STYLES.order}>
      {children}
    </Text>
  </Box>
);

const MostPopularProduct = ({ products = [], isLoading = false, ...rest }) => {
  const isProductsEmpty = isEmpty(products);
  const isEmptyAndLoaded = isProductsEmpty && !isLoading;
  return (
    <Products.Wrap
      bg={isEmptyAndLoaded ? 'light.500' : 'white'}
      {...PRODUCTS_STYLES.wrapper}
      {...rest}>
      <Products.Header />
      <Products.Rows products={products}>
        {({ order, name, sku, isEmptyRow }) => {
          return (
            <Products.Row key={`${order.toString()}${name}`}>
              <Products.RowOrder isEmptyAndLoaded={isEmptyAndLoaded}>{order}</Products.RowOrder>
              <Products.RowDetail {...{ isProductsEmpty, isLoading, isEmptyRow }}>
                <Products.ProductName {...{ isProductsEmpty, isLoading, name }} />
                <Products.ProductSKU {...{ isLoading, sku }} />
              </Products.RowDetail>
            </Products.Row>
          );
        }}
      </Products.Rows>
      <Products.Footer />
    </Products.Wrap>
  );
};

export default MostPopularProduct;
