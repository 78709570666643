import { GridItem, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { SlzGridModal } from 'components/common/SlzModal';
import SetupRequirement from 'components/common/SetupRequirement';
import { checkIfUsesExternalWMS } from '../../../services/consignments-api';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';

import {
  selectConsignmentArrivingTypeSchema,
  sendConsignmentSchema,
  multipleConsignmentUploadSchema,
  consignmentDetailsSchema
} from 'modules/consignments/components/modal/form/validation/send';
import {
  SEND_CONSIGNMENT_MODAL_STEPS,
  SEND_CONSIGNMENT_TYPES
} from 'modules/consignments/constants';
import Action from './Action';
import Header from './Header';
import { transformWarehousesList } from 'modules/consignments/mappers/warehouses-list-mapper';
import { useWarehouseQuery } from 'modules/consignments/hooks/useWarehouseQuery';
import { SEND_CONSIGNMENT_STEPS } from './ModalContent';
import { useMutation } from 'react-query';
import { transformMultipleProductConsignment } from 'modules/consignments/mappers/multiple-product-consignment-mapper';
import { transform } from 'modules/consignments/mappers/single-product-consignment-mapper';
import {
  uploadMultipleConsignment,
  uploadSingleConsignment
} from 'modules/consignments/services/products-api';
import { notifyUpload } from 'modules/b2c-orders/utils/notifyUpload';
import useSlzToast from 'hooks/useSlzToast';

const ModalContent = ({ currentType, ...rest }) => {
  const Content = SEND_CONSIGNMENT_STEPS[currentType];
  return <Content currentType={currentType} {...rest} />;
};

const Modal = (props) => {
  const { formValues, isOpen, onClose, onSave } = props;
  const [isUploaded, setIsUploaded] = useState(false);
  const [usesExternalWMS, setUsesExternalWMS] = useState(false);
  const { requiredSettings, isRequiredSettingsEmpty, isLoadingRequiredSettings, isRequiredSettingsFetching } = useCheckRequiredSettings();
  const [currentType, setCurrentType] = useState(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE);
  const [csvFiles, setCsvFiles] = useState([]);
  const [selectedConsignmentType, setSelectedConsignmentType] = useState(null);
  const [selectedConsignmentArrivingType, setSelectedConsignmentArrivingType] = useState(null);
  const [_, setToast] = useSlzToast();

  const toast = useToast();
  const mutationSingleUpload = useMutation({
    mutationFn: (payload) => uploadSingleConsignment(payload)
  });

  const mutationMultipleUpload = useMutation({
    mutationFn: ({ csvFiles, payload, numberToLoop }) =>
      uploadMultipleConsignment(csvFiles, payload, numberToLoop)
  });

  const { data: warehousesData, isLoading } = useWarehouseQuery((warehousesData) =>
    transformWarehousesList(warehousesData)
  );

  const getValidationSchema = useCallback(() => {
    switch (currentType) {
      case SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE:
        return sendConsignmentSchema;
      case SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE:
        return selectConsignmentArrivingTypeSchema;
      case SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS:
        return selectedConsignmentType === SEND_CONSIGNMENT_TYPES.SINGLE
          ? consignmentDetailsSchema
          : multipleConsignmentUploadSchema;
      default:
        return yup.object().shape({});
    }
  }, [currentType, selectedConsignmentType]);

  const methods = useForm({
    defaultValues: formValues,
    resolver: yupResolver(getValidationSchema()),
    shouldFocusError: false
  });
  let flattenedErrors = [];

  useEffect(() => {
    setSelectedConsignmentType(methods.watch('consignmentType'));

    async function determineExternalWMS() { await checkUsesExternalWMS(); }
    determineExternalWMS();

  }, [methods.watch('consignmentType')]);

  useEffect(() => {
    setSelectedConsignmentArrivingType(methods.watch('consignmentArrivingType'));
  }, [methods.watch('consignmentArrivingType')]);

  useEffect(() => {
    formValues && methods.reset(formValues);
  }, [formValues]);

  const handleDisappearedToast = (onClose) => onClose && onClose();

  const handleUploadSingleConsignment = async () => {
    const consignment = methods.getValues();
    const payload = transform(consignment);

    try {
      const { data } = await mutationSingleUpload.mutateAsync(payload);
      if (!data.error) {
        notifyUpload({
          toast,
          onCloseComplete: handleDisappearedToast,
          colorScheme: 'positive',
          title: 'Stock record successfully uploaded.',
          status: 'success',
          position: 'top'
        });
        onClose();
      } else {
        notifyUpload({
          toast,
          colorScheme: 'negative',
          title: 'Stock record upload failed!',
          description: `${data.message || ''}`,
          status: 'warning',
          position: 'top'
        });
      }
    } catch (error) {
      console.log('error', error);
      notifyUpload({
        toast,
        colorScheme: 'negative',
        title: 'Cannot upload the stock record.',
        status: 'warning',
        position: 'top'
      });
    }
  };

  const handleUploadMultipleConsignment = async () => {
    const consignment = methods.getValues();
    const payload = transformMultipleProductConsignment(consignment);

    try {
      const { data } = await mutationMultipleUpload.mutateAsync({
        csvFiles,
        payload,
        numberToLoop: consignment.repeatConsignments
      });
      if (!data.error) {
        notifyUpload({
          toast,
          onCloseComplete: handleDisappearedToast,
          colorScheme: 'positive',
          title: 'Stock record successfully uploaded.',
          status: 'success',
          position: 'top'
        });
        onClose();
      } else {
        notifyUpload({
          toast,
          colorScheme: 'negative',
          title: 'Stock record upload failed!',
          description: `${data.message || ''}`,
          status: 'warning',
          position: 'top'
        });
      }
    } catch (error) {
      console.log('error', error);
      notifyUpload({
        toast,
        colorScheme: 'negative',
        title: 'Cannot upload the stock record.',
        status: 'warning',
        position: 'top'
      });
    }
  };

  useEffect(() => {
    let formErrors = Object.values(methods?.formState?.errors);
    if (formErrors.length > 0) {
      for (let i = 0; i < formErrors.length; i++) {
        if (formErrors[i]?.message) {
          flattenedErrors.push(`${formErrors[i]?.message} \n`);
        } else {
          let nestedErrors = Object.values(formErrors[i]);
          for (let x = 0; x < nestedErrors.length; x++) {
            flattenedErrors.push(`${nestedErrors[x]?.message} \n`);
          }
        }
      }
      let toastDesc = flattenedErrors.map((y) => <Text>{y}</Text>);

      setToast({
        colorScheme: 'negative',
        title: 'Error(s)',
        description: toastDesc,
        status: 'warning'
      });
    }
  }, [methods?.formState?.errors]);

  const onSubmit = () => {
    switch (currentType) {
      case SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.LABELLING);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.LABELLING:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY:
        selectedConsignmentType === SEND_CONSIGNMENT_TYPES.SINGLE
          ? handleUploadSingleConsignment()
          : handleUploadMultipleConsignment();
        break;
    }
  };

  const resetAll = () => {
    setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE);
    setIsUploaded(false);
    methods?.reset();
  };

  const onBack = useCallback(() => {
    switch (currentType) {
      case SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.LABELLING:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS);
        break;
      case SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY:
        setCurrentType(SEND_CONSIGNMENT_MODAL_STEPS.LABELLING);
        break;
    }
  }, [currentType]);

  const handleEditStep = (step) => setCurrentType(step);

  const onModalClose = useCallback(() => {
    resetAll();
    onClose();
  }, []);

  const checkUsesExternalWMS = async () => {
    try {
      var companyUsesExternalWMS = await checkIfUsesExternalWMS();
      setUsesExternalWMS(companyUsesExternalWMS);

      // Gets called in product add menu for some reason. We check formValues to ensure this is the create consignment modal
      if (companyUsesExternalWMS && formValues !== undefined)
        setToast({
          colorScheme: 'negative',
          title: 'Error(s)',
          description: 'Send a consignment is not available',
          status: 'warning'
        });
      }  catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  return (
    !isLoadingRequiredSettings && !usesExternalWMS && (
      <SlzGridModal
        id="inventory-send-consignment-slz-modal"
        isOpen={isOpen}
        onClose={onModalClose}>
        <GridItem gridColumn={'2/12'}>
          <Header
            currentType={currentType}
            onBack={onBack}
            consignmentType={selectedConsignmentType}
          />
        </GridItem>

        <GridItem gridColumn={'2/12'}>
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '51.625rem' }}>
              {!isRequiredSettingsEmpty ? (
                <SetupRequirement mb="12.5rem" settings={requiredSettings} onClose={onClose} />
              ) : (
                !isRequiredSettingsFetching && (
                  <ModalContent
                    currentType={currentType}
                    warehousesData={warehousesData}
                    selectedConsignmentType={selectedConsignmentType}
                    selectedConsignmentArrivingType={selectedConsignmentArrivingType}
                    setCsvFiles={setCsvFiles}
                    csvFiles={csvFiles}
                    handleEditStep={handleEditStep}
                  />
                )
              )}

              <Action
                isActionDisabled={!isRequiredSettingsEmpty || usesExternalWMS}
                onClickCancel={onModalClose}
                currentType={currentType}
                selectedConsignmentType={selectedConsignmentType}
                isLoading={mutationSingleUpload.isLoading || mutationMultipleUpload.isLoading}
                onBack={onBack}
              />
            </form>
          </FormProvider>
        </GridItem>
      </SlzGridModal>
    )
  );
};

export default Modal;
