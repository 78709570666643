import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Alcohol = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="0.643rem"
            h="1.125rem"
            viewBox="0 0 10.286 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Path_10271" data-name="Path 10271" d="M10.286,11.895v-.264A5.484,5.484,0,0,0,8.84,7.98L8.8,7.932c-.707-.8-1.086-3.965-1.084-4.947a.545.545,0,0,0-.027-.107.564.564,0,0,0,.027-.108V1.385A1.576,1.576,0,0,0,6,0H4.287A1.576,1.576,0,0,0,2.573,1.385V2.769a.517.517,0,0,0,.027.108.5.5,0,0,0-.027.107c0,.982-.376,4.148-1.083,4.947l-.044.049A5.483,5.483,0,0,0,0,11.631v1.362a.628.628,0,0,0,0,.082v2.848A2.364,2.364,0,0,0,2.573,18H7.715a2.364,2.364,0,0,0,2.571-2.077V11.895Zm-6-10.51H6v.692H4.287Zm-1.444,7.4.043-.049C3.7,7.811,4.179,4.964,4.27,3.462H6.018c.091,1.5.568,4.35,1.385,5.273l.043.048a4.287,4.287,0,0,1,1.116,2.743,2.72,2.72,0,0,0-.685.532,1.314,1.314,0,0,1-1,.4H6.84a1.312,1.312,0,0,1-1-.4,3.187,3.187,0,0,0-2.41-.981,3.39,3.39,0,0,0-1.7.441A4.284,4.284,0,0,1,2.842,8.784m4.873,7.831H2.573a.788.788,0,0,1-.857-.692V13.4a2.721,2.721,0,0,0,.7-.537,1.312,1.312,0,0,1,1-.4h.036a1.314,1.314,0,0,1,1,.4,3.175,3.175,0,0,0,2.367.981H6.9a3.385,3.385,0,0,0,1.67-.443v2.52a.788.788,0,0,1-.857.692" transform="translate(0)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Alcohol;