import React from 'react';
import { Box, HStack, useStyleConfig } from '@chakra-ui/react';
import { Icon } from '@chakra-ui/icons';
import { SlzModal } from 'components/common/SlzModal';
import { SlzButton } from 'components/common/SlzButton';

export default function DeleteBundleModal({ isOpen, onClose, onConfirm, isLoading }) {
  const { iconDelete, button } = useStyleConfig('SlzModal');
  const WarningIcon = (props) => {
    return (
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="currentColor"
        {...props}>
        <path d="M8 1.45l6.705 13.363h-13.409l6.705-13.363zM8 0c-0.345 0-0.69 0.233-0.951 0.698l-6.829 13.611c-0.523 0.93-0.078 1.691 0.989 1.691h13.583c1.067 0 1.512-0.761 0.989-1.691h0l-6.829-13.611c-0.262-0.465-0.606-0.698-0.951-0.698v0z"></path>
        <path d="M9 13c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z"></path>
        <path d="M8 11c-0.552 0-1-0.448-1-1v-3c0-0.552 0.448-1 1-1s1 0.448 1 1v3c0 0.552-0.448 1-1 1z"></path>
      </Icon>
    );
  };

  return (
    <SlzModal
      id="product-slz-modal"
      size="4xl"
      variant="deleteModal"
      onClose={onClose}
      closeOnOverlayClick={false}
      maxW={'680px'}
      showOverlay={false}
      isOpen={isOpen}>
      <HStack>
        <Box marginRight={'7.625rem'}>
          <Icon data-testid="attention-card-icon" as={WarningIcon} sx={iconDelete} />
          <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#DE4259' }}>
            Delete this bundle ?
          </span>
        </Box>
        <HStack spacing={11}>
          <SlzButton onClick={onClose} sx={button.cancel} size="lg" width="full">
            Cancel
          </SlzButton>
          <SlzButton
            onClick={onConfirm}
            sx={button.delete}
            isLoading={isLoading}
            size="lg"
            width="full">
            Delete
          </SlzButton>
        </HStack>
      </HStack>
    </SlzModal>
  );
}
