import { cloneElement, forwardRef } from 'react';

import { FormControl } from '@chakra-ui/react';
import { Select, CreatableSelect, useChakraSelectProps } from 'chakra-react-select';
import uniqueId from 'lodash/uniqueId';

import { SEL_PREFIX_ID } from '../Constants';
import { DROPDOWN_TEST_ID, SELECT_CREATABLE_STYLES, SELECT_STYLES } from './constants';
import DefaultComponents from './DefaultComponents';

const SlzDropdown = ({ children, customComponents, ...rest }) => {
  const ID = uniqueId(`${SEL_PREFIX_ID}-Slz-Dropdown`);
  const components = {
    Option: DefaultComponents.Option,
    DropdownIndicator: DefaultComponents.Indicator,
    ...customComponents
  };

  const selectProps = useChakraSelectProps({
    placeholder: 'Select',
    errorMessage: 'Error message',
    name: 'select',
    size: 'sm',
    width: '100%',
    hideSelectedOptions: false,
    chakraStyles: SELECT_STYLES,
    closeMenuOnSelect: !rest?.isMulti,
    components,
    ...rest
  });

  if (children) {
    const select = cloneElement(children, selectProps);
    return select;
  }

  return (
    <FormControl data-testid={DROPDOWN_TEST_ID}>
      <Select id={ID} {...selectProps}>
        {children}
      </Select>
    </FormControl>
  );
};

SlzDropdown.Creatable = forwardRef((props, ref) => (
  <SlzDropdown chakraStyles={SELECT_CREATABLE_STYLES} {...props}>
    <CreatableSelect ref={ref} />
  </SlzDropdown>
));

export default SlzDropdown;
