import { Box, Flex, Text } from '@chakra-ui/react';
import { tooltipArrowStyle } from './chart.styles';

const CustomTooltipContent = ({ children, thinArrow, ...rest }) => {
  return (
    <Box {...rest}>
      <Flex
        border="commonStone"
        borderRadius={1}
        bg="white"
        height="max-content"
        justify="center"
        alignItems="center"
        shadow="slz-tooltip"
        _after={tooltipArrowStyle({ thinArrow })}>
        {children}
      </Flex>
    </Box>
  );
};

export default CustomTooltipContent;
