import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useQuery } from 'hooks/useQuery';
import { DATE_DISPLAY_FORMAT_YYYYMMDD } from 'constants';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, PAGE_INDEX, PAGE_SIZE, DATE_FROM, DATE_TO } from 'constants/table.js';

const useTableFilterHandler = () => {
  const history = useHistory();
  const routerQuery = useQuery();

  const handleCurrentPageChange = useCallback(
    (currentPage) => {
      if (currentPage >= DEFAULT_PAGE_INDEX) {
        routerQuery.set(PAGE_INDEX, currentPage);
        history.push({
          search: routerQuery.toString()
        });
        return;
      }
    },
    [routerQuery]
  );

  const handleItemPerPageChange = useCallback(
    (pageSize) => {
      if (pageSize <= DEFAULT_PAGE_SIZE) {
        routerQuery.set(PAGE_SIZE, pageSize);
        routerQuery.set(PAGE_INDEX, DEFAULT_PAGE_INDEX);
        history.push({ search: routerQuery.toString() });
        return;
      }
    },[routerQuery]);

  const handleSelectedDateRange = useCallback((startDate, endDate) => {
    if (startDate && endDate) {
      routerQuery.set(DATE_FROM, startDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD));
      routerQuery.set(DATE_TO, endDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD));
    } else {
      routerQuery.delete(DATE_FROM);
      routerQuery.delete(DATE_TO);
    }
    history.push({
      search: routerQuery.toString()
    });
  }, []);

  const handleSearch = useCallback(
    (queryKey, value) => {
      if (routerQuery.has(PAGE_INDEX)) {
        routerQuery.delete(PAGE_INDEX);
      }

      const val = value?.trim();

      if (val) {
        routerQuery.set(queryKey, val);
      } else {
        routerQuery.delete(queryKey);
      }

      history.push({
        search: routerQuery.toString()
      });
    },
    [routerQuery]
  );

  return {
    handleCurrentPageChange,
    handleItemPerPageChange,
    handleSelectedDateRange,
    handleSearch
  };
};

export default useTableFilterHandler;
