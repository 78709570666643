import { Box, Text } from '@chakra-ui/react';
import { cardInformation, cardInformation1, cardInformation2, cardInformation3 } from './data';
import SlzCurrencyIcon from '../../components/common/SlzCurrencyIcon';
import { SlzCompactCreditCard, SlzCreditCard } from '../../components/common/SlzPaymentCard';

const CurencyIconsAndCreditCards = () => {
  return (
    <>
      <Box>
        <Text as="h1" fontSize="2xl" fontWeight={600} my={10}>
          Currency Icons
        </Text>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'GB'} />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'lg'} code={'GB'} />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'GB'} />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'md'} code={'GB'} />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'GB'} />
          </Box>
        </Box>
        <Box style={{ display: 'flex', width: '120px' }}>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'CO'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'EU'} />
          </Box>
          <Box style={{ marginRight: '12px' }}>
            <SlzCurrencyIcon size={'sm'} code={'GB'} />
          </Box>
        </Box>
      </Box>
      <Text as="h1" fontSize="2xl" fontWeight={600} my={10}>
        Credit Card
      </Text>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}>
        <SlzCompactCreditCard size="xs" type={cardInformation.type} />
        <SlzCompactCreditCard size="xs" type={cardInformation1.type} />
        <SlzCompactCreditCard size="xs" type={cardInformation2.type} />
        <SlzCompactCreditCard size="xs" type={cardInformation3.type} />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}>
        <SlzCompactCreditCard size="sm" type={cardInformation.type} />
        <SlzCompactCreditCard size="sm" type={cardInformation1.type} />
        <SlzCompactCreditCard size="sm" type={cardInformation2.type} />
        <SlzCompactCreditCard size="sm" type={cardInformation3.type} />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}>
        <SlzCompactCreditCard size="lg" type={cardInformation.type} />
        <SlzCompactCreditCard size="lg" type={cardInformation1.type} />
        <SlzCompactCreditCard size="lg" type={cardInformation2.type} />
        <SlzCompactCreditCard size="lg" type={cardInformation3.type} />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}>
        <SlzCreditCard info={cardInformation} onRemove={() => console.log('remove')} />
        <SlzCreditCard info={cardInformation1} onRemove={() => console.log('remove')} />
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginBottom: '12px'
        }}>
        <SlzCreditCard size="xl" info={cardInformation} onRemove={() => console.log('remove')} />
        <SlzCreditCard size="xl" info={cardInformation1} onRemove={() => console.log('remove')} />
      </Box>
    </>
  );
};
export default CurencyIconsAndCreditCards;
