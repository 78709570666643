import React from 'react';
import { HStack } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import { B2B_ORDER_ACTION_CONTENT } from 'modules/b2b-orders/constants';

const DEFAULT_CHILDREN = ({ disableNextButton }) => (
  <SlzButton variant="solid" type="submit" size="lg" isDisabled={disableNextButton}>
    {B2B_ORDER_ACTION_CONTENT.next}
  </SlzButton>
);

const B2BOrderAction = ({
  onPrevious,
  disableNextButton = false,
  onCancel,
  children = DEFAULT_CHILDREN({ disableNextButton })
}) => {
  return (
    <HStack w="full" spacing={0} justifyContent="space-between" mt={7} mb={6}>
      <SlzButton variant="outline" size="lg" onClick={onPrevious}>
        {B2B_ORDER_ACTION_CONTENT.previous}
      </SlzButton>
      <HStack>
        <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onCancel}>
          {B2B_ORDER_ACTION_CONTENT.cancel}
        </SlzButton>
        {children}
      </HStack>
    </HStack>
  );
};

export default B2BOrderAction;
