import { Grid, GridItem, HStack, Heading } from '@chakra-ui/react';

import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';

import { ItemCount } from 'modules/b2c-orders/components/form/b2c-form/OrderSummaryB2C';
import BaseSkeleton from './BaseSkeleton';

const Overview = () => (
  <ShimmerSkeleton>
    <HStack justifyContent="space-between" mb="5.625rem">
      <ShimmerSkeleton.Block w="23.239rem" h="23.239rem" />
      <ShimmerSkeleton.Block w="23.239rem" h="23.239rem" />
    </HStack>
  </ShimmerSkeleton>
);

const B2CUpload = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block mb={2} w="100%" h="24.313rem" />
  </ShimmerSkeleton>
);

const B2COrderDetails = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block h="0.938rem" w="7.5rem" mb="0.938rem" />
    <ShimmerSkeleton.Input w="20.134rem" mb="1.125rem" />
    <HStack>
      <ShimmerSkeleton.Input w="20.134rem" />
      <ShimmerSkeleton.Input w="20.134rem" />
      <ShimmerSkeleton.Input flex="1" />
    </HStack>
  </ShimmerSkeleton>
);

const B2CDeliveryDetails = () => (
  <ShimmerSkeleton>
    <HStack justifyContent="space-between" mb="0.938rem">
      <ShimmerSkeleton.Block h="0.938rem" w="8.625rem" />
      <ShimmerSkeleton.Block h="3rem" w="9.692rem" />
    </HStack>
    <Grid
      gridTemplateColumns="20.186rem repeat(2, 238.8px)"
      gridTemplateRows="repeat(3, 4.688rem)"
      gridColumnGap="0.625rem"
      gridRowGap="0.625rem"
      mb="1.125rem">
      {[...Array(9)].map(() => (
        <GridItem>
          <ShimmerSkeleton.Input titleStyle={{ w: '8.625rem' }} />
        </GridItem>
      ))}
    </Grid>
    <HStack alignItems="flex-start">
      <ShimmerSkeleton.Input w="20.186rem" titleStyle={{ w: '8.125rem' }}>
        <HStack wrap="wrap">
          {[...Array(9)].map(() => (
            <ShimmerSkeleton.Block
              ml="0 !important"
              mb="0.625rem !important"
              mr="0.625rem !important"
              w="calc(50% - 0.625rem)"
              h="1.75rem"
            />
          ))}
        </HStack>
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input flex="1" titleStyle={{ w: '2.688rem' }}>
        <ShimmerSkeleton.Block h="10.875rem" w="100%" />
      </ShimmerSkeleton.Input>
    </HStack>
  </ShimmerSkeleton>
);

const B2COrderSummary = ({ itemCount }) => (
  <ShimmerSkeleton>
    <HStack justifyContent="space-between" mb="0.938rem">
      <ShimmerSkeleton.Block h="0.938rem" w="8.625rem" />
      <ItemCount label="Item count" value={itemCount} />
    </HStack>
    <HStack alignItems="top" spacing="0.625rem">
      <ShimmerSkeleton.Input w="20.188rem" titleStyle={{ w: '8.75rem' }}>
        {[...Array(4)].map(() => (
          <ShimmerSkeleton.Block h="1.875rem" mb="0.75rem" w="100%" />
        ))}
        <ShimmerSkeleton.Block h="8.625rem" w="100%" />
      </ShimmerSkeleton.Input>
      <ShimmerSkeleton.Input flex="1" titleStyle={{ w: '8.75rem' }}>
        {[...Array(2)].map(() => (
          <ShimmerSkeleton.Block h="10.875rem" mb="0.75rem" w="100%" />
        ))}
      </ShimmerSkeleton.Input>
    </HStack>
  </ShimmerSkeleton>
);

const B2CConfirmation = () => (
  <ShimmerSkeleton>
    <ShimmerSkeleton.Block h="1.25rem" mb="0.625rem" w="12.125rem" />
    <ShimmerSkeleton.Block h="1.25rem" mb="1.5rem" w="100px" />

    <ShimmerSkeleton.Block h="1.875rem" w="16.875rem" mb="1.5rem" />
    <HStack mb="3.75rem">
      <ShimmerSkeleton.Block h="3rem" w="14.938rem" />
      <ShimmerSkeleton.Block h="3rem" w="14.938rem" />
    </HStack>

    <ShimmerSkeleton.Block h="1.25rem" mb="1.5rem" w="100px" />

    {[...Array(2)].map(() => (
      <ShimmerSkeleton.Input titleStyle={{ h: '1.25rem', w: '100px' }}>
        <Grid
          gridTemplateColumns="323.92px 14.938rem 9.75rem"
          gridTemplateRows="repeat(3, 3rem)"
          gridColumnGap="0.75rem"
          gridRowGap="0.25rem">
          {[...Array(4)].map(() => (
            <ShimmerSkeleton.Block w="100%" h="100%" />
          ))}
        </Grid>
      </ShimmerSkeleton.Input>
    ))}
  </ShimmerSkeleton>
);

const TrackingReferences = () => (
  <ShimmerSkeleton display="flex" alignItems="top">
    <ShimmerSkeleton.Block />
    <ShimmerSkeleton.Block />
    <ShimmerSkeleton.Block />

    <ShimmerSkeleton.Rows numberOfRows={14} />
  </ShimmerSkeleton>
);

const B2BOverview = () => (
  <ShimmerSkeleton>
    <HStack gap="0.375rem">
      {[...Array(3)].map(() => (
        <ShimmerSkeleton.Block h="23.188rem" flex="1" />
      ))}
    </HStack>
    <ShimmerSkeleton.Block mt="1rem" h="5.25rem" w="100%" />
    <ShimmerSkeleton.Block mt="1rem" mb="3.125rem" h="1.313rem" w="35rem" />
  </ShimmerSkeleton>
);

const ORDER_TEMPLATES = {
  overview: Overview,
  'b2c.overview': Overview,
  'b2c.upload': B2CUpload,
  'b2c.singleOrder.1': B2COrderDetails,
  'b2c.singleOrder.2': B2CDeliveryDetails,
  'b2c.singleOrder.3': B2COrderSummary,
  'b2c.singleOrder.4': B2CConfirmation,
  trackingReferences: TrackingReferences,
  'b2b.overview': B2BOverview
};

const OrderSkeleton = (props) => {
  return (
    <GridItem gridColumn="2/12">
      <BaseSkeleton baseTemplates={ORDER_TEMPLATES} {...props} />
    </GridItem>
  );
};

export default OrderSkeleton;
