import React from 'react';
import { Icon } from '@chakra-ui/react';

const AmazonShortIcon = (props) => {
  return (
    <Icon id="Amazon_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.551 30" {...props}>
      <g id="Amazon_logo" transform="translate(-0.364 -0.246)">
        <path
          id="path8"
          d="M132.853,120.365c-5.785,4.264-14.17,6.539-21.39,6.539a38.706,38.706,0,0,1-26.13-9.971c-.542-.49-.056-1.157.594-.776a52.591,52.591,0,0,0,26.143,6.933,51.986,51.986,0,0,0,19.942-4.078c.979-.416,1.8.641.841,1.352"
          transform="translate(-70.73 -96.658)"
          fill="#f90"
        />
        <path
          id="path10"
          d="M352.035,112.359c-.737-.945-4.888-.446-6.751-.225-.568.069-.654-.425-.143-.78,3.306-2.327,8.732-1.655,9.364-.875s-.165,6.223-3.272,8.818c-.477.4-.932.186-.719-.342.7-1.742,2.262-5.646,1.521-6.6"
          transform="translate(-287.507 -91.404)"
          fill="#f90"
        />
        <path
          id="path12"
          d="M345.587,5.754V3.492a.558.558,0,0,1,.572-.572h10.127a.564.564,0,0,1,.585.572V5.429a3.187,3.187,0,0,1-.763,1.421l-5.248,7.492a11.181,11.181,0,0,1,5.776,1.239,1.031,1.031,0,0,1,.537.88v2.414c0,.329-.364.715-.745.516a11.666,11.666,0,0,0-10.7.017c-.351.191-.719-.191-.719-.52V16.6a2.735,2.735,0,0,1,.373-1.556l6.08-8.719h-5.291a.56.56,0,0,1-.585-.568"
          transform="translate(-287.68 -2.232)"
          fill="#221f1f"
        />
        <path
          id="path14"
          d="M106.661,18.4h-3.081a.582.582,0,0,1-.55-.524V2.065a.578.578,0,0,1,.594-.568H106.5a.58.58,0,0,1,.559.529V4.093h.056a4.067,4.067,0,0,1,4.056-2.929c1.928,0,3.133.932,4,2.929a4.55,4.55,0,0,1,7.822-1.2c.975,1.33.776,3.263.776,4.957l0,9.98a.582.582,0,0,1-.594.572H120.09a.586.586,0,0,1-.555-.572V9.449a19.791,19.791,0,0,0-.087-2.964,1.6,1.6,0,0,0-1.811-1.361,2.047,2.047,0,0,0-1.842,1.3,9.573,9.573,0,0,0-.286,3.029V17.83a.582.582,0,0,1-.594.572h-3.077a.582.582,0,0,1-.555-.572l0-8.381c0-1.764.29-4.359-1.9-4.359-2.214,0-2.128,2.531-2.128,4.359V17.83a.582.582,0,0,1-.594.572"
          transform="translate(-85.696 -0.766)"
          fill="#221f1f"
        />
        <path
          id="path16"
          d="M434.5,1.164c4.572,0,7.046,3.926,7.046,8.918,0,4.823-2.734,8.649-7.046,8.649-4.489,0-6.933-3.926-6.933-8.818,0-4.923,2.474-8.749,6.933-8.749m.026,3.228c-2.271,0-2.414,3.094-2.414,5.022s-.03,6.058,2.388,6.058c2.388,0,2.5-3.328,2.5-5.356a14.618,14.618,0,0,0-.459-4.195,1.927,1.927,0,0,0-2.015-1.53"
          transform="translate(-356.591 -0.766)"
          fill="#221f1f"
        />
        <path
          id="path18"
          d="M529.691,18.4h-3.068a.586.586,0,0,1-.555-.572l0-15.817a.583.583,0,0,1,.594-.516h2.856a.59.59,0,0,1,.55.442V4.357h.056c.862-2.162,2.071-3.194,4.2-3.194a4.043,4.043,0,0,1,3.6,1.863c.806,1.265.806,3.393.806,4.923V17.9a.592.592,0,0,1-.594.5h-3.09a.583.583,0,0,1-.546-.5V9.315c0-1.729.2-4.26-1.928-4.26a2.014,2.014,0,0,0-1.781,1.265,7,7,0,0,0-.49,2.994V17.83a.592.592,0,0,1-.6.572"
          transform="translate(-438.807 -0.766)"
          fill="#221f1f"
        />
        <path
          id="use28"
          d="M9.442,10.083V9.415c-2.227,0-4.58.477-4.58,3.1,0,1.33.689,2.232,1.872,2.232a2.5,2.5,0,0,0,2.132-1.4,6.027,6.027,0,0,0,.576-3.267m3.107,7.51a.643.643,0,0,1-.728.074,7.586,7.586,0,0,1-1.768-2.054,6.1,6.1,0,0,1-5.079,2.24c-2.591,0-4.611-1.6-4.611-4.8A5.235,5.235,0,0,1,3.649,8.016a21.348,21.348,0,0,1,5.794-1.07v-.4A3.964,3.964,0,0,0,9.07,4.315a2.062,2.062,0,0,0-1.729-.8,2.349,2.349,0,0,0-2.479,1.85.645.645,0,0,1-.533.563l-2.99-.321A.546.546,0,0,1,.88,4.961C1.569,1.338,4.84.246,7.77.246A6.985,6.985,0,0,1,12.411,1.78c1.5,1.4,1.356,3.267,1.356,5.3v4.8a4.214,4.214,0,0,0,1.161,2.856.579.579,0,0,1-.009.819c-.628.524-1.746,1.5-2.362,2.045l-.009-.009"
          transform="translate(40.391 0)"
          fill="#221f1f"
        />
        <path
          id="path30"
          d="M9.442,10.083V9.415c-2.227,0-4.58.477-4.58,3.1,0,1.33.689,2.232,1.872,2.232a2.5,2.5,0,0,0,2.132-1.4,6.027,6.027,0,0,0,.576-3.267m3.107,7.51a.643.643,0,0,1-.728.074,7.586,7.586,0,0,1-1.768-2.054,6.1,6.1,0,0,1-5.079,2.24c-2.591,0-4.611-1.6-4.611-4.8A5.235,5.235,0,0,1,3.649,8.016a21.348,21.348,0,0,1,5.794-1.07v-.4A3.964,3.964,0,0,0,9.07,4.315a2.062,2.062,0,0,0-1.729-.8,2.349,2.349,0,0,0-2.479,1.85.645.645,0,0,1-.533.563l-2.99-.321A.546.546,0,0,1,.88,4.961C1.569,1.338,4.84.246,7.77.246A6.985,6.985,0,0,1,12.411,1.78c1.5,1.4,1.356,3.267,1.356,5.3v4.8a4.214,4.214,0,0,0,1.161,2.856.579.579,0,0,1-.009.819c-.628.524-1.746,1.5-2.362,2.045l-.009-.009"
          transform="translate(0 0)"
          fill="#221f1f"
        />
      </g>
    </Icon>
  );
};

export default AmazonShortIcon;
