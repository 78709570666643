import React, { Fragment } from 'react';
import { GridItem, HStack, Heading, Icon, Text } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { DirectDebitConfigsForm } from '../form';
import { useAddDirectDebit } from '../../hooks';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { PREFERENCES_ACTIONS, USER_SETTING_MODAL_LAYOUT } from 'modules/preferences/constants';

export default function DirectDebitConfigsModalLayout() {
  const { preferenceDispatch } = usePreferenceContext();
  const addingCreditCard = useAddDirectDebit();

  const onClose = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.default.key }
    });
  };

  return (
    <Fragment>
      <GridItem gridColumn="2/12">
        <HStack alignItems="flex-end" my="6">
          <HStack spacing={1}>
            <Icon
              as={FaChevronLeft}
              fontSize={14}
              color="dark.500"
              cursor="pointer"
              onClick={onClose}
            />
            <Heading as="h3" fontSize="xl" lineHeight={'none'} mb="0">
              Back to payment methods
            </Heading>
            <Icon as={FaChevronRight} fontSize={14} color="dark.500" />
            <Text>Add bank account</Text>
          </HStack>
        </HStack>
      </GridItem>

      <GridItem gridColumn="2/12">
        <DirectDebitConfigsForm onBack={onClose} onSubmit={addingCreditCard.mutate} />
      </GridItem>
    </Fragment>
  );
}
