import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { INPUT_STATUS } from 'components/common/Constants';

export default function FormWrapper({ defaultValues, validationSchema, children }) {
  const methods = useForm({
    defaultValues,
    ...(validationSchema
      ? {
          resolver: yupResolver(validationSchema),
          reValidateMode: 'onSubmit',
          shouldFocusError: false
        }
      : {})
  });
  const [focusedFields, setFocusedFields] = useState({});
  const errors = methods.formState.errors;

  const resetFieldStatus = (name) => {
    methods.resetField(name, {
      defaultValue: methods.getValues(name)
    });
    setFocusedFields((prev) => ({ ...prev, [name]: true }));
  };

  const resetAllFieldsStatus = () => {
    setFocusedFields({});
  };

  const getFieldStatus = (name) => {
    const isError = errors[name];
    const isValid = !isError && !focusedFields[name] && methods.formState.isSubmitted;

    if (isError) {
      return INPUT_STATUS.ERROR;
    }

    if (isValid) {
      return '';
    }
  };

  const clearField = (field) => {
    methods.resetField(field, { keepError: true });
    resetFieldStatus(field);
  };

  return children({
    methods,
    errors,
    resetFieldStatus,
    getFieldStatus,
    resetAllFieldsStatus,
    clearField
  });
}
