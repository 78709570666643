import { APPEND_ORDER_LIST, GET_ORDER_LIST, SET_QUERY_PARAM } from '../actions';
import { INITIAL_ORDER_STATE } from '../constants';

export const orderReducer = (state = INITIAL_ORDER_STATE, action = {}) => {
  const { type, payload = {} } = action;
  switch (type) {
    case GET_ORDER_LIST: {
      return {
        ...state,
        list: payload?.orders
      };
    }
    case APPEND_ORDER_LIST: {
      return {
        ...state,
        list: [...state.list, ...payload?.orders]
      };
    }
    case SET_QUERY_PARAM: {
      return {
        ...state,
        params: payload?.params
      };
    }
    default: {
      throw Error('Unknown action: ' + type);
    }
  }
};
