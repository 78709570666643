import { useQuery } from 'react-query';
import { getTotalOrderByArea } from '../services/dashboard-api';

export const useDeliveryDestinationQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: ['dashboard-DeliveryDestination'],
    queryFn: getTotalOrderByArea,
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled
  });
};
