import { useEffect, useRef } from 'react';
import { HStack, VStack, Text, Box, Flex, useBoolean } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { SlzButton } from 'components/common/SlzButton';
import { ControlledField, Field } from 'components/form';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import useSlzToast from 'hooks/useSlzToast';
import {
  PACKAGING_SETTING_HEADERS,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { useAddMailingBagMutation, useUpdateMailingBagMutation } from 'modules/preferences/hooks';
import { CustomErrorMessage, WrapField } from '../other';
import { SlzMeasuredInput } from 'components/common/SlzInput';

const PackageSettingLabel = ({ label, ...rest }) => {
  return (
    <Text fontSize="lg" lineHeight="1.313rem" fontWeight="bold" {...rest}>
      {label}
    </Text>
  );
};

const CustomMailingBagForm = ({ onBack }) => {
  const { forms } = useMultipleFormContext();
  const [_, setToast] = useSlzToast();
  const addMutation = useAddMailingBagMutation();
  const editMutation = useUpdateMailingBagMutation();
  const [isError, setIsError] = useBoolean(false);
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const timerRef = useRef();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag]: form } = forms;
  const methods = form?.form;

  const isAddingForm = routerQuery.get('id');
  const boxFormMutation = isAddingForm ? editMutation : addMutation;
  const isSubmittingMailingBag = addMutation.isLoading || editMutation.isLoading;

  const handleErrorMessage = (err) => {
    const errorMessage = err.response.data.message;

    if (errorMessage === PACKAGING_SETTING_HEADERS.MAILING_BAGS.error) {
      setIsError.on();
      methods?.setError('name', { type: 'custom', message: 'custom message' });
    } else {
      setToast({
        description: errorMessage || PACKAGING_SETTING_HEADERS.COMMON.error,
        status: 'warning',
        colorScheme: 'negative'
      });

      setIsError.off();
    }
  };

  const handleOnSubmit = async (data) => {
    try {
      await boxFormMutation.mutateAsync(data);

      if (isAddingForm) {
        setToast({
          description: PACKAGING_SETTING_HEADERS.COMMON.success
        });
      } else {
        setToast({
          description: `${methods?.getValues('name') || ''} ${
            PACKAGING_SETTING_HEADERS.MAILING_BAGS.success
          }`,
          chakraToastProps: {
            containerStyle: {
              maxWidth: '65.5rem !important'
            }
          }
        });
      }

      methods?.reset();
      timerRef.current = setTimeout(() => onBack(), 0);
    } catch (err) {
      handleErrorMessage(err);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
      routerQuery.delete('id');
      history.push({ search: routerQuery.toString() });
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(handleOnSubmit)}>
        <VStack alignItems="flex-start" spacing="1.688rem">
          <VStack alignItems="flex-start" w="90%">
            <PackageSettingLabel label="Mailing bag name" />
            <Field name="name" placeholder="Mailing bag name" isInlineError />
            {isError && (
              <Box width="full">
                <CustomErrorMessage
                  message={PACKAGING_SETTING_HEADERS.MAILING_BAGS.nameExistsError}
                  fontSize="0.875rem"
                  width="full"
                  mt="2.063rem"
                  height="3rem"
                  onClose={() => {
                    setIsError.off();
                  }}
                />
              </Box>
            )}
          </VStack>
          <VStack alignItems="flex-start" w="full">
            <PackageSettingLabel label="Dimensions (cm)" />
            <Flex w="full" gap={3}>
              <WrapField text="Minimum 10cm">
                <ControlledField name="width" unit="W" isInlineError component={SlzMeasuredInput} />
              </WrapField>

              <WrapField text="Minimum 10cm">
                <ControlledField
                  name="height"
                  unit="H"
                  isInlineError
                  component={SlzMeasuredInput}
                />
              </WrapField>
            </Flex>
          </VStack>
          <VStack alignItems="flex-start" w="full">
            <PackageSettingLabel label="Weight limit" />
            <Flex w="full" gap={3}>
              <WrapField text="Minimum 0.01Kg">
                <ControlledField
                  name="maxWeight"
                  unit="Kg"
                  alignment="right"
                  isInlineError
                  component={SlzMeasuredInput}
                />
              </WrapField>
            </Flex>
          </VStack>
        </VStack>

        <HStack justifyContent="space-between" mt={12} mb={6}>
          <SlzButton size="lg" variant="outline" colorScheme="negative" onClick={onBack}>
            Cancel
          </SlzButton>

          <SlzButton type="submit" size="lg" isDisabled={isSubmittingMailingBag}>
            Add custom bag
          </SlzButton>
        </HStack>
      </form>
    </FormProvider>
  );
};

export default CustomMailingBagForm;
