import { HStack } from '@chakra-ui/react';
import { AddUserIcon, HomeIcon, TruckIcon } from 'components/common/Icons';
import { SlzRadio } from 'components/common/SlzRadio';
import {
  Card,
  CardBody,
  CardFooter,
  CardIcon,
  CardPermissions,
  CardRoleBanner,
  CardRoleDescription,
  CardRolesAndPermissions
} from './CardRolesAndPermissions';
import { RETAILER_OWNER, RETAILER_USER, SUPPLIER } from './constants';
import { VERTICAL_CARDS_STYLES } from './styles';

const AddRolesAndPermsCard = () => {
  return (
    <HStack>
      <CardRolesAndPermissions styles={VERTICAL_CARDS_STYLES}>
        <Card spacing={0}>
          <SlzRadio pos="absolute" top="5px" right="10px" />
          <CardIcon>
            <HomeIcon w="95px" h="95px" color="main.500" />
          </CardIcon>
          <CardBody spacing={5}>
            <CardRoleBanner
              w="176px"
              h="30px"
              textAlign="center"
              fontSize="1.25rem"
              bg="szrDenim.700"
              color="lightAndShadow.reverseText">
              Retailer Owner
            </CardRoleBanner>
            <CardRoleDescription>{RETAILER_OWNER.ROLES}</CardRoleDescription>
          </CardBody>
          <CardFooter spacing="3px">
            <CardRoleBanner
              color="lightAndShadow.reverseText"
              w="218px"
              h="28px"
              textAlign="center"
              fontSize="12px"
              lineHeight="16px"
              bg="main.500">
              Retailer owner can access
            </CardRoleBanner>
            <CardPermissions minH="77px">{RETAILER_OWNER.PERMISSIONS}</CardPermissions>
          </CardFooter>
        </Card>
      </CardRolesAndPermissions>

      <CardRolesAndPermissions>
        <Card w="265px" minH="491px" spacing={0}>
          <SlzRadio pos="absolute" top="5px" right="10px" />
          <CardIcon w="145px" h="145px">
            <AddUserIcon w="95px" h="95px" color="main.500" />
          </CardIcon>
          <CardBody spacing={5} minH="134px">
            <CardRoleBanner
              w="176px"
              h="30px"
              textAlign="center"
              fontSize="1.25rem"
              bg="szrCerulean.700"
              color="lightAndShadow.reverseText">
              Retailer user
            </CardRoleBanner>
            <CardRoleDescription>{RETAILER_USER.ROLES}</CardRoleDescription>
          </CardBody>
          <CardFooter spacing="3px">
            <CardRoleBanner
              color="lightAndShadow.reverseText"
              w="218px"
              h="28px"
              textAlign="center"
              fontSize="12px"
              lineHeight="16px"
              bg="main.500">
              Retailer user can access
            </CardRoleBanner>
            <CardPermissions minH="77px">{RETAILER_USER.PERMISSIONS}</CardPermissions>
          </CardFooter>
        </Card>
      </CardRolesAndPermissions>

      <CardRolesAndPermissions>
        <Card w="265px" minH="491px" spacing={0}>
          <SlzRadio pos="absolute" top="5px" right="10px" />
          <CardIcon w="145px" h="145px">
            <TruckIcon w="95px" h="95px" color="#FD642E" />
          </CardIcon>
          <CardBody spacing={5} minH="134px">
            <CardRoleBanner
              w="120px"
              h="30px"
              textAlign="center"
              fontSize="1.25rem"
              bg="#FD642E"
              color="lightAndShadow.reverseText">
              Supplier
            </CardRoleBanner>
            <CardRoleDescription>{SUPPLIER.ROLES}</CardRoleDescription>
          </CardBody>
          <CardFooter spacing="3px">
            <CardRoleBanner
              color="lightAndShadow.reverseText"
              w="218px"
              h="28px"
              textAlign="center"
              fontSize="12px"
              lineHeight="16px"
              bg="main.500">
              Supplier can access
            </CardRoleBanner>
            <CardPermissions minH="77px">{SUPPLIER.PERMISSIONS}</CardPermissions>
          </CardFooter>
        </Card>
      </CardRolesAndPermissions>
    </HStack>
  );
};

export default AddRolesAndPermsCard;
