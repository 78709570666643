import { Icon } from '@chakra-ui/react';

const SingleProductIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="83.999"
      height="84.001"
      viewBox="0 0 83.999 84.001"
      fill="currentColor"
      {...props}>
      <g
        id="Single_product_consignment_icon"
        data-name="Single product consignment icon"
        transform="translate(-524 -529)">
        <path
          id="Rectangle_184"
          data-name="Rectangle 184"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(525 530)"
        />
        <path
          id="Rectangle_185"
          data-name="Rectangle 185"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(571.666 530)"
        />
        <path
          id="Rectangle_186"
          data-name="Rectangle 186"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(525 576.668)"
        />
        <path
          id="Rectangle_187"
          data-name="Rectangle 187"
          d="M8.333-1H27a9.344,9.344,0,0,1,9.333,9.333V27A9.344,9.344,0,0,1,27,36.333H8.333A9.344,9.344,0,0,1-1,27V8.333A9.344,9.344,0,0,1,8.333-1ZM27,27V8.333H8.327L8.333,27Z"
          transform="translate(571.666 576.668)"
        />
      </g>
    </Icon>
  );
};

export default SingleProductIcon;
