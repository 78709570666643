const FLEX_STYLES = {
  display: 'flex',
  alignItems: 'center'
};

const SELECT_DEFAULT_STYLES = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    background: 'dark.500',
    opacity: 1,
    mr: 0,
    '> svg': {
      color: 'white'
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    background: 'light.500',
    h: 5,
    w: 5,
    borderRadius: 2.5,
    m: 0,
    '&:hover': {
      background: 'light.700'
    },
    '> svg': {
      p: 0.25
    }
  }),
  menuList: (provided) => ({
    ...provided,
    p: 0.75
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 'dropdown',
    '> div': {
      '> div': {
        ...FLEX_STYLES,
        m: 0,
        p: 0,
        minHeight: 7.5,
        // fontSize: 'md',
        '.chakra-checkbox__control': { mt: 0, ml: 1 },
        wordWrap: 'break-word',
        '&:hover': {
          background: 'main.300'
        },
        ' > div:has(input[type=checkbox]:checked)': {
          background: 'light.500',
          color: 'dark.700',
          '&:hover': {
            background: 'main.300'
          }
        }
      }
    }
  })
};

export const SELECT_STYLES = {
  ...SELECT_DEFAULT_STYLES,
  indicatorsContainer: (provided) => ({
    ...provided,
    '> hr + div': {
      p: '5px'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 'md',
    mx: 0
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 'md',
    color: 'lightAndShadow.mainText'
  }),
  container: (provided, state) => ({
    ...provided,
    '> div + div': {
      borderRadius: 0.75,
      boxShadow: 'slz-2md',
      mt: 0.75
    },
    borderRadius: 0.75,
    bgColor: state.isDisabled ? 'light.500' : 'light.300'
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid',
    borderColor: state.isDisabled ? 'light.500' : 'dark.300',
    borderRadius: 0.75,
    fontSize: 'md',
    '> div:first-child': {
      px: 3,
      py: 0
    },
    '&:hover': {
      borderColor: 'main.500'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    borderColor: 'dark.300',
    borderStyle: 'solid',
    borderWidth: 0.25,
    background: 'light.500',
    py: 0,
    px: 1.5,
    minHeight: 4,
    wordWrap: 'break-word',
    '&:hover': {
      borderColor: 'main.500',
      '> div': {
        background: 'main.500'
      }
    },
    mt: 0.5,
    mb: 0,
    mr: 1.5,
    ml: 0
  })
};

export const SELECT_CREATABLE_STYLES = {
  ...SELECT_STYLES,
  indicatorsContainer: (provided) => ({
    ...provided,
    p: 0,
    '> hr + div': {
      p: 0
    }
  }),
  menu: (...args) => ({
    ...SELECT_STYLES.menu(...args),
    '> div > div': {
      fontSize: 'md'
    }
  }),
  control: (...args) => ({
    ...SELECT_STYLES.control(...args),
    flexDir: 'row-reverse',
    minW: 16,
    '>div': {
      ':first-child': {
        p: 0
      },
      '>div:first-child': { textAlign: 'right', pr: 2 }
    }
  })
};

export const DROPDOWN_TEST_ID = 'slz-dropdown-control';
