import React from 'react';
import { Flex, FormControl } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import FormLabel from './FormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzButton } from 'components/common/SlzButton';

export default function PasswordConfirmationIntegrationForm({ onSubmit }) {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)}>
        <FormControl mb="5">
          <FormLabel>Password</FormLabel>
          <SlzInput type="password" {...methods.register('password')} showIcon={false} />
        </FormControl>

        <Flex mb={5} mt={10} justifyContent="end">
          <SlzButton type="submit" size="lg">
            Manage
          </SlzButton>
        </Flex>
      </form>
    </FormProvider>
  );
}
