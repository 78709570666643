import classNames from 'classnames';
import * as dates from './dates';
import { DATE_DISPLAY_FORMAT_YYYYMMDD } from '../constants';
import dayjs from 'dayjs';
export default function cx(props) {
  const {
    day,
    calendar,
    startDate,
    endDate,
    range,
    maxDate,
    minDate,
    isShowFuture,
    disableChoosingPrev
  } = props;
  const weekend = dates.isWeekend(day);
  const inRange = dates.isBetweenExclusive(startDate, endDate, day);
  const isEndDate =
    endDate &&
    day.format(DATE_DISPLAY_FORMAT_YYYYMMDD) === endDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD);
  const isStartDate =
    startDate &&
    day.format(DATE_DISPLAY_FORMAT_YYYYMMDD) === startDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD);

  let activeHover = true;
  let disabled = false;
  if (disableChoosingPrev) {
    activeHover = datesActive(startDate, endDate, isShowFuture, range); // TODO: LAMNV change active
    disabled = dates.isNotBetween(minDate, maxDate, day); // TODO: LAMNV change active
  }
  const active = isStartDate || isEndDate;
  const off = calendar.month() !== day.month();
  const available = !disabled;
  return classNames({
    weekend,
    only: !off && ((props.startDate && !props.endDate) || (!props.startDate && props.endDate)),
    'in-range': !off && inRange,
    'active-hover': activeHover && !off,
    'in-range-active': !off && (inRange || (isStartDate && !isEndDate)),
    'end-date': isEndDate,
    'end-date-left': isEndDate && startDate > endDate,
    'start-date': isStartDate,
    'start-date-right': isStartDate && endDate && startDate > endDate,
    'out-range': !isShowFuture && day >= dayjs(),
    active,
    off,
    disabled,
    available
  });
}

export const datesActive = (startDate, endDate, isShowFuture, range) => {
  if (startDate && endDate) {
    return false;
  } else {
    if (!startDate) {
      return isShowFuture ? true : range <= dayjs();
    } else {
      if (!endDate) {
        return isShowFuture ? range > startDate : range > startDate && range <= dayjs();
      } else {
        return isShowFuture
          ? range > startDate && range < endDate
          : range > startDate && range < endDate && range <= dayjs();
      }
    }
  }
};

export const outRangeDate = (day, isShowFuture) => {
  return !isShowFuture && day > dayjs();
};
