import { Fragment } from 'react';
import map from 'lodash/map';

import { DEFAULT_PAGING_OPTIONS, PAGE_INDEX } from 'constants/table.js';
import SlzTable from 'components/common/SlzTable/Table';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

import { INVOICE_COLS } from 'modules/billing/components/table/billing.columns';

const InvoiceTable = (props) => {
  const {
    data,
    queryParams,
    invoiceTypes,
    totalPage,
    selectedInvoiceTypes,
    handleItemPerPageChange,
    handleCurrentPageChange,
    handleFilterByInvoiceTypeChange,
    handleOpenModal
  } = props;
  const routerQuery = useRouterQuery();

  INVOICE_COLS[0].isClickable = true;
  INVOICE_COLS[0].onClick = (row) => {
    handleOpenModal(row);
  };

  return (
    <Fragment>
      <SlzTable
        isLoading={props?.isLoading}
        variant="billingInvoice"
        sizes={['sm', 'md', 'lg']}
        showMarked={true}
        showHeader={true}
        columns={INVOICE_COLS}
        data={data}
        onRefetching={props?.onRefetching}
        isEmptyPage={props?.isEmptyPage}
        messageToast={props?.messageToast}
        displayFields={map(INVOICE_COLS, 'field')}
        filters={{
          title: 'Invoice types',
          byField: 'type',
          options: invoiceTypes,
          isLocal: false,
          defaultSelectedValues: selectedInvoiceTypes,
          handleSelectedFilter: (invoiceType) => handleFilterByInvoiceTypeChange(invoiceType)
        }}
        filterByDate={{
          field: 'issueDate',
          start: queryParams?.startDate,
          end: queryParams?.endDate
        }}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => handleItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: queryParams?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage: totalPage,
            currentPage: parseInt(routerQuery.get(PAGE_INDEX)),
            isGreyBg: true,
            onChange: (currentPage) => handleCurrentPageChange(currentPage)
          }
        }}
      />
    </Fragment>
  );
};

export default InvoiceTable;
