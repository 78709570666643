import { Box, FormControl, HStack, Text, VStack } from '@chakra-ui/react';

import { FormLabel, Tag } from 'modules/b2b-orders/components/form';
import { B2B_PRODUCT_TRACKING, B2B_PRODUCT_TRACKING_TAGS } from 'modules/b2b-orders/constants';
import { Tooltip } from 'modules/b2b-orders/components/tooltip';

const B2BProductTracking = ({
  selectedProducts,
  step,
  boxesLength,
  isShowTooltip = true,
  ...rest
}) => {
  return (
    <HStack spacing="0.75rem" mb={1} justifyContent="space-between" {...rest}>
      <VStack alignItems="start">
        <Box display="flex" gap="0.313rem">
          <Text fontSize="1.25rem" fontWeight="bold" lineHeight="1.625rem">
            {B2B_PRODUCT_TRACKING[step].title}
          </Text>
          {isShowTooltip && (
            <Tooltip
              placement="right"
              trigger="hover"
              content={<Text fontSize="md">Product Selection</Text>}
            />
          )}
        </Box>
        {B2B_PRODUCT_TRACKING[step].description}
      </VStack>
      <HStack>
        {B2B_PRODUCT_TRACKING_TAGS[step].map((trackingTag) => (
          <FormControl key={trackingTag.label} flexBasis="10%">
            <FormLabel>{trackingTag.label}</FormLabel>
            <Tag>{trackingTag.tag(selectedProducts, boxesLength)}</Tag>
          </FormControl>
        ))}
      </HStack>
    </HStack>
  );
};

export default B2BProductTracking;
