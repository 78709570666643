import React from 'react';

import { VStack } from '@chakra-ui/react';

import SlzLink from 'components/common/SlzLink/SlzLink';

const CompanyInfo = ({ email, telephone }) => {
  const mailto = `mailto:${email}`;
  const tel = `tel:${telephone}`;

  return (
    <VStack spacing={0} alignItems="end">
      <SlzLink href={mailto} color="main.500" fontWeight="medium">
        {email}
      </SlzLink>
      <SlzLink href={tel} fontWeight="medium" color="dark.500">
        {telephone}
      </SlzLink>
    </VStack>
  );
};

export default CompanyInfo;
