import React from 'react';

import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, FormControl } from '@chakra-ui/react';

import { SlzSingleButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';

const GeneralTrackingField = ({ index, append, register, disabled, length }) => {
  return (
    <Flex gap="12px">
      <FormControl flexBasis="29.15%">
        <SlzInput
          name="trackingNumber"
          placeholder="Tracking number"
          w="239px"
          {...register(`generalTracking.${index}.trackingNumber`)}
          disabled={disabled}
          sx={{ border: '1px solid #C3C4CB !important', bg: '#FAFAFA' }}
        />
      </FormControl>

      <FormControl flexBasis="19.15%">
        <SlzInput
          name="courierProvider"
          placeholder="Courier"
          w="156px"
          {...register(`generalTracking.${index}.courierProvider`)}
          disabled={disabled}
          sx={{ border: '1px solid #C3C4CB !important', bg: '#FAFAFA' }}
        />
      </FormControl>

      <FormControl flexBasis="18.85%">
        <Box flex="1">
          {index === length - 1 && (
            <SlzSingleButton
              icon={<AddIcon />}
              size="lg"
              onClick={() => append({ trackingNumber: '', courierProvider: '' })}
            />
          )}
        </Box>
      </FormControl>
    </Flex>
  );
};

export default GeneralTrackingField;
