import { HStack, Text } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';
import { Tooltip } from '../tooltip';
import { PaginatedTooltipContent } from 'components/common/SlzToolTip/templates';
import { B2B_COURIER_OPTIONS_TOOLTIP_CONTENT } from 'modules/b2b-orders/constants';

const B2BHeaderShipping = ({ title, btnName, tooltip, ...rest }) => {
  return (
    <HStack justifyContent="space-between" alignItems="end" my="0.813rem" {...rest}>
      <Text fontWeight="bold" fontSize="xl" lineHeight="tall">
        {title}
      </Text>
      {!tooltip && btnName && <SlzButton borderRadius={6}>{btnName}</SlzButton>}
      {tooltip && (
        <Tooltip
          placement="bottom"
          trigger="click"
          content={
            <PaginatedTooltipContent tooltipContent={B2B_COURIER_OPTIONS_TOOLTIP_CONTENT} />
          }>
          <HStack maxHeight="1.25rem !important" my={1.25}>
            <SlzButton borderRadius={6}>{btnName}</SlzButton>
          </HStack>
        </Tooltip>
      )}
    </HStack>
  );
};

export default B2BHeaderShipping;
