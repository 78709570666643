import { Fragment, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFormContext } from 'react-hook-form';
import { Box, VStack } from '@chakra-ui/react';

import { B2BOrderAction, B2BProductTracking } from '.';
import { BoxHiddenScroll } from 'components/layout';
import { BOX_SPLIT_SUMMARY_STYLE } from 'modules/b2b-orders/constants/styles';
import SlzTable from 'components/common/SlzTable/Table';
import {
  BOX_SPLIT_SUMMARY_NONE_PARTIAL_COLUMNS,
  BOX_SPLIT_SUMMARY_PARTIAL_COLUMNS
} from 'modules/b2b-orders/constants/b2b-summaries-orders-columns';
import useValidateSkuSplitMutation from 'modules/b2b-orders/hooks/useValidateSkuSplitMutation';
import { getSeperateSkuSummaryData } from 'modules/b2b-orders/utils';
import { BOX_SPLIT } from 'modules/b2b-orders/constants';

const BoxSplitSummary = ({ step, defaultValues, onNext, ...rest }) => {
  const [boxes, setBoxes] = useState([]);
  const { setValue } = useFormContext();
  const validateSkuSplitMutation = useValidateSkuSplitMutation();
  const boxSplitData = getSeperateSkuSummaryData(boxes, defaultValues.stockSelection);

  const validateSkuSplit = async () => {
    const payload = {
      isAutoSplit: true,
      selectedItems: defaultValues.stockSelection
    };

    if (defaultValues.preferences.requireSeperateSKUs) {
      payload.isAutoSplit = false;
    }
    try {
      const response = await validateSkuSplitMutation.mutateAsync(payload);
      setBoxes(response?.boxes);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!defaultValues) return;
    validateSkuSplit();
  }, [defaultValues]);

  useEffect(() => {
    if (isEmpty(boxes)) return;
    setValue(BOX_SPLIT, [{ boxes }]);
  }, [boxes]);

  return (
    <Fragment>
      <VStack w="full" h="full" alignItems="flex-start" spacing={3}>
        <B2BProductTracking
          step={step}
          selectedProducts={defaultValues?.stockSelection}
          boxesLength={boxes.length}
          isShowTooltip={false}
          w="full"
          alignItems="center"
        />

        <BoxHiddenScroll maxH="41.938rem" w="full">
          <VStack w="full" alignItems="flex-start" spacing={6}>
            <Box w="full">
              {boxSplitData && boxSplitData.isHavePartialBox && (
                <SlzTable
                  sx={BOX_SPLIT_SUMMARY_STYLE.summaryTable}
                  showBoxShadow={false}
                  variant="selectedBundle"
                  sizes={['sm', 'md', 'lg']}
                  showMarked={false}
                  showHeader={true}
                  showToolbar={false}
                  autoResize={false}
                  columns={BOX_SPLIT_SUMMARY_PARTIAL_COLUMNS}
                  data={boxSplitData.rows}
                  displayFields={BOX_SPLIT_SUMMARY_PARTIAL_COLUMNS?.map((col) => col.field)}
                />
              )}
              {boxSplitData && !boxSplitData.isHavePartialBox && (
                <SlzTable
                  sx={BOX_SPLIT_SUMMARY_STYLE.summaryTable}
                  showBoxShadow={false}
                  variant="selectedBundle"
                  sizes={['sm', 'md', 'lg']}
                  showMarked={false}
                  showHeader={true}
                  showToolbar={false}
                  autoResize={false}
                  columns={BOX_SPLIT_SUMMARY_NONE_PARTIAL_COLUMNS}
                  data={boxSplitData.rows}
                  displayFields={BOX_SPLIT_SUMMARY_NONE_PARTIAL_COLUMNS?.map((col) => col.field)}
                />
              )}
            </Box>
          </VStack>
        </BoxHiddenScroll>
      </VStack>
      <B2BOrderAction {...rest} />
    </Fragment>
  );
};

export default BoxSplitSummary;
