import {
  Box,
  Card,
  HStack,
  Popover,
  PopoverTrigger,
  useRadio,
  useRadioGroup,
  useStyleConfig
} from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import React from 'react';
import { SEL_PREFIX_ID } from '../Constants';

export const SlzRadioToggle = React.forwardRef((props, ref) => {
  const {
    options,
    name,
    defaultValue,
    onChange,
    size,
    isPopOverOpen,
    selectedToggleOption,
    setSelectedToggleOption,
    handlePopOverClose,
    PopOverContent={},
    readOnly = false,
    showPopOver = false,
    ...rest
  } = props;
  const { px, _checked, _focus, h, borderRadius } = useStyleConfig('SlzRadioToggle', { size });

  function RadioCard(props) {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label" width={props.width} h={h} display="flex" alignItems="center" margin="0">
        <input {...input} disabled={readOnly} />
        {props.index === options.length - 1 && showPopOver ? (
          <Popover
            returnFocusOnClose={false}
            isOpen={isPopOverOpen}
            onClose={handlePopOverClose}
            placement="right"
            direction="ltr"
            arrowSize={40}
            closeOnBlur={false}
            modifiers={[
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: ({ reference }) => [0, 35]
                }
              }
            ]}>
            <PopoverTrigger>
              <Box
                w="full"
                {...checkbox}
                cursor={readOnly ? 'not-allowed' : 'pointer'}
                textAlign={'center'}
                _checked={{ ..._checked }}
                _hover={{ ..._focus }}
                borderRadius={borderRadius}
                px={px}>
                {props.children}
              </Box>
            </PopoverTrigger>
            <PopOverContent />
          </Popover>
        ) : (
          <Box
            w="full"
            {...checkbox}
            cursor={readOnly ? 'not-allowed' : 'pointer'}
            textAlign={'center'}
            _checked={{ ..._checked }}
            _hover={{ ..._focus }}
            borderRadius={borderRadius}
            px={px}>
            {props.children}
          </Box>
        )}
      </Box>
    );
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: defaultValue,
    value: defaultValue,
    onChange: readOnly ? undefined : onChange
  });

  const group = getRootProps();

  return (
    <Card
      bg="light.500"
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Radio-Toggle`)}
      {...rest}
      borderRadius={borderRadius}>
      <HStack {...group} mx={'0.375rem'}>
        {options.map((item, index) => {
          const radio = getRadioProps({ value: item.key });
          return (
            <RadioCard
              ref={ref}
              key={item.key}
              {...radio}
              width={`${100 / options.length}%`}
              index={index}>
              {item.value}
            </RadioCard>
          );
        })}
      </HStack>
    </Card>
  );
});
