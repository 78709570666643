import { DragHandleIcon } from '@chakra-ui/icons';
import SlzTable from '../../components/common/SlzTable/Table';
import ProductTable from './ProductTable';
import { Box, Stack, Text } from '@chakra-ui/react';
import CategoryTable from './CategoryTable';
import SuggestionTable from './SuggestionTable';

const Tables = () => {
  const columns = [
    {
      name: 'Name',
      field: 'name',
      isSorted: true
    },
    {
      name: 'Age',
      field: 'age',
      isSorted: true
    },
    {
      field: 'email',
      name: 'Email'
    },
    {
      field: 'address',
      name: 'Address'
    }
  ];

  const rows = [
    {
      id: 123,
      name: 'John',
      age: 125,
      email: 'tesla@email.com',
      address: 'Hoan Kiem'
    },
    {
      id: 22,
      name: 'Adam',
      age: 15,
      email: 'tesla@email.com',
      address: 'Hoan Kiem'
    },
    {
      id: 3,
      name: 'Haley',
      age: 35,
      email: 'tesla@email.com',
      address: 'Hoan Kiem'
    },
    {
      id: 4,
      name: 'Haley',
      age: 35,
      email: 'tesla@email.com',
      address: 'Hoan Kiem'
    },
    {
      id: 5,
      name: 'Haley Test',
      age: 20,
      email: 'tesla@email.com',
      address: 'Hoan Kiem'
    }
  ];

  return (
    <Stack spacing={5} my={5}>
      <Box>
        <Text as="h1" fontSize="2xl" fontWeight={600}>
          New Table
        </Text>

        <SlzTable
          variant="simple"
          sizes={['sm', 'md', 'lg']}
          columns={columns}
          data={rows}
          showToolbar={false}
          displayFields={['name', 'age', 'email', 'address']}
          hyperLinks={['product_name']}
          actions={null}
        />
      </Box>

      <Box>
        <Text as="h1" fontSize="2xl" fontWeight={600}>
          Product Table
        </Text>
        <ProductTable />
      </Box>

      <Box>
        <Text as="h1" fontSize="2xl" fontWeight={600}>
          Category Table
        </Text>
        <CategoryTable />
      </Box>
      {/* 
      <Box>
        <Text as="h1" fontSize="2xl" fontWeight={600}>
          Suggestion Table
        </Text>
        <SuggestionTable />
      </Box> */}
    </Stack>
  );
};

export default Tables;
