import { Flex, Text, VStack } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { RETURN_DETAIL_ORDER_INFOR_STYLE } from 'modules/returns/constants/styles';
import { RETURN_DETAIL_TEXT } from 'modules/returns/constants';

const ReturnDetailOrderInformation = ({ isLoading, data, ...rest }) => {
  return (
    <SlzPaper w="full" h={48} bg="light.500" borderRadius={1.5} {...rest}>
      {isLoading ? (
        <Skeleton width="full" height="full" />
      ) : (
        <VStack w="full" h="full" alignItems="flex-start">
          <Flex {...RETURN_DETAIL_ORDER_INFOR_STYLE.headerInfor}>
            {RETURN_DETAIL_TEXT.orderInfor.header}
          </Flex>
          <VStack {...RETURN_DETAIL_ORDER_INFOR_STYLE.detailInfor}>
            <Text>
              {RETURN_DETAIL_TEXT.orderInfor.orderId} {data?.orderReference}{' '}
            </Text>
            <Text>{RETURN_DETAIL_TEXT.orderInfor.fulfilment} Nuneaton</Text>
            <Text>
              {RETURN_DETAIL_TEXT.orderInfor.customerName} {data?.customerName}
            </Text>
          </VStack>
        </VStack>
      )}
    </SlzPaper>
  );
};

export default ReturnDetailOrderInformation;
