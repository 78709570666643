import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useStyleConfig,
  PopoverArrow
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { default as SlzButtonGroup } from './SlzButtonGroup';
import './SplitButton.scss';

const SlzSplitButton = (props) => {
  const {
    items,
    size,
    leftIcon,
    colorScheme,
    onlyShowPlaceholder = false,
    defaultValue,
    onSelected,
    onClick,
    placeholder,
    hasArrow = false,
    type,
    ...rest
  } = props;
  const [selectedItem, setSelectedItem] = useState();
  const style = useStyleConfig('SlzSplitButton', { size, colorScheme });

  const handleSelectedItem = (item, onClose) => {
    setSelectedItem(item);
    onSelected(item);
    onClose();
  };

  const getClassName = (item) => {
    const isSelected = selectedItem?.text === item.text;
    const isDisabled = item.isDisabled;

    if (isSelected) {
      return 'split-button-item-selected';
    }

    if (isDisabled) {
      return 'split-button-item-disabled';
    }

    return '';
  };

  const getSelectedText = () => {
    if (onlyShowPlaceholder) {
      return placeholder;
    }

    if (selectedItem) {
      return selectedItem.text;
    }

    const defaultItem = items.find((item) => item.value === defaultValue);

    return defaultItem ? defaultItem?.text : placeholder;
  };

  return (
    <Box className="split-button" data-testid="slz-split-button">
      <Popover placement="bottom-end" gutter={hasArrow ? 6 : 3}>
        {({ isOpen, onClose }) => (
          <SlzButtonGroup size={size} colorScheme={colorScheme} {...rest} pos="relative">
            {leftIcon ? (
              <IconButton
                type={type}
                onClick={onClick}
                icon={leftIcon}
                borderLeftRadius="0.375rem"
              />
            ) : (
              <Button type={type} onClick={onClick} {...rest}>
                {getSelectedText()}
              </Button>
            )}
            {items?.length > 0 && (
              <>
                <PopoverTrigger>
                  <IconButton
                    colorScheme={colorScheme}
                    borderRightRadius={leftIcon ? 1.5 : ''}
                    sx={isOpen ? style.rightButtonIcon : ''}
                    size={size}
                    icon={
                      <ChevronDownIcon
                        sx={style.rightIcon}
                        transitionDuration="0.3s"
                        transform={`rotate(${isOpen ? 180 : 0}deg)`}
                        color={isOpen && 'dark.700'}
                      />
                    }
                    {...rest}
                  />
                </PopoverTrigger>
                <PopoverContent sx={style} w="full">
                  {hasArrow && <PopoverArrow />}
                  <PopoverBody p={0} w="full" data-testid="slz-split-button-child">
                    {items.map((item) => (
                      <Box
                        key={item.value}
                        onClick={() => handleSelectedItem(item, onClose)}
                        sx={style?.itemSelect}
                        className={getClassName(item)}>
                        {item.text}
                      </Box>
                    ))}
                  </PopoverBody>
                </PopoverContent>{' '}
              </>
            )}
          </SlzButtonGroup>
        )}
      </Popover>
    </Box>
  );
};
export default SlzSplitButton;
