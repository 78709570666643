import { Card, HStack, Text, VStack, Box, FormErrorMessage, FormControl } from '@chakra-ui/react';
import SlzUpload from 'components/common/SlzUpload/SlzUpload';
import { TagIcon } from '../assets';
import { SlzChip } from 'components/common/SlzChip';
import { SlzButton } from 'components/common/SlzButton';
import { DownloadIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import {
  ORDER_EXAMPLE_CSV_FILE,
  ORDER_CSV_INSTRUCTIONS_FILE,
  INPUT_STATUS
} from 'components/common/Constants';
import { CsvUpload } from 'components/common/Icons';
import { v4 as uuid } from 'uuid';
import Files from './Files';
import { useFormContext } from 'react-hook-form';
import { Fragment, useEffect } from 'react';
import colors from 'theme/newdesign/foundations/colors';
import SlzInput from 'components/common/SlzInput/SlzInput';
import InventorySkeleton from 'components/common/Skeleton/templates/inventory';
import Warehouse from '../Warehouse';

const MultipleConsignmentUpload = ({
  warehousesData,
  setCsvFiles,
  csvFiles,
  selectedConsignmentType,
  currentType
}) => {
  const {
    setValue,
    register,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const onLoadFile = (file, reader) => {
    clearErrors('csvFiles');
    setCsvFiles((oldList) => [
      ...oldList,
      {
        id: uuid(),
        name: file.name,
        text: reader.result,
        file
      }
    ]);
  };

  useEffect(() => {
    setValue('csvFiles', csvFiles);
  }, [csvFiles]);

  const UploadCSVForm = (
    <VStack
      mb={2}
      p={5}
      sx={{
        background: 'light.300',
        borderWidth: '2px',
        borderStyle: 'dashed',
        borderColor: errors?.csvFiles ? colors.red[500] : 'dark.300',
        borderRadius: 3
      }}
      textAlign="center"
      justifyContent="center">
      <Box sx={{ py: 3 }}>
        <CsvUpload color="dark.500" />
      </Box>
      <Text
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '0px',
          color: 'dark.500',
          textAlign: 'center'
        }}
        mb={3}>
        Drag and drop your CSV file here <Box as="br"></Box> or
      </Text>
      <SlzButton h="40px" w="100px">
        Browse
      </SlzButton>
    </VStack>
  );

  return (
    <InventorySkeleton
      isLoaded
      type={selectedConsignmentType}
      template={`inventory.${currentType}`}>
      <VStack w="51.625rem" mb={7} alignItems="left">
        <HStack w="full" mb="-8px">
          <Warehouse warehousesData={warehousesData} currentType={currentType} />
        </HStack>
        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Text
              sx={{
                textAlign: 'left',
                lineHeight: '20px',
                letterSpacing: '-0.14px',
                fontSize: '16px',
                color: 'table.mainText'
              }}>
              <b>Repeat Consignments</b>
            </Text>
          </HStack>
        </Card>
        <Card
          bg="light.500"
          mt="2rem"
          w="100%"
          h="4.5rem"
          px="1.5rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <FormControl isInvalid={errors?.repeatConsignments}>
            <SlzInput
              value={null}
              type={'number'}
              min={0}
              {...register('repeatConsignments')}
              placeholder={
                errors?.repeatConsignments?.message ??
                'Choose the quantity of consignments you are sending in with the same configuration that is in your CSV'
              }
              fontSize={'15px'}
              sx={
                errors?.repeatConsignments ? { borderColor: `${colors.red[500]} !important` } : {}
              }
            />
          </FormControl>
        </Card>

        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Text
              sx={{
                textAlign: 'left',
                lineHeight: '20px',
                letterSpacing: '-0.14px',
                fontSize: '16px',
                color: 'table.mainText'
              }}>
              <b>CSV Upload</b>
            </Text>
          </HStack>
        </Card>
        <Card
          bg="light.500"
          mt="2rem"
          w="100%"
          h="100%"
          px="1.5rem"
          variant="filled"
          borderWidth={'1px'}
          borderColor={errors?.csvFiles ? colors.red[500] : ''}
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <VStack mb={7} mt={7} alignItems="centre">
            <HStack>
              <SlzChip size="xs" bg="#CCE5FF" color="#0973EA" h={5} lineHeight="1rem" ml={1}>
                Note
              </SlzChip>
              <Text fontSize="sm" w="23rem">
                <b>
                  Please use the following CSV example and Documentation to learn how to send us a
                  box or a pallet as a stock consignment to us.
                </b>
              </Text>
              <Link to={ORDER_CSV_INSTRUCTIONS_FILE} target="_blank" download>
                <SlzButton>
                  CSV Instructions <DownloadIcon />
                </SlzButton>
              </Link>
              <Link to={ORDER_EXAMPLE_CSV_FILE} target="_blank" download>
                <SlzButton>
                  CSV Example <DownloadIcon />
                </SlzButton>
              </Link>
            </HStack>
            <FormControl isInvalid={errors?.csvFiles}>
              <Fragment>
                <SlzUpload
                  mb={5}
                  uploadForm={UploadCSVForm}
                  onLoadFile={onLoadFile}
                  acceptType=".csv"
                />
                {csvFiles.length > 0 && <Files csvFiles={csvFiles} setCsvFiles={setCsvFiles} />}
              </Fragment>
              <FormErrorMessage>{errors?.csvFiles?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
        </Card>
      </VStack>
    </InventorySkeleton>
  );
};

export default MultipleConsignmentUpload;
