import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Text, HStack } from '@chakra-ui/react';
import { SlzTag } from 'components/common/SlzTag';
import useSlzToast from 'hooks/useSlzToast';
import { BoxHiddenScroll } from 'components/layout';

import {
  useGetAllWarehousesQuery,
  useGetSelectedWarehouseQuery,
  useAddWarehouseMutation
} from 'modules/preferences/hooks';

import { WAREHOUSE_SETTING_CONTENT } from 'modules/preferences/constants';
import { WAREHOUSE_DETAILS } from 'components/common/Constants';
import { ConfirmSelectedWarehouse, WarehouseCard } from 'modules/preferences/components/other';

const warehousePayload = {
    id: '',
    name:'',
    existingCustomer: false,
    existingCustomerReference: ''
}

const SELECTED_STYLES = {
  wrapper: {
    width: '15rem',
    marginRight: '0.75rem !important',
    marginLeft: '0rem !important',
    marginTop: '0.75rem !important'
  }
}

const WarehouseSetting = ({ updateRequiredSettings }) => {
  const { data: allWarehouses, isFetched: isWarehousesFetched } = useGetAllWarehousesQuery();
  const { data: selectedWarehouse, refetch: refetchSelectedWarehouse } =
    useGetSelectedWarehouseQuery();
  const useSelectWarehouse = useAddWarehouseMutation();
  const [_, setToast] = useSlzToast();
  
  const methods = useForm({
    warehousePayload
  });
  const errors = methods.formState.errors;

  const handleSubmitWarehouse = (warehouse) => {
    setToast({
      ...WAREHOUSE_SETTING_CONTENT.toast.confirm,
      render: () => (
        <ConfirmSelectedWarehouse
          warehouse={warehouse}
          updateRequiredSettings={updateRequiredSettings}
          refetchSelectedWarehouse={refetchSelectedWarehouse}
          useSelectWarehouse={useSelectWarehouse}
        />
      )
    });
  }
  
  const handleSetWarehouseDetails = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    
    for (let [key, value] of formData.entries()){
      console.log(key, value);
      switch (key) {
        case (WAREHOUSE_DETAILS.WAREHOUSE_ID):
          warehousePayload.id = value; 
          break;
        case (WAREHOUSE_DETAILS.WAREHOUSE_NAME):
          warehousePayload.name = value;
          break;
        case (WAREHOUSE_DETAILS.EXISTING_CUST):
          warehousePayload.existingCustomer = Boolean(value);
          break;
        case (WAREHOUSE_DETAILS.CUSTOMER_REFERENCE):
          warehousePayload.existingCustomerReference = value;
          break;
      }
    }
    
    // if existingCustomer is checked then they must enter a customer reference
    let formValid = true;
    if (warehousePayload.existingCustomer && warehousePayload.existingCustomerReference.trim().length === 0)
        formValid = false;

    // if form is valid, created payload and submit WarehouseSelection, otherwise show an error toast
    if (!formValid){
      setToast({
        ...WAREHOUSE_SETTING_CONTENT.check
        });
    } else {
      handleSubmitWarehouse(warehousePayload);
    }   
  };

  return (
    <>
      <Text fontSize="0.875rem">{WAREHOUSE_SETTING_CONTENT.description.first}</Text>
      <Text fontSize="0.875rem">{WAREHOUSE_SETTING_CONTENT.description.second}</Text>
      <HStack fontSize="0.875rem" mt="0.5rem" mb="12px">
        <SlzTag size="sm">{WAREHOUSE_SETTING_CONTENT.note.tag}</SlzTag>
        <Text>{WAREHOUSE_SETTING_CONTENT.note.text}</Text>
      </HStack>
      <BoxHiddenScroll maxH="35.375rem" w="full">
        <HStack alignItems="flex-start" flexWrap="wrap" ml="0.25rem">
          {isWarehousesFetched &&
            allWarehouses.map((warehouse) => (
             <FormProvider {...methods}>
                <form onSubmit={handleSetWarehouseDetails}>   
                  <WarehouseCard
                    {...SELECTED_STYLES.wrapper}
                    key={warehouse?.id}
                    isPostMVP={false}
                    showStatus={false}
                    selectedWarehouse={selectedWarehouse?.id}
                    warehouse={{ ...warehouse, state: 'available' }}>                   
                  </WarehouseCard>
                </form>
              </FormProvider>
            ))}
        </HStack>
      </BoxHiddenScroll>
    </>
  );
};

export default WarehouseSetting;
