import { Fragment, useEffect, useState } from 'react';
import { Box, Flex, HStack, Text, useToken } from '@chakra-ui/react';
import { Controller, FormProvider, useFieldArray } from 'react-hook-form';

import SetupRequirement from 'components/common/SetupRequirement';
import { SlzToggle } from 'components/common/SlzToggle';
import { BoxHiddenScroll } from 'components/layout';
import { SETTING_NAVIGATE_MENU_STATUS, SETTING_STATUS } from 'constants/common';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import useSlzToast from 'hooks/useSlzToast';

import {
  COURIER_SERVICE_TOOLTIP_CONTENT,
  COURIER_SERVICE_TOOLTIP_CONTENTS,
  EU,
  ROW,
  ROW_COURIER_SETTING_TEXT,
  ROW_TEXT,
  ROW_WARNING_TEXT,
  SFP_TEXT,
  STYLE_WARNING_TOAST,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { useCourierSettings } from 'modules/preferences/hooks';
import {
  transformCourierSettings,
  transformIsSFP,
  transformServiceTimes
} from 'modules/preferences/utils';
import { CourierCard } from 'modules/preferences/components/other';
import PreferenceSkeleton from 'components/common/Skeleton/templates/preference';
import { PreferenceHeader as CourierSettingHeader } from './common';
import { Tooltip } from './tooltip';
import { PaginatedTooltipContent } from 'components/common/SlzToolTip/templates';

const HeaderCourierSetting = ({ isSFP, country, onToggleEU, onToggleROW }) => {
  const isEU = country === EU;
  const isROW = country === ROW;

  const handleToggleEU = (event) => {
    onToggleEU(!event.target.checked);
  };

  const handleToggleROW = (event) => {
    onToggleROW(!event.target.checked);
  };

  return (
    <Flex justifyContent="space-between" my={2}>
      <Flex gap={3}>
        <Text fontWeight="medium">{`${
          isROW ? ROW_COURIER_SETTING_TEXT : `${country} Services`
        }`}</Text>
        {isEU || isROW ? (
          <SlzToggle
            defaultChecked={isSFP !== undefined && !isSFP}
            onChange={isEU ? handleToggleEU : handleToggleROW}
          />
        ) : null}
      </Flex>
      {isEU || isROW ? (
        <CourierSettingTooltip
          placement="top"
          content={COURIER_SERVICE_TOOLTIP_CONTENTS[isEU ? EU : ROW_TEXT]}>
          <Text as="u" color="main.500" fontWeight="medium" fontSize="sm">
            {`${isEU ? EU : ROW_TEXT} ${SFP_TEXT}`}
          </Text>
        </CourierSettingTooltip>
      ) : null}
    </Flex>
  );
};

const CourierSettingTooltip = ({ content, placement = 'right', ...rest }) => {
  return (
    <Tooltip
      placement={placement}
      trigger="hover"
      content={<PaginatedTooltipContent tooltipContent={content} />}
      {...rest}
    />
  );
};

const CourierSetting = ({
  isRequiredSettingsEmpty,
  requiredSettings,
  isRequiredSettingsFetching,
  activatedSetting,
  ...rest
}) => {
  const [_, setToast, toast] = useSlzToast();
  const [isToggleEU, setIsToggleEU] = useState(false);
  const [isToggleROW, setIsToggleROW] = useState(false);
  const { data, isFetching } = useCourierSettings();
  const [borderColor] = useToken('colors', ['primary.lightBorder60']);
  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.courier]: form } = forms;
  const methods = form?.form;

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'regions'
  });

  const onToggleEU = (isChecked) => {
    setIsToggleEU(!isChecked);

    const serviceTimes = transformCourierSettings(fields, EU);

    methods.setValue(transformIsSFP(fields, EU), isChecked);

    serviceTimes?.forEach((serviceTime) => {
      const getValue = methods.getValues(serviceTime);

      methods.setValue(serviceTime, {
        ...transformServiceTimes(getValue, !isChecked),
        isActive: !isChecked
      });
    });
  };

  const onToggleROW = (isChecked) => {
    setIsToggleROW(!isChecked);
    onClickWarning(!isChecked);

    const serviceTimes = transformCourierSettings(fields, ROW);

    methods.setValue(transformIsSFP(fields, ROW), isChecked);

    serviceTimes?.forEach((serviceTime) => {
      const getValue = methods.getValues(serviceTime);

      methods.setValue(serviceTime, {
        ...transformServiceTimes(getValue, !isChecked),
        isActive: !isChecked
      });
    });
  };

  const onClickWarning = (isActive) => {
    if (isActive) {
      setToast({
        chakraToastProps: {
          duration: null,
          position: 'bottom'
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <Text fontSize="1rem" fontWeight="normal" color="dark.700" ml={4}>
            {ROW_WARNING_TEXT[0]}
            <Text as="span" fontWeight="medium">
              {ROW_WARNING_TEXT[1]}
            </Text>
            {ROW_WARNING_TEXT[2]}
          </Text>
        )
      });
    } else {
      toast.closeAll();
    }
  };

  useEffect(() => {
    if (!isFetching) {
      methods.reset({ regions: data });
    }
  }, [isFetching]);

  return (
    <PreferenceSkeleton
      isLoaded={!isFetching && !isRequiredSettingsFetching}
      template={activatedSetting}>
      <BoxHiddenScroll
        maxH="41.938rem"
        sx={{
          p: '0 0.25rem 0.25rem 0.25rem',
          '.wrap-courier-setting:last-child': {
            borderBottom: '0',
            pb: 0
          },
          '.wrap-courier-setting:first-of-type': {
            pt: 0,
            pb: 6
          }
        }}>
        <CourierSettingHeader
          header={SETTING_STATUS.COURIER}
          tooltip={<CourierSettingTooltip content={COURIER_SERVICE_TOOLTIP_CONTENT} />}
        />
        <SetupRequirement.RequirementValidation
          requiredSettingProps={{
            isRequiredSettingsEmpty,
            requiredSettings,
            isRequiredSettingsFetching
          }}
          requirement={SETTING_NAVIGATE_MENU_STATUS.warehouse}
          {...rest}>
          {() => (
            <FormProvider {...methods}>
              <form>
                {fields?.map(
                  ({ countryRegion, availableServiceTimeOptions, isSFP }, regionIndex) => (
                    <Fragment key={countryRegion}>
                      <Box
                        pt={4}
                        pb={6}
                        borderBottom="2px"
                        borderColor={borderColor}
                        className="wrap-courier-setting">
                        {
                          <HeaderCourierSetting
                            isSFP={isSFP}
                            country={countryRegion}
                            onShowWarning={onClickWarning}
                            onToggleEU={onToggleEU}
                            onToggleROW={onToggleROW}
                          />
                        }
                        <HStack flexWrap="wrap" alignItems="flex-start" gap={3} spacing={0}>
                          {availableServiceTimeOptions?.map((option, index) => {
                            const { companyName } = option;
                            const isDisabled =
                              (countryRegion === EU && !isToggleEU && isSFP) ||
                              (countryRegion === ROW && !isToggleROW && isSFP);

                            return (
                              <Fragment key={`${countryRegion}-${index}`}>
                                <Controller
                                  render={({ field }) => (
                                    <CourierCard
                                      {...field}
                                      region={countryRegion}
                                      isDisabled={isDisabled}
                                    />
                                  )}
                                  control={methods.control}
                                  name={`regions.${regionIndex}.availableServiceTimeOptions.${index}.${companyName}`}
                                />
                              </Fragment>
                            );
                          })}
                        </HStack>
                      </Box>
                    </Fragment>
                  )
                )}
              </form>
            </FormProvider>
          )}
        </SetupRequirement.RequirementValidation>
      </BoxHiddenScroll>
    </PreferenceSkeleton>
  );
};

export default CourierSetting;
