import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import { USER_SETTING_FORMS_DEFAULT_NAME } from '../constants';
import { addCreditCard, replaceCreditCard, checkCreditCard } from '../services/payment-apis';

const useTaxesDutiesPreferenceMutation = () =>
  useMutation({
    mutationFn: async (payload) => {
      const { [USER_SETTING_FORMS_DEFAULT_NAME.credit]: creditForm } = payload;

      if (!isEmpty(creditForm)) {
        const hasCard = await checkCreditCard();
        !hasCard && (await addCreditCard(creditForm));
        hasCard && (await replaceCreditCard(creditForm));
      }
    }
  });

export default useTaxesDutiesPreferenceMutation;
