import { Text, VStack } from '@chakra-ui/react';

import DashboardSkeleton from 'components/common/Skeleton/templates/dashboard';

const SummaryCard = ({
  isLoading,
  title,
  value,
  description,
  tagColor,
  color = tagColor,
  isEmpty = false,
  subBlock,
  ...rest
}) => {
  const textColor = isEmpty ? undefined : color;
  const borderColor = isEmpty && !isLoading && tagColor !== 'transparent' ? 'stone.300' : tagColor;

  return (
    <VStack
      shadow="slz-4md"
      borderLeft="0.75rem solid"
      borderLeftColor={borderColor}
      borderRadius="0.375rem"
      flex={1}
      p={1}
      pr="1rem"
      spacing="-0.375rem"
      bg={isEmpty && !isLoading ? 'light.500' : 'white'}
      {...rest}>
      <Text fontSize="0.75rem" fontWeight="bold" lineHeight="1rem">
        {title}
      </Text>
      <DashboardSkeleton
        isLoaded={!isLoading}
        subBlock={subBlock}
        isFaded={false}
        template="summaryNumber">
        <Text
          fontSize="2rem"
          textAlign="center"
          fontWeight="bold"
          lineHeight="2.6875rem"
          color={textColor}>
          {value}
        </Text>
        <Text fontSize="0.625rem" lineHeight="0.8125rem">
          {description}
        </Text>
      </DashboardSkeleton>
    </VStack>
  );
};
export default SummaryCard;
