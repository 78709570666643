import { mockProductTag } from 'modules/products/_mock';

export const transformProductsImageRequired = ({ products = [], pageCount = 0 }) => {
  const data = {
    products: products,
    totalPage: pageCount
  };

  data.products = data.products.map((product) => ({
    ...product,
    tag: mockProductTag(product?.companyItemID)
  }));

  return data;
};
