import React from 'react';
import map from 'lodash/map';

import SlzTable from 'components/common/SlzTable/Table';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { DEFAULT_PAGING_OPTIONS, PAGE_INDEX } from 'constants/table.js';

import { COLLECTION_COLS } from 'modules/billing/components/table/billing.columns';

const CollectionTable = ({
  data,
  totalPage,
  queryParams,
  isLoading,
  handleItemPerPageChange,
  handleCurrentPageChange,
  handleOpenModal,
  onRefetching,
  isEmptyPage,
  messageToast
}) => {
  const routerQuery = useRouterQuery();

  COLLECTION_COLS[0].isClickable = true;
  COLLECTION_COLS[0].onClick = (row) => {
    handleOpenModal(row);
  };

  return (
    <>
      <SlzTable
        isLoading={isLoading}
        variant="billing"
        sizes={['sm', 'md', 'lg']}
        showHeader={true}
        columns={COLLECTION_COLS}
        data={data}
        onRefetching={onRefetching}
        isEmptyPage={isEmptyPage}
        messageToast={messageToast}
        displayFields={map(COLLECTION_COLS, 'field')}
        hyperLinks={['caNumber']}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => handleItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: queryParams?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage: totalPage,
            currentPage: parseInt(routerQuery.get(PAGE_INDEX)),
            isGreyBg: true,
            onChange: (currentPage) => handleCurrentPageChange(currentPage)
          }
        }}
      />
    </>
  );
};

export default CollectionTable;
