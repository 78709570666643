import React, { Fragment, memo } from 'react';

import { GridItem, Image, VStack, Text } from '@chakra-ui/react';

import { SlzGridModal } from 'components/common/SlzModal';
import { INTEGRATION_TYPE } from '../../constants';
import EditShopifyCredentials from '../form/EditShopifyCredentials';
import EditWooCommerceCredentials from '../form/EditWooCommerceCredentials';

const COMPONENT_MAP = {
  [INTEGRATION_TYPE.shopify]: EditShopifyCredentials,
  [INTEGRATION_TYPE.woocommerce]: EditWooCommerceCredentials
};

function CreateIntegrationBody({ type, ...rest }) {
  const Component = COMPONENT_MAP[type] || Fragment;

  return <Component {...rest} />;
}

export default memo(function EditCredentialsModal({
  logo,
  isOpen,
  onClose,
  type,
  onSubmit,
  credentials,
  onClickRateMatcher,
  isSubmitting
}) {
  return (
    <SlzGridModal isOpen={isOpen} onClose={onClose}>
      <GridItem gridColumn="2/12" mb="5" pt="8">
        <VStack alignItems="start" spacing={1}>
          <Image src={logo} h="10" />
          <Text as="h5" fontSize="24px" fontWeight="medium">
            {credentials?.shopName}
          </Text>
        </VStack>
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <CreateIntegrationBody
          type={type}
          onCancel={onClose}
          onSubmit={onSubmit}
          data={credentials}
          onClickRateMatcher={onClickRateMatcher}
          isSubmitting={isSubmitting}
        />
      </GridItem>
    </SlzGridModal>
  );
});
