import { useQuery } from 'react-query';
import { getConsignments } from '../services/consignments-api';

export const useConsignmentsQuery = (filters, select, enabled) => {
  return useQuery({
    queryKey: [`consignments`, filters],
    queryFn: () => getConsignments(filters),
    cacheTime: 60 * 1000,
    select: select,
    enabled: enabled
  });
};
