import { Icon } from '@chakra-ui/react';
import React from 'react';

const BagIcon = ({ fill = '#303134', ...rest }) => {
  return (
    <Icon
      width="31"
      height="34"
      viewBox="0 0 31 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}>
      <path
        d="M8.39703 0.203033H21.834C23.17 0.204621 24.4507 0.735982 25.3953 1.68063C26.34 2.62529 26.8714 3.9061 26.873 5.24203V8.29898C26.874 9.34038 27.1166 10.3673 27.582 11.299L29.168 14.472C29.8661 15.872 30.2302 17.4147 30.232 18.979V28.754C30.2304 30.0899 29.699 31.3707 28.7543 32.3153C27.8097 33.26 26.529 33.7914 25.1931 33.793H5.03802C3.70209 33.7914 2.42128 33.26 1.47662 32.3153C0.531972 31.3707 0.000550286 30.0899 -0.0010376 28.754V18.979C-3.55557e-05 17.4138 0.36461 15.8702 1.06403 14.47L3.35797 9.88199V5.24002C3.36009 3.90443 3.89177 2.62418 4.83636 1.67996C5.78096 0.735742 7.06144 0.20462 8.39703 0.203033ZM25.197 30.436C25.6424 30.4355 26.0694 30.2583 26.3843 29.9434C26.6993 29.6284 26.8765 29.2014 26.877 28.756V18.981C26.8752 17.9397 26.6325 16.9129 26.168 15.981L24.5831 12.81C23.8841 11.4104 23.5198 9.86747 23.519 8.30301V5.246C23.5187 4.80069 23.3418 4.37366 23.027 4.05869C22.7123 3.74371 22.2853 3.56654 21.84 3.56601H8.40302C7.95771 3.56654 7.53079 3.74371 7.216 4.05869C6.90122 4.37366 6.72432 4.80069 6.72406 5.246V10.285C6.7242 10.5457 6.66359 10.8028 6.54706 11.036L4.07501 15.981C3.60951 16.9126 3.36651 17.9395 3.36505 18.981V28.756C3.36558 29.2014 3.54266 29.6284 3.8576 29.9434C4.17255 30.2583 4.59958 30.4355 5.04498 30.436H25.197Z"
        fill={fill}
      />
      <path
        d="M5.03808 33.795C4.81407 33.8005 4.59123 33.7611 4.38268 33.6792C4.17413 33.5972 3.98408 33.4744 3.82372 33.3179C3.66336 33.1614 3.53598 32.9744 3.44897 32.7679C3.36196 32.5614 3.31713 32.3396 3.31713 32.1155C3.31713 31.8915 3.36196 31.6697 3.44897 31.4632C3.53598 31.2567 3.66336 31.0697 3.82372 30.9132C3.98408 30.7567 4.17413 30.6339 4.38268 30.5519C4.59123 30.47 4.81407 30.4306 5.03808 30.436C5.48348 30.4355 5.91051 30.2584 6.22546 29.9434C6.5404 29.6285 6.7176 29.2014 6.71813 28.756V18.9811C6.71633 17.9398 6.47356 16.913 6.00903 15.9811L3.5371 11.0361C3.42274 10.8377 3.35009 10.618 3.32372 10.3905C3.29736 10.163 3.31783 9.93255 3.38378 9.71325C3.44973 9.49395 3.55981 9.29043 3.70727 9.11523C3.85473 8.94002 4.03638 8.7968 4.2412 8.69439C4.44603 8.59198 4.66967 8.53255 4.89831 8.51971C5.12695 8.50686 5.35578 8.54086 5.57079 8.61968C5.7858 8.6985 5.98251 8.82044 6.14867 8.97802C6.31484 9.1356 6.447 9.32553 6.5371 9.53606L9.00903 14.4811C9.70707 15.881 10.0713 17.4237 10.0731 18.988V28.7631C10.0697 30.0971 9.53794 31.3754 8.59435 32.3185C7.65076 33.2615 6.37211 33.7924 5.03808 33.795Z"
        fill={fill}
      />
      <path
        d="M15.999 20.129V23.129H20.999V20.129H15.999ZM14.999 17.129H21.999C23.1035 17.129 23.999 18.0244 23.999 19.129V24.129C23.999 25.2336 23.1035 26.129 21.999 26.129H14.999C13.8944 26.129 12.999 25.2336 12.999 24.129V19.129C12.999 18.0244 13.8944 17.129 14.999 17.129Z"
        fill={fill}
      />
    </Icon>
  );
};

export default BagIcon;
