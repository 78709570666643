import {
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  Flex,
  Box,
  VStack,
  Container
} from '@chakra-ui/react';
import SlzChip from '../../components/common/SlzChip/SlzChip';
import colors from './../../theme/newdesign/foundations/themeColors';
import SlzTag from '../../components/common/SlzTag/SlzTag';
import SlzLabel from '../../components/common/SlzLabel/SlzLabel';
import SlzIndicator from '../../components/common/SlzIndicator/SlzIndicator';
import SlzAttentionCard from '../../components/common/SlzAttentionCard/SlzAttentionCard';
import SlzToolTip from '../../components/common/SlzToolTip';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import SlzPagination from '../../components/common/SlzPagination/SlzPagination';
import { SlzAlert } from '../../components/common/SlzAlert';
import { SlzStatusCard } from 'components/common/SlzStatusCard';

const SlzChips = ({ sizes }) => (
  <Stack direction="column">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      SlzChips
    </Text>
    <HStack>
      {sizes.map((size) => (
        <>
          <Text as="h3">{size}</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            {Object.keys(colors).map((color) => (
              <GridItem w="100%">
                <SlzChip size={size} ml={2} mt={2} variant="subtle" colorScheme={color}>
                  SlzChip text
                </SlzChip>
              </GridItem>
            ))}
          </Grid>
        </>
      ))}
    </HStack>
  </Stack>
);

const Tags = ({ sizes }) => (
  <Stack direction="column">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Tags
    </Text>
    <HStack>
      {sizes.map((size) => (
        <>
          <Text as="h3">{size}</Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            {Object.keys(colors).map((color) => (
              <GridItem w="100%">
                <SlzTag
                  size={size}
                  ml={2}
                  mt={2}
                  variant="subtle"
                  colorScheme={color}
                  borderRadius="full"
                  closable={true}>
                  Tag text
                </SlzTag>
              </GridItem>
            ))}
          </Grid>
        </>
      ))}
    </HStack>
  </Stack>
);

const StatusLabels = ({ sizes }) => (
  <Stack direction="column">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Status Labels
    </Text>
    <Flex flexWrap="wrap" my={5}>
      {Object.keys(colors).map((color) => (
        <Box pl={2}>
          <SlzLabel variant="solid" colorScheme={color}>
            {color}
          </SlzLabel>
        </Box>
      ))}
      {Object.keys(colors).map((color) => (
        <Box pl={2}>
          <SlzLabel variant="outline" colorScheme={color}>
            {color}
          </SlzLabel>
        </Box>
      ))}
    </Flex>
  </Stack>
);

const CourierLabels = () => (
  <VStack flexDirection="start">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Courier Labels
    </Text>
    <Flex>
      <Box pl={2}>
        <SlzLabel variant="courier" bg="#EE2722" color="#FDDD32">
          Royal Mail
        </SlzLabel>
      </Box>
      <Box pl={2}>
        <SlzLabel variant="courier" bg="#99CC00" color="#FAFAFA">
          Yodel
        </SlzLabel>
      </Box>
      <Box pl={2}>
        <SlzLabel variant="courier" bg="#007BC4" color="#FAFAFA">
          Evri
        </SlzLabel>
      </Box>
      <Box pl={2}>
        <SlzLabel variant="courier" bg="#330001" color="#FFBE2C">
          UPS
        </SlzLabel>
      </Box>
      <Box pl={2}>
        <SlzLabel variant="courier" bg="#FD4F00" color="#FAFAFA">
          Evri
        </SlzLabel>
      </Box>
    </Flex>
  </VStack>
);

const Alerts = () => (
  <VStack flexDirection="start">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Alert
    </Text>
    <Flex flexWrap="wrap" my={5}>
      {Object.keys(colors).map((color) => (
        <SlzAlert size="md" ml={2} mt={2} variant="subtle" color={`${color}.500`}>
          {color}
        </SlzAlert>
      ))}
    </Flex>
  </VStack>
);

const Indicators = () => (
  <VStack flexDirection="start">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Indicators
    </Text>
    <HStack m={3}>
      <VStack>
        <SlzIndicator size="sm" color="marine.500" isAnimated />
        <SlzIndicator size="md" color="tetra.500" isAnimated />
        <SlzIndicator size="lg" color="negative.500" isAnimated />
      </VStack>
      <VStack>
        <SlzIndicator size="sm" color="marine.500" isAnimated />
        <SlzIndicator size="md" color="tetra.500" isAnimated />
        <SlzIndicator size="lg" color="negative.500" isAnimated />
      </VStack>
      <VStack>
        <SlzIndicator size="sm" color="marine.500" isAnimated />
        <SlzIndicator size="md" color="tetra.500" isAnimated />
        <SlzIndicator size="lg" color="negative.500" isAnimated />
      </VStack>

      <VStack>
        <SlzIndicator size="sm" color="mustard.500" />
        <SlzIndicator size="md" color="tetra.500" />
        <SlzIndicator size="lg" color="percival.500" />
      </VStack>
      <VStack>
        <SlzIndicator size="sm" color="percival.500" />
        <SlzIndicator size="md" color="mustard.500" />
        <SlzIndicator size="lg" color="negative.500" />
      </VStack>
    </HStack>
  </VStack>
);

const AttentionCards = (props) => (
  <Stack w="50%" my={5}>
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Attention Cards
    </Text>
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {['success', 'error', 'warning', 'ghost', 'primary'].map((status) => (
        <GridItem w="100%">
          <SlzAttentionCard
            variant="solid"
            status={status}
            title="This an attention card"
            description="The most basic way to get someone’s attention is this: Break the pattern."
            {...props}
          />
        </GridItem>
      ))}
    </Grid>
    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {['success', 'error', 'warning', 'ghost', 'primary'].map((status) => (
        <GridItem w="100%">
          <SlzAttentionCard
            w="17.563rem"
            maxW="17.563rem"
            variant="solid"
            status={status}
            title="This an attention card"
            description="The most basic way to get someone’s attention is this: Break the pattern."
            {...props}
          />
        </GridItem>
      ))}
    </Grid>
    <SlzAttentionCard
      w="423px"
      maxW="423px"
      variant="product"
      status={'success'}
      title={
        <>
          For shelf locations your product must be below <strong>5kg</strong>, if you product
          exceeds that weight it will be kept on pallets to provide you with a more cost-effective
          storage
        </>
      }
    />

    <SlzAttentionCard
      w="500px"
      maxW="500px"
      variant="product"
      status={'success'}
      title="The most basic way to get someone’s attention is this: Break the pattern.The most basic way to get someone’s attention is this: Break the pattern.The most basic way to get someone’s attention is this: Break the pattern.The most basic way to get someone’s attention is this: Break the pattern."
    />
  </Stack>
);

const StatusCard = () => (
  <Stack w="50%" my={5}>
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Status Card
    </Text>

    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
      {['success', 'main', 'dark'].map((status) => (
        <GridItem>
          <SlzStatusCard
            showIcon
            variant={status}
            title="UK shipping"
            description="Matched to 48 Hour service"
          />
        </GridItem>
      ))}
      <GridItem>
        <SlzStatusCard title="UK shipping" description="Matched to 48 Hour service" />
      </GridItem>
    </Grid>
  </Stack>
);

export const tooltipContent = (
  <Stack justifyContent="center" alignItems="center" w="full">
    <Stack justifyContent="center" alignItems="center" w="full">
      <HStack fontSize="19px">
        <strong>Let me help you with that</strong>
      </HStack>
      <HStack fontSize="14px" textAlign="justify">
        <span>
          Have you tried this? or maybe that? Good one! Now, don’t forget about the one and the two
        </span>
      </HStack>
    </Stack>
    <HStack>
      <SlzPagination
        {...{
          colorScheme: '#fff',
          showTitle: false,
          variant: 'normalLight',
          pages: null,
          direction: {
            usingDots: true,
            numDots: 5,
            isGreyBg: true,
            prevLabel: '',
            nextLabel: '',
            currentPage: 1,
            totalPage: 5,
            labelColor: '#F2F2F2',
            onChange: (currentIdx) => {}
          }
        }}
      />
    </HStack>
  </Stack>
);

const ToolTip = () => (
  <VStack my={5}>
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      ToolTips
    </Text>
    <ToolTipPlacement placement={'right-start'} />
  </VStack>
);
const ToolTipPlacement = ({ placement }) => (
  <SlzToolTip content={tooltipContent} placement={placement} variant="blue">
    <HStack w="full">
      <Box tabIndex="0" role="icon" children={<InfoOutlineIcon color={'#0052CC'} />} />
    </HStack>
  </SlzToolTip>
);
const ElementOne = () => {
  const sizes = ['lg', 'md', 'sm'];

  return (
    <>
      <ToolTip />
      <SlzChips sizes={sizes} />
      <Tags sizes={sizes} />
      <StatusLabels />
      <CourierLabels />
      <Alerts />
      <Indicators />
      <StatusCard />
      <AttentionCards />
    </>
  );
};

export default ElementOne;
