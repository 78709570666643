import { useQuery } from 'react-query';
import { getShippingRatesByIntegrationId } from '../services/integration-api';

export const useShippingRatesQuery = (id, select, enabled) => {
  return useQuery({
    queryKey: [`shippingrates-${id}`],
    queryFn: () => getShippingRatesByIntegrationId(id),
    select: select,
    enabled: enabled
  });
};
