import { GridItem } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { SlzGrid } from 'components/common/SlzGrid';
import { APIConnectionCard } from '../common';
import useSlzToast from 'hooks/useSlzToast';
import { useRemoveConnection } from 'modules/integrations/hooks';
import { TOAST_MESSAGE_SUCCESS } from 'modules/integrations/constants';

const API = ({ apiConnections, refetch, onClickSetupNewApi, onClickManageApi }) => {
  const [_, setToast] = useSlzToast();
  const mutationRemoveConnection = useRemoveConnection();

  const handleOnClickDeleteApi = async (id) => {
    try {
      await mutationRemoveConnection.mutateAsync(id);
      refetch();
      setToast({ title: TOAST_MESSAGE_SUCCESS });
    } catch (err) {
      const { message, response } = err;
      setToast({
        colorScheme: 'negative',
        title: response?.data?.message ?? message,
        status: 'warning'
      });

      return;
    }
  };

  return (
    <SlzGrid px={0}>
      {isEmpty(apiConnections) ? (
        <GridItem gridColumn="span 3">
          <APIConnectionCard isSetupNew onClickSetupNewApi={onClickSetupNewApi} />
        </GridItem>
      ) : (
        apiConnections.map((api) => (
          <GridItem key={api.id} gridColumn="span 3">
            <APIConnectionCard
              api={api}
              refetch={refetch}
              onClickManageApi={onClickManageApi}
              onClickDeleteApi={handleOnClickDeleteApi}
            />
          </GridItem>
        ))
      )}
    </SlzGrid>
  );
};

export default API;
