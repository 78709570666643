import { useQuery } from 'react-query';
import { getReturns } from '../services/returns-api';

export const useReturnsQuery = ({ filters, select, enabled }) => {
  return useQuery({
    queryKey: [`returns`, filters],
    queryFn: () => getReturns(filters),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled
  });
};
