import { Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { SlzBreadcrumb } from '../../components/common/SlzBreadcrumb';
import SlzLink from '../../components/common/SlzLink/SlzLink';
import SlzPaper from '../../components/common/SlzPaper/SlzPaper';

const breadCrumbItems = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/section',
    title: 'Section'
  },
  {
    path: '/section/subsection',
    title: 'Subsection'
  },
  {
    path: '/section/subsection/item',
    title: 'Item'
  }
];

const Breadcrumbs = () => (
  <>
    <VStack alignItems="start">
      <Text as="h1" fontSize="2xl" fontWeight={600}>
        Breadcrumbs
      </Text>
      <SlzBreadcrumb
        items={breadCrumbItems.slice(0, 2)}
        onNavigate={(path) => console.log(path)}></SlzBreadcrumb>
      <SlzBreadcrumb
        items={breadCrumbItems}
        onNavigate={(path) => console.log(path)}></SlzBreadcrumb>
    </VStack>
  </>
);

const Paper = () => {
  return (
    <HStack alignItems="start">
      <Text as="h1" fontSize="2xl" fontWeight={600}>
        Paper
      </Text>
      <HStack spacing={4}>
        <SlzPaper size="sm" />
        <SlzPaper size="md" />
        <SlzPaper size="lg" />
      </HStack>
    </HStack>
  );
};

const Links = () => {
  return (
    <VStack alignItems="start">
      <Text as="h1" fontSize="2xl" fontWeight={600}>
        Links
      </Text>
      <Flex mt={5}>
        <SlzLink m={5} href="#" leftIcon isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} href="#" leftIcon isExternal>
          FAQ
        </SlzLink>
        <SlzLink m={5} href="#" isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} href="#" isExternal>
          FAQ
        </SlzLink>
        <SlzLink m={5} href="#" rightIcon isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} href="#" rightIcon isExternal>
          FAQ
        </SlzLink>
      </Flex>
      <Flex>
        <SlzLink m={5} variant="underline" href="#" leftIcon isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} variant="underline" href="#" leftIcon isExternal>
          FAQ
        </SlzLink>
        <SlzLink m={5} variant="underline" href="#" isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} variant="underline" href="#" isExternal>
          FAQ
        </SlzLink>
        <SlzLink m={5} variant="underline" href="#" rightIcon isExternal>
          Read more
        </SlzLink>
        <SlzLink m={5} variant="underline" href="#" rightIcon isExternal>
          FAQ
        </SlzLink>
      </Flex>
    </VStack>
  );
};

const ElementThree = () => {
  return (
    <Stack my={5} w="full">
      <HStack justifyContent="space-between" w="50%" spacing={10}>
        <Breadcrumbs />
        <Paper />
      </HStack>
      <Links />
    </Stack>
  );
};

export default ElementThree;
