import { MAX_ITEMS } from 'modules/b2c-orders/constants';
import * as yup from 'yup';

export const orderDetailsSchema = yup
  .object({
    orderId: yup.string().required('Order ID is required'),
    items: yup.array().of(
      yup.object().shape({
        productId: yup.string().required('Product ID is required'),
        sku: yup.string(),
        count: yup
          .number()
          .typeError('Count is required')
          .min(1, 'Minimum is 1')
          .max(MAX_ITEMS, 'Maximum is ' + MAX_ITEMS)
          .required('Count is required')
      })
    )
  })
  .required();

export const deliveryDetailsSchema = yup
  .object({
    name: yup.string().required('Name is required'),
    phoneNumber: yup.number().typeError('Phone number is required'),
    email: yup
      .string()
      .email()
      .matches(/^(?!.*@[^,]*,)/)
      .required('Email address is required'),
    addressOne: yup.string().required('Address line 1 is required'),
    town: yup.string().required('Town is required'),
    county: yup.string().required('county is required'),
    postCode: yup.string().required('Postcode is required'),
    countryCode: yup.object().required('Country code is required').nullable()
  })
  .required();
