import { SlzToggle } from 'components/common/SlzToggle';
import { ControlledField } from 'components/form';
import { PACKAGE_SETTINGS_STYLE } from 'modules/preferences/styles';

const Toggle = ({ value, ...rest }) => {
  return <SlzToggle isChecked={value} {...rest} />;
};

const PackagingToggle = ({ packageSetting }) => {
  return (
    <ControlledField
      name={packageSetting.keyName}
      {...PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.toggle}
      component={Toggle}
    />
  );
};

export default PackagingToggle;
