import React from 'react';
import { FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { Flex, FormControl } from '@chakra-ui/react';

import { FormLabel } from 'modules/users/components/form';
import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { SlzButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';
// import { NUMBER_PATTERN, SPECIAL_CHAR_PATTERN } from 'modules/users/constants';
import { PasswordCriteria } from '../other';
import { useCheckPasswordCriteria } from 'modules/users/hooks';
import ReCAPTCHA from './ReCAPTCHA';

const NUMBER_PATTERN = /^(?=.*[0-9])/;
const SPECIAL_CHAR_PATTERN = /^(?=.*[#$^+=!*()@%&£"'[\]{},_\\/;:`\-~<>?.])/;

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(6, null)
    .matches(NUMBER_PATTERN, null)
    .matches(SPECIAL_CHAR_PATTERN, null),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
  isBot: yup.boolean().required()
});

const ResetPasswordForm = ({ onSubmit }) => {
  const defaultValues = {
    password: undefined,
    confirmPassword: undefined
  };

  return (
    <FormWrapper defaultValues={defaultValues} validationSchema={validationSchema}>
      {({
        methods,
        errors,
        getFieldStatus,
        resetFieldStatus,
        resetAllFieldsStatus,
        clearField
      }) => {
        const password = methods.watch('password');
        const confirmPassword = methods.watch('confirmPassword');
        const passwordCriteria = useCheckPasswordCriteria({
          password,
          confirmPassword,
          delayTime: 500,
          isDirty: methods.formState.isDirty
        });
        const isInvalidReCAPTCHA = !!methods.getFieldState('isBot').error;

        const handleOnChangeReCAPTCHA = (token) => {
          if (token) {
            methods.setValue('isBot', !!token);
            methods.clearErrors('isBot');
          }
        };

        return (
          <FormProvider {...methods}>
            <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <PasswordCriteria criteriaStatus={passwordCriteria} />
              <FormControl mt={5} mb={5}>
                <FormLabel>Password</FormLabel>
                <SlzInput
                  id="password"
                  type="password"
                  showIcon
                  {...methods.register('password')}
                  placeholder="Password"
                />
              </FormControl>
              <FormControl mb={6} isInvalid={errors.confirmPassword}>
                <FormLabel>Confirm password</FormLabel>
                <SlzInput
                  id="confirmPassword"
                  type="password"
                  showIcon
                  {...methods.register('confirmPassword')}
                  placeholder="Confirm password"
                  status={getFieldStatus('confirmPassword')}
                  onFocus={() => resetFieldStatus('confirmPassword')}
                  onClickError={() => clearField('confirmPassword')}
                />
              </FormControl>
              <Flex justifyContent="center">
                <ReCAPTCHA isInvalid={isInvalidReCAPTCHA} onChange={handleOnChangeReCAPTCHA} />
              </Flex>
              <Flex mb={5} mt={8} justifyContent="space-between">
                <SlzButton type="submit" size="lg" w="full" onClick={resetAllFieldsStatus}>
                  Reset password
                </SlzButton>
              </Flex>
            </form>
          </FormProvider>
        );
      }}
    </FormWrapper>
  );
};

export default ResetPasswordForm;
