import { Icon } from '@chakra-ui/react';
import React from 'react';

const ImageUpload = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="59.448"
      height="47.352"
      viewBox="0 0 59.448 47.352"
      fill="currentColor"
      {...props}>
      <g id="Group_4001" data-name="Group 4001" transform="translate(0 0)">
        <g id="Group_3729" data-name="Group 3729" transform="translate(0 14.595) rotate(-17)">
          <rect
            id="Rectangle_5052"
            data-name="Rectangle 5052"
            width="49.919"
            height="34.253"
            rx="1"
            fill="currentColor"
          />
          <rect
            id="Rectangle_5053"
            data-name="Rectangle 5053"
            width="42.533"
            height="28.22"
            transform="translate(3.525 3.021)"
            fill="#fafafa"
          />
          <path
            id="Path_9738"
            data-name="Path 9738"
            d="M16.09,4.065l-.977,1.783a.852.852,0,0,1-1.375.311L7.38.326A1.188,1.188,0,0,0,5.451.8L.283,10.895A2.7,2.7,0,0,0,0,12.118v3.244c0,.7.425,1.262.949,1.262h33.08c.342,0,.463-.6.167-.829L18.123,3.49A1.33,1.33,0,0,0,16.09,4.065Z"
            transform="translate(6.502 12.53)"
            fill="#fff"
          />
          <ellipse
            id="Ellipse_1152"
            data-name="Ellipse 1152"
            cx="4.234"
            cy="4.234"
            rx="4.234"
            ry="4.234"
            transform="translate(31.647 4.711)"
            fill="currentColor"
          />
        </g>
        <g id="Group_3731" data-name="Group 3731" transform="translate(9.529 13.099)">
          <rect
            id="Rectangle_5052-2"
            data-name="Rectangle 5052"
            width="49.919"
            height="34.253"
            rx="1"
            fill="currentColor"
          />
          <rect
            id="Rectangle_5053-2"
            data-name="Rectangle 5053"
            width="42.533"
            height="28.22"
            transform="translate(3.525 3.021)"
            fill="#fafafa"
          />
          <path
            id="Path_9738-2"
            data-name="Path 9738"
            d="M525.322,456.809l-.977,1.783a.852.852,0,0,1-1.375.311l-6.357-5.833a1.188,1.188,0,0,0-1.93.479l-5.167,10.09a2.7,2.7,0,0,0-.283,1.223v3.244c0,.7.425,1.262.949,1.262h33.081c.342,0,.463-.6.167-.829l-16.073-12.3A1.33,1.33,0,0,0,525.322,456.809Z"
            transform="translate(-502.73 -440.214)"
            fill="currentColor"
          />
          <ellipse
            id="Ellipse_1152-2"
            data-name="Ellipse 1152"
            cx="4.234"
            cy="4.234"
            rx="4.234"
            ry="4.234"
            transform="translate(31.647 4.711)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ImageUpload;
