import { Icon } from '@chakra-ui/react';
import React from 'react';

const PackagingIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 18.724 20.362"
      fill="currentColor"
      stroke="currentColor"
      {...props}>
      <g id="Group_33750" data-name="Group 33750" transform="translate(-189.414 -2147.189)">
        <path
          id="Path_3455"
          data-name="Path 3455"
          d="M198.776,2148.551l8,4.5v9l-8,4.5-8-4.5v-9l8-4.5"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_19"
          data-name="Line 19"
          y1="4.5"
          x2="8"
          transform="translate(198.776 2153.051)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_20"
          data-name="Line 20"
          y2="9"
          transform="translate(198.776 2157.551)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_21"
          data-name="Line 21"
          x1="8"
          y1="4.5"
          transform="translate(190.776 2153.051)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_22"
          data-name="Line 22"
          x1="8"
          y2="4.5"
          transform="translate(194.776 2150.801)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default PackagingIcon;
