import { useEffect, useState } from 'react';

import { FormProvider } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { SlzSearchInput } from 'components/common/SlzInput';
import { B2BOrderAction, B2BProductTracking } from 'modules/b2b-orders/components/other';
import {
  B2B_SELECTED_PRODUCT_ITEMS,
  B2B_SELECTED_PRODUCT_MESSAGE
} from 'modules/b2b-orders/constants';

import useSlzToast from 'hooks/useSlzToast';
import { calculateQuantitySelectedProducts } from 'modules/b2b-orders/utils';
import B2BProductSelectionTable from 'modules/b2b-orders/components/table/B2BProductSelectionTable';
import { transformSelectionProduct } from 'modules/b2b-orders/mappers/bulk-order-product-mapper';
import useValidateSelectedStockMutation from 'modules/b2b-orders/hooks/useValidateSelectedStockMutation';

const B2BProductSelectionForm = ({ step, ...props }) => {
  const { defaultValues, onNext, onPrevious, ...rest } = props;
  const useValidateSelectedStock = useValidateSelectedStockMutation();
  const [searchText, setSearchText] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(defaultValues?.selectedProducts || []);

  const [isInvalidQuantity, setIsInvalidQuantity] = useState(false);

  const [_, setToast] = useSlzToast();

  useEffect(() => {
    if (
      calculateQuantitySelectedProducts(selectedProducts) > B2B_SELECTED_PRODUCT_ITEMS.MAXIMUM_ITEMS
    ) {
      showWarningQuantity({
        title: B2B_SELECTED_PRODUCT_ITEMS.OVER_ITEMS_MESSAGE,
        position: 'bottom',
        maxW: '61.313rem'
      });
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (selectedProducts.length < 1) {
      setIsInvalidQuantity(true);
      return;
    }

    const invalidQuantity = selectedProducts.some(
      (product) => product.quantity > product.totalStock
    );
    setIsInvalidQuantity(invalidQuantity);
  }, [selectedProducts]);

  const showWarningQuantity = ({ title, position = 'top', maxW }) => {
    setToast({
      colorScheme: 'negative',
      title: title,
      status: 'warning',
      chakraToastProps: {
        position,
        containerStyle: {
          maxWidth: `${maxW} !important`
        }
      }
    });
  };

  const handleSelectedProduct = (row) => {
    setSelectedProducts((selectedProducts) => {
      const foundProduct = selectedProducts?.find(
        ({ companyItemID, sku }) => companyItemID === row.companyItemID && sku === row.sku
      );
      if (foundProduct) {
        return [...selectedProducts];
      }
      const newSelectedProduct = { ...row, quantity: 1 };

      if (newSelectedProduct.quantity > newSelectedProduct.totalStock) {
        showWarningQuantity({
          title: B2B_SELECTED_PRODUCT_MESSAGE.NOT_ENOUGH_STOCK,
          maxW: '58.063rem'
        });
      }

      if (selectedProducts.length === 0) {
        return [...selectedProducts, { ...newSelectedProduct, showConfirmQuantity: true }];
      }
      return [...selectedProducts, { ...newSelectedProduct }];
    });
  };

  const handleSelectedQuantityProduct = (product, quantity) => {
    if (quantity.value === 'remove') {
      setSelectedProducts((selectedProducts) => {
        const currSelectedProductIndex = selectedProducts.findIndex(
          ({ companyItemID, sku }) => companyItemID === product.companyItemID && sku === product.sku
        );

        selectedProducts.splice(currSelectedProductIndex, 1);
        return [...selectedProducts];
      });
      return;
    }

    if (isNaN(quantity.value) || quantity.value < 0) {
      return;
    }

    if (product?.totalStock < +quantity.value) {
      showWarningQuantity({
        title: B2B_SELECTED_PRODUCT_MESSAGE.NOT_ENOUGH_STOCK,
        maxW: '58.063rem'
      });
    }

    setSelectedProducts((selectedProducts) => {
      const currSelectedProductIndex = selectedProducts.findIndex(({ companyItemID, sku }) => {
        return companyItemID === product.companyItemID && sku === product.sku;
      });

      return selectedProducts.map((selectedProduct, index) => {
        if (index === currSelectedProductIndex) {
          return { ...selectedProduct, quantity: +quantity.value };
        }
        return { ...selectedProduct };
      });
    });
  };

  const handleCloseConfirmQuantity = () => {
    setSelectedProducts((selectedProducts) => {
      if (selectedProducts.length > 0) {
        delete selectedProducts[0].showConfirmQuantity;
      }
      return [...selectedProducts];
    });
  };

  const handleNextStep = async () => {
    const { data } = await useValidateSelectedStock.mutateAsync({
      items: transformSelectionProduct(selectedProducts)
    });

    if (data.data.invalidItems.length === 0) {
      const currentValues = {
        ...defaultValues,
        stockSelection: transformSelectionProduct(selectedProducts),
        selectedProducts
      };
      onNext(currentValues);
    }
  };

  const handlePreviousStep = () => {
    const currentValues = {
      ...defaultValues,
      stockSelection: transformSelectionProduct(selectedProducts),
      selectedProducts
    };
    onPrevious({ values: currentValues });
  };

  return (
    <>
      <FormWrapper>
        {({ methods }) => {
          return (
            <FormProvider {...methods}>
              <form onSubmit={methods?.handleSubmit(handleNextStep)}>
                <B2BProductTracking step={step} selectedProducts={selectedProducts} />
                <Box mb={1}>
                  <SlzSearchInput
                    clearable
                    size="xs"
                    width="100%"
                    placeholder="Search by name, SKU, EAN"
                    variant="accent"
                    height="1.875rem"
                    sx={{
                      border: 'common',
                      borderRadius: 'md',
                      fontSize: 'md',
                      color: 'dark.500',
                      '::placeholder': {
                        color: 'dark.500'
                      }
                    }}
                    onSearch={setSearchText}
                    onReset={() => setSearchText('')}
                  />
                </Box>
                <B2BProductSelectionTable
                  selectedProducts={selectedProducts}
                  searchText={searchText}
                  onSelectedProduct={handleSelectedProduct}
                  onSelectedQuantity={handleSelectedQuantityProduct}
                  onCloseConfirmQuantity={handleCloseConfirmQuantity}
                />
                <B2BOrderAction
                  disableNextButton={isInvalidQuantity}
                  onPrevious={handlePreviousStep}
                  {...rest}
                />
              </form>
            </FormProvider>
          );
        }}
      </FormWrapper>
    </>
  );
};

export default B2BProductSelectionForm;
