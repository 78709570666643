import { Box, Icon } from '@chakra-ui/react'

export const LeftArrow = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="0.75rem"
        h="0.713rem"
        viewBox="0 0 7.2 12"
        {...props}>
        <path id="Path_10161" data-name="Path 10161" d="M279.67,27.912l-.67-.694-.67.694A.923.923,0,0,0,279.67,27.912ZM279,25.83l-4.382-4.542a.925.925,0,0,0-1.34,0,1.008,1.008,0,0,0,0,1.389l5.053,5.236.67-.694.67.694,5.053-5.236a1.008,1.008,0,0,0,0-1.389.925.925,0,0,0-1.34,0Z" transform="translate(28.2 -273) rotate(90)" fill="#686a73" fill-rule="evenodd" />
    </Icon>
)

export const InfoIcon = (props) => (
    <Icon
        xmlns="http://www.w3.org/2000/svg"
        w="1.441rem"
        h="1.391rem"
        viewBox="0 0 23.059 22.253"
        {...props}>
        <g d="Group_34175" data-name="Group 34175">
            <path id="Polygon_17" data-name="Polygon 17" d="M12.5,4.33,3.3,22H21.7L12.5,4.33m0-2.582a1.484,1.484,0,0,1,1.33.807L23.858,21.807A1.5,1.5,0,0,1,22.527,24H2.473a1.5,1.5,0,0,1-1.33-2.193L11.17,2.554A1.484,1.484,0,0,1,12.5,1.747Z" transform="translate(-0.97 -1.747)" fill="#0973ea" />
            <path id="Path_1322" data-name="Path 1322" d="M0,11.107a1,1,0,0,1-1-1V3.791a1,1,0,0,1,1-1,1,1,0,0,1,1,1v6.316A1,1,0,0,1,0,11.107Z" transform="translate(11.53 3.753)" fill="#0973ea" />
            <circle id="Ellipse_1122" data-name="Ellipse 1122" cx="1.5" cy="1.5" r="1.5" transform="translate(10.03 16.253)" fill="#0973ea" />
        </g>
    </Icon>
)