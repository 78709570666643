import { SlzToggle } from '../../SlzToggle';
import { NestedFieldContainer } from './NestedField';

export const NestedToggle = (props) => {
  const { field, ...rest } = props;
  return (
    <NestedFieldContainer
      showTooltip={false}
      SlzComponent={SlzToggle}
      isControlled={true}
      field={field}
      fieldProps={rest}
      {...rest}
    />
  );
};
