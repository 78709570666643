import React from 'react';
import { Icon } from '@chakra-ui/react';

const AddressBookIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="currentcolor" {...props}>
      <g id="Group_33710" data-name="Group 33710" transform="translate(-49.332 -702.664)">
        <path
          id="Path_5819"
          data-name="Path 5819"
          d="M66.332,705.664v12a2,2,0,0,1-2,2h-10a2,2,0,0,1-2-2v-12a2,2,0,0,1,2-2h10A2,2,0,0,1,66.332,705.664Z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5820"
          data-name="Path 5820"
          d="M56.332,715.664h6"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_424"
          data-name="Ellipse 424"
          cx="2"
          cy="2"
          r="2"
          transform="translate(57.332 708.664)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5821"
          data-name="Path 5821"
          d="M50.332,707.664h3"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5822"
          data-name="Path 5822"
          d="M50.332,711.664h3"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5823"
          data-name="Path 5823"
          d="M50.332,715.664h3"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default AddressBookIcon;
