import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';

export const API_CARD_STYLES = {
  paper: {
    w: 'full',
    minH: '12.75rem',
    bg: 'white',
    borderRadius: 3,
    boxShadow: 'slz-1md'
  },
  apiChip: {
    size: 'lg',
    h: '1.875rem',
    borderRadius: 1,
    fontSize: 'xl',
    fontWeight: 'bold',
    lineHeight: 'tall',
    color: 'light.300',
    colorScheme: 'indigo',
    flexBasis: 'unset'
  },
  connected: {
    size: 'lg',
    colorScheme: 'positive',
    color: 'light.500',
    lineHeight: '4'
  },
  setupNewButton: {
    size: 'sm',
    variant: 'solid',
    colorScheme: 'main',
    color: 'light.500',
    lineHeight: '4',
    px: 6
  },
  manageButton: {
    size: 'md',
    variant: 'outline',
    colorScheme: 'main',
    borderRadius: 1.5,
    lineHeight: '4',
    px: 6
  }
};

export const AGREE_TERM_BASE_STYLE = {
  w: '32.438rem',
  alignItems: 'center',
  h: '2.625rem',
  border: '1px solid',
  borderRadius: 1.5,
  pl: 3.5,
  mt: 5,
  bg: 'light.500',
  borderColor: 'light.700'
};

export const AGREE_TERM_CHECKED_STYLE = {
  ...AGREE_TERM_BASE_STYLE,
  bg: 'main.300',
  borderColor: 'main.500'
};

export const PERMISSION_DROPDOWN_STYLE = {
  ...SELECT_STYLES,
  container: (provided) => ({
    ...provided,
    '> div': {
      borderRadius: 1.5
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 'lg',
    color: 'dark.500'
  }),
  control: (provided, state) => ({
    ...provided,
    '> div:first-child': {
      px: 5,
      py: 0
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 'lg',
    color: 'dark.700'
  }),
  menu: (...args) => ({
    ...SELECT_STYLES.menu(...args),
    '> div > div': {
      h: '1.875rem'
    }
  }),
  menuList: (provided) => ({
    ...provided,
    p: 0.75,
    borderRadius: 1.5,
    boxShadow: 'slz-2md'
  })
};
