import { Icon } from '@chakra-ui/react';

const WarehouseIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34144" data-name="Group 34144" transform="translate(-313 -381)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(313 381)"
          fill="#0973ea"
        />
        <g id="Group_1724" data-name="Group 1724" transform="translate(318 386)">
          <path
            id="Path_6974"
            data-name="Path 6974"
            d="M537.832,1816.164a.5.5,0,0,1-.5-.5v-6.175l-4-1.778-4,1.778v6.175a.5.5,0,1,1-1,0v-6.5a.5.5,0,0,1,.3-.457l4.5-2a.5.5,0,0,1,.406,0l4.5,2a.5.5,0,0,1,.3.457v6.5A.5.5,0,0,1,537.832,1816.164Z"
            transform="translate(-528.332 -1806.664)"
            fill="#fafafa"
          />
          <path
            id="Path_6975"
            data-name="Path 6975"
            d="M537.733,1820.8h-4.91a.5.5,0,0,1-.491-.514V1817.2a.5.5,0,0,1,.491-.514h2.946a.514.514,0,0,1,0,1.027h-2.455v2.054h3.928v-3.081h-1.473a.514.514,0,0,1,0-1.027h1.964a.5.5,0,0,1,.491.514v4.108A.5.5,0,0,1,537.733,1820.8Z"
            transform="translate(-530.278 -1811.299)"
            fill="#fafafa"
          />
          <path
            id="Path_6976"
            data-name="Path 6976"
            d="M536.9,1819.826a.514.514,0,0,1-.514-.514v-4.621h-1.027v1.54a.514.514,0,0,1-1.027,0v-1.54a1.028,1.028,0,0,1,1.027-1.027h1.027a1.028,1.028,0,0,1,1.027,1.027v4.621A.513.513,0,0,1,536.9,1819.826Z"
            transform="translate(-531.367 -1810.325)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default WarehouseIcon;
