import { createIcon } from "@chakra-ui/react";
const PaypalIcon = createIcon({
    displayName: "PaypalIcon",
    viewBox: "0 0 178.196 47.484",
    path: (
        <g id="Group_329" data-name="Group 329" transform="translate(0)">
            <path id="Path_1513" data-name="Path 1513" d="M71.291,96.444c-.758,4.973-4.558,4.973-8.232,4.973H60.968l1.467-9.288a1.154,1.154,0,0,1,1.141-.975h.958c2.5,0,4.864,0,6.084,1.427.727.851.951,2.116.673,3.863m-1.6-12.981H55.832a1.926,1.926,0,0,0-1.9,1.626l-5.606,35.541a1.155,1.155,0,0,0,1.144,1.334h6.617a1.926,1.926,0,0,0,1.9-1.627L59.5,110.75a1.924,1.924,0,0,1,1.9-1.628h4.387c9.13,0,14.4-4.417,15.776-13.173.62-3.831.026-6.84-1.768-8.948-1.97-2.314-5.463-3.539-10.1-3.539" transform="translate(-48.309 -83.461)" fill="#fff" />
            <path id="Path_1514" data-name="Path 1514" d="M110.284,112.467A7.4,7.4,0,0,1,102.8,118.8a5.142,5.142,0,0,1-5.5-6.453,7.43,7.43,0,0,1,7.438-6.387,5.6,5.6,0,0,1,4.426,1.807,5.746,5.746,0,0,1,1.123,4.7m9.251-12.918H112.9a1.157,1.157,0,0,0-1.141.975l-.293,1.856-.464-.673c-1.437-2.086-4.641-2.782-7.839-2.782-7.335,0-13.6,5.555-14.819,13.347a12.53,12.53,0,0,0,2.472,10.2c2.023,2.383,4.917,3.376,8.36,3.376a12.7,12.7,0,0,0,9.187-3.8l-.3,1.844a1.155,1.155,0,0,0,1.139,1.338h5.979a1.926,1.926,0,0,0,1.9-1.627l3.587-22.717a1.15,1.15,0,0,0-1.137-1.333" transform="translate(-56.721 -86.727)" fill="#fff" />
            <path id="Path_1515" data-name="Path 1515" d="M164.473,99.716H157.8a1.933,1.933,0,0,0-1.6.845l-9.2,13.551-3.9-13.021a1.932,1.932,0,0,0-1.848-1.374h-6.554a1.156,1.156,0,0,0-1.1,1.528l7.347,21.559-6.907,9.75a1.155,1.155,0,0,0,.943,1.825h6.661a1.924,1.924,0,0,0,1.583-.827l22.183-32.022a1.154,1.154,0,0,0-.948-1.814" transform="translate(-66.309 -86.894)" fill="#fff" />
            <path id="Path_1516" data-name="Path 1516" d="M196.619,96.444c-.756,4.973-4.555,4.973-8.232,4.973H186.3l1.47-9.288a1.151,1.151,0,0,1,1.139-.975h.958c2.5,0,4.864,0,6.084,1.427.727.851.948,2.116.67,3.863m-1.6-12.981H181.158a1.925,1.925,0,0,0-1.9,1.626l-5.606,35.541a1.154,1.154,0,0,0,1.139,1.334H181.9a1.347,1.347,0,0,0,1.33-1.139l1.59-10.074a1.925,1.925,0,0,1,1.9-1.628h4.386c9.132,0,14.4-4.417,15.777-13.173.622-3.831.024-6.84-1.769-8.948-1.967-2.314-5.459-3.539-10.1-3.539" transform="translate(-74.775 -83.461)" fill="#fff" />
            <path id="Path_1517" data-name="Path 1517" d="M235.611,112.467a7.394,7.394,0,0,1-7.488,6.337,5.142,5.142,0,0,1-5.5-6.453,7.433,7.433,0,0,1,7.438-6.387,5.6,5.6,0,0,1,4.426,1.807,5.718,5.718,0,0,1,1.123,4.7m9.251-12.918h-6.633a1.149,1.149,0,0,0-1.139.975l-.293,1.856-.466-.673c-1.437-2.086-4.639-2.782-7.837-2.782-7.335,0-13.6,5.555-14.817,13.347a12.538,12.538,0,0,0,2.471,10.2c2.027,2.383,4.917,3.376,8.36,3.376a12.7,12.7,0,0,0,9.187-3.8l-.3,1.844a1.156,1.156,0,0,0,1.143,1.338h5.977a1.926,1.926,0,0,0,1.9-1.627l3.589-22.717a1.158,1.158,0,0,0-1.145-1.333" transform="translate(-83.188 -86.727)" fill="#fff" />
            <path id="Path_1518" data-name="Path 1518" d="M261.657,84.438l-5.689,36.192a1.154,1.154,0,0,0,1.139,1.333h5.719a1.923,1.923,0,0,0,1.9-1.627L270.339,84.8a1.154,1.154,0,0,0-1.138-1.335h-6.4a1.157,1.157,0,0,0-1.139.977" transform="translate(-92.158 -83.461)" fill="#fff" />
        </g>
    )
});

export default PaypalIcon;