import { Fragment, useMemo } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { SlzChip } from 'components/common/SlzChip';
import { getOrderShippingStatus } from 'modules/returns/utils';
import { COURIER_MAP } from 'constants/common';

const InformationColumn = ({ label, value, contentProps, isTag }) => {
  return (
    <VStack justifyContent="space-between" alignItems="flex-start" spacing={0}>
      <Text fontSize="1rem" lineHeight="1.3125rem" fontWeight="bold" color="black">
        {label}
      </Text>
      {isTag ? (
        <Box>
          <SlzChip borderRadius="0.375rem" size="lg" {...contentProps}>
            {value}
          </SlzChip>
        </Box>
      ) : (
        <Text fontSize="0.875rem" lineHeight="1.1875rem" color="black" {...contentProps}>
          {value}
        </Text>
      )}
    </VStack>
  );
};

const OrderDetailShippingCard = ({ data }) => {
  const { weight, warehouseName, providerName, status } = data;

  const courier = COURIER_MAP[providerName];
  const shippingStatus = useMemo(() => getOrderShippingStatus(data), [status]);

  return (
    <Fragment>
      <HStack
        shadow="slz-md"
        alignItems="flex-start"
        justifyContent="space-between"
        borderLeft="12px solid"
        borderLeftColor="szrCerulean.500"
        borderRadius="0.375rem"
        padding="1rem 2.25rem 1.8125rem 1.5rem"
        mb={4}
        bg="light.300">
        <InformationColumn
          label="Shipping status"
          value={shippingStatus.label}
          isTag
          contentProps={{
            fontSize: '1rem',
            minH: '30px',
            bg: shippingStatus.bg
          }}
        />
        <InformationColumn label="Weight" value={`${weight}kg`} />
        <InformationColumn label="Fulfilment centre" value={warehouseName} />
        <InformationColumn
          label="Courier"
          value={providerName}
          isTag
          contentProps={{
            fontSize: '1rem',
            minH: '30px',
            bg: courier?.bg,
            color: courier?.color,
            px: 3
          }}
        />
        <InformationColumn
          label="Tracking number"
          value={data.parcelReference}
          isTag
          contentProps={{ fontWeight: 'bold', px: 2 }}
        />
      </HStack>
    </Fragment>
  );
};
export default OrderDetailShippingCard;
