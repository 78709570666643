import CommonEndpoints from 'components/common/Endpoints';
import { mapUpdateCourierSettings } from '../utils';
import axiosClient from 'config/axios';
import { buildQuery } from 'utils/helper';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';

const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};

export const getEORIDetails = async () => {
  return await axiosClient.request(CommonEndpoints.RETAILER.GET.EORI_DETAILS);
};

export const updateEORIDetails = async ({ identifier = null, eoriNumber: number = 0 }) => {
  const payload = { identifier, number };
  return await axiosClient.post(CommonEndpoints.RETAILER.POST.UPDATE_EORI, payload);
};

export const removeEORIDetails = async (identifier) => {
  return await axiosClient.post(`${CommonEndpoints.RETAILER.POST.REMOVE_EORI}${identifier}`);
};

export const updateCustomerAccessPreference = async (payload) => {
  return await axiosClient.post(`${CommonEndpoints.RETAILER.POST.SECURITY_PREFERENCE}`, payload);
};

export const updateOrderPreference = async (enabled) => {
  return await axiosClient.post(
    `${CommonEndpoints.RETAILER.POST.UPDATE_ORDER_PREFERENCE}/toggle/${enabled}`
  );
};

export const getCustomerServiceAccess = async () => {
  return await axiosClient.request(CommonEndpoints.RETAILER.GET.CUSTOMER_SERVICE_ACCESS);
};

export const getPartialOrderPreference = async () => {
  const response = await axiosClient.get(CommonEndpoints.RETAILER.GET.PARTIAL_ORDER_PREFERENCE);
  return response?.data?.data;
};

export const updatePartialOrderPreference = async (partialOrder) => {
  return await axiosClient.post(
    `${CommonEndpoints.RETAILER.POST.UPDATE_ORDER_PREFERENCE}/${partialOrder}`
  );
};

export const getVatRegisteredCountries = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.VAT_DETAILS}`;
  return await axiosClient.request(url);
};

export const updateVatRegisteredCountries = async (payload) => {
  const url = `${CommonEndpoints.RETAILER.POST.UPDATE_VAT}`;
  return await axiosClient.post(url, payload);
};

export const getServiceTimes = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS}`;
  return await axiosClient.request(url);
};

export const getCurrentPreferences = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES}`;
  return await axiosClient.request(url);
};

export const updateCurrentPreferences = async (payload) => {
  const url = `${CommonEndpoints.RETAILER.POST.COURIER_REGIONAL_PREFERENCES}`;
  return await axiosClient.post(url, mapUpdateCourierSettings(payload));
};

export const getIOSSNumber = async () => {
  return await axiosClient.get(CommonEndpoints.RETAILER.GET.IOSS_DETAILS);
};

export const updateIOSSNumber = async (payload) => {
  return await axiosClient.post(CommonEndpoints.RETAILER.POST.UPDATE_IOSS, payload);
};

export const removeIOSSNumber = async () => {
  return await axiosClient.post(CommonEndpoints.RETAILER.POST.REMOVE_IOSS, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const getGradingActionsPreferenceOptions = async () => {
  const url = `${CommonEndpoints.RETURNS.GET.OPTIONS}`;
  return await axiosClient.request(url);
};

export const getGradingActionsPreferenceInformation = async () => {
  const url = `${CommonEndpoints.RETURNS.GET.INFORMATION}`;
  return await axiosClient.request(url);
};

export const getGradingActionsPreference = async () => {
  const url = `${CommonEndpoints.RETURNS.GET.GRADE_PREFERENCES}`;
  return await axiosClient.request(url);
};

export const updateReturnGradingPreference = async (payload) => {
  return await axiosClient.post(CommonEndpoints.RETURNS.POST.TOGGLE_GRADING_PREFERENCES, payload);
};

export const getReturnSettings = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.CONFIGURATION_RETURNS}`;
  return await axiosClient.request(url);
};

export const getReturnPreferences = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.RETURN_PREFERENCES}`;
  return await axiosClient.request(url);
};

export const putDisableReturnSettings = async () => {
  const url = `${CommonEndpoints.RETAILER.PUT.DISABLE_RETURNS}`;
  return await axiosClient.put(url);
};

export const putEnableReturnSettings = async () => {
  const url = `${CommonEndpoints.RETAILER.PUT.ENABLE_RETURNS}`;
  return await axiosClient.put(url);
};

export const updateToggleReturnSettingPreferences = async (payload) => {
  const url = `${CommonEndpoints.RETAILER.POST.TOGGLE_RETURN_PREFERENCES}`;
  return await axiosClient.post(url, payload);
};

export const updateReturnSettingApplyToAll = async (payload) => {
  const url = `${CommonEndpoints.RETAILER.POST.RETURN_APPLY_TO_ALL}`;
  return await axiosClient.post(url, payload);
};

export const updateReturnSettingApplyToNew = async (payload) => {
  const url = `${CommonEndpoints.RETAILER.POST.RETURN_APPLY_TO_NEW}`;
  return await axiosClient.post(url, payload);
};

export const getProductNoImage = async (filters = { pageIndex: DEFAULT_PAGE_INDEX }) => {
  const url = `${CommonEndpoints.STOCK.GET.PRODUCT_NO_IMAGE}`;
  return await axiosClient.request(buildQuery(url, filters));
};

export async function uploadSingleImage(productId, files) {
  let formData = new FormData();
  formData.append('files', files, files.name);

  return await axiosClient.post(
    `${CommonEndpoints.STOCK.POST.UPLOAD_IMAGE}/${productId}`,
    formData,
    config
  );
}

export const updatePackagingExtra = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.EXTRA}`;
  return await axiosClient.post(url, payload);
};

export const getPackagingExtras = async () => {
  const url = `${CommonEndpoints.PACKAGING.GET.EXTRAS}`;
  return await axiosClient.request(url);
};
export const getCustomPackagingOverview = async () => {
  const url = `${CommonEndpoints.PACKAGING.GET.CUSTOM_OVERVIEW}`;
  return await axiosClient.request(url);
};

export const getCustomPackaging = async (customPackaging) => {
  const url = `${CommonEndpoints.PACKAGING.GET[customPackaging]}`;
  return await axiosClient.request(url);
};

export const updateCustomPackagingOverview = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.ENFORCE}`;
  return await axiosClient.post(url, payload);
};

export const updateCustomPackagingStatus = async (params) => {
  const url = `${CommonEndpoints.PACKAGING.PUT[params.type]}${params.id}`;
  return await axiosClient.put(url);
};

export const putArchiveCustomPackaging = async (id) => {
  const url = `${CommonEndpoints.PACKAGING.PUT.ARCHIVE}${id}`;
  return await axiosClient.put(url);
};

export const addCustomPackagingBox = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.BOX}`;
  return await axiosClient.post(url, payload);
};

export const addCustomPackagingMailingBag = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.MAILING_BAG}`;
  return await axiosClient.post(url, payload);
};

export const updateCustomPackagingBox = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.BOX}`;
  return await axiosClient.put(url, payload);
};

export const updateCustomPackagingMailingBag = async (payload) => {
  const url = `${CommonEndpoints.PACKAGING.POST.MAILING_BAG}`;
  return await axiosClient.put(url, payload);
};

export const addSelectedWarehouse = async (payload) => {
  const url = `${CommonEndpoints.WAREHOUSE_SETTING.POST.SELECTION}`;
  return await axiosClient.post(url, payload);
};

export const getSelectedWarehouse = async () => {
  const url = `${CommonEndpoints.WAREHOUSE_SETTING.GET.SELECTION}`;
  return await axiosClient.get(url);
};

export const getAllWarehouses = async () => {
  const url = `${CommonEndpoints.WAREHOUSE_SETTING.GET.ALL}`;
  return await axiosClient.get(url);
};

export const updateGradingApplyToAll = async () => {
  const url = `${CommonEndpoints.RETAILER.POST.GRADING_APPLY_TO_ALL}`;
  return await axiosClient.post(url);
};

export const updateGradingApplyToNew = async () => {
  const url = `${CommonEndpoints.RETAILER.POST.GRADING_APPLY_TO_NEW}`;
  return await axiosClient.post(url);
};
