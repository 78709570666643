import { useQuery } from 'react-query';
import { getBilling } from '../services/billing-api';

export const useBillingQuery = ({ filters, select, enabled }) => {
  return useQuery({
    queryKey: [`billing`, filters],
    queryFn: () => getBilling(filters),
    cacheTime: 60 * 1000,
    select: select,
    enabled: enabled
  });
};
