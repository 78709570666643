import { HStack, VStack, Box } from '@chakra-ui/react';

import InventorySkeleton from 'components/common/Skeleton/templates/inventory';

import Warehouse from 'modules/consignments/components/modal/send/Warehouse';
import SelectedProduct from './SelectedProduct';
import ConsignmentLocation from './ConsignmentLocation';
import ConsignmentDetails from './ConsignmentDetails';
import StockboxDimensions from './StockboxDimensions';
import GTIN from './GTIN';
import BatchInformation from './BatchInformation';

const SingleConsignmentUpload = ({ warehousesData, currentType, selectedConsignmentType }) => {
  return (
    <>
      <VStack mb="3rem">
        <HStack w="full">
          <SelectedProduct />
          <Warehouse warehousesData={warehousesData} currentType={currentType} width="50%" />
        </HStack>
        <Box w="100%">
          <InventorySkeleton
            isLoaded={!!warehousesData}
            type={selectedConsignmentType}
            template={`inventory.${currentType}`}>
            <VStack w="100%">
              <ConsignmentLocation />
              <HStack w="full">
                <ConsignmentDetails />
                <StockboxDimensions />
              </HStack>
              <HStack w="full">
                <GTIN />
                <BatchInformation />
              </HStack>
            </VStack>
          </InventorySkeleton>
        </Box>
      </VStack>
    </>
  );
};

export default SingleConsignmentUpload;
