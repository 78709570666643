import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { keyframes } from '@chakra-ui/react';
import buttonTheme from './slz-button';

const parts = [
  'container',
  'notificationNumber',
  'leftButton',
  'rightButton',
  'rightButtonActive',
  'optionContainer',
  'option',
  'selectedOption',
  'arrow'
];

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const zoomOut = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const containerStyle = defineStyle({});

const notificationNumberStyle = defineStyle({
  w: 4,
  h: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pos: 'absolute',
  top: '-0.25rem',
  left: '0.8rem',
  fontSize: '0.75rem',
  borderRadius: '50%',
  bg: 'notification.number',
  animation: `${zoomOut} 0.2s ease-out`
});

const leftButtonStyle = defineStyle({
  ...buttonTheme.baseStyle,
  bg: 'dark.700',
  _hover: {
    bg: 'dark.500'
  },
  _active: {
    bg: 'charcoal.300'
  }
});

const rightButtonStyle = defineStyle({
  ...buttonTheme.baseStyle,
  bg: 'dark.700',
  _hover: {
    bg: 'dark.500'
  },
  _active: {
    bg: 'charcoal.300'
  }
});

const rightButtonActiveStyle = defineStyle({
  ...rightButtonStyle,
  bg: 'charcoal.300'
});

const optionContainerStyle = defineStyle({
  w: '10rem',
  borderColor: 'notification.optionContainer',
  boxShadow: '0rem 0.125rem 0.5rem #00000033',
  right: '0.375rem'
});

const optionStyle = defineStyle({
  minH: '1.875rem',
  py: 0,
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  mx: '0.188rem',
  fontSize: '0.875rem',
  _hover: {
    bg: 'light.700'
  },
  _first: {
    mt: '0.188rem'
  },
  _last: {
    mb: '0.188rem'
  }
});

const selectedOptionStyle = defineStyle({
  ...optionStyle,
  bg: 'main.300',
  _hover: {}
});

const arrowStyle = defineStyle({
  borderLeft: '0.063rem solid',
  borderTop: '0.063rem solid',
  borderColor: 'notification.optionContainer',
  left: '0.375rem !important'
});

const baseStyle = definePartsStyle((props) => ({
  container: containerStyle,
  notificationNumber: notificationNumberStyle,
  leftButton: leftButtonStyle,
  rightButton: rightButtonStyle,
  rightButtonActive: rightButtonActiveStyle,
  optionContainer: optionContainerStyle,
  option: optionStyle,
  selectedOption: selectedOptionStyle,
  arrow: arrowStyle
}));

export default defineMultiStyleConfig({
  baseStyle
});
