import { Icon } from '@chakra-ui/react';

const AdditionalDetailsIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34173" data-name="Group 34173" transform="translate(-379 -352)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(379 352)"
          fill="#0973ea"
        />
        <g id="Group_34271" data-name="Group 34271" transform="translate(-1633.278 -343.866)">
          <path
            id="Path_4933"
            data-name="Path 4933"
            d="M2021.9,711.423h-2.7a1.424,1.424,0,0,1-1.423-1.423v-5.69a1.424,1.424,0,0,1,1.423-1.423h.948a.474.474,0,1,1,0,.948h-.948a.475.475,0,0,0-.474.474V710a.475.475,0,0,0,.474.474h2.7a.474.474,0,0,1,0,.948Z"
            transform="translate(0.356 -1.052)"
            fill="#fafafa"
          />
          <path
            id="Path_4934"
            data-name="Path 4934"
            d="M2030.144,707.155a.474.474,0,0,1-.474-.474V704.31a.475.475,0,0,0-.474-.474h-.948a.474.474,0,0,1,0-.948h.948a1.424,1.424,0,0,1,1.422,1.423v2.371A.474.474,0,0,1,2030.144,707.155Z"
            transform="translate(-4.902 -1.052)"
            fill="#fafafa"
          />
          <path
            id="Path_4935"
            data-name="Path 4935"
            d="M2023.2,700.887h.948a1.423,1.423,0,0,1,0,2.845h-.948a1.423,1.423,0,1,1,0-2.845Zm.948,1.9a.474.474,0,0,0,0-.948h-.948a.474.474,0,0,0,0,.948Z"
            transform="translate(-1.747)"
            fill="#fafafa"
          />
          <path
            id="Path_4936"
            data-name="Path 4936"
            d="M2024.144,709.835h-1.9a.474.474,0,1,1,0-.948h1.9a.474.474,0,0,1,0,.948Z"
            transform="translate(-2.103 -4.206)"
            fill="#fafafa"
          />
          <path
            id="Path_4937"
            data-name="Path 4937"
            d="M2023.67,713.835h-1.423a.474.474,0,1,1,0-.948h1.423a.474.474,0,1,1,0,.948Z"
            transform="translate(-1.747 -6.31)"
            fill="#fafafa"
          />
          <path
            id="Ellipse_250"
            data-name="Ellipse 250"
            d="M.66-1A1.66,1.66,0,1,1-1,.66,1.662,1.662,0,0,1,.66-1Zm0,2.371A.711.711,0,1,0-.052.66.712.712,0,0,0,.66,1.371Z"
            transform="translate(2023.871 707.578)"
            fill="#fafafa"
          />
          <path
            id="Path_4938"
            data-name="Path 4938"
            d="M2033.933,719.521a.473.473,0,0,1-.335-.139l-1.186-1.185a.474.474,0,1,1,.671-.671l1.186,1.185a.474.474,0,0,1-.335.81Z"
            transform="translate(-7.268 -8.676)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default AdditionalDetailsIcon;
