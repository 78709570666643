import AmericanExpressIcon from '../components/common/Icons/AmericanExpressIcon';
import MasterCardIcon from '../components/common/Icons/MasterCardIcon';
import PaypalIcon from '../components/common/Icons/PaypalIcon';
import VisaIcon from '../components/common/Icons/VisaIcon';

export const creditType = {
  PAYPAL: 'paypal',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'american-express'
};

export const creditCard = [
  {
    name: creditType.PAYPAL,
    icon: <PaypalIcon />
  },
  {
    name: creditType.VISA,
    icon: <VisaIcon />
  },
  {
    name: creditType.MASTERCARD,
    icon: <MasterCardIcon />
  },
  {
    name: creditType.AMERICAN_EXPRESS,
    icon: <AmericanExpressIcon />
  }
];

export const CREDIT_CARD_TYPE = {
  PAYPAL: 'paypal',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  AMERICAN_EXPRESS: 'american_express',
  BACS: 'BACS'
};

export const PAYMENT_CARD_STATUS = {
  SUCCESSFUL: 'active',
  ACTIVE: 'active',
  UNSUCCESSFUL: 'inactive',
  PENDING: 'pending',
  REMOVED: 'removed'
};

export const CREDIT_CARD_PLACEHOLDER = {
  customerName: 'John Doe',
  cardNumber: '**** **** **** 1111'
};
