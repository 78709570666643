import { useQuery } from 'react-query';
import { hasDirectDebit } from '../services/payment-apis';

export default function useHasDirectDebit() {
  return useQuery({
    queryKey: ['has-direct-debit'],
    queryFn: hasDirectDebit,
    select: (value) => value.data?.data
  });
}
