import { Box, Card, FormControl, HStack, Text, VStack } from '@chakra-ui/react';
import { AdditionalDetailsIcon } from '../assets';
import { useFormContext } from 'react-hook-form';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzDateSinglePicker from 'components/common/SlzDateSinglePicker';
import { DATE_DISPLAY_FORMAT_DDMMYY } from 'constants';
import SlzInput from 'components/common/SlzInput/SlzInput';

const BatchInformation = () => {
  const {
    setValue,
    register
  } = useFormContext();

  const handleSelectedDate = (date) => setValue('expiryDate', date.format(DATE_DISPLAY_FORMAT_DDMMYY));

  return (
    <>
      <VStack w="50%">
        <Card
          bg="light.500"
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <AdditionalDetailsIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Text
              sx={{
                textAlign: 'left',
                fontSize: 'lg',
                color: 'table.mainText'
              }}>
              <b>Additional details</b>
            </Text>
          </HStack>
        </Card>
        <Card
          bg="light.500"
          border="1px solid"
          borderColor="light.700"
          mt="2rem"
          w="100%"
          h="5.875rem"
          px="1.5rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <HStack sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <FormControl>
                <HStack>
                  <SlzFormLabel
                    display="flex"
                    gap="1"
                    alignItems="left"
                    color="dark.700"
                    mr="0 !important"
                    fontSize="sm">
                    Batch number -
                  </SlzFormLabel>
                  <Text
                    sx={{ fontSize: 'sm', fontWeight: 'normal', marginLeft: '0 !important' }}>
                    <i>optional</i>
                  </Text>
                </HStack>
                <SlzInput
                  type="text"
                  size="sm"
                  fontSize="sm"
                  textAlign="right"
                  bgColor="light.300"
                  w="10.375rem"
                  {...register('batchNumber')}
                  onFocus={() => {}}
                />
              </FormControl>
            </Box>

            <Box>
              <FormControl>
                <HStack>
                  <SlzFormLabel fontSize="sm" color="dark.700" mr="0 !important">
                    Expiration date -
                  </SlzFormLabel>
                  <Text
                    size="sm"
                    fontSize='sm'
                    sx={{ fontWeight: 'normal', marginLeft: '0 !important' }}>
                    <i>optional</i>
                  </Text>
                </HStack>
                <SlzDateSinglePicker size="sm" startDatePlaceHolder="DD/MM/YY" onDateInputClick={handleSelectedDate} />
              </FormControl>
            </Box>
          </HStack>
        </Card>
      </VStack>
    </>
  );
};

export default BatchInformation;
