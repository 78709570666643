import { Fragment, useState } from 'react';
import { Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';

import FormWrapper from 'components/common/SlzForms/FormWrapper';
import { SlzStatusCard } from 'components/common/SlzStatusCard';
import { SlzTag } from 'components/common/SlzTag';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

import { RateMatcherFormActions } from 'modules/integrations/components/form';
import {
  RATE_MATCHER_SETTING_STEP,
  RATE_MATCHER_SUMMARY_TEXT,
  PREFIX
} from 'modules/integrations/constants';
import {
  getManuallyMatchedRates,
  getStepOneValues,
  getUnManuallyMatchedRates
} from 'modules/integrations/utils';
import { RateMatcherText, RateMatcherWarning } from 'modules/integrations/components/other';

const SelectedDefaultRates = ({ selectedDefaultRates = [] }) => {
  return (
    <VStack align="start" spacing="2px" w="full">
      <Heading fontSize="1rem" color="dark.700" lineHeight="1.563rem" textDecoration="underline">
        {RATE_MATCHER_SUMMARY_TEXT.selectedDefaultRate}
      </Heading>
      <Flex flexWrap="wrap" gap={3} spacing="0px" w="full">
        {selectedDefaultRates.map(({ title, description, isMatched }, index) => (
          <SlzStatusCard
            key={`${index}-${title}`}
            variant="main"
            flexBasis={'32%'}
            title={title}
            description={description}
            showIcon={!isMatched}
          />
        ))}
      </Flex>
    </VStack>
  );
};

const ManuallyMatchedRates = ({ manuallyMatchedRates = [] }) => {
  return (
    <VStack align="start" spacing="2px">
      <Heading fontSize="1rem" color="dark.700" lineHeight="1.563rem" textDecoration="underline">
        {RATE_MATCHER_SUMMARY_TEXT.manuallyMatchedRates}
      </Heading>
      <HStack flexWrap="wrap" gap={3} spacing="0px">
        {manuallyMatchedRates.map(({ title, description }, index) => (
          <SlzStatusCard
            key={`${index}-${title}`}
            variant="success"
            title={title}
            description={description}
          />
        ))}
      </HStack>
    </VStack>
  );
};

const UnmatchedRates = ({ unmatchedRates = [] }) => {
  return (
    <VStack align="start" spacing="2px">
      <Heading fontSize="1rem" color="dark.700" lineHeight="1.563rem" textDecoration="underline">
        {RATE_MATCHER_SUMMARY_TEXT.unMatchedRates}
      </Heading>
      <HStack flexWrap="wrap" gap={3} spacing="0px">
        {unmatchedRates.map(({ title, description }, index) => (
          <SlzStatusCard
            key={`${index}-${title}`}
            variant="dark"
            title={title}
            description={description}
          />
        ))}
      </HStack>
    </VStack>
  );
};

const RateMatcherNote = () => (
  <HStack w="90%" justifyItems="start" alignItems="start">
    <SlzTag color="main.500" minW="7%">
      Note
    </SlzTag>
    <RateMatcherText>{RATE_MATCHER_SUMMARY_TEXT.summaryNote}</RateMatcherText>
  </HStack>
);

const RateMatcherStepSummary = ({
  defaultValues,
  shopifyShippingRatePreferences,
  onSubmit,
  onSave,
  onPrevStep,
  ...rest
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const stepOneValues = getStepOneValues(defaultValues);
  const matchedRates = getManuallyMatchedRates(defaultValues?.shippingRates);
  const unmatchedRates = getUnManuallyMatchedRates(defaultValues?.shippingRates);

  const handleSave = () => {
    setIsSubmitted(true);
    unmatchedRates.length === 0 && onSave(defaultValues);
  };

  return (
    <Fragment>
      <FormWrapper defaultValues={defaultValues}>
        {({ methods }) => {
          return (
            <FormProvider {...methods}>
              <form>
                <IntegrationSkeleton isLoaded={true} template={`${PREFIX}.${rest?.currentStep}`}>
                  <VStack align="start" spacing="1.563rem">
                    <Heading
                      fontSize="1.25rem"
                      color="dark.700"
                      lineHeight="1.563rem"
                      textDecoration="underline">
                      {RATE_MATCHER_SUMMARY_TEXT.yourRateMatcherSummary}
                    </Heading>
                    <VStack align="start" spacing="2.125rem">
                      {stepOneValues.length > 0 && (
                        <SelectedDefaultRates selectedDefaultRates={stepOneValues} />
                      )}
                      {matchedRates.length > 0 && (
                        <ManuallyMatchedRates manuallyMatchedRates={matchedRates} />
                      )}
                      {unmatchedRates.length > 0 && (
                        <UnmatchedRates unmatchedRates={unmatchedRates} />
                      )}
                    </VStack>
                    <VStack align="start" spacing="1.5rem">
                      {unmatchedRates.length > 0 && isSubmitted && (
                        <RateMatcherWarning
                          onIgnore={() => onSave(methods.getValues())}
                          onConfigure={() =>
                            onPrevStep(RATE_MATCHER_SETTING_STEP.step2, methods.getValues())
                          }
                          onClose={() => setIsSubmitted(false)}
                        />
                      )}
                      <RateMatcherNote />
                    </VStack>
                  </VStack>
                </IntegrationSkeleton>
                <RateMatcherFormActions onPrevStep={onPrevStep} {...rest} onSave={handleSave} />
              </form>
            </FormProvider>
          );
        }}
      </FormWrapper>
    </Fragment>
  );
};

export default RateMatcherStepSummary;
