import { Flex, HStack, Heading } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';

import {
  CUSTOM_LIBRARY_TEXT,
  NO_LIBRARY_TITLE,
  PREFERENCES_ACTIONS,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { useCustomPackagingQuery } from 'modules/preferences/hooks';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { CUSTOM_LIBRARY_STYLE } from 'modules/preferences/styles';
import { AddCustomBox, LibraryCard } from '.';

const ManageLibraries = () => {
  const { preference, preferenceDispatch } = usePreferenceContext();
  const { data: libraries, isLoading, refetch } = useCustomPackagingQuery(preference.managedType);

  const addCustomBox = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.customBoxAndMailing.key }
    });
  };

  const handleShowTitle = (type) => {
    return CUSTOM_LIBRARY_TEXT.title[type];
  };

  return (
    <>
      <HStack {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.header.wrapper}>
        <Heading {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.header.text}>
          {handleShowTitle(preference.managedType)}
        </Heading>
        <SlzButton {...CUSTOM_LIBRARY_STYLE.LIBRARY_TITLE.header.button} onClick={addCustomBox}>
          {CUSTOM_LIBRARY_TEXT.addButton}
        </SlzButton>
      </HStack>
      {!isLoading && (
        <Flex flexWrap="wrap">
          {libraries?.length > 0 ? (
            libraries?.map((library) => (
              <LibraryCard key={library.id} library={library} refetch={refetch} />
            ))
          ) : (
            <AddCustomBox
              title={NO_LIBRARY_TITLE[preference.managedType]}
              onAddBox={addCustomBox}
            />
          )}
        </Flex>
      )}
    </>
  );
};

export default ManageLibraries;
