import { Box, HStack, VStack } from '@chakra-ui/react';
import {
  B2BCourierOptionsShippingForm,
  B2BCourierResults,
  B2BHeaderShipping,
  B2BShippingAddress
} from '.';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import {
  B2B_NO_COURIER_ERROR_WARNING_TEXT,
  B2B_SHOW_ERROR_WARNING_TEXT,
  COURIER_OPTIONS_WARNING_BOX_TEXT
} from 'modules/b2b-orders/constants';

const ShowError = ({ differentService, courierError, showError, originalServiceTime }) => {
  return (
    <>
      {differentService && (
        <SlzAttentionCard
          description={
            <>
              {`Sorry, we could not find a ${originalServiceTime} courier option. You can select a different
      service time above and try again. Alternatively, you can select the suggested option
      below:`}
            </>
          }
          {...COURIER_OPTIONS_WARNING_BOX_TEXT.attentionCard}
        />
      )}
      {(courierError || showError) && (
        <SlzAttentionCard {...B2B_NO_COURIER_ERROR_WARNING_TEXT.attentionCard} />
      )}
    </>
  );
};

const B2BCourierNetWorkShipping = ({ order, courierResult, ...rest }) => {
  const {
    shippingService,
    originalServiceTimePreference,
    showDifferentServiceMessage,
    noCourierError,
    showError
  } = courierResult;

  const isShowCourier = shippingService && shippingService.id !== '';

  return (
    <HStack alignItems="flex-start" gap={1}>
      <Box>
        <B2BHeaderShipping title="Shipping Address" />
        <B2BShippingAddress
          address={order}
          w="20.25rem"
          minH="10.5rem"
          fontSize="lg"
          pt="1.125rem"
          pl="1.125rem"
        />
      </Box>
      <VStack alignItems="flex-start" gap={1}>
        <Box>
          <B2BHeaderShipping title="Courier Options" btnName="Courier information" tooltip />
          <B2BCourierOptionsShippingForm w="30.688rem" minH="18.313rem" order={order} {...rest} />
        </Box>
        <Box w="full">
          {isShowCourier && <B2BHeaderShipping title="Courier Results" my={2.5} />}
          <ShowError
            differentService={showDifferentServiceMessage}
            courierError={noCourierError}
            showError={showError}
            originalServiceTime={originalServiceTimePreference}
          />
          {isShowCourier && (
            <B2BCourierResults
              h="3.875rem"
              w="30.688rem"
              shippingService={shippingService}
              {...rest}
            />
          )}
        </Box>
      </VStack>
    </HStack>
  );
};

export default B2BCourierNetWorkShipping;
