import { GridItem } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SlzGrid } from '../../../components/common/SlzGrid';
import { useGlobalState } from '../../../contexts/GlobalContext';
import { useQuery as useRouterQuery } from '../../../hooks/useQuery';
import { SET_BREADCRUMB_LINKS } from '../../../reducers/appReducer';
import ConsignmentsFilter from '../components/form/ConsignmentsFilter';
import ConsignmentsTable from '../components/table/ConsignmentsTable';
import OnHoldTable from '../components/table/OnHoldTable';
import ConsignmentTabs from '../components/tabs/ConsignmentTabs';
import { GET_CONSIGNMENT_LIST, SET_QUERY_PARAM } from '../actions';
import useTableFilterHandler from '../../../hooks/useTableFilterHandler';

import {
  CONSIGNMENT_PARAMS,
  CONSIGNMENT_PROCESS,
  CONSIGNMENT_PROCESSES,
  BREAD_CRUMB_ITEMS,
  RECEIVED_STATUSES,
  CONSIGNMENT_KEY_PARAM
} from '../constants';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  PAGE_INDEX,
  PAGE_SIZE,
  SEARCH_TEXT,
  FILTER_BY,
  DATE_FROM,
  DATE_TO,
  SORT_BY,
  IS_DESC
} from 'constants/table.js';
import { useConsignmentsContext, useOnHoldContext } from '../context/ConsignmentsContext';
import { useConsignmentsQuery } from '../hooks/useConsignmentsQuery';
import { transformConsignments } from '../mappers/consignments-mapper';
import { DATE_DISPLAY_FORMAT_YYYYMMDD } from 'constants';

export default function ConsignmentList() {

  const routerQuery = useRouterQuery();
  const history = useHistory();
  const [enableQuery, setEnableQuery] = useState(false);
  const [_, dispatch] = useGlobalState();
  const { consignment, consignmentDispatch } = useConsignmentsContext();
  const { onHoldDispatch } = useOnHoldContext();
  const { handleSearch } = useTableFilterHandler();

  const {
    data: { items = [], totalPage } = {},
    isFetching,
    refetch,
    isError
  } = useConsignmentsQuery(consignment?.params, (data) => transformConsignments(data), enableQuery);

  const consignmentProcess =
    routerQuery.get(CONSIGNMENT_PARAMS.PROCESS) ?? CONSIGNMENT_PROCESS.CONSIGNMENTS;
  const [tabIndex, setTabIndex] = useState(
    consignmentProcess
      ? CONSIGNMENT_PROCESSES.findIndex((process) => process.key === consignmentProcess)
      : 0
  );

  const [selectedReceivedStatuses, setSelectedReceivedStatuses] = useState([]);
  const [isOpenSendConsignmentModal, setIsOpenSendConsignmentModal] = useState(false);

  useEffect(() => {
    const params = {
      pageIndex: routerQuery.get(PAGE_INDEX) ?? DEFAULT_PAGE_INDEX,
      pageSize: routerQuery.get(PAGE_SIZE) ?? DEFAULT_PAGE_SIZE,
      process: routerQuery.get(CONSIGNMENT_PARAMS.PROCESS) || CONSIGNMENT_PROCESSES[tabIndex]?.key,
      searchText: routerQuery.get(SEARCH_TEXT) ?? '',
      dateFrom: routerQuery.get(DATE_FROM) ?? '',
      dateTo: routerQuery.get(DATE_TO) ?? '',
      sortBy: routerQuery.get(SORT_BY) ?? '',
      isDesc: routerQuery.get(IS_DESC) || '',
      filterBy: routerQuery.getAll(FILTER_BY) ?? ''
    };

    consignmentDispatch({
      type: SET_QUERY_PARAM,
      payload: { params }
    });

    onHoldDispatch({
      type: SET_QUERY_PARAM,
      payload: { params }
    });
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [consignment?.params]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  useEffect(() => {
    consignmentDispatch({
      type: GET_CONSIGNMENT_LIST,
      payload: { items }
    });
  }, [isFetching]);

  const handleCurrentPageChange = (currentPage) => {
    if (currentPage >= DEFAULT_PAGE_INDEX) {
      routerQuery.set(PAGE_INDEX, currentPage);
      history.push({
        search: routerQuery.toString()
      });
      return;
    }
  };

  const handleItemPerPageChange = (pageSize) => {
    routerQuery.set(PAGE_INDEX, DEFAULT_PAGE_INDEX);

    if (pageSize <= DEFAULT_PAGE_SIZE) {
      routerQuery.set(PAGE_SIZE, pageSize);
      history.push({
        search: routerQuery.toString()
      });
      return;
    }
  };

  const handleFilterByReceivedStatusChange = (options) => {
    if (options && options.length > 0) {
      const selected = options.map((option) => option.key);
      setSelectedReceivedStatuses(selected);

      while (routerQuery.getAll(FILTER_BY).length) {
        routerQuery.delete(FILTER_BY);
      }

      selected.forEach((value) => {
        routerQuery.append(FILTER_BY, value);
      });

      history.push({
        search: routerQuery.toString()
      });
    } else {
      setSelectedReceivedStatuses([]);

      if (routerQuery.has(FILTER_BY)) {
        routerQuery.delete(FILTER_BY);
        history.push({
          search: routerQuery.toString()
        });
      }
    }
  };

  const handleSelectedDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
      routerQuery.set(DATE_FROM, startDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD));
      routerQuery.set(DATE_TO, endDate.format(DATE_DISPLAY_FORMAT_YYYYMMDD));
    } else {
      routerQuery.delete(DATE_FROM);
      routerQuery.delete(DATE_TO);
    }
    history.push({
      search: routerQuery.toString()
    });
  };

  const onTabChange = (index) => {
    setTabIndex(index);
    switchConsignmentProcess(CONSIGNMENT_PROCESSES[index]?.key);
  };

  const switchConsignmentProcess = (process) => {
    routerQuery.set(CONSIGNMENT_PARAMS.PROCESS, process);
    history.push({ search: routerQuery.toString() });
  };

  useEffect(() => {
    const tabIndex = CONSIGNMENT_PROCESSES.findIndex(
      (process) => process.key === consignmentProcess
    );
    setTabIndex(tabIndex);
  }, [routerQuery.get(CONSIGNMENT_PARAMS.PROCESS)]);

  const handleCloseSendConsignmentModal = () => {
    routerQuery.delete(CONSIGNMENT_KEY_PARAM.IS_SEND_CONSIGNMENT_MODAL);
    history.push({ search: routerQuery.toString() });
    setIsOpenSendConsignmentModal(false);
  };

  const handleOnSort = (column, isDesc) => {
    if (column) {
      routerQuery.set(SORT_BY, column?.sortField);
      routerQuery.set(IS_DESC, isDesc);
    }
    history.push({
      search: routerQuery.toString()
    });
  };

  return (
    <Fragment>
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <ConsignmentsFilter
            process={consignmentProcess}
            handleSelectedDateRange={handleSelectedDateRange}
            onSearch={(val) => handleSearch(SEARCH_TEXT, val)}
            initSearchValue={routerQuery.get(SEARCH_TEXT) || ''}
          />
          <ConsignmentTabs tabIndex={tabIndex} onChange={onTabChange} />
          {consignmentProcess === CONSIGNMENT_PROCESS.CONSIGNMENTS && (
            <ConsignmentsTable
              totalPage={totalPage}
              isLoading={isFetching}
              onRefetching={refetch}
              isEmptyPage={isError || totalPage === 0}
              messageToast="Sorry! We could not retrieve your consignment information"
              onPageChange={handleCurrentPageChange}
              onItemPerPageChange={handleItemPerPageChange}
              //receivedStatuses={RECEIVED_STATUSES}
              selectedReceivedStatuses={selectedReceivedStatuses}
              isOpenSendConsignmentModal={routerQuery.get(CONSIGNMENT_KEY_PARAM.IS_SEND_CONSIGNMENT_MODAL) || isOpenSendConsignmentModal}
              onCloseSendConsignmentModal={handleCloseSendConsignmentModal}
              handleFilterByReceivedStatusChange={handleFilterByReceivedStatusChange}
              setEnableQuery={setEnableQuery}
              onSort={handleOnSort}
            />
          )}
          {consignmentProcess === CONSIGNMENT_PROCESS.ON_HOLD && (
            <OnHoldTable
              onPageChange={handleCurrentPageChange}
              onItemPerPageChange={handleItemPerPageChange}
              isOpenSendConsignmentModal={
                routerQuery.get(CONSIGNMENT_KEY_PARAM.IS_SEND_CONSIGNMENT_MODAL) ||
                isOpenSendConsignmentModal
              }
              onCloseSendConsignmentModal={handleCloseSendConsignmentModal}
            />
          )}
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
}
