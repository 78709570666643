import { useQuery } from 'react-query';
import { getThirdPartyModule } from '../services/integration-api';

export const useThirdPartyModulesQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: [`third-party-modules`],
    queryFn: () => getThirdPartyModule(),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
