import { useQueries } from 'react-query';
import { Text } from '@chakra-ui/react';

import {
  getGradingActionsPreferenceOptions,
  getGradingActionsPreferenceInformation,
  getGradingActionsPreference
} from '../services/preferences-api';
import { tranformGradeOptions } from '../mappers/grade-options-mapper';

const GradeLabel = (
  <Text fontSize="md" fontWeight="normal" color="dark.500">
    Select a grade
  </Text>
);

const useReturnSettingGradings = (enabled) => {
  const [
    { data: gradingOptions, isFetching: isFetchingGradingOptions },
    { data: gradingInformation, isFetching: isFetchingGradingInformation },
    { data: gradingActions, isFetching: isFetchingGradingActions }
  ] = useQueries([
    {
      queryKey: [`grading-actions-preference-option`],
      queryFn: () => getGradingActionsPreferenceOptions(),
      select: (data) => tranformGradeOptions(data),
      enabled: enabled
    },
    {
      queryKey: [`grading-actions-preference-information`],
      queryFn: () => getGradingActionsPreferenceInformation(),
      enabled: enabled
    },
    {
      queryKey: [`grading-actions-preference`],
      queryFn: () => getGradingActionsPreference(),
      enabled: enabled
    }
  ]);

  const gradeOptions = [
    {
      label: GradeLabel,
      options: gradingOptions
    }
  ];

  return {
    data: { gradingOptions: gradeOptions, gradingInformation, gradingActions },
    isFetching: isFetchingGradingOptions || isFetchingGradingInformation || isFetchingGradingActions
  };
};

export default useReturnSettingGradings;
