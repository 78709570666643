import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { ACTIVE_USER_SETTING_MODAL, COMPANY_NAVIGATE_MENU_STATUS } from 'constants/common';
import { useGlobalState } from 'contexts/GlobalContext';
import { DASHBOARD_PATHNAME } from 'modules/dashboard/constants';

const PaymentNewCardReturn = () => {
  const history = useHistory();
  const [{ app }, dispatch] = useGlobalState();
  useEffect(() => {
    dispatch({
      type: ACTIVE_USER_SETTING_MODAL,
      payload: {
        type: COMPANY_NAVIGATE_MENU_STATUS.paymentMethods,
        text: 'Payment methods'
      }
    });
  }, []);

  useEffect(() => {
    if (app && !app[ACTIVE_USER_SETTING_MODAL]) {
      history.push(DASHBOARD_PATHNAME);
    }
  }, [app]);

  return null;
};

export default PaymentNewCardReturn;
