import { Text } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';

const B2BShippingAddress = ({ address, ...rest }) => {
  const { addressLine1, addressLine2, country, postCode, town } = address.destinationAddress;

  return (
    <SlzPaper borderRadius={1.5} {...rest}>
      <Text>{addressLine1}</Text>
      <Text>{addressLine2}</Text>
      <Text>{town}</Text>
      <Text>{postCode}</Text>
      <Text>{country}</Text>
    </SlzPaper>
  );
};

export default B2BShippingAddress;
