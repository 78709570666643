import ConsignmentLabelling from '../ConsignmentLabelling';

const MultipleConsignmentLabelling = ({
  warehousesData,
  currentType,
  selectedConsignmentType,
  ...rest
}) => {
  return (
    <ConsignmentLabelling
      warehousesData={warehousesData}
      currentType={currentType}
      selectedConsignmentType={selectedConsignmentType}
      {...rest}
    />
  );
};

export default MultipleConsignmentLabelling;
