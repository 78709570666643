import { Box, Flex, Text, createIcon } from '@chakra-ui/react';

const CloseIcon = createIcon({
  displayName: 'CTACloseIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill="currentColor"
      d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
  )
});

const CustomErrorMessage = ({ message, fontSize, onClose, ...rest }) => {
  return (
    <Flex
      sx={{
        borderRadius: 1.5
      }}
      color="lightAndShadow.reverseText"
      bg="negative.500"
      alignItems="center"
      justifyContent="center"
      position="relative"
      {...rest}>
      <Text fontSize={fontSize}>{message}</Text>
      <CloseIcon
        sx={{ cursor: 'pointer', w: '1.5rem', h: '1.5rem', position: 'absolute', right: 3 }}
        onClick={onClose}
      />
    </Flex>
  );
};

export default CustomErrorMessage;
