import { Stack } from '@chakra-ui/react';
import { Fragment } from 'react';

import ProductSkeleton from 'components/common/Skeleton/templates/product';

import IdentifiersAndDetailsSection from 'modules/products/components/modal/form/IdentifiersAndDetailsSection';
import SalesAndPricingSection from 'modules/products/components/modal/form/SalesAndPricingSection';
import PrepackedProduct from 'modules/products/components/modal/form/PrepackedProduct';
import ReturnPreference from 'modules/products/components/modal/form/ReturnPreference';
import { PRODUCTS_STEPS } from 'modules/products/constants';

const ADAPTATION_SECTION_STEP = {
  [PRODUCTS_STEPS.OVERVIEW]: IdentifiersAndDetailsSection,
  [PRODUCTS_STEPS.SALES_PRICING]: SalesAndPricingSection,
  [PRODUCTS_STEPS.PACKAGES_PREFERENCE]: PrepackedProduct,
  [PRODUCTS_STEPS.RETURN_INFORMATION]: ReturnPreference
};

const AdaptationSection = (props) => {
  const { current, isEditMode = false } = props;
  const render = () => {
    const Component = ADAPTATION_SECTION_STEP[current?.id] || Fragment;

    return (
      <ProductSkeleton isLoaded showInfo={false} template={`section.${current.id}`}>
        <Component isEditable={isEditMode} />
      </ProductSkeleton>
    );
  };

  return <Stack id="Adaption">{render()}</Stack>;
};

export default AdaptationSection;
