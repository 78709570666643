import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const parts = ['clearButton', 'searchIcon', 'searchButton'];
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(() => ({
  searchButton: {
    _active: { color: 'dark.700', bg: 'main.300' },
    minW: '1.5rem'
  },
  clearButton: {
    minW: 'none',
    bg: 'light.500',
    color: 'dark.500',
    rounded: 'full',
    _hover: { bg: 'light.700' }
  },
  searchIcon: {
    color: 'currentColor'
  }
}));

const sizes = {
  lg: definePartsStyle({
    searchButton: {
      w: '2.625rem',
      h: '2.625rem'
    },
    clearButton: {
      w: '1.25rem',
      h: '1.25rem'
    },
    searchIcon: {
      w: '1.313rem',
      h: '1.313rem'
    }
  }),
  md: definePartsStyle({
    searchButton: {
      w: '1.8rem',
      h: '1.8rem'
    },
    clearButton: {
      w: '1.2rem',
      h: '1.2rem'
    },
    searchIcon: {
      w: '1.2rem',
      h: '1.2rem'
    }
  }),
  sm: definePartsStyle({
    searchButton: {
      w: '1.5rem',
      h: '1.5rem'
    },
    clearButton: {
      w: '1rem',
      h: '1rem'
    },
    searchIcon: {
      w: '1rem',
      h: '1rem'
    }
  }),
  xs: definePartsStyle({
    searchButton: {
      w: '1.5rem',
      h: '1.5rem'
    },
    clearButton: {
      w: '1.25rem',
      h: '1.25rem'
    },
    searchIcon: {
      w: '0.75rem',
      h: '0.75rem'
    }
  })
};

const defaultProps = {
  size: 'lg'
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps
});
