import { Fragment } from 'react';
import { HStack, VStack } from '@chakra-ui/react';

import { BoxHiddenScroll } from 'components/layout';
import { B2B_ORDER_ACTION_CONTENT, BULK_ORDER_CREATE_TEXT } from 'modules/b2b-orders/constants';
import { SlzButton } from 'components/common/SlzButton';
import {
  B2BOrderAction,
  B2BOrderOptionTable,
  B2BOrderSubmitted,
  B2BOrderSummarySection,
  B2BOrderSummaryTable,
  B2BProductTracking
} from '../other';
import {
  B2B_ORDER_SUMMARY_COLUMNS,
  BOX_SPLIT_SUMMARY_COLUMNS,
  BOX_SPLIT_SUMMARY_FOR_SEPARATE_SKU_COLUMNS
} from 'modules/b2b-orders/constants/b2b-summaries-orders-columns';
import {
  getB2BOrderSummaryData,
  getSeperateSkuSummaryData,
  getCustomBoxSplitSummaryData
} from 'modules/b2b-orders/utils';

const B2BOrderSummaryStep = ({ defaultValues: bulkOrder, isSubmitted, step, ...rest }) => {
  const { b2bOrderSummaryRows, orderDetailRows, shippingRows, b2bOrderOptionRows } =
    getB2BOrderSummaryData(bulkOrder);

  const { _, rows: seperateSkuSummaryRows } = bulkOrder?.boxSplits[0]
    ? getSeperateSkuSummaryData(bulkOrder?.boxSplits[0].boxes, bulkOrder.stockSelection)
    : null;

  const customBoxSplitSummaryRows = bulkOrder?.boxSplits[0]
    ? getCustomBoxSplitSummaryData(bulkOrder?.boxSplits[0].boxes)
    : null;

  return (
    <Fragment>
      {isSubmitted ? (
        <B2BOrderSubmitted {...rest} />
      ) : (
        <>
          <VStack w="full" alignItems="flex-start" spacing={0}>
            <B2BProductTracking
              step={step}
              selectedProducts={bulkOrder.stockSelection}
              boxesLength={bulkOrder?.boxSplits[0] ? bulkOrder?.boxSplits[0].boxes.length : 0}
              isShowTooltip={false}
              w="full"
              alignItems="flex-start"
            />
            <BoxHiddenScroll maxH="41.938rem" w="full">
              <VStack w="full" alignItems="flex-start" spacing={6}>
                <HStack w="full" alignItems="flex-start" spacing={3}>
                  <B2BOrderSummarySection
                    title={BULK_ORDER_CREATE_TEXT.SUMMARY.orderDetail}
                    rows={orderDetailRows}
                  />
                  <B2BOrderOptionTable rows={b2bOrderOptionRows} />
                </HStack>
                <B2BOrderSummaryTable
                  title={BULK_ORDER_CREATE_TEXT.SUMMARY.b2bOrderSummary}
                  columns={B2B_ORDER_SUMMARY_COLUMNS}
                  rows={b2bOrderSummaryRows}
                />

                {bulkOrder?.preferences?.requireSeperateSKUs && seperateSkuSummaryRows && (
                  <B2BOrderSummaryTable
                    title={BULK_ORDER_CREATE_TEXT.SUMMARY.boxSplitSummary}
                    columns={BOX_SPLIT_SUMMARY_FOR_SEPARATE_SKU_COLUMNS}
                    rows={seperateSkuSummaryRows}
                  />
                )}

                {bulkOrder?.preferences?.isBoxSplit && customBoxSplitSummaryRows && (
                  <B2BOrderSummaryTable
                    title={BULK_ORDER_CREATE_TEXT.SUMMARY.boxSplitSummary}
                    columns={BOX_SPLIT_SUMMARY_COLUMNS}
                    rows={customBoxSplitSummaryRows}
                  />
                )}

                <B2BOrderSummarySection
                  title={BULK_ORDER_CREATE_TEXT.SUMMARY.shipping}
                  rows={shippingRows}
                />
              </VStack>
            </BoxHiddenScroll>
          </VStack>
          <B2BOrderAction {...rest}>
            <SlzButton variant="solid" size="lg" onClick={() => rest.onNext()}>
              {B2B_ORDER_ACTION_CONTENT.next}
            </SlzButton>
          </B2BOrderAction>
        </>
      )}
    </Fragment>
  );
};

export default B2BOrderSummaryStep;
