import { Icon } from '@chakra-ui/react';

const StockboxDimensionsIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34165" data-name="Group 34165" transform="translate(-379 -352)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(379 352)"
          fill="#0973ea"
        />
        <g id="Group_34270" data-name="Group 34270" transform="translate(-1681.189 -248.304)">
          <path
            id="Path_5014"
            data-name="Path 5014"
            d="M2072.9,604.887a.539.539,0,0,1,.383.159l2.167,2.167a.541.541,0,0,1,0,.766l-7.583,7.583a.542.542,0,0,1-.766,0l-2.167-2.166a.542.542,0,0,1,0-.766l7.583-7.583A.54.54,0,0,1,2072.9,604.887Zm1.4,2.708-1.4-1.4-6.817,6.817,1.4,1.4Z"
            fill="#fafafa"
          />
          <path
            id="Path_5015"
            data-name="Path 5015"
            d="M2077.627,609.283a.539.539,0,0,1-.383-.159l-.812-.812a.541.541,0,0,1,.766-.766l.813.812a.542.542,0,0,1-.383.925Z"
            transform="translate(-5.271 -1.146)"
            fill="#fafafa"
          />
          <path
            id="Path_5016"
            data-name="Path 5016"
            d="M2074.627,612.283a.539.539,0,0,1-.383-.159l-.812-.812a.542.542,0,0,1,.766-.766l.812.813a.542.542,0,0,1-.383.924Z"
            transform="translate(-3.896 -2.521)"
            fill="#fafafa"
          />
          <path
            id="Path_5017"
            data-name="Path 5017"
            d="M2071.627,615.283a.539.539,0,0,1-.383-.159l-.812-.812a.542.542,0,0,1,.766-.766l.813.812a.542.542,0,0,1-.383.925Z"
            transform="translate(-2.521 -3.896)"
            fill="#fafafa"
          />
          <path
            id="Path_5018"
            data-name="Path 5018"
            d="M2068.627,618.283a.54.54,0,0,1-.383-.159l-.812-.812a.541.541,0,0,1,.766-.766l.813.812a.542.542,0,0,1-.383.925Z"
            transform="translate(-1.146 -5.271)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default StockboxDimensionsIcon;
