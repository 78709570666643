import { Box, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { HorizontalUserManagementCard, UserManagementAction } from '../other';
import { ROLES_USER_ICON } from 'modules/users/constants';

const UserManagementSummaryForm = ({ defaultValues, onNext, ...rest }) => {
  const methods = useForm({
    defaultValues: defaultValues
  });

  const transformSupplier = () => {
    const supplier = defaultValues.suppliers.find(({ id }) => id === defaultValues.roles);

    const transformSupplier = {
      ...supplier,
      icon: ROLES_USER_ICON[supplier.vendor][supplier.role]
    };

    return { ...transformSupplier, ...defaultValues };
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(onNext)}>
          <VStack alignItems="initial" gap={2} mb={10}>
            <HorizontalUserManagementCard {...transformSupplier()} />
          </VStack>
          <UserManagementAction {...rest} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default UserManagementSummaryForm;
