export const INPUT_STATUS = {
  ERROR: 'error',
  POSITIVE: 'positive'
};

const CountryList = [
  { code: 'AD', name: 'Andorra' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AG', name: 'Antigua and Barbuda' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AL', name: 'Albania' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AO', name: 'Angola' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AT', name: 'Austria' },
  { code: 'AU', name: 'Australia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AX', name: 'Åland Islands' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BI', name: 'Burundi' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BL', name: 'Saint Barthélemy' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BN', name: 'Brunei Darussalam' },
  { code: 'BO', name: 'Bolivia (Plurinational State of)' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BR', name: 'Brazil' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BY', name: 'Belarus' },
  { code: 'BZ', name: 'Belize' },
  { code: 'CA', name: 'Canada' },
  { code: 'CC', name: 'Cocos (Keeling) Islands' },
  { code: 'CD', name: 'Congo, Democratic Republic of the' },
  { code: 'CF', name: 'Central African Republic' },
  { code: 'CG', name: 'Congo' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CL', name: 'Chile' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CV', name: 'Cabo Verde' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czechia' },
  { code: 'DE', name: 'Germany' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EE', name: 'Estonia' },
  { code: 'EG', name: 'Egypt' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'ES', name: 'Spain' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FK', name: 'Falkland Islands (Malvinas)' },
  { code: 'FM', name: 'Micronesia (Federated States of)' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FR', name: 'France' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GE', name: 'Georgia' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'GR', name: 'Greece' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GU', name: 'Guam' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HM', name: 'Heard Island and McDonald Islands' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HR', name: 'Croatia' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HU', name: 'Hungary' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IL', name: 'Israel' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IN', name: 'India' },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'IQ', name: 'Iraq' },
  { code: 'IR', name: 'Iran (Islamic Republic of)' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IT', name: 'Italy' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JO', name: 'Jordan' },
  { code: 'JP', name: 'Japan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KM', name: 'Comoros' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'KP', name: "Korea (Democratic People's Republic of)" },
  { code: 'KR', name: 'Korea, Republic of' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'LA', name: "Lao People's Democratic Republic" },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LY', name: 'Libya' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MD', name: 'Moldova, Republic of' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MF', name: 'Saint Martin (French part)' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'ML', name: 'Mali' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'MO', name: 'Macao' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MT', name: 'Malta' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'MV', name: 'Maldives' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MX', name: 'Mexico' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NE', name: 'Niger' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NO', name: 'Norway' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NU', name: 'Niue' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'OM', name: 'Oman' },
  { code: 'PA', name: 'Panama' },
  { code: 'PE', name: 'Peru' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PL', name: 'Poland' },
  { code: 'PM', name: 'Saint Pierre and Miquelon' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'PS', name: 'Palestine, State of' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PW', name: 'Palau' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RS', name: 'Serbia' },
  { code: 'RU', name: 'Russian Federation' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SD', name: 'Sudan' },
  { code: 'SE', name: 'Sweden' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SM', name: 'San Marino' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SO', name: 'Somalia' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SS', name: 'South Sudan' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'SX', name: 'Sint Maarten (Dutch part)' },
  { code: 'SY', name: 'Syrian Arab Republic' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'TC', name: 'Turks and Caicos Islands' },
  { code: 'TD', name: 'Chad' },
  { code: 'TF', name: 'French Southern Territories' },
  { code: 'TG', name: 'Togo' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TW', name: 'Taiwan, Province of China' },
  { code: 'TZ', name: 'Tanzania, United Republic of' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UM', name: 'United States Minor Outlying Islands' },
  { code: 'US', name: 'United States of America' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VA', name: 'Holy See' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
  { code: 'VG', name: 'Virgin Islands (British)' },
  { code: 'VI', name: 'Virgin Islands (U.S.)' },
  { code: 'VN', name: 'Viet Nam' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'WF', name: 'Wallis and Futuna' },
  { code: 'WS', name: 'Samoa' },
  { code: 'YE', name: 'Yemen' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' }
].sort((a, b) => (a.name > b.name ? 1 : -1));

const RetailerOrderSubNavList = [
  { text: 'Dashboard', link: '/retailer/orders' },
  { text: 'Pending Orders', link: '/retailer/orders/pendingorders' },
  { text: 'Orders in Process', link: '/retailer/orders/inprocessorders' },
  { text: 'Processed Orders', link: '/retailer/orders/processedorders' },
  { text: 'Unsuccessful Orders', link: '/retailer/orders/unsuccessfulorders' },
  { text: 'Upload Order', link: '/retailer/orders/upload' },
  { text: 'Tracking Reference', link: '/retailer/orders/tracking' }
];

const BulkOrderSubNavList = [
  { text: 'Pending Orders', link: '/retailer/bulkorder' },
  { text: 'Orders in Process', link: '/retailer/bulkorder/inprocessorders' },
  { text: 'Processed Orders', link: '/retailer/bulkorder/processedorders' },
  { text: 'Unsuccessful Orders', link: '/retailer/bulkorder/unsuccessfulorders' },
  { text: 'Create Bulk Order', link: '/retailer/bulkorder/info' }
];

const ProductsSubNavList = [
  { text: 'Products', link: '/supplier/products' },
  { text: 'Add Product', link: '/supplier/products/add' },
  { text: 'Upload Product CSV', link: '/supplier/products/upload' },
  { text: 'Bundles', link: '/supplier/products/bundles' }
];

const InventorySubNavList = [
  { text: 'Inventory', link: '/supplier/stockconsignments' },
  { text: 'Send Mixed Products', link: '/supplier/stockconsignments/upload' },
  { text: 'Send Single Product', link: '/supplier/stockconsignments/create' },
  { text: 'On Hold Inventory', link: '/supplier/stockconsignments/quarantine' }
];

const PreferencesSubNavList = [
  { text: 'Global', link: '/retailer/preferences' },
  { text: 'Regional', link: '/retailer/preferences/region' },
  { text: 'Packaging', link: '/retailer/preferences/packaging' },
  { text: 'Security', link: '/retailer/preferences/security' },
  { text: 'Taxes', link: '/retailer/preferences/taxes' }
];

const EoriRegions = [
  { code: 'GB', name: 'Great Britain' },
  { code: 'NI', name: 'Northern Ireland' },
  { code: 'EU', name: 'European Union' }
];

const EUCountryList = [
  { code: 'AT', name: 'Austria' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czechia' },
  { code: 'DK', name: 'Denmark' },
  { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  { code: 'GR', name: 'Greece' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IT', name: 'Italy' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MT', name: 'Malta' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'RO', name: 'Romania' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'ES', name: 'Spain' },
  { code: 'SE', name: 'Sweden' }
].sort((a, b) => (a.name > b.name ? 1 : -1));

const InvoicingSubNavList = [
  { text: 'Invoice', link: '/company/invoices' },
  { text: 'Collection', link: '/company/invoices/collectionadvice' },
  { text: 'Remittance', link: '/company/invoices/remittanceadvice' }
];

const PaymentsSubNavList = [
  { text: 'Card Payment', link: '/retailer/finance/newcard' },
  { text: 'Direct Debit', link: '/retailer/finance/newdirectdebit' }
];

const PaymentsRestrictedSubNavList = [{ text: 'Card Payment', link: '/retailer/finance/newcard' }];

const TAG_DEFAULT_COLOR = 'pebbel.500';
const TAG_DEFAULT_NAME = 'custom tag';
const NO_AVATAR_URL = '/Images/no-avatar.png';
const IMAGE_ICON_URL = '/Images/image-icon.png';
const PRODUCT_EXAMPLE_CSV_FILE = '/files/ProductItemUploadExample.csv';
const PRODUCT_EXAMPLE_CSV_FILE_NAME = 'ProductItemUploadExample.csv';
const ORDER_EXAMPLE_CSV_FILE = '/files/StockUploadExample.csv';
const ORDER_EXAMPLE_CSV_FILE_NAME = 'StockUploadExample.csv';
const ORDER_CSV_INSTRUCTIONS_FILE = '/files/SelazarOrderUploadInstructions.pdf';
const ORDER_CSV_INSTRUCTIONS_FILE_NAME = 'SelazarOrderUploadInstructions.pdf';
const SEL_PREFIX_ID = 'SEL';

const CategoryOptions = [
  {
    label: 'Cameras & Optics',
    value: '94a9e4a2-0563-4215-b92a-1c7e5b49315a'
  },
  {
    label: 'Apparel & Accessories',
    value: '86032d1d-706f-49be-84ac-1e7e26bf2fe6'
  },
  {
    label: 'Furniture',
    value: '004f246d-b4c0-4517-8acc-3539ec9b915a'
  },
  {
    label: 'Hardware',
    value: '86b708ac-9a02-42b4-8e3b-4d39880c85b4'
  },
  {
    label: 'Luggage & Bags',
    value: 'b4bc6844-221a-415a-8c10-505869d8151b'
  },
  {
    label: 'Toys & Games',
    value: '61df4094-e4f6-47dc-9b74-5e1933a3264f'
  },
  {
    label: 'Home & Garden',
    value: '2e7dd35b-ab53-4916-96b4-7a9bba2abc1b'
  },
  {
    label: 'Media',
    value: '71d3a3d0-ca75-4b0e-b556-7cccbe021b30'
  },
  {
    label: 'Animals & Pet Supplies',
    value: '8e046a9c-a1bb-479a-b7b3-809434fa3d65'
  },
  {
    label: 'Religious & Ceremonial',
    value: 'e62821e2-d461-4e59-9d2b-9de32c208a85'
  },
  {
    label: 'Mature',
    value: '1fbb5cb2-b43e-4550-8259-a65b09ba7fa2'
  },
  {
    label: 'Health & Beauty',
    value: '7d174de1-c406-45a8-b415-bc4135d127f5'
  },
  {
    label: 'Vehicles & Parts',
    value: '4badd7a1-083d-4bba-b3bb-cf87578ff45c'
  },
  {
    label: 'Office Supplies',
    value: 'c788e5da-55ed-41bc-8235-d364495c11f7'
  },
  {
    label: 'Arts & Entertainment',
    value: '8412985b-8662-426e-8409-d93901fe85d5'
  },
  {
    label: 'Electronics',
    value: '39bcd949-7471-4064-8114-dc8b54f0aec5'
  },
  {
    label: 'Food, Beverages & Tobacco',
    value: 'd9b08592-fe38-4020-acb6-e9badc79ec56'
  },
  {
    label: 'Baby & Toddler',
    value: '64185c11-e9c6-4af5-8c2b-ec230a78d2da'
  },
  {
    label: 'Sporting Goods',
    value: '32b044a3-b227-4517-ab11-ec27e684429e'
  },
  {
    label: 'Business & Industrial',
    value: '599dc26d-6acc-4f8e-922f-f6d0322f4a3a'
  },
  {
    label: 'GB-Migration-Test',
    value: '8614719c-a503-470c-afe2-f8456e8e14ba'
  },
  {
    label: 'Software',
    value: '8614719c-a503-470c-afe2-f8456e8e14be'
  }
];

export const SKELETON_TYPE = {
  TABLE: 'table',
  MODAL: 'modal',
  DEFAULT: 'default',
  PAGE: 'page',
  PRODUCT_IMAGES_REQUIRED: 'product-images-required'
};

export const WAREHOUSE_DETAILS =  {
  WAREHOUSE_ID: 'WarehouseID', 
  WAREHOUSE_NAME: 'WarehouseName', 
  EXISTING_CUST: 'ExistingRef', 
  CUSTOMER_REFERENCE: 'CustomerRef'
};

export {
  CountryList,
  RetailerOrderSubNavList,
  BulkOrderSubNavList,
  ProductsSubNavList,
  InventorySubNavList,
  PreferencesSubNavList,
  EUCountryList,
  EoriRegions,
  InvoicingSubNavList,
  PaymentsSubNavList,
  PaymentsRestrictedSubNavList,
  TAG_DEFAULT_COLOR,
  TAG_DEFAULT_NAME,
  NO_AVATAR_URL,
  PRODUCT_EXAMPLE_CSV_FILE,
  PRODUCT_EXAMPLE_CSV_FILE_NAME,
  ORDER_EXAMPLE_CSV_FILE,
  ORDER_EXAMPLE_CSV_FILE_NAME,
  ORDER_CSV_INSTRUCTIONS_FILE,
  ORDER_CSV_INSTRUCTIONS_FILE_NAME,
  SEL_PREFIX_ID,
  IMAGE_ICON_URL,
  CategoryOptions
};


