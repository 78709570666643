import {
  Card,
  CardBody,
  CardFooter,
  CardIcon,
  CardPermissions,
  CardRoleBanner,
  CardRoleDescription,
  CardRolesAndPermissions
} from 'modules/users/common/cards/CardRolesAndPermissions';
import { STYLE_STEP_TWO_ROLE_USER } from 'modules/users/constants';

const AddRoleUserManagementCard = (props) => {
  const {
    children,
    icon,
    iconRight,
    label,
    text,
    bgLabel,
    bgIcon,
    description,
    permissions,
    isChecked,
    isError,
    radioProps
  } = props;
  return (
    <CardRolesAndPermissions styles={STYLE_STEP_TWO_ROLE_USER} {...radioProps}>
      <Card spacing={0} isChecked={isChecked} isError={isError}>
        {children}
        <CardIcon bg={bgIcon}>
          {icon}
          {iconRight}
        </CardIcon>
        <CardBody spacing={5}>
          <CardRoleBanner
            h="1.875rem"
            textAlign="center"
            fontSize="xl"
            bg={bgLabel}
            color="lightAndShadow.reverseText">
            {label}
          </CardRoleBanner>
          <CardRoleDescription>{description}</CardRoleDescription>
        </CardBody>
        <CardFooter spacing="0.75">
          <CardRoleBanner
            color="lightAndShadow.reverseText"
            w="13.625rem"
            h="1.75rem"
            textAlign="center"
            fontSize="sm"
            lineHeight="4"
            bg="main.500">
            {text}
          </CardRoleBanner>
          <CardPermissions minH="4.813rem" justifyContent="flex-start">
            {permissions}
          </CardPermissions>
        </CardFooter>
      </Card>
    </CardRolesAndPermissions>
  );
};

export default AddRoleUserManagementCard;
