import { Flex, Heading, VStack } from '@chakra-ui/react';

import { DotIcon } from 'components/common/Icons';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

import { RATE_MATCHER_STEP_INSTRUCTION_TEXT, PREFIX } from 'modules/integrations/constants';
import { RateMatcherShippingOption, RateMatcherText } from 'modules/integrations/components/other';
import { RateMatcherFormActions } from 'modules/integrations/components/form';
import { RateMatchingState } from 'modules/integrations/components/other/RateMatcherShippingOption';

const RateMatcherInstructionText = ({ children }) => {
  return (
    <Flex alignItems="baseline">
      <DotIcon w="3" h="3" color="main.500" mr="0.625rem" />
      <RateMatcherText>{children}</RateMatcherText>
    </Flex>
  );
};

const RateMatcherStepInstruction = ({
  shopName,
  shippingRates,
  isLoading,
  setIsLoaded,
  ...rest
}) => {
  return (
    <IntegrationSkeleton isLoaded={!isLoading} template={`${PREFIX}.${rest?.currentStep}`}>
      <VStack spacing={6}>
        <RateMatcherText>{RATE_MATCHER_STEP_INSTRUCTION_TEXT.header}</RateMatcherText>
        <VStack w="full" alignItems="flex-start">
          <Heading fontSize="1.188rem" color="dark.700" lineHeight="1.563rem" fontWeight="semibold">
            {RATE_MATCHER_STEP_INSTRUCTION_TEXT.steps}
          </Heading>
          <VStack spacing={6}>
            <RateMatcherInstructionText>
              <b>{RATE_MATCHER_STEP_INSTRUCTION_TEXT.step1}</b>
              {RATE_MATCHER_STEP_INSTRUCTION_TEXT.step1Description}
            </RateMatcherInstructionText>
            <RateMatcherInstructionText>
              <b>{RATE_MATCHER_STEP_INSTRUCTION_TEXT.step2}</b>
              {RATE_MATCHER_STEP_INSTRUCTION_TEXT.step2Description}
            </RateMatcherInstructionText>
          </VStack>
        </VStack>
        <RateMatcherShippingOption shopName={shopName} isLoading={isLoading}>
          <RateMatchingState
            numberOfMatchedRate={rest?.numberOfMatchedRate}
            numberOfShippingRate={rest?.numberOfShippingRate}
          />
        </RateMatcherShippingOption>
      </VStack>
      <RateMatcherFormActions {...rest} />
    </IntegrationSkeleton>
  );
};

export default RateMatcherStepInstruction;
