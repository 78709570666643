import { Icon } from '@chakra-ui/react';
import React from 'react';

const ShopifyIcon = (props) => {
  return (
    <Icon
      id="Shop_app_store"
      data-name="Shop app store"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84.266 24"
      {...props}>
      <g id="Shop_app_store" data-name="Shop app store" transform="translate(0 -0.053)">
        <path
          id="Path_8703"
          data-name="Path 8703"
          d="M18.1,4.559a.228.228,0,0,0-.206-.192c-.085-.007-1.752-.032-1.752-.032s-1.394-1.353-1.532-1.488a.581.581,0,0,0-.511-.065L13.4,3a4.884,4.884,0,0,0-.335-.824,2.362,2.362,0,0,0-2.1-1.45h0c-.061,0-.122.006-.182.012-.026-.031-.052-.061-.08-.092A1.84,1.84,0,0,0,9.239.054C8.1.084,6.973.9,6.057,2.361a9.152,9.152,0,0,0-1.274,3.3l-2.234.692c-.657.206-.678.227-.764.846C1.72,7.671,0,20.974,0,20.974l14.417,2.493,6.249-1.553S18.119,4.678,18.1,4.559ZM12.677,3.222l-1.119.346A6.189,6.189,0,0,0,11.214,1.5c.861.162,1.285,1.135,1.463,1.716ZM10.8,3.8,8.4,4.545A5.7,5.7,0,0,1,9.613,2.186a2.422,2.422,0,0,1,.817-.6A5.427,5.427,0,0,1,10.8,3.8Zm-1.546-3a1.161,1.161,0,0,1,.682.179,3.291,3.291,0,0,0-.882.686A6.606,6.606,0,0,0,7.567,4.8l-1.973.612C5.98,3.591,7.508.856,9.259.806Z"
          transform="translate(0 0)"
          fill="#95bf47"
        />
        <path
          id="Path_8704"
          data-name="Path 8704"
          d="M240.607,46.139c-.085-.007-1.752-.032-1.752-.032s-1.394-1.353-1.532-1.488a.342.342,0,0,0-.193-.089V65.239l6.248-1.553s-2.547-17.236-2.565-17.355A.228.228,0,0,0,240.607,46.139Z"
          transform="translate(-222.712 -41.772)"
          fill="#5e8e3e"
        />
        <path
          id="Path_8705"
          data-name="Path 8705"
          d="M70.3,120.032l-.726,2.715a4.112,4.112,0,0,0-1.769-.308c-1.406.089-1.422.976-1.406,1.2.077,1.216,3.271,1.479,3.45,4.324a3.363,3.363,0,0,1-3.1,3.891,4.646,4.646,0,0,1-3.561-1.21l.486-2.067a4.4,4.4,0,0,0,2.29.889.9.9,0,0,0,.879-.966c-.1-1.581-2.7-1.491-2.866-4.093-.138-2.189,1.3-4.409,4.474-4.61A3.916,3.916,0,0,1,70.3,120.032Z"
          transform="translate(-59.348 -112.451)"
          fill="#fff"
        />
        <path
          id="Path_8706"
          data-name="Path 8706"
          d="M399.176,153.622c-.724-.393-1.094-.724-1.094-1.18,0-.579.517-.952,1.325-.952a4.736,4.736,0,0,1,1.78.393l.657-2.028a4.18,4.18,0,0,0-2.4-.476c-2.493,0-4.222,1.428-4.222,3.435a3.177,3.177,0,0,0,1.885,2.628c.869.5,1.18.851,1.18,1.366,0,.538-.435.973-1.242.973a5.577,5.577,0,0,1-2.338-.621L394,159.189a5.389,5.389,0,0,0,2.814.7c2.566,0,4.408-1.262,4.408-3.539A3.35,3.35,0,0,0,399.176,153.622Z"
          transform="translate(-370.044 -140.247)"
        />
        <path
          id="Path_8707"
          data-name="Path 8707"
          d="M529.983,83.472a3.864,3.864,0,0,0-3.021,1.51l-.042-.02,1.094-5.732h-2.851L522.39,93.822h2.858l.952-4.986c.373-1.885,1.346-3.04,2.256-3.04.641,0,.89.435.89,1.056a6.478,6.478,0,0,1-.125,1.262l-1.078,5.707H531l1.117-5.9a10.681,10.681,0,0,0,.207-1.863C532.321,84.445,531.472,83.472,529.983,83.472Z"
          transform="translate(-490.628 -74.363)"
        />
        <path
          id="Path_8708"
          data-name="Path 8708"
          d="M703.71,149c-3.435,0-5.711,3.106-5.711,6.562a3.728,3.728,0,0,0,3.932,3.994c3.373,0,5.649-3.021,5.649-6.56A3.708,3.708,0,0,0,703.71,149Zm-1.407,8.36c-.973,0-1.387-.827-1.387-1.862,0-1.635.851-4.3,2.4-4.3,1.014,0,1.345.869,1.345,1.718,0,1.761-.848,4.451-2.359,4.451Z"
          transform="translate(-655.56 -139.89)"
        />
        <path
          id="Path_8709"
          data-name="Path 8709"
          d="M852.328,149a3.909,3.909,0,0,0-3.023,1.7h-.041l.165-1.532h-2.523c-.122,1.034-.352,2.608-.579,3.787l-1.986,10.447H847.2l.786-4.221h.061a3.325,3.325,0,0,0,1.676.373c3.353,0,5.546-3.435,5.546-6.912C855.264,150.72,854.418,149,852.328,149Zm-2.731,8.4a1.813,1.813,0,0,1-1.18-.413l.476-2.67c.331-1.78,1.262-2.959,2.255-2.959.869,0,1.138.807,1.138,1.573C852.284,154.776,851.19,157.4,849.6,157.4Z"
          transform="translate(-793.003 -139.89)"
        />
        <path
          id="Path_8710"
          data-name="Path 8710"
          d="M1065.841,81.65a1.63,1.63,0,0,0-1.634,1.656,1.314,1.314,0,0,0,1.345,1.428h.041a1.613,1.613,0,0,0,1.676-1.656A1.358,1.358,0,0,0,1065.841,81.65Z"
          transform="translate(-999.501 -76.635)"
        />
        <path
          id="Path_8711"
          data-name="Path 8711"
          d="M1025.41,162.9h2.856l1.945-10.12h-2.876Z"
          transform="translate(-963.063 -143.441)"
        />
        <path
          id="Path_8712"
          data-name="Path 8712"
          d="M1119.936,80.563h-1.986l.1-.475c.166-.973.745-1.842,1.7-1.842a2.964,2.964,0,0,1,.912.145l.557-2.232a3.767,3.767,0,0,0-1.552-.249,4.239,4.239,0,0,0-2.793.952,5.42,5.42,0,0,0-1.656,3.229l-.083.475h-1.324l-.414,2.152h1.324l-1.511,7.988h2.858l1.507-7.991h1.966Z"
          transform="translate(-1045.525 -71.244)"
        />
        <path
          id="Path_8713"
          data-name="Path 8713"
          d="M1215.7,152.78s-1.785,4.5-2.587,6.953h-.041c-.056-.79-.7-6.953-.7-6.953h-3l1.718,9.292a.615.615,0,0,1-.061.476,4.894,4.894,0,0,1-1.552,1.718,6.173,6.173,0,0,1-1.614.807l.786,2.422a6.525,6.525,0,0,0,2.8-1.552,19.284,19.284,0,0,0,3.745-5.67l3.5-7.491Z"
          transform="translate(-1134.42 -143.441)"
        />
      </g>
    </Icon>
  );
};

export default ShopifyIcon;
