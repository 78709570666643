import React from 'react';

import { HStack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import map from 'lodash/map';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { SlzButton } from 'components/common/SlzButton';
import { BoxesFields } from 'modules/b2b-orders/components/form';

const BoxesForm = ({ boxes, hasData, isEdit, onSaveBoxes }) => {
  const boxesMethods = useForm({
    defaultValues: { boxes }
  });

  const handleSaveBoxesTracking = (values) => {
    let boxesData = { ...values.boxes };

    if (isEdit) {
      boxesData = map(values?.boxes, (box, index) => {
        return every([box.trackingNumber, box.courierProvider], isEmpty) ? boxes[index] : box;
      });

      boxesMethods.setValue('boxes', boxesData);
      boxesMethods.reset({}, { keepValues: true });
    }

    onSaveBoxes({ ...values, boxes: boxesData }, boxesMethods.formState.isDirty);
  };

  const getButtonLabel = () => {
    if (isEdit) {
      return 'Save';
    }

    if (hasData) {
      return 'Edit';
    }

    return 'Add tracking';
  };

  return (
    <FormProvider {...boxesMethods}>
      <form
        onSubmit={boxesMethods?.handleSubmit(handleSaveBoxesTracking)}
        style={{ width: '100%' }}>
        <HStack spacing="3.5rem" mb={6}>
          <Text fontSize="1.25rem" fontWeight="bold" mb={0}>
            Box tracking
          </Text>
          <SlzButton variant="outline" borderRadius="6px" type="submit">
            {getButtonLabel()}
          </SlzButton>
        </HStack>

        <BoxesFields
          control={boxesMethods.control}
          register={boxesMethods.register}
          disabled={!isEdit}
        />
      </form>
    </FormProvider>
  );
};

export default BoxesForm;
