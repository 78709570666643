const USER_API = process.env.REACT_APP_USER_API;
const SUPPLIER_API = process.env.REACT_APP_SUPPLIER_API;
const COURIER_API = process.env.REACT_APP_COURIER_API;
const SYSTEM_API = process.env.REACT_APP_SYSTEM_API;
const RETAILER_API = process.env.REACT_APP_RETAILER_API;
const FULFILMENT_API = process.env.REACT_APP_FULFILMENT_API;
const INTERNAL_API = process.env.REACT_APP_SELAZAR_API;

const CommonEndpoints = {
  MAPS_URL:
    'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDXmh1OKZscTPQfdFhWF9SiiTItR84rn8k',

  LOGIN: USER_API + 'api/user/login',
  SELAZAR_LOGIN: USER_API + 'api/user/systemlogin',
  GET_ALL_USERS: USER_API + 'api/user/getall',
  GET_FILTERED_USERS: USER_API + 'api/user/by',
  GET_USER: USER_API + 'api/user/getbyid/',
  GET_USER_BY_ID: USER_API + 'api/user/getbyuserid/',
  GET_USER_WITH_ROLES: USER_API + 'api/user/getuserwithroles/',
  ADD_USER: USER_API + 'api/user/create',
  DELETE_USER: USER_API + 'api/user/',
  ADD_COMPANY_USER: USER_API + 'api/user/createforcompany',
  EDIT_COMPANY_USER: USER_API + 'api/user/editforcompany',
  EDIT_USER_DETAILS: USER_API + 'api/user/editdetails',
  TOGGLE_USER: USER_API + 'api/user/toggleuser/',
  GET_USERS_NAME_BY_USER_ID: USER_API + 'api/user/getusersnamebyuserid/',
  EDIT_PASSWORD_USER: USER_API + 'api/user/editpassword',

  ALL_ADDRESS_TYPES: USER_API + 'api/companyaddresstype/getall',

  GET_ADDRESS_SERVICES: USER_API + 'api/companyaddress/servicesofferedoptions',
  GET_LOCATION: USER_API + 'api/companyaddress/getbyid/',
  GET_LOCATIONS_BY_COMPANY: USER_API + 'api/companyaddress/getbycompanyid/',
  CREATE_LOCATION: USER_API + 'api/companyaddress/create',
  UPDATE_LOCATION: USER_API + 'api/companyaddress/update',
  DELETE_LOCATION: USER_API + 'api/companyaddress/delete/',
  TOGGLE_COMPANY_ADDRESS: USER_API + 'api/companyaddress/toggle/',

  ALL_BILLING_DETAILS: USER_API + 'api/billingdetails/getbillingdetailsbycompanyid/',
  UPDATE_BILLING_DETAILS: USER_API + 'api/billingdetails/update',

  GET_ALL_ROLES: USER_API + 'api/role/getall/',
  GET_ROLE_OPTIONS_FOR_COMPANY: USER_API + 'api/role/getroleoptionsforcompany/',

  GET_FEATURE_BREAKDOWN_FOR_USER: USER_API + 'api/feature/byuser/',
  GET_FEATURES_FOR_USER: USER_API + 'api/feature/getfeaturesforuser/',
  GET_FEATURES_BY_COMPANY: USER_API + 'api/feature/getfeaturesbycompanyid/',
  UPDATE_OR_CREATE_FEATUREUSER: USER_API + 'api/featureuser/updateorcreatefeatureuser/',

  ALL_ORDERS: SUPPLIER_API + 'api/order/getall',
  CURRENT_ORDERS: SUPPLIER_API + 'api/order/getcurrentbycompanyid/',
  EXPORT_CSV: SUPPLIER_API + 'api/orderstocklocation/exportordersbycompanycsv/',

  ALL_STATUSES: SUPPLIER_API + 'api/status/getall',

  ALL_PROMOTION_TYPES: SUPPLIER_API + 'api/promotiontype/getall',
  ALL_PROMOTIONS: SUPPLIER_API + 'api/promotion/getallpromotionswithtypedetails',
  CREATE_PROMOTION: SUPPLIER_API + 'api/promotion/create',
  UPDATE_PROMOTION: SUPPLIER_API + 'api/promotion/update',
  DELETE_PROMOTION: SUPPLIER_API + 'api/promotion/delete/',

  GET_ALL_SERVICES: COURIER_API + 'api/companyservice/getcompanyservicesbycompanyid/',
  GET_SERVICE: COURIER_API + 'api/companyservice/getbyidwithservice/',
  GET_SERVICE_TIMES: COURIER_API + 'api/companyservice/getservicetimes',
  FREEZE_SERVICE: COURIER_API + 'api/companyservice/freeze/',
  DEFROST_SERVICE: COURIER_API + 'api/companyservice/unfreeze/',
  CREATE_SERVICE: COURIER_API + 'api/companyservice/create',
  UPDATE_SERVICE: COURIER_API + 'api/companyservice/update',
  DELETE_SERVICE: COURIER_API + 'api/companyservice/delete/',
  GET_ALL_SERVICE_REGIONS: COURIER_API + 'api/serviceregion/getall',

  CATEGORY: {
    GET: {
      ALL: SUPPLIER_API + 'api/category/getall'
    }
  },

  CUSTOM_TAGS: {
    GET: {
      ALL: SUPPLIER_API + 'api/itemcustomtag/getallitemcustomtagsforcompany'
    }
  },

  STOCK: {
    GET: {
      ALL: SUPPLIER_API + 'api/product/getstockforcompany',
      BY: SUPPLIER_API + 'api/product/by',
      BY_ID: SUPPLIER_API + 'api/product/getbyid/',
      COUNT: SUPPLIER_API + 'api/product/getstockcount/',
      TOTAL_STOCK_COUNT: SUPPLIER_API + 'api/product/totalstock/',
      SKUS: SUPPLIER_API + 'api/product/:id/skus',
      UNRECEIVED_UNPROCESSED: SUPPLIER_API + 'api/stockcsv/getunreceivedandunprocessedstock',
      FOR_COMPANY: SUPPLIER_API + 'api/stockcsv/getstockforcompany',
      CONSIGNMENTS_BY: SUPPLIER_API + 'api/stockcsv/listincludeexternal',
      BY_ID_WITH_ITEMS: SUPPLIER_API + 'api/stockcsv/detailsincludeexternal',
      MOST_RECENT_PROCESSED: SUPPLIER_API + 'api/stockcsv/getmostrecentprocessedstock',
      QUARANTINE: SUPPLIER_API + 'api/stockcsv/quarantine',
      EXPORT_CSV: SUPPLIER_API + 'api/companyitem/export',
      PRODUCT_BUNDLES: SUPPLIER_API + 'api/productbundles',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle/',
      PRODUCT_BUNDLE_SKU_CHECK: SUPPLIER_API + 'api/productbundle/sku/',
      PRODUCT_NO_IMAGE: SUPPLIER_API + 'api/companyitem/noimage',
      GET_RETURN_INFO_BY_ID: SUPPLIER_API + 'api/productreturnsettings/getforcompanyitem/',
      LABEL_DOWNLOAD: SUPPLIER_API + 'api/stockconsignmentlabel/labeldownload'
    },
    POST: {
      UPLOAD_CSV: SUPPLIER_API + 'api/stockcsv/upload',
      UPLOAD_ITEM_CSV: SUPPLIER_API + 'api/companyitemcsv/upload',
      CREATE: SUPPLIER_API + 'api/product/createproduct',
      UPLOAD_IMAGES: SUPPLIER_API + 'api/itemimage/upload/',
      UPLOAD_IMAGE: SUPPLIER_API + 'api/itemimage/uploadsingle',
      DOWNLOAD_LABELS: SUPPLIER_API + 'api/stockconsignmentlabel/download/',
      DOWNLOAD_FULFILMENT_LABELS: SUPPLIER_API + 'api/stockconsignmentlabel/downloadforfulfilment/',
      QUALITY_REPORT_RESPONSE: SUPPLIER_API + 'api/stockcsv/qualityreport/response',
      CLEAR_DELETED_SKU_STOCK_COUNTS: SUPPLIER_API + 'api/companyItem/cleardeletedskustock/',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle',
      SET_RETURN_INFO_BY_ID: SUPPLIER_API + 'api/productreturnsettings/setforcompanyitem/'
    },
    PUT: {
      UPDATE: SUPPLIER_API + 'api/product/updateproduct',
      ADD_STOCK: SUPPLIER_API + 'api/stockcsv/uploadsingle/',
      AFFIX_LABELS: SUPPLIER_API + 'api/stockcsv/labelsaffixed',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle'
    },
    DELETE: {
      ITEM_IMAGE: SUPPLIER_API + 'api/itemimage/delete/',
      IMAGE_FOR_SKU: SUPPLIER_API + 'api/itemimage/deleteforstockkeepingunit/',
      PRODUCT_BUNDLE: SUPPLIER_API + 'api/productbundle/'
    }
  },

  INTEGRATIONS: {
    GET: {
      ALL: RETAILER_API + 'api/integrations',
      INTEGRATION_BY_ID: RETAILER_API + 'api/integration/',
      VERIFY_PASSWORD: RETAILER_API + 'api/verifyPassword'
    },
    SHOPIFY: {
      GET: {
        SHIPPING_RATES_PREFIX: RETAILER_API + 'api/integration/shopify/',
        SHIPPING_RATES_SUFFIX: '/shippingrates'
      },
      POST: {
        CREATE: RETAILER_API + 'api/integration/shopify',
        SHIPPING_RATES_PREFIX: RETAILER_API + 'api/integration/shopify/',
        SHIPPING_RATES_SUFFIX: '/shippingrates'
      },
      PUT: {
        UPDATE: RETAILER_API + 'api/integration/shopify/'
      }
    },
    WOOCOMMERCE: {
      POST: {
        CREATE: RETAILER_API + 'api/integration/woocommerce'
      },
      PUT: {
        UPDATE: RETAILER_API + 'api/integration/woocommerce/'
      }
    },
    APP: {
      GET: {
        INTEGRATIONS: RETAILER_API + 'api/thirdpartyintegration/all',
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/',
        MODULES: RETAILER_API + 'api/thirdpartyintegration/modules'
      },
      PUT: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/edit'
      },
      POST: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/create'
      },
      DELETE: {
        INTEGRATION: RETAILER_API + 'api/thirdpartyintegration/'
      }
    }
  },

  ORDERS: {
    GET: {
      ORDER_COLLECTION_PROVIDER_ID_BY_ORDER_ID: SUPPLIER_API + 'api/order/getordercollectionprovideridbyorderid/',
      AWAITING_FULFILMENT_ORDER_COUNT: RETAILER_API + 'api/order/awaitingfulfilment/count',
      ORDER_COUNT: RETAILER_API + 'api/order/countorders',
      ORDER_AVERAGE: RETAILER_API + 'api/order/averagecost',
      POPULAR_ORDERS: RETAILER_API + 'api/order/items/popular',
      TOTALS_BY_AREA: RETAILER_API + 'api/order/area/totals',
      FULFILMENT_TOTALS: RETAILER_API + 'api/order/fulfilment/totals/today',
      TOTALS_BY_DATE: RETAILER_API + 'api/order/totals/bydate',
      COSTS_BY_DATE: RETAILER_API + 'api/order/costs/bydate',
      UNPROCESSED: RETAILER_API + 'api/order/unprocessed',
      UNPROCESSED_TODAY: RETAILER_API + 'api/order/unprocessed/totals/today',
      AWAITING_FULFILMENT_TODAY: RETAILER_API + 'api/order/awaitingfulfilment/totals/today',
      COMPLETED_TODAY: RETAILER_API + 'api/order/completed/totals/today',
      RECENT_FAILED: RETAILER_API + 'api/order/failed/recent',
      TRACKING: RETAILER_API + 'api/order/tracking',
      ITEMS: RETAILER_API + 'api/order/',
      TODAY_ORDER_COUNT_BY_STATUS: RETAILER_API + 'api/order/{status}/totals/today',
      ON_HOLD_DETAILS: RETAILER_API + 'api/order/queue/details/',
      UNSUCCESSFUL_DETAILS: RETAILER_API + 'api/order/unsuccessful/details/'
    },
    POST: {
      UPLOAD: RETAILER_API + 'api/ordercsv/upload',
      CREATE: RETAILER_API + 'api/orderwizard/createorder',
      CREATE_INTERNAL_EXTERNAL_ORDER: RETAILER_API + 'api/createinternalexternalorder',      
    },
    PUT: {
      UPDATE_EXTERNAL_ORDER: RETAILER_API + 'api/order/updateexternalorder'
    },
    DELETE: {
      DELETE_EXTERNAL_ORDER: RETAILER_API + 'api/order/deleteexternal'
    }
  },

  ADDRESS_BOOK: {
    GET: {
      ADDRESS_BY_COMPANY: USER_API + 'api/companycustomeraddress/by/'
    },
    POST: {
      CUSTOMER_ADDRESS:
        USER_API + 'api/companycustomeraddress/createcompanycustomeraddressforcompany'
    },
    PUT: {
      CUSTOMER_ADDRESS:
        USER_API + 'api/companycustomeraddress/updatecompanycustomeraddressforcompany/'
    },
    DELETE: {
      CUSTOMER_ADDRESS:
        USER_API + 'api/companycustomeraddress/deletecompanycustomeraddressforcompany/'
    }
  },

  COMPANY: {
    GET: {
      COMPANY_BY_ID: USER_API + 'api/company/getbyid/'
    }
  },

  FINANCE: {
    CARD: {
      POST: {
        NEW: RETAILER_API + 'api/carddetails/addcard'
      },
      DELETE: {
        REMOVE: RETAILER_API + 'api/carddetails/remove'
      },
      PUT: {
        REPLACE: RETAILER_API + 'api/carddetails/replace'
      },
      GET: {
        LATEST: RETAILER_API + 'api/carddetails/latest',
        HAS_VALID_DETAILS: RETAILER_API + 'api/carddetails/checkcardvalidity'
      }
    },
    DIRECT_DEBIT: {
      GET_FOR_COMPANY: USER_API + 'api/companydirectdebit/getforcompany',
      ENABLED: USER_API + 'api/companydirectdebit/enabled',
      CREATE: RETAILER_API + 'api/directdebitdetails/createsession',
      CURRENT: RETAILER_API + 'api/directdebitdetails/current',
      DISABLE_CURRENT: RETAILER_API + 'api/directdebitdetails/disablecurrent',
      REMOVE_CURRENT: RETAILER_API + 'api/directdebitdetails/removecurrent',
      HAS_VALID_DETAILS: RETAILER_API + 'api/directdebitdetails/checkcardvalidity'
    }
  },

  SELAZAR: {
    USERS: {
      GET: {
        ALL: USER_API + 'api/user/getallforselazar'
      }
    },
    ISSUES: {
      GET: {
        RAISED_BY: SYSTEM_API + 'api/issue/getissuesraisedbyselazar',
        RAISED_BY_COUNT: SYSTEM_API + 'api/issue/getissuescountraisedbyselazar',
        RAISED_AGAINST: SYSTEM_API + 'api/issue/getissuesraisedagainstselazar',
        RAISED_AGAINST_COUNT: SYSTEM_API + 'api/issue/getissuescountraisedagainstselazar',
        NOTIFY_COMPANIES: SYSTEM_API + 'api/issue/getnotifycompanies/',
        ACTOR_COMPANIES: SYSTEM_API + 'api/issue/getactorcompanies/',
        MOST_COMMON_TYPE: SYSTEM_API + 'api/issuetype/getmostcommonissuetype',
        ASSIGNED_TO_ME_COUNT: SYSTEM_API + 'api/issue/getissuescountassignedtome'
      }
    },
    ORDERS: {
      GET: {
        PENDING_ORDERS_COUNT: INTERNAL_API + 'api/order/getcountofpendingorders',
        TOTAL_ORDERS_COUNT: INTERNAL_API + 'api/order/getcountofallorders',
        ORDERS_COUNT_THIS_MONTH: INTERNAL_API + 'api/order/getcountofallordersthismonth',
        ORDERS_COUNT_TODAY: INTERNAL_API + 'api/order/getcountofallorderstoday',
        TOTAL_ORDERS_VALUE: INTERNAL_API + 'api/order/gettotalvalueofallorders',
        ORDERS_VALUE_THIS_MONTH: INTERNAL_API + 'api/order/gettotalvalueofallordersthismonth',
        ORDERS_VALUE_TODAY: INTERNAL_API + 'api/order/gettotalvalueofallorderstoday'
      }
    }
  },

  ISSUES: {
    GET: {
      ALL_ACTOR_ISSUES_BY_COMPANY: SYSTEM_API + 'api/issue/getissuesbyactorid/',
      ALL_RAISED_BY_ISSUES_BY_COMPANY: SYSTEM_API + 'api/issue/getissuesbyraisedbyid/',
      ASSIGNED_TO_ME: SYSTEM_API + 'api/issue/getissuesassignedtome'
    },
    PUT: {
      MARK_AS_READ: SYSTEM_API + 'api/issue/markissueasread/',
      ASSIGN_TO_USER: SYSTEM_API + 'api/issue/assignissuetouser/',
      ASSIGN_TO_ME: SYSTEM_API + 'api/issue/assignissuetome/'
    },
    POST: {
      CREATE: SYSTEM_API + 'api/issue/create'
    }
  },

  ISSUE_TYPES: {
    GET: {
      ALL: SYSTEM_API + 'api/issuetype/getall',
      BY_AOC: SYSTEM_API + 'api/issuetype/getbyareaofconcern/',
      BY_PARTY: SYSTEM_API + 'api/issuetype/getbyparty/'
    }
  },

  INVOICES: {
    GET: {
      ALL: INTERNAL_API + 'api/invoice/getforcompany',
      TYPES: INTERNAL_API + 'api/invoice/gettypes'
    }
  },

  COLLECTION_ADVICE: {
    GET: {
      ALL: INTERNAL_API + 'api/collectionadvice/getforcompany'
    }
  },

  REMITTANCE_ADVICE: {
    GET: {
      ALL: INTERNAL_API + 'api/remittanceadvice/getforcompany'
    }
  },

  RESOLUTION_TYPES: {
    GET: {
      BY_ISSUE: SYSTEM_API + 'api/resolutiontype/getresolutiontypesbyissueid/'
    }
  },

  RESOLUTIONS: {
    POST: {
      RESOLVE: SYSTEM_API + 'api/resolution/resolve'
    }
  },

  REGULAR_COLLECTIONS: {
    GET: {
      FOR_CURRENT_COMPANY: COURIER_API + 'api/regularcollection/getallforcurrentcompany'
    },
    PUT: {
      ACTIVATE: COURIER_API + 'api/regularcollection/activate/',
      DEACTIVATE: COURIER_API + 'api/regularcollection/deactivate/'
    }
  },

  AD_HOC_COLLECTIONS: {
    GET: {
      FOR_CURRENT_COMPANY: COURIER_API + 'api/collection/getallforcurrentcompany',
      COLLECTION_COMPANY_COLLECTION_ID:
        COURIER_API + 'api/collection/getcollectioncompanyidbycollectionid/'
    }
  },

  RETAILER: {
    GET: {
      ALL_ORDERS: RETAILER_API + 'api/order/allorders',
      ORDERS: RETAILER_API + 'api/order/getall',
      PENDING: RETAILER_API + 'api/order/pending',
      UNSUCCESSFUL: RETAILER_API + 'api/order/unsuccessful',
      PROCESSING: RETAILER_API + 'api/order/processing',
      PROCESSED: RETAILER_API + 'api/order/processed',
      PARTIAL: RETAILER_API + 'api/order/partial',
      ONHOLD: RETAILER_API + 'api/order/queue',
      ORDER_COST_BREAKDOWN: RETAILER_API + 'api/order/costbreakdown/',
      ORDER_TRACKING_ROOT_URL: RETAILER_API + 'api/order/trackingrooturl/',
      UNPROCESSED_ORDER_CSV: RETAILER_API + 'api/ordercsv/getunprocessedordercsvs',
      PROCESSED_RETURNS: RETAILER_API + 'api/return/processedreturns',
      UNPROCESSED_RETURNS: RETAILER_API + 'api/return/unprocessedreturns',
      COURIER_PARTNER_PREFERENCES: RETAILER_API + 'api/partnerpreference/courier',
      COURIER_REGIONAL_PREFERENCES: RETAILER_API + 'api/regionpreference',
      COURIER_PRICE_BAND_PREFERENCES: RETAILER_API + 'api/courierpricebandpreference',
      SERVICES_TIMES_OPTIONS: RETAILER_API + 'api/regionpreference/options/servicetimes',
      WORLD_AREA_OPTIONS: RETAILER_API + 'api/regionpreference/options/worldareas',
      APPROVED_COURIERS: RETAILER_API + 'api/courierpricebandpreference/couriers',
      CUSTOMER_SERVICE_ACCESS: RETAILER_API + 'api/securitypreference/customerserviceaccess',
      BULK_ORDER_PENDING: RETAILER_API + 'api/bulkorder/pending',
      BULK_ORDER_PROCESSING: RETAILER_API + 'api/bulkorder/processing',
      BULK_ORDER_PROCESSED: RETAILER_API + 'api/bulkorder/processed',
      BULK_ORDER_UNSUCCESSFUL: RETAILER_API + 'api/bulkorder/unsuccessful',
      BULK_ORDER_DETAILS: RETAILER_API + 'api/bulkorder/details/',
      ORDER_DETAILS: RETAILER_API + 'api/order/details',
      EORI_DETAILS: RETAILER_API + 'api/taxdetails/eori',
      VAT_DETAILS: RETAILER_API + 'api/taxdetails/vat',
      IOSS_DETAILS: RETAILER_API + 'api/taxdetails/ioss',
      WAREHOUSES: RETAILER_API + 'api/warehouse/getall',
      WAREHOUSE_SELECTION: RETAILER_API + 'api/warehouse/selection',
      PARTIAL_ORDER_PREFERENCE: RETAILER_API + 'api/partialorderpreference',
      CONFIGURATION_RETURNS: RETAILER_API + 'api/returns/config',
      RETURN_PREFERENCES: RETAILER_API + 'api/returnspreference/returnpreference'
    },
    POST: {
      COURIER_PARTNER_PREFERENCES: RETAILER_API + 'api/partnerpreference/courier',
      COURIER_REGIONAL_PREFERENCES: RETAILER_API + 'api/regionpreference',
      COURIER_PRICE_BAND_PREFERENCES: RETAILER_API + 'api/courierpricebandpreference',
      SECURITY_PREFERENCE: RETAILER_API + 'api/securitypreference',
      BULK_ORDER_SKU_SPLIT_VALIDATE: RETAILER_API + 'api/bulkorder/skusplit/validate/',
      BULK_ORDER_NO_PACKAGING_VALIDATE: RETAILER_API + 'api/bulkorder/nopackaging/validate',
      BULK_ORDER_MANUAL_SPLIT_VALIDATE: RETAILER_API + 'api/bulkorder/manualsplit/validate',
      BULK_ORDER_SELECTED_ITEMS_VALIDATE: RETAILER_API + 'api/bulkorder/selectedstock/validate',
      BULK_ORDER_SUBMIT: RETAILER_API + 'api/bulkorder/submit',
      BULK_ORDER_UPDATE_PAYLOAD: RETAILER_API + 'api/bulkorder/updatepayload',
      UPDATE_EORI: RETAILER_API + 'api/taxdetails/eori',
      REMOVE_EORI: RETAILER_API + 'api/taxdetails/eori/remove/',
      UPDATE_VAT: RETAILER_API + 'api/taxdetails/vat',
      UPDATE_IOSS: RETAILER_API + 'api/taxdetails/ioss',
      REMOVE_IOSS: RETAILER_API + 'api/taxdetails/ioss/remove',
      UPDATE_ORDER_PREFERENCE: RETAILER_API + 'api/partialorderpreference/toggle',
      TOGGLE_RETURN_PREFERENCES: RETAILER_API + 'api/returnspreference/toggle',
      RETURN_APPLY_TO_ALL: RETAILER_API + 'api/returnspreference/all',
      RETURN_APPLY_TO_NEW: RETAILER_API + 'api/returnspreference/new',
      GRADING_APPLY_TO_ALL: RETAILER_API + 'api/gradingactionspreference/all',
      GRADING_APPLY_TO_NEW: RETAILER_API + 'api/gradingactionspreference/new',
      ORDER_DETAILS_WITH_EXTERNAL: RETAILER_API + 'api/order/detailsincludeexternal'
    },
    PUT: {
      COURIER_PRICE_BAND_PREFERENCES: RETAILER_API + 'api/courierpricebandpreference',
      CANCEL_ORDER: RETAILER_API + 'api/ordercsv/cancelorder',
      CANCEL_SHOPIFY_ORDER: RETAILER_API + 'api/shopifyorder/cancel',
      CANCEL_WOOCOMMERCE_ORDER: RETAILER_API + 'api/woocommerceorder/cancel',
      BULK_ORDER_CANCEL: RETAILER_API + 'api/bulkorder/cancel/',
      ENABLE_RETURNS: RETAILER_API + 'api/returns/enable',
      DISABLE_RETURNS: RETAILER_API + 'api/returns/disable'
    },
    DELETE: {
      COURIER_PRICE_BAND_PREFERENCES: RETAILER_API + 'api/courierpricebandpreference'
    }
  },

  WAREHOUSE_SETTING: {
    GET: {
      WAREHOUSES: USER_API + 'api/company/{id}/warehouses',
      SELECTION: RETAILER_API + 'api/warehouse/selection',
      ALL: RETAILER_API + 'api/warehouse/getall'
    },
    POST: {
      SELECTION: USER_API + 'api/warehouse/createforcurrentcompany'
    }
  },

  RETURNS: {
    GET: {
      RETAILER_RETURNS: RETAILER_API + 'api/retailerreturns/processedreturns',
      RETAILER_RETURN_DETAIL: RETAILER_API + 'api/retailerreturns/',
      OPTIONS: RETAILER_API + 'api/gradingactionspreference/options',
      INFORMATION: RETAILER_API + 'api/gradingactionspreference/information',
      GRADE_PREFERENCES: RETAILER_API + 'api/gradingactionspreference/gradingpreferences',
      RETURN_BY_ID: RETAILER_API + 'api/retailerreturns/'
    },
    POST: {
      TOGGLE_GRADING_PREFERENCES: RETAILER_API + 'api/gradingactionspreference/toggle',
      GRADING_APPLY_TO_ALL: RETAILER_API + 'api/gradingactionspreference/all',
      GRADING_APPLY_TO_NEW: RETAILER_API + 'api/gradingactionspreference/new'
    }
  },

  COURIER: {
    GET: {
      ACTIVE: COURIER_API + 'api/company/active',
      COMPANY_SUPPORTED_AREAS: COURIER_API + 'api/companysupportedarea'
    }
  },

  FULFILMENT: {
    GET: {
      LABELS: FULFILMENT_API + 'api/labelprintqueue/getqueue',
      NUMBER_OF_ORDERS: FULFILMENT_API + 'api/order/countawaitingfulfilmenttoday',
      NUMBER_OF_COLLECTIONS: FULFILMENT_API + 'api/order/countawaitingcollectiontoday',
      INBOUND_STOCK_LABELS: FULFILMENT_API + 'api/checkinrun/labels'
    }
  },

  REGISTRATION: {
    POST: {
      RETAILER: {
        REGISTER: USER_API + 'api/registration/retailer',
        RESEND_VERIFICATION_CODE: USER_API + 'api/registration/resendverificationcode'
      },
      EMAIL: {
        CONFIRM: USER_API + 'api/confirmemailrequest/confirm/'
      }
    },
    GET: {
      CONFIRM_EMAIL_REQUEST: USER_API + 'api/confirmemailrequest/getliverequestbyid/'
    }
  },

  // NOTIFICATION: {
  //     GET: {
  //         TOAST: SYSTEM_API + 'api/notificationtoast/getlatest'
  //     },
  //     PUT: {
  //         MARK_AS_READ: SYSTEM_API + 'api/notificationtoast/markasread/'
  //     }
  // },

  PASSWORD_RESET: {
    GET: {
      BY_ID: USER_API + 'api/passwordresetrequest/getliverequestbyid/'
    },
    PUT: {
      CHANGE_PASSWORD: USER_API + 'api/passwordresetrequest/changepassword',
      RESET_PASSWORD: USER_API + 'api/passwordresetrequest/resetpassword/'
    }
  },

  INTERNAL: {
    GET: {
      BULK_ORDER_AVAILABLE_SERVICE_TIMES: INTERNAL_API + 'api/bulkorder/getavailableservicetimes'
    },
    POST: {
      BULK_ORDER_FIND_SHIPPING_OPTIONS: INTERNAL_API + 'api/bulkorder/couriers/calculate'
    },
    GET: {
      BULK_ORDER_LIMITS: INTERNAL_API + 'api/bulkorder/limits',
      BULK_ORDER_PACKING_SLIP: INTERNAL_API + 'api/BulkOrder/generate/',
      BULK_ORDER_AVAILABLE_SERVICE_TIMES: INTERNAL_API + 'api/bulkorder/getavailableservicetimes'
    }
  },

  PACKAGING: {
    GET: {
      CUSTOM_OVERVIEW: RETAILER_API + 'api/custompackaging/overview',
      BOXES: RETAILER_API + 'api/custompackaging/boxes',
      MAILING_BAGS: RETAILER_API + 'api/custompackaging/mailingbags',
      BOX: RETAILER_API + 'api/custompackaging/box/',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag/',
      EXTRAS: RETAILER_API + 'api/packagingextra/packagingextras'
    },
    PUT: {
      ARCHIVE: RETAILER_API + 'api/custompackaging/archive/',
      ENABLE: RETAILER_API + 'api/custompackaging/enable/',
      DISABLE: RETAILER_API + 'api/custompackaging/disable/',
      BOX: RETAILER_API + 'api/custompackaging/box/',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag/'
    },
    POST: {
      EXTRA: RETAILER_API + 'api/packagingextra',
      BOX: RETAILER_API + 'api/custompackaging/box',
      MAILING_BAG: RETAILER_API + 'api/custompackaging/mailingbag',
      ENFORCE: RETAILER_API + 'api/custompackaging/enforcepackaging'
    }
  },

  WAREHOUSE: {
    GET: {
      WAREHOUSES: USER_API + 'api/company/{id}/warehouses',
      ACCESS_REQUEST_BY_ID: USER_API + 'api/companywarehouseaccessrequest/{id}',
      USES_EXTERNAL_WMS: USER_API + 'api/warehouse/usesexternalwms'
    },
    POST: {
      CREATE_WAREHOUSE_REQUEST_ACCESS: USER_API + 'api/companywarehouseaccessrequest/create'
    }
  },

  GET_UNRESOLVED_ISSUES_BY_COMPANY: SYSTEM_API + 'api/issue/getunresolvedissuesbycompanyid/',
  GET_UNRESOLVED_ISSUES_BY_USER: SYSTEM_API + 'api/issue/getunresolvedissuesbyuserid/',
  GET_UNREAD_AND_UNRESOLVED_COUNTS: SYSTEM_API + 'api/issue/getunreadandunresolvedcounts/'
};

export default CommonEndpoints;
