import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from 'config/axios';
import { INTEGRATION_TYPE } from '../constants';
import { buildQuery } from 'utils/helper';

export const getIntegrations = async () => {
  return await axiosClient.request(CommonEndpoints.INTEGRATIONS.GET.ALL);
};

export const createIntegrateWooCommerceApi = async (payload) => {
  return await axiosClient.post(CommonEndpoints.INTEGRATIONS.WOOCOMMERCE.POST.CREATE, payload);
};

export const createIntegrateShopifyApi = async (payload) => {
  return await axiosClient.post(CommonEndpoints.INTEGRATIONS.SHOPIFY.POST.CREATE, payload);
};

export async function updateIntegration(id, payload) {
  try {
    const endpointMap = {
      [INTEGRATION_TYPE.woocommerce]: `${CommonEndpoints.INTEGRATIONS.WOOCOMMERCE.PUT.UPDATE}${id}`,
      [INTEGRATION_TYPE.shopify]: `${CommonEndpoints.INTEGRATIONS.SHOPIFY.PUT.UPDATE}${id}`
    };

    const endpoint = endpointMap[payload?.integrationType];
    const { data } = await axiosClient.put(endpoint, payload);
    return data;
  } catch (err) {
    return err;
  }
}

export const getIntegrationById = async (id) => {
  const url = `${CommonEndpoints.INTEGRATIONS.GET.INTEGRATION_BY_ID}${id}`;
  return await axiosClient.request(url);
};

export const getShippingRatesByIntegrationId = async (id) => {
  const url = `${CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX}${id}${CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX}`;
  return await axiosClient.request(url);
};

export const getRegionPreferenceOptions = async () => {
  return await axiosClient.request(CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS);
};

export const getServiceTimes = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS}`;
  return await axiosClient.request(url);
};

export const getCurrentPreferences = async () => {
  const url = `${CommonEndpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES}`;
  return await axiosClient.request(url);
};

export const createShippingRatesByIntegrationId = async (id, payload) => {
  const url = `${CommonEndpoints.INTEGRATIONS.SHOPIFY.POST.SHIPPING_RATES_PREFIX}${id}${CommonEndpoints.INTEGRATIONS.SHOPIFY.POST.SHIPPING_RATES_SUFFIX}`;
  return await axiosClient.post(url, payload);
};

export const getThirdPartyIntegration = async () => {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.GET.INTEGRATIONS}`;
  return await axiosClient.request(url);
};

export const deleteThirdPartyIntegration = async (id) => {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.DELETE.INTEGRATION}${id}`;
  return await axiosClient.remove(url);
};
export const getThirdPartyModule = async () => {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.GET.MODULES}`;
  return await axiosClient.request(url);
};
export const getApiConnectionDetail = async (id) => {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.GET.INTEGRATION}${id}`;
  return await axiosClient.request(url);
};

export async function createNewApiIntegration(payload) {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.POST.INTEGRATION}`;
  return await axiosClient.post(url, payload);
}

export async function editApiIntegration(payload) {
  const url = `${CommonEndpoints.INTEGRATIONS.APP.PUT.INTEGRATION}`;
  return await axiosClient.put(url, payload);
}

export async function verifyPassword(params) {
  const url = `${CommonEndpoints.INTEGRATIONS.GET.VERIFY_PASSWORD}`;
  return await axiosClient.request(buildQuery(url, params));
}
