import { Icon } from '@chakra-ui/react';
import React from 'react';

const HeadphoneIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 18 20"
      {...props}>
      <g id="Group_33739" data-name="Group 33739" transform="translate(-1249.773 -893.887)">
        <path
          id="Path_3842"
          data-name="Path 3842"
          d="M1252.773,903.887h0a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2v-2A2,2,0,0,1,1252.773,903.887Z"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3843"
          data-name="Path 3843"
          d="M1264.773,903.887h0a2,2,0,0,1,2,2v2a2,2,0,0,1-2,2h0a2,2,0,0,1-2-2v-2A2,2,0,0,1,1264.773,903.887Z"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3844"
          data-name="Path 3844"
          d="M1250.773,905.887v-3a8,8,0,0,1,16,0v3"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3845"
          data-name="Path 3845"
          d="M1264.773,909.887c0,1.657-2.687,3-6,3"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default HeadphoneIcon;
