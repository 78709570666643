import { Fragment, useEffect } from 'react';
import { GridItem } from '@chakra-ui/react';

import { SlzGrid } from 'components/common/SlzGrid';
import { useGlobalState } from 'contexts/GlobalContext';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';

import { BREAD_CRUMB_ITEMS, DELIVERY_DESTINATION_CHART_HOLDER } from 'modules/dashboard/constants';
import {
  DeliveryDestination,
  OrdersToday,
  RecentUnsuccessfulOrdersList,
  OrderStatistics,
  MostPopularProduct,
  LineGraphChart
} from 'modules/dashboard/components/other';
import {
  transformDeliveryDestinations,
  transformMostPopularProduct,
  transformOrders
} from 'modules/dashboard/mappers/dashboard-mapper';
import {
  useDeliveryDestinationQuery,
  usePopularProductQuery,
  useOrderCostsQuery,
  useOrderTotalsQuery,
  useOrdersStatistics24HoursQuery,
  useOrdersStatistics28DaysQuery
} from 'modules/dashboard/hooks';
import { isLineChartNoData } from '../utils';

const Dashboard = () => {
  const [_, dispatch] = useGlobalState();
  const { data: ordersStatistics24Hours, isFetching: isFetchingOrdersStatistics24Hours } =
    useOrdersStatistics24HoursQuery({
      enabled: true
    });

  const { data: ordersStatistics28days, isFetching: isFetchingOrdersStatistics28days } =
    useOrdersStatistics28DaysQuery({
      enabled: true
    });

  const {
    data: deliveryDestinationData,
    isFetching: isFetchingDeliveryDestination,
    refetch: refetchDeliveryDestination
  } = useDeliveryDestinationQuery({
    enabled: true,
    select: transformDeliveryDestinations
  });
  const { data: mostPopularProducts, isFetching: isLoadingPopularProducts } =
    usePopularProductQuery({
      enabled: true,
      select: transformMostPopularProduct
    });

  const {
    data: orderCosts,
    isLoading: isOrderCostLoading,
    isSuccess: isFetchCostSuccess,
    refetch: refetchOrderCosts
  } = useOrderCostsQuery({
    select: transformOrders
  });

  const {
    data: orderTotals,
    isLoading: isOrderTotalsLoading,
    isSuccess: isFetchTotalSuccess,
    refetch: refetchOrderTotals
  } = useOrderTotalsQuery();

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_ITEMS
    });
  }, []);

  return (
    <Fragment>
      <SlzGrid>
        <GridItem colSpan={{ base: 6, sm: 6, md: 12, lg: 6, xl: 6 }}>
          <OrderStatistics
            data24Hours={ordersStatistics24Hours}
            data28Days={ordersStatistics28days}
            isLoading={isFetchingOrdersStatistics24Hours || isFetchingOrdersStatistics28days}
          />
        </GridItem>
        <GridItem colSpan={{ base: 3, sm: 3, md: 6, lg: 3, xl: 3 }}>
          <OrdersToday />
        </GridItem>
        <GridItem colSpan={{ base: 3, sm: 3, md: 6, lg: 3, xl: 3 }}>
          <DeliveryDestination
            data={
              isFetchingDeliveryDestination
                ? DELIVERY_DESTINATION_CHART_HOLDER
                : deliveryDestinationData
            }
            isLoading={isFetchingDeliveryDestination}
            onReload={refetchDeliveryDestination}
            isEmpty={!deliveryDestinationData?.total}
          />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 12, md: 6, lg: 4, xl: 4 }}>
          <RecentUnsuccessfulOrdersList />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 12, md: 4, lg: 3, xl: 3 }}>
          <MostPopularProduct products={mostPopularProducts} isLoading={isLoadingPopularProducts} />
        </GridItem>
        <GridItem colSpan={{ base: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
          <LineGraphChart
            title="Order value (£)"
            data={orderCosts}
            mainColor="primrose.500"
            lineColor="primrose.700"
            loadingColor="aconitePurple.300"
            isLoading={isOrderCostLoading}
            isSuccess={isFetchCostSuccess && !isLineChartNoData(orderCosts)}
            refetch={refetchOrderCosts}
          />
          &emsp;
          <LineGraphChart
            title="Total orders"
            data={orderTotals}
            mainColor="sunrise.500"
            lineColor="sunrise.700"
            loadingColor="halfAndCatchFire.300"
            isLoading={isOrderTotalsLoading}
            unit="orders"
            isSuccess={isFetchTotalSuccess && !isLineChartNoData(orderTotals)}
            refetch={refetchOrderTotals}
          />
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
};

export default Dashboard;
