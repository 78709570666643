import { HStack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { SlzButton } from 'components/common/SlzButton';
import useAuth from 'hooks/useAuth';
import useSlzToast from 'hooks/useSlzToast';

import { WAREHOUSE_SETTING_CONTENT } from 'modules/preferences/constants';

const ConfirmSelection = ({ warehouseName, handleSubmitWarehouse, toast }) => (
  <HStack ml="1.5rem">
    <Text fontSize="md" mr="2.875rem">
      {WAREHOUSE_SETTING_CONTENT.toast.confirm.description(warehouseName)}
    </Text>
    <SlzButton
      borderRadius="0.375rem"
      size="md"
      variant="solid"
      color="success"
      colorScheme="light"
      onClick={toast.closeAll}>
      Cancel
    </SlzButton>
    <SlzButton
      onClick={() => handleSubmitWarehouse()}
      borderRadius="0.375rem"
      size="md"
      variant="outline"
      color="light.500"
      borderColor="light.500"
      colorScheme="positive">
      Confirm
    </SlzButton>
  </HStack>
);

const SuccessfulSelection = ({ warehouseName }) => (
  <Text fontSize="md" mr="2.875rem">
    {WAREHOUSE_SETTING_CONTENT.toast.success.description(warehouseName)}
  </Text>
);

const CONFIRM_CONTENTS = {
  200: SuccessfulSelection,
  0: ConfirmSelection
};

const ConfirmSelectedWarehouse = ({
  warehouse = null,
  refetchSelectedWarehouse,
  useSelectWarehouse,
  updateRequiredSettings
}) => {
  const [returnStatus, setReturnStatus] = useState(0);
  const [_, __, toast] = useSlzToast();
  const { setUser } = useAuth();

  useEffect(() => {
    if (returnStatus === 200) {
      updateRequiredSettings();
      refetchSelectedWarehouse();
    }
  }, [returnStatus]);

  const handleSubmitWarehouse = async () => {
    const { status, data } = await useSelectWarehouse.mutateAsync(warehouse);
    if (status === 200) {
      setReturnStatus(status);
      setUser(data.data.userToken);
      window.location.reload();
    }
  };
  const Component = CONFIRM_CONTENTS[returnStatus];

  return (
    <Component
      handleSubmitWarehouse={handleSubmitWarehouse}
      toast={toast}
      warehouseId={warehouse.id}
      warehouseName={warehouse.name}
    />
  );
};

export default ConfirmSelectedWarehouse;
