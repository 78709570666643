import { Text } from '@chakra-ui/react';

const UserFormHeading = ({ text, ...rest }) => {
  return (
    <Text
      fontSize="custom.2xl"
      fontWeight="black"
      color="szrNavy.500"
      lineHeight={11}
      mb={4}
      {...rest}>
      {text}
    </Text>
  );
};

export default UserFormHeading;
