import { useQuery } from 'react-query';
import { getProducts } from '../services/bulk-orders-api';

export const useBulkOrderProductQuey = ({ filters, select, enabled }) => {
  return useQuery({
    queryKey: [`bulk-orders`, filters],
    queryFn: () => getProducts(filters),
    cacheTime: 60 * 1000,
    select: select,
    enabled: enabled
  });
};
