import React from 'react';
import { Icon } from '@chakra-ui/react';

const VerticalEmptyBarChart = () => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="70.707"
      height="70.707"
      viewBox="0 0 70.707 70.707">
      <g id="Group_160617" data-name="Group 160617" transform="translate(-51.332 -1952.664)">
        <path
          id="Line_722"
          data-name="Line 722"
          d="M4.05,24.252A5.051,5.051,0,0,1-1,19.2V4.05a5.05,5.05,0,0,1,10.1,0V19.2A5.051,5.051,0,0,1,4.05,24.252Z"
          transform="translate(52.332 1999.119)"
          fill="#c3c4cb"
        />
        <path
          id="Line_723"
          data-name="Line 723"
          d="M4.05,39.4A5.051,5.051,0,0,1-1,34.353V4.05a5.05,5.05,0,0,1,10.1,0v30.3A5.051,5.051,0,0,1,4.05,39.4Z"
          transform="translate(72.534 1983.967)"
          fill="#c3c4cb"
        />
        <path
          id="Line_724"
          data-name="Line 724"
          d="M4.05,54.555A5.051,5.051,0,0,1-1,49.5V4.05a5.05,5.05,0,0,1,10.1,0V49.5A5.051,5.051,0,0,1,4.05,54.555Z"
          transform="translate(92.736 1968.816)"
          fill="#c3c4cb"
        />
        <path
          id="Line_725"
          data-name="Line 725"
          d="M4.05,69.707A5.051,5.051,0,0,1-1,64.656V4.05a5.05,5.05,0,0,1,10.1,0V64.656A5.051,5.051,0,0,1,4.05,69.707Z"
          transform="translate(112.938 1953.664)"
          fill="#c3c4cb"
        />
      </g>
    </Icon>
  );
};

export default VerticalEmptyBarChart;
