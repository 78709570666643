import { Icon } from '@chakra-ui/react';
import React from 'react';

const PersonCircleIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95" {...props}>
      <svg>
        <g id="Group_34350" data-name="Group 34350" transform="translate(0 0)">
          <g id="Group_34351" data-name="Group 34351" transform="translate(0 0)">
            <path
              id="Path_10012"
              data-name="Path 10012"
              d="M95,47.5A47.5,47.5,0,1,0,16.634,83.529c.082.075.155.153.242.222a47.316,47.316,0,0,0,61.163.078,4.483,4.483,0,0,0,.434-.4A47.369,47.369,0,0,0,95,47.5m-85.5,0A38,38,0,1,1,76.507,71.993,23.894,23.894,0,0,0,57.015,61.751H37.992A23.576,23.576,0,0,0,18.473,71.968,37.8,37.8,0,0,1,9.5,47.5"
              transform="translate(0 0.001)"
              fill="#0973ea"
            />
            <path
              id="Path_10013"
              data-name="Path 10013"
              d="M31.477,46.319a19,19,0,1,0-19-19,19.021,19.021,0,0,0,19,19"
              transform="translate(16.022 10.683)"
              fill="#0973ea"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default PersonCircleIcon;
