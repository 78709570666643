import { GridItem } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useTableFilterHandler from 'hooks/useTableFilterHandler';
import { SlzGrid } from '../../../components/common/SlzGrid';
import { useGlobalState } from '../../../contexts/GlobalContext';
import { useQuery as useRouterQuery } from '../../../hooks/useQuery';
import { SET_BREADCRUMB_LINKS } from '../../../reducers/appReducer';
import BillingFilter from '../components/form/BillingFilter';
import { BillingModal } from '../components/modal';
import RemittanceTable from '../components/table/RemittanceTable';
import InvoiceTable from '../components/table/InvoiceTable';
import BillingTabs from '../components/table/BillingTabs';
import CollectionTable from '../components/table/CollectionTable';

import {
  BILLING_BREADCRUMB_TEXT,
  BILLING_DEFAULT_PAGE,
  BILLING_DEFAULT_PAGE_SIZE,
  BILLING_PARAMS,
  BILLING_PROCESS,
  BILLING_PROCESSES,
  BILLING_PROCESS_LIST,
  BILLING_REMITTANCE,
  BREAD_CRUMB_ITEMS,
  INVOICE_TYPE,
  NUMBER_MAPPER
} from '../constants';
import { PAGE_INDEX, PAGE_SIZE, DATE_FROM, DATE_TO } from 'constants/table.js';
import { useBillingQuery } from '../hooks/useBillingQuery';
import { transformBilling } from '../mappers/billing-list-mapper';
import { useInvoiceTypeQuery } from '../hooks/useInvoiceTypeQuery';
import { useWarehouseQuery } from '../hooks/useWarehouseQuery';
import { transformInvoiceTypes } from '../mappers/invoice-type-mapper';
import { DATE_DISPLAY_FORMAT_YYYYMMDD } from '../../../constants';

const defaultBillingForRenderSkeleton = {
  id: null,
  customerReference: null,
  customerOrganizationPersonName: null,
  status: null,
  trackingCode: null,
  source: null,
  createdDate: null,
  billingContents: []
};

const BILLING_DATA = {
  id: 'a036bd4c-3936-419e-3bed-08db04b082fe',
  invoiceType: 'ORDERS',
  number: 'INV519',
  date: '09/01/2023',
  collectionAdviceNumber: 'CA1000968',
  scheduleType: 'SCHEDULED',
  paymentAttemptDate: '16/01/2023',
  totalCharge: 150.97,
  paymentMethod: 'Direct debit',
  paymentReference: 'pi_3MLyEfDRWFjp9Npj0gDWZSdz',
  customerAddress: {
    line: '118 Regent Street',
    town: 'Soho',
    county: 'London',
    country: 'England',
    postcode: 'W1B 5FEP'
  },
  collectionFrom: '24/10/2022',
  collectionTo: '30/10/2022',
  process: BILLING_PROCESS.REMITTANCE_ADVICE,
  invoiceType: INVOICE_TYPE.STOCK_PROCESSING,
  finalTotal: '£76.56',
  invoiceTotalCost: '£61.95',
  adminChargeCost: '£1.86',
  vatCost: '£12.76',
  cardPayment: '£76.56',
  adminCharge: '3.00%',
  vat: '20.00%'
};

export default function BillingList() {
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const billingProcess = routerQuery.get(BILLING_PARAMS.PROCESS) ?? BILLING_PROCESS.INVOICE;
  // const isOpenModal = BILLING_PROCESS_LIST.includes(billingProcess);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [billingParams, setBillingParams] = useState({ process: billingProcess });
  const [enableQuery, setEnableQuery] = useState();
  const [selectedRowData, setSelectedRowData] = useState(BILLING_DATA);
  const [selectedInvoiceTypes, setSelectedInvoiceTypes] = useState([]);
  const [tabIndex, setTabIndex] = useState(
    billingProcess ? BILLING_PROCESSES.findIndex((process) => process.key === billingProcess) : 0
  );

  const [_, dispatch] = useGlobalState();

  const { data: wareHouses } = useWarehouseQuery({
    enabled: billingProcess === BILLING_PROCESS.INVOICE
  });
  const { data, isFetching, isError, refetch, remove } = useBillingQuery({
    filters: billingParams,
    select: (data) => transformBilling({ billing: data, process: billingProcess, wareHouses }),
    enableQuery
  });

  const { data: invoiceTypes } = useInvoiceTypeQuery({
    select: (data) => transformInvoiceTypes(data),
    enabled: billingProcess === BILLING_PROCESS.INVOICE
  });

  const { handleCurrentPageChange, handleItemPerPageChange, handleSelectedDateRange } =
    useTableFilterHandler();

  useEffect(() => {
    setBillingParams({
      process: routerQuery.get(BILLING_PARAMS.PROCESS) || BILLING_PROCESSES[tabIndex]?.key,
      dateFrom: routerQuery.get(DATE_FROM) ?? '',
      dateTo: routerQuery.get(DATE_TO) ?? '',
      pageIndex: routerQuery.get(PAGE_INDEX) ?? BILLING_DEFAULT_PAGE,
      pageSize: routerQuery.get(PAGE_SIZE) ?? BILLING_DEFAULT_PAGE_SIZE,
      invoiceType: routerQuery.get('invoiceType') ?? '',
      orderDescending: true
    });
  }, [routerQuery]);

  useEffect(() => {
    setEnableQuery(true);
  }, [billingParams]);

  useEffect(() => {
    const breadCrumb = [...BREAD_CRUMB_ITEMS, { title: BILLING_BREADCRUMB_TEXT[billingProcess] }];

    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: breadCrumb
    });
  }, [billingProcess]);

  const onTabChange = (index) => {
    setTabIndex(index);
    switchBillingProcess(BILLING_PROCESSES[index]?.key);
    remove();
  };

  const switchBillingProcess = (process) => {
    routerQuery.set(BILLING_PARAMS.PROCESS, process);
    history.push({ search: routerQuery.toString() });
  };

  const closeModal = () => {
    // routerQuery.delete(BILLING_PARAMS.PROCESS);
    // history.push({ search: routerQuery.toString() });
    setIsOpenModal(false);
    setSelectedRowData(BILLING_DATA);
  };

  const handleFilterByInvoiceTypeChange = (options) => {
    if (options && options.length > 0) {
      setBillingParams({
        ...billingParams,
        invoiceType: options.map((option) => option.key)
      });
      setSelectedInvoiceTypes(options.map((option) => option.key));
    } else {
      setBillingParams({
        ...billingParams,
        invoiceType: ''
      });
      setSelectedInvoiceTypes([]);
    }
  };

  const handleOpenModal = (row) => {
    setIsOpenModal(true);
    setSelectedRowData({
      ...BILLING_DATA,
      process: billingProcess,
      invoiceType: row.type,
      number: row[NUMBER_MAPPER[billingProcess]] || BILLING_DATA.number
    });
  };

  useEffect(() => {
    const tabIndex = BILLING_PROCESSES.findIndex((process) => process.key === billingProcess);
    setTabIndex(tabIndex);
  }, [routerQuery.get(BILLING_PARAMS.PROCESS)]);

  return (
    <Fragment>
      <BillingModal isOpen={isOpenModal} onClose={closeModal} data={selectedRowData} />
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <BillingFilter
            wareHouses={wareHouses}
            process={billingProcess}
            handleSelectedDateRange={handleSelectedDateRange}
            handleOpenModal={handleOpenModal}
          />
          <BillingTabs tabIndex={tabIndex} onChange={onTabChange} />
          {billingProcess === BILLING_PROCESS.INVOICE && (
            <InvoiceTable
              data={data?.items || []}
              totalPage={data?.totalPage}
              queryParams={billingParams}
              isLoading={isFetching}
              onRefetching={refetch}
              isEmptyPage={isError || data?.totalPage === 0}
              messageToast="Sorry! We could not retrieve your billing information"
              invoiceTypes={invoiceTypes}
              selectedInvoiceTypes={selectedInvoiceTypes}
              handleCurrentPageChange={handleCurrentPageChange}
              handleItemPerPageChange={handleItemPerPageChange}
              handleFilterByInvoiceTypeChange={handleFilterByInvoiceTypeChange}
              handleOpenModal={handleOpenModal}
            />
          )}
          {billingProcess === BILLING_PROCESS.REMITTANCE_ADVICE && (
            <RemittanceTable
              data={data?.items || []}
              totalPage={data?.totalPage}
              queryParams={billingParams}
              isLoading={isFetching}
              onRefetching={refetch}
              isEmptyPage={isError || data?.totalPage === 0}
              messageToast="Sorry! We could not retrieve your billing information"
              handleCurrentPageChange={handleCurrentPageChange}
              handleItemPerPageChange={handleItemPerPageChange}
              handleOpenModal={handleOpenModal}
            />
          )}
          {billingProcess === BILLING_PROCESS.COLLECTION_ADVICE && (
            <CollectionTable
              data={data?.items || []}
              totalPage={data?.totalPage}
              queryParams={billingParams}
              isLoading={isFetching}
              onRefetching={refetch}
              isEmptyPage={isError || data?.totalPage === 0}
              messageToast="Sorry! We could not retrieve your billing information"
              handleCurrentPageChange={handleCurrentPageChange}
              handleItemPerPageChange={handleItemPerPageChange}
              handleOpenModal={handleOpenModal}
            />
          )}
        </GridItem>
      </SlzGrid>
    </Fragment>
  );
}
