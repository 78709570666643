import { Box } from '@chakra-ui/react';

const PreferencePaper = ({ children, ...rest }) => {
  return (
    <Box boxShadow="slz-md" borderRadius={3} bg="light.500" {...rest}>
      {children}
    </Box>
  );
};

export default PreferencePaper;
