import { Link } from '@chakra-ui/react';
import { TbBuildingWarehouse } from 'react-icons/tb';

import { PaymentMethodIcon } from 'components/common/Icons';

import { COMPANY_NAVIGATE_MENU_STATUS, SETTING_NAVIGATE_MENU_STATUS } from 'constants/common';

export const REQUIRED_SETTINGS_CONTENT = {
  common: {
    title: 'Please complete the following steps before starting to use our platform',
    note: (
      <>
        Our team is ready to assist you in selecting the perfect warehouse and add the all the
        necessary payment Information to commence your journey with us. You can call us on{' '}
        <Link color="main.500" href="tel:+4402039507860">
          +44 (0)20 3950 7860
        </Link>{' '}
        or email us at{' '}
        <Link textDecor="underline" color="main.500" href="mailto:customer.service@selazar.com">
          customer.service@selazar.com
        </Link>
      </>
    )
  },
  [SETTING_NAVIGATE_MENU_STATUS.warehouse]: {
    title: '. Select Your Warehouse',
    description: 'To start trading, you must select a warehouse from our network',
    icon: <TbBuildingWarehouse size="1.875rem" />,
    text: 'Select a warehouse',
    button: 'Select a warehouse'
  },
  [COMPANY_NAVIGATE_MENU_STATUS.paymentMethods]: {
    title: '. Add a payment method',
    description: 'To kickstart our services, we require a payment method on file',
    icon: <PaymentMethodIcon w="2.188rem" h="1.75rem" />,
    text: 'Add a payment method',
    button: 'Add card'
  }
};

export const SETTING_TEXT = {
  [COMPANY_NAVIGATE_MENU_STATUS.paymentMethods]: 'Payment methods',
  [SETTING_NAVIGATE_MENU_STATUS.warehouse]: 'Warehouse settings'
};

export const REQUIRED_SETTINGS = [
  SETTING_NAVIGATE_MENU_STATUS.warehouse,
  COMPANY_NAVIGATE_MENU_STATUS.paymentMethods
];
