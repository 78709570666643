import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';

const baseStyle = defineStyle((props) => {
  const { theme } = props;
  return {
    container: {
      position: 'relative',
      color: getColor(theme, 'dark.700'),
      m: '0.375rem 1.5rem 0 0',
      '.left': {
        mr: 6
      },
      hr: {
        background: getColor(theme, 'dark.300'),
        position: 'absolute',
        height: '96%',
        width: '0.063rem',
        top: '0.25rem',
        right: 0
      }
    },
    definedRangeHeader: {
      paddingLeft: 6,
      paddingTop: '0.625rem',
      marginBottom: '0.375rem',
      color: 'dark.700',
      fontWeight: 600
    },
    definedRangeContent: {
      minWidth: '7.875rem',
      fontSize: '0.875rem',
      ul: {
        listStyle: 'none',
        p: 0,
        m: 0,
        textAlign: 'left',
        li: {
          cursor: 'pointer',
          h: '1.875rem',
          listStyle: 'none',
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 6,
          '&:hover': {
            backgroundColor: `light.700`
          },
          '&.active': {
            backgroundColor: `main.300`
          }
        }
      }
    }
  };
});

export default defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'main'
  }
});
