import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import {
  updateEORIDetails,
  updateIOSSNumber,
  updateVatRegisteredCountries
} from '../services/preferences-api';
import { USER_SETTING_FORMS_DEFAULT_NAME } from '../constants';
import { checkEuVatEnabled } from '../utils';

const useTaxesDutiesPreferenceMutation = () =>
  useMutation({
    mutationFn: async (payload) => {
      try {
        const {
          [USER_SETTING_FORMS_DEFAULT_NAME.vat]: vatForm,
          [USER_SETTING_FORMS_DEFAULT_NAME.eoriNumber]: eoriForm,
          [USER_SETTING_FORMS_DEFAULT_NAME.iossNumber]: iossNumberForm
        } = payload;
        !isEmpty(eoriForm) &&
          (await Promise.all(
            eoriForm?.eoriNumbers?.map(async (eoriNumber) => {
              eoriNumber?.eoriNumber && updateEORIDetails(eoriNumber);
            })
          ));

        !isEmpty(vatForm) &&
          checkEuVatEnabled(eoriForm?.eoriNumbers) &&
          (await updateVatRegisteredCountries({ VatCountries: vatForm }));

        !isEmpty(iossNumberForm) && (await updateIOSSNumber(iossNumberForm));

        return;
      } catch (error) {
        return { error: error };
      }
    }
  });

export default useTaxesDutiesPreferenceMutation;
