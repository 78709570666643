import { useEffect, useState } from 'react';
import { HStack, StackItem, Text } from '@chakra-ui/react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';

import { SlzToggle } from 'components/common/SlzToggle';
import useSlzToast from 'hooks/useSlzToast';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

import {
  ENABLE_RETURNS,
  PREFERENCES_ACTIONS,
  RETURN_SETTINGS_PREFERENCES_TEXT,
  USER_SETTING_FORMS_DEFAULT_NAME,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { PreferencePaper } from 'modules/preferences/components/common';
import { getProductNoImage } from 'modules/preferences/services/preferences-api';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { ReturnsIcon } from 'components/common/Icons';
import { useIsMount } from 'hooks/useIsMount';

const EnableReturnsSettingsForm = ({ enabled, isFetching, onToggle }) => {
  const { paperContent } = RETURN_SETTINGS_PREFERENCES_TEXT;
  const { preferenceDispatch } = usePreferenceContext();
  const [_, setToast] = useSlzToast();
  const isMount = useIsMount();

  const mutationProductNoImage = useMutation({
    mutationFn: () => getProductNoImage()
  });

  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.returnSettingEnable]: form } = forms;

  const methods = form?.form;
  const returnSettingEnabled = useWatch({ name: 'enabled', control: methods.control });

  const getProductImages = async () => {
    try {
      const productNoImages = await mutationProductNoImage.mutateAsync();
      if (productNoImages.products.length > 0) {
        preferenceDispatch({
          type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
          payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.productImagesRequired.key }
        });
        return;
      }
      onToggle(returnSettingEnabled);
    } catch (err) {
      setToast({ title: ENABLE_RETURNS.failed, status: 'warning', colorScheme: 'negative' });
    }
  };

  useEffect(() => {
    if (!isFetching && !methods.formState.isDirty) {
      methods?.reset({ enabled });
    }
  }, [isFetching]);

  useEffect(() => {
    if (returnSettingEnabled && !isMount) {
      getProductImages();
      return;
    }
    onToggle(returnSettingEnabled);
  }, [returnSettingEnabled]);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <PreferencePaper w="full" mt={2.5} bg="light.300">
          <HStack p="1.25rem 0 1.25rem 1.25rem" alignItems="center" spacing={4}>
            <StackItem>
              <ReturnsIcon w="1.688rem" h="1.875rem" />
            </StackItem>
            <HStack w="full" justifyContent="space-between" pr={4}>
              <StackItem>
                <Text>{paperContent}</Text>
              </StackItem>
              <StackItem>
                <Controller
                  control={methods?.control}
                  name="enabled"
                  render={({ field }) => {
                    return (
                      <SlzToggle
                        value={field?.value}
                        isChecked={field?.value}
                        onChange={field?.onChange}
                        mb={0}
                      />
                    );
                  }}
                />
              </StackItem>
            </HStack>
          </HStack>
        </PreferencePaper>
      </form>
    </FormProvider>
  );
};

export default EnableReturnsSettingsForm;
