import { useHistory } from 'react-router';
import useLocalStorage from './useLocalStorage';

const USER_KEY = 'user';
const USERS_HAS_SEL_PROFILE = 'System';

export default function useAuth() {
  const [user] = useLocalStorage(USER_KEY, null);
  const history = useHistory();

  const getUser = () => {
    const user = localStorage.getItem(USER_KEY);
    return user ? JSON.parse(user) : null;
  };

  const setUser = (user) => {
    if (user) {
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  };

  const hasPermission = (feature) => {
    const user = getUser();
    return user && user.permissions.includes(feature);
  };

  const hasProfile = (profile) => {
    const user = getUser();
    return user && user.profiles.includes(profile);
  };

  const isLogged = () => {
    const user = getUser();
    return !!user?.token;
  };

  const isSelazar = () => {
    const user = getUser();
    return user?.profiles?.length > 0 && user?.profiles[0] === USERS_HAS_SEL_PROFILE;
  };

  const onLogout = () => {
    localStorage.clear();
    history && history.push('/');
  };

  return {
    user,
    getUser,
    setUser,
    hasPermission,
    hasProfile,
    isLogged,
    isSelazar,
    onLogout
  };
}
