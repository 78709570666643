import { Icon } from '@chakra-ui/react';
import React from 'react';

const ManageUserIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 17.414 21.414"
      {...props}>
      <g id="Group_33732" data-name="Group 33732" transform="translate(-2451.773 -1372.887)">
        <circle
          id="Ellipse_375"
          data-name="Ellipse 375"
          cx="4"
          cy="4"
          r="4"
          transform="translate(2454.773 1373.887)"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5566"
          data-name="Path 5566"
          d="M2452.773,1391.887v-2a4,4,0,0,1,4-4h1"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_376"
          data-name="Ellipse 376"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(2460.773 1385.887)"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5567"
          data-name="Path 5567"
          d="M2465.273,1390.387l2.5,2.5"
          fill="none"
          stroke="#303134"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default ManageUserIcon;
