import { Heading, HStack, ModalHeader, VStack, Text, Box, color } from '@chakra-ui/react';
import {
  SEND_CONSIGNMENT_MODAL_STEPS,
  SEND_CONSIGNMENT_TYPES
} from 'modules/consignments/constants';
import SlzArrowLeftIcon from 'components/common/Icons/ArrowLeftIcon';
import Tooltip from 'modules/integrations/components/form/Tooltip';

const Header = ({ currentType, onBack, consignmentType }) => {
  return (
    <ModalHeader paddingInline={0} sx={{ ml: 0, width: '100%' }}>
      <HStack
        align="baseline"
        sx={{
          alignItems: 'flex-start',
          justifyContent:
            currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE ? 'left' : 'center'
        }}>
        {currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE && (
          <SlzArrowLeftIcon
            sx={{cursor: currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE ? 'pointer' : ''}}
            onClick={() => onBack && onBack()}
            color="#686A73"
            w={8}
            h={10}
          />
        )}

        <VStack alignItems="flex-start">
          <Heading fontSize="2rem" style={{ marginLeft: 0, paddingLeft: 0 }}>
            Send inventory
          </Heading>

          {currentType !== SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_TYPE && (
            <HStack alignItems="baseline" mt="0px !important">
              <Text
                sx={{
                  textAlign: 'left',
                  fontSize: '1rem',
                  color: 'dark.500',
                  fontWeight: 'normal'
                }}>
                {consignmentType === SEND_CONSIGNMENT_TYPES.MULTIPLE
                  ? 'Multiple product consignment'
                  : 'Single product consignment'}
              </Text>
              <Tooltip content={'abc'} />
            </HStack>
          )}
        </VStack>
      </HStack>
    </ModalHeader>
  );
};

export default Header;
