import { number, object, string, array } from 'yup';
import { MAX_DIMENSION, MAX_WEIGHT } from '../../../../constants';

export const validation = {
  dimension: number()
    // .when(['height', 'width', 'depth'], {
    //   is: (h, w, d) => h * w * d > MAX_DIMENSION,
    //   then: (schema) =>
    //     schema.test({
    //       name: 'dimension',
    //       test: (value, ctx) => ctx.createError({ message: 'Maximum is 100', type: 'max' })
    //     })
    // })
    .when(['height', 'width', 'depth'], {
      is: (h, w, d) => Number.isNaN(h) || Number.isNaN(w) || Number.isNaN(d),
      then: (schema) =>
        schema.test({
          name: 'dimension',
          test: (value, ctx) =>
            ctx.createError({ message: 'This field is required', type: 'required' })
        })
    }),
  height: number().typeError('').required(),
  width: number().typeError('').required(),
  depth: number().typeError('').required(),
  weight: number().typeError('').required('This field is required'),
  commodityCode: string()
    .typeError('')
    .min(8, 'Minimum characters is 8')
    .max(10, 'Maximum characters is 10')
    .required('This field is required'),
  countryCodeOfManufacture: object().required('This field is required'),
  description: string()
    .trim()
    .max(100, 'maximum characters is 100')
    .required('This field is required')
  // sku: string().required('This field is required')
  // sku: array().of(
  //   object().shape({
  //     skuCode: string().required('SKU code is required'),
  //     skuDescription: string().required('SKU description is required')
  //   })
  // )
};
