import React from 'react';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import { DEFAULT_LOGO } from 'constants/common';

export default function PublicLayoutHeader({
  imageSrc = DEFAULT_LOGO,
  title = '',
  description = '',
  error,
  isCenter
}) {
  return (
    <Flex flexDir="column" alignItems={isCenter && 'center'}>
      <Image src={imageSrc} w={60} mb={8} />

      {error && (
        <Box mb={6}>
          <SlzAttentionCard status="error" title={error?.title} description={error?.description} />
        </Box>
      )}

      <Heading fontSize="2rem" color="szrNavy.500" mb={4} textAlign="inherit">
        {title}
      </Heading>
      {description && (
        <Text mb={5} textAlign="inherit">
          {description}
        </Text>
      )}
    </Flex>
  );
}
