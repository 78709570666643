import { Icon } from '@chakra-ui/react';
import React from 'react';

const RemittanceAdvicesIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33681" data-name="Group 33681" transform="translate(-10371.996 6962.335)">
        <g id="Group_33689" data-name="Group 33689" transform="translate(9361.664 -7663.292)">
          <path
            id="Path_8083"
            data-name="Path 8083"
            d="M1020.332,702.664v4a1,1,0,0,0,1,1h4"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8084"
            data-name="Path 8084"
            d="M1023.332,720.664h-10a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h7l5,5v11A2,2,0,0,1,1023.332,720.664Z"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8085"
            data-name="Path 8085"
            d="M1021.332,714.664h-6"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8086"
            data-name="Path 8086"
            d="M1017.832,717.164l-2.5-2.5,2.5-2.5"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default RemittanceAdvicesIcon;
