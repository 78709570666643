import { Card, FormControl, FormErrorMessage, HStack, Text, VStack } from '@chakra-ui/react';
import SlzPaper from 'components/common/SlzPaper/SlzPaper';
import { CONSIGNMENT_ARRIVING_TYPES } from 'modules/consignments/constants';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ConsignmentArrivingIcon, ConsignmentBoxIcon, PalletIcon } from './assets';

const getPaperStyles = {
  w: '21.063rem',
  h: '10.25rem',
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  bgColor: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0px 3px 6px #00000029',
  cursor: 'pointer',
  _hover: {
    borderColor: 'main.500',
    borderWidth: 2
  }
};

const getPaperDefaultStyles = {
  borderColor: 'main.500',
  borderWidth: 2
};

const getPaperErrorStyles = {
  borderColor: 'negative.500',
  borderWidth: 2
};

const activeColors = {
  [CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX]: {
    [CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX]: 'main.500',
    [CONSIGNMENT_ARRIVING_TYPES.PALLETS]: 'dark.500'
  },
  [CONSIGNMENT_ARRIVING_TYPES.PALLETS]: {
    [CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX]: 'dark.500',
    [CONSIGNMENT_ARRIVING_TYPES.PALLETS]: 'main.500'
  }
};

const SelectConsignmentArrivingType = () => {
  const {
    setValue,
    register,
    watch,
    resetField,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const [selectedValue, setSelectedValue] = useState(null);

  const handleConsignmentArrivingTypeClick = (value) => {
    setValue('consignmentArrivingType', value);
    clearErrors('consignmentArrivingType');
  };

  const resetConsignmentBoxValues = () => {
    resetField('height');
    resetField('width');
    resetField('depth');
    resetField('weight');
  };

  useEffect(() => {
    setSelectedValue(watch('consignmentArrivingType'));
  }, [watch('consignmentArrivingType')]);

  return (
    <VStack
      w="51.625rem"
      mb={selectedValue === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX ? 3 : '3rem'}
      alignItems="left">
      <Card
        bg="light.500"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem">
          <ConsignmentArrivingIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Text
            sx={{
              textAlign: 'left',
              lineHeight: '20px',
              letterSpacing: '-0.14px',
              fontSize: '16px',
              color: 'table.mainText'
            }}>
            <b>How is your consignment arriving?</b>
          </Text>
        </HStack>
      </Card>
      <FormControl
        sx={{ marginTop: '0.3rem !important' }}
        isInvalid={errors.consignmentArrivingType}>
        <Card
          bg="light.500"
          w="51.625rem"
          h="16.688rem"
          variant="filled"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            ...(errors?.consignmentArrivingType && getPaperErrorStyles)
          }}>
          <HStack align="baseline" sx={{ justifyContent: 'space-evenly' }}>
            <VStack>
              <SlzPaper
                id="pallets"
                sx={{
                  ...getPaperStyles,
                  ...(errors?.consignmentArrivingType && getPaperErrorStyles),
                  ...(selectedValue === CONSIGNMENT_ARRIVING_TYPES.PALLETS && getPaperDefaultStyles)
                }}
                size="lg"
                {...register('consignmentArrivingType')}
                onClick={() => {
                  handleConsignmentArrivingTypeClick(CONSIGNMENT_ARRIVING_TYPES.PALLETS);
                  resetConsignmentBoxValues();
                }}>
                <PalletIcon
                  sx={{
                    width: '5.25rem',
                    height: '5.25rem',
                    color: `${
                      activeColors[CONSIGNMENT_ARRIVING_TYPES.PALLETS][selectedValue] || 'dark.500'
                    }`
                  }}
                />
              </SlzPaper>
              <Text
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  letterSpacing: '0px',
                  color: `${
                    activeColors[CONSIGNMENT_ARRIVING_TYPES.PALLETS][selectedValue] || 'dark.500'
                  }`
                }}>
                Pallets
              </Text>
            </VStack>
            <VStack>
              <SlzPaper
                id="consignmentBox"
                sx={{
                  ...getPaperStyles,
                  ...(errors?.consignmentArrivingType && getPaperErrorStyles),
                  ...(selectedValue === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX &&
                    getPaperDefaultStyles)
                }}
                size="lg"
                {...register('consignmentArrivingType')}
                onClick={() =>
                  handleConsignmentArrivingTypeClick(CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX)
                }>
                <ConsignmentBoxIcon
                  sx={{ width: '5.25rem', height: '5.25rem' }}
                  color={`${
                    activeColors[CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX][selectedValue] ||
                    'dark.500'
                  }`}
                />
              </SlzPaper>
              <Text
                sx={{
                  fontSize: '20px',
                  lineHeight: '26px',
                  letterSpacing: '0px',
                  color: `${
                    activeColors[CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX][selectedValue] ||
                    'dark.500'
                  }`
                }}>
                Consignment box
              </Text>
            </VStack>
          </HStack>
        </Card>
        <FormErrorMessage fontSize="lg">
          {errors?.consignmentArrivingType?.message}
        </FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default SelectConsignmentArrivingType;
