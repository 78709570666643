import { useMemo } from 'react';
import { getRandomColor } from 'components/common/SlzTable/utils';
import { applyVariableToMessage } from 'utils/helper';
import { ROLE, ROLES_AND_PERMISSIONS, VENDOR } from '../constants';

export const getRandomColorById = (id) => {
  const color = useMemo(() => {
    return `${getRandomColor()}.700`;
  }, [id]);
  return color;
};

const timeBrackets = [
  {
    limit: 60,
    text: 'Just now'
  },
  {
    limit: 60 * 60,
    text: '{{minutes}} minutes ago'
  },
  {
    limit: 60 * 60 * 24,
    text: '{{hours}} hours ago'
  },
  {
    limit: 60 * 60 * 48,
    text: 'yesterday at {{lastLoginDateString}}'
  },
  {
    limit: 60 * 60 * 24 * 7,
    text: '{{days}} days ago at {{lastLoginDateString}}'
  },
  {
    limit: 60 * 60 * 24 * 30 * 12,
    text: '{{remainDays}} days {{months}} months ago at {{lastLoginDateString}}'
  }
];

export const getUserLastSeen = (lastLogin) => {
  const lastSeen = useMemo(() => {
    if (!lastLogin) return 'No Logins';
    const lastLoginDate = new Date(lastLogin);
    const lastLoginDateString = lastLoginDate.toLocaleString('en-GB');
    const now = new Date();
    const timeDifference = now.getTime() - lastLoginDate.getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const time = timeBrackets.find((timeBracket) => seconds < timeBracket.limit);
    if (time) {
      const messageVariables = {
        minutes,
        hours,
        lastLoginDateString,
        days,
        remainDays: days % 30,
        months
      };
      return applyVariableToMessage(messageVariables, time.text);
    }
    return `${days % 30} days ${months % 12} months ${years} years ago`;
  }, [lastLogin]);

  return lastSeen;
};

export const mapRolesUserManagement = (roleOptions) => {
  const roleOptionsForCompany = Object.keys(roleOptions?.data);
  const roles = Object.values(roleOptions?.data);
  const infoVendor = {};

  roleOptionsForCompany.forEach((vendor, vendorIndex) =>
    roles[vendorIndex].forEach(({ name, id }) => {
      infoVendor[vendor] = { ...infoVendor[vendor], [name]: id };
    })
  );

  return ROLES_AND_PERMISSIONS.map((value) => {
    return {
      ...value,
      value: infoVendor[value.vendor][value.role],
      id: infoVendor[value.vendor][value.role]
    };
  });
};
