import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';

import { SlzPaper } from 'components/common/SlzPaper';
import { SlzButton } from 'components/common/SlzButton';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import Source from 'modules/b2c-orders/components/other/Source';
import { DATE_FORMAT_DDMMYYYY_HH_MM_SS } from 'constants/date';

const BulkOrderInfoDetail = (props) => {
  const { data, isLoading, showSorce, title } = props;

  return (
    <SlzPaper w={props.w} h={props.h} {...props}>
      <Flex
        h={props.h}
        justifyContent="space-between"
        alignItems="center"
        borderLeftWidth="0.75rem"
        borderRadius="0.375rem"
        borderLeftColor={data?.status?.bg}>
        {isLoading ? (
          <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
        ) : (
          <>
            <HStack>
              <Box mx={5} justifyContent="space-between" width="18rem">
                <Flex gap={2}>
                  <Text fontSize="1rem" fontWeight="bold">
                    {title}
                  </Text>
                  <Text fontSize="1rem" fontWeight="bold">
                    {data?.purchaseOrderNumber}
                  </Text>
                </Flex>
                <Text fontSize="0.875rem">
                  {dayjs(data?.createdDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS)}
                </Text>
              </Box>
              {showSorce && (
                <Box position="relative">
                  <Text position="absolute" top="-1.688rem" fontSize="1rem" fontWeight="bold">
                    Source
                  </Text>
                  <Source
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textColor: 'light.300',
                      h: '30px',
                      px: 6,
                      fontSize: '1rem',
                      borderRadius: 1.5
                    }}
                    type={data?.source?.type}
                    name={data?.source?.name}
                  />
                </Box>
              )}
            </HStack>
            <Flex width="25rem" justifyContent="space-between" mr="2rem">
              <Flex
                fontWeight="bold"
                justifyContent="center"
                alignItems="center"
                px="0.938rem"
                fontSize="1rem"
                borderRadius="0.375rem"
                bg="light.700"
                color="#000000">
                Courier cost £{data?.totalCost}
              </Flex>
              <SlzButton rightIcon={<DeleteIcon />} size="lg">
                Delete
              </SlzButton>
            </Flex>
          </>
        )}
      </Flex>
    </SlzPaper>
  );
};

export default BulkOrderInfoDetail;
