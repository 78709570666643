import { Box, useRadio, useStyleConfig } from '@chakra-ui/react';
import React from 'react';

const SlzRadioButton = (props) => {
  const { variant, colorScheme = 'main', size, isDisabled } = props;
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const styles = useStyleConfig('Button', { variant, colorScheme, size });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  if (variant === 'outline') {
    styles._hover.bg = `primary.${colorScheme}Hover80`;
  }

  return (
    <Box as="label" mb="0">
      <input {...input} />
      <Box
        {...checkbox}
        className="radio-btn"
        __css={styles}
        _checked={styles._active}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          pointerEvents: isDisabled && 'none'
        }}>
        {props.children}
      </Box>
    </Box>
  );
};

export default SlzRadioButton;
