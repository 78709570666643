import { Box, HStack } from '@chakra-ui/react';
import { SlzChip } from 'components/common/SlzChip';
import SlzCurrencyIcon from 'components/common/SlzCurrencyIcon';
import { INVOICE_TYPE } from '../../constants';

export const INVOICE_COLS = [
  {
    name: 'Invoice',
    field: 'invoiceNumber',
    isSorted: true,
    isHyperLink: true,
    isDisplayed: true
  },
  {
    name: 'Date',
    field: 'issueDate',
    isSorted: true,
    isDisplayed: false
  },
  {
    name: 'Warehouse',
    field: 'wareHouse',
    isSorted: true,
    isDisplayed: false
  },
  {
    name: 'Type',
    field: 'type',
    isSorted: true,
    isEmphasis: true,
    isDisplayed: true
  },
  {
    name: 'Total',
    field: 'total',
    isSorted: true,
    isDisplayed: true
  },
  {
    name: 'Currency',
    field: 'currency',
    isSorted: true,
    render: ({ row, field }) => {
      return <SlzCurrencyIcon size={'md'} code={row[field] || 'ER'} />;
    },
    isDisplayed: false
  }
];

export const COLLECTION_COLS = [
  {
    name: 'CA Number',
    field: 'caNumber',
    isSorted: true,
    isDisplayed: true
  },
  {
    name: 'Issue date',
    field: 'issueDate',
    isSorted: true,
    isDisplayed: false
  },
  {
    name: 'Period',
    field: 'period',
    isSorted: true,
    render: ({ row }) => {
      return row?.period ? (
        <HStack gap={5} bg={'#DEDEDE'} w="full" h="full" justifyContent={'center'}>
          <p>{row['period'][0]}</p>
          <p>{row['period'][1]}</p>
        </HStack>
      ) : (
        <></>
      );
    },
    isDisplayed: false
  },
  {
    name: 'Total',
    field: 'total',
    isSorted: true,
    isDisplayed: true
  },

  {
    name: 'Currency',
    field: 'currency',
    isSorted: true,
    render: ({ row, field }) => {
      return <SlzCurrencyIcon size={'md'} code={row[field] || 'ER'} />;
    },
    isDisplayed: false
  }
];

export const REMITTANCE_COLS = [
  {
    name: 'RA Number',
    field: 'raNumber',
    isSorted: true,
    isHyperLink: true,
    isDisplayed: true
  },
  {
    name: 'Issue Date',
    field: 'issueDate',
    isSorted: true,
    isDisplayed: false
  },
  {
    name: 'Period',
    field: 'period',
    isSorted: true,
    render: ({ row }) => {
      return row?.period ? (
        <HStack gap={5} bg={'#DEDEDE'} w="full" h="full" justifyContent={'center'}>
          <p>{row['period'][0]}</p>
          <p>{row['period'][1]}</p>
        </HStack>
      ) : (
        <></>
      );
    },
    isDisplayed: false
  },
  {
    name: 'Total',
    field: 'total',
    isSorted: true,
    isDisplayed: true
  },
  {
    name: 'Currency',
    field: 'currency',
    isSorted: true,
    render: ({ row, field }) => {
      return <SlzCurrencyIcon size={'md'} code={row[field] || 'ER'} />;
    },
    isDisplayed: false
  }
];

export const DETAILED_COLLECTION_COLS = [
  {
    name: 'Invoice date',
    field: 'invoiceDate',
    width: 'auto'
  },
  {
    name: 'Invoice reference',
    field: 'invoiceReference',
    width: 'auto'
  },
  {
    name: 'Invoice type',
    field: 'invoiceType',
    width: 'auto',
    render: ({ row }) => {
      const bgColor = Object.values(INVOICE_TYPE).find(
        (invoiceType) => invoiceType.name === row.invoiceType
      ).color;
      return (
        <SlzChip
          m={1.5}
          h={6}
          color="#FFFFFF"
          flexBasis="none"
          lineHeight="16px"
          size="md"
          variant="subtle"
          cursor="pointer"
          marginLeft="30%"
          bg={bgColor}>
          {row.invoiceType}
        </SlzChip>
      );
    }
  },
  {
    name: 'Amount',
    field: 'amount',
    width: 'auto',
    render: ({ row }) => {
      return <Box ml="3.438rem">£{row.amount}</Box>;
    }
  }
];

export const DETAILED_REMITTANCE_COLS = [
  {
    name: 'Invoice date',
    field: 'invoiceDate',
    width: 'auto'
  },
  {
    name: 'Invoice reference',
    field: 'invoiceReference',
    width: 'auto'
  },
  {
    name: 'Invoice type',
    field: 'invoiceType',
    width: 'auto',
    render: ({ row }) => {
      const bgColor = Object.values(INVOICE_TYPE).find(
        (invoiceType) => invoiceType.name === row.invoiceType
      ).color;
      return (
        <SlzChip
          m={1.5}
          h={6}
          color="#FFFFFF"
          flexBasis="none"
          lineHeight="16px"
          size="md"
          variant="subtle"
          colorScheme={bgColor}
          marginLeft="30%"
          bg={bgColor}>
          {row.invoiceType}
        </SlzChip>
      );
    }
  },
  {
    name: 'Amount',
    field: 'amount',
    width: 'auto',
    render: ({ row }) => {
      return <Box ml="3.438rem">£{row.amount}</Box>;
    }
  }
];
