import { Fragment } from 'react';
import { GridItem } from '@chakra-ui/react';

import { SlzTimelineStepper } from 'components/common/SlzTimelineStepper';

import { USER_MANAGEMENT_STEPS } from 'modules/users/constants';
import { UserManagementModalHeader } from '../other';

export const UserManagementStep = ({ currentStep, ...rest }) => {
  const StepComponent = USER_MANAGEMENT_STEPS[currentStep]?.component || Fragment;
  return <StepComponent currentStep={currentStep} {...rest} />;
};

const UserManagementModalLayout = (props) => {
  const {
    defaultValues,
    setCurrentStep,
    currentStep,
    onCancel,
    onNext,
    onPrevious,
    setLayoutKey,
    isSubmitting
  } = props;

  return (
    <>
      <GridItem gridColumn="2/12" mb="3" mt={3.5}>
        <UserManagementModalHeader />
      </GridItem>

      <GridItem gridColumn="2/12" mb={10} px={8} mt={3.5}>
        <SlzTimelineStepper
          steps={USER_MANAGEMENT_STEPS}
          disabled={true}
          step={currentStep + 1}
          onCurrentStepChange={setCurrentStep}
          labelStyles={{
            fontSize: 'md'
          }}
        />
      </GridItem>

      <GridItem gridColumn="2/12" mt={0.5}>
        <UserManagementStep
          defaultValues={defaultValues}
          isSubmitting={isSubmitting}
          currentStep={currentStep}
          onCancel={onCancel}
          onPrevious={onPrevious}
          onNext={onNext}
          setCurrentStep={setCurrentStep}
          setLayoutKey={setLayoutKey}
        />
      </GridItem>
    </>
  );
};
export default UserManagementModalLayout;
