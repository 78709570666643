import { useQuery } from 'react-query';
import { getConsignmentDetail } from '../services/consignments-api';

export const useConsignmentDetailQuery = (consignmentId, select) => {
  return useQuery([`consignment-detail-${consignmentId}`], () => getConsignmentDetail(consignmentId), {
    cacheTime: 60 * 1000,
    staleTime: 30 * 1000,
    select
  });
};
