import { useQuery } from 'react-query';
import { getRoleCompany } from '../services/users-api';
import { mapRolesUserManagement } from '../utils';

const useRolesCompanyQuery = () => {
  return useQuery({
    queryKey: ['role-company'],
    queryFn: () => getRoleCompany(),
    staleTime: 10 * 1000,
    select: ({ data }) => mapRolesUserManagement(data)
  });
};

export default useRolesCompanyQuery;
