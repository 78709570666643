import { Fragment, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useIsMount } from 'hooks/useIsMount';

import { B2BOrderAction, B2BProductTracking } from 'modules/b2b-orders/components/other';
import {
  CUSTOM_BOX_CHECKING_FLAGS,
  CUSTOM_BOX_PREFIX,
  CUSTOM_BOX_STEP,
  VALIDATE_STATUS
} from 'modules/b2b-orders/constants';
import { filterArray } from 'modules/b2b-orders/utils';
import { ACTION_BUTTON_PROGRESS, CUSTOM_BOX_PROGRESS } from 'modules/b2b-orders/constants/progress';

const CustomBoxActionButtons = ({ status, ...rest }) => {
  const StepComponent = ACTION_BUTTON_PROGRESS[status] || Fragment;
  return <StepComponent {...rest} />;
};

const CustomBoxContent = ({ customBoxStep, ...rest }) => {
  const StepComponent = CUSTOM_BOX_PROGRESS[customBoxStep] || Fragment;
  return <StepComponent {...rest} />;
};

const CustomBoxWrapper = ({ children, selectedProducts, customBoxStep }) => {
  const { watch } = useFormContext();
  return (
    <Box h="100%">
      <B2BProductTracking
        boxesLength={filterArray(watch(CUSTOM_BOX_PREFIX)).length}
        step={customBoxStep}
        selectedProducts={selectedProducts}
        isShowTooltip={false}
      />
      {children}
    </Box>
  );
};

const CustomBoxSplit = ({ step, defaultValues, ...rest }) => {
  const [customBoxStep, setCustomBoxStep] = useState(CUSTOM_BOX_STEP.setup);
  const isMount = useIsMount();
  const { setValue, getValues, watch } = useFormContext();
  const [validateProgress, setValidateProgress] = useState(VALIDATE_STATUS.validating);
  const [validatedBoxes, setValidatedBoxes] = useState({});
  const [isValidating, setIsValidating] = useState(false);

  useEffect(() => {
    if (!isMount && customBoxStep === CUSTOM_BOX_STEP.setup) {
      setValidateProgress(VALIDATE_STATUS.validating);
    }
  }, [watch(CUSTOM_BOX_PREFIX)]);

  const handleNextToSummary = () => {
    setCustomBoxStep(CUSTOM_BOX_STEP.summary);
  };

  const handleValidateBoxSplit = async () => {
    setValue(
      CUSTOM_BOX_CHECKING_FLAGS.validateFlag,
      !getValues(CUSTOM_BOX_CHECKING_FLAGS.validateFlag)
    );
    setIsValidating(true);
  };

  const handleBackToPreviousStep = (event) => {
    if (customBoxStep === CUSTOM_BOX_STEP.setup) {
      rest.onPrevious(event);
      return;
    }

    setCustomBoxStep(CUSTOM_BOX_STEP.setup);
    setValidateProgress(VALIDATE_STATUS.validating);
  };

  const handleOnPrevious = () => {
    if (customBoxStep == 'summary') {
      setCustomBoxStep('setup');
      setValidateProgress(VALIDATE_STATUS.validating);
      return;
    }
    onPrevious && onPrevious({ defaultValues });
  };

  return (
    <CustomBoxWrapper
      selectedProducts={defaultValues?.stockSelection}
      customBoxStep={customBoxStep}>
      <CustomBoxContent
        step={step}
        customBoxStep={customBoxStep}
        defaultValues={defaultValues}
        validatedBoxes={validatedBoxes}
        setValidatedBoxes={setValidatedBoxes}
        setValidateProgress={setValidateProgress}
        setIsValidating={setIsValidating}
      />
      <B2BOrderAction onCancel={rest.onCancel} onPrevious={handleBackToPreviousStep}>
        <CustomBoxActionButtons
          disableNextButton={rest?.disableNextButton || false}
          status={validateProgress || VALIDATE_STATUS.done}
          onValidateBoxSplit={handleValidateBoxSplit}
          onNextToSummary={handleNextToSummary}
          onPrevious={handleOnPrevious}
          isValidating={isValidating}
        />
      </B2BOrderAction>
    </CustomBoxWrapper>
  );
};

export default CustomBoxSplit;
