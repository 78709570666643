import { Icon } from '@chakra-ui/react';
import React from 'react';

const AddUserIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 21 20"
      {...props}>
      <g id="Group_33731" data-name="Group 33731" transform="translate(-2448.773 -1324.887)">
        <circle
          id="Ellipse_374"
          data-name="Ellipse 374"
          cx="4"
          cy="4"
          r="4"
          transform="translate(2451.773 1325.887)"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5564"
          data-name="Path 5564"
          d="M2449.773,1343.887v-2a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_5565"
          data-name="Path 5565"
          d="M2462.773,1333.887h6m-3-3v6"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default AddUserIcon;
