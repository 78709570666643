import React from 'react';
import { Card, Text, VStack, HStack, Box } from '@chakra-ui/react';
import { FileIcon, CheckIcon, WarehouseIcon } from '../assets';
import {
  CONSIGNMENT_ARRIVING_TYPES,
  CONSIGNMENT_BOX_LOCATION_TEXT,
  PALLET_LOCATION_TEXT,
  SEND_CONSIGNMENT_MODAL_STEPS
} from 'modules/consignments/constants';

const StockDetails = ({ csvFiles, handleEditStep, consignmentArrivingType }) => {
  const arrivingLocation =
    consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX
      ? CONSIGNMENT_BOX_LOCATION_TEXT
      : PALLET_LOCATION_TEXT;

  return (
    <>
      <Card
        bg="light.700"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem" display="flex" justifyContent="space-between">
          <Box w="50%" display="flex" alignItems="center" justifyContent="flex-start">
            <WarehouseIcon marginRight="0.375rem" sx={{ width: '1.25rem', height: '100%' }} />

            <Box display="flex" alignItems="baseline">
              <Text
                marginRight="0.375rem"
                sx={{
                  fontSize: 'lg',
                  color: 'table.mainText',
                  fontWeight: 'bold'
                }}>
                Stock details
              </Text>

              <Text
                onClick={() => handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.CONSIGNMENT_DETAILS)}
                fontSize="xs"
                cursor="pointer"
                color="main.500">
                Edit
              </Text>
            </Box>
          </Box>
          <Box
            rounded="0.375rem"
            h="1.5rem"
            py="0.313rem"
            px="0.75rem"
            bgColor="main.300"
            display="flex"
            alignItems="center">
            <Text fontSize="sm" color="main.500">
              {arrivingLocation}
            </Text>
          </Box>
        </HStack>
      </Card>
      <Card
        bg="light.300"
        border="1px solid"
        borderColor="light.700"
        w="full"
        px="1.5rem"
        py="1rem"
        variant="filled"
        flexGrow={1}
        sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '0.2rem !important' }}>
        <VStack>
          {csvFiles.length &&
            csvFiles.map(({ name }) => (
              <Box
                py="0.557rem"
                px="0.750rem"
                rounded="0.375rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                bgColor="lightAndShadow.darkModeText"
                w="22.813rem">
                <Box w="50%" display="flex" justifyContent="flex-start" alignItems="center">
                  <FileIcon sx={{ width: '1.5rem', height: '1.5rem', marginRight: '0.375rem' }} />
                  <Text fontSize="sm" color="dark.700">
                    {name}
                  </Text>
                </Box>
                <CheckIcon w="fit-content" />
              </Box>
            ))}
        </VStack>
      </Card>
    </>
  );
};

export default StockDetails;
