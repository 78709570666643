import { mode } from '@chakra-ui/theme-tools';

const baseStyle = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
    color: 'table.mainText',
    'tr:not(:first-child)': {
      borderTopColor: 'white',
      borderTopStyle: 'solid',
      borderTopWidth: '0.188rem'
    }
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    tr: {
      // borderTopColor: 'white',
      // borderTopStyle: 'solid',
      // borderTopWidth: '0.188rem',
      borderBottomColor: 'white',
      borderBottomStyle: 'solid',
      borderBottomWidth: '0.188rem'
    }
  },
  th: {
    textAlign: 'center',
    fontFamily: 'heading',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'unset',
    letterSpacing: 'wider'
  },
  td: {
    // overflow: 'hidden',
    textAlign: 'center',
    borderBottom: 'none'
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium'
  }
};

const variantSimple = (props) => {
  const { colorScheme: c } = props;
  return {
    th: {
      h: '3rem',
      fontWeight: 'bold',
      color: mode('table.headText', 'table.headText')(props),
      borderColor: mode('table.headText', 'table.headText')(props),
      bg: mode('table.rowBg', 'table.rowBg')(props),
      _selected: {
        bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
      }
    },
    tr: {
      bg: mode('table.rowBg', 'table.rowBg')(props),
      td: {
        h: '3rem',
        maxH: '3rem',
        lineHeight: '1.25rem',
        color: mode('table.mainText', 'table.mainText')(props),
        span: {
          bg: mode('#C3C4CB', '#C3C4CB')(props)
        },
        _selected: {
          bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
        },
        _hover: {
          bg: mode('light.700', 'light.300')(props)
        }
      },
      _hover: {
        bg: mode('light.700', 'light.300')(props),
        cursor: 'pointer',
        td: {
          span: {
            bg: mode('#C3C4CB', '#C3C4CB')(props)
          }
        }
      },
      _selected: {
        td: {
          bg: mode('main.300', 'main.300')(props)
        }
      }
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props)
    },
    tfoot: {
      h: '3rem',
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};

const variantBilling = (props) => {
  const variant = variantOrder(props);
  return {
    ...variant,
    tr: {
      ...variant.tr,
      'td:nth-child(1)': {
        fontWeight: '500 !important'
      },
      'td:last-child': {
        span: {
          fontWeight: '400 !important'
        }
      }
    }
  };
};

const variantBillingInvoice = (props) => {
  const variant = variantOrder(props);
  return {
    ...variant,
    tr: {
      ...variant.tr,
      'td:nth-child(2), td:nth-child(5) p': {
        fontWeight: '500 !important'
      },
      'td:last-child': {
        span: {
          fontWeight: '400 !important'
        }
      }
    }
  };
};

const variantProductList = (props) => {
  return {
    ...variantOrder(props)
  };
};

const variantAddressBookList = (props) => {
  const variant = variantOrder(props);

  return {
    ...variant,

    table: {
      ...variant.table,
      tbody: {
        tr: {
          h: '4.688rem'
        }
      }
    }
  };
};

const variantOrder = (props) => {
  const { colorScheme: c } = props;
  return {
    table: {
      // borderCollapse: 'collapse',
      'tr.parent': {
        // borderTopColor: 'white',
        // borderTopStyle: 'solid'
        // borderTopWidth: '0.188rem'
      },
      'tr.expand-row': {
        // borderBottomColor: `white`,
        // borderBottomStyle: 'solid'
        borderTopWidth: '0'
      }
    },
    th: {
      h: '3rem',
      fontWeight: 'bold',
      color: mode('table.headText', 'table.headText')(props),
      borderColor: mode('table.headText', 'table.headText')(props),
      bg: mode('table.rowBg', 'table.rowBg')(props),
      _selected: {
        bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
      }
    },
    tr: {
      bg: mode('table.rowBg', 'table.rowBg')(props),
      td: {
        lineHeight: '1.25rem',
        color: mode('table.mainText', 'table.mainText')(props),
        borderColor: mode('table.mainText', 'table.mainText')(props),
        span: {
          bg: mode('#C3C4CB', '#C3C4CB')(props)
        },
        _selected: {
          bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
        },
        _hover: {
          bg: mode('light.700', 'light.300')(props)
        }
      },
      _hover: {
        bg: mode('light.700', 'light.300')(props),
        cursor: 'pointer',
        td: {
          span: {
            bg: mode('#C3C4CB', '#C3C4CB')(props)
          }
        }
      },
      _selected: {
        td: {
          bg: mode('main.300', 'main.300')(props)
        }
      },
      '.expand-row': {}
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props)
    },
    tfoot: {
      h: '3rem',
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};

const variantProduct = (props) => {
  return {
    ...variantSimple(props),
    table: {
      tbody: {
        tr: {
          td: {
            h: '3.75rem',
            maxH: '3.75rem'
          }
          // 'td.marked-left-td, td.emphasized-cell, td.number': {
          //   display: 'inline-block'
          // }
        }
      }
    }
  };
};

const variantProductSelected = (props) => {
  return {
    ...variantOrder(props),
    table: {
      maxH: '496px',
      h: '496px',
      thead: {
        th: {
          fontSize: '12px',
          lineHeight: '16px',
          px: 0,
          py: 0,
          maxH: '30px',
          h: '30px'
        },
        'th:nth-child(3)': {
          paddingLeft: '18px'
        }
      },
      tbody: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          visibility: 'hidden',
          display: 'none'
        },
        tr: {
          'td.empty-productSelected': {
            fontSize: '12px',
            h: '22.188rem'
          }
        }
      }
    }
  };
};

const variantSelectedBundle = (props) => {
  return {
    ...variantOrder(props),
    table: {
      thead: {
        th: {
          fontSize: '12px',
          lineHeight: '16px',
          bg: '#cce5ff',
          px: 0,
          py: 0,
          maxH: '30px',
          h: '30px'
        },
        'th:nth-child(2)': {
          paddingLeft: '18px'
        }
      },
      tbody: {
        tr: {
          'td.empty-selectedBundle': {
            fontSize: '12px',
            h: '22.188rem'
          }
        }
      }
    }
  };
};

const variantOrderSummary = (props) => {
  return {
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      textAlign: 'left'
    },
    thead: {
      display: 'block',
      width: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        visibility: 'hidden',
        display: 'none'
      },
      tr: {
        borderBottomWidth: 0,
        borderTopWidth: 0
      }
    },
    tbody: {
      maxHeight: '10.875rem',
      display: 'block',
      width: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        visibility: 'hidden',
        display: 'none'
      },
      'tr:not(:first-child)': {
        borderTopWidth: '0'
      }
    },
    th: {
      p: '0.5rem 0.75rem',
      color: '#0973EA',
      bg: '#CCE5FF',
      textAlign: 'left',
      letterSpacing: 0,
      fontSize: '1rem',
      fontWeight: 'medium',
      lineHeight: 1,
      display: 'block',
      width: '100%'
    },
    tr: {
      display: 'flex',
      textAlign: 'left',
      borderBottomWidth: 0,
      borderTopWidth: 0,
      '&:nth-of-type(even)': {
        bg: '#CCE5FF'
      },
      '&:nth-of-type(odd)': {
        bg: '#e6f2ff'
      }
    },
    td: {
      textAlign: 'left',
      p: '0.125rem 0.75rem',
      fontSize: '14px',
      display: 'block',
      width: '100%'
    }
  };
};
const variantDetailOrder = (props) => {
  const { colorScheme: c } = props;
  return {
    table: {
      borderCollapse: 'collapse',
      th: {
        borderBottom: '0px'
      },
      'tr.parent': {
        borderTopColor: 'white',
        borderTopStyle: 'solid',
        borderTopWidth: '0.188rem'
      },
      'tr.expand-row': {
        borderTopColor: `${c}.700`,
        borderTopStyle: 'solid',
        borderTopWidth: '0.188rem'
      },
      td: {
        borderBottom: '0.188rem solid white'
      },
      'tr>td:first-of-type': {
        pl: 0
      },
      'td.marked-left-td': {
        borderBottomWidth: `0.188rem`,
        borderBottomColor: `${c}`
      },
      'tr:last-of-type >td': {
        borderBottom: 'none'
      }
    },
    th: {
      h: '3rem',
      fontWeight: 'bold',
      color: mode('table.headText', 'table.headText')(props),
      borderColor: mode('table.headText', 'table.headText')(props),
      bg: mode('table.rowBg', 'table.rowBg')(props),
      _selected: {
        bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
      }
    },
    tr: {
      bg: mode('table.rowBg', 'table.rowBg')(props),
      td: {
        lineHeight: '1.25rem',
        color: mode('table.mainText', 'table.mainText')(props),
        span: {
          bg: mode('#C3C4CB', '#C3C4CB')(props)
        },
        _selected: {
          bg: mode('primary.mainSelected', 'primary.mainSelected')(props)
        },
        _hover: {
          bg: mode('light.700', 'light.300')(props)
        }
      },
      _hover: {
        bg: mode('light.700', 'light.300')(props),
        cursor: 'pointer',
        td: {
          span: {
            bg: mode('#C3C4CB', '#C3C4CB')(props)
          }
        }
      },
      _selected: {
        td: {
          bg: mode('main.300', 'main.300')(props)
        }
      },
      '.expand-row': {}
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props)
    },
    tfoot: {
      h: '3rem',
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 }
        }
      }
    }
  };
};

const variantSetting = (props) => {
  return {
    ...variantSimple(props),

    table: {
      thead: {
        tr: {
          th: {
            bgColor: 'white',
            textAlign: 'start',
            h: '1.875rem',
            maxH: '1.875rem',
            p: '0 1.25rem',
            fontSize: '0.875rem'
          }
        }
      },
      tr: {
        //stripped rows
        bgColor: 'white',
        ':nth-child(odd)': { bgColor: 'table.eoriOddRowBg' },
        ':not(:first-child)': {
          borderTopWidth: '0'
        },
        td: {
          ':hover': {
            bgColor: 'unset !important',
            color: 'unset !important'
          },
          ':not(.active-cell)': {
            opacity: 0.4
          },
          '.active-text': {
            fontWeight: '500',
            color: 'main.500'
          },
          w: '3.125rem',
          h: '1.875rem',
          maxH: '1.875rem',
          p: '0 1.25rem',
          fontSize: '0.75rem',
          color: 'dark.700',
          ':not(.button-group)': {
            textAlign: 'start'
          }
        }
      }
    }
  };
};

const variantReturnProduct = (props) => {
  return {
    ...variantSimple(props),

    table: {
      borderRadius: '0.375rem',
      overflow: 'hidden',
      thead: {
        tr: {
          borderWidth: 0,
          th: {
            ':first-of-type': {
              w: '15.8125rem'
            },
            ':last-of-type': {
              textAlign: 'right'
            },
            bgColor: 'main.300',
            textAlign: 'start',
            h: '1.8125rem',
            maxH: '1.8125rem',
            p: '0 0.5rem',
            fontSize: '1rem',
            color: 'main.500'
          }
        }
      },
      tr: {
        //stripped rows
        borderWidth: '0 !important',
        '&:nth-of-type(even)': {
          bg: '#CCE5FF'
        },
        '&:nth-of-type(odd)': {
          bg: '#e6f2ff'
        },
        td: {
          textAlign: 'start',
          ':hover': {
            bg: 'unset !important'
          },
          ':first-of-type': {
            w: '15.8125rem'
          },
          ':last-of-type': {
            textAlign: 'right'
          },
          h: '1.4375rem',
          maxH: '1.4375rem',
          p: '0 0.5rem',
          fontSize: '0.875rem',
          color: 'dark.700'
        }
      }
    }
  };
};

const variantB2BOrderOption = (props) => {
  return {
    table: {
      tr: {
        bg: 'light.500',
        ':nth-child(odd)': {
          bg: 'light.300',
          _hover: {
            bg: 'light.300',
            cursor: 'auto'
          }
        },
        ':not(:first-child)': {
          border: 'none'
        },
        border: 'none',
        td: {
          h: 12
        },
        _hover: {
          bg: 'light.500',
          cursor: 'auto'
        }
      }
    }
  };
};

const variantB2BOrderSummary = (props) => {
  return {
    ...variantB2BOrderOption(props),
    table: {
      w: '100%',
      tableLayout: 'fixed',
      thead: {
        h: '30px'
      },
      th: {
        bg: 'light.700',
        py: 0
      },
      tr: {
        bg: 'light.500',
        ':nth-child(odd)': {
          bg: 'light.300',
          _hover: {
            bg: 'light.300',
            cursor: 'auto'
          }
        },
        ':not(:first-child)': {
          border: 'none'
        },
        border: 'none',
        td: {
          h: 6,
          fontSize: 'md',
          color: 'dark.700',
          '> div.slz-table-cell': {
            justifyContent: 'flex-start',
            pl: 3
          }
        },
        _hover: {
          bg: 'light.500',
          cursor: 'auto'
        }
      }
    }
  };
};

const variantList = () => {
  return {
    th: {
      color: 'list.headText',
      bg: 'list.rowBg'
    },
    thead: {
      tr: {
        borderColor: 'list.borderColor',
        borderBottomStyle: 'solid',
        borderBottomWidth: '0.031rem'
      }
    },
    table: {
      'tr:not(:first-child)': {
        borderTop: 'none'
      }
    },
    tr: {
      bg: 'list.rowBg',
      borderBottomColor: 'list.borderColor',
      borderBottomStyle: 'solid',
      borderBottomWidth: '0.031rem'
    }
  };
};

const variants = {
  simple: variantSimple,
  billing: variantBilling,
  billingInvoice: variantBillingInvoice,
  detailOrder: variantDetailOrder,
  product: variantProduct,
  order: variantOrder,
  orderSummary: variantOrderSummary,
  productSelected: variantProductSelected,
  selectedBundle: variantSelectedBundle,
  productList: variantProductList,
  addressBookList: variantAddressBookList,
  setting: variantSetting,
  returnProduct: variantReturnProduct,
  b2bOrderOption: variantB2BOrderOption,
  b2bOrderSummary: variantB2BOrderSummary,
  list: variantList
};

export const Table = {
  baseStyle,
  variants,
  defaultProps: {
    variant: 'simple',
    size: 'md',
    colorScheme: 'gray'
  }
};
