import { chakra, RadioGroup, Stack } from '@chakra-ui/react';
import { SlzRadio } from '.';
import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzRadioGroup = React.forwardRef((props, ref) => {
  const { data, error, onChange, onValueChange, radioProps, ...hookFormProps } = props;
  const [value, setValue] = useState(props?.value);

  const handleValueChange = (event) => {
    onChange && onChange(event);
    onValueChange && onValueChange(event);
    setValue(event);
  };

  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);

  return (
    <RadioGroup
      className="slz-radio-group"
      ref={ref}
      {...hookFormProps}
      value={String(value)}
      onChange={(event) => handleValueChange(event)}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Radio`)}>
      <Stack direction="column">
        {data &&
          data.map(({ id, name, value, ...rest }, index) => {
            return (
              <SlzRadio
                margin={0}
                marginBottom={'12px'}
                key={index}
                value={String(value)}
                {...rest}
                {...radioProps}
                isInvalid={index === data.length - 1 && error}>
                {name}
              </SlzRadio>
            );
          })}
        {error && (
          <chakra.span
            __css={{ color: '#DE4259', mt: '12px', height: '100%' }}
            className="error-message">{`*${error}`}</chakra.span>
        )}
      </Stack>
    </RadioGroup>
  );
});

export default SlzRadioGroup;
