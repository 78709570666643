import { useQuery } from 'react-query';
import { getInvoiceTypes } from '../services/billing-api';

export const useInvoiceTypeQuery = ({ select, enabled }) => {
  return useQuery({
    queryKey: [`invoice-types`],
    queryFn: () => getInvoiceTypes(),
    cacheTime: 60 * 1000,
    select: select,
    enabled: enabled
  });
};
