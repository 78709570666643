import { useQuery } from 'react-query';

import { getReturnSettings } from 'modules/preferences/services/preferences-api';

export const useReturnSettingsEnableQuery = (enabled) => {
  return useQuery({
    queryKey: [`return-settings-configuration-preference`],
    queryFn: () => getReturnSettings(),
    enabled: enabled
  });
};
