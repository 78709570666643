const GradeOptionDesc = {
  Restock: 'your product will be placed back on the shelves to be sold again',
  Quarantine: 'your products will be held in quarantine until you tell us how to action them',
  Disposal: 'your products will be discarded'
};

export const tranformGradeOptions = (gradeOptions) => {
  return gradeOptions?.map((option) => {
    return {
      value: option.grade,
      label: `${option.grade}, ${GradeOptionDesc[option.grade]}`
    };
  });
};
