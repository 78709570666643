import { interval } from 'utils';

export const DATE_FORMAT_DDMMYY = 'DD/MM/YY';
export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_DDMMYYYY_HH_MM_SS = 'DD/MM/YYYY, HH:mm:ss';
export const DATE_FORMAT_DDMMYYYY_HH_MM_SS_2 = 'DD/MM/YYYY - HH:mm:ss';
export const DATE_FORMAT_MMYYYY = 'MM/YYYY';
export const DATE_DISPLAY_FORMAT_DDMMYY = 'DD/MM/YY';
export const DATE_DISPLAY_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const NUMBER_OF_DAYS_IN_MONTH = 42;
export const NUMBER_OF_COLUMNS_IN_MONTH = 7;
export const NUMBER_OF_ROWS_IN_MONTH = 6;
export const RANGE_PRESETS = [
  { id: 1, name: 'Yesterday' },
  { id: 2, name: 'Last 7 days' },
  { id: 3, name: 'Last 14 days' },
  { id: 4, name: 'Last 30 days' },
  { id: 5, name: 'Last 60 days' },
  { id: 6, name: 'Last 90 days' }
];

export const DATE_RANGE_PICKER_DEFAULT_VALUE = {
  startDate: null,
  endDate: null,
  definedRangeSelected: -1
};

export const DAYS_OF_WEEKEND = [0, 6];
export const DAY_STRING_DEFAULT_DISPLAY = 'Select a start date';
export const TODAY = 'Today';
export const STARTDAY = 'Start date';
export const ENDDAY = 'End date';
export const PRESETS_MAPPER = (toDate, calendar, days = 1) => ({
  toDate,
  fromDate: calendar.clone().subtract(days, 'day')
});
export const END_DATE_MAPPER = {
  true: (isOpen, day, startDate) => ({
    endDate: isOpen && day >= startDate ? day : null,
    closedOrOpen: isOpen && day >= startDate ? interval.CLOSED : interval.OPEN
  }),
  false: (isOpen, day, _) => ({
    endDate: isOpen ? day : null,
    closedOrOpen: isOpen ? interval.CLOSED : interval.OPEN
  })
};
