import React, { useState, useEffect, useRef, forwardRef } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import filter from 'lodash/filter';
import lowerCase from 'lodash/lowerCase';
import includes from 'lodash/includes';
import uniqueId from 'lodash/uniqueId';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useDisclosure,
  useOutsideClick
} from '@chakra-ui/react';

import { useLazyLoading } from 'hooks/useLazyLoading';

import SlzSearchInput from './SlzSearchInput';
import { AutocompleteInputSkeleton } from '../Skeleton/Skeleton';
import { dropdownTableSx } from './styles';

const VARIANT = {
  STANDARD: 'standard',
  ACCENT: 'accent'
};

const StandardRow = forwardRef(({ record, showedFields, boldField, onNavigate }, ref) => {
  return (
    <Tr ref={ref} sx={{ '& > td': { px: '0.625rem' } }} onClick={onNavigate}>
      {map(showedFields, (field, j) => {
        const name = get(record, `${field}.name`);
        return (
          <Td
            key={j}
            fontWeight={field === boldField && 'medium'}
            minW="20"
            sx={{
              'tr > &': {
                bgColor: 'unset'
              }
            }}
            _first={{ roundedLeft: 1 }}
            _last={{ roundedRight: 1 }}>
            {name ? name : get(record, field)}
          </Td>
        );
      })}
    </Tr>
  );
});

const AccentCell = ({ field, boldField, accentField, record }) => {
  const isAccent = field === accentField;
  const name = get(record, `${field}.name`);

  const color = get(record, `${field}.color`);

  const tdStyle = color
    ? {
        'tr > &': {
          bgColor: color,
          color: 'white'
        },
        'tr:hover > &': {
          bgColor: color
        }
      }
    : {
        'tr > &': {
          bgColor: 'unset'
        }
      };

  return (
    <Td
      minW="20"
      fontWeight={field === boldField && 'medium'}
      data-accented={isAccent || undefined}
      sx={{ ...tdStyle, ...field.customStyles }}>
      {field.showHeader ? (
        <>
          <Box display="flex" flexDirection="column" alignItems="baseline" h="100%">
            <Box sx={{ ...field.customHeaderStyles }}>{field.header}</Box>
            <Box>{get(record, field.name) || get(record, field.secondOptionValue)}</Box>
          </Box>
        </>
      ) : (
        name || get(record, field.name) || get(record, field)
      )}
    </Td>
  );
};

const AccentRow = forwardRef(
  ({ record, showedFields, boldField, accentField, onNavigate }, ref) => {
    const accentColor = record[accentField]?.color;
    return (
      <Tr
        ref={ref}
        pos="relative"
        sx={{
          '& > td': { px: '0.625rem' },
          '&:hover > td[data-accented]': {
            bg: accentColor
          }
        }}
        onClick={onNavigate}
        _before={{
          content: '""',
          pos: 'absolute',
          w: '0.5625rem',
          h: '100%',
          bg: accentColor
        }}>
        {map(showedFields, (field, j) => (
          <AccentCell
            key={j}
            record={record}
            field={field}
            boldField={boldField}
            accentField={accentField}
          />
        ))}
      </Tr>
    );
  }
);

function SearchDropdown({
  variant,
  results,
  showedFields,
  boldField,
  accentField,
  onNavigate,
  process,
  isFetching,
  isFirstLoaded
}) {
  return (
    <>
      <TableContainer {...dropdownTableSx}>
        <Table size="sm" boxShadow="none">
          <Tbody>
            <AutocompleteInputSkeleton
              isFirstLoaded={isFirstLoaded}
              isLoaded={!isFetching}
              process={process}>
              {size(showedFields) > 0 &&
                results?.map((record, index) => {
                  if (variant === VARIANT.ACCENT)
                    return (
                      <AccentRow
                        key={`${record?.id}-${index}`}
                        showedFields={showedFields}
                        boldField={boldField}
                        accentField={accentField}
                        record={record}
                        ref={record?.ref}
                        onNavigate={() => onNavigate && onNavigate(record)}
                      />
                    );

                  return (
                    <StandardRow
                      key={`${record?.id}-${index}`}
                      showedFields={showedFields}
                      boldField={boldField}
                      ref={record?.ref}
                      record={record}
                      onNavigate={() => onNavigate && onNavigate(record)} //this one will be adjust late
                    />
                  );
                })}
            </AutocompleteInputSkeleton>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

function doFilter(items, { keyword, sensitive = false, searchBy = [], onFound }) {
  return filter(items, (item) => {
    for (const field of searchBy) {
      const text = JSON.stringify(
        get(item, `${field}.color`) ? get(item, `${field}.name`) : get(item, field)
      );

      if (sensitive && includes(text, keyword)) {
        onFound(field);
        return item;
      }

      if (includes(lowerCase(text), lowerCase(keyword))) {
        onFound(field);
        return item;
      }
    }
  });
}

const SlzAutocompleteInput = (props) => {
  const {
    isLocal = false,
    sensitive,
    searchBy = [],
    showedFields = [],
    variant = VARIANT.STANDARD || VARIANT.ACCENT,
    accentField,
    clearable = true,
    placeholder,
    customInputStyles,
    showfieldsHeader,
    onSearch,
    onTypingSearch,
    onReset,
    onNavigate,
    process,
    searchParams,
    fetchFn,
    transformFn,
    initValue
  } = props;
  const [results, setResults] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const [boldField, setBoldField] = useState();
  const [enabled, setEnabled] = useState(false);
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const processRef = useRef(process);
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => onClose()
  });

  const { data, isFetching, remove } = useLazyLoading({
    filters: searchParams,
    fetchFn: fetchFn,
    transformFn: transformFn,
    enabled: enabled
  });

  const onTyping = (searchTermInput) => {
    setSearchTerm(searchTermInput);
    onTypingSearch && onTypingSearch(searchTermInput);

    if (size(searchTermInput) < minStartSearch) {
      setResults([]);
      setEnabled(false);
      onClose();
      return;
    }

    remove();
    setEnabled(true);
    onOpen();
    setIsResetEnabled(false);

    if (!isLocal) return;

    if (size(searchBy) > 0) {
      const results = doFilter(data?.pages.flat(1), {
        keyword: searchTermInput,
        sensitive,
        searchBy,
        onFound: setBoldField
      });

      setResults(results);
      size(results) > 0 ? onOpen() : onClose();
    }
  };

  // can be clear, need to be confirm
  useEffect(() => {
    if (onReset) {
      onReset(() => setSearchTerm(''));
    }
  }, [onReset]);

  useEffect(() => {
    if (!isLocal && data !== undefined && data.pages[0] !== undefined) {
      setResults(data?.pages.flat(1));
    }

    if (isFetching === false && data === undefined && results.length === 0) {
      onClose();
    }
  }, [isFetching]);

  useEffect(() => {
    if (processRef.current !== process) {
      setResults([]);
      setIsResetEnabled(true);
      processRef.current = process;
    }
  }, [process]);

  return (
    <Box id={uniqueId('SEL-Slz-IconButton')} ref={ref} pos="relative" w={props.width}>
      <SlzSearchInput
        isResetEnable={isResetEnabled}
        clearable={clearable}
        placeholder={placeholder}
        customInputStyles={customInputStyles}
        onTyping={debounce(onTyping, 300)}
        onFocus={() => size(searchTerm) >= minStartSearch && onOpen()}
        onSearch={onSearch}
        initValue={initValue}
      />
      {/* TODO: DM-376 - Configure autocomplete feature */}
      {/* {isOpen > 0 && (
        <SearchDropdown
          isFirstLoaded={data === undefined && results.length === 0}
          results={results}
          variant={variant}
          showedFields={showedFields}
          accentField={accentField}
          boldField={boldField}
          onNavigate={onNavigate}
          process={process}
          searchParams={searchParams}
          fetchFn={fetchFn}
          transformFn={transformFn}
          isFetching={isFetching}
        />
      )} */}
    </Box>
  );
};

export default SlzAutocompleteInput;
SlzAutocompleteInput.propTypes = {
  clearable: PropTypes.bool,
  showfieldsHeader: PropTypes.bool,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'accent']),
  accentField: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  customInputStyles: PropTypes.object,
  searchBy: PropTypes.arrayOf(PropTypes.string),
  showedFields: PropTypes.arrayOf(PropTypes.string)
};
