import breakpoints from './breakpoints';
import colors from './colors';
import typography from './typography';
import spacing from './spacing';
import borderRadius from './borderRadius';
import sizes from './size';
import shadows from './shadows';
import borders from './borders';
import zIndices from './z-index';

export const foundations = {
  breakpoints,
  colors,
  shadows,
  borders,
  zIndices,
  ...typography,
  ...spacing,
  ...borderRadius,
  ...sizes,
};
