import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function TruckIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.165 24.69"
      width="33.165"
      height="24.69"
      fill="currentColor"
      {...props}>
      <g id="Orders_section_icon" data-name="Orders section icon" transform="translate(1 1)">
        <circle
          id="Ellipse_27"
          data-name="Ellipse 27"
          cx="3.241"
          cy="3.241"
          r="3.241"
          transform="translate(4.862 16.207)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_28"
          data-name="Ellipse 28"
          cx="3.241"
          cy="3.241"
          r="3.241"
          transform="translate(21.069 16.207)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3494"
          data-name="Path 3494"
          d="M145.638,2218H142.4v-6.483m-1.621-12.966H158.6V2218m-6.483,0h9.724m6.483,0h3.241v-9.724H158.6m0-8.1h8.1l4.862,8.1"
          transform="translate(-140.776 -2198.551)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_32"
          data-name="Line 32"
          x2="6.483"
          transform="translate(1.62 6.483)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
