import { Box, Flex, Text } from '@chakra-ui/react';
import { SlzChip } from 'components/common/SlzChip';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';

export const PRODUCT_IMAGES_REQUIRED_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 81,
    type: COL_CUSTOM
  },
  {
    name: 'Product',
    field: 'name',
    width: 280,
    render: ({ row, field }) => (
      <Text fontSize="0.75rem" fontWeight="medium" color="dark.700" textAlign="center">
        {row[field]}
      </Text>
    )
  },
  {
    name: 'SKU',
    field: 'skuCode',
    width: 280,
    isCopyable: true,
    render: ({ row, field, paddingValue }) => {
      return (
        <Flex px={paddingValue} justifyContent="center">
          <Box>
            {row[field] && (
              <SlzChip
                flex="1"
                bg="light.700"
                color="dark.700"
                _hover={{
                  bg: 'dark.300'
                }}>
                {row[field] || ''}
              </SlzChip>
            )}
          </Box>
        </Flex>
      );
    }
  },
  {
    name: 'Tag',
    field: 'tag',
    width: 170,
    isEmphasis: true
  }
];
