export default {
  luckyCharm: {
    300: '#0DA55F',
    500: '#1C9D5E',
    700: '#157749'
  },
  tetra: {
    300: '#27F490',
    500: '#23D57E',
    700: '#1B9D5D'
  },
  marine: {
    300: '#42EAE2',
    500: '#3CD1CA',
    700: '#33AAA4'
  },
  ibiza: {
    300: '#ABF018',
    500: '#9FDE14',
    700: '#81B218'
  },
  mustard: {
    300: '#F2D840',
    500: '#D5BF40',
    700: '#B5A43D'
  },
  sunshine: {
    300: '#FEE16E',
    500: '#FECB04',
    700: '#E0BA25'
  },
  californiaOrange: {
    300: '#FEAD3B',
    500: '#FDA122',
    700: '#EF971D'
  },
  percival: {
    300: '#FFC7C7',
    500: '#FDADAD',
    700: '#F2A6A6'
  },
  sunrise: {
    300: '#FF8458',
    500: '#FD8458',
    700: '#F05C28',
    800: '#FD642E'
  },
  strawberry: {
    100: '#FC506B19',
    300: '#FC506B',
    500: '#EF3753',
    700: '#DE2E4A'
  },
  cherryBomb: {
    300: '#D1345B',
    500: '#B23050',
    700: '#6F483D'
  },
  willow: {
    300: '#986557',
    500: '#7F5347',
    700: '#6F483D'
  },
  barb: {
    300: '#FF55AB',
    500: '#FC158A',
    700: '#EA0F7F'
  },
  bubblegum: {
    300: '#FFB5F8',
    500: '#FAA1F1',
    700: '#E692DE'
  },
  coral: {
    300: '#FF9090',
    500: '#FD7575',
    700: '#E56D6D'
  },
  dusk: {
    300: '#5F36B1',
    500: '#401694',
    700: '#2D1165'
  },
  indigo: {
    300: '#767AEA',
    500: '#5559DF',
    700: '#4649B9'
  },
  primrose: {
    300: '#936AE3',
    500: '#784BD1',
    700: '#6741B4'
  },
  grunge: {
    300: '#9A4CA8',
    500: '#7E3B8A',
    700: '#70327B'
  },
  mauve: {
    300: '#B87AEB',
    500: '#A25DDC',
    700: '#8F50C4'
  },
  noir: {
    300: '#606060',
    500: '#333333',
    700: '#191818'
  },
  charcoal: {
    300: '#969696',
    500: '#808080',
    700: '#646262'
  },
  pebbel: {
    300: '#D0D0D0',
    500: '#C4C4C4',
    700: '#B5B4B4'
  },
  stone: {
    300: '#C3C4CB',
    500: '#C3C4CB',
    700: '#C3C4CB'
  },
  storm: {
    300: '#ACC0D1',
    500: '#9AADBD',
    700: '#8B9FAD'
  },
  iceberg: {
    300: '#77B8D8',
    500: '#68A1BD',
    700: '#598CA5'
  },
  szrMaya: {
    300: '#88D7FF',
    500: '#66CCFF',
    700: '#5CB8E6'
  },
  szrCerulean: {
    300: '#73ABFC',
    500: '#579BFC',
    700: '#4E8ADE'
  },
  szrDenim: {
    300: '#3366AD',
    500: '#225091',
    700: '#1C4277'
  },
  szrElectricBlue: {
    300: '#6DBDFD',
    500: '#4DABF7',
    700: '#449ADE'
  },
  szrNavy: {
    300: '#0F5083',
    500: '#023156',
    700: '#01213B'
  },
  szrLeaf: {
    300: '#88D660',
    500: '#6EBD45',
    700: '#41891B'
  },
  main: {
    300: '#CCE5FF',
    500: '#0973EA',
    700: '#0760B9'
  },
  positive: {
    300: '#BDE5CF',
    500: '#2A985A',
    700: '#0F773C'
  },
  negative: {
    300: '#F5CCD3',
    500: '#DE4359',
    700: '#AD3343'
  },
  light: {
    300: '#FAFAFA',
    500: '#F2F2F2',
    700: '#DEDEDE'
  },
  dark: {
    300: '#C3C4CB',
    500: '#676A74',
    700: '#303134'
  },
  red: {
    300: '#DE4259',
    500: '#DE4259',
    700: '#DE4259'
  },
  slzrLilac: {
    300: '#EDEEFC',
    500: '#DDDFFF',
    700: '#E5E7FF'
  },
  raisin: {
    300: '#222222'
  },
  creamsicle: {
    500: '#FFAD3B'
  },
  aconitePurple: {
    300: '#784BD14D'
  },
  halfAndCatchFire: {
    300: '#FD642E4D'
  },
  success: '#2A985A',
  error: '#DE4359',
  warning: '#FECB04',
  info: '#0973EA',
  white: '#FFFFFF',
  black: '#000000',
  primary: {
    main: '#0973EA',
    main50: '#0973EA',
    mainHover: '#0760B9',
    mainHover50: '#0760B9',
    mainHover80: '#0973EA80',
    mainSelected: '#CCE5FF',
    mainSelected50: '#CCE5FF80',
    mainBorderSelected: '#3371E2',
    mainDisabled: '#DEDEDE',
    positive: '#2A985A',
    positive50: '#2A985A',
    positiveHover: '#0F773C',
    positiveHover50: '#0F773C',
    positiveHover80: '#2A985A80',
    positiveSelected: '#BDE5CF',
    positiveSelected30: '#BDE5CF30',
    positiveSelected50: '#BDE5CF',
    negative: '#DE4259',
    negative50: '#2A985A',
    negativeHover: '#AD3343',
    negativeHover50: '#AD3343',
    negativeHover80: '#DE425980',
    negativeSelected: '#F5CCD3',
    negativeSelected30: '#F5CCD330',
    negativeSelected50: '#F5CCD3',
    lightBorder60: '#EEEEEE'
  },
  lightAndShadow: {
    mainText: '#303134',
    darkModeText: '#ffffff',
    secondaryText: '#676A74',
    disableText: '#C3C4CB',
    borderObject: '#DEDEDE',
    borderText: '#C3C4CA',
    disabledObject: '#F2F2F2',
    background: '#FAFAFA',
    reverseText: '#FFFFFF'
  },
  table: {
    headText: '#303134',
    mainText: '#000000',
    rowBg: '#F2F2F2',
    rowBgHover: '#F2F2F2',
    eoriOddRowBg: '#CCE5FF4D'
  },
  list: {
    headText: '#303134',
    mainText: '#303134',
    rowBg: '#ffffff',
    rowBgHover: '#ffffff',
    borderColor: '#C3C4CA'
  },
  attention: {
    warningBg: '#FECB04',
    ghostBg: '#F2F2F2'
  },
  icon: {
    500: '#3474E2'
  },
  notification: {
    number: '#D33007',
    optionContainer: '#BBBCC4'
  },
  checkbox: {
    invalid: {
      300: '#F5CCD333',
      500: '#DE4259'
    }
  },
  dropdown: {
    container: {
      border: {
        color: '#C3C4CA'
      }
    },
    control: {
      hover: {
        border: {
          color: '#2B60B3'
        }
      }
    }
  },
  paypal: {
    500: '#243b80'
  },
  visa: {
    500: '#0866b2'
  },
  mastercard: {
    500: '#36495d'
  },
  american_express: {
    500: '#096fd0'
  },
  BACS: {
    500: '#70327B'
  },
  yellowTan: {
    500: '#fee16e'
  },
  aero: {
    500: '#7db2ee'
  },
  kinglyCloud: {
    500: '#dedede'
  },
  elemental: {
    500: '#d0d1d4'
  },
  flatAluminum: {
    500: '#c3c4cb'
  },
  boxShadow: '#00000034',
  userSettingNavItem: {
    borderColor: '#e2e8f0',
    bgHover: '#aaaaaa'
  },
  skeleton: {
    background: '#E2E2E2',
    lineColor0: '#eaeaea00',
    lineColor20: '#eaeaea33',
    lineColor50: '#eaeaea80'
  },
  gradeDescription: {
    activedBorder: '#3474E2',
    disabledBorder: '#C3C4CA'
  }
};
