import { useState, useCallback } from 'react';
import { dayjs } from 'utils';
import { Box, HStack, Tab, TabList, Tabs } from '@chakra-ui/react';

import SlzDateRangePicker from 'components/common/SlzDateRangePicker';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants/date';
import { RETURN_DISPLAY_FIELDS_INPUT_SEARCH } from 'modules/returns/constants';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { getReturns } from 'modules/returns/services/returns-api';
import { transformReturns } from 'modules/returns/mappers/return-list-mapper';

const ReturnsFilter = (props) => {
  const { onSearchingNavigate, params, onDateRangeChange, onSearch, initSearchValue } = props;

  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  const handleAutoCompleteSearch = (searchTerm) => {
    if (!searchTerm) {
      onSearch(searchTerm);
    }

    setSearchParams({
      pageIndex: DEFAULT_PAGE_INDEX,
      search: searchTerm
    });
  };

  const transformFn = useCallback((data) => transformReturns(data).returns, []);

  return (
    <Box>
      <HStack mb={6} gap="1.5625rem">
        <SlzAutocompleteInput
          width="30%"
          variant="accent"
          showedFields={RETURN_DISPLAY_FIELDS_INPUT_SEARCH}
          placeholder="Search"
          onTypingSearch={handleAutoCompleteSearch}
          transformFn={transformFn}
          onNavigate={onSearchingNavigate}
          fetchFn={getReturns}
          searchParams={searchParams}
          onSearch={onSearch}
          initValue={initSearchValue}
        />

        <SlzDateRangePicker
          onDateRangeClick={onDateRangeChange}
          startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
          endDatePlaceHolder={TODAY}
          key={`${params?.dateFrom}-${params?.dateTo}`}
          startDate={params?.dateFrom ? dayjs(params?.dateFrom) : null}
          endDate={params?.dateTo ? dayjs(params?.dateTo) : dayjs()}
        />
      </HStack>
      <HStack gap="25px" w="full" mb={6}>
        <Tabs variant="line" size={['sm', 'md', 'lg']} index={0}>
          <TabList>
            <Tab w={36} key="All">
              Returns
            </Tab>
          </TabList>
        </Tabs>
      </HStack>
    </Box>
  );
};

export default ReturnsFilter;
