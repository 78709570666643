import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { Box, Table, TableContainer, Tbody, Td, Tr, VStack, useMediaQuery } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { isEmpty, lowerCase, map, orderBy, uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

import { useGlobalState } from 'contexts/GlobalContext';
import { DATE_FORMAT_DDMMYYYY } from '../../../constants';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { isBetween } from '../../../utils/dates';
import { SEL_PREFIX_ID } from '../Constants';
import { CELL_MARKED_WIDTH } from '../Layout/Constants';
import { skeletonAnimation as animation } from '../Skeleton/config';
import { Skeleton } from '../Skeleton/Skeleton';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from 'constants/table.js';
import SlzPagination from '../SlzPagination/SlzPagination';
import { DEFAULT_EMPTY_MSG, NUM_OF_SECONDARY_MENU_COLUMN, TABLES } from './Constants';
import Header from './Header';
import Rows from './Rows';
import './Table.scss';
import { TableProvider } from './TableContext';
import Toolbar from './Toolbar';
import {
  calculatorColumnFixedWidth,
  calculatorTableDimensions,
  countColumnDisplay,
  getFields,
  mappingAccordionRows,
  mappingEmphasisFieldTable
} from './utils';
import useSlzToast from 'hooks/useSlzToast';
import { SelazarMainIcon } from '../Icons';
import { SlzButton } from '../SlzButton';

const SlzTable = (props) => {
  const {
    isLoading = false,
    variant,
    sizes,
    isEmptyPage = false,
    onRefetching,
    messageToast,
    showMarked = false,
    showHeader = true,
    showToolbar = true,
    showBoxShadow = true,
    markedWidth = CELL_MARKED_WIDTH,
    columns = [],
    data: dataSource = [],
    events = {},
    targetField = null,
    displayFields = [],
    hyperLinks = [],
    filters = {
      title: 'Filter',
      byField: '',
      options: []
    },
    filterByDate = {
      field: null,
      start: null,
      end: null
    },
    rowProps = {
      hasExpandedRow: false,
      accordionProps: {
        template: <></>
      }
    },
    actions = null,
    pagination,
    onSelectedRow,
    autoResize = true,
    tableWidth,
    preLoadersSkeleton: PreLoadersSkeleton,
    emptyMsg,
    ...rest
  } = props;
  const [context, setContext] = useState({
    columnWidth: 0,
    paddingValue: 0,
    columns,
    rows: [],
    displayFields,
    rowProps,
    actions,
    targetField,
    variant,
    isLoading,
    hyperLinks
  });
  const [{ app }] = useGlobalState();
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [totalPage, setTotalPage] = useState(dataSource.length);
  const [itemPerPage, setItemPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentItems, setCurrentItems] = useState(dataSource);
  const [items, setItems] = useState(dataSource);
  const [rows, setRows] = useState([]);
  const [direction, setDirection] = useState(0);
  const [sortedField, setSortedField] = useState(null);
  const [activeRowId, setActiveRowId] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { width } = useWindowDimensions();
  const [isLargerThan1279] = useMediaQuery('(min-width: 1279px)');
  const [_, setToast] = useSlzToast();
  const history = useHistory();
  const routerQuery = useRouterQuery();

  const getDimension = useMemo(() => {
    const columnFixedWidth = calculatorColumnFixedWidth({
      showMarked,
      actions,
      columns,
      displayFields,
      markedWidth,
      isAccordion: rowProps?.hasExpandedRow
    });
    const columnDisplayCount = countColumnDisplay({ columns, displayFields });
    return autoResize
      ? calculatorTableDimensions({
          columnFixedWidth,
          columnDisplayCount,
          isLargerThan1279,
          width,
          isNavbarExpanded: app?.isNavbarExpanded
        })
      : { columnWidth: 'auto', paddingValue: 'auto' };
  }, [
    width,
    columns,
    showMarked,
    // showImage,
    app?.isNavbarExpanded,
    actions,
    markedWidth,
    // imageWidth,
    isLargerThan1279,
    rowProps?.hasExpandedRow
  ]);

  const filterRowsByDate = (resources) => {
    const { start, end } = filterByDate;

    if (!start && !end) {
      return resources ?? currentItems;
    }

    return [...(resources ?? currentItems)].filter((item) => {
      if (!item[filterByDate?.field]) {
        return;
      }

      const dateItem = dayjs(item[filterByDate?.field], DATE_FORMAT_DDMMYYYY, true);
      const isBefore = start && !end && start.isBefore(dateItem);
      const isAfter = !start && end && end.isAfter(dateItem);

      return isBefore || isAfter || isBetween(start, end, dateItem);
    });
  };

  const filterRowsByIdentifiers = (resources) => {
    const valuesFilters = map(selectedFilters, (filterValue) => lowerCase(filterValue?.key));

    if (isEmpty(valuesFilters)) {
      return resources ?? currentItems;
    }

    if (filters?.byField) {
      return [...(resources ?? currentItems)].filter((item) => {
        return (
          item[filters?.byField] && valuesFilters.includes(lowerCase(item[filters?.byField]?.name))
        );
      });
    } else {
      return [...(resources ?? currentItems)].filter((item) => {
        return selectedFilters.some(
          (filter) => filter?.byField && item[filter?.byField] === filter.value
        );
      });
    }
  };

  const [tableFilters, setTableFilters] = useReducer((prev, next) => {
    let newItems = [];

    if (next?.selectedFilters) {
      if (filterByDate?.start) {
        newItems = filterRowsByDate();
      }
      newItems = filterRowsByIdentifiers(isEmpty(newItems) ? currentItems : newItems);
    }

    if (next?.filterByDate) {
      if (selectedFilters) {
        newItems = filterRowsByIdentifiers();
      }
      newItems = filterRowsByDate(isEmpty(newItems) ? currentItems : newItems);
    }

    setItems(newItems);
  }, {});

  useEffect(() => {
    setContext((context) => ({
      ...context,
      ...getDimension
    }));
  }, [width, isLargerThan1279, app?.isNavbarExpanded]);

  useEffect(() => {
    if (!isLoading) {
      setItems(dataSource);
      setCurrentItems(dataSource);
    }
  }, [dataSource, isLoading]);

  useEffect(() => {
    if (!!rowProps?.hasExpandedRow) {
      setContext((context) => ({
        ...context,
        rows: rowProps?.hasExpandedRow ? mappingAccordionRows(rows) : rows
      }));
    }
  }, [rowProps?.hasExpandedRow]);

  useEffect(() => {
    setContext((context) => ({
      ...context,
      isLoading
    }));
  }, [isLoading]);

  useEffect(() => {
    setTableFilters({
      ...tableFilters,
      filterByDate
    });
  }, [filterByDate.start, filterByDate.end]);

  useEffect(() => {
    setContext((context) => ({
      ...context,
      displayFields
    }));
  }, [displayFields]);

  const {
    onSort = useCallback(
      (column) => {
        column?.field && setSortedField(column?.field);
        setDirection(direction === 1 ? 0 : 1);

        routerQuery.set('orderBy', `${column?.field}&${direction}`);
        routerQuery.toString();
        history.push({
          search: routerQuery.toString(),
          state: { prevPath: history.location.pathname }
        });
      },
      [direction]
    )
  } = events;

  const handleItemPerPageChange = (numPerPage) => {
    setItemPerPage(numPerPage);
  };

  const handleCurrentPageChange = (currentPage) => {
    currentPage <= totalPage && setCurrentPage(currentPage);
  };

  useEffect(() => {
    setTotalPage(Math.ceil(items.length / itemPerPage));
  }, [itemPerPage, items]);

  useEffect(() => {
    setCurrentPage(DEFAULT_PAGE_INDEX);
  }, [itemPerPage, totalPage]);

  useEffect(() => {
    if (items.length && true === pagination?.isLocal) {
      return setRows(items.slice(itemPerPage * (currentPage - 1), currentPage * itemPerPage));
    }

    setRows(items);
  }, [items, currentPage, itemPerPage]);

  useEffect(() => {
    if (items.length) {
      setItems((prev) => {
        const newItems = [...prev];
        return orderBy(
          newItems,
          [mappingEmphasisFieldTable(sortedField)],
          [TABLES.orderBy[direction]]
        );
      });
    }
  }, [direction, sortedField]);

  useEffect(() => {
    setTableFilters({
      ...tableFilters,
      selectedFilters
    });
  }, [selectedFilters]);

  const handleSelectedRow = useCallback(
    (id, row) => {
      onSelectedRow && onSelectedRow(id, row);
      setActiveRowId(id);
    },
    [onSelectedRow]
  );

  const handleSelectedFilter = (selectedFilters) => {
    if (filters?.isLocal) {
      setSelectedFilters(selectedFilters);
      return;
    }
    filters?.handleSelectedFilter && filters?.handleSelectedFilter(selectedFilters);
  };

  const getPaginationProps = () => {
    if (pagination && pagination?.direction && pagination.isLocal) {
      pagination.direction = {
        ...pagination.direction,
        currentPage,
        totalPage,
        itemPerPage,
        onChange: (numPerPage) => {
          handleCurrentPageChange(numPerPage);
        }
      };
    }

    if (pagination && pagination?.pages && pagination.isLocal) {
      pagination.pages = {
        ...pagination.pages,
        onChange: (itemPerPage) => {
          handleItemPerPageChange(itemPerPage);
        }
      };
    }

    return pagination || {};
  };

  useEffect(() => {
    if (isEmptyPage && !isLoading) {
      setToast({
        title: messageToast,
        status: 'warning',
        colorScheme: 'negative'
      });
    }
  }, [isEmptyPage, isLoading]);

  return (
    <TableProvider value={context}>
      <Box
        data-testid="slz-table-test"
        boxShadow={showBoxShadow && '0px 0.188rem 0.375rem #00000029'}
        borderTopLeftRadius="0.5rem"
        borderTopRightRadius="0.5rem"
        id={uniqueId(`${SEL_PREFIX_ID}-Slz-Table`)}>
        {isLoading ? (
          <Skeleton
            borderTopLeftRadius="0.5rem"
            borderTopRightRadius="0.5rem"
            animation={animation}
            width="100%"
            height="100%"></Skeleton>
        ) : (
          <Toolbar
            filters={filters}
            showToolbar={showToolbar}
            pagination={pagination}
            paginationProps={getPaginationProps()}
            onFilter={handleSelectedFilter}
          />
        )}
        <TableContainer {...rest}>
          <Table {...rest} sx={{ ...rest.sx, w: tableWidth }} variant={variant}>
            {showHeader && (
              <Header
                columns={columns}
                actions={actions}
                showMarked={showMarked}
                // showImage={showImage}
                displayFields={displayFields}
                onSort={onSort}
              />
            )}
            <Tbody>
              {rows?.length > 0 ? (
                <Rows
                  rows={rows}
                  columns={columns}
                  actions={actions}
                  targetField={targetField}
                  hyperLinks={hyperLinks}
                  activeRowId={activeRowId}
                  showMarked={showMarked}
                  markedWidth={markedWidth}
                  // showImage={showImage}
                  setRows={setRows}
                  onClick={handleSelectedRow}
                />
              ) : isLoading ? (
                <Skeleton
                  borderTopLeftRadius="0.5rem"
                  borderTopRightRadius="0.5rem"
                  animation={animation}
                  width="100%"
                  height="100%"></Skeleton>
              ) : (
                <EmptyRows
                  cols={columns?.length + (showMarked ? 1 : 0) + NUM_OF_SECONDARY_MENU_COLUMN}
                  variant={variant}
                  onRefetching={onRefetching}
                  emptyMsg={emptyMsg}
                />
              )}
              {PreLoadersSkeleton && <PreLoadersSkeleton />}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </TableProvider>
  );
};

const EmptyRows = ({ cols, variant, onRefetching, emptyMsg }) => (
  <Tr>
    {emptyMsg ? (
      <Td colSpan={cols} fontSize="sm" bg="light.500">
        {emptyMsg}
      </Td>
    ) : (
      <Td
        className={`empty-${variant}`}
        colSpan={cols}
        bg="light.500"
        height="70vh"
        _hover={{ bg: 'light.500 !important' }}>
        <VStack gap={6}>
          <SelazarMainIcon w="full" />
          <SlzButton variant="outline" size="lg" onClick={onRefetching}>
            Reload page
          </SlzButton>
        </VStack>
      </Td>
    )}
  </Tr>
);

SlzTable.propTypes = {
  variant: PropTypes.arrayOf(TABLES.variant),
  showMarked: PropTypes.bool,
  showHeader: PropTypes.bool,
  showToolbar: PropTypes.bool,
  // showImage: PropTypes.bool,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  displayFields: PropTypes.array,
  hyperLinks: PropTypes.array,
  events: PropTypes.object,
  actions: PropTypes.object,
  pagination: PropTypes.instanceOf(SlzPagination)
};
export default SlzTable;
