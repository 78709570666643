import { Accordion, Fade, HStack, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import SlzTable from 'components/common/SlzTable/Table';
import useSlzToast from 'hooks/useSlzToast';

import {
  CUSTOM_BOX_SPLIT_BUTTONS_STYLE,
  CUSTOM_BOX_SPLIT_STYLES
} from 'modules/b2b-orders/constants/styles';
import B2BAccordionTable from 'modules/b2b-orders/components/table/B2BAccordionTable';
import { getB2BProductSelectionColumns } from 'modules/b2b-orders/components/table/bulk-order-box-split.columns';
import {
  B2B_CUSTOM_BOX_SPLIT_CONTENT,
  B2B_CUSTOM_BOX_SPLIT_TOAST,
  BOX_SPLIT_ERROR_TYPE,
  CUSTOM_BOX_CHECKING_FLAGS,
  CUSTOM_BOX_ERROR_FLAG,
  CUSTOM_BOX_PREFIX,
  VALIDATE_STATUS
} from 'modules/b2b-orders/constants';
import {
  customBoxDefaultValuesMapper,
  filterArray,
  manualSplitPayloadMapper
} from 'modules/b2b-orders/utils';
import { AddBoxButton, DeleteBoxButton } from '.';
import useValidateManualSplitMutation from 'modules/b2b-orders/hooks/useValidateManualSplitMutation';

const CustomBoxSplitSetup = ({
  setValidateProgress,
  defaultValues,
  step,
  setValidatedBoxes,
  setIsValidating
}) => {
  const useValidateMutation = useValidateManualSplitMutation();
  const { setValue, getValues, watch, reset } = useFormContext();
  const [_, setToast, toast] = useSlzToast();
  const [isShowActions, onToggle] = useBoolean();

  useEffect(() => {
    filterArray(getValues(CUSTOM_BOX_PREFIX)).length === 0 &&
      setValue(CUSTOM_BOX_PREFIX, [
        customBoxDefaultValuesMapper(defaultValues?.stockSelection),
        customBoxDefaultValuesMapper(defaultValues?.stockSelection)
      ]);
  }, []);

  useEffect(() => {
    const itemError = getValues(CUSTOM_BOX_ERROR_FLAG.itemError);
    const boxError = getValues(CUSTOM_BOX_ERROR_FLAG.boxError);

    if (itemError?.shouldValidate && boxError?.shouldValidate) {
      const isItemErrorEmpty = handleErrorMessage(itemError.errors);
      const isBoxErrorEmpty = handleErrorMessage(boxError.errors);

      if (isItemErrorEmpty && isBoxErrorEmpty) {
        handleValidateManualSplit();
      }
    }
  }, [watch(CUSTOM_BOX_ERROR_FLAG.itemError), watch(CUSTOM_BOX_ERROR_FLAG.boxError)]);

  const handleErrorMessage = (errorType) => {
    if (errorType !== undefined && errorType.length > 0) {
      !toast.isActive(errorType[0].type) &&
        setToast({
          chakraToastProps: {
            id: errorType[0].type,
            duration: null
          },
          description: B2B_CUSTOM_BOX_SPLIT_TOAST.error[errorType[0].type],
          status: B2B_CUSTOM_BOX_SPLIT_TOAST.error.status,
          colorScheme: B2B_CUSTOM_BOX_SPLIT_TOAST.error.colorScheme
        });
      setIsValidating(false);
      return false;
    }
    return errorType == undefined ? false : true;
  };

  const checkEmptyPackaging = (data) => {
    const emptyPackaging = data.boxes.filter(
      (items) => items.companyPackagingID === null && !items.isStockBox
    );

    if (emptyPackaging.length !== 0) {
      handleErrorMessage([{ type: BOX_SPLIT_ERROR_TYPE.maximumItemsInBox }]);
      setValue(CUSTOM_BOX_CHECKING_FLAGS.isManualValidateSuccess, false);

      return;
    }

    setValue(CUSTOM_BOX_CHECKING_FLAGS.isManualValidateSuccess, true);
    toast.closeAll();
    reset({}, { keepValues: true });
    setValidatedBoxes(data);
    setValidateProgress(VALIDATE_STATUS.summary);
    setIsValidating(false);
  };

  const handleValidateManualSplit = async () => {
    try {
      const { data } = await useValidateMutation.mutateAsync(
        manualSplitPayloadMapper(getValues(CUSTOM_BOX_PREFIX))
      );
      checkEmptyPackaging(data.data);
    } catch (error) {
      handleErrorMessage([{ type: BOX_SPLIT_ERROR_TYPE.generalValidationError }]);
    }
  };

  return (
    <>
      <HStack mt="0.75rem" alignItems="start" justifyContent="space-between">
        <SlzTable
          {...CUSTOM_BOX_SPLIT_STYLES.selectedTable}
          columns={getB2BProductSelectionColumns()}
          data={defaultValues?.stockSelection || []}
          {...B2B_CUSTOM_BOX_SPLIT_CONTENT.selectedTable}
        />
        <VStack
          {...CUSTOM_BOX_SPLIT_STYLES.accordionTable.wrapper}
          onMouseLeave={onToggle.off}
          onMouseEnter={onToggle.on}>
          <Accordion allowMultiple w="100%" mt="0 !important">
            {filterArray(watch(CUSTOM_BOX_PREFIX)).map((box, index) => (
              <B2BAccordionTable
                key={index}
                setValidateProgress={setValidateProgress}
                accordionIndex={index + 1}
                selectedProducts={Object.values(box)}
              />
            ))}
          </Accordion>
          <Fade style={{ width: '100%', marginTop: '0' }} in={isShowActions}>
            <HStack {...CUSTOM_BOX_SPLIT_BUTTONS_STYLE.wrapper}>
              <AddBoxButton defaultValues={defaultValues?.stockSelection} />
              <DeleteBoxButton />
            </HStack>
          </Fade>
        </VStack>
      </HStack>
    </>
  );
};

export default CustomBoxSplitSetup;
