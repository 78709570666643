import { Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Checkbox, Flex, Spacer, calc } from '@chakra-ui/react';

import { SlzTextInput } from 'components/common/SlzInput';
import { ControlledField } from 'components/form';
import { ApiPermission } from '../other';
import { API_PERMISSION_TOOLTIP_CONTENT, API_SETUP_TEXT } from 'modules/integrations/constants';
import {
  AGREE_TERM_BASE_STYLE,
  AGREE_TERM_CHECKED_STYLE
} from 'modules/integrations/constants/style';
import { ApiConnectionHeading } from '../common';

const ApiSetupDetailAndPermForm = ({ permissions }) => {
  return (
    <Fragment>
      <ApiConnectionHeading text={API_SETUP_TEXT.yourApiDetail} />
      <Flex gap={3} mb={7}>
        <ControlledField
          inputGroupStyles={{
            w: calc('100%').subtract('0.75rem').divide(2).toString()
          }}
          label="Name"
          name="name"
          component={SlzTextInput}
          errorMessageStyles={{
            fontSize: 'lg'
          }}
        />
        <Spacer />
      </Flex>

      <ApiConnectionHeading
        text={API_SETUP_TEXT.permissions}
        showTooltip
        tooltipContent={API_PERMISSION_TOOLTIP_CONTENT}
      />
      <Flex w="full" flexWrap="wrap" columnGap={3} rowGap={5}>
        {permissions?.map((module) => {
          return <ApiPermission key={uniqueId()} module={module} />;
        })}
      </Flex>

      <ControlledField
        name="terms"
        errorMessageStyles={{
          fontSize: 'lg'
        }}
        component={(field) => {
          const isChecked = field?.value;
          return (
            <Flex sx={isChecked ? AGREE_TERM_CHECKED_STYLE : AGREE_TERM_BASE_STYLE}>
              <Checkbox {...field}>{API_SETUP_TEXT.confirmTerm}</Checkbox>
            </Flex>
          );
        }}
      />
    </Fragment>
  );
};

export default ApiSetupDetailAndPermForm;
