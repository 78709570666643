import { AddIcon } from '@chakra-ui/icons';
import { Stack, HStack, Text, VStack } from '@chakra-ui/react';
import {
  SlzButton,
  SlzButtonGroup,
  SlzRadioButton,
  SlzSingleButton
} from '../../components/common/SlzButton';

const Buttons = () => {
  return (
    <Stack alignItems="flex-start" spacing={5} my="8">
      <Text fontSize="2xl" fontWeight="bold">
        Buttons
      </Text>
      <Stack>
        <Text fontSize="xl">Standards</Text>
        <HStack spacing={8}>
          {['main', 'positive', 'negative'].map((color) => (
            <VStack alignItems="flex-start">
              <SlzButton size="sm" variant="solid" colorScheme={color}>
                This is a button
              </SlzButton>
              <SlzButton size="md" variant="solid" colorScheme={color}>
                This is a button
              </SlzButton>
            </VStack>
          ))}
          <VStack alignItems="flex-start">
            <SlzButton size="sm" variant="solid" isDisabled>
              This is a button
            </SlzButton>
            <SlzButton size="md" variant="solid" isDisabled>
              This is a button
            </SlzButton>
          </VStack>
        </HStack>
      </Stack>
      <Stack>
        <Text fontSize="xl">Outline</Text>
        <HStack spacing={8}>
          {['main', 'positive', 'negative'].map((color) => (
            <VStack alignItems="flex-start">
              <SlzButton size="sm" variant="outline" colorScheme={color}>
                This is a button
              </SlzButton>
              <SlzButton size="md" variant="outline" colorScheme={color}>
                This is a button
              </SlzButton>
            </VStack>
          ))}
          <VStack alignItems="flex-start">
            <SlzButton size="sm" variant="outline" isDisabled>
              This is a button
            </SlzButton>
            <SlzButton size="md" variant="outline" isDisabled>
              This is a button
            </SlzButton>
          </VStack>
        </HStack>
      </Stack>

      <Stack>
        <Text fontSize="xl">Group Buttons</Text>
        <VStack>
          <SlzButtonGroup
            size={['sm', 'md', 'lg']}
            variant="solid"
            options={[
              { value: 'left', label: 'Left' },
              { value: 'middle', label: 'Middle' },
              { value: 'right', label: 'Right' }
            ]}
            onChange={(value) => console.log(value)}
          />
          <SlzButtonGroup
            size={['sm', 'md', 'lg']}
            variant="outline"
            options={[
              { value: 'left', label: 'Left' },
              { value: 'middle', label: 'Middle' },
              { value: 'right', label: 'Right' }
            ]}
            onChange={(value) => console.log(value)}
          />
        </VStack>
      </Stack>

      <Stack>
        <Text fontSize="xl">Single Button</Text>
        <Stack spacing={8} my={5}>
          <HStack spacing={8}>
            <SlzSingleButton size="sm" icon={<AddIcon />} />
            <SlzSingleButton size="md" icon={<AddIcon />} />
            <SlzSingleButton size="lg" icon={<AddIcon />} />
            <SlzSingleButton size="lg" icon={<AddIcon />} disabled />
          </HStack>
          <HStack spacing={8}>
            <SlzSingleButton variant="outline" size="sm" icon={<AddIcon />} />
            <SlzSingleButton variant="outline" size="md" icon={<AddIcon />} />
            <SlzSingleButton variant="outline" size="lg" icon={<AddIcon />} />
            <SlzSingleButton variant="outline" size="lg" icon={<AddIcon />} disabled />
          </HStack>
          <HStack spacing={8}>
            <SlzSingleButton variant="hollow" size="sm" icon={<AddIcon />} />
            <SlzSingleButton variant="hollow" size="md" icon={<AddIcon />} />
            <SlzSingleButton variant="hollow" size="lg" icon={<AddIcon />} />
            <SlzSingleButton variant="hollow" size="lg" icon={<AddIcon />} disabled />
          </HStack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Buttons;
