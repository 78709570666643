import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';

import { fetchFulfillmentCentres } from 'services/fulfillmentCentre';
import { getUser } from 'Utilities';

import { EnabledCentreCard } from 'modules/fulfilmentCentres/components/EnabledCentreCard/EnabledCentreCard';
import { AvailableDisabledCentreCard } from 'modules/fulfilmentCentres/components/AvailableDisabledCentreCard/AvailableDisabledCentreCard';
import {
  FULFILLMENT_CENTRES_CONTENT,
  WAREHOUSE_STATE_MAPPER
} from 'modules/fulfilmentCentres/constants';

const FulfillmentCentres = () => {
  const [availableNetwork, setAvailableNetwork] = useState([]);
  const [myNetwork, setMyNetwork] = useState([]);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const company = getUser();
      const response = await fetchFulfillmentCentres(company.companyID);

      separateWarehouses(response);
    } catch (error) {
      console.log(error);
    }
  });

  const fetchCategories = async () => {
    try {
      const response = await getCategories();

      setCategories(transformCategories(response));
    } catch (error) {
      console.log(error);
    }
  };

  const separateWarehouses = () => {
    const myNetworkFiltered = warehouses?.filter((warehouse) => {
      const validStates = [1, 3];
      if (validStates.includes(warehouse.state)) {
        warehouse.state = WAREHOUSE_STATE_MAPPER[warehouse.state];
        return warehouse;
      }
    });

    const availableNetworkFiltered = warehouses?.filter((warehouse) => {
      const validStates = [0, 1];
      if (validStates.includes(warehouse.state)) {
        warehouse.state = WAREHOUSE_STATE_MAPPER[warehouse.state];
        return warehouse;
      }
    });

    setMyNetwork(myNetworkFiltered);
    setAvailableNetwork(availableNetworkFiltered);
  };

  return (
    <Tabs variant="line">
      <TabList>
        {FULFILLMENT_CENTRES_CONTENT.TABS_LIST.map((network) => (
          <Tab key={network.key}>{network.name}</Tab>
        ))}
      </TabList>

      <TabPanels>
        <TabPanel>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="1.5rem">
            {myNetwork.map((warehouse) => {
              return (
                <EnabledCentreCard
                  categories={categories}
                  isPostMVP={false}
                  warehouse={warehouse}
                />
              );
            })}
          </Box>
        </TabPanel>
        <TabPanel>
          <Box mb="1.25rem">
            <Text fontSize="1.063rem" fontWeight="bold" mb="0.688">
              {FULFILLMENT_CENTRES_CONTENT.AVAILABLE_NETWORK.title}
            </Text>
            <Text fontSize="1rem">{FULFILLMENT_CENTRES_CONTENT.AVAILABLE_NETWORK.description}</Text>
          </Box>
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="1.5rem">
            {availableNetwork.map((warehouse) => {
              return (
                <AvailableDisabledCentreCard
                  categories={categories}
                  isPostMVP={false}
                  warehouse={warehouse}
                />
              );
            })}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default FulfillmentCentres;
