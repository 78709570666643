import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  useMultiStyleConfig
} from '@chakra-ui/react';
import React, { useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import { EyeCloseIcon, EyeIcon } from '../Icons';
import { INPUT_STATUS } from '../Constants';
import * as extendStyles from './styles';

export const PasswordIcon = ({ showedPassword, setShowedPassword }) => {
  return showedPassword ? (
    <InputRightElement
      lineHeight={0}
      pos="relative"
      zIndex={1}
      children={<EyeIcon color="light.500" _hover={{ color: 'light.700', cursor: 'pointer' }} />}
      onClick={() => setShowedPassword(false)}
    />
  ) : (
    <InputRightElement
      lineHeight={0}
      pos="relative"
      zIndex={1}
      children={
        <EyeCloseIcon color="light.500" _hover={{ color: 'light.700', cursor: 'pointer' }} />
      }
      onClick={() => setShowedPassword(true)}
    />
  );
};

const StatusIcon = ({ status, onClick }) => {
  if (status === INPUT_STATUS.POSITIVE) {
    return <CheckIcon data-testid="positive-icon" color="positive.500" w={3} h={3} />;
  }
  if (status === INPUT_STATUS.ERROR) {
    return (
      <CloseIcon data-testid="error-icon" color="negative.500" w={3} h={3} onClick={onClick} />
    );
  }
  return null;
};

/**
 * @description This is old SlzInput.
 */

const SlzInput = React.forwardRef((props, ref) => {
  const {
    type,
    status,
    showIcon,
    onChangeValue,
    onChange,
    onClickError,
    disabled,
    readonly,
    inputGroupProps,
    sx = {},
    ...rest
  } = props;
  const styles = useMultiStyleConfig('Input', { size: props.size });
  const [showedPassword, setShowedPassword] = useState(props.showedPassword || false);
  const disabledStyles = styles.field._disabled;
  const { color, ...readonlyStyles } = { ...disabledStyles };
  const _disabledSx = readonly ? readonlyStyles : disabledStyles;

  const handleOnChange = (e) => {
    if (type === 'number' && e.target.min && parseFloat(e.target.value) < e.target.min) {
      e.target.value = Math.abs(e.target.value);
    }
    if (type === 'number' && e.target.max && parseFloat(e.target.value) > e.target.max) {
      e.target.value = parseInt(e.target.value / 10);
    }
    onChange && onChange(e);
    onChangeValue && onChangeValue(e.target.value);
  };

  return (
    <InputGroup
      id={uniqueId('SEL-Slz-Input')}
      w={'full'}
      size={rest.size}
      {...inputGroupProps}
      sx={{
        ...inputGroupProps?.sx,
        '& > input': {
          px: styles.field.px,
          ...inputGroupProps?.sx['& > input'],
          '::-ms-reveal': {
            display: 'none'
          }
        }
      }}>
      {props.leftElContent && (
        <InputLeftElement children={props.leftElContent} sx={extendStyles.inputElementSx} />
      )}
      {props.leftAddonContent && (
        <InputLeftAddon
          children={props.leftAddonContent}
          pos="relative"
          zIndex={1}
          px="2"
          borderRightColor="dark.300"
        />
      )}
      <Input
        id="slz-custom-input"
        ref={ref}
        flex={1}
        type={type === 'password' && showedPassword ? 'text' : type}
        onChange={handleOnChange}
        disabled={disabled || readonly}
        sx={extendStyles.inputSx(sx, styles, { _disabled: _disabledSx })}
        autoComplete="off"
        {...rest}
      />
      <Input
        data-testid="substitute-input"
        className="substitute"
        isInvalid={status === INPUT_STATUS.ERROR}
        data-valid={status === INPUT_STATUS.POSITIVE || undefined}
        disabled={disabled || readonly}
        borderRadius={`${styles.field.borderRadius} !important`}
        sx={extendStyles.substituteInputSx(sx, styles, {
          _disabled: {
            ..._disabledSx,
            ...sx?._disabled
          }
        })}
        {...rest}
        name=""
        value=""
        placeholder=""
        tabIndex={-1}
      />
      {props.rightAddonContent && (
        <InputRightAddon
          children={props.rightAddonContent}
          pos="relative"
          zIndex={1}
          borderLeftColor="dark.300"
        />
      )}
      {props.rightElContent && (
        <InputRightElement sx={extendStyles.inputElementSx} children={props.rightElContent} />
      )}
      {props.type === 'password' && status !== INPUT_STATUS.ERROR ? (
        <PasswordIcon showedPassword={showedPassword} setShowedPassword={setShowedPassword} />
      ) : (
        showIcon && (
          <InputRightElement
            sx={extendStyles.inputElementSx}
            children={<StatusIcon status={status} onClick={onClickError} />}
            mr={styles.field.px / 2}
          />
        )
      )}
    </InputGroup>
  );
});

export default SlzInput;

SlzInput.propTypes = {
  type: PropTypes.oneOf(['text', 'password', 'number']),
  status: PropTypes.oneOf(['positive', 'error']),
  showIcon: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rightElContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  leftElContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  leftAddonContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  rightAddonContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};
