import { React } from 'react';
import { Box, Icon } from '@chakra-ui/react';

const Pallet = (props) => (
    <Box maxW="full">
        <Icon
            xmlns="http://www.w3.org/2000/svg"
            w="1.012rem"
            h="1.125rem"
            viewBox="0 0 16.196 18"
            fill="gray"
            {...props}>
            <g id="Group_34560" data-name="Group 34560" clip-path="url(#clip-path)">
                <path id="Union_319" data-name="Union 319" d="M6372.317,20986.746h-3.763a3.374,3.374,0,0,1-6.556-1.125v-6.75a1.125,1.125,0,0,1,1.126-1.125h1.125v-3.371a1.125,1.125,0,0,1,1.126-1.125h3.372v-1.125a1.128,1.128,0,0,1,1.125-1.129h4.5a1.123,1.123,0,0,1,1,.625l3.377,6.746a.106.106,0,0,1,0,.012l.013.023c0,.012.007.016.011.027l.01.023c0,.012.008.02.012.031a.063.063,0,0,0,0,.008,1.123,1.123,0,0,1,.064.422V20980H6380v-7.875a1.126,1.126,0,1,1,2.251,0v10.125h2.246a1.125,1.125,0,1,1,0,2.25h-3.372a1.125,1.125,0,0,1-1.125-1.125v-1.125h-1.126v3.371a3.374,3.374,0,0,1-6.555,1.125Zm2.059-1.125a1.123,1.123,0,1,0,1.12-1.121A1.124,1.124,0,0,0,6374.375,20985.621Zm-9,1.125a1.123,1.123,0,1,0-1.126-1.125A1.129,1.129,0,0,0,6365.376,20986.746Zm6.939-2.246a3.379,3.379,0,0,1,4.306-2.059V20980H6364.25v2.441a3.379,3.379,0,0,1,4.306,2.059Zm3.609-6.754-2.249-4.5H6371v4.5Zm-7.177,0v-2.246H6366.5v2.246Z" transform="translate(-6361.999 -20970.996)" fill="gray" />
            </g>
        </Icon>
    </Box>
);
export default Pallet;