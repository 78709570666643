import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { CloseCircleIcon } from 'components/common/Icons';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';

const UploadError = ({ title, description, okText, isOpen, onOpen, onClose, onCancel, onOk }) => (
  <Flex pos="relative" w="full" h="100%">
    <SlzPopoverConfirm
      variant="returnSettings"
      title={title}
      description={description}
      okText={okText}
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onOk={onOk}>
      <Flex
        w="100%"
        h="100%"
        px="3px"
        justifyContent="center"
        backgroundColor="white"
        sx={{
          px: '3px',
          py: 0
        }}
        onClick={onOpen}>
        <VStack
          spacing="1px"
          backgroundColor="negative.300"
          w="95%"
          justifyContent="center"
          border="1px solid"
          borderColor="red.500">
          <Box bgColor="white" borderRadius="50%" p="0.01rem">
            <CloseCircleIcon w="28px" h="28px" fill="red.300" />
          </Box>
          <Text fontSize="0.625rem" color="dark.700" lineHeight="0.625rem">
            Error!
          </Text>
        </VStack>
      </Flex>
    </SlzPopoverConfirm>
  </Flex>
);

export default UploadError;
