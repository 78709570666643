import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import MainLayout from 'components/layout/MainLayout';
import PageNotFound from 'components/layout/PageNotFound';
import useAuth from 'hooks/useAuth';
import PrivateRoutes from './PrivateRoutes';
import ProtectedRoute from './ProtectedRoute';
import PublicRoutes, { PublicRoute } from './PublicRoutes';
import SelazarRoute from './SelazarRoute';

const NotFound = () => {
  const { isLogged } = useAuth();
  const isAuthenticated = isLogged();

  return isAuthenticated ? (
    <MainLayout>
      <PageNotFound />
    </MainLayout>
  ) : (
    <PageNotFound />
  );
};

const Routes = () => {
  const { isSelazar } = useAuth();
  const belongToSelazar = isSelazar();

  if (belongToSelazar) {
    return <SelazarRoute />;
  }

  return (
    <Router>
      <Switch>
        {PublicRoutes.map((props) => (
          <PublicRoute key={props.path} {...props} />
        ))}
        {PrivateRoutes.map((props) => (
          <ProtectedRoute key={props.path} {...props} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
