import CommonEndpoints from 'components/common/Endpoints';
import { buildQuery } from 'utils/helper';
import axiosClient from 'config/axios';

export async function getBundles(filters) {
  const url = new URL(CommonEndpoints.STOCK.GET.PRODUCT_BUNDLES);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function getBundleDetail(bundleId) {
  const url = `${CommonEndpoints.STOCK.GET.PRODUCT_BUNDLE}${bundleId}`;
  return await axiosClient.request(url);
}

export async function createBundle(payload) {
  const url = `${CommonEndpoints.STOCK.POST.PRODUCT_BUNDLE}`;
  return await axiosClient.post(url, {
    ...payload,
    ignore_whitespace: false
  });
}

export async function uploadBundleCsv(files) {
  const url = `${CommonEndpoints.BUNDLES.POST.UPLOAD}`;

  let formData = new FormData();
  files.forEach((f) => {
    formData.append('files', f.file, f.name);
  });

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await axiosClient.post(url, formData, config);
}

export async function updateBundle(payload) {
  try {
    const result = await axiosClient.put(CommonEndpoints.STOCK.PUT.PRODUCT_BUNDLE, payload);
    return result?.data;
  } catch (err) {
    return err;
  }
}

export async function getCompanyItem(query) {
  try {
    const url = new URL(CommonEndpoints.STOCK.GET.BY);
    return await axiosClient.request(buildQuery(url, query));
  } catch (err) {
    return err;
  }
}

export async function deleteProductBundle(id) {
  try {
    const result = await axiosClient.remove(`${CommonEndpoints.STOCK.DELETE.PRODUCT_BUNDLE}${id}`);
    return result?.data;
  } catch (err) {
    return err;
  }
}
