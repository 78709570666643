import { Fragment, useEffect, useMemo, useState } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, HStack } from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TAG_DEFAULT_COLOR } from 'components/common/Constants';
import { SlzButton } from 'components/common/SlzButton';
import { SlzChip } from 'components/common/SlzChip';
import { SlzCustomTag } from 'components/common/SlzCustomTag';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import ProductCardsForm from './ProductCardsForm';
import { NestedFieldContainer } from 'components/common/SlzForms/base/NestedField';
import { NestedInput } from 'components/common/SlzForms/base/NestedInput';
import { NestedTextarea } from 'components/common/SlzForms/base/NestedTextarea';
import BundleTracking from '../other/BundleTracking';
import { getAllItemCustomTags } from 'modules/products/services/customTags-api';

const BundleEditableFormFields = ({ isEditMode = false, setColorTag }) => {
  const {
    register,
    getValues,
    formState: { errors }
  } = useFormContext();

  const [customTags, setCustomTags] = useState([]);

  const obtainCustomTags = async () => {
    const dataCustomTags = await getAllItemCustomTags();
    setCustomTags(dataCustomTags);
  };

  useEffect(() => {
    obtainCustomTags();
  }, []);

  return (
    <Fragment>
      <HStack pt={'1.188rem'} spacing={3}>
        <Box flex={2}>
          <NestedInput
            label="Bundle SKU"
            field="skuCode"
            showTooltip={false}
            fieldProps={{ readonly: true }}
            labelProps={{ fontSize: '1rem', color: 'lightAndShadow.mainText' }}
          />
        </Box>

        <Box flex={1} maxWidth={'9.25rem'}>
          <FormControl>
            <SlzFormLabel color={'lightAndShadow.mainText'} fontSize="lg" fontWeight="bold">
              Price
            </SlzFormLabel>
            <HStack>
              <Box
                pos="relative"
                _after={{
                  content: '"GBP £"',
                  pos: 'absolute',
                  top: '0.375rem',
                  left: '0.375rem',
                  fontSize: '0.875rem',
                  color: 'dark.500'
                }}>
                <SlzInput
                  size="sm"
                  type="string"
                  readonly={!isEditMode}
                  textAlign="right"
                  pl="12"
                  autoComplete="off"
                  {...register('price')}
                />
              </Box>
            </HStack>
            <FormErrorMessage>{errors['price']?.message}</FormErrorMessage>
          </FormControl>
        </Box>

        <Box flex={2} maxWidth={'19.188rem'}>
          <NestedFieldContainer
            labelProps={{ fontSize: '1rem', color: 'lightAndShadow.mainText' }}
            label="Bundle Tag"
            field="bundleTag">
            {!isEditMode ? (
              <SlzChip
                w={'100%'}
                borderRadius={0.75}
                h={7.5}
                bg={getValues('bundleTag.colorCode') || TAG_DEFAULT_COLOR}>
                {getValues('bundleTag.text')}
              </SlzChip>
            ) : (
              <SlzCustomTag
                field="bundleTag"
                height="1.875rem"
                setColorTag={setColorTag}
                items={customTags}
                {...register('bundleTag')}
              />
            )}
          </NestedFieldContainer>
        </Box>
      </HStack>
      <HStack pt={'1.188rem'}>
        <NestedTextarea
          size="sm"
          label="Description"
          field="description"
          showTooltip={false}
          fieldProps={{
            readonly: !isEditMode,
            rows: 4,
            borderRadius: '0.375rem',
            zIndex: 0
          }}
          labelProps={{ fontSize: '1rem', color: 'lightAndShadow.mainText' }}
        />
      </HStack>
    </Fragment>
  );
};

export default function EditBundleForm({
  defaultValues,
  onCancel,
  onSubmit,
  onAddProducts,
  isEditMode,
  products,
  setColorTag,
  setProducts
}) {
  const methods = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues?.id])
  });

  const handleRemoveItemCard = (value) => {
    const remainItems = methods
      ?.getValues('productBundleCompanyItems')
      ?.filter((item) => item.id !== value.id);

    methods.setValue('productBundleCompanyItems', remainItems);
    defaultValues.productBundleCompanyItems = remainItems;
    setProducts(remainItems);
  };

  const handleOnAddProducts = () => {
    const formValues = methods.getValues();
    onAddProducts(formValues);
  };

  useEffect(() => {
    if (defaultValues?.bundleTag?.colorCode) {
      setColorTag(defaultValues?.bundleTag?.colorCode);
    }
  }, [defaultValues?.bundleTag?.colorCode]);

  useEffect(() => {
    if (defaultValues?.id) {
      methods?.reset(defaultValues);
      setProducts(defaultValues?.productBundleCompanyItems);
    }
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <BundleTracking bundleItems={methods?.watch('productBundleCompanyItems')} isEdit={true} />
        <BundleEditableFormFields isEditMode={isEditMode} setColorTag={setColorTag} />
        <ProductCardsForm
          isEditMode={isEditMode}
          products={products}
          onClick={handleOnAddProducts}
          onRemove={handleRemoveItemCard}
        />
        <Box mt={'9.933rem'}>
          {isEditMode && (
            <Flex justifyContent="space-between">
              <SlzButton
                onClick={onCancel}
                maxW={91}
                variant="outline"
                size="md"
                colorScheme="negative">
                Cancel
              </SlzButton>
              <SlzButton type="submit" maxW={15} borderRadius={2} width="full">
                Save
              </SlzButton>
            </Flex>
          )}
        </Box>
      </form>
    </FormProvider>
  );
}
