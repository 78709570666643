import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { chakra, createIcon, HStack, Input, useBoolean, useStyles } from '@chakra-ui/react';
import { useOutside } from 'hooks/useOutside';
import { uniqueId } from 'lodash';

import { SEL_PREFIX_ID } from '../Constants';
import { PAGINATION_NEXT_TITLE, PAGINATION_PREV_TITLE } from './Constants';
import { DEFAULT_PAGE_INDEX, DEFAULT_TOTAL_PAGES } from 'constants/table.js';
import { Dot } from './Icon';

const ENTER_KEY = 'Enter';

const disableStyle = {
  '> *': {
    cursor: 'not-allowed',
    opacity: 0.6
  },
  label: {
    cursor: 'not-allowed'
  },
  _hover: {
    bg: 'none'
  }
};

const DirectionArrowLeftIcon = createIcon({
  displayName: 'DirectionArrowLeftIcon',
  viewBox: '0 0 1024 1024',
  path: (
    <path
      fill="currentColor"
      d="M752.145 0c8.685 0 17.572 3.434 24.237 10.099 13.33 13.33 13.33 35.143 0 48.473L320.126 515.03l449.591 449.591c13.33 13.33 13.33 35.144 0 48.474-13.33 13.33-35.142 13.33-48.472 0L247.418 539.268c-13.33-13.33-13.33-35.144 0-48.474L727.91 10.1C734.575 3.435 743.46.002 752.146.002z"></path>
  )
});

const DirectionArrowRightIcon = createIcon({
  displayName: 'DirectionArrowRightIcon',
  viewBox: '0 0 1024 1024',
  path: (
    <path
      fill="currentColor"
      d="M271.653 1023.192c-8.685 0-17.573-3.432-24.238-10.097-13.33-13.33-13.33-35.144 0-48.474L703.67 508.163 254.08 58.573c-13.33-13.331-13.33-35.145 0-48.475 13.33-13.33 35.143-13.33 48.473 0L776.38 483.925c13.33 13.33 13.33 35.143 0 48.473l-480.492 480.694c-6.665 6.665-15.551 10.099-24.236 10.099z"></path>
  )
});

const Direction = (props) => {
  const {
    usingDots = false,
    showDirectionLabel = true,
    numDots,
    prevLabel,
    nextLabel,
    labelColor = '',
    labelFontSize = '',
    prevIcon,
    nextIcon,
    currentPage,
    onChange
  } = props;
  const totalPage = props.totalPage || DEFAULT_TOTAL_PAGES;

  const styles = useStyles();
  const { container, previous, next, middle } = styles;
  const dotsArray = Array.from(Array(numDots || 0).keys());
  const [isReachedPrev, setIsReachedPrev] = useState(false);
  const [isReachedNext, setIsReachedNext] = useState(false);
  const [isInputPage, setIsInputPage] = useBoolean();
  const [editingPageNumber, setEditingPageNumber] = useState(currentPage || DEFAULT_PAGE_INDEX);

  const wrapperRef = useRef(null);
  useOutside(wrapperRef, setIsInputPage.off);

  const pageReducer = (prev, next) => {
    const isReached = (next > 0 && next < prev) || (next > prev && next <= totalPage);
    setIsReachedPrev(next <= DEFAULT_PAGE_INDEX);
    setIsReachedNext(next >= totalPage);

    return isReached ? next : prev;
  };

  const [page, setPage] = useReducer(pageReducer, currentPage || DEFAULT_PAGE_INDEX);

  const handlePrev = useCallback(() => {
    !isReachedPrev && setPage(parseInt(page) - 1);
  }, [page, isReachedPrev]);

  const handleNext = useCallback(() => {
    !isReachedNext && setPage(parseInt(page) + 1);
  }, [page, isReachedNext]);

  const getDirectionStyle = (directionStyle) => {
    let styles = {};

    if (!showDirectionLabel) {
      styles = {
        ...styles,
        ...{
          w: 7.5,
          h: 7.5,
          justifyContent: 'center'
        }
      };
    }

    return {
      ...directionStyle,
      ...styles
    };
  };

  const getCurrentDotPos = useMemo(() => {
    const isFirstStep = page >= 1 && page <= numDots;
    const isLastStep = page <= totalPage && page > totalPage - numDots;

    const inMiddleSteps = !isFirstStep && !isLastStep;

    if (inMiddleSteps) {
      const nextStep = page;
      const endStep = nextStep + numDots - 1;
      const pivot = Math.ceil((endStep - nextStep) / 2);

      return pivot;
    }

    if (isLastStep) {
      return (page === totalPage ? numDots : page % numDots) - 1;
    }

    return page - 1;
  }, [page, totalPage]);

  const handlePageNumberChange = (event) => setEditingPageNumber(event.target.value);
  const handleOnKeyPress = (event) => {
    const { key, target } = event;

    if (key !== ENTER_KEY) {
      return;
    }

    let enteredNumber = target.value;
    if (isNaN(enteredNumber)) {
      setEditingPageNumber(page);
      return;
    }

    enteredNumber = Math.max(parseInt(enteredNumber), DEFAULT_PAGE_INDEX);
    enteredNumber = Math.min(enteredNumber, totalPage);
    setIsReachedPrev(enteredNumber <= DEFAULT_PAGE_INDEX);
    setIsReachedNext(enteredNumber >= totalPage);
    setPage(enteredNumber);
    setEditingPageNumber(enteredNumber);
    setIsInputPage.off();
  };

  const renderDots = useMemo(
    () => (
      <HStack alignItems="center">
        {dotsArray.map((idx) => (
          <Dot
            key={idx}
            styles={{
              ...middle?.dot,
              ...(idx === getCurrentDotPos ? middle?.dot?.active : {})
            }}
          />
        ))}
      </HStack>
    ),
    [dotsArray, numDots]
  );

  const renderPages = (
    <chakra.div onClick={setIsInputPage.on} cursor="pointer">
      {isInputPage ? (
        <Input
          p={1}
          w={8}
          h={4}
          value={editingPageNumber}
          onChange={handlePageNumberChange}
          onKeyPress={handleOnKeyPress}
        />
      ) : (
        page
      )}{' '}
      {` / ${totalPage}`}
    </chakra.div>
  );

  useEffect(() => {
    page && onChange(page);
    page && setEditingPageNumber(page);

    const onlyOnePage = totalPage === DEFAULT_TOTAL_PAGES;
    setIsReachedNext(onlyOnePage || parseInt(page) === totalPage);
    setIsReachedPrev(onlyOnePage || parseInt(page) === DEFAULT_PAGE_INDEX);
  }, [page]);

  return (
    <HStack
      ref={wrapperRef}
      alignItems="center"
      __css={container}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Direction`)}
      data-testid="slz-direction">
      <HStack
        className="slz-direction-prev-btn"
        data-testid="slz-direction-prev-btn"
        as="button"
        type="button"
        alignItems="center"
        __css={getDirectionStyle({
          ...previous,
          ...(isReachedPrev || page === DEFAULT_PAGE_INDEX ? disableStyle : {})
        })}
        gap={previous.spacing}
        onClick={handlePrev}>
        {prevIcon || <DirectionArrowLeftIcon fontWeight="bold" />}{' '}
        {showDirectionLabel && (
          <chakra.label
            whiteSpace="nowrap"
            mb={0}
            sx={{ color: labelColor || '', fontSize: labelFontSize }}
            data-testid="slz-direction-prev-label">
            {prevLabel || PAGINATION_PREV_TITLE}
          </chakra.label>
        )}
      </HStack>
      <HStack alignItems="center" __css={middle}>
        <chakra.div mb={0} mx={middle.spacing}>
          {usingDots ? renderDots : renderPages}
        </chakra.div>
      </HStack>
      <HStack
        className="slz-direction-next-btn"
        data-testid="slz-direction-next-btn"
        as="button"
        type="button"
        alignItems="center"
        __css={getDirectionStyle({
          ...next,
          ...(isReachedNext || page === totalPage ? disableStyle : {})
        })}
        gap={next.spacing}
        onClick={handleNext}>
        {' '}
        {showDirectionLabel && (
          <chakra.label
            whiteSpace="nowrap"
            sx={{ color: labelColor || '', fontSize: labelFontSize }}
            mb={0}
            data-testid="slz-direction-next-label">
            {nextLabel || PAGINATION_NEXT_TITLE}
          </chakra.label>
        )}
        {nextIcon || <DirectionArrowRightIcon fontWeight="bold" />}
      </HStack>
    </HStack>
  );
};

export default Direction;
