import { Icon } from '@chakra-ui/react';
import React from 'react';

const BoxSplitIcon = ({ color = '#303134', ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      color={color}
      {...props}>
      <path
        id="Union_335"
        data-name="Union 335"
        d="M7.778,20a3.323,3.323,0,0,1-2.322-.944l-.035-.033-.034-.036a3.321,3.321,0,0,1-.943-2.32V15.556H3.333A3.337,3.337,0,0,1,0,12.222V3.333A3.337,3.337,0,0,1,3.333,0h8.889a3.337,3.337,0,0,1,3.334,3.333V4.444h1.111A3.337,3.337,0,0,1,20,7.778v8.889A3.337,3.337,0,0,1,16.667,20Zm8.889-2.222a1.113,1.113,0,0,0,1.111-1.111V8.237L8.237,17.778Zm-10-1.572,9.539-9.539H7.778A1.112,1.112,0,0,0,6.667,7.778v8.428ZM2.222,3.333v8.889a1.112,1.112,0,0,0,1.111,1.111H4.444V7.778A3.338,3.338,0,0,1,7.778,4.444h5.555V3.333a1.112,1.112,0,0,0-1.111-1.111H3.333A1.113,1.113,0,0,0,2.222,3.333Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default BoxSplitIcon;
