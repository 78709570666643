import React from 'react';
import { Icon, useBoolean, useMultiStyleConfig, useTheme } from '@chakra-ui/react';
import { VscChromeClose } from 'react-icons/vsc';
import { HiCheck } from 'react-icons/hi';
import Input from './Input';
import { EyeCloseIcon, EyeIcon } from '../Icons';

export default React.forwardRef(function PasswordInput(
  { size, isInvalid, isValid, onClear, onChange, ...props },
  ref
) {
  const theme = useTheme();
  const inputStyles = useMultiStyleConfig('Input', { size });
  const inputHeight = theme.sizes[inputStyles.field.h];
  const iconSize = theme.fontSizes[inputStyles.field.fontSize];
  const [showedPassword, setShowedPassword] = useBoolean();

  return (
    <Input.Group size={size}>
      <Input
        ref={ref}
        type={showedPassword ? 'text' : 'password'}
        isInvalid={isInvalid}
        isValid={isValid}
        onChange={onChange}
        {...props}
      />

      {isInvalid && (
        <Input.RightElement>
          <Icon as={VscChromeClose} color="negative.500" onClick={onClear} />
        </Input.RightElement>
      )}
      {isValid && (
        <Input.RightElement>
          <Icon as={HiCheck} color="positive.500" />
        </Input.RightElement>
      )}

      <Input.RightElement>
        <Icon
          as={showedPassword ? EyeIcon : EyeCloseIcon}
          sx={{
            color: 'light.500',
            _hover: { color: 'light.700', cursor: 'pointer' }
          }}
          fontSize={`calc(${iconSize} + 0.5rem)`}
          onClick={setShowedPassword.toggle}
        />
      </Input.RightElement>
    </Input.Group>
  );
});
