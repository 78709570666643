import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function IntegrationIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.897"
      height="29.898"
      viewBox="0 0 29.897 29.898"
      fill="currentColor"
      {...props}>
      <g
        id="Integrations_components_icon"
        data-name="Integrations components icon"
        transform="translate(1.414 1.414)">
        <path
          id="Path_4733"
          data-name="Path 4733"
          d="M1927.072,86.887l7.519,7.519-2.256,2.256a5.318,5.318,0,0,1-7.643-7.4l.123-.122Z"
          transform="translate(-1921.056 -73.352)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4734"
          data-name="Path 4734"
          d="M1938.292,90.7l-7.52-7.519,2.256-2.256a5.318,5.318,0,0,1,7.641,7.4l-.122.122Z"
          transform="translate(-1917.238 -77.17)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4735"
          data-name="Path 4735"
          d="M1921.773,97.147l3.76-3.76"
          transform="translate(-1921.773 -70.077)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4736"
          data-name="Path 4736"
          d="M1937.273,81.647l3.759-3.76"
          transform="translate(-1913.963 -77.887)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4737"
          data-name="Path 4737"
          d="M1929.781,85.887l-3.008,3.008"
          transform="translate(-1919.254 -73.856)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_4738"
          data-name="Path 4738"
          d="M1932.781,88.887l-3.008,3.008"
          transform="translate(-1917.742 -72.345)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
}
