import { InfoOutlineIcon } from '@chakra-ui/icons';
import SlzToolTip from 'components/common/SlzToolTip';

export default function Tooltip({ content, ...rest }) {
  return (
    <SlzToolTip content={content} placement="top-start" variant="blue" {...rest}>
      <InfoOutlineIcon boxSize="3" color="main.500" />
    </SlzToolTip>
  );
}
