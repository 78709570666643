import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { getColor } from '@chakra-ui/theme-tools';
import { runIfFn } from '../utils/run-if-fn';
import { default as checkboxTheme } from './slz-checkbox';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle((props) => {
  const { theme } = props;
  const controlStyle = runIfFn(checkboxTheme.baseStyle, props)?.control;
  return {
    ...controlStyle,
    borderRadius: 'full',
    _checked: {
      ...controlStyle?.['_checked'],
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '0.5rem',
        h: '0.5rem',
        borderWidth: '0.125rem',
        borderColor: 'currentColor',
        borderRadius: '50%',
        bg: 'currentColor'
      }
    },
    _disabled: {
      borderColor: getColor(theme, `light.700`),
      _hover: {
        borderColor: getColor(theme, `light.700`)
      }
    },
    _readOnly: {
      borderColor: getColor(theme, `dark.300`),
      bg: getColor(theme, `dark.300`),
      opacity: 1,
      _checked: {
        borderColor: getColor(theme, `main.300`)
      },
      _hover: {
        borderColor: getColor(theme, `dark.300`),
        _checked: {
          borderColor: getColor(theme, `main.300`)
        }
      },
      '&.disable-no-selected': {
        bg: `none`,
        _checked: {
          ...controlStyle?.['_checked'],
          _before: {
            content: `""`,
            display: 'inline-block',
            pos: 'relative',
            w: '0.5rem',
            h: '0.5rem',
            borderRadius: '50%',
            bg: 'currentColor'
          }
        }
      }
    }
  };
});

const baseStyleIcon = defineStyle((props) => {
  return {
    color: `white`,
    bg: `main.500`,
    borderWidth: '0.094rem',
    borderStyle: 'solid',
    borderRadius: '50%',
    p: '1px',
    pointerEvents: 'none'
  };
});

const baseStyle = definePartsStyle((props) => ({
  label: checkboxTheme.baseStyle?.(props).label,
  container: { ...checkboxTheme.baseStyle?.(props).container },
  control: { ...baseStyleControl(props) },
  icon: baseStyleIcon(props)
}));

const sizes = {
  md: definePartsStyle({
    control: { w: '4', h: '4' },
    label: { fontSize: 'md' }
  }),
  lg: definePartsStyle({
    control: { w: '4', h: '4' },
    label: { fontSize: 'lg' }
  }),
  sm: definePartsStyle({
    control: { width: '3', height: '3' },
    label: { fontSize: 'sm' }
  })
};

export default defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
    colorScheme: 'main'
  }
});
