import React from 'react';
import { Icon } from '@chakra-ui/react';

const ShopifyShortIcon = (props) => {
  return (
    <Icon
      id="Group_3720"
      data-name="Group 3720"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 42.367 47.999"
      {...props}>
      <g id="Group_3720" data-name="Group 3720" transform="translate(2340.841 18125.938)">
        <path
          id="Path_8703"
          data-name="Path 8703"
          d="M37.108,9.291a.467.467,0,0,0-.421-.394c-.175-.014-3.592-.066-3.592-.066s-2.858-2.773-3.14-3.05a1.191,1.191,0,0,0-1.048-.133l-1.436.445A10.012,10.012,0,0,0,26.783,4.4a4.843,4.843,0,0,0-4.308-2.972h-.006c-.125,0-.249.012-.374.024-.052-.064-.106-.125-.163-.188A3.771,3.771,0,0,0,18.94.055c-2.33.06-4.644,1.736-6.523,4.728a18.762,18.762,0,0,0-2.611,6.772l-4.58,1.418c-1.347.423-1.39.465-1.567,1.735C3.526,15.67,0,42.942,0,42.942l29.557,5.111,12.81-3.184S37.145,9.534,37.108,9.291ZM25.989,6.548,23.7,7.258a12.688,12.688,0,0,0-.707-4.232c1.765.332,2.634,2.327,3,3.519ZM22.15,7.736,17.215,9.261a11.678,11.678,0,0,1,2.493-4.835,4.966,4.966,0,0,1,1.675-1.22,11.125,11.125,0,0,1,.767,4.527ZM18.981,1.6a2.38,2.38,0,0,1,1.4.366A6.747,6.747,0,0,0,18.573,3.37a13.543,13.543,0,0,0-3.06,6.418l-4.045,1.255C12.26,7.306,15.392,1.7,18.981,1.6Z"
          transform="translate(-2340.841 -18125.99)"
          fill="#95bf47"
        />
        <path
          id="Path_8704"
          data-name="Path 8704"
          d="M244.259,47.829c-.174-.014-3.592-.066-3.592-.066s-2.858-2.773-3.14-3.05a.7.7,0,0,0-.4-.183V86.985L249.939,83.8s-5.222-35.335-5.259-35.578A.467.467,0,0,0,244.259,47.829Z"
          transform="translate(-2548.413 -18164.924)"
          fill="#5e8e3e"
        />
        <path
          id="Path_8705"
          data-name="Path 8705"
          d="M77.771,120.293l-1.488,5.566a8.43,8.43,0,0,0-3.627-.632c-2.883.182-2.916,2-2.883,2.458.157,2.493,6.706,3.033,7.074,8.864.29,4.587-2.433,7.728-6.357,7.977a9.524,9.524,0,0,1-7.3-2.48l1-4.238s2.606,1.957,4.694,1.822a1.845,1.845,0,0,0,1.8-1.979c-.206-3.241-5.538-3.056-5.875-8.391-.283-4.487,2.665-9.039,9.172-9.451A8.028,8.028,0,0,1,77.771,120.293Z"
          transform="translate(-2396.154 -18230.795)"
          fill="#fff"
        />
      </g>
    </Icon>
  );
};

export default ShopifyShortIcon;
