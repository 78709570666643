import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ConsignmentLabelling from '../ConsignmentLabelling';

const SingleConsignmentLabelling = ({ warehousesData, currentType, ...rest }) => {
  const { watch } = useFormContext();

  const [isGTINEnabled, setIsGTINEnabled] = useState(false);

  useEffect(() => {
    setIsGTINEnabled(watch('gtin'));
  }, [watch('gtin')]);

  return (
    <ConsignmentLabelling
      warehousesData={warehousesData}
      currentType={currentType}
      isGTINEnabled={isGTINEnabled}
      {...rest}
    />
  );
};

export default SingleConsignmentLabelling;
