import { useTheme } from '@chakra-ui/react';
import React from 'react';
import GoogleReCAPTCHA from 'react-google-recaptcha';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;

const style = {
  border: '1px solid',
  overflow: 'hidden',
  width: 303,
  height: 76,
  borderRadius: 3
};

export default function ReCAPTCHA({ isInvalid, ...props }) {
  const { colors } = useTheme();

  return (
    <GoogleReCAPTCHA
      style={{
        ...style,
        borderColor: isInvalid ? colors.negative['500'] : colors.light['500']
      }}
      sitekey={captchaKey}
      {...props}
    />
  );
}
