import { DATE_FORMAT_DDMMYYYY_HH_MM_SS } from 'constants/date';
import dayjs from 'dayjs';
import { random, sample, get } from 'lodash';
import {
  ORDER_PROCESSING_STATUS,
  ORDER_PROCESSING_STATUS_MAPPING
} from 'modules/b2c-orders/constants';
import { COURIER_MAP, ORDER_STATUS_COLOR } from 'constants/common';
import { setUntrackedService } from 'modules/b2c-orders/mappers/common';
import { transformAccordionItems } from 'modules/b2c-orders/mappers/order-list-accordion-mapper';

const getOrderShipments = (data, orderedCompanyItems, orderedCompanyItemsPayload) => {
  const { items, warehouseName } = data;
  const shipments = items?.map((shipment, i) => {
    const orderedCompanyItem = orderedCompanyItems?.find(
      ({ companyItem }) => companyItem?.itemID === items[i]?.id
    );

    const orderedCompanyItemPayload = orderedCompanyItemsPayload?.find(
      ({ CompanyItem }) => CompanyItem?.ItemID === items[i]?.id
    );

    return {
      quantity: shipment?.quantity,
      ean: shipment?.ean,
      name: shipment?.name,
      skuCode: shipment?.skuCode,
      image: get(orderedCompanyItem, `companyItem.item.itemImages[0].link`, null),
      suid: shipment?.selazarUniqueID,
      weight: get(orderedCompanyItem, `companyItem.item.weight`, 0),
      wareHouse: warehouseName,
      price: `£${get(orderedCompanyItemPayload, `CompanyItem.CurrentPrice.Price`, 0)}`
    };
  });

  return shipments;
};

const getOrderBreakdown = (data) => {
  if(data?.shipments != null && data?.shipments?.length != 0) { 
    const order = data?.shipments?.map((shipment) => {
      const orderedCompanyItems = data?.orderContents?.orderedCompanyItems;
      const orderedCompanyItemsPayload = JSON.parse(
        data?.orderContentsPayload || ''
      )?.OrderedCompanyItems;
      return {
        id: data.id,
        status: ORDER_PROCESSING_STATUS_MAPPING[shipment?.status] || sample(ORDER_PROCESSING_STATUS),
        courier: COURIER_MAP[shipment?.providerName],
        items: getOrderShipments(shipment, orderedCompanyItems, orderedCompanyItemsPayload),
        tracking: setUntrackedService([shipment?.parcelReference])
      };
    });
      
    return order;
  }
  else {
    if(data?.orderContents != null && data?.orderContents?.length !=0) {
      const order = [data?.orderContents].map((orderedCompanyItems) => {
        return {
          id: data.id,
          status: ORDER_PROCESSING_STATUS_MAPPING[data?.status] || sample(ORDER_PROCESSING_STATUS),
          items: transformAccordionItems(orderedCompanyItems)
        };
      });

      return order;
    }
  }
};

const getOrderNumbers = (resp, data) => {
  const orderedCompanyItems = resp?.orderContents?.orderedCompanyItems;
  if(resp?.orderContentsPayload){
    const orderedCompanyItemsPayload = JSON.parse(
    resp?.orderContentsPayload || ''
    )?.OrderedCompanyItems;

    const order = data?.map((partialOrderItem, index) => {
      return {
        id: `${resp.customerReference}_part${index + 1}`,
        status:
          ORDER_PROCESSING_STATUS_MAPPING[partialOrderItem?.status] ||
          sample(ORDER_PROCESSING_STATUS),
        courier: COURIER_MAP[partialOrderItem?.providerName] || null,
        tracking: setUntrackedService([partialOrderItem?.parcelReference]),
        items: getOrderShipments(partialOrderItem, orderedCompanyItems, orderedCompanyItemsPayload)
      };
    });
    
    return order;
  }
};

export const transformOrderDetail = (returnData, colorStatus) => {
  const resp = returnData?.data.order ? returnData.data.order : returnData.data;

  const order = {
    id: null,
    createdDate: null,
    source: {},
    status: {},
    subStatus: {},
    totalCost: 0,
    customer: {},
    costBreakdown: {},
    isShipment: false,
    orderBreakdown: [],
    isPartialOrder: false,
    orderNumbers: []
  };

  order.id = resp?.id;
  order.createdDate = dayjs(resp?.createdDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS);
  order.source = {
    type: resp?.source,
    name: resp?.source
  };

  order.status = {
    key: resp?.status?.name ?? 0,
    name: resp?.status?.name ?? 'N/A',
    color: ORDER_STATUS_COLOR[colorStatus] ?? '#579BFC' // mock data
  };

  order.subStatus = {
    key: resp?.status?.name ?? 0,
    name: resp?.status?.name ?? 'N/A',
    color: ORDER_STATUS_COLOR[colorStatus] ?? '#579BFC' // mock data
  };
  const { total, ...orderCostBreakdownRest } = resp?.orderCostBreakdown ?? {};
  order.totalCost = total;
  order.customer = {
    reference: resp?.customerReference,
    email: resp?.customerEmailAddress,
    phone: resp?.customerPhoneNumber,
    personName: resp?.customerOrganisationPersonName,
    departmentName: resp?.customerOrganisationDepartmentName,
    addressLine1: resp?.customerAddressLine1,
    addressLine2: resp?.customerAddressLine2,
    addressTown: resp?.customerAddressTown,
    postcode: resp?.customerAddressPostcode,
    country: resp?.customerAddressCounty,
    countryCode: resp?.customerCountryCode
  };
  order.costBreakdown = orderCostBreakdownRest ?? {};
  order.isShipment = (resp?.shipments?.length == 0 ? true : false);
  order.orderBreakdown = getOrderBreakdown(resp) ?? [];

  order.isPartialOrder =
    !!(resp.cancelledPartialOrderItems?.length ?? []) ||
    !!(resp.pendingPartialOrderItems?.length ?? []) ||
    !!(resp.failedPartialOrderItems?.length ?? []);
  order.orderNumbers =
    getOrderNumbers(
      resp,
      [
        (resp?.cancelledPartialOrderItems ?? [],
        resp?.pendingPartialOrderItems ?? [],
        resp?.failedPartialOrderItems ?? [])
      ].flat()
    ) ?? [];
  return order;
};
