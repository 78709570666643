import { getCountries } from 'utils';

export const transformAddressBook = ({ items = [], pageCount = 0 }) => {
  const addresses = items.map((item) => {
    return {
      ...item,
      location: `${item.addressLine1}, ${item.town}, ${item.postCode}, ${item.county}, ${item.country}`
    };
  });

  return {
    addresses,
    totalPage: pageCount
  };
};

export const transformRecipientPayload = (data, companyID) => {
  const {
    addressLine1,
    addressLine2,
    companyName,
    phone,
    postCode,
    email,
    town,
    name,
    country,
    county,
    id
  } = data;

  return {
    addressLine1,
    addressLine2,
    companyID,
    companyName,
    country: country.value,
    county,
    email,
    name,
    phone,
    postCode,
    town,
    id
  };
};

export const transformRecipientToFormValues = (data) => {
  if (!data) {
    return {
      addressLine1: '',
      addressLine2: '',
      companyName: '',
      country: null,
      county: '',
      email: '',
      name: '',
      phone: '',
      postCode: '',
      town: ''
    };
  }

  const countries = getCountries();

  const {
    id,
    addressLine1,
    addressLine2,
    companyName,
    phone,
    postCode,
    email,
    town,
    name,
    country,
    county
  } = data;

  return {
    id,
    addressLine1,
    addressLine2,
    companyName,
    country: countries.find((countryItem) => countryItem.value === country),
    county,
    email,
    name,
    phone,
    postCode,
    town
  };
};
