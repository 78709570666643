import { useQuery } from 'react-query';
import { getWarehouses } from '../services/billing-api';

export const useWarehouseQuery = ({ enabled }) => {
  return useQuery({
    queryKey: [`warehouse`],
    queryFn: () => getWarehouses(),
    cacheTime: 60 * 1000,
    enabled: enabled
  });
};
