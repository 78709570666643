import React from 'react';
import { Icon } from '@chakra-ui/react';

const UpsLogoCompany = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20.168"
      height="24"
      viewBox="0 0 20.168 24"
      {...props}>
      <svg>
        <g id="United_Parcel_Service_logo_2014" transform="translate(-47.822 728.214)">
          <g id="Group_33865" data-name="Group 33865" transform="translate(47.822 -728.214)">
            <path
              id="Path_9968"
              data-name="Path 9968"
              d="M48.7-717.576l.087,9.905,2.225,2.749,6.677,3.535,8.2-4.015,1.7-4.058-.175-13.135-6.2-.174-6.458,1.047-5.891,3.4Z"
              transform="translate(-48.179 724.735)"
              fill="#301506"
              fill-rule="evenodd"
            />
            <path
              id="Path_9969"
              data-name="Path 9969"
              d="M10.083,0A21.36,21.36,0,0,0,0,2.237v11.87a7.524,7.524,0,0,0,2.714,6.041c1.648,1.352,6.745,3.58,7.37,3.85.592-.258,5.75-2.52,7.372-3.85a7.525,7.525,0,0,0,2.713-6.041V2.237A21.362,21.362,0,0,0,10.083,0Zm5.758,2.3c1.16.015,2.3.087,3.4.189v11.62a6.584,6.584,0,0,1-2.374,5.325c-1.4,1.15-5.608,3.037-6.784,3.555A44.226,44.226,0,0,1,3.3,19.433,6.609,6.609,0,0,1,.926,14.108V7.318c4.466-4.1,9.89-5.085,14.915-5.02Zm-5.5,5.411a4.238,4.238,0,0,0-2.365.624V19.766H9.736V16.073a2.77,2.77,0,0,0,.789.1c1.947,0,3.066-1.755,3.066-4.317s-1.152-4.146-3.245-4.146Zm6,0A2.329,2.329,0,0,0,13.989,10c0,.933.262,1.631,1.708,2.48.772.453,1.083.752,1.1,1.3a.934.934,0,0,1-1.052.979,2.8,2.8,0,0,1-1.677-.714v1.615a3.841,3.841,0,0,0,1.928.544,2.372,2.372,0,0,0,2.541-2.347c.033-1.023-.25-1.8-1.716-2.658-.654-.384-1.171-.636-1.154-1.275.017-.623.535-.842,1.032-.838a2.35,2.35,0,0,1,1.572.72V8.279a3,3,0,0,0-1.925-.57Zm-14.52.178V13.4c0,1.859.879,2.8,2.615,2.8a4.662,4.662,0,0,0,2.644-.7V7.887H5.33V14.5a1.473,1.473,0,0,1-.83.214c-.8,0-.924-.737-.924-1.235V7.887H1.822Zm8.536,1.235c1.018,0,1.44.813,1.44,2.778,0,1.918-.483,2.844-1.492,2.844a1.655,1.655,0,0,1-.57-.106V9.249a1.519,1.519,0,0,1,.622-.127Z"
              transform="translate(0 0)"
              fill="#fab80a"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default UpsLogoCompany;
