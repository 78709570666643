import { useCallback, useState } from 'react';
import { Box, HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router';

import useAuth from 'hooks/useAuth';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { SlzButton } from 'components/common/SlzButton';
import { SlzAutocompleteInput } from 'components/common/SlzInput';
import { ADDRESS_BOOK_DISPLAY_FIELDS_INPUT_SEARCH } from 'modules/address-book/constants';
import { getAddressBook } from 'modules/address-book/services/address-book-api';
import { transformAddressBook } from 'modules/address-book/mappers/address-book-list-mapper';
import { getUsers } from 'modules/users/services/users-api';
import {
  USERS_DISPLAY_FIELDS_INPUT_SEARCH,
  USER_MANAGEMENT_KEY_PARAM
} from 'modules/users/constants';

const UserFilter = (props) => {
  const { onSearchingNavigate, setUsers, onReset, setSearchName } = props;
  const history = useHistory();
  const routerQuery = useRouterQuery();
  const [searchParams, setSearchParams] = useState({ pageIndex: DEFAULT_PAGE_INDEX });

  const { getUser } = useAuth();
  const user = getUser();

  const handleAutocompleteSearch = (searchTerm) => {
    if (!searchTerm) {
      onReset();
    }

    setSearchParams({
      pageIndex: DEFAULT_PAGE_INDEX,
      name: searchTerm
    });
  };

  const handleOpenAddUserModal = () => {
    routerQuery.set(USER_MANAGEMENT_KEY_PARAM.IS_CREATE_USER_MANAGEMENT, true);
    history.push({
      search: routerQuery.toString()
    });
  };

  const transformFn = useCallback((data) => data.users, []);

  return (
    <Box>
      <HStack mb={6} gap={6}>
        <SlzAutocompleteInput
          width="55%"
          variant="accent"
          showedFields={USERS_DISPLAY_FIELDS_INPUT_SEARCH}
          placeholder="Search"
          onTypingSearch={handleAutocompleteSearch}
          transformFn={transformFn}
          onNavigate={(user) => {
            setUsers((prev) => ({ ...prev, users: [user] }));
          }}
          onSearch={setSearchName}
          fetchFn={getUsers}
          searchParams={searchParams}
        />

        <SlzButton size="lg" onClick={handleOpenAddUserModal}>
          Add user
        </SlzButton>
      </HStack>

      <HStack gap="25px" w="full" mb={6}>
        <Tabs variant="line" size={['sm', 'md', 'lg']} index={0}>
          <TabList>
            <Tab w={36} key="All">
              All
            </Tab>
          </TabList>
        </Tabs>
      </HStack>
    </Box>
  );
};

export default UserFilter;
