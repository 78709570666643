import { useQuery } from 'react-query';
import { getServiceTimes } from '../services/integration-api';

export const useServiceTimesQuery = (enabled) => {
  return useQuery({
    queryKey: [`service-times`],
    queryFn: () => getServiceTimes(),
    enabled: enabled
  });
};
