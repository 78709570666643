import { Flex, Text } from '@chakra-ui/react';
import { SlzChip } from 'components/common/SlzChip';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import { getFilterColor, getGradingColor, isUnprocessedReturn } from 'modules/returns/utils';
import ImageCell from 'components/common/SlzTable/templates/ImageCell';

export const ACCORDION_COLUMNS = [
  {
    field: 'productImage',
    type: COL_CUSTOM,
    width: 48,
    render: ImageCell
  },
  {
    field: 'itemName',
    width: 200,
    cellHeader: (row) => (
      <Text fontSize="0.875rem" lineHeight="1.375rem" ml={5}>
        {row.itemName || ''}
      </Text>
    ),
    cellValue: (row) => (
      <Text fontSize="0.625rem" lineHeight="0.75rem" ml="1.4375rem">
        {`SKU ${row.skucode || ''}`}
      </Text>
    )
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    width: 200,
    cellValue: (row) => row['ean'] || row['suid'] || 'N/A'
  },
  {
    header: 'Warehouse',
    field: 'warehouse',
    width: 200,
    cellValue: (row) => row['warehouse'] || 'N/A'
  },
  {
    field: 'warehouseGrading',
    cellHeader: (row) =>
      isUnprocessedReturn(row.returnData?.status?.key) ? null : 'Warehouse grading',

    cellValue: (row) => {
      if (isUnprocessedReturn(row.returnData?.status?.key)) {
        return null;
      }

      return (
        <SlzChip
          rounded="full"
          size="sm"
          bg={`${getGradingColor(row.grade)} !important`}
          fontSize="0.75rem"
          color="lightAndShadow.reverseText">
          {`${row.grade} - ${row.action}`}
        </SlzChip>
      );
    }
  }
];

export const RETURN_COLUMNS = [
  {
    name: 'Order ID',
    field: 'orderID',
    isSorted: true,
    isClickable: true,
    render: ({ row, onClick }) => {
      return (
        <Flex direction="column" onClick={onClick}>
          <Text noOfLines={1} fontSize="1rem" fontWeight="medium" color="main.500">
            {row?.orderID}
          </Text>
        </Flex>
      );
    }
  },
  {
    name: 'Customer',
    field: 'customerName',
    isSorted: true
  },
  {
    name: 'Return Service',
    field: 'returnService',
    isSorted: true,
    render: ({ row, onClick }) => {
      return (
        <SlzChip
          color="white"
          bg={getFilterColor(row.returnService.key)}
          size="md"
          borderRadius="0.375rem">
          {row.returnService.value}
        </SlzChip>
      );
    }
  },
  {
    name: 'Status',
    field: 'status',
    isSorted: true,
    isEmphasis: true
  },
  {
    name: 'Date processed',
    field: 'formattedProcessedDate',
    isSorted: true,
    type: 'date',
    render: ({ row, onClick }) => {
      return (
        <Flex direction="column">
          <Text noOfLines={1} fontSize="1rem" fontWeight="medium">
            {row?.formattedProcessedDate}
          </Text>
        </Flex>
      );
    }
  }
];
