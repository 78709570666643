import { Flex, Text } from '@chakra-ui/react';

import { quantityOptions } from 'modules/products/_mock';
import { SlzDropdown } from 'components/common/SlzDropdown';

const ProductSelect = ({ row, onChange }) => {
  const handleClick = (item) => {
    onChange && onChange(row, item?.value);
  };

  return (
    <Flex
      data-testid={`btn-hamburger-icon-${row?.id}`}
      justifyContent="center"
      alignItems="center"
      pos="relative">
      <SlzDropdown.Creatable
        value={{ value: row.quantity, label: row.quantity }}
        options={quantityOptions}
        onChange={(value) => handleClick(value)}
        menuPosition="fixed"
        formatCreateLabel={(value) => <Text fontSize="0.875rem">{value}</Text>}
        maxMenuHeight={443}
      />
    </Flex>
  );
};

export default ProductSelect;
