import { useCallback } from 'react';
import { GridItem } from '@chakra-ui/react';
import { SlzGridModal } from 'components/common/SlzModal';
import { BoxHiddenScroll } from 'components/layout';
import { useOrderDetailQuery } from 'modules/returns/hooks/useOrderDetailQuery';
import OrderDetailModalHeader from '../other/OrderDetailModalHeader';
import OrderDetailShippingCard from '../other/OrderDetailShippingCard';
import OrderDetailInformation from '../other/OrderDetailInformation';
import Action from './Action';
import { getItemCount } from 'modules/returns/utils';

const OrderDetailModal = (props) => {
  const { isOpen, onClose, returnDetail } = props;

  const { data } = useOrderDetailQuery({
    id: returnDetail?.orderID,
    select: (response) => response?.data?.data,
    enabled: isOpen
  });

  const { shipments = [], ...restOrder } = data ?? {};

  const onModalClose = useCallback(() => {
    onClose?.();
  }, []);

  return (
    <SlzGridModal id="order-detail-slz-modal" isOpen={isOpen} onClose={onModalClose}>
      <GridItem gridColumn={'2/12'} mt="0.8125rem">
        <OrderDetailModalHeader
          onBack={onModalClose}
          orderValue={data?.orderCostBreakdown?.total || 0}
          itemCount={getItemCount(data?.orderContents)}
        />
      </GridItem>
      <GridItem gridColumn={'2/12'}>
        <BoxHiddenScroll h="36.6875rem">
          {shipments.map((shipment) => (
            <OrderDetailShippingCard key={shipment.parcelReference} data={shipment} />
          ))}
          <OrderDetailInformation data={restOrder} />
        </BoxHiddenScroll>
      </GridItem>
      <GridItem gridColumn={'2/12'} mb="1.5625rem" mt="1.875rem">
        <Action onClose={onModalClose} />
      </GridItem>
    </SlzGridModal>
  );
};

export default OrderDetailModal;
