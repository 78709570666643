import { useQuery } from 'react-query';

import { getCustomerServiceAccess } from 'modules/preferences/services/preferences-api';

export const useGetCustomerPreferenceQuery = () => {
  return useQuery({
    queryKey: [`customer-preference`],
    queryFn: () => getCustomerServiceAccess()
  });
};
