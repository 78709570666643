export const Menu = {
  button: {
    after: {
      content: '""',
      w: '0',
      h: '0',
      pos: 'absolute',
      transition: 'all 0.3s ease-out',
      transform: 'scale(0)',
      transformOrigin: 'center',
      transformBox: 'fill-box',
      left: '50%',
      top: '50%',
      zIndex: -1
    },
    hover: {
      _after: {
        bg: '#BBBCC4',
        borderRadius: '50%',
        w: 7.5,
        h: 7.5,
        transform: 'translate(-50%, -50%) scale(1)'
      }
    }
  },
  list: {
    before: {
      content: '""',
      pos: 'absolute',
      w: 0,
      h: 0,
      top: '-1.125rem',
      right: '0.625rem',
      // boxShadow: 'xl',
      borderLeft: '0.625rem solid transparent',
      borderRight: '0.625rem solid transparent',
      borderBottom: '1.25rem solid #FFFFFF'
    }
  }
};
