import React from 'react';
import { Icon } from '@chakra-ui/react';

const CustomPackagingIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.801 12" fill="currentcolor" {...props}>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Group_4889"
        data-name="Group 4889"
        transform="translate(0 0)">
        <g
          id="Group_4886"
          data-name="Group 4886"
          transform="translate(0 0)"
          clip-path="url(#clip-path)">
          <path
            id="Path_9850"
            data-name="Path 9850"
            d="M5.4,12a.6.6,0,0,1-.294-.077l-4.8-2.7A.6.6,0,0,1,0,8.7V3.3a.6.6,0,0,1,.306-.523l4.8-2.7a.6.6,0,0,1,.588,0l4.8,2.7A.6.6,0,0,1,10.8,3.3V8.7a.6.6,0,0,1-.306.523l-4.8,2.7A.6.6,0,0,1,5.4,12M1.2,8.349l4.2,2.363L9.6,8.349v-4.7L5.4,1.288,1.2,3.651Z"
            transform="translate(0 0)"
            fill="#3cd1ca"
          />
          <path
            id="Path_9851"
            data-name="Path 9851"
            d="M8.6,8.4a.6.6,0,0,1-.295-1.123l4.8-2.7a.6.6,0,0,1,.588,1.046l-4.8,2.7A.6.6,0,0,1,8.6,8.4"
            transform="translate(-3.2 -1.8)"
            fill="#3cd1ca"
          />
          <path
            id="Path_9852"
            data-name="Path 9852"
            d="M8.6,15.6A.6.6,0,0,1,8,15V9.6a.6.6,0,0,1,1.2,0V15a.6.6,0,0,1-.6.6"
            transform="translate(-3.2 -3.6)"
            fill="#3cd1ca"
          />
          <path
            id="Path_9853"
            data-name="Path 9853"
            d="M5.4,8.4a.6.6,0,0,1-.293-.077l-4.8-2.7A.6.6,0,0,1,.894,4.576l4.8,2.7A.6.6,0,0,1,5.4,8.4"
            transform="translate(0 -1.799)"
            fill="#3cd1ca"
          />
          <path
            id="Path_9854"
            data-name="Path 9854"
            d="M4.6,6.15a.6.6,0,0,1-.295-1.123l4.8-2.7a.6.6,0,0,1,.588,1.046l-4.8,2.7A.6.6,0,0,1,4.6,6.15"
            transform="translate(-1.6 -0.9)"
            fill="#3cd1ca"
          />
        </g>
      </g>
    </Icon>
  );
};

export default CustomPackagingIcon;
