import { createContext, useContext } from 'react';

export const MultipleFormContext = createContext(null);

export const MultipleFormProvider = ({ children, ...rest }) => {
  return (
    <MultipleFormContext.Provider value={{ ...rest }}>{children}</MultipleFormContext.Provider>
  );
};

export const useMultipleFormContext = () => useContext(MultipleFormContext);
