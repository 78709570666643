import { useQuery } from 'react-query';
import { getRecentUnsuccessfulOrders } from '../services/dashboard-api';

export const useRecentUnsuccessfulOrdersQuery = ({ select }) => {
  return useQuery({
    queryKey: [`recent-unsuccessful-orders`],
    queryFn: getRecentUnsuccessfulOrders,
    select
  });
};
