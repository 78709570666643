import React from 'react';
import { Icon } from '@chakra-ui/react';

const HorizontalEmptyBarChart = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="70.707"
      height="82"
      viewBox="0 0 70.707 82"
      {...props}>
      <g id="Group_160619" data-name="Group 160619" transform="translate(15753.354 16342)">
        <path
          id="Line_722"
          data-name="Line 722"
          d="M5.051,0A5.051,5.051,0,0,0,0,5.051V20.2a5.051,5.051,0,1,0,10.1,0V5.051A5.051,5.051,0,0,0,5.051,0Z"
          transform="translate(-15753.354 -16268) rotate(-90)"
          fill="#c3c4cb"
        />
        <path
          id="Line_723"
          data-name="Line 723"
          d="M5.051,0A5.051,5.051,0,0,0,0,5.051v30.3a5.051,5.051,0,0,0,10.1,0V5.051A5.051,5.051,0,0,0,5.051,0Z"
          transform="translate(-15753.354 -16288.201) rotate(-90)"
          fill="#c3c4cb"
        />
        <path
          id="Line_724"
          data-name="Line 724"
          d="M5.051,0A5.051,5.051,0,0,0,0,5.051V50.505a5.051,5.051,0,1,0,10.1,0V5.051A5.051,5.051,0,0,0,5.051,0Z"
          transform="translate(-15753.354 -16308.404) rotate(-90)"
          fill="#c3c4cb"
        />
        <path
          id="Line_725"
          data-name="Line 725"
          d="M5.051,0A5.051,5.051,0,0,0,0,5.051V65.657a5.051,5.051,0,1,0,10.1,0V5.051A5.051,5.051,0,0,0,5.051,0Z"
          transform="translate(-15753.354 -16328.605) rotate(-90)"
          fill="#c3c4cb"
        />
        <rect
          id="Rectangle_147745"
          data-name="Rectangle 147745"
          width="2"
          height="82"
          rx="1"
          transform="translate(-15753 -16342)"
          fill="#676a74"
        />
      </g>
    </Icon>
  );
};

export default HorizontalEmptyBarChart;
