export const usersTableStyle = {
  '.emphasized-cell': { pr: 0 },
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  'th:nth-of-type(2)': {
    paddingLeft: '1.5rem'
  },
  'th:not(:last-child)': {
    textAlign: 'start'
  }
};
