import { Fragment } from 'react';
import map from 'lodash/map';

import SlzTable from 'components/common/SlzTable/Table';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

import { REMITTANCE_COLS } from 'modules/billing/components/table/billing.columns';
import { PAGE_INDEX, DEFAULT_PAGING_OPTIONS } from 'constants/table.js';

const filterOptions = [];

const RemittanceTable = (props) => {
  const {
    data,
    queryParams,
    totalPage,
    handleItemPerPageChange,
    handleCurrentPageChange,
    handleOpenModal
  } = props;
  const routerQuery = useRouterQuery();

  REMITTANCE_COLS[0].isClickable = true;
  REMITTANCE_COLS[0].onClick = (row) => {
    handleOpenModal(row);
  };

  return (
    <Fragment>
      <SlzTable
        isLoading={props?.isLoading}
        variant="billing"
        sizes={['sm', 'md', 'lg']}
        columns={REMITTANCE_COLS}
        data={data}
        onRefetching={props?.onRefetching}
        isEmptyPage={props?.isEmptyPage}
        messageToast={props?.messageToast}
        displayFields={map(REMITTANCE_COLS, 'field')}
        filters={{
          title: 'Status',
          byField: 'status',
          options: filterOptions
        }}
        filterByDate={{
          field: 'createdDate',
          start: queryParams?.startDate,
          end: queryParams?.endDate
        }}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => handleItemPerPageChange(numPerPage),
            pageOptions: DEFAULT_PAGING_OPTIONS,
            currentItemPerPage: queryParams?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage: totalPage,
            currentPage: parseInt(routerQuery.get(PAGE_INDEX)),
            isGreyBg: true,
            onChange: (currentPage) => handleCurrentPageChange(currentPage)
          }
        }}
      />
    </Fragment>
  );
};

export default RemittanceTable;
