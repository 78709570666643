import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  HStack,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SlzPaper } from 'components/common/SlzPaper';
import { SlzRadioToggle } from 'components/common/SlzRadioToggle';
import { notifyUpload } from 'modules/b2c-orders/utils/notifyUpload';
import { LABELS_TOGGLE_OPTIONS, NO_SHIPPING_REFERENCE_TEXT } from 'modules/consignments/constants';
import { affixLabels } from 'modules/consignments/services/consignments-api';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

const initialCheckboxState = {
  consignmentLabelCheckbox: false,
  stockBoxLabelCheckbox: false,
  productLabelCheckbox: false
};

const ShippingAndLabelling = (props) => {
  const { shippingAndLabelling, isLoading, refetchConsignmentDetails } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedToggleOption, setSelectedToggleOption] = useState(
    shippingAndLabelling?.labelsAffixed
      ? LABELS_TOGGLE_OPTIONS[0].key
      : LABELS_TOGGLE_OPTIONS[1].key
  );

  const handleToggleChange = (e) => {
    if (e === LABELS_TOGGLE_OPTIONS[0].key) {
      setIsOpen(true);
      return;
    }

    setSelectedToggleOption(e);
  };

  const handlePopOverClose = () => setIsOpen(false);

  const LabelsAffixedPopover = useCallback(() => {
    const [labelsCheckboxes, setLabelsCheckboxes] = useState(initialCheckboxState);
    const handleCheckboxChange = ({ target: { name } }) =>
      setLabelsCheckboxes({ ...labelsCheckboxes, [name]: !labelsCheckboxes[name] });

    const toast = useToast();
    const mutationAffixLabels = useMutation({
      mutationFn: (consignmentId) => affixLabels(consignmentId)
    });

    useEffect(() => {
      setSelectedToggleOption(
        shippingAndLabelling?.labelsAffixed
          ? LABELS_TOGGLE_OPTIONS[0].key
          : LABELS_TOGGLE_OPTIONS[1].key
      );
    }, []);

    useEffect(() => {
      if (shippingAndLabelling) {
        let labels = {};
        if (!shippingAndLabelling?.pickFromPallet && shippingAndLabelling?.hasProductsWithoutGtin)
          labels = { ...labels, stockBoxLabelCheckbox: false };
        if (shippingAndLabelling?.hasProductsWithSelazarUniqueID)
          labels = { ...labels, productLabelCheckbox: false };
        setLabelsCheckboxes({ ...labels, consignmentLabelCheckbox: false });
      }
    }, [...Object.values(shippingAndLabelling)]);

    const handleConfirmLabelsAffixed = async () => {
      const checkBoxesValues = Object.values(labelsCheckboxes);
      const areAllLabelsAffixed = checkBoxesValues.every((value) => value === true);

      if (areAllLabelsAffixed) {
        try {
          const { data } = await mutationAffixLabels.mutateAsync(
            shippingAndLabelling?.consignmentId
          );
          if (!data.error) {
            notifyUpload({
              toast,
              colorScheme: 'positive',
              title: 'Labels affixed.',
              status: 'success',
              position: 'top'
            });

            handlePopOverClose();
            refetchConsignmentDetails();
          } else {
            notifyUpload({
              toast,
              colorScheme: 'negative',
              title: 'Something went wrong',
              description: `${data.message || ''}`,
              status: 'warning',
              position: 'top'
            });
          }
        } catch (error) {
          notifyUpload({
            toast,
            colorScheme: 'negative',
            title: 'Something went wrong',
            status: 'warning',
            position: 'top'
          });
        }
      }
    };

    return (
      <>
        <PopoverContent
          sx={{
            width: 'fit-content',
            pt: '20px',
            pr: '26px',
            pb: '14px',
            p: '26px',
            height: 'fit-content',
            textAlign: 'left',
            verticalAlign: 'top',
            display: 'flex',
            justifyContent: 'flex-start'
          }}>
          <PopoverHeader fontWeight="semibold" fontSize="12px" borderBottom={0} pt={0} pb={0}>
            Have you affixed all the labels?
          </PopoverHeader>
          <PopoverArrow sx={{ transform: 'scaleY(.5) rotate(45deg) !important' }} />
          <PopoverCloseButton />
          <PopoverBody fontSize="10px" pt={0} pb={0}>
            <VStack alignItems="flex-start">
              <Text>
                Please confirm you have affixed all of the <br /> necessary labels to your
                consignment. <br /> The labels you should have affixed are:
              </Text>
              <Checkbox
                onChange={handleCheckboxChange}
                checked={labelsCheckboxes?.consignmentLabelCheckbox}
                name="consignmentLabelCheckbox"
                id="Slz-checkbox-1"
                alignItems="flex-end"
                size="sm">
                Stock consignment labels
              </Checkbox>
              {!shippingAndLabelling?.pickFromPallet &&
                shippingAndLabelling?.hasProductsWithoutGtin && (
                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={labelsCheckboxes?.stockBoxLabelCheckbox}
                    name="stockBoxLabelCheckbox"
                    id="Slz-checkbox-2"
                    alignItems="flex-end"
                    size="sm">
                    Stock box labels
                  </Checkbox>
                )}
              {shippingAndLabelling?.hasProductsWithSelazarUniqueID && (
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={labelsCheckboxes?.productLabelCheckbox}
                  name="productLabelCheckbox"
                  id="Slz-checkbox-3"
                  alignItems="flex-end"
                  size="sm">
                  Product labels
                </Checkbox>
              )}
            </VStack>
          </PopoverBody>
          <PopoverFooter borderTop={0} display="flex" justifyContent="flex-end">
            <ButtonGroup size="sm">
              <Button onClick={handlePopOverClose} variant="outline" borderColor="main.500">
                Cancel
              </Button>
              <Button isDisabled={Object.values(labelsCheckboxes).some(value => value === false)} onClick={handleConfirmLabelsAffixed} borderColor="" bgColor="main.500">
                Confirm
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </>
    );
  }, [shippingAndLabelling]);

  return (
    <SlzPaper w={props.w} h={props.h} borderRadius="0.375rem" bg="light.500">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <>
          <VStack>
            <Box
              w="full"
              borderBottomWidth="0.188rem"
              borderBottomColor="white"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {props.title}
            </Box>
            <Box w="full" h="fit-content" pl="1.563rem" pr="2.375rem" pt="1rem" pb="2rem">
              <VStack>
                <Box w="full" mb="0.688rem">
                  <FormControl>
                    <SlzFormLabel fontSize="sm" color="dark.700">
                      Shipping reference
                    </SlzFormLabel>
                    <Box w="8.625rem">
                      <SlzInput
                        fontSize="sm"
                        textAlign="left"
                        color="dark.700 !important"
                        size="sm"
                        bgColor="light.300 !important"
                        value={
                          shippingAndLabelling?.shippingReference || NO_SHIPPING_REFERENCE_TEXT
                        }
                        disabled
                      />
                    </Box>
                  </FormControl>
                </Box>
                <Box w="full" mb="0.688rem !important">
                  <SlzFormLabel fontSize="sm" color="dark.700">
                    Label Affixing
                  </SlzFormLabel>
                  <Box
                    w="fit-content"
                    h="1.5rem"
                    rounded="0.188rem"
                    bgColor={!shippingAndLabelling?.labelAffixing ? 'positive.300' : 'main.300'}
                    px="1.5rem"
                    display="flex"
                    alignItems="center">
                    <Text
                      w="full"
                      color={!shippingAndLabelling?.labelAffixing ? 'positive.500' : 'main.500'}
                      fontSize="sm">
                      {shippingAndLabelling?.labelAffixing
                        ? 'Affixing my own labels'
                        : 'Yes, affix labels'}
                    </Text>
                  </Box>
                </Box>
                <HStack w="full">
                  <Box>
                    <SlzFormLabel fontSize="sm" color="dark.700">
                      Labels status
                    </SlzFormLabel>
                    <Box
                      w="6.625rem"
                      h="1.5rem"
                      rounded="0.188rem"
                      bgColor={
                        shippingAndLabelling?.labelsAffixed ? 'positive.300' : 'negative.300'
                      }
                      px="1.5rem"
                      display="flex"
                      alignItems="center">
                      <Text
                        w="full"
                        color={shippingAndLabelling?.labelsAffixed ? 'positive.500' : 'red.300'}
                        fontSize="sm"
                        whiteSpace="nowrap"
                        textAlign="center">
                        {shippingAndLabelling?.labelsAffixed ? 'Affixed' : 'Not affixed'}
                      </Text>
                    </Box>
                  </Box>
                  <Box position="relative">
                    <SlzFormLabel fontSize="sm" color="dark.700">
                      Labels affixed?
                    </SlzFormLabel>
                    <SlzRadioToggle
                      showPopOver={true}
                      readOnly={shippingAndLabelling?.labelsAffixed}
                      name="labbelsAffixed"
                      size="sm"
                      options={LABELS_TOGGLE_OPTIONS}
                      defaultValue={selectedToggleOption}
                      isPopOverOpen={isOpen}
                      PopOverContent={LabelsAffixedPopover}
                      selectedToggleOption={selectedToggleOption}
                      setSelectedToggleOption={setSelectedToggleOption}
                      handlePopOverClose={handlePopOverClose}
                      onChange={(e) => handleToggleChange(e)}
                    />
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </>
      )}
    </SlzPaper>
  );
};

export default ShippingAndLabelling;
