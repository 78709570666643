import { Card, Text, HStack, Box, FormControl } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ConsignmentBoxMiniIcon } from '../assets';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';

const ConsignmentBoxDimensionsSummary = ({ consignmentBoxDimensions, handleEditStep }) => {
  return (
    <>
      <Card
        h="3rem"
        bg="light.700"
        variant="filled"
        sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
        <HStack px="1.5rem">
          <ConsignmentBoxMiniIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
          <Box display="flex" alignItems="baseline">
            <Text
              marginRight="0.375rem"
              sx={{
                fontSize: 'lg',
                color: 'table.mainText',
                fontWeight: 'bold'
              }}>
              Consignment box dimensions & weight
            </Text>

            <Text onClick={() => handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE)} fontSize="xs" cursor="pointer" color="main.500">
              Edit
            </Text>
          </Box>
        </HStack>
      </Card>

      <Card
        px="1.5rem"
        paddingTop="0.813rem"
        bg="light.300"
        border="1px solid"
        borderColor="light.700"
        w="100%"
        h="5.75rem"
        variant="filled"
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: '0.2rem !important'
        }}>
        <HStack w="full">
          <DimensionsField
            label="Dimensions (cm)"
            consignmentBoxDimensions={consignmentBoxDimensions}
          />
        </HStack>
      </Card>
    </>
  );
};

const DimensionsField = ({ label, consignmentBoxDimensions }) => {
  const [dimensionFields] = useState([
    { field: 'height', unit: 'H' },
    { field: 'width', unit: 'W' },
    { field: 'depth', unit: 'D' }
  ]);

  return (
    <>
      <Box w="15.678rem" mr="1.5rem">
        <FormControl>
          <SlzFormLabel fontSize="sm" display="flex" alignItems="center" color="dark.700">
            {label}
          </SlzFormLabel>
          <HStack>
            {dimensionFields.map(({ field, unit }) => (
              <SlzInput
                fontSize="sm"
                key={field}
                size="sm"
                value={consignmentBoxDimensions[field]}
                color="dark.700 !important"
                textAlign="right"
                bgColor="light.300"
                leftElContent={unit}
                disabled
              />
            ))}
          </HStack>
        </FormControl>
      </Box>
      {/* Todo change the Box width conditionally based on the type of the summary (multiple or single) */}
      <Box w="5.497rem">
        <FormControl>
          <SlzFormLabel fontSize="sm" color="dark.700">
            Weight (kg)
          </SlzFormLabel>
          <SlzInput
            fontSize="sm"
            color="dark.700 !important"
            size="sm"
            value={consignmentBoxDimensions['weight']}
            bgColor="light.300"
            textAlign="right"
            disabled
          />
        </FormControl>
      </Box>
    </>
  );
};

export default ConsignmentBoxDimensionsSummary;
