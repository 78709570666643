export const tooltipArrowStyle = ({ thinArrow = false }) => ({
  content: `''`,
  position: 'absolute',
  top: '89%',
  left: '50%',
  width: '10px',
  height: '10px',
  background: 'white',
  transform: `translateX(-50%) scaleX(${thinArrow ? 50 : 100}%) rotate(45deg)`,
  'border-right': '1px solid #C3C4CB',
  'border-bottom': '1px solid #C3C4CB'
});

export const PRODUCTS_STYLES = {
  wrapper: {
    shadow: 'slz-4md',
    flex: 1,
    spacing: 0,
    borderRadius: '0.375rem'
  },
  order: {
    fontSize: '0.875rem',
    mt: 1,
    lineHeight: '1.1875rem'
  },
  row: {
    spacing: 0,
    alignItems: 'stretch',
    w: '100%',
    borderTop: 'common',
    justifyContent: 'flex-start'
  },
  rowDetail: {
    wrap: (isLoading) => ({
      px: '1.625rem',
      flex: 1,
      alignItems: 'start',
      py: '0.3125rem',
      ...(isLoading ? { pr: '4.1875rem', spacing: 0 } : { pr: '1.625rem', spacing: -0.5 })
    }),
    name: (isLoading) => ({
      fontSize: '0.625rem',
      lineHeight: '0.8125rem',
      ...(isLoading ? { mb: 0 } : { mb: '-0.0625rem' })
    })
  }
};
