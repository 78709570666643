import { HStack, Text } from '@chakra-ui/react';
import { DefaultComponents } from 'components/common/SlzDropdown';
import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';
import { NestedDropdown } from 'components/common/SlzForms/base/NestedDropdown';

const RateMatcherOption = (props) => {
  const { data: { isMatched = false, MatchedIcon = <></> } = {}, label, ...rest } = props;
  return (
    <DefaultComponents.Option {...rest}>
      <HStack w="100%" spacing={2} justifyContent="space-between" pr={2}>
        <Text>{label}</Text>
        {isMatched && MatchedIcon}
      </HStack>
    </DefaultComponents.Option>
  );
};

const RateMatcherSingleValue = (props) => {
  const { data: { isMatched = false, MatchedIcon = <></> } = {}, children } = props;

  return (
    <HStack
      w="100%"
      spacing={2}
      pl="1.188rem"
      justifyContent="space-between"
      position="absolute"
      pr={2}>
      <Text display="inline" height="1.313rem" w="6.875rem" isTruncated>
        {children}
      </Text>
      {isMatched && MatchedIcon}
    </HStack>
  );
};

const RateMatcherDropdown = ({ options, placeholder, field, label, isSFP, ...rest }) => {
  const fieldProps = {
    placeholder,
    options,
    isDisabled: isSFP,
    customComponents: {
      Option: RateMatcherOption,
      SingleValue: RateMatcherSingleValue
    },
    chakraStyles: {
      ...SELECT_STYLES,
      container: (provided) => ({
        ...provided,
        '> div': {
          borderRadius: 5
        }
      })
    }
  };

  return (
    <NestedDropdown
      field={field}
      label={label}
      isDisabled
      disabledErrors
      showTooltip={false}
      labelProps={{ color: 'dark.700', fontWeight: 'medium', fontSize: '0.88rem' }}
      fieldProps={fieldProps}
      {...rest}
    />
  );
};

RateMatcherDropdown.Option = RateMatcherOption;
RateMatcherDropdown.SingleValue = RateMatcherSingleValue;
export default RateMatcherDropdown;
