import { DATE_FORMAT_DDMMYYYY } from '../../../constants/date';
import dayjs from 'dayjs';

export const transformOnHold = ({ items = [], pageCount = 0 }) => {
  const data = {
    items,
    totalPage: pageCount
  };
  data.items = data.items.map((order) => {
    return {
      id: order.id,
      createdDate: dayjs(order.quarantineDate).format(DATE_FORMAT_DDMMYYYY),
      orderName: order.fileName,
      status: {
        key: "status",
        name: order.status,
        bgColor: "sunrise.300",
        color: "sunrise.300"
      },
      outcome: order.quarantineResolution
    };
  });

  return data;
};