import capitalize from 'lodash/capitalize';
import { INVOICE_TYPE } from '../constants';

export const transformInvoiceTypes = (types) => {
  if (!types) return [];
  const filterOptions = types.map((invoiceType) => {
    const filterOption = {
      key: invoiceType.value,
      text: capitalize(invoiceType.name),
      bg: INVOICE_TYPE[invoiceType.value]?.color
    };
    return filterOption;
  });
  return filterOptions;
};
