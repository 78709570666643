import {
  Box,
  Card,
  FormControl,
  FormErrorMessage,
  HStack,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react';
import { SlzDropdown } from 'components/common/SlzDropdown';
import { TagIcon, WarehouseIcon } from '../assets';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { CONSIGNMENT_ARRIVING_TYPES, CONSIGNMENT_LOCATION, SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';
import { SlzFormLabel } from 'components/common/SlzFormLabel';
import { SlzInput } from 'components/common/SlzInput';
import Tooltip from 'modules/integrations/components/form/Tooltip';
import SlzDateSinglePicker from 'components/common/SlzDateSinglePicker';
import dayjs from 'dayjs';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';

const getLocationDefaultStyles = {
  backgrounColor: '#FFFFFF',
  variant: 'outline',
  border: '1px solid',
  borderColor: 'main.500',
  width: '50%',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const getSelectedLocationStyles = { backgroundColor: 'main.500', color: '#FFFFFF' };

const ConsignmentLocation = () => {
  const {
    formState: { errors },
    setValue,
    watch,
    register,
    clearErrors
  } = useFormContext();

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(watch('consignmentArrivingType'));
  }, [watch('consignmentArrivingType')]);

  return (
    <>
      <FormControl>
        <HStack w="100%" mt={2.5} mb={3}>
          <Card
            sx={{
              ...getLocationDefaultStyles,
              borderRightRadius: 0,
              borderRight: 0,
              ...(selectedValue === CONSIGNMENT_ARRIVING_TYPES.PALLETS && getSelectedLocationStyles)
            }}>
            <Text>This consignment is on a pallet</Text>
          </Card>
          <Card
            sx={{
              ...getLocationDefaultStyles,
              borderLeftRadius: 0,
              marginLeft: '0 !important',
              ...(selectedValue === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX && getSelectedLocationStyles)
            }}>
            <Text>This consignment is in a box</Text>
          </Card>
        </HStack>
      </FormControl>
    </>
  );
};

export default ConsignmentLocation;
