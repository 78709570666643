import { Fragment } from 'react';

import MultipleConsignmentLabelling from 'modules/consignments/components/modal/send/multipleConsignmentUpload/MultipleConsignmentLabelling';
import MultipleConsignmentUpload from 'modules/consignments/components/modal/send/multipleConsignmentUpload/MultipleConsignmentUpload';
import MultipleProductConsignmentSummary from 'modules/consignments/components/modal/send/multipleConsignmentUpload/MultipleProductConsignmentSummary';
import ProductsDropdown from 'modules/consignments/components/modal/send/singleConsignmentUpload/ProductsDropdown';
import SingleConsignmentLabelling from 'modules/consignments/components/modal/send/singleConsignmentUpload/SingleConsignmentLabelling';
import SingleConsignmentUpload from 'modules/consignments/components/modal/send/singleConsignmentUpload/SingleConsignmentUpload';
import SingleProductConsignmentSummary from 'modules/consignments/components/modal/send/summary/SingleProductConsignmentSummary';
import ConsignmentBoxDimensions from 'modules/consignments/components/modal/send/ConsignmentBoxDimensions';

export const CONSIGNMENTS_PATHNAME = '/inventory/consignments';
export const CONSIGNMENTS_ONHOLD_PATHNAME = `${CONSIGNMENTS_PATHNAME}/onhold`;

export const BREAD_CRUMB_ITEMS = [
  {
    path: '/',
    title: 'Home'
  },
  {
    path: '/inventory',
    title: 'Inventory'
  }
];

export const CONSIGNMENTS_INITIAL_STATE = {
  list: [],
  params: {},
  filters: []
};

export const ONHOLD_INITIAL_STATE = {
  list: [],
  params: {},
  filters: []
};

export const RECEIVED_STATUSES = [
  {
    text: 'Received',
    bg: '#21C474',
    key: 'IsReceived'
  },
  {
    text: 'Not Received',
    bg: 'szrCerulean.300',
    key: 'IsNotReceived'
  }
];

export const CONSIGNMENT_SKELETON_CELL = {
  id: null,
  consignmentName: null,
  createdDate: null,
  receivedStatus: null,
  labelStatus: null
};

export const ONHOLD_SKELETON_CELL = {
  id: null,
  orderName: null,
  createdDate: null,
  status: null,
  outcome: null
};

export const CONSIGNMENT_PROCESSES = [
  {
    key: 'consignments',
    label: 'Consignments'
  },
  {
    key: 'onHold',
    label: 'On Hold'
  }
];

export const CONSIGNMENT_PARAMS = {
  PROCESS: 'process'
};

export const CONSIGNMENT_PROCESS = {
  CONSIGNMENTS: 'consignments',
  ON_HOLD: 'onHold'
};

export const CONSIGNMENT_KEY_PARAM = {
  IS_SEND_CONSIGNMENT_MODAL: 'isSendConsignmentModal'
};

export const SEND_CONSIGNMENT_MODAL_STEPS = {
  SELECT_CONSIGNMENT_TYPE: 1,
  SELECT_CONSIGNMENT_ARRIVING_TYPE: 2,
  CONSIGNMENT_DETAILS: 3,
  LABELLING: 4,
  SUMMARY: 5
};

export const SEND_CONSIGNMENT_TYPES = {
  SINGLE: 1,
  MULTIPLE: 2
};

export const CONSIGNMENT_LOCATION = {
  PALLET: 1,
  BOX: 2
};

export const CONSIGNMENT_ARRIVING_TYPES = {
  PALLETS: 1,
  CONSIGNMENT_BOX: 2
};

export const EAN_LIMIT_QUANTITY = 20000;

export const SUID_LIMIT_QUANTITY = 10000;

export const INVALID_STOCKBOX_SIZE = 'invalidStockboxSize';

export const MAX_WEIGHT = 2000; //kg

const customHeaderStyles = { fontSize: '10px', fontWeight: 'bold' };

export const PRODUCT_COLUMNS = [
  {
    showHeader: false,
    name: 'name',
    field: 'name',
    customStyles: { fontWeight: 'bold' }
  },
  {
    showHeader: true,
    header: 'SKU',
    name: 'sku',
    field: 'sku',
    customHeaderStyles: { ...customHeaderStyles }
  },
  {
    showHeader: true,
    header: 'EAN/SUID',
    name: 'ean',
    field: 'ean',
    secondOptionValue: 'selazarUniqueID',
    customHeaderStyles: { ...customHeaderStyles }
  },
  {
    name: 'tag.color',
    field: 'tag',
    customHeaderStyles: { ...customHeaderStyles }
  },
];

export const PALLET_LOCATION_TEXT = 'Pallet';
export const CONSIGNMENT_BOX_LOCATION_TEXT = 'Consignment Box';
export const NO_BATCH_NUMBER_TEXT = 'No batch number';
export const NO_SHIPPING_REFERENCE_TEXT = 'No shipping reference';

export const SEND_INVENTORY_MODAL_CONTENT = {
  1: (
    <>
      Our <b>inbound stock processing wizard</b> or <b>ISP wizard</b> allows you to build and
      organise your inventory. <br />
      Our system will guide you through the steps required to complete different types of
      consignments we accept. <br />
      Unique labels are created for each consignment. <br />
      These labels are specific and non-transferable across consignments. <br />
      If you have any questions about sending in your inventory, please contact us via
      customer.support@selazar.com
    </>
  )
};

export const SUMMARY_CONTENT = {
  1: SingleProductConsignmentSummary,
  2: MultipleProductConsignmentSummary
};

export const LABELLING_CONTENT = {
  1: SingleConsignmentLabelling,
  2: MultipleConsignmentLabelling
};

export const CONSIGNMENT_DETAIL_CONTENT = {
  1: SingleConsignmentUpload,
  2: MultipleConsignmentUpload
};

export const CONSIGNMENT_ARRIVING_TYPE_CONTENT = {
  dropdown: {
    1: ProductsDropdown,
    2: Fragment
  },
  boxDimension: {
    1: Fragment,
    2: ConsignmentBoxDimensions
  },
  isSubCardRequired: {
    1: true,
    2: false
  }
};

export const CONSIGNMENT_LABELLING_TEXT_CONTENTS = {
  note: (
    <b>
      Please be aware that by selecting this labelling <br />
      service the consignment will attract additional <br />
      processing costs.
    </b>
  ),
  importantNote: (
    <>
      The inbound stock process requires for all your products, stock boxes and consignments to be
      properly labelled. <br />
      You can affix the labels yourself or you can request Selazar to do it for you <br />
      Would you like Selazar to affix any necessary stock consignment labels for you upon receipt?
    </>
  ),
  toggleText: <b>Yes, I would like Selazar to affix the labels</b>
};

export const CONSIGNMENT_STATUS_COLOR = {
  ['On Hold']: 'sunrise.300',
  ['Received']: 'tetra.500',
  ['Not Received']: 'szrCerulean.300',
  ['Quality Check in Progress']: 'sunshine.500',
  ['Quality Check Completed']: 'sunshine.500',
  ['Check-in in Progress']: 'marine.500',
  ['Check-in Completed']: 'marine.500',
  ['Processed']: 'positive.500',
  ['Processing']: 'positive.500',
};

export const CONSIGNMENT_LABEL_TEXT = 'Download stock consignment labels';
export const STOCK_BOX_LABEL_TEXT = 'Download stock box labels';
export const PRODUCT_LABELS_TEXT = 'Download product labels';

export const LABELS_TOGGLE_OPTIONS = [
  {
    key: '0',
    value: 'Yes'
  },
  {
    key: '1',
    value: 'No'
  }
];