import { Box, HStack, Text } from '@chakra-ui/react';

import { PaginatedTooltipContent } from 'components/common/SlzToolTip/templates';
import { SlzButton } from 'components/common/SlzButton';

import { B2B_ORDER_OPTION_STYLE, MODIFY_BUTTON_STYLE } from 'modules/b2b-orders/constants/styles';
import {
  B2B_SELF_FULFILLED_TOOLTIP_CONTENT,
  BULK_ORDER_ORDER_OPTIONS_STEP_INDEX
} from 'modules/b2b-orders/constants';
import { Tooltip } from 'modules/b2b-orders/components/tooltip';
import { OrderOptionToggle, OrderOptionTag } from '.';

const B2BOrderOptionTitle = ({
  title,
  toggle,
  tooltip,
  tag,
  isTitleDisabled = false,
  modify,
  onJumpToStep
}) => (
  <HStack {...B2B_ORDER_OPTION_STYLE.title.wrapper}>
    <Box
      {...B2B_ORDER_OPTION_STYLE.title.backgroundIcon}
      bg={B2B_ORDER_OPTION_STYLE[isTitleDisabled].icon}>
      {title.icon}
    </Box>
    <Text
      {...B2B_ORDER_OPTION_STYLE.title.text}
      color={B2B_ORDER_OPTION_STYLE[isTitleDisabled].title}>
      {title.text}
    </Text>
    {toggle?.show && <OrderOptionToggle toggle={toggle} />}
    {tooltip?.show && (
      <Tooltip
        placement="right"
        trigger="hover"
        content={<PaginatedTooltipContent tooltipContent={B2B_SELF_FULFILLED_TOOLTIP_CONTENT} />}
      />
    )}
    {tag?.show && <OrderOptionTag tag={tag} />}
    {modify?.show && (
      <SlzButton
        onClick={() => onJumpToStep(BULK_ORDER_ORDER_OPTIONS_STEP_INDEX)}
        {...MODIFY_BUTTON_STYLE}>
        {modify.text}
      </SlzButton>
    )}
  </HStack>
);

export default B2BOrderOptionTitle;
