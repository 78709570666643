import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, Heading, VStack, useDisclosure } from '@chakra-ui/react';

import { ControlledField } from 'components/form';
import EditPasswordForm from './EditPasswordForm';
import { SlzTextInput } from 'components/common/SlzInput';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import { EDIT_USER_TEXT, ERROR_TYPE_EMAIL } from 'modules/users/constants';

const EditUserDetailForm = ({ title, userId, ...rest }) => {
  const checkEmailPopover = useDisclosure();
  const methods = useFormContext();
  const emailFieldState = methods.getFieldState('email');

  const handleOnCloseCheckEmailPopover = () => {
    checkEmailPopover.onClose();
    methods.setError('email', {
      type: 'manual',
      message: 'invalid email'
    });
  };

  useEffect(() => {
    if (emailFieldState?.invalid && emailFieldState?.error?.type === ERROR_TYPE_EMAIL) {
      checkEmailPopover.onOpen();
      return;
    }
    checkEmailPopover.onClose();
  }, [emailFieldState]);

  return (
    <Fragment>
      {title && (
        <Heading fontSize="xl" lineHeight="tall">
          {title}
        </Heading>
      )}
      <VStack w="full" spacing={5}>
        <Flex w="full" gap={3}>
          <ControlledField
            label="Forename"
            name="forename"
            component={SlzTextInput}
            isInlineError
          />
          <ControlledField label="Surname" name="surname" component={SlzTextInput} isInlineError />
        </Flex>
        <Flex w="full" gap={3}>
          <SlzPopoverConfirm
            variant="emailNote"
            placement="bottom"
            title={EDIT_USER_TEXT.checkEmailAddress}
            description={EDIT_USER_TEXT.checkEmailAddressDesc}
            okText="Ok"
            gutter={12}
            arrowSize={25}
            cancelText={null}
            isOpen={checkEmailPopover.isOpen}
            onClose={handleOnCloseCheckEmailPopover}
            onOk={handleOnCloseCheckEmailPopover}>
            <Box w="full">
              <ControlledField label="Email" name="email" component={SlzTextInput} isInlineError />
            </Box>
          </SlzPopoverConfirm>
          <EditPasswordForm userId={userId} {...rest} />
        </Flex>
      </VStack>
    </Fragment>
  );
};

export default EditUserDetailForm;
