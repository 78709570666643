import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { INIT_PASSWORD_CRITERIA, PASSWORD_CRITERIA_STATUS } from '../constants';

const getPasswordCriteriaStatus = ({ password, confirmPassword, pattern }) => {
  const isPasswordMatch = password === confirmPassword;
  const hasValidInput = password && confirmPassword;

  if (pattern) {
    return pattern.test(password) ? PASSWORD_CRITERIA_STATUS.pass : PASSWORD_CRITERIA_STATUS.fail;
  }

  if (!hasValidInput) {
    return PASSWORD_CRITERIA_STATUS.fail;
  }

  return isPasswordMatch ? PASSWORD_CRITERIA_STATUS.pass : PASSWORD_CRITERIA_STATUS.fail;
};

export const useCheckPasswordCriteria = ({
  password,
  confirmPassword,
  isDirty = false,
  delayTime = 0,
  isCheckConfirmPassword = true
}) => {
  const { confirmPassword: _, ...rest } = INIT_PASSWORD_CRITERIA;

  const defaultPasswordCriteria = isCheckConfirmPassword ? INIT_PASSWORD_CRITERIA : { ...rest };

  const [passwordCriteriaStatus, setPasswordCriteriaStatus] = useState(defaultPasswordCriteria);

  const updatedPasswordCriteriaStatus = Object.entries(defaultPasswordCriteria).reduce(
    (criteria, [key, { pattern, ...rest }]) => {
      const status = getPasswordCriteriaStatus({ password, confirmPassword, pattern });

      return { ...criteria, [key]: { ...rest, status } };
    },

    {}
  );

  useEffect(() => {
    isDirty &&
      debounce(() => setPasswordCriteriaStatus(updatedPasswordCriteriaStatus), delayTime)();
  }, [confirmPassword, isDirty]);

  useEffect(() => {
    if (isDirty || password) {
      setPasswordCriteriaStatus(updatedPasswordCriteriaStatus);
    } else {
      setPasswordCriteriaStatus(defaultPasswordCriteria);
    }
  }, [password, isDirty]);

  return passwordCriteriaStatus;
};
