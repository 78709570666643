import { Box, Text } from '@chakra-ui/react'

const TradingZone = ({ tradingZone }) => {
    return (

        <Box
            bg='#A25DDC'
            minW='1.8rem'
            maxWidth='fit-content'
            h='1.5rem'
            borderRadius='0.188rem'
            textAlign='center'
            color='white'
            display='flex'
            justifyContent='center'>
            <Text
                fontSize={12}
                w='100%'
                h='100%'
                ml='0.375rem'
                mr='0.375rem' 
                mt='0.25rem'>{tradingZone}</Text>
        </Box>
    )
}

export default TradingZone;