import React from 'react';
import { Icon } from '@chakra-ui/react';

const MarketPlaceIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33695" data-name="Group 33695" transform="translate(-10370.244 6903.336)">
        <g id="Group_33698" data-name="Group 33698" transform="translate(9844.469 -9050.824)">
          <path
            id="Rectangle_137"
            data-name="Rectangle 137"
            d="M1.1-1H5.291a2.1,2.1,0,0,1,2.1,2.1V5.291a2.1,2.1,0,0,1-2.1,2.1H1.1A2.1,2.1,0,0,1-1,5.291V1.1A2.1,2.1,0,0,1,1.1-1ZM5.291,5.291V1.1H1.1V5.291Z"
            transform="translate(526.776 2149.551)"
            fill="#303134"
          />
          <path
            id="Rectangle_138"
            data-name="Rectangle 138"
            d="M1.1-1H5.291a2.1,2.1,0,0,1,2.1,2.1V5.291a2.1,2.1,0,0,1-2.1,2.1H1.1A2.1,2.1,0,0,1-1,5.291V1.1A2.1,2.1,0,0,1,1.1-1ZM5.291,5.291V1.1H1.1V5.291Z"
            transform="translate(526.776 2160.037)"
            fill="#303134"
          />
          <path
            id="Rectangle_139"
            data-name="Rectangle 139"
            d="M1.1-1H5.291a2.1,2.1,0,0,1,2.1,2.1V5.291a2.1,2.1,0,0,1-2.1,2.1H1.1A2.1,2.1,0,0,1-1,5.291V1.1A2.1,2.1,0,0,1,1.1-1ZM5.291,5.291V1.1H1.1V5.291Z"
            transform="translate(537.261 2160.037)"
            fill="#303134"
          />
          <path
            id="Line_37"
            data-name="Line 37"
            d="M6.34,1.1H.049a1.049,1.049,0,1,1,0-2.1H6.34a1.049,1.049,0,0,1,0,2.1Z"
            transform="translate(537.261 2152.697)"
            fill="#303134"
          />
          <path
            id="Line_38"
            data-name="Line 38"
            d="M.049,7.389A1.049,1.049,0,0,1-1,6.34V.049a1.049,1.049,0,1,1,2.1,0V6.34A1.049,1.049,0,0,1,.049,7.389Z"
            transform="translate(540.407 2149.551)"
            fill="#303134"
          />
        </g>
      </g>
    </Icon>
  );
};

export default MarketPlaceIcon;
