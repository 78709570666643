import { Icon } from '@chakra-ui/react';
import React from 'react';

const CollectionAdvicesIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33682" data-name="Group 33682" transform="translate(-10371.996 6903.336)">
        <g id="Group_33690" data-name="Group 33690" transform="translate(9361.664 -7652.716)">
          <path
            id="Path_8087"
            data-name="Path 8087"
            d="M1020.332,750.664v4a1,1,0,0,0,1,1h4"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8088"
            data-name="Path 8088"
            d="M1023.332,768.664h-10a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h7l5,5v11A2,2,0,0,1,1023.332,768.664Z"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8089"
            data-name="Path 8089"
            d="M1015.332,762.664h6"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_8090"
            data-name="Path 8090"
            d="M1018.832,765.164l2.5-2.5-2.5-2.5"
            fill="none"
            stroke="#303134"
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </Icon>
  );
};

export default CollectionAdvicesIcon;
