import { createIcon } from "@chakra-ui/react";
const VisaIcon = createIcon({
    displayName: "VisaIcon",
    viewBox: "0 0 147.849 47.966",
    path: (
        <>
            <path
                fill="#ffffff"
                d="M64.093 47.169H52.029L59.569.83h12.065z"
            />
            <path
                fill="#ffffff"
                d="M41.877.83l-11.5 31.871-1.361-6.863-4.06-20.837S24.465.828 19.233.828H.223L0 1.612a45.068 45.068 0 0112.619 5.3l10.482 40.257h12.57L54.865.83z"
            />
            <path
                fill="#ffffff"
                d="M136.771 47.169h11.077L138.19.83h-9.7c-4.479 0-5.569 3.453-5.569 3.453L104.93 47.169h12.571l2.515-6.883h15.337zm-13.27-16.392l6.339-17.341 3.566 17.341z"
            />
            <path
                fill="#ffffff"
                d="M105.874 11.973l1.721-9.953A34.829 34.829 0 0096.744 0c-5.987 0-20.2 2.618-20.2 15.34 0 11.973 16.686 12.121 16.686 18.408s-14.966 5.162-19.905 1.2l-1.795 10.4a33.8 33.8 0 0013.619 2.618c8.231 0 20.653-4.265 20.653-15.864 0-12.048-16.837-13.17-16.837-18.408s11.749-4.566 16.912-1.721"
            />
            <path
                fill="#f9a533"
                d="M29.019 25.841l-4.06-20.837S24.468.831 19.236.831H.223L0 1.615a46.76 46.76 0 0117.906 8.99 36.142 36.142 0 0111.112 15.236"
            />
        </>
    )
});

export default VisaIcon;