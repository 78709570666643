import { Icon } from '@chakra-ui/react';
import React from 'react';

const ChipCardIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 69.447 48"
      fill="currentColor"
      strokeWidth="0"
      {...props}>
      <g id="Group_3841" data-name="Group 3841" transform="translate(-851.322 -6320.314)">
        <rect
          id="Rectangle_1010"
          data-name="Rectangle 1010"
          width="69.447"
          height="48"
          rx="6"
          transform="translate(851.322 6320.318)"
          fill="#dedede"
        />
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M-4099,21939.006c1.289,0,3.33-.732,5.739-4.219a32.951,32.951,0,0,0,4.163-9.137,18.455,18.455,0,0,1-2.9-10.467,18.864,18.864,0,0,1,2.551-9.951,31.325,31.325,0,0,0-4.051-8.348c-1.828-2.541-3.73-3.883-5.5-3.883s-3.675,1.342-5.5,3.883a31.305,31.305,0,0,0-4.051,8.342,18.858,18.858,0,0,1,2.554,9.957,18.447,18.447,0,0,1-2.9,10.469,33.429,33.429,0,0,0,4.079,9.012,14.063,14.063,0,0,0,2.63,3,5.124,5.124,0,0,0,3.193,1.346m0,1c-2.816,0-5.171-2.6-6.651-4.781a34.743,34.743,0,0,1-4.351-9.779c1.879-2.338,3-6.174,3-10.262a17.406,17.406,0,0,0-2.654-9.8,32.727,32.727,0,0,1,4.34-9.082c2.023-2.812,4.207-4.3,6.314-4.3s4.288,1.486,6.312,4.3a32.765,32.765,0,0,1,4.339,9.088,17.412,17.412,0,0,0-2.65,9.795c0,4.084,1.121,7.92,3,10.26C-4089.946,21932.457-4094.22,21940.006-4099,21940.006Z"
          transform="translate(4985.045 -15571.688)"
          fill="#c3c4cb"
        />
        <path
          id="Line_52"
          data-name="Line 52"
          d="M24,.5H0v-1H24Z"
          transform="translate(851.822 6333.498)"
          fill="#c3c4cb"
        />
        <path
          id="Line_55"
          data-name="Line 55"
          d="M24,.5H0v-1H24Z"
          transform="translate(895.822 6333.498)"
          fill="#c3c4cb"
        />
        <path
          id="Line_53"
          data-name="Line 53"
          d="M24,.5H0v-1H24Z"
          transform="translate(851.822 6353.498)"
          fill="#c3c4cb"
        />
        <path
          id="Line_54"
          data-name="Line 54"
          d="M24,.5H0v-1H24Z"
          transform="translate(895.822 6353.498)"
          fill="#c3c4cb"
        />
      </g>
    </Icon>
  );
};

export default ChipCardIcon;
