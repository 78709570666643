import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Link, Text, Box } from '@chakra-ui/react'
import SlzButton from '../../../../components/common/SlzButton/SlzButton';
import { Warehouse, Active, UKFlag, Info, Standy, Configure, Attention } from '../../../../components/common/Icons/EnabledCentreCards/EnabledFulfilmentCentreIcons'
import RequestAccessModal from '../RequestAccessModal/RequestAccessModal';
import { getAccessRequestById } from 'services/fulfillmentCentre';
import useSlzToast from 'hooks/useSlzToast';

export const EnabledCentreCard = ({ categories, isPostMVP, warehouse }) => {

    const [accessRequestByID, setAccessRequestByID] = useState([]);
    const [isModalActive, setIsModalActive] = useState(false);
    const [_, setToast] = useSlzToast();

    const IconHeader = (status) => {
        switch (status) {
            case "Active":
                return <Active />;
            case "Standy":
                return <Standy />;
            case "Configure":
                return <Configure />;
            case "Pending":
                return <PendingStatusIndicator />;
            default:
                return null;
        }
    }

    const ButtonFooter = (status) => {
        switch (status) {
            case "Active":
            case "Standy":
                return <SlzButton type="submit" colorScheme="main">
                    Preferences
                </SlzButton>
            case "Configure":
                return <Box>
                    <SlzButton type="submit" colorScheme="main" variant="outline">
                        Configure
                    </SlzButton>
                    <Attention sx={{ transform: 'translate(-12px, -10px)', position: 'absolute' }} />
                </Box>
            case "Pending":
                return <SlzButton type="submit" colorScheme="main" variant="outline" onClick={() => getAccessRequest()}>
                    Review
                </SlzButton>
            default:
                return null;
        }
    }

    const textColor = () => {
        if (warehouse.state === "Standy" || warehouse.state === "Configure" || warehouse.state === "Pending") return "stone.300";
        else return "table.mainText";
    }

    const labelColor = () => {
        if (warehouse.state === "Pending") return "stone.300";
        else return "table.mainText";
    }

    const PendingStatusIndicator = () => {
        return (
            <Box w='5.375rem' h='1.125rem' justifyContent='center' display='flex' alignItems='center' borderRadius='1.313rem' bg='sunshine.500'>
                <Text fontSize={12} color='willow.500'>Pending</Text>
            </Box>
        )
    }

    const PostMVPInformation = () => {
        return (
            <>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Text fontSize={12} color={labelColor()} ml='0.75rem' fontWeight="bold">Total SKUs:</Text>
                    <Box w='7.25rem' h='1.5rem' borderRadius='0.188rem' ml='3.563rem' display='flex' alignItems='center' bg='lightAndShadow.darkModeText'>
                        <Text fontSize={12} color={textColor()} ml='0.75rem'>{totalSKUs}</Text>
                    </Box>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Text fontSize={12} color={labelColor()} ml='0.75rem' fontWeight="bold">Total Items:</Text>
                    <Box w='7.25rem' h='1.5rem' borderRadius='0.188rem' ml='3.438rem' display='flex' alignItems='center' bg='lightAndShadow.darkModeText'>
                        <Text fontSize={12} color={textColor()} ml='0.75rem'>{totalItems}</Text>
                    </Box>
                </Box>
            </>
        )
    }

    const getAccessRequest = async () => {
        const response = await getAccessRequestById(warehouse.requestID);
        if(response === "error") setToast({ title: "Error in reviewing your pending request. Please try again.", status: 'warning', colorScheme: 'negative', chakraToastProps:{duration: null} });
        setAccessRequestByID(response);
        showModal();
    }

    const formattedDate = (fullDate) => {
        const date = new Date(fullDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const shortDate = (day < 10 ? '0' : '') + day + '/' + (month < 10 ? '0' : '') + month + '/' + year;

        return shortDate;
    }

    const showModal = () => {
        setIsModalActive(true);
    }

    return (
        <>
            <RequestAccessModal
                isOpen={isModalActive}
                warehouse={warehouse}
                categories={categories}
                setIsModalActive={setIsModalActive}
                accessRequestByID={accessRequestByID}
                requesStatus={warehouse.state}
            />
            <Card w='19.125rem' borderRadius='0.75rem' boxShadow='md'>
                <CardHeader bg='indigo.500' color='white' borderRadius='0.75rem 0.75rem 0rem 0rem' p='1.35rem 1.5rem 1.188rem 1.5rem'>
                    <Box>
                        <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                            <Box
                                display='flex'
                                flexDirection='row'
                                alignItems='flex-end'
                                gap='0.313rem'>
                                <Warehouse />
                                <Text fontSize={12}>Fulfilment Centre</Text>
                            </Box>
                            {IconHeader(warehouse.state)}
                        </Box>
                        <Text fontSize={20}>{warehouse.warehouseName}</Text>
                    </Box>
                </CardHeader>

                <CardBody p='0.906rem 1.513rem 1.5rem 1.5rem' borderRadius='0.75rem 0.75rem 0rem 0rem'>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <Box>
                            <Text fontSize={12}>{warehouse.address?.line1}</Text>
                            <Text fontSize={12}>{warehouse.address?.line2}</Text>
                            <Text fontSize={12}>{warehouse.address?.postCode}</Text>
                            <Text fontSize={12}>{warehouse.address?.town}, {warehouse.address?.country}</Text>
                        </Box>
                        <Box display='flex' flexDirection='row' alignItems='flex-start' gap='0.375rem'>
                            <Box w='1.25rem' h='1.25rem' color='white' bg='szrNavy.500' borderRadius='0.188rem' display='flex' alignItems='center' justifyContent='center'>
                                <Text>{warehouse.currency.symbol}</Text>
                            </Box>
                            <UKFlag />
                        </Box>
                    </Box>
                    <Box w='16.125rem' h={isPostMVP ? '6.75rem' : '3rem'} mt='0.969rem' display='flex'
                        flexDirection='column' gap='0.375rem' justifyContent='center' borderRadius='0.375rem'
                        bg={warehouse.state === "Pending" ? 'light.500' : 'slzrLilac.300'} disabled
                    >
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Text fontSize={12} color={labelColor()} ml='0.75rem' fontWeight="bold">Start Date:</Text>
                            <Info ml='0.313rem' />
                            <Box w='7.25rem' h='1.5rem' borderRadius='0.188rem' ml='2.75rem' display='flex' alignItems='center' bg='lightAndShadow.darkModeText'>
                                <Text fontSize={12} color={labelColor()} ml='0.75rem'>{formattedDate(warehouse.activeOn)}</Text>
                            </Box>
                        </Box>
                        {isPostMVP && <PostMVPInformation />}
                    </Box>
                    <Box display='flex' justifyContent={isPostMVP ? 'space-between' : 'flex-end'} alignItems='flex-end' mt='3rem'>
                        {isPostMVP && <Link><Text as='u' fontSize={12} color='main.500'>View full details</Text></Link>}
                        <Box>
                            {ButtonFooter(warehouse.state)}
                        </Box>
                    </Box>
                </CardBody>
            </Card ></>
    )
}
