import { Icon } from '@chakra-ui/react';

const ConsignmentBoxMiniIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34279" data-name="Group 34279" transform="translate(-379 -352)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(379 352)"
          fill="#0973ea"
        />
        <g id="Group_34282" data-name="Group 34282" transform="translate(194.374 -1790.939)">
          <path
            id="Path_3455"
            data-name="Path 3455"
            d="M194.626,2158.33a.539.539,0,0,1-.264-.069l-4.312-2.426a.538.538,0,0,1-.275-.47v-4.851a.539.539,0,0,1,.275-.469l4.312-2.426a.539.539,0,0,1,.529,0l4.312,2.426a.539.539,0,0,1,.275.469v4.851a.538.538,0,0,1-.275.47l-4.312,2.426A.539.539,0,0,1,194.626,2158.33Zm-3.773-3.28,3.773,2.122,3.773-2.122v-4.22l-3.773-2.123-3.773,2.123Z"
            transform="translate(0)"
            fill="#fafafa"
          />
          <path
            id="Line_19"
            data-name="Line 19"
            d="M-.461,2.5a.539.539,0,0,1-.47-.275.539.539,0,0,1,.206-.734L3.587-.931a.539.539,0,0,1,.734.206.539.539,0,0,1-.206.734L-.2,2.434A.537.537,0,0,1-.461,2.5Z"
            transform="translate(195.087 2150.977)"
            fill="#fafafa"
          />
          <path
            id="Line_20"
            data-name="Line 20"
            d="M-.461,4.929A.539.539,0,0,1-1,4.39V-.461A.539.539,0,0,1-.461-1a.539.539,0,0,1,.539.539V4.39A.539.539,0,0,1-.461,4.929Z"
            transform="translate(195.087 2153.402)"
            fill="#fafafa"
          />
          <path
            id="Line_21"
            data-name="Line 21"
            d="M3.85,2.5a.537.537,0,0,1-.264-.069L-.725.009A.539.539,0,0,1-.931-.725.539.539,0,0,1-.2-.931L4.115,1.495A.539.539,0,0,1,3.85,2.5Z"
            transform="translate(190.776 2150.977)"
            fill="#fafafa"
          />
          <path
            id="Line_22"
            data-name="Line 22"
            d="M-.461,2.5a.539.539,0,0,1-.47-.275.539.539,0,0,1,.206-.734L3.587-.931a.539.539,0,0,1,.734.206.539.539,0,0,1-.206.734L-.2,2.434A.537.537,0,0,1-.461,2.5Z"
            transform="translate(192.932 2149.764)"
            fill="#fafafa"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ConsignmentBoxMiniIcon;
