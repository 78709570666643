import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import badgeTheme from './badge';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

const baseStyle = {
  borderRadius: 'full',
  display: 'inline-flex',
  verticalAlign: 'top',
  alignItems: 'center',
  h: '1.125rem',
  flexBasis: 5,
  fontSize: '0.75rem',
  px: 3,
  lineHeight: 1
};

const variantCourier = defineStyle(() => {
  return {
    h: 5,
    fontSize: '1rem',
    borderRadius: 'md',
    lineHeight: 1.313
  };
});

const variantOutline = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(`${c}.300`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.500`);
  const color = mode(lightColor, darkColor)(props);
  return {
    color,
    boxShadow: `inset 0 0 0rem 0.063rem ${color}`,
    bg: transparentize(color, 0.16)(props)
  };
});

const variants = {
  solid: defineStyle((props) => badgeTheme.variants?.solid(props)),
  outline: variantOutline,
  courier: variantCourier
};

export default defineStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'solid',
    colorScheme: 'main'
  }
});
