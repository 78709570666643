import { VStack } from '@chakra-ui/react';
import AddProductOverview from '../../form/AddProductOverview';
import ReturnPreference from '../../form/ReturnPreference';
import PrepackedProduct from '../../form/PrepackedProduct';
import WrapForm from '../common/WrapForm';
import ProductInformation from '../ProductInformation';
import UniqueIdentifiers from '../UniqueIdentifiers';
import { Mode } from 'modules/products/constants';

const AddManual = () => {
  return (
    <VStack gap={5} id="slz-prod-manual-forms">
      <WrapForm label="Product Overview">
        <AddProductOverview />
      </WrapForm>
      <WrapForm label="Product information" pb="10">
        <ProductInformation />
      </WrapForm>
      <WrapForm label="Unique Identifiers" pb="10">
        <UniqueIdentifiers />
      </WrapForm>
      <WrapForm label="Packaging requirements">
        <PrepackedProduct isEditable={true} mode={Mode.ADD} />
      </WrapForm>
    </VStack>
  );
};

export default AddManual;
