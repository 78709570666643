import { Box, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import { ControlledField } from 'components/form';

import { SlzDropdown } from 'components/common/SlzDropdown';
import B2BProductInformationCell from 'components/common/SlzTable/templates/B2BProductInformationCell';
import ImageCell from 'components/common/SlzTable/templates/ImageCell';
import { AssignedField } from 'modules/b2b-orders/components/other';
import {
  CUSTOM_BOX_PREFIX,
  VALIDATE_STATUS,
  boxSplitQuantityOptions
} from 'modules/b2b-orders/constants';

const Select = ({ row, boxIndex, value, setValidateProgress }) => {
  const { setValue } = useFormContext();

  const handleSelectedQuantityProduct = (productId, product, boxIndex) => {
    if (isNaN(product.value) || product.value < 0) {
      return;
    }

    setValidateProgress(VALIDATE_STATUS.validating);
    setValue(`${CUSTOM_BOX_PREFIX}.${boxIndex}.${productId}`, product, { shouldDirty: true });
  };

  return (
    <SlzDropdown.Creatable
      isInvalid={row.quantity > row.totalStock}
      options={boxSplitQuantityOptions}
      onChange={(item) =>
        handleSelectedQuantityProduct(
          row?.identifier,
          {
            ...row,
            quantity: Number(item.value),
            value: Number(item.value),
            label: Number(item.label)
          },
          boxIndex
        )
      }
      value={value}
      menuPosition="fixed"
      formatCreateLabel={(value) => <Text fontSize="0.875rem">{value}</Text>}
      maxMenuHeight={443}
    />
  );
};

export const PRODUCT_SELECTION_COLUMNS = [
  {
    name: '',
    field: 'image',
    width: 48,
    type: COL_CUSTOM,
    render: ImageCell
  },
  {
    name: 'Selected Products',
    field: 'name',
    isSorted: true,
    width: 230,
    header: { align: 'left', paddingLeft: '1.125rem' },
    render: B2BProductInformationCell
  },
  {
    name: 'Assigned',
    field: 'totalStock',
    width: 72,
    type: 'text',
    render: ({ row }) => (
      <AssignedField identifier={row.identifier} originalQuantity={row.quantity} />
    )
  }
];

export const B2B_SELECTED_PRODUCT_COLUMNS = [
  {
    name: 'Box 1',
    field: 'name',
    isSorted: true,
    width: 250,
    header: { align: 'left', paddingLeft: '1.125rem' },
    render: ({ row }) => <B2BProductInformationCell pl="0.75rem" row={row} />
  }
];

export const getB2BProductSelectionColumns = () => {
  const columns = [...PRODUCT_SELECTION_COLUMNS];

  return columns;
};

export const getB2BProductSelectedColumns = ({ boxIndex, ...rest }) => {
  const columns = [...B2B_SELECTED_PRODUCT_COLUMNS];

  columns[1] = {
    name: 'Select Quantity',
    field: 'quantity',
    width: 66,
    render: ({ row }) => (
      <Box pos="relative" w="4.5rem" ml="auto" mr="0.375rem !important">
        <ControlledField
          row={row}
          boxIndex={boxIndex}
          name={`${CUSTOM_BOX_PREFIX}.${boxIndex}.${row.identifier}`}
          component={Select}
          {...rest}
        />
      </Box>
    )
  };

  return columns;
};
