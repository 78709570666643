import { useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Text, Flex, VStack, StackItem, HStack } from '@chakra-ui/react';
import { Controller, FormProvider } from 'react-hook-form';

import {
  RETURN_SETTING_GRADES,
  RETURN_SETTING_STYLE,
  SETTING_APPLIES_OPTIONS,
  RETURN_SETTING_GRADING_TEXT,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { Tooltip } from '../tooltip';
import { PreferencePaper as ReturnSettingPaper } from '../common';
import {
  ReturnSettingGradeDesc,
  ReturnSettingDropdown,
  ReturnSettingGradePreferenceHeader
} from '../other';
import { SlzTag } from 'components/common/SlzTag';
import { useReturnSettingGradings } from 'modules/preferences/hooks';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

const ReturnSettingGradeForm = ({ disabled = false }) => {
  const {
    data: { gradingOptions, gradingInformation, gradingActions },
    isFetching
  } = useReturnSettingGradings();

  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.grade]: form } = forms;
  const methods = form?.form;
  const setFormDefaultValues = () => {
    return {
      grading: gradingActions?.grading.reduce(
        (grade, currentValue) => ({
          ...grade,
          [currentValue.grade]: gradingOptions[0]?.options?.find(
            (option) => option.value == currentValue.action
          )
        }),
        {}
      ),
      selection: SETTING_APPLIES_OPTIONS.find((option) => option.value == gradingActions?.selection)
    };
  };

  useEffect(() => {
    if (!isFetching) {
      methods?.reset(setFormDefaultValues());
    }
  }, [isFetching]);

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <ReturnSettingGradePreferenceHeader
          header="Grading actions"
          fontSize="1.063rem"
          disabled={disabled}
          tooltip={
            <Tooltip
              placement="right"
              disabled={disabled}
              trigger="hover"
              content={RETURN_SETTING_GRADING_TEXT.gradeNote.tooltip}
            />
          }
        />
        <ReturnSettingPaper bg="light.300" w="full" minH="25.25rem" mt={2.5} py={5} ps={8} pe={6}>
          <Flex justifyContent="flex-end">
            <ReturnSettingGradeDesc gradingInformation={gradingInformation} disabled={disabled} />
          </Flex>
          <VStack align="flex-start" spacing={6} w="full">
            {RETURN_SETTING_GRADES.map((grade) => (
              <StackItem w="full" key={uniqueId()}>
                <ReturnSettingGradePreferenceHeader
                  disabled={disabled}
                  header={`${RETURN_SETTING_GRADING_TEXT.grade} ${grade}`}
                />
                <Controller
                  control={methods?.control}
                  name={`grading[${grade}]`}
                  render={({ field }) => {
                    return (
                      <ReturnSettingDropdown
                        w="90%"
                        placeholder="Select the grade"
                        isLoaded={!isFetching}
                        disabled={disabled}
                        options={gradingOptions}
                        value={field?.value}
                        onChange={field?.onChange}
                      />
                    );
                  }}
                />
              </StackItem>
            ))}
            <StackItem w="full">
              <ReturnSettingGradePreferenceHeader
                fontWeight="normal"
                disabled={disabled}
                header={RETURN_SETTING_GRADING_TEXT.gradeActionApply}
                tooltip={
                  <Tooltip placement="right" disabled={disabled} trigger="hover" content={null} />
                }
              />
              {gradingOptions && (
                <Controller
                  control={methods?.control}
                  name="selection"
                  render={({ field }) => {
                    return (
                      <ReturnSettingDropdown
                        w="full"
                        isLoaded={!isFetching}
                        disabled={disabled}
                        options={SETTING_APPLIES_OPTIONS}
                        value={field?.value}
                        onChange={field?.onChange}
                      />
                    );
                  }}
                />
              )}
            </StackItem>
            <HStack w="full">
              <SlzTag
                size="lg"
                fontWeight="normal"
                sx={disabled && RETURN_SETTING_STYLE.noteDisabled}>
                {RETURN_SETTING_GRADING_TEXT.gradeNote.note}
              </SlzTag>
              <Text fontSize="md" sx={disabled && RETURN_SETTING_STYLE.textDisabled}>
                {RETURN_SETTING_GRADING_TEXT.gradeNote.content}
              </Text>
            </HStack>
          </VStack>
        </ReturnSettingPaper>
      </form>
    </FormProvider>
  );
};

export default ReturnSettingGradeForm;
