import { Box, chakra, Flex, HStack, StylesProvider, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { Pages } from './Pages';
import Direction from './Direction';
import { PAGINATION_TITLE } from './Constants';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const SlzPagination = (props) => {
  const {
    showTitle = true,
    isShowPages = true,
    isShowDirection = true,
    pages,
    direction,
    title,
    variant,
    colorScheme,
    size,
    ...rest
  } = props;
  const styles = useMultiStyleConfig('Pagination', {
    variant,
    colorScheme,
    size,
    direction,
    isGreyBg: direction?.isGreyBg,
    isTooltipBg: direction?.isTooltipBg,
    showDirectionLabel: direction?.showDirectionLabel
  });

  return (
    <StylesProvider value={styles}>
      <Box
        __css={{ ...styles.container, ...rest }}
        id={uniqueId(`${SEL_PREFIX_ID}-Slz-Pagination`)}
        data-testid="slz-pagination">
        <HStack justifyContent="center" gap={1}>
          {showTitle && (
            <Flex>
              <chakra.label
                mb={0}
                fontWeight="semibold"
                fontSize="1rem"
                data-testid="slz-pagination-title">
                {title ?? PAGINATION_TITLE}
              </chakra.label>
            </Flex>
          )}
          <HStack justifyContent="center" gap={2.5}>
            {isShowPages && pages && <Pages {...pages} />}
            {isShowDirection && direction && <Direction {...direction} />}
          </HStack>
        </HStack>
      </Box>
    </StylesProvider>
  );
};

export default SlzPagination;
