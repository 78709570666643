import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { DELIVERY_DESTINATION } from 'modules/dashboard/constants';
import DeliveryDestinationChart from './DeliveryDestinationChart';
import ChartLayout from './ChartLayout';

const DeliveryDestination = ({ data = {}, onReload, isLoading, isEmpty = false, ...rest }) => {
  const isShowEmptyChart = isEmpty && !isLoading;
  return (
    <VStack
      shadow="slz-4md"
      borderRadius="0.375rem"
      h="10.9375rem"
      bg={isEmpty && !isLoading ? 'light.500' : 'white'}
      spacing={0}
      {...rest}>
      <ChartLayout
        onReload={onReload}
        isSuccess={!isShowEmptyChart}
        layout="horizontal"
        pl="1.625rem"
        pr="3rem">
        <FullDataChart data={data} isLoading={isLoading} />
      </ChartLayout>
    </VStack>
  );
};

const FullDataChart = ({ data, isLoading }) => {
  return (
    <VStack w="100%" h="100%" px={3} py={4} spacing={0} alignItems="flex-start">
      <Box pl={8}>
        <Text fontSize="0.875rem" lineHeight="1.1875rem" fontWeight="bold">
          Delivery destinations
        </Text>
        <HStack spacing={6} mt={2} mb={-3} sx={{ 'div:last-child': { width: '6rem' } }}>
          {DELIVERY_DESTINATION.map((item) => (
            <HStack spacing={1} key={item.field}>
              <Box w={3} h={3} bg={item.background} />
              <Text fontSize="0.625rem" fontWeight="medium">
                {item.legendLabel}
              </Text>
            </HStack>
          ))}
        </HStack>
      </Box>
      <DeliveryDestinationChart data={data} isLoading={isLoading} />
    </VStack>
  );
};

export default DeliveryDestination;
