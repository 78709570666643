import React from 'react';
import { Icon } from '@chakra-ui/react';

const ConsignmentsIcon = ({ color = '#303134', ...props }) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      color={color}
      {...props}>
      <g id="Group_33673" data-name="Group 33673" transform="translate(-10369.188 6903.767)">
        <g id="tabler-icon-packages" transform="translate(10369.188 -6903.767)">
          <path id="Path_9908" data-name="Path 9908" d="M0,0H21.43V21.43H0Z" fill="none" />
          <path
            id="Path_9909"
            data-name="Path 9909"
            d="M6.358,21.554a.893.893,0,0,1-.893-.893V16.256L1.434,13.837a.893.893,0,0,1,0-1.531L5.9,9.627a.893.893,0,0,1,.919,0l4.465,2.679a.893.893,0,0,1,.434.766v4.911a.893.893,0,0,1-.434.766L6.817,21.427A.892.892,0,0,1,6.358,21.554ZM3.628,13.072l3.188,1.913a.893.893,0,0,1,.434.766v3.334l2.679-1.607v-3.9L6.358,11.434Z"
            transform="translate(-0.107 -1.017)"
            fill="currentColor"
          />
          <path
            id="Path_9910"
            data-name="Path 9910"
            d="M6.357,21.876a.889.889,0,0,1-.459-.127L1.434,19.07A.893.893,0,0,1,1,18.3V13.393a.893.893,0,1,1,1.786,0V17.8l4.031,2.419a.893.893,0,0,1-.46,1.659Z"
            transform="translate(-0.107 -1.338)"
            fill="currentColor"
          />
          <path
            id="Path_9911"
            data-name="Path 9911"
            d="M6.894,17.007A.893.893,0,0,1,6.43,15.35l4.465-2.706a.893.893,0,1,1,.926,1.527L7.356,16.877A.889.889,0,0,1,6.894,17.007Z"
            transform="translate(-0.642 -1.34)"
            fill="currentColor"
          />
          <path
            id="Path_9912"
            data-name="Path 9912"
            d="M16.358,21.554a.893.893,0,0,1-.893-.893V16.256l-4.031-2.419a.893.893,0,0,1,0-1.531L15.9,9.627a.893.893,0,0,1,.919,0l4.465,2.679a.893.893,0,0,1,.434.766v4.911a.893.893,0,0,1-.434.766l-4.465,2.679A.892.892,0,0,1,16.358,21.554Zm-2.729-8.483,3.188,1.913a.893.893,0,0,1,.434.766v3.334l2.679-1.607v-3.9l-3.572-2.143Z"
            transform="translate(-1.178 -1.017)"
            fill="currentColor"
          />
          <path
            id="Path_9913"
            data-name="Path 9913"
            d="M16.357,22.465a.889.889,0,0,1-.459-.127l-4.465-2.679a.893.893,0,0,1,.919-1.531l4.465,2.679a.893.893,0,0,1-.46,1.659Z"
            transform="translate(-1.178 -1.927)"
            fill="currentColor"
          />
          <path
            id="Path_9914"
            data-name="Path 9914"
            d="M16.894,16.965a.893.893,0,0,1-.46-1.659L20.9,12.627a.893.893,0,1,1,.919,1.531l-4.465,2.679A.889.889,0,0,1,16.894,16.965Z"
            transform="translate(-1.713 -1.338)"
            fill="currentColor"
          />
          <path
            id="Path_9915"
            data-name="Path 9915"
            d="M11.358,13.054a.893.893,0,0,1-.893-.893V7.756L6.434,5.337a.893.893,0,0,1,0-1.531L10.9,1.127a.893.893,0,0,1,.919,0l4.465,2.679a.893.893,0,0,1,.434.766V9.483a.893.893,0,0,1-1.786,0V5.077L11.358,2.934,8.628,4.572l3.188,1.913a.893.893,0,0,1,.434.766v4.911A.893.893,0,0,1,11.358,13.054Z"
            transform="translate(-0.642 -0.107)"
            fill="currentColor"
          />
          <path
            id="Path_9916"
            data-name="Path 9916"
            d="M6.893,10.687A.893.893,0,0,1,6,9.794V4.923a.893.893,0,0,1,1.786,0V9.794A.893.893,0,0,1,6.893,10.687Z"
            transform="translate(-0.642 -0.432)"
            fill="currentColor"
          />
          <path
            id="Path_9917"
            data-name="Path 9917"
            d="M11.894,8.465a.893.893,0,0,1-.46-1.659L15.9,4.127a.893.893,0,1,1,.919,1.531L12.352,8.337A.889.889,0,0,1,11.894,8.465Z"
            transform="translate(-1.178 -0.428)"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
};

export default ConsignmentsIcon;
