export const UPDATE_PRODUCTS_ACTIONS = {
  REPLACE: 'REPLACE',
  OPEN_CONFIRM_MODAL: 'OPEN_CONFIRM_MODAL',
  CANCEL_CONFIRM_MODAL: 'CANCEL_CONFIRM_MODAL',
  CLOSE_CONFIRM_MODAL: 'CLOSE_CONFIRM_MODAL',
  FILE_SIZE_ERROR: 'FILE_SIZE_ERROR',
  UPLOADING_IMAGE: 'UPLOADING_IMAGE',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  LOAD_FILE_ERROR: 'LOAD_FILE_ERROR'
};
