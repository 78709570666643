import { Alert } from '@chakra-ui/react';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

const SlzAlert = (props) => {
  return (
    <Alert id={uniqueId('SEL-Slz-Alert')} data-testid="slz-alert" {...props}>
      {props.children}
    </Alert>
  );
};

export default SlzAlert;
