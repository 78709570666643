import { Icon } from '@chakra-ui/react';
import React from 'react';

const UserIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.125rem"
      height="1.125rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33478" data-name="Group 33478" transform="translate(-92.776 -2243.551)">
        <circle
          id="Ellipse_47"
          data-name="Ellipse 47"
          cx="9"
          cy="9"
          r="9"
          transform="translate(93.776 2244.551)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_48"
          data-name="Ellipse 48"
          cx="3"
          cy="3"
          r="3"
          transform="translate(95.665 2247.107)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_3574"
          data-name="Path 3574"
          d="M94.262,2256.908a3.987,3.987,0,0,1,2.4-.8h4a4,4,0,0,1,3.834,2.855"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_49"
          data-name="Ellipse 49"
          cx="2.904"
          cy="2.904"
          r="2.904"
          transform="translate(104.093 2249.758)"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1.936"
        />
        <path
          id="Path_3575"
          data-name="Path 3575"
          d="M101.166,2261.289A4,4,0,0,1,105,2258.44h5.1l-5.555,3.095Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="2"
        />
      </g>
    </Icon>
  );
};

export default UserIcon;
