import React, { useEffect, useState } from 'react';

import { HStack, TableContainer } from '@chakra-ui/react';

import SlzTable from 'components/common/SlzTable/Table';
import Toolbar from 'components/common/SlzTable/Toolbar';
import {
  getB2BProductSelectedColumns,
  getB2BProductSelectionColumns
} from 'modules/b2b-orders/components/table/bulk-order-product-selection.columns';
import { useBulkOrderProductQuey } from 'modules/b2b-orders/hooks/useBulkOrderProductQuery';
import { B2B_PRODUCT } from 'modules/b2b-orders/constants';
import { transformProducts } from 'modules/b2b-orders/mappers/bulk-order-product-mapper';
import { B2B_PRODUCT_SELECTION_TABLE_STYLE } from 'modules/b2b-orders/constants/styles';

const B2BProductSelectionTable = ({
  isLoading,
  selectedProducts,
  searchText = '',
  onSelectedProduct,
  onSelectedQuantity,
  onCloseConfirmQuantity
}) => {
  const [enableQuery, setEnableQuery] = useState(false);
  const [pageSize, setPageSize] = useState(B2B_PRODUCT.ITEM_PER_PAGES);
  const [pageIndex, setPageIndex] = useState(B2B_PRODUCT.PAGE_DEFAULT);
  const [totalPage, setTotalPage] = useState(0);
  const [products, setProducts] = useState([]);

  const { data } = useBulkOrderProductQuey({
    filters: { pageSize, pageIndex, pagingRequired: true, searchText },
    select: transformProducts,
    enabled: enableQuery
  });

  useEffect(() => {
    setEnableQuery(true);
  }, [pageSize, pageIndex, searchText]);

  useEffect(() => {
    if (data?.totalPage > 0) {
      setTotalPage(data?.totalPage);
    }
  }, [data?.totalPage]);

  useEffect(() => {
    setProducts(() => {
      return data?.products?.map((item) => {
        const product = { ...item };
        if (selectedProducts) {
          const selectedItem = selectedProducts?.find(
            (selectedProduct) =>
              selectedProduct?.companyItemID === item?.companyItemID &&
              selectedProduct?.sku === item?.sku
          );
          product.isSelected = !!selectedItem;
          const selectedQuantity = selectedItem?.quantity || 0;
          product.totalStock = Math.max(product.totalStock - selectedQuantity, 0);
        }
        return product;
      });
    });
  }, [data?.products, selectedProducts]);

  const pagination = {
    isLocal: false,
    pages: {
      onChange: setPageSize,
      pageOptions: B2B_PRODUCT.PAGES_OPTIONS_DEFAULT,
      currentItemPerPage: pageSize || '',
      itemDisplay: 'items'
    },
    direction: {
      usingDots: false,
      isGreyBg: true,
      totalPage: totalPage,
      currentPage: pageIndex,
      onChange: setPageIndex
    },
    showTitle: false
  };

  return (
    <>
      <TableContainer sx={{ overflow: 'visible' }}>
        <Toolbar
          variant="productSelection"
          showToolbar={true}
          pagination={pagination}
          showFilterTitle={false}
          paginationProps={pagination}
          height="2.5rem"
          padding="0"
          borderRadius="0.188rem !important"
          sx={{ ps: 0 }}
        />
        <HStack alignItems="start" justifyContent="space-between">
          <SlzTable
            isLoading={isLoading}
            sx={{
              ...B2B_PRODUCT_SELECTION_TABLE_STYLE,
              '.emphasized-cell': { pr: 0 },
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              'th:nth-child(3)': {
                paddingLeft: '0 !important'
              },
              'td:nth-child(3) p': {
                fontSize: '2xs'
              }
            }}
            tableWidth="25rem"
            overflowX="unset"
            overflowY="scroll"
            showBoxShadow={false}
            variant="productSelected"
            sizes={['sm', 'md', 'lg']}
            showMarked={false}
            showHeader={true}
            showToolbar={false}
            columns={getB2BProductSelectionColumns({ onSelectedProduct })}
            data={products || []}
            displayFields={['image', 'name', 'totalStock', 'action']}
            autoResize={false}
          />

          <SlzTable
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              ...B2B_PRODUCT_SELECTION_TABLE_STYLE,
              th: {
                textAlign: 'start',
                paddingLeft: '1rem !important'
              }
            }}
            overflowX="unset"
            overflowY="scroll"
            showBoxShadow={false}
            variant="selectedBundle"
            sizes={['sm', 'md', 'lg']}
            showMarked={false}
            showHeader={true}
            showToolbar={false}
            autoResize={false}
            columns={getB2BProductSelectedColumns({ onCloseConfirmQuantity, onSelectedQuantity })}
            data={selectedProducts}
            displayFields={['image', 'name', 'quantity']}
            emptyMsg="Your product selection will appear on this side"
          />
        </HStack>
      </TableContainer>
    </>
  );
};

export default B2BProductSelectionTable;
