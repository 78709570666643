import { PAYMENT_CARD_STATUS, CREDIT_CARD_TYPE } from '../../constants/credit-card';

export const cardInformation = {
  customerName: 'James Smith',
  email: '**********@selazar.com',
  expiredDate: '03/30',
  type: CREDIT_CARD_TYPE.PAYPAL,
  status: PAYMENT_CARD_STATUS.SUCCESSFUL
};
export const cardInformation1 = {
  customerName: 'James Smith',
  expiredDate: '03/30',
  cardNumber: '**** **** **** 1111',
  type: CREDIT_CARD_TYPE.VISA,
  status: PAYMENT_CARD_STATUS.SUCCESSFUL
};
export const cardInformation2 = {
  customerName: 'James Smith',
  expiredDate: '03/30',
  cardNumber: '**** **** **** 1111',
  type: CREDIT_CARD_TYPE.MASTERCARD,
  status: PAYMENT_CARD_STATUS.SUCCESSFUL
};
export const cardInformation3 = {
  customerName: 'James Smith',
  expiredDate: '03/30',
  cardNumber: '**** **** **** 1111',
  type: CREDIT_CARD_TYPE.AMERICAN_EXPRESS,
  status: PAYMENT_CARD_STATUS.UNSUCCESSFUL
};
