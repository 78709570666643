import { Icon } from '@chakra-ui/react';

const CheckIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32.173"
      viewBox="0 0 32 32.173"
      fill="currentColor"
      {...props}>
      <g id="Group_34341" data-name="Group 34341" transform="translate(-364.85 -635.414)">
        <ellipse
          id="Ellipse_975"
          data-name="Ellipse 975"
          cx="15"
          cy="15.086"
          rx="15"
          ry="15.086"
          transform="translate(365.85 636.414)"
          fill="#0973ea"
          stroke="#0073ea"
          stroke-width="2"
        />
        <path
          id="Back_Stroke_"
          data-name="Back (Stroke)"
          d="M8.047,12.889,7.3,12.142l-.748.748A1.058,1.058,0,0,0,8.047,12.889ZM7.3,10.646,2.406,5.753a1.058,1.058,0,0,0-1.5,1.5l5.641,5.641.748-.748.748.748L18.931,2.006a1.058,1.058,0,1,0-1.5-1.5Z"
          transform="translate(370.93 644.801)"
          fill="#fff"
          fill-rule="evenodd"
        />
      </g>
    </Icon>
  );
};

export default CheckIcon;
