import { Icon } from '@chakra-ui/react';
import React from 'react';

const BundleIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.968 48" {...props}>
      <g id="Group_33249" data-name="Group 33249" transform="translate(1802 6825.136)">
        <rect
          id="sports-balls-800x400"
          width="100%"
          height="100%"
          transform="translate(-1802 -6825.136)"
          fill="#0973ea"
        />
        <g
          id="Ellipse_1218"
          data-name="Ellipse 1218"
          transform="translate(-1798.465 -6821.533)"
          fill="none"
          stroke="#fafafa"
          strokeWidth="2">
          <circle cx="20.45" cy="20.45" r="20.45" stroke="none" />
          <circle cx="20.45" cy="20.45" r="19.45" fill="none" />
        </g>
        <path
          id="Path_9813"
          data-name="Path 9813"
          d="M13,30.175V23.611h2.3a3.019,3.019,0,0,1,1.813.458,1.565,1.565,0,0,1,.618,1.341,1.483,1.483,0,0,1-.248.85,1.432,1.432,0,0,1-.69.539,1.386,1.386,0,0,1,.8.509,1.506,1.506,0,0,1,.291.938,1.732,1.732,0,0,1-.6,1.434,2.72,2.72,0,0,1-1.722.5Zm1.353-2.859v1.772h1.159a1.118,1.118,0,0,0,.746-.228.781.781,0,0,0,.268-.629.824.824,0,0,0-.933-.915Zm0-.956h1q1.023-.018,1.023-.816a.754.754,0,0,0-.259-.642,1.364,1.364,0,0,0-.818-.2h-.947Zm7.376,3.318a1.634,1.634,0,0,1-1.335.586,1.541,1.541,0,0,1-1.2-.451,1.925,1.925,0,0,1-.422-1.321V25.3h1.3v3.152q0,.762.694.762a.941.941,0,0,0,.911-.46V25.3h1.308v4.878H21.765ZM25.174,25.3l.041.564a1.7,1.7,0,0,1,1.4-.654,1.416,1.416,0,0,1,1.154.455,2.125,2.125,0,0,1,.388,1.362v3.152h-1.3v-3.12a.845.845,0,0,0-.18-.6.807.807,0,0,0-.6-.187.9.9,0,0,0-.825.469v3.44h-1.3V25.3Zm3.769,2.4a2.94,2.94,0,0,1,.512-1.817,1.66,1.66,0,0,1,1.4-.676,1.494,1.494,0,0,1,1.177.532V23.25h1.308v6.925H32.162l-.063-.519a1.533,1.533,0,0,1-1.253.609,1.656,1.656,0,0,1-1.382-.679A3.035,3.035,0,0,1,28.943,27.7Zm1.3.095a1.92,1.92,0,0,0,.239,1.051.779.779,0,0,0,.694.365.872.872,0,0,0,.852-.509V26.776a.86.86,0,0,0-.843-.509Q30.246,26.266,30.246,27.795Zm5.478,2.381H34.417V23.25h1.308Zm3.318.09a2.4,2.4,0,0,1-1.747-.658,2.34,2.34,0,0,1-.674-1.754v-.126a2.947,2.947,0,0,1,.284-1.314,2.085,2.085,0,0,1,.8-.893,2.259,2.259,0,0,1,1.188-.313,2.025,2.025,0,0,1,1.576.631,2.565,2.565,0,0,1,.575,1.79v.532H37.942a1.21,1.21,0,0,0,.381.766,1.152,1.152,0,0,0,.8.289,1.406,1.406,0,0,0,1.177-.546l.64.717a1.956,1.956,0,0,1-.794.647A2.6,2.6,0,0,1,39.043,30.266Zm-.149-4a.816.816,0,0,0-.629.262,1.351,1.351,0,0,0-.309.748h1.813v-.1a.963.963,0,0,0-.234-.67A.842.842,0,0,0,38.894,26.262Z"
          transform="translate(-1804.807 -6828.313)"
          fill="#fafafa"
        />
      </g>
    </Icon>
  );
};

export default BundleIcon;
