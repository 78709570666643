export const REQUIRED_SETTINGS_STYLE = {
  title: {
    as: 'h5',
    fontSize: 'md',
    mb: '1.75rem',
    mt: '2rem'
  },
  description: {
    fontSize: 'md',
    mb: '0.563rem'
  },
  card: {
    bg: 'light.500',
    boxShadow: 'slz-md',
    borderRadius: '0.75rem',
    alignItems: 'center',
    py: '1rem',
    pl: '1.125rem',
    pr: '1.563rem'
  }
};
