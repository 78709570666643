import React, { useEffect, useState } from 'react';

import { Box, HStack, Skeleton, TableContainer, Td, Tr } from '@chakra-ui/react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';

import Toolbar from 'components/common/SlzTable/Toolbar';
import SlzTable from 'components/common/SlzTable/Table';
import ProductSelect from '../other/ProductSelect';
import { PRODUCT_SELECTION_COLUMNS, YOUR_BUNDLE_SELECTED_COLUMNS } from '../../_mock';

import {
  AUTO_COMPLETE_SKELETONS,
  USING_SKELETON_FEATURES
} from 'components/common/Skeleton/constants';
import { SCROLL_BAR_STYLE } from 'modules/products/constants/constants.style';
import { DEFAULT_EMPTY_MSG } from 'components/common/SlzTable/Constants';

const ProductTableSkeleton = () => (
  <>
    {[...Array(10)].map((_, rowIndex) => (
      <Tr key={`tr-${USING_SKELETON_FEATURES.PRODUCT.BUNDLE_WORKFLOW}-${rowIndex}`}>
        {AUTO_COMPLETE_SKELETONS[USING_SKELETON_FEATURES.PRODUCT.BUNDLE_WORKFLOW].map(
          ({ height, p, pl, colSpan, ...rest }, tdIndex) => (
            <Td
              key={`td-${USING_SKELETON_FEATURES.PRODUCT.BUNDLE_WORKFLOW}-${tdIndex}`}
              h={height}
              colSpan={colSpan}
              p={p}
              pl={pl}>
              <Skeleton height={height} {...rest}></Skeleton>
            </Td>
          )
        )}
      </Tr>
    ))}
  </>
);

const ProductSelectionTable = ({
  isLoading,
  isFetching,
  filters,
  products,
  pagination,
  selectedProducts,
  isEditMode,
  onSelectProduct,
  onSelectQuantity
}) => {
  const [items, setItems] = useState(products);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    setItems(products);
  }, [products]);

  useEffect(() => {
    const valuesFilters = map(selectedFilters, (filterValue) => lowerCase(filterValue?.key));

    if (isEmpty(valuesFilters)) {
      return setItems(products);
    }

    const filteredItems = products.filter((product) => valuesFilters.includes(product?.tag?.name));
    setItems(filteredItems);
  }, [selectedFilters]);

  useEffect(() => {
    setItems(() => {
      return products?.map((item) => {
        if (selectedProducts) {
          const selectedItemIndex = selectedProducts.findIndex(({ companyItemID, skuCode }) => {
            return companyItemID === item.companyItemID && skuCode === item.sku;
          });
          item.isSelected = selectedItemIndex !== -1;
        }
        return { ...item };
      });
    });
  }, [products, selectedProducts]);

  const handleSelectedFilter = (selectedFilters) => {
    if (filters?.isLocal) {
      setSelectedFilters(selectedFilters);
      return;
    }
    filters?.handleSelectedFilter(selectedFilters);
  };

  const getTableSize = () => ({
    w: isEditMode ? '387px' : '407px',
    maxW: isEditMode ? '387px' : '407px',
    h: 'fit-content',
    maxH: '510px'
  });

  const yourBundleSelectedColumns = [...YOUR_BUNDLE_SELECTED_COLUMNS];
  yourBundleSelectedColumns[2] = {
    name: 'Quantity',
    field: 'quantity',
    render: ({ row }) => (
      <Box w="full">
        <ProductSelect row={row} onChange={onSelectQuantity} />
      </Box>
    )
  };

  return (
    <TableContainer>
      <Toolbar
        filters={filters}
        showToolbar={true}
        pagination={pagination}
        showFilterTitle={false}
        paginationProps={pagination}
        onFilter={handleSelectedFilter}
        height="2.5rem"
        padding="0"
        sx={{ ps: 0 }}
      />
      <HStack alignItems="top" spacing="0.75rem">
        <SlzTable
          isLoading={isLoading}
          sx={{
            ...getTableSize(),
            '.emphasized-cell': { pr: 0 },
            ...SCROLL_BAR_STYLE,
            'tr:has(div.selected-product)': {
              bg: 'main.300'
            }
          }}
          preLoadersSkeleton={isFetching && ProductTableSkeleton}
          tableWidth="400px"
          overflowX="unset"
          overflowY="scroll"
          showBoxShadow={false}
          variant="productSelected"
          sizes={['sm', 'md', 'lg']}
          showMarked={true}
          showHeader={true}
          showToolbar={false}
          columns={PRODUCT_SELECTION_COLUMNS}
          data={items}
          displayFields={['image', 'name', 'tag']}
          autoResize={false}
          onSelectedRow={onSelectProduct}
        />
        <SlzTable
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            ...getTableSize()
          }}
          overflowX="unset"
          overflowY="scroll"
          showBoxShadow={false}
          variant="selectedBundle"
          sizes={['sm', 'md', 'lg']}
          showMarked={false}
          showHeader={true}
          emptyMsg={DEFAULT_EMPTY_MSG}
          showToolbar={false}
          autoResize={false}
          columns={yourBundleSelectedColumns}
          data={selectedProducts}
          displayFields={['image', 'name', 'quantity']}
        />
      </HStack>
    </TableContainer>
  );
};

export default ProductSelectionTable;
