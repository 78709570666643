import { Card, HStack, Text } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Fragment } from 'react';

import SlzToolTip from 'components/common/SlzToolTip';

import { CONSIGNMENT_LABELLING_STYLES } from 'modules/consignments/constants/styles';
import { TagIcon } from 'modules/consignments/components/modal/send/assets';

const ConsigmentCard = ({
  showTooltip = false,
  title = '',
  Icon = TagIcon,
  wrapperContentStyle,
  children,
  ...rest
}) => {
  return (
    <>
      <Card {...CONSIGNMENT_LABELLING_STYLES.title} mt="8px">
        <HStack px="1.5rem">
          <Icon width="1.25rem" height="1.25rem" />
          <Text {...CONSIGNMENT_LABELLING_STYLES.text}>
            <b>{title}</b>
          </Text>
          {showTooltip && (
            <SlzToolTip content="" variant="blue">
              <InfoOutlineIcon boxSize="3" color="main.500" />
            </SlzToolTip>
          )}
        </HStack>
      </Card>
      <Card {...CONSIGNMENT_LABELLING_STYLES.wrapperCard} {...rest}>
        {children || Fragment}
      </Card>
    </>
  );
};

export default ConsigmentCard;
