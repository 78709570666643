import { Icon, Search2Icon } from '@chakra-ui/icons';
import { IoClose } from 'react-icons/io5';
import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useMultiStyleConfig
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

const ENTER_KEY = 'Enter';

const SlzSearchInput = (props) => {
  const { clearable, isResetEnable, onTyping, onSearch, onReset, initValue, ...rest } = props;
  const [value, setValue] = useState(initValue || '');
  const styles = useMultiStyleConfig('SlzSearchInput', { size: props.size });

  const resetValue = () => {
    setValue('');
    onTyping && onTyping('');
  };

  const onChange = (e) => {
    setValue(e.target.value);
    onTyping && onTyping(e.target.value);
  };

  useEffect(() => {
    if (value === '') onReset && onReset();
  }, [value]);

  useEffect(() => {
    isResetEnable === true && resetValue();
  }, [isResetEnable]);

  return (
    <InputGroup
      id={uniqueId('SEL-Slz-SearchInput')}
      size={props.size}
      w={props?.w || props?.width || 'full'}>
      <Input
        w="full"
        value={value}
        onChange={onChange}
        sx={{ ...rest.customInputStyles }}
        {...rest}
        onKeyDown={(e) => e.code === ENTER_KEY && onSearch?.(value)}
      />
      <InputRightElement
        w="auto"
        h="full"
        px="1"
        children={
          <HStack spacing="1.5">
            {clearable && (
              <IconButton
                icon={<Icon as={IoClose} />}
                sx={styles.clearButton}
                onClick={resetValue}
              />
            )}
            <IconButton
              role="button"
              icon={<Search2Icon sx={styles.searchIcon} />}
              sx={styles.searchButton}
              onClick={() => onSearch && onSearch(value)}
            />
          </HStack>
        }
      />
    </InputGroup>
  );
};

export default SlzSearchInput;

SlzSearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onTyping: PropTypes.func,
  onSearch: PropTypes.func
};
