import { useEffect } from 'react';
import { Flex, HStack, RadioGroup, StackItem, Text, VStack, useBoolean } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { SlzPaper } from 'components/common/SlzPaper';
import { HeadphoneIcon } from 'components/common/Icons';
import { SlzToggle } from 'components/common/SlzToggle';
import { SlzButton } from 'components/common/SlzButton';
import { SlzRadio } from 'components/common/SlzRadio';
import { useBulkOrderServiceTimesQuery } from 'modules/b2b-orders/hooks/useBulkOrderServiceTimesQuery';

const CourierOptionsHeader = (props) => {
  const { header, isLoading, isLoadingServiceTimes, onToggle, onChange, serviceTime, ...rest } =
    props;

  const handleChange = (e) => {
    onToggle(e.target.checked);
    onChange(e.target.checked ? serviceTime : 'noPreference');
  };

  return (
    <HStack p="1.25rem 0 0.75rem 1.25rem" alignItems="center" spacing={4} {...rest}>
      <StackItem>
        <HeadphoneIcon w="27px" h="30px" />
      </StackItem>
      <StackItem>
        <Text>{header}</Text>
      </StackItem>
      <StackItem>
        <SlzToggle isDisabled={isLoadingServiceTimes || isLoading} onChange={handleChange} mb={0} />
      </StackItem>
    </HStack>
  );
};

const SelectServiceTimes = (props) => {
  const { header, options = [], isDisabled, serviceTime, handleChangeServiceTime, ...rest } = props;

  useEffect(() => {
    handleChangeServiceTime(serviceTime);
  }, [serviceTime]);

  return (
    <VStack alignItems="flex-start" pl={5} mb={4} opacity={isDisabled && 0.5} {...rest}>
      <Text as="i">{header}</Text>
      <RadioGroup w="full" isDisabled={isDisabled} {...rest}>
        <Flex w="full" height="9.375rem" rowGap={1} wrap={'wrap'} flexDirection="column">
          {options.length > 0 &&
            options.map(({ value, label }) => (
              <SlzRadio key={value} value={value} w={'50%'}>
                {label}
              </SlzRadio>
            ))}
        </Flex>
      </RadioGroup>
    </VStack>
  );
};

const B2BCourierOptionsShippingForm = (props) => {
  const {
    control,
    handleChangeServiceTime,
    fetchShippingOption,
    isLoading,
    order,
    watch,
    title,
    ...rest
  } = props;
  const { country } = order.destinationAddress;
  const watchServiceTime = watch('serviceTimePreference');

  const [isToggle, setIsToggle] = useBoolean(true);

  const { isLoading: isLoadingServiceTimes, data: serviceTimeOptions } =
    useBulkOrderServiceTimesQuery(country);

  return (
    <SlzPaper {...rest}>
      <Controller
        name="serviceTimePreference"
        control={control}
        render={({ field }) => (
          <CourierOptionsHeader
            fontSize="1rem"
            header="Override default courier preferences for this order"
            isLoading={isLoading}
            serviceTime={watchServiceTime}
            isLoadingServiceTimes={isLoadingServiceTimes}
            onChange={field?.onChange}
            onToggle={setIsToggle.toggle}
          />
        )}
      />

      <Controller
        name="serviceTimePreference"
        control={control}
        render={({ field }) => (
          <SelectServiceTimes
            header="Select a service time for this order"
            h="10rem"
            isDisabled={isToggle}
            serviceTime={watchServiceTime}
            handleChangeServiceTime={handleChangeServiceTime}
            isLoading={isLoadingServiceTimes}
            options={serviceTimeOptions}
            onChange={field?.onChange}
            value={field?.value}
          />
        )}
      />

      <Flex
        w="full"
        h="3.375rem"
        borderColor="light.700"
        borderTopWidth={0.25}
        justifyContent="flex-end"
        alignItems="center">
        <SlzButton mr={5} borderRadius={1.5} isDisabled={isLoading} onClick={fetchShippingOption}>
          Find Courier Options
        </SlzButton>
      </Flex>
    </SlzPaper>
  );
};

export default B2BCourierOptionsShippingForm;
