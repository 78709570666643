import { SlzButton } from 'components/common/SlzButton';
import { RATE_MATCHER_SETTING_STEP } from '../../constants';
import { useFormContext } from 'react-hook-form';
const StepOneCTAButtons = ({ onSubmit, onNextStep, clearErrors }) => {
  const { getValues } = useFormContext();
  return (
    <>
      <SlzButton
        size="sm"
        marginRight="1rem"
        variant="solid"
        color="negative.500"
        colorScheme="light"
        onClick={() => {
          onNextStep(RATE_MATCHER_SETTING_STEP.step2, getValues());
          onSubmit();
        }}>
        Ignore this
      </SlzButton>
      <SlzButton
        size="sm"
        variant="outline"
        colorScheme="light"
        color="light.300"
        onClick={() => clearErrors()}>
        Configure
      </SlzButton>
    </>
  );
};

export default StepOneCTAButtons;
