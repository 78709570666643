import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import { SlzPaper } from 'components/common/SlzPaper';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { RETURN_DETAIL_OVERVIEW_STYLE } from 'modules/returns/constants/styles';
import { RETURN_DETAIL_TEXT } from 'modules/returns/constants';
import { getFilterColor, getReturnServiceLabel } from 'modules/returns/utils';

const ReturnDetailOverview = (props) => {
  const { isLoading, data, returnStatus, onViewOrder, ...rest } = props;
  const returnServiceLabel = getReturnServiceLabel(data?.returnService);
  const returnServiceColor = getFilterColor(data?.returnService);
  const statusColor = getFilterColor(returnStatus);
  return (
    <SlzPaper w="full" h="3.75rem" bg="light.500" borderRadius={1.5} {...rest}>
      <HStack {...RETURN_DETAIL_OVERVIEW_STYLE} borderColor={statusColor}>
        {isLoading ? (
          <Skeleton width="full" height="full"></Skeleton>
        ) : (
          <>
            <Flex justifyContent="space-between">
              <Box w="26rem">
                <Text fontSize="lg" fontWeight="bold">
                  {RETURN_DETAIL_TEXT.overview.orderId} {data?.orderReference}
                </Text>
                <Text fontSize="md">01/04/2022, 16:49:08</Text>
              </Box>
              <Box>
                <Text fontSize="lg" fontWeight="bold">
                  {RETURN_DETAIL_TEXT.overview.returnService}
                </Text>
                <SlzLabel borderRadius={1.5} bg={returnServiceColor}>
                  {returnServiceLabel}
                </SlzLabel>
              </Box>
            </Flex>
            <Flex flex={2} justifyContent="flex-end">
              <SlzButton borderRadius={1.5} onClick={onViewOrder}>
                {RETURN_DETAIL_TEXT.overview.orderButton}
              </SlzButton>
            </Flex>
          </>
        )}
      </HStack>
    </SlzPaper>
  );
};

export default ReturnDetailOverview;
