import { HStack, Text } from '@chakra-ui/react';
import { SlzTag } from 'components/common/SlzTag';
import { BULK_ORDER_CREATE_TEXT } from 'modules/b2b-orders/constants';

const B2BOrderNote = ({ noteContent, ...rest }) => {
  return (
    <HStack {...rest}>
      <SlzTag size="lg" fontWeight="normal">
        {BULK_ORDER_CREATE_TEXT.OVERVIEW.note}
      </SlzTag>
      <Text fontSize="md">{noteContent}</Text>
    </HStack>
  );
};

export default B2BOrderNote;
