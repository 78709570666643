import { Fade, Stack } from '@chakra-ui/react';
import AddImagesForm from './AddImagesForm';
import Upload from './Upload';

const AddFileUploadForm = ({ csvFiles, isUploaded, setCsvFiles }) => {
  return (
    <Stack>
      {isUploaded && (
        <Fade in={isUploaded}>
          <AddImagesForm items={csvFiles} />
        </Fade>
      )}
      {!isUploaded && (
        <Fade in={!isUploaded}>
          <Upload csvFiles={csvFiles} setCsvFiles={setCsvFiles} />
        </Fade>
      )}
    </Stack>
  );
};

export default AddFileUploadForm;
