import { Fragment } from 'react';
import { GridItem } from '@chakra-ui/react';

import { SlzTimelineStepper } from 'components/common/SlzTimelineStepper';
import OrderSkeleton from 'components/common/Skeleton/templates/order';
import SetupRequirement from 'components/common/SetupRequirement';

import { BULK_ORDER_STEPS } from 'modules/b2b-orders/constants';
import { B2BOrderModalHeader } from 'modules/b2b-orders/components/other';

export const CreateB2BOrderStep = ({ currentStep, ...rest }) => {
  const StepComponent = BULK_ORDER_STEPS[currentStep]?.component || Fragment;
  return <StepComponent currentStep={currentStep} {...rest} />;
};

const CreateB2BOrderModalLayout = (props) => {
  const {
    defaultValues,
    currentStep,
    recipient,
    isSubmitted,
    onJumpToStep,
    onSaveValues,
    onPrevious,
    onNext,
    onCancel,
    onBack,
    setIsFormDirty,
    setCurrentStep,
    setLayoutKey,
    setRecipient
  } = props;

  return (
    <Fragment>
      <GridItem gridColumn="2/12" mb="3" mt={3.5}>
        <B2BOrderModalHeader onBack={onBack} />
      </GridItem>

      <GridItem gridColumn="2/12" mb={10} px={8} mt={3.5}>
        <SlzTimelineStepper
          steps={BULK_ORDER_STEPS.slice(1)}
          disabled={true}
          step={currentStep}
          onCurrentStepChange={setCurrentStep}
          labelStyles={{
            fontSize: 'md'
          }}
        />
      </GridItem>

      <GridItem gridColumn="2/12" mt={0.5}>
        <SetupRequirement.RequirementValidation>
          {({ isRequiredSettingsFetching }) => (
            <OrderSkeleton isLoaded={!isRequiredSettingsFetching} template="b2b.overview">
              <CreateB2BOrderStep
                defaultValues={defaultValues}
                isSubmitted={isSubmitted}
                currentStep={currentStep}
                recipient={recipient}
                onCancel={onCancel}
                onPrevious={onPrevious}
                onNext={onNext}
                onJumpToStep={onJumpToStep}
                onSaveValues={onSaveValues}
                setCurrentStep={setCurrentStep}
                setLayoutKey={setLayoutKey}
                setRecipient={setRecipient}
                setIsFormDirty={setIsFormDirty}
              />
            </OrderSkeleton>
          )}
        </SetupRequirement.RequirementValidation>
      </GridItem>
    </Fragment>
  );
};
export default CreateB2BOrderModalLayout;
