export const transformAccordionItems = (data) => {
  const products = data?.boxSplit?.boxes.flatMap((box) => box.items);

  const productsWithNoDuplicate = products.reduce((acc, obj) => {
    const key = obj['identifier'];
    const mergedQuantity = (acc[key]?.quantity ?? 0) + obj.quantity;
    const product = {
      ...obj,
      quantity: mergedQuantity,
      weight: 10, //mock
      price: 90 // mock
    };
    return { ...acc, [key]: product };
  }, {});

  return Object.values(productsWithNoDuplicate);
};
