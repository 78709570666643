import { Box, Flex, Spacer } from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import { SEL_PREFIX_ID } from '../Constants';
import React from 'react';
import { SlzFormLabel } from '../SlzFormLabel';

const SlzFlexBoxView = (props) => {
  return (
    <Box>
      {props?.label && <SlzFormLabel {...props.propsLabel}>{props.label}</SlzFormLabel>}
      <Flex
        sx={{
          py: 1,
          px: 2.5,
          h: 7.5
        }}
        id={uniqueId(`${SEL_PREFIX_ID}-Slz-FlexBoxView`)}
        {...props}>
        <Box>{props?.leftComponent}</Box>
        <Spacer />
        <Box>{props?.rightComponent}</Box>
      </Flex>
    </Box>
  );
};

export default SlzFlexBoxView;
