import { HStack, Stack, VStack, Box, Text } from '@chakra-ui/react';
import SlzUserAvatar from '../../components/common/SlzUserAvatar/SlzUserAvatar';

import {
  SkeletonCircle,
  SkeletonPageTemplate,
  SkeletonModalTemplate,
  SkeletonText,
  SlzSkeletonDefault
} from '../../components/common/Skeleton/Skeleton';
import SlzSkeleton from '../../components/common/SlzSkeleton/SlzSkeleton';

const Skeletons = () => (
  <Stack py="8">
    <Text as="h1" fontSize="2xl" fontWeight={600}>
      Skeletons
    </Text>
    <SlzSkeleton type="page" />
    <SlzSkeleton type="modal" />
    <SlzSkeleton type="table" />
    <SkeletonPageTemplate my={5}></SkeletonPageTemplate>
    <Box py={5}>
      <SkeletonCircle size="10"></SkeletonCircle>
      <SkeletonText mt="4" noOfLines={4} spacing="4" />
      <SkeletonPageTemplate my={5}></SkeletonPageTemplate>
      <SkeletonModalTemplate my={5}></SkeletonModalTemplate>
    </Box>
    <SlzSkeletonDefault />
  </Stack>
);

const UserAvatar = () => {
  return (
    <Stack py="8">
      <Text as="h1" fontSize="2xl" fontWeight={600}>
        User Avatar
      </Text>
      <HStack alignItems="flex-start">
        <VStack>
          <SlzUserAvatar size="sm" title="Kola Tioluwani" subtitle="Developer" />
          <SlzUserAvatar size="sm" variant="square" title="Kola Tioluwani" subtitle="Developer" />
        </VStack>
        <VStack>
          <SlzUserAvatar
            src="https://bit.ly/dan-abramov"
            size="md"
            title="Dan Abrahmov"
            subtitle="Developer"
          />
          <SlzUserAvatar
            src="https://bit.ly/dan-abramov"
            size="md"
            variant="square"
            title="Dan Abrahmov"
            subtitle="Developer"
          />
        </VStack>
        <VStack>
          <SlzUserAvatar
            src="https://bit.ly/ryan-florence"
            size="lg"
            title="Ryan Florence"
            subtitle="Developer"
          />
          <SlzUserAvatar
            src="https://bit.ly/ryan-florence"
            size="lg"
            variant="square"
            title="Ryan Florence"
            subtitle="Developer"
          />
        </VStack>
      </HStack>
    </Stack>
  );
};

const ElementFour = () => {
  return (
    <Stack my={5} w="full">
      <Skeletons />
      <UserAvatar />
    </Stack>
  );
};

export default ElementFour;
