import { FormControl } from '@chakra-ui/react';

import { mapAvailableServiceTimesList } from 'modules/integrations/utils';
import { RateMatcherDropdown } from '../other';
import { MatchedIcon } from '../other/MatchedIcon';
import { REGION_SHIPPING_LABELS } from 'modules/integrations/constants';

export default function DefaultShipping({
  index,
  isEditMode,
  getUniqueServiceKeys,
  serviceTime,
  regionPreference
}) {
  const serviceTimes = Object.assign({}, ...mapAvailableServiceTimesList(serviceTime));
  const { uniqueServiceTimeKeys, isSFP } = getUniqueServiceKeys({
    serviceTimes,
    serviceTime,
    isEditMode
  });
  const options = uniqueServiceTimeKeys.map((uniqueServiceTime) => ({
    label: serviceTimes[uniqueServiceTime],
    value:
      regionPreference?.serviceTime === uniqueServiceTime
        ? regionPreference
        : {
            shippingRateID: null,
            shippingRateName: null,
            shippingRatePrice: null,
            countryRegion: regionPreference?.countryRegion,
            serviceTime: uniqueServiceTime
          },
    isMatched: regionPreference?.serviceTime === uniqueServiceTime,
    showInputIcon: regionPreference?.serviceTime === uniqueServiceTime,
    MatchedIcon
  }));
  return (
    <>
      {!isSFP && (
        <FormControl key={`world-area-${index}`} maxWidth="240px" w="100%">
          <RateMatcherDropdown
            field={`${serviceTime.countryRegion.toLowerCase()}DefaultShipping`}
            label={REGION_SHIPPING_LABELS[serviceTime.countryRegion]}
            options={options}
            placeholder={'Select your shipping rate'}
            isDisabled={isSFP}
          />
        </FormControl>
      )}
    </>
  );
}
