import { useQuery } from 'react-query';
import { checkCreditCard } from '../services/payment-apis';

const useCheckCreditCardQuery = () => {
  return useQuery({
    queryKey: [`check-credit-card`],
    queryFn: () => checkCreditCard(),
    select: (response) => response.data.data
  });
};

export default useCheckCreditCardQuery;
