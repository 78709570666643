import { Fragment, memo, useEffect, useState } from 'react';

import { GridItem } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { SlzGridModal as RateMatcherModal } from 'components/common/SlzModal';
import useSlzToast from 'hooks/useSlzToast';
import { RateMatcherHeader } from 'modules/integrations/components/other';
import {
  CREATE_SHIPPING_RATES_FAILED,
  CREATE_SHIPPING_RATES_SUCCESS,
  RATE_MATCHER_DEFAULT_VALUES,
  RATE_MATCHER_MATCHED_DEFAULT,
  RATE_MATCHER_SETTING_STEP,
  RATE_MATCHER_SETTING_STEP_COMPONENT
} from 'modules/integrations/constants';
import {
  useCurrentPreferences,
  useServiceTimesQuery,
  useShippingRatesQuery
} from 'modules/integrations/hooks';
import { useRateMatcherUtils } from 'modules/integrations/hooks/useRateMatcherUtils';
import { createShippingRatesByIntegrationId } from 'modules/integrations/services/integration-api';
import { getMatchedShippingRates, mapShippingRates } from 'modules/integrations/utils';

const SettingStep = ({ ...rest }) => {
  const RateMatcherStepComponent =
    RATE_MATCHER_SETTING_STEP_COMPONENT[rest?.currentStep] || Fragment;

  return <RateMatcherStepComponent {...rest} />;
};

const RateMatcherSettingModal = ({
  integrationId,
  shopName,
  isOpen,
  onClose,
  shopifyShippingRatePreferences,
  refetch
}) => {
  const [currentStep, setCurrentStep] = useState(RATE_MATCHER_SETTING_STEP.instruction);
  const [rateMatcher, setRateMatcher] = useState(RATE_MATCHER_DEFAULT_VALUES);
  const [numberOfMatchedRate, setNumberOfMatchedRate] = useState(RATE_MATCHER_MATCHED_DEFAULT);

  const [_, setToast] = useSlzToast();
  const { data: shippingRates, isLoading } = useShippingRatesQuery(integrationId, null, isOpen);
  const { data: fetchedServiceTimes } = useServiceTimesQuery(isOpen);
  const { data: fetchedCurrentPreferences } = useCurrentPreferences(isOpen);

  const { isLoaded, regionPreferences, getUniqueServiceKeys } = useRateMatcherUtils({
    integrationId,
    shippingRates,
    shopifyShippingRatePreferences,
    fetchedCurrentPreferences,
    fetchedServiceTimes
  });

  const isEditMode = shopifyShippingRatePreferences?.length !== 0;

  const createShippingRatesMutation = useMutation({
    mutationFn: (payload) => createShippingRatesByIntegrationId(integrationId, payload)
  });

  const handleNextStep = (step, formValues) => handleOnSubmit(step, formValues);
  const handlePrevStep = (step, formValues) => handleOnSubmit(step, formValues);

  const handleOnClose = () => {
    setCurrentStep(RATE_MATCHER_SETTING_STEP.instruction);
    setRateMatcher({});
    setNumberOfMatchedRate(0);
    onClose && onClose();
  };

  const handleOnSubmit = (step, values) => {
    setRateMatcher((prevRateMatcher) => ({
      ...prevRateMatcher,
      ...values
    }));
    setCurrentStep(step);
  };

  const handleOnSave = async (values) => {
    try {
      const shippingRatesPayload = mapShippingRates(values);

      await createShippingRatesMutation.mutateAsync(shippingRatesPayload);
      refetch && refetch();
      handleOnClose();
      setToast({ description: CREATE_SHIPPING_RATES_SUCCESS });
    } catch (error) {
      setToast({
        colorScheme: 'negative',
        title: CREATE_SHIPPING_RATES_FAILED,
        status: 'warning'
      });
    }
  };

  const updateMatchedShippingRates = (shippingRates) => {
    setNumberOfMatchedRate(getMatchedShippingRates(shippingRates || rateMatcher?.shippingRates));
  };

  useEffect(() => {
    if (isLoaded && isOpen) {
      setRateMatcher((prev) => ({
        ...prev,
        ...regionPreferences
      }));
    }
  }, [isLoaded, isOpen]);

  useEffect(() => {
    if (rateMatcher?.shippingRates) {
      updateMatchedShippingRates();
    }
  }, [rateMatcher?.shippingRates]);
  return (
    <RateMatcherModal isOpen={isOpen} onClose={handleOnClose}>
      <GridItem gridColumn="2/12" mb="5" pt="8">
        <RateMatcherHeader shopName={shopName} />
      </GridItem>
      <GridItem gridColumn="2/12" mb="5">
        <SettingStep
          isLoading={isLoading}
          isEditMode={isEditMode}
          defaultValues={rateMatcher}
          shopName={shopName}
          currentStep={currentStep}
          shopifyShippingRatePreferences={shopifyShippingRatePreferences}
          fetchedCurrentPreferences={fetchedCurrentPreferences}
          fetchedServiceTimes={fetchedServiceTimes}
          numberOfMatchedRate={numberOfMatchedRate}
          numberOfShippingRate={shippingRates?.length || 0}
          getUniqueServiceKeys={getUniqueServiceKeys}
          updateMatchedShippingRates={updateMatchedShippingRates}
          onSubmit={handleOnSubmit}
          onPrevStep={handlePrevStep}
          onNextStep={handleNextStep}
          onClose={handleOnClose}
          onSave={handleOnSave}
        />
      </GridItem>
    </RateMatcherModal>
  );
};

export default memo(RateMatcherSettingModal);
