import { Redirect, Route } from 'react-router-dom';

import MainLayout from 'components/layout/MainLayout';
import useAuth from 'hooks/useAuth';
import ForbiddenPage from 'components/layout/ForbiddenPage';

/**
 *
 * Check current user has permission or profile to access the component
 *
 */
const ShouldRedirectToComponent = ({ feature, profile, component: Component, ...rest }) => {
  const { hasPermission, hasProfile } = useAuth();

  let canAccess = hasPermission(feature);

  if (profile) {
    canAccess = hasProfile(profile);
  }
  canAccess = true;
  return canAccess ? <Component {...rest} /> : <ForbiddenPage />;
};

/**
 *
 * Check current user is authenticated or not to render component properly
 *
 */
const ProtectedRoute = ({ component, feature, profile, ...rest }) => {
  const { isLogged } = useAuth();
  const isAuthenticated = isLogged();

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <MainLayout>
            <ShouldRedirectToComponent
              {...props}
              component={component}
              feature={feature}
              profile={profile}
            />
          </MainLayout>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        );
      }}
    />
  );
};

export default ProtectedRoute;
