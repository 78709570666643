import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

import { SlzSplitButton } from 'components/common/SlzButton';
import {
  BUSINESS_MODEL,
  CREATE_ORDER_OPTIONS,
  ORDER_KEY_PARAM
} from 'modules/b2c-orders/constants';
import { CategorizingOrderModal, MultipleOrderB2CModal } from '../modal';
import { CreateB2BOrderModal } from 'modules/b2b-orders/components/modal';
import { usingExternalWMS } from 'modules/b2c-orders/services/orders-api';
import { useEffect,useState } from 'react';

const CreateOrderAction = (props) => {
    const routerQuery = useRouterQuery();
    const history = useHistory(); 
    const [hideButton, setHideButton] = useState([]);

    useEffect(() => {
      const dataApi = async () => {
         const results = await usingExternalWMS();
         setHideButton(results);
       }
      dataApi();
    },[]);

    const handleCreateOrder = () => {
      routerQuery.set(ORDER_KEY_PARAM.IS_CREATE_ORDER_OPTION_MODAL, true);
      history.push({
        search: routerQuery.toString()
      });
    };

    const handleCreateB2COrder = () => {
      routerQuery.set(ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL, true);
      history.push({
        search: routerQuery.toString()
      });
    };

    const handleSelectedItem = (item) => {
      if (item?.value === BUSINESS_MODEL.B2C) {
        routerQuery.set(ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL, true);
      } else {
        routerQuery.set(ORDER_KEY_PARAM.IS_CREATE_B2B_ORDER_MODAL, true);
      }
      history.push({
        search: routerQuery.toString()
      });
    };

    const shouldOpenMultipleOrderB2CModal = () => {
      return routerQuery.get(ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL);
    };

    const shouldOpenB2BModal = () => {
      return routerQuery.get(ORDER_KEY_PARAM.IS_CREATE_B2B_ORDER_MODAL);
    };

    return (
      <Fragment>
        <CategorizingOrderModal
          isOpen={routerQuery.get(ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL)}
        />
        <MultipleOrderB2CModal isOpen={shouldOpenMultipleOrderB2CModal()} {...props} />
        {/* <CreateB2BOrderModal isOpen={shouldOpenB2BModal()} {...props} /> */}
   
        {!hideButton && <SlzSplitButton
          items={CREATE_ORDER_OPTIONS}
          size={'lg'}
          placeholder="Create order"
          hasArrow={true}
          onlyShowPlaceholder={true}
          onClick={handleCreateB2COrder}
          onSelected={handleSelectedItem}
        />}
      </Fragment>
    );
};

export default CreateOrderAction;
