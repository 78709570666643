import { useEffect, useReducer, useState } from 'react';

import { SlzGridModal } from 'components/common/SlzModal';
import { ACTIVE_USER_SETTING_MODAL, COMPANY_NAVIGATE_MENU_STATUS } from 'constants/common';
import { useGlobalState } from 'contexts/GlobalContext';
import { MultipleFormProvider } from 'contexts/MultipleFormContext';
import useMultipleRHFForms from 'hooks/useMultipleRHFForms';
import useSlzToast from 'hooks/useSlzToast';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';

import {
  CONFIRM_LEAVE_TOAST_ID,
  INITIAL_PREFERENCE_STATE,
  PREFERENCES_ACTIONS,
  STYLE_WARNING_TOAST,
  USER_PREFERENCES_SETTING_TOAST_MESSAGE_MAP,
  USER_SETTING_FORMS,
  USER_SETTING_FORMS_DEFAULT_VALUES,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { useUserSettingMutations } from 'modules/preferences/hooks';
import { preferenceReducer } from 'modules/preferences/reducer';
import { ConfirmLeaveToast } from 'modules/preferences/components/other';
import { PreferenceProvider } from 'modules/preferences/context/PreferenceContext';

const UserSettingModalLayout = ({ modalLayout, ...rest }) => {
  const Layout = USER_SETTING_MODAL_LAYOUT[modalLayout]?.component;
  return <Layout {...rest} />;
};

const getBackModalLayoutKey = (modalLayout) => {
  switch (modalLayout) {
    case USER_SETTING_MODAL_LAYOUT.customBoxAndMailing.key:
      return USER_SETTING_MODAL_LAYOUT.managedLibraries.key;
    default:
      return USER_SETTING_MODAL_LAYOUT.default.key;
  }
};

const UserSettingModal = ({ onClose }) => {
  const [{ app }] = useGlobalState();
  const requiredSettingProps = useCheckRequiredSettings();
  const [_, setToast, toast] = useSlzToast();
  const [activateSetting, setActivateSetting] = useState();
  const [callbackFns, setCallbackFns] = useState({ onClose: undefined });
  const [preference, dispatch] = useReducer(preferenceReducer, INITIAL_PREFERENCE_STATE);
  const userSettingMutation = useUserSettingMutations(activateSetting?.type);

  const forms = useMultipleRHFForms({
    initialFormsValues: [...USER_SETTING_FORMS_DEFAULT_VALUES],
    visibleForms: USER_SETTING_FORMS[activateSetting?.type]
  });

  const isRootFormDirty = forms?.rootFormState.isDirty;
  const isCloseAllModal = preference?.modalLayout === USER_SETTING_MODAL_LAYOUT.default.key;

  const handleOnItemClick = ({ settingType, settingText }) => {
    const currentSetting = {
      type: settingType,
      text: settingText
    };

    if (!isRootFormDirty) {
      setActivateSetting(currentSetting);
      return;
    }

    showConfirmLeaveToast({ nextSettingToNavigate: currentSetting, isCloseModal: false });
  };

  const handleOnCloseModal = () => {
    if (callbackFns?.onClose) {
      callbackFns?.onClose();

      setCallbackFns((prev) => ({
        ...prev,
        onClose: undefined
      }));
      return;
    }

    if (!isCloseAllModal) {
      forms.resetAllVisibleForms();
      handleOnBackToUserSettingModal();
      return;
    }

    if (isRootFormDirty) {
      showConfirmLeaveToast({
        isCloseModal: true
      });
      return;
    }

    onClose && onClose();
  };

  const handleOnLeaveClick = ({ isCloseModal, nextSettingToNavigate }) => {
    forms?.resetAllVisibleForms();
    toast.closeAll();

    if (!isCloseAllModal) {
      handleOnBackToUserSettingModal();
      return;
    }

    if (isCloseModal) {
      onClose && onClose();
      return;
    }

    setActivateSetting(nextSettingToNavigate);
  };

  const handleOnSave = async ({ nextSettingToNavigate, isCloseModal }) => {
    const activatedSettingType = activateSetting?.type;
    const toastMessage = USER_PREFERENCES_SETTING_TOAST_MESSAGE_MAP[activatedSettingType];

    //Temporarily fixed by this way
    if (activatedSettingType === COMPANY_NAVIGATE_MENU_STATUS.paymentMethods) {
      toast.closeAll();
      return;
    }

    if (!forms.isVisibleFormsValid()) {
      const errorToastId = toastMessage?.error.replaceAll(' ', '');

      !toast.isActive(errorToastId) &&
        setToast({
          chakraToastProps: {
            id: errorToastId
          },
          title: toastMessage?.error,
          status: 'warning',
          colorScheme: 'negative'
        });

      return;
    }

    try {
      const formsValues = forms.takeVisibleFormsValues();
      forms.setSubmitForm(true);
      const response = await userSettingMutation?.mutateAsync(formsValues);
      forms.setSubmitForm(false);
      if (response?.error !== undefined) throw toastMessage?.error;
      forms.setSubmitStatus(true);
      forms?.resetAllVisibleForms();
      toast.close(CONFIRM_LEAVE_TOAST_ID);
      setToast({ title: toastMessage?.success });

      nextSettingToNavigate && setActivateSetting(nextSettingToNavigate);
    } catch (err) {
      forms.setSubmitStatus(false);
      toast.close(CONFIRM_LEAVE_TOAST_ID);
      setToast({ title: toastMessage?.error, status: 'warning', colorScheme: 'negative' });
    }
  };

  const showConfirmLeaveToast = ({ nextSettingToNavigate, isCloseModal }) => {
    !toast.isActive(CONFIRM_LEAVE_TOAST_ID) &&
      setToast({
        chakraToastProps: {
          id: CONFIRM_LEAVE_TOAST_ID,
          duration: null
        },
        ...STYLE_WARNING_TOAST,
        render: () => (
          <ConfirmLeaveToast
            isSubmitting={forms.rootFormState.isSubmitting}
            onLeaveClick={() => handleOnLeaveClick({ isCloseModal, nextSettingToNavigate })}
            onSaveClick={() => handleOnSave({ nextSettingToNavigate, isCloseModal })}
          />
        )
      });
  };

  const handleOnBackToUserSettingModal = () => {
    const modalLayout = getBackModalLayoutKey(isCloseAllModal ? '' : preference?.modalLayout);

    dispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout }
    });
  };

  useEffect(() => {
    app && setActivateSetting(app[ACTIVE_USER_SETTING_MODAL]);
  }, [app]);

  return (
    <PreferenceProvider value={{ preference, preferenceDispatch: dispatch }}>
      <SlzGridModal
        isOpen={true}
        onClose={handleOnCloseModal}
        closeOnOverlayClick={false}
        variant="userSetting">
        <MultipleFormProvider {...forms}>
          <UserSettingModalLayout
            modalLayout={preference?.modalLayout}
            activateSetting={activateSetting}
            // trigger={trigger}
            onItemClick={handleOnItemClick}
            onClose={onClose && onClose}
            onBack={handleOnBackToUserSettingModal}
            onSave={handleOnSave}
            setCallbackFns={setCallbackFns}
            {...requiredSettingProps}
          />
        </MultipleFormProvider>
      </SlzGridModal>
    </PreferenceProvider>
  );
};
export default UserSettingModal;
