import React from 'react';
import { Box, Flex, Text, useStyleConfig } from '@chakra-ui/react';
import uniqueId from 'lodash/uniqueId';
import { ExclamationIcon } from '../Icons';

const SlzStatusCard = (props) => {
  const { title, description, variant, showIcon = false, ...rest } = props;
  const styles = useStyleConfig('SlzStatusCard', { variant });
  return (
    <Flex id={uniqueId('SEL-Slz-Status-Card')} sx={{ ...styles.container, ...rest }}>
      <Box sx={styles.title}>
        <Text>{title}</Text>
      </Box>
      <Flex sx={styles.description}>
        <Text>{description}</Text>
      </Flex>
      {showIcon && (
        <Box sx={styles.icon}>
          <ExclamationIcon />
        </Box>
      )}
    </Flex>
  );
};

export default SlzStatusCard;
