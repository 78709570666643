import { Flex, Text, VStack } from '@chakra-ui/react';
import { SlzChip } from 'components/common/SlzChip';

const B2BOrderExplanationBox = ({ icon, label, description, ...rest }) => {
  return (
    <VStack
      spacing={5}
      py="6"
      w="16.625rem"
      h="23.188rem"
      bg="light.300"
      border="1px"
      borderColor="dark.300"
      boxShadow="slz-3md"
      borderRadius={1.5}
      {...rest}>
      <VStack spacing={6}>
        <Flex
          align="center"
          justifyContent="center"
          w="9.063rem"
          h="9.063rem"
          bg="main.300"
          borderRadius={6}>
          {icon}
        </Flex>
        <Flex>
          <SlzChip
            size="lg"
            variant="subtle"
            flexBasis="none"
            fontSize="1.25rem"
            bg="sunrise.300"
            color="lightAndShadow.reverseText">
            {label}
          </SlzChip>
        </Flex>
      </VStack>
      <Text px={6} fontSize="1.063rem" fontWeight="normal" textAlign="center" lineHeight="1.438rem">
        {description}
      </Text>
    </VStack>
  );
};
export default B2BOrderExplanationBox;
