import { Heading, Text, HStack, Box } from '@chakra-ui/react';
import { useHistory } from 'react-router';

import { SlzButton } from 'components/common/SlzButton';
import { filterRequiredSettings } from 'utils/helper';
import { useGlobalState } from 'contexts/GlobalContext';
import { ACTIVE_USER_SETTING_MODAL } from 'constants/common';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';

import { REQUIRED_SETTINGS_CONTENT, SETTING_TEXT } from './constants';
import { REQUIRED_SETTINGS_STYLE } from './styles';

const RequiredSetting = ({
  setting,
  onItemClick,
  cardinalNumber,
  onClose,
  children = undefined
}) => {
  const [_, dispatch] = useGlobalState();
  const history = useHistory();

  const handleOpenRequiredSettingTab = ({ settingType, settingText }) => {
    dispatch({
      type: ACTIVE_USER_SETTING_MODAL,
      payload: {
        type: settingType,
        text: settingText
      }
    });

    onClose && onClose();
    history.push({
      search: undefined
    });
  };

  const onOpenTab = onItemClick || handleOpenRequiredSettingTab;

  return (
    <>
      <Heading {...REQUIRED_SETTINGS_STYLE.title}>
        {cardinalNumber}
        {REQUIRED_SETTINGS_CONTENT[setting].title}
      </Heading>
      <Text {...REQUIRED_SETTINGS_STYLE.description}>
        {REQUIRED_SETTINGS_CONTENT[setting].description}
      </Text>
      {children || (
        <HStack {...REQUIRED_SETTINGS_STYLE.card}>
          {REQUIRED_SETTINGS_CONTENT[setting].icon}
          <Text mr="auto !important">{REQUIRED_SETTINGS_CONTENT[setting].text}</Text>
          <SlzButton
            px="0.75rem"
            borderRadius={1.5}
            onClick={() => onOpenTab({ settingType: setting, settingText: SETTING_TEXT[setting] })}>
            {REQUIRED_SETTINGS_CONTENT[setting].button}
          </SlzButton>
        </HStack>
      )}
    </>
  );
};

const SetupRequirement = ({ settings = [], onItemClick, children, onClose, ...rest }) => {
  return (
    <Box {...rest}>
      <Text fontSize="md" fontWeight="bold" mb="1.75rem">
        {REQUIRED_SETTINGS_CONTENT.common.title}
      </Text>
      {!!settings.length &&
        filterRequiredSettings(settings).map((setting, index) => (
          <RequiredSetting
            key={setting}
            onClose={onClose}
            cardinalNumber={index + 1}
            onItemClick={onItemClick}
            setting={setting}
          />
        ))}
      {children}
      <Text fontSize="md" my="2rem">
        {REQUIRED_SETTINGS_CONTENT.common.note}
      </Text>
    </Box>
  );
};

const RequirementValidation = ({
  children,
  requiredSettingProps,
  requirement = '',
  setup: Setup,
  ...rest
}) => {
  const { requiredSettings, isRequiredSettingsEmpty, isRequiredSettingsFetching } =
    requiredSettingProps || useCheckRequiredSettings();

  const canAccess = !requiredSettings.includes(requirement);
  const SetupComponent = Setup || SetupRequirement;

  return isRequiredSettingsEmpty && canAccess ? (
    children({
      isRequiredSettingsFetching
    })
  ) : (
    <>
      <SetupComponent settings={requiredSettings} {...rest} />
    </>
  );
};

SetupRequirement.RequiredSetting = RequiredSetting;
SetupRequirement.RequirementValidation = RequirementValidation;
export default SetupRequirement;
