import React from 'react';
import { VStack, HStack } from '@chakra-ui/react';
import StockDetails from './StockDetailsSummary';
import Shipping from '../summary/Shipping';
import ConsignmentBoxDimensionsSummary from './ConsignmentBoxDimensionsSummary';
import Warehouse from '../Warehouse';
import { useFormContext } from 'react-hook-form';
import { CONSIGNMENT_ARRIVING_TYPES } from 'modules/consignments/constants';

const MultipleProductConsignmentSummary = ({ warehousesData, currentType, handleEditStep }) => {
  const { getValues } = useFormContext();

  const {
    consignmentBoxDimensions,
    consignmentArrivingType,
    csvFiles,
    shippingReference,
    enabledLabelling,
    productLabelsSelected,
    stockBoxLabelsSelected
  } = getValues();

  return (
    <>
      <Warehouse warehousesData={warehousesData} currentType={currentType} handleEditStep={handleEditStep} bg="light.700" />
      <HStack mb={3} h="100%" display="flex" alignItems="stretch">
        <VStack w="50%">
          {consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.CONSIGNMENT_BOX && (
            <VStack w="full">
              <ConsignmentBoxDimensionsSummary
                consignmentBoxDimensions={consignmentBoxDimensions}
                handleEditStep={handleEditStep}
              />
            </VStack>
          )}
          <StockDetails csvFiles={csvFiles} handleEditStep={handleEditStep} />
        </VStack>
        <VStack w="50%" mb={3}>
          <Shipping
            shippingReference={shippingReference}
            enabledLabelling={enabledLabelling}
            productLabelsSelected={productLabelsSelected}
            stockBoxLabelsSelected={stockBoxLabelsSelected}
            handleEditStep={handleEditStep}
          />
        </VStack>
      </HStack>
    </>
  );
};

export default MultipleProductConsignmentSummary;
