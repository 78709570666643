import { useQuery } from 'react-query';
import { getOrders } from '../services/orders-api';

export const useOrdersQuery = (filters, select, enabled) => {
  return useQuery({
    queryKey: [`orders`, filters],
    queryFn: () => getOrders(filters),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
