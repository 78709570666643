import { array, object, string } from 'yup';

export const validation = object().shape({
  products: array()
    .of(
      object().shape({
        imageUrl: string().ensure().required('Image is required')
      })
    )
    .required('')
});
