import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import { object } from 'yup';

import { TickIcon } from 'components/common/Icons';
import {
  SERVICE_TIMES_ORDERED,
  SERVICE_TIMES_ORDERED_LABEL,
  SERVICE_TIME_COUNTRY_REGIONS,
  SFP,
  SFP_VALUE
} from '../constants';
import { DEFAULT_RATE_MATCHER_STEP1 } from '../constants';

export const getRegionOptions = (region, fetchedCurrentPreferences) => {
  const currentPreferences = fetchedCurrentPreferences?.map((currentRegionPreference) => {
    const isSFP =
      currentRegionPreference.preferences
        .flatMap((p) => p.ServiceTimes)
        .findIndex((s) => s === SFP_VALUE) > -1;
    return {
      ...currentRegionPreference,
      isSFP
    };
  });

  const regionOptions = currentPreferences
    .filter(({ isSFP }) => !isSFP)
    ?.map((currentPreference) => {
      return {
        label: SERVICE_TIME_COUNTRY_REGIONS[currentPreference?.countryRegion],
        value: currentPreference?.countryRegion,
        isMatched: currentPreference?.countryRegion === region?.value,
        MatchedIcon: currentPreference?.countryRegion === region?.value && (
          <TickIcon ml="auto" w="12px" h="16px" color="positive" />
        )
      };
    });
  return regionOptions;
};

export const getServiceTimeOptions = (
  selectedServiceTime,
  region,
  fetchedCurrentPreferences,
  fetchedServiceTimes
) => {
  const serviceTimeBelongRegion = fetchedServiceTimes?.find(
    (regionPreference) => regionPreference.countryRegion === region?.value
  );
  if (!serviceTimeBelongRegion) return;

  const serviceTimesList = serviceTimeBelongRegion.availableServiceTimeOptions
    .filter((s) => s.companyName !== SFP)
    .flatMap((m) => m.serviceTimes);

  const serviceTimes = Object.assign({}, ...serviceTimesList);

  let availableServiceTimes;
  const currentRegionPreference = fetchedCurrentPreferences.find(
    (c) => c.countryRegion === region?.value
  );

  if (currentRegionPreference) {
    availableServiceTimes = currentRegionPreference.preferences.flatMap((p) => p.ServiceTimes);
  }
  let uniqueServiceTimes = Object.keys(serviceTimes);

  if (availableServiceTimes)
    uniqueServiceTimes = uniqueServiceTimes.filter((u) => availableServiceTimes.includes(u));
  uniqueServiceTimes = sortServiceTimes(uniqueServiceTimes);

  const serviceTimeOptions = uniqueServiceTimes.map((serviceTime) => {
    return {
      label: serviceTimes[serviceTime],
      value: serviceTime,
      isMatched: serviceTime === selectedServiceTime?.value,
      MatchedIcon: serviceTime === selectedServiceTime?.value && (
        <TickIcon ml="auto" w="12px" h="16px" color="positive" />
      )
    };
  });

  return serviceTimeOptions;
};

export const getStepOneValues = (defaultValues) => {
  const stepOneValues = Object.keys(DEFAULT_RATE_MATCHER_STEP1).map((defaultRateMatcher) => {
    return {
      title: DEFAULT_RATE_MATCHER_STEP1[defaultRateMatcher],
      description: defaultValues[defaultRateMatcher]?.label || 'Not matched',
      isMatched: Boolean(defaultValues[defaultRateMatcher])
    };
  });

  return stepOneValues || [];
};

export const getManuallyMatchedRates = (shippingRates) => {
  const matchedShippingRates = shippingRates?.filter(
    ({ countryRegion, serviceTime, shippingRateID }) =>
      countryRegion && serviceTime && shippingRateID
  );

  const formattedMatchedShippingRates = matchedShippingRates?.map(
    ({ name, shippingZoneName, price, countryRegion, serviceTime }) => {
      const title = `${name} - ${shippingZoneName} - £${price.toFixed(2)}`;
      const description = `Matched to ${countryRegion} - ${SERVICE_TIMES_ORDERED_LABEL[serviceTime]} service`;

      return {
        title,
        description
      };
    }
  );

  return formattedMatchedShippingRates || [];
};

export const getUnManuallyMatchedRates = (shippingRates) => {
  const unMatchedShippingRates = shippingRates?.filter(
    ({ countryRegion, serviceTime }) => !countryRegion || !serviceTime
  );

  const formattedUnMatchedShippingRates = unMatchedShippingRates?.map(
    ({ name, shippingZoneName, price }) => ({
      title: `${name} - ${shippingZoneName} - £${price.toFixed(2)}`,
      description: 'Unmatched'
    })
  );

  return formattedUnMatchedShippingRates || [];
};

export const mapShippingRates = (formData) => {
  const mappedShippingRates = formData?.shippingRates
    ?.filter(({ countryRegion, serviceTime }) => countryRegion && serviceTime)
    .map(({ shippingRateID, name, price, countryRegion, serviceTime }) => {
      return {
        shippingRateID,
        shippingRateName: name,
        shippingRatePrice: price,
        countryRegion: countryRegion,
        serviceTime: serviceTime
      };
    });
  if (
    formData?.restofworldDefaultShipping?.value &&
    formData?.restofworldDefaultShipping?.value.serviceTime
  )
    mappedShippingRates?.push(formData?.restofworldDefaultShipping?.value);

  if (formData?.ukDefaultShipping?.value && formData?.ukDefaultShipping?.value.serviceTime)
    mappedShippingRates?.push(formData?.ukDefaultShipping?.value);

  if (formData?.euDefaultShipping?.value && formData?.euDefaultShipping?.value.serviceTime)
    mappedShippingRates?.push(formData?.euDefaultShipping?.value);

  return mappedShippingRates;
};

export const updateValidationSchema = (validationSchema, region) =>
  validationSchema.concat(
    object({
      [`${region.countryRegion.toLowerCase()}DefaultShipping`]: object().required()
    })
  );

export const transformShippingRates = ({ shippingRates = [], useDropdown = false }) => {
  const getDropdownValue = (value, label) => {
    const commonProps = { isMatched: true, showInputIcon: false };
    return {
      value,
      label: label || value,
      ...commonProps
    };
  };

  return shippingRates?.map((shippingRate) => {
    let { countryRegion, serviceTime } = shippingRate;
    const labelTime = SERVICE_TIMES_ORDERED_LABEL[serviceTime];
    countryRegion =
      useDropdown && countryRegion
        ? getDropdownValue(countryRegion)
        : countryRegion?.value || countryRegion;
    serviceTime =
      useDropdown && serviceTime
        ? getDropdownValue(serviceTime, labelTime)
        : serviceTime?.value || serviceTime;

    return {
      ...shippingRate,
      countryRegion,
      serviceTime
    };
  });
};

export const getMatchedShippingRates = (shippingRates) => {
  const count =
    filter(
      shippingRates,
      ({ countryRegion, serviceTime, shippingRateID }) =>
        countryRegion && serviceTime && shippingRateID
    )?.length || 0;
  return count;
};

export const sortServiceTimes = (serviceTimes) => {
  serviceTimes.sort(function (a, b) {
    if (SERVICE_TIMES_ORDERED.indexOf(a) > SERVICE_TIMES_ORDERED.indexOf(b)) return 1;
    else return -1;
  });
  return serviceTimes;
};

export const mapAvailableServiceTimesList = ({ availableServiceTimeOptions = [] }) => {
  return availableServiceTimeOptions
    .filter(({ companyName }) => companyName !== SFP)
    .flatMap(({ serviceTimes }) => serviceTimes);
};
