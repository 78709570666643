import React from 'react';
import { Tag, TagLabel, TagRightIcon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { CloseCircleIcon } from '../Icons';

const SlzTag = (props) => {
  const { children, closable, onClose, variant, customTagStyles, customTagIconStyles } = props;
  const propStyles = {
    ...props
  }
  delete propStyles?.customTagStyles;
  delete propStyles?.customTagIconStyles;
  return (
    <Tag sx={customTagStyles} data-testid="tag" id={uniqueId('SEL-Slz-Tag')} {...propStyles}>
      <TagLabel role="label">{children}</TagLabel>
      {closable && (
        <TagRightIcon
          sx={customTagIconStyles}
          role="button"
          as={CloseCircleIcon}
          color={variant === 'solid' ? '#fff' : `primary.${propStyles.colorScheme}`}
          marginStart="1.5"
          marginEnd="-1.5"
          cursor="pointer"
          w="12px"
          h="12px"
          onClick={onClose}
        />
      )}
    </Tag>
  );
};

export default SlzTag;
SlzTag.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  closable: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func
};
