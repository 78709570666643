import * as yup from 'yup';

export const repcipientDetailsSchema = yup
  .object({
    name: yup.string().required('Name is required'),
    phone: yup
      .string()
      .matches(/\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/, 'Phone is required')
      .required('Phone is required'),
    companyName: yup.string().required('Name is required'),
    email: yup
      .string()
      .email()
      .matches(/^(?!.*@[^,]*,)/)
      .required('Email is required'),

    addressLine1: yup.string().required('Address line 1 is required'),
    town: yup.string().required('Town is required'),
    county: yup.string().required('County is required'),
    postCode: yup.string().required('Postcode is required'),
    country: yup.object().typeError('Country code is required').required('Country code is required')
  })
  .required();

// to do validation something
