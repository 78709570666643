import { useEffect, useRef } from 'react';

import { useBoolean } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

const useMultipleRHFForms = ({ initialFormsValues = [], visibleForms = [] }) => {
  const [isDirty, setIsDirty] = useBoolean();
  const [isValid, setIsValid] = useBoolean();
  const [isSubmitting, setIsSubmitting] = useBoolean();
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useBoolean(false);
  const forms = useRef({});

  forms.current = initialFormsValues.reduce((acc, { name, options = {} }) => {
    acc[name] = {
      form: useForm(options),
      initialOptions: options
    };

    return acc;
  }, {});

  const resetAllVisibleForms = () => {
    visibleForms?.forEach((formName) => {
      const form = forms.current[formName]?.form;
      if (form) {
        form.reset(form.getValues());
      }
    });

    setIsDirty.off();
  };

  const takeVisibleFormValues = (formName) => {
    const form = forms.current?.[formName]?.form;
    return form?.getValues() || {};
  };

  const takeVisibleFormsValues = () => {
    return visibleForms?.reduce(
      (acc, formName) => ({
        ...acc,
        [formName]: takeVisibleFormValues(formName)
      }),
      {}
    );
  };

  // const isVisibleFormsValid = () => {
  //   return !visibleForms.some((formName) => {
  //     const { isValid } = forms.current[formName]?.form?.formState;
  //     return !isValid;
  //   });
  // };

  const isVisibleFormsValid = () => {
    return !visibleForms.some((formName) => {
      const { isValid, isDirty } = forms.current[formName]?.form?.formState;
      return !isValid && isDirty;
    });
  };

  const setDirtyForm = () => {
    const hasDirtyForm = Object.values(forms.current).some(({ form }) => form?.formState?.isDirty);
    hasDirtyForm ? setIsDirty.on() : setIsDirty.off();
  };

  // const setValidForm = () => {
  //   const isValidForms = isVisibleFormsValid();
  //   isValidForms ? setIsValid.on() : setIsValid.off();
  // };

  const setSubmitForm = (submitting) => {
    submitting ? setIsSubmitting.on() : setIsSubmitting.off();
  };

  const setSubmitStatus = (isSuccess) => {
    isSuccess ? setIsSubmitSuccessful.on() : setIsSubmitSuccessful.off();
  };

  useEffect(() => {
    setDirtyForm();
    isVisibleFormsValid();
  }, [forms.current]);

  return {
    forms: forms.current,
    rootFormState: {
      isValid,
      isDirty,
      isSubmitting,
      isSubmitSuccessful
    },
    isVisibleFormsValid,
    resetAllVisibleForms,
    takeVisibleFormValues,
    takeVisibleFormsValues,
    setSubmitStatus,
    setSubmitForm
  };
};

export default useMultipleRHFForms;
