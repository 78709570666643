import React from 'react';

import { Box, HStack, Heading, Text, VStack, useStyleConfig } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { ExclamationIcon } from 'components/common/Icons';

const stepOneValues = ({ title, stepOneValues = [] }) => {
  const styles = useStyleConfig('SlzStatusCard');
  return (
    <SlzPaper w="full" h="auto" p={5} mb="2.35rem">
      <Text fontWeight="medium" fontSize="1rem">
        {title}
      </Text>
      <HStack spacing={8} fontSize="0.875rem">
        {stepOneValues.map((rateMatcher) => (
          <VStack alignItems="start" spacing={0} key={rateMatcher?.title}>
            <Heading fontSize="0.875rem" color="dark.700" lineHeight="1.563rem">
              {rateMatcher.title}
            </Heading>
            <Box
              border="1px solid"
              w="150px"
              h="27px"
              borderRadius="5px"
              p="2px 8px"
              sx={{
                ...styles.container,
                borderColor: rateMatcher?.isMatched ? 'main.500' : 'negative.500'
              }}>
              <Text fontWeight="bold" isTruncated>
                {rateMatcher?.description}
              </Text>
              {!rateMatcher?.isMatched && (
                <Box sx={styles.icon}>
                  <ExclamationIcon />
                </Box>
              )}
            </Box>
          </VStack>
        ))}
      </HStack>
    </SlzPaper>
  );
};

export default stepOneValues;
