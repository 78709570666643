import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import { AiOutlineFile } from 'react-icons/ai';

import { SlzButton } from 'components/common/SlzButton';
import { SlzChip } from 'components/common/SlzChip';

const Files = ({ csvFiles, setCsvFiles }) => (
  <VStack
    w="826px"
    sx={{
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'dark.300',
      borderRadius: 1.5,
      pt: 1.5,
      pb: 1.5,
      px: 3,
      alignSelf: 'center'
    }}>
    {csvFiles.map((item, i) => (
      <HStack justifyContent="space-between" w="full" key={`list-${i}`} spacing={12}>
        <Box flex={1}>
          <HStack>
            <SlzChip px={1.5} mr={-0.5} py={1.5} maxW={6}>
              <AiOutlineFile size={14} />
            </SlzChip>
            <Text
              sx={{
                fontSize: '12px',
                lineHeight: '16px',
                letterSpacing: '0px',
                color: 'dark.700'
              }}>
              {item.name}
            </Text>
          </HStack>
        </Box>
        <Box textAlign="end">
          <SlzButton
            colorScheme="red"
            variant="outline"
            onClick={() => setCsvFiles(csvFiles.filter((row) => row.id !== item.id))}>
            Delete
          </SlzButton>
        </Box>
      </HStack>
    ))}
  </VStack>
);

export default Files;
