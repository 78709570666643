import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useMultiStyleConfig
} from '@chakra-ui/react';
import { useState } from 'react';
import { BellIcon } from '../Icons';
import { ROTATION_0_DEG, ROTATION_180_DEG } from './Constants';
import { uniqueId } from 'lodash';
import { SEL_PREFIX_ID } from '../Constants';

const NotificationIcon = (props) => {
  const { number, style } = props;

  const getStyle = () =>
    number <= 10
      ? style
      : {
          ...style,
          w: '1.25rem',
          h: '1.25rem',
          letterSpacing: '-0.113rem'
        };

  return (
    <Box pos="relative">
      <BellIcon w="1.5rem" h="1.675rem" />
      {number > 0 && (
        <Box data-testid="slz-notification-number" sx={getStyle()}>
          {number == 1 ? '' : number > 10 ? '10+' : number}
        </Box>
      )}
    </Box>
  );
};

const SlzNotification = (props) => {
  const { notifications, number, size, colorScheme, variant } = props;
  const styles = useMultiStyleConfig('SlzNotification', { variant, colorScheme });

  const [rotation, setRotation] = useState(ROTATION_0_DEG);
  const [isActive, setIsActive] = useState(false);
  const [currentSelectedOption, setCurrentSelectedOption] = useState();

  const handleSelectOption = (option) => {
    setCurrentSelectedOption(option);
    props?.onSelectOption(option);
  };

  const getOptionStyle = (option) => {
    if (option.key === currentSelectedOption?.key) {
      return styles.selectedOption;
    }
    return styles.option;
  };

  const getRightButtonStyle = () => {
    if (isActive) {
      return styles.rightButtonActive;
    }
    return styles.rightButton;
  };

  const open = () => {
    setRotation(ROTATION_180_DEG);
    setIsActive(true);
  };

  const close = () => {
    setRotation(ROTATION_0_DEG);
    setIsActive(false);
  };

  return (
    <ButtonGroup
      data-testid="slz-notification-wrap"
      isAttached
      size={size}
      sx={styles.container}
      id={uniqueId(`${SEL_PREFIX_ID}-Slz-Notification`)}>
      <Button
        data-testid="slz-notification-left-button"
        px="3"
        leftIcon={<NotificationIcon style={styles.notificationNumber} number={number} />}
        colorScheme="dark"
        variant="solid"
        borderEnd="0.063rem solid white"
        sx={styles.leftButton}>
        Notifications
      </Button>

      <Popover placement="bottom-end" onOpen={() => open()} onClose={() => close()} isLazy>
        <PopoverTrigger>
          <IconButton
            data-testid="slz-notification-right-button"
            sx={getRightButtonStyle()}
            colorScheme="dark"
            variant="solid"
            icon={
              <ChevronDownIcon
                data-testid="slz-notification-right-icon"
                boxSize={6}
                transitionDuration="0.3s"
                transform={rotation}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent sx={styles.optionContainer} data-testid="slz-notification-option-wrap">
          <PopoverArrow sx={styles.arrow} />
          {notifications.map((notification) => (
            <PopoverHeader
              role="slz-notification-option"
              key={notification.key}
              sx={getOptionStyle(notification)}
              onClick={() => handleSelectOption(notification)}>
              <Text noOfLines={3} m={0}>
                {notification.text}
              </Text>
            </PopoverHeader>
          ))}
        </PopoverContent>
      </Popover>
    </ButtonGroup>
  );
};

export default SlzNotification;
