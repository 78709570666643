import { Box, Text, VStack } from '@chakra-ui/react';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzButton } from 'components/common/SlzButton';
import { ORDER_STATUS_COLOR } from 'constants/common';
import { ISSUE_MESSAGE, ORDER_UNSUCCESSFUL_SUB_STATUS } from 'modules/b2c-orders/constants';

const ShowIssue = ({ status }) => {
  if (Object.values(ORDER_UNSUCCESSFUL_SUB_STATUS).includes(status?.name)) {
    return (
      <VStack spacing={0}>
        <SlzButton
          size="lg"
          borderBottomRadius="0"
          width="full"
          bg={ORDER_STATUS_COLOR.unsuccessful}
          _hover={{ bg: ORDER_STATUS_COLOR.unsuccessful }}>
          {status?.name}
        </SlzButton>
        <Box
          height="auto"
          px={8}
          pb={4}
          borderBottomRadius="0.375rem"
          boxShadow="slz-2md"
          bg="light.500">
          <Text py={2} fontWeight="bold">
            Issue
          </Text>
          <Text fontSize="md">{ISSUE_MESSAGE}</Text>
        </Box>
      </VStack>
    );
  }
  return (
    <SlzButton
      size="lg"
      width="full"
      bg={status?.color}
      _hover={{ bg: status?.color }}
      boxShadow="slz-md">
      {status?.name}
    </SlzButton>
  );
};

const B2COrderSubStatus = (props) => {
  const { status, isLoading } = props;

  return (
    <Skeleton isLoaded={!isLoading} width="100%" height="100%" borderRadius={1.5}>
      <ShowIssue status={status} />
    </Skeleton>
  );
};

export default B2COrderSubStatus;
