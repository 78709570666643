import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { USER_SETTING_FORMS_DEFAULT_NAME } from 'modules/preferences/constants';
import {
  addCustomPackagingBox,
  addCustomPackagingMailingBag,
  updateCustomPackagingBox,
  updateCustomPackagingMailingBag,
  updateCustomPackagingOverview,
  updatePackagingExtra
} from 'modules/preferences/services/preferences-api';

const usePackagingSettingMutation = () =>
  useMutation({
    mutationFn: async (payload) => {
      const {
        [USER_SETTING_FORMS_DEFAULT_NAME.box]: boxForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.mailingBag]: mailingBagForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.configureExtras]: extrasForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateBox]: updateBoxForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag]: updateMailingBagForm
      } = payload;

      const hasEmptyValue = (obj) => Object.values(obj).some((value) => value === '');

      const isUpdateBox = updateBoxForm?.id;

      const isUpdateMailingBag = updateMailingBagForm?.id;

      const apiBoxService = isUpdateBox ? updateCustomPackagingBox : addCustomPackagingBox;

      const apiMailingBagService = isUpdateMailingBag
        ? updateCustomPackagingMailingBag
        : addCustomPackagingMailingBag;

      if (!hasEmptyValue(updateBoxForm)) {
        await apiBoxService(updateBoxForm);
      }

      if (!hasEmptyValue(updateMailingBagForm)) {
        await apiMailingBagService(updateMailingBagForm);
      }

      !isEmpty(extrasForm) &&
        (await Promise.all(
          extrasForm?.extras?.map(async (extra) => {
            await updatePackagingExtra(extra);
          })
        ));

      !isEmpty(boxForm) &&
        (await updateCustomPackagingOverview({ enforced: boxForm.box, isBox: true }));

      !isEmpty(mailingBagForm) &&
        (await updateCustomPackagingOverview({
          enforced: mailingBagForm.mailingBag,
          isBox: false
        }));
    }
  });

export default usePackagingSettingMutation;
