import { keyframes } from '@chakra-ui/react';

const animationSkeletonKeyframes = keyframes`
  0% { background-color: hsl(200, 20%, 90%); }
  100% {background-color: hsl(200, 20%, 95%); }
`;

const animationTextSkeletonKeyframes = keyframes`
  0% { color: hsl(200, 20%, 90%); }
  100% { color: hsl(200, 20%, 95%); }
`;

const animationShimmerSkeletonKeyframes = keyframes`
  100% { transform: translateX(100%); }
`;

export const skeletonAnimation = `${animationSkeletonKeyframes} 0.3s ease-out infinite alternate`;
export const skeletonTextAnimation = `${animationTextSkeletonKeyframes} 0.3s ease-out infinite alternate`;
export const skeletonShimmerAnimation = `${animationShimmerSkeletonKeyframes} 0.8s infinite`;
