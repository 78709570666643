import { Fragment } from 'react';
import map from 'lodash/map';
import SlzTable from 'components/common/SlzTable/Table';
import { useQuery as useRouterQuery } from '../../../../hooks/useQuery';
import { mockBundleFilter } from '../../_mock';
import BundleAccordionTable from './BundleAccordionTable';
import { BUNDLE_COLS, BUNDLE_NESTED_COLS } from './bundles.columns';
import { PAGES_OPTIONS_DEFAULT, BUNDLE_DETAIL_TYPE } from 'modules/products/constants';
import { PAGE_INDEX } from 'constants/table.js';
import { useQuery } from 'react-query';
import { getAllItemCustomTags } from 'modules/products/services/customTags-api';
import { transformBundleTagsForFilter } from 'modules/products/mappers/bundle-list-mapper';

const BundlesTable = (props) => {
  const {
    data,
    queryParams,
    totalPage,
    onRefetching,
    handleItemPerPageChange,
    handleCurrentPageChange,
    handleOpenModal
  } = props;
  const routerQuery = useRouterQuery();

  const { data: tagsData } = useQuery([`bundle-tags`], getAllItemCustomTags, {
    enabled: true,
    select: transformBundleTagsForFilter
  });

  BUNDLE_COLS[1].isClickable = true;
  BUNDLE_COLS[1].onClick = (row) => {
    handleOpenModal(row);
  };

  const accordionProps = {
    isShowMarker: true,
    markedWidth: 12,
    columns: BUNDLE_NESTED_COLS,
    showImage: true,
    imageWidth: 48,
    imageStyles: {
      w: '3rem',
      h: 'inherit'
    },
    type: BUNDLE_DETAIL_TYPE,
    displayFields: map(BUNDLE_NESTED_COLS, 'field')
  };

  return (
    <Fragment>
      <SlzTable
        isLoading={props?.isLoading}
        variant="productList"
        sizes={['sm', 'md', 'lg']}
        showMarked={true}
        showHeader={true}
        messageToast="Sorry! We could not retrieve any of your product information"
        onRefetching={onRefetching}
        isEmptyPage={props?.isEmptyPage}
        columns={BUNDLE_COLS}
        data={data}
        rowProps={{
          hasExpandedRow: true,
          accordionProps: {
            ...accordionProps,
            template: ({ row }) => {
              return <BundleAccordionTable row={row} accordionProps={accordionProps} />;
            }
          }
        }}
        filters={{
          isLocal: true,
          title: 'Bundle tag',
          byField: 'bundleTag',
          options: tagsData
        }}
        displayFields={map(BUNDLE_COLS, 'field')}
        pagination={{
          isLocal: false,
          pages: {
            onChange: (numPerPage) => handleItemPerPageChange(numPerPage),
            pageOptions: PAGES_OPTIONS_DEFAULT,
            currentItemPerPage: queryParams?.pageSize
          },
          direction: {
            usingDots: false,
            totalPage: totalPage,
            currentPage: parseInt(routerQuery.get(PAGE_INDEX)),
            // numDots: 5,
            isGreyBg: true,
            onChange: (currentPage) => handleCurrentPageChange(currentPage)
          }
        }}
      />
    </Fragment>
  );
};

export default BundlesTable;
