import { Box, Flex, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SlzPasswordInput, SlzTextInput } from 'components/common/SlzInput';
import { ControlledField } from 'components/form';
import { userDetailsValidationSchema } from './validation-schemas';
import { PasswordCriteria, UserManagementAction } from '../other';
import { useCheckPasswordCriteria } from 'modules/users/hooks';

const AddUserDetailsForm = ({ defaultValues, onNext, ...rest }) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(userDetailsValidationSchema),
    shouldFocusError: false
  });

  const password = methods.watch('password');
  const passwordCriteria = useCheckPasswordCriteria({
    password,
    confirmPassword: '',
    delayTime: 500,
    isDirty: methods?.formState.dirtyFields?.password,
    isCheckConfirmPassword: false
  });

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods?.handleSubmit(onNext)}>
          <VStack alignItems="initial" gap={2} mb={10}>
            <Flex gap={2}>
              <ControlledField
                name="forename"
                label="Forename"
                component={SlzTextInput}
                isInlineError
              />
              <ControlledField
                name="surname"
                label="Surname"
                component={SlzTextInput}
                isInlineError
              />
            </Flex>
            <Flex gap={2}>
              <ControlledField
                id="emailUser"
                name="email"
                label="Email"
                component={SlzTextInput}
                isInlineError
              />
            </Flex>
            <Flex gap={2}>
              <ControlledField
                autocomplete="new-password"
                id="passwordUser"
                name="password"
                label="Password"
                component={SlzPasswordInput}
                isInlineError
              />
            </Flex>
          </VStack>
          <Box pt={2} pb={6}>
            <PasswordCriteria criteriaStatus={passwordCriteria} />
          </Box>
          <UserManagementAction {...rest} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default AddUserDetailsForm;
