import { Flex, Text, VStack } from '@chakra-ui/react';
import { CheckCircleIcon } from 'components/common/Icons';
import { SlzButton } from 'components/common/SlzButton';
import { BULK_ORDER_CREATE_TEXT } from 'modules/b2b-orders/constants';
import { B2B_ORDER_SUBMITTED_STYLE } from 'modules/b2b-orders/constants/styles';

const B2BOrderSubmitted = (props) => {
  return (
    <VStack w="full" spacing={16} mt={6}>
      <VStack w="full" spacing={6}>
        <CheckCircleIcon color="main.500" w={60} h={60} />
        <Flex {...B2B_ORDER_SUBMITTED_STYLE.orderSubmitted}>
          <Text fontSize="custom.2xl" fontWeight="medium">
            {BULK_ORDER_CREATE_TEXT.SUMMITTED.orderSubmitted}
          </Text>
        </Flex>
      </VStack>
      <VStack {...B2B_ORDER_SUBMITTED_STYLE.needToCancel}>
        <Text fontWeight="bold">{BULK_ORDER_CREATE_TEXT.SUMMITTED.needToCancel}</Text>
        <Text>{BULK_ORDER_CREATE_TEXT.SUMMITTED.needToCancelContent}</Text>
      </VStack>
      <Flex w="full" justifyContent="flex-end">
        <SlzButton variant="solid" size="lg" onClick={props?.onCancel}>
          Close
        </SlzButton>
      </Flex>
    </VStack>
  );
};

export default B2BOrderSubmitted;
