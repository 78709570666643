import { CheckIcon } from '@chakra-ui/icons';
import { Box, ListItem, Text, UnorderedList, useMultiStyleConfig } from '@chakra-ui/react';
import React, { useState, useEffect, forwardRef } from 'react';
import size from 'lodash/size';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

const TimelineStepper = forwardRef((props, ref) => {
  const { steps, step = 1, onCurrentStepChange, disabled = false, labelStyles, ...rest } = props;
  const styles = useMultiStyleConfig('SlzTimelineStepper');
  const [currentStep, setCurrentStep] = useState(step);

  const getStepStyle = (step) => {
    if (step === currentStep) {
      return styles.currentStep;
    }
    if (step < currentStep) {
      return styles.completedStep;
    }
    return styles.step;
  };

  const getConnectorStyle = (step) => {
    if (step < currentStep) {
      return {
        ...styles.connector,
        bgColor: '#7EBEF2',
        h: '0.375rem'
      };
    }
    if (step === currentStep) {
      return {
        ...styles.connector,
        bgColor: 'main.300'
      };
    }
    return styles.connector;
  };

  const handleChangeCurrentStep = (step) => {
    setCurrentStep(step);
    if (onCurrentStepChange) {
      onCurrentStepChange(step);
    }
  };

  const next = () => {
    !disabled &&
      setCurrentStep((prevStep) => {
        if (prevStep < size(steps)) {
          if (onCurrentStepChange) {
            onCurrentStepChange(prevStep + 1);
          }
          return prevStep + 1;
        }
        return prevStep;
      });
  };

  const back = () => {
    !disabled &&
      setCurrentStep((prevStep) => {
        if (prevStep > 1) {
          if (onCurrentStepChange) {
            onCurrentStepChange(prevStep - 1);
          }
          return prevStep - 1;
        }
        return prevStep;
      });
  };

  useEffect(() => {
    if (ref) {
      ref.current = {
        next,
        back
      };
    }
  }, []);

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  return (
    <UnorderedList id={uniqueId('SEL-Slz-TimelineStepper')} sx={{ ...styles.steps }} {...rest}>
      {steps?.map(({ key, label }, index) => {
        const theStep = index + 1;
        return (
          <React.Fragment key={key}>
            <ListItem
              data-testid="stepItem"
              sx={{ ...getStepStyle(theStep), cursor: disabled ? 'unset' : 'pointer' }}
              _after={{
                content: `"${label}"`,
                width: 'max-content',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                fontSize: '1.0625rem',
                top: '40px',
                color: theStep > currentStep ? 'dark.300' : 'dark.700',
                ...labelStyles
              }}
              onClick={() => !disabled && handleChangeCurrentStep(theStep)}>
              {theStep >= currentStep && <Text data-testid="stepText">{theStep}</Text>}
              {theStep < currentStep && (
                <CheckIcon data-testid="stepIcon" sx={styles.completedIcon} />
              )}
            </ListItem>
            {theStep !== size(steps) && (
              <Box data-testid="stepConnector" sx={getConnectorStyle(theStep)}></Box>
            )}
          </React.Fragment>
        );
      })}
    </UnorderedList>
  );
});

export default TimelineStepper;
TimelineStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.arrayOf([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  step: PropTypes.number,
  onCurrentStepChange: PropTypes.func
};
