export const transformAccordionItems = (data) => {
  const bundleItems = data?.orderedProductBundles?.map((bundle) => ({
    quantity: bundle?.quantity,
    ean: bundle?.companyItem?.ean,
    name: bundle?.productBundle?.name,
    weight: bundle?.companyItem?.weight || 0,
    skuCode: bundle?.productBundle?.skuCode,
    suid: bundle?.companyItem?.selazarUniqueID,
    wareHouse: 'Selazar Nuneaton',
    price: '£' + (bundle?.productBundle?.price || 0),
    isBundle: true
  }));

  const orderedItems = data?.orderedCompanyItems?.map((order) => ({
    quantity: order?.quantity,
    ean: order?.companyItem?.item?.ean,
    name: order?.companyItem?.item?.name,
    weight: order?.companyItem?.item?.weight || 0,
    skuCode: order?.skuCode,
    suid: order?.companyItem?.item?.selazarUniqueID,
    wareHouse: 'Selazar Nuneaton',
    price: '£' + (order?.companyItem?.item?.price || 0),
    imageUrl: null,
    isBundle: false
  }));

  return [...bundleItems, ...orderedItems];
};
