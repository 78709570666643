import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import { Box, Flex, Stack, Text, VStack, useRadio } from '@chakra-ui/react';
import { WarningIcon } from '../icons';
import { VERTICAL_CARDS_STYLES } from './styles';

const CardContext = createContext();
const useCardCtx = () => useContext(CardContext);

const CardStyleProvider = ({ children, styles = VERTICAL_CARDS_STYLES }) => {
  return <CardContext.Provider value={{ styles }}>{children}</CardContext.Provider>;
};

export const Card = ({
  children,
  wrap: Wrap = VStack,
  isChecked = false,
  isError = false,
  ...rest
}) => {
  const { card: defaultStyles = {} } = useCardCtx()?.styles;
  const isCheckedStyles = isChecked ? useCardCtx()?.styles?.cardChecked : null;
  const isErrorStyles = isError ? useCardCtx()?.styles?.cardError : null;
  return (
    <Wrap sx={{ ...defaultStyles, ...isCheckedStyles, ...isErrorStyles }} {...rest}>
      {children}
    </Wrap>
  );
};

export const CardIcon = ({ children = WarningIcon, ...rest }) => {
  const { icon: defaultStyles = {} } = useCardCtx()?.styles;
  return (
    <Flex sx={{ ...defaultStyles }} w="fit-content" {...rest}>
      {children}
    </Flex>
  );
};

export const CardBody = ({ children = <></>, wrap: Wrap = VStack, ...rest }) => {
  const { body: defaultStyles = {} } = useCardCtx()?.styles;
  return (
    <Wrap sx={{ ...defaultStyles }} {...rest}>
      {children}
    </Wrap>
  );
};

export const CardFooter = ({ children = <></>, wrap: Wrap = VStack, ...rest }) => {
  const { footer: defaultStyles = {} } = useCardCtx()?.styles;
  return (
    <Wrap sx={{ ...defaultStyles }} {...rest}>
      {children}
    </Wrap>
  );
};

export const CardRoleBanner = ({ children, inside = 'body', ...rest }) => {
  // const { banner: defaultStyles = {} } = useCardCtx()?.styles?.[inside];
  return <CardChip {...rest}>{children}</CardChip>;
};

export const CardRoleDescription = ({ children, ...rest }) => {
  return (
    <Text fontSize="10px" lineHeight="14px" color="table.mainText" sx={rest}>
      {children}
    </Text>
  );
};

const CardChip = ({ children, ...rest }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderRadius={0.75}
      bg="main.300"
      fontSize="12px"
      lineHeight="14px"
      w="full"
      {...rest}>
      {children}
    </Flex>
  );
};

export const CardPermissions = ({ children, ...rest }) => {
  return (
    <CardChip p="8px 10px 16px 12px" color="main.500" alignItems="start" {...rest}>
      {children}
    </CardChip>
  );
};

export const CardRolesAndPermissions = ({ children, styles, ...rest }) => {
  const { getInputProps, getRadioProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getRadioProps();
  return (
    <CardStyleProvider styles={styles}>
      <Box as="label">
        <input {...input} />
        <Box {...checkbox}>{children}</Box>
      </Box>
    </CardStyleProvider>
  );
};

CardBody.propTypes = {
  wrap: PropTypes.instanceOf(Stack)
};

CardFooter.propTypes = {
  wrap: PropTypes.instanceOf(Stack)
};

Card.propTypes = {
  wrap: PropTypes.instanceOf(Stack)
};
