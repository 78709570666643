import { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  List,
  ListItem,
  StylesProvider,
  useDisclosure,
  useMultiStyleConfig,
  VStack
} from '@chakra-ui/react';

import usePreviousState from 'hooks/usePreviousState';
import { SelazarLogo } from '../Icons';
import CollapseButton from './CollapseButton';
import NavbarButton from './NavbarButton';
import NavbarSection from './NavbarSection';
import { useGlobalState } from 'contexts/GlobalContext';
import { ACTIVE_NAVBAR_ITEM } from 'constants/common';

const NavbarFooter = ({ activeSection, isExpanded, navigateToHelp, navigateToHome }) => {
  return (
    <List spacing={4} w="full">
      {/*<ListItem>
        <NavbarButton
          icon={() => <QuestionOutlineIcon w="1.875rem" h="1.875rem" />}
          name={'Help'}
          isActive={activeSection?.key === 'help'}
          isExpanded={isExpanded}
          onClick={navigateToHelp}
        />
  </ListItem>*/}
      <ListItem display="flex" pl="1rem">
        <Box w={8} h={8} overflow={isExpanded ? 'visible' : 'hidden'}>
          <Link to="/" onClick={navigateToHome}>
            <SelazarLogo h="2rem" w="auto" />
          </Link>
        </Box>
      </ListItem>
    </List>
  );
};

const SlzNavbar = ({ configs = [], activeItem = {}, size, isExpanded, onCollapseMenu }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const styles = useMultiStyleConfig('SlzNavbar', {
    size,
    variant: isExpanded ? 'expanded' : 'minimal'
  });
  const [_, dispatch] = useGlobalState();
  const history = useHistory();
  const [activeSection, setActiveSection] = useState(activeItem?.section);
  const prevActiveSection = usePreviousState(activeSection);
  const [activeLinkKey, setActiveLinkKey] = useState(activeItem?.key);

  useEffect(() => {
    setActiveSection(activeItem?.section);
    setActiveLinkKey(activeItem?.key);
  }, [activeItem]);

  const handleNavigation = (path, key, children) => {
    children ? onOpen() : onClose();
    if (path) {
      history.push(path);
      setActiveLinkKey(key);
      dispatch({
        type: ACTIVE_NAVBAR_ITEM,
        payload: {
          key,
          section: activeSection
        }
      });
    }
  };

  const navigateToHelp = () => handleActiveSection({ key: 'help', path: '/help' });
  const navigateToHome = () => handleActiveSection({ key: 'home', path: '/' });

  const handleActiveSection = (link) => {
    setActiveSection(link);
    dispatch({
      type: ACTIVE_NAVBAR_ITEM,
      payload: {
        key: link.key,
        section: link
      }
    });
    handleNavigation(link.path, link.key, link.children);
  };

  const handleCloseSection = () => {
    onClose();
    const shouldActivePreviousSection = !activeSection.path && prevActiveSection?.path;
    if (shouldActivePreviousSection) {
      setActiveSection(prevActiveSection);
    }
  };

  return (
    <StylesProvider value={styles}>
      <Box as="aside" __css={styles.navbar}>
        <VStack h="full" py={10} alignItems="flex-start">
          <Box mb={4} w="full" pos="relative" textAlign="center">
            <CollapseButton isExpanded={isExpanded} onClick={onCollapseMenu} />
          </Box>

          <List spacing={4} flex="1" w="full">
            {configs.map((section) => {
              const isActive = section.key === activeSection?.key;
              return (
                <ListItem key={section.key}>
                  <NavbarButton
                    icon={section.icon}
                    name={section.name}
                    isActive={isActive}
                    isShowArrow={isOpen && isActive}
                    isExpanded={isExpanded}
                    onClick={() => handleActiveSection(section)}
                  />
                </ListItem>
              );
            })}
          </List>

          <NavbarFooter
            activeSection={activeSection}
            isExpanded={isExpanded}
            navigateToHelp={navigateToHelp}
            navigateToHome={navigateToHome}
          />
        </VStack>

        <NavbarSection
          isOpen={isOpen}
          onClose={handleCloseSection}
          sections={activeSection}
          activeLinkKey={activeLinkKey}
          onNavigate={handleNavigation}
          isExpanded={isExpanded}
        />
      </Box>
    </StylesProvider>
  );
};

export default SlzNavbar;
