import { Flex, Image, Text } from '@chakra-ui/react';
import BundleIcon from 'components/common/Icons/BundleIcon';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';
import { DEFAULT_IMAGE } from 'constants/common';

export const PRODUCT_PATHNAME = '/products';
export const PRODUCT_STATUS = 'status';
export const BUNDLE_DETAIL_TYPE = 'bundle_detail';
export const CREATE_BUNDLE_STEP = {
  BUNDLE_DETAILS: 'Bundle details',
  PRODUCT_SELECTION: 'Product selection',
  BUNDLE_CONFIRMATION: 'Confirmation'
};

export const EDIT_BUNDLE_STEP = {
  BUNDLE_DETAILS: 'Bundle details',
  PRODUCT_SELECTION: 'Product selection'
};

export const PRODUCT_DEFAULT_PAGE = 1;
export const PRODUCT_KEY = 'product_list';
export const TAG_DEFAULT_NAME = 'custom tag';
export const TAG_DEFAULT_COLOR = 'pebbel.500';

export const BUNDLE_DEFAULT_FORM_VALUE = {
  tag: { text: 'custom tag', colorCode: 'pebbel.500' }
};

export const CSV_DELIMITER = ',';
export const FORM_MESSAGES = {
  required: 'This field is required',
  invalid: 'This field is invalid'
};

export const MAX_WEIGHT = 5; //kg
export const MAX_DIMENSION = 20250;

export const FORM_VALIDATIONS = {
  rq: { required: true },
  maxL: (length) => {
    maxLength: length;
  },
  minL: (length) => {
    minLength: length;
  }
};

export const FORM_DEFAULTS_VALUES = {
  name: '',
  brand: '',
  category: null,
  tag: { name: TAG_DEFAULT_NAME, color: TAG_DEFAULT_COLOR },
  stock: 0,
  description: '',
  sku: '',
  requiresBoxPackaging: true,
  requiresBubbleWrapPackaging: true, // Could be wrong
  isFragile: true,
  prepackaged: true
};

export const PRODUCTS_STEPS = {
  OVERVIEW: 1,
  SALES_PRICING: 2,
  PACKAGES_PREFERENCE: 3,
  RETURN_INFORMATION: 4
};

export const PRODUCTS_ADD_TYPES = {
  MANUAL: 1,
  UPLOAD: 2
};

export const Mode = {
  ADD: 'ADD',
  EDIT: 'EDIT'
};

export const PRODUCT_KEY_PARAM = {
  IS_CREATE_PRODUCT_MODAL: 'isCreateProductModal'
};

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Inventory'
  }
];

export const INVENTORY_BREADCRUMB_TEXT = {
  product: 'Products',
  bundle: 'Bundles'
};

export const PRODUCT_TAB = {
  product: {
    key: 'product',
    label: 'All'
  },
  bundle: {
    key: 'bundle',
    label: 'Bundles'
  }
};

export const country = [
  {
    value: 'BD',
    label: 'Bangladesh'
  },
  {
    value: 'BE',
    label: 'Belgium'
  },
  {
    value: 'BF',
    label: 'Burkina Faso'
  },
  {
    value: 'BG',
    label: 'Bulgaria'
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina'
  },
  {
    value: 'BB',
    label: 'Barbados'
  },
  {
    value: 'WF',
    label: 'Wallis and Futuna'
  },
  {
    value: 'BL',
    label: 'Saint Barthelemy'
  },
  {
    value: 'BM',
    label: 'Bermuda'
  },
  {
    value: 'BN',
    label: 'Brunei'
  },
  {
    value: 'BO',
    label: 'Bolivia'
  },
  {
    value: 'BH',
    label: 'Bahrain'
  },
  {
    value: 'BI',
    label: 'Burundi'
  },
  {
    value: 'BJ',
    label: 'Benin'
  },
  {
    value: 'BT',
    label: 'Bhutan'
  },
  {
    value: 'JM',
    label: 'Jamaica'
  },
  {
    value: 'BV',
    label: 'Bouvet Island'
  },
  {
    value: 'BW',
    label: 'Botswana'
  },
  {
    value: 'WS',
    label: 'Samoa'
  },
  {
    value: 'BQ',
    label: 'Bonaire, Saint Eustatius and Saba '
  },
  {
    value: 'BR',
    label: 'Brazil'
  },
  {
    value: 'BS',
    label: 'Bahamas'
  },
  {
    value: 'JE',
    label: 'Jersey'
  },
  {
    value: 'BY',
    label: 'Belarus'
  },
  {
    value: 'BZ',
    label: 'Belize'
  },
  {
    value: 'RU',
    label: 'Russia'
  },
  {
    value: 'RW',
    label: 'Rwanda'
  },
  {
    value: 'RS',
    label: 'Serbia'
  },
  {
    value: 'TL',
    label: 'East Timor'
  },
  {
    value: 'RE',
    label: 'Reunion'
  },
  {
    value: 'TM',
    label: 'Turkmenistan'
  },
  {
    value: 'TJ',
    label: 'Tajikistan'
  },
  {
    value: 'RO',
    label: 'Romania'
  },
  {
    value: 'TK',
    label: 'Tokelau'
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau'
  },
  {
    value: 'GU',
    label: 'Guam'
  },
  {
    value: 'GT',
    label: 'Guatemala'
  },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'GR',
    label: 'Greece'
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea'
  },
  {
    value: 'GP',
    label: 'Guadeloupe'
  },
  {
    value: 'JP',
    label: 'Japan'
  },
  {
    value: 'GY',
    label: 'Guyana'
  },
  {
    value: 'GG',
    label: 'Guernsey'
  },
  {
    value: 'GF',
    label: 'French Guiana'
  },
  {
    value: 'GE',
    label: 'Georgia'
  },
  {
    value: 'GD',
    label: 'Grenada'
  },
  {
    value: 'GB',
    label: 'United Kingdom'
  },
  {
    value: 'GA',
    label: 'Gabon'
  },
  {
    value: 'SV',
    label: 'El Salvador'
  },
  {
    value: 'GN',
    label: 'Guinea'
  },
  {
    value: 'GM',
    label: 'Gambia'
  },
  {
    value: 'GL',
    label: 'Greenland'
  },
  {
    value: 'GI',
    label: 'Gibraltar'
  },
  {
    value: 'GH',
    label: 'Ghana'
  },
  {
    value: 'OM',
    label: 'Oman'
  },
  {
    value: 'TN',
    label: 'Tunisia'
  },
  {
    value: 'JO',
    label: 'Jordan'
  },
  {
    value: 'HR',
    label: 'Croatia'
  },
  {
    value: 'HT',
    label: 'Haiti'
  },
  {
    value: 'HU',
    label: 'Hungary'
  },
  {
    value: 'HK',
    label: 'Hong Kong'
  },
  {
    value: 'HN',
    label: 'Honduras'
  },
  {
    value: 'HM',
    label: 'Heard Island and McDonald Islands'
  },
  {
    value: 'VE',
    label: 'Venezuela'
  },
  {
    value: 'PR',
    label: 'Puerto Rico'
  },
  {
    value: 'PS',
    label: 'Palestinian Territory'
  },
  {
    value: 'PW',
    label: 'Palau'
  },
  {
    value: 'PT',
    label: 'Portugal'
  },
  {
    value: 'SJ',
    label: 'Svalbard and Jan Mayen'
  },
  {
    value: 'PY',
    label: 'Paraguay'
  },
  {
    value: 'IQ',
    label: 'Iraq'
  },
  {
    value: 'PA',
    label: 'Panama'
  },
  {
    value: 'PF',
    label: 'French Polynesia'
  },
  {
    value: 'PG',
    label: 'Papua New Guinea'
  },
  {
    value: 'PE',
    label: 'Peru'
  },
  {
    value: 'PK',
    label: 'Pakistan'
  },
  {
    value: 'PH',
    label: 'Philippines'
  },
  {
    value: 'PN',
    label: 'Pitcairn'
  },
  {
    value: 'PL',
    label: 'Poland'
  },
  {
    value: 'PM',
    label: 'Saint Pierre and Miquelon'
  },
  {
    value: 'ZM',
    label: 'Zambia'
  },
  {
    value: 'EH',
    label: 'Western Sahara'
  },
  {
    value: 'EE',
    label: 'Estonia'
  },
  {
    value: 'EG',
    label: 'Egypt'
  },
  {
    value: 'ZA',
    label: 'South Africa'
  },
  {
    value: 'EC',
    label: 'Ecuador'
  },
  {
    value: 'IT',
    label: 'Italy'
  },
  {
    value: 'VN',
    label: 'Vietnam'
  },
  {
    value: 'SB',
    label: 'Solomon Islands'
  },
  {
    value: 'ET',
    label: 'Ethiopia'
  },
  {
    value: 'SO',
    label: 'Somalia'
  },
  {
    value: 'ZW',
    label: 'Zimbabwe'
  },
  {
    value: 'SA',
    label: 'Saudi Arabia'
  },
  {
    value: 'ES',
    label: 'Spain'
  },
  {
    value: 'ER',
    label: 'Eritrea'
  },
  {
    value: 'ME',
    label: 'Montenegro'
  },
  {
    value: 'MD',
    label: 'Moldova'
  },
  {
    value: 'MG',
    label: 'Madagascar'
  },
  {
    value: 'MF',
    label: 'Saint Martin'
  },
  {
    value: 'MA',
    label: 'Morocco'
  },
  {
    value: 'MC',
    label: 'Monaco'
  },
  {
    value: 'UZ',
    label: 'Uzbekistan'
  },
  {
    value: 'MM',
    label: 'Myanmar'
  },
  {
    value: 'ML',
    label: 'Mali'
  },
  {
    value: 'MO',
    label: 'Macao'
  },
  {
    value: 'MN',
    label: 'Mongolia'
  },
  {
    value: 'MH',
    label: 'Marshall Islands'
  },
  {
    value: 'MK',
    label: 'Macedonia'
  },
  {
    value: 'MU',
    label: 'Mauritius'
  },
  {
    value: 'MT',
    label: 'Malta'
  },
  {
    value: 'MW',
    label: 'Malawi'
  },
  {
    value: 'MV',
    label: 'Maldives'
  },
  {
    value: 'MQ',
    label: 'Martinique'
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands'
  },
  {
    value: 'MS',
    label: 'Montserrat'
  },
  {
    value: 'MR',
    label: 'Mauritania'
  },
  {
    value: 'IM',
    label: 'Isle of Man'
  },
  {
    value: 'UG',
    label: 'Uganda'
  },
  {
    value: 'TZ',
    label: 'Tanzania'
  },
  {
    value: 'MY',
    label: 'Malaysia'
  },
  {
    value: 'MX',
    label: 'Mexico'
  },
  {
    value: 'IL',
    label: 'Israel'
  },
  {
    value: 'FR',
    label: 'France'
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory'
  },
  {
    value: 'SH',
    label: 'Saint Helena'
  },
  {
    value: 'FI',
    label: 'Finland'
  },
  {
    value: 'FJ',
    label: 'Fiji'
  },
  {
    value: 'FK',
    label: 'Falkland Islands'
  },
  {
    value: 'FM',
    label: 'Micronesia'
  },
  {
    value: 'FO',
    label: 'Faroe Islands'
  },
  {
    value: 'NI',
    label: 'Nicaragua'
  },
  {
    value: 'NL',
    label: 'Netherlands'
  },
  {
    value: 'NO',
    label: 'Norway'
  },
  {
    value: 'NA',
    label: 'Namibia'
  },
  {
    value: 'VU',
    label: 'Vanuatu'
  },
  {
    value: 'NC',
    label: 'New Caledonia'
  },
  {
    value: 'NE',
    label: 'Niger'
  },
  {
    value: 'NF',
    label: 'Norfolk Island'
  },
  {
    value: 'NG',
    label: 'Nigeria'
  },
  {
    value: 'NZ',
    label: 'New Zealand'
  },
  {
    value: 'NP',
    label: 'Nepal'
  },
  {
    value: 'NR',
    label: 'Nauru'
  },
  {
    value: 'NU',
    label: 'Niue'
  },
  {
    value: 'CK',
    label: 'Cook Islands'
  },
  {
    value: 'XK',
    label: 'Kosovo'
  },
  {
    value: 'CI',
    label: 'Ivory Coast'
  },
  {
    value: 'CH',
    label: 'Switzerland'
  },
  {
    value: 'CO',
    label: 'Colombia'
  },
  {
    value: 'CN',
    label: 'China'
  },
  {
    value: 'CM',
    label: 'Cameroon'
  },
  {
    value: 'CL',
    label: 'Chile'
  },
  {
    value: 'CC',
    label: 'Cocos Islands'
  },
  {
    value: 'CA',
    label: 'Canada'
  },
  {
    value: 'CG',
    label: 'Republic of the Congo'
  },
  {
    value: 'CF',
    label: 'Central African Republic'
  },
  {
    value: 'CD',
    label: 'Democratic Republic of the Congo'
  },
  {
    value: 'CZ',
    label: 'Czech Republic'
  },
  {
    value: 'CY',
    label: 'Cyprus'
  },
  {
    value: 'CX',
    label: 'Christmas Island'
  },
  {
    value: 'CR',
    label: 'Costa Rica'
  },
  {
    value: 'CW',
    label: 'Curacao'
  },
  {
    value: 'CV',
    label: 'Cape Verde'
  },
  {
    value: 'CU',
    label: 'Cuba'
  },
  {
    value: 'SZ',
    label: 'Swaziland'
  },
  {
    value: 'SY',
    label: 'Syria'
  },
  {
    value: 'SX',
    label: 'Sint Maarten'
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan'
  },
  {
    value: 'KE',
    label: 'Kenya'
  },
  {
    value: 'SS',
    label: 'South Sudan'
  },
  {
    value: 'SR',
    label: 'Suriname'
  },
  {
    value: 'KI',
    label: 'Kiribati'
  },
  {
    value: 'KH',
    label: 'Cambodia'
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis'
  },
  {
    value: 'KM',
    label: 'Comoros'
  },
  {
    value: 'ST',
    label: 'Sao Tome and Principe'
  },
  {
    value: 'SK',
    label: 'Slovakia'
  },
  {
    value: 'KR',
    label: 'South Korea'
  },
  {
    value: 'SI',
    label: 'Slovenia'
  },
  {
    value: 'KP',
    label: 'North Korea'
  },
  {
    value: 'KW',
    label: 'Kuwait'
  },
  {
    value: 'SN',
    label: 'Senegal'
  },
  {
    value: 'SM',
    label: 'San Marino'
  },
  {
    value: 'SL',
    label: 'Sierra Leone'
  },
  {
    value: 'SC',
    label: 'Seychelles'
  },
  {
    value: 'KZ',
    label: 'Kazakhstan'
  },
  {
    value: 'KY',
    label: 'Cayman Islands'
  },
  {
    value: 'SG',
    label: 'Singapore'
  },
  {
    value: 'SE',
    label: 'Sweden'
  },
  {
    value: 'SD',
    label: 'Sudan'
  },
  {
    value: 'DO',
    label: 'Dominican Republic'
  },
  {
    value: 'DM',
    label: 'Dominica'
  },
  {
    value: 'DJ',
    label: 'Djibouti'
  },
  {
    value: 'DK',
    label: 'Denmark'
  },
  {
    value: 'VG',
    label: 'British Virgin Islands'
  },
  {
    value: 'DE',
    label: 'Germany'
  },
  {
    value: 'YE',
    label: 'Yemen'
  },
  {
    value: 'DZ',
    label: 'Algeria'
  },
  {
    value: 'US',
    label: 'United States'
  },
  {
    value: 'UY',
    label: 'Uruguay'
  },
  {
    value: 'YT',
    label: 'Mayotte'
  },
  {
    value: 'UM',
    label: 'United States Minor Outlying Islands'
  },
  {
    value: 'LB',
    label: 'Lebanon'
  },
  {
    value: 'LC',
    label: 'Saint Lucia'
  },
  {
    value: 'LA',
    label: 'Laos'
  },
  {
    value: 'TV',
    label: 'Tuvalu'
  },
  {
    value: 'TW',
    label: 'Taiwan'
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago'
  },
  {
    value: 'TR',
    label: 'Turkey'
  },
  {
    value: 'LK',
    label: 'Sri Lanka'
  },
  {
    value: 'LI',
    label: 'Liechtenstein'
  },
  {
    value: 'LV',
    label: 'Latvia'
  },
  {
    value: 'TO',
    label: 'Tonga'
  },
  {
    value: 'LT',
    label: 'Lithuania'
  },
  {
    value: 'LU',
    label: 'Luxembourg'
  },
  {
    value: 'LR',
    label: 'Liberia'
  },
  {
    value: 'LS',
    label: 'Lesotho'
  },
  {
    value: 'TH',
    label: 'Thailand'
  },
  {
    value: 'TF',
    label: 'French Southern Territories'
  },
  {
    value: 'TG',
    label: 'Togo'
  },
  {
    value: 'TD',
    label: 'Chad'
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands'
  },
  {
    value: 'LY',
    label: 'Libya'
  },
  {
    value: 'VA',
    label: 'Vatican'
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'AE',
    label: 'United Arab Emirates'
  },
  {
    value: 'AD',
    label: 'Andorra'
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda'
  },
  {
    value: 'AF',
    label: 'Afghanistan'
  },
  {
    value: 'AI',
    label: 'Anguilla'
  },
  {
    value: 'VI',
    label: 'U.S. Virgin Islands'
  },
  {
    value: 'IS',
    label: 'Iceland'
  },
  {
    value: 'IR',
    label: 'Iran'
  },
  {
    value: 'AM',
    label: 'Armenia'
  },
  {
    value: 'AL',
    label: 'Albania'
  },
  {
    value: 'AO',
    label: 'Angola'
  },
  {
    value: 'AQ',
    label: 'Antarctica'
  },
  {
    value: 'AS',
    label: 'American Samoa'
  },
  {
    value: 'AR',
    label: 'Argentina'
  },
  {
    value: 'AU',
    label: 'Australia'
  },
  {
    value: 'AT',
    label: 'Austria'
  },
  {
    value: 'AW',
    label: 'Aruba'
  },
  {
    value: 'IN',
    label: 'India'
  },
  {
    value: 'AX',
    label: 'Aland Islands'
  },
  {
    value: 'AZ',
    label: 'Azerbaijan'
  },
  {
    value: 'IE',
    label: 'Ireland'
  },
  {
    value: 'ID',
    label: 'Indonesia'
  },
  {
    value: 'UA',
    label: 'Ukraine'
  },
  {
    value: 'QA',
    label: 'Qatar'
  },
  {
    value: 'MZ',
    label: 'Mozambique'
  }
];

export const PRODUCT_SELECTION_TITLE_MODAL = ['Product selection', 'Premium Bedding Bundle'];

export const CONFIRMATION_TITLE_MODAL = ['Confirmation', 'Premium Bedding Bundle'];

export const PAGES_OPTIONS_DEFAULT = [5, 10, 15];
export const ITEM_PER_PAGES = 20;
export const PAGE_DEFAULT = 1;
export const productDetail = {
  category: '',
  description: '',
  sku: '',
  ean: '',
  selazarUniqueID: '',
  status: '',
  isPrePacked: true,
  depth: 0,
  width: 0,
  height: 0,
  weight: 0,
  commodityCode: '',
  countryCodeOfManufacture: '',
  brand: '',
  currentPrice: 0,
  stockCount: 0,
  units: 0,
  requiresBoxPackaging: false,
  requiresPaperPackaging: false,
  isFragile: false
};

export const PRODUCT_QUANTITY_OPTIONS = [
  {
    value: 1,
    label: 1
  },
  {
    value: 2,
    label: 2
  },
  {
    value: 3,
    label: 3
  },
  {
    value: 4,
    label: 4
  },
  {
    value: 5,
    label: 5
  },
  {
    value: 6,
    label: 6
  },
  {
    value: 7,
    label: 7
  },
  {
    value: 8,
    label: 8
  },
  {
    value: 9,
    label: 9
  },
  {
    value: 10,
    label: 10
  }
];

export const returnPreferencesOptions = ({ isReadOnly }) => {
  return [
    {
      id: 0,
      name: 'Returnable',
      value: true,
      isReadOnly: isReadOnly,
      isNoSelection: isReadOnly
    },
    {
      id: 1,
      name: 'Non-returnable',
      value: false,
      isReadOnly: isReadOnly,
      isNoSelection: isReadOnly
    }
  ];
};

export const STOCK_STATUS_COLOR = {
  GOOD: '#CCE5FF',
  OK: '#FECB2E80',
  LOW: '#F5CCD3',
  UNKNOWN: '#CAD4DC'
};

export const orderInformationCols = [
  {
    field: 'image',
    type: COL_CUSTOM,
    width: 48,
    render: ({ row, variant }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
            h: '100%',
            maxH: '100%',
            py: 0
          }}>
          {row?.isBundle ? (
            <BundleIcon />
          ) : (
            <Image
              w="full"
              h="full"
              loading="lazy"
              src={row?.image || DEFAULT_IMAGE}
              alt=""
              srcSet={row?.image || DEFAULT_IMAGE}
              fallbackSrc={row?.image || DEFAULT_IMAGE}
            />
          )}
        </Flex>
      );
    }
  },
  {
    field: 'name',
    cellHeader: (row) => <Text fontSize="md">{row.name}</Text>,
    cellValue: (row) => <Text fontSize="xs">SKU {row.skuCode}</Text> || ''
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    cellValue: (row) => row['ean'] || row['suid'] || 'N/A'
  },
  {
    header: 'Ware House',
    field: 'wareHouse',
    type: 'string'
  },
  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number',
    cellValue: (row) => (
      <Text fontSize="md" ml="1rem">
        {row.quantity}
      </Text>
    )
  },
  {
    field: 'weight',
    header: 'Weight',
    type: 'number',
    cellValue: (row) => `${row.weight} kg`
  },
  {
    field: 'price',
    header: 'Price',
    type: 'string'
  }
];
