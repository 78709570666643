import { HStack, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';
import { SlzTag } from 'components/common/SlzTag';

import { USER_SETTING_MODAL_LAYOUT } from 'modules/preferences/constants';
import { PACKAGE_SETTINGS_STYLE } from 'modules/preferences/styles';

const PackagingInfo = ({
  packageSetting,
  onManagedPackaging,
  isEnabled,
  packagingOverview,
  packagingType
}) => {
  return (
    <HStack>
      <HStack {...PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.title}>
        {packageSetting.icon}
        <Text>{packageSetting.title}</Text>
      </HStack>
      <SlzButton
        onClick={() =>
          onManagedPackaging(USER_SETTING_MODAL_LAYOUT.managedLibraries.key, packagingType)
        }
        isDisabled={!isEnabled}
        {...PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.button}>
        {packageSetting.button}
      </SlzButton>
      <SlzTag
        {...PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.tag}
        sx={!isEnabled && PACKAGE_SETTINGS_STYLE.PACKAGE_TOGGLE_CARD.tag.disabled}>
        <Text>
          {packagingOverview?.count} {packageSetting.tagText}
        </Text>
      </SlzTag>
    </HStack>
  );
};

export default PackagingInfo;
