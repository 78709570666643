import { AddIcon } from '@chakra-ui/icons';
import { useFormContext } from 'react-hook-form';

import { SlzTag } from 'components/common/SlzTag';
import useSlzToast from 'hooks/useSlzToast';

import {
  B2B_CUSTOM_BOX_SPLIT_CONTENT,
  B2B_CUSTOM_BOX_SPLIT_TOAST,
  CUSTOM_BOX_PREFIX
} from 'modules/b2b-orders/constants';
import { CUSTOM_BOX_SPLIT_BUTTONS_STYLE } from 'modules/b2b-orders/constants/styles';
import { useBulkOrderLimitQuery } from 'modules/b2b-orders/hooks/useBulkOrderLimitQuery';
import { customBoxDefaultValuesMapper, filterArray } from 'modules/b2b-orders/utils';

const AddBoxButton = ({ defaultValues }) => {
  const { setValue, getValues } = useFormContext();
  const [_, setToast] = useSlzToast();
  const { data: limit } = useBulkOrderLimitQuery();

  const handleAddBox = () => {
    const formBoxes = filterArray(getValues(CUSTOM_BOX_PREFIX));
    if (formBoxes.length < limit.boxLimit) {
      formBoxes.push({
        ...customBoxDefaultValuesMapper(defaultValues)
      });
      setValue(CUSTOM_BOX_PREFIX, formBoxes);
    }

    if (formBoxes.length > limit.boxLimit) {
      setToast({
        description: B2B_CUSTOM_BOX_SPLIT_TOAST.error.maximumLimitOfBoxes,
        status: B2B_CUSTOM_BOX_SPLIT_TOAST.error.status,
        colorScheme: B2B_CUSTOM_BOX_SPLIT_TOAST.error.colorScheme
      });
    }
  };
  return (
    <SlzTag {...CUSTOM_BOX_SPLIT_BUTTONS_STYLE.addButton.tag} onClick={handleAddBox}>
      <AddIcon {...CUSTOM_BOX_SPLIT_BUTTONS_STYLE.addButton.icon} />
      {B2B_CUSTOM_BOX_SPLIT_CONTENT.buttons.add}
    </SlzTag>
  );
};

export default AddBoxButton;
