import { CONSIGNMENT_ARRIVING_TYPES } from "../constants";

export const transform = (consignment) => {
  const data = {
    gtin: consignment?.gtin,
    companyItemID: consignment?.product.companyItemID,
    stockQuantity: consignment?.totalQuantityItems,
    boxQuantity: consignment?.stockBoxQuantity,
    itemsPerBox: consignment?.itemsPerStockbox,
    batchNumber: consignment?.batchNumber,
    expiryDate: consignment?.expiryDate && formatDate(consignment?.expiryDate),
    supplierPrintedLabels: !consignment?.enabledLabelling,
    requiresGTIN: consignment?.gtinSwitch,
    shippingReference: consignment?.shippingReference,
    isPallet: consignment?.consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.PALLETS,
    boxHeight: consignment?.consignmentBoxDimensions?.height,
    boxWidth: consignment?.consignmentBoxDimensions?.width,
    boxDepth: consignment?.consignmentBoxDimensions?.depth,
    boxWeight: consignment?.consignmentBoxDimensions?.weight,
    stockBoxHeight: consignment?.stockBoxDimensions.height,
    stockBoxWidth: consignment?.stockBoxDimensions.width,
    stockBoxDepth: consignment?.stockBoxDimensions.depth,
    stockBoxWeight: consignment?.stockBoxDimensions.weight,
    skuCode: consignment?.product.sku,
    pickFromPallet: consignment?.consignmentArrivingType === CONSIGNMENT_ARRIVING_TYPES.PALLETS,
    companyItemIsPFP: consignment?.product.pickFromPallet,
    ignore_whitespace: false
  }

  return data;
};