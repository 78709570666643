import React from 'react';

import { FormProvider } from 'react-hook-form';
import { Box, Flex, FormControl, Heading } from '@chakra-ui/react';
import * as yup from 'yup';

import { SlzButton } from 'components/common/SlzButton';
import SlzInput from 'components/common/SlzInput/SlzInput';
import FormLabel from './FormLabel';
import IntegrationForm from './IntegrationForm';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';

const validationSchema = yup.object({
  // shopName: yup.string().required('Store name is required'),
  // storeUrl: yup.string().required('Store URL is required'),
  // key: yup.string().required('Customer key is required'),
  // secret: yup.string().required('Customer secret is required')
  shopName: yup.string(),
  storeUrl: yup.string(),
  key: yup.string(),
  secret: yup.string()
});

export default function EditWooCommerceCredentials({ onSubmit, data, isSubmitting }) {
  const defaultValues = {
    shopName: data?.metadata?.shopName,
    storeUrl: data?.metadata?.storeUrl,
    key: '**************************',
    secret: '**************************'
  };

  return (
    <Box>
      <IntegrationSkeleton isLoaded template="editCredentials">
        <IntegrationForm validationSchema={validationSchema} defaultValues={defaultValues}>
          {({
            methods,
            errors,
            dirtyFields,
            getFieldStatus,
            resetFieldStatus,
            resetAllFieldsStatus,
            clearField
          }) => {
            return (
              <FormProvider {...methods}>
                <form
                  onSubmit={methods?.handleSubmit((values) => onSubmit(values, dirtyFields))}
                  style={{ width: '100%' }}>
                  <FormControl mb="5" isInvalid={errors.shopName}>
                    <FormLabel>Store name</FormLabel>
                    <SlzInput
                      {...methods.register('shopName')}
                      showIcon
                      // placeholder={errors?.shopName?.message}
                      // status={getFieldStatus('shopName')}
                      // onFocus={() => resetFieldStatus('shopName')}
                      // onClickError={() => clearField('shopName')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.storeUrl}>
                    <FormLabel>Store URL</FormLabel>
                    <SlzInput
                      {...methods.register('storeUrl')}
                      showIcon
                      // placeholder={errors?.storeUrl?.message}
                      // status={getFieldStatus('storeUrl')}
                      // onFocus={() => resetFieldStatus('storeUrl')}
                      // onClickError={() => clearField('storeUrl')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.key}>
                    <FormLabel>Customer key</FormLabel>
                    <SlzInput
                      type="password"
                      {...methods.register('key')}
                      showIcon
                      // placeholder={errors.key?.message}
                      // status={getFieldStatus('key')}
                      // onFocus={() => resetFieldStatus('key')}
                      // onClickError={() => clearField('key')}
                    />
                  </FormControl>

                  <FormControl mb="5" isInvalid={errors.secret}>
                    <FormLabel>Customer secret</FormLabel>
                    <SlzInput
                      type="password"
                      {...methods.register('secret')}
                      showIcon
                      // placeholder={errors.secret?.message}
                      // status={getFieldStatus('secret')}
                      // onFocus={() => resetFieldStatus('secret')}
                      // onClickError={() => clearField('secret')}
                    />
                  </FormControl>

                  <Flex mb={5} mt={10} justifyContent="end">
                    <SlzButton
                      type="submit"
                      size="lg"
                      onClick={resetAllFieldsStatus}
                      disabled={isSubmitting}
                      isLoading={isSubmitting}>
                      Save
                    </SlzButton>
                  </Flex>
                </form>
              </FormProvider>
            );
          }}
        </IntegrationForm>
      </IntegrationSkeleton>
    </Box>
  );
}
