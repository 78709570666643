import { useMutation } from 'react-query';
import { uploadSingleImage, createProduct } from '../services/products-api';

export default function useCreateProductMutation() {
  return useMutation({
    mutationFn: async ({ payload, imageFile }) => {
      const response = await createProduct(payload);
      const {
        data: { data }
      } = response;

      await uploadSingleImage(imageFile, data?.itemID);

      return response;
    }
  });
}
