import { createContext, useContext, useReducer } from 'react';
import { INITIAL_BULK_ORDER_STATE } from '../constants';
import { bulkOrderReducer } from '../reducers';

export const BulkOrderContext = createContext(null);

export const BulkOrderProvider = ({ children }) => {
  const [bulkOrder, dispatch] = useReducer(bulkOrderReducer, INITIAL_BULK_ORDER_STATE);
  return (
    <BulkOrderContext.Provider value={{ bulkOrder, bulkOrderDispatch: dispatch }}>
      {children}
    </BulkOrderContext.Provider>
  );
};

export const useBulkOrderContext = () => useContext(BulkOrderContext);
