import { CONSIGNMENTS_PATHNAME, CONSIGNMENTS_ONHOLD_PATHNAME } from '../constants';
import { ConsignmentsProvider } from '../context/ConsignmentsContext';
import ConsignmentDetail from '../pages/consignment-detail';
import ConsignmentsList from '../pages/consignments-list';
import ConsignmentOnHoldDetail from '../pages/consignment-onhold-detail'

const Consignments = () => (
  <ConsignmentsProvider>
      <ConsignmentsList />
  </ConsignmentsProvider>
);

const ConsignmentsRoutes = [
  {
    path: CONSIGNMENTS_PATHNAME,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: Consignments
  },
  {
    path: `${CONSIGNMENTS_PATHNAME}/:consignmentId`,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: ConsignmentDetail
  },
  {
    path: `${CONSIGNMENTS_ONHOLD_PATHNAME}/:consignmentId`,
    profile: 'Supplier',
    feature: 'ViewOrder',
    exact: true,
    component: ConsignmentOnHoldDetail
  }
];

export default ConsignmentsRoutes;
