import React from 'react';
import { Icon } from '@chakra-ui/react';

const DotIcon = (props) => {
  const { color = 'dark.700', ...rest } = props;
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill={color}
      {...rest}>
      <circle id="Ellipse_1225" data-name="Ellipse 1225" cx="3" cy="3" r="3" />
    </Icon>
  );
};

export default DotIcon;
