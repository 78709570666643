import { useEffect, useState } from 'react';
import { GridItem, useDisclosure } from '@chakra-ui/react';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import { BREAD_CRUMB_USERS_ITEMS, USERS_TABLE } from 'modules/users/constants';
import { useGlobalState } from 'contexts/GlobalContext';
import { SlzGrid } from 'components/common/SlzGrid';
import UsersTable from 'modules/users/components/table/UsersTable';
import { UsersFilter } from 'modules/users/components/other';
import { useUsersQuery } from '../hooks/useUsersQuery';
import { EditUserModal } from '../components/modal';

const UsersList = () => {
  const [_, dispatch] = useGlobalState();
  const editUserModal = useDisclosure();
  const [enableQuery, setEnableQuery] = useState(false);
  const [pageSize, setPageSize] = useState(USERS_TABLE.ITEM_PER_PAGES);
  const [pageIndex, setPageIndex] = useState(USERS_TABLE.PAGE_DEFAULT);
  const [totalPage, setTotalPage] = useState(null);
  const [editUserId, setEditUserId] = useState();
  const [users, setUsers] = useState([]);
  const [searchName, setSearchName] = useState('');

  const { data, isLoading, refetch, isError, isFetching } = useUsersQuery({
    filters: { name: searchName, sortBy: '', pageIndex, pageSize },
    enabled: enableQuery
  });

  const handleOnEditUser = (userId) => {
    if (!userId) return;
    editUserModal.onOpen();
    setEditUserId(userId);
  };

  const handleResetFilter = () => {
    setSearchName('');
    refetch();
  };

  useEffect(() => {
    setEnableQuery(true);
  }, [pageSize, pageIndex, searchName]);

  useEffect(() => {
    if (data?.pageCount >= 0) {
      setTotalPage(data?.pageCount);
    }
  }, [data?.pageCount]);

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: BREAD_CRUMB_USERS_ITEMS
    });
  }, []);

  useEffect(() => {
    setUsers(data);
  }, [isFetching]);

  return (
    <>
      <EditUserModal
        isOpen={editUserModal.isOpen}
        onClose={editUserModal.onClose}
        userId={editUserId}
        refetch={refetch}
      />
      <SlzGrid>
        <GridItem colSpan={{ base: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <UsersFilter
            setUsers={setUsers}
            onReset={handleResetFilter}
            setSearchName={setSearchName}
          />
          <UsersTable
            data={users}
            isLoading={isLoading}
            pageSize={pageSize}
            pageIndex={pageIndex}
            totalPage={totalPage}
            setPageSize={setPageSize}
            setPageIndex={setPageIndex}
            refetch={refetch}
            isEmptyPage={isError || totalPage === 0}
            onEditUser={handleOnEditUser}
          />
        </GridItem>
      </SlzGrid>
    </>
  );
};

export default UsersList;
