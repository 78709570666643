import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
const baseStyle = defineStyle((props) => {
  return {
    boxShadow: '0rem 0.125rem 0.5rem #00000033',
    itemSelect: itemSelect,
    rightButtonIcon: rightButtonIcon(props),
    rightIcon: rightIcon(props)
  };
});
const sizes = {
  lg: defineStyle({
    fontSize: '1rem'
  }),
  md: defineStyle({
    fontSize: '0.875rem'
  }),
  sm: defineStyle({
    fontSize: '0.75rem'
  })
};

const itemSelect = {
  minH: '1.875rem',
  lineHeight: '1.875rem',
  px: 3,
  mx: 0.75,
  color: '#323338',
  fontSize: '0.875rem',
  cursor: 'pointer',
  _hover: {
    bg: '#f2f2f2'
  },
  _first: {
    mt: 0.75
  },
  _last: {
    mb: 0.75
  }
};
const rightButtonIcon = defineStyle((props) => {
  const { colorScheme } = props;
  return {
    bg: `${colorScheme}.300`,
    _hover: {
      bg: `${colorScheme}.300`
    }
  };
});
const rightIcon = defineStyle((props) => {
  const { size } = props;
  switch (size) {
    case 'lg':
    case 'md':
      return {
        width: '24px',
        height: '24px'
      };
    case 'sm':
      return {
        width: '12px',
        height: '12px'
      };
    default:
      break;
  }
});

const defaultProps = {
  colorScheme: 'main'
};

export default defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps
});
