import { useContext, createContext, useReducer } from 'react';
import rootReducer from '../reducers';

const GlobalContext = createContext();

const initialState = {
  app: null
};

export function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return <GlobalContext.Provider value={[state, dispatch]}>{children}</GlobalContext.Provider>;
}

export const useGlobalState = () => useContext(GlobalContext);
