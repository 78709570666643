import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';

const BulkOrderCustomerDetail = (props) => {
  const { customer, isLoading, titleCustomer, titleAddress } = props;

  const getAddress = () => {
    const address = [
      customer?.shippingAddress?.addressLine1,
      customer?.shippingAddress?.addressLine2,
      customer?.shippingAddress?.postcode,
      customer?.shippingAddress?.town,
      customer?.shippingAddress?.county,
      customer?.shippingAddress?.country
    ];
    return address.join(', ');
  };

  return (
    <SlzPaper
      w={props.w}
      h={props.h}
      minH="20.75rem"
      borderRadius="0.375rem"
      bg="light.500"
      boxShadow="0px 3px 6px #00000029"
      overflow="hidden">
      {isLoading ? (
        <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
      ) : (
        <>
          <VStack>
            <Box
              w="100%"
              borderBottomWidth="0.188rem"
              borderBottomColor="light.300"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {titleCustomer}
            </Box>
            <Box w="100%" pl={8} py={4}>
              <HStack spacing="2rem">
                <Box alignSelf="flex-start">{props.iconTop}</Box>
                <Box w="60%" fontSize="0.875rem">
                  <Text>{customer?.contactDetails?.name}</Text>
                  <Text>{customer?.contactDetails?.emailAddress}</Text>
                  <Text>{customer?.contactDetails?.contactNumber}</Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
          <VStack>
            <Box
              w="100%"
              borderBottomWidth="0.188rem"
              borderBottomColor="light.300"
              p={4}
              pl={8}
              fontWeight="bold"
              fontSize="1rem">
              {titleAddress}
            </Box>
            <Box w="100%" pl={8} py={4}>
              <HStack spacing="2rem">
                <Box alignSelf="flex-start">{props.iconBottom}</Box>
                <Box w="60%" fontSize="0.875rem">
                  <Text>{getAddress()}</Text>
                </Box>
              </HStack>
            </Box>
          </VStack>
        </>
      )}
    </SlzPaper>
  );
};

export default BulkOrderCustomerDetail;
