import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useMutation } from 'react-query';

import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import useSlzToast from 'hooks/useSlzToast';
import { HeaderTooltip, PreferenceHeader } from 'modules/preferences/components/common';
import { EORITable } from 'modules/preferences/components/table';
import {
  TAXE_AND_DUTY_MESSAGE,
  TAX_AND_DUTY_FEATURE_HEADERS,
  USER_SETTING_FORMS_DEFAULT_NAME
} from 'modules/preferences/constants';
import { removeEORIDetails, updateEORIDetails } from 'modules/preferences/services/preferences-api';

const EORINumbersBody = ({ eoriDetailsMethods, children }) => {
  return (
    <FormProvider {...eoriDetailsMethods}>
      <form>{children}</form>
    </FormProvider>
  );
};

const EORINumbers = ({ eoriNumbers, isFetching, refetch }) => {
  const [enabledRows, setEnabledRows] = useState([]);
  const [_, setToast] = useSlzToast();

  const { forms } = useMultipleFormContext();
  const { [USER_SETTING_FORMS_DEFAULT_NAME.eoriNumber]: form } = forms;
  const eoriDetailsMethods = form?.form;

  const { fields } = useFieldArray({
    control: eoriDetailsMethods?.control,
    name: 'eoriNumbers'
  });

  const mutationUpdateEORINumber = useMutation({
    mutationFn: (eoriDetail) => updateEORIDetails(eoriDetail)
  });

  const mutationRemoveEORINumber = useMutation({
    mutationFn: (identifier) => removeEORIDetails(identifier)
  });

  const handleClickRow = (index) => {
    setEnabledRows((enabledRows) => {
      const currentEnabledRows = [...enabledRows];
      if (currentEnabledRows.includes(index)) {
        currentEnabledRows.splice(currentEnabledRows.indexOf(index), 1);
      } else {
        currentEnabledRows.push(index);
      }
      return currentEnabledRows;
    });
  };

  const handleSaveEORINumber = async (eoriNumber, index) => {
    try {
      await mutationUpdateEORINumber.mutateAsync(eoriNumber);
      setToast({ title: TAXE_AND_DUTY_MESSAGE.eori.success });
      handleClickRow(index);
      await refetch();
    } catch (error) {}
  };

  const handleRemoveEORINumber = async (identifier) => {
    try {
      await mutationRemoveEORINumber.mutateAsync(identifier);
      refetch();
    } catch (error) {}
  };

  const resetForm = async () => {
    eoriDetailsMethods.reset({ eoriNumbers });
  };

  useEffect(() => {
    !isFetching && resetForm();
  }, [isFetching]);

  return (
    <Box w="full">
      <PreferenceHeader
        header={TAX_AND_DUTY_FEATURE_HEADERS.EORI_NUMBERS.header}
        description={TAX_AND_DUTY_FEATURE_HEADERS.EORI_NUMBERS.description}
        tooltip={<HeaderTooltip tooltip={TAX_AND_DUTY_FEATURE_HEADERS.EORI_NUMBERS.tooltip} />}
      />
      <EORINumbersBody eoriDetailsMethods={eoriDetailsMethods}>
        <EORITable
          key="eori-table"
          items={fields}
          enableRows={enabledRows}
          onClick={handleClickRow}
          onSave={handleSaveEORINumber}
          onRemove={handleRemoveEORINumber}
        />
      </EORINumbersBody>
    </Box>
  );
};

export default EORINumbers;
