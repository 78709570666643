import { useFormContext } from 'react-hook-form';
import SlzInput from '../../SlzInput/SlzInput';
import { NestedFieldContainer } from './NestedField';

export const NestedInput = ({ fieldProps = {}, ...rest }) => {
  const {
    formState: { errors }
  } = useFormContext();
  return (
    <NestedFieldContainer
      SlzComponent={SlzInput}
      isControlled={true}
      fieldProps={{
        ...{ size: 'sm', autoComplete: 'off', status: errors[rest?.field] && 'error' },
        ...fieldProps
      }}
      {...rest}
    />
  );
};
