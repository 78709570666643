import React from 'react';
import { Icon } from '@chakra-ui/react';

const CustomFillIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="31.108"
      height="28"
      viewBox="0 0 31.108 28"
      {...props}>
      <g id="Group_34229" data-name="Group 34229" transform="translate(-581.332 -674)">
        <g id="Group_1399" data-name="Group 1399" transform="translate(581.332 674)">
          <path
            id="Path_6098"
            data-name="Path 6098"
            d="M212.4,1006a9.11,9.11,0,0,1-7.352-3.784,6.007,6.007,0,0,0-4.792-2.441l-.149,0-.149,0a6.007,6.007,0,0,0-4.792,2.441,1.555,1.555,0,0,1-2.559-1.768,9.33,9.33,0,0,1,15,0,6.006,6.006,0,0,0,4.908,2.44h.068c2.149.045,4.274-1.842,5.088-2.656l3.111-3.111a1.555,1.555,0,0,1,2.2,2.2l-3.111,3.111c-1.341,1.341-4.045,3.567-7.209,3.568Z"
            transform="translate(-192.332 -996.663)"
            fill="#303134"
          />
        </g>
        <g id="Group_34229-2" data-name="Group 34229" transform="translate(581.332 680.224)">
          <path
            id="Path_6098-2"
            data-name="Path 6098"
            d="M212.4,1006a9.11,9.11,0,0,1-7.352-3.784,6.007,6.007,0,0,0-4.792-2.441l-.149,0-.149,0a6.007,6.007,0,0,0-4.792,2.441,1.555,1.555,0,0,1-2.559-1.768,9.33,9.33,0,0,1,15,0,6.006,6.006,0,0,0,4.908,2.44h.068c2.149.045,4.274-1.842,5.088-2.656l3.111-3.111a1.555,1.555,0,0,1,2.2,2.2l-3.111,3.111c-1.341,1.341-4.045,3.567-7.209,3.568Z"
            transform="translate(-192.332 -996.663)"
            fill="#303134"
          />
        </g>
        <g id="Group_34230" data-name="Group 34230" transform="translate(581.332 686.44)">
          <path
            id="Path_6098-3"
            data-name="Path 6098"
            d="M212.4,1006a9.11,9.11,0,0,1-7.352-3.784,6.007,6.007,0,0,0-4.792-2.441l-.149,0-.149,0a6.007,6.007,0,0,0-4.792,2.441,1.555,1.555,0,0,1-2.559-1.768,9.33,9.33,0,0,1,15,0,6.006,6.006,0,0,0,4.908,2.44h.068c2.149.045,4.274-1.842,5.088-2.656l3.111-3.111a1.555,1.555,0,0,1,2.2,2.2l-3.111,3.111c-1.341,1.341-4.045,3.567-7.209,3.568Z"
            transform="translate(-192.332 -996.663)"
            fill="#303134"
          />
        </g>
        <g id="Group_34231" data-name="Group 34231" transform="translate(581.332 692.664)">
          <path
            id="Path_6098-4"
            data-name="Path 6098"
            d="M212.4,1006a9.11,9.11,0,0,1-7.352-3.784,6.007,6.007,0,0,0-4.792-2.441l-.149,0-.149,0a6.007,6.007,0,0,0-4.792,2.441,1.555,1.555,0,0,1-2.559-1.768,9.33,9.33,0,0,1,15,0,6.006,6.006,0,0,0,4.908,2.44h.068c2.149.045,4.274-1.842,5.088-2.656l3.111-3.111a1.555,1.555,0,0,1,2.2,2.2l-3.111,3.111c-1.341,1.341-4.045,3.567-7.209,3.568Z"
            transform="translate(-192.332 -996.663)"
            fill="#303134"
          />
        </g>
      </g>
    </Icon>
  );
};

export default CustomFillIcon;
