import { HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { PRODUCT_TAB } from '../../constants';

const ProductTabs = ({ tab, onChange }) => {
  const tabIndex = Object.keys(PRODUCT_TAB).findIndex((key) => key === tab);
  return (
    <HStack gap="25px" w="full" mb={6}>
      <Tabs variant="line" size={['sm', 'md', 'lg']} index={tabIndex} onChange={onChange}>
        <TabList flexWrap={'wrap'}>
          {Object.values(PRODUCT_TAB).map(({ key, label }) => (
            <Tab w={36} key={key}>
              {label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </HStack>
  );
};

export default ProductTabs;
