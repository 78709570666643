import React, { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { GridItem, Heading, HStack, Icon, useBoolean } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaChevronRight } from 'react-icons/fa';

import useSlzToast from 'hooks/useSlzToast';
import { CONFIRM_LEAVE_TOAST } from 'constants';
import IntegrationSkeleton from 'components/common/Skeleton/templates/integrations';
import { SlzGridModal } from 'components/common/SlzModal';

import { transformApiDetail } from 'modules/integrations/mappers/integration-list-mapper';
import { ApiSetupFormAction } from 'modules/integrations/components/form';
import {
  useApiConnectionDetailQuery,
  useSetupApiMutation,
  useThirdPartyModulesQuery
} from 'modules/integrations/hooks';
import {
  API_SETUP_DETAIL_STEP,
  API_SETUP_DETAIL_VALIDATION_SCHEMA,
  API_SETUP_NOTIFICATION_VALIDATION_SCHEMA,
  API_SETUP_STEPS,
  DEFAULT_FORM_VALUES,
  WARNING_LEAVE_TOAST_ID,
  API_SETUP_TEXT
} from 'modules/integrations/constants';

const ApiSetupStep = ({ currentStep, ...rest }) => {
  const StepComponent = API_SETUP_STEPS[currentStep]?.component || Fragment;
  return <StepComponent currentStep={currentStep} {...rest} />;
};

export default function CreateApiIntegrationModal({ apiConnectionId, isOpen, onClose, refetch }) {
  const [currentStep, setCurrentStep] = useState(API_SETUP_DETAIL_STEP);
  const [showNextStep, setShowNextStep] = useBoolean();
  const [isEditMode, setIsEditMode] = useBoolean();
  const [_, setToast, toast] = useSlzToast();
  const setupApiMutation = useSetupApiMutation();

  const {
    data: permissions,
    isSuccess,
    isFetching
  } = useThirdPartyModulesQuery({
    enabled: isOpen
  });

  const { data: apiDetail, isSuccess: isApiDetailSuccess } = useApiConnectionDetailQuery({
    id: apiConnectionId,
    select: (data) => transformApiDetail(data),
    enabled: isOpen && !!apiConnectionId
  });

  const methods = useForm({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: async (data, context, options) => {
      const validationSchema =
        currentStep === API_SETUP_DETAIL_STEP
          ? API_SETUP_DETAIL_VALIDATION_SCHEMA
          : API_SETUP_NOTIFICATION_VALIDATION_SCHEMA;
      return yupResolver(validationSchema)(data, context, options);
    },
    shouldFocusError: false,
    mode: 'onChange',
    context: {
      currentStep
    }
  });
  const ordersSQSSettingsEnabled = useWatch({
    name: 'ordersSQSSettings[enabled]',
    control: methods.control
  });

  const inventorySQSSettingsEnabled = useWatch({
    name: 'inventorySQSSettings[enabled]',
    control: methods.control
  });

  const handleOnClose = () => {
    setCurrentStep(API_SETUP_DETAIL_STEP);
    setIsEditMode.off();
    methods.reset(DEFAULT_FORM_VALUES);
    onClose();
  };

  const onSubmit = async (data) => {
    try {
      await setupApiMutation.mutateAsync(data);
      const successMessage = isEditMode
        ? API_SETUP_TEXT.successUpdateMessage(data.name)
        : API_SETUP_TEXT.successMessage(data.name);
      setToast({
        description: successMessage,
        status: 'success',
        colorScheme: 'positive'
      });
      refetch && refetch();
      handleOnClose();
    } catch (error) {
      if (error?.response.data.error) {
        setToast({
          description: API_SETUP_TEXT.errorMessage,
          status: 'warning',
          colorScheme: 'negative'
        });
      }
    }
  };

  const handleOnLeave = () => {
    toast.closeAll();
    handleOnClose();
  };

  const showConfirmLeaveToast = () => {
    !toast.isActive(WARNING_LEAVE_TOAST_ID) &&
      setToast({
        type: CONFIRM_LEAVE_TOAST,
        chakraToastProps: {
          id: WARNING_LEAVE_TOAST_ID,
          duration: null
        },
        warningText: API_SETUP_TEXT.warningMessage,
        okText: API_SETUP_TEXT.setupButton,
        cancelText: API_SETUP_TEXT.leaveButton,
        onCancelClick: handleOnLeave,
        onOkClick: toast.closeAll
      });
  };

  useEffect(() => {
    if (ordersSQSSettingsEnabled || inventorySQSSettingsEnabled) {
      setShowNextStep.on();
      return;
    }
    setShowNextStep.off();
  }, [ordersSQSSettingsEnabled, inventorySQSSettingsEnabled]);

  useEffect(() => {
    methods.reset(apiDetail);
  }, [apiDetail, isOpen]);

  useEffect(() => {
    if (!!apiConnectionId) {
      setIsEditMode.on();
      return;
    }
    setIsEditMode.off();
  }, [apiConnectionId, isOpen]);

  return (
    <SlzGridModal isOpen={isOpen} onClose={handleOnClose}>
      <GridItem gridColumn="2/12" mb={6} pt="2.563rem">
        <HStack spacing={0}>
          <Heading fontSize="custom.2xl" lineHeight="10">
            {API_SETUP_TEXT.apis}
          </Heading>
          <Icon as={FaChevronRight} fontSize={28} color="dark.500" cursor="pointer" />
          <Heading fontSize="2xl" lineHeight="8" fontWeight="medium">
            {isEditMode ? API_SETUP_TEXT.manageIntegration : API_SETUP_TEXT.setupNew}
          </Heading>
        </HStack>
      </GridItem>
      <GridItem gridColumn="2/12" mb={6}>
        <IntegrationSkeleton template="apis" isLoaded={!isFetching}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {isSuccess && <ApiSetupStep currentStep={currentStep} permissions={permissions} />}

              <ApiSetupFormAction
                submitText={isEditMode ? API_SETUP_TEXT.updateButton : API_SETUP_TEXT.setupButton}
                currentStep={currentStep}
                showNextStep={showNextStep}
                isDisabled={!methods.formState.isDirty || !methods.formState.isValid}
                onCancel={showConfirmLeaveToast}
                setCurrentStep={setCurrentStep}
              />
            </form>
          </FormProvider>
        </IntegrationSkeleton>
      </GridItem>
    </SlzGridModal>
  );
}
