import { Fragment } from 'react';
import { Heading, HStack, Image, Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';
import { SlzChip } from 'components/common/SlzChip';
import { RATE_MATCHER_HEADER_TEXT } from 'modules/integrations/constants';

const RateMatcherHeader = ({ shopName }) => {
  return (
    <Fragment>
      <HStack spacing={0}>
        <HStack spacing={1}>
          <Image src="Images/shopify-logo.png" h="10" />
          <SlzChip size="xs" bg="#9EDE14" h={4} lineHeight={4}>
            2.0
          </SlzChip>
        </HStack>
        <Icon as={FaChevronRight} fontSize={28} color="dark.500" cursor="pointer" />
        <Heading as="h3" fontSize="2xl" lineHeight={8} mb="0" color="dark.700" fontWeight="medium">
          {RATE_MATCHER_HEADER_TEXT}
        </Heading>
      </HStack>
      <Heading as="h3" fontSize="2xl" lineHeight={8} mb="0" color="dark.700" fontWeight="medium">
        {shopName}
      </Heading>
    </Fragment>
  );
};
export default RateMatcherHeader;
