import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['card', 'header', 'body', 'info', 'chip', 'logo'];
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle(({ theme, colorScheme }) => ({
  card: {
    aspectRatio: '35/24',
    fontSize: '1rem',
    bgColor: `${colorScheme}.500`,
    py: '1.2em',
    px: '1.25em',
    rounded: '1em',
    display: 'flex',
    flexDir: 'column'
  },
  body: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    mb: '0.5em'
  },
  info: {
    color: 'white',
    height: '68%',
    lineHeight: 1.2
  }
}));

const variants = {
  standard: definePartsStyle(({ theme }) => {
    return {
      logo: {
        marginTop: 'auto!important',
        '& > svg': {
          height: '1.75em',
          width: 'auto',
          maxWidth: '5em'
        }
      },
      chip: {
        width: '2.67em',
        height: '1.83em',
        ml: '1.2em',
        mb: '1em'
      }
    };
  }),
  compact: definePartsStyle(({ theme }) => ({
    card: {
      px: '0'
    },
    logo: {
      width: 'full',
      height: 'full',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        height: '3.75em',
        width: 'auto'
      }
    },
    info: {
      display: 'none'
    }
  }))
};

const sizes = {
  xs: definePartsStyle(({ theme }) => ({
    card: {
      width: '4.375rem',
      fontSize: '0.25rem'
    }
  })),
  sm: definePartsStyle(({ theme }) => ({
    card: {
      width: '10rem',
      fontSize: '0.5rem'
    }
  })),
  md: definePartsStyle(({ theme }) => ({
    card: {
      width: '15rem',
      fontSize: '0.75rem'
    }
  })),
  lg: definePartsStyle(({ theme }) => ({
    card: {
      width: '20rem',
      fontSize: '1rem'
    }
  })),
  xl: definePartsStyle(({ theme }) => ({
    card: {
      width: '25rem',
      fontSize: '1.25rem'
    }
  })),
  '2xl': definePartsStyle(({ theme }) => ({
    card: {
      width: '35rem',
      fontSize: '1.75rem'
    }
  }))
};

export default defineMultiStyleConfig({
  variants,
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'standard',
    colorScheme: 'dark'
  }
});
