import { Flex, Image } from '@chakra-ui/react';
import SlzLabel from 'components/common/SlzLabel/SlzLabel';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';

export const BULK_ORDER_ACCORDION_COLUMNS = [
  {
    field: 'image',
    type: COL_CUSTOM,
    width: 48,
    render: ({ row, variant }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
            h: '100%',
            maxH: '100%',
            py: 0
          }}>
          <Image
            w="full"
            h="full"
            objectFit={'contain'}
            loading="lazy"
            src={row?.fileLink || DEFAULT_IMAGE}
            alt={row?.fileName ?? ''}
            srcSet={row?.fileLink || DEFAULT_IMAGE}
            fallbackSrc={row?.fileLink || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    field: 'name',
    cellHeader: (row) => row.name,
    cellValue: (row) => row.sku || ''
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    cellValue: (row) => row.identifier || 'N/A'
  },
  {
    header: 'Warehouse',
    field: 'wareHouse',
    type: 'string',
    cellValue: (row) => row.wareHouse || 'Selazar Nuneaton' //mock
  },
  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number'
  },
  {
    field: 'weight',
    header: 'Item weight',
    type: 'number'
  },
  {
    field: 'price',
    header: 'Price',
    type: 'string'
  }
];

export const BULK_ORDER_COLUMNS = [
  {
    name: 'Purchase order number',
    field: 'purchaseOrderNumber',
    isSorted: false,
    isDisplayed: true
  },
  {
    name: 'Item count',
    field: 'totalOrderItems',
    isSorted: false,
    isDisplayed: false
  },
  {
    name: 'Date created',
    field: 'createdDate',
    isSorted: true,
    type: 'date',
    isDisplayed: false,
    sortField: 'DATE_CREATED'
  },
  {
    name: 'Status',
    field: 'status',
    isSorted: false,
    isEmphasis: true,
    isDisplayed: false
  },
  {
    name: 'Shipping',
    field: 'requiresShipping',
    isSorted: true,
    sortField: 'SHIPPING',
    type: COL_CUSTOM,
    render: ({ row }) =>
      row?.requiresShipping ? (
        <SlzLabel variant="solid" colorScheme="szrDenim">
          Selazar fulfilled
        </SlzLabel>
      ) : (
        <SlzLabel variant="outline" colorScheme="main" size="sm">
          Self-fulfilled
        </SlzLabel>
      ),
    isDisplayed: false
  }
];
