import React, { useEffect } from 'react';

import { GridItem, HStack, Heading, Icon } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import {
  PACKAGING_BACK_TITLE,
  PACKAGING_TYPES,
  PREFERENCES_ACTIONS,
  USER_SETTING_FORMS_DEFAULT_NAME,
  USER_SETTING_MODAL_LAYOUT
} from 'modules/preferences/constants';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { CustomBoxForm, CustomMailingBagForm } from '../form';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';

const CustomBoxAndMailingLibraryModal = () => {
  const { preference, preferenceDispatch } = usePreferenceContext();
  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateBox]: formBox } = forms;
  const { [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag]: formMailingBag } = forms;
  const methodsBox = formBox?.form;
  const methodsMailingBag = formMailingBag?.form;

  const onClose = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: { modalLayout: USER_SETTING_MODAL_LAYOUT.managedLibraries.key }
    });
  };

  const handleShowForm = (type) => {
    const formComponents = {
      [PACKAGING_TYPES.box]: <CustomBoxForm onBack={onClose} />,
      [PACKAGING_TYPES.mailingBag]: <CustomMailingBagForm onBack={onClose} />
    };

    return formComponents[type];
  };

  const handleShowTitle = (type) => {
    return PACKAGING_BACK_TITLE[type];
  };

  useEffect(() => {
    return () => {
      if (preference.managedType === PACKAGING_TYPES.box) {
        methodsBox?.reset();
        const formValues = methodsBox.getValues();
        Object.keys(formValues).forEach((key) => {
          methodsBox.setValue(key, '');
        });
      }
      if (preference.managedType === PACKAGING_TYPES.mailingBag) {
        methodsMailingBag?.reset();
        const formValues = methodsMailingBag.getValues();
        Object.keys(formValues).forEach((key) => {
          methodsMailingBag.setValue(key, '');
        });
      }
    };
  }, []);

  return (
    <>
      <GridItem gridColumn="2/12">
        <HStack alignItems="flex-end" my="6">
          <HStack spacing={1} cursor="pointer">
            <Icon as={FaChevronLeft} fontSize={14} color="dark.500" onClick={onClose} />
            <Heading
              as="h3"
              fontSize="xl"
              fontWeight="normal"
              lineHeight={'none'}
              mb="0"
              onClick={onClose}>
              {handleShowTitle(preference.managedType)}
            </Heading>
          </HStack>
        </HStack>
      </GridItem>

      <GridItem gridColumn="2/12">{handleShowForm(preference.managedType)}</GridItem>
    </>
  );
};

export default CustomBoxAndMailingLibraryModal;
