import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GridItem, HStack, Text, Icon, Box, Heading, useToast, Link } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FaChevronLeft } from 'react-icons/fa';
import { dayjs } from 'utils';

import { useQuery as useRouterQuery } from 'hooks/useQuery';
import SlzToolTip from 'components/common/SlzToolTip';
import { SlzGridModal } from 'components/common/SlzModal';
import { ORDER_KEY_PARAM } from 'modules/b2c-orders/constants';
import SlzDateRangePicker from 'components/common/SlzDateRangePicker';
import { DATE_DISPLAY_FORMAT_DDMMYY, TODAY } from 'constants/date';
import { SlzButton } from 'components/common/SlzButton';
import SlzToast from 'components/common/SlzToast/SlzToast';
import { useTrackingReferences } from '../../hooks/useTrackingReferences';

function SuccessToast({ onClose, link, filename }) {
  return (
    <SlzToast
      colorScheme="positive"
      description={
        <div>
          Your Tracking reference CSV is ready! Please download the file{' '}
          <Link download={filename} href={link} textDecor="underline">
            here
          </Link>
        </div>
      }
      status="success"
      variant="solid"
      onClose={onClose}
    />
  );
}

function ErrorToast({ desc, onClose }) {
  return (
    <SlzToast
      colorScheme="negative"
      status="error"
      variant="solid"
      description={desc}
      onClose={onClose}
    />
  );
}

const handleShowingToast = (toast, { isError, isSuccess, dateRange, data }) => {
  const TOAST_ID = 'trackingReferences';
  const toastProps = {
    id: TOAST_ID,
    isClosable: true,
    position: 'top',
    duration: 5000
  };

  const isInvalidDateRange = dayjs(dateRange[0]).isAfter(dayjs(dateRange[1]));
  if (isInvalidDateRange) {
    toastProps.render = ({ onClose }) => (
      <ErrorToast
        desc="Oooops! We can only search from older date to newer date Please try again!"
        onClose={onClose}
      />
    );
  } else if (isError) {
    toastProps.render = ({ onClose }) => (
      <ErrorToast
        desc="We could not find any tracking information between those two dates"
        onClose={onClose}
      />
    );
  } else if (isSuccess) {
    toastProps.duration = null;
    toastProps.render = ({ onClose }) => (
      <SuccessToast link={data.link} filename={data.filename} onClose={onClose} />
    );
  }

  if (toast.isActive(TOAST_ID)) {
    toast.update(TOAST_ID, toastProps);
    return;
  }
  toast(toastProps);
};

export default function TrackingModal({ isOpen }) {
  const history = useHistory();
  const routerQuery = useRouterQuery();
  const toast = useToast();
  const [dateRange, setDateRange] = useState([]);
  const { data, refetch: exportTracking, isError, isSuccess } = useTrackingReferences(dateRange);

  const handleCloseTrackingModal = () => {
    routerQuery.delete(ORDER_KEY_PARAM.IS_TRACKING_MODAL);
    history.push({ search: routerQuery.toString() });
  };

  const handleSelectedDateRange = (startDate, endDate) => {
    setDateRange([startDate, endDate]);
  };

  useEffect(() => {
    if (isError || isSuccess) {
      handleShowingToast(toast, { isError, isSuccess, dateRange, data });
    }
  }, [isError, isSuccess, dateRange]);

  return (
    <SlzGridModal isOpen={isOpen} onClose={handleCloseTrackingModal}>
      <GridItem gridColumn="2/12" mb="1.563rem">
        <HStack alignItems="flex-start">
          <Icon
            as={FaChevronLeft}
            fontSize={28}
            color="dark.500"
            mt="0.5"
            cursor="pointer"
            onClick={handleCloseTrackingModal}
          />
          <Box>
            <Heading as="h3" fontSize="2rem" lineHeight={'none'} mb="0">
              Tracking references
            </Heading>
            <Text color="dark.500" display="flex" gap="5px">
              Export to CSV
              <SlzToolTip content={'This allows you to download a CSV export of the tracking numbers for each order between two dates.'} placement="bottom-start" variant="blue">
                <InfoOutlineIcon boxSize="3" color="main.500" />
              </SlzToolTip>
            </Text>
          </Box>
        </HStack>
      </GridItem>

      <GridItem gridColumn="2/12" mb="3">
        <Heading as="h4" size="md" mb="0.25">
          Order tracking details
        </Heading>
        <Text>Search your tracking references between two dates.</Text>

        <HStack spacing={3} mt="0.5rem">
          <SlzDateRangePicker
            disableChoosingPrev={false}
            onDateRangeClick={handleSelectedDateRange}
            startDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}
            startDate={null}
            endDatePlaceHolder={DATE_DISPLAY_FORMAT_DDMMYY}          
            endDate={null}
          />
          <SlzButton size="lg" onClick={exportTracking}>
            Search
          </SlzButton>
        </HStack>
      </GridItem>
    </SlzGridModal>
  );
}
