import { useQuery } from 'react-query';
import { getCustomPackaging } from 'modules/preferences/services/preferences-api';

const useCustomPackagingQuery = (customPackaging) => {
  return useQuery({
    queryKey: [`custom-packaging-${customPackaging}`],
    queryFn: () => getCustomPackaging(customPackaging)
  });
};

export default useCustomPackagingQuery;
