import React, { useEffect, useState } from 'react';
import { Flex, Text, Image, Box, useBoolean } from '@chakra-ui/react';
import { FORGOT_PASSWORD_TEXT } from 'modules/users/constants';
import { SlzButton } from 'components/common/SlzButton';
import SlzLink from 'components/common/SlzLink/SlzLink';
import { UserFormHeading } from '../other';

export default function ResendEmailForm({ onSubmit }) {
  const [isResendEmail, setIsResendEmail] = useBoolean();
  const [contactUsMail, setContactUsMail] = useState(FORGOT_PASSWORD_TEXT.customerService);

  const handleOnSubmit = () => {
    setIsResendEmail.on();
    onSubmit();
  };

  useEffect(() => {
    isResendEmail && setContactUsMail(FORGOT_PASSWORD_TEXT.emailSupport);
  }, [isResendEmail]);

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" mb="4.125rem">
      <Image src="Images/selazar-login.svg" mb={5} mt="24.26vh"/>
      <UserFormHeading
        text={
          !isResendEmail
            ? FORGOT_PASSWORD_TEXT.checkYourInbox
            : FORGOT_PASSWORD_TEXT.resendingYourEmail
        }
      />
      <Text>
        {!isResendEmail
          ? FORGOT_PASSWORD_TEXT.instructionUpResendEmail
          : FORGOT_PASSWORD_TEXT.instructionUpResendingEmail}
      </Text>
      <Text>
        {!isResendEmail
          ? FORGOT_PASSWORD_TEXT.instructionDownResendEmail
          : FORGOT_PASSWORD_TEXT.instructionDownResendingEmail}
      </Text>

      <Box mt="1.625rem" mb={isResendEmail && '4.813rem'}>
        <Image src="Images/resend-email.svg" />
      </Box>

      {!isResendEmail && (
        <Box mt="1.563rem">
          <Text>{FORGOT_PASSWORD_TEXT.cannotFindEmail}</Text>
        </Box>
      )}

      {!isResendEmail && <Box mt="0.625rem">{FORGOT_PASSWORD_TEXT.or}</Box>}

      <Flex w="full" mt="0.563rem">
        <SlzButton
          onClick={handleOnSubmit}
          isDisabled={isResendEmail}
          w={'full'}
          type="submit"
          size="lg">
          {FORGOT_PASSWORD_TEXT.resendEmail}
        </SlzButton>
      </Flex>

      <Box mt="1rem">
        {!isResendEmail
          ? FORGOT_PASSWORD_TEXT.emailAddressNoLongInUse
          : FORGOT_PASSWORD_TEXT.refreshPage}
      </Box>

      <Box mt="0.25rem">
        {FORGOT_PASSWORD_TEXT.contactUs}
        <SlzLink variant="underline" href={`mailto:${contactUsMail}`} color="main.500">
          {contactUsMail}
        </SlzLink>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        h="3rem"
        borderRadius="0.375rem"
        mt="1.625rem"
        w="full"
        size="lg"
        bg="light.500"
        color="lightAndShadow.mainText"
        fontWeight="medium">
        {FORGOT_PASSWORD_TEXT.closeThisWindows}
      </Box>
    </Flex>
  );
}
