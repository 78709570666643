import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import { NAVIGATE_ITEMS_STATUS, NAVIGATE_USER_SETTINGS } from 'constants/common';
import RequiredSettingStatus from 'modules/users/components/other/RequiredSettingStatus';

const settingItemStyle = {
  w: 'full',
  ps: 2.5,
  pe: 2,
  py: '0.281rem',
  fontSize: '1rem',
  lineHeight: '1.313rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  _hover: {
    fontWeight: 'bold',
    bg: 'main.300',
    borderRadius: '0.188rem'
  }
};

const settingItemActiveStyle = {
  fontWeight: 'bold',
  bg: 'main.300',
  borderRadius: '0.188rem'
};

const SettingSection = ({ sectionIcon, sectionText, children }) => {
  return (
    <VStack alignItems="flex-start" spacing={6} w="full">
      <HStack pl={2.5} spacing={3}>
        {sectionIcon}
        <Heading fontSize="1.5rem">{sectionText}</Heading>
      </HStack>
      <VStack spacing={3.5} alignItems="flex-start" w="full">
        {children}
      </VStack>
    </VStack>
  );
};

const SettingItem = ({ label, iconLeft, iconRight, isActive, onClick }) => {
  return (
    <Box __css={settingItemStyle} sx={isActive ? settingItemActiveStyle : null} onClick={onClick}>
      {iconLeft}
      <Text display="inline-block" ml={3}>
        {label}
      </Text>
      {iconRight}
    </Box>
  );
};

const UserSettingSideNav = ({ settingType, onItemClick, requiredSettings }) => {
  const handleOnItemClick = (value, text) => {
    if (value === settingType) return;
    onItemClick && onItemClick({ settingType: value, settingText: text });
  };

  return (
    <VStack as="aside" alignItems="flex-start" spacing={20} pr={14} w="full">
      {NAVIGATE_USER_SETTINGS.map(({ key, label, icon }) => {
        const sectionItems = NAVIGATE_ITEMS_STATUS[key].items;
        return (
          <SettingSection key={key} sectionIcon={icon} sectionText={label}>
            {sectionItems?.map(({ value, iconLeft, iconRight, label }) => {
              const isActive = value === settingType;
              return (
                <SettingItem
                  key={value}
                  iconLeft={iconLeft}
                  iconRight={
                    <RequiredSettingStatus
                      iconRight={null}
                      value={value}
                      requiredSettings={requiredSettings}
                    />
                  }
                  label={label}
                  isActive={isActive}
                  onClick={() => handleOnItemClick(value, label)}
                />
              );
            })}
          </SettingSection>
        );
      })}
    </VStack>
  );
};
export default UserSettingSideNav;
