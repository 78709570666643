export const COL_SPAN_6 = 6;
export const COL_SPAN_5 = 5;

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGING_OPTIONS = [5, 10, 20];
export const DEFAULT_TOTAL_PAGES = 1;

export const PAGE_INDEX = 'pageIndex';
export const PAGE_SIZE = 'pageSize';
export const SEARCH_TEXT = 'searchText';
export const TAB = 'tab';
export const DATE_FROM = 'dateFrom';
export const DATE_TO = 'dateTo';
export const STATUS = 'status';
export const FILTER_BY = 'filterBy';
export const SORT_BY = 'sortBy';
export const IS_DESC = 'isDesc';