import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const parts = ['link', 'leftIcon', 'rightIcon'];

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts);

const linkStyle = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-out',
  cursor: 'pointer',
  textDecoration: 'none',
  outline: 'none',
  color: 'dark.700',
  _hover: {
    color: 'main.500'
  },
  _focusVisible: {
    boxShadow: 'outline'
  }
});

const iconStyle = defineStyle({
  w: '1.375rem',
  h: '1.375rem',
  verticalAlign: 'sub'
});

const leftIconStyle = defineStyle({
  ...iconStyle,
  marginRight: '0.375rem'
});

const rightIconStyle = defineStyle({
  ...iconStyle,
  marginLeft: '0.375rem'
});

const baseStyle = definePartsStyle(() => ({
  link: linkStyle,
  leftIcon: leftIconStyle,
  rightIcon: rightIconStyle
}));

const variantUnderline = defineStyle(() => {
  return {
    link: {
      textDecoration: 'underline',
      _hover: {
        textDecoration: 'underline'
      }
    }
  };
});

const variants = {
  underline: variantUnderline
};

export default defineMultiStyleConfig({
  baseStyle,
  variants
});
