import { useHistory } from 'react-router';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import SlzTable from 'components/common/SlzTable/Table';
import { B2B_ORDER_PATHNAME, BULK_ORDER_SKELETON_CELL, BULK_ORDER_TYPE } from '../../constants';
import { BULK_ORDER_COLUMNS, BULK_ORDER_ACCORDION_COLUMNS } from './bulk-order.columns';
import { useBulkOrderContext } from 'modules/b2b-orders/context/BulkOrderContext';
import BulkOrderAccordionTable from './BulkOrderAccordionTable';

const columns = [...BULK_ORDER_COLUMNS];

export const accordionProps = {
  isShowMarker: true,
  markedWidth: 12,
  columns: BULK_ORDER_ACCORDION_COLUMNS,
  showImage: true,
  imageWidth: 48,
  imageStyles: {
    w: '3rem',
    h: 'inherit'
  },
  type: BULK_ORDER_TYPE,
  displayFields: BULK_ORDER_ACCORDION_COLUMNS.map((col) => col.field)
};

const BulkOrderTable = (props) => {
  const { currentPage, totalPage, onPageChange, onItemPerPageChange, onSort } = props;
  const { bulkOrder } = useBulkOrderContext();
  const history = useHistory();

  columns[0].isClickable = true;
  columns[0].onClick = (row) => {
    history.push(`${B2B_ORDER_PATHNAME}/${row.id}?status=${bulkOrder?.params?.tab}`);
  };

  return (
    <SlzTable
      isLoading={props?.isLoading}
      variant="order"
      showMarked
      isEmptyPage={props?.isEmptyPage}
      messageToast={props?.messageToast}
      onRefetching={props?.onRefetching}
      sizes={['sm', 'md', 'lg']}
      columns={columns}
      data={bulkOrder?.list || Array(5).fill(BULK_ORDER_SKELETON_CELL)}
      events={{ onSort }}
      rowProps={{
        hasExpandedRow: true,
        accordionProps: {
          ...accordionProps,
          template: ({ row }) => {
            return <BulkOrderAccordionTable row={row} accordionProps={accordionProps} />;
          }
        }
      }}
      displayFields={BULK_ORDER_COLUMNS.map((col) => col.field)}
      hyperLinks={[BULK_ORDER_COLUMNS[0].field]}
      filters={{
        isLocal: true,
        title: 'Status',
        byField: 'status',
        options: bulkOrder?.filters || []
      }}
      filterByDate={{
        field: 'createdDate',
        start: bulkOrder?.params?.startDate,
        end: bulkOrder?.params?.endDate
      }}
      pagination={{
        isLocal: false,
        pages: {
          onChange: (numPerPage) => onItemPerPageChange(numPerPage),
          pageOptions: DEFAULT_PAGING_OPTIONS,
          currentItemPerPage: bulkOrder?.params?.pageSize
        },
        direction: {
          usingDots: false,
          totalPage,
          currentPage,
          // numDots: 5,
          isGreyBg: true,
          onChange: (currentPage) => onPageChange(currentPage)
        }
      }}
    />
  );
};

export default BulkOrderTable;
