import React from 'react';
import { Icon } from '@chakra-ui/react';

const MyAppIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="1.25rem"
      height="1.25rem"
      viewBox="0 0 20 20"
      {...props}>
      <g id="Group_33692" data-name="Group 33692" transform="translate(-10370.244 7019.835)">
        <g id="Group_33696" data-name="Group 33696" transform="translate(7970.471 -7383.723)">
          <path
            id="Ellipse_350"
            data-name="Ellipse 350"
            d="M1.574-1A2.574,2.574,0,1,1-1,1.574,2.577,2.577,0,0,1,1.574-1Zm0,3.432a.858.858,0,1,0-.858-.858A.859.859,0,0,0,1.574,2.432Z"
            transform="translate(2411.068 372.319)"
            fill="#303134"
          />
          <path
            id="Path_5462"
            data-name="Path 5462"
            d="M2405.779,367.887h6.863a6.005,6.005,0,1,1,0,12.011h-6.863a6.005,6.005,0,0,1,0-12.011Zm6.863,10.3a4.29,4.29,0,1,0,0-8.579h-6.863a4.29,4.29,0,0,0,0,8.579Z"
            transform="translate(0)"
            fill="#303134"
          />
        </g>
      </g>
    </Icon>
  );
};

export default MyAppIcon;
