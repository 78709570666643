import { Card, HStack, Image, Text, VStack, Box } from '@chakra-ui/react';
import { TagIcon } from '../assets';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { SEND_CONSIGNMENT_MODAL_STEPS } from 'modules/consignments/constants';
import { NO_AVATAR_URL } from 'components/common/Constants';

const dataContainerStyles = { marginTop: '0 !important' };
const dataFontStyles = { fontSize: '14px' };
const dataLabelFontStyles = { ...dataFontStyles, fontWeight: 'bold', w: '4rem' };
const fields = [
  { label: 'Name', field: 'name' },
  { label: 'SKU', field: 'sku' },
  { label: 'EAN/SUID', field: 'ean', field2: 'selazarUniqueID' }
];

const SelectedProduct = ({ currentType, handleEditStep, bg="light.500" }) => {
  const { watch } = useFormContext();

  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    setSelectedProduct(watch('product'));
  }, []);

  return (
    <>
      <VStack w="50%">
        <Card
          bg={bg}
          variant="filled"
          sx={{ width: '100%', height: '3rem', display: 'flex', justifyContent: 'center' }}>
          <HStack px="1.5rem">
            <TagIcon sx={{ width: '1.25rem', height: '1.25rem' }} />
            <Box display="flex" alignItems="baseline">
              <Text
                marginRight="0.375rem"
                sx={{
                  fontSize: 'lg',
                  color: 'table.mainText',
                  fontWeight: 'bold'
                }}>
                Selected product
              </Text>

              {currentType === SEND_CONSIGNMENT_MODAL_STEPS.SUMMARY && (
                <Text
                  onClick={() =>
                    handleEditStep(SEND_CONSIGNMENT_MODAL_STEPS.SELECT_CONSIGNMENT_ARRIVING_TYPE)
                  }
                  fontSize="xs"
                  cursor="pointer"
                  color="main.500">
                  Edit
                </Text>
              )}
            </Box>
          </HStack>
        </Card>
        <Card
          bg="light.300"
          border="1px solid"
          borderLeft="none"
          borderColor="light.700"
          mt="2rem"
          w="100%"
          h="5.563rem"
          variant="filled"
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '0.2rem !important' }}>
          <HStack>
            <Image
              sx={{ borderLeft: 'none', borderLeftRadius: '0.375rem' }}
              src={(selectedProduct !== null && selectedProduct?.imageUrl) || NO_AVATAR_URL}
              w="79px"
              h="5.563rem"
            />

            <VStack display="flex" alignItems="flex-start">
              {fields.map(({ label, field, field2 }) => (
                <HStack sx={{ ...dataContainerStyles }}>
                  <Text sx={{ ...dataLabelFontStyles }}>{label}</Text>
                  <Text sx={{ ...dataFontStyles }}>
                    {selectedProduct !== null && selectedProduct[field] !== null
                      ? selectedProduct[field]
                      : selectedProduct !== null && selectedProduct[field2]}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </HStack>
        </Card>
      </VStack>
    </>
  );
};

export default SelectedProduct;
