import { useState, useEffect } from 'react';

import { useCheckCreditCardQuery, useGetSelectedWarehouseQuery } from 'modules/preferences/hooks';
import {
  COMPANY_NAVIGATE_MENU_STATUS,
  NAVIGATE_MENU_STATUS,
  SETTING_NAVIGATE_MENU_STATUS
} from 'constants/common';

const useCheckRequiredSettings = () => {
  const {
    data: warehouse,
    isFetching: isWarehouseFetching,
    refetch: refetchSelectedWarehouse
  } = useGetSelectedWarehouseQuery();
  const {
    data: payment,
    isFetching: isPaymentFetching,
    refetch: refetchCheckCredit
  } = useCheckCreditCardQuery();
  const [requiredSettings, setRequiredSettings] = useState([]);

  const updateRequiredSettings = async () => {
    await refetchSelectedWarehouse();
    await refetchCheckCredit();
  };

  useEffect(() => {
    if (!isWarehouseFetching && !isPaymentFetching) {
      const listRequiredSetting = [];
      !warehouse?.id &&
        listRequiredSetting.push(
          SETTING_NAVIGATE_MENU_STATUS.warehouse,
          NAVIGATE_MENU_STATUS.SETTING
        );

      !payment &&
        listRequiredSetting.push(
          COMPANY_NAVIGATE_MENU_STATUS.paymentMethods,
          NAVIGATE_MENU_STATUS.COMPANY
        );

      setRequiredSettings(listRequiredSetting);
    }
  }, [isWarehouseFetching, isPaymentFetching]);

  return {
    requiredSettings,
    updateRequiredSettings,
    isRequiredSettingsEmpty: !requiredSettings.length,
    isLoadingRequiredSettings: isWarehouseFetching || isPaymentFetching,
    isRequiredSettingsFetching: isWarehouseFetching && isPaymentFetching
  };
};

export default useCheckRequiredSettings;
