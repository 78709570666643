import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import badgeTheme from './badge';
import { getColor, mode, transparentize } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleContainer = defineStyle({
  fontWeight: 'medium',
  lineHeight: 1.2,
  outline: 0,
  borderRadius: 'md',
  _focusVisible: {
    boxShadow: 'outline'
  }
});

const baseStyleLabel = defineStyle({
  lineHeight: 1.2,
  overflow: 'visible'
});

const baseStyleCloseButton = defineStyle({
  fontSize: '1rem',
  w: 3,
  h: 3,
  p: 1,
  transitionProperty: 'common',
  transitionDuration: 'normal',
  borderRadius: 'full',
  marginStart: '0.375rem',
  marginEnd: '-0.375rem',
  opacity: 1,
  _disabled: {
    opacity: 0.4
  },
  _focusVisible: {
    boxShadow: 'outline',
    bg: 'rgba(0, 0, 0, 0.14)'
  },
  _hover: {
    opacity: 0.8
  },
  _active: {
    opacity: 1
  },
  '& > svg': {
    w: '10px',
    h: '10px'
  }
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
  label: baseStyleLabel,
  closeButton: baseStyleCloseButton
});

const sizes = {
  sm: definePartsStyle({
    container: {
      minH: 4,
      minW: 5,
      fontSize: '0.625rem',
      px: 3
    },
    label: {
      lineHeight: 4
    }
  }),
  md: definePartsStyle({
    container: {
      minH: 5,
      minW: 6,
      fontSize: '0.75rem',
      px: 3
    },
    label: {
      lineHeight: 5
    }
  }),
  lg: definePartsStyle({
    container: {
      minH: 6,
      minW: 8,
      fontSize: '0.75rem',
      px: 3
    },
    label: {
      lineHeight: 6
    }
  })
};

const variantSubtle = definePartsStyle((props) => {
  const { colorScheme } = props;
  return {
    container: badgeTheme.variants?.subtle(props),
    closeButton: {
      bgColor: `${colorScheme}.500`,
      color: 'white'
    }
  };
});

const variantSolid = definePartsStyle((props) => {
  const { colorScheme } = props;

  return {
    container: badgeTheme.variants?.solid(props),
    closeButton: {
      bgColor: 'white',
      color: colorScheme
    }
  };
});

const variantOutline = defineStyle((props) => {
  const { colorScheme: c, theme } = props;
  const darkColor = transparentize(`${c}.300`, 0.8)(theme);
  const lightColor = getColor(theme, `${c}.500`);
  const color = getColor(theme, mode(lightColor, darkColor)(props));

  if (c === 'default') {
    return {
      container: {
        color: 'dark.500',
        boxShadow: `inset 0 0 0px 1px ${getColor(theme, 'dark.300')}`,
        bg: 'light.500',
        _hover: {
          boxShadow: `inset 0 0 0px 1px ${getColor(theme, 'main.500')}`
        }
      },
      closeButton: {
        bgColor: `dark.500`,
        color: 'white'
      }
    };
  }

  return {
    container: {
      color,
      boxShadow: `inset 0 0 0px 1px ${color}`,
      bg: transparentize(color, 0.16)(props)
    },
    closeButton: {
      bgColor: `${c}.500`,
      color: 'white'
    }
  };
});

const variantPendingPayment = definePartsStyle((props) => {
  const { colorScheme } = props;
  if (colorScheme === 'mustard') {
    return {
      container: {
        color: 'dark.500',
        bg: '#FECB2E'
      }
    };
  }

  return {
    container: badgeTheme.variants?.subtle(props)
  };
});

const variants = {
  subtle: variantSubtle,
  solid: variantSolid,
  outline: variantOutline,
  pendingPayment: variantPendingPayment
};

export default defineMultiStyleConfig({
  variants,
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'subtle',
    colorScheme: 'main'
  }
});
