import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { SlzPaper } from 'components/common/SlzPaper';
import { skeletonAnimation as animation } from 'components/common/Skeleton/config';
import { Skeleton } from 'components/common/Skeleton/Skeleton';
import { SlzChip } from 'components/common/SlzChip';
import { DATE_FORMAT_DDMMYYYY_HH_MM_SS } from 'constants';
import dayjs from 'dayjs';

const ConsignmentRowDetail = (props) => {
  const { consignmentDetail, isLoading } = props;

  return (
    <SlzPaper w={props.w} h={props.h} bg={props.bg}>
      <Flex
        h={props.h}
        justifyContent="space-between"
        alignItems="center"
        borderLeftWidth="0.75rem"
        borderRadius="0.375rem"
        borderLeftColor={consignmentDetail?.consignmentInfoDetails?.status?.color}>
        {isLoading ? (
          <Skeleton animation={animation} width="100%" height="100%"></Skeleton>
        ) : (
          <>
            <HStack flex="1">
              <Box mx={5} justifyContent="space-between" width="18rem">
                <Text fontSize="1rem" fontWeight="bold">
                  {consignmentDetail?.consignmentInfoDetails?.fileName}
                </Text>
                <Text fontSize="0.875rem">{dayjs(consignmentDetail?.consignmentInfoDetails?.createdDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS)}</Text>
              </Box>
              <Box>
                <Text fontSize="0.75rem" fontWeight="bold">
                  Status
                </Text>
                <SlzChip h="1.5rem" bgColor={consignmentDetail?.consignmentInfoDetails?.status?.color}>{consignmentDetail?.consignmentInfoDetails?.status?.name}</SlzChip>
              </Box>
            </HStack>
          </>
        )}
      </Flex>
    </SlzPaper>
  );
};

export default ConsignmentRowDetail;
