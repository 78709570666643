import { Icon } from '@chakra-ui/react';

const MultipleProductsIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="currentColor"
      {...props}>
      <g
        id="Multiple_product_consignment_icon"
        data-name="Multiple product consignment icon"
        transform="translate(-920.499 -529)">
        <path
          id="Path_4044"
          data-name="Path 4044"
          d="M1398.439,701.887h28a4.667,4.667,0,0,1,4.667,4.667v37.333a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V706.554A4.667,4.667,0,0,1,1398.439,701.887Zm23.333,9.333h-18.667v28h18.667Z"
          transform="translate(-473.273 -172.887)"
        />
        <path
          id="Path_4045"
          data-name="Path 4045"
          d="M1398.439,713.887h28a4.667,4.667,0,0,1,4.667,4.667V737.22a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V718.554A4.667,4.667,0,0,1,1398.439,713.887Zm23.333,9.333h-18.667v9.333h18.667Z"
          transform="translate(-473.273 -128.887)"
        />
        <path
          id="Path_4046"
          data-name="Path 4046"
          d="M1408.439,709.887h28a4.667,4.667,0,0,1,4.667,4.667v37.333a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V714.553A4.667,4.667,0,0,1,1408.439,709.887Zm23.333,9.333h-18.667v28h18.667Z"
          transform="translate(-436.607 -143.553)"
        />
        <path
          id="Path_4047"
          data-name="Path 4047"
          d="M1408.439,701.887h28a4.667,4.667,0,0,1,4.667,4.667V725.22a4.667,4.667,0,0,1-4.667,4.667h-28a4.667,4.667,0,0,1-4.667-4.667V706.554A4.667,4.667,0,0,1,1408.439,701.887Zm23.333,9.333h-18.667v9.333h18.667Z"
          transform="translate(-436.607 -172.887)"
        />
      </g>
    </Icon>
  );
};

export default MultipleProductsIcon;
