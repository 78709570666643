import { Fragment } from 'react';
import { Box, GridItem, Heading, HStack, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router';

import { B2CIcon, CsvUpload, ClipboardListIcon } from 'components/common/Icons';
import SetupRequirement from 'components/common/SetupRequirement';
import B2BIcon from 'components/common/Icons/B2BIcon';
import { SlzGridModal } from 'components/common/SlzModal';
import { SlzPaper } from 'components/common/SlzPaper';
import OrderSkeleton from 'components/common/Skeleton/templates/order';
import { useQuery } from 'hooks/useQuery';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';

import { BUSINESS_MODEL, ORDER_KEY_PARAM } from 'modules/b2c-orders/constants';

const data = {
  title: 'Create order',
  template: 'overview',
  description: (
    <>
      Welcome to our order creation wizard! We are here to assist you in creating a new order. If
      you are shipping directly to individual customers, please select <b>B2C order</b>. If you are
      a shipping to other businesses, please select <b>B2B order</b>
    </>
  ),
  options: [
    {
      type: BUSINESS_MODEL.B2C,
      icon: <B2CIcon sx={{ width: '240px', height: '250px' }} />,
      title: 'Create B2C order',
      template: 'b2c.overview',
      description: (
        <>
          Welcome to our <b>B2C order wizard</b>. Please choose the option that best suits your
          needs. You can create a <b>single order</b> by entering the details manually.
          Alternatively, if you have <b>multiple orders</b>, you can use a CSV file to upload them.
        </>
      ),
      label: 'B2C order',
      param: [
        ORDER_KEY_PARAM.IS_CREATE_ORDER_OPTION_MODAL,
        ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL,
        true
      ],
      options: [
        {
          label: 'Single order',
          icon: <ClipboardListIcon sx={{ width: '240px', height: '240px' }} />,
          type: 'single',
          param: [
            ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL,
            ORDER_KEY_PARAM.IS_CREATE_SINGLE_ORDER_MODAL,
            true
          ]
        },
        {
          label: 'Multiple orders',
          icon: <CsvUpload sx={{ width: '240px', height: '240px' }} />,
          type: 'multiple',
          param: [
            ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL,
            ORDER_KEY_PARAM.IS_CREATE_MULTIPLE_ORDER_MODAL,
            true
          ]
        }
      ]
    },
    {
      type: BUSINESS_MODEL.B2B,
      icon: <B2BIcon sx={{ width: '240px', height: '240px' }} />,
      title: 'Create B2B order',
      description:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id illo beatae blanditiis quibusdam ipsa eveniet temporibus earum aliquam sequi sed odio, molestiae eaque suscipit veniam cum tempore molestias. Veniam, voluptate.',
      label: 'B2B order',
      param: [
        ORDER_KEY_PARAM.IS_CREATE_ORDER_OPTION_MODAL,
        ORDER_KEY_PARAM.IS_CREATE_B2B_ORDER_MODAL,
        true
      ],
      options: null
    }
  ]
};

const CategorizingOrderModal = ({ isOpen, model }) => {
  const history = useHistory();
  const query = useQuery();

  const getOrderContentData = () => {
    if (query.get(ORDER_KEY_PARAM.IS_CREATE_B2C_ORDER_MODAL)) {
      return data.options[0];
    }
    /* if (query.get(ORDER_KEY_PARAM.IS_CREATE_B2B_ORDER_MODAL)) {
      return data.options[1];
    } */
    return data;
  };

  const handleCloseCreateOrderModal = () => {
    history.push({ search: undefined });
  };

  return (
    <SlzGridModal
      id="order-add-slz-modal"
      isOpen={isOpen}
      onClose={handleCloseCreateOrderModal}
      sx={{ padding: '1.5rem 5.25rem' }}>
      <CategorizingOrderContent data={getOrderContentData()} />
    </SlzGridModal>
  );
};

export default CategorizingOrderModal;

const CategorizingOrderHeader = ({ data, isShowDescription }) => (
  <Fragment>
    <Heading as="h3" fontSize="32px" textAlign="center" my={2} lineHeight="base">
      {data.title}
    </Heading>
    <OrderSkeleton isLoaded={isShowDescription} h="2.796rem" w="100%" mb="3.375rem">
      <Text textAlign="justify" fontSize="1rem" letterSpacing="-0.16px">
        {data.description}
      </Text>
    </OrderSkeleton>
  </Fragment>
);

const CategorizingOrderBody = ({ options }) => {
  const query = useQuery();
  const history = useHistory();

  const setOrderOption = (param) => {
    query.delete(param[0]);
    query.set(param[1], param[2]);
    history.push({
      search: query.toString()
    });
  };

  return (
    <Box mb="12">
      <HStack spacing={20}>
        {options?.map(({ label, icon, param }) => (
          <Box key={label} color="dark.500">
            <SlzPaper
              w="23.25rem"
              h="23.25rem"
              textAlign="center"
              lineHeight="23.25rem"
              bgColor="light.500"
              mb={4}
              _hover={{
                borderColor: 'main.500',
                borderWidth: 3,
                cursor: 'pointer'
              }}
              onClick={() => setOrderOption(param)}>
              {icon}
            </SlzPaper>
            <Text textAlign="center" fontSize="2xl">
              {label}
            </Text>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

const CategorizingOrderContent = ({ data }) => {
  const requiredSettingProps = useCheckRequiredSettings();

  return (
    <GridItem gridColumn={'2/12'}>
      <GridItem gridColumn={'2/12'}>
        <CategorizingOrderHeader
          data={data}
          isShowDescription={!requiredSettingProps.isRequiredSettingsFetching}
        />
      </GridItem>
      <SetupRequirement.RequirementValidation requiredSettingProps={requiredSettingProps} mt="14">
        {({ isRequiredSettingsFetching }) => (
          <OrderSkeleton
            isLoaded={!isRequiredSettingsFetching}
            title={data.title}
            template={data.template || null}>
            <GridItem gridColumn={'2/12'} mt="14">
              <CategorizingOrderBody options={data.options} />
            </GridItem>
          </OrderSkeleton>
        )}
      </SetupRequirement.RequirementValidation>
    </GridItem>
  );
};
