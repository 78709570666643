import { Icon } from '@chakra-ui/react';
import React from 'react';
const BiCloudUpload = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      fill="#676A74"
      strokeWidth="0"
      id="tabler-icon-cloud-upload"
      width="192.291"
      height="192.291"
      viewBox="0 0 192.291 192.291"
      {...props}>
      <path id="Path_9723" data-name="Path 9723" d="M0,0H192.291V192.291H0Z" fill="none" />
      <path
        id="Path_9724"
        data-name="Path 9724"
        d="M143.146,124.544h-8.012a8.012,8.012,0,1,1,0-16.024h8.012a20.03,20.03,0,0,0,0-40.061h-8.012a8.012,8.012,0,0,1-7.818-9.767A29.019,29.019,0,0,0,123.01,36.15c-5.285-7.979-14.206-13.625-24.474-15.492C78.41,17,58.8,28.446,54.819,46.177A8.012,8.012,0,0,1,47,52.434c-16.366,0-29.681,12.58-29.681,28.042S30.635,108.519,47,108.519a8.012,8.012,0,1,1,0,16.024C21.8,124.544,1.3,104.775,1.3,80.477c0-22.31,17.285-40.8,39.621-43.678A48.91,48.91,0,0,1,62.093,11.847,56.773,56.773,0,0,1,101.4,4.892C115.942,7.535,128.687,15.7,136.369,27.3a45.288,45.288,0,0,1,7.676,25.145,36.055,36.055,0,0,1-.9,72.1Z"
        transform="translate(9.084 27.687)"
        fill="#676a74"
      />
      <path
        id="Path_9725"
        data-name="Path 9725"
        d="M64.085,51.061a7.987,7.987,0,0,1-5.665-2.347L40.049,30.343,21.678,48.714A8.012,8.012,0,0,1,10.347,37.383L34.383,13.347a8.012,8.012,0,0,1,11.331,0L69.75,37.383a8.012,8.012,0,0,1-5.665,13.678Z"
        transform="translate(56.097 77.133)"
        fill="#676a74"
      />
      <path
        id="Line_429"
        data-name="Line 429"
        d="M7.012,87.133A8.012,8.012,0,0,1-1,79.121V7.012a8.012,8.012,0,1,1,16.024,0V79.121A8.012,8.012,0,0,1,7.012,87.133Z"
        transform="translate(89.133 89.133)"
        fill="#676a74"
      />
    </Icon>
  );
};
export default BiCloudUpload;
