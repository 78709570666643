import { Fragment, useState } from 'react';
import { uniqueId } from 'lodash';
import {
  Avatar,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToken
} from '@chakra-ui/react';
import { useQuery as useRouterQuery } from 'hooks/useQuery';
import { FaChevronLeft } from 'react-icons/fa';
import { useHistory } from 'react-router';
import intersection from 'lodash/intersection';

import { CompanyIcon, LogoutIcon, SettingIcon, UserIcon } from 'components/common/Icons';
import {
  ACTIVE_USER_SETTING_MODAL,
  NAVIGATE_ITEMS_STATUS,
  NAVIGATE_MENU_STATUS,
  MANAGE_USER_PERMISSION,
  MANAGE_PREFERENCE_PERMISSION
} from 'constants/common';
import { SlzButton } from 'components/common/SlzButton';
import useAuth from 'hooks/useAuth';
import { useGlobalState } from 'contexts/GlobalContext';
import { getUser } from 'Utilities';
import {
  MAPPING_ROLE_BY_PERMISSIONS,
  RoleTags,
  USER_MANAGEMENT_KEY_PARAM
} from 'modules/users/constants';
import useCheckRequiredSettings from 'hooks/useCheckRequiredSettings';
import RequiredSettingStatus from './RequiredSettingStatus';
import { SlzTag } from 'components/common/SlzTag';
import { useGetUserByIdQuery } from 'modules/users/hooks/userGetUserByIdQuery';

const getUserRole = (permissions) => {
  let roleIndex = 2;
  for (const key of Object.keys(MAPPING_ROLE_BY_PERMISSIONS)) {
    const commonPermissions = intersection(permissions, MAPPING_ROLE_BY_PERMISSIONS[key]);

    const isMatched = commonPermissions.length === MAPPING_ROLE_BY_PERMISSIONS[key].length;
    if (isMatched) {
      roleIndex = key;
      break;
    }
  }

  return RoleTags[roleIndex];
};

const NavigationRoleHeader = ({ permissions }) => {
  const roleTag = getUserRole(permissions) || RoleTags.retailerUser;
  return (
    <SlzTag
      variant="outline"
      size="sm"
      color={roleTag?.color}
      boxShadow={`inset 0 0 0px 1px ${useToken('colors', roleTag?.color)}`}
      bg="white"
      mb="1px"
      px={3}
      w="fit-content"
      borderRadius="3px">
      {roleTag?.label}
    </SlzTag>
  );
};

const NavigationIcon = ({ iconType }) => {
  const NAVIGATE_ICON_MAP = {
    [NAVIGATE_MENU_STATUS.USER]: <UserIcon w="1.875rem" h="1.875rem" mr="2" />,
    [NAVIGATE_MENU_STATUS.COMPANY]: <CompanyIcon w="1.875rem" h="1.875rem" mr="2" />,
    [NAVIGATE_MENU_STATUS.SETTING]: <SettingIcon w="1.875rem" h="1.875rem" mr="2" />,
    [NAVIGATE_MENU_STATUS.LOGOUT]: <LogoutIcon w="1.875rem" h="1.875rem" mr="2" fontWeight="bold" />
  };
  return NAVIGATE_ICON_MAP[iconType];
};

const NavigationHeader = (props) => {
  const { activeMenu, name, email, profiles, permissions, onNavigationItemClick } = props;

  return (
    <>
      {activeMenu === NAVIGATE_MENU_STATUS.MAIN ? (
        <>
          <Avatar w="30px" h="30px" name={name || ''} />
          <Flex flexDirection="column" ml={3} wordBreak="break-all">
            <Text color="main.500" fontWeight="bold" m={0} mb={-0.5} pt={0.5}>
              {name}
            </Text>
            <Text fontSize="0.75rem" m={0} mt={-0.5} pb={0.5}>
              {email}
            </Text>
            <NavigationRoleHeader permissions={permissions} />
          </Flex>
        </>
      ) : (
        <SlzButton
          w="full"
          justifyContent="flex-start"
          p={0}
          my="0.350rem"
          leftIcon={<Icon as={FaChevronLeft} fontSize="md" color="dark.700" ml="-1" />}
          onClick={onNavigationItemClick}
          colorScheme="whiteAlpha">
          <NavigationIcon iconType={activeMenu} />
          <Text
            color="dark.700"
            textTransform="capitalize"
            fontWeight="bold"
            fontSize="1.25rem"
            m={0}>
            {activeMenu}
          </Text>
        </SlzButton>
      )}
    </>
  );
};

const NavigateItems = ({ type, setActiveMenu, onClose }) => {
  const { onLogout } = useAuth();
  const user = getUser();
  const history = useHistory();
  const routerQuery = useRouterQuery();
  const [_, dispatch] = useGlobalState();
  const { requiredSettings } = useCheckRequiredSettings();

  const handleOnClick = ({ label, allowClickOnItem, allowOpenModal, value }) => {
    allowClickOnItem && setActiveMenu(value);

    if (allowOpenModal) {
      dispatch({
        type: ACTIVE_USER_SETTING_MODAL,
        payload: {
          type: value,
          text: label
        }
      });
      onClose();
    }

    if (NAVIGATE_MENU_STATUS.ADDUSER === value) {
      routerQuery.set(USER_MANAGEMENT_KEY_PARAM.IS_CREATE_USER_MANAGEMENT, true);
      history.push({
        search: routerQuery.toString()
      });
      onClose();
    }

    NAVIGATE_MENU_STATUS.LOGOUT === value && onLogout();
    NAVIGATE_MENU_STATUS.MANAGE_USERS === value && history.push('/users');
  };

  const hasManagementUsersPermission = (navigateValue) => {
    if (navigateValue === NAVIGATE_MENU_STATUS.USER)
      return user.permissions.includes(MANAGE_USER_PERMISSION);

    if (navigateValue === NAVIGATE_MENU_STATUS.SETTING)
      return user.permissions.includes(MANAGE_PREFERENCE_PERMISSION);
    
    return true;
  };

  return NAVIGATE_ITEMS_STATUS[type]?.items?.map(
    ({ label, iconLeft, iconRight, value, allowClickOnItem, allowOpenModal }, index) => (
      <>
        {hasManagementUsersPermission(value) && (
          <Fragment key={uniqueId(`user_navigation_${index}`)}>
            <SlzButton
              boxSizing="border-box"
              borderBottom="1px"
              borderColor="userSettingNavItem.borderColor"
              leftIcon={iconLeft}
              rightIcon={
                <RequiredSettingStatus
                  iconRight={iconRight}
                  value={value}
                  requiredSettings={requiredSettings}
                />
              }
              onClick={() => handleOnClick({ label, value, allowClickOnItem, allowOpenModal })}
              className="navigate-item-btn"
              w="full"
              pl="0.74rem"
              fontSize="1rem"
              justifyContent="flex-start"
              colorScheme="whiteAlpha"
              color="dark.700"
              borderRadius={0}
              py="1.469rem"
              _hover={{ bg: 'userSettingNavItem.bgHover' }}>
              {label}
            </SlzButton>
          </Fragment>
        )}
      </>
    )
  );
};

const UserNavigation = () => {
  const user = getUser();
  const [activeMenu, setActiveMenu] = useState(NAVIGATE_MENU_STATUS.MAIN);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data } = useGetUserByIdQuery({ id: user.id });
  const name = data?.forename && data?.surname ? `${data?.forename} ${data?.surname}` : '';

  const handleNavigationItemClick = () => {
    setActiveMenu(NAVIGATE_MENU_STATUS.MAIN);
  };

  return (
    <Popover isLazy placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Avatar
          onClick={() => setActiveMenu(NAVIGATE_MENU_STATUS.MAIN)}
          w="48px"
          h="48px"
          name={name}
        />
      </PopoverTrigger>
      <PopoverContent
        w="16.125rem"
        borderRadius="0.75rem"
        borderColor="stone.300"
        boxShadow="0px 3px 6px #00000029">
        <PopoverArrow
          borderLeftWidth="1px"
          borderTopWidth="1px"
          borderLeftColor="stone.300"
          borderTopColor="stone.300"
        />
        <PopoverHeader display="flex" alignItems="center">
          <NavigationHeader
            name={name}
            email={data?.email || ''}
            activeMenu={activeMenu}
            profiles={user.profiles}
            permissions={user.permissions}
            onNavigationItemClick={handleNavigationItemClick}
          />
        </PopoverHeader>
        <PopoverBody
          p={0}
          sx={{
            '.navigate-item-btn:last-child': {
              borderBottomRadius: '0.65rem',
              py: '1.5rem',
              borderBottom: '0'
            }
          }}>
          <NavigateItems type={activeMenu} setActiveMenu={setActiveMenu} onClose={onClose} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default UserNavigation;
