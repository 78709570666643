import { PRODUCT_PATHNAME } from '../constants';
import ProductList from '../pages/product-list';

const ProductsRoutes = [
  {
    path: PRODUCT_PATHNAME,
    component: ProductList,
    profile: 'Supplier',
    feature: 'ViewStock',
    exact: true
  }
];

export default ProductsRoutes;
