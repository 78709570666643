import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from 'config/axios';

export async function getTodayOrderCountByStatus(status) {
  const url = CommonEndpoints.ORDERS.GET.TODAY_ORDER_COUNT_BY_STATUS.replace('{status}', status);
  return await axiosClient.get(url);
}

export async function getOrderAverageCost() {
  const url = CommonEndpoints.ORDERS.GET.ORDER_AVERAGE;
  return await axiosClient.get(url);
}

export async function getCountOrder() {
  const url = CommonEndpoints.ORDERS.GET.ORDER_COUNT;
  return await axiosClient.get(url);
}

export const getOrdersToday = async () => {
  const url = CommonEndpoints.ORDERS.GET.FULFILMENT_TOTALS;
  return await axiosClient.get(url);
};

export async function getTotalOrderByArea() {
  const url = CommonEndpoints.ORDERS.GET.TOTALS_BY_AREA;
  return await axiosClient.get(url);
}

export const getRecentUnsuccessfulOrders = async () => {
  const url = CommonEndpoints.RETAILER.GET.UNSUCCESSFUL;
  return await axiosClient.get(url);
};

export async function getPopularProducts() {
  const url = CommonEndpoints.ORDERS.GET.POPULAR_ORDERS;
  return await axiosClient.get(url);
}

export async function getOrderCosts() {
  const url = CommonEndpoints.ORDERS.GET.COSTS_BY_DATE;
  return await axiosClient.get(url);
}

export async function getOrderTotals() {
  const url = CommonEndpoints.ORDERS.GET.TOTALS_BY_DATE;
  return await axiosClient.get(url);
}
