const borderRadius = {
  radii: {
    0.25: '0.0625rem', // 1px
    0.75: '0.1875rem', // 3px
    1: '0.25rem',
    1.5: '0.375rem',
    2.5: '0.625rem', // 10px
    3: '0.75rem',
    4: '1rem', // 16px
    6: '1.5rem'
  }
};

export default borderRadius;
