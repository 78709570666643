import { Image, Text } from '@chakra-ui/react';
import * as yup from 'yup';
import {
  AmazonShortIcon,
  EbayShortIcon,
  ShopifyIcon,
  ShopifyShortIcon,
  WoocommerceShortIcon
} from 'components/common/Icons/integrations';
import {
  ApiSetupDetailAndPermForm,
  ApiSetupSQSNotificationForm,
  RateMatcherStepInstruction,
  RateMatcherStepOne,
  RateMatcherStepSummary,
  RateMatcherStepTwo
} from '../components/form';

export const INTEGRATION_PATHNAME = '/integration';
export const SHOPIFY_STORE_URL = 'https://apps.shopify.com/selazar';
export const RATE_MATCHER_TOAST_ID = 'rate-macher-toast';

export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Integrations'
  }
];

export const INTEGRATION_TAB = {
  my_app: {
    key: 'my_app',
    label: 'My Apps'
  },
  api: {
    key: 'api',
    label: 'APIs'
  },
  market_place: {
    key: 'market_place',
    label: 'Marketplace'
  }
};

export const INTEGRATION_TYPE = {
  shopify: 'Shopify',
  shopify_v2: 'Shopify 2.0',
  woocommerce: 'WooCommerce',
  amazon: 'Amazon',
  ebay: 'Ebay'
};

export const MARKET_PLACE_CARD_STATUS = {
  new: 'New',
  existing: 'Existing'
};

export const DEFAULT_EXPIRED_PASSWORD_INTEGRATION_IN_MINUTES = 5;

export const MARKET_PLACE_CARDS = [
  {
    type: INTEGRATION_TYPE.shopify,
    icon: <ShopifyShortIcon w="3rem" h="3rem" />,
    name: 'Shopify',
    status: MARKET_PLACE_CARD_STATUS.new,
    description: 'E-commerce platform for online stores and retail point-of-sale systems.',
    externalLink: '#',
    tooltipContent: 'Tooltip content'
  },
  {
    type: INTEGRATION_TYPE.shopify_v2,
    icon: <ShopifyShortIcon w="3rem" h="3rem" />,
    name: 'Shopify',
    status: MARKET_PLACE_CARD_STATUS.new,
    description: 'E-commerce platform for online stores and retail point-of-sale systems.',
    externalLink: '#',
    tooltipContent: 'Tooltip content'
  },
  {
    type: INTEGRATION_TYPE.woocommerce,
    icon: <WoocommerceShortIcon w="3rem" h="1.875rem" />,
    name: 'Woocommerce',
    status: MARKET_PLACE_CARD_STATUS.new,
    description: 'WooCommerce is an open-source e-commerce plugin for WordPress.',
    externalLink: '#',
    tooltipContent: 'Tooltip content'
  },
  {
    type: INTEGRATION_TYPE.amazon,
    icon: <AmazonShortIcon w="6.25rem" h="1.875rem" />,
    name: 'Amazon',
    status: MARKET_PLACE_CARD_STATUS.new,
    description:
      'Amazon is an online marketplace that enables third-party sellers to list their products.',
    externalLink: '#',
    tooltipContent: 'Tooltip content'
  },
  {
    type: INTEGRATION_TYPE.ebay,
    icon: <EbayShortIcon w="4.688rem" h="1.875rem" />,
    name: 'Ebay',
    status: MARKET_PLACE_CARD_STATUS.existing,
    description:
      'eBay is an online marketplace which allows to buy and sell goods and services worldwide',
    externalLink: '#',
    tooltipContent: 'Tooltip content'
  }
];

export const INTEGRATION_EXIST_ERROR = 'Integration already exists';

export const MANAGE_TYPE = {
  CONNECT: 'connect',
  INVENTORY: 'inventory',
  ERRORS: 'errors',
  UPDATED_STORE_NAME: 'updatedStoreName',
  UPDATED_STORE_URL: 'updatedStoreUrl',
  UPDATED_CREDENTIALS: 'updatedCredentials',
  CREATED_SUCCESS: 'createdSuccess',
  ALREADY_INTEGRATION: 'alreadyIntegration',
  ERROR_CREATE_INTEGRATION: 'errorCreateIntegration'
};

export const MESSAGES_MANAGE = {
  connect: `Your Store {{shopName}} is now {{status}}`,
  inventory: `Manage my inventory is now switched {{status}} for {{shopName}}`,
  errors:
    'Your Woocommerce store credentials could not be verified, please check you have the correct information and try again',
  updatedStoreName: `Your {{integrationType}} store name has been updated`,
  updatedStoreUrl: `Your {{integrationType}} store url has been updated`,
  updatedCredentials: `Your {{integrationType}} credentials has been updated`,
  createdSuccess: `Your {{integrationType}} app is now installed and connected. You must finish setting it up by configuring the rate matcher`,
  alreadyIntegration: `Your {{integrationType}} store {{shopName}} is already integrated with Selazar`,
  errorCreateIntegration: `Your {{integrationType}} store credentials could not be verified, please check you have the correct information and try again`,
  passwordValidationError: `Your {{integrationType}} store credentials could not be verified, please check you have the correct information and try again`
};

export const INTEGRATIONS_TEXT = {
  instructionPassword:
    'Please enter your WooCommerce password if you want to make any changes to your integration. For security reasons, we can only store your password for five minutes.'
};

export const KEY = {
  PASSWORD: 'password'
};

export const CARD_ICONS = {
  [INTEGRATION_TYPE.shopify]: <ShopifyIcon w="5.25rem" h="1.5rem" />,
  [INTEGRATION_TYPE.woocommerce]: (
    <Image src="/Images/woocommerce-logo.png" w="7.375rem" h="1.5rem" />
  )
};

export const RATE_MATCHER_SUMMARY_TEXT = {
  yourRateMatcherSummary: 'Your rate matcher summary',
  selectedDefaultRate: 'Selected default rates',
  manuallyMatchedRates: 'Manually matched rates',
  unMatchedRates: 'Unmatched rates',
  summaryNote:
    'If you introduce new shipping rates to your Shopify store and wish to match them to a Selazar service, you must update this configuration, otherwise Selazar will use your current defaults.'
};

export const RATE_MATCHER_SETTING_STEP = {
  instruction: 'instruction',
  overview: 'overview',
  step1: 'step1',
  step2: 'step2',
  summary: 'summary'
};

export const RATE_MATCHER_SETTING_STEP_COMPONENT = {
  instruction: RateMatcherStepInstruction,
  overview: RateMatcherStepSummary,
  step1: RateMatcherStepOne,
  step2: RateMatcherStepTwo,
  summary: RateMatcherStepSummary
};

export const RATE_MATCHER_CTA_TEXT = {
  [RATE_MATCHER_SETTING_STEP.step1]: {
    title: 'You have not completed your shipping options!',
    description: (
      <>
        Not configuring your shipping options may result in higher delivery charges than expected.{' '}
        <br />
        We recommend you assign all your Shopify shipping rates to the Selazar delivery times.{' '}
        <br />
        Default times are used in case we cannot determine the correct shipping option for your
        order.
      </>
    )
  }
};

export const RATE_MATCHER_STEP_1 = {
  header: 'Step one - Set your default shipping rates',
  description:
    'The Selazar platform will identify the number of available shipping options that you have created within your Shopify store and allow you to assign these to the desired Selazar shipping times.'
};

export const RATE_MATCHER_STEP_2 = {
  header: 'Step two - Match your Shopify shipping rates',
  description:
    'The Selazar platform will identify the number of available shipping options that you have created within your Shopify store and allow you to assign these to the desired Selazar shipping times.'
};

export const UK = 'UK';
export const EU = 'EU';
export const ROW = 'RestOfWorld';

export const AVAILABLE_COUNTRY_REGIONS = [UK, EU, ROW];

export const SFP = 'Self Fulfilled Package';
export const SFP_VALUE = 'SFP';
export const SERVICE_TIMES_ORDERED = [
  'TwentyFourPreTenThirty',
  'TwentyFourPreNoon',
  'TwentyFour',
  'FortyEight',
  'SeventyTwo',
  'OneToTwo',
  'TwoToThree',
  'ThreeToFive',
  'FiveToTen',
  'TwentyFour_NI',
  'TwentyFour_ROI',
  'ThreeToEight_RestOfEurope',
  'FortyEight_UK'
];

export const SERVICE_TIMES_ORDERED_LABEL = {
  TwentyFourPreTenThirty: '24 Hour Pre-10:30',
  TwentyFourPreNoon: '24 Hour Pre-noon',
  TwentyFour: '24 Hour',
  FortyEight: '48 Hour',
  SeventyTwo: '72 Hour',
  OneToTwo: '1-2 Days',
  TwoToThree: '2-3 Days',
  ThreeToFive: '3-5 Days',
  FiveToTen: '5-10 Days',
  TwentyFour_NI: 'Intra Northern Ireland - 24 Hour',
  TwentyFour_ROI: 'Republic of Ireland - 24 Hour',
  ThreeToEight_RestOfEurope: 'Rest of Europe - 3-8 Days',
  FortyEight_UK: 'UK Mainland - 48 Hour'
};

export const SERVICE_TIME_COUNTRY_REGIONS = {
  EU: 'EU',
  UK: 'UK',
  RestOfWorld: 'Rest of the world'
};

export const RATE_MATCHER_MATCHED_DEFAULT = 0;

export const RATE_MATCHER_DEFAULT_VALUES = {
  shippingRates: []
};

export const SHOPIFY_SHIPPING_RATES_BUTTON_ACTIONS = [
  {
    value: 'remove',
    text: 'Remove',
    isDisabled: false
  },
  {
    value: 'edit',
    text: 'Edit',
    isDisabled: false
  }
];

export const RATE_MATCHER_HEADER_TEXT = 'Shipping rate matcher configuration';

export const RATE_MATCHER_STEP_INSTRUCTION_TEXT = {
  header: `The Shopify rate matcher is used to match the shipping rates that you created in Shopify to the desired shipping service times within the Selazar portal.`,
  steps: `There are two simple steps.`,
  step1: `Step one. `,
  step1Description: `Set your default shipping rates per general region, in case your shipping rates cannot be directly matched.`,
  step2: `Step two. `,
  step2Description: `Refine your Shopify shipping rates by matching them to a Selazar
  service time. If you have set your default shipping for each region, this step is
  optional. Before we can match any rates to service times we need to ensure that the
  rates (profiles) are created in Shopify.`
};

export const RATE_MATCHER_SHIPPING_OPTION_TEXT = {
  description: `The Selazar platform will identify the number of available shipping options that you
  have created within your Shopify store and allow you to assign these to the desired
  Selazar shipping times.`,
  totalOption: `Total Shopify shipping options:`,
  allRateMatched: `All your rates have been matched`,
  unMatchedRates: `Unmatched shopify rates:`
};

export const DEFAULT_RATE_MATCHER_STEP1 = {
  ukDefaultShipping: 'UK shipping',
  euDefaultShipping: 'EU shipping',
  restofworldDefaultShipping: 'Rest of the world'
};

export const REGION_SHIPPING_LABELS = {
  UK: 'UK default shipping',
  EU: 'EU default shipping',
  RestOfWorld: 'Rest of the world default shipping'
};

export const CREATE_SHIPPING_RATES_SUCCESS = 'Your rate matcher has been successfully configured!';

export const CREATE_SHIPPING_RATES_FAILED = 'Could not create Shopify shipping rate preferences!';

export const PREFIX = 'rateMatcher';

export const API_CARD_TEXT = {
  api: 'API',
  connected: 'Connected',
  apiConnection: 'API connection',
  apiConnectionDesc: 'Set up an API connection to connect your app.',
  lastConnectionDate: 'Last connection date',
  setupNew: 'Set up new',
  delete: 'Delete',
  manage: 'Manage'
};

export const REMOVE_API_CONNECTION = {
  title: 'Delete this API connection?',
  description: (apiName) => `Are you sure you want to delete ${apiName} API connection? 
    Deleting will revoke credentials and break any connections this connection is used for in your app.`,
  okText: 'Delete'
};

export const TOAST_MESSAGE_SUCCESS = 'We have successfully deleted the API connection.';
export const WARNING_LEAVE_TOAST_ID = 'api-warning-leave-toast-id';
export const ALPHANUMERIC_DASH_MIN_PATTERN = /^[a-zA-Z0-9- ]{3,}$/;

export const DEFAULT_FORM_VALUES = {
  name: '',
  terms: false,
  permissions: {},
  inventorySQSSettings: {
    enabled: false,
    url: '',
    accessKey: '',
    privateKey: '',
    ignore_whitespace: false
  },
  ordersSQSSettings: {
    enabled: false,
    url: '',
    accessKey: '',
    privateKey: '',
    ignore_whitespace: false
  }
};
const BASE_SQS_VALIDATION_SCHEMA = yup.object({
  enabled: yup.boolean(),
  url: yup.string().when('enabled', (enabled, schema) => {
    return enabled ? schema.required('SQS URL is required') : schema;
  }),
  accessKey: yup.string().when('enabled', (enabled, schema) => {
    return enabled ? schema.required('Access Key is required') : schema;
  }),
  privateKey: yup.string().when('enabled', (enabled, schema) => {
    return enabled ? schema.required('Private Key is required') : schema;
  })
});

export const API_SETUP_DETAIL_VALIDATION_SCHEMA = yup.object({
  name: yup
    .string()
    .trim()
    .required(
      'Make sure the name only contains letters, numbers, dashes and is at least 3 characters'
    )
    .matches(
      ALPHANUMERIC_DASH_MIN_PATTERN,
      'Make sure the name only contains letters, numbers, dashes and is at least 3 characters'
    ),
  terms: yup
    .boolean()
    .oneOf(
      [true],
      'Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy'
    )
});

export const API_SETUP_NOTIFICATION_VALIDATION_SCHEMA = yup.object({
  inventorySQSSettings: BASE_SQS_VALIDATION_SCHEMA,
  ordersSQSSettings: BASE_SQS_VALIDATION_SCHEMA
});

export const NO_ACCESS_PERMISSION_OPTION = {
  value: '',
  label: 'No Access'
};
export const MODULE_ORDERS = 'Orders';
export const MODULE_STOCK = 'Stock';
export const READ_AND_WRITE_ACCESS = 'Read and Write Access';
export const API_SETUP_STEPS = [
  {
    component: (props) => <ApiSetupDetailAndPermForm {...props} />
  },
  {
    component: (props) => <ApiSetupSQSNotificationForm {...props} />
  }
];
export const API_SETUP_DETAIL_STEP = 0;
export const API_SETUP_SQS_STEP = 1;

export const API_SETUP_TEXT = {
  apis: 'APIs',
  setupNew: 'Set up a new integration',
  manageIntegration: 'Manage integration',
  yourApiDetail: 'Your app details',
  permissions: 'Permissions',
  confirmTerm: 'I confirm and agree to the privacy policy and terms & conditions.',
  receiveNotificationsForStock: 'Receive notifications via SQS for stock',
  receiveNotificationsForOrders: 'Receive notifications via SQS for orders',
  sqsNotifications: 'SQS notifications',
  ordersAccess: 'Orders access',
  stockAccess: 'Stock access',

  successMessage: (apiName) => `Your API integration ${apiName} is now connected`,
  successUpdateMessage: (apiName) => `Your API integration ${apiName} has been updated`,
  errorMessage: `This name already exists and the integration cannot be created. Please choose a different name and try again.`,
  warningMessage: 'Oops! Are you sure you want to leave without finishing the set up?',
  setupButton: 'Set up',
  leaveButton: 'Leave',
  updateButton: 'Update'
};

export const API_PERMISSION_TOOLTIP_CONTENT = (
  <Text fontSize="md">
    You should only choose the permissions your private apps need to work. Don’t use API access in
    ways that violate the Selazar API License terms.
  </Text>
);
