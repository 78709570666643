import { HStack } from '@chakra-ui/react';
import { SlzButton } from 'components/common/SlzButton';

const Action = ({ onClose }) => {
  return (
    <HStack w="full" justifyContent="space-between">
      <SlzButton colorScheme="main" size="lg" variant="outline" onClick={onClose}>
        Back
      </SlzButton>
    </HStack>
  );
};

export default Action;
