import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SlzSelect } from 'components/common/SlzSelect';
import Field from './Field';

export default function ControlledField({
  component: Component = SlzSelect,
  name = '',
  label,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => {
        const isBooleanValue = typeof field.value === 'boolean';
        isBooleanValue && Object.assign(field, { isChecked: field.value });

        return <Field label={label} component={Component} {...props} {...field} />;
      }}
      control={control}
      name={name}
    />
  );
}
