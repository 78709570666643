import { CREATE_BUNDLE_STEP, EDIT_BUNDLE_STEP } from '../../constants';
import {
  BundleConfirmationForm,
  BundleDetailsForm,
  EditBundleForm,
  ProductSelectionForm
} from '../form';

import { Mode } from '../../constants/index';

const CREATE_BUNDLE_CONTENT = {
  [CREATE_BUNDLE_STEP.BUNDLE_DETAILS]: BundleDetailsForm,
  [CREATE_BUNDLE_STEP.PRODUCT_SELECTION]: ProductSelectionForm,
  [CREATE_BUNDLE_STEP.BUNDLE_CONFIRMATION]: BundleConfirmationForm
};

const EDIT_BUNDLE_CONTENT = {
  [EDIT_BUNDLE_STEP.BUNDLE_DETAILS]: EditBundleForm,
  [EDIT_BUNDLE_STEP.PRODUCT_SELECTION]: ProductSelectionForm
};

const BUNDLE_MODAL_CONTENTS = {
  [Mode.ADD]: (type) => CREATE_BUNDLE_CONTENT[type],
  [Mode.EDIT]: (type) => EDIT_BUNDLE_CONTENT[type]
};

const BundleModalContent = ({ type, action, ...rest }) => {
  const Content = BUNDLE_MODAL_CONTENTS[action](type);
  return <Content {...rest} />;
};

export default BundleModalContent;
