import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { SlzSplitButton } from 'components/common/SlzButton';
import { COL_CUSTOM } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';
import { SlzTag } from 'components/common/SlzTag';
import { ACTION_OPTIONS } from 'modules/users/constants';
import { getRandomColorById, getUserLastSeen } from 'modules/users/utils';

export const USERS_COLUMNS = [
  {
    field: 'image',
    type: COL_CUSTOM,
    width: 84,
    render: ({ row, variant }) => {
      const summaryName =
        row?.forename?.charAt(0)?.toUpperCase() + row?.surname?.charAt(0)?.toUpperCase();
      return (
        <Flex
          w="inherit"
          justifyContent="center"
          alignItems="center"
          bg={getRandomColorById}
          sx={{
            ...getHeightOfCell(variant),
            h: '100%',
            maxH: '100%',
            py: 0
          }}>
          <Text fontSize="2xl" color="light.500">
            {summaryName || ''}
          </Text>
        </Flex>
      );
    }
  },
  {
    name: 'Name',
    field: 'forename',
    isSorted: true,
    type: COL_CUSTOM,
    render: ({ row, onClick }) => {
      return (
        <VStack
          spacing="0.313rem"
          direction="column"
          alignItems="start"
          pl={6}
          pt={3}
          pb="0.563rem">
          <Box textAlign="start">
            <Text
              noOfLines={1}
              fontSize="lg"
              lineHeight="1.313rem"
              fontWeight="bold"
              color="main.500"
              colorScheme="blue"
              textAlign="start"
              onClick={onClick}>
              {row?.forename} {row?.surname}
            </Text>
            <Text
              noOfLines={1}
              fontSize="0.75rem"
              lineHeight="1rem"
              color="dark.700"
              onClick={onClick}>
              {row?.email}
            </Text>
          </Box>
          {row.active ? (
            <SlzTag
              size="sm"
              fontWeight="normal"
              color="white"
              bgColor="#579BFB"
              borderRadius="3px">
              Active
            </SlzTag>
          ) : (
            <SlzTag
              size="sm"
              fontWeight="normal"
              color="red.300"
              bgColor="negative.300"
              borderRadius="3px">
              Inactive
            </SlzTag>
          )}
        </VStack>
      );
    }
  },
  {
    name: 'Last seen',
    field: 'lastSeen',
    isSorted: true,
    render: ({ row }) => {
      return <Text textAlign="start">{getUserLastSeen(row.lastLogin)}</Text>;
    }
  },
  {
    name: 'Actions',
    field: 'actions',
    type: COL_CUSTOM,
    isClickable: true,
    render: ({ row, onClick }) => {
      return (
        <Box>
          <SlzSplitButton
            items={ACTION_OPTIONS(row?.active, row?.thisIsYou)}
            size="lg"
            hasArrow
            placeholder="Actions"
            onSelected={onClick}
            onlyShowPlaceholder></SlzSplitButton>
        </Box>
      );
    }
  }
];
