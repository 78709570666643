import { Icon } from '@chakra-ui/react';
import React from 'react';

const EyeCloseIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="currentColor"
      {...props}>
      <g id="Group_3394" data-name="Group 3394" transform="translate(5716)">
        <circle
          id="Ellipse_209"
          dataName="Ellipse 209"
          cx="15"
          cy="15"
          r="15"
          transform="translate(-5716)"
          fill="currentColor"
        />
        <g id="tabler-icon-eye" transform="translate(-5713 3)">
          <path id="Path_8701" dataName="Path 8701" d="M0,0H24V24H0Z" fill="none" />
          <g id="tabler-icon-eye-off">
            <path id="Path_8698" data-name="Path 8698" d="M0,0H24V24H0Z" fill="none" />
            <line
              id="Line_428"
              data-name="Line 428"
              x2="18"
              y2="18"
              transform="translate(3 3)"
              fill="none"
              stroke="#676a74"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_8699"
              data-name="Path 8699"
              d="M10.584,10.587a2,2,0,0,0,2.828,2.83"
              fill="none"
              stroke="#676a74"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_8700"
              data-name="Path 8700"
              d="M9.363,5.365A9.466,9.466,0,0,1,12,5q6,0,10,7a19.028,19.028,0,0,1-2.5,3.488m-2.14,1.861A9.38,9.38,0,0,1,12,19Q6,19,2,12A15.693,15.693,0,0,1,6.632,6.659"
              fill="none"
              stroke="#676a74"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
};

export default EyeCloseIcon;
