import { GridItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SlzGridModal } from 'components/common/SlzModal';
import { BUNDLE_DEFAULT_FORM_VALUE, CREATE_BUNDLE_STEP, Mode } from 'modules/products/constants';
import BundleModalContent from '../other/BundleModalContent';
import { BundleCreateHeader } from '../other/BundleModalHeader';

export default function CreateBundleModal({ isOpen, onClose, onSubmitBundle }) {
  const [indicatorLabels, setIndicatorLabels] = useState([CREATE_BUNDLE_STEP.BUNDLE_DETAILS]);
  const [step, setStep] = useState(CREATE_BUNDLE_STEP.BUNDLE_DETAILS);
  const [bundle, setBundle] = useState(BUNDLE_DEFAULT_FORM_VALUE);

  const handleChangeContent = (theStep) => (values) => {
    setBundle((prev) => {
      let bundleName = values.name || prev.name;
      setStep(theStep);
      theStep === CREATE_BUNDLE_STEP.BUNDLE_DETAILS
        ? setIndicatorLabels([theStep])
        : setIndicatorLabels([theStep, bundleName]);
      return { ...prev, ...values };
    });
  };

  const handleGoBack = () => {
    if (step === CREATE_BUNDLE_STEP.PRODUCT_SELECTION) {
      handleChangeContent(CREATE_BUNDLE_STEP.BUNDLE_DETAILS)({});
      return;
    }

    if (step === CREATE_BUNDLE_STEP.BUNDLE_CONFIRMATION) {
      handleChangeContent(CREATE_BUNDLE_STEP.PRODUCT_SELECTION)({});
    }
  };

  const handleCancel = () => {
    setBundle(BUNDLE_DEFAULT_FORM_VALUE);
    setStep(CREATE_BUNDLE_STEP.BUNDLE_DETAILS);
    setIndicatorLabels([CREATE_BUNDLE_STEP.BUNDLE_DETAILS]);
    onClose();
  };

  const handleSubmit = (values) => {
    if (step === CREATE_BUNDLE_STEP.BUNDLE_DETAILS) {
      handleChangeContent(CREATE_BUNDLE_STEP.PRODUCT_SELECTION)(values);
      return;
    }

    if (step === CREATE_BUNDLE_STEP.PRODUCT_SELECTION) {
      handleChangeContent(CREATE_BUNDLE_STEP.BUNDLE_CONFIRMATION)(values);
      return;
    }

    if (step === CREATE_BUNDLE_STEP.BUNDLE_CONFIRMATION) {
      onSubmitBundle && onSubmitBundle(bundle); //Do submit data here
      // https://supplierdev.selazar-labs.co.uk/api/productbundle
      handleCancel();
    }
  };

  return (
    <SlzGridModal isOpen={isOpen} onClose={handleCancel}>
      <GridItem gridColumn="2/12" mb="3">
        <BundleCreateHeader title="Create bundle" indicatorLabels={indicatorLabels} />
      </GridItem>
      <GridItem gridColumn="2/12" mb="3">
        <BundleModalContent
          type={step}
          action={Mode.ADD}
          defaultValues={bundle}
          onSubmit={handleSubmit}
          onPrevious={handleGoBack}
          onCancel={handleCancel}
        />
      </GridItem>
    </SlzGridModal>
  );
}
