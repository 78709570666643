import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';

import SlzInput from 'components/common/SlzInput/SlzInput';

const FormInput = (props) => {
  const { name, status, otherPlaceholder = '', value, methods, ...rest } = props;
  const [placeholderContent, setPlaceholderContent] = useState(otherPlaceholder);

  const renderOtherPlaceholder = () => {
    if (!placeholderContent) return null;

    return {
      content: `"${placeholderContent}"`,
      position: 'absolute',
      right: '20px',
      top: '50%',
      fontSize: '0.875rem',
      transform: 'translateY(-50%)',
      color: '#718096'
    };
  };

  useEffect(() => {
    if (status || value) {
      setPlaceholderContent('');
      return;
    }
    setPlaceholderContent(otherPlaceholder);
  }, [status, value]);

  return (
    <Box position="relative" _before={renderOtherPlaceholder()}>
      <SlzInput
        status={status}
        sx={{ '::placeholder': { color: '#718096' } }}
        {...methods.register(name)}
        {...rest}
      />
    </Box>
  );
};

export default FormInput;
