import { Box } from '@chakra-ui/react';
import SlzChip from 'components/common/SlzChip/SlzChip';
import { CARD_ICONS } from 'modules/integrations/constants';

const IntegrationCardIcon = ({ integrationType, isIntegrateFromApp }) => {
  return (
    <Box>
      {CARD_ICONS[integrationType]}
      {isIntegrateFromApp && (
        <SlzChip size="xs" bg="#9EDE14" h={4} lineHeight="1rem" ml={1}>
          2.0
        </SlzChip>
      )}
    </Box>
  );
};
export default IntegrationCardIcon;
