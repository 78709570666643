import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from 'config/axios';
import { buildQuery } from 'utils/helper';

export const callToLogin = (payload) => {
  const url = new URL(CommonEndpoints.LOGIN);
  return axiosClient.post(url, payload);
};

export const resetPassword = (payload) => {
  const url = new URL(CommonEndpoints.PASSWORD_RESET.PUT.CHANGE_PASSWORD);
  return axiosClient.put(url, payload);
};

export const getPasswordResetRequest = (passwordResetRequestId) => {
  const url = `${CommonEndpoints.PASSWORD_RESET.GET.BY_ID}${passwordResetRequestId}`;
  return axiosClient.request(url);
};

export async function forgotPassword(email) {
  try {
    const endpoint = `${CommonEndpoints.PASSWORD_RESET.PUT.RESET_PASSWORD}${email}`;
    const { data } = await axiosClient.put(endpoint);
    return data;
  } catch (err) {
    return err?.response?.data;
  }
}

export async function registerRetailer(payload) {
  const url = CommonEndpoints.REGISTRATION.POST.RETAILER.REGISTER;
  return axiosClient.post(url, payload);
}

export const resendVerificationCode = (payload) => {
  const url = new URL(CommonEndpoints.REGISTRATION.POST.RETAILER.RESEND_VERIFICATION_CODE);
  return axiosClient.post(url, payload);
};

export async function getUsers(filters) {
  const url = CommonEndpoints.GET_FILTERED_USERS;
  return await axiosClient.request(buildQuery(url, filters));
}

export async function toggleUser(userId) {
  const url = `${CommonEndpoints.TOGGLE_USER}${userId}`;
  return axiosClient.put(url);
}

export async function removeUser(userId) {
  const url = `${CommonEndpoints.DELETE_USER}${userId}`;
  return axiosClient.remove(url);
}

export async function editPassword(payload) {
  try {
    const endpoint = CommonEndpoints.EDIT_PASSWORD_USER;
    const { data } = await axiosClient.put(endpoint, payload);
    return data;
  } catch (err) {
    return err?.response?.data;
  }
}

export async function editUserDetail(payload) {
  try {
    const endpoint = CommonEndpoints.EDIT_USER_DETAILS;
    const { data } = await axiosClient.put(endpoint, payload);
    return data;
  } catch (err) {
    return err?.response?.data;
  }
}

export const getUseWithRoles = (id) => {
  const url = `${CommonEndpoints.GET_USER_WITH_ROLES}${id}`;
  return axiosClient.request(url);
};

export async function getRoleCompany() {
  const url = CommonEndpoints.GET_ROLE_OPTIONS_FOR_COMPANY;
  return axiosClient.get(url);
}

export async function addCompanyUser(payload) {
  const url = CommonEndpoints.ADD_COMPANY_USER;
  return axiosClient.post(url, payload);
}

export const confirmEmailRequest = (id) => {
  const url = `${CommonEndpoints.REGISTRATION.POST.EMAIL.CONFIRM}${id}`;
  return axiosClient.post(url);
};

export const getUserById = (id) => {
  const url = `${CommonEndpoints.GET_USER_BY_ID}${id}`;
  return axiosClient.request(url);
};
