import { Flex, Text } from '@chakra-ui/react';
import ControlledField from 'components/form/ControlledField';
import { SlzTextInput } from 'components/common/SlzInput';
import { getCountries } from 'utils';

const AddressDetails = () => {
  return (
    <>
      <Flex gap={3}>
        <ControlledField
          label="Address Line 1*"
          name="addressLine1"
          placeholder="Address Line 1"
          component={SlzTextInput}
          isInlineError
        />

        <ControlledField
          label={
            <>
              Address Line 2
              <Text as="span" fontWeight="normal" fontStyle="italic">
                {' '}
                - optional
              </Text>
            </>
          }
          name="addressLine2"
          placeholder="Address Line 2"
          component={SlzTextInput}
          isInlineError
        />
      </Flex>

      <Flex gap={3} mt="1.125rem">
        <ControlledField
          label="Town*"
          name="town"
          placeholder="Town"
          component={SlzTextInput}
          isInlineError
        />

        <ControlledField
          label="Postcode*"
          name="postCode"
          placeholder="Postcode"
          component={SlzTextInput}
          isInlineError
        />
      </Flex>

      <Flex gap={3} mt="1.125rem">
        <ControlledField
          label="County*"
          name="county"
          placeholder="County"
          component={SlzTextInput}
          isInlineError
        />

        <ControlledField
          label="Country*"
          name="country"
          placeholder="Country"
          options={getCountries()}
          isInlineError
        />
      </Flex>
    </>
  );
};

export default AddressDetails;
