import { Box, Text } from '@chakra-ui/react';

const OrderHeader = (props) => {
  const { text, children, isShow = true, ...rest } = props;

  return (
    isShow && (
      <Box flex={1} {...rest}>
        <Text fontSize="1rem" fontWeight="bold">
          {text}
        </Text>
        {children}
      </Box>
    )
  );
};

export default OrderHeader;
