import { Flex, Image, Text } from '@chakra-ui/react';
import { COL_CUSTOM, DEFAULT_IMAGE } from 'components/common/SlzTable/Constants';
import { getHeightOfCell } from 'components/common/SlzTable/Styles';

export const CONSIGNMENT_BREAKDOWN_ACCORDION_COLUMNS = [
  {
    field: 'image',
    type: COL_CUSTOM,
    width: 48,
    render: ({ row, variant }) => {
      return (
        <Flex
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
            h: '100%',
            maxH: '100%',
            py: 0
          }}>
          <Image
            w="full"
            h="full"
            loading="lazy"
            src={row?.imageUrl || DEFAULT_IMAGE}
            alt=""
            srcSet={row?.imageUrl || DEFAULT_IMAGE}
            fallbackSrc={row?.imageUrl || DEFAULT_IMAGE}
          />
        </Flex>
      );
    }
  },
  {
    field: 'name',
    render: ({ row, variant }) => {
      return (
        <Flex
          direction="column"
          w="inherit"
          sx={{
            ...getHeightOfCell(variant),
          }}>
          <Text fontSize="md" w="full" h="full">{row.name}</Text>
          <Text fontSize="xs" w="full" h="full">{row.sku && `SKU ${row.sku}`}</Text>
        </Flex>
      );
    }
  },
  {
    header: 'EAN/SUID',
    field: 'ean',
    cellValue: (row) => row.ean || row.suid
  },
  {
    field: 'quantity',
    header: 'Quantity',
    type: 'number'
  }
];