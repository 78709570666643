import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useMultiStyleConfig,
  Button
} from '@chakra-ui/react';
import { BsSliders } from 'react-icons/bs';
import { ChevronDownIcon } from '@chakra-ui/icons';
import SlzChip from '../SlzChip/SlzChip';
import SlzTag from '../SlzTag/SlzTag';
import { useState, useCallback, useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import differenceWith from 'lodash/differenceWith';
import { SEL_PREFIX_ID } from '../Constants';
import { FilterSlidersIcon } from '../Icons';

const ROTATION_0_DEG = 'rotate(0deg)';
const ROTATION_180_DEG = 'rotate(180deg)';

const sortOptions = (options) => {
  return options.sort((a, b) => (a.text > b.text ? 1 : -1));
};

const SlzFilter = (props) => {
  const {
    size,
    title,
    options,
    defaultSelectedValues,
    onSelectOption,
    showFilterTitle = true,
    shouldSortOptions = true,
    ...rests
  } = props;
  const styles = useMultiStyleConfig('SlzFilter', { size });

  const [rotation, setRotation] = useState(ROTATION_0_DEG);
  const [isActive, setIsActive] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [unselectedOptions, setUnselectedOptions] = useState(
    shouldSortOptions ? sortOptions(options) : options
  );

  useEffect(() => {
    setUnselectedOptions(options);
    setSelectedOptions([]);
  }, [options]);

  useEffect(() => {
    if (defaultSelectedValues && defaultSelectedValues.length > 0) {
      const defaultSelectedOptions = options.filter((option) =>
        defaultSelectedValues.find((key) => option.key === key)
      );
      const remainOptions = differenceWith(options, defaultSelectedOptions);
      setUnselectedOptions(remainOptions);
      setSelectedOptions(defaultSelectedOptions);
    }
  }, [defaultSelectedValues]);

  const getButtonStyle = () => {
    if (isActive) {
      return styles.buttonActive;
    }
    return styles.button;
  };

  const open = () => {
    setRotation(ROTATION_180_DEG);
    setIsActive(true);
  };

  const close = () => {
    setRotation(ROTATION_0_DEG);
    setIsActive(false);
  };

  const handleSelectOption = useCallback(
    (option) => {
      if (option) {
        setUnselectedOptions((prev) => {
          const filteredOptions = prev.filter((_) => _.key !== option.key);
          return shouldSortOptions ? sortOptions(filteredOptions) : filteredOptions;
        });
        setSelectedOptions((prev) => {
          const options = [...prev, option];
          onSelectOption && onSelectOption(options);
          return options;
        });
      }
    },
    [selectedOptions]
  );

  const handleUnselectOption = useCallback(
    (option) => {
      if (option) {
        setUnselectedOptions((prev) => {
          return shouldSortOptions ? [...prev, option] : sortOptions([...prev, option]);
        });
        setSelectedOptions((prev) => {
          const filteredOptions = prev.filter((_) => _.key !== option.key);
          const options = shouldSortOptions ? sortOptions(filteredOptions) : filteredOptions;
          onSelectOption && onSelectOption(options);
          return options;
        });
      }
    },
    [selectedOptions]
  );

  return (
    <Flex align="center" id={uniqueId(`${SEL_PREFIX_ID}-Slz-Filter`)} {...rests}>
      {showFilterTitle && (
        <Box as="span" fontSize="lg" color="dark.500" fontWeight="medium" pr={3}>
          Filter
        </Box>
      )}
      <Popover placement="bottom-start" onOpen={() => open()} onClose={() => close()} isLazy>
        <PopoverTrigger>
          <Button sx={getButtonStyle()}>
            <FilterSlidersIcon w="1.125rem" h="1.125rem" ml={3} />
            <ChevronDownIcon w={5} h={5} mr={1.5} transitionDuration="0.3s" transform={rotation} />
          </Button>
        </PopoverTrigger>
        <PopoverContent sx={styles.optionContainer}>
          <Box sx={styles.title}>{title}</Box>
          {unselectedOptions.map((option) => (
            <SlzChip
              key={option.key}
              my={option?.my || 1.5}
              mx={1.5}
              h={option?.height || 6}
              color={option?.color || '#FFFFFF'}
              flexBasis="none"
              lineHeight="16px"
              size={option?.size || 'md'}
              cursor="pointer"
              colorScheme={option.colorScheme}
              bg={option.bg}
              onClick={() => handleSelectOption(option)}>
              {option.text}
            </SlzChip>
          ))}
        </PopoverContent>
      </Popover>
      <Flex flexWrap="wrap" pl={3}>
        {selectedOptions.map((option) => (
          <SlzTag
            key={option.key}
            mx={1.5}
            my={0.5}
            size="md"
            variant="outline"
            colorScheme={option.colorScheme}
            borderRadius="full"
            closable={true}
            onClose={() => handleUnselectOption(option)}>
            {option.text}
          </SlzTag>
        ))}
      </Flex>
    </Flex>
  );
};

export default SlzFilter;
