import { useState } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { SlzPagination } from 'components/common/SlzPagination';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';

const PaginatedTooltipContent = ({ tooltipContent }) => {
  const { title, contents, totalPage = 0, isPagination = false } = tooltipContent;
  const [page, setPage] = useState(DEFAULT_PAGE_INDEX);

  const direction = {
    usingDots: true,
    isGreyBg: true,
    isTooltipBg: true,
    prevLabel: '',
    nextLabel: '',
    currentPage: DEFAULT_PAGE_INDEX,
    labelColor: 'light.500',
    labelFontSize: '0.75rem',
    totalPage: totalPage,
    numDots: totalPage,
    onChange: setPage
  };

  return (
    <VStack justifyContent="center" alignItems="center" w="full" spacing={2.5}>
      <VStack w="full" alignItems="flex-start">
        <Text fontSize="0.75rem" fontWeight="medium">
          {title}
          {isPagination && (
            <Box as="span" pl={2.5}>
              {page}/{totalPage}
            </Box>
          )}
        </Text>
        <Text fontSize="0.625rem" fontWeight="normal">
          {contents[page - 1]}
        </Text>
      </VStack>
      {isPagination && (
        <HStack>
          <SlzPagination
            {...{
              colorScheme: 'light',
              showTitle: false,
              variant: 'normalLight',
              pages: null,
              direction: direction
            }}
          />
        </HStack>
      )}
    </VStack>
  );
};
export default PaginatedTooltipContent;
