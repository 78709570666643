import { Icon } from '@chakra-ui/react';

const TruckIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}>
      <g id="Group_34277" data-name="Group 34277" transform="translate(-379 -352)">
        <circle
          id="Ellipse_1219"
          data-name="Ellipse 1219"
          cx="10"
          cy="10"
          r="10"
          transform="translate(379 352)"
          fill="#0973ea"
        />
        <g id="Group_34274" data-name="Group 34274" transform="translate(383.635 356.914)">
          <g id="Group_34276" data-name="Group 34276" transform="translate(0 1)">
            <path
              id="Path_10027"
              data-name="Path 10027"
              d="M10.711,3.44a.515.515,0,0,0-.059-.134L9.124.758A.511.511,0,0,0,8.686.51H6.642A.511.511,0,0,0,6.131,0H.511a.511.511,0,1,0,0,1.022H5.62V6.13H4.5a1.524,1.524,0,0,0-2.878,0H1.533V4.6a.511.511,0,0,0-1.022,0V6.641a.511.511,0,0,0,.511.511h.6a1.524,1.524,0,0,0,2.878,0H6.736a1.524,1.524,0,0,0,2.878,0h.6a.511.511,0,0,0,.511-.511V3.584a.515.515,0,0,0-.018-.145M3.066,7.152a.511.511,0,1,1,.511-.511.511.511,0,0,1-.511.511m3.577-5.62H8.4l.92,1.533H6.642Zm1.533,5.62a.511.511,0,1,1,.511-.511.511.511,0,0,1-.511.511M9.708,6.13H9.614a1.524,1.524,0,0,0-2.878,0H6.642V4.087H9.708Z"
              transform="translate(0 -0.001)"
              fill="#fafafa"
            />
            <path
              id="Path_10028"
              data-name="Path 10028"
              d="M1.511,5.022H3.555A.511.511,0,0,0,3.555,4H1.511a.511.511,0,0,0,0,1.022"
              transform="translate(-0.489 -1.957)"
              fill="#fafafa"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
};

export default TruckIcon;
