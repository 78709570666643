import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@chakra-ui/react';

import {
  CustomFillIcon,
  EvriLogoCompany,
  FlyerIcon,
  BagIcon,
  PackagingIcon,
  RoyalMailLogoCompany,
  StickerIcon,
  TageIcon,
  UpsLogoCompany,
  WhistlLogoCompany,
  YodelLogoCompany,
  TriangleWarningIcon
} from 'components/common/Icons';
import { COMPANY_NAVIGATE_MENU_STATUS, SETTING_NAVIGATE_MENU_STATUS } from 'constants/common';
import { SELECT_STYLES } from 'components/common/SlzDropdown/constants';

import {
  CourierSetting,
  CustomerSetting,
  OrderSetting,
  PaymentSetting,
  ReturnSetting,
  TaxAndDutySetting,
  WarehouseSetting
} from 'modules/preferences/components';
import PackageSetting from 'modules/preferences/components/PackageSetting';
import {
  DefaultModalLayout,
  ProductImagesRequiredModal,
  CreditConfigsModalLayout,
  DirectDebitConfigsModalLayout
} from 'modules/preferences/components/modal';
import {
  iossNumberValidationSchema,
  creditValidationSchema,
  directDebitValidationSchema
} from 'modules/preferences/components/form/validation-schemas';
import ManagedLibrariesLayout from 'modules/preferences/components/modal/ManagedLibrariesLayout';

import { CustomBoxAndMailingLibraryModal } from 'modules/preferences/components/modal';
import {
  customBoxValidationSchema,
  customMailingBagValidationSchema
} from 'modules/preferences/components/form/validation-packaging-return-schemas';

export const ORDER_SETTING_PREFERENCES_DEFAULT_VALUES = {};
export const ORDER_SETTING_PREFERENCES_TEXT = {
  paperContent: 'Enable partial orders fulfilment',
  header: 'B2C partial order fulfilment',
  description:
    'Partial order fulfilment provides the flexibility to fulfil any part of the order in stock'
};

export const CUSTOMER_SUPPORT_ACCESS_PREFERENCES_DEFAULT_VALUES = {};
export const CUSTOMER_SUPPORT_ACCESS_PREFERENCES_TEXT = {
  preference: 'CustomerSupportAccess',
  paperContent: 'Enable customer support access',
  header: 'Customer support access',
  description:
    'Customer support access allows our team to resolve any technical issues you may encounter, by granting temporary access to your portal'
};

export const RETURN_SETTINGS_PREFERENCES_TEXT = {
  paperContent: 'Enable returns',
  header: 'Return settings',
  description: '',
  productsReturnHeaderText: 'My products',
  productsReturnDropdownText: 'This setting applies to'
};

export const CONFIRM_LEAVE_TOAST_ID = 'confirmLeaveToastId';
export const LEAVE_WARNING_TEXT =
  'Oops! Are you sure you want to leave without saving the changes?';

export const ROW_WARNING_TEXT = [
  'Please note, costs for ',
  'rest of the world courier services ',
  'can be highter than standard'
];

export const COMPANIES = {
  UPS: 'UPS',
  Yodel: 'Yodel',
  RoyalMail: 'Royal Mail',
  Evri: 'Evri',
  Whistl: 'Whistl'
};

export const ICONS_PARTNER_COMPANY_MAP = {
  [COMPANIES.UPS]: UpsLogoCompany,
  [COMPANIES.Yodel]: YodelLogoCompany,
  [COMPANIES.RoyalMail]: RoyalMailLogoCompany,
  [COMPANIES.Evri]: EvriLogoCompany,
  [COMPANIES.Whistl]: WhistlLogoCompany
};

export const SFP = 'Self Fulfilled Package';
export const SFP_TEXT = 'Self-fulfilled shipping?';

export const UK = 'UK';
export const EU = 'EU';
export const ROW = 'RestOfWorld';
export const SFP_VALUE = 'SFP';

export const ROW_TEXT = 'RoW';

export const ROW_COURIER_SETTING_TEXT = 'Rest of the world';

export const AVAILABLE_COUNTRY_REGIONS = [UK, EU, ROW];

export const USER_PREFERENCES_SETTING_MAP = {
  taxesDuties: TaxAndDutySetting,
  paymentMethods: PaymentSetting,
  customerSupportAccess: CustomerSetting,
  courierSettings: CourierSetting,
  returnSettings: ReturnSetting,
  packagingSettings: PackageSetting,
  orderSettings: OrderSetting,
  warehouseSettings: WarehouseSetting
};

export const EORI_REGIONS = [
  { identifier: 'GB', region: 'Great Britain', eoriNumber: '' },
  { identifier: 'NI', region: 'Northern Ireland', eoriNumber: '' },
  { identifier: 'EU', region: 'European Union', eoriNumber: '' }
];

export const TAXE_AND_DUTY_FEATURE = {
  eori: 'eori',
  vat: 'vat'
};

export const TAXE_AND_DUTY_MESSAGE = {
  [TAXE_AND_DUTY_FEATURE.eori]: {
    success: 'Your EORI Number has been successfully saved'
  },
  [TAXE_AND_DUTY_FEATURE.vat]: {
    success: 'Your VAT settings has been successfully saved'
  }
};

export const SAVE_COURIER_SETTINGS_SUCCESS = 'Your settings have been successfully saved';

export const REMOVE_EORI_NUMBER_CONFIRMATION = {
  title: 'Remove EORI Number',
  description:
    'Removing this EORI number will result in us being unable to process orders for %region%',
  okText: 'Remove'
};

export const INVALIDATION_EORI_NUMBER_CONFIRMATION = {
  title: 'Remove EORI Number cannot be blank',
  description: 'Examples GB205672212000 or SE5512345678',
  okText: 'OK'
};

export const EDITING_OPTIONS_BUTTON = {
  save: { text: 'Save', value: 'save' },
  cancel: { text: 'Cancel', value: 'cancel' }
};

export const OPTIONS_BUTTON = {
  edit: { text: 'Edit', value: 'edit' },
  remove: { text: 'Remove', value: 'remove' }
};

export const BUTTON_LABEL = 'Add';

export const TAX_AND_DUTY_FEATURE_HEADERS = {
  EORI_NUMBERS: {
    header: 'EORI Number',
    description: 'Enter your EORI number(s) here',
    tooltip: {
      content:
        'Enter your EORI number(s) here. Your GB EORI is required for orders going to Northern Ireland, the EU, and Rest of World. You can get more information on EORI numbers and how to get one at ',
      link: {
        content: 'gov.uk/eori',
        href: 'https://www.gov.uk/eori'
      }
    }
  },
  EU_VAT_REGISTERED: {
    header: 'EU VAT Registered',
    description: (
      <>
        Choose below with which EU countries you are VAT registered.
        <br /> You must add an EU EORI number before making your selection.
      </>
    ),
    tooltip: {
      content:
        'Choose below with which EU countries you are VAT Registered. You must add an EU EORI number before making your selection. You can find out more information ',
      link: {
        content: 'here',
        href: 'https://ec.europa.eu/taxation_customs/business/vat/eu-country-specific-information-vat_en'
      }
    }
  }
};

export const EU_VAT_COUNTRIES = [
  { code: 'AT', country: 'Austria' },
  { code: 'BE', country: 'Belgium' },
  { code: 'BG', country: 'Bulgaria' },
  { code: 'HR', country: 'Croatia' },
  { code: 'CY', country: 'Cyprus' },
  { code: 'CZ', country: 'Czechia' },
  { code: 'DK', country: 'Denmark' },
  { code: 'EE', country: 'Estonia' },
  { code: 'FI', country: 'Finland' },
  { code: 'FR', country: 'France' },
  { code: 'DE', country: 'Germany' },
  { code: 'GR', country: 'Greece' },
  { code: 'HU', country: 'Hungary' },
  { code: 'IE', country: 'Ireland' },
  { code: 'IT', country: 'Italy' },
  { code: 'LV', country: 'Latvia' },
  { code: 'LT', country: 'Lithuania' },
  { code: 'LU', country: 'Luxembourg' },
  { code: 'MT', country: 'Malta' },
  { code: 'NL', country: 'Netherlands' },
  { code: 'PL', country: 'Poland' },
  { code: 'PT', country: 'Portugal' },
  { code: 'RO', country: 'Romania' },
  { code: 'SK', country: 'Slovakia' },
  { code: 'SI', country: 'Slovenia' },
  { code: 'ES', country: 'Spain' },
  { code: 'SE', country: 'Sweden' }
];

export const EU_VAT_REGISTERED_STYLE = {
  table: {
    borderWidth: '0.063rem',
    borderStyle: 'solid',
    borderColor: 'light.700',
    boxShadow: 'slz-2md',
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.75rem'
  },
  header: {
    paddingTop: '0.794rem',
    w: '100%',
    background: 'light.300',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    display: 'flex',
    pb: '0.269rem',
    pr: '1.438rem',
    countriesSelected: {
      _hover: {
        borderColor: 'lightAndShadow.borderText'
      },
      _disabled: {
        _hover: {
          borderColor: 'lightAndShadow.borderText'
        },
        bgColor: 'light.500'
      },
      bg: 'lightAndShadow.reverseText',
      borderColor: 'lightAndShadow.borderText',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'md',
      pl: '1.188rem',
      pr: '0.4rem',
      py: '0.25rem',
      fontSize: 'md',
      fontWeight: 'bold',
      color: 'dark.700',
      w: 'auto',
      ml: 'auto',
      h: '1.875rem'
    }
  },
  flex: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    px: '1.875rem',
    pt: '0.75rem',
    w: '100%',
    h: '200px'
  }
};

export const IOSS_NUMBER_REGEX = /^IM[A-Za-z0-9]{3}[0-9]{7}$/;

export const IOSS_ACTIONS = [
  {
    text: 'Edit',
    value: 'edit'
  },
  {
    text: 'Remove',
    value: 'remove'
  }
];

export const IOSS_EDIT_ACTIONS = [
  {
    text: 'Cancel',
    value: 'cancel'
  }
];

export const VAT_REGISTERED_MODE = {
  start: 'START',
  edit: 'EDIT'
};

export const VAT_SELECTED_TEXT = {
  empty: 'No countries selected',
  contained: 'Countries selected'
};

export const COURIER_SERVICE_TOOLTIP_CONTENTS = {
  [ROW_TEXT]: {
    title: 'Self-fulfilled shipping',
    contents: [
      `You can arrange self-fulfillment by contacting us on
      028 3950 7860 or emailing support@selazar.com.
      Please note this may add time to orders being
      shipped and you must use self fulfillment
      exclusively.`
    ]
  },
  [EU]: {
    title: 'Self-fulfilled shipping',
    contents: [
      `You can arrange self-fulfillment by contacting us on
      028 3950 7860 or emailing support@selazar.com.
      Please note this may add time to orders being
      shipped and you must use self fulfillment
      exclusively.`
    ]
  }
};

export const COURIER_SERVICE_TOOLTIP_CONTENT = {
  title: 'Courier selection & settings.',
  contents: [
    `Modifying these settings can result in orders failing to be processed and can limit the
  destinations you can deliver.`,
    `This can result in orders failing to be processed, in this situation, you will be notified.`,
    `If a courier is eligible for a selected service time but is not available for the order, the next eligible service time may be selected.`
  ],
  totalPage: 3,
  isPagination: true
};

export const SELF_FULFILLED_TOOLTIP_CONTENT = {
  title: 'Self-fulfilled shipping',
  contents: [
    `You can arrange self-fulfillment by contacting us on 028 3950 7860 or emailing support@selazar.com. Please note this may add time to orders being shipped and you must use self fulfillment exclusively.`
  ],
  totalPage: 1,
  isPagination: false
};

export const ORDER_SETTING_TOOLTIP_CONTENT = {
  title: 'Courier selection & settings.',
  contents: [
    `Enabling partial order fulfilment provides the flexibility to fulfil any part of the order in stock. Any out of stock part of the order will be fulfilled once stock is available.`,
    `This may result in a single order having multiple parcels. If turned off, any existing partial orders will remain in the queue, but we will not accept any new partial orders`
  ],
  totalPage: 2,
  isPagination: true
};

export const FOOTER_TYPE = {
  HAVE_CANCEL_BUTTON: 'haveCancelButton',
  CLOSE_BUTTON: 'closeButton',
  BACK_BUTTON: 'backToSettings',
  HAVE_DONE_BUTTON: 'haveDoneButton'
};

export const FOOTER_TEXT = {
  [FOOTER_TYPE.HAVE_CANCEL_BUTTON]: {
    cancel: 'Cancel',
    Save: 'Save'
  },
  [FOOTER_TYPE.CLOSE_BUTTON]: 'Close',
  [FOOTER_TYPE.BACK_BUTTON]: 'Back to settings',
  [FOOTER_TYPE.HAVE_DONE_BUTTON]: {
    cancel: 'Cancel',
    Save: 'Done'
  }
};

export const DOUBLE_BUTTONS = {
  [FOOTER_TYPE.HAVE_CANCEL_BUTTON]: true,
  [FOOTER_TYPE.HAVE_DONE_BUTTON]: true
};

export const SINGLE_BUTTON = {
  [FOOTER_TYPE.CLOSE_BUTTON]: true,
  [FOOTER_TYPE.BACK_BUTTON]: true
};

export const USER_SETTING_FORMS_DEFAULT_NAME = {
  customerAccess: 'customerAccess',
  order: 'order',
  eoriNumber: 'eoriNumbers',
  vat: 'vat',
  iossNumber: 'iossNumber',
  credit: 'credit',
  directDebit: 'directDebit',
  courier: 'courier',
  grade: 'grade',
  returnSettingEnable: 'enable',
  returnSettingMyProduct: 'myProduct',
  configureExtras: 'extras',
  box: 'box',
  mailingBag: 'mailingBag',
  formUpdateBox: 'formUpdateBox',
  formUpdateMailingBag: 'formUpdateMailingBag'
};

export const USER_SETTING_FORMS = {
  taxesDuties: [
    USER_SETTING_FORMS_DEFAULT_NAME.vat,
    USER_SETTING_FORMS_DEFAULT_NAME.eoriNumber,
    USER_SETTING_FORMS_DEFAULT_NAME.iossNumber
  ],
  customerSupportAccess: [USER_SETTING_FORMS_DEFAULT_NAME.customerAccess],
  orderSettings: [USER_SETTING_FORMS_DEFAULT_NAME.order],
  paymentMethods: [
    USER_SETTING_FORMS_DEFAULT_NAME.credit
    // USER_SETTING_FORMS_DEFAULT_NAME.directDebit
  ],
  courierSettings: [USER_SETTING_FORMS_DEFAULT_NAME.courier],
  returnSettings: [
    USER_SETTING_FORMS_DEFAULT_NAME.returnSettingEnable,
    USER_SETTING_FORMS_DEFAULT_NAME.returnSettingMyProduct,
    USER_SETTING_FORMS_DEFAULT_NAME.grade
  ],
  packagingSettings: [
    USER_SETTING_FORMS_DEFAULT_NAME.configureExtras,
    USER_SETTING_FORMS_DEFAULT_NAME.box,
    USER_SETTING_FORMS_DEFAULT_NAME.mailingBag,
    USER_SETTING_FORMS_DEFAULT_NAME.formUpdateBox,
    USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag
  ]
};

export const BANK_TYPES = [
  {
    label: 'BACS',
    value: 'bacs_debit'
  }
];

export const USER_SETTING_FORMS_DEFAULT_VALUES = [
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.customerAccess,
    options: {
      defaultValues: {
        access: false
      }
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.order,
    options: {
      defaultValues: {
        partialOrder: false
      }
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.eoriNumber,
    options: {
      defaultValues: {
        eoriNumbers: []
      },
      ...{
        reValidateMode: 'onChange',
        shouldFocusError: false
      }
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.vat,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.iossNumber,
    options: {
      defaultValues: {
        isActiveIOSS: false,
        euioss: ''
      },
      resolver: yupResolver(iossNumberValidationSchema(IOSS_NUMBER_REGEX)),
      shouldFocusError: false,
      reValidateMode: 'onSubmit'
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.courier,
    options: {
      defaultValues: { regions: [] }
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.credit,
    options: {
      defaultValues: {
        forename: '',
        surname: '',
        email: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        postCode: ''
      },
      resolver: yupResolver(creditValidationSchema)
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.directDebit,
    options: {
      defaultValues: {
        name: '',
        email: '',
        phone: '',
        addressLine1: '',
        addressLine2: '',
        town: '',
        postCode: '',
        bankAccountType: BANK_TYPES[0].value
      },
      resolver: yupResolver(directDebitValidationSchema),
      reValidateMode: 'onSubmit',
      shouldFocusError: false
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.returnSettingEnable,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.returnSettingMyProduct,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.grade,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.configureExtras,
    options: {
      defaultValues: { extras: [] }
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.box,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.mailingBag,
    options: {
      defaultValues: {}
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.formUpdateBox,
    options: {
      defaultValues: {
        name: '',
        height: '',
        width: '',
        depth: '',
        maxWeight: ''
      },
      resolver: yupResolver(customBoxValidationSchema),
      reValidateMode: 'onSubmit',
      shouldFocusError: false
    }
  },
  {
    name: USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag,
    options: {
      defaultValues: {
        name: '',
        height: '',
        width: '',
        maxWeight: ''
      },
      resolver: yupResolver(customMailingBagValidationSchema),
      reValidateMode: 'onSubmit',
      shouldFocusError: false
    }
  }
];

export const USER_SETTING_MODAL_LAYOUT = {
  default: {
    key: 'default',
    component: DefaultModalLayout
  },
  productImagesRequired: {
    key: 'productImagesRequired',
    component: ProductImagesRequiredModal
  },
  credit: {
    key: 'credit',
    component: CreditConfigsModalLayout
  },
  directDebit: {
    key: 'directDebit',
    component: DirectDebitConfigsModalLayout
  },
  managedLibraries: {
    key: 'managedLibraries',
    component: ManagedLibrariesLayout
  },
  customBoxAndMailing: {
    key: 'customBoxAndMailing',
    component: CustomBoxAndMailingLibraryModal
  }
};

export const INITIAL_PREFERENCE_STATE = {
  // formValues: {},
  // isAllowToNavigate: true,
  modalLayout: USER_SETTING_MODAL_LAYOUT.default.key
};

export const PREFERENCES_ACTIONS = {
  // UPDATE_FORM_VALUES: 'UPDATE_FORM_VALUES',
  // SET_IS_ALLOW_TO_NAVIGATE: 'SET_IS_ALLOW_TO_NAVIGATE',
  SET_MODAL_LAYOUT: 'SET_MODAL_LAYOUT',
  // UPDATE_EORI_FORM_VALUES: 'UPDATE_EORI_FORM_VALUES',
  MANAGED_TYPE: 'MANAGED_TYPE'
};

export const USER_PREFERENCES_SETTING_FOOTER_TYPE_MAP = {
  taxesDuties: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  paymentMethods: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  customerSupportAccess: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  courierSettings: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  returnSettings: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  packagingSettings: FOOTER_TYPE.HAVE_CANCEL_BUTTON,
  orderSettings: FOOTER_TYPE.HAVE_CANCEL_BUTTON
};

export const PACKAGING_SETTING_FOOTER_TYPE = {
  backToSettings: FOOTER_TYPE.BACK_BUTTON
};

export const USER_PREFERENCES_SETTING_TOAST_MESSAGE_MAP = {
  taxesDuties: {
    success: 'Taxes and Duties preference has been successfully saved',
    error: 'There was a problem updating your taxies and duties preference. Please try again.'
  },
  paymentMethods: {
    success: 'Payment preference has been successfully saved',
    error: 'There was a adding/updating problem your payment preference. Please try again.'
  },
  customerSupportAccess: {
    success: 'Customer support access updated successfully.',
    error: 'There was a problem updating your customer support access preference. Please try again.'
  },
  courierSettings: {
    success: 'Your settings have been successfully saved',
    error: 'There was a problem updating your courier settings preference. Please try again'
  },
  returnSettings: {
    success: 'Your settings have been successfully saved',
    error: 'There was a problem updating your return settings. Please try again'
  },
  packagingSettings: {
    success: 'Your settings have been successfully saved',
    error: 'There was a problem updating your packaging settings preference. Please try again'
  },
  orderSettings: {
    success: 'Partial Order preference updated successfully.',
    error: 'Partial Order preference selection failed, please try again later.'
  }
};
export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const MESSAGES = {
  ADD_PAYMENT_CARD_SUCCESS: 'Your card has been successfully added',
  REPLACE_PAYMENT_CARD_SUCCESS: 'Your card has been successfully replaced',
  REMOVE_DEBIT_PAYMENT_CARD_SUCCESS: 'Your direct debit has been successfully removed',
  REMOVE_PAYMENT_CARD_SUCCESS: 'Your card has been successfully removed',
  INVALID_IOSS_TITLE: (
    <>
      <TriangleWarningIcon w="1.25rem" h="1.25rem" color="red.300" marginRight="0.313rem" /> IOSS
      Number format
    </>
  ),
  INVALID_IOSS_DESCRIPTION:
    'Please ensure your IOSS number is in the correct format Example: IM1234567890 or IMDEU4567890',
  REMOVING_IOSS_TITLE: 'Removing IOSS Number?',
  REMOVING_IOSS_DESCRIPTION:
    'By removing the IOSS number, it will no longer be included in any orders.',
  DIRECT_DEBIT_PENDING: (
    <>
      Our team will review your application within 6 business days.
      <br /> We will notify you of any updates.
    </>
  ),
  CUSTOMERREF_TEXT: 'Please enter a valid customer reference.'
};

export const RETURN_SETTING_GRADES = ['A', 'B', 'C'];

export const APPLY_TO_ALL_PRODUCT = 'ApplyToAllProducts';
export const APPLY_TO_ONLY_NEW_PRODUCT = 'ApplyToOnlyNewProducts';

export const RETURN_SETTING_GRADING_TEXT = {
  grade: 'Grade',
  gradeActionApply: 'These grading actions apply to',
  gradeNote: {
    note: 'Note',
    tooltip:
      'When customers return products we will take action based on the actions you have set.',
    content: 'These changes will not affect returns currently in process'
  },
  gardeDescription: {
    header: 'Selazar grading scale',
    description: 'These are the options that we use in the Selazar warehouse to grade your returns.'
  }
};

export const STATUS_RETURN_SETTINGS = {
  returnable: 'returnable',
  nonReturnable: 'non-returnable'
};

export const MY_PRODUCTS_RETURN_SETTINGS_OPTIONS = [
  { value: 'true', label: 'My products are returnable' },
  { value: 'false', label: 'My products are non-returnable' }
];

export const SETTING_APPLIES_OPTIONS = [
  {
    value: APPLY_TO_ALL_PRODUCT,
    label: 'All my products',
    description: 'All existing and new products will be updated to use the chosen settings'
  },
  {
    value: APPLY_TO_ONLY_NEW_PRODUCT,
    label: 'ONLY new products',
    description: 'Only products added after the update will use the chosen settings'
  }
];

export const RETURN_SETTING_STYLE = {
  customSelect: {
    ...SELECT_STYLES,
    container: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      '> div': {
        borderRadius: 1.5
      },
      '> div + div': {
        boxShadow: 'slz-2md'
      },
      bgColor: 'light.300',
      cursor: 'pointer'
    }),
    menuList: (provided) => ({
      ...provided,
      px: 0.75,
      py: 0,
      borderRadius: 1.5
    }),
    menu: (provided) => ({
      ...provided,
      '> div': {
        '> div': {
          m: 0,
          py: 0,
          px: 0,
          my: 0.75
        }
      }
    })
  },
  customSelectDisabled: {
    ...SELECT_STYLES,
    container: (provided) => ({
      ...provided,
      fontSize: '0.875rem',
      '> div': {
        borderRadius: 1.5,
        minH: 'unset'
      },
      borderRadius: 1.5,
      border: '1px solid',
      borderColor: 'dark.300',
      bgColor: 'light.500',
      pointerEvents: 'none'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'dark.300'
    })
  },
  settingGradeDesc: {
    display: 'flex',
    h: 6,
    px: 3.5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    border: '1px',
    borderColor: 'gradeDescription.activedBorder',
    cursor: 'pointer',
    fontSize: '0.75rem',
    color: 'light.300',
    bg: 'main.500'
  },
  settingGradeDescDisabled: {
    display: 'flex',
    h: 6,
    px: 3.5,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    border: '1px',
    cursor: 'pointer',
    fontSize: '0.75rem',
    bg: 'light.700',
    color: 'dark.300',
    borderColor: 'gradeDescription.disabledBorder'
  },
  textDisabled: {
    color: 'dark.300'
  },
  noteDisabled: {
    color: 'dark.300',
    bg: 'light.700'
  }
};

export const PRODUCT_IMAGES_REQUIRED = {
  TITLE: 'Product images required',
  CONTENT:
    'Please add a photo to each product. Returns will not be activated until all products have an image attached.',
  FILE_UPLOAD_RULE: 'Formats PNG, JPEG, JPG - Max size 1MB',
  UPLOAD_IMAGE_SUCCESS: 'Image was successfully uploaded',
  IMAGES_REQUIRED: 'Images required'
};

export const filterOptions = [
  {
    key: 'hard',
    text: 'Hard',
    colorScheme: 'szrElectricBlue'
  },
  {
    key: 'easy',
    text: 'Easy',
    colorScheme: 'ibiza'
  },
  {
    key: 'difficult',
    text: 'Difficult',
    colorScheme: 'californiaOrange'
  },
  {
    key: 'finished',
    text: 'Finished',
    colorScheme: 'tetra'
  }
];

export const paginationConfig = (totalPage, currentPage, handleCurrentPageChange) => {
  return {
    isLocal: false,
    showTitle: false,
    direction: {
      usingDots: false,
      isShowPage: false,
      totalPage: totalPage,
      currentPage: currentPage,
      isGreyBg: true,
      onChange: (currentPage) => handleCurrentPageChange(currentPage)
    }
  };
};

export const productImagesRequiredTableStyle = {
  maxWidth: '100%',
  th: {
    fontSize: '0.875rem'
  },
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  h: 'fit-content',
  maxH: '650px',
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  shadow: 'none',
  '.emphasized-cell': {
    paddingRight: 0
  }
};

export const productImagesRequiredTableFilters = {
  title: 'Tags',
  byField: 'tag',
  options: filterOptions,
  isLocal: true,
  sx: { ps: '0.75rem' }
};

export const ENABLE_RETURNS = {
  success: 'Enabled returns successfully!',
  failed: 'Enabled returns failed!'
};

export const TYPE_EXTRAS = {
  tape: 1,
  flyer: 2,
  sticker: 3,
  customfill: 4
};

export const CONFIGURE_EXTRAS_MAP = {
  [TYPE_EXTRAS.tape]: {
    typeName: 'Tape',
    icon: TageIcon
  },
  [TYPE_EXTRAS.flyer]: {
    typeName: 'Flyer',
    icon: FlyerIcon
  },
  [TYPE_EXTRAS.sticker]: {
    typeName: 'Sticker',
    icon: StickerIcon
  },
  [TYPE_EXTRAS.customfill]: {
    typeName: 'Custom Fill (1 Type)',
    icon: CustomFillIcon
  }
};

export const PACKAGING_SETTING_HEADERS = {
  COMMON: {
    header: 'Custom packaging',
    description:
      'All Selazar packaging comes unbranded as standard. However, for those wanting something a little more tailored, you can fully personalise how your orders are packaged using our custom packaging feature.',
    error: 'There was a problem. Please try again',
    success: 'You have successfully updated your custom box'
  },
  BOXES: {
    header: 'Custom boxes',
    tooltip: {
      content: 'When you enforce custom boxes we will only use your own boxes and never Selazars.'
    },
    success:
      'has been added successfully. We will be in contact when we receive your packaging at the warehouse. Do not enable your packaging option until our team has confirmed that it has been received as it can cause issues with orders',
    error: 'A custom box with that name already exists.',
    nameExistsError:
      'A box with that name already exists! Please use a different name and try again'
  },
  MAILING_BAGS: {
    header: 'Custom mailing bags',
    tooltip: {
      content:
        'When you enforce custom mailing bags we will only use your own bags and never Selazars.'
    },
    success: `has been added successfully. We will be in contact when we receive your packaging at the warehouse. Do not enable your packaging option until our team has confirmed that it has been received as it can cause issues with orders`,
    error: 'A custom mailing bag with that name already exists.',
    nameExistsError:
      'A bag with that name already exists! Please use a different name and try again'
  }
};

export const PACKAGING_SETTINGS = {
  BOXES: {
    title: 'Enforce custom boxes',
    keyName: 'box',
    icon: <PackagingIcon w="1.893rem" h="2.104rem" />,
    tagText: 'Boxes',
    button: 'Manage'
  },
  MAILING_BAGS: {
    title: 'Enforce mailing bags',
    keyName: 'mailingBag',
    icon: <BagIcon w="1.893rem" h="2.104rem" />,
    tagText: 'Mail bags',
    button: 'Manage'
  }
};

export const PACKAGING_TYPES = {
  box: 'BOXES',
  mailingBag: 'MAILING_BAGS'
};

export const PACKAGING_BACK_TITLE = {
  [PACKAGING_TYPES.box]: 'Add custom box',
  [PACKAGING_TYPES.mailingBag]: 'Add custom mailing bag'
};

export const NO_LIBRARY_TITLE = {
  BOXES: 'Add custom box',
  MAILING_BAGS: 'Add mailer bag'
};

export const CUSTOM_LIBRARY_TEXT = {
  backButton: 'Back to packaging settings',
  title: {
    [PACKAGING_TYPES.box]: 'Custom box library',
    [PACKAGING_TYPES.mailingBag]: 'Custom mailer bag library'
  },
  addButton: 'Add',
  card: {
    buttons: {
      edit: 'Edit',
      delete: 'Delete'
    },
    dimensions: 'Dimensions (cm)',
    weightLimit: 'Weight limit (kg)'
  }
};

export const CUSTOM_LIBRARY_TYPE = {
  true: 'ENABLE',
  false: 'DISABLE'
};

export const DELETE_CUSTOM_MAILING_BAG_CONFIRMATION = {
  title: 'Delete {{boxName}}',
  description:
    'Are you sure you want to delete {{boxName}}? Please be aware that any in progress orders using this custom mailing bag will not be affected.',
  boxDescription:
    'Are you sure you want to delete {{boxName}}? Please be aware that any in progress orders using this custom box will not be affected.',
  okText: 'Delete'
};

export const DELETE_CUSTOM_BOX = {
  success: 'We have successfully deleted custom {{type}}.',
  fail: 'We cannot delete a custom {{type}}.'
};

export const DIMENSIONS_TEXT = {
  [PACKAGING_TYPES.mailingBag]: (height, width) => `${height}H ${width}W`,
  [PACKAGING_TYPES.box]: (height, width, depth) => `${height}H ${width}W ${depth}D`
};

export const WAREHOUSE_SETTING_CONTENT = {
  description: {
    first:
      'Choosing the ideal warehouse location is a crucial initial step to embark on your customer journey with us. Our team is ready to assist you in selecting the perfect warehouse that aligns with your unique requirements.',
    second: (
      <>
        You can call us on{' '}
        <Link color="main.500" href="tel:+4402039507860">
          +44 (0)20 3950 7860
        </Link>{' '}
        or email us at{' '}
        <Link textDecor="underline" color="main.500" href="mailto:customer.service@selazar.com">
          customer.service@selazar.com
        </Link>
      </>
    )
  },
  note: {
    tag: 'Note',
    text: 'Once you select and confirm your warehouse you will be unable to undo this action.'
  },
  check: {
    description: (
      <>
        You have stated you are an existing customer. Please enter your Customer Reference.
      </>
    ),  
    status: 'warning',
    colorScheme: 'negative'
  },
  toast: {
    confirm: {
      description: (warehouse) => (
        <>
          Please confirm you wish to select <strong>{warehouse}</strong> as your warehouse, this
          cannot be undone
        </>
      ),
      status: 'success',
      colorScheme: 'positive'
    },
    success: {
      description: (warehouseName) => (
        <>
          You have selected &nbsp;<strong>{warehouseName}</strong>&nbsp; as your warehouse.
        </>
      ),
      status: 'success',
      colorScheme: 'positive'
    }
  }
};

export const PRODUCTS_MISSING_IMAGE_WARNING_TEXT =
  'Oops! We cannot process returns if some of your products are missing their images. Please complete this step if you want to activate returns';
export const MAX_FILE_SIZE = 1024 * 1024; // 1MB

export const ERROR_TYPE = {
  LIMIT_EXCEEDED: 'LIMIT_EXCEEDED',
  INCORRECT_IMAGE_FORMAT: 'INCORRECT_IMAGE_FORMAT'
};

export const UPLOAD_ERROR = {
  [ERROR_TYPE.LIMIT_EXCEEDED]: {
    title: 'The image is too large!',
    description: 'The file you attempted to upload is bigger than 1Mb. Please try again.'
  },
  [ERROR_TYPE.INCORRECT_IMAGE_FORMAT]: {
    title: 'Incorrect image format',
    description: 'The file you attempted to upload is not a valid image format. Please try again.'
  }
};
