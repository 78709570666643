import { Box, Flex, HStack, Text, VStack, List, ListItem } from '@chakra-ui/react';
import ShimmerSkeleton from 'components/common/Skeleton/SkeletonShimmer';
import { SlzAlert } from 'components/common/SlzAlert';
import { SlzPaper } from 'components/common/SlzPaper';
import { useRecentUnsuccessfulOrdersQuery } from 'modules/dashboard/hooks/';
import { recentUnsuccessfulOrdersMapper } from 'modules/dashboard/mappers/recent-unsuccessful-orders-mapper';

const RecentUnsuccessfulOrdersRow = ({ isLoading, row }) => (
  <HStack
    justifyContent="space-between"
    borderBottom="commonThin"
    padding="0.313rem 1rem 0.313rem 1.5rem"
    bg="white"
    spacing={10}>
    <VStack w="full" overflow="auto" alignItems="start" color="dark.700" spacing={0}>
      <ShimmerSkeleton.Block isLoaded={!isLoading} w="full" h="1.125rem">
        <Text color="dark.500" fontSize="lg" lineHeight="1.313rem" fontWeight="bold">
          {row?.customerName || 'Customer name'}
        </Text>
      </ShimmerSkeleton.Block>
      <HStack w="full" fontSize="sm" lineHeight="1rem">
        <Box minW="3rem">
          <Text whiteSpace="nowrap">Order ID</Text>
        </Box>
        <ShimmerSkeleton.Block isLoaded={!isLoading} w="full" minH="0.75rem">
          <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {row?.id}
          </Text>
        </ShimmerSkeleton.Block>
      </HStack>
      <HStack w="full" fontSize="xs" lineHeight="0.813rem">
        <Box minW="3rem">
          <Text>Reference</Text>
        </Box>
        <ShimmerSkeleton.Block isLoaded={!isLoading} w="full" minH="0.75rem">
          <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {row?.internalReference}
          </Text>
        </ShimmerSkeleton.Block>
      </HStack>
    </VStack>

    <Flex flexBasis="7rem" maxW="7rem" h="full" justifyContent="flex-end">
      <ShimmerSkeleton.Block isLoaded={!isLoading}>
        <SlzAlert
          size="md"
          variant="subtle"
          color={row ? 'strawberry.300' : 'dark.500'}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis">
          {row?.status || 'Reason for failure'}
        </SlzAlert>
      </ShimmerSkeleton.Block>
    </Flex>
  </HStack>
);

const Header = ({ recentUnsuccessfulOrdersLength }) => (
  <HStack
    justifyContent="space-between"
    w="full"
    bg="white"
    paddingLeft="1.438rem"
    borderBottom="commonThin"
    borderTopRadius={1.5}>
    <Text fontSize="xl" lineHeight="1.625rem" fontWeight="medium">
      Recent unsuccessful orders
    </Text>

    <VStack
      spacing="0.188rem"
      w="4rem"
      h="4rem"
      border={recentUnsuccessfulOrdersLength ? 'commonStrawberry' : 'commonGrey'}
      bg={recentUnsuccessfulOrdersLength ? 'strawberry.100' : 'light.500'}
      py="0.188rem"
      px="0.313rem"
      borderTopRightRadius={1.5}>
      <Text
        fontSize="xs"
        fontWeight="normal"
        lineHeight="0.813rem"
        color={recentUnsuccessfulOrdersLength ? 'strawberry.300' : 'dark.500'}
        alignSelf="start">
        Current
      </Text>
      <Box textAlign="center">
        <Text fontSize="xl" lineHeight="1.5rem" fontWeight="normal">
          {recentUnsuccessfulOrdersLength || 0}
        </Text>
        {recentUnsuccessfulOrdersLength > 0 && (
          <Text fontSize="xs" lineHeight="0.813rem">
            orders
          </Text>
        )}
      </Box>
    </VStack>
  </HStack>
);

const ListOrders = ({ recentUnsuccessfulOrders, children }) => (
  <List
    marginBottom={0}
    sx={{
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }}
    maxH="41.125rem"
    overflowX="unset"
    overflowY="scroll">
    {recentUnsuccessfulOrders?.length
      ? recentUnsuccessfulOrders?.map((row, i) => children({ row, key: row.id }))
      : [...Array(10)].map((_, i) => children({ key: i }))}
  </List>
);

const RecentUnsuccessfulOrdersList = () => {
  const { data, isLoading } = useRecentUnsuccessfulOrdersQuery({
    select: recentUnsuccessfulOrdersMapper
  });

  return (
    <SlzPaper w="full" h="full" borderRadius={1.5} overflow="hidden">
      <Header recentUnsuccessfulOrdersLength={data?.recentUnsuccessfulOrders?.length} />
      <ListOrders recentUnsuccessfulOrders={data?.recentUnsuccessfulOrders}>
        {({ row, key }) => {
          return <RecentUnsuccessfulOrdersRow key={key} {...{ isLoading, row }} />;
        }}
      </ListOrders>
    </SlzPaper>
  );
};

export default RecentUnsuccessfulOrdersList;
