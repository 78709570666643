import React, { useEffect, useState } from 'react';

import { GridItem, Heading, HStack, Icon } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';

import { SlzGridModal } from 'components/common/SlzModal';
import Tooltip from 'modules/integrations/components/form/Tooltip';
import { GeneralTrackingForm, BoxesForm } from 'modules/b2b-orders/components/form';

const TrackingDetailModal = ({
  isOpen,
  onClose,
  title,
  trackingDetail,
  onSaveBulkOrderTracking
}) => {
  const [trackingDetailState, setTrackingDetailState] = useState({
    generalTrackingState: {
      hasData: false,
      isEdit: false
    },
    boxesState: {
      hasData: false,
      isEdit: false
    }
  });

  const handleSaveGeneralTracking = (values, isDirtyForm) => {
    const payload = { ...trackingDetail, boxSplit: { ...trackingDetail?.boxSplit, ...values } };
    payload.status = payload?.status?.text;

    trackingDetailState.generalTrackingState.isEdit &&
      isDirtyForm &&
      onSaveBulkOrderTracking(payload);

    const generalTrackingState = {
      ...trackingDetailState.generalTrackingState,
      isEdit: !trackingDetailState.generalTrackingState.isEdit
    };

    setTrackingDetailState({ ...trackingDetailState, generalTrackingState });
  };

  const handleSaveBoxes = (values, isDirtyForm) => {
    const payload = { ...trackingDetail, boxSplit: { ...trackingDetail?.boxSplit, ...values } };
    payload.status = payload?.status?.text;

    trackingDetailState.boxesState.isEdit && isDirtyForm && onSaveBulkOrderTracking(payload);

    const boxesState = {
      ...trackingDetailState.boxesState,
      isEdit: !trackingDetailState.boxesState.isEdit
    };

    setTrackingDetailState({ ...trackingDetailState, boxesState });
  };

  const updateTrackingDetailState = () => {
    const hasGeneralTrackingData = Object.values(
      trackingDetail?.boxSplit?.generalTracking || []
    )?.some((obj) => !isEmpty(obj));

    const hasBoxesData = Object.values(trackingDetail?.boxSplit?.boxes || [])?.some(
      (obj) => !isEmpty([obj?.trackingNumber, obj.courierProvider])
    );

    const generalTrackingState = {
      ...trackingDetailState.generalTrackingState,
      hasData: hasGeneralTrackingData
    };

    const boxesState = {
      ...trackingDetailState.boxesState,
      hasData: hasBoxesData
    };

    setTrackingDetailState({ ...trackingDetailState, generalTrackingState, boxesState });
  };

  useEffect(() => {
    updateTrackingDetailState();
  }, [trackingDetail?.boxSplit]);

  return (
    <SlzGridModal isOpen={isOpen} onClose={onClose}>
      <GridItem gridColumn="2/12" mb="10" pt="8">
        <HStack spacing={1}>
          <Icon as={FaChevronLeft} fontSize={28} color="dark.500" cursor="pointer" />
          <Heading as="h3" fontSize="2rem" lineHeight={'none'} mb="0">
            {title}
          </Heading>
          <Tooltip content={'abc'} />
        </HStack>
      </GridItem>

      <GridItem gridColumn="2/11" mb="16">
        <GeneralTrackingForm
          generalTracking={trackingDetail?.boxSplit?.generalTracking}
          purchaseOrderNumber={trackingDetail?.purchaseOrderNumber}
          hasData={trackingDetailState.generalTrackingState.hasData}
          isEdit={trackingDetailState.generalTrackingState.isEdit}
          onSaveGeneralTracking={handleSaveGeneralTracking}
        />
      </GridItem>

      <GridItem gridColumn="2/11" mb="14">
        <BoxesForm
          boxes={trackingDetail?.boxSplit?.boxes}
          hasData={trackingDetailState.boxesState.hasData}
          isEdit={trackingDetailState.boxesState.isEdit}
          onSaveBoxes={handleSaveBoxes}
        />
      </GridItem>
    </SlzGridModal>
  );
};

export default TrackingDetailModal;
