import React from 'react';

import { Flex, FormControl, HStack, Image, Text, VStack } from '@chakra-ui/react';

import SlzInput from 'components/common/SlzInput/SlzInput';
import { DEFAULT_IMAGE } from 'constants/common';

const BoxTrackingInfo = ({ item }) => {
  return (
    <HStack spacing="2rem" w="100%" backgroundColor="#FAFAFA">
      <Image src={DEFAULT_IMAGE} w="3rem"></Image>
      <HStack flex justifyContent="space-between" w="100%" pr={8}>
        <VStack spacing={0} alignItems="start">
          <Text fontSize="0.875rem" mb="0">
            {item?.name}
          </Text>
          <Text fontSize="0.625rem">{item?.sku}</Text>
        </VStack>
        <VStack spacing={0} alignItems="start">
          <Text fontSize="0.625rem" mb="0">
            Quantity
          </Text>
          <Text fontSize="0.875rem">{item?.quantity}</Text>
        </VStack>
      </HStack>
    </HStack>
  );
};

const BoxesField = ({ item, index, register, disabled }) => {
  return (
    <Flex direction="column">
      <Text fontSize="1.25rem" fontWeight="bold" mb={1}>
        Box {index + 1}
      </Text>
      <HStack alignItems="start" mt={0} spacing={3}>
        <VStack alignItems="start" w="50%" spacing="3px">
          {item?.items?.map((item, index) => (
            <BoxTrackingInfo item={item} key={index} />
          ))}
        </VStack>
        <FormControl flexBasis="29.15%">
          <SlzInput
            name="trackingNumber"
            placeholder="Tracking number"
            w="239px"
            {...register(`boxes.${index}.trackingNumber`)}
            disabled={disabled}
            sx={{ border: '1px solid #C3C4CB !important', bg: '#FAFAFA' }}
          />
        </FormControl>

        <FormControl flexBasis="19.15%">
          <SlzInput
            name="courierProvider"
            placeholder="Courier"
            w="156px"
            {...register(`boxes.${index}.courierProvider`)}
            disabled={disabled}
            sx={{ border: '1px solid #C3C4CB !important', bg: '#FAFAFA' }}
          />
        </FormControl>
      </HStack>
    </Flex>
  );
};

export default BoxesField;
