import { SlzTag } from 'components/common/SlzTag';

export const MatchedIcon = (
  <SlzTag
    size="sm"
    fontSize="0.63rem"
    h="1rem"
    ml="auto"
    variant="solid"
    color="positive.500"
    bg="positive.300">
    Matched
  </SlzTag>
);
