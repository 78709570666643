import { useHistory } from 'react-router';
import { DEFAULT_PAGING_OPTIONS } from 'constants/table.js';
import SlzTable from 'components/common/SlzTable/Table';
import {
  RETURN_SKELETON_CELL,
  RETURN_DISPLAY_FIELDS,
  FILTER_OPTIONS,
  RETURNS_PATHNAME
} from '../../constants';
import { ACCORDION_COLUMNS, RETURN_COLUMNS } from './columns';
import { STATUS_CELL_STYLE } from './constants.style';
import { ReturnAccordionTable } from '.';

const columns = [...RETURN_COLUMNS];

const accordionProps = {
  isShowMarker: true,
  markedWidth: 12,
  columns: ACCORDION_COLUMNS,
  showImage: true,
  imageWidth: 48,
  imageStyles: {
    w: '3rem',
    h: 'inherit'
  },
  displayFields: ACCORDION_COLUMNS.map((col) => col.field)
};

const ReturnsTable = (props) => {
  const {
    currentPage,
    totalPage,
    onPageChange,
    onItemPerPageChange,
    onFilterChange,
    data,
    params
  } = props;

  const history = useHistory();

  columns[0].onClick = (row) => {
    history.push(`${RETURNS_PATHNAME}/${row.retailerReturnID}?status=${row.status.key}`);
  };

  return (
    <SlzTable
      isLoading={props?.isLoading}
      variant="order"
      sx={STATUS_CELL_STYLE}
      showMarked
      sizes={['sm', 'md', 'lg']}
      columns={columns}
      onRefetching={props?.onRefetching}
      isEmptyPage={props?.isEmptyPage}
      messageToast={props?.messageToast}
      data={data || Array(5).fill(RETURN_SKELETON_CELL)}
      rowProps={{
        hasExpandedRow: true,
        accordionProps: {
          ...accordionProps,
          template: ({ row }) => {
            return <ReturnAccordionTable row={row} accordionProps={accordionProps} />;
          }
        }
      }}
      displayFields={RETURN_DISPLAY_FIELDS}
      hyperLinks={['orderID']}
      filters={{
        title: '',
        shouldSortOptions: false,
        byField: 'filter',
        options: FILTER_OPTIONS,
        handleSelectedFilter: onFilterChange,
        defaultSelectedValues: params?.filter ? [params?.filter] : []
      }}
      filterByDate={{
        field: 'createdDate',
        start: params?.startDate,
        end: params?.endDate
      }}
      pagination={{
        isLocal: false,
        pages: {
          onChange: onItemPerPageChange,
          pageOptions: DEFAULT_PAGING_OPTIONS,
          currentItemPerPage: params?.pageSize
        },
        direction: {
          usingDots: false,
          totalPage,
          currentPage,
          isGreyBg: true,
          onChange: onPageChange
        }
      }}
    />
  );
};

export default ReturnsTable;
