import { useCallback } from 'react';
import {
  COMPANIES,
  CONFIGURE_EXTRAS_MAP,
  EU,
  ICONS_PARTNER_COMPANY_MAP,
  SETTING_APPLIES_OPTIONS,
  SFP,
  UK,
  VAT_SELECTED_TEXT
} from '../constants';

export const mapCourierSettings = (serviceTimes, regionPreference) => {
  const result = useCallback(
    serviceTimes?.map((country) => ({
      ...country,
      idSFP: handleFindToIdSFP(serviceTimes),
      isSFP: handleIsSFP(regionPreference, country),
      availableServiceTimeOptions: country?.availableServiceTimeOptions
        ?.filter((item) => item?.companyName !== SFP)
        .map((serviceTimeOption) => ({
          companyName: serviceTimeOption.companyName,
          [serviceTimeOption.companyName]: {
            ...serviceTimeOption,
            isActive:
              country?.countryRegion === UK
                ? handleIsActiveServiceTimesUK(serviceTimeOption?.companyID, regionPreference)
                : !handleIsServiceTimesSFP(regionPreference, country),
            companyLogo: ICONS_PARTNER_COMPANY_MAP[serviceTimeOption?.companyName],
            serviceTimes: Object.entries(serviceTimeOption?.serviceTimes)?.map(([value, label]) => {
              const serviceTime = {
                value,
                label,

                isChecked: handleIsCheckedServiceTimeRegion(
                  regionPreference,
                  country?.countryRegion,
                  serviceTimeOption?.companyID,
                  value,
                  serviceTimeOption?.companyName
                )
              };
              if (
                serviceTimeOption?.companyName === COMPANIES.RoyalMail &&
                country?.countryRegion === UK
              )
                serviceTime['isTracked'] = handleIsTrackedServiceTimeRegion(
                  value,
                  regionPreference,
                  serviceTimeOption?.companyID,
                  country?.countryRegion
                );
              return serviceTime;
            })
          }
        }))
    })),
    [serviceTimes, regionPreference]
  );
  return result;
};

const handleIsTrackedServiceTimeRegion = (
  serviceTime,
  regionPreference,
  companyID,
  currentCountryRegion
) => {
  const service = regionPreference?.find(
    ({ countryRegion }) => countryRegion === currentCountryRegion
  );
  const partnerCompany = service?.preferences?.find(
    ({ partnerCompanyID }) => partnerCompanyID === companyID
  );

  const hasTracking = Boolean(partnerCompany?.serviceTrackingPreferences[serviceTime]);
  return hasTracking;
};

const handleIsCheckedServiceTimeRegion = (
  regionPreference,
  countryRegion,
  companyID,
  serviceTime,
  companyName
) => {
  const region = regionPreference?.find((region) => region.countryRegion === countryRegion);
  const company = region?.preferences?.find((company) => company.partnerCompanyID === companyID);
  const isRoyalMail = countryRegion === UK && companyName === COMPANIES.RoyalMail;

  if (isRoyalMail) {
    return company?.ServiceTimes.includes(serviceTime);
  }

  const serviceTimes = company?.ServiceTimes ?? [];
  const serviceTrackingPreferences = company?.serviceTrackingPreferences ?? {};
  const isCheckedServiceTimeAndRegion =
    serviceTimes.includes(serviceTime) || serviceTrackingPreferences[serviceTime] === true;
  return isCheckedServiceTimeAndRegion;
};

const handlePreferences = (countryRegion, optionServiceTime) => {
  const serviceTimes =
    optionServiceTime?.filter((item) => item.isChecked)?.map((item) => item.value) ?? [];

  const serviceTrackingPreferences = {};

  for (const item of optionServiceTime ?? []) {
    if (countryRegion !== EU) {
      const isTracked = Object.keys(item).includes('isTracked') && item.isTracked;
      serviceTrackingPreferences[item.value] = item.isChecked && isTracked;
    } else {
      serviceTrackingPreferences[item.value] = item.isChecked;
    }
  }

  return { ServiceTimes: serviceTimes, serviceTrackingPreferences: serviceTrackingPreferences };
};

const handleFindToIdSFP = (serviceTimes) => {
  const UKRegionDifference = serviceTimes?.find(({ countryRegion }) => countryRegion !== UK);

  const idSFP = UKRegionDifference?.availableServiceTimeOptions?.find(
    ({ companyName }) => companyName === SFP
  )?.companyID;

  return idSFP ?? '';
};

export const mapUpdateCourierSettings = ({ regions: data }) => {
  const regions = data?.map(({ isSFP, idSFP, countryRegion, availableServiceTimeOptions }) => {
    const regionOption = {
      countryRegion,
      preferences: availableServiceTimeOptions || []
    };

    if (!isSFP) {
      regionOption.preferences = availableServiceTimeOptions?.map((regionOption) => {
        return {
          partnerCompanyID: regionOption[regionOption.companyName]?.companyID,
          ...handlePreferences(countryRegion, regionOption[regionOption.companyName]?.serviceTimes)
        };
      });
    } else {
      regionOption.preferences = [
        {
          partnerCompanyID: idSFP,
          ServiceTimes: ['SFP'],
          serviceTrackingPreferences: {}
        }
      ];
    }

    return regionOption;
  });

  return regions;
};

export const transformServiceTimes = (regions, isChecked) => {
  const serviceTimes = regions?.serviceTimes?.map((serviceTime) => ({
    ...serviceTime,
    isChecked: isChecked
  }));

  return { ...regions, serviceTimes: serviceTimes };
};

const handleIsActiveServiceTimesUK = (companyID, regionPreference) => {
  const regionUK = regionPreference?.find((region) => region?.countryRegion === UK);
  const company = regionUK?.preferences.find((company) => company?.partnerCompanyID === companyID);

  const isEmptyCompany = company?.ServiceTimes?.length !== 0;

  return isEmptyCompany;
};

const handleIsServiceTimesSFP = (regions, country) => {
  const findCountryRegion = regions?.find(
    (region) => region?.countryRegion === country?.countryRegion
  );

  const checkServiceTimesSFP =
    findCountryRegion?.preferences[0]?.ServiceTimes?.includes('SFP') ||
    findCountryRegion?.preferences[0]?.ServiceTimes?.length === 0;

  return checkServiceTimesSFP;
};

const handleIsSFP = (regions, country) => {
  const findCountryRegion = regions?.find(
    (region) => region?.countryRegion === country?.countryRegion
  );

  const checkServiceTimesSFP = findCountryRegion?.preferences[0]?.ServiceTimes?.includes('SFP');

  return checkServiceTimesSFP;
};

export const countSelectedVatCountries = (formValues, isEuVatEnabled) => {
  let count = 0;
  for (const key in formValues) {
    if (true === formValues[key]) {
      count++;
    }
  }

  return count === 0 || !isEuVatEnabled
    ? VAT_SELECTED_TEXT.empty
    : VAT_SELECTED_TEXT.contained + `  ${count}`;
};

export const checkEuVatEnabled = (eoriNumbers) => {
  const euEoriNumber = eoriNumbers?.find(
    ({ identifier, lastEdited }) => identifier === EU && lastEdited !== undefined
  );
  return Boolean(euEoriNumber);
};

export const transformCourierSettings = (regions, country) => {
  const findIndexRegion = regions?.findIndex((regions) => regions.countryRegion === country);

  const region = regions?.[findIndexRegion];

  const serviceTimeOptions = [];

  region?.availableServiceTimeOptions?.forEach((serviceTimeOption, indexServiceTime) => {
    serviceTimeOptions.push(
      `regions.${findIndexRegion}.availableServiceTimeOptions.${indexServiceTime}.${serviceTimeOption.companyName}`
    );
  });

  return serviceTimeOptions;
};

export const transformIsSFP = (regions, country) => {
  const findIndexRegion = regions?.findIndex((regions) => regions.countryRegion === country);

  return `regions.${findIndexRegion}.isSFP`;
};

export const mapReturnSettings = (returnPreference) => {
  const findSelection = SETTING_APPLIES_OPTIONS.find(
    (item) => item.value === returnPreference?.selection
  );

  return {
    returnable: String(returnPreference?.returnable),
    selection: findSelection
  };
};

export const mapPackagingExtras = (extras) => {
  return extras?.map((extra) => {
    return {
      ...extra,
      typeName: CONFIGURE_EXTRAS_MAP[extra?.type].typeName,
      icon: CONFIGURE_EXTRAS_MAP[extra?.type].icon
    };
  });
};
