import React, { Fragment } from 'react';

import { Box, Heading, HStack, Icon } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TbTrash } from 'react-icons/tb';
import { SlzButton } from '../../../../components/common/SlzButton';

function BundleIndicatorHeader({ indicatorLabels = [] }) {
  const isIndicatorLabelExisting = !isEmpty(indicatorLabels);

  return (
    <HStack spacing={1}>
      {isIndicatorLabelExisting && <Icon as={FaChevronRight} fontSize={14} color="dark.500" />}
      {indicatorLabels.map((label, index) => (
        <Fragment key={label + index}>
          <Heading as="h4" fontSize="1.25rem" lineHeight={'none'} mb="0">
            {label}
          </Heading>
          <Icon as={FaChevronRight} fontSize={14} color="dark.500" />
        </Fragment>
      ))}
    </HStack>
  );
}

export function BundleCreateHeader(props) {
  return (
    <HStack alignItems="flex-end" my="6">
      <HStack>
        <Icon as={FaChevronLeft} fontSize={28} color="dark.500" cursor="pointer" />
        <Heading as="h3" fontSize="2rem" lineHeight={'none'} mb="0">
          {props?.title}
        </Heading>
      </HStack>
      <BundleIndicatorHeader {...props} />
    </HStack>
  );
}

export function BundleEditHeader({ indicatorLabels, onEdit, isEditMode, name, onDelete }) {
  return (
    <HStack justifyContent="space-between" align="baseline">
      <HStack spacing={1}>
        <Heading size="lg">{name}</Heading>
        {!isEditMode && (
          <Box
            ml={'0.375rem'}
            mt={'0.25rem'}
            as="span"
            fontSize={16}
            color="main.500"
            cursor="pointer"
            onClick={onEdit}>
            Edit
          </Box>
        )}
        <BundleIndicatorHeader indicatorLabels={indicatorLabels} />
      </HStack>
      <SlzButton
        onClick={onDelete}
        alignSelf="flex-end"
        size="lg"
        maxW={122}
        borderRadius="0.375rem"
        width="full">
        Delete
        <Box ml={'0.587rem'}>
          <Icon as={TbTrash} fontSize="2xl" />
        </Box>
      </SlzButton>
    </HStack>
  );
}
