import { useEffect } from 'react';
import { Box, Flex, HStack, VStack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { stepOneSchema } from './validation-order-wizard-schemas';
import { SlzTextInput } from 'components/common/SlzInput';
import { ControlledField } from 'components/form';
import { B2B_MODAL_LAYOUT_KEYS, BULK_ORDER_CREATE_TEXT } from 'modules/b2b-orders/constants';
import { DEFAULT_PAGE_INDEX } from 'constants/table.js';
import { B2BOrderAction, B2BOrderNote } from '../other';
import RecipientDropdown from 'modules/b2b-orders/components/other/RecipientDropdown';
import RecipientDetailCard from 'modules/address-book/components/form/RecipientDetailCard';
import { useAddressBookQuery } from 'modules/address-book/hooks/useAddressBookQuery';
import { getUser } from 'Utilities';
import { transformOrderDetail } from 'modules/b2b-orders/mappers/bulk-order-detail-mapper';

const LabelForm = ({ text, ...rest }) => {
  return (
    <Text fontWeight="bold" fontSize="xl" lineHeight="1.625rem" {...rest}>
      {text}
    </Text>
  );
};

const B2BOderDetailsForm = (props) => {
  const user = getUser();

  const { data: { items = [] } = {} } = useAddressBookQuery({
    companyID: user.companyID,
    filters: { pageIndex: DEFAULT_PAGE_INDEX }
  });

  const { recipient, setIsFormDirty } = props;

  const methods = useForm({
    defaultValues: {
      ...props?.defaultValues,
      recipient: recipient
        ? {
            label: JSON.stringify(recipient),
            value: recipient
          }
        : null
    },
    resolver: yupResolver(stepOneSchema)
  });

  const watchRecipient = methods.watch('recipient');

  useEffect(() => {
    const recipient = methods.getValues('recipient')?.value;

    if (recipient) {
      props.setRecipient(recipient);
    }
  }, [watchRecipient]);

  useEffect(() => {
    setIsFormDirty?.(methods?.formState?.isDirty);
  }, [methods?.formState?.isDirty]);

  const handleRemoveRecipient = () => {
    props.setRecipient(null);
    methods.setValue('recipient', null);
  };

  const handleNextStep = (values) => {
    const { contactDetails, destinationAddress } = transformOrderDetail(values.recipient.value);
    const submitValues = {
      ...values,
      contactDetails,
      destinationAddress
    };

    props.onNext(submitValues);
  };

  const handleModifyRecipient = () => {
    props.onSaveValues(methods.getValues());
    props?.setLayoutKey(B2B_MODAL_LAYOUT_KEYS.EDIT_RECIPIENT_DETAILS);
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <HStack justifyContent={'space-between'} alignItems="end" my="0.813rem">
          <LabelForm text={BULK_ORDER_CREATE_TEXT.ORDERDETAILS.header} />
        </HStack>
        <form onSubmit={methods?.handleSubmit(handleNextStep)}>
          <VStack alignItems="initial" gap={2} mb={10}>
            <Flex gap={2}>
              <Flex flexBasis="39.2%">
                <ControlledField
                  name="purchaseOrderNumber"
                  label="Purchase Order Number*"
                  placeholder="Purchase Order Number"
                  component={SlzTextInput}
                  isInlineError
                />
              </Flex>
            </Flex>
            <VStack gap={1} alignItems="flex-start">
              <LabelForm text={BULK_ORDER_CREATE_TEXT.ORDERDETAILS.title} />

              <B2BOrderNote noteContent={BULK_ORDER_CREATE_TEXT.ORDERDETAILS.noteContent} />
            </VStack>

            {!recipient ? (
              <RecipientDropdown
                name="recipient"
                addresses={items}
                onAddRecipient={handleModifyRecipient}
              />
            ) : (
              <RecipientDetailCard
                data={recipient}
                onEdit={handleModifyRecipient}
                onRemove={handleRemoveRecipient}
              />
            )}
          </VStack>
          <B2BOrderAction {...props} />
        </form>
      </FormProvider>
    </Box>
  );
};

export default B2BOderDetailsForm;
