import { GridItem } from '@chakra-ui/react';

import { SlzGrid } from 'components/common/SlzGrid';
import { MARKET_PLACE_CARDS } from '../../constants';
import { MarketPlaceCard } from '../common';

const MarketPlace = ({ onClickMarketPlaceCard }) => {
  return (
    <SlzGrid px={0}>
      {MARKET_PLACE_CARDS.map((card) => (
        <GridItem key={card.type} gridColumn="span 3">
          <MarketPlaceCard data={card} onClickMarketPlaceCard={onClickMarketPlaceCard} />
        </GridItem>
      ))}
    </SlzGrid>
  );
};

export default MarketPlace;
