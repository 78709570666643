const BASE_STYLES = {
  card: {
    cursor: 'pointer',
    pos: 'relative',
    border: 'commonGrey',
    borderRadius: 3,
    p: '28px 24px 22px 24px',
    gap: '25px',
    _hover: {
      border: 'commonBlue'
    }
  },
  cardChecked: {
    border: 'commonBlue'
  },
  icon: {
    borderRadius: 6,
    p: '22px 24.8px 28px 25px',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export const VERTICAL_CARDS_STYLES = {
  ...BASE_STYLES,
  card: {
    ...BASE_STYLES.card,
    w: '265px',
    minH: '491px'
  },
  icon: {
    ...BASE_STYLES.icon,
    w: '145px',
    minH: '145px'
  },
  body: {
    minH: '134px'
  },
  footer: {
    minH: '77px'
  }
};

export const HORIZONTAL_CARDS_STYLES = {
  ...BASE_STYLES,
  card: {
    ...BASE_STYLES.card,
    w: '827px',
    minH: '129px',
    p: '22px',
    border: 'common'
  },
  icon: {
    ...BASE_STYLES.icon,
    w: '81px',
    minH: '81px',
    borderRadius: 3,
    p: '12px'
  },
  body: { alignItems: 'start', flexBasis: '50%' },
  footer: { maxW: '409px' }
};

export const HORIZONTAL_CARDS_EDIT_FORM_STYLES = {
  ...BASE_STYLES,
  card: {
    ...BASE_STYLES.card,
    w: 'full',
    minH: '6.563rem',
    border: 'common',
    p: 0,
    gap: 0
  },
  icon: {
    ...BASE_STYLES.icon,
    w: '6.5rem',
    px: 0,
    pt: '1.625rem',
    pb: 2.5,
    minH: '6.563rem',
    borderRadius: 0,
    borderStartRadius: 3,
    flexDirection: 'column',
    gap: 1
  },
  body: { alignItems: 'start', flexBasis: '50%' },
  footer: { flex: 1, p: 3 }
};
