import { Box, useRadioGroup } from '@chakra-ui/react';

const UserRoleSelectionCard = ({ styles, userRoles, ...rest }) => {
  const { getRootProps, getRadioProps } = useRadioGroup(rest);
  const group = getRootProps();
  return (
    <Box {...styles} {...group}>
      {rest.children(getRadioProps)}
    </Box>
  );
};

export default UserRoleSelectionCard;
