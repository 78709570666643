import { Box } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  ASSIGNED_FIELD_STATUS,
  BOX_SPLIT_ERROR_TYPE,
  CUSTOM_BOX_CHECKING_FLAGS,
  CUSTOM_BOX_ERROR_FLAG,
  CUSTOM_BOX_PREFIX
} from 'modules/b2b-orders/constants';

const AssignedField = ({ originalQuantity, identifier }) => {
  const { watch, getValues, setValue } = useFormContext();
  const [validatedClassname, setValidatedClassname] = useState(ASSIGNED_FIELD_STATUS.unknown);

  const registeredFields = watch(CUSTOM_BOX_PREFIX)?.filter((box) => !isEmpty(box));
  const fieldNames =
    registeredFields?.map((_, index) => `${CUSTOM_BOX_PREFIX}.${index}.${identifier}`) || [];

  useEffect(() => {
    if (getValues(CUSTOM_BOX_CHECKING_FLAGS.validateFlag) !== undefined) {
      const totalQuantity = calculateTotalQuantity(getValues(fieldNames));
      const itemError = getValues(CUSTOM_BOX_ERROR_FLAG.itemError)?.errors || [];
      const filteredErrors = itemError.filter((error) => error?.id !== identifier);

      switch (true) {
        case totalQuantity > originalQuantity:
          setError(BOX_SPLIT_ERROR_TYPE.tooManyItems, identifier, filteredErrors);
          break;
        case totalQuantity < originalQuantity:
          setError(BOX_SPLIT_ERROR_TYPE.remainingItems, identifier, filteredErrors);
          break;
        default:
          setValidatedClassname(ASSIGNED_FIELD_STATUS.matched);
          setValue(CUSTOM_BOX_ERROR_FLAG.itemError, {
            shouldValidate: true,
            errors: [...filteredErrors]
          });
          break;
      }
    }
  }, [watch(CUSTOM_BOX_CHECKING_FLAGS.validateFlag)]);

  const setError = (type, id, filteredErrors) => {
    setValidatedClassname(ASSIGNED_FIELD_STATUS.unmatched);
    setValue(CUSTOM_BOX_ERROR_FLAG.itemError, {
      shouldValidate: true,
      errors: [
        ...filteredErrors,
        {
          isError: true,
          type,
          id
        }
      ]
    });
  };

  const calculateTotalQuantity = (productQuantities) => {
    return productQuantities
      .map((productQuantity) => productQuantity?.value || 0)
      .reduce((prev, next) => prev + next, 0);
  };

  return (
    <Box className={validatedClassname}>
      {calculateTotalQuantity(watch(fieldNames))}/{originalQuantity}
    </Box>
  );
};

export default AssignedField;
