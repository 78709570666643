import { useEffect, useState } from 'react';

import {
  Box,
  Container,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react';
import { Col, Row } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { CheckSuccessIcon, UpsLogoCompany } from 'components/common/Icons';
import { SlzButton, SlzSplitButton } from 'components/common/SlzButton';
import { DefaultComponents, SlzDropdown } from 'components/common/SlzDropdown';
import SlzFilter from 'components/common/SlzFilter/SlzFilter';
import SlzNotification from 'components/common/SlzNotification/SlzNotification';
import SlzNotificationWindows from 'components/common/SlzNotification/SlzNotificationWindows';
import SlzPagination from 'components/common/SlzPagination/SlzPagination';
import { useGlobalState } from 'contexts/GlobalContext';
import { useQuery } from 'hooks/useQuery';
import { MatchedIcon } from 'modules/integrations/components/other/MatchedIcon';
import { CourierCard } from 'modules/preferences/components/other';
import { SETTING_APPLIES_OPTIONS } from 'modules/preferences/constants';
import { SET_BREADCRUMB_LINKS } from 'reducers/appReducer';
import Buttons from '../Button';
import CurencyIconsAndCreditCards from '../CurrencyIconsAndCreditCards';
import ElementFour from '../Elements/ElementFour';
import ElementOne from '../Elements/ElementOne';
import ElementThree from '../Elements/ElementThree';
import ElementTwo from '../Elements/ElementTwo';
import Selection from '../Selection';
import Tables from '../Table';
import TextFields from '../TextField';
import { filterOptions, itemsNotificationWindows, itemsSplitButton, notifications } from './data';
import { AddRolesAndPermsCard, HorizontalRolesAndPermsCard } from 'modules/users/common/cards';
import SlzScrollBar from 'components/common/SlzScrollBar/SlzScrollBar';

const Main = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [notification, setNotification] = useState(0);
  const query = useQuery();
  const [_, dispatch] = useGlobalState();
  const methods = useForm({
    defaultValues: { courier: SETTINGS }
  });

  useEffect(() => {
    dispatch({
      type: SET_BREADCRUMB_LINKS,
      payload: [
        {
          path: '/',
          title: 'Home'
        },
        {
          path: '/demo',
          title: 'Demo'
        }
      ]
    });
  }, []);

  const receiveNotification = () => {
    let number = notification;
    setNotification(number + 1);
  };

  const clearNotification = () => {
    setNotification(0);
  };

  const colorOptions = [
    { value: 'blue', label: 'Blue', color: '#0052CC' },
    {
      value: 'purple',
      label: 'Purple',
      color: '#5243AA'
    },
    { value: 'red', label: 'Red', color: '#FF5630', isDisabled: true },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' }
  ];

  const quantityOptions = [
    { value: '5', label: '5' },
    {
      value: '10',
      label: '10'
    },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '500', label: '500' },
    { value: '1000', label: '1000' }
  ];

  const rateMatcherOptions = [
    { label: 'test', value: 'TEST', isMatched: true, showInputIcon: true, MatchedIcon },
    { label: 'test1', value: 'TEST1', isMatched: false, showInputIcon: true, MatchedIcon }
  ];

  const RateMatcherOption = (props) => {
    const { data: { isMatched = false, MatchedIcon = <></> } = {}, label, ...rest } = props;
    return (
      <DefaultComponents.Option {...rest}>
        <HStack w="100%" spacing={2} justifyContent="space-between" pr={2}>
          <Text>{label}</Text>
          {isMatched && MatchedIcon}
        </HStack>
      </DefaultComponents.Option>
    );
  };

  const ReturnSettingOption = (props) => {
    const { data, label, ...rest } = props;
    return (
      <DefaultComponents.Option {...rest}>
        <HStack spacing={2}>
          <Text>{label}</Text>
          <Text fontSize="sm">{data?.description}</Text>
        </HStack>
      </DefaultComponents.Option>
    );
  };

  return (
    <Container maxW="container.4xl">
      <Container maxW="full">
        {/* <Box display="flex" mb={5}>
          <ProductAddModal isOpen={true} onSave={(values) => console.log(values)} />
        </Box> */}
        <Box mt={5} mb={5} h="10rem">
          <Text fontWeight="bold">Scrollbar</Text>
          <SlzScrollBar>
            <Box h="100rem">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe esse quis eius,
              excepturi assumenda iusto, dolorum numquam repellat praesentium, itaque dicta
              voluptatibus repellendus dolore non culpa corporis nemo quo laboriosam?
            </Box>
          </SlzScrollBar>
        </Box>

        <Box display="flex" mt={5} mb={5}>
          <SlzNotificationWindows items={itemsNotificationWindows} alertContent="important" />
        </Box>
        <Box display="flex" mb={5}>
          <SlzFilter
            options={filterOptions}
            title="Order status"
            onSelectOption={(options) => {
              console.log('selected filter option: ', options);
            }}
          />
        </Box>
        <Box mt={4} mb={4}>
          <Row>
            <Col sm={2}>
              <SlzSplitButton
                items={itemsSplitButton}
                size={'lg'}
                // defaultValue={2}
                placeholder="This is a split button"
                onSelected={(i) => {
                  console.log('Slz Split Button Item: ', { i });
                }}></SlzSplitButton>
            </Col>
            <Col sm={2}>
              <SlzSplitButton
                items={itemsSplitButton}
                size={'md'}
                // defaultValue={2}
                colorScheme="positive"
                leftIcon={<CheckSuccessIcon />}
                placeholder="This is a split button"
                onSelected={(i) => {
                  console.log('Slz Split Button Item: ', { i });
                }}></SlzSplitButton>
            </Col>
          </Row>
        </Box>
        <Box mt={4} mb={4}>
          <Box mb={1}>
            <Tag size="lg">Dropdown</Tag>
          </Box>
          <Row sm={12}>
            <Col sm={6}>
              <Box mb={1}>
                <Row sm={12}>
                  <Col sm={4}>Single Dropdown</Col>
                  <Col sm={8}>
                    <SlzDropdown placeholder="SM Select" size="sm" options={colorOptions} />
                    <SlzDropdown placeholder="MD Select" size="md" options={colorOptions} />
                    <SlzDropdown placeholder="LG Select" size="lg" options={colorOptions} />
                  </Col>
                </Row>
              </Box>
            </Col>
            <Col sm={6}>
              <Box mb={1}>
                <Row sm={12}>
                  <Col sm={4}>Multi Dropdown</Col>
                  <Col sm={8}>
                    <SlzDropdown options={colorOptions} isMulti />
                  </Col>
                </Row>
              </Box>
            </Col>
            <Col sm={6}>
              <Row sm={12}>
                <Col sm={4}>Invalid Dropdown</Col>
                <Col sm={8}>
                  <SlzDropdown isInvalid options={colorOptions} size="md" />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row sm={12}>
                <Col sm={4}>Disable Dropdown</Col>
                <Col sm={8}>
                  <SlzDropdown options={colorOptions} isDisabled size="md" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>

        <Box my={5}>
          <HStack>
            <SlzDropdown.Creatable isInvalid options={quantityOptions} onChange={console.log} />
            <SlzDropdown
              options={rateMatcherOptions}
              customComponents={{ Option: RateMatcherOption }}
              placeholder={'Select your shipping rate'}
              isDisabled={false}
            />
            <SlzDropdown
              options={SETTING_APPLIES_OPTIONS}
              customComponents={{ Option: ReturnSettingOption }}
              placeholder={'Select your shipping rate'}
              isDisabled={false}
            />
          </HStack>
        </Box>
        <VStack spacing={5} m={5}>
          <Text as="h4">User Roles & Permissions</Text>
          <AddRolesAndPermsCard />
          <HorizontalRolesAndPermsCard />
        </VStack>
        <SlzButton mr={5} onClick={receiveNotification}>
          Receive notification
        </SlzButton>
        <SlzButton mr={5} onClick={clearNotification}>
          Clear notification
        </SlzButton>
        <Box mt={5}>
          <SlzNotification
            size="lg"
            number={notification}
            colorScheme="dark"
            variant="outline"
            notifications={notifications}
            onSelectOption={(option) => {
              console.log('notification: ', option);
            }}
          />
        </Box>
        <Box>
          <header>Pagination</header>
          <Box bg="dark.500">
            <SlzPagination
              py={5}
              showTitle={false}
              variant="light"
              direction={{
                labelColor: null,
                usingDots: true,
                currentPage: 1,
                totalPage: 10,
                numDots: 5,
                isGreyBg: true,
                onChange: (currentPage) => {
                  console.log(currentPage);
                }
              }}
            />
          </Box>
          <Box bg={'#0973EA'}>
            <SlzPagination
              {...{
                py: 5,
                colorScheme: '#fff',
                showTitle: false,
                variant: 'lightDark',
                pages: null,
                direction: {
                  usingDots: true,
                  numDots: 5,
                  isGreyBg: true,
                  prevLabel: '',
                  nextLabel: '',
                  currentPage: 1,
                  totalPage: 5,
                  labelColor: '#F2F2F2',
                  onChange: (currentIdx) => { }
                }
              }}
            />
          </Box>
          <Box bg="main.500">
            <SlzPagination
              py={5}
              showTitle={false}
              variant="normalLight"
              direction={{
                usingDots: true,
                currentPage: 1,
                totalPage: 10,
                numDots: 5,
                isGreyBg: true,
                onChange: (currentPage) => {
                  console.log(currentPage);
                }
              }}
            />
          </Box>
          <Box bg={'#FAFAFA'}>
            <SlzPagination
              {...{
                py: 5,
                colorScheme: '#fff',
                showTitle: false,
                variant: 'black',
                pages: null,
                direction: {
                  usingDots: true,
                  numDots: 5,
                  isGreyBg: true,
                  prevLabel: '',
                  nextLabel: '',
                  currentPage: 1,
                  totalPage: 5,
                  labelColor: '#000000',
                  onChange: (currentIdx) => { }
                }
              }}
            />
          </Box>
          <Box bg={'#0973EA'}>
            <SlzPagination
              {...{
                py: 5,
                colorScheme: '#fff',
                showTitle: false,
                variant: 'blackDark',
                pages: null,
                direction: {
                  usingDots: true,
                  numDots: 5,
                  isGreyBg: true,
                  prevLabel: '',
                  nextLabel: '',
                  currentPage: 1,
                  totalPage: 5,
                  labelColor: '#000000',
                  onChange: (currentIdx) => { }
                }
              }}
            />
          </Box>
        </Box>
        <SlzPagination
          py={5}
          direction={{
            usingDots: true,
            currentPage: 1,
            totalPage: 10,
            numDots: 5,
            isGreyBg: true,
            onChange: (currentPage) => {
              console.log(currentPage);
            }
          }}
          pages={{
            onChange: (numPerPage) => console.log(`number per pages ${numPerPage}`),
            pageOptions: [5, 10, 15, 20, 25]
          }}
        />
        <SlzPagination
          py={5}
          direction={{
            usingDots: false,
            currentPage: 1,
            totalPage: 10,
            numDots: 5,
            onChange: (currentPage) => {
              console.log(currentPage);
            }
          }}
          pages={{
            onChange: (numPerPage) => console.log(`number per pages ${numPerPage}`),
            pageOptions: [5, 10, 15, 20, 25]
          }}
        />
        <Tabs variant="line" size={['sm', 'md', 'lg']}>
          <TabList>
            <Tab>One</Tab>
            <Tab>Two</Tab>
            <Tab>Three</Tab>
            <Tab>One</Tab>
            <Tab>Two</Tab>
            <Tab isDisabled>Three12</Tab>
          </TabList>

          <TabPanels>
            <TabPanel></TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      <ElementOne />
      <ElementTwo />
      <TextFields />
      <Buttons />
      {/* <Box>
        <SlzButton onClick={() => setVisibleModal(true)}>Open Modal</SlzButton>
        <SlzModal variant="product" isOpen={visibleModal} onClose={() => setVisibleModal(false)}>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor earum illo excepturi.
          Sequi nihil, cum eligendi voluptatum ipsam officia porro adipisci tenetur cumque
          laudantium ipsa cupiditate unde, optio asperiores nulla.
        </SlzModal>
      </Box> */}
      <Tables />
      <ElementThree />
      <ElementFour />
      <Selection />
      <CurencyIconsAndCreditCards />

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((value) => console.log(value))}>
          <Controller
            render={({ field }) => <CourierCard {...field} />}
            control={methods.control}
            name="courier"
          />
          <button type="submit">Submit</button>
        </form>
      </FormProvider>
    </Container>
  );
};

export default Main;

// It just is dummy data for testing and will be removed.
export const SETTINGS = {
  companyID: '1164d3b4-77a2-4e21-a1fa-bb4e2b88593c',
  companyName: UpsLogoCompany,
  isActive: true,
  serviceTimes: [
    {
      label: '24 Hour',
      value: 'TwentyFour',
      isChecked: true,
      isTracked: false
    },
    {
      label: '24 Hour Pre-10:30',
      value: 'TwentyFourPreTenThirty',
      isChecked: false,
      isTracked: true
    },
    {
      label: '24 Hour Pre-noon',
      value: 'TwentyFourPreNoon',
      isChecked: true
    }
  ]
};
