import {
  AddUserIcon,
  CompanyIcon,
  CourierIcon,
  HeadphoneIcon,
  LogoutIcon,
  ManageUserIcon,
  OrderIcon,
  PackagingIcon,
  PaymentMethodIcon,
  ReturnIcon,
  SettingIcon,
  TaxesIcon,
  UserIcon,
  DashboardIcon,
  TruckIcon,
  HomeIcon,
  StoreIcon,
  AddressBookIcon,
  CompassIcon,
  ReturnsIcon,
  TrolleyIcon,
  BillingIcon,
  WarehouseIcon,
  IntegrationIcon,
  InvoicesIcon,
  CollectionAdvicesIcon,
  RemittanceAdvicesIcon,
  ProductLibraryStockIcon,
  CreateNewProductIcon,
  ConsignmentsIcon,
  SendConsignmentIcon,
  MyAppIcon,
  ApiAccessIcon,
  MarketPlaceIcon,
  LinkedInIcon,
  TwitterIcon,
  InstagramIcon,
  FacebookIcon
} from 'components/common/Icons';
import { Icon } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';
import { TbBuildingWarehouse } from 'react-icons/tb';

export const DEFAULT_IMAGE = 'https://via.placeholder.com/150';

export const NAVIGATE_MENU_STATUS = {
  MAIN: 'main',
  USER: 'users',
 // COMPANY: 'company',
  SETTING: 'settings',
  LOGOUT: 'log out',
  MANAGE_USERS: 'manageUsers',
  ADDUSER: 'addUser'
};

export const SETTING_NAVIGATE_MENU_STATUS = {
  warehouse: 'warehouseSettings',
  //packaging: 'packagingSettings',
  //return: 'returnSettings',
  order: 'orderSettings',
  courier: 'courierSettings',
  customerSupportAccess: 'customerSupportAccess'
};

export const COMPANY_NAVIGATE_MENU_STATUS = {
  taxesDuties: 'taxesDuties',
  paymentMethods: 'paymentMethods'
};

export const SETTING_STATUS = {
  //CUSTOMER: 'Customer support access',
  //COURIER: 'Courier settings',
  //ORDER: 'Order settings',
  //RETURN: 'Returns settings',
  //PACKAGING: 'Packaging settings',
  WAREHOUSE: 'Warehouse settings'
};

export const NAVIGATE_USER_SETTINGS = [
 /* {
    key: NAVIGATE_MENU_STATUS.COMPANY,
    label: 'Company',
    icon: <CompanyIcon w="1.875rem" h="1.875rem" strokeWidth="1" />
  },*/
  {
    key: NAVIGATE_MENU_STATUS.SETTING,
    label: 'Settings',
    icon: <SettingIcon w="1.875rem" h="1.875rem" strokeWidth="1" />
  }
];

export const NAVIGATE_ITEMS_STATUS = {
  [NAVIGATE_MENU_STATUS.MAIN]: {
    value: [NAVIGATE_MENU_STATUS.MAIN],
    items: [
      {
        iconLeft: <UserIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: NAVIGATE_MENU_STATUS.USER,
        label: 'Users',
        allowClickOnItem: true
      },
      /*{
        iconLeft: <CompanyIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: NAVIGATE_MENU_STATUS.COMPANY,
        label: 'Company',
        allowClickOnItem: true
      },*/
      {
        iconLeft: <SettingIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: NAVIGATE_MENU_STATUS.SETTING,
        label: 'Settings',
        allowClickOnItem: true
      },
      {
        iconLeft: <LogoutIcon />,
        iconRight: '',
        value: NAVIGATE_MENU_STATUS.LOGOUT,
        label: 'Log out',
        allowClickOnItem: true
      }
    ]
  },
  [NAVIGATE_MENU_STATUS.USER]: {
    value: NAVIGATE_MENU_STATUS.USER,
    items: [
      {
        iconLeft: <AddUserIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: 'addUser',
        label: 'Add user',
        allowClickOnItem: false
      },
      {
        iconLeft: <ManageUserIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: 'manageUsers',
        label: 'Manage users',
        allowClickOnItem: false
      }
    ]
  },
  /*[NAVIGATE_MENU_STATUS.COMPANY]: {
    value: NAVIGATE_MENU_STATUS.COMPANY,
    items: [
      {
        iconLeft: <TaxesIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: COMPANY_NAVIGATE_MENU_STATUS.taxesDuties,
        label: 'Taxes & Duties',
        allowClickOnItem: false,
        allowOpenModal: true
      },
      {
        iconLeft: <PaymentMethodIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: COMPANY_NAVIGATE_MENU_STATUS.paymentMethods,
        label: 'Payment methods',
        allowClickOnItem: false,
        allowOpenModal: true
      }
    ]
  },*/
  [NAVIGATE_MENU_STATUS.SETTING]: {
    value: NAVIGATE_MENU_STATUS.SETTING,
    items: [
      /*{
        iconLeft: <HeadphoneIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.customerSupportAccess,
        label: SETTING_STATUS.CUSTOMER,
        allowClickOnItem: false,
        allowOpenModal: true
      },
      {
        iconLeft: <CourierIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.courier,
        label: SETTING_STATUS.COURIER,
        allowClickOnItem: false,
        allowOpenModal: true
      },
      {
        iconLeft: <OrderIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.order,
        label: SETTING_STATUS.ORDER,
        allowClickOnItem: false,
        allowOpenModal: true
      },
      {
        iconLeft: <ReturnIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.return,
        label: SETTING_STATUS.RETURN,
        allowClickOnItem: false,
        allowOpenModal: true
      },
      {
        iconLeft: <PackagingIcon />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.packaging,
        label: SETTING_STATUS.PACKAGING,
        allowClickOnItem: false,
        allowOpenModal: true
      },*/
      {
        iconLeft: <TbBuildingWarehouse size="1.125rem" />,
        iconRight: <Icon as={FaChevronRight} fontSize="md" color="dark.500" ml={-1} />,
        value: SETTING_NAVIGATE_MENU_STATUS.warehouse,
        label: SETTING_STATUS.WAREHOUSE,
        allowClickOnItem: false,
        allowOpenModal: true
      }
    ]
  },
  [NAVIGATE_MENU_STATUS.LOGOUT]: {
    value: NAVIGATE_MENU_STATUS.LOGOUT,
    items: []
  }
};

export const ACTIVE_NAVBAR_ITEM = 'activeNavBarItem';
export const SET_IS_NAVBAR_EXPANDED = 'isNavbarExpanded';
export const ACTIVE_USER_SETTING_MODAL = 'activeUserSettingModal';

export const NAVBAR_CONFIGS = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon
  },
  {
    key: 'orders',
    name: 'Orders',
    icon: TruckIcon,
    children: [
      {
        key: 'orders/b2c',
        name: 'B2C Orders',
        path: '/orders/b2c',
        icon: HomeIcon
      },
      //Temporarily commented out
      /* {
        key: 'orders/b2b',
        name: 'B2B Orders',
        path: '/orders/b2b',
        icon: StoreIcon
      }, */
      {
        key: 'orders/tracking-references',
        name: 'Tracking references',
        path: '/orders/b2c?isTrackingModal=true',
        icon: CompassIcon
      },
      {
        key: 'orders/address-book',
        name: 'Address book',
        path: '/orders/address-book',
        icon: AddressBookIcon
      }
    ]
  },
 /* {
    key: 'returns',
    name: 'Returns',
    path: '/returns',
    icon: ReturnsIcon
  },*/  
  {
    key: 'inventory',
    name: 'Inventory',
    icon: TrolleyIcon,
    children: [
      {
        key: 'products',
        name: 'Product library & Stock',
        path: '/products',
        icon: ProductLibraryStockIcon
      },
      {
        key: 'products?isCreateProductModal=true',
        name: 'Create new product',
        path: '/products?isCreateProductModal=true',
        icon: CreateNewProductIcon
      },
      {
        key: 'inventory/consignments',
        name: 'Consignments',
        path: '/inventory/consignments',
        icon: ConsignmentsIcon
      },
      {
        key: 'inventory/consignments?isSendConsignmentModal=true',
        name: 'Send a consignment',
        path: '/inventory/consignments?isSendConsignmentModal=true',
        icon: SendConsignmentIcon
      }
    ]
  },
  {
    key: 'billing',
    name: 'Billing',
    icon: BillingIcon,
    children: [
      {
        key: '/billing?process=invoice',
        name: 'Invoices',
        path: '/billing?process=invoice',
        icon: InvoicesIcon
      },
      {
        key: '/billing?process=remittance',
        name: 'Remittance advices',
        path: '/billing?process=remittance',
        icon: RemittanceAdvicesIcon
      },
      {
        key: '/billing?process=collection',
        name: 'Collection advices',
        path: '/billing?process=collection',
        icon: CollectionAdvicesIcon
      }
    ]
  }
];

const NAVBAR_CONFIG_INTEGRATION = [
  {
    key: 'integration',
    name: 'Integrations',
    icon: IntegrationIcon,
    children: [
      {
        key: '/integration?tab=my_app',
        name: 'My Apps',
        path: '/integration?tab=my_app',
        icon: MyAppIcon
      },
      {
        key: '/integration?tab=api',
        name: 'API access',
        path: '/integration?tab=api',
        icon: ApiAccessIcon
      },
      {
        key: '/integration?tab=market_place',
        name: 'Marketplace',
        path: '/integration?tab=market_place',
        icon: MarketPlaceIcon
      }
    ]
  }
];

export const NAVBAR_CONFIGS_ADMIN = NAVBAR_CONFIGS.concat(NAVBAR_CONFIG_INTEGRATION);

export const ICON_MAP = {
  'linkedin-icon': LinkedInIcon,
  'twitter-icon': TwitterIcon,
  'instagram-icon': InstagramIcon,
  'facebook-icon': FacebookIcon
};

export const DEFAULT_LOGO = 'Images/selazar-login.svg';

export const TRACKING_OPTIONS = [
  {
    label: 'Tracked',
    value: true
  },
  {
    label: 'Untracked',
    value: false
  }
];

export const EMPHASIS_FIELDS = ['tag', 'type', 'status', 'source', 'bundleTag'];

export const OWNER_ROLE = 'System';

export const MANAGE_USER_PERMISSION = 'AddUser';
export const MANAGE_PREFERENCE_PERMISSION = 'ManagePreferences';

export const USEFUL_LINKS_TEXT = 'Here are some useful links';
export const EMAIL_CUSTOMER_SERVICE = 'customer.service@selazar.com';
export const KNOWLEDGE_BASE_URL = 'https://support.selazar.com';

export const ORDER_STATUS_COLOR = {
  ['pending']: 'creamsicle.500',
  ['inprocess']: 'tetra.500',
  ['processed']: 'szrCerulean.500',
  ['unsuccessful']: 'strawberry.300',
  ['partial']: 'sunrise.500',
  ['onhold']: 'primrose.500',
  ['received']: 'szrCerulean.500',
  ['processing']: 'tetra.500'
};

export const COURIER_MAP = {
  'Royal Mail': {
    bg: '#EE2722',
    color: '#FDDD32',
    name: 'Royal Mail'
  },
  Yodel: {
    bg: '#99CC00',
    color: '#FAFAFA',
    name: 'Yodel'
  },
  Evri: {
    bg: '#007BC4',
    color: '#FAFAFA',
    name: 'Evri'
  },
  UPS: {
    bg: '#330001',
    color: '#FFBE2C',
    name: 'UPS'
  },
  Whistl: {
    bg: '#FD4F00',
    color: '#FAFAFA',
    name: 'Whistl'
  }
};
