import { useQuery } from 'react-query';
import { getAddressBook } from '../services/address-book-api';

export const useAddressBookQuery = ({ companyID, filters, select, enabled }) => {
  return useQuery({
    queryKey: [`addressbook`, filters],
    queryFn: () => getAddressBook(companyID, filters),
    cacheTime: 60 * 1000,
    //staleTime: 2 * 1000,
    select: select,
    enabled: enabled
  });
};
