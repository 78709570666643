import { createIcon } from '@chakra-ui/react';

const DirectDebitIcon = createIcon({
  displayName: 'DirectDebitIcon',
  viewBox: '0 0 48 15.264',
  path: (
    <>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_7777"
            dataName="Rectangle 7777"
            width="48"
            height="15.264"
            transform="translate(0 0)"
            fill="#fafafa"
          />
        </clipPath>
      </defs>
      <g id="Group_34347" dataName="Group 34347" transform="translate(0 0)">
        <g
          id="Group_34342"
          dataName="Group 34342"
          transform="translate(0 0)"
          clipPath="url(#clip-path)">
          <path
            id="Path_10055"
            dataName="Path 10055"
            d="M11.9.1V.328c3.078.689,5.785,2.643,5.783,7.151a6.816,6.816,0,0,1-7.022,7.011c-4.777,0-8.755-2.81-8.755-5.142,0-2.12,4.192-3.7,7.812-3.821l1.689.035,0-5.49C4.266.067.074,3.258.072,7.677c0,4.212,4.8,7.63,10.748,7.634S21.6,12.142,21.6,7.692c0-3.9-4.1-7.3-9.7-7.6"
            transform="translate(-0.06 -0.06)"
            fill="#fafafa"
          />
          <path
            id="Path_10056"
            dataName="Path 10056"
            d="M11.9.1V.328c3.078.689,5.785,2.643,5.783,7.151a6.816,6.816,0,0,1-7.022,7.011c-4.777,0-8.755-2.81-8.755-5.142,0-2.12,4.192-3.7,7.812-3.821l1.689.035,0-5.49C4.266.067.074,3.258.072,7.677c0,4.212,4.8,7.63,10.748,7.634S21.6,12.142,21.6,7.692C21.6,3.795,17.5.388,11.9.1Z"
            transform="translate(-0.06 -0.06)"
            fill="#fafafa"
          />
          <path
            id="Path_10057"
            dataName="Path 10057"
            d="M39.124,38.439c0,2.356,2.636,3.724,4.687,3.872l0-7.985c-2.442.091-4.689,1.781-4.689,4.113"
            transform="translate(-32.496 -28.511)"
            fill="#fafafa"
          />
          <path
            id="Path_10058"
            dataName="Path 10058"
            d="M39.124,38.439c0,2.356,2.636,3.724,4.687,3.872l0-7.985C41.372,34.417,39.125,36.107,39.124,38.439Z"
            transform="translate(-32.496 -28.511)"
            fill="#fafafa"
          />
          <path
            id="Path_10059"
            dataName="Path 10059"
            d="M234.048,18.238l.108-.606c-1.232-.577-4.45-.309-4.393,1.861.049,1.882,2.482,2.44,4.314,1.929l-.062-.661c-.558.148-2.994.344-2.963-1.251.039-1.992,2.717-1.365,3-1.271"
            transform="translate(-190.84 -14.426)"
            fill="#fafafa"
          />
          <path
            id="Path_10060"
            dataName="Path 10060"
            d="M234.048,18.238l.108-.606c-1.232-.577-4.45-.309-4.393,1.861.049,1.882,2.482,2.44,4.314,1.929l-.062-.661c-.558.148-2.994.344-2.963-1.251C231.09,17.516,233.768,18.144,234.048,18.238Z"
            transform="translate(-190.84 -14.426)"
            fill="#fafafa"
          />
          <path
            id="Path_10061"
            dataName="Path 10061"
            d="M257.164,17.915v.652h1.645v3.417h1.164V18.569h1.614v-.652Z"
            transform="translate(-213.599 -14.88)"
            fill="#fafafa"
          />
          <path
            id="Path_10062"
            dataName="Path 10062"
            d="M257.164,17.915v.652h1.645v3.417h1.164V18.569h1.614v-.652Z"
            transform="translate(-213.599 -14.88)"
            fill="#fafafa"
          />
          <path
            id="Path_10063"
            dataName="Path 10063"
            d="M209.712,21.772l2.156,0v-.682h-2.156V20.1h2.045V19.42l-2.045,0V18.386l2.173,0v-.682l-3.262,0,0,4.068h1.089Z"
            transform="translate(-173.282 -14.705)"
            fill="#fafafa"
          />
          <path
            id="Path_10064"
            dataName="Path 10064"
            d="M209.712,21.772l2.156,0v-.682h-2.156V20.1h2.045V19.42l-2.045,0V18.386l2.173,0v-.682l-3.262,0,0,4.068h1.089Z"
            transform="translate(-173.282 -14.705)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7771"
            dataName="Rectangle 7771"
            width="1.219"
            height="4.051"
            transform="translate(28.045 3.003)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7772"
            dataName="Rectangle 7772"
            width="1.219"
            height="4.051"
            transform="translate(28.045 3.003)"
            fill="#fafafa"
          />
          <path
            id="Path_10065"
            dataName="Path 10065"
            d="M134.675,17.9h-2.182l0,4.05h2.182c1.48,0,2.762-.748,2.762-2.006s-1.281-2.043-2.761-2.044m-.214,3.368-.75.02V18.474l.937.051a1.44,1.44,0,0,1,1.508,1.382c0,.776-.64,1.364-1.7,1.363"
            transform="translate(-110.047 -14.868)"
            fill="#fafafa"
          />
          <path
            id="Path_10066"
            dataName="Path 10066"
            d="M134.675,17.9h-2.182l0,4.05h2.182c1.48,0,2.762-.748,2.762-2.006S136.156,17.9,134.675,17.9Zm-.214,3.368-.75.02V18.474l.937.051a1.44,1.44,0,0,1,1.508,1.382C136.157,20.683,135.518,21.271,134.461,21.271Z"
            transform="translate(-110.047 -14.868)"
            fill="#fafafa"
          />
          <path
            id="Path_10067"
            dataName="Path 10067"
            d="M134.665,47.945h-2.182v4.05h2.182c1.48,0,2.762-.749,2.762-2.006s-1.28-2.043-2.761-2.044m-.214,3.368-.75.02V48.516l.937.051a1.44,1.44,0,0,1,1.508,1.383c0,.776-.64,1.364-1.7,1.363"
            transform="translate(-110.039 -39.821)"
            fill="#fafafa"
          />
          <path
            id="Path_10068"
            dataName="Path 10068"
            d="M134.665,47.945h-2.182v4.05h2.182c1.48,0,2.762-.749,2.762-2.006S136.146,47.946,134.665,47.945Zm-.214,3.368-.75.02V48.516l.937.051a1.44,1.44,0,0,1,1.508,1.383C136.147,50.726,135.508,51.314,134.451,51.313Z"
            transform="translate(-110.039 -39.821)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7773"
            dataName="Rectangle 7773"
            width="1.176"
            height="3.01"
            transform="translate(41.261 9.229)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7774"
            dataName="Rectangle 7774"
            width="1.176"
            height="3.01"
            transform="translate(41.261 9.229)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7775"
            dataName="Rectangle 7775"
            width="1.176"
            height="0.776"
            transform="translate(41.262 7.822)"
            fill="#fafafa"
          />
          <rect
            id="Rectangle_7776"
            dataName="Rectangle 7776"
            width="1.176"
            height="0.776"
            transform="translate(41.262 7.822)"
            fill="#fafafa"
          />
          <path
            id="Path_10069"
            dataName="Path 10069"
            d="M263.62,52.768V51.326h1v-.639h-1v-.776h-1.2v.776h-.937v.639h.937V52.9c0,1.066,1.423,1.093,2.233.906v-.665c-.375.128-1.031.008-1.031-.376"
            transform="translate(-217.185 -41.455)"
            fill="#fafafa"
          />
          <path
            id="Path_10070"
            dataName="Path 10070"
            d="M263.62,52.768V51.326h1v-.639h-1v-.776h-1.2v.776h-.937v.639h.937V52.9c0,1.066,1.423,1.093,2.233.906v-.665C264.276,53.271,263.62,53.151,263.62,52.768Z"
            transform="translate(-217.185 -41.455)"
            fill="#fafafa"
          />
          <path
            id="Path_10071"
            dataName="Path 10071"
            d="M171.624,53.072c-1.3,0-2.212.714-2.212,1.619,0,1.015.99,1.638,2.211,1.639a4.188,4.188,0,0,0,1.743-.246v-.708c-.315.332-2.668.749-2.727-.42l3.017,0c.077-1.415-1.03-1.885-2.032-1.886m-.976,1.322c.017-.5.435-.717.989-.717a.805.805,0,0,1,.9.713Z"
            transform="translate(-140.713 -44.081)"
            fill="#fafafa"
          />
          <path
            id="Path_10072"
            dataName="Path 10072"
            d="M171.624,53.072c-1.3,0-2.212.714-2.212,1.619,0,1.015.99,1.638,2.211,1.639a4.188,4.188,0,0,0,1.743-.246v-.708c-.315.332-2.668.749-2.727-.42l3.017,0C173.734,53.543,172.626,53.073,171.624,53.072Zm-.976,1.322c.017-.5.435-.717.989-.717a.805.805,0,0,1,.9.713Z"
            transform="translate(-140.713 -44.081)"
            fill="#fafafa"
          />
          <path
            id="Path_10073"
            dataName="Path 10073"
            d="M181.915,19.8a1.006,1.006,0,0,0,.955-1.04c0-.921-1.182-1.075-1.793-1.076l-2.184,0V21.75H180.1V20.13h.45a.69.69,0,0,1,.666.41l.642,1.211h1.272l-.933-1.663a1.245,1.245,0,0,0-.281-.29m-1.031-.359h-.793V18.348h.81c.377,0,.75.18.75.551,0,.337-.452.541-.767.541"
            transform="translate(-148.586 -14.687)"
            fill="#fafafa"
          />
          <path
            id="Path_10074"
            dataName="Path 10074"
            d="M181.915,19.8a1.006,1.006,0,0,0,.955-1.04c0-.921-1.182-1.075-1.793-1.076l-2.184,0V21.75H180.1V20.13h.45a.69.69,0,0,1,.666.41l.642,1.211h1.272l-.933-1.663A1.245,1.245,0,0,0,181.915,19.8Zm-1.031-.359h-.793V18.348h.81c.377,0,.75.18.75.551C181.651,19.236,181.2,19.44,180.884,19.44Z"
            transform="translate(-148.586 -14.687)"
            fill="#fafafa"
          />
          <path
            id="Path_10075"
            dataName="Path 10075"
            d="M207.8,47.4a1.885,1.885,0,0,0-1.444.5v-1.81h-1.176l0,4.392h1.176V50.1a1.844,1.844,0,0,0,1.436.494,1.64,1.64,0,0,0,1.794-1.623A1.582,1.582,0,0,0,207.8,47.4m-.422,2.532a1,1,0,0,1-1.023-.813v-.227a1,1,0,0,1,1.023-.811.931.931,0,1,1,0,1.85"
            transform="translate(-170.421 -38.283)"
            fill="#fafafa"
          />
          <path
            id="Path_10076"
            dataName="Path 10076"
            d="M207.8,47.4a1.885,1.885,0,0,0-1.444.5v-1.81h-1.176l0,4.392h1.176V50.1a1.844,1.844,0,0,0,1.436.494,1.64,1.64,0,0,0,1.794-1.623A1.582,1.582,0,0,0,207.8,47.4Zm-.422,2.532a1,1,0,0,1-1.023-.813v-.227a1,1,0,0,1,1.023-.811.931.931,0,1,1,0,1.85Z"
            transform="translate(-170.421 -38.283)"
            fill="#fafafa"
          />
        </g>
      </g>
    </>
  )
});

export default DirectDebitIcon;
