import React from 'react';
import { Icon } from '@chakra-ui/react';

const StickerIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="29.925"
      height="29.925"
      viewBox="0 0 29.925 29.925"
      {...props}>
      <g id="Group_34228" data-name="Group 34228" transform="translate(-1729.773 -317.887)">
        <path
          id="Line_316"
          data-name="Line 316"
          d="M.662,13.962A1.662,1.662,0,0,1-.513,11.124L11.124-.513a1.663,1.663,0,1,1,2.351,2.351L1.838,13.476A1.657,1.657,0,0,1,.662,13.962Z"
          transform="translate(1745.735 333.85)"
          fill="#303134"
        />
        <path
          id="Path_4483"
          data-name="Path 4483"
          d="M1746.4,347.812H1734.76a4.993,4.993,0,0,1-4.987-4.987v-19.95a4.993,4.993,0,0,1,4.987-4.987h19.95a4.993,4.993,0,0,1,4.988,4.987v11.637a1.663,1.663,0,0,1-1.663,1.663h-9.975v9.975A1.663,1.663,0,0,1,1746.4,347.812Zm-11.637-26.6a1.664,1.664,0,0,0-1.662,1.663v19.95a1.664,1.664,0,0,0,1.662,1.663h9.975v-8.312a3.329,3.329,0,0,1,3.325-3.325h8.312v-9.975a1.664,1.664,0,0,0-1.663-1.663Z"
          fill="#303134"
        />
      </g>
    </Icon>
  );
};

export default StickerIcon;
