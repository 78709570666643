export const PRODUCT_KEY = 'product_list';
export const ORDER_KEY = 'order_list';
export const DEFAULT_IMAGE = 'https://via.placeholder.com/150';
export const COL_NUMBER_TYPE = 'number';
export const ACCORDION_COL_SPAN = 10;
export const NUM_OF_SECONDARY_MENU_COLUMN = 1;
export const ORDER_TYPE = 'order';
export const VARIANT_DETAIL_ORDER = 'detailOrder';
export const COL_CUSTOM = 'col-custom';
export const TABLES = {
  variant: ['normal', 'special'],
  type: {
    normal: ['normal', 'solid', 'simple', 'order', 'productSelected', 'selectedBundle']
  },
  orderBy: { 0: 'asc', 1: 'desc' }
};
export const DEFAULT_EMPTY_MSG = 'Your product selection will appear on this side';
