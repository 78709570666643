import CommonEndpoints from 'components/common/Endpoints';
import axiosClient from '../../../config/axios';
import { buildQuery } from '../../../utils/helper';
import { BILLING_PROCESS } from '../constants';

export async function getBilling(filters) {
  const process = filters?.process;
  let url = new URL(CommonEndpoints.INVOICES.GET.ALL);
  switch (process) {
    case BILLING_PROCESS.REMITTANCE_ADVICE:
      url = new URL(CommonEndpoints.REMITTANCE_ADVICE.GET.ALL);
      break;
    case BILLING_PROCESS.COLLECTION_ADVICE:
      url = new URL(CommonEndpoints.COLLECTION_ADVICE.GET.ALL);
      break;
    default:
      break;
  }

  return await axiosClient.request(buildQuery(url, filters));
}

export async function getBillingDetail(id) {}
export async function getInvoiceTypes() {
  return await axiosClient.request(CommonEndpoints.INVOICES.GET.TYPES);
}

export async function getWarehouses() {
  return await axiosClient.request(CommonEndpoints.RETAILER.GET.WAREHOUSES);
}
