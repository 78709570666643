import { SlzButton } from 'components/common/SlzButton';

import {
  Active,
  Standy,
  Configure,
  Attention
} from 'components/common/Icons/EnabledCentreCards/EnabledFulfilmentCentreIcons';

import {
  AlcoholIcon,
  BinIcon,
  FragileIcon,
  GarmenOnHangerIcon,
  HazmatIcon,
  OversizedItemIcon,
  PalletIcon,
  SecureIcon,
  ShelfIcon,
  TemperatureControlledIcon
} from 'components/common/Icons/storageCapabilitiesIcons';
import PendingStatusIndicator from 'modules/fulfilmentCentres/components/other/PendingStatusIndicator';

export const FULFILLMENT_CENTRES = '/fulfillment';

export const WAREHOUSE_STATE_MAPPER = {
  0: 'Available',
  1: 'Pending',
  3: 'Active'
};

export const FULFILLMENT_CENTRES_CONTENT = {
  TABS_LIST: [
    {
      key: 'myNetwork',
      name: 'My Network'
    },
    {
      key: 'availableNetwork',
      name: 'Available Network'
    }
  ],
  AVAILABLE_NETWORK: {
    title: 'Request Access to Additional Fulfilment Centres',
    description:
      'Having additional fulfillment centers will provide a better customer experience by reducing delivery times, shipping costs, and increasing the availability of products'
  }
};

export const ICON_CHIP = {
  CustomPackaging: {
    colorScheme: 'marine',
    children: 'Custom Packaging',
    icon: 'customPackaging'
  },
  EmissionFree: {
    colorScheme: 'szrLeaf',
    children: 'Emission Free',
    icon: 'emissionFree'
  },
  NextDayDelivery: {
    colorScheme: 'szrLeaf',
    children: 'Next Day Delivery',
    icon: 'nextDayDelivery'
  },
  SameDayDelivery: {
    colorScheme: 'szrLeaf',
    children: 'Same Day Delivery',
    icon: 'sameDayDelivery'
  }
};

export const STOREAGE_CAPABILITY_ICON = {
  AlcoholIcon: <AlcoholIcon />,
  BinIcon: <BinIcon />,
  FragileIcon: <FragileIcon />,
  GarmenOnHangerIcon: <GarmenOnHangerIcon />,
  HazmatIcon: <HazmatIcon />,
  OversizedItemIcon: <OversizedItemIcon />,
  PalletIcon: <PalletIcon />,
  SecureIcon: <SecureIcon />,
  ShelfIcon: <ShelfIcon />,
  TemperatureControlledIcon: <TemperatureControlledIcon />
};

export const AVAILABLE_DISABLED_FOOTER_BUTTON = {
  Available: (
    <SlzButton type="submit" colorScheme="main">
      Request
    </SlzButton>
  ),
  New: (
    <SlzButton type="submit" colorScheme="main">
      Request
    </SlzButton>
  ),
  ComingSoon: (
    <SlzButton type="submit" colorScheme="main" variant="outline" isDisabled="true">
      Request
    </SlzButton>
  ),
  Pending: (
    <SlzButton type="submit" colorScheme="main" variant="outline">
      Review
    </SlzButton>
  )
};

export const AVAILABLE_DISABLED_HEADER_ICON = {
  New: () => (
    <PendingStatusIndicator
      background="barb.500"
      text="New"
      textColor="lightAndShadow.reverseText"
    />
  ),
  ComingSoon: () => (
    <PendingStatusIndicator
      background="negative.300"
      text="ComingSoon"
      textColor="barb.500"
      border="0.063rem solid"
      borderColor="barb.500"
    />
  ),
  Pending: () => (
    <PendingStatusIndicator background="sunshine.500" text="Pending" textColor="willow.500" />
  )
};

export const ENABLED_FOOTER_BUTTON = {
  Active: () => (
    <SlzButton type="submit" colorScheme="main">
      Preferences
    </SlzButton>
  ),
  Standy: () => (
    <SlzButton type="submit" colorScheme="main">
      Preferences
    </SlzButton>
  ),
  Configure: () => (
    <Box>
      <SlzButton type="submit" colorScheme="main" variant="outline">
        Configure
      </SlzButton>
      <Attention sx={{ transform: 'translate(-12px, -10px)', position: 'absolute' }} />
    </Box>
  ),
  Pending: () => (
    <SlzButton type="submit" colorScheme="main" variant="outline">
      Review
    </SlzButton>
  )
};

export const ENABLED_HEADER_ICON = {
  Active: Active,
  Standy: Standy,
  Configure: Configure,
  Pending: PendingStatusIndicator
};

export const ENABLED_DEFAULT_COLOR = 'table.mainText';

export const ENABLED_TEXT_COLOR = {
  Standy: 'stone.300',
  Configure: 'stone.300',
  Pending: 'stone.300'
};

export const ENABLED_LABEL_COLOR = {
  Pending: 'stone.300'
};
