export const B2B_ORDER_OPTIONS_WRAPPER_STYLE = {
  sx: {
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  },
  maxH: '31.25rem',
  overflowY: 'scroll'
};

export const B2B_ORDER_OPTION_STYLE = {
  title: {
    wrapper: {
      borderRadius: 1.5,
      maxH: '3.5rem',
      py: '1.125rem',
      px: '1.438rem',
      bg: 'light.500'
    },
    backgroundIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      w: '1.25rem',
      h: '1.25rem',
      borderRadius: 'full'
    },
    text: {
      lineHeight: 5,
      fontWeight: 'bold'
    }
  },
  tag: {
    wrapper: {
      size: 'sm',
      maxHeight: '1rem',
      border: '0.063rem solid',
      borderColor: 'main.500',
      ml: 'auto !important'
    },
    content: {
      fontSize: 'xs',
      fontWeight: 'normal',
      ml: '0.125rem !important'
    }
  },
  toggleButton: {
    wrapper: {
      borderRadius: 1.5,
      p: '1.5rem',
      bg: 'light.500',
      mt: '0.188rem'
    },
    groupButtons: {
      width: '100%',
      size: 'lg'
    },
    button: {
      width: '100%',
      ml: '0rem !important',
      size: 'lg',
      _disabled: {
        color: 'stone.300',
        cursor: 'not-allowed',
        bg: 'main.300',
        _hover: {
          bg: 'main.300'
        } 
      }
    }
  },
  true: {
    title: 'stone.300',
    icon: 'stone.300'
  },
  false: {
    title: 'dark.700',
    icon: 'main.500'
  }
};

export const TOGGLE_BUTTON_INACTIVE_STYLE = {
  color: 'dark.700',
  border: '0.063rem solid',
  borderColor: 'main.500',
  bg: 'light.500',
  _hover: {
    bg: 'gray.300'
  },
  _active: { bg: 'gray.200' },
  _disabled: {
    border: '0.063rem solid ',
    borderColor: 'stone.300',
    color: 'stone.300',
    bg: 'light.500',
    cursor: 'not-allowed',
    _hover: {
      bg: 'light.500'
    }
  }
};

export const MODIFY_BUTTON_STYLE = {
  fontSize: 'md',
  borderRadius: 1.5,
  size: 'sm',
  ml: 'auto !important',
  h: '1.875rem',
  px: '1.5rem'
};

export const B2B_BOX_SPLIT_STYLE = {
  attentionCard: {
    maxW: '30.693rem',
    mt: '1.5rem',
    status: 'success',
    w: 'auto',
    mb: 'auto',
    borderRadius: 1.5
  },
  form: {
    height: '37.5rem',
    display: 'flex',
    flexDirection: 'column'
  }
};

export const B2B_ORDER_SUBMITTED_STYLE = {
  needToCancel: {
    spacing: 0,
    pl: 5,
    pr: 9,
    pt: 4,
    pb: 6,
    borderRadius: 1.5,
    border: '1px',
    borderColor: 'main.500',
    w: '44.375rem',
    h: '5.875rem',
    bg: 'light.500',
    alignItems: 'flex-start',
    fontSize: 'md',
    color: 'main.500'
  },
  orderSubmitted: {
    borderRadius: 1.5,
    w: 80,
    h: '3.75rem',
    bg: 'main.300',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export const B2B_PRODUCT_SELECTION_TABLE_STYLE = {
  w: '25.438rem',
  maxW: '25.438rem',
  minW: '25.438rem',
  h: 'fit-content',
  maxH: '24.063rem',
  th: {
    textAlign: 'center'
  },
  'th:first-of-type': {
    borderLeftRadius: '0.188rem'
  },
  'th:last-of-type': {
    borderRightRadius: '0.188rem'
  },
  tr: {
    bgColor: 'light.300'
  },
  td: {
    color: 'dark.700'
  }
};

export const SELECT_RECIPIENT_SELECT_STYLE = {
  container: (provided) => ({
    ...provided,
    bg: 'white'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bgColor: 'inherit'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    opacity: 0
  }),
  input: (provided) => ({
    ...provided,
    color: 'inherit'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'inherit'
  })
};

export const CUSTOM_BOX_SPLIT_STYLES = {
  selectedTable: {
    sx: {
      w: '25.438rem',
      maxW: '25.438rem',
      minW: '25.438rem',
      h: 'fit-content',
      maxH: '24.063rem',
      '.emphasized-cell': { pr: 0 },
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      th: {
        paddingLeft: '0rem !important',
        bg: 'light.700'
      },
      'th:first-of-type': {
        borderLeftRadius: '0.188rem'
      },
      'th:last-of-type': {
        borderRightRadius: '0.188rem'
      },
      'th:nth-of-type(2)': {
        paddingLeft: '0.5rem !important'
      },
      'tr:has(div.unmatched)': {
        background: '#F5CCD380'
      },
      'tr:has(div.matched)': {
        background: '#BDE5CF80'
      },
      tr: {
        bgColor: 'light.300'
      }
    },
    tableWidth: '25rem',
    overflowX: 'unset',
    overflowY: 'scroll',
    showBoxShadow: false,
    variant: 'productSelected',
    sizes: ['sm', 'md', 'lg'],
    showMarked: false,
    showHeader: true,
    showToolbar: false,
    autoResize: false
  },
  accordionTable: {
    wrapper: {
      sx: {
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '::-webkit-scrollbar': {
          display: 'none'
        },
        tr: {
          bgColor: 'light.300'
        }
      },
      h: '25rem',
      overflowY: 'scroll'
    },
    accordionItem: {
      _last: {
        mb: '0'
      },
      w: '100%',
      border: 'none',
      mb: '1.125rem'
    },
    button: (status) => ({
      outline: 'none !important',
      px: '0.75rem',
      minW: '25.438rem',
      h: '1.75rem',
      mb: '0.188rem',
      w: '100% !important',
      display: 'flex',
      bg:
        status.length === 0
          ? 'light.700'
          : status.includes('error')
          ? 'negative.300'
          : 'positive.300',
      borderRadius: '0.188rem',
      justifyContent: 'space-between',
      _hover: {
        bg:
          status.length === 0
            ? 'light.700'
            : status.includes('error')
            ? 'negative.300'
            : 'positive.300'
      }
    }),
    tag: (status) => ({
      size: 'sm',
      px: '0.375rem',
      fontWeight: 'thin',
      bg: status.includes('error') ? 'red.500' : 'positive.500',
      color: 'white',
      mr: '0.75rem'
    }),
    table: {
      sx: {
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        w: '25.438rem',
        maxW: '25.438rem',
        minW: '25.438rem',
        h: 'fit-content',
        maxH: '24.063rem',
        th: {
          textAlign: 'start',
          paddingLeft: '1rem !important'
        }
      },
      overflowX: 'unset',
      overflowY: 'scroll',
      showBoxShadow: false,
      variant: 'selectedBundle',
      showMarked: false,
      showHeader: false,
      showToolbar: false,
      autoResize: false
    }
  }
};

export const CUSTOM_BOX_SPLIT_BUTTONS_STYLE = {
  wrapper: {
    w: '100%',
    justifyContent: 'space-between',
    bg: 'light.300',
    h: '3rem',
    px: '0.75rem',
    pt: '0.463rem',
    pb: '0.538rem'
  },
  addButton: {
    tag: {
      fontSize: 'lg',
      fontWeight: 'normal',
      color: 'dark',
      borderRadius: 4,
      py: '0.25rem',
      pl: '0.375rem',
      pr: '0.625rem',
      size: 'lg',
      cursor: 'pointer'
    },
    icon: {
      mr: '0.375rem',
      p: '0.375rem',
      borderRadius: 'full',
      w: '1.5rem',
      h: '1.5rem',
      bg: 'main.700',
      color: 'white'
    }
  },
  deleteButton: {
    textAlign: 'center',
    display: 'flex',
    px: '0.313rem',
    variant: 'ghost',
    size: 'lg',
    colorScheme: 'light'
  }
};

const summaryTableStyle = {
  borderTopRadius: 0.75,
  h: 'fit-content',
  maxH: '24.063rem',
  overflowX: 'unset',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  thead: {
    th: {
      fontSize: 'sm',
      lineHeight: 'none',
      bg: 'light.500',
      py: 0,
      maxH: '1.875rem',
      h: '1.875rem'
    }
  },
  tr: {
    bg: 'light.300'
  },
  td: {
    h: '45px',
    maxH: '45px'
  }
};

export const BOX_SPLIT_SUMMARY_STYLE = {
  summaryTable: summaryTableStyle,
  selectedProductTable: {
    ...summaryTableStyle,
    thead: {
      th: {
        fontSize: 'sm',
        lineHeight: 'none',
        bg: 'main.300',
        py: 0,
        maxH: '1.875rem',
        h: '1.875rem'
      }
    },
    td: {
      h: 6,
      maxH: 6
    }
  }
};
