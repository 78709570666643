import SlzAccordionTable from 'components/common/SlzTable/SlzAccordion/SlzAccordionTable';
import { useReturnDetailQuery } from 'modules/returns/hooks/useReturnDetailQuery';
import { transformAccordionItems } from '../../mappers/return-list-mapper';

const ReturnAccordionTable = ({ row, accordionProps }) => {
  const {
    data: itemDetails,
    isLoading,
    refetch
  } = useReturnDetailQuery({
    returnID: row?.retailerReturnID,
    select: (data) => transformAccordionItems(data, row)
  });

  return (
    <SlzAccordionTable
      variant="order"
      isShow={row?.isExpand}
      rowId={row?.retailerReturnID}
      data={itemDetails}
      isLoading={isLoading}
      refetch={refetch}
      isChild={true}
      configProps={{ ...accordionProps, markerColor: row?.status?.color }}
    />
  );
};

export default ReturnAccordionTable;
