import CommonEndpoints from '../../../components/common/Endpoints';
import { buildQuery } from '../../../utils/helper';
import axiosClient from '../../../config/axios';

export async function getOnHold(filters) {
  let url = new URL(CommonEndpoints.STOCK.GET.QUARANTINE);
  return await axiosClient.request(buildQuery(url, filters));
}

export async function createQualityReportResponse(payload) {
  let url = CommonEndpoints.STOCK.POST.QUALITY_REPORT_RESPONSE;
  return await axiosClient.post(url, payload);
}