import { Text, VStack } from '@chakra-ui/react';
import { chakraComponents } from 'chakra-react-select';

export const SelectRecipientOptionCustom = {
  Option: ({ value, ...props }) => {
    return (
      <chakraComponents.Option {...props}>
        <VStack alignItems="start" p="0.375rem 0.688rem" spacing={0}>
          <Text fontSize="lg">{`${value.name} - ${value.companyName}`}</Text>
          <Text fontSize="sm" color="dark.500">
            {`${value.addressLine1}, ${value.addressLine2}, ${value.county}, ${value.town}, ${value.postCode}`}
          </Text>
        </VStack>
      </chakraComponents.Option>
    );
  }
};
