import { Icon } from '@chakra-ui/react';
import React from 'react';

const CustomerCircleIcon = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="dark.700"
      {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_6761"
            data-name="Rectangle 6761"
            width="35"
            height="35"
            transform="translate(-2 -2)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_4208"
        data-name="Group 4208"
        transform="translate(2 2)"
        clip-path="url(#clip-path)">
        <path
          id="Path_9792"
          data-name="Path 9792"
          d="M15.735,31.469A15.735,15.735,0,1,1,31.469,15.735,15.752,15.752,0,0,1,15.735,31.469m0-28.322A12.588,12.588,0,1,0,28.322,15.735,12.6,12.6,0,0,0,15.735,3.147"
        />
        <path
          id="Path_9793"
          data-name="Path 9793"
          d="M12.294,16.588a6.294,6.294,0,1,1,6.294-6.294,6.3,6.3,0,0,1-6.294,6.294m0-9.441a3.147,3.147,0,1,0,3.147,3.147,3.15,3.15,0,0,0-3.147-3.147"
          transform="translate(3.441 2.294)"
        />
        <path
          id="Path_9794"
          data-name="Path 9794"
          d="M23.1,20.639a1.574,1.574,0,0,1-1.507-1.123,4.749,4.749,0,0,0-4.524-3.367h-6.3a4.693,4.693,0,0,0-4.522,3.361,1.573,1.573,0,1,1-3.013-.9A7.818,7.818,0,0,1,10.77,13h6.3A7.914,7.914,0,0,1,24.6,18.615a1.573,1.573,0,0,1-1.056,1.959,1.606,1.606,0,0,1-.452.065"
          transform="translate(1.817 7.456)"
        />
      </g>
    </Icon>
  );
};

export default CustomerCircleIcon;
