import { Fragment, useEffect, useState } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { ControlledField } from 'components/form';
import { useRolesCompanyQuery } from 'modules/users/hooks';
import { ROLES_USER_ICON } from 'modules/users/constants';
import { UserRoleSelectionCard } from '../other';
import HorizontalEditUserRoleCard from '../other/HorizontalEditUserRoleCard';

const EditUserRolesForm = ({ title, isMyOwnUser, ...rest }) => {
  const { data: fetchedRoles, isFetching } = useRolesCompanyQuery();
  const [userRoles, setUserRoles] = useState();
  const methods = useFormContext();
  const selectedRole = methods.getValues('roles');

  useEffect(() => {
    if (fetchedRoles) {
      const roles = fetchedRoles.map((role) => {
        return {
          ...role,
          icon: ROLES_USER_ICON[role.vendor][role.role]
        };
      });
      if (!isMyOwnUser) {
        setUserRoles(roles);
        return;
      }
      const myOwnRole = roles.find((role) => role.id == selectedRole);
      setUserRoles([
        {
          ...myOwnRole,
          defaultChecked: true,
          isReadOnly: true
        }
      ]);
    }
  }, [fetchedRoles, selectedRole]);

  return (
    <Fragment>
      {title && (
        <Heading fontSize="xl" lineHeight="tall">
          {title}
        </Heading>
      )}
      <Box {...rest}>
        <ControlledField
          name="roles"
          component={(props) => (
            <UserRoleSelectionCard
              styles={{
                w: 'full',
                display: 'flex',
                gap: 3,
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
              {...props}>
              {(getRadioProps) =>
                userRoles &&
                userRoles.map((role) => {
                  const isChecked = role.id === props.value;
                  const radioProps = getRadioProps({ value: role.id });
                  return (
                    <Fragment key={role.id}>
                      <HorizontalEditUserRoleCard
                        isChecked={isChecked}
                        radioProps={radioProps}
                        {...role}
                      />
                    </Fragment>
                  );
                })
              }
            </UserRoleSelectionCard>
          )}
        />
      </Box>
    </Fragment>
  );
};

export default EditUserRolesForm;
