import { HStack, Text, VStack } from '@chakra-ui/react';

import { HorizontalEmptyBarChart, VerticalEmptyBarChart } from 'components/common/Icons';
import { Fragment } from 'react';

const NO_DATA_ICON = {
  horizontal: HorizontalEmptyBarChart,
  vertical: VerticalEmptyBarChart
};

const LAYOUT_TYPES = {
  horizontal: HStack,
  vertical: VStack
};

const NoDataLayout = ({ children, layout, ...rest }) => {
  const Layout = LAYOUT_TYPES[layout];

  return <Layout {...rest}>{children}</Layout>;
};

const ChartLayout = ({ layout = 'vertical', onReload, isSuccess = true, children, ...rest }) => {
  const Icon = NO_DATA_ICON[layout] || Fragment;

  return isSuccess ? (
    children
  ) : (
    <NoDataLayout
      w="100%"
      h="100%"
      alignItems="center"
      justifyContent="space-between"
      layout={layout}
      {...rest}>
      <Icon />
      <VStack spacing={1}>
        <Text color="dark.500" fontWeight="medium" fontSize="1rem" lineHeight="1.3125rem">
          No data available
        </Text>
        <Text
          color="main.500"
          fontSize="1rem"
          lineHeight="1.3125rem"
          sx={{ cursor: 'pointer' }}
          onClick={onReload}>
          Reload
        </Text>
      </VStack>
    </NoDataLayout>
  );
};

export default ChartLayout;
