import React from 'react';
import { Icon } from '@chakra-ui/react';

export default function ChevronRightIcon(props) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.2 12"
      width="7.2"
      height="12"
      fill="currentColor"
      {...props}>
      <path
        id="Path_8358"
        data-name="Path 8358"
        d="M6.67,6.913,6,6.218l-.67.694A.923.923,0,0,0,6.67,6.913ZM6,4.83,1.618.288a.925.925,0,0,0-1.34,0,1.008,1.008,0,0,0,0,1.389L5.33,6.913,6,6.218l.67.694,5.053-5.236a1.008,1.008,0,0,0,0-1.389.925.925,0,0,0-1.34,0Z"
        transform="translate(0 12) rotate(-90)"
        fill="#676a74"
        fillRule="evenodd"
      />
    </Icon>
  );
}
