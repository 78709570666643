import { HStack, Text } from '@chakra-ui/react';

import { SlzButton } from 'components/common/SlzButton';

const ConfirmToast = ({
  message,
  confirmText,
  cancelText,
  confirmProps,
  cancelProps,
  onConfirmed,
  onCancel
}) => {
  return (
    <HStack ps={4} spacing={5}>
      <Text fontSize="1rem" fontWeight="normal" color="dark.700" mr="0.125rem">
        {message}
      </Text>
      <SlzButton size="md" borderRadius={1.5} onClick={onConfirmed} {...confirmProps}>
        {confirmText}
      </SlzButton>
      <SlzButton size="md" borderRadius={1.5} onClick={onCancel} mr="0.375rem" {...cancelProps}>
        {cancelText}
      </SlzButton>
    </HStack>
  );
};

export default ConfirmToast;
