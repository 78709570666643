export const RETAILER_OWNER = {
  ROLES:
    'A Retailer owner has the ability to manage and oversee the overall platform. They have access to all sections, functionalities and permissions, allowing them to configure settings, manage users, assign roles, and perform administrative tasks.',
  PERMISSIONS:
    'B2C Orders | B2B Orders | Returns Inventory | Products & stock Consignments | Billing Integrations | Company preferences'
};

export const RETAILER_USER = {
  ROLES:
    'Retailer users can create/edit products, handle orders, and manage consignments. Retailer users have access to inventory control, order management, and shipment monitoring.',
  PERMISSIONS: 'B2C Orders | Inventory Products & stock | Consignments'
};

export const SUPPLIER = {
  ROLES:
    'Suppliers have access to create/edit and managing consignments and generating shipping labels.',
  PERMISSIONS: 'Products & stock | Consignments'
};
