import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Text,
  useBoolean,
  useDisclosure
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { SlzToggle } from 'components/common/SlzToggle';
import { SlzButton } from 'components/common/SlzButton';

import {
  CUSTOM_LIBRARY_TEXT,
  CUSTOM_LIBRARY_TYPE,
  DELETE_CUSTOM_BOX,
  PREFERENCES_ACTIONS,
  USER_SETTING_MODAL_LAYOUT,
  DELETE_CUSTOM_MAILING_BAG_CONFIRMATION,
  USER_SETTING_FORMS_DEFAULT_NAME,
  PACKAGING_TYPES,
  DIMENSIONS_TEXT
} from 'modules/preferences/constants';
import { CUSTOM_LIBRARY_STYLE } from 'modules/preferences/styles';
import {
  putArchiveCustomPackaging,
  updateCustomPackagingStatus
} from 'modules/preferences/services/preferences-api';
import { SlzPopoverConfirm } from 'components/common/SlzPopoverConfirm';
import { applyVariableToMessage } from 'utils/helper';
import useSlzToast from 'hooks/useSlzToast';
import { usePreferenceContext } from 'modules/preferences/context/PreferenceContext';
import { useMultipleFormContext } from 'contexts/MultipleFormContext';
import { useQuery as useRouterQuery } from 'hooks/useQuery';

const MaxWeight = ({ maxWeight }) => (
  <HStack {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.weightLimit.wrapper}>
    <Text {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.weightLimit.text}>
      {CUSTOM_LIBRARY_TEXT.card.weightLimit}
    </Text>
    <Input {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.weightLimit.input} value={`${maxWeight}Kg`} />
  </HStack>
);

const Dimensions = ({ height, width, depth, type }) => (
  <HStack {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.dimensions.wrapper}>
    <Text {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.dimensions.text}>
      {CUSTOM_LIBRARY_TEXT.card.dimensions}
    </Text>
    <Input
      {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.dimensions.input}
      value={DIMENSIONS_TEXT[type](height, width, depth)}
    />
  </HStack>
);

const LibraryCard = ({ library, refetch }) => {
  const [isEnabled, setIsEnabled] = useBoolean(library.enabled);
  const routerQuery = useRouterQuery();
  const history = useHistory();
  const { forms } = useMultipleFormContext();

  const { [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateBox]: formBox } = forms;
  const { [USER_SETTING_FORMS_DEFAULT_NAME.formUpdateMailingBag]: formMailingBag } = forms;
  const methodsBox = formBox?.form;
  const methodsMailingBag = formMailingBag?.form;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [_, setToast] = useSlzToast();

  const messagesVariable = { boxName: library.friendlyName };

  const { preference, preferenceDispatch } = usePreferenceContext();
  const { managedType } = preference;
  const { wrapperStatusStyle, ...wrapperStyle } = CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.wrapper;

  const usePackagingLibraryMutation = useMutation({
    mutationFn: (payload) => {
      return updateCustomPackagingStatus(payload);
    }
  });

  const isSubmittingLibrary = usePackagingLibraryMutation.isLoading;
  const deleteMailingBagMutation = useMutation({
    mutationFn: (mailingBagId) => putArchiveCustomPackaging(mailingBagId)
  });

  const handleTogglePackagingStatus = async (event) => {
    try {
      await usePackagingLibraryMutation.mutateAsync({
        type: CUSTOM_LIBRARY_TYPE[event.target.checked],
        id: library.id
      });
      !event.target.checked ? setIsEnabled.on() : setIsEnabled.off();
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteLibrary = async (id) => {
    const messagesVariable = {
      type: managedType === PACKAGING_TYPES.box ? 'box' : 'bag'
    };
    try {
      await deleteMailingBagMutation.mutateAsync(id);
      setToast({
        description: applyVariableToMessage(messagesVariable, DELETE_CUSTOM_BOX.success)
      });
      refetch();
    } catch (error) {
      setToast({
        description: applyVariableToMessage(messagesVariable, DELETE_CUSTOM_BOX.fail),
        status: 'warning',
        colorScheme: 'negative'
      });
    }
  };

  const setValueForManagedType = (methods, value) => {
    if (methods) {
      const { friendlyName: name, id, ...rest } = value;
      methods.setValue('name', name);
      routerQuery.set('id', id);
      history.push({ search: routerQuery.toString() });
      Object.entries({ ...rest, id }).forEach(([field, value]) => {
        methods.setValue(field, value);
      });
    }
  };

  const editPackagingLiBrary = () => {
    preferenceDispatch({
      type: PREFERENCES_ACTIONS.SET_MODAL_LAYOUT,
      payload: {
        modalLayout: USER_SETTING_MODAL_LAYOUT.customBoxAndMailing.key
      }
    });

    if (managedType === PACKAGING_TYPES.box) {
      setValueForManagedType(methodsBox, library);
    }

    if (managedType === PACKAGING_TYPES.mailingBag) {
      setValueForManagedType(methodsMailingBag, library);
    }
  };

  return (
    <FormControl
      isDisabled={!isEnabled}
      background={wrapperStatusStyle[isEnabled]}
      {...wrapperStyle}
      sx={!isEnabled && wrapperStyle.disabled}>
      <HStack {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.header.wrapper}>
        <Box>
          <Text {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.header.name}>{library.friendlyName}</Text>
          <HStack spacing={0}>
            <SlzButton
              onClick={editPackagingLiBrary}
              isDisabled={isEnabled}
              {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.header.buttons.edit}>
              {CUSTOM_LIBRARY_TEXT.card.buttons.edit}
            </SlzButton>
            <Flex pos="relative" w="full">
              <SlzPopoverConfirm
                title={applyVariableToMessage(
                  messagesVariable,
                  DELETE_CUSTOM_MAILING_BAG_CONFIRMATION?.title
                )}
                description={applyVariableToMessage(
                  messagesVariable,
                  managedType === PACKAGING_TYPES.box
                    ? DELETE_CUSTOM_MAILING_BAG_CONFIRMATION?.boxDescription
                    : DELETE_CUSTOM_MAILING_BAG_CONFIRMATION?.description
                )}
                okText={DELETE_CUSTOM_MAILING_BAG_CONFIRMATION?.okText}
                isOpen={isOpen}
                onClose={onClose}
                onCancel={onClose}
                onOk={() => handleDeleteLibrary(library.id)}>
                <Button
                  {...CUSTOM_LIBRARY_STYLE.LIBRARY_CARD.header.buttons.delete}
                  onClick={onOpen}>
                  {CUSTOM_LIBRARY_TEXT.card.buttons.delete}
                </Button>
              </SlzPopoverConfirm>
            </Flex>
          </HStack>
        </Box>
        <SlzToggle
          onChange={handleTogglePackagingStatus}
          isDisabled={isSubmittingLibrary}
          isChecked={isEnabled}
          defaultChecked={library.enabled}
        />
      </HStack>
      <Dimensions
        height={library.height}
        width={library.width}
        depth={library.depth}
        type={managedType}
      />
      <MaxWeight maxWeight={library.maxWeight} />
    </FormControl>
  );
};

export default LibraryCard;
