import { createContext, useContext, useReducer } from 'react';
import { INITIAL_ORDER_STATE } from '../constants';
import { orderReducer } from '../reducers';

export const OrderContext = createContext(null);

export const OrderProvider = ({ children }) => {
  const [order, dispatch] = useReducer(orderReducer, INITIAL_ORDER_STATE);
  return (
    <OrderContext.Provider value={{ order, orderDispatch: dispatch }}>
      {children}
    </OrderContext.Provider>
  );
};

export const useOrderContext = () => useContext(OrderContext);
