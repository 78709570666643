import { Box, Text } from '@chakra-ui/react';
import { TbAlertCircle, TbBox, TbBoxMultiple, TbForklift, TbWeight } from 'react-icons/tb';

import CommonEndpoints from 'components/common/Endpoints';
import {
  BoxSplitIcon,
  ConsignmentsIcon,
  FragileIcon,
  PackagingIcon,
  SelazarLogo,
  SendConsignmentIcon,
  TruckIcon
} from 'components/common/Icons';
import B2BIcon from 'components/common/Icons/B2BIcon';
import {
  B2BOverviewStep,
  B2BOderDetailsForm,
  B2BOrderOptionList,
  B2BBoxSplit,
  B2BShippingForm,
  B2BProductSelectionForm,
  B2BOrderSummaryStep
} from 'modules/b2b-orders/components/form';
import { calculateQuantitySelectedProducts } from 'modules/products/utils';
import { SlzTag } from 'components/common/SlzTag';
import { CreateB2BOrderStep } from '../components/modal/CreateB2BOrderModal';
import CreateB2BOrderModalLayout from '../components/modal/CreateB2BOrderModalLayout';
import ModifyRecipientLayout from '../components/modal/ModifyRecipientLayout';
import SlzLink from 'components/common/SlzLink/SlzLink';
import { SlzAttentionCard } from 'components/common/SlzAttentionCard';
import { B2B_BOX_SPLIT_STYLE } from './styles';

export const B2B_ORDER_PATHNAME = '/orders/b2b';
export const BULK_ORDER_TAB = {
  pending: {
    key: 'pending',
    label: 'Pending'
  },
  inprocess: {
    key: 'inprocess',
    label: 'In process'
  },
  processed: {
    key: 'processed',
    label: 'Processed'
  },
  unsuccessful: {
    key: 'unsuccessful',
    label: 'Unsuccessful'
  }
};

export const BULK_ORDER_ENDPOINT_MAPPING = {
  pending: CommonEndpoints.RETAILER.GET.BULK_ORDER_PENDING,
  inprocess: CommonEndpoints.RETAILER.GET.BULK_ORDER_PROCESSING,
  processed: CommonEndpoints.RETAILER.GET.BULK_ORDER_PROCESSED,
  unsuccessful: CommonEndpoints.RETAILER.GET.BULK_ORDER_UNSUCCESSFUL
};

export const BULK_ORDER_FILTER_OPTIONS = [
  { id: 1, key: 'Order received', text: 'Order received', bg: '#579BFC' },
  { id: 2, key: 'Processing', text: 'Processing', bg: '#FFAD3B' },
  {
    id: 3,
    key: 'Fulfilment In Progress',
    text: 'Fulfilment In Progress',
    bg: '#21C474'
  },
  {
    id: 4,
    key: 'Awaiting Fulfilment',
    text: 'Awaiting Fulfilment',
    bg: '#A25DDC'
  },
  { id: 5, key: 'Order shipped', text: 'Order shipped', bg: '#3CD1CA' },
  {
    id: 6,
    key: 'Awaiting Collection',
    text: 'Awaiting Collection',
    bg: '#FD8458'
  },
  {
    id: 7,
    key: 'Shipped',
    text: 'Shipped',
    bg: '#117F4C'
  },
  {
    id: 8,
    key: 'Cancelled',
    text: 'Cancelled',
    bg: '#FC506B'
  },
  {
    id: 9,
    key: 'Unprocessed',
    text: 'Unprocessed',
    bg: '#4BA6EF'
  }
];
export const BULK_ORDER_TYPE = 'order';
export const BREAD_CRUMB_ITEMS = [
  {
    path: null,
    title: 'Orders'
  },
  {
    path: null,
    title: 'B2B Orders'
  }
];
export const BULK_ORDER_SKELETON_CELL = {
  id: null,
  purchaseOrderNumber: null,
  totalOrderItems: null,
  createdDate: null,
  trackingCode: null,
  status: null,
  requiresShipping: null
};

export const CREATE_B2B_ORDER_MODAL_LAYOUT = {
  default: {
    key: 'default',
    component: CreateB2BOrderModalLayout
  },
  editRecipientDetails: {
    key: 'editRecipientDetails',
    component: ModifyRecipientLayout
  }
};

export const B2B_MODAL_LAYOUT_KEYS = {
  DEFAULT: 'default',
  EDIT_RECIPIENT_DETAILS: 'editRecipientDetails'
};

export const INITIAL_BULK_ORDER_STATE = {
  list: [],
  params: {},
  filters: BULK_ORDER_FILTER_OPTIONS,
  modalLayout: CREATE_B2B_ORDER_MODAL_LAYOUT.default.key,
  recipient: null
};

export const ORDER_PROCESSING_STATUS = [
  { key: 1, value: 'Received', label: 'Order received', color: '#579BFC' },
  { key: 2, value: 'Processing', label: 'Processing', color: '#FFAD3B' },
  { key: 3, value: 'Fulfilment In Progress', label: 'Fulfilment in progress', color: '#23D57E' },
  { key: 4, value: 'Awaiting Fulfilment', label: 'Awaiting fulfilment', color: '#FD8458' },
  { key: 5, value: 'Shipped', label: 'Order shipped', color: '#3CD1CA' }
];

export const DEFAULT_GENERAL_TRACKING = [
  {
    trackingNumber: '',
    courierProvider: ''
  }
];

export const BULK_ORDER_OVERVIEW_STEP_INDEX = 0;

export const BULK_ORDER_ORDER_OPTIONS_STEP_INDEX = 2;
export const BULK_ORDER_SUMARY_STEP_INDEX = 1;

export const BULK_ORDER_STEP = {
  step3: 'productSelection',
  step4: 'boxSplit',
  step6: 'summary'
};

export const BULK_ORDER_STEPS = [
  {
    key: 0,
    label: 'Overview',
    component: (props) => <B2BOverviewStep {...props} />
  },
  {
    key: 1,
    label: 'Order details',
    component: (props) => <B2BOderDetailsForm {...props} />
  },
  {
    key: 2,
    label: 'B2B Order Options',
    component: (props) => <B2BOrderOptionList {...props} />
  },
  {
    key: 3,
    label: 'Product Selection',
    component: (props) => <B2BProductSelectionForm step={BULK_ORDER_STEP.step3} {...props} />
  },
  {
    key: 4,
    label: 'Box Split',
    component: (props) => <B2BBoxSplit step={BULK_ORDER_STEP.step4} {...props} />
  },
  {
    key: 5,
    label: 'Shipping',
    component: (props) => <B2BShippingForm {...props} />
  },
  {
    key: 6,
    label: 'Confirmation',
    component: (props) => <B2BOrderSummaryStep step={BULK_ORDER_STEP.step6} {...props} />
  }
];

export const BULK_ORDER_EXPLANATIONS = [
  {
    label: 'B2B',
    icon: <B2BIcon w="5.938rem" h="5.938rem" color="main.500" />,
    description:
      'Ship your products in larger quantities to other businesses using our B2B Order Wizard'
  },
  {
    label: 'Box Splits',
    icon: <PackagingIcon w="5.938rem" h="5.938rem" color="main.500" />,
    description: 'Customise your product-to-box configuration to match your buyer’s specific needs'
  },
  {
    label: 'Shipping',
    icon: <TbForklift size="7.375rem" />,
    description:
      'Options that suit both standard and specialised shipping requirements for B2B orders'
  }
];

export const BULK_ORDER_CREATE_TEXT = {
  COMMON: {
    note: 'Note'
  },
  OVERVIEW: {
    email: 'customer.service@selazar.com',
    telephone: '+44 (0)20 3950 7860',
    contactText: 'If you need any help or have specific requirements please give our friendly ',
    customerServiceTeam: 'Customer Service team',
    callAt: ' a call at ',
    note: 'Note',
    noteContent:
      'Please note that we do not accept ad-hoc, unscheduled collections from the warehouse.'
  },
  ORDERDETAILS: {
    header: 'Order details',
    title: 'Recipient details',
    noteContent: 'The following information is regarding the recipient of the B2B order.'
  },
  BOX_SPLIT: {
    boxSplitDetails: 'Box Split Details'
  },
  SHIPPING: {
    header: 'Shipping',
    email: 'customer.service@selazar.com',
    telephoneAndText: 'on +44 (0) 20 3950 7860 to arrange',
    contactText: 'Please contact',
    note: 'collection of this B2B order.',
    customerServiceTeam: 'Customer Services',
    noteContent: 'Your order will be palletised for collection.'
  },
  SUMMARY: {
    title: 'Confirm B2B Order',
    orderDetail: 'Order details',
    b2bOrderOptions: 'B2B order options',
    b2bOrderSummary: 'B2B order summary',
    boxSplitSummary: 'Box split summary',
    shipping: 'Shipping'
  },
  SUMMITTED: {
    orderSubmitted: 'Order submitted',
    needToCancel: 'Need to cancel?',
    needToCancelContent:
      'You can cancel your B2B order if it is in the ‘Pending’ stage, awaiting fulfilment from our warehouse staff. We have no way to estimate how long this will be.'
  }
};

export const B2B_BOX_SPLIT_TEXT = {
  disabledBoxSplit: {
    title: 'Box Split Summary',
    attentionCard: {
      description: (
        <>
          You have not enabled custom box split or separate SKUs per box.
          <br />
          Box allocation will be automatically generated.
        </>
      ),
      icon: null
    }
  },
  summary: {
    attentionCard: {
      description: (
        <>
          You have enabled separate SKUs per box.
          <br />
          Box allocation is automatically generated.
        </>
      ),
      icon: null
    }
  }
};

export const CUSTOM_BOX_STEP = {
  setup: 'setup',
  summary: 'customBoxSummary'
};

export const B2B_PRODUCT_TRACKING = {
  productSelection: {
    title: 'Product selection',
    description: (
      <Text fontSize="0.875rem" mt="0 !important" lineHeight="1.188rem">
        There is a maximum of 3000 items and/or 50 different SKUs
      </Text>
    )
  },
  [CUSTOM_BOX_STEP.setup]: {
    title: 'Box Split - Step 1 of 2',
    description: (
      <Text fontSize="0.875rem" mt="0.522rem !important" lineHeight="1.188rem">
        <SlzTag mr="0.641rem">Note</SlzTag>
        Add product quantities to create box splits (add up to 50 boxes)
      </Text>
    )
  },
  [CUSTOM_BOX_STEP.summary]: {
    title: 'Box Split - Step 2 of 2'
  },
  boxSplitSummary: {
    title: 'Box Split Summary',
    description: (
      <SlzAttentionCard
        sx={{
          w: '30.625rem',
          mt: 3.5
        }}
        {...B2B_BOX_SPLIT_TEXT.summary.attentionCard}
        {...B2B_BOX_SPLIT_STYLE.attentionCard}
      />
    )
  },
  summary: {
    title: 'Confirm B2B Order'
  }
};

export const B2B_PRODUCT = {
  ITEM_PER_PAGES: 15,
  PAGE_DEFAULT: 1,
  PAGES_OPTIONS_DEFAULT: [10, 15, 20]
};

export const quantityOptions = [
  { value: 'remove', label: 'Remove' },
  { value: 1, label: 1 },
  { value: 5, label: 5 },
  {
    value: 10,
    label: 10
  },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 }
];

export const boxSplitQuantityOptions = [{ value: 0, label: 0 }, ...quantityOptions.slice(1)];

export const B2B_SELECTED_PRODUCT_MESSAGE = {
  NOT_ENOUGH_STOCK:
    'Sorry, your current stock levels for this product are insufficient. To complete this order please send more stock. Your stock must be received before your inventory levels are updated.'
};

export const B2B_SELECTED_PRODUCT_ITEMS = {
  MAXIMUM_ITEMS: 3000,
  OVER_ITEMS_MESSAGE:
    'There is a maximum of 3000 items per bulk order. Please reduce this number or split the order into multiple B2B orders.'
};

export const CONFIRM_LEAVE_TOAST_ID = 'confirmLeaveToastId';

export const LEAVE_WARNING_TEXT =
  'Oops! Are you sure you want to leave without saving the changes?';

export const SUCCESS_TOAST_TEXT = `Your B2B order was created successfully! This may take up to 15 minutes to display in pending orders.`;

export const STYLE_WARNING_TOAST = {
  colorScheme: 'sunshine',
  status: 'warning',
  variant: 'solid',
  iconStyle: {
    color: 'dark.700'
  },
  closeButtonStyle: {
    color: 'dark.700'
  }
};

export const POPOVER_CONFIRMATION_QUANTITY = {
  title: 'Quantity',
  description: 'You can type or select a number from the dropdown'
};

export const ORDER_OPTIONS_KEY = {
  requireShipping: 'requireShipping',
  requirePackaging: 'requirePackaging',
  isBoxSplitOrSeparate: 'isBoxSplitOrSeparate',
  isFragile: 'isFragile',
  requireSeperateSKUs: 'requireSeperateSKUs',
  isBoxSplit: 'isBoxSplit',
  packagingPreference: 'packagingPreference'
};

export const ORDER_OPTIONS_TOGGLE_KEY = {
  isBoxSplitEnabled: 'isBoxSplitEnabled'
};

export const DISABLE_CONDITION = {
  [ORDER_OPTIONS_KEY.requireShipping]: () => false,
  [ORDER_OPTIONS_KEY.requirePackaging]: (watch) =>
    watch(ORDER_OPTIONS_KEY.requireShipping) === true,
  [ORDER_OPTIONS_KEY.isBoxSplitOrSeparate]: (watch) =>
    watch(ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled) === false,
  [ORDER_OPTIONS_KEY.isFragile]: () => false,
  [ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled]: (watch) =>
    watch(ORDER_OPTIONS_KEY.requirePackaging) === false &&
    watch(ORDER_OPTIONS_KEY.requireShipping) === false
};

export const ORDER_OPTIONS_CONTENT = [
  {
    key: ORDER_OPTIONS_KEY.requireShipping,
    title: {
      text: 'How do you want your B2B order shipped?',
      icon: <TruckIcon w="0.625rem" h="0.625rem" color="lightAndShadow.darkModeText" />
    },
    tooltip: {
      show: true,
      content: ''
    },
    toggle: {
      show: false
    },
    tag: {
      show: false
    },
    content: [
      {
        text: "Selazar's Courier Network",
        icon: (
          <SelazarLogo
            w="1.25rem"
            h="1.25rem"
            mb="0.188rem"
            showName={false}
            color="currentColor"
          />
        ),
        value: true
      },
      {
        text: 'Self-fulfilled',
        icon: <SendConsignmentIcon w="1.25rem" h="1.25rem" color="currentColor" />,
        value: false
      }
    ]
  },
  {
    key: ORDER_OPTIONS_KEY.requirePackaging,
    title: {
      text: 'How do you want your self-fulfilled order packed?',
      icon: <SendConsignmentIcon w="0.625rem" h="0.625rem" color="lightAndShadow.darkModeText" />
    },
    tooltip: {
      show: false,
      content: ''
    },
    toggle: {
      show: false
    },
    tag: {
      show: false
    },
    content: [
      {
        text: 'In boxes and palletised',
        icon: <ConsignmentsIcon w="1.25rem" h="1.25rem" mb="0.188rem" color="currentColor" />,
        value: true
      },
      {
        text: 'Loose and shrink wrapped on pallet',
        icon: <TbForklift size="1.582rem" color="currentColor" />,
        value: false
      }
    ]
  },
  {
    key: ORDER_OPTIONS_KEY.isBoxSplitOrSeparate,
    title: {
      text: 'Do you want to use box split?',
      icon: <TbBox size="0.625rem" color="white" />
    },
    tooltip: {
      show: false,
      content: ''
    },
    toggle: {
      show: true,
      key: ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled
    },
    tag: {
      show: true,
      content: {
        icon: <TbAlertCircle size="0.75rem" />,
        text: 'What is box split'
      }
    },
    content: [
      {
        text: 'Custom Box Split',
        icon: <BoxSplitIcon w="1.25rem" h="1.25rem" color="currentColor" />,
        value: true
      },
      {
        text: 'Separate SKUs per Box',
        icon: <TbBoxMultiple size="1.25rem" />,
        value: false
      }
    ]
  },
  {
    key: ORDER_OPTIONS_KEY.isFragile,
    title: {
      text: 'Does you B2B Order contain any fragile items?',
      icon: <FragileIcon w="0.625rem" h="0.625rem" color="lightAndShadow.darkModeText" />
    },
    tooltip: {
      show: false,
      content: ''
    },
    toggle: {
      show: false
    },
    tag: {
      show: false
    },
    content: [
      {
        text: 'Fragile items',
        icon: <FragileIcon w="1.25rem" h="1.25rem" color="currentColor" />,
        value: true
      },
      {
        text: 'No Fragile items',
        icon: <TbWeight size="1.25rem" />,
        value: false
      }
    ]
  }
];

export const B2B_ORDER_DEFAULT_VALUES = {
  serviceTimePreference: 'noPreference',
  purchaseOrderNumber: '',
  contactDetails: {
    emailAddress: '',
    contactNumber: '',
    name: '',
    companyName: '',
    ignore_whitespace: false
  },
  destinationAddress: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    county: '',
    country: '',
    postCode: ''
  },
  preferences: {
    [ORDER_OPTIONS_KEY.requireShipping]: true,
    [ORDER_OPTIONS_KEY.requirePackaging]: false,
    [ORDER_OPTIONS_KEY.isFragile]: false,
    [ORDER_OPTIONS_KEY.packagingPreference]: 'PAPER',
    [ORDER_OPTIONS_KEY.requireSeperateSKUs]: false,
    [ORDER_OPTIONS_KEY.isBoxSplit]: false
  },
  shippingService: {
    id: '',
    name: '',
    service: '',
    cost: '',
    selected: false
  },
  selected: false,
  showError: false,
  shippingResult: {},
  noCourierError: false,
  showDifferentServiceMessage: false,
  originalServiceTimePreference: ''
};

export const B2B_SELF_FULFILLED_TOOLTIP_CONTENT = {
  title: 'Self-fulfilled shipping',
  contents: [
    `Self-fulfilled B2B orders are palletised. You can choose to have these items packed in boxes or loose and shrink wrapped on the pallet.`,
    `We can help you with the shipping of these or you can arrange the pickup yourself but, in each case, you need to contact Customer Services on +44 (0) 20 3950 7860 to arrange this.`,
    `Please be aware that we do not accept ad-hoc, unscheduled collections from the warehouse.`
  ],
  totalPage: 3,
  isPagination: true
};

export const B2B_BOX_SPLIT_TOOLTIP_CONTENT = {
  title: 'Box Split',
  contents: [
    `Custom box split allows you to specify the products and the quantity that go into each box. You will configure your box split at stage 4 in the process.`,
    `Separate SKUs per box ensures that each box will contain one SKU. No SKUs will be mixed in the boxes.`,
    `If you do not enable box split, Selazar will calculate the best product-to-box configuration for your B2B order.`
  ],
  totalPage: 3,
  isPagination: true
};

export const B2B_COURIER_OPTIONS_TOOLTIP_CONTENT = {
  title: 'Courier Options',
  contents: [
    `Enabling 'Override default courier preferences' will override your default courier preferences for this B2B order only`,
    `If we cannot find an option for the service selection you have made we will return the quickest option we have`
  ],
  totalPage: 2,
  isPagination: true
};

export const B2B_BOX_SPLIT_OPTION = {
  key: ORDER_OPTIONS_KEY.isBoxSplitOrSeparate,
  toggleKey: ORDER_OPTIONS_TOGGLE_KEY.isBoxSplitEnabled,
  title: {
    text: 'You have not enabled box split',
    icon: <TbBox size="0.625rem" color="white" />
  },
  modify: {
    show: true,
    text: 'Modify selection'
  },
  content: [
    {
      text: 'Custom Box Split',
      icon: <BoxSplitIcon w="1.25rem" h="1.25rem" color="currentColor" />,
      value: true
    },
    {
      text: 'Separate SKUs per Box',
      icon: <TbBoxMultiple size="1.25rem" />,
      value: false
    }
  ]
};

export const B2B_SHIPPING_OPTION = {
  key: ORDER_OPTIONS_KEY.requireShipping,
  title: {
    text: 'Self-fulfilled shipping enabled',
    icon: <TruckIcon w="0.625rem" h="0.625rem" color="lightAndShadow.darkModeText" />
  },
  tooltip: {
    show: false,
    content: ''
  },
  toggle: {
    show: false
  },
  modify: {
    show: true,
    text: 'Modify selection'
  },
  tag: {
    show: false
  },
  content: [
    {
      text: "Selazar's Courier Network",
      icon: (
        <SelazarLogo w="1.25rem" h="1.25rem" mb="0.188rem" showName={false} color="currentColor" />
      ),
      value: true
    },
    {
      text: 'Self-fulfilled',
      icon: <SendConsignmentIcon w="1.25rem" h="1.25rem" color="currentColor" />,
      value: false
    }
  ]
};

export const COURIER_OPTIONS_WARNING_BOX_TEXT = {
  attentionCard: {
    borderRadius: 1.5,
    my: 2,
    bg: 'sunshine.300',
    icon: null
  }
};

export const B2B_SHOW_ERROR_SERVICE_WARNING_TEXT = {
  title: 'Show Different Service Message',
  attentionCard: {
    description: (
      <>
        You have not enabled custom box split or separate SKUs per box.
        <br />
        Box allocation will be automatically generated.
      </>
    ),
    ...COURIER_OPTIONS_WARNING_BOX_TEXT.attentionCard
  }
};

export const B2B_SHOW_ERROR_WARNING_TEXT = {
  title: 'Show Error Api',
  attentionCard: {
    description: <>There was a problem retrieving couriers. Please try again.</>,
    ...COURIER_OPTIONS_WARNING_BOX_TEXT.attentionCard
  }
};

export const B2B_SHOW_DIFFERENT_WARNING_TEXT = {
  title: 'Show Different Service Message',
  attentionCard: {
    description: (
      <>
        You have not enabled custom box split or separate SKUs per box.
        <br />
        Box allocation will be automatically generated.
      </>
    ),
    w: 'full',
    ...COURIER_OPTIONS_WARNING_BOX_TEXT.attentionCard
  }
};

export const B2B_NO_COURIER_ERROR_WARNING_TEXT = {
  title: 'No Courier Error',
  attentionCard: {
    description: (
      <>
        Sorry, we could not find a suitable courier option. You can return to previous steps and
        alter your B2B order, or contact our{' '}
        <SlzLink
          variant="underline"
          color="main.500"
          href={`mailto:${BULK_ORDER_CREATE_TEXT.SHIPPING.email}`}>
          Customer Service
        </SlzLink>{' '}
        team on +44 (0) 20 3950 7860 for alternative options.
      </>
    ),
    ...COURIER_OPTIONS_WARNING_BOX_TEXT.attentionCard
  }
};

export const B2B_SHIPPING_ATTENTION_TEXT = {
  attentionCard: {
    w: '30.688rem',
    h: '6rem',
    borderRadius: 1.5,
    mb: '12.5rem',
    mt: 6,
    icon: null,
    status: 'success',
    description: (
      <Text fontSize={'md'}>
        <Box as="span">{BULK_ORDER_CREATE_TEXT.SHIPPING.contactText} </Box>
        <SlzLink
          variant="underline"
          color="main.500"
          href={`mailto:${BULK_ORDER_CREATE_TEXT.SHIPPING.email}`}>
          {BULK_ORDER_CREATE_TEXT.SHIPPING.customerServiceTeam}
        </SlzLink>
        <Box as="span"> {BULK_ORDER_CREATE_TEXT.SHIPPING.telephoneAndText} </Box>
        <Box as="span">
          {BULK_ORDER_CREATE_TEXT.SHIPPING.note}
          <br />
          {BULK_ORDER_CREATE_TEXT.SHIPPING.noteContent}
        </Box>
      </Text>
    )
  }
};

export const B2B_ORDER_OPTION_STATUS = {
  shipping: {
    selfFulfilled: {
      text: 'Self-fulfilled',
      variant: 'outline',
      colorScheme: 'main'
    },
    selazarFulfilled: {
      text: 'Selazar fulfilled',
      colorScheme: 'szrDenim'
    }
  },
  packing: {
    boxes: {
      text: 'Boxes & palletised',
      colorScheme: 'grunge'
    },
    loose: {
      text: 'Loose & palletised',
      colorScheme: 'mauve'
    }
  },
  boxSplit: {
    none: {
      text: 'N/A',
      colorScheme: 'dark'
    },
    customeBoxSplit: {
      text: 'Custom box split',
      colorScheme: 'tetra'
    },
    separateSKU: {
      text: 'Separate by SKU',
      colorScheme: 'marine'
    }
  },
  fragileItem: {
    notFragile: {
      text: 'Not fragile',
      colorScheme: 'coral'
    },
    fragile: {
      text: 'Fragile',
      colorScheme: 'negative'
    }
  }
};

export const B2B_PRODUCT_TRACKING_TAGS = {
  productSelection: [
    {
      label: 'SKUs',
      tag: (selectedProducts) => selectedProducts?.length || 0
    },
    {
      label: 'Items',
      tag: (selectedProducts) => calculateQuantitySelectedProducts(selectedProducts)
    }
  ],
  [CUSTOM_BOX_STEP.setup]: [
    {
      label: 'SKUs',
      tag: (selectedProducts) => selectedProducts?.length || 0
    },
    {
      label: 'Items',
      tag: (selectedProducts) => calculateQuantitySelectedProducts(selectedProducts)
    },
    {
      label: 'Boxes',
      tag: (_, boxesLength) => boxesLength || 0
    }
  ],
  [CUSTOM_BOX_STEP.summary]: [
    {
      label: 'SKUs',
      tag: (selectedProducts) => selectedProducts?.length || 0
    },
    {
      label: 'Items',
      tag: (selectedProducts) => calculateQuantitySelectedProducts(selectedProducts)
    },
    {
      label: 'Boxes',
      tag: (_, boxesLength) => boxesLength || 0
    }
  ],
  boxSplitSummary: [
    {
      label: 'SKUs',
      tag: (selectedProducts) => selectedProducts?.length || 0
    },
    {
      label: 'Items',
      tag: (selectedProducts) => calculateQuantitySelectedProducts(selectedProducts)
    },
    {
      label: 'Boxes',
      tag: (_, boxesLength) => boxesLength || 0
    }
  ],
  summary: [
    {
      label: 'SKUs',
      tag: (selectedProducts) => selectedProducts?.length || 0
    },
    {
      label: 'Items',
      tag: (selectedProducts) => calculateQuantitySelectedProducts(selectedProducts)
    },
    {
      label: 'Boxes',
      tag: (selectedProducts) => selectedProducts?.length || 0
    }
  ]
};

export const SELECT_RECIPIENTS = {
  PLACEHOLDER: 'Select from address book…',
  NO_OPTIONS_PLACEHOLDER: 'No recipients in address book…'
};

export const B2B_CUSTOM_BOX_SPLIT_CONTENT = {
  selectedTable: {
    displayFields: ['image', 'name', 'totalStock'],
    emptyMsg: 'No products selected'
  },
  accordionTable: {
    displayFields: ['name', 'quantity']
  },
  buttons: {
    add: 'Add Box',
    delete: 'Delete'
  }
};

export const BOX_SPLIT = 'boxSplits';
export const CUSTOM_BOX_PREFIX = 'customBox';
export const CUSTOM_BOX_ERROR_FLAG = {
  itemError: 'itemError',
  boxError: 'boxError'
};

export const ASSIGNED_FIELD_STATUS = {
  matched: 'matched',
  unmatched: 'unmatched',
  unknown: ''
};

export const BOX_SPLIT_ERROR_TYPE = {
  maximumLimitOfBoxes: 'maximumLimitOfBoxes',
  maximumItemsInBox: 'maximumItemsInBox',
  remainingItems: 'remainingItems',
  emptyBox: 'emptyBox',
  tooManyItems: 'tooManyItems',
  generalValidationError: 'generalValidationError'
};

export const B2B_CUSTOM_BOX_SPLIT_TOAST = {
  error: {
    status: 'warning',
    colorScheme: 'negative',
    [BOX_SPLIT_ERROR_TYPE.maximumLimitOfBoxes]:
      'There is a maximum limit of 50 boxes. Please reduce this number or split the order into multiple B2B orders.',
    [BOX_SPLIT_ERROR_TYPE.maximumItemsInBox]:
      'There are too many items in some boxes. You can add more boxes and reduce the number of items in each box.',
    [BOX_SPLIT_ERROR_TYPE.remainingItems]: 'There are remaining items that need assigned',
    [BOX_SPLIT_ERROR_TYPE.emptyBox]: 'There are boxes without assigned items',
    [BOX_SPLIT_ERROR_TYPE.tooManyItems]: 'There are too many items assigned.',
    [BOX_SPLIT_ERROR_TYPE.generalValidationError]:
      'Sorry, something went wrong. Please try again in a minute.'
  }
};

export const B2B_CUSTOM_BOX_SPLIT_TAG = {
  success: 'Success',
  'error.empty': 'No items in box',
  'error.tooManyItems': 'Too many items in box'
};

export const VALIDATE_STATUS = {
  validating: 'validating',
  summary: 'summary',
  done: 'done'
};

export const ACCORDION_TABLE_CONTENT = {
  title: 'Box',
  accordionToggle: 'Select Quantity'
};

export const MODIFY_RECIPIENT_HEADER = {
  ADD: 'Add new recipient',
  EDIT: 'Edit recipient details'
};

export const SERVICE_TIMES_LABELS = {
  noPreference: 'No preference, cheapest option',
  TwentyFourPreTenThirty: '24 hours (pre-10:30)',
  TwentyFourPreNoon: '24 hours (pre-noon)',
  TwentyFour: '24 hours',
  OneToTwo: '1-2 days',
  FortyEight: '48 hours',
  TwoToThree: '2-3 days',
  SeventyTwo: '72 hours',
  ThreeToFive: '3-5 days',
  FiveToTen: '5-10 days'
};

export const CUSTOM_BOX_CHECKING_FLAGS = {
  isManualValidateSuccess: 'isManualValidateSuccess',
  validateFlag: 'validateFlag'
};

export const B2B_ORDER_ACTION_CONTENT = {
  start: 'Start',
  next: 'Next',
  check: 'Check',
  cancel: 'Cancel',
  previous: 'Previous'
};
