import dayjs from 'dayjs';
import {
  DATE_FORMAT_DDMMYYYY_HH_MM_SS,
  DATE_FORMAT_DDMMYYYY,
  DATE_FORMAT_DDMMYY
} from '../../../constants';
import { BILLING_PROCESS, INVOICE_TYPE } from '../constants';

export const transformBilling = ({ billing, process, wareHouses }) => {
  const data = {
    totalPage: billing?.pageCount
  };
  if (process === BILLING_PROCESS.COLLECTION_ADVICE) {
    return transformCollection({ data, billing });
  }

  if (process === BILLING_PROCESS.REMITTANCE_ADVICE) {
    return transformRemittance({ data, billing });
  }

  if (process === BILLING_PROCESS.INVOICE) {
    return transformInvoice({ data, billing, wareHouses });
  }

  data.items = []; //TODO
  return data;
};

const transformRemittance = ({ data, billing }) => ({
  ...data,
  items: billing?.remittanceAdvices?.map((remittance) => ({
    id: remittance.id,
    raNumber: remittance?.remittanceAdviceNumber,
    issueDate: dayjs(remittance?.remittanceDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS),
    period: [
      remittance?.collectionAdvice?.collectionAdviceContents?.collectionFrom
        ? dayjs(remittance?.collectionAdvice?.collectionAdviceContents?.collectionFrom).format(
            DATE_FORMAT_DDMMYY
          )
        : '',
      remittance?.collectionAdvice?.collectionAdviceContents?.collectionTo
        ? dayjs(remittance?.collectionAdvice?.collectionAdviceContents?.collectionTo).format(
            DATE_FORMAT_DDMMYY
          )
        : ''
    ],
    total: remittance?.collectionAdvice?.formattedTotal || 0,
    currency: remittance?.collectionAdvice?.currencyAbbreviation?.slice(0, 2) // get first 2 codes
  }))
});

const transformCollection = ({ data, billing }) => ({
  ...data,
  items: billing?.collectionAdvices?.map((collection) => ({
    id: collection.id,
    caNumber: collection?.collectionAdviceNumber,
    issueDate: dayjs(collection?.collectionDate).format(DATE_FORMAT_DDMMYYYY_HH_MM_SS),
    period: [
      collection?.collectionAdviceContents?.collectionFrom
        ? dayjs(collection?.collectionAdviceContents?.collectionFrom).format(DATE_FORMAT_DDMMYY)
        : '',
      collection?.collectionAdviceContents?.collectionTo
        ? dayjs(collection?.collectionAdviceContents?.collectionTo).format(DATE_FORMAT_DDMMYY)
        : ''
    ],
    total: collection?.formattedTotal || 0,
    currency: collection?.currencyAbbreviation?.slice(0, 2)
  }))
});

const transformInvoice = ({ data, billing, wareHouses }) => ({
  ...data,
  items: billing?.invoices?.map((invoice) => ({
    id: invoice.id,
    invoiceNumber: invoice?.invoiceReferenceNumber,
    issueDate: dayjs(invoice?.invoiceDate).format(DATE_FORMAT_DDMMYYYY),
    wareHouse: wareHouses ? wareHouses.find((x) => x.id == invoice?.warehouseID)?.name : '',
    type: {
      key: invoice?.invoiceType,
      name: INVOICE_TYPE[invoice?.invoiceType]?.name,
      color: INVOICE_TYPE[invoice?.invoiceType]?.color
    },
    total: invoice?.finalTotal || 0,
    currency: invoice?.currencyAbbreviation?.slice(0, 2)
  }))
});
