import { useQuery } from 'react-query';
import { getCustomPackagingOverview } from 'modules/preferences/services/preferences-api';

const usePackagingOverviewQuery = () => {
  return useQuery({
    queryKey: [`packaging-overview`],
    queryFn: () => getCustomPackagingOverview()
  });
};
export default usePackagingOverviewQuery;
