import React from 'react';
import { Icon } from '@chakra-ui/react';

const NextDayDeliveryIcon = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" fill="currentcolor" {...props}>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Group_2324"
        data-name="Group 2324"
        transform="translate(8.485 16.971) rotate(-135)"
        clip-path="url(#clip-path)">
        <path
          id="Path_3650"
          data-name="Path 3650"
          d="M4.8,11.995a.666.666,0,0,1-.664-.657l0-.359v-.016L4.085,8a.666.666,0,0,1,.654-.677h.01a.666.666,0,0,1,.664.657L5.44,9.841A4.707,4.707,0,0,0,7.034,4.211,4.664,4.664,0,0,0,.921,1.7.666.666,0,0,1,.407.473,5.989,5.989,0,0,1,8.258,3.695a6.042,6.042,0,0,1-1.669,6.941l1.518-.022a.667.667,0,0,1,.02,1.333l-3.321.048Z"
          transform="translate(3.218 0)"
          fill="#6ebd45"
        />
        <path
          id="Path_3651"
          data-name="Path 3651"
          d="M.664,1.337A.668.668,0,0,1,.664,0a.663.663,0,0,1,.664.663V.67a.666.666,0,0,1-.664.667"
          transform="translate(1.005 2.196)"
          fill="#6ebd45"
        />
        <path
          id="Path_3652"
          data-name="Path 3652"
          d="M.664,1.337A.668.668,0,0,1,.664,0a.663.663,0,0,1,.664.663V.67a.666.666,0,0,1-.664.667"
          transform="translate(0 4.77)"
          fill="#6ebd45"
        />
        <path
          id="Path_3653"
          data-name="Path 3653"
          d="M.664,1.337A.668.668,0,0,1,.664,0a.663.663,0,0,1,.664.663V.67a.666.666,0,0,1-.664.667"
          transform="translate(0.418 7.498)"
          fill="#6ebd45"
        />
        <path
          id="Path_3654"
          data-name="Path 3654"
          d="M.664,1.337A.668.668,0,0,1,.664,0a.663.663,0,0,1,.664.663V.67a.666.666,0,0,1-.664.667"
          transform="translate(2.13 9.654)"
          fill="#6ebd45"
        />
        <path
          id="Path_3655"
          data-name="Path 3655"
          d="M.664,1.337A.668.668,0,0,1,.664,0a.663.663,0,0,1,.664.663V.67a.666.666,0,0,1-.664.667"
          transform="translate(4.695 10.663)"
          fill="#6ebd45"
        />
      </g>
    </Icon>
  );
};

export default NextDayDeliveryIcon;
