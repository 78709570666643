import { GridItem } from '@chakra-ui/react';
import { SlzGrid } from 'components/common/SlzGrid';
import { IntegrationCard } from '../common';

const MyApp = (props) => {
  const {
    cards,
    isLoading,
    isDisable,
    onClickEditCredentials,
    onOpenManageIntegration,
    onSaveIntegration,
    onClickRateMatcher
  } = props;
  return (
    <SlzGrid px={0}>
      {cards &&
        cards.map((card) => (
          <GridItem key={card?.id} gridColumn="span 4">
            <IntegrationCard
              isDisable={isDisable}
              isLoading={isLoading}
              data={card}
              onClickEditCredentials={onClickEditCredentials}
              onOpenManageIntegration={onOpenManageIntegration}
              onSaveIntegration={onSaveIntegration}
              onClickRateMatcher={onClickRateMatcher}
            />
          </GridItem>
        ))}
    </SlzGrid>
  );
};

export default MyApp;
