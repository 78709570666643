import dayjs from 'dayjs';
import CommonEndpoints from '../../../components/common/Endpoints';
import { buildQuery } from '../../../utils/helper';
import axiosClient from '../../../config/axios';
import { ORDER_STATUS } from '../constants';
import moment from 'moment';
import { extractData } from 'Consumer';

const ORDER_URLS = {
  [ORDER_STATUS.pending.key]: CommonEndpoints.RETAILER.GET.PENDING,

  [ORDER_STATUS.processing.key]: CommonEndpoints.RETAILER.GET.PROCESSING,

  [ORDER_STATUS.processed.key]: CommonEndpoints.RETAILER.GET.PROCESSED,

  [ORDER_STATUS.partial.key]: CommonEndpoints.RETAILER.GET.PARTIAL,

  [ORDER_STATUS.unsuccessful.key]: CommonEndpoints.RETAILER.GET.UNSUCCESSFUL,

  [ORDER_STATUS.onhold.key]: CommonEndpoints.RETAILER.GET.ONHOLD
};

export async function getOrders(filters) {
  const { status, filterBy, isDesc, ...orderFilter } = filters;
  const searchParams = {
    ...orderFilter,
    status: filterBy || '',
    orderDescending: isDesc || ''
  };
  const ORDER_FILTERS = {
    [ORDER_STATUS.unsuccessful.key]: { ...searchParams, maxDaysAgo: 30 }
  };

  const url = new URL(ORDER_URLS[status] || CommonEndpoints.RETAILER.GET.ALL_ORDERS);

  return await axiosClient.request(buildQuery(url, ORDER_FILTERS[status] || searchParams));
}

/* export async function getOrderDetail(orderId, status, source) {
  let url = `${CommonEndpoints.RETAILER.GET.ORDER_DETAILS}/${orderId}`;
  switch (status) {
    case ORDER_STATUS.unsuccessful.key:
      url = `${CommonEndpoints.ORDERS.GET.UNSUCCESSFUL_DETAILS}${orderId}/${source}`;
      break;
    case ORDER_STATUS.onhold.key:
      url = `${CommonEndpoints.ORDERS.GET.ON_HOLD_DETAILS}${orderId}`;
      break;
    default:
      break;
  }
  return await axiosClient.request(url);
} */

export async function getOrderDetail(payload) {
    const orderDetails = {
      id: payload.id,
      createdDate: moment(payload.createdDate, "DD MM YYYY"),
      customerOrganizationPersonName: payload.customerOrganizationPersonName,
      customerReference: payload.customerReference,
      source: payload.source.name,
      status: payload.status.name,
      trackingCode: payload.trackingCode
    };
    const url = `${CommonEndpoints.RETAILER.POST.ORDER_DETAILS_WITH_EXTERNAL}`;
  return await axiosClient.post(url, orderDetails);
}

export async function createOrder(payload) {}

export async function uploadOrderCsv(files) {
  const url = `${CommonEndpoints.ORDERS.POST.UPLOAD}`;

  let formData = new FormData();
  files.forEach((f) => {
    formData.append('files', f.file, f.name);
  });

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  return await axiosClient.post(url, formData, config);
}

export async function exportTrackingReferences({ startDate = '', endDate = '' }) {
  const url = `${CommonEndpoints.ORDERS.GET.TRACKING}?dateFrom=${startDate}&dateTo=${endDate}`;
  const res = await axiosClient.get(url);
  const blob = new Blob(['\ufeff', res.data], { type: res.headers['content-type'] });
  const link = URL.createObjectURL(blob);
  const filename = 'TrackingExport_' + dayjs().format('DDMMYYYY') + '.csv';
  return {
    link,
    filename
  };
}

export async function usingExternalWMS() {
  const url = `${CommonEndpoints.WAREHOUSE.GET.USES_EXTERNAL_WMS}`;

  try {
    const { data: response } = await axiosClient.get(url);
    const result = extractData(response);

    return result;
  } 
  catch (error) {
    console.log(error);
  }
}