import { useQuery } from 'react-query';
import { getPopularProducts } from '../services/dashboard-api';

export const usePopularProductQuery = ({ select }) => {
  return useQuery({
    queryKey: [`popular-product`],
    queryFn: getPopularProducts,
    select
  });
};
