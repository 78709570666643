import { FormLabel } from '@chakra-ui/react';
import React from 'react';

const SlzFormLabel = (props) => {
  return (
    <FormLabel fontSize="md" fontWeight="bold" mb="0" color="dark.500" {...props}>
      {props.children}
    </FormLabel>
  );
};

export default SlzFormLabel;
