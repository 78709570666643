import { Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { BILLING_PROCESS } from '../../constants';

const tableStyle = {
  'th, td': { textAlign: 'left', border: 'none', h: 'auto' },
  'tr:hover, td:hover': { bg: 'none', cursor: 'unset' },
  'tr, td': { bg: 'none' }
};

const tableBodyStyle = {
  tr: { bg: 'white' },
  td: { pl: 0, pr: 5, py: 0 },
  'tr > td:first-child': { pr: 10 }
};

const tableFooterStyle = {
  th: { bg: 'main.300', px: 5, py: 2, fontSize: '1rem' },
  _before: { content: "''", display: 'block', h: 3 }
};

function InvoiceTotalCost({ data }) {
  const { invoiceTotalCost, adminChargeCost, vatCost, adminCharge, vat, finalTotal, cardPayment } =
    data;

  return (
    <TableContainer>
      <Table sx={tableStyle}>
        <Thead sx={{ th: { bg: 'white', px: 5, pt: 0 } }}>
          <Tr>
            <Th bg="white" fontSize="1.0625rem">
              Total Cost
            </Th>
            <Th isNumeric bg="white"></Th>
          </Tr>
        </Thead>
        <Tbody sx={{ td: { px: 5, py: 0, h: 'auto' } }}>
          <Tr>
            <Td>Invoice Total</Td>
            <Td isNumeric>{invoiceTotalCost}</Td>
          </Tr>
          <Tr>
            <Td>Admin charge ({adminCharge})</Td>
            <Td isNumeric>{adminChargeCost}</Td>
          </Tr>
          <Tr>
            <Td>VAT ({vat})</Td>
            <Td isNumeric>{vatCost}</Td>
          </Tr>
        </Tbody>
        <Tfoot
          h="auto"
          sx={{
            ...tableFooterStyle,
            'tr > td': { h: 'auto', py: 0 }
          }}>
          <Tr bg="main.300" mt="5">
            <Th>Total</Th>
            <Th isNumeric>{finalTotal}</Th>
          </Tr>
          <Tr>
            <Td>Payment</Td>
          </Tr>
          <Tr>
            <Td>Amount Due</Td>
            <Td isNumeric>{cardPayment}</Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

function CollectionTotalCost({ data }) {
  const { finalTotal, number, date, paymentAttemptDate } = data;

  return (
    <TableContainer>
      <Table
        sx={{
          ...tableStyle,
          'th, td': { textAlign: 'left', border: 'none', h: '8!important' }
        }}>
        <Tbody sx={tableBodyStyle}>
          <Tr>
            <Td fontWeight="bold">Collection advice date</Td>
            <Td>{date}</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Collection advice number</Td>
            <Td sx={{ fontWeight: 'bold', color: 'main.500!important' }}>{number}</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Payment attempt date</Td>
            <Td>{paymentAttemptDate}</Td>
          </Tr>
        </Tbody>
        <Tfoot h="auto" sx={tableFooterStyle}>
          <Tr bg="main.300" mt="5">
            <Th>Total</Th>
            <Th isNumeric>{finalTotal}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

function RemittanceTotalCost({ data }) {
  const { date, number, paymentMethod, paymentReference, finalTotal } = data;

  return (
    <TableContainer>
      <Table
        sx={{
          ...tableStyle,
          'th, td': { textAlign: 'left', border: 'none', h: '8!important' }
        }}>
        <Tbody sx={tableBodyStyle}>
          <Tr>
            <Td fontWeight="bold">Remittance advice date</Td>
            <Td>{date}</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Remittance advice number</Td>
            <Td sx={{ fontWeight: 'bold', color: 'main.500!important' }}>{number}</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Payment method</Td>
            <Td>{paymentMethod}</Td>
          </Tr>
          <Tr>
            <Td fontWeight="bold">Payment reference</Td>
            <Td>{paymentReference}</Td>
          </Tr>
        </Tbody>
        <Tfoot h="auto" sx={tableFooterStyle}>
          <Tr bg="main.300" mt="5">
            <Th>Total</Th>
            <Th isNumeric>{finalTotal}</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
}

export default function TotalCost({ data }) {
  const {
    invoiceTotalCost,
    adminChargeCost,
    vatCost,
    adminCharge,
    vat,
    date,
    number,
    paymentAttemptDate,
    paymentMethod,
    paymentReference,
    finalTotal,
    cardPayment
  } = data;

  if (data.process === BILLING_PROCESS.INVOICE) {
    return (
      <InvoiceTotalCost
        data={{
          invoiceTotalCost,
          adminChargeCost,
          vatCost,
          adminCharge,
          vat,
          finalTotal,
          cardPayment
        }}
      />
    );
  }

  if (data.process === BILLING_PROCESS.COLLECTION_ADVICE) {
    return <CollectionTotalCost data={{ date, number, paymentAttemptDate, finalTotal }} />;
  }

  if (data.process === BILLING_PROCESS.REMITTANCE_ADVICE) {
    return (
      <RemittanceTotalCost data={{ date, number, paymentMethod, paymentReference, finalTotal }} />
    );
  }

  return <></>;
}
