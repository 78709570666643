import { Box, Button, Heading, HStack, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { first } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router';

import { SlzModal } from 'components/common/SlzModal';
import SlzPagination from 'components/common/SlzPagination/SlzPagination';
import { useQuery } from 'hooks/useQuery';
import ProductSkeleton from 'components/common/Skeleton/templates/product';

import AdaptationSection from './AdaptationSection';
import { productSchema } from 'modules/products/components/modal/form/validation/schema';
import ProductOverview from 'modules/products/components/modal/form/ProductOverview';
import { PRODUCTS_ACTIONS } from 'modules/products/_mock';
import { TAG_DEFAULT_COLOR, TAG_DEFAULT_NAME } from 'modules/products/constants';

const DEFAULT_VIEW_IDX = 0;

const getViewByIdx = (views = [], idx) =>
  views.length && first(views.filter((view) => view?.id === idx));

const Modal = (props) => {
  const {
    currentViewIdx = DEFAULT_VIEW_IDX,
    formValues,
    isOpen,
    onClose,
    onSave,
    isLoading,
    onAddStock
  } = props;
  const query = useQuery();
  const history = useHistory();
  const [currentView, setCurrentView] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [colorTag, setColorTag] = useState(TAG_DEFAULT_COLOR);

  const methods = useForm({
    defaultValues: formValues || {},
    resolver: yupResolver(productSchema)
  });
  const actions = PRODUCTS_ACTIONS;

  const onSubmit = (data) => {
    if (data.tag && (!data.tag?.name || data.tag?.name?.trim() === '')) {
      data.tag.name = TAG_DEFAULT_NAME;
    }
    onSave && onSave(data);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (formValues?.tag && formValues?.tag?.colorCode !== '') {
      setColorTag(formValues.tag?.colorCode);
    }
  }, [formValues?.tag?.colorCode]);

  useEffect(() => {
    setCurrentView(getViewByIdx(actions, currentViewIdx));
  }, [currentViewIdx]);

  useEffect(() => {
    if (!isOpen) {
      setIsEditMode(false);
      setColorTag(formValues?.tag?.colorCode);
    }
  }, [isOpen]);

  useEffect(() => {
    formValues && methods.reset(formValues);
  }, [formValues]);

  const getPreviousLabel = () => {
    if (currentViewIdx <= 1) {
      return currentView?.label;
    }

    return getViewByIdx(actions, currentViewIdx - 1)?.label;
  };

  const getNextLabel = () => {
    if (currentViewIdx >= actions.length) {
      return currentView?.label;
    }

    return getViewByIdx(actions, currentViewIdx + 1)?.label;
  };

  const handleOnEditClick = () => {
    setIsEditMode(true);
  };

  return (
    <SlzModal
      id="product-slz-modal"
      size="4xl"
      variant="product"
      accentColor={colorTag}
      colorScheme={colorTag}
      isOpen={isOpen}
      onClose={onClose}>
      <VStack justifyContent="center" alignItems="center">
        <FormProvider {...methods}>
          <form onSubmit={methods?.handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <HStack align="baseline">
              <Heading as="h3" fontSize="2xl" lineHeight="2rem">
                {currentView?.label}
              </Heading>
              {!isEditMode && (
                <Box
                  as="span"
                  fontSize={12}
                  color="main.500"
                  cursor="pointer"
                  onClick={handleOnEditClick}>
                  Edit
                </Box>
              )}
            </HStack>
            <ProductSkeleton isLoaded={!isLoading} template="overview">
              <ProductOverview
                current={currentView}
                setColorTag={setColorTag}
                isEditMode={isEditMode}
                onAddStock={onAddStock}
              />
              <AdaptationSection current={currentView} isEditMode={isEditMode} />

              <HStack pt={15} w="full" justifyContent="space-between">
                <HStack justifyContent="center" alignItems="center" w="full">
                  <SlzPagination
                    {...{
                      showTitle: false,
                      pages: null,
                      direction: {
                        usingDots: true,
                        numDots: 4,
                        isGreyBg: true,
                        prevLabel: getPreviousLabel(),
                        nextLabel: getNextLabel(),
                        currentPage: currentViewIdx,
                        totalPage: actions.length,
                        labelColor: 'main.500',
                        onChange: (currentIdx) => {
                          query.set('modal', currentIdx);
                          history.push({
                            search: query.toString()
                          });
                        }
                      }
                    }}
                  />
                </HStack>
                {isEditMode && (
                  <Button maxW={15} borderRadius={2} width="full" type="submit">
                    Save
                  </Button>
                )}
              </HStack>
            </ProductSkeleton>
          </form>
        </FormProvider>
      </VStack>
    </SlzModal>
  );
};

export default Modal;
