import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useInfiniteQuery } from 'react-query';
import useIntersectionObserver from './useIntersectionObserver';

export const useLazyLoading = ({ filters, fetchFn, transformFn, enabled = true }) => {
  const { measureRef, isIntersecting, observer } = useIntersectionObserver();

  const infiniteQuery = useInfiniteQuery({
    queryKey: [`infinite-query`, filters],
    cacheTime: 0,
    queryFn: ({ pageParam = { ...filters } }) => fetchFn(pageParam),
    select: (data) => {
      if (!transformFn) {
        return data;
      }
      const pages = data.pages.map(transformFn);
      const lastPage = pages[pages.length - 1];
      if (lastPage && !isEmpty(lastPage)) {
        lastPage[lastPage.length - 1].ref = measureRef;
      }
      return { ...data, pages };
    },
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;
      return lastPage.pageCount >= nextPage ? { ...filters, pageIndex: nextPage } : undefined;
    },
    enabled: enabled
  });
  useEffect(() => {
    if (isIntersecting) {
      infiniteQuery.fetchNextPage();
      observer.disconnect();
    }
  }, [isIntersecting]);

  return infiniteQuery;
};
