import { useQuery } from 'react-query';
import { getOrderCosts, getRecentUnsuccessfulOrders } from '../services/dashboard-api';

export const useOrderCostsQuery = ({ select }) => {
  return useQuery({
    queryKey: [`order-costs`],
    queryFn: getOrderCosts,
    select
  });
};
