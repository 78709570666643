import { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  PopoverArrow,
  VStack,
  HStack,
  Portal
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ControlledField } from 'components/form';
import SlzInput from 'components/common/SlzInput/SlzInput';
import { PasswordCriteria } from '../other';
import { useCheckPasswordCriteria, useEditPasswordMutation } from 'modules/users/hooks';
import { SlzButton } from 'components/common/SlzButton';
import { SlzTextInput } from 'components/common/SlzInput';
import { EDIT_USER_STYLES } from 'modules/users/constants/style';

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .min(6, null)
    .matches(/^(?=.*[0-9])/, null)
    .matches(/^(?=.*[#$^+=!*()@%&£"'[\]{},_\\/;:`\-~<>?.])/, null)
});
const defaultValues = {
  password: undefined
};

const EditPasswordForm = ({ userId, setEditPasswordForm }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const editPasswordMutation = useEditPasswordMutation();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });
  const password = methods.watch('password');

  const passwordCriteria = useCheckPasswordCriteria({
    password,
    confirmPassword: '',
    delayTime: 500,
    isDirty: methods?.formState.isDirty,
    isCheckConfirmPassword: false
  });

  const handleOnClose = () => {
    setEditPasswordForm && setEditPasswordForm(null);
    onClose();
  };

  const handleEditPassword = async () => {
    const isPasswordValid = await methods?.trigger('password');
    if (!isPasswordValid) return;
    const payload = {
      id: userId,
      password: password
    };
    try {
      await editPasswordMutation.mutateAsync(payload);
      handleOnClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      methods.reset();
      setEditPasswordForm && setEditPasswordForm(methods);
    }
  }, [isOpen]);

  useEffect(() => {
    if (methods.formState.isDirty) {
      setEditPasswordForm && setEditPasswordForm(methods);
    }
  }, [password, methods.formState.isDirty]);

  return (
    <FormControl>
      <FormLabel mb={1} fontSize="1rem">
        Password
        <Box matchWidth={true} as="span" onClick={onOpen} {...EDIT_USER_STYLES.editPasswordButton}>
          Edit
        </Box>
      </FormLabel>
      <Popover
        arrowSize="17"
        gutter="17"
        placement="bottom"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        closeOnBlur={false}>
        <PopoverTrigger>
          <SlzTextInput type="password" bg="light.500" value="********************" />
        </PopoverTrigger>
        <Portal>
          <Box zIndex="popover" w="full" h="full" position={'relative'}>
            <PopoverContent {...EDIT_USER_STYLES.editPasswordContent}>
              <PopoverArrow {...EDIT_USER_STYLES.arrow} />
              <FormProvider {...methods}>
                <form>
                  <VStack w="full" spacing={6} alignItems="flex-start">
                    <ControlledField
                      label="Your new password"
                      name="password"
                      type="password"
                      component={SlzInput}
                      isInlineError
                      showIcon={true}
                    />
                    <PasswordCriteria p={0} bg="transparent" criteriaStatus={passwordCriteria} />
                  </VStack>
                  <HStack justifyContent="flex-end" pt={8}>
                    <SlzButton
                      variant="outline"
                      colorScheme="negative"
                      borderRadius={1.5}
                      onClick={handleOnClose}>
                      Cancel
                    </SlzButton>
                    <SlzButton
                      onClick={handleEditPassword}
                      variant="solid"
                      borderRadius={1.5}
                      isDisabled={!methods?.formState.isDirty}>
                      Done
                    </SlzButton>
                  </HStack>
                </form>
              </FormProvider>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </FormControl>
  );
};

export default EditPasswordForm;
