import { HStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { SlzButton } from 'components/common/SlzButton';
import {
  DEFAULT_RATE_MATCHER_STEP1,
  RATE_MATCHER_SETTING_STEP
} from 'modules/integrations/constants';
import { transformShippingRates } from 'modules/integrations/utils';

const CancelButton = ({ onClose }) => (
  <SlzButton variant="outline" size="lg" colorScheme="negative" onClick={onClose}>
    Cancel
  </SlzButton>
);

const PreviousButton = ({ onClick }) => (
  <SlzButton variant="outline" size="lg" colorScheme="main" onClick={onClick}>
    Previous
  </SlzButton>
);

const NextButton = ({ onClick }) => (
  <SlzButton variant="solid" size="lg" onClick={onClick}>
    Next
  </SlzButton>
);

const StartButton = ({ onClick }) => (
  <SlzButton variant="solid" size="lg" onClick={onClick}>
    Start
  </SlzButton>
);

const SaveButton = ({ onSave }) => (
  <SlzButton variant="solid" size="lg" onClick={onSave}>
    Save
  </SlzButton>
);

const RATE_MATCHER_FOOTER_BUTTONS = {
  instruction: {
    left: CancelButton,
    right: ({ isEditMode, onNextStep }) => {
      if (isEditMode) {
        return <NextButton onClick={() => onNextStep(RATE_MATCHER_SETTING_STEP.overview)} />;
      }

      return <StartButton onClick={() => onNextStep(RATE_MATCHER_SETTING_STEP.step1)} />;
    }
  },
  overview: {
    left: ({ onPrevStep }) => (
      <PreviousButton onClick={() => onPrevStep(RATE_MATCHER_SETTING_STEP.instruction)} />
    ),
    right: ({ onClose, onNextStep }) => {
      const { getValues } = useFormContext();

      return (
        <>
          <CancelButton onClose={onClose} />
          <StartButton onClick={() => onNextStep(RATE_MATCHER_SETTING_STEP.step1, getValues())} />
        </>
      );
    }
  },
  step1: {
    left: ({ isEditMode, onPrevStep }) => {
      const prevStep = isEditMode
        ? RATE_MATCHER_SETTING_STEP.overview
        : RATE_MATCHER_SETTING_STEP.instruction;
      return <PreviousButton onClick={() => onPrevStep(prevStep)} />;
    },
    right: ({ onClose, onNextStep }) => {
      const { trigger, getValues } = useFormContext();
      const onClickNextStep = async () => {
        await trigger(Object.keys(DEFAULT_RATE_MATCHER_STEP1)).then((valid) => {
          if (valid) {
            const formValues = getValues();
            onNextStep(RATE_MATCHER_SETTING_STEP.step2, formValues);
          }
        });
      };

      return (
        <>
          <CancelButton onClose={onClose} />
          <NextButton onClick={() => onClickNextStep(RATE_MATCHER_SETTING_STEP.step1)} />
        </>
      );
    }
  },
  step2: {
    left: ({ onPrevStep }) => {
      const { getValues } = useFormContext();

      return (
        <PreviousButton
          onClick={() => {
            const formValues = getValues();
            const defaultValues = {
              ...formValues,
              shippingRates: transformShippingRates({
                shippingRates: formValues?.shippingRates
              })
            };
            onPrevStep(RATE_MATCHER_SETTING_STEP.step1, defaultValues);
          }}
        />
      );
    },
    right: ({ onNextStep, onClose }) => {
      const { getValues } = useFormContext();

      return (
        <>
          <CancelButton onClose={onClose} />
          <NextButton
            onClick={() => {
              const formValues = getValues();
              const defaultValues = {
                ...formValues,
                shippingRates: transformShippingRates({
                  shippingRates: formValues?.shippingRates
                })
              };
              onNextStep(RATE_MATCHER_SETTING_STEP.summary, defaultValues);
            }}
          />
        </>
      );
    }
  },
  summary: {
    left: ({ onPrevStep }) => {
      const { getValues } = useFormContext();

      return (
        <PreviousButton
          onClick={() => {
            onPrevStep(RATE_MATCHER_SETTING_STEP.step2, getValues());
          }}
        />
      );
    },
    right: ({ onClose, onSave }) => {
      const { getValues } = useFormContext();

      return (
        <>
          <CancelButton onClose={onClose} />
          <SaveButton onSave={() => onSave(getValues())} />
        </>
      );
    }
  }
};

const RateMatcherFormActions = (props) => {
  const { currentStep, numberOfMatchedRate, ...rest } = props;

  const { left: LeftFormActions, right: RightFormActions } =
    RATE_MATCHER_FOOTER_BUTTONS[currentStep];

  return (
    <HStack w="full" spacing={0} justifyContent="space-between" mt={20} mb={5}>
      <LeftFormActions {...rest} />
      <HStack spacing={3}>
        <RightFormActions {...rest} />
      </HStack>
    </HStack>
  );
};

export default RateMatcherFormActions;
