import { useMutation } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import {
  putDisableReturnSettings,
  putEnableReturnSettings,
  updateGradingApplyToAll,
  updateGradingApplyToNew,
  updateReturnGradingPreference,
  updateReturnSettingApplyToAll,
  updateReturnSettingApplyToNew,
  updateToggleReturnSettingPreferences
} from '../services/preferences-api';
import {
  APPLY_TO_ALL_PRODUCT,
  APPLY_TO_ONLY_NEW_PRODUCT,
  USER_SETTING_FORMS_DEFAULT_NAME
} from '../constants';

const updateReturnPreference = async ({ myProductForm }) => {
  const transformMyProductForm = {
    returnable: myProductForm?.returnable,
    selection: myProductForm?.selection.value
  };

  if (myProductForm?.selection.value === APPLY_TO_ALL_PRODUCT) {
    await updateReturnSettingApplyToAll();
  }
  if (myProductForm?.selection.value === APPLY_TO_ONLY_NEW_PRODUCT) {
    await updateReturnSettingApplyToNew();
  }
  await updateToggleReturnSettingPreferences(transformMyProductForm);
};

const updateGradingPreference = async ({ gradeForm }) => {
  const gradingPreferencePayload = {
    grading: Object.entries(gradeForm?.grading).map(([key, { value }]) => {
      return {
        grade: key,
        action: value
      };
    }),
    selection: gradeForm?.selection.value
  };
  if (gradeForm?.selection.value === APPLY_TO_ALL_PRODUCT) {
    await updateGradingApplyToAll();
  }
  if (gradeForm?.selection.value === APPLY_TO_ONLY_NEW_PRODUCT) {
    await updateGradingApplyToNew();
  }
  await updateReturnGradingPreference(gradingPreferencePayload);
};

const useReturnSettingsMutation = () =>
  useMutation({
    mutationFn: async (payload) => {
      const {
        [USER_SETTING_FORMS_DEFAULT_NAME.returnSettingEnable]: enableReturnForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.returnSettingMyProduct]: myProductForm,
        [USER_SETTING_FORMS_DEFAULT_NAME.grade]: gradeForm
      } = payload;

      !isEmpty(enableReturnForm) &&
        (enableReturnForm.enabled
          ? await putEnableReturnSettings()
          : await putDisableReturnSettings());

      !isEmpty(myProductForm) && updateReturnPreference({ myProductForm });
      !isEmpty(gradeForm) && updateGradingPreference({ gradeForm });
    }
  });

export default useReturnSettingsMutation;
